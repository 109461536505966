import { Grid, makeStyles } from "@material-ui/core";

function ToolbarFlexSpacer ( props ) {

    const { sm, md, lg, xs, xl, width } = props;

    const useStyle = makeStyles((theme) => ({
        root: {
            width: width
        }
    }));

    const classes = useStyle ();
    
    return (
        width
        ?
        <Grid item>
            <span className={classes.root} ></span>
        </Grid>
        :
        <Grid item {...props} xs={ ( sm === undefined && md === undefined && lg === undefined && xs === undefined && xl === undefined ) ? true : xs } />
    );

}

export default ToolbarFlexSpacer;

