import Button from "../../input/Button";

function DialogActionButtonLayout ( props ) {

    const { close, moveable, primary, onInitialized, ...others } = props;

    return <Button {...others} />
    
}

export default DialogActionButtonLayout;

