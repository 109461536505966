import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import layoutConf from "../../config/layoutConf";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

function MomSysMergeSplitHistory ( props ) {

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);
    
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);
    
        handleResize();
    
        return () => window.removeEventListener("resize", handleResize);
    
    }, []);
    
    return (
        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType
                                id="MomSysZvwMerge" 
                                view="MomSysZvwMerge"
                                headerTitle = {t("term.mergeList")}
                                onInitialized = {(grid) => grid.selectionMode = "None"}
                                />

                        </ReflexElement>

                        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType 
                                id="MomSysZvwSplit"
                                view="MomSysZvwSplit"
                                headerTitle = {t("term.splitList")}
                                onInitialized = {(grid) => grid.selectionMode = "None"}
                            />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }}/>

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType 
                                id="MomSysNsZvwMerge"
                                view="MomSysNsZvwMerge"
                                headerTitle = {t("term.nsMergeList")}
                                onInitialized = {(grid) => grid.selectionMode = "None"}
                            />

                        </ReflexElement>

                        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }}/>

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType 
                                id="MomSysNsZvwSplit"
                                view="MomSysNsZvwSplit"
                                headerTitle = {t("term.nsSplitList")}
                                onInitialized = {(grid) => grid.selectionMode = "None"}
                            />

                        </ReflexElement>

                    </ReflexContainer>
                </ReflexElement>

            </ReflexContainer>
        </div>
    );

}

export default MomSysMergeSplitHistory;
    