import { TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    }
}));

function SearchTextField ( props ) {

    const classes=useStyle();

    const { t } = useTranslation();

    const [ , setSearchValue ] = useState();
    
    const { id, search, searchRef, autoFocus, onSearchChangeValue } = props;

    const onChange = ( search, value ) => {

        setSearchValue ( value );

        if ( search ) {

            search.applySearch ( value );

        }

        if(onSearchChangeValue) {
            onSearchChangeValue(value);
        }
        
    }

    return (
        <TextField
            id={id}
            className={classes.root}
            placeholder={t("common.search")}
            inputRef={searchRef}
            autoFocus={autoFocus}
            onChange={(e) => onChange(search, e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>                    
                )
            }}
        />
    );
}

export default SearchTextField;
