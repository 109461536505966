import { useState } from "react";
import { useTranslation } from "react-i18next";

import SimpleScreenTypeWithoutApi from "../screenTypes/functional/SimpleScreenTypeWithoutApi";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import AccessControl from "../functional/AccessControl";

import LogViewer from "./LogViewer";

const filterSchema = {

    name: "LogViewerFilter",
    type: "VIEW",
    searchAll: true,
    columns: [
        {
            name: "logLevel",
            type: "varchar(255)",
            formItemType: "select",
            selectItems: [
                {
                    name: "💀 FATAL",
                    value: 60,
                },
                {
                    name: "🚨 ERROR",
                    value: 50,
                },
                {
                    name: "⚠️ WARN",
                    value: 40,
                },
                {
                    name: "💬 INFO",
                    value: 30,
                },
                {
                    name: "🐞 DEBUG",
                    value: 20,
                },
                {
                    name: "👣 TRACE",
                    value: 10,
                },
                {
                    name: "🔒 SECURITY",
                    value: 1000,
                },
            ],
            filter: {
                type: "valueEqual",
                formOrder: 1,
            }
        },
        {
            name: "srcFile",
            type: "varchar(255)",
            formItemType: "text",
            filter: {
                type: "valueEqual",
                formOrder: 2,
            }
        },
        {
            name: "srcLine",
            type: "varchar(255)",
            formItemType: "text",
            filter: {
                type: "valueEqual",
                formOrder: 3,
            }
        },
        {
            name: "logMsg",
            type: "varchar(255)",
            formItemType: "text",
            filter: {
                type: "valueEqual",
                formOrder: 4,
            }
        },
    ],
    useFilterFormOrder: true
};

const outputSizes = [
    25,
    50,
    75,
    100,
];


function LogViewerWithHeader() {

    const { t } = useTranslation();

    const [ connected, setConnected ] = useState ( false );

    const [ , setViewer ] = useState ();
    
    const onClear = () => {

        setViewer ( ( viewer ) => {

            if ( viewer ) {
                    
                viewer.clear();

            }

            return viewer;

        } );

    };

    const onCopy = () => {

        setViewer ( ( viewer ) => {

            if ( viewer ) {
                    
                viewer.copy();

            }

            return viewer;

        } );
        
    };

    const onOpen = () => {

        setConnected ( true );

    };

    const onClose = () => {

        setConnected ( false );

    };

    const onClickConnect = () => {

        setConnected ( ( connected ) => {

            setViewer ( ( viewer ) => {

                if ( viewer ) {

                    if ( connected ) {

                        viewer.disconnect ();

                    } else {

                        viewer.connect();

                    }

                }

                return viewer;

            } );

            return connected;

        } );

    };

    const onInitialized = ( viewer ) => {

        setViewer ( viewer );

    }

    return (

        <SimpleScreenTypeWithoutApi
            id="logViewerWithHeader"
            defaultOutputSize={ 25 }
            outputSizes={outputSizes}
            filterSchema={filterSchema}
            onAppendButtonAtFirst={ () =>
                <>
        
                    <BasicCrudScreenActionItemLayout>
        
                        <ActionButtonLayout id="clearLog" onClick={onClear}>{t("buttonName.clear")}</ActionButtonLayout>
        
                    </BasicCrudScreenActionItemLayout>
        
                    <BasicCrudScreenActionItemLayout>
        
                        <ActionButtonLayout id="copyLog" onClick={onCopy}>{t("buttonName.copyLog")}</ActionButtonLayout>
        
                    </BasicCrudScreenActionItemLayout>

                            
                    <BasicCrudScreenActionItemLayout>
        
                        <ActionButtonLayout id="connectLog" onClick={onClickConnect}>{connected?t("buttonName.disconnect"):t("buttonName.connect")}</ActionButtonLayout>
        
                    </BasicCrudScreenActionItemLayout>
        
                </>
            }
        >
            <AccessControl
                accessTarget="LogViewer"
                accessMethod="GET"
            >
                <LogViewer onInitialized={onInitialized} onLogOpen={onOpen} onLogClose={onClose} />
            </AccessControl>
        </SimpleScreenTypeWithoutApi>
    );
}

export default LogViewerWithHeader;
