import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import EguMstMaterialFileCreateButton from "../input/EguMstMaterialFileCreateButton";
import EguMstMaterialFileDeleteButton from "../input/EguMstMaterialFileDeleteButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";

function EguMstMaterialFile(props) {
    const { id, selectMstGrid } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectFile, setSelectFile] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;

        async function schemaSetting() {
            let schema = await getSchema("EguMstMaterialFile");

            if ( mounted ) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMstGrid]);
    
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectFile(grid.selectedItems[0]);
                    }
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectFile(grid.selectedItems[0]);
                    }
                });
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
        if(api) {
            api.refresh();
        };
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onModifyUrl = (url) => {
        return `api/EguMstMaterialFile/${selectMstGrid.materialCode}/${selectMstGrid.materialCodeVer}`;
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="EguMstMaterialFile"
                view="EguVwMstMaterialFile"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                onModifyViewUrl={(url) => onModifyUrl(url)}
                headerTitle={t("term.standardFile")}
                noCreate
                noEdit
                noDelete
                noExcel
                noSearch
                noFilter
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <EguMstMaterialFileCreateButton
                                    id = "EguMstMaterialFileCreate"
                                    schema = {schema}
                                    refresh = {api}
                                    selectMstGrid = {selectMstGrid}
                                    onSuccess = {onSuccess}
                                />
                            </AccessControl>

                            <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                <EguMstMaterialFileDeleteButton
                                    id={id}
                                    schema={schema}
                                    grid={grid}
                                    refresh={api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default EguMstMaterialFile;
