import MaterialDialog from "../input/MaterialDialog";/* eslint-disable react-hooks/exhaustive-deps */
import WijmoDialog from "../input/WijmoDialog";

import uiConf from "../../../config/uiConf";

function DialogLayout ( props ) {

    const { platform, modaless, ...others } = props;

    if ( modaless ) {

        return <WijmoDialog {...others} />

    } else {
        
        if ( platform ) {

            switch ( platform ) {
    
                case "material-ui":
        
                    return <MaterialDialog {...others} />
    
                case "wijmo":

                    return <WijmoDialog {...others} />
        
                default:
        
                    return <MaterialDialog {...others} />
        
            }
        
        } else {
    
            switch ( uiConf.dialogPlatform ) {
    
                case "material-ui":
        
                    return <MaterialDialog {...others} />
        
                case "wijmo":
        
                    return <WijmoDialog {...others} />

                default:
    
                    return <MaterialDialog {...others} />
                        
            }
            
        }
    
    }

}

export default DialogLayout;
