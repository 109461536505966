/* eslint-disable react-hooks/exhaustive-deps */
import { Children, cloneElement, useState, useEffect } from "react";
import { getOption } from "../../lib/api/getOptions";

import uiConf from "../../config/uiConf";

function Search ( props ) {

    const { control, onChange, onInitialized, children, ...others } = props;

    const [ search, setSearch ] = useState ( "" );

    const [ , setTimer ] = useState ( null );
    const [ , setSearchDelay ] = useState ( uiConf.searchDelay );

    const delayedSetSearch = ( value ) => {

        setSearchDelay ( ( searchDelay ) => {

            setTimer ( ( timer ) => {

                if ( timer ) {
    
                    clearTimeout ( timer );
    
                }
    
                return setTimeout ( ( ) => {
    
                    setSearch ( value );
    
                    if ( onChange ) {
    
                        onChange ( value );
    
                    }
    
                }, searchDelay );
    
            });

            return searchDelay;

        } );


    };
    
    const onControlValueChanged = ( event ) => {

        delayedSetSearch ( event.target.value );

    }

    useEffect ( () => {

        let mounted = true;

        ( async () => {
        
            let searchDelay = await getOption ( "default", "searchDelay" );

            if ( mounted ) {
                
                setSearchDelay ( searchDelay ? searchDelay : uiConf.searchDelay );

            }
    
        } ) ();

        return ( () => {
                
                mounted = false;
    
        } );

    }, [] )
    
    useEffect ( () => {

        if ( control ) {

            control.current.addEventListener ( "change", onControlValueChanged );

        }

        let myInstance = {
            
            applySearch: ( value ) => {

                delayedSetSearch ( value );
        
            }

        }

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

    }, [ control ] );
        
    return (
        <>
            {
                Children.map(children, (child => 
                    cloneElement(child, {...others, $search: search})
                ))
            }
        </>
    );

}

export default Search;
