import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MonthlyProductInOutReport (props){

    const table = "MonthlyProductionReport";
    const view = "MonthlyProductionReport";

    const { date, setView, setExcelFileName, onSearchInitialized } = props;
    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if ( date && api ) {

            setView(view);
            setExcelFileName("월간");

            api.refresh ();

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api, date ] );

    const onModifyUrl = ( url ) => {

        let newUrl = `${url}/${date.getFullYear()}/${date.getMonth()+1}`        

        return newUrl;
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {
        grid.allowSorting = false;
    };

    return (
    
        <BasicCrudScreenType
            id="MonthlyProductionReport"
            noHeader
            table={table}
            view={view}
            viewSchemaUrl={`/api/MonthlyProductionReportSchema/${date.getFullYear()}/${date.getMonth()+1}`}
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onInitialized={onGridInitialized}
        />

    );

}
export default MonthlyProductInOutReport;