import { TextField, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../input/IconButton";
import Icon from "../presentation/Icon";
import InputAdornment from '@mui/material/InputAdornment';
import {BsQrCodeScan} from 'react-icons/bs'

import _, { debounce } from "lodash";
const Hangul = require('hangul-js');

const useStyle = makeStyles((theme) => ({
    root: {
        width:'100%', 
        display:'flex',  
        flexDirection:'row',
    },
    barcodeText: {
        flex:1
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
}));

function BarcodeTextField ( props ) {

    const classes=useStyle();

    const { t } = useTranslation();

    const [barcode , setBarcode ] = useState('');
    const barcodeRef = useRef(null)
    const { barcodeCallback, id } = props;

    const barcodeConverter = (input) => {
        console.log('input:',input)

        const koreanToEnglishMap = {
            'ㅂ': 'Q',
            'ㅈ': 'W',
            'ㄷ': 'E',
            'ㄱ': 'R',
            'ㅅ': 'T',
            'ㅛ': 'Y',
            'ㅕ': 'U',
            'ㅑ': 'I',
            'ㅐ': 'O',
            'ㅔ': 'P',
            'ㅁ': 'A',
            'ㄴ': 'S',
            'ㅇ': 'D',
            'ㄹ': 'F',
            'ㅎ': 'G',
            'ㅗ': 'H',
            'ㅓ': 'J',
            'ㅏ': 'K',
            'ㅣ': 'L',
            'ㅋ': 'Z',
            'ㅌ': 'X',
            'ㅊ': 'C',
            'ㅍ': 'V',
            'ㅠ': 'B',
            'ㅜ': 'N',
            'ㅡ': 'M',
            'ㄲ': 'R',
            'ㄸ': 'E',
            'ㅃ': 'Q',
            'ㅆ': 'T',
            'ㅉ': 'W',
            'ㅒ': 'O',
            'ㅖ': 'P',
        };

        let output = '';

        for (let i = 0; i < input.length; i++) {
            const char = input.charAt(i);
            
            if (koreanToEnglishMap[char]) {
                output += koreanToEnglishMap[char];
            }  else {
                output += char;
            }
        }

        return output
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
          // QR 코드 값이 모두 입력되었을 때 처리하는 로직 작성
          if (barcode.length > 0) {
            // console.log('QR 코드 입력 완료:', barcode);
            const convertedInput = barcodeConverter(Hangul.disassemble(barcode).join(''))

            if (convertedInput != barcode) {
                setBarcode(barcodeConverter(Hangul.disassemble(barcode).join('')))
                return
            }      
            if (barcodeCallback) barcodeCallback(barcode)               
          }
          
        }, 300); // 초의 디바운스 시간 설정
    
        return () => {
          clearTimeout(timerId);
        };
    }, [barcode]);

    return (
        <div className={classes.root}>
            <TextField inputRef={barcodeRef} 
                id={id}
                 value={barcode} 
                 className={classes.barcodeText}
                onChange={(e) => { setBarcode(e.target.value); }} autoFocus={true} 
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BsQrCodeScan />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton className={classes.closeButton} onClick={(e) => {barcodeRef.current.focus();setBarcode('')}} >
                            <Icon >close</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

            {/* <BsQrCodeScan /> */}

            
        </div>
        
    );
}

export default BarcodeTextField;
