import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";

import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";

import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysInspectionGroup(props) {
    const [height, setHeight] = useState(0);

    const [inspectionItemSchema, setInspectionItemSchema] = useState();
    const [inspectionCriteriaSchema, setInspectionCriteriaSchema] = useState();
    const [inspectionGroupSchema, setInspectionGroupSchema] = useState();
    const [inspectionListSchema, setInspectionListSchema] = useState();

    const [inspectionGroupGrid, setInspectionGroupGrid] = useState();
    const [inspectionListGrid, setInspectionListGrid] = useState();

    const [inspectionGroupIsEmpty, setInspectionGroupIsEmpty] = useState();
    const [inspectionListIsEmpty, setInspectionListIsEmpty] = useState();

    const [inspectionGroupApi, setInspectionGroupApi] = useState();
    const [inspectionListApi, setInspectionListApi] = useState();

    const [inspectionGroupSelect, onInspectionGroupSelect] = useState();

    const { t } = useTranslation();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            if (mounted) {
                let inspectionItemSchema = await getSchema("MomSysInspectionItem");
                setInspectionItemSchema(inspectionItemSchema);

                let inspectionCriteriaSchema = await getSchema("MomSysInspectionCriteria");
                setInspectionCriteriaSchema(inspectionCriteriaSchema);

                let inspectionGroupSchema = await getSchema("MomSysInspectionGroup");
                setInspectionGroupSchema(inspectionGroupSchema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (
                // inspectionGroupSelect &&
                 mounted) {
                let schema = await getSchema("MomSysInspectionList");

                for (let column of schema.columns) {
                    switch (column.name) {
                        case "inspectionGroupId":
                            column.defaultValue = inspectionGroupSelect?.inspectionGroupId;
                            break;
                        default:
                            break;
                    }
                }

                setInspectionListSchema(schema);
            }
        })();

        setInspectionListApi((api) => {
            if (api) {
                api.refresh();
            }

            return api;
        });

        return () => {
            mounted = false;
        };
    }, [inspectionGroupSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (inspectionGroupGrid && mounted) {
                inspectionGroupGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });

                inspectionGroupGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });
            }
        })();

        return () => {
            mounted = false;
        };
    }, [inspectionGroupGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.4} data-cy={"MomSysInspectionGroup"}>
                    <BasicCrudScreenType
                        id={"MomSysInspectionGroup"}
                        headerTitle={t("term.momSysInspectionGroup")}
                        table={"MomSysInspectionGroup"}
                        view={"MomSysVwInspectionGroup"}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setInspectionGroupGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setInspectionGroupApi(api);
                        }}
                        onLoadData={(data) => {
                            setInspectionGroupIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <CreateRecordButton
                                        id={"MomSysInspectionGroup"}
                                        schema={inspectionGroupSchema}
                                        refresh={inspectionGroupApi}
                                        onSuccess={onSuccess}
                                    />
                                    <UpdateRecordButton
                                        id={"MomSysInspectionGroup"}
                                        schema={inspectionGroupSchema}
                                        grid={inspectionGroupGrid}
                                        refresh={inspectionGroupApi}
                                        disabled={inspectionGroupIsEmpty}
                                        onSuccess={onSuccess}
                                    />
                                    <DeleteRecordButton
                                        id={"MomSysInspectionGroup"}
                                        schema={inspectionGroupSchema}
                                        grid={inspectionGroupGrid}
                                        refresh={inspectionGroupApi}
                                        color="secondary"
                                        disabled={inspectionGroupIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.6} data-cy={"MomSysInspectionList"}>
                    {/* {inspectionGroupSelect?.inspectionGroupId ? ( */}
                        <BasicCrudScreenType
                            id="MomSysInspectionList"
                            headerTitle={t("term.momSysInspectionList")}
                            table="MomSysInspectionList"
                            view="MomSysVwInspectionList"
                            onModifyViewSchema={(schema) => {
                                if (schema) {
                                    for (const column of schema.columns) {
                                        switch (column.name) {
                                            case "inspectionGroupName":
                                                column.grid.hide = true;
                                                column.filter = null;
                                                break;

                                            default:
                                                break;
                                        }
                                    }
                                }
                            }}
                            onModifyViewUrl={(url) => {
                                return url + "/" + inspectionGroupSelect?.inspectionGroupId;
                            }}
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setInspectionListGrid(grid);
                            }}
                            onApiInitialized={(api) => {
                                setInspectionListApi(api);
                            }}
                            onLoadData={(data) => {
                                setInspectionListIsEmpty(data.length < 1 ? true : false);
                            }}
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onAppendButtonInFrontOfSearch={() => (
                                <React.Fragment>
                                    <BasicCrudScreenActionItemLayout>
                                        <CreateRecordButton
                                            id={"MomSysInspectionList"}
                                            schema={inspectionListSchema}
                                            refresh={inspectionListApi}
                                            onSuccess={onSuccess}
                                        />
                                        <DeleteRecordButton
                                            id={"MomSysInspectionList"}
                                            schema={inspectionListSchema}
                                            grid={inspectionListGrid}
                                            refresh={inspectionListApi}
                                            color="secondary"
                                            disabled={inspectionListIsEmpty}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                        />
                                    </BasicCrudScreenActionItemLayout>
                                </React.Fragment>
                            )}
                        />
                    ) 
                   {/*  : (
                        <React.Fragment></React.Fragment>
                    )} */}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysInspectionGroup;
