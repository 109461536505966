import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Typography } from '@material-ui/core';

import apiClient from "../../lib/common/apiClient";
import Link from '@material-ui/core/Link';
import React from 'react';
import tenantState from "../../states/tenantState";

function Copyright(props) {

    const [ dbInfo, setDbInfo ] = useState ( "" );
    const [ tenant ] = useRecoilState( tenantState );

    useEffect ( () => {

        ( async () => {

            let response = await apiClient.get ( `/api/VwTenant/${tenant}` );
            let tenantInfo = response.data;
            let dbConf = JSON.parse ( tenantInfo.dbConf );

            setDbInfo ( `${dbConf.adminConnectionInfo.host}/${dbConf.connectionInfo.database}`);

        } ) ();

    }, [tenant] );

    return (
        <React.Fragment>
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
            >
            {
                ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' )
                ?
                (
                    <>
                        {'Copyright © '}
                        <Link color="inherit" href="https://ariatech.kr/">
                                주식회사 아리아텍 All rights reserved
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </>
                )
                :
                dbInfo
            }
            </Typography>
        </React.Fragment>
    );
}

export default Copyright;
