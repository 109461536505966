import { useState } from "react";
import { InputAdornment } from "@material-ui/core";

import Icon from "../../presentation/Icon";
import FormItemText from "./FormItemText";

function FormItemIcon ( props ) {

    const { InputProps, onChange, ...others } = props;

    const [ iconName, setIconName ] = useState ( props.value );

    const onChangeIconName = ( value ) => {

        setIconName ( value );

        if ( onChange ) {

            onChange ( value );

        }
    }

    return (
        <FormItemText
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <Icon>{iconName}</Icon>
                    </InputAdornment>                    
                )
            }}
            onChange={onChangeIconName}
            {...others}
        />
    );

}

export default FormItemIcon;

