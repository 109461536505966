/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysProductLots ( props ) {

    const { t } = useTranslation();
    const { lotInfo } = props;

    const [, setApi] = useState();

    useEffect ( () => {
        
        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [lotInfo] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        grid.childItemsPath = "products";
        
    };

    const onApiInitialized = (api) => {

        setApi ( api );

    }

    const onModifyUrl = ( lotInfo, url ) => {
        
        let newUrl;

        if ( lotInfo ) {

            newUrl = `${url}/${lotInfo.lotNo}`;

        } else {

            newUrl = `${url}/undefined`;

        }

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwProductsFromLot"
            headerTitle={t("term.productLots")}
            table="MomSysVwProductsFromLot"
            view="MomSysVwProductsFromLot"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewUrl={( url ) => onModifyUrl ( lotInfo, url )}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysProductLots;
