/* eslint-disable react-hooks/exhaustive-deps */
import FilterCancelButtonLayout from "../layout/FilterCancelButtonLayout";

function FilterCancelButton ( props ) {

    const { onCancel, ...others } = props;

    const onClick = () => {

        if ( onCancel ) {
            
            onCancel ();

        }

    }

    return <FilterCancelButtonLayout onClick={onClick} {...others} />

}

export default FilterCancelButton;

