import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AutoFilterFormItem from "./AutoFilterFormItem";

import AutoFilterFormDateTimeRangeWithButtonsLayout from "../layout/AutoFilterFormDateTimeRangeWithButtonsLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";
import { Button } from "@material-ui/core";

function AutoFilterFormDateTimeRangeWithButtons ( props ) {

    const { t } = useTranslation ();

    const { id, tabIndex, column, filterValue, onChange } = props;
    
    const [ filter, setFilter ] = useState(

        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: "" },
            condition2: { operator: "5", value: "" }
        
        }

    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    useEffect ( () => {

        if ( column ) {

            if ( !column.filter.value ) {

                column.filter.value = {};
                
            }

            column.filter.value.width = "20%";

        }

    }, [column] )

    const onChangeCondition1Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.operator = "3";
            newFilter.condition1.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition2Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.operator = "5";
            newFilter.condition2.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onYesterdayClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setDate ( now.getDate () - 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setDate ( now.getDate () - 1 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    const onTodayClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    const onPrevWeekClick = () => {

        let now = new Date ();
        let start = new Date ( now );

        let day = now.getDay ();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }
        start.setDate ( now.getDate () - 7 - day );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );
        
        let end = new Date( start );

        end.setDate ( start.getDate () + 6 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    const onCurrentWeekClick = () => {

        let now = new Date ();
        let start = new Date ( now );

        let day = now.getDay ();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }

        start.setDate ( now.getDate () - day) ;
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        let end = new Date( start );

        end.setDate ( start.getDate () + 6 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    const onPrevMonthClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setMonth ( now.getMonth () - 1 );
        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setMonth ( now.getMonth () );
        end.setDate ( 0 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    const onCurrentMonthClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setMonth ( now.getMonth () );
        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setMonth ( now.getMonth () + 1 );
        end.setDate ( 0 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        let filter = {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: start },
            condition2: { operator: "5", value: end }
        
        };

        setFilter ( filter );

        if ( onChange ) {

            setTimeout ( () => onChange ( filter ), 0 );

        }

    };

    return (

        <AutoFilterFormDateTimeRangeWithButtonsLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem                    
                        id={id+"-condition1-value"}
                        column={column}
                        value={filter.condition1.value}
                        autoFocus={tabIndex===1}
                        onChange={onChangeCondition1Value}
                />

            </AutoFilterFormValueItemLayout>

            <span style={{display: "inline-block", paddingTop: "32px", paddingLeft: "4px", paddingRight: "4px"}}>~</span>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem
                        id={id+"-condition2-value"}
                        label=" "
                        column={column}
                        value={filter.condition2.value}
                        onChange={onChangeCondition2Value}
                />

            </AutoFilterFormValueItemLayout>

            <Button onClick={onYesterdayClick}>{t("term.yesterday")}</Button>
            <Button onClick={onTodayClick}>{t("term.today")}</Button>
            <Button onClick={onPrevWeekClick}>{t("term.prevWeek")}</Button>
            <Button onClick={onCurrentWeekClick}>{t("term.currentWeek")}</Button>
            <Button onClick={onPrevMonthClick}>{t("term.prevMonth")}</Button>
            <Button onClick={onCurrentMonthClick}>{t("term.currentMonth")}</Button>
            
        </AutoFilterFormDateTimeRangeWithButtonsLayout>

    );

}

export default AutoFilterFormDateTimeRangeWithButtons;

