import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguUpdateOutSourcingFakeInputButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }
        console.log(formData);

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        refresh.refresh();
    };

    const checkFormValidationCallback = (formData) => {
        /*** 2023/04/28 : OutSourcing Revision : 가입고 수량이 더 많아도 상관 없음.
        if (formData.fakeInputQty < formData.outSourcingQty) {
            return [false, t("error.fakeInputQtyCannotBeSmallerThanOutSourcingQty")];
        }
        ***/

        return [true, ""];
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkFormValidationCallback={checkFormValidationCallback}
            />
        </>
    );
}

export default EguUpdateOutSourcingFakeInputButton;
