/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useTranslation } from "react-i18next";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import defineConf from "../../../config/defineConf";

function DataGridMrpEventTypeColumn(props) {
    const { schema, ...others } = props;

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    const { t } = useTranslation();

    useEffect(() => {
        setCellTemplate(() => (ctx) => {
            let eventType = parseInt(ctx.item.eventType);

            switch (eventType) {
                case defineConf.mrpEventType.CONSUME:
                    return <span>{t(`selectItems.mrpEventType.consume`)}</span>;

                case defineConf.mrpEventType.PRODUCTION:
                    return <span>{t(`selectItems.mrpEventType.production`)}</span>;

                case defineConf.mrpEventType.INPUT_BY_PURCHASE:
                    return <span>{t(`selectItems.mrpEventType.inputByPurchase`)}</span>;

                default:
                    return <span>{eventType}</span>;
            }
        });
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridMrpEventTypeColumn;
