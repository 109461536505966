import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysFailLotMoveDialog from "../dialogTypes/MomSysFailLotMoveDialog";

function MomSysFailLotMoveButton ( props ) {

    const { id, refresh, onClose, onSuccess, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, modifyUrlCallback, items, ...others } = props;

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);
    const [ schema, setSchema ] = useState();

    useEffect(() => {

        let schema = {

            name: "MomSysFailLotManagement",
            type: "BASE TABLE",
            searchAll: false,

            columns: [
                {

                    name: "lotNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    hideInForm: true,
                    formOrder: 10,
                    formItemType: "text",
                    grid: {
                        isReadOnly: true, 
                        type: "editor"
                    }
                },
                {
                    name: "materialCode",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    hideInForm: true,
                    formOrder: 20,
                    formItemType: "text",
                },
                {
                    name: "materialCodeVer",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 30,
                    formItemType: "text",
                },
                {
                    name: "materialName",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    hideInForm: true,
                    formOrder: 40,
                    formItemType: "text",
                    grid: {
                        isReadOnly: true, 
                        type: "editor"
                    }
                },
                {
                    name: "qtyCurrent",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    hideInForm: true,
                    formOrder: 45,
                    formItemType: "text",
                    grid: {
                        isReadOnly: true, 
                        type: "editor",
                        format: "g4"
                    },
                },
                {
                    name: "price",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 50,
                    grid: {
                        isReadOnly: false, 
                        type: "editor",
                        format: "g4"
                    },
                    validation: {
                        msg: "error.floatRequired",
                        type: "regexp",
                        value: "^[+-]?([0-9]*[.])?[0-9]+$",
                    },
                    editor: { 
                        type: "number", 
                        props: {
                            format: 'g4',
                            min: 0
                        }
                    }
                },
                {
                    name: "amount",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 60,
                    grid: {
                        isReadOnly: false, 
                        type: "editor",
                        format: "g4"
                    },
                    validation: {
                        msg: "error.floatRequired",
                        type: "regexp",
                        value: "^[+-]?([0-9]*[.])?[0-9]+$",
                    },
                    editor: { 
                        type: "number", 
                        props: {
                            format: 'g4',
                            min: 0
                        }
                    }
                },
                {
                    name: "wareHouseCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    hideInForm: false,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysVwInputWareHouse",
                        nameColumn: ["wareHouseCode", "wareHouseName"],
                        valueColumn: "wareHouseCode",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    },
                    required: true,
                },
            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen ( true );

    };

    const onDialogClose = () => {

        setOpen ( false );

        if ( onClose ) {

            onClose ();

        }

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    const modifyUrl = (url) => {

        return url + "/failLotMove"

    }

    return (

        <>
            <CreateRecordButtonLayout id={id+"-failLotMove-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <MomSysFailLotMoveDialog
                id={id+"failLotMove-dialog"}
                mode="create"
                formId={id+"failLotMove-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.failLotMove` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onDialogClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                modifyUrlCallback={modifyUrl}
                noKeepOpen
                items={items}
                maxWidth={"lg"}
            />
        </>

    );
}

export default MomSysFailLotMoveButton;

