import { useState, useEffect } from "react";

import AutoFilterFormItem from "./AutoFilterFormItem";
import FilterFormItemCondition from "../input/FilterFormItemCondition";
import AutoFilterFormValueConditionLayout from "../layout/AutoFilterFormValueConditionLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";
import AutoFilterFormValueConditionItemLayout from "../layout/AutoFilterFormValueConditionItemLayout";
import { useTranslation } from "react-i18next";

function AutoFilterFormValueCondition ( props ) {

    const { t } = useTranslation ();
    
    const { id, tabIndex, column, filterValue, onChange } = props;

    const [ filter, setFilter ] = useState(

        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: "true",
            condition1: { operator: "", value: "" },
            condition2: { operator: "", value: "" }
        
        }

    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    const onChangeValue = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition = ( condition ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.operator = condition;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    return (

        <AutoFilterFormValueConditionLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem                    
                            id={id+"-condition1-value"}
                            column={column}
                            value={filter.condition1.value}
                            autoFocus={tabIndex === 1}
                            onChange={onChangeValue}
                />

            </AutoFilterFormValueItemLayout>

            <AutoFilterFormValueConditionItemLayout column={column} >


                <FilterFormItemCondition
                        id={id+"-condition1-operator"}
                        column={column}
                        label={t("dataTableFilter.inputTitle.filterOpr")}
                        value={filter.condition1.operator}
                        onChange={onChangeCondition}
                />

            </AutoFilterFormValueConditionItemLayout>

        </AutoFilterFormValueConditionLayout>

    );

}

export default AutoFilterFormValueCondition;

