import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";
import { CollectionView } from "@grapecity/wijmo";
import { cloneDeep } from "lodash";

import BasicStaticGridScreenType from "../screenTypes/functional/BasicStaticGridScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";

import AutoForm from "../form/functional/AutoForm";
import Button from "../input/Button";

import checkValidation from "../../lib/utils/checkValidation";

function MomSysFailedLot(props) {

    const { t } = useTranslation();

    const { notifyWarning, onChangeFailedLotList } = props;

    const [ grid, setGrid ] = useState();
    const [ formData, setFormData ] = useState();
    
    const [ failedLotList, setFailedLotList ] = useState([]);
    const [ collectionView, setCollectionView ] = useState();
    
    const schema = {

        name: "FailedLot",
        type: "BASE TABLE",
        searchAll: false,

        columns: [

            {
                name: "failureCode",
                type: "varchar(50)",
                required: true,
                formOrder: 1,
                formItemType: "autocomplete",
                codeInfo: {
                    tableName: "MomSysVwProcessingFailureCode",
                    nameColumn: "decisionName",
                    valueColumn: "decisionCode",
                    sortByName: true,
                },
                grid: {
                    columnOrder: 1,
                },
                visible: true,
                width: "100%",
                validation: false
            },
            {
                name: "qty",
                required: true,
                type: "float",
                formOrder: 3,
                formItemType: "text",
                validation: {
                    msg: "error.positiveFloatRequired",
                    type: "conditions",
                    operator: "and",
                    value: [
                        {
                            msg: "error.floatRequired",
                            type: "regexp",
                            value: "^[+-]?([0-9]*[.])?[0-9]+$"
                        },
                        {
                            msg: "error.positiveFloatRequired",
                            type: "value",
                            operator: "gt",
                            value: 0
                        }    
                    ]    
                },
                grid: {
                    columnOrder: 3,
                },
                visible: true,
                width: "100%",
            },

        ]

    };

    useEffect(() => {

        let collectionView = new CollectionView(failedLotList);

        setCollectionView(collectionView);
        
    }, [failedLotList]);

    const onChangeForm = (formData ) => {

        setFormData ( formData );

    };

    const addFailedLot = ( formData ) => {

        if ( ! checkItemValidataion ( formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        setFailedLotList ( ( failedLotList ) => {

            let lotList = cloneDeep ( failedLotList );
            
            let lotInList = false;

            for ( const lot of lotList ) {

                if ( lot.failureCode === formData.failureCode ) {

                    lot.qty += parseInt ( formData.qty );
                    lotInList = true;

                }


            }

            if ( !lotInList ) {

                lotList.push ( { ...formData, qty: parseInt ( formData.qty ) } );

            }
            
            if ( onChangeFailedLotList ) {

                onChangeFailedLotList ( lotList );
    
            }

            return lotList;

        } );

    };

    const deleteFailedLot = ( ( grid, failedLotList ) => {

        let lotList = cloneDeep ( failedLotList );

        if ( ! grid || ! grid.selectedItems ) {

            return;

        }

        for ( const item of grid.selectedItems ) {

            lotList = lotList.filter ( ( lot ) => lot.failureCode !== item.failureCode );

        }

        setFailedLotList ( lotList );

        if ( onChangeFailedLotList ) {

            onChangeFailedLotList ( lotList );

        }

    } );

    const checkItemValidataion = ( formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "edit", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                if ( notifyWarning ) {

                    notifyWarning ( msg );

                }

                return false;

            }
            
        }

        return true;

    }

    const onGridInitialized = ( grid ) => {

        setGrid ( grid );

    }

    return (

        <>
            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.7} >

                    <BasicStaticGridScreenType                        
                        id="FailedLotList"
                        headerTitle={t("term.failedLotList")}
                        schema={schema}
                        itemsSource={collectionView}
                        onAppendButton={ () => (
                            <>
                                <ToolbarFlexSpacer xs />

                                <BasicCrudScreenActionItemLayout>

                                    <Button                            
                                        id="DeleteFailedLot"
                                        color="secondary"
                                        disabled={failedLotList.length < 1}
                                        onClick={ () => deleteFailedLot ( grid, failedLotList ) }
                                    >
                                        {t("buttonName.delete")}
                                    </Button>

                                </BasicCrudScreenActionItemLayout>
                            </>
                        ) }
                        onInitialized={onGridInitialized}
                    />

                </ReflexElement>

                <ReflexSplitter
                    style={{border: "none",width: "1px",color:"lightgray"}}
                />

                <ReflexElement flex={0.3} >

                    <div style={{padding: "16px"}}>

                        <div style={{paddingBottom: "8px"}}>

                            <AutoForm
                                id="input-bonus-loss-form"
                                initialData={formData}
                                schema={schema}
                                mode="edit"
                                onChange={ onChangeForm }
                            />

                        </div>

                        <Button id="AddFailedLot" color="primary" style={{float: "right"}} onClick={() => addFailedLot ( formData )}>{t("buttonName.addFailedLot")}</Button>

                    </div>

                </ReflexElement>

            </ReflexContainer>


        </>

    )

}

export default MomSysFailedLot;
