import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import IconButton from "../input/IconButton";
import Icon from "../presentation/Icon";

import { getOption } from '../../lib/api/getOptions';

import getList from '../../lib/api/getList';

import BasicCrudScreenTypeLayout from "../screenTypes/layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudScreenContentLayout from "../screenTypes/layout/BasicCrudScreenContentLayout";
import BasicCrudScreenFooterLayout from "../screenTypes/layout/BasicCrudScreenFooterLayout";
import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";

import EguProductionStatusSummaryPage from "./EguProductionStatusSummaryPage";

function EguProductionMonitoring ( props ) {
    
    const { t } = useTranslation();
    const { handle, onComplete } = props;

    let currentTime = new Date ();

    const [time, setTime] = useState ( `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}` );
    const [page, setPage] = useState ("");
    const [productionStatus, setProductionStatus] = useState ();

    const getData = async ( start, length, interval ) => {

        try {
    
            let result = await getList ( `EguVwDashProductionMonitoring?$start=${start}&$length=${length}` );

            let productionStatus = cloneDeep ( result );
    
            productionStatus.start = start;
            productionStatus.length = length;
            productionStatus.interval = interval;
    
            if ( productionStatus?.data?.length > 0 ) {
                        
                while ( productionStatus?.data?.length < length ) {
    
                    productionStatus.data.push ( {
                        orderBuyerId: "-",
                        materialName: "",
                        category: "제심",
                        outSourcing: "",
                        plannedQty: "",
                        status: "",
                        startTime: "",
                        endTime: "",
                        resultQty: "",
                        failQty: "",
                        failRatio: ""
                    } );
    
                    productionStatus.data.push ( {
                        orderBuyerId: "",
                        materialName: "",
                        category: "우드",
                        outSourcing: "",
                        plannedQty: "",
                        status: "",
                        startTime: "",
                        endTime: "",
                        resultQty: "",
                        failQty: "",
                        failRatio: ""
                    } );
    
                    productionStatus.data.push ( {
                        orderBuyerId: "",
                        materialName: "",
                        category: "몰딩",
                        outSourcing: "",
                        plannedQty: "",
                        status: "",
                        startTime: "",
                        endTime: "",
                        resultQty: "",
                        failQty: "",
                        failRatio: ""
                    } );
    
                    productionStatus.data.push ( {
                        orderBuyerId: "-",
                        materialName: "",
                        category: "포장",
                        outSourcing: "",
                        plannedQty: "",
                        status: "",
                        startTime: "",
                        endTime: "",
                        resultQty: "",
                        failQty: "",
                        failRatio: ""
                    } );
    
                }
                
                let page = Math.floor ( start / length ) + 1 + " / " +  Math.ceil ( result.total / length );
    
                let currentTime = new Date ();
    
                setTime ( `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}` );
                setPage ( page );
                setProductionStatus ( productionStatus );
    
            } else {
    
                if ( onComplete ) {
    
                    onComplete ();
                }
    
            }

        } catch ( reason ) {

            // retry on error
            console.error ( reason );
            setTimeout ( () => getData ( start, length, interval ), interval * 1000 );

        }

    }

    const nextPage = ( start, length, interval ) => {

        let currentTime = new Date ();

        setTime ( `${currentTime.getFullYear()}-${currentTime.getMonth() + 1}-${currentTime.getDate()} ${currentTime.getHours()}:${currentTime.getMinutes()}` );
        
        ( async () => {

            await getData ( start, length, interval );

        } ) ();

    };

    useEffect ( () => {

        ( async () => {

            let length = await getOption ( "dashboard", "productionMonitoringPageLength" );
            let interval = await getOption ( "dashboard", "productionMonitoringDisplayInterval" );

            length = length !== undefined ? parseInt(length) : 20;
            interval = interval !== undefined ? parseInt(interval) : 5;

            let start = 0;

            await getData ( start, length, interval );
    
        } ) ();

        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    return (

        <BasicCrudScreenTypeLayout>

            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                    <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                        <span
                            color="inherit"
                            align="center"
                            style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginLeft: "8px",
                                marginTop: "0px",
                                marginBottom: "8px",
                            }}
                        >
                            { t ("term.productionStatus") }
                        </span>
                        
                        <ToolbarFlexSpacer xs />

                        {
                            handle
                            ?
                            (
                                <span
                                    style={{
                                        marginRight: "8px"
                                    }}                                
                                >

                                    <span
                                        color="inherit"
                                        align="center"
                                        style={{
                                            fontSize: "16px",
                                            marginLeft: "8px",
                                            marginTop: "0px",
                                            marginBottom: "8px",
                                            marginRight: "8px"
                                        }}
                                    >
                                        {time}
                                    </span>

                                    {
                                        handle.active
                                        ?
                                        <IconButton size="small" onClick={handle.exit} style={{marginBottom: "4px"}}>
                                            <Icon>fullscreen_exit</Icon>
                                        </IconButton>
                                        :
                                        <IconButton size="small" onClick={handle.enter} style={{marginBottom: "4px"}}>
                                            <Icon>fullscreen</Icon>
                                        </IconButton>
                                    }

                                </span>

                            )
                            :
                            <span
                                color="inherit"
                                align="center"
                                style={{
                                    fontSize: "16px",
                                    marginLeft: "8px",
                                    marginTop: "0px",
                                    marginBottom: "8px",
                                    marginRight: "8px"
                                }}
                            >
                                {time}
                            </span>

                        }


                    </BasicCrudActionsLayout>
                    
            </BasicCrudScreenHeaderLayout>

             <BasicCrudScreenContentLayout className="basicCrudScreenContentLayout">
 
                <>
                    <EguProductionStatusSummaryPage productionStatus={productionStatus} onComplete={nextPage} />;

                </>

            </BasicCrudScreenContentLayout>
            <BasicCrudScreenFooterLayout className="basicCrudScreenFooterLayout">
                        
                <ToolbarFlexSpacer xs />

                <span
                    color="inherit"
                    align="center"
                    style={{
                            fontSize: "16px",
                            marginLeft: "8px",
                            marginTop: "8px",
                            marginRight: "8px"
                    }}
                >
                    {page}
                </span>

            </BasicCrudScreenFooterLayout>

        </BasicCrudScreenTypeLayout>



    )
}

export default EguProductionMonitoring;
