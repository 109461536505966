import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function CreateMaterialDueDiligenceButton(props) {
    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, profileName, dueDiligence, ...others } = props;

    const [open, setOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        if (schema) {
            let modifiedSchema = schema;

            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "materialInOutputDate":
                        column.hideOnCreate = true;
                        column.disabled = true;

                        break;

                    case "username":
                        column.defaultValue = profileName;

                        break;

                    case "reasonAdjustment":
                        column.required = true;

                        break;

                    default:
                        break;
                }

                setModifiedSchema(modifiedSchema);
            }
        }
    }, [schema, profileName]);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        refresh.refresh();
    };

    const modifyUrlCallback = (url) => {
        let modifiedUrl = `${url}/${modifiedSchema.dueDiligence}`;
        return modifiedUrl;
    };

    const onApiFailure = (reason, notifyFailure) => {
        notifyFailure(t("error.inventoryAmountNotEnough"));
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.${"adjustment"}`) : ""}
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                modifyUrlCallback={modifyUrlCallback}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                onFailure={onApiFailure}
            />
        </>
    );
}

export default CreateMaterialDueDiligenceButton;
