import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import { debounce } from "lodash";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import MomSysProductionCancelButton from "../input/MomSysProductionCancelButton";

function MomSysProductionCancel ( props ) {

    const [grid, setGrid] = useState();
    const [onSelectMst, setOnSelectMst] = useState();
    const [isEmpty, setIsEmpty] = useState();
    const [api, setApi] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelectMst(grid.selectedItems[0]);
                        } else {
                            setOnSelectMst();
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelectMst(grid.selectedItems[0]);
                        } else {
                            setOnSelectMst();
                        }
                    },10)
                );
            }
        })();
    }, [grid]);

    const onInitialized = (grid) => {

        grid.selectionMode = "Row"

        setGrid(grid);

    }

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onApiInitialized = (api) => {

        setApi(api);

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    return (

        <BasicCrudScreenType
            id="MomSysVwProductionCancel"
            table="MomSysVwProductionCancel"
            view="MomSysVwProductionCancel"
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={onLoadData}
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={"ProductionCancel"}
                        accessMethod="POST"
                    >
                        <MomSysProductionCancelButton
                            id={"prodctionCancel"}
                            onSelectMst={onSelectMst}
                            disabled={isEmpty}
                            refresh={api}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
        />                                

    );

}

export default MomSysProductionCancel;
