/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysDirectShipDetailCreateDialog(props) {
    const classes = useStyles();

    const {
        id,
        schema,
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onSelectMst,

        initialData,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;

    const { t } = useTranslation();

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [formData, setFormData] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [keepOpen, setKeepOpen] = useState(false);

    const [directShipDetailTargetGrid, setDirectShipDetailTargetGrid] = useState();
    const [directShipDetailTargetSelect, setDirectShipDetailTargetSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (directShipDetailTargetGrid) {
            (async () => {
                directShipDetailTargetGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDirectShipDetailTargetSelect(grid.selectedItems[0]);
                        } else {
                            setDirectShipDetailTargetSelect();
                        }
                    },10)
                );

                directShipDetailTargetGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDirectShipDetailTargetSelect(grid.selectedItems[0]);
                        } else {
                            setDirectShipDetailTargetSelect();
                        }
                    },10)
                );
            })();
        }
    }, [directShipDetailTargetGrid]);

    useEffect(() => {
        if (directShipDetailTargetSelect) {
            (async () => {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        directShipId: onSelectMst.directShipId,

                        materialCode: directShipDetailTargetSelect.materialCode,
                        materialCodeVer: directShipDetailTargetSelect.materialCodeVer,
                        materialName: directShipDetailTargetSelect.materialName,
                        itemSpecName: directShipDetailTargetSelect.itemSpecName,
                        itemUnitName: directShipDetailTargetSelect.itemUnitName,
                        materialTypeName: directShipDetailTargetSelect.materialTypeName,

                        qty: 0,
                    };

                    return newFormData;
                });
            })();
        }
    }, [directShipDetailTargetSelect]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : JSON.stringify(reason));
    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        const url = `/api/${schema.name}`;

        let apiData = { ...formData };
        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        dialogInstance.hide();

        setDirectShipDetailTargetSelect();

        setFormData();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setDirectShipDetailTargetSelect();

        setFormData();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.MomSysDirectShipDetail.create")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}>
                        <BasicCrudScreenType
                            id="MomSysVwDirectShipDetailTarget"
                            view="MomSysVwDirectShipDetailTarget"
                            headerTitle={t("term.directShipDetailTarget")}
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setDirectShipDetailTargetGrid(grid);
                            }}
                            onLoadData={(data) => {
                                setOkButtonDisabled(data.length < 1 ? true : false);
                            }}
                            embededInOtherScreenType
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="direct-ship-detail-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                                setInitialDataCallback={(formData) => {
                                    if (directShipDetailTargetSelect) {
                                        const numQty = Number(formData.qty);
                                        formData.mediumUnitQty =
                                            directShipDetailTargetSelect.materialMediumUnitQty * numQty;
                                        formData.smallUnitQty =
                                            directShipDetailTargetSelect.materialSmallUnitQty * numQty;
                                    }
                                }}
                                modifyFormDataCallback={(formData, name) => {
                                    if (directShipDetailTargetSelect) {
                                        let numQty = Number(formData.qty);

                                        if (name === "mediumUnitQty") {
                                            numQty =
                                                Number(formData.mediumUnitQty) /
                                                directShipDetailTargetSelect.materialMediumUnitQty;
                                        } else if (name === "smallUnitQty") {
                                            numQty =
                                                Number(formData.smallUnitQty) /
                                                directShipDetailTargetSelect.materialSmallUnitQty;
                                        }

                                        formData.qty = numQty;
                                        formData.mediumUnitQty =
                                            directShipDetailTargetSelect.materialMediumUnitQty * numQty;
                                        formData.smallUnitQty =
                                            directShipDetailTargetSelect.materialSmallUnitQty * numQty;
                                    }
                                }}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysDirectShipDetailCreateDialog;
