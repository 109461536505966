/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Hidden } from "@material-ui/core";
import { cloneDeep } from "lodash";

import uiConf from "../../../config/uiConf";
import { getOption } from "../../../lib/api/getOptions";

import BasicCrudScreenTypeLayout from "../layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../layout/BasicCrudScreenHeaderLayout";
import BasicCrudScreenContentLayout from "../layout/BasicCrudScreenContentLayout";
import BasicCrudScreenContentWithoutFooterLayout from "../layout/BasicCrudScreenContentWithoutFooterLayout";
import BasicCrudScreenFooterLayout from "../layout/BasicCrudScreenFooterLayout";
import BasicCrudActionsLayout from "../layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../layout/BasicCrudScreenActionItemLayout";

import ToolbarFlexSpacer from "../../layout/ToolbarFlexSpacer";

import Filter from "../../functional/Filter";
import Search from "../../functional/Search";

import SearchTextField from "../../input/SearchTextField";
import OpenFilterButton from "../../input/OpenFilterButton";

import FilterConditionPanelLayout from "../../layout/FilterConditionPanelLayout";

import AutoFilterForm from "../../filter/functional/AutoFilterForm";
import FilterApplyButton from "../../input/FilterApplyButton";
import FilterClearButton from "../../input/FilterClearButton";
import FilterCancelButton from "../../input/FilterCancelButton";

import OutputSizeSelect from "../../input/OutputSizeSelect";
import { useTranslation } from "react-i18next";

import FilterDialog from "../../dialogTypes/FilterDialog";

function SimpleScreenType ( props ) {

    const { t } = useTranslation();

    const {

        id,

        children,

        embededInOtherScreenType,
        
        noHeader,
        noSearch,
        noFilter,
        noOutputSizeSelect,

        
        headerTitle,
        headerTitleStyle,

        filterSchema,
        
        buttonsInFrontOfSearch,

        $queries,

        onAppendHeaderAtFirst,
        onAppendContentAtFirst,
        onAppendFooterAtFirst,

        onAppendHeaderAtLast,
        onAppendContentAtLast,
        onAppendFooterAtLast,

        onAppendButtonAtFirst,
        onAppendButtonAtLast,
        onAppendButtonInFrontOfSearch,

        onSearchInitialized,
            
        searchRef,
        autoFocus,

        defaultOutputSize,
        outputSizes,

        ...others
        
    } = props;

    const [ , setForm ] = useState ();
    const [ , setFilterDialog ] = useState();

    const [ filter, setFilter ] = useState();
    const [ filterApplyButton, setFilterApplyButton ] = useState();
    const [ filterValue, setFilterValue ] = useState ();
    const [ showFilter, setShowFilter ] = useState(false);
    const [ search, setSearch ] = useState();
    const [ size, setSize ] = useState(defaultOutputSize ?? uiConf.defaultOutputSize);
    const [ useFilterDialog, setUseFilterDialog ] = useState(false);

    useEffect ( () => {

        let mounted = true;


        ( async () => {

            let useFilterDialog = await getOption ( "default", "useFilterDialog" );

            if ( mounted ) {

                if ( useFilterDialog && ( useFilterDialog.toLowerCase () === "true" ) ) {

                    setUseFilterDialog ( true );
    
                } else {
    
                    setUseFilterDialog ( false );
    
                }
    
            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [] );

    const onFormInitialized = ( form ) => {

        setForm ( form );

    }

    const onFilterInitialized = ( filter ) => {

        setFilter ( filter );

    };

    const onFilterApplyButtonInitialized = ( filterApplyButton ) => {

        setFilterApplyButton ( filterApplyButton );

    };

    const onApplyFilter = ( filterValue ) => {

        if ( filterValue ) {

            setFilterValue ( [...filterValue] );

        } else {

            setFilterValue ( () => {

                let newFilterValue = [];

                for ( const column of filterSchema.columns ) {
        
                    if ( column.filter ) {
    
                        newFilterValue.push ( {
    
                            binding: column.name,
                            type: "condition",
                            and: true,
                            condition1: { operator: "", value: "" },
                            condition2: { operator: "", value: "" }
    
                        } );
    
                    }
                }

                return newFilterValue;

            } );

        }
        
    }

    const onClearFilter = () => {


        setForm ( ( form ) => {

            form.clear ();
            return form;

        } );

    }

    const toggleOpenFilter = () => {

        setUseFilterDialog ( ( useFilterDialog ) => {

            if ( useFilterDialog ) {

                setFilterDialog ( ( filterDialog ) => {

                    filterDialog.show ();

                    return filterDialog;

                } );


            } else {

                setShowFilter ( ( showFilter ) => ( !showFilter ) );

            }

            return useFilterDialog;

        } );

    };

    const onSearchInitializedInternal = ( search ) => {

        setSearch ( search );

        if ( onSearchInitialized ) {

            onSearchInitialized ( search );

        }

    };

    const onSizeChange = ( size ) => {

        setSize ( parseInt ( size ) );

    }

    const closeFilter = () => {

        setShowFilter ( false );

    };

    const onFilterDialogInitialized = ( dialog ) => {

        setFilterDialog ( dialog );

    };

    return (

        <div>

            <BasicCrudScreenTypeLayout>

                {
                    
                    noHeader
                    ?
                    <></>
                    :
                    <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">


                        <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

        
                            {/* Action Button ids and Dialog ids are assigned inside Action Button Component */}

                            {
                                onAppendHeaderAtFirst
                                ?
                                onAppendHeaderAtFirst ()
                                :
                                <></>
                            }

                            {
                                headerTitle
                                ?
                                <span
                                    color="inherit"
                                    align="center"
                                    style={
                                        headerTitleStyle
                                        ||
                                        {
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            marginLeft: "8px",
                                            marginTop: "20px"
                                        }
                                    }>
                                    { headerTitle }
                                </span>
                                :
                                <></>
                            }

                            <Hidden xsDown>

                            {
                                onAppendButtonAtFirst
                                ?
                                onAppendButtonAtFirst ()
                                :
                                <></>
                            }

                            {
                                buttonsInFrontOfSearch
                                ?
                                <ToolbarFlexSpacer xs />
                                :
                                <></>
                            }

                            {
                                onAppendButtonAtLast
                                ?
                                onAppendButtonAtLast ()
                                :
                                <></>
                            }

                            </Hidden>

                            <Hidden smDown>

                            {

                                buttonsInFrontOfSearch
                                ?
                                <></>
                                :
                                <ToolbarFlexSpacer xs />

                            }

                            {
                                onAppendButtonInFrontOfSearch
                                ?
                                onAppendButtonInFrontOfSearch ()
                                :
                                <></>
                            }

                            {
                                noSearch
                                ?
                                <></>
                                :
                                <BasicCrudScreenActionItemLayout>

                                    <SearchTextField
                                        id={id+"-search-text"}
                                        className="basicCrudScreenSearchInput"
                                        search={search}
                                        searchRef={searchRef}
                                        autoFocus={autoFocus}
                                    />

                                </BasicCrudScreenActionItemLayout>
                            }

                            {
                                noFilter
                                ?
                                <></>
                                :
                                <BasicCrudScreenActionItemLayout>

                                    <OpenFilterButton
                                        id={id+"-open-filter-button"}
                                        className="basicCrudScreenOpenFilterButton"
                                        color="primary"
                                        onClick={toggleOpenFilter}
                                        style={{marginTop: "4px"}}
                                    />
                                    
                                </BasicCrudScreenActionItemLayout>
                            }

                            </Hidden>

                            {

                                buttonsInFrontOfSearch
                                ?
                                <></>
                                :
                                <Hidden mdUp>
                                
                                    <ToolbarFlexSpacer xs />

                                    {
                                        onAppendButtonInFrontOfSearch
                                        ?
                                        onAppendButtonInFrontOfSearch ()
                                        :
                                        <></>
                                    }

                                </Hidden>

                            }

                            {
                                onAppendHeaderAtLast
                                ?
                                onAppendHeaderAtLast ()
                                :
                                <></>
                            }

                        </BasicCrudActionsLayout>

                        {
                            noFilter
                            ?
                            <></>
                            :
                            (
                                useFilterDialog
                                ?
                                <FilterDialog

                                    id={id+"-filter-dialog"}
                                    title={t("dialog.title.Filter")}
                                    schema={filterSchema}
                                    filter={filter}
                                    filterValue={filterValue?cloneDeep(filterValue):filterValue}
                                    onInitialized={onFilterDialogInitialized}
                                    onFormInitialized={onFormInitialized}
                                    onApply={onApplyFilter}
                                    onClear={onClearFilter}
                                    onCancel={closeFilter}

                                />
                                :
                                <FilterConditionPanelLayout
                                    className="basicCrudScreenFilterPanel"
                                    show={showFilter}
                                >

                                    <AutoFilterForm
                                        id={id+"-filter-form"}
                                        className="autoFilterForm"
                                        schema={filterSchema}
                                        filterValue={filterValue?cloneDeep(filterValue):filterValue} // object deep copy - 내부에서 객체 멤버가 수정되어 버그 발생
                                        filterApplyButton={filterApplyButton}
                                        onInitialized={onFormInitialized}
                                    />
                                    <FilterApplyButton
                                        id={id+"-filter-apply-button"}
                                        className="filterApplyButton"
                                        schema={filterSchema}
                                        filter={filter}
                                        onApply={onApplyFilter}
                                        onInitialized={onFilterApplyButtonInitialized}
                                    />
                                    <FilterClearButton
                                        id={id+"-filter-clear-button"}
                                        className="filterClearButton"
                                        onClear={onClearFilter}
                                    />
                                    <FilterCancelButton
                                        id={id+"-filter-cancel-button"}
                                        className="filterCancelButton"
                                        onCancel={closeFilter}
                                    />                    

                                </FilterConditionPanelLayout>

                            )
                        }
                        
                    </BasicCrudScreenHeaderLayout>

                }
                {
                    noOutputSizeSelect
                    ?
                    <BasicCrudScreenContentWithoutFooterLayout className="basicCrudScreenContentLayout">

                        {
                            onAppendContentAtFirst
                            ?
                            onAppendContentAtFirst ()
                            :
                            <></>
                        }

                        <Filter onInitialized={onFilterInitialized} >

                            <Search onInitialized={onSearchInitializedInternal}>

                                {
                                    children &&
                                    React.Children.map(children, ( ( child ) => (
                                        React.isValidElement ( child )
                                        ?
                                        React.cloneElement(child, { ...others, size } )
                                        :
                                        child
                                    ) ) )
                                }

                            </Search>

                        </Filter>

                        {
                            onAppendContentAtLast
                            ?
                            onAppendContentAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenContentWithoutFooterLayout>
                    :
                    <BasicCrudScreenContentLayout className="basicCrudScreenContentLayout">

                        {
                            onAppendContentAtFirst
                            ?
                            onAppendContentAtFirst ()
                            :
                            <></>
                        }

                        <Filter onInitialized={onFilterInitialized} >

                            <Search onInitialized={onSearchInitializedInternal}>

                                {
                                    children &&
                                    React.Children.map(children, ( ( child ) => (
                                        React.isValidElement ( child )
                                        ?
                                        React.cloneElement(child, { ...others, size } )
                                        :
                                        child
                                    ) ) )
                                }

                            </Search>

                        </Filter>

                        {
                            onAppendContentAtLast
                            ?
                            onAppendContentAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenContentLayout>

                }

                {
                    noOutputSizeSelect
                    ?
                    <></>
                    :
                    <BasicCrudScreenFooterLayout className="basicCrudScreenFooterLayout" embededInOtherScreenType={embededInOtherScreenType}>
                        
                        {
                            onAppendFooterAtFirst
                            ?
                            onAppendFooterAtFirst ()
                            :
                            <></>
                        }

                        <ToolbarFlexSpacer xs />

                        <ToolbarFlexSpacer xs />

                        {
                            <BasicCrudScreenActionItemLayout>

                                <OutputSizeSelect
                                    id={id+"-output-size-select"}
                                    className="outputSizeSelect"
                                    defaultValue={defaultOutputSize}
                                    outputSizes={outputSizes}
                                    onChange={onSizeChange}
                                />

                            </BasicCrudScreenActionItemLayout>
                        }
                        
                        {
                            onAppendFooterAtLast
                            ?
                            onAppendFooterAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenFooterLayout>

                }


            </BasicCrudScreenTypeLayout>
            
        </div>

    );

}

export default SimpleScreenType;
