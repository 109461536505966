import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import { useState } from "react";
import { Typography } from "@material-ui/core";

function ConfirmDialog ( props ) {

    const {

        id,
        title,
        msg,
        data,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onOk,
        onCancel,
        onClose,
        onInitialized,

        ...others

    } = props;

    const [instance, setInstance] = useState ();

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onCloseDialog = () => {

        if ( onClose ) {

            onClose ();
            
        }

        if ( onCancel ) {

            onCancel ();

        }

    };

    const onConfirm = () => {

        instance.hide ();

        if ( onOk ) {

            onOk ( data );

        }
    };

    const onDeny = () => {

        instance.hide ();

        if ( onCancel ) {

            onCancel ();

        }
        
    };

    return (
        <Dialog
            id={id}
            title={title}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.confirmDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent dividers>
                <Typography color="inherit" variant="body2" align="center" >{ msg }</Typography>
            </DialogContent>

            <DialogActionsOkCancel
                onOk={onConfirm}
                onCancel={onDeny}
            />
        </Dialog>
    );

}

export default ConfirmDialog;
