import {useEffect, useState} from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysUdtCompany from './MomSysUdtCompany';
import MomSysUdtCompanyPhone from './MomSysUdtCompanyPhone';
import MomSysUdtCompanySales from './MomSysUdtCompanySales';


function MomSysCompanyManager ( props ) {
    const [height, setHeight] = useState(0);
    const [mstGridData, setMstGridData] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const masterGridSetting = (data) => {
        setMstGridData(data)
    } 

    return (
        <div style={{height: height}}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement data-cy="Company" flex={0.5}>
                    <MomSysUdtCompany
                        id="MomSysUdtCompany"
                        masterGridSetting={masterGridSetting}
                    />
                </ReflexElement>
                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />
                <ReflexElement>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement data-cy="CompanyPhone" flex={0.5}>
                            {
                                (mstGridData)
                                ?
                                <MomSysUdtCompanyPhone
                                    id="MomSysUdtCompanyPhone"
                                    mstGridData={mstGridData}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />
                        <ReflexElement data-cy="CompanySales">
                            {
                                (mstGridData)
                                ?
                                <MomSysUdtCompanySales
                                    id="MomSysUdtCompanySales"
                                    mstGridData={mstGridData}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );

}

export default MomSysCompanyManager;
