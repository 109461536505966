import { useTranslation } from "react-i18next";
import { FormControl, FormLabel, FormControlLabel, FormHelperText, RadioGroup, Radio } from "@material-ui/core";

function FormItemRadio ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, error, helperText, label, name, value, InputLabelProps, onChange, ...others } = props;


    const onRadioChange = ( event ) => {
        onChange ( event.target.value );
    };

    const formControlStyle = column.formControlStyle ? column.formControlStyle : {};
    const groupStyle = column.groupStyle ? column.groupStyle : {};
    const radioStyle = column.radioStyle ? column.radioStyle : {};

    return (
        <FormControl className={className} error={error} required={column.required} style={formControlStyle} >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup id={id} name={name} value={ t ( value ) } style={groupStyle} onChange={onRadioChange} row={column.row}>
            {
                column.selectItems.map ( ( item ) => <FormControlLabel key={ item.name } value={ t ( item.value ) } control={<Radio color="primary" style={radioStyle} {...others} />} label={ item.name } /> )
            }
            </RadioGroup>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FormItemRadio;
