import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PopDirectShipDetail from "./PopDirectShipDetail";
import PopDirectShipButton from "../input/PopDirectShipButton";
import { debounce } from "lodash";
import PrintReportButton from "../input/PrintReportButton";

function PopDirectShip ( props ) {

    const id = "PopDirectShip";

    const { t } = useTranslation ();

    const setNotification = useSetRecoilState(notificationState);
    
    const [ height, setHeight ] = useState(0);

    const [api, setApi] = useState();

    const [detailApi, setDetailApi] = useState();

    const [popDetailApi, setPopDetailApi] = useState();

    const [isEmptyPop, setIsEmptyPop] = useState();

    const [grid, setGrid] = useState();

    const [detailGrid, setDetailGrid] = useState();

    const [selectMst, setSelectMst] = useState();

    const [selectDetail, setSelectDetail] = useState();

    const [reportUrl, setReportUrl] = useState();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectMst(grid.selectedItems[0]);
                        }else {
                            setSelectMst([]);
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectMst(grid.selectedItems[0]);
                        }else {
                            setSelectMst([]);
                        }

                    },10)
                );
            }
        })();
    }, [grid]);

    useEffect(() => {
        (async () => {
            if (detailGrid) {
                detailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectDetail(grid.selectedItems[0]);
                        }else {
                            setSelectDetail([]);
                        }
                    },10)
                );

                detailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectDetail(grid.selectedItems[0]);
                        }else {
                            setSelectDetail([]);
                        }

                    },10)
                );
            }
        })();
    }, [detailGrid]);
    
    useEffect(() => {
        const path = `popDirectShipDetail/${selectDetail?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [selectDetail]);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onDetailInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setDetailGrid(grid);

    };

    const onApiInitialized = ( api ) => {

        setApi(api);

    };

    const onPopApiInitialized = (api) => {

        setPopDetailApi(api);

    };

    const updateMaster = () => {

        if(api) {

            api.refresh();

        }

        if(detailApi) {

            detailApi.refresh();

        }

        if(popDetailApi) {

            popDetailApi.refresh();

        }

    };

    const onModifyViewUrl = ( url ) => {

        return url + "/product";

    };

    const onLoadDataPop = (isEmpty) => {

        setIsEmptyPop(isEmpty);

    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    <PopDirectShipButton
                        createButtonName={"materialOutput"}
                        id={id}
                        disabled={isEmptyPop}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        updateMaster={updateMaster}
                    />
                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6} data-cy="Master">

                    <BasicCrudScreenType 
                        id="MomSysZvwMaterial"
                        headerTitle={t("tableName.MomSysZvwMaterial")}
                        table="MomSysZvwMaterial"
                        view="MomSysZvwMaterial"
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        // onModifyViewUrl={onModifyViewUrl}
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4}>

                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={0.5} data-cy="Detail">

                            <BasicCrudScreenType 
                                id="MomSysVwDirectShipDetailLot" 
                                headerTitle={t("term.directShipDetailLotLog")}
                                table="MomSysVwDirectShipDetailLot" 
                                view="MomSysVwDirectShipDetailLot" 
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={onDetailInitialized}
                                onApiInitialized={(api) => setDetailApi(api)}
                                onAppendButtonInFrontOfSearch={() => (
                                    <>
                                        <PopCrudScreenActionItemLayout>
                                            <PrintReportButton
                                                noLayout
                                                url={reportUrl}
                                                disabled={!selectDetail?.lotNo}
                                            >
                                                {t("buttonName.lotInfoPrint")}
                                            </PrintReportButton>
                                        </PopCrudScreenActionItemLayout>
                                    </>
                                )}
                            />

                        </ReflexElement>

                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                        <ReflexElement flex={0.5} data-cy="DetailLot">

                            <PopDirectShipDetail 
                                id="PopDirectShipDetail" 
                                table="PopDirectShipDetail" 
                                view="VwPopDirectShipDetail" 
                                onLoadDataPop={onLoadDataPop}
                                onPopApiInitialized={onPopApiInitialized}
                                selectMst={selectMst}
                            />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default PopDirectShip;
