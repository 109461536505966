import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";

function UserLotProductionReport (props){

    const { t } = useTranslation ();
    const { startDate, endDate } = props;
    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if ( startDate && endDate && api ) {

            api.refresh ();

        }

    }, [ api, startDate, endDate ] );

    const onModifyUrl = ( url ) => {

        let newUrl = `${url}/${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth()+1}/${endDate.getDate()}`        

        return newUrl;
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    return (
    
        <BasicCrudScreenType
            id="UserLotProductionReport"
            headerTitle={t("term.outputMaterial")}
            noCreate
            noEdit
            noDelete
            noExcel
            table="UserLotProductionReport"
            view="UserLotProductionReport"
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
        />

    );

}
export default UserLotProductionReport;