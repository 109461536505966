import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import SaveToExcelButtonLayout from "../layout/SaveToExcelButtonLayout";
import apiClient from '../../lib/common/apiClient';
import notificationState from "../../states/notificationState";

function EguMaterialStatusSaveExcelButton ( props ) {
    const {searchValue, filterValue, onSuccess, onFailure, view, ...others} = props;
    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);
    const [today, setToday] = useState();

    useEffect(() => {
        function settingToday() {
            let newDate = new Date();
            let year = newDate.getFullYear();
            let month = checkSingleNumber((newDate.getMonth() + 1));
            let date = checkSingleNumber(newDate.getDate());
            let today = year + "/" + month + "/" + date;
            setToday(today);
        }

        function checkSingleNumber(value) {
            if(value < 10) {
                return "0" + value;
            }
            return value;
        }

        settingToday();
    }, []);

    const successNotification = (result) => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result) => {
        const targetFileName = today + '_자재 현황 관리.xlsx';
        
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const onSaveToExcel = () => {
        let url = `/api/MomSysVwInventorySummary/excel/material`;

        let query = false;
        if(searchValue) {
            url += '?search=' + searchValue;
            query = true;
        }

        if(filterValue) {
            for(const data of filterValue) {
                if(query) {
                    url += '&';
                } else {
                    url += '?';
                }

                url += `${data.binding}=${data.condition1.value}`;
                
                if(!query) {
                    query = true;
                }
            }
        }

        apiClient
            .get(url, { responseType: "blob" })
            .then((result) => {
                doHandleFileStream(result);
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <SaveToExcelButtonLayout id={"save-to-excel-button"} color="primary" onClick = {onSaveToExcel} {...others}/>
        </>
    );
}

export default EguMaterialStatusSaveExcelButton;
