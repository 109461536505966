/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import PreViewDialog from "../../dialogTypes/PreViewDialog";

const LOCAL_URL = 'http://localhost:8080';

function DataGridPreViewColumn ( props ) {
    const { schema, ...others } = props;

    const [, setDialog] = useState ();
    const [url, setUrl] = useState ();
    const [cellTemplate, setCellTemplate] = useState ((ctx) => {
        return <></>;
    });

    useEffect(() => {
        setCellTemplate(() => ((ctx) => {
            return (getPreViewButton(ctx));
        }));
    }, [schema]);

    function getPreViewButton(ctx) {
        let fileUrl = getFileUrl(ctx);
        if(fileUrl) {
            return (
                <>
                    <span>
                        {getButtom(ctx)}
                    </span>
                </>
            )
        }
        return <></>;
    }

    function getButtom(ctx) {
        switch(getUpperCaseExtension(getFileUrl(ctx))) {
            case "PDF":
                return getPdfButton(ctx);

            case "JPG": case "JPEG": case "PNG":  case "BMP": case "GIF":
                return getImageButton(ctx);

            default :
                return <></>;
        }
    }

    function getFileUrl(ctx) {
        return ctx.item[schema.name];
    }

    function getUpperCaseExtension(fileUrl) {
        return fileUrl.substring(fileUrl.lastIndexOf(".") + 1).toUpperCase();
    }

    function getPdfButton(ctx) {
        return (
            <button onClick={() => onClick(ctx)} style={{border: "0", background: "white"}}> 
                PDF 파일보기
            </button>
        )
    }

    function getImageButton(ctx) {
        let fileUrl = getFileUrl(ctx);
        return (
            <img
                alt={fileUrl}
                src={
                    ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' ) ? fileUrl : (LOCAL_URL + fileUrl)
                }
                style={{marginLeft: "auto", marginRight: "auto", height: "22px"}}
                onClick={() => onClick(ctx)}
            />
        )
    }

    const onClick = (ctx) => {
        setUrl(getFileUrl(ctx));
        setDialog((dialog) => {
            if(dialog) {
                dialog.show ();
            }
            return dialog;
        });
    }

    const onDialogInitialized = (dialog) => {
        setDialog (dialog);
    }

    return (
        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
            <PreViewDialog url={url} onInitialized={onDialogInitialized}/>
        </DataGridColumn>
    );
}

export default DataGridPreViewColumn;

