import { makeStyles } from "@material-ui/styles";

import MaterialDialogTitle from "../presentation/MaterialDialogTitle";
import WijmoDialogTitle from "../presentation/WijmoDialogTitle";

import uiConf from "../../../config/uiConf";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        margin: 0,
        fontSize: "medium",
        height: "30px",
    }
}));

function DialogTitleLayout ( props ) {

    const { platform } = props;

    const classes = useStyles ();
    const styledProps = { classes, ...props }

    if ( platform ) {

        switch ( platform ) {

            case "material-ui":
    
                return <MaterialDialogTitle className={classes.root} {...styledProps} />
    
            case "wijmo":
    
                return <WijmoDialogTitle {...styledProps} />

            default:
    
                return <MaterialDialogTitle className={classes.root} {...styledProps} />
    
        }

    } else {

        switch ( uiConf.dialogPlatform ) {

            case "material-ui":
    
                return <MaterialDialogTitle className={classes.root} {...styledProps} />
    
            case "wjimo":
    
                return <WijmoDialogTitle {...styledProps} />

            default:
    
                return <MaterialDialogTitle className={classes.root} {...styledProps} />
    
        }

    }

}

export default DialogTitleLayout;
