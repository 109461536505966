/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateRecordButton from "../input/CreateRecordButton";


function DbTaskMaster ( props ) {

    const { t } = useTranslation();

    const { onSelect } = props;

    const setNotification = useSetRecoilState ( notificationState );

    const [copyMasterDbSchema, setCopyMasterDbSchema] = useState();
    const [copyTenantSchema, setCopyTenantSchema] = useState();
    const [copyAllTenantSchema, setCopyAllTenantSchema] = useState();
    const [copyTenantFromSchema, setCopyTenantFromSchema] = useState();
    const [copyAllTenantFromSchema, setCopyAllTenantFromSchema] = useState();
    const [copyTableFromSchema, setCopyTableFromSchema] = useState();
    const [api, setApi] = useState();


    useEffect( () => {

        let mounted = true;

        ( async () => {

            let copyMasterDbSchema = await getSchema ( "CopyMasterDb" );
            let copyTenantSchema = await getSchema ( "CopyTenant" );
            let copyAllTenantSchema = await getSchema ( "CopyAllTenant" );
            let copyTenantFromSchema = await getSchema ( "CopyTenantFrom" );
            let copyAllTenantFromSchema = await getSchema ( "CopyAllTenantFrom" );
            let copyTableFromSchema = await getSchema ( "CopyTableFrom" );

            if ( mounted ) {

                setCopyMasterDbSchema ( copyMasterDbSchema );
                setCopyTenantSchema ( copyTenantSchema );
                setCopyAllTenantSchema ( copyAllTenantSchema );
                setCopyTenantFromSchema ( copyTenantFromSchema );
                setCopyAllTenantFromSchema ( copyAllTenantFromSchema );
                setCopyTableFromSchema ( copyTableFromSchema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    const onGridInitialized = (grid) => {

        if (grid) {

            grid.selectionMode = "Row";

            grid.selectionChanged.addHandler( ( grid ) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    if ( onSelect ) {

                        let master = grid.selectedItems[0];

                        onSelect ( master );

                    }

                } else {

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }

                }

            } );

            grid.itemsSourceChanged.addHandler( ( grid ) => {
                
                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    if ( onSelect ) {

                        let master = grid.selectedItems[0];
                        
                        onSelect ( master );

                    }

                } else {

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }
                        
                }

            } );

        }
    
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };
    
    const onClose = () => {

        setApi ( ( api ) => {

            api.refresh ();

            return api;

        } );
                
    }

    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );

        setApi ( ( api ) => {

            api.refresh ();

            return api;

        } );
    
    };

    return (

        <BasicCrudScreenType
            id="DbTaskMaster"
            headerTitle={t("term.dbTaskMaster")}
            table="CopyMasterDb"
            view="VwDbTaskMaster"
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={ () => (

                <>

                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-master-db-button"}
                            schema={copyMasterDbSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyMasterDb"

                        />

                    </BasicCrudScreenActionItemLayout>
                
                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-tenant-button"}
                            schema={copyTenantSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyTenant"

                        />

                    </BasicCrudScreenActionItemLayout>
                
                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-all-tenant-button"}
                            schema={copyAllTenantSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyAllTenant"

                        />

                    </BasicCrudScreenActionItemLayout>
                
                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-tenant-from-button"}
                            schema={copyTenantFromSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyTenantFrom"

                        />

                    </BasicCrudScreenActionItemLayout>
                
                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-all-tenant-from-button"}
                            schema={copyAllTenantFromSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyAllTenantFrom"

                        />

                    </BasicCrudScreenActionItemLayout>
                
                    <BasicCrudScreenActionItemLayout>

                        <CreateRecordButton
                            id={"copy-table-from-button"}
                            schema={copyTableFromSchema}
                            refresh={api}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            createButtonName="copyTableFrom"

                        />

                    </BasicCrudScreenActionItemLayout>

                </>

            ) }
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default DbTaskMaster;
