import { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import EguMaterialInputStatusDetail from "./EguMaterialInputStatusDetail";
import { debounce } from "lodash";

function EguMaterialInputStatus(props) {
    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [materialInputStatusGrid, setMaterialInputStatusGrid] = useState();
    const [materialInputStatusSelect, setMaterialInputStatusSelect] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (materialInputStatusGrid) {
                materialInputStatusGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMaterialInputStatusSelect(grid.selectedItems[0]);
                        } else {
                            setMaterialInputStatusSelect([]);
                        }
                    },10)
                );

                materialInputStatusGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMaterialInputStatusSelect(grid.selectedItems[0]);
                        } else {
                            setMaterialInputStatusSelect([]);
                        }
                    },10)
                );
            }
        })();
    }, [materialInputStatusGrid]);

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <BasicCrudScreenType
                        id="EguVwMaterialInputStatus"
                        view="EguVwMaterialInputStatus"
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setMaterialInputStatusGrid(grid);
                        }}
                        headerTitle={t("term.eguInputList")}
                        noExcel
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>
                    {materialInputStatusSelect ? (
                        <EguMaterialInputStatusDetail onSelectMst={materialInputStatusSelect} />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguMaterialInputStatus;
