import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";
import apiClient from '../../lib/common/apiClient';
import notificationState from "../../states/notificationState";

function DataAllSaveToExcelButton ( props ) {
    const {id, grid, view, excelFileName, ...others} = props;
    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);
    const [today, setToday] = useState();
    const [date, setDate] = useState(new Date());
    const [header, setHeader] = useState([]);

    useEffect(() => {
        if(grid) {
            let arr = [];
            for(const column of grid.columns) {
                arr.push(column._name);
            }
            setHeader(arr);
        }
    }, [grid]);

    useEffect(() => {
        function settingToday() {
            let year = date.getFullYear();
            let month = checkSingleNumber((date.getMonth() + 1));
            let day = checkSingleNumber(date.getDate());
            let today = year + "-" + month + "-" + day;
            setToday(today);
        }

        function checkSingleNumber(value) {
            if(value < 10) {
                return "0" + value;
            }
            return value;
        }

        settingToday();
    }, [date]);

    const successNotification = (result) => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result) => {
        const targetFileName = today + ' ' + excelFileName + '.xlsx';
        
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const onSaveToExcel = () => {
        const url = `/api/DataAllExcelDownload/${view}`;

        let json = {};
        for(let i = 0; i < header.length; i++) {
            let count = i;
            if(count < 10) {
                count = '0' + count;
            }
            json[header[i]] = count;
        }
        let headerName = new FormData();
        headerName.append("json", JSON.stringify(json));

        apiClient
            .post(url, headerName, { 
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: "blob" 
            })
            .then((result) => {
                doHandleFileStream(result);
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <SaveToExeclButtonLayout id={"save-to-execl-button"} color="primary" onClick = {onSaveToExcel} {...others}/>
        </>
    );
}

export default DataAllSaveToExcelButton;
