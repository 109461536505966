/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";

function DataGridMomSysUdtCompanyStateColumn (props) {
    const {schema, ...others} = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect(() => {

        setCellTemplate(() => ((ctx) => {

            if( ctx.item[schema.name] === 1 ) {
                return <span>O</span>
            } else{
                return <span>-</span>
            }

        }))
    },[schema])

    return (
        <DataGridColumn schema={schema} {...others}  >
            <FlexGridCellTemplate  cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    )
}

export default DataGridMomSysUdtCompanyStateColumn