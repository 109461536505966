/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import apiClient from "../../lib/common/apiClient";
import notificationState from "../../states/notificationState";

function DownloadBomBopFileUploadTemplateButton ( props ) {

    const {t} = useTranslation();

    const {id, ...others} = props;
    const setNotification = useSetRecoilState(notificationState);
    
    const url = '/api/BomBopFileUploadTemplate';
    const targetFileName = t('fileName.bomBopUploadTemplate');

    const successNotification = (result) => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result) => {
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const fileDownload = () => {
        apiClient
            .get(url, { responseType: "blob" }) // Note : responseType := "blob" !!
            .then((result) => {
                doHandleFileStream(result);
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <ActionButtonLayout
                id={id}
                onClick={fileDownload}
                {...others}
            >
                {t("buttonName.excelFormDownload")}
            </ActionButtonLayout>
        </>
    );
}

export default DownloadBomBopFileUploadTemplateButton;

