import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function SupportedLanguage ( props ) {

    const { onSelect, ...others } = props;

    const onInitialized = ( ( grid ) => {
    
        grid.selectionMode = "Row";
    
        grid.selectionChanged.addHandler ( ( grid ) => {
    
    
            if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                if ( onSelect ) {
    
                    onSelect ( grid.selectedItems[0] );
    
                }
    
            } else {
    
                    if ( onSelect ) {
    
                        onSelect ( undefined );
    
                    }
    
            }
    
        } );
    
        grid.itemsSourceChanged.addHandler ( ( grid ) => {
    
            if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                if ( onSelect ) {
    
                    onSelect ( grid.selectedItems[0] );
    
                }
    
            } else {
    
                    if ( onSelect ) {
    
                        onSelect ( undefined );
    
                    }
    
            }
        } );
    
    } );

    return <BasicCrudScreenType id="SupportedLanguage" table="SupportedLanguage" view="SupportedLanguage" onInitialized={onInitialized} {...others} />

}

export default SupportedLanguage;
