import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

function FilterFormItemLtLeCondition ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, label, InputLabelProps, onChange, ...others } = props;

    const conditions = [
        {
            name: t("term.none"),
            value: ""
        },
        {
            name: t("dataTableFilter.filterOperator.numberLT"),
            value: "4"
        },
        {
            name: t("dataTableFilter.filterOperator.numberLE"),
            value: "5"
        }
    ];


    const selectProps = {
        displayEmpty: false,
        defaultValue: "",
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        ...others
    };

    if ( column.filter.conditionStyle ) {

        selectProps.style = column.filter.conditionStyle;

    }

    return (
        <FormControl className={className} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
                { ...selectProps }
            >
                {
                    conditions.map ( ( item ) => <MenuItem key={item.name} value={ t ( item.value ) }>{item.name}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FilterFormItemLtLeCondition;
