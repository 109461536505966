import { atom } from "recoil";

// top menu selection state

const topMenuSelectionState = atom({
        
    key: "topMenuSelectionState",
    default: 1

});

export default topMenuSelectionState;
