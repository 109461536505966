import React from "react";
import {
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Typography,
    Popover,
    makeStyles,
} from "@material-ui/core";
import Avatar from "../presentation/Avatar";

import { useHistory } from "react-router-dom";

import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        maxHeight: "400px",
        backgroundColor: theme.palette.background.paper,
    },
    msg: {
        display: "inline",
        wordWarp: true,
    },
    avatar: {
        width: 32,
        height: 32,
    },
}));

function MessageList(props) {

    const classes = useStyles ();

    const history = useHistory();

    const { id, open, anchorEl, messages, onClose, onRead } = props;

    const onMessageClick = ( message ) => {

        onClose ();

        ( async () => {

            apiClient.put ( `api/InBoxMsg/${message.messageId}/markRead`, {} )
            .then ( () => {
    
                if ( onRead ) {

                    onRead ( message );
                    
                }
                
            } )
            .catch ( () => {

                // Do Nothing
    
            } );
    
        } ) ();

        history.push ( "/" + message.topMenuPath + "/" + message.menuPath );

    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                square: true
            }}
        >
            <List
                className={classes.root}
            >
                {

                    messages
                        .slice()
                        .sort ( ( msgA, msgB ) => {

                            if ( uiConf.messageSortCompare ) {

                                return uiConf.messageSortCompare ( msgA, msgB, uiConf.messageSortColumn, uiConf.messageSortOrder );

                            } else {

                                return 0;

                            }

                        } )
                        .map ( ( message, index ) => (

                            <div key={"list-item-"+message.messageId}>

                                {
                                    index > 0
                                    ?
                                    <Divider key={"divider-"+message.messageId} light component="li" />
                                    :
                                    <></>                            
                                }

                                <ListItem  alignItems="flex-start" onClick={ () => onMessageClick ( message ) }>
                                    <ListItemAvatar>
                                        <Avatar
                                            photo={message.senderPhoto}
                                            email={message.senderEmail}
                                            className={classes.avatar}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.msg}
                                                    color="textPrimary"
                                                >
                                                    { message.message }
                                                </Typography>
                                            </>
                                        }
                                    />

                                </ListItem>
                            </div>

                    ) )

                }

            </List>
        </Popover>
    );
}

export default MessageList;
