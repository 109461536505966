import React from "react";
import { makeStyles } from "@material-ui/core";

import Notification from "../../presentation/Notification";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "absolute",
    height: "45px",
    bottom: "8px",
    width: "100%",
    overflow: "hidden",
    borderTop: "1px solid rgb(212, 212, 212)",

    "& > *": {
      display: "flex",
      justifyContent: "center",
    },
  },
  gridStyles: {
    width: "100%",
  },
}));

function BasicCrudScreenFooterLayout(props) {
  const classes = useStyle();

  const { children, className, embededInOtherScreenType, ...others } = props;

  return (
    <footer className={classes.root}>
        <div style={{marginLeft: "8px", marginRight: "8px"}}>
            {React.Children.map(children, (child) => React.cloneElement(child, others))}
        </div>
        {
            embededInOtherScreenType
            ?
            <></>
            :
            <Notification />
        }
    </footer>
  );
}

export default BasicCrudScreenFooterLayout;
