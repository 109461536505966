import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { cloneDeep, debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";

import PsmMaterialListRegisterButton from "../input/PsmMaterialListRegisterButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import PsmMaterialEventData from "./PsmMaterialEventData";

function PsmMaterialList(props) {
    const id = "PsmMaterialList";
    const table = "PsmMaterialList";
    const view = "PsmVwMaterialList";

    const [height, setHeight] = useState(0);

    const [schema, setSchema] = useState();
    const [modifiedSchemaForUpdate, setModifiedSchemaForUpdate] = useState();

    const [psmMaterialListSelect, setPsmMaterialListSelect] = useState();
    const [, setPsmMaterialListData] = useState();
    const [psmMaterialListIsEmpty, setPsmMaterialListIsEmpty] = useState();
    const [psmMaterialListGrid, setPsmMaterialListGrid] = useState();
    const [psmMaterialListApi, setPsmMaterialListApi] = useState();

    const [, setPsmEventDataList] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setSchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let modifiedSchemaForUpdate = cloneDeep(schema);

        if (modifiedSchemaForUpdate && psmMaterialListSelect) {
            for (const column of modifiedSchemaForUpdate.columns) {
                switch (column.name) {
                    case "materialTypeName":
                        column.hideInForm = true;
                        break;

                    default:
                        break;
                }
            }

            setModifiedSchemaForUpdate(modifiedSchemaForUpdate);
        }
    }, [schema, psmMaterialListSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (psmMaterialListGrid && mounted) {
                psmMaterialListGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialListSelect(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialListSelect([]);
                        }
                    },10)
                );

                psmMaterialListGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialListSelect(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialListSelect([]);
                        }
                    },10)
                );

                psmMaterialListGrid.loadedRows.addHandler(
                    debounce((grid, event) => {
                        for (let row of grid.rows) {
                            if (row.dataItem.qtyCurrent < row.dataItem.qtySafetyStock) {
                                row.cssClass = `failed-stock-apply`;
                            } else {
                                row.cssClass = null;
                            }
                        }
                    },10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [psmMaterialListGrid]);

    // useEffect(() => {
    //     let mounted = true;

    //     (async () => {
    //         if (psmMaterialListData && mounted) {
    //             const { data: producingTimeList } = await getSpecific("PsmMaterialProducingTime");

    //             for (const item of psmMaterialListData) {
    //                 const mat = producingTimeList.find(
    //                     (v) => v.materialCode === item.materialCode && v.materialCodeVer === item.materialCodeVer
    //                 );

    //                 if (mat) {
    //                     item.baseProducingTime = mat.producingTimeInSecPerCount;
    //                 }
    //             }

    //             //*** 2022/08/16 itkim : grid.refresh() is useless
    //              if (psmMaterialListGrid) {
    //                  psmMaterialListGrid.refresh(false); // false : refresh grid content only
    //              }
    //             //***/
    //         }
    //     })();

    //     return () => {
    //         mounted = false;
    //     };
    // }, [psmMaterialListData, psmMaterialListGrid]);

    /***
    const onUpdateMaster = (eventDataList) => {
        if (
            psmMaterialListSelect &&
            psmMaterialListGrid &&
            eventDataList &&
            eventDataList?.length > 0 &&
            psmMaterialListSelect.materialCode === eventDataList[0].eventMaterialCode &&
            psmMaterialListSelect.materialCodeVer === eventDataList[0].eventMaterialCodeVer
        ) {
            const selectedItem = psmMaterialListGrid.selectedItems[0];

            const firstLackingEventData = eventDataList.find((v) => v.netQty < 0);
            const lastEventData = eventDataList[eventDataList.length - 1];

            if (firstLackingEventData) {
                selectedItem.firstLackingQty = -1.0 * firstLackingEventData.netQty;
                selectedItem.firstLackingDate = firstLackingEventData.eventTime;
            }

            if (lastEventData.netQty < 0) {
                selectedItem.totalLackingQty = -1.0 * lastEventData.netQty;
            }

            psmMaterialListGrid.refresh(false); // false : refresh grid content only
        }
    };
    ***/

    // useEffect(() => {
    //     if (
    //         psmMaterialListSelect &&
    //         psmMaterialListGrid &&
    //         psmEventDataList &&
    //         psmEventDataList?.length > 0 &&
    //         psmMaterialListSelect.materialCode === psmEventDataList[0].eventMaterialCode &&
    //         psmMaterialListSelect.materialCodeVer === psmEventDataList[0].eventMaterialCodeVer
    //     ) {
    //         const selectedItem = psmMaterialListGrid.selectedItems[0];

    //         const firstLackingEventData = psmEventDataList.find((v) => v.netQty < 0);
    //         const lastEventData = psmEventDataList[psmEventDataList.length - 1];

    //         if (firstLackingEventData) {
    //             selectedItem.firstLackingQty = -1.0 * firstLackingEventData.netQty;
    //             selectedItem.firstLackingDate = firstLackingEventData.eventTime;
    //         }

    //         if (lastEventData.netQty < 0) {
    //             selectedItem.totalLackingQty = -1.0 * lastEventData.netQty;
    //         }

    //         //*** 2022/08/16 itkim : grid.refresh() is useless
    //          psmMaterialListGrid.refresh(false); // false : refresh grid content only
    //         //***/
    //     }
    // }, [psmMaterialListSelect, psmMaterialListGrid, psmEventDataList]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.psmMaterialList")}
                        onLoadData={(data) => {
                            setPsmMaterialListIsEmpty(data.length < 1 ? true : false);
                            setPsmMaterialListData(data);
                        }}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPsmMaterialListGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPsmMaterialListApi(api);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <PsmMaterialListRegisterButton
                                        id={id}
                                        schema={schema}
                                        refresh={psmMaterialListApi}
                                        onSuccess={onSuccess}
                                    />

                                    <UpdateRecordButton
                                        id={id}
                                        schema={modifiedSchemaForUpdate}
                                        grid={psmMaterialListGrid}
                                        refresh={psmMaterialListApi}
                                        disabled={psmMaterialListIsEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteSingleRecordButton
                                        id={id}
                                        schema={schema}
                                        grid={psmMaterialListGrid}
                                        refresh={psmMaterialListApi}
                                        makeItemName={(row) => {
                                            return `${t("columnName.materialCode")}: ${row.materialCode}, ${t(
                                                "columnName.materialCodeVer"
                                            )}: ${row.materialCodeVer}`;
                                        }}
                                        color="secondary"
                                        disabled={psmMaterialListIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    { psmMaterialListSelect ? (
                        <PsmMaterialEventData
                            psmMaterialListSelect={psmMaterialListSelect}
                            onEventDataLoad={(data) => {
                                setPsmEventDataList(data);
                            }}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default PsmMaterialList;
