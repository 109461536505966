import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/styles";

import NumberFormat from 'react-number-format';

import getNewDateObj from '../../lib/common/getNewDateObj';

const useStyles = makeStyles((theme) => ({
    container: {
        position: "absolute",
        width: "100%",
        height: "100.1%",
        overflow: "auto",
        borderTop: "1px solid #e0e0e0",
        borderRight: "1px solid rgba(0,0,0,.2)",
    },
    table: {
        fontSize: "0.95rem",        
        position: "absolute",
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
        textAlign: "center",        
        vertialAlign: "center",
        borderRight: "1px solid rgba(0,0,0,.2)",
    },
    header: {
        paddingTop: "6px",
        paddingBottom: "6px",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        borderLeft: "1px solid rgba(0,0,0,.2)",
        color: "#000000",
        backgroundColor: "#eeeeee",
    },
    orderBuyerId: {
        width: "155px",
    },
    companyName: {
        width: "160px",
    },
    processGroupHeader: {
        paddingTop: "2px",
        paddingBottom: "2px",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        borderLeft: "1px solid rgba(0,0,0,.2)",
        color: "#000000",
        backgroundColor: "#eeeeee",
    },
    processHeader: {
        width: "55px",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        borderLeft: "1px solid rgba(0,0,0,.2)",
        color: "#000000",
        backgroundColor: "#eeeeee",
    },
    row: {
        borderBottom: "1px solid rgba(0,0,0,.2)",
    },
    col: {
        textAlign: "left",
        paddingLeft: "12px",
        borderLeft: "1px solid rgba(0,0,0,.2)",
    },
    alignCenter: {
        textAlign: "center",
        paddingLeft: "0px",
    },
    number: {
        width: "85px",
        textAlign: "right",
        paddingRight: "6px",
    },
    date: {
        width: "120px",
    },
    time: {
        width: "170px",
    }
} ) );

function EguSalesStatusSummaryTable ( props ) {

    const classes = useStyles();

    const { t } = useTranslation();
    const { data } = props;

    const formatDate = ( date ) => {

        if ( date === null || date === undefined || date === "" ) {

            return "";

        }

        let dateObj = getNewDateObj( date );

        if ( !dateObj ) {
            return "";
        }

        return `${dateObj.getFullYear ()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;

    }

    const formatTime = ( time ) => {

        if ( time === null || time === undefined || time === "" ) {

            return "";

        }

        let dateObj = getNewDateObj( time );

        if ( !dateObj ) {
            return "";
        }

        return `${dateObj.getFullYear ()}-${dateObj.getMonth() + 1}-${dateObj.getDate()} ${("0" + dateObj.getHours()).slice(-2)}:${("0" + dateObj.getMinutes()).slice(-2)}`;

    }

    const formatStatus = ( status ) => {

        switch ( status ) {

            case "미지시":

                return "계획";

            case "지시완료":

                return "지시";

            case "생산중":

                return "진행";

            case "생산완료":

                return "완료";

            default:

                return "";

        }
    }

    return (

        <div className={classes.container}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={`${classes.header} ${classes.orderBuyerId}`} rowSpan={2}>{t("columnName.orderBuyerId")}</th>
                        <th className={`${classes.header} ${classes.companyName}`} rowSpan={2}>{t("columnName.companyName")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.materialName")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.unitCost")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.soQty")}</th>
                        <th className={`${classes.header} ${classes.date}`} rowSpan={2}>{t("columnName.orderBuyerDate")}</th>
                        <th className={`${classes.header} ${classes.date}`} rowSpan={2}>{t("columnName.dueDate")}</th>
                        <th className={`${classes.processGroupHeader}`} colSpan={4}>{t("columnName.process")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.qtyInput")}</th>
                        <th className={`${classes.header} ${classes.time}`} rowSpan={2}>{t("columnName.shipmentOrderDate")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.shipmentQty")}</th>
                        <th className={`${classes.header} ${classes.time}`} rowSpan={2}>{t("columnName.shipDate")}</th>
                        <th className={`${classes.header}`} rowSpan={2}>{t("columnName.shipQty")}</th>
                    </tr>
                    <tr>
                        <th className={`${classes.processHeader}`}>{t("columnName.process1")}</th>
                        <th className={`${classes.processHeader}`}>{t("columnName.process2")}</th>
                        <th className={`${classes.processHeader}`}>{t("columnName.process3")}</th>
                        <th className={`${classes.processHeader}`}>{t("columnName.process4")}</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data?.map ( ( item, index ) =>
                        <tr className={`${classes.row}`} key={index}>
                            <td className={`${classes.col}`}>{item.orderBuyerId}</td>
                            <td className={`${classes.col}`} >{item.companyName}</td>
                            <td className={`${classes.col}`} >{item.materialName}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.unitCost === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.unitCost}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.qty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.qty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatDate(item.orderBuyerDate)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatDate(item.dueDate)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatStatus(item.process1)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatStatus(item.process2)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatStatus(item.process3)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatStatus(item.process4)}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.qtyInput === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.qtyInput}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatTime(item.shipmentOrderDate)}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.shipmentQty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.shipmentQty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatTime(item.shipDate)}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.shipQty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.shipQty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>                            
                        </tr>
                    )

                }
                </tbody>
            </table>
        </div>
    )
}

export default EguSalesStatusSummaryTable;
