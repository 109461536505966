import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import subMenuSelectionState from "../../../states/subMenuSelectionState";
import SubMenuSelectItem from "../input/SubMenuSelectItem";
import notificationState from "../../../states/notificationState";

function SubMenuItem ( props ) {

    const setSubMenuSelection = useSetRecoilState(subMenuSelectionState);
    const setNotification = useSetRecoilState(notificationState);

    const history = useHistory();

    const onClick = () => {

        setSubMenuSelection ( props.menuItem.subMenuItemId );

        // 메뉴 클릭시 이전 스낵바 내용은 초기화. 메뉴누를 때마다 계속 보여지는 문제가 있음
        setNotification({
            open: false,
            severity: "success",
            msg: ""
        });
        history.push ( "/" + props.menuItem.topMenuPath + "/" + props.menuItem.menuPath );

    }

    return <SubMenuSelectItem menuItem={props.menuItem} onClick={onClick} />

}

export default SubMenuItem;
