import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import EguCostInfoCreateDialog from "../dialogTypes/EguCostInfoCreateDialog";
import { cloneDeep } from "lodash";
import getSpecific from "../../lib/api/getSpecific";

function EguCostInfoUpdateButton(props) {

    const { t } = useTranslation();

    const { id, schema, grid, gridDetail, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const [newSchema, setNewSchema] = useState();

    useEffect(() => {
        if (schema) {
            let newSchema = cloneDeep(schema);

            for (let column of newSchema.columns) {
                if (column.name === "baseYearMonth") {
                    column.readonly = true;
                    break;
                }
            }
            setNewSchema(newSchema)
        }
    }, [schema])

    const onOpen = () => {

        if (grid) {

            (async () => {

                let result = await getSpecific("EguCostInfoMgmt/baseYearMonth", grid.selectedItems[0].baseYearMonth)

                setFormData(result?.data[0])
                setOpen(true);

            })()

        }

    };

    const onClose = () => {

        setOpen(false);

    };

    const onUpdateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        refresh.refresh();

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <EguCostInfoCreateDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={newSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                isUpdate={true}
            />
        </>

    );
}

export default EguCostInfoUpdateButton;

