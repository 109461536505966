import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useState } from 'react';
import YearMonthDateRangeSelector from "../input/YearMonthDateRangeSelector";
import GroupByColumnPicker from "../input/GroupByColumnPicker";
import format from 'format-number';
// import * as wjcGrid from '@grapecity/wijmo.grid';

function MomSysCostAnalysis ( props ) {

    const {
        id,
    } = props;

    const [orderBy, setOrderBy] = useState();

    const [from, setFrom] = useState();

    const [to, setTo] = useState();

    const [view, setView] = useState("MomSysVwCostAnalysis");

    const [groupBy, setGroupBy] = useState([

        { name: "division", value: true },
        // { name: "orderBuyerId", value: true },

    ] );

    const checkItemSelected = ( groupBy, name ) => {

        let selected = groupBy[groupBy.findIndex ( ( item ) => item.name === name )].value;

        return selected;

    }

    const [groupByFields, setGroupByFields] = useState ( [

        "division",
        // "orderBuyerId",

    ] );

    const onDateRangeChanged = ( from, to ) => {

        let date = new Date();

        if ( to > date ) {

            to = date;

        }

        setFrom ( from );
        setTo ( to );

    }

    const onGroupByChanged = ( groupBy, orderBy ) => {

        let selectedCount = 0;

        for ( const item of groupBy ) {

            if ( item.value ) {

                selectedCount++;

            }

        }

        if ( selectedCount === 0 ) {

            groupBy = [

                { name: "division", value: true },
                // { name: "orderBuyerId", value: true },

            ];

        }

        let newGroupBy = [...groupBy];
        let newGroupByFields = [];

        for ( const item of groupBy ) {
                
            if ( item.name === "division" && item.value ) {

                newGroupByFields.push ( "division" );
                
            }

            // if ( item.name === "orderBuyerId" && item.value ) {

            //     newGroupByFields.push ( "orderBuyerId" );
                
            // }

        }
        
        // Group By 변경 후 컬럼 순서가 이상해 보이는 것을 막기 위해 Column Order 초기화

        let gridStorageKey = `grid-${id}-data-grid-layout`;
        localStorage.removeItem ( gridStorageKey );

        // clear order by info to avoid internal server error by order by not included column

        orderBy.setOrderBy ( [] );

        // To make set state instantly

        setTimeout( () => setGroupBy ( newGroupBy ), 0 );
        setTimeout( () => setGroupByFields ( newGroupByFields ), 0 );;

        // To make schema updated

        setTimeout ( () => setView ( undefined ), 0 );
        setTimeout ( () => setView ( view ), 0 );

    }

    const makeQueries = ( from, to, groupByFields ) => {

        let queries = {
            $from: from,
            $to: to,
            $groupBy: JSON.stringify(groupByFields)
        };

        return queries;

    }

    const onInitialized = ( grid ) => {

        grid.allowMerging = "Cells";

        grid.selectionMode = "None";

        grid.allowDragging = false;

        grid.allowPinning = false;

        // grid.loadedRows.addHandler( ( grid, e ) => {

        //     let v;
        //     let sum = 0;

        //     for( const row of grid.rows ) {

        //         if ( row.index === 0) {

        //             v = row.dataItem.division;

        //         }

        //         if ( !row.dataItem.division.startsWith(v, 0) ) {

        //             let newRow = new wjcGrid.Row();

        //             newRow.cssClass = "high-value";
        //             newRow.dataItem = {
        //                 division: "합계",
        //                 sales: sum,
        //             }
                    
        //             grid.rows.insert(row.index, newRow);
                    
        //             v = row.dataItem.division;
        //             sum = 0;

        //         } else {

        //             console.log(row.dataItem);

        //             sum += row.dataItem.sales;

        //         }

        //     }

        //     let newRow = new wjcGrid.Row();

        //     newRow.dataItem = {
        //         division: "합계",
        //         sales: sum,
        //     }
            
        //     grid.rows.insert(grid.rows.length, newRow);

        // })

    };

    const onOrderByInitialized = (orderBy) => {

        setOrderBy ( orderBy );

    }

    const onModifyViewSchema = ( groupBy, schema ) => {

        for ( const column of schema.columns ) {

            switch ( column.name ) {

                case "division":

                    column.grid.hide = checkItemSelected ( groupBy, "division" ) ? false : true;
                    
                    break;

                // case "orderBuyerId":

                //     column.grid.hide = checkItemSelected ( groupBy, "orderBuyerId" ) ? false : true;
                    
                //     break;

                default:

                    break;

            }

        }

    };

    const onLoadData = ( data ) => {
        
        for ( let row of data ) {

            row.profitRate = format({ round: 2, suffix: "%" })(row.profitRate);        

        }

    }

    return(

        <BasicCrudScreenType 
            id={id}
            view={view}
            $queries={ makeQueries ( from, to, groupByFields ) }
            onLoadData={ onLoadData }
            noPagination
            noExcel
            noSearch
            noFilter
            onAppendHeaderAtFirst={ () =>
                <>

                    <YearMonthDateRangeSelector onChange={onDateRangeChanged}/>
                    <div style={{marginLeft: "32px"}} />
                    <GroupByColumnPicker color="primary" groupByColumns={ groupBy } onChange={( groupBy ) => onGroupByChanged ( groupBy, orderBy ) } />

                </>
            }
            onInitialized = {onInitialized}
            onOrderByInitialized={onOrderByInitialized}
            onModifyViewSchema={ ( schema ) => onModifyViewSchema ( groupBy, schema ) }
        />
    ) 

}

export default MomSysCostAnalysis;