import printJson from "./printJson";

function prettyLog ( inputType, outputType, logData ) {

    const logLevels = {

        silent: Infinity,
        fatal: 60,
        error: 50,
        warn: 40,
        info: 30,
        debug: 20,
        trace: 10,
        security: 1000
                    
    };

    let json = {};

    switch ( inputType ) {

        case "json":

            json = logData;
            break;

        default:

            json = JSON.parse ( logData );
            break;

    }

    let jsonStr = "";

    let level = json.level ?? logLevels.silent;
    let levelEmoji = "  ";
    let levelStr = "";
    let levelColor = "#000000";

    switch ( level ) {

        case logLevels.fatal:

            levelEmoji = "💀";
            levelStr = "FATAL";
            levelColor = "#FF0000";
            
            break;

        case logLevels.error:

            levelEmoji = "🚨";
            levelStr = "ERROR";
            levelColor = "#FF0000";

            break;

        case logLevels.warn:

            levelEmoji = "⚠️";
            levelStr = "WARN ";
            levelColor = "#FFBF00";

            break;

        case logLevels.info:

            levelEmoji = "💬";
            levelStr = "INFO ";
            levelColor = "#00EB00";

            break;

        case logLevels.debug:

            levelEmoji = "🐞";
            levelStr = "DEBUG";
            levelColor = "#0000EB";

            break;

        case logLevels.trace:

            levelEmoji = "👣";
            levelStr = "TRACE";
            levelColor = "#000000";

            break;

        case logLevels.security:

            levelEmoji = "🔒";
            levelStr = "SECURITY";
            levelColor = "#EB00EB";

            break;

        default:

            levelEmoji = "  ";
            levelStr = "";
            levelColor = "#000000";

            break;

    }

    let time = new Date ( json.time );
    let timeStr = `${time.getFullYear()}-${( time.getMonth() + 1) < 10 ? '0' : ''}${time.getMonth() + 1}-${time.getDate() < 10 ? '0' : ''}${time.getDate()} ${time.getHours() < 10 ? '0' : ''}${time.getHours()}:${time.getMinutes() < 10 ? '0' : ''}${time.getMinutes()}:${time.getSeconds()}.${time.getMilliseconds()}`;
    let pid = json.pid ?? "";
    let hostname = json.hostname ?? "";
    let file = json.file ?? "";
    let line = json.line ?? "";
    let msg = json.msg ?? "";

    for ( let attr in json ) {

        switch ( attr ) {

            case "level":
            case "time":
            case "pid":
            case "hostname":
            case "file":
            case "line":
            case "msg":

                delete json[attr];
                break;

            default:
                break;

        }

    }

    jsonStr = printJson ( json, "    ", true );


    switch ( outputType ) {

        case "string":

            return `${levelEmoji} ${levelStr} [${timeStr}] (${pid} on ${hostname} ${file} ${line}):${msg}\n${jsonStr}\n`;

        default:

            return (
                        
                <div style={{padding: "0px", margin: "0px", overflow: "hidden"}}>
                    <pre style={{padding: "0px", margin: "0px", overflow: "hidden"}}><span style={{fontFamily:"serif"}}>{levelEmoji}</span> <span style={{color:`${levelColor}`,fontWeight: 700}}>{levelStr}</span><span style={{overflow: "hidden"}}> {`[${timeStr}] (${pid} on ${hostname} ${file} ${line}):${msg}`}</span></pre>
                    {
                        jsonStr.length > 0 ? <pre style={{padding: "0px", margin: "2px", overflow: "hidden"}}>{jsonStr}</pre> : <></>
                    }
                </div>

            );
            

    }


}

export default prettyLog;
