/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FormLabel, Divider } from "@material-ui/core";

import uiConf from "../../../config/uiConf";
import { getOption } from "../../../lib/api/getOptions";

import AutoFilterLayout from "../layout/AutoFilterLayout";
import AutoFilterColumnPicker from "../input/AutoFilterColumnPicker";
import AutoFilterFormGeneric from "./AutoFilterFormGeneric";
import AutoFilterFormRange from "./AutoFilterFormRange";
import AutoFilterFormRangeWoOp from "./AutoFilterFormRangeWoOp";
import AutoFilterFormValueEqual from "./AutoFilterFormValueEqual";
import AutoFilterFormValueCondition from "./AutoFilterFormValueCondition";
import AutoFilterFormGroupLayout from "../layout/AutoFilterFormGroupLayout";
import AutoFilterFormDateRangeWithButtons from "./AutoFilterFormDateRangeWithButtons";
import AutoFilterFormDateTimeRangeWithButtons from "./AutoFilterFormDateTimeRangeWithButtons";
import { useTranslation } from "react-i18next";

function AutoFilterForm ( props ) {

    const { t } = useTranslation();

    const { id, filterValue, filterApplyButton, onInitialized, schema, ...others  } = props;

    const [ , setInstance ] = useState();
    const [ filterFormValue, setFilterFormValue ] = useState ( );
    const [ selected, setSelected ] = useState ();
    const [ orderedColumns, setOrderedColumns ] = useState([]);
    const [ , setApplyButton ] = useState ();
    const [ useDateRangeFilterButtons, setUseDateRangeFilterButtons ] = useState ( true );

    useEffect ( () => {

        let mounted = true;

        let myInstance = {

            clear: () => {

                setFilterFormValue ( ( oldFilterValue ) => {
    
                    let newFilterValue = [];

                    for ( const column of schema.columns ) {
        
                        if ( column.filter && !column.filter.hide ) {
        
                            newFilterValue.push ( {
        
                                binding: column.name,
                                type: "condition",
                                and: true,
                                condition1: { operator: "", value: "" },
                                condition2: { operator: "", value: "" }
        
                            } );
        
                        }
                    }

                    setApplyButton ( ( button ) => {
                        
                        button.set ( newFilterValue );

                        return button;

                    } );
    
                    return newFilterValue;
        
                } );

            }

        };

        setInstance ( myInstance );

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

        ( async () => {

            let useDateRangeFilterButtons = await getOption ( "default", "useDateRangeFilterButtons" );

            if ( mounted ) {

                if ( useDateRangeFilterButtons && useDateRangeFilterButtons.toLowerCase () === "true" ) {

                    setUseDateRangeFilterButtons ( true );
    
                } else {
    
                    setUseDateRangeFilterButtons ( false );
    
                }
    
            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [] );
    
    useEffect ( () => {

        if ( filterValue ) {

            setFilterFormValue ( [...filterValue] );

        } else {

            setFilterFormValue ( () => {

                let newFilterValue = [];

                for ( const column of schema.columns ) {
    
                    if ( column.filter && !column.filter.hide ) {
    
                        newFilterValue.push ( {
    
                            binding: column.name,
                            type: "condition",
                            and: true,
                            condition1: { operator: "", value: "" },
                            condition2: { operator: "", value: "" }
    
                        } );
    
                    }
                }

                return newFilterValue;

            } );

        }

    }, [schema, filterValue] )

    useEffect ( () => {

        if ( schema && filterApplyButton ) {

            let filteredColumns = schema.columns.filter ( ( column ) => column.filter && !column.filter.hide );

            for ( const index in filteredColumns ) {

                filteredColumns[index].formIndex = index;

            }

            setApplyButton ( filterApplyButton );

            let storageKey = `${id}-column-selection`;

            let selectionString = localStorage.getItem ( storageKey );
            let selection = JSON.parse ( selectionString );
    
            if ( selection &&  ( filteredColumns.length === selection.length ) ) {
    
                setSelected ( selection );
    
            } else {

                setSelected ( filteredColumns.map ( () => true ) );

            }
    
            setFilterFormValue ( ( oldFilterValue ) => {

                let newFilterValue = oldFilterValue;

                if ( !oldFilterValue ) {

                    newFilterValue = [];
        
                    for ( const column of schema.columns ) {
        
                        if ( column.filter && !column.filter.hide ) {
        
                            newFilterValue.push ( {
        
                                binding: column.name,
                                type: "condition",
                                and: true,
                                condition1: { operator: "", value: "" },
                                condition2: { operator: "", value: "" }
        
                            } );
        
                        }
                    }

                    setTimeout ( () => filterApplyButton.set ( newFilterValue ), 0 );
    
                }

                return newFilterValue;
    
            } )

            let orderedColumns = schema ? schema.columns.slice() : [];

            if ( schema ) {
    
                if ( schema.useFilterFormOrder ) {
    
                    if ( schema.filterFormGroups && schema.filterFormGroups.length > 0 ) {
    
                        orderedColumns.sort( ( columnA, columnB ) => {

                            let formGroupA = 0;
                            let formGroupB = 0;
                            let formOrderA = 0;
                            let formOrderB = 0;

                            if ( columnA.filter && columnA.filter.formGroup ) {

                                formGroupA = columnA.filter.formGroup;

                            }

                            if ( columnB.filter && columnB.filter.formGroup ) {

                                formGroupB = columnB.filter.formGroup;

                            }

                            if ( columnA.filter && columnA.filter.formOrder ) {

                                formOrderA = columnA.filter.formOrder;

                            }

                            if ( columnB.filter && columnB.filter.formOrder ) {

                                formOrderB = columnB.filter.formOrder;

                            }

                            return ( formGroupA - formGroupB ) ? ( formGroupA - formGroupB ) : formOrderA - formOrderB;

                        } );
                            
    
                    } else {
      
                        orderedColumns.sort( ( columnA, columnB ) => {

                            let formOrderA = 0;
                            let formOrderB = 0;
    
                            if ( columnA.filter && columnA.filter.formOrder ) {

                                formOrderA = columnA.filter.formOrder;

                            }

                            if ( columnB.filter && columnB.filter.formOrder ) {

                                formOrderB = columnB.filter.formOrder;

                            }

                            return formOrderA - formOrderB;

                        } );
    
                    }
    
                }
                else {
    
                    if ( schema.filterFormGroups && schema.filterFormGroups.length > 0 ) {
    
                        orderedColumns.sort( ( columnA, columnB ) => {

                            let formGroupA = 0;
                            let formGroupB = 0;

                            if ( columnA.filter && columnA.filter.formGroup ) {

                                formGroupA = columnA.filter.formGroup;

                            }

                            if ( columnB.filter && columnB.filter.formGroup ) {

                                formGroupB = columnB.filter.formGroup;

                            }

                            return formGroupA - formGroupB;

                        } );
    
                    }
                }

            }
            
            setOrderedColumns ( orderedColumns );
    
        }
        
    }, [schema, filterApplyButton] );

    const onPartialFilterValueChanged = ( partialFilterValue ) => {

        setFilterFormValue ( ( oldFilterValue ) => {

            let newFilterValue = oldFilterValue.map ( ( oldPartialFilter ) => {

                if ( oldPartialFilter.binding === partialFilterValue.binding ) {

                    return {...partialFilterValue};

                } else {

                    return oldPartialFilter
                }

            } );

            setTimeout ( () => filterApplyButton.set ( newFilterValue ), 0 );

            return newFilterValue;
    
        } );

    };

    const onSelectChanged = ( newSelected, schema ) => {

        let storageKey = `${id}-column-selection`;
        let selectionString = JSON.stringify ( newSelected );

        localStorage.setItem ( storageKey, selectionString );

        setFilterFormValue ( ( oldFilterValue ) => {

            setTimeout ( () => {

                setSelected ( newSelected )
        
                filterApplyButton.set ( oldFilterValue.filter ( ( _filter, index ) => newSelected[index] ) );

            },0 );
            
            return oldFilterValue;

        } );

    };

    let tabIndex = 0;
    return (

        <AutoFilterLayout>
            
            {
                schema
                ?
                <>
                <AutoFilterColumnPicker id={id+"-column-picker"} columns={schema.columns} value={selected} onChange={( selected ) => onSelectChanged ( selected, schema )} />
                </>
                :
                <></>
            }
            {
                ( schema && selected )
                ?
                ( ( schema && schema.filterFormGroups && schema.filterFormGroups.length > 0 ) ? schema.filterFormGroups : [""] )
                    .map ( ( filterFormGroupName, index ) => {

                        return (

                            <div key={index}>

                                { index === 0 ? <></> : ( uiConf.displayRulerBetweenFilterFormGroup ? <Divider variant="middle"/> : <></> ) } {/* Horizontal Ruler between Filter Form Groups */}
                                { ( uiConf.displayFilterFormGroupName && filterFormGroupName !== "" ) ? <FormLabel component="legend">{ t ( filterFormGroupName ) }</FormLabel> : <></> } {/* Filter FormGroup Name */}

                                <AutoFilterFormGroupLayout {...others}  >
                                {
                                    orderedColumns
                                        .filter ( ( column ) => column.filter && !column.filter.hide )
                                        .filter ( ( column ) => selected[column.formIndex] )
                                        .filter ( ( column ) =>  {

                                            if ( column.filter.formGroup === undefined) {

                                                return true;

                                            }

                                            if ( column.filter.formGroup !== index ) {

                                                return false;
                                            }

                                            return true;

                                        } )
                                        .map ( ( column ) => {

                                            tabIndex++;
                                            
                                            switch ( column.filter.type ) {

                                                case "valueEqual":

                                                    return (

                                                        <AutoFilterFormValueEqual id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                    );

                                                case "valueCondition":

                                                    return (

                                                        <AutoFilterFormValueCondition id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                    );

                                                case "range":
                                                        
                                                        switch ( column.formItemType ) {

                                                            
                                                            case "date":

                                                                return (
                                                                    useDateRangeFilterButtons
                                                                    ?
                                                                    <AutoFilterFormDateRangeWithButtons id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                    :
                                                                    <AutoFilterFormRangeWoOp id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                );

                                                            case "time":
                                                            case "datetime":

                                                                return (
                                                                    useDateRangeFilterButtons
                                                                    ?
                                                                    <AutoFilterFormDateTimeRangeWithButtons id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                    :
                                                                    <AutoFilterFormRangeWoOp id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                );

                                                            default:

                                                                if ( column.type.startsWith ( "date" ) || column.type.startsWith ( "time" ) ) {

                                                                    return (
                                                                        useDateRangeFilterButtons
                                                                        ?
                                                                        <AutoFilterFormDateTimeRangeWithButtons id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                        :
                                                                        <AutoFilterFormRangeWoOp id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                    );

                                                                }

                                                                return (
                                                                    <AutoFilterFormRange id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                                );

                                                        }

                                                case "rangeWithOp":

                                                        return (
                                                            <AutoFilterFormRange id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />
                                                        );

                                                case "rangeWoOp":

                                                    return (

                                                        <AutoFilterFormRangeWoOp id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />

                                                    );

                                                default:

                                                    return (

                                                        <AutoFilterFormGeneric id={id+"-"+column.name} tabIndex={tabIndex} key={column.name} column={column} filterValue={filterFormValue && filterFormValue[column.formIndex]} onChange={ ( filter ) => onPartialFilterValueChanged ( filter ) } />

                                                    );

                                            }

                                        } )
                                }
                                </AutoFilterFormGroupLayout>

                            </div>
                        );

                    } )
                :
                <></>

            }
            <Divider />

        </AutoFilterLayout>
    );
}

export default AutoFilterForm;

