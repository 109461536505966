import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },
    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function AutoFormDialog(props) {

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [disabled, setDisabled] = useState ( true );
    const [isEmpty, setIsEmpty] = useState ( );
    const [formData, setFormData] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [keepOpen, setKeepOpen] = useState(false);

    const {

        // Dialog Properties

        id,
        title,
        mode,
        platform,
        resizable,
        moveable,
        fullWidth,
        maxWidth,
        onInitialized,
        noKeepOpen,

        // Form Properties

        formId,
        schema,
        initialData,
        setInitialDataCallback,
        modifyFormDataCallback,
        checkShowCallback,
        checkDisabledCallback,
        checkFormValidationCallback,
        checkItemValidationCallback,
        modifyUrlCallback,

        // customize error message callback

        customizeErrorMsgCallback,

        // AutoFormDialog Properties

        onClose,
        onSuccess,
        onFailure,

        // Do Not Notify Using Snackbar by Dialog itself

        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,

        doNotUseKeyAsUrlWhenUpdate,

        onSelectMst,
        refresh,

        ...others

    } = props;

    const [tableSchema, setTableSchema] = useState(schema);

    useEffect(() => {

        if (schema) {

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        setTableSchema(schema);

    }, [schema]);
    
    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );

        refresh.refresh();

    };

    const notifyWarning = (msg) => {

        console.warn(msg);

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "warning",
                msg: msg,
                open: true,
            });

        }

    };

    const checkItemValidataion = (formData, schema) => {


        for (const column of schema.columns) {

            let [valid, msg] = checkValidation(mode, column, column.validation, formData[column.name] || "");

            if (valid && checkItemValidationCallback) {

                [valid, msg] = checkItemValidationCallback(formData, column);

            }

            if (!valid) {

                notifyWarning(msg);
                return false;

            }

        }

        return true;

    }

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseDialog = () => {

        setDisabled(false);

        if (onClose) {

            onClose();

        }

    }

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onApiSuccess = (result, schema) => {

        instance.hide();

        if  ( onSuccess ) {

            setTimeout ( ( ) =>  onSuccess ( result.data ), 0 );

        } else {

            onNotifySuccess ( result.data );

        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = (mode, formData, schema) => {

        if (!checkItemValidataion(formData, schema)) {

            console.error("Form Validation Failed");

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (valid && checkFormValidationCallback) {

                [valid, msg] = checkFormValidationCallback(formData);

            }

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }

        }
        else if (checkFormValidationCallback) {

            const [valid, msg] = checkFormValidationCallback(formData);

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }

        // build base api url

        let url = "/api/" + schema.name;

        if (modifyUrlCallback) {

            url = modifyUrlCallback(url);

        }

        let apiData = { ...formData };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( apiData[formItem] !== undefined ) {

                json[formItem] = apiData[formItem];

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    };

    const onCancel = () => {

        instance.hide();

    };

    const onChange = ( formData ) => {

        setFormData ( formData );

        setDisabled ( false );

    }

    const checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>

            </>

        )

    }

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    return (
        <Dialog
            id={id}
            title={title}
            platform={platform ? platform : uiConf.dialogPlatform}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.6} >

                        <BasicCrudScreenType 
                            id="EguVwPopProductOutputList"
                            embededInOtherScreenType
                            headerTitle={t("tableName.OutputMaterialList")}
                            table="EguVwPopProductOutputList"
                            view="EguVwPopProductOutputList"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            noFilter
                            noSearch
                            onModifyViewUrl={(url) => `${url}/${onSelectMst.planId}`}
                            onLoadData={onLoadData}
                            onInitialized={(grid) => grid.selectionMode = "None"}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.4} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id={formId}
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                setInitialDataCallback={setInitialDataCallback}
                                modifyFormDataCallback={modifyFormDataCallback}
                                checkShowCallback={checkShowCallback}
                                checkDisabledCallback={checkDisabledCallback}
                                checkValidationCallback={checkItemValidationCallback}
                                onChange={onChange}
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={noKeepOpen ? () => <></> : checkKeepOpen}
                labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                buttonDisabled={[disabled || isEmpty, false]}
                onOk={() => onOk(mode, formData, tableSchema)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default AutoFormDialog;
