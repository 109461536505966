/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { Dialog, Paper } from '@material-ui/core';

function PaperComponent ( props ) {
    const nodeRef = useRef(null);
    return (
        <Draggable nodeRef={nodeRef}
            handle=".draggableDialogHeader" cancel={'[className="MuiDialogContent-root"]'} >
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

function MaterialDialog ( props ) {

    const { modaless, show, moveable, children, onClose, onInitialized, ...others } = props;

    const [ open, setOpen ] = useState(show?show:false);
    
    const closeDialog = () => {

        setOpen(false);
        
        if ( onClose ) {

            onClose ();
            
        }

    };

    useEffect ( () => {

        let myInstance = {

            show: () => {

                setOpen(true);

            },

            hide: () => {

                setOpen(false);

                if ( onClose ) {

                    onClose ();

                }

            }

        }

        if ( onInitialized  ) {

            onInitialized ( myInstance );

        }

    }, [] );

    if ( !modaless ) {

        if ( moveable ) {

            return (
                <Dialog
                    onClose={closeDialog}
                    open={open}
                    PaperComponent={PaperComponent}
                    {...others}
                >
                    { children }
                </Dialog>

            );

        } else {

            return (
                <Dialog
                    onClose={closeDialog}
                    open={open}
                    {...others}
                >
                    { children }
                </Dialog>

            );

        }

    }
    else {

        return <h3>Material UI does not support modaless Dialog</h3>

    }

}

export default MaterialDialog;
