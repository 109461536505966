/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import notificationState from "../../../states/notificationState";
import apiClient from "../../../lib/common/apiClient";

const EMPTY = '';
const UNDERBAR = '_';
const PERIOD = '.';

// Form Item Type Select에 대응하는 Custom DataGridColumn
function DataGridFileDownloadColumn(props) {
    const { t } = useTranslation();
    const { schema, ...others } = props;
    const [, setNotification] = useRecoilState(notificationState);
    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    useEffect(() => {
        setCellTemplate(() => ((ctx) => {
            if(ctx.item[schema.name] && ctx.item[schema.name][0] === '/') {
                return (
                    <>
                        <span onClick={() => onClick(ctx)}>
                            <IconButton size="small" sx={{p: 0, m: 0}}>
                                <Icon>download</Icon>
                            </IconButton>
                        </span>
                    </>
                );
            }
        }));
    }, [schema]);

    const onClick = (ctx) => {
        let url;
        if(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
            url = ctx.item[schema.name];
        } else {
            url = `http://localhost:8080${ctx.item[schema.name]}`;
        }

        let targetFileName = ctx.item[schema.name];
        if(schema.grid.targetFileName) {
            targetFileName = ctx.item[schema.grid.targetFileName];
        }

        let firstInputName;
        if(schema.grid.firstInputName) {
            firstInputName = schema.grid.firstInputName;
        }

        let lastInputName;
        if(schema.grid.lastInputName) {
            lastInputName = schema.grid.lastInputName;
        }

        let firstColumnName;
        if(schema.grid.firstColumnName) {
            firstColumnName = schema.grid.firstColumnName;
        }

        let secondColumnName;
        if(schema.grid.secondColumnName) {
            secondColumnName = schema.grid.secondColumnName;
        }

        let lastColumnName;
        if(schema.grid.lastColumnName) {
            lastColumnName = schema.grid.lastColumnName;
        }

        let filePath;
        if(schema.grid.filePathColumn) {
            filePath = schema.grid.filePathColumn;
        }
        
        if(isCustomFileName(ctx, firstInputName, firstColumnName, filePath)){
            targetFileName = getDownloadFileName(ctx, firstInputName, lastInputName, firstColumnName, secondColumnName, lastColumnName, filePath);
        }

        apiClient
            .get(url, {responseType: "blob"})
            .then((result) => {
                const blob = new Blob([result.data]);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = targetFileName;
                link.click ();
                window.URL.revokeObjectURL(link.href);
            })
            .catch(async(reason) => {
                const blob = reason.response.data
                const msg = await blob.text();
                onApiFailure(msg)
            })
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("success.fileDownloadFailure", {reason})
        );
    };
    
    return (
        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

function isCustomFileName(ctx, firstInputName, firstColumnName, filePath) {
    const keys = Object.keys(ctx.item);
    for(const key of keys) {
        if(customFileNameIsNotEmpty(firstInputName, firstColumnName, filePath) && key === firstColumnName) {
            return true;
        }
    }
    return false;
}

function customFileNameIsNotEmpty(firstInputName, firstColumnName, filePath) {
    if((isNotEmpty(firstInputName) || isNotEmpty(firstColumnName)) && isNotEmpty(filePath)) {
        return true;
    }
    return false;
}

function isNotEmpty(value) {
    if(value === undefined || value === 'undefined' || value === null || value === EMPTY) {
        return false;
    }
    return true;
}

function getDownloadFileName(ctx, firstInputName, lastInputName, firstColumnName, secondColumnName, lastColumnName, filePath) {
    let fileName = EMPTY;
    if(isNotEmpty(firstInputName)) {
        fileName += firstInputName;
    }

    if(isNotEmpty(lastInputName)) {
        fileName += getUnderbar(fileName);
        fileName += lastInputName;
    }

    if(isNotEmpty(firstColumnName)) {
        fileName += getUnderbar(fileName);
        fileName += ctx.item[firstColumnName];
    }

    if(isNotEmpty(secondColumnName)) {
        fileName += getUnderbar(fileName);
        fileName += ctx.item[secondColumnName];
    }

    if(isNotEmpty(lastColumnName)) {
        fileName += getUnderbar(fileName);
        fileName += ctx.item[lastColumnName];
    }

    fileName += PERIOD + ctx.item[filePath].substring(ctx.item[filePath].lastIndexOf(PERIOD) + 1).toLowerCase();
    
    return fileName;
}

function getUnderbar(value) {
    if(value.length !== 0) {
        return UNDERBAR;
    }
    return EMPTY;
}

export default DataGridFileDownloadColumn;

