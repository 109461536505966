import { useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

import findRootRedirectPath from "../../../lib/utils/findRootRedirectPath";
import menuInfoState from "../../../states/menuInfoState";
import rootRedirectPathState from "../../../states/rootRedirectPathState";
import topMenuSelectionState from "../../../states/topMenuSelectionState";
import TopMenuItemLayout from "../layout/TopMenuItemLayout";

function TopMenuItem ( props ) {

    const menuInfo = useRecoilValue(menuInfoState);
    const setRootRedirectPath = useSetRecoilState(rootRedirectPathState);
    const setTopMenuSelection = useSetRecoilState(topMenuSelectionState);

    const history = useHistory();

    const onClick = () => {

        setTopMenuSelection ( props.menuItem.topMenuItemId );
        history.push ( "/" + props.menuItem.menuPath );
        setRootRedirectPath ( findRootRedirectPath ( props.menuItem.topMenuItemId, menuInfo ) );
        
    }

    return <TopMenuItemLayout menuItem={props.menuItem} onClick={onClick} />

}

export default TopMenuItem;
