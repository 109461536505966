import { useCallback, useEffect, useState } from "react";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";

function EguPopNoticeUpdateButton(props) {

    const createButtonName = "쓰기";

    const { id, schema, onSuccess, api, noticeNo, ...others } = props;

    const [openWindow, setOpenWindow] = useState();

    const [, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const close = useCallback(() => {

        if (api) {

            api.refresh();

        }

        if (openWindow) {

            openWindow.removeEventListener('beforeunload', close)
        }

    }, [openWindow, api])

    useEffect(() => {

        (async () => {

            if (openWindow) {

                openWindow.addEventListener('beforeunload', close);

            }

        })()

    }, [openWindow, api, close]);

    const onOpen = () => {

        let newWindow = window.open(`/newWindow/EguPopNoticeUpdateDialog/${noticeNo}`, 'update', '_blank, width=' + window.screen.width / 2 + ', height=' + window.screen.height / 2)

        const receiveMessage = async (e) => {

            const result = e.data.message;

            switch (result) {

                case 'Success':
                    if (onSuccess) {
                        onSuccess(e.data.t);
                        window.removeEventListener("message", receiveMessage);
                    }
                    break;

                case 'Failure':
                    notifyFailure(e.data.t);
                    window.removeEventListener("message", receiveMessage);
                    break;

                case 'SuccessKeepOpen':

                    if (onSuccess) {
                        onSuccess(e.data.t);
                    }
                    break;

                case 'FailureKeepOpen':
                    notifyFailure(e.data.t);
                    break;

                default:
                    break;
            }
        }

        window.addEventListener("message", receiveMessage, false);

        setOpenWindow(newWindow);
    };

    return (
        <>
            <UpdateRecordButtonLayout
                id={id + "create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
        </>
    );
}

export default EguPopNoticeUpdateButton;