import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilValue } from "recoil";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

import authProfileState from "../../states/authProfileState";

import "./SidePaneLayout.css";

const useStyles = makeStyles((theme) => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: "rgba(255, 255, 255, 0.7)",
        "&:hover,&:focus": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
    },
    itemCategory: {
        backgroundColor: "#232f3e",
        /* backgroundColor: "#ffffff", */
        /* boxShadow: "0 -1px 0 #404854 inset", */
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        textAlign : 'center',
        minHeight : "48px" 
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: "#4fc3f7",
    },
    itemPrimary: {
        fontSize: "inherit",
    },
    itemIcon: {
        minWidth: "auto",
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    drawer: {
        width: 180,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width:180,
        overflow: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width:55,
        overflow: 'hidden',
    },
    
    
}));

function SidePaneLayout(props) {

    const { children } = props;

    const authProfile = useRecoilValue ( authProfileState );

    const classes = useStyles();

    return (

        <Drawer variant="permanent" {...props}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.sideMenuOpen,
                [classes.drawerClose]: !props.sideMenuOpen,
            })}
            classes={{
                paper: clsx({
                  [classes.drawerOpen]: props.sideMenuOpen,
                  [classes.drawerClose]: !props.sideMenuOpen,
                }),
            }}
        >
            <List disablePadding >
                
                <ListItem
                    className={clsx(
                        classes.firebase,
                        classes.item,
                        classes.itemCategory
                    )}
                >
                {
                    authProfile.smallLogo
                    ?
                    <img alt="smallLogo" src={( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' ) ? authProfile.smallLogo : "http://localhost:8080" + authProfile.smallLogo} />
                    :
                    <span style={{margin: "auto"}}>Radix-MOM</span>
                }
                </ListItem>
                <Divider className={classes.divider} />
                {children}
            </List>
        </Drawer>

    );
}

export default SidePaneLayout;
