/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function EguOutSourcingLogShip(props) {
    const { title, onSelectMst, onLogShipApi } = props;

    const view = "EguVwOutSourcingLogShip";

    const [eguOutSourcingLogShipApi, setEguOutSourcingLogShipApi] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        if (eguOutSourcingLogShipApi) {
            eguOutSourcingLogShipApi.refresh();
        }
    }, [onSelectMst?.outSourcingShipId, onSelectMst?.materialCode, onSelectMst?.materialcodeVer]);

    return (
        <BasicCrudScreenType
            id={view}
            view={view}
            headerTitle={t(title)}
            onInitialized={(grid) => {
                grid.selectionMode = "None";
            }}
            onApiInitialized={(api) => {
                setEguOutSourcingLogShipApi(api);

                if (onLogShipApi) {
                    onLogShipApi(api);
                }
            }}
            onModifyViewUrl={(url) =>
                `${url}/${onSelectMst?.outSourcingShipId}/${onSelectMst?.materialCode}/${onSelectMst?.materialCodeVer}`
            }
            noExcel
        />
    );
}

export default EguOutSourcingLogShip;
