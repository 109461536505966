import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import DataAllSaveToExcelButton from "../input/DataAllSaveToExcelButton";

function MomSysUdtWareHouse ( props ) {
    const id = "MomSysUdtWareHouse";

    const [disableEdit, setDisableEdit] = useState(false);
    const [disableDelete, setDisableDelete] = useState(false);
    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysUdtWareHouse");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {

        if ( grid ) {

            grid.selectionChanged.addHandler((grid, event) => {

                let item = grid.selectedItems && grid.selectedItems[0];

                if ( item ) {

                    switch ( item.wareHouseCode ) {

                        case "W000": // 작업대기창고 ( 1.LINE )
                        case "W998": // 불량제품창고 ( 4.FAILURE )
                        case "W999": // 작업출고창고 ( 1.LINE )
                        case "Z999": // 출고대기창고 ( 3.SHIP )

                            setDisableEdit(true);
                            setDisableDelete(true);
                            break;

                        default:

                            setDisableEdit(false);
                            setDisableDelete(false);
                            break;

                    }

                } else {

                    setDisableEdit ( true );
                    setDisableDelete ( true );

                }

            });

            grid.itemsSourceChanged.addHandler((grid, event) => {

                let item = grid.selectedItems && grid.selectedItems[0];

                if ( item ) {

                    switch ( item.wareHouseCode ) {

                        case "W000": // 작업대기창고 ( 1.LINE )
                        case "W998": // 불량제품창고 ( 4.FAILURE )
                        case "W999": // 작업출고창고 ( 1.LINE )
                        case "Z999": // 출고대기창고 ( 3.SHIP )

                            setDisableEdit(true);
                            setDisableDelete(true);
                            break;
                            
                        default:

                            setDisableEdit(false);
                            setDisableDelete(false);
                            break;

                    }

                } else {

                    setDisableEdit ( true );
                    setDisableDelete ( true );
                    
                }
                
            });

        }

        setGrid(grid);

    }

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return <BasicCrudScreenType 
        id={id}
        table="MomSysUdtWareHouse" 
        view="MomSysVwWareHouse" 
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized} 
        noCreate
        noEdit
        noDelete
        noExcel
        onAppendButtonInFrontOfSearch={() => (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <CreateRecordButton
                            id={id}
                            schema={schema}
                            refresh={api}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>
                    
                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                        <UpdateRecordButton 
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            disabled={disableEdit}
                            onSuccess={notifySuccess}
                        />
                    </AccessControl>
                    
                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <DeleteRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            disabled={disableDelete}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                        <DataAllSaveToExcelButton
                            id={id}
                            grid={grid}
                            view="MomSysVwWareHouse"
                            excelFileName="창고"
                            color="primary"
                            disabled={selectGrid ? false : true}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )}
    />
}

export default MomSysUdtWareHouse;
