import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysMaterialStatusDetail from "./MomSysMaterialStatusDetail";
import MomSysProductTreeButton from "../input/MomSysProductTreeButton";
import {debounce} from "lodash";
import EguMaterialStatusSaveExcelButton from "../input/EguMaterialStatusSaveExcelButton";

function EguMaterialStatus ( props ) {

    const [ mstGrid, setMstGrid ] = useState();
    
    const [ height, setHeight ] = useState(0);

    const [searchVal, setSearchVal] = useState();
    const [filterVal, setFilterVal] = useState ();
    
    const [material, setMaterial] = useState();

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };
    useEffect( () => {

        ( async () => {

            if (mstGrid) {
    
                mstGrid.selectionChanged.addHandler(debounce((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        setMaterial(() => {

                            return (
                                {
                                    ...material,
                                    qty : material.qtyInStock
                                });
                        });
                    } else {

                        setMaterial([]);

                    }
                },10));
                
                mstGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        setMaterial(() => {

                            return (
                                {
                                    ...material,
                                    qty : material.qtyInStock
                                });
                        });
                        
                    } else {

                        setMaterial([]);

                    }
                    
                    
                },10));

                mstGrid.loadedRows.addHandler((flex, e) => {
                    /* let rows  = e.getRow(); */
                    for(const row of flex.rows) {
                        
                        if(row.dataItem.qtySpareStock < row.dataItem.qtySafetyStock) {
                            row.cssClass = `failed-stock-apply`

                        }
                    }
                })

            }

        } ) ()

    },[mstGrid]);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);


    const onMstInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setMstGrid(grid);

    };

    const onSearchChangeValue = (value) => {
        setSearchVal(value);
    }

    const onFilterChangeValue = (value) => {
        setFilterVal(value);
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysVwInventorySummary" 
                        view="MomSysVwInventorySummary" 
                        headerTitle={t("term.materialList")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onMstInitialized}
                        onSearchChangeValue={onSearchChangeValue}
                        onFilterChangeValue={onFilterChangeValue}
                        onModifyViewUrl = {(url) => `${url}/material`}
                        onAppendButtonAtFirst={() =>
                            <>

                                <BasicCrudScreenActionItemLayout>

                                    <MomSysProductTreeButton color="primary" material={material} />

                                </BasicCrudScreenActionItemLayout>

                            </>
                        }
                        onAppendButtonInFrontOfSearch={
                            () => (
                                <>
                                    <BasicCrudScreenActionItemLayout>
                                        <EguMaterialStatusSaveExcelButton 
                                            searchValue={searchVal}
                                            filterValue={filterVal}
                                        />
                                    </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                        
                    />
            </ReflexElement>

            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>

                        {
                            (material)
                            ?
                            <MomSysMaterialStatusDetail
                                id="MomSysVwMaterialStock" 
                                view="MomSysVwMaterialStock" 
                                material = {material}
                            />
                            :
                            <></>
                        }

                </ReflexElement>

            </ReflexContainer>
        </div>

    );

}


export default EguMaterialStatus;
