import { useState, useEffect } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import { useTranslation } from "react-i18next";

import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

function MomSysMaterialInput ( props ) {

    const { t } = useTranslation();

    const [ height, setHeight ] = useState(0);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);


    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    return (
        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">
                
                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysZvwInputInspect" 
                        view="MomSysZvwInputInspect"
                        headerTitle = {t("term.standard")}
                        onInitialized = {(grid) => grid.selectionMode = "None"}
                    />

                    </ReflexElement>

                    <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                    <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysNsZvwInputInspect" 
                        view="MomSysNsZvwInputInspect"
                        headerTitle = {t("term.nonStandard")} 
                        onInitialized = {(grid) => grid.selectionMode = "None"}
                    />

                    </ReflexElement>

            </ReflexContainer>
        
        </div>

    )
}

export default MomSysMaterialInput;
