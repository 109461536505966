/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import * as wjGrid from '@grapecity/wijmo.grid';

import format from 'format-number';

import getNewDateObj from "../../lib/common/getNewDateObj";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

class RestrictedMergeManager extends wjGrid.MergeManager {

    getMergedRange(p, r, c, clip = true) {

        // create basic cell range
        var rng = null;

        // start with single cell
        rng = new wjGrid.CellRange(r, c);
        var pcol = c > 0 ? c - 1 : c;

        // get reference values to use for merging
        var val = p.getCellData(r, c, false);
        var pval = p.getCellData(r, pcol, false);

        if ( c > 2 ) {

            return rng;

        }

        // expand up
        while (rng.row > 0 &&
            p.getCellData(rng.row - 1, c, false) === val &&
            p.getCellData(rng.row - 1, pcol, false) === pval) {
            rng.row--;
        }
        
        // expand down
        while (rng.row2 < p.rows.length - 1 &&
            p.getCellData(rng.row2 + 1, c, false) === val &&
            p.getCellData(rng.row2 + 1, pcol, false) === pval) {
            rng.row2++;
        }
        
        // don't bother with single-cell ranges
        if (rng.isSingleCell) {
            rng = null;
        }
        
        // done
        return rng;
    }

}

function EguProductionStatus ( props ) {

    const { t } = useTranslation();

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        grid.allowMerging = "Cells";
        grid.allowSorting = false;
        grid.allowDragging = false;
        grid.allowPinning = false;
        grid.mergeManager = new RestrictedMergeManager ();
            
    };

    const formatTime = ( time ) => {

        if ( time === null || time === undefined || time === "") {

            return "";

        }

        let dateObj = getNewDateObj( time );

        if ( !dateObj ) {
            return "";
        }

        return `${dateObj.getFullYear ()}-${dateObj.getMonth() + 1}-${dateObj.getDate()} ${("0" + dateObj.getHours()).slice(-2)}:${("0" + dateObj.getMinutes()).slice(-2)}`;

    }
    const onLoadData = ( data ) => {

        for ( let row of data ) {

            row.plannedQty = format({ round: 4 })(row.plannedQty);        
            row.startTime = formatTime ( row.startTime );        
            row.endTime = formatTime ( row.endTime );
            row.resultQty = format({ round: 4 })(row.resultQty);        
            row.resulfailQtytQty = format({ round: 4 })(row.failQty);        
            row.failRatio = format({ round: 2, suffix: "%" })(row.failRatio);        

        }

    }

    return (

        <BasicCrudScreenType
            id="EguProductionStatus"
            headerTitle={t("term.productionStatus")}
            table="EguVwDashProductionMonitoring"
            view="EguVwDashProductionMonitoring"
            noCreate
            noEdit
            noDelete
            noExcel
            noFilter
            onLoadData={onLoadData}
            onInitialized={onGridInitialized}
        />                                

    );

}

export default EguProductionStatus;
