import { useTranslation } from "react-i18next";

import Button from "../input/Button";

function FilterApplyButtonLayout ( props ) {

    const { t } = useTranslation();

    return <Button {...props}>{t("common.apply")}</Button>;

}

export default FilterApplyButtonLayout;

