import "./TopMenuLayout.css";

function TopMenuLayout ( props ) {

    const { children } = props;

    return (

        <div data-cy="TopMenu">
            { children }
        </div>

    );

}

export default TopMenuLayout;
