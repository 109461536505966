import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOk from "../dialog/functional/DialogActionsOk";

import ApprovalPathManage from "../screens/ApprovalPathManage";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    }
}));

function ApprovalPathEditDialog ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    const {

        id,

        requester,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onOk,
        onClose,
        onInitialized,

        ...others

    } = props;

    const [instance, setInstance] = useState ();
    const [ notification, setNotification ] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const notifyInfo = (msg) => {

        setNotification({
            severity: "info",
            msg: msg,
            open: true,
        });

    };

    const notifyWarning = (msg) => {

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyError = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );

    };

    const onNotifyInfo = ( msg ) => {

        notifyInfo ( msg );

    };

    const onNotifyWarning = ( msg ) => {

        notifyWarning ( msg );

    };

    const onNotifyError = ( msg ) => {

        notifyError ( msg );

    };

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );
        
        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onCloseDialog = () => {

        if ( onClose ) {

            onClose ();
            
        }

    };

    const onConfirm = () => {

        instance.hide ();

        if ( onOk ) {

            onOk ();

        }
    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.confirmDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        { t ( "dialog.title.ApprovalPath.manage" ) }
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        { t ( "dialog.title.ApprovalPath.manage" ) }
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent className={classes.root} dividers>

                <ApprovalPathManage

                    embededInOtherScreenType

                    requester={requester}

                    noEdit
                    noSearch
                    noFilter

                    onNotifySuccess={onNotifySuccess}
                    onNotifyInfo={onNotifyInfo}
                    onNotifyWarning={onNotifyWarning}
                    onNotifyError={onNotifyError}

                    fixedHeight={600}

                />
                
            </DialogContent>

            <DialogActionsOk
                onOk={onConfirm}
            />

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

        </Dialog>

    );

}

export default ApprovalPathEditDialog;
