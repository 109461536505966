/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguStockExcelUploadButton ( props ) {

    const {t} = useTranslation();
    const {id, schema, refresh, onSuccess, createButtonName, ...others} = props;
    const [open, setOpen] = useState(false);

    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {

        if (schema) {

            let modifiedSchema = cloneDeep(schema);

            for (const column of modifiedSchema.columns) {

                switch (column.name) {

                    case "wareHouseCode": case "excelFile": 
                        column.hideInForm = false;
                        column.hideInForm = false;

                        break;

                    default:
                        column.hideInForm = true;

                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }

    }, [schema]);

    const onExcelUploadModifiyUrl = (url)=> {

        let newUrl = `${url}`;

        return newUrl;
    }

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                formId={id+"create-form"}
                mode="create"
                title={t(`dialog.title.EguMstMaterial.excelUpload`)}
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback={onExcelUploadModifiyUrl}
            />
        </>
    );
}

export default EguStockExcelUploadButton;

