import getSpecific from "./getSpecific";

async function getMenuInfo ( menuSetId ) {

    let menuInfo = {};

    menuInfo.topMenuItems = (await getSpecific("VwTopMenuItems", menuSetId )).data;
    menuInfo.subMenuItems = (await getSpecific("VwSubMenuItems", menuSetId )).data;

    return menuInfo;

}

export default getMenuInfo;