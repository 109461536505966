import React, { Children, cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ( ( theme ) => ( {

  root: {

    '& > * + *': {

        marginTop: theme.spacing(2),

    },

  },

} ) );

function PaginationLayout (props) {

    const classes = useStyles();

    const { children, ...others } = props;

  return (

    <div className={classes.root}>

        {

            Children.map ( children, ( child => cloneElement ( child, others ) ) )

        }

    </div>

  );

}

export default PaginationLayout;

