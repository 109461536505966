function getAuthConf () {

    return (
    
        {
            checkLoginPeriod: 30000,
            "jwt": {
                "cookieName": "$jwtAuthToken"
            }
        }

    );

}

let authConf = getAuthConf();

module.exports = authConf;