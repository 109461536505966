/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import PopProductShipLotUpdateDialog from "../dialogTypes/PopProductShipLotUpdateDialog";


function PopProductShipLotUpdateButton ( props ) {
    const {id, schema, lotGrid, refresh, shipmentSelect, onSuccess, customizeErrorMsgCallback, modifyUrlCallback, ...others} = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();
    const {t} = useTranslation();
    
    const onOpen = () => {
        if(lotGrid) {
            setFormData({...lotGrid});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if(onSuccess) {
            onSuccess(msg);
        }
        refresh.refresh();
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <PopProductShipLotUpdateDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                modifyUrlCallback={modifyUrlCallback}
            />
        </>
    );
}

export default PopProductShipLotUpdateButton;

