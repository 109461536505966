/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguMstMaterialCopyCreateDialog from "../dialogTypes/EguMstMaterialCopyCreateDialog";

import defineConf from "../../config/defineConf";

function EguMstMaterialCopyCreateButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSelectGrid, onSuccess, ...others} = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if(onSelectGrid) {
            setFormData(onSelectGrid);
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    const checkShowCallback = (formData, column) => {

        if( parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.HALF_FINISHED_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.RAW_MATERIAL ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.WIP_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.SUBSIDIARY_MATERIAL ) {

            formData.mediumUnitQty = null;
            formData.smallUnitQty = null;
            
            switch(column.name) {
                case "mediumUnitQty" : 
                case "smallUnitQty" : 
                    return false;

                default :
                    return true;
            }
        } else {
            switch(column.name) {
                default :
                return true;
            }
        }
    }

    return (
        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <EguMstMaterialCopyCreateDialog
                id={id+"append-dialog"}
                title={t(`dialog.title.${schema.name}.create`)}
                schema={schema}
                open={open}
                onClose={onClose}
                initialData={formData}
                onSuccess={onCreateSuccess}
                checkShowCallback = {checkShowCallback}
            />
        </>
    );
}

export default EguMstMaterialCopyCreateButton;

