import { useState } from "react";
import { InputAdornment } from "@material-ui/core";

import ColorPickerButton from "../../input/ColorPickerButton";
import FormItemText from "./FormItemText";

function FormItemColor ( props ) {

    const { InputProps, onChange, ...others } = props;

    const [ color, setColor ] = useState ( props.value );

    const onChangeText = ( value ) => {

        setColor ( value );

        if ( onChange ) {

            onChange ( value );

        }

    };

    const onChangeColor = ( value ) => {

        setColor ( value );

        if ( onChange ) {

            onChange ( value );

        }

    };

    return (
        <FormItemText
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <ColorPickerButton value={color} onChange={onChangeColor} />
                    </InputAdornment>                    
                )
            }}
            onChange={onChangeText}
            {...others}
        />
    );

}

export default FormItemColor;

