import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysProductionErrorHistory ( props ) {

    return <BasicCrudScreenType 
                id="MomSysZvwFail"
                view="MomSysZvwFail"
                noExcel
            />

}

export default MomSysProductionErrorHistory;
    