import { DialogTitle } from "@material-ui/core";

function MaterialDialogHeader ( props ) {

    return (
        <DialogTitle {...props} />
    );

}

export default MaterialDialogHeader;