import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";
import uiConf from "../../config/uiConf";
import { useRecoilValue } from "recoil";

import authProfileState from "../../states/authProfileState";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguOutSourcingInputInspectionRegisterDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [outSourcingFakeInputNotInspectedGrid, setOutSourcingFakeInputNotInspectedGrid] = useState();
    const [outSourcingFakeInputNotInspectedSelect, setOutSourcingFakeInputNotInspectedSelect] = useState();
    const [outSourcingFakeInputNotInspectedApi, setOutSourcingFakeInputNotInspectedApi] = useState();

    const authProfile = useRecoilValue(authProfileState);

    if (schema && !schema.columnMap) {
        let columnMap = {};

        for (const column of schema.columns) {
            columnMap[column.name] = column;
        }

        schema.columnMap = columnMap;
    }

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outSourcingFakeInputNotInspectedGrid && mounted) {
                outSourcingFakeInputNotInspectedGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingFakeInputNotInspectedSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingFakeInputNotInspectedSelect();
                        }
                    }, 100)
                );

                outSourcingFakeInputNotInspectedGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingFakeInputNotInspectedSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingFakeInputNotInspectedSelect();
                        }
                    }, 100)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outSourcingFakeInputNotInspectedGrid]);

    useEffect(() => {
        if (outSourcingFakeInputNotInspectedSelect) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    outSourcingFakeInputId: outSourcingFakeInputNotInspectedSelect.outSourcingFakeInputId,
                    outSourcingId: outSourcingFakeInputNotInspectedSelect.outSourcingId,
                    outSourcingName: outSourcingFakeInputNotInspectedSelect.outSourcingName,
                    companyName: outSourcingFakeInputNotInspectedSelect.companyName,

                    outSourcingMaterialCode: outSourcingFakeInputNotInspectedSelect.outSourcingMaterialCode,
                    outSourcingMaterialCodeVer: outSourcingFakeInputNotInspectedSelect.outSourcingMaterialCodeVer,
                    outSourcingMaterialName: outSourcingFakeInputNotInspectedSelect.outSourcingMaterialName,
                    itemSpecName: outSourcingFakeInputNotInspectedSelect.itemSpecName,
                    itemUnitName: outSourcingFakeInputNotInspectedSelect.itemUnitName,

                    outSourcingQty: outSourcingFakeInputNotInspectedSelect.outSourcingQty,
                    outSourcingMediumUnitQty: outSourcingFakeInputNotInspectedSelect.outSourcingMediumUnitQty,
                    outSourcingSmallUnitQty: outSourcingFakeInputNotInspectedSelect.outSourcingSmallUnitQty,

                    fakeInputTotalQty: outSourcingFakeInputNotInspectedSelect.fakeInputTotalQtyoutSourcingQty,
                    fakeInputTotalMediumUnitQty: outSourcingFakeInputNotInspectedSelect.fakeInputTotalMediumUnitQty,
                    fakeInputTotalSmallUnitQty: outSourcingFakeInputNotInspectedSelect.fakeInputTotalSmallUnitQty,

                    fakeInputQty: outSourcingFakeInputNotInspectedSelect.fakeInputQty,
                    fakeInputMediumUnitQty: outSourcingFakeInputNotInspectedSelect.fakeInputMediumUnitQty,
                    fakeInputSmallUnitQty: outSourcingFakeInputNotInspectedSelect.fakeInputSmallUnitQty,

                    fakeInputDate: outSourcingFakeInputNotInspectedSelect.fakeInputDate,

                    inspector: authProfile.username,
                    inspectionDate: new Date(),
                };

                for (const column of schema.columns) {
                    if (column?.defaultValue !== undefined) {
                        newFormData[column.name] = column.defaultValue;
                    }
                }

                setOkButtonDisabled(false);

                return newFormData;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, outSourcingFakeInputNotInspectedSelect]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        if (outSourcingFakeInputNotInspectedApi) {
            outSourcingFakeInputNotInspectedApi.refresh();
        }

        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("edit", column, column.validation, formData[column.name]);

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url;

        url = "/api/EguOutSourcingInputInspection";

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData(null);

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <React.Fragment>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </React.Fragment>
        );
    };

    const onChangeFormData = (formData) => {
        setTimeout(() => {
            setOkButtonDisabled(false);

            setFormData(formData);
        }, 0);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData(null);

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.EguOutSourcingInputInspection.register")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.65}>
                        <BasicCrudScreenType
                            id="EguVwOutSourcingFakeInputNotInspected"
                            view="EguVwOutSourcingFakeInputNotInspected"
                            embededInOtherScreenType
                            headerTitle={t("term.fakeInput")}
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setOutSourcingFakeInputNotInspectedGrid(grid);
                            }}
                            onApiInitialized={(api) => {
                                setOutSourcingFakeInputNotInspectedApi(api);
                            }}
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                    <ReflexElement flex={0.35}>
                        <div style={{ padding: "16px" }}>
                            <AutoForm
                                id="egu-outsourcing-input-inspection-register-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                                modifyFormDataCallback={(formData) => {
                                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                        formData.defectiveCode = "INS000";
                                    }
                                }}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                //labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguOutSourcingInputInspectionRegisterDialog;
