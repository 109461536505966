import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import layoutConf from "../../config/layoutConf";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import EguOutSourcingFakeInputPurchaseDetail from "../screens/EguOutSourcingFakeInputPurchaseDetail";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguOutSourcingFakeInputDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [outSourcingShipNotFakeInputGrid, setOutSourcingShipNotFakeInputGrid] = useState();
    const [outSourcingShipNotFakeInputApi, setOutSourcingShipNotFakeInputApi] = useState();
    const [outSourcingShipNotFakeInputSelect, setOutSourcingShipNotFakeInputSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    if (schema && !schema.columnMap) {
        let columnMap = {};

        for (const column of schema.columns) {
            columnMap[column.name] = column;
        }

        schema.columnMap = columnMap;
    }

    useEffect(() => {
        (async () => {
            if (outSourcingShipNotFakeInputGrid) {
                outSourcingShipNotFakeInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingShipNotFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingShipNotFakeInputSelect([]);
                        }
                    }, 10)
                );

                outSourcingShipNotFakeInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingShipNotFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingShipNotFakeInputSelect([]);
                        }
                    }, 10)
                );
            }
        })();
    }, [outSourcingShipNotFakeInputGrid]);

    useEffect(() => {
        if (outSourcingShipNotFakeInputSelect) {
            let toBeFakeInputQty =
                Number(outSourcingShipNotFakeInputSelect.outSourcingQty) -
                Number(outSourcingShipNotFakeInputSelect.fakeInputTotalQty);

            if (toBeFakeInputQty < 0) {
                toBeFakeInputQty = 0;
            }

            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    outSourcingId: outSourcingShipNotFakeInputSelect.outSourcingId,
                    outSourcingName: outSourcingShipNotFakeInputSelect.outSourcingName,
                    orderBuyerId: outSourcingShipNotFakeInputSelect.orderBuyerId,
                    orderBuyerName: outSourcingShipNotFakeInputSelect.orderBuyerName,
                    companyName: outSourcingShipNotFakeInputSelect.companyName,

                    outSourcingMaterialCode: outSourcingShipNotFakeInputSelect.outSourcingMaterialCode,
                    outSourcingMaterialCodeVer: outSourcingShipNotFakeInputSelect.outSourcingMaterialCodeVer,
                    outSourcingMaterialName: outSourcingShipNotFakeInputSelect.outSourcingMaterialName,

                    materialMediumUnitQty: outSourcingShipNotFakeInputSelect.materialMediumUnitQty,
                    materialSmallUnitQty: outSourcingShipNotFakeInputSelect.materialSmallUnitQty,

                    outSourcingQty: outSourcingShipNotFakeInputSelect.outSourcingQty,
                    outSourcingMediumUnitQty: outSourcingShipNotFakeInputSelect.outSourcingMediumUnitQty,
                    outSourcingSmallUnitQty: outSourcingShipNotFakeInputSelect.outSourcingSmallUnitQty,

                    fakeInputTotalQty: outSourcingShipNotFakeInputSelect.fakeInputTotalQty,
                    fakeInputTotalMediumUnitQty: outSourcingShipNotFakeInputSelect.fakeInputTotalMediumUnitQty,
                    fakeInputTotalSmallUnitQty: outSourcingShipNotFakeInputSelect.fakeInputTotalSmallUnitQty,

                    fakeInputQty: toBeFakeInputQty,
                    fakeInputMediumUnitQty: toBeFakeInputQty * outSourcingShipNotFakeInputSelect.materialMediumUnitQty,
                    fakeInputSmallUnitQty: toBeFakeInputQty * outSourcingShipNotFakeInputSelect.materialSmallUnitQty,

                    fakeInputDate: new Date(),

                    startProcessName: outSourcingShipNotFakeInputSelect.startProcessName,
                    endProcessName: outSourcingShipNotFakeInputSelect.endProcessName,

                    planId: outSourcingShipNotFakeInputSelect.planId,
                    lotNo: outSourcingShipNotFakeInputSelect.lotNo,
                };

                return newFormData;
            });
        }
    }, [outSourcingShipNotFakeInputSelect]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            if (outSourcingShipNotFakeInputApi) {
                outSourcingShipNotFakeInputApi.refresh();
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        /*** 2023/04/28 : OutSourcing Revision : 하나의 outSourcingId에 대해 여러번의 가입고가 가능하도록 수정
        if (formData.fakeInputQty < formData.outSourcingQty) {
            nofifyWarning(t("error.fakeInputQtyCannotBeSmallerThanOutSourcingQty"));
            return false;
        }
        ***/

        // build base api url

        let url;

        url = `/api/${schema.name}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData();

        setOutSourcingShipNotFakeInputSelect();

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };
    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData();

        setOutSourcingShipNotFakeInputSelect();

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.EguOutSourcingFakeInput.create")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.65}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.5}>
                                <BasicCrudScreenType
                                    id="EguVwOutSourcingShipNotFakeInput"
                                    view="EguVwOutSourcingShipNotFakeInput"
                                    headerTitle={t("term.outSourcingShip")}
                                    noExcel
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setOutSourcingShipNotFakeInputGrid(grid);
                                    }}
                                    onApiInitialized={(api) => {
                                        setOutSourcingShipNotFakeInputApi(api);
                                    }}
                                    embededInOtherScreenType
                                />
                            </ReflexElement>

                            <ReflexSplitter />

                            <ReflexElement flex={0.5}>
                                {outSourcingShipNotFakeInputSelect ? (
                                    <EguOutSourcingFakeInputPurchaseDetail
                                        id="EguOutSourcingPurchaseDetail"
                                        onSelectMst={outSourcingShipNotFakeInputSelect}
                                    />
                                ) : (
                                    <></>
                                )}
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                    <ReflexElement flex={0.35}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="outsourcing-fake-input-create-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                                modifyFormDataCallback={(formData, name) => {
                                    if (name === "fakeInputQty") {
                                        const fakeInputQty = Number(formData.fakeInputQty);

                                        formData.fakeInputMediumUnitQty = formData.materialMediumUnitQty * fakeInputQty;
                                        formData.fakeInputSmallUnitQty = formData.materialSmallUnitQty * fakeInputQty;
                                    }
                                }}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} // [okButtonDisabled, cancelButtonDisabled]
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguOutSourcingFakeInputDialog;
