import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function CreateRecordButtonLayout ( props ) {

    const {createButtonName, ...others} = props;

    const { t } = useTranslation();

    return (
        createButtonName
        ?
        <ActionButtonLayout {...others}>{t("buttonName."+ createButtonName)}</ActionButtonLayout>
        :
        <ActionButtonLayout {...others}>{t("buttonName.create")}</ActionButtonLayout>
    );

}

export default CreateRecordButtonLayout;

