import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";
import MomSysBackShipFailureButtonLayout from '../layout/MomSysBackShipFailureButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysBackShipFailureButton (props) {

    const { t } = useTranslation();

    const {id, backShipLotNo, onSelect, grid, refresh, onSuccess, schema, customizeErrorMsgCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [modifiedSchema, setModifiedSchema] = useState();
    
    useEffect(() => {

        if( schema ) {

            let modifiedSchema = cloneDeep(schema);

            let lotNoColumn = {};
            lotNoColumn.name = "lotNo";

            lotNoColumn.formGroup = 0;
            lotNoColumn.formOrder = 1;

            lotNoColumn.type = "text";

            lotNoColumn.readonly = true;
            lotNoColumn.disabled = true;
            
            modifiedSchema.columns.push (lotNoColumn);

            let orderBuyerIdColumn = {};
            orderBuyerIdColumn.name = "orderBuyerId";

            orderBuyerIdColumn.formGroup = 0;
            orderBuyerIdColumn.formOrder = 2;

            orderBuyerIdColumn.type = "text";

            orderBuyerIdColumn.readonly = true;
            orderBuyerIdColumn.disabled = true;
            
            modifiedSchema.columns.push (orderBuyerIdColumn);

            let materialCodeColumn = {};
            materialCodeColumn.name = "materialCode";

            materialCodeColumn.formGroup = 0;
            materialCodeColumn.formOrder = 3;

            materialCodeColumn.type = "text";

            materialCodeColumn.readonly = true;
            materialCodeColumn.disabled = true;
            
            modifiedSchema.columns.push (materialCodeColumn);

            let materialCodeVerColumn = {};
            materialCodeVerColumn.name = "materialCodeVer";

            materialCodeVerColumn.formGroup = 0;
            materialCodeVerColumn.formOrder = 4;

            materialCodeVerColumn.type = "text";

            materialCodeVerColumn.readonly = true;
            materialCodeVerColumn.disabled = true;
            
            modifiedSchema.columns.push (materialCodeVerColumn);

            /* let shipLotNoColumn = {};
            shipLotNoColumn.name = "shipLotNo";

            shipLotNoColumn.formGroup = 0;
            shipLotNoColumn.formOrder = 5;

            shipLotNoColumn.type = "text";

            shipLotNoColumn.readonly = true;
            shipLotNoColumn.disabled = true;
            
            modifiedSchema.columns.push (shipLotNoColumn); */

            let qtyColumn = {};
            qtyColumn.name = "qty";

            qtyColumn.formGroup = 0;
            qtyColumn.formOrder = 6;

            qtyColumn.type = "text";

            qtyColumn.readonly = true;
            qtyColumn.disabled = true;
            
            modifiedSchema.columns.push (qtyColumn);

            /* let memoColumn = {};
            memoColumn.name = "memo";

            memoColumn.formGroup = 0;
            memoColumn.formOrder = 7;

            memoColumn.type = "text";

            memoColumn.readonly = true;
            memoColumn.disabled = true;
            
            modifiedSchema.columns.push (memoColumn); */

            let backShipQty = {};
            backShipQty.name = "backShipQty";

            backShipQty.formGroup = 1;
            backShipQty.formOrder = 0;

            backShipQty.type = "text";
            backShipQty.validation = {
                msg: "error.floatRequired",
                type: "regexp",
                value: "^[+-]?([0-9]*[.])?[0-9]+$",
            };

            backShipQty.required = true;
            
            modifiedSchema.columns.push (backShipQty);

            for (const column of modifiedSchema.columns) {
                
                switch(column.name) {
                    
                    case "shipLotNo" :

                        column.formGroup = 0;
                        column.formOrder = 0;
                        
                        column.disabled = true;
                        column.hideInForm = false;

                    break;

                    case "shipQty" :

                        column.hideInForm = true;
                    break;
                        
                    case "description" :
                        
                        column.required = true;
                        column.hideInForm = false;

                    break;


                    default :
                    break;
                    
                }
            }
            
            setModifiedSchema(modifiedSchema);
        }
            
    },[schema])

    const onOpen = () => {

        setOpen ( true );
    };

    const onClose = () => {

        setOpen ( false );

    };

    const onShipSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
        
        if(refresh) {
            refresh.refresh();

        }
    
      };

    const modifyUrlCallback = (url) => {

        let modifyUrl = `/api/Material/${onSelect.lotNo}/backShip`;

        return modifyUrl;
    }

     const setInitialDataCallback = ( formData ) => {

        if ( !formData ) {

            formData = {};
            
        }
        
        formData.materialCode = onSelect.materialCode;
        formData.materialName = onSelect.materialName;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.orderBuyerId = onSelect.orderBuyerId;
        formData.shipLotNo = onSelect.shipLotNo;
        formData.lotNo = onSelect.lotNo;
        formData.qty = onSelect.shipQty || onSelect.backShipAvailableQty;
        /* formData.memo = onSelect.description; */
        formData.backShipQty = onSelect.shipQty || onSelect.backShipAvailableQty;
    }

    return (
        <>
        <MomSysBackShipFailureButtonLayout id = {id+"-backShip-failure-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"backShip-dialog"}
                mode="create"
                formId={id+"backShip-form"}
                title={schema ? t(`dialog.title.${schema.name}.${'backShip'}`) : ""}
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onShipSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback ={setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    )

}

export default MomSysBackShipFailureButton;