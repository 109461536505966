import { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import EguMaterialFakeInputButton from "../input/EguMaterialFakeInputButton";
import EguMaterialFakeInputDetail from "./EguMaterialFakeInputDetail";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";

function EguMaterialFakeInput(props) {
    const id = "EguMaterialFakeInput";
    const table = "EguMaterialFakeInput";
    const view = "EguVwMaterialFakeInput";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [eguMaterialFakeInputSchema, setEguMaterialFakeInputSchema] = useState();
    const [eguMaterialFakeInputGrid, setEguMaterialFakeInputGrid] = useState();
    const [eguMaterialFakeInputApi, setEguMaterialFakeInputApi] = useState();
    const [eguMaterialFakeInputDataIsEmpty, setEguMaterialFakeInputDataIsEmpty] = useState();
    const [eguMaterialFakeInputSelect, setEguMaterialFakeInputSelect] = useState();
    const [eguMaterialFakeInputDetailDataIsEmpty, setEguMaterialFakeInputDetailDataIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setEguMaterialFakeInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (eguMaterialFakeInputGrid) {
                eguMaterialFakeInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguMaterialFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setEguMaterialFakeInputSelect([]);
                        }
                    },10)
                );

                eguMaterialFakeInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguMaterialFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setEguMaterialFakeInputSelect([]);
                        }
                    },10)
                );
            }
        })();
    }, [eguMaterialFakeInputGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        headerTitle={t("term.fakeInput")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setEguMaterialFakeInputGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguMaterialFakeInputApi(api);
                        }}
                        onLoadData={(data) => {
                            setEguMaterialFakeInputDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noExcel
                        noCreate
                        noEdit
                        noDelete
                        onAppendButtonInFrontOfSearch={() => (
                            <>
                                <BasicCrudScreenActionItemLayout>
                                    <EguMaterialFakeInputButton
                                        id={id}
                                        schema={eguMaterialFakeInputSchema}
                                        refresh={eguMaterialFakeInputApi}
                                        onSuccess={onSuccess}
                                    />

                                    <UpdateRecordButton
                                        id={id}
                                        schema={eguMaterialFakeInputSchema}
                                        grid={eguMaterialFakeInputGrid}
                                        refresh={eguMaterialFakeInputApi}
                                        disabled={eguMaterialFakeInputDataIsEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteSingleRecordButton
                                        id={id}
                                        schema={eguMaterialFakeInputSchema}
                                        grid={eguMaterialFakeInputGrid}
                                        refresh={eguMaterialFakeInputApi}
                                        makeItemName={(row) =>
                                            `${t("columnName.materialFakeInputId")}: ${row.materialFakeInputId}`
                                        }
                                        disabled={eguMaterialFakeInputSelect?.length <= 0 ? true : false || !eguMaterialFakeInputDetailDataIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        color="secondary"
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    {eguMaterialFakeInputSelect ? (
                        <EguMaterialFakeInputDetail
                            onSelectMst={eguMaterialFakeInputSelect}
                            onDetailEmpty={setEguMaterialFakeInputDetailDataIsEmpty}
                        />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguMaterialFakeInput;
