/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import UpdateRecordButton from "../input/UpdateRecordButton";
import CreateRecordButton from "../input/CreateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import getSpecific from "../../lib/api/getSpecific";
import MomSysCodeGenerateCopyButton from "../input/MomSysCodeGenerateCopyButton";

function MomSysCodeGenerate(props) {
    const id = "MomSysCodeGenerate";

    const { t } = useTranslation ();

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();
    const [selectValue, setSelectValue] = useState();
    const [tenantInfo, setTenantInfo] = useState();

    const setNotification = useSetRecoilState(notificationState);
    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysCodeGenerate");
            if(mounted) {
                setSchema(schema);
            }
        }
        async function tenantSetting() {
            const VwTenant = await getSpecific("VwTenant");
            if(mounted) {
                setTenantInfo(VwTenant.data);
            }
        }

        schemaSetting();
        tenantSetting();
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    }
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    }
                });
            }
        })();

    }, [grid]);

    useEffect(() => {

        if(api) {
            api.refresh();
        }

    }, [selectValue])

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onResourceChange = ( event ) => {

        setSelectValue(event.target.value);

    }

    const onModifyViewUrl = ( url, db ) => {

        if(db) {
            return url + "/" + db;
        }

        return url + "/MOM-MASTER";

    }

    const modifyUrlCallback = ( url, db ) => {

        if(db) {
            return url + "?" + db;
        }

        return url + "?MOM-MASTER";

    }

    const onAppendButtonAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={schema && schema.name} accessMethod="GET">
                    <span
                        color="inherit"
                        align="center"
                        style={{
                                fontSize: "14px",
                                marginLeft: "15px",
                                marginTop: "20px",
                                marginRight: "8px",
                        }}
                    >
                        { t("term.dbName") } :
                    </span>

                    <Select
                        id={ "codeGenerate-select-db" }
                        value={selectValue || "MOM-MASTER"}
                        onChange={ onResourceChange }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                square: true
                            }
                        }}

                        style={{
                            fontSize: "14px",
                            width: "100px",
                            textAlign: "center"
                        }}

                    >
                        {
                            tenantInfo?.map ( ( item ) => 
                                <MenuItem 
                                    value={JSON.parse(item.dbConf).connectionInfo.database} 
                                    style={{ fontSize: "14px "}}
                                >
                                    {item.tenantName}
                                </MenuItem>
                            )
                        }
                    </Select>
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        )
    }

    return (
        <BasicCrudScreenType
            id="MomSysCodeGenerate"
            table="MomSysCodeGenerate"
            view="MomSysVwCodeGenerate"
            onApiInitialized={onApiInitialized}
            onInitialized={onInitialized}
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={(url) => onModifyViewUrl(url, selectValue)}
            onAppendButtonAtFirst={onAppendButtonAtFirst}
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <MomSysCodeGenerateCopyButton
                                id={id}
                                grid={grid}
                                refresh={api}
                                disabled={selectGrid ? false : true}
                                onSuccess={notifySuccess}
                                createButtonName={"copy"}
                            />
                        </AccessControl>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <CreateRecordButton
                                id={id}
                                schema={schema}
                                refresh={api}
                                onSuccess={notifySuccess}
                                modifyUrlCallback={(url) => modifyUrlCallback(url, selectValue)}
                            />
                        </AccessControl>
                        <AccessControl
                            accessTarget={schema && schema.name}
                            accessMethod={schema && (schema.updateWithPatch ? "PATCH" : "PUT")}
                        >
                            <UpdateRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                disabled={selectGrid ? false : true}
                                onSuccess={notifySuccess}
                                modifyUrlCallback={(url) => modifyUrlCallback(url, selectValue)}
                                doNotUseKeyAsUrlWhenUpdate={true}
                                />
                        </AccessControl>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                color="secondary"
                                onSuccess={notifySuccess}
                                onFailure={notifyFailure}
                                onPartialFailure={notifyWarning}
                                disabled={selectGrid ? false : true}
                                modifyUrlCallback={(url) => modifyUrlCallback(url, selectValue)}
                            />
                        </AccessControl>
                    </BasicCrudScreenActionItemLayout>
                </>
            )}
        />
    );
}

export default MomSysCodeGenerate;
