import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AutoFilterColumnPickerLayout from "../layout/AutoFilterColumnPickerLayout";
import AutoFilterColumnPickerItemLayout from "../layout/AutoFilterColumnPickerItemLayout";

function AutoFilterColumnPicker ( props ) {

    const { t } = useTranslation ();
    const { columns, className, value, helperText, onChange, ...others } = props;

    const checkboxProps = { color: "primary", ...others };

    const onChangeSelection = ( index, checked ) => {

        let newSelected = [...value];
        
        newSelected[index] = checked;
        
        if ( onChange ) {
            
            onChange ( newSelected );

        }

    };

    return (

        <AutoFilterColumnPickerLayout>
        {

            ( columns && value )
            ?
            columns.filter ( ( column ) => column.filter && !column.filter.hide )
                .map ( ( column, index ) => (

                    <AutoFilterColumnPickerItemLayout key={ column.name }>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ value[index] }
                                    name={ column.name }
                                    size="small"
                                    onChange={
                                        ( e ) => onChangeSelection ( index, e.target.checked )
                                    }
                                    { ...checkboxProps }
                                />
                            }
                            label={ t ( `columnName.${column.displayName||column.name}` ) }
                        />        

                    </AutoFilterColumnPickerItemLayout>
                    
                ) )
            :
            <></>

        }
        </AutoFilterColumnPickerLayout>

    );

}

export default AutoFilterColumnPicker;

