/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddMaterialButtonLayout from "../layout/AddMaterialButtonLayout";
import AddBomMaterialDialog from "../dialogTypes/MomSysAddBomMaterialDialog";

function AddMaterialButton ( props ) {

    const { t } = useTranslation();
    const { id, schema, process, refresh, onSuccess, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            refresh.refresh ();
            onSuccess ( msg );

        }

    };

    return (

        <>
            <AddMaterialButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <AddBomMaterialDialog
                id={id+"append-dialog"}
                title={ t ( "dialog.title.MomSysUdtBillOfMaterial.create" ) }
                schema={schema}
                process={process}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default AddMaterialButton;

