/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import layoutConf from "../../config/layoutConf";
import MomSysStockMoveCreateItem from "./MomSysStockMoveCreateItem";
import MomSysStockMoveCreateItemDetail from "./MomSysStockMoveCreateItemDetail";
import DialogActionButton from "../dialog/functional/DialogActionButton";
import ConfirmDialog from "./ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function MomSysStockMoveCreateDialog(props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [selectedItemGrid, setSelectedItemGrid] = useState({});
    const [selectedItemDetailGrid, setSelectedItemDetailGrid] = useState({});

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const MOVETYPE = {
        IMMEDIATELY: 'immediately',
        REGISTRATION: 'registration',
        OVERWRITE: 'overwrite'
    }

    useEffect(() => {
        Object.freeze(MOVETYPE)
    }, [])

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setOpen(true);

        setConfirmMsg(t("dialog.msg.confirm.overwriteWareHouseMove"));
        setConfirmData(formData);

        setOpen(true);
    };

    const onCloseConfirm = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (selectedItemDetailGrid) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,
                    lotNo: selectedItemDetailGrid.lotNo,
                    extLotNo: selectedItemDetailGrid.extLotNo,
                    materialCode: selectedItemDetailGrid.materialCode,
                    materialCodeVer: selectedItemDetailGrid.materialCodeVer,
                    orderBuyerId: selectedItemDetailGrid.orderBuyerId,
                    materialName: selectedItemDetailGrid.materialName,
                    abbreviations: selectedItemDetailGrid.abbreviations,
                    itemSpecName: selectedItemDetailGrid.itemSpecName,
                    itemUnitName: selectedItemDetailGrid.itemUnitName,
                    qtyQueued: selectedItemDetailGrid.qtyQueued,
                    mediumUnitQty: selectedItemDetailGrid.mediumUnitQty,
                    smallUnitQty: selectedItemDetailGrid.smallUnitQty,
                    manufacturedDate: selectedItemDetailGrid.manufacturedDate,
                    expirationDate: selectedItemDetailGrid.expirationDate,
                    beforeWareHouseCode: selectedItemDetailGrid.wareHouseCode,
                    beforeWareHouseName: selectedItemDetailGrid.wareHouseName,
                    wareHouseName: selectedItemDetailGrid.wareHouseName,
                    orderPurchaseId: selectedItemDetailGrid.orderPurchaseId,
                    workState: selectedItemDetailGrid.workState,
                    jaHo: selectedItemDetailGrid.jaHo,
                    lineNo: selectedItemDetailGrid.lineNo,
                    qty: selectedItemDetailGrid.qtyQueued,
                    moveDate: new Date()
                }
                return newFormData;
            });
        }
    }, [selectedItemDetailGrid]);

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }
        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        console.log(result)
        
        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout(onSuccess(result.data.message), 0);
        }
    };


    const onApiFailure = (reason) => {
        console.log(reason.response)
        if (reason?.response?.data?.recheck) {
            onOpen();
            notifyFailure(t(reason.response.data.message));
            return;
        }

        notifyFailure(t(reason.response.data));
    };

    const onOk = (schema, formData, moveType) => {
        save(schema, formData, moveType);
    }

    function save(schema, formData, moveType) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        let afterWareHouseCode = formData.afterWareHouseCode.split(', ');

        if(afterWareHouseCode[0] === formData.beforeWareHouseCode) {
            nofifyWarning(t("warning.doNotMoveSameWareHouse"));
            return;
        }

        if(formData.qtyQueued < formData.qty){
            nofifyWarning(t("warning.notEnoughQuantity"));
            return;
        }
        
        let url = "/api/MomSysStockMove";
        let apiData = { ...formData };

        let form = new FormData();
        let json = {};
        let deleted = {};

        if (schema) {
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);
        json.move = moveType;
        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
            return key;
        }
    }

    function notUndefined(apiData, key) {
        if (apiData !== undefined) {
            return key;
        }
    }

    const onCancel = () => {
        setFormData((formData) => {
            let newFormData = {
                ...formData
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
                <DialogActionButton data-cy="CancelButton" primary onClick={() => onOk(schema, formData, MOVETYPE.IMMEDIATELY)} {...others}>{t("buttonName.warehouseMove")}</DialogActionButton>
            </>
        )
    }

    const onChange = (formData) => {
        setFormData(formData);
        setInteraction(true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({ ...notification, open: false });
        });
    };

    const onCloseDialog = () => {
        setInteraction(false);
        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        if (onClose) {
            onClose();
        }
    }

    const masterGridSetting = (data) => {
        setSelectedItemGrid(data);
    }

    const detailGridSetting = (data) => {
        setSelectedItemDetailGrid(data);
    }

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {t("dialog.title.MomSysStockMove.create")}
                    </DialogTitle>
                </DialogHeaderWithClose>
                <Divider />
                <DialogContent style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement data-cy="master">
                                    <MomSysStockMoveCreateItem
                                        masterGridSetting={masterGridSetting}
                                    />
                                </ReflexElement>
                                <ReflexSplitter style={{ height: layoutConf.reflex.splitterWidth }} />
                                <ReflexElement data-cy="detail">
                                    <MomSysStockMoveCreateItemDetail
                                        selectedItemGrid={selectedItemGrid}
                                        detailGridSetting={detailGridSetting}
                                    />

                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />
                        <ReflexElement flex={0.35}>
                            <div
                                style={{
                                    padding: "16px"
                                }}
                            >
                                <AutoForm
                                    id="momSysStockMoveCreateDialog"
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChange}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>
                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <Divider />
                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("buttonName.create")]}
                    // buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData, MOVETYPE.REGISTRATION)}
                    onCancel={onCancel}
                />
            </Dialog>

            <ConfirmDialog
                id={id + "-delete-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.wareHouseMove`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onCloseConfirm}
                onOk={() => onOk(schema, formData, MOVETYPE.OVERWRITE)}
                onCancel={onCloseConfirm}
            /></>
    );
}

export default MomSysStockMoveCreateDialog;
