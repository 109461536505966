import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguPopHoldAndReleaseDialog from "../dialogTypes/EguPopHoldAndReleaseDialog";

function EguPopHoldAndReleaseButton(props) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, onSelectMst, onTrigger, ...others } = props;

    const [open, setOpen] = useState(false);

    const [schema, setSchema] = useState();

    useEffect(() => {

        let schema = {

            name: "EguPopProductManage",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {

                    name: "lotNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    required: true,
                    formOrder: 1,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    hideInForm: true,
                    formOrder: 2,
                    formItemType: "text",

                },
                {

                    name: "planId",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 3,
                    formItemType: "text",

                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen(true);
        onTrigger(true)
    };

    const onClose = () => {

        setOpen(false);
        onTrigger(false)
    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    const modifyUrlCallback = (url) => {

        if (createButtonName === "holdProcessing") {

            url = `${url}/${onSelectMst.resultLotNo}/hold`

        } else {

            url = `${url}/${onSelectMst.resultLotNo}/release`

        }

        return url;

    }

    return (

        <>
            <CreateRecordButtonLayout id={`${id}-${(createButtonName === "holdProcessing" ? "hold" : "release")}-button`} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <EguPopHoldAndReleaseDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={createButtonName === "holdProcessing" ? t(`dialog.title.Produce.holdProcess`) : t(`dialog.title.Produce.releaseHoldProcess`)}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyUrlCallback={modifyUrlCallback}
                onSelectMst={onSelectMst}
            />
        </>

    );
}

export default EguPopHoldAndReleaseButton;

