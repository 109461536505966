import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguMaterialFakeInputDetailDialog from "../dialogTypes/EguMaterialFakeInputDetailDialog";

function EguMaterialFakeInputDetailButton(props) {
    const {
        id,
        refresh,
        onSuccess,
        onFailure,
        schema,
        createButtonName,
        fakeInputSelect,
        customizeErrorMsgCallback,
        ...others
    } = props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <EguMaterialFakeInputDetailDialog
                id={id + "-create-dialog"}
                schema={schema}
                fakeInputSelect={fakeInputSelect}
                open={open}
                onClose={onClose}
                onSuccess={onSuccess}
                onFailure={onFailure}
                detailRefresh={refresh}
            />
        </>
    );
}

export default EguMaterialFakeInputDetailButton;
