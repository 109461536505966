import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useSetRecoilState } from "recoil";
import layoutConf from "../../config/layoutConf";
import notificationState from "../../states/notificationState";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";
import EguOutSourcingShipButton from "../input/EguOutSourcingShipButton";
import EguOutSourcingShipCancelButton from "../input/EguOutSourcingShipCancelButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import EguOutSourcingShipDetail from "./EguOutSourcingShipDetail";
import getSpecific from "../../lib/api/getSpecific";
import SaveToExcelButton from "../input/SaveToExcelButton";
import { CellType } from "@grapecity/wijmo.grid";
import { debounce } from "lodash";
import defineConf from "../../config/defineConf";

function EguOutSourcingShip(props) {
    const id = "EguOutSourcingShip";
    const table = "EguOutSourcingShip";
    const view = "EguVwOutSourcingShip";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [eguOutSourcingShipSchema, setEguOutSourcingShipSchema] = useState();

    const [eguOutSourcingShipGrid, setEguOutSourcingShipGrid] = useState();
    const [eguOutSourcingShipApi, setEguOutSourcingShipApi] = useState();
    const [eguOutSourcingShipData, setEguOutSourcingShipData] = useState();
    const [eguOutSourcingShipDataIsEmpty, setEguOutSourcingShipDataIsEmpty] = useState();
    const [eguOutSourcingShipSelect, setEguOutSourcingShipSelect] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (eguOutSourcingShipGrid) {
                eguOutSourcingShipGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingShipSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingShipSelect([]);
                        }
                    }, 10)
                );

                eguOutSourcingShipGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingShipSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingShipSelect([]);
                        }
                    }, 10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eguOutSourcingShipGrid]);

    const onUpdateMaster = () => {
        if (eguOutSourcingShipGrid) {
            (async () => {
                const updateTarget = eguOutSourcingShipGrid.selectedItems[0];

                const eguVwOutSourcingShipTuple = await getSpecific(
                    "EguVwOutSourcingShip",
                    eguOutSourcingShipSelect.outSourcingShipId
                );

                updateTarget.outSourcingStatus = eguVwOutSourcingShipTuple.outSourcingStatus;

                eguOutSourcingShipGrid.refresh(false); // grid.refresh(false) : content update only, grid에 즉시 반영
            })();
        }
    };

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setEguOutSourcingShipSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (msg) => {
        notifyFailure(msg.response ? msg.response.data : msg);
    };

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (eguOutSourcingShipData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (eguOutSourcingShipData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        headerTitle={t("term.outSourcingShip")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setEguOutSourcingShipGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguOutSourcingShipApi(api);
                        }}
                        noCreate
                        noExcel
                        noEdit
                        noDelete
                        onLoadData={(data) => {
                            setEguOutSourcingShipData(data);
                            setEguOutSourcingShipDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <EguOutSourcingShipButton
                                    id={id}
                                    schema={eguOutSourcingShipSchema}
                                    refresh={eguOutSourcingShipApi}
                                    onSuccess={onSuccess}
                                />

                                <EguOutSourcingShipCancelButton
                                    id={id}
                                    schema={eguOutSourcingShipSchema}
                                    grid={eguOutSourcingShipGrid}
                                    refresh={eguOutSourcingShipApi}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    disabled={
                                        eguOutSourcingShipDataIsEmpty ||
                                        eguOutSourcingShipSelect?.outSourcingStatus !==
                                            defineConf.outSourcingStatus.NONE_COMPLETE
                                    }
                                />

                                <SaveToExcelButton
                                    id={id}
                                    grid={eguOutSourcingShipGrid}
                                    view={view}
                                    color="primary"
                                    exportFormatItem={exportFormatItem}
                                    disabled={eguOutSourcingShipDataIsEmpty}
                                />
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4}>
                    {eguOutSourcingShipSelect ? (
                        <EguOutSourcingShipDetail
                            onSelectMst={eguOutSourcingShipSelect}
                            isMstEmpty={eguOutSourcingShipDataIsEmpty}
                            onUpdateMaster={onUpdateMaster}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguOutSourcingShip;
