import DialogActions from "./DialogActions";
import DialogActionButton from "./DialogActionButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { getOption } from "../../../lib/api/getOptions";

function DialogActionsOk ( props ) {

    const { t } = useTranslation ();
    const { label, close, onOk, ...others } = props;

    const [ replaceDialogActionButtonLabel, setReplaceDialogActionButtonLabel ] = useState ( true );

    useEffect ( () => {

        let mounted = true;

        ( async () => {

                let replaceDialogActionButtonLabel = await getOption ( "default", "replaceDialogActionButtonLabel" );

                if ( mounted ) {

                    if ( replaceDialogActionButtonLabel && replaceDialogActionButtonLabel.toLowerCase() === "true" ) {

                        setReplaceDialogActionButtonLabel ( true );
    
                    } else {
    
                        setReplaceDialogActionButtonLabel ( false );
    
                    }
    
                }

                return ( () => {

                    mounted = false;

                } );
                
        } ) ();

    }, [] );

    return (
        <DialogActions {...others} >
            <DialogActionButton primary close={close} onClick={onOk} {...others}>{ ( replaceDialogActionButtonLabel && label ) || t("common.ok")}</DialogActionButton>                    
        </DialogActions>
    );

}

export default DialogActionsOk;

