import { useTranslation } from "react-i18next";
import ActionButtonLayout from "./ActionButtonLayout";

import ExcelIcon from "../presentation/ExcelIcon";

function MomSysStockTakingApplyExcelButtonLayout(props) {
    const { t } = useTranslation();

    const { ...others } = props;

    return (
        <ActionButtonLayout startIcon={<ExcelIcon />} {...others}>
            {t("buttonName.stockApplyExcel")}
        </ActionButtonLayout>
    );
}

export default MomSysStockTakingApplyExcelButtonLayout;
