/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../lib/common/apiClient";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function OrderPurchaseInputConfirmButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, title, refresh, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setConfirmMsg(
            t("dialog.msg.confirm.input", {
                orderPurchaseId: `${grid.selectedItems[0].orderPurchaseId}`,
                orderPurchaseName: `${grid.selectedItems[0].orderPurchaseName}`,
            })
        );
        setConfirmData(grid.selectedItems[0]);

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOk = (requestData) => {
        let url = `/api/${schema.name}/inputConfirm`;

        for (let key of schema.keys) {
            url += `/${grid.selectedItems[0][key]}`;
        }

        apiClient
            .put(url)
            .then((result) => {
                if (refresh) {
                    refresh.refresh();
                }
                onSuccess(t("success.inputConfirm"));
            })
            .catch((reason) => {
                onFailure(t("error.inputConfirmFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    };

    return (
        <>
            <ActionButtonLayout id={id + "-input-confirm-button"} onClick={onOpen} {...others}>
                {t("buttonName.inputConfirm")}
            </ActionButtonLayout>
            <ConfirmDialog
                id={id + "-input-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.inputConfirm`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>
    );
}

export default OrderPurchaseInputConfirmButton;
