import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysBusinessDetailUpdateButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, onSuccess, customizeErrorMsgCallback, title, checkItemValidationCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {

        if ( grid ) {

            setFormData ( {...(grid.selectedItems[0])} );

        }
        
        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUpdateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={ title ? title : schema ? t ( `dialog.title.${schema.name}.update` )  : "" }
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidationCallback}
            />
        </>

    );
}

export default MomSysBusinessDetailUpdateButton;

