import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";


import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";


import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguLaborCostCreateDialog(props) {

    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,
        selectMst,
        initialData,

        modifyFormDataCallback,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,
        maxWidth,
        platform,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,
        isUpdate,

        getFormData,

        ...others
    } = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [initData, setInitData] = useState({});
    const [instance, setInstance] = useState();
    const [newSchema, setNewSchema] = useState(schema);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    useEffect(() => {
        if (initialData) {
            setInitData(initialData)
        }
    }, [initialData])

    useEffect(() => {
        if (schema) {

            let newSchema = cloneDeep(schema);

            setNewSchema(newSchema)

        }
    }, [schema])


    const { t } = useTranslation();

    const onCloseDialog = () => {
        setInitData({});
        setDisabled(true);

        if (onClose) {

            onClose();

        }
    };

    const onCancel = () => {
        setInitData({});
        setDisabled(true);

        instance.hide();

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChange = (formData) => {
        let total = 0;

        let pay = parseInt(formData.pay) || 0
        let bonusPay = parseInt(formData.bonusPay) || 0
        let severancePay = parseInt(formData.severancePay) || 0
        let premium = parseInt(formData.premium) || 0

        total = pay + bonusPay + severancePay + premium

        let directLaborCost = total * parseFloat(formData.directLaborCostPer) / 100;
        let indirectLaborCost = total * parseFloat(formData.indirectLaborCostPer) / 100;
        let researchGrant = total * parseFloat(formData.researchGrantPer) / 100;
        let salesManagementCost = total * parseFloat(formData.salesManagementCostPer) / 100;

        formData.total = total;
        formData.directLaborCost = directLaborCost
        formData.indirectLaborCost = indirectLaborCost
        formData.researchGrant = researchGrant
        formData.salesManagementCost = salesManagementCost

        setDisabled(false)
        setFormData(formData)
        getFormData(formData)
        // setInitData(formData)

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const refreshed = (msg) => {

        notifySuccess(msg);



        if (refresh) {

            refresh.refresh();
        }

    };


    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        refreshed()

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (!keepOpen) {

            instance.hide();


        }

        initialData ? setInitData(initialData) : setInitData({});
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (newSchema, formData) => {

        for (const column of newSchema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {

                notifyWarning(msg);
                return false;

            }
        }

        return true;
    };

    const naNIsZero = (number) => {
        if (isNaN(number)) {
            return 0
        }
        return parseFloat(number);
    }

    const onOk = (newSchema, formData) => {
        let indirectLaborCostPer = naNIsZero(Math.floor(parseFloat(formData.indirectLaborCostPer) * 10000))
        let directLaborCostPer = naNIsZero(Math.floor(parseFloat(formData.directLaborCostPer) * 10000))
        let salesManagementCostPer = naNIsZero(Math.floor(parseFloat(formData.salesManagementCostPer) * 10000))
        let researchGrantPer = naNIsZero(Math.floor(parseFloat(formData.researchGrantPer) * 10000))

        let total = indirectLaborCostPer + directLaborCostPer + salesManagementCostPer + researchGrantPer;

        if (total !== 100 * 10000) {
            notifyWarning(t("warning.totalAddHundredPercent"))
            return;
        }

        if (parseInt(formData.total) === 0) {
            notifyWarning(t("warning.totalIsNotZero"))
            return;
        }

        if (!checkItemValidation(newSchema, formData)) {

            console.error(" From Validation Failed ");

            return;
        }

        if (newSchema.validation) {

            let [valid, msg] = checkConditionWithMsg(formData, newSchema.validation);

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }

        let url = `/api/EguLaborCost`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        if (newSchema && !newSchema.columnMap) { //autoFormDialog columnMap 생성부분 

            let columnMap = {};

            for (const column of newSchema.columns) {

                columnMap[column.name] = column;

            }

            newSchema.columnMap = columnMap;

        }

        for (const formItem in apiData) {

            if (newSchema.columnMap && newSchema.columnMap[formItem] && newSchema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }
            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        if (isUpdate) {
            for (const column of schema.columns) {

                if (column.key === "PRI") {

                    url += "/" + formData[column.name];

                }
            }
            apiClient
                .put(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(reason));
        } else {

            apiClient
                .post(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(reason));
        }

    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                title={title}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                {uiConf.modalWithCloseButton ? (
                    <DialogHeaderWithClose>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeaderWithClose>
                ) : (
                    <DialogHeader>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeader>
                )}

                <DialogContent dividers>
                    <AutoForm
                        id={formId}
                        schema={newSchema}
                        mode='edit'
                        initialData={initData}
                        onChange={onChange}
                    // modifyFormDataCallback={modifyFormDataCallback}
                    />

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(newSchema, formData)}
                    onCancel={onCancel}
                />
            </Dialog>
        </>
    );
}

export default EguLaborCostCreateDialog;
