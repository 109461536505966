import React from "react";
import DialogHeaderLayout from "../layout/DialogHeaderLayout";

function DialogHeader ( props ) {

    const { children, moveable, resizable, ...others } = props;

    return (
        moveable
        ?
        <DialogHeaderLayout classes={{ root: "draggableDialogHeader"}} style={{ cursor: 'move' }}  {...others} >
            {
                children &&
                React.Children.map(children, ( ( child ) => (
                        React.isValidElement ( child )
                        ?
                        React.cloneElement(child, others )
                        :
                        child
                ) ) )
            }
        </DialogHeaderLayout>
        :
        <DialogHeaderLayout {...props}>
            {
                children &&
                React.Children.map(children, ( ( child ) => (
                        React.isValidElement ( child )
                        ?
                        React.cloneElement(child, others )
                        :
                        child
                ) ) )
            }
        </DialogHeaderLayout>
    )
}

export default DialogHeader;
