import { useEffect, useState, useRef } from "react";

function WebSocketClient ( props ) {

    const { url, onOpen, onClose, onError, onMessage, onInitialized } = props;

    const [ , setInstance ] = useState ();

    const ws = useRef ( null );

    useEffect ( () => {

        let instance = {

            connect: () => {

                const location = window.location;

                let wsUrl =  ( ( location.protocol === "https:" ) ? "wss://" : "ws://") + location.hostname;
        
                if ( process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging' ) {
        
                    wsUrl += ':8080';
        
                } else {

                    let parsed = location.origin.split ( ":" );

                    if ( parsed.length > 2 ) {

                        let port = parsed[ 2 ];
                        wsUrl += `:${port}`;
                        
                    }

                }

                wsUrl += `/ws/${url}`;
        
                if ( ! ws.current ) {
                            
                    ws.current = new WebSocket ( wsUrl );
                
                    ws.current.onopen = ( event ) => {
        
                        if ( onOpen ) {
        
                            onOpen ( event );
        
                        }
        
                    };
                    
                    ws.current.onclose = ( error ) => {
        
        
                        if ( onClose ) {
        
                            onClose ( error );
                            ws.current = null;
        
                        }
        
                    };
        
                    ws.current.onerror = ( error ) => {
        
                        if ( onError ) {
        
                            onError ( error );
                            ws.current?.close ();
                            ws.current = null;
                
                        }
        
                    };
        
                    ws.current.onmessage = ( event ) => {
        
                        if ( onMessage ) {
        
                            onMessage ( event );
        
                        }
        
                    };
        
                }
        

            },

            disconnect: () => {

                if ( ws.current ) {
                        
                    ws.current?.close ();
                    ws.current = null;

                }

                /*
                if ( onClose ) {

                    onClose ( "" );

                }
                */

            }

        };

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );

        }

        instance.connect ();

        return ( () => {

            ws.current?.close ()

        } );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    return <></>;

}

export default WebSocketClient;
