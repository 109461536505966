import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

import MomSysShipmentDetailCreateDialog from "../dialogTypes/MomSysShipmentDetailCreateDialog";

function MomSysShipmentDetailCreateButton(props) {
    const { t } = useTranslation();

    const {
        id,
        onSelectMst,
        schema,
        onSuccess,
        createButtonName,
        modifyUrlCallback,
        customizeErrorMsgCallback,
        title,
        grid,
        ...others
    } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <MomSysShipmentDetailCreateDialog
                id={id + "-create-dialog"}
                mode="create"
                formId={id + "-create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                onSelectMst={onSelectMst}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                grid={grid}
            />
        </>
    );
}

export default MomSysShipmentDetailCreateButton;
