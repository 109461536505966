function getUiConf () {

    return (
    
        {

            // form options

            defaultFormMode: "create",
            displayRulerBetweenFormGroup: true,
            displayRulerBetweenFilterFormGroup: true,
            displayFormGroupName: false,
            displayFilterGroupName: false,
            formValidationDelay: 500,  // Form Data 변경 후 Validation 까지의 Delay

            // dialog options

            dialogPlatform: "material-ui",
            dialogMoveable: "true",
            dialogResizable: "false",
            dialogFullWidth: true,
            autoFormDialogMaxWidth: "md",
            confirmDialogMaxWidth: "sm",
            approvalRequestDialogMaxWidth: "sm",
            approvalStateDialogMaxWidth: "sm",
            modalWithCloseButton: true,
            updateWithPatch: false,
            dialogNotifyAutoHideDuration: 6000,

            // notification options

            notificationFetchDuration: 60000,
            notificationSortColumn: "requestTime",
            notificationSortOrder: "desc",
            notificationSortCompare: ( notiA, notiB, columnName, order ) => {

                if ( order.toLowerCase () === "desc" ) {

                    return ( '' + notiB[columnName] ).localeCompare ( notiA[columnName] );

                } else {

                    return ( '' + notiA[columnName] ).localeCompare ( notiB[columnName] );

                }

            },

            messageFetchDuration: 60000,
            messageSortColumn: "sendTime",
            messageSortOrder: "desc",
            messageSortCompare: ( msgA, msgB, columnName, order ) => {

                if ( order.toLowerCase () === "desc" ) {

                    return ( '' + msgB[columnName] ).localeCompare ( msgA[columnName] );

                } else {

                    return ( '' + msgA[columnName] ).localeCompare ( msgB[columnName] );

                }

            },

            // search options

            searchDelay: 500,  // Search Input 변경된 후 적용 까지의 Delay

            // output size options

            outputSizes: [
                20,
                40,
                80,
                100,
                200,
                400
            ],
            defaultOutputSize: 20,

            // report window options

            reportWindowWidth: 800,
            reportWindowHeight: 600,

        }

    );

}

let uiConf = getUiConf();

module.exports = uiConf;
