/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysOrderBuyerDetailButton from "../input/MomSysOrderBuyerDetailButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import { debounce, cloneDeep } from "lodash";
import SaveToExcelButton from "../input/SaveToExcelButton";

function EguUglOrderBuyerDetail(props) {
    const { onSelectMst, onUpdateMaster, onDetailEmpty } = props;

    const id = "EguUglOrderBuyerDetail";
    const table = "MomSysUglOrderBuyerDetail";
    const view = "EguVwOrderBuyerDetail";

    const { t } = useTranslation();

    const [eguUglOrderBuyerDetailSchema, setEguUglOrderBuyerDetailSchema] = useState();
    const [eguUglOrderBuyerDetailUpdateSchema, setEguUglOrderBuyerDetailUpdateSchema] = useState();
    const [eguUglOrderBuyerDetailGrid, setEguUglOrderBuyerDetailGrid] = useState();
    const [eguUglOrderBuyerDetailApi, setEguUglOrderBuyerDetailApi] = useState();
    const [eguUglOrderBuyerDetailDataIsEmpty, setEguUglOrderBuyerDetailDataIsEmpty] = useState();
    const [eguUglOrderBuyerDetailSelect, setEguUglOrderBuyerDetailSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (mounted) {
                const schema = await getSchema(table);

                setEguUglOrderBuyerDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (eguUglOrderBuyerDetailSchema) {
            const updateSchema = cloneDeep(eguUglOrderBuyerDetailSchema);

            if (onSelectMst?.approvalState) {
                for (const column of updateSchema.columns) {
                    switch (column.name) {
                        case "qty":
                        case "mediumUnitQty":
                        case "smallUnitQty":
                            break;

                        default:
                            column.readonly = true;
                            break;
                    }
                }
            } else {
            }

            setEguUglOrderBuyerDetailUpdateSchema(updateSchema);
        }
    }, [eguUglOrderBuyerDetailSchema, onSelectMst]);

    useEffect(() => {
        if (eguUglOrderBuyerDetailApi) {
            eguUglOrderBuyerDetailApi.refresh();
        }
    }, [onSelectMst.orderBuyerId]);

    useEffect(() => {
        (async () => {
            if (eguUglOrderBuyerDetailGrid) {
                eguUglOrderBuyerDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerDetailSelect();
                        }
                    },10)
                );

                eguUglOrderBuyerDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerDetailSelect();
                        }
                    },10)
                );
            }
        })();
    }, [eguUglOrderBuyerDetailGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (eguUglOrderBuyerDetailApi) {
            eguUglOrderBuyerDetailApi.refresh();
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onPartialFailure = (msg) => {
        notifyWarning(msg);

        if (eguUglOrderBuyerDetailApi) {
            eguUglOrderBuyerDetailApi.refresh();
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }
    };

    const onDialogUpdateDetail = () => {
        if (eguUglOrderBuyerDetailApi) {
            eguUglOrderBuyerDetailApi.refresh();
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.soDetail")}
            table={table}
            view={view}
            onModifyViewUrl={(url) => `${url}/${onSelectMst.orderBuyerId}`}
            onInitialized={(grid) => {
                setEguUglOrderBuyerDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setEguUglOrderBuyerDetailApi(api);
            }}
            onLoadData={(data) => {
                const isEmpty = data.length < 1 ? true : false;

                setEguUglOrderBuyerDetailDataIsEmpty(isEmpty);

                if (onDetailEmpty) {
                    onDetailEmpty(isEmpty);
                }
            }}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={eguUglOrderBuyerDetailSchema && eguUglOrderBuyerDetailSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysOrderBuyerDetailButton
                            id={id}
                            schema={eguUglOrderBuyerDetailSchema}
                            onSelectMst={onSelectMst}
                            disabled={onSelectMst.approvalState ? true : false || onSelectMst.length <= 0 ? true : false}
                            onSuccess={onSuccess}
                            onDialogUpdateDetail={onDialogUpdateDetail}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={eguUglOrderBuyerDetailSchema && eguUglOrderBuyerDetailSchema.name}
                        accessMethod={
                            eguUglOrderBuyerDetailSchema &&
                            (eguUglOrderBuyerDetailSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id={id}
                            schema={eguUglOrderBuyerDetailUpdateSchema}
                            grid={eguUglOrderBuyerDetailGrid}
                            disabled={
                                //onSelectMst.approvalState ||
                                eguUglOrderBuyerDetailDataIsEmpty
                            }
                            checkFormValidationCallback={(formData) => {
                                if (String(formData.qty).includes(".")) {
                                    return [false, t("error.invalidQtyNumber")];
                                }

                                return [true, ""];
                            }}
                            modifyFormDataCallback={(formData, name) => {
                                if (eguUglOrderBuyerDetailSelect) {
                                    let numQty = Number(formData.qty);

                                    if (name === "mediumUnitQty") {
                                        numQty =
                                            Number(formData.mediumUnitQty) /
                                            eguUglOrderBuyerDetailSelect.materialMediumUnitQty;
                                    } else if (name === "smallUnitQty") {
                                        numQty =
                                            Number(formData.smallUnitQty) /
                                            eguUglOrderBuyerDetailSelect.materialSmallUnitQty;
                                    }

                                    formData.qty = numQty;
                                    formData.mediumUnitQty =
                                        eguUglOrderBuyerDetailSelect.materialMediumUnitQty * numQty;
                                    formData.smallUnitQty = eguUglOrderBuyerDetailSelect.materialSmallUnitQty * numQty;
                                }
                            }}
                            onSuccess={onSuccess}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={eguUglOrderBuyerDetailSchema && eguUglOrderBuyerDetailSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id={id}
                            schema={eguUglOrderBuyerDetailSchema}
                            grid={eguUglOrderBuyerDetailGrid}
                            color="secondary"
                            disabled={onSelectMst.approvalState || eguUglOrderBuyerDetailDataIsEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onPartialFailure}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={eguUglOrderBuyerDetailSchema && eguUglOrderBuyerDetailSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id={id}
                            grid={eguUglOrderBuyerDetailGrid}
                            view={view}
                            color="primary"
                            exportFormatItem={() => {}}
                            disabled={eguUglOrderBuyerDetailDataIsEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default EguUglOrderBuyerDetail;
