import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import notificationState from "../../states/notificationState";
import apiClient from "../../lib/common/apiClient";

function DbProcess(props) {

    const { t } = useTranslation ();

    const [ msg, setMsg ] = useState ();
    const [ disabled, setDisabled ] = useState ( true );
    const [ , setApi ] = useState ();
    const [ , setProcess ] = useState ();
    const [ , setConfirmDialog ] = useState ();

    const setNotification = useSetRecoilState(notificationState);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {

        grid.selectionChanged.addHandler ( ( grid, event ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );
                setProcess ( grid.selectedItems[0] );

            } else {

                setDisabled ( true );
                setProcess ( null );

            }

        } );

        grid.itemsSourceChanged.addHandler ( (grid, event) => {
 
            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );
                setProcess ( grid.selectedItems[0] );

            } else {

                setDisabled ( true );
                setProcess ( null );

            }

        } );
    
    };

    const onConfirmDialogInitialized = ( dialog ) => {

        setConfirmDialog ( dialog );

    }

    const onClickKillProcess = () => {

        setProcess ( ( process ) => {

            setMsg ( t ( "msg.killProcessConfirm", { id: process.ID } ) );
            setConfirmDialog ( ( dialog ) => {
    
                dialog.show ();
                return dialog;
    
            } );

            return process;

        } );

    };

    const onConfirmed = ( data ) => {

        setProcess ( ( process ) => {

            let id = process.ID;

            apiClient.del ( `/api/DbProcess/${id}` )
                .then((result) =>  {

                    notifySuccess ( t ( "success.killProcessSuccess", { id } ) );

                    setApi ( ( api ) => {

                        api.refresh ();

                        return api;

                    } );

                } )
                .catch((reason) => {

                    notifyFailure ( t ( "error.killProcessFailed", { id } ) );

                } );
            
        } );

    };
    
    return <>

        <BasicCrudScreenType
            id="DbProcess"
            table="VwDbProcess"
            view="VwDbProcess"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonAtLast={ () =>     
                <>
        
                    <BasicCrudScreenActionItemLayout>
        
                        <ActionButtonLayout id="killProcess" disabled={disabled} onClick={onClickKillProcess}>{t("buttonName.killProcess")}</ActionButtonLayout>
        
                    </BasicCrudScreenActionItemLayout>
        
                </>
            }
        />
        <ConfirmDialog
            id={"kill-process-confirm-dialog"}
            title={ t("term.killProcess") }
            msg={msg}
            data={process}
            onOk={ () => onConfirmed () }
            onInitialized={onConfirmDialogInitialized}
        />

    </>;

}

export default DbProcess;
