/* eslint-disable react-hooks/exhaustive-deps */
import {  useState } from "react";

import apiClient from "../../lib/common/apiClient";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function GridActionButton ( props ) {

    const { id, title, method, dialog, setData, setOption, setMsg, setUrl, onClick, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {

        if ( dialog ) {

            if ( onClick ) {

                onClick();
                return;
            }
        }

        if ( setMsg ) {

            setConfirmMsg ( setMsg() );

        }

        if ( setUrl ) {

            setConfirmData ( setUrl() );

        }

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onOk = ( url ) => {

        switch ( method ) {

            case "put":

                apiClient.put ( url, setData ? setData() : undefined, setOption ? setOption() : undefined )
                    .then ( ( result ) => {
        
                        onSuccess ();
        
                    } )
                    .catch ( ( reason ) => {
        
                        console.error ( reason );                
                        onFailure ( reason );
        
                    } );

                break;

            case "patch":

                apiClient.patch ( url, setData ? setData() : undefined, setOption ? setOption() : undefined )
                    .then ( ( result ) => {
        
                        onSuccess ();
        
                    } )
                    .catch ( ( reason ) => {
        
                        console.error ( reason );                
                        onFailure ( reason );
        
                    } );

                break;

            case "delete":

                apiClient.del ( url, setData ? setData() : undefined, setOption ? setOption() : undefined  )
                    .then ( ( result ) => {
        
                        onSuccess ();
        
                    } )
                    .catch ( ( reason ) => {
        
                        console.error ( reason );                
                        onFailure ( reason );
        
                    } );

                break;

            default:

                apiClient.post ( url, setData ? setData() : undefined, setOption ? setOption() : undefined  )
                    .then ( ( result ) => {
        
                        onSuccess ();
        
                    } )
                    .catch ( ( reason ) => {
        
                        console.error ( reason );                
                        onFailure ( reason );
        
                    } );
    
                break;

        }

    };

    return (

        <>
            <ActionButtonLayout id={id+"-button"} onClick={onOpen} {...others} >
                { title }
            </ActionButtonLayout>
            {
                dialog
                ?
                <>
                {
                    dialog ()
                }
                </>
                :
                <ConfirmDialog
                    id={id+"-confirm-dialog"}
                    title={ title }
                    msg={confirmMsg}
                    data={confirmData}
                    open={open}
                    onClose={onClose}
                    onOk={onOk}
                    onCancel={onClose}
                />
            
            }
        </>

    );

}

export default GridActionButton;

