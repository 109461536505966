import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysStockMoveCreateItem(props) {
    const { selectedItemGrid, detailGridSetting } = props;
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const setNotification = useSetRecoilState(notificationState);
    const [selectedItemDetailGrid, setSelectedItemDetailGrid] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        detailGridSetting(grid.selectedItems[0]);
                    } else {
                        detailGridSetting({});
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        detailGridSetting(grid.selectedItems[0]);
                    } else {
                        detailGridSetting({});
                    };
                });
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemGrid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    function modefiedUrl(url) {
        return url + '/' + selectedItemGrid.materialCode + '/' + selectedItemGrid.materialCodeVer;
    }

    return (
        <>
            <BasicCrudScreenType
                id="MomSysVwStockMoveItemDetail"
                table="MomSysVwStockMoveItemDetail"
                view="MomSysVwStockMoveItemDetail"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                onModifyViewUrl = {(url) => `${modefiedUrl(url)}`}
                headerTitle={t("term.itemByLotNo")}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default MomSysStockMoveCreateItem;
