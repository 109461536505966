/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function PopProductShipOfOrderBuyerShipDialog(props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, formData, modifyUrlCallback, ...others
    } = props;

    const  classes = useStyles ();
    const {t} = useTranslation ();

    const [instance, setInstance] = useState ();
    const [, setInteraction] = useState(false);
    const [keepOpen, setKeepOpen] = useState(false);
    const [shipForm, setShipForm] = useState(formData);
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        if(schema) {
            let modifiedSchema = cloneDeep(schema);
            for( const column of modifiedSchema.columns) {
                switch(column.name) {
                    case "createdAt":
                        column.hideInForm = true;
                        break;

                    case "createdBy":
                        column.hideInForm = true;
                        break;

                    case "createdByFullName":
                        column.hideInForm = true;
                        break;

                    case "updatedAt":
                        column.hideInForm = true;
                        break;

                    case "updatedBy":
                        column.hideInForm = true;
                        break;

                    case "updatedByFullName":
                        column.hideInForm = true;
                        break;

                    case "approvalStateId":
                        column.hideInForm = true;
                        break;

                    case "approvalState":
                        column.hideInForm = true;
                        break;

                    case "factoryShipDate":
                        column.hideInForm = true;
                        break;

                    case "cutOff":
                        column.hideInForm = true;
                        break;

                    case "ETDBusan":
                        column.hideInForm = true;
                        break;

                    case "deliveryState":
                        column.hideInForm = true;
                        break;

                    case "description":
                        column.hideInForm = true;
                        break;

                    case "shipmentState":
                        column.hideInForm = true;
                        break;

                    case "shipDueDate":
                        column.hideInForm = true;
                        break;

                    case "shipmentId":
                        column.disabled = true;
                        break;

                    case "shipmentNo":
                        column.disabled = true;
                        break;

                    case "shipmentOrderDate":
                        column.disabled = true;
                        break;
                    
                    case "orderBuyerId":
                        column.disabled = true;
                        break;

                    case "orderBuyerName":
                        column.disabled = true;
                        break;
                        
                    case "companyName":
                        column.disabled = true;
                        break;
                        
                    case "dueDate":
                        column.disabled = true;
                        break;
                    
                    case "shipmentQty":
                        column.disabled = true;
                        break;
                    
                    case "materialCode":
                        column.disabled = true;
                        break;
            
                    case "materialCodeVer":
                        column.disabled = true;
                        break;
        
                    case "materialName":
                        column.disabled = true;
                        break;

                    case "shipDate":
                        column.hideInForm = false;
                        break;
    
                    default :
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [schema]);

    useEffect(() => {
        setShipForm(formData);
    }, [])
    
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                notifyWarning (msg);
                return false;
            }
        }
        return true;
    }

    const notifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        if (onSuccess) {
            onSuccess (t("success.insertSuccess", {table: "MomSysMaterialByCompany"}));
        }

        setKeepOpen ((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout (onSuccess(result.data), 0);
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure (t(reason.response.data));
    };

    const onOk = () => {
        save(schema, formData);
    }

    function save(schema, formData) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                notifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        let url = "/api/MomSysShipDetailLotTemp/popProductShipOk";
        
        if(modifyUrlCallback){
            url = modifyUrlCallback(url);
        }

        let apiData = { ...shipForm };
        let form = new FormData ();
        let json = {};
        let deleted = {};

        if (schema) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);

        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
           return key;
        }
    }

    function notUndefined(apiData, key) {
        if(apiData !== undefined) {
            return key;
        }
    }

    const onCancel = () => {
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>
            </>
        )
    }

    const onChange = (formData) => {
        setShipForm({...formData});
        setInteraction (true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({...notification, open: false});
        });
    };

    const onCloseDialog = () => {
        setInteraction (false);
        
        if ( onClose ) {
            onClose ();
        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.PopProductShipDialog.create" ) }
                </DialogTitle>
            </DialogHeaderWithClose>
        <Divider />
        <DialogContent style={{padding: "0px", height: "700px"}}>
            <div
                style={{
                    padding: "16px"
                }}                    
            >   
                <AutoForm
                    id="popProductShipDialog"
                    schema={modifiedSchema}
                    mode="edit"
                    initialData={shipForm ? formData : shipForm}
                    onChange={onChange}
                />
            </div>
            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>
        </DialogContent>
        <Divider />
        <DialogActionsOkCancel
            firstActionComponent={checkKeepOpen}
            labels={[ t("common.add")]}
            onOk={() => onOk(schema, shipForm)}
            onCancel={onCancel}
        />
        </Dialog>
    );
}

export default PopProductShipOfOrderBuyerShipDialog;

