/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysOrderPurchaseDetailButton from "../input/MomSysOrderPurchaseDetailButton";
import MomSysOrderBuyerPurchaseDetailUpdateButton from "../input/MomSysOrderBuyerPurchaseDetailUpdateButton";
import MomSysBusinessDetailDeleteButton from "../input/MomSysBusinessDetailDeleteButton";
import MomSysPoDetailDeliveredButton from "../input/MomSysPoDetailDeliveredButton";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import SaveToExcelButton from "../input/SaveToExcelButton";
import defineConf from "../../config/defineConf";

function MomSysUglOrderPurchaseDetail ( props ) {

    const {
        id,
        onSelectMst,
        onUpdateMaster,
        onDetailEmpty,
    } = props;

    const { t } = useTranslation ();

    const [ grid, setGrid ] = useState();

    const [ tableSchema, setTableSchema ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const [ onSelectDetail, setOnSelectDetail ] = useState();

    const [ inputState, setInputState ] = useState();

    const [ , setDetailApi ] = useState();

    const [ , setNotification ] = useRecoilState(notificationState);

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler((grid, event) => {

                    if( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectDetail(grid.selectedItems[0])

                        setInputState(grid.selectedItems[0].inputState)

                    } else {

                        setOnSelectDetail([]);

                    };

                });
    
                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectDetail(grid.selectedItems[0])

                        setInputState(grid.selectedItems[0].inputState)
                        
                    } else {

                        setOnSelectDetail([]);

                    };

                });
            }

        } ) ()

    }, [grid] )

    useEffect(() => {

        let mounted = false;

        (async () => {

            let schema = await getSchema("MomSysUglOrderPurchaseDetail");

            if ( mounted ) {

                for (const column of schema.columns) {
    
                    switch (column.name) {
        
                    case "orderPurchaseId":
        
                        column.defaultValue = onSelectMst.orderPurchaseId;
                        column.disabled = true;
        
                        break;
    
                    default:
        
                        break;
                    }
                }
    
                setTableSchema(schema);
    
            }
    
        })();

        return ( () => {
            mounted = false;
        } );

      }, [] );

    useEffect(() => {

        setDetailApi ( ( detailApi ) => {

            if ( detailApi ) {
    
                detailApi.refresh ();
    
            }
    
            return detailApi;

        })

    }, [ onSelectMst.orderPurchaseId ] );

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {
            
            onUpdateMaster ();

        }

    };

    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );
        
        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const notifyWarning = (msg) => {
        
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {
            
            onUpdateMaster ();

        }
        
    };

    const onPartialFailure = ( msg ) => {

        notifyWarning ( msg );

        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };

    const onModifyUrl = ( url, orderPurchaseId ) => {

        return url + "/" + orderPurchaseId;

    };

    const modifyUrlCallback = ( url ) => {

        let modifiedUrl = `${url}/create/${onSelectMst.orderPurchaseId}`;
    
        return modifiedUrl;
    
    };

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onLoadData = (data) => {

        let isEmpty = data.length < 1 ? true : false;

        setIsEmpty(isEmpty);

        onDetailEmpty(isEmpty);

    };

    const onApiInitialized = ( api ) => {

        setDetailApi ( api );

    };

    const onDialogUpdateDetail = () => {

        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

        if ( onUpdateMaster ) {

            onUpdateMaster();

        }

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    
    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">

                    <MomSysOrderPurchaseDetailButton
                        id={id}
                        schema={tableSchema}
                        disabled={onSelectMst.approvalState ? true : false}
                        onSuccess = {onNotifySuccess}
                        modifyUrlCallback = {modifyUrlCallback}
                        onDialogUpdateDetail={onDialogUpdateDetail}
                        onSelectMst = {onSelectMst}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                    <MomSysOrderBuyerPurchaseDetailUpdateButton
                        id = {id}
                        schema = {tableSchema}
                        grid ={grid}
                        disabled={onSelectMst.approvalState ? true : false || isEmpty}
                        onSuccess ={onNotifySuccess}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                    <MomSysBusinessDetailDeleteButton
                        id = {id}
                        schema = {tableSchema}
                        grid = {grid}
                        color = "secondary"
                        disabled = {onSelectMst.approvalState ? true :false || isEmpty}
                        onSuccess = {onNotifySuccess}
                        onFailure={onFailure}
                        onPartialFailure={onPartialFailure}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                    <MomSysPoDetailDeliveredButton
                        id = {id}
                        schema = {tableSchema}
                        grid ={grid}
                        disabled={onSelectMst.approvalState !== defineConf.approvalState.APPROVED ? true : false || isEmpty || inputState }
                        onSuccess ={onNotifySuccess}
                        createButtonName="materialInputInspect"
                        onSelectMst={onSelectMst}
                        onSelectDetail={onSelectDetail}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="SAVETOEXCEL">
                    <SaveToExcelButton
                        id={id}
                        grid={grid}
                        view="MomSysZvwOrderPurchaseDetail" 
                        color="primary"
                        exportFormatItem={() => {}}
                        disabled={isEmpty}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
    
        );
    };

    return (
    
        <BasicCrudScreenType 
            id="MomSysUglOrderPurchaseDetail" 
            headerTitle={t("term.poDetail")}
            table="MomSysUglOrderPurchaseDetail" 
            view="MomSysZvwOrderPurchaseDetail" 
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={( url ) => onModifyUrl ( url, onSelectMst.orderPurchaseId )}
            onInitialized = {onInitialized}
            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={onLoadData}
        />
    
    );

}

export default MomSysUglOrderPurchaseDetail;
