/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import { FormLabel } from "@material-ui/core";
import { cloneDeep } from "lodash";

import AutoFormGroupLayout from "../form/layout/AutoFormGroupLayout";
import AutoFormItemLayout from "../form/layout/AutoFormItemLayout";
import AutoFormLayout from "../form/layout/AutoFormLayout";
import checkCondition from "../../lib/utils/checkCondition";
import AutoFormItem from "../form/functional/AutoFormItem";

const checkIsEmptyValue = (value) => {
    if (value === null || value === undefined || value === "") {
        return true;
    }

    return false;
}


function AutoFormVertical(props) {

    const { t } = useTranslation();
    const { id, schema, mode, initialData, noAutoFocus, onChange, setInitialDataCallback, modifyFormDataCallback, checkShowCallback, checkDisabledCallback, checkValidationCallback, ...others } = props;
    const [formData, setFormData] = useState(initialData);
    const [orderedColumns, setOrderedColumns] = useState([]);
    const [interaction, setInteraction] = useState(false);

    useEffect(() => {

        setFormData((prevFormData) => {

            if (schema) {

                let newFormData = prevFormData ? cloneDeep(prevFormData) : {};

                for (const column of schema.columns) {

                    if (mode === "create") {

                        if (column.formItemType === "file") {

                            newFormData[column.name] = {

                                deletedFiles: [],
                                activeFiles: []

                            }

                        } else {

                            newFormData[column.name] = column.defaultValue;

                        }

                    } else {

                        if (column.password) {

                            newFormData[column.name] = "";

                        }

                    }

                }

                // modify form data with setInitialDataCallback if exist

                if (setInitialDataCallback) {

                    setInitialDataCallback(newFormData);

                }

                return newFormData;

            } else {

                return {};

            }

        });

        let orderedColumns = schema ? schema.columns.slice() : [];

        if (schema) {

            if (schema.useFormOrder) {

                if (schema.formGroups && schema.formGroups.length > 0) {

                    orderedColumns.sort((columnA, columnB) => ((columnA.formGroup ? columnA.formGroup : 0) - (columnB.formGroup ? columnB.formGroup : 0)) ? ((columnA.formGroup ? columnA.formGroup : 0) - (columnB.formGroup ? columnB.formGroup : 0)) : ((columnA.formOrder ? columnA.formOrder : 0) - (columnB.formOrder ? columnB.formOrder : 0)));

                } else {

                    orderedColumns.sort((columnA, columnB) => (columnA.formOrder ? columnA.formOrder : 0) - (columnB.formOrder ? columnB.formOrder : 0));

                }

            }
            else {

                if (schema.formGroups && schema.formGroups.length > 0) {

                    orderedColumns.sort((columnA, columnB) => (columnA.formGroup - columnB.formGroup));

                }
            }

        }

        setOrderedColumns(orderedColumns);

    }, [schema]);

    useEffect(() => {

        if (mode === "edit" && schema) {

            // transform file type form data here

            let formData = initialData ? cloneDeep(initialData) : {};

            for (const column of schema.columns) {

                if (column.formItemType === "file") {

                    if (column.maxFiles === undefined || column.maxFiles > 1) {

                        formData[column.name] = {

                            deletedFiles: [],
                            activeFiles: (formData[column.name] && formData[column.name].length > 0) ? JSON.parse(formData[column.name]).map((url) => ({
                                type: "url",
                                url: url
                            })) : []

                        }

                    } else {

                        formData[column.name] = {

                            deletedFiles: [],
                            activeFiles:
                                (formData[column.name] && formData[column.name].length > 0)
                                    ?
                                    [
                                        {
                                            type: "url",
                                            url: formData[column.name]
                                        }
                                    ]
                                    :
                                    []

                        }
                    }
                }

            }

            setFormData(formData);

        }

    }, [initialData]);

    const onChangeFormItem = (name, value) => {

        setFormData((prevFormData) => {

            let newFormData = { ...prevFormData, [name]: value };

            if (modifyFormDataCallback) {

                modifyFormDataCallback(newFormData, name, value);

            }

            setTimeout(() => onChange(newFormData), 0); // to improve latency

            return newFormData;

        });
        setInteraction(true);

    };

    let tabIndex = 0;

    return (

        <AutoFormLayout {...others} style={{ display: "flex", flexDirection: "row" }}>
            {
                ((schema && schema.formGroups && schema.formGroups.length > 0) ? schema.formGroups : [""])
                    .map((formGroupName, index) => {

                        return (

                            <div key={index} style={index === 0 ? { width: "33%" } : { width: "100%" }}>

                                {/* {index === 0 ? <></> : (uiConf.displayRulerBetweenFormGroup ? <hr></hr> : <></>)} Horizontal Ruler between Form Groups */}
                                {schema.isFormGroupLabel || (uiConf.displayFormGroupName && formGroupName !== "") ? <FormLabel component="legend">{t(formGroupName)}</FormLabel> : <></>} {/* FormGroup Name */}

                                <AutoFormGroupLayout  {...others} style={index === 0 ? { paddingRight: "5%" } : { borderLeftWidth: "thin", borderLeftColor: "#d3d3d3", borderLeftStyle: "solid", paddingLeft: "1%" }}>

                                    {
                                        orderedColumns
                                            .filter((column) => {

                                                if (column.hideInForm || (mode === "create" && column.hideOnCreate) || (mode === "edit" && column.hideOnEdit)) {

                                                    return false;

                                                }

                                                if (column.formGroup === undefined) {

                                                    return true;

                                                }

                                                if (column.formGroup !== index) {

                                                    return false;
                                                }

                                                return true;

                                            })
                                            .filter((column) => (checkShowCallback ? checkShowCallback(formData, column) : true))
                                            .map((column) => {

                                                let readonly = column.readonly || column.key === "PRI";
                                                let disabled = column.disabled || (column.disableOn && checkCondition(formData, column.disableOn));

                                                if (!disabled && checkDisabledCallback) {

                                                    disabled = checkDisabledCallback(formData, column);

                                                }

                                                let [valid, helperText] = checkValidation(mode, column, column.validation, checkIsEmptyValue(formData[column.name]) ? "" : formData[column.name]);

                                                // ignore password legth validation when edit mode

                                                if (mode === "edit" && column.password && (formData[column.name] || "") === "") {

                                                    [valid, helperText] = [true, ""];

                                                }

                                                if (valid && checkValidationCallback) {

                                                    [valid, helperText] = checkValidationCallback(formData, column);

                                                }

                                                if (!(disabled || (mode === "edit" && readonly))) {

                                                    tabIndex++;

                                                }

                                                return (

                                                    <AutoFormItemLayout key={column.name} column={column}>

                                                        <AutoFormItem

                                                            id={id + "-" + column.name}
                                                            label={t(`columnName.${column.displayName || column.name}`)}
                                                            name={column.name}

                                                            disabled={disabled || (mode === "edit" && readonly)}

                                                            error={interaction && !valid}
                                                            helperText={interaction && helperText}

                                                            column={column}
                                                            value={formData && (checkIsEmptyValue(formData[column.name]) ? (checkIsEmptyValue(column.defaultValue) ? "" : column.defaultValue) : formData[column.name])}

                                                            onChange={(value) => onChangeFormItem(column.name, value)}

                                                            autoFocus={(tabIndex === 1) && (!noAutoFocus)}

                                                        />

                                                    </AutoFormItemLayout>

                                                );

                                            })

                                    }

                                </AutoFormGroupLayout>

                            </div>

                        );

                    })
            }
        </AutoFormLayout>

    )

}

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguCostInfoCreateDialog(props) {
    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,

        isUpdate,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        appendCheckBox,
        initialData,

        ...others
    } = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [instance, setInstance] = useState();
    const [height, setHeight] = useState(0);


    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });


    useEffect(() => {
        setFormData((formData) => {
            return {
                formData,
                ...initialData
            }
        })
    }, [initialData]);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const { t } = useTranslation();

    const onCloseDialog = () => {

        setDisabled(true);

        setFormData((formData) => {

            let newFormData = {
                ...formData,
                description: undefined,
                shipmentOrderDate: undefined,
            }
            return newFormData
        })

        if (onClose) {
            onClose();
        }
    };

    const onCancel = () => {

        setFormData((formData) => {

            let newFormData = {
                ...formData,
                description: undefined,
                shipmentOrderDate: undefined,
            }
            return newFormData
        })

        setDisabled(true);

        instance.hide();
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChange = (formData) => {
        setFormData(formData);

        setDisabled(false);
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const refreshed = (msg) => {

        notifySuccess(msg);

        if (refresh) {

            refresh.refresh();

        }

        setFormData({})
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        refreshed()
        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (!keepOpen) {

            instance.hide();


        }


    };

    const onApiFailure = (reason) => {

        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response
                    ? reason.response.data
                    : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation(
                "create",
                column,
                column.validation,
                formData[column.name] || ""
            );

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning(
                    t("warning.formDataValidationFail", { msg: msg })
                );
                return;
            }
        }

        let url = `/api/EguCostInfoMgmt`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        if (isUpdate) {
            for (const column of schema.columns) {

                if (column.key === "PRI") {

                    url += "/" + formData[column.name];

                }
            }
            apiClient
                .put(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(reason));
        }

        else {

            apiClient
                .post(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(reason));
        }
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() =>
                                setKeepOpen((keepOpen) => !keepOpen)
                            }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={
                        <span className={classes.label}>
                            {t("term.keepDialogOpen")}
                        </span>
                    }
                />

                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent style={{ height: height }}>
                    <div
                        style={{
                            padding: "32px",
                        }}
                    >
                        <AutoFormVertical
                            id={`${schema?.name}create-form`}
                            schema={schema}
                            mode="edit"
                            initialData={formData}
                            onChange={onChange}
                        />
                    </div>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    // buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default EguCostInfoCreateDialog;
