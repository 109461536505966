import { useState } from "react";
import { useTranslation } from "react-i18next";

import uiConf from "../../config/uiConf";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import EstimateDownloadButtonLayout from "../layout/EstimateDownloadButtonLayout";
import apiClient from "../../lib/common/apiClient";

function EstimateFileDownloadButton(props) {
    const { id, estimateNumber, versionMajor, versionMinor, ...others } = props;

    const { t } = useTranslation();

    const url = `/api/EstimateFileDownload/${estimateNumber}/${versionMajor}/${versionMinor}`;
    const targetFileName = `견적서_${estimateNumber}_v${versionMajor}.xlsx`;

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const onSuccess = (result) => {
        setNotification({
            severity: "success",
            msg: t("success.fileDownloadSuccess"),
            open: true,
        });
    };
    const onFailure = (reason) => {
        setNotification({
            severity: "error",
            msg: t("error.fileDownloadFailure", { reason: reason.response ? reason.response.data : reason }),
            open: true,
        });
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const doHandleFileStream = (result) => {
        const blob = new Blob([result.data]);

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const fileDownload = () => {
        apiClient
            .get(url, { responseType: "blob" }) // Note : responseType := "blob" !!
            .then((result) => {
                doHandleFileStream(result);
                onSuccess(result);
            })
            .catch((reason) => onFailure(reason));
    };

    return (
        <>
            <EstimateDownloadButtonLayout
                id={id + "estimate-file-download-button"}
                onClick={fileDownload}
                {...others}
            ></EstimateDownloadButtonLayout>
            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                    {notification.msg}
                </Alert>
            </Snackbar>
        </>
    );
}

export default EstimateFileDownloadButton;
