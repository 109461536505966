import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import AutoForm from "../form/functional/AutoForm";
import { debounce } from 'lodash';

const getNextDayDate = (date) => {
    return new Date(new Date(date).getTime() + 24 * 3600 * 1000);
};

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysShipmentCreateDialog(props) {
    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        appendCheckBox,

        ...others
    } = props;

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [shipmentAll, setShipmentAll] = useState(false);

    const [momSysVwPreShipmentGrid, setMomSysVwPreShipmentGrid] = useState();
    const [momSysVwPreShipmentApi, setMomSysVwPreShipmentApi] = useState();
    const [momSysVwPreShipmentSelect, setMomSysVwPreShipmentSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysVwPreShipmentGrid) {
                momSysVwPreShipmentGrid.selectionChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMomSysVwPreShipmentSelect(grid.selectedItems[0]);
                    }
                },10));

                momSysVwPreShipmentGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMomSysVwPreShipmentSelect(grid.selectedItems[0]);
                    }
                },10));
            }
        })();
    }, [momSysVwPreShipmentGrid]);

    useEffect(() => {
        if (momSysVwPreShipmentSelect) {
            (async () => {
                setFormData((formData) => {
                    const newFormData = {
                        ...formData,

                        shipmentOrderDate: new Date(),
                        shipDueDate: getNextDayDate(new Date()),

                        orderBuyerId: momSysVwPreShipmentSelect.orderBuyerId,
                        orderBuyerName: momSysVwPreShipmentSelect.orderBuyerName,

                        dueDate: momSysVwPreShipmentSelect.dueDate,
                        factoryShipDate: momSysVwPreShipmentSelect.factoryShipDate,
                        cutOff: momSysVwPreShipmentSelect.cutOff,
                        ETDBusan: momSysVwPreShipmentSelect.ETDBusan,

                        companyName: momSysVwPreShipmentSelect.companyName,
                    };

                    for (const column of schema.columns) {
                        if (column?.defaultValue !== undefined) {
                            newFormData[column.name] = column.defaultValue;
                        }
                    }

                    return newFormData;
                });

                setOkButtonDisabled(false); // 일단 아무것도 입력 안해도 "OK"할 수 있도록 함
            })();
        }
    }, [momSysVwPreShipmentSelect, schema]);

    const { t } = useTranslation();

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    const onCancel = () => {
        dialogInstance.hide();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const refreshed = (msg) => {
        notifySuccess(msg);

        if (momSysVwPreShipmentApi) {
            momSysVwPreShipmentApi.refresh();
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        if (shipmentAll) {
            let url = `/api/MomSysShipmentDetail/all/${momSysVwPreShipmentSelect.orderBuyerId}`;
            apiClient
                .post(
                    url,
                    { shipmentId: result.data.shipmentId },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then(() => {
                    apiClient
                        .patch(`/api/MomSysShipmentDetail/shipmentState/${momSysVwPreShipmentSelect.orderBuyerId}`)
                        .then(() => {
                            dialogInstance.hide();

                            if (onSuccess) {
                                setTimeout(onSuccess(result.data.message), 0);
                            }
                        })
                        .catch((reason) => onApiFailure(reason));
                });
        } else {
            refreshed();
            if (onSuccess) {
                setTimeout(onSuccess(result.data.message), 0);
            }
            if (!keepOpen) {
                dialogInstance.hide();
            }
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        const url = `/api/${schema.name}`;

        const apiData = { ...formData };

        const form = new FormData();

        const json = {};
        const deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />

                {appendCheckBox ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.keepOpenSwitch}
                                checked={shipmentAll}
                                color="primary"
                                onChange={() => setShipmentAll((shipmentAll) => !shipmentAll)}
                                name="checkKeepOpen"
                                size="small"
                            />
                        }
                        label={<span className={classes.label}>{t("term.shipmentAll")}</span>}
                    />
                ) : (
                    <></>
                )}
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <BasicCrudScreenType
                                id={formId}
                                embededInOtherScreenType
                                headerTitle={t("tableName.MomSysUglOrderBuyerMst")}
                                view="MomSysVwPreShipment"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={(grid) => {
                                    grid.selectionMode = "Row";
                                    setMomSysVwPreShipmentGrid(grid);
                                }}
                                onApiInitialized={(api) => {
                                    setMomSysVwPreShipmentApi(api);
                                }}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                        <ReflexElement>
                            <div
                                style={{
                                    padding: "16px",
                                }}
                            >
                                <AutoForm
                                    id={`${schema.name}create-form`}
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChangeFormData}
                                    modifyFormDataCallback={(formData, name) => {
                                        if (name === "shipmentOrderDate") {
                                            formData.shipDueDate = getNextDayDate(formData.shipmentOrderDate);
                                        }
                                    }}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysShipmentCreateDialog;
