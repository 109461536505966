import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSpecific from '../../lib/api/getSpecific';

function CreatePoButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, ...others } = props;

    const [ formData, setFormData ] = useState();

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    const modifyFormDataCallback = async ( newFormData, name, value ) => {

        if ( name === 'basePoId' && value !== '' ) {
            let apiResult = await getSpecific ( "Po", value );
    
            newFormData.poName = apiResult.poName;
            newFormData.vendorId = apiResult.vendorId;
            newFormData.description = apiResult.description;

            setFormData(newFormData)
        }

    }

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.create` ) : "" }
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyFormDataCallback={modifyFormDataCallback}
            />
        </>

    );
}

export default CreatePoButton;

