/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import MomSysDirectShipDetailLot from "./MomSysDirectShipDetailLot";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysDirectShipDetailCreateButton from "../input/MomSysDirectShipDetailCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import { debounce } from "lodash";


function MomSysDirectShipDetail(props) {
    const { onSelectMst, onDetailEmpty, onDetailData } = props;

    const id = "MomSysDirectShipDetail";
    const table = "MomSysDirectShipDetail";
    const view = "MomSysVwDirectShipDetail";

    const { t } = useTranslation();

    const [momSysDirectShipDetailSchema, setMomSysDirectShipDetailSchema] = useState();
    const [momSysDirectShipDetailGrid, setMomSysDirectShipDetailGrid] = useState();
    const [momSysDirectShipDetailApi, setMomSysDirectShipDetailApi] = useState();
    const [momSysDirectShipDetailDataIsEmpty, setMomSysDirectShipDetailDataIsEmpty] = useState();
    const [momSysDirectShipDetailSelect, setMomSysDirectShipDetailSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysDirectShipDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (momSysDirectShipDetailApi) {
            momSysDirectShipDetailApi.refresh();
        }
    }, [onSelectMst?.directShipId, onSelectMst?.shipDate]);

    useEffect(() => {
        (async () => {
            if (momSysDirectShipDetailGrid) {
                momSysDirectShipDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipDetailSelect();
                        }
                    },10)
                );

                momSysDirectShipDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipDetailSelect();
                        }
                    },10)
                );
            }
        })();
    }, [momSysDirectShipDetailGrid]);

    const onUpdateDetail = () => {
        if (momSysDirectShipDetailGrid) {
            const updateTarget = momSysDirectShipDetailGrid.selectedItems[0];

            (async () => {
                const specificData = await getSpecific(`${view}/specific`, updateTarget.directShipDetailId);

                updateTarget.shipQty = specificData.shipQty;
                updateTarget.shipMediumUnitQty = specificData.shipMediumUnitQty;
                updateTarget.shipSmallUnitQty = specificData.shipSmallUnitQty;

                momSysDirectShipDetailGrid.refresh(false); // grid.refresh(false) : grid.content only, grid에 즉시 반영
            })();
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (momSysDirectShipDetailApi) {
            momSysDirectShipDetailApi.refresh();
        }
    };

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={0.6} data-cy="DetailList">
                <BasicCrudScreenType
                    id={id}
                    view={view}
                    headerTitle={t("term.directShipDetail")}
                    onInitialized={(grid) => {
                        grid.selectionMode = "Row";
                        setMomSysDirectShipDetailGrid(grid);
                    }}
                    onApiInitialized={(api) => {
                        setMomSysDirectShipDetailApi(api);
                    }}
                    onLoadData={(data) => {
                        const detailDataEmpty = data.length < 1 ? true : false;

                        setMomSysDirectShipDetailDataIsEmpty(detailDataEmpty);

                        if (onDetailEmpty) {
                            onDetailEmpty(detailDataEmpty);
                        }

                        if (onDetailData) {
                            onDetailData(data);
                        }
                    }}
                    onModifyViewUrl={(url) => `${url}/${onSelectMst?.directShipId}`}
                    noCreate
                    noDelete
                    noEdit
                    noExcel
                    onAppendButtonInFrontOfSearch={() => (
                        <>
                            <BasicCrudScreenActionItemLayout>
                                <MomSysDirectShipDetailCreateButton
                                    id={id}
                                    schema={momSysDirectShipDetailSchema}
                                    onSelectMst={onSelectMst}
                                    onSuccess={onSuccess}
                                    disabled={onSelectMst?.shipDate || onSelectMst?.length <= 0 ? true : false}
                                />

                                <UpdateRecordButton
                                    id={id}
                                    schema={momSysDirectShipDetailSchema}
                                    grid={momSysDirectShipDetailGrid}
                                    disabled={momSysDirectShipDetailDataIsEmpty || onSelectMst?.shipDate}
                                    onSuccess={onSuccess}
                                    modifyFormDataCallback={(formData, name) => {
                                        let numQty = Number(formData.qty);

                                        if (name === "mediumUnitQty") {
                                            numQty = Number(formData.mediumUnitQty) / formData.materialMediumUnitQty;
                                        } else if (name === "smallUnitQty") {
                                            numQty = Number(formData.smallUnitQty) / formData.materialSmallUnitQty;
                                        }

                                        formData.qty = numQty;
                                        formData.mediumUnitQty = formData.materialMediumUnitQty * numQty;
                                        formData.smallUnitQty = formData.materialSmallUnitQty * numQty;
                                    }}
                                />

                                <DeleteSingleRecordButton
                                    id={id}
                                    schema={momSysDirectShipDetailSchema}
                                    grid={momSysDirectShipDetailGrid}
                                    makeItemName={(row) => {
                                        return `${t("columnName.directShipDetailId")}: ${row.directShipDetailId}`;
                                    }}
                                    color="secondary"
                                    disabled={momSysDirectShipDetailDataIsEmpty || onSelectMst?.shipDate}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />
                            </BasicCrudScreenActionItemLayout>
                        </>
                    )}
                />
            </ReflexElement>

            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

            <ReflexElement flex={0.4} data-cy="DetailLot">
                    <MomSysDirectShipDetailLot
                        onSelectMst={onSelectMst}
                        onSelectDetail={momSysDirectShipDetailSelect}
                        onUpdateDetail={onUpdateDetail}
                        momSysDirectShipDetailDataIsEmpty={momSysDirectShipDetailDataIsEmpty}
                    />

            </ReflexElement>
        </ReflexContainer>
    );
}

export default MomSysDirectShipDetail;
