import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from "lodash";
import AutoFormDialog from '../dialogTypes/AutoFormDialog';
import MomSysStockTakingApplyExcelButtonLayout from '../layout/MomSysStockTakingApplyExcelButtonLayout';

function MomSysStockTakingDetailApplyExcelButton ( props ) {
    const {stockTakingId, mstSchema, grid ,onSelect, onSuccess, refresh, ...others} = props;
    const { t } = useTranslation ();
    const [formData, setFormData] = useState();
    const [modifiedSchema, setModifiedSchema] = useState();
    const [ open, setOpen ] = useState(false);

    useEffect(()=> {
        if(mstSchema) {
            let modifiedSchema = cloneDeep(mstSchema);
            for ( const column of modifiedSchema.columns ) {
                switch ( column.name ) {
                    case "stockTakingDoc":
                        column.hideInForm = false;
                        break;
                    
                    default:
                        column.hideInForm = true;
                        break;
                }
            }
            setModifiedSchema(modifiedSchema);
        }
    },[mstSchema]);

    const onOpen = () => {
        if(grid) {
            setFormData ({...(grid.selectedItems[0])});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const modifyUrlCallback = (url) => {
        let modifiedUrl = `${url}?excel`;
        return modifiedUrl;
    }

    const setInitialDataCallback = (formData) => {
        if (!formData) {
            formData = {};
        }

        formData.stockTakingId = onSelect.stockTakingId;
        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
    };

    const onUpdateSuccess = (data) => {
        if (onSuccess) {
            onSuccess(data.message);
        }
        refresh.refresh();
    }
    const doHandleBackendErrorMsg = (reason) => {
        const response = reason.response;
        return `${response.data}`;
    } 

    return (
        <>
            <MomSysStockTakingApplyExcelButtonLayout id = {"stockApplyExcel-create-button"} onClick = {onOpen} {...others} />
            <AutoFormDialog
                id={"stockApplyExcel-dialog"}
                mode="create"
                formId={"stockApplyExcel-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'stockApplyExcel'}`) : ""}
                schema = {modifiedSchema}
                initialData={formData}
                open={open}
                onSuccess={onUpdateSuccess}
                onClose={onClose}
                setInitialDataCallback ={setInitialDataCallback}
                modifyUrlCallback = {modifyUrlCallback}
                customizeErrorMsgCallback = {doHandleBackendErrorMsg}
            />
        </>
    );
}

export default MomSysStockTakingDetailApplyExcelButton;

