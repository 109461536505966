import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import EguLaborCostCreateDialog from "../dialogTypes/EguLaborCostCreateDialog";

function EguLaborCostUpdateButton(props) {

    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {

        if (grid) {

            setFormData({ ...(grid.selectedItems[0]) });

        }

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onUpdateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }
        if (refresh) {

            refresh.refresh();
        }

        setTimeout(() => {
            if (grid) {

                setFormData({ ...(grid.selectedItems[0]) });

            }
        }, 100)

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <EguLaborCostCreateDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                isUpdate={true}
            />
        </>

    );
}

export default EguLaborCostUpdateButton;

