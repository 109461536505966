import { useTranslation } from 'react-i18next';

import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function BasicTabScreenType ( props ) {

    const { t } = useTranslation();

    const { children, tabs, value, tabLabels, toolbarStyle, tabTextColor, tabCyData, tabsTextColor, tabsStyle, notSelectedTabSpanStyle, selectedTabSpanStyle, scrollButtons, tabsVariant, selectedTabStyle, selectedTabStyles, notSelectedTabStyle, notSelectedTabStyles, leftGridItems, rightGridItems, onTabChanged } = props;

    const onTabClicked = ( tab ) => {

        if ( onTabChanged ) {

            onTabChanged ( tab );

        }

    };

    return (

        <div>

            <Toolbar style={toolbarStyle ?? {
                top: "0px",
                margin: "0px",
                padding: "0px",
                marginLeft: "0px",
                height: "48px",
                width: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
                backgroundColor: "rgb(255,255,255)",
                borderBottom: "1px solid rgb(212, 212, 212)",
            } }>

                <Grid container nowrap spacing={1} alignItems="stretch" style={{ height: "48px", width: "100%"}}>
                    <Grid item>
                        <Tabs
                            value={value}
                            textColor={tabsTextColor??"inherit"}
                            variant={tabsVariant??"scrollable"}
                            scrollButtons={scrollButtons??"off"}
                            style={tabsStyle??{
                                left: 0,
                                margin: "0px",
                                padding: "5px",
                                height: "48px",
                                backgroundColor: "rgb(255,255,255)",
                            }}
                        >
                            {
                                tabs.map ( ( tabItem, index ) =>
                                    <Tab
                                        className={` classes.selectedTab : classes.notSelectedTab}`}
                                        textColor={tabTextColor?tabTextColor:"primary"}
                                        data-cy={tabCyData?tabCyData[index]:`tab-${tabItem}`}
                                        onClick={()=>onTabClicked(tabItem)}
                                        style={
                                            tabItem === value
                                            ?
                                            (
                                                selectedTabStyles
                                                ?
                                                selectedTabStyles[index]
                                                :
                                                (
                                                    selectedTabStyle
                                                    ?
                                                    selectedTabStyle
                                                    :
                                                    {
                                                        borderTop: "0px solid rgb(128, 128, 128)",
                                                        borderLeft: "0px solid rgb(128, 128, 128)",
                                                        borderRight: "0px solid rgb(128, 128, 128)",
                                                        borderRadius: "10px 10px 0px 0px",
                                                        margin: "0px",
                                                        marginTop: "2px",
                                                        width: "150px",
                                                        minHeight: "42px",
                                                        opacity: 1,
                                                        backgroundColor: "#7280ce"
                                                    }                                                    
                                                )

                                            )
                                            :
                                            (
                                                notSelectedTabStyles
                                                ?
                                                notSelectedTabStyles[index]
                                                :
                                                (
                                                    notSelectedTabStyle
                                                    ?
                                                    notSelectedTabStyle
                                                    :
                                                    {
                                                        margin: "0px",
                                                        marginTop: "2px",
                                                        padding: "0px",
                                                        width: "150px",
                                                        minHeight: "42px",
                                                    }                                                    
                                                )
                                            )                                            
                                        }
                                        label={
                                            <span
                                                style={
                                                    tabItem === value
                                                    ?
                                                    (
                                                        selectedTabSpanStyle
                                                        ?
                                                        selectedTabSpanStyle
                                                        :
                                                        {
                                                            color: "white"
                                                        }
                                                    )
                                                    :
                                                    (
                                                        notSelectedTabSpanStyle
                                                        ?
                                                        notSelectedTabSpanStyle
                                                        :
                                                        {
                                                            color: "black"
                                                        }
                                                    )                                                    
                                                }
                                            >
                                                {
                                                    tabLabels
                                                    ?
                                                    t(tabLabels[index])
                                                    :tabItem
                                                }
                                            </span>
                                        }
                                    />
                                )
                            }
                        </Tabs>
                    </Grid>
                    {
                        leftGridItems
                        ?
                        leftGridItems.map ( ( gridItem ) => gridItem )
                        :
                        <Grid item>
                            <span></span>
                        </Grid>
                    }
                    <Grid item xs>
                        <span></span>
                    </Grid>
                    {
                        rightGridItems
                        ?
                        rightGridItems.map ( ( gridItem ) => gridItem )
                        :
                        <Grid item>
                            <span></span>
                        </Grid>
                    }
                </Grid>

            </Toolbar>

            {children}
            
        </div>

    );

}

export default BasicTabScreenType;