import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import { useTranslation } from "react-i18next";
import getExpirationDate from "../../lib/utils/getExpirationDate";

function PopMaterialFakeInputButton(props) {
    const {
        id,
        schema,
        grid,
        refresh,
        onSuccess,
        onFailure,
        createButtonName,
        fakeInputSelect,
        customizeErrorMsgCallback,
        ...others
    } = props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [formData, setFormData] = useState();

    const [oldQty, setOldQty] = useState();
    const [oldPrice, setOldPrice] = useState();

    const onOpen = () => {

        if (grid) {


            let qtyFakeInput = grid.selectedItems[0].qtyOrderPurchase - grid.selectedItems[0].qtyFakeInput;
            qtyFakeInput = qtyFakeInput > 0 ? qtyFakeInput: 0;

            setOldQty(qtyFakeInput);
            setOldPrice(grid.selectedItems[0].unitCost);

            setFormData({ ...grid.selectedItems[0],
                qtyFakeInput : qtyFakeInput,
                fakeInputDate : new Date(),
                manufacturedDate: new Date(),
                expirationDate: getExpirationDate(grid.selectedItems[0].standardExpirationDate, new Date()), 
                price: grid.selectedItems[0].unitCost,
                amount: qtyFakeInput * grid.selectedItems[0].unitCost
            } );
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFakeInputSuccess = (msg) => {

        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const onChangeFormData = (formData) => {
        let qty = formData.qtyFakeInput;
        let price = formData.price;
        if(oldQty !== qty || oldPrice !== price) {
            setOldQty(qty);
            setOldPrice(price);

            setFormData({
                ...formData,
                amount: (formData.qtyFakeInput * formData.price).toFixed(4)
            });
        } else {
            setFormData({...formData});
        }
    }

    return (
        <>
            <CreateRecordButtonLayout id={id + "-create-button"} onClick={onOpen} createButtonName={"fakeInput"}{...others}/>
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="edit"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onFakeInputSuccess}
                onChange={onChangeFormData}
                modifyFormDataCallback={(formData, name) => {
                    if (name === "manufacturedDate") {
                        formData.expirationDate = getExpirationDate(
                            grid.selectedItems[0]?.standardExpirationDate,
                            new Date(formData.manufacturedDate)
                        );
                    }
                }}
            />
        </>
    );
}

export default PopMaterialFakeInputButton;
