//
import * as wjcCore from '@grapecity/wijmo';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import { useCallback, useEffect, useState } from 'react';
import apiClient from '../../lib/common/apiClient';

import style from "../css/tenantFileTemplateExcelPreview.module.css"

const TenantFileTemplateExcelPreview = (props) => {

    const { filePath } = props;
    const _navContainerStyle = {
        marginTop: "40px",
        borderBottom: "1px solid #ddd",
        paddingLeft: "0",
        marginBottom: "0",
        listStyle: "none",
    };

    const [workBook, setWorkBook] = useState(null);
    const [sheetIndex, setSheetIndex] = useState(-1);
    const [lists, setLists] = useState([])

    const _drawWorksheet = useCallback((workbook, sheetIndex, rootElement, maxRows, maxColumns) => {
        //NOTES:
        //Empty cells' values are numeric NaN, format is "General"
        //
        //Excessive empty properties:
        //fill.color = undefined
        //
        // netFormat should return '' for ''. What is 'General'?
        // font.color should start with '#'?
        // Column/row styles are applied to each cell style, this is convenient, but Column/row style info should be kept,
        // for column/row level styling
        // formats conversion is incorrect - dates and virtually everything; netFormat - return array of formats?
        // ?row heights - see hello.xlsx
        if (!workbook || !workbook.sheets || sheetIndex < 0 || workbook.sheets.length === 0) {
            return;
        }
        //
        sheetIndex = Math.min(sheetIndex, workbook.sheets.length - 1);
        //
        if (maxRows == null) {
            maxRows = 200;
        }
        //
        if (maxColumns == null) {
            maxColumns = 100;
        }
        //
        // Namespace and XlsxConverter shortcuts.
        let sheet = workbook.sheets[sheetIndex], defaultRowHeight = 20, defaultColumnWidth = 60, tableEl = document.createElement('table');
        //
        tableEl.border = '1';
        tableEl.style.borderCollapse = 'collapse';
        //
        let maxRowCells = 0;
        for (let r = 0; sheet.rows && r < sheet.rows.length; r++) {
            if (sheet.rows[r] && sheet.rows[r].cells) {
                maxRowCells = Math.max(maxRowCells, sheet.rows[r].cells.length);
            }
        }
        //
        // add columns
        let columns = sheet.columns || [], invisColCnt = columns.filter(col => col.visible === false).length;
        //
        if (sheet.columns) {
            maxRowCells = Math.min(Math.max(maxRowCells, columns.length), maxColumns);
            //
            for (let c = 0; c < maxRowCells; c++) {
                let col = columns[c];
                //
                if (col && !col.visible) {
                    continue;
                }
                //
                let colEl = document.createElement('col');
                tableEl.appendChild(colEl);
                let colWidth = defaultColumnWidth + 'px';
                if (col) {
                    _importStyle(colEl.style, col.style);
                    if (col.autoWidth) {
                        colWidth = '';
                    }
                    else if (col.width != null) {
                        colWidth = col.width + 'px';
                    }
                }
                colEl.style.width = colWidth;
            }
        }
        //
        // generate rows
        let rowCount = Math.min(maxRows, sheet.rows.length);
        for (let r = 0; sheet.rows && r < rowCount; r++) {
            let row = sheet.rows[r], cellsCnt = 0; // including colspan
            //
            if (row && !row.visible) {
                continue;
            }
            //
            let rowEl = document.createElement('tr');
            tableEl.appendChild(rowEl);
            //
            if (row) {
                _importStyle(rowEl.style, row.style);
                if (row.height != null) {
                    rowEl.style.height = row.height + 'px';
                }
                //
                for (let c = 0; row.cells && c < row.cells.length; c++) {
                    let cell = row.cells[c], cellEl = document.createElement('td'), col = columns[c];
                    //
                    if (col && !col.visible) {
                        continue;
                    }
                    //
                    cellsCnt++;
                    //
                    rowEl.appendChild(cellEl);
                    if (cell) {
                        cellEl.style.border = "double";
                        cellEl.style.borderWidth = "1px"
                        _importStyle(cellEl.style, cell.style);
                        let value = cell.value;
                        //
                        // if (!(value == null || value !== value)) { // TBD: check for NaN should be eliminated
                        if (!(value == null)) { // TBD: check for NaN should be eliminated
                            if (wjcCore.isString(value) && value.charAt(0) === "'") {
                                value = value.substr(1);
                            }
                            let netFormat = '';
                            if (cell.style && cell.style.format) {
                                netFormat = wjcXlsx.Workbook.fromXlsxFormat(cell.style.format)[0];
                            }
                            let fmtValue = netFormat ? wjcCore.Globalize.format(value, netFormat) : value;
                            cellEl.innerHTML = wjcCore.escapeHtml(fmtValue);
                        }
                        //
                        if (cell.colSpan && cell.colSpan > 1) {
                            cellEl.colSpan = _getVisColSpan(columns, c, cell.colSpan);
                            cellsCnt += cellEl.colSpan - 1;
                            c += cell.colSpan - 1;
                        }
                        //
                        if (cell.note) {
                            wjcCore.addClass(cellEl, 'cell-note');
                            cellEl.title = cell.note.text;
                        }
                    }
                }
            }
            //
            // pad with empty cells
            let padCellsCount = maxRowCells - cellsCnt - invisColCnt;
            for (let i = 0; i < padCellsCount; i++) {
                rowEl.appendChild(document.createElement('td'));
            }
            //
            if (!rowEl.style.height) {
                rowEl.style.height = defaultRowHeight + 'px';
            }
        }
        //
        // do it at the end for performance
        rootElement.appendChild(tableEl);
    },[])

    const _drawSheet = useCallback((workBook, sheetIndex) => {
        let drawRoot = document.getElementById('tableHost');
        drawRoot.textContent = '';
        setSheetIndex(sheetIndex);
        _drawWorksheet(workBook, sheetIndex, drawRoot, 200, 100);
    },[_drawWorksheet])

    const _loadWorkbook = useCallback((data) => {
        let reader = new FileReader();
        //
        reader.onload = (e) => {
            let workbook = new wjcXlsx.Workbook();
            workbook.loadAsync(reader.result, (result) => {
                _drawSheet(result, result?.activeWorksheet || 0);
                setWorkBook(result)
            });
        };
        //
        // let file = document.getElementById('importFile').files[0];
        if (data) {
            reader.readAsArrayBuffer(data);
        }
    },[_drawSheet])

    useEffect(() => {
        let mount = true;
        if (filePath) {
            (async () => {
                let url;

                if ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {

                    url = filePath

                } else {

                    url = `http://localhost:8080${filePath}`;

                }

                if (url) {

                    apiClient
                        .get(url, { responseType: "blob" })
                        .then((result) => {
                            if (mount) {

                                _loadWorkbook(result?.data);
                            }

                        })
                        .catch(async (reason) => {
                            console.log(reason)
                        })
                }
            })();

            return (() => {

                if (mount) mount = false;
            })
        }
    }, [filePath,_loadWorkbook])

    const _getVisColSpan = (columns, startFrom, colSpan) => {
        let res = colSpan;
        //
        for (let i = startFrom; i < columns.length && i < startFrom + colSpan; i++) {
            let col = columns[i];
            if (col && !col.visible) {
                res--;
            }
        }
        //
        return res;
    }

    const _importStyle = (cssStyle, xlsxStyle) => {

        if (!xlsxStyle) {
            return;
        }
        //
        if (xlsxStyle.fill) {
            if (xlsxStyle.fill.color) {
                cssStyle.backgroundColor = xlsxStyle.fill.color;
            }
        }
        //
        if (xlsxStyle.hAlign && xlsxStyle.hAlign !== wjcXlsx.HAlign.Fill) {
            cssStyle.textAlign = wjcXlsx.HAlign[xlsxStyle.hAlign].toLowerCase();
        }
        //
        let font = xlsxStyle.font;
        if (font) {
            if (font.family) {
                cssStyle.fontFamily = font.family;
            }
            if (font.bold) {
                cssStyle.fontWeight = 'bold';
            }
            if (font.italic) {
                cssStyle.fontStyle = 'italic';
            }
            if (font.size != null) {
                cssStyle.fontSize = font.size + 'px';
            }
            if (font.underline) {
                cssStyle.textDecoration = 'underline';
            }
            if (font.color) {
                cssStyle.color = font.color;
            }
        }
    }

    useEffect(() => {
        if (workBook) {
            console.log('a');
            const lists = workBook.sheets.map((sheet, index) => {
                return <li role="presentation" className={index === sheetIndex ? `${style.nav_list} active` : style.nav_list} >
                    <a className={`${style.nav_list_button}`} href="!#" onClick={e => { e.preventDefault(); _drawSheet(workBook, index); }}>{sheet.name}</a>
                </li>;
            });
            setLists(lists)
        }
    }, [workBook, sheetIndex,_drawSheet])

    return <div className={`${style.container_fluid}`}>
        <ul className={`${style.nav} ${style.nav_tabs}`} style={_navContainerStyle}>
            {lists}
        </ul>

        <div className={style.tableHost} id="tableHost"></div>
    </div>;
}

export default TenantFileTemplateExcelPreview