import TopMenuLayout from "../layout/TopMenuLayout";
import TopMenuItem from "./TopMenuItem";

function TopMenu ( props ) {

    return (
        <TopMenuLayout>
            {
                props.menuItems
                ?
                    props.menuItems.slice().sort( (menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )
                    .map( ( menuItem ) => <TopMenuItem key={menuItem.topMenuItemId} menuItem={menuItem} /> )
                :
                    <></>
            }
        </TopMenuLayout>
    )
}

export default TopMenu;
