import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysOrderBuyerPurchaseDetailUpdateButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, onSuccess, customizeErrorMsgCallback, title, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({
                ...grid.selectedItems[0],
            });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "-update-dialog"}
                mode="edit"
                formId={id + "-update-form"}
                title={title ? title : schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyFormDataCallback={(formData, name) => {
                    if (name === "qty") {
                        const numQty = Number(formData.qty);
                        formData.mediumUnitQty = formData.materialMediumUnitQty * numQty;
                        formData.smallUnitQty = formData.materialSmallUnitQty * numQty;
                    }
                }}
            />
        </>
    );
}

export default MomSysOrderBuyerPurchaseDetailUpdateButton;
