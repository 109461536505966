/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";


// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridSelectColumn ( props ) {

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    const { t } = useTranslation();

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            if ( schema ) {

                let selectMap = {};
                
                for ( const item of schema.selectItems ) {

                    selectMap[item.value] = item.name
 
                }; 

                return (
                    <>
                        <span>{ t ( selectMap[ctx.item[schema.name]] || selectMap[schema.defaultValue] ) }</span>
                    </>
                );

            } else {

                return <></>;

            }

        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridSelectColumn;

