const getExpirationDate = (expirationInterval, date) => {
    if (!expirationInterval) {
        expirationInterval = 6; // default : 6 month
    }

    if (!date) {
        date = new Date();
    }

    date.setMonth(date.getMonth() + expirationInterval);

    return date;
};

export default getExpirationDate;
