import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AccessControl from "../functional/AccessControl";
import MomSysUdtCompanySalesCreateButton from "../input/MomSysUdtCompanySalesCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"

function MomSysUdtCompanySales ( props ) {
    const {id, mstGridData} = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);
    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;

        async function schemaSetting() {
            let schema = await getSchema("MomSysUdtCompanySales");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mstGridData]);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onLoadData = (data) => {
        setIsEmpty(data.length < 1 ? true : false)
    }

    const onModifyUrl = (url) => {
        return `api/MomSysUdtCompanySales/0/${mstGridData.companyCode}`;
    };

    return (
        <>
            <BasicCrudScreenType 
                id={id}
                table="MomSysUdtCompanySales" 
                view="MomSysVwCompanySales"
                headerTitle={t("term.companySales")}
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized} 
                onLoadData={onLoadData}
                onModifyViewUrl={(url) => onModifyUrl(url)}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <MomSysUdtCompanySalesCreateButton
                                id="MomSysUdtCompanySalesCreateButton"
                                schema={schema}
                                refresh={api}
                                mstGridData={mstGridData}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                            <UpdateRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                onSuccess={onSuccess}
                                disabled={isEmpty ? true : false}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={isEmpty ? true : false}
                            />
                        </AccessControl>
                    </>
                    )}
                />
        </>
    );

}

export default MomSysUdtCompanySales;
