/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateMaterialButtonLayout from "../layout/UpdateMaterialButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function UpdateMaterialButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, material, refresh, onSuccess, ...others } = props;

    const [tableSchema, setTableSchema] = useState( schema );
    const [formData, setFormData] = useState( {} );
    const [open, setOpen] = useState(false);

    useEffect ( () => {

        setTableSchema ( schema );

    }, [schema] )

    useEffect ( () => {


        if ( material ) {

            setFormData ( {
                bomMaterialCode: material.bopMaterialCode,
                bomMaterialCodeVer: material.bopMaterialCodeVer,
                bomMaterialName: material.bopMaterialName,
                bomIndex: material.bomIndex,
                processCode: material.processCode,
                processName: material.processName,
                componentCode: material.inOutMaterialCode,
                componentCodeVer: material.inOutMaterialVer,
                componentName: material.inOutMaterialName,
                componentQty: material.componentQty,
                description: material.description,
                inWareHouseCode: material.wareHouseCode,
            } );
    
        } else {

            setFormData ( {} );

        }

    }, [material] );

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUpdateSuccess = ( msg ) => {

        if ( onSuccess ) {

            refresh.refresh ();
            onSuccess ( msg );

        }

    };

    return (

        <>
            <UpdateMaterialButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={ t ( "dialog.title.MomSysUdtBillOfMaterial.update" ) }
                schema={tableSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
            />
        </>

    );
}

export default UpdateMaterialButton;

