import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

function FormItemSelect ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, label, InputLabelProps, onChange, ...others } = props;

    const selectProps = {
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        ...others
    };

    if ( column.displayEmpty ) {

        selectProps.displayEmpty = true;

    }

    if ( column.style ) {

        selectProps.style = column.style;

    }

    return (
        <FormControl className={className} required={column.required} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                { ...selectProps }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
            >
                {
                    column.sortByValue
                    ?
                    column.selectItems
                        .sort ( ( a, b ) => ( '' + a.value ).localeCompare ( b.value ) )
                        .map ( ( item ) => <MenuItem key={item.name} value={ item.value }>{t ( item.name )}</MenuItem> )
                    :
                    column.selectItems
                        .sort ( ( a, b ) => ( '' + a.name ).localeCompare ( b.name ) )
                        .map ( ( item ) => <MenuItem key={item.name} value={ item.value }>{t ( item.name )}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FormItemSelect;
