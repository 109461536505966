import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import MomSysMoveLotButtonLayout from '../layout/MomSysMoveLotButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysMoveLotButton (props) {

    const {id, onSelect, grid ,schema, standard, refresh, onSuccess, customizeErrorMsgCallback, ...others} = props;

    const [modifiedSchema, setModifiedSchema] = useState();

    const [formData, setFormData] = useState();

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);
    
    useEffect ( () => {

        if(schema) {

            let modifiedSchema = cloneDeep(schema);

            let lineNoColumn = {};

            lineNoColumn.name = "lineNo";
            
            lineNoColumn.formGroup = 1;
            lineNoColumn.formOrder = 2;
            lineNoColumn.hideInForm = true;
            lineNoColumn.formItemType = "autocomplete";
            lineNoColumn.codeInfo = {
                tableName : "MomSysUdtLineNo",
                nameColumn : "lineNo",
                valueColumn : "lineNo",
                useEmptyValue: true,
                emptyName: "term.none",
                sortByName : true,
            }
            lineNoColumn.hideInForm=  true;

            modifiedSchema.columns.push (lineNoColumn);
            
            let lotNo = {};
            
            lotNo.name = "lotNo";
            
            lotNo.readonly = true;
            lotNo.disabled = true;
            lotNo.formGroup = 0;
            lotNo.formOrder = 1;
            lotNo.formItemType = "text";
            
            modifiedSchema.columns.push (lotNo);

            let materialCodeColumn = {};
            
            materialCodeColumn.name = "materialCode";
            
            materialCodeColumn.readonly = true;
            materialCodeColumn.disabled = true;
            materialCodeColumn.formGroup = 0;
            materialCodeColumn.formOrder = 3;
            materialCodeColumn.formItemType = "text";
            
            modifiedSchema.columns.push (materialCodeColumn);

            let materialCodeVerColumn = {};
            
            materialCodeVerColumn.name = "materialCodeVer";
            
            materialCodeVerColumn.readonly = true;
            materialCodeVerColumn.disabled = true;
            materialCodeVerColumn.formGroup = 0;
            materialCodeVerColumn.formOrder = 4;
            materialCodeVerColumn.formItemType = "text";
            
            modifiedSchema.columns.push (materialCodeVerColumn);

            let materialCodeNameColumn = {};
            
            materialCodeNameColumn.name = "materialName";
            
            materialCodeNameColumn.readonly = true;
            materialCodeNameColumn.disabled = true;
            materialCodeNameColumn.formGroup = 0;
            materialCodeNameColumn.formOrder = 5;
            materialCodeNameColumn.formItemType = "text";
            
            modifiedSchema.columns.push (materialCodeNameColumn);

            setModifiedSchema ( modifiedSchema );
        }
            
    }, [schema]);

    const onOpen = () => {

        if(grid) {

            setFormData ( {...(grid.selectedItems[0])} );
        }

        for(const column of modifiedSchema.columns) {

            switch(column.name) {

                case "lineNo" :
                    column.hideInForm  = true;
                    break;

                default :
                    break;

            }
        }
        
        setModifiedSchema ( modifiedSchema );
        
        setOpen ( true );

    };

    const setInitialDataCallback = ( formData ) => {

        if ( !formData ) {

            formData = {};
            
        }

        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.materialName = onSelect.materialName;
        formData.wareHouseFrom = onSelect.wareHouseCode;
        formData.qty = onSelect.qtyCurrent;
        formData.lineNo = onSelect.lineNo;
    }


    const onClose = () => {

        setOpen ( false );

    };

    const onMoveLotSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };

    const modifyFormDataCallback = ( newFormData, name, value ) => {

        if(name === "lineNo" || value === "W000" || value ==="W999") {
            for(const column of modifiedSchema.columns) {
                if(column.name === "lineNo") column.hideInForm = false;
            }
    
            setModifiedSchema(modifiedSchema);


        } else{

            for(const column of modifiedSchema.columns) {
                if(column.name === "lineNo") column.hideInForm = true;
            }

            setModifiedSchema(modifiedSchema);
            
        }
    
    }

    const modifyUrlCallback = (url) => {

        let modifiedUrl;

        if(standard) {

            modifiedUrl = `/api/Material/${onSelect.lotNo}/move`;

        } else {

            modifiedUrl = `/api/NsMaterial/${onSelect.lotNo}/move`;
        }
        
        return modifiedUrl;
 
    }

     const checkItemValidation = ( formData, column ) => {

        /* if(column.name === "lineNo") {

            if(formData["wareHouseTo"] === "W000" || formData["wareHouseTo"] === "W999") {

                if(formData["lineNo"] === onSelect.lineNo) {

                    return [ false, t( "error.failToMoveSameLineNo" ) ];
                }
            }
        } */

        if ( column.name === "wareHouseTo" ) {
            
            if ( (( formData["wareHouseFrom"] || "" ) === ( formData["wareHouseTo"] || "" )) && (formData["lineNo"] === onSelect.lineNo)) {

                return [ false, t( "error.failToMoveSameWareHouseOrLineNo" ) ];

            }

        }

        return [ true, "" ];

    }

    return (
        <>
        <MomSysMoveLotButtonLayout id = {id+"-moveLot-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"moveLot-dialog"}
                mode="create"
                formId={id+"moveLot-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'moveLot'}`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onMoveLotSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback = {setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyFormDataCallback = {modifyFormDataCallback}
                checkItemValidationCallback={checkItemValidation}
               
            />
        </>
    )

}

export default MomSysMoveLotButton;