import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import Namespace from "./Namespace";
import TranslationPath from "./TranslationPath";
import TranslationValue from "./TranslationValue";


function Translation ( props ) {

    const { t } = useTranslation();

    const [ height, setHeight ] = useState ( 0 );
    const [ namespace, setNamespace ] = useState();
    const [ translationPath, setTranslationPath ] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectNamespace = ( namespace ) => {

        setNamespace ( namespace );

    };

    const onSelectTranslationPath = ( translationPath ) => {

        setTranslationPath ( translationPath );

    };

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.4}>

                            <Namespace                                
                                headerTitle={t("term.namespace")}
                                noCreate
                                noEdit
                                noDelete
                                noFilter
                                noExcel
                                onSelect={onSelectNamespace}
                                onAppendButtonInFrontOfSearch={ () =>

                                    <BasicCrudScreenActionItemLayout>
                                        <Button color="primary" href={namespace? (( ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? "" : "http://localhost:8080") + `/api/TranslationData/${namespace.namespaceId}`):""} target="_blank" download> { t ( "buttonName.export" ) } </Button>
                                    </BasicCrudScreenActionItemLayout>

                                }
                            />

                        </ReflexElement>

                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterHeight }}
                        />

                        <ReflexElement>

                            <TranslationPath namespace={namespace} onSelect={onSelectTranslationPath} />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterWidth }}
                />

                <ReflexElement>

                    <TranslationValue id="Modify" modify namespace={namespace} translationPath={translationPath} />

                </ReflexElement>

            </ReflexContainer>

        </div>

    )

}

export default Translation;
