/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "react-reflex/styles.css";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function BatchHistoryMaster ( props ) {

    const { t } = useTranslation();

    const { onSelect } = props;

    const [grid, setGrid] = useState();

    useEffect( () => {

        let mounted = true;

        ( async () => {

            if (grid) {

                grid.selectionChanged.addHandler( ( grid ) => {

                    if ( mounted ) {

                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                let history = grid.selectedItems[0];

                                onSelect ( history );
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );

                            }
    
                        }
    
                    }

                } );

                grid.itemsSourceChanged.addHandler( ( grid ) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                let history = grid.selectedItems[0];
                                
                                onSelect ( history );
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );

                            }
                                
                        }

                    }

                } );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [grid] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        setGrid(grid);
        
    };
    
    return (

        <BasicCrudScreenType
            id="BatchHistory"
            headerTitle={t("term.batchHistory")}
            table="VwBatchHistoryMaster"
            view="VwBatchHistoryMaster"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
        />                                

    );

}

export default BatchHistoryMaster;
