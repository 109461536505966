import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";
import { useRecoilState, useRecoilValue } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { cloneDeep, debounce } from "lodash";

import authProfileState from "../../states/authProfileState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";

import apiClient from "../../lib/common/apiClient";

import EguSelfInspectionRegisterButton from "../input/EguSelfInspectionRegisterButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import ApprovalButton from "../input/ApprovalButton";
import EguSelfInspectionWareHouseMoveButton from "../input/EguSelfInspectionWareHouseMoveButton";
import EguSelfInspectionFileDownloadButton from "../input/EguSelfInspectionFileDownloadButton";
import MomSysInspectionRslt from "./MomSysInspectionRslt";

function EguSelfInspection(props) {
    const id = "EguSelfInspection";
    const table = "EguSelfInspection";
    const view = "EguVwSelfInspection";

    const { t } = useTranslation();
    const [height, setHeight] = useState(0);

    const [selfInspectionSchema, setSelfInspectionSchema] = useState();
    const [modifiedSchemaForUpdate, setModifiedSchemaForUpdate] = useState();

    const [selfInspectionGrid, setSelfInspectionGrid] = useState();
    const [selfInspectionApi, setSelfInspectionApi] = useState();
    const [selfInspectionIsEmpty, setSelfInspectionIsEmpty] = useState();
    const [selfInspectionSelect, setSelfInspectionSelect] = useState();

    const [inspectionRsltIsEmpty, setInspectionRsltIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const authProfile = useRecoilValue(authProfileState);

    const [approvalCtx, setApprovalCtx] = useState();

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setSelfInspectionSchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 16,
            tableName: table,
            statusColumn: "approvalStateId",
            schema: selfInspectionSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },

            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.eguSelfInspection", {
                    selfInspectionId: item.selfInspectionId,
                })}`;
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfInspectionSchema]);

    useEffect(() => {
        let modifiedSchemaForUpdate = cloneDeep(selfInspectionSchema);

        if (modifiedSchemaForUpdate) {
            for (const column of modifiedSchemaForUpdate.columns) {
                switch (column.name) {
                    case "inspectionGroupId":
                        column.disabled = true;
                        break;

                    case "samplingQty":
                        column.required = false;
                        break;

                    default:
                        break;
                }
            }

            setModifiedSchemaForUpdate(modifiedSchemaForUpdate);
        }
    }, [selfInspectionSchema]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (selfInspectionGrid && mounted) {
                selfInspectionGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelfInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setSelfInspectionSelect([]);
                        }
                    }, 10)
                );

                selfInspectionGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelfInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setSelfInspectionSelect([]);
                        }
                    }, 10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [selfInspectionGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    /* 2023.02.08 itkim : 원래 불량으로 판단되면 승인 후, 바로 특정 불량 창고(W998) 로 이동하는 기능음.
     * 하지만 YEMAT 요청 사항으로, 불량으로 판단 되어도 창고 이동 버튼을 활성화 시키고
     * 여기서 직접 여러 불량 창고 중 하나를 선택해서 이동 시킬 수 있도록 함.
     */
    const onApprovedCallback = () => {
        if (selfInspectionSelect.qcResult !== defineConf.qcResult.QC_OK) {
            (async () => {
                const eguVwSelfInspectionTuple = await getSpecific(
                    "EguVwSelfInspection",
                    selfInspectionSelect.selfInspectionId
                );

                if (
                    eguVwSelfInspectionTuple.approvalStateId &&
                    eguVwSelfInspectionTuple.approvalState === defineConf.approvalState.APPROVED
                ) {
                    const url = `/api/EguSelfInspection/approved`; /* see customApis/post/EguSelfInspection.js */

                    let form = new FormData();

                    let json = {};
                    let deleted = {};

                    json["targetLotNo"] = selfInspectionSelect.targetLotNo;
                    json["materialCode"] = selfInspectionSelect.materialCode;
                    json["materialCodeVer"] = selfInspectionSelect.materialCodeVer;
                    json["materialName"] = selfInspectionSelect.materialName;
                    json["qcResult"] = selfInspectionSelect.qcResult;
                    json["approvalStateId"] = eguVwSelfInspectionTuple.approvalStateId;

                    form.append("deleted", JSON.stringify(deleted));
                    form.append("json", JSON.stringify(json));

                    apiClient
                        .post(url, form, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(() => {
                            selfInspectionApi.refresh();
                        })
                        .catch((reason) => {
                            notifyFailure(reason.response ? reason.response.data : reason);
                        });
                }
            })();
        }
    };

    const onUpdateMaster = () => {
        if (selfInspectionGrid) {
            (async () => {
                const selfInspectionMst = await getSpecific(
                    "EguVwSelfInspection",
                    selfInspectionGrid.selectedItems[0].selfInspectionId
                );

                selfInspectionGrid.selectedItems[0].qcResult = selfInspectionMst.qcResult;
                selfInspectionGrid.refresh(false); // grid.refresh(false) : content update only, grid에 즉시 반영
            })();
        }
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.eguSelfInspection")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setSelfInspectionGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setSelfInspectionApi(api);
                        }}
                        onLoadData={(data) => {
                            setSelfInspectionIsEmpty(data.length < 1 ? true : false);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <EguSelfInspectionRegisterButton
                                        id={id}
                                        schema={selfInspectionSchema}
                                        refresh={selfInspectionApi}
                                        onSuccess={onSuccess}
                                    />

                                    <UpdateRecordButton
                                        id={id}
                                        schema={modifiedSchemaForUpdate}
                                        grid={selfInspectionGrid}
                                        refresh={selfInspectionApi}
                                        disabled={selfInspectionSelect?.approvalState || selfInspectionIsEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteRecordButton
                                        id={id}
                                        schema={modifiedSchemaForUpdate}
                                        color="secondary"
                                        grid={selfInspectionGrid}
                                        refresh={selfInspectionApi}
                                        disabled={selfInspectionSelect?.approvalState || selfInspectionIsEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <ApprovalButton
                                        grid={selfInspectionGrid}
                                        refresh={selfInspectionApi}
                                        approvalCtx={approvalCtx}
                                        disabled={!selfInspectionSelect || inspectionRsltIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        //onApprovedCallback={onApprovedCallback}
                                    />

                                    <EguSelfInspectionWareHouseMoveButton
                                        id={id}
                                        schema={selfInspectionSchema}
                                        grid={selfInspectionGrid}
                                        refresh={selfInspectionApi}
                                        disabled={
                                            selfInspectionSelect?.approvalState !== defineConf.approvalState.APPROVED ||
                                            //selfInspectionSelect?.qcResult !== defineConf.qcResult.QC_OK ||
                                            selfInspectionIsEmpty
                                        }
                                        modifyUrlCallback={(url) => "/api/EguSelfInspection/wareHouseMove"}
                                        onSuccess={onSuccess}
                                    />

                                    <EguSelfInspectionFileDownloadButton
                                        id={id}
                                        disabled={selfInspectionIsEmpty || !selfInspectionSelect?.selfInspectionId}
                                        selfInspectionId={selfInspectionSelect?.selfInspectionId}
                                    ></EguSelfInspectionFileDownloadButton>
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    {selfInspectionSelect ? (
                        <MomSysInspectionRslt
                            inspectionSelect={selfInspectionSelect}
                            onUpdateMaster={onUpdateMaster}
                            onInspectionRsltEmpty={setInspectionRsltIsEmpty}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguSelfInspection;
