/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import notificationState from "../../states/notificationState";
import EguStockExcelDownloadButton from "../input/EguStockExcelDownloadButton";
import EguStockExcelUploadButton from "../input/EguStockExcelUploadButton";

import { Grid, TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import ExcelUploadSuccess from './ExcelUploadSuccess';
import ExcelUploadError from './ExcelUploadError';

const excelUploadItemViewName = "EguVwStockExcelUpload";
const excelUploadItemErrorViewName = "EguVwStockExcelUploadError";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    }
}));

function EguStockExcelUpload(props) {
    const classes = useStyle();

    const [schema, setSchema] = useState();
    const [tab, setTab] = useState("successView");
    const [api, setApi] = useState(null);
    const [, setSearch] = useState();
    const [, setSearchVal] = useState();

    const {t} = useTranslation();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema("MomSysLogAdjust");
            if(mounted) {
                setSchema(schema);
            }
        })();

        return(() => {
            mounted = false;
        });
    }, []);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onApiInitialized = (api) => {

        setApi(api)
    }

    const onTabChanged = (tab) => {
        setTab(tab);
    };
    
    const onSearchInitialized = (search) => {
        setSearch(search);
        setSearchVal((searchVal) => {
            if(searchVal) {
                search.applySearch(searchVal);
            }
            return searchVal;
        } );
    }

    const onChangeSearch = (e) => {
        setSearch((search) => {
            if(search) {
                setSearchVal(e.target.value);
                search.applySearch(e.target.value);
            }
            return search;
        });
    };

    return (
        <BasicTabScreenType
            id="EguLogStockExcelUpload"
            tabs={["successView", "errorView"]}
            tabLabels={["엑셀 업로드 성공", "엑셀 업로드 실패"]}
            tabsStyle={{
                left: 0,
                margin: "0px",
                padding:"0px",
                height: "48px",
                width: "450px"
            }}
            onTabChanged={onTabChanged}
            value={tab}
            rightGridItems={
                [
                    <Grid item>
                        <EguStockExcelDownloadButton
                            id = "EguStockExcelDownloadForm"
                            createButtonName = "excelUpload"
                            onSuccess = {onSuccess}
                        />

                        <EguStockExcelUploadButton
                            id = "EguStockExcelUpload"
                            createButtonName = "excelUpload"
                            schema = {schema}
                            refresh = {api}
                            onSuccess = {onSuccess}
                        />
                    </Grid>,
                    <Grid item>
                        <TextField
                            id={"stock-excel-upload-search"}
                            className={classes.root}
                            placeholder={t("common.search")}
                            onChange={onChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>                    
                                )
                            }}
                            style={{
                                bottom: "2px",
                                width: "120px"                        
                            }}
                        />
                    </Grid>
                ]
            }
        >
            <EguCustomerExcelUploadView
                viewName={tab}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                api={api}
            />
        </BasicTabScreenType>
    );
}

function EguCustomerExcelUploadView(props) {
    const { viewName, onSearchInitialized, onApiInitialized } = props;

    switch(viewName) {
        case "successView":
            return <ExcelUploadSuccess 
                id='EguStockExcelUploadSuccessButton'
                table={excelUploadItemViewName}
                view={excelUploadItemViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
            />

        case "errorView":
            return <ExcelUploadError
                id='EguStockExcelUploadErrorButton'
                table={excelUploadItemErrorViewName}
                view={excelUploadItemErrorViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
            />

        default:
            return <></>;
    }
}

export default EguStockExcelUpload;
