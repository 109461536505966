import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function MomSysOutSourcingButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.outSourcingIn")}</ActionButtonLayout>;

}

export default MomSysOutSourcingButtonLayout;

