import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },
    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function MomSysCodeGenerateCopyDialog(props) {

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [keepOpen, setKeepOpen] = useState(false);

    const {

        // Dialog Properties

        id,
        title,
        mode,
        platform,
        resizable,
        moveable,
        fullWidth,
        maxWidth,
        onInitialized,
        noKeepOpen,

        // Form Properties

        formId,
        schema,
        initialData,
        setInitialDataCallback,
        modifyFormDataCallback,
        checkShowCallback,
        checkDisabledCallback,
        checkFormValidationCallback,
        checkItemValidationCallback,
        modifyUrlCallback,

        // customize error message callback

        customizeErrorMsgCallback,

        // AutoFormDialog Properties

        onClose,
        onChange,
        onSuccess,
        onFailure,

        // Do Not Notify Using Snackbar by Dialog itself

        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,

        doNotUseKeyAsUrlWhenUpdate,

        barcodeCallback,
        focusRefs,

        grid,

        ...others

    } = props;

    const [tableSchema, setTableSchema] = useState(schema);

    useEffect(() => {

        if (schema) {

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        setTableSchema(schema);

    }, [schema]);

    const notifyWarning = (msg) => {

        console.warn(msg);

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "warning",
                msg: msg,
                open: true,
            });

        }

    };

    const checkItemValidataion = (formData, schema) => {


        for (const column of schema.columns) {

            let [valid, msg] = checkValidation(mode, column, column.validation, formData[column.name] || "");

            if (valid && checkItemValidationCallback) {

                [valid, msg] = checkItemValidationCallback(formData, column);

            }

            if (!valid) {

                notifyWarning(msg);
                return false;

            }

        }

        return true;

    }

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseDialog = () => {

        setInteraction(false);

        if (onClose) {

            onClose();

        }

    }

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChangeFormData = (formData) => {

        setTimeout(() => {

            setInteraction(true);

            setFormData(formData);

            if (onChange) {

                onChange(formData);

            }

        }, 0);

    };

    const onApiSuccess = (result, schema) => {

        switch (mode) {

            case "edit":

                notifySuccess(t("success.updateSuccess", { updateCount: 1 }));
                break;

            default:

                notifySuccess(t("success.insertSuccess", { table: schema.name }));
                break;

        }

        setKeepOpen((keepOpen) => {

            if (!keepOpen) {

                instance.hide();

            }

            return keepOpen;

        });

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

    };

    const onApiFailure = (reason) => {

        let msg;

        if (customizeErrorMsgCallback) {

            msg = customizeErrorMsgCallback ( reason );

        } else {

            switch (mode) {

                case "edit":
    
                    msg = t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
                    break;
    
                default:
    
                    msg = t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
                    break;
    
            }
    
        }

        if (onFailure) {

            onFailure(reason, notifyFailure);

        } else {

            notifyFailure ( msg );

        }

    };

    const onOk = (mode, formData, schema) => {

        if (!checkItemValidataion(formData, schema)) {

            console.error("Form Validation Failed");

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (valid && checkFormValidationCallback) {

                [valid, msg] = checkFormValidationCallback(formData);

            }

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }

        }
        else if (checkFormValidationCallback) {

            const [valid, msg] = checkFormValidationCallback(formData);

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }
                
        // build base api url

        let url = "/api/" + schema.name + "/copy";

        if (modifyUrlCallback) {

            url = modifyUrlCallback(url);

        }

        let apiData = { ...formData, selectedItems: grid.selectedItems };

        let form = new FormData();
        let json = {};
        let deleted = {};

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }

            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result, schema))
            .catch((reason) => onApiFailure(reason));

    };

    const onCancel = () => {

        instance.hide();

    };

    const checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>

            </>

        )

    }

    return (
        <Dialog
            id={id}
            title={title}
            platform={platform ? platform : uiConf.dialogPlatform}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent dividers>
                <AutoForm
                    id={formId}
                    schema={tableSchema}
                    mode={mode ? mode : uiConf.defaultFormMode}
                    initialData={initialData}
                    setInitialDataCallback={setInitialDataCallback}
                    modifyFormDataCallback={modifyFormDataCallback}
                    checkShowCallback={checkShowCallback}
                    checkDisabledCallback={checkDisabledCallback}
                    checkValidationCallback={checkItemValidationCallback}
                    onChange={onChangeFormData}
                    barcodeCallback={barcodeCallback}
                    focusRefs={focusRefs}
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <DialogActionsOkCancel
                firstActionComponent={noKeepOpen ? () => <></> : checkKeepOpen}
                labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(mode, formData, tableSchema)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysCodeGenerateCopyDialog;
