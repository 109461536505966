import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import EguMstMaterialCopyCreateButton from "../input/EguMstMaterialCopyCreateButton";
import EguMstMaterialCreateButton from "../input/EguMstMaterialCreateButton";
import EguMstMaterialUpdateButton from "../input/EguMstMaterialUpdateButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"
import DataAllSaveToExcelButton from "../input/DataAllSaveToExcelButton";
import apiClient from "../../lib/common/apiClient";

function EguMstMaterial(props) {
    const { id, masterGridSetting } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [onSelectGrid, setOnSelectGrid] = useState();
    const [extAttrArr, setExtAttrArr] = useState([]);

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysMstMaterial");

            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
        
    }, []);

    useEffect(() => {

        if(schema && extAttrArr.length > 0) {

            dynamicExtAttrColumnSetting(schema, extAttrArr, true);

        }

    }, [schema, extAttrArr])

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onModifyViewSchema = async (schema) => {

        const result = await getExtAttrsByTenant();

        dynamicExtAttrColumnSetting(schema, result, false);

        setExtAttrArr(result);

    }

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="MomSysMstMaterial"
                view="EguVwMaterial"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.standard")}
                noCreate
                noEdit
                noDelete
                noExcel
                onModifyViewSchema={(schema) => onModifyViewSchema(schema)}
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <EguMstMaterialCopyCreateButton
                                    id = "EguMstMaterialCopyCreate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSelectGrid = {onSelectGrid}
                                    onSuccess = {onSuccess}
                                    createButtonName = {"materialCopyCreate"}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>

                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <EguMstMaterialCreateButton
                                    id = "EguMstMaterialCreate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSuccess = {onSuccess}
                                />
                            </AccessControl>
                            
                            <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                                <EguMstMaterialUpdateButton
                                    id = "EguMstMaterialUpdate"
                                    schema = {schema}
                                    refresh = {api}
                                    grid = {grid}
                                    onSuccess = {onSuccess}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>

                            <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                <DeleteRecordButton
                                    id={id}
                                    schema={schema}
                                    grid={grid}
                                    refresh={api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>

                            <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                                <DataAllSaveToExcelButton
                                    id={id}
                                    grid={grid}
                                    view="EguVwMaterial"
                                    excelFileName="품목 정보"
                                    color="primary"
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

async function getExtAttrsByTenant() {

    let result = await apiClient.get ( `api/VwExtMaterialAttrTenant/byTenant` );

    const extAttrTenantArr = result.data.data;

    const extAttrArr = [];

    for(const extAttrTenant of extAttrTenantArr) {

        result = await apiClient.get ( `api/VwExtMaterialAttr/${extAttrTenant.extAttrId}` );

        const extAttrsByExtAttrId = result.data.data;

        extAttrArr.push(extAttrsByExtAttrId);

    }

    return extAttrArr;

}

function dynamicExtAttrColumnSetting(schema, extAttrArr, isTable) {

    for(const column of schema.columns) {

        if(column.name.startsWith("extAttr")) {

            const extAttrNum = parseInt(column.name.substring(7));

            if(extAttrNum > extAttrArr.length) {

                if(isTable) {

                    column.hideInForm = true;

                } else {
    
                    column.grid.visible = false;

                    column.filter = undefined;

                }

            } else {

                if(isTable) {

                    column.hideInForm = false;
                    
                } else {
    
                    column.grid = {
                        ...column.grid, 
                        type: "select",
                        visible: true
                    };

                }

                column.formItemType = "select";

                column.selectItems = [];

                extAttrArr[extAttrNum - 1].map((r) => {

                    column.selectItems.push(

                        {
                            name: r.extAttrName,
                            value: r.extAttrCode
                        }

                    )

                })

            }

        }

    }

}

export default EguMstMaterial;
