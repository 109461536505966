import { useEffect, useState } from "react";
import "./gridEvent.css"
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function StockItemLotNo ( props ) {
    const { selectStockItem, setSelectLotNo } = props;
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [, setSelectGrid] = useState();

    const {t} = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                        setSelectLotNo(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                        setSelectLotNo(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                        setSelectLotNo(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                        setSelectLotNo(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectStockItem]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onModifyUrl = (url) => {
        let modifyUrl = 'api/ExceptionInOut/getLotIds';
        modifyUrl += `/${selectStockItem.materialCode}`;
        modifyUrl += `/${selectStockItem.materialCodeVer}`;

        return modifyUrl;
    };

    return <BasicCrudScreenType 
        id="stockItemLotNo"
        table="VwExceptionOutLots" 
        view="VwExceptionOutLots"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        onModifyViewUrl={(url) => onModifyUrl(url)}
        headerTitle={t("tableName.stockItemLotNo")}
        refresh={api}
        noCreate
        noEdit
        noDelete
        noExcel
    />;
}

export default StockItemLotNo;
