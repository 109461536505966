/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { cloneDeep } from "lodash";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import getList from "../../lib/api/getList";
import getSchema from "../../lib/api/getSchema";

import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({

    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },

}));

function AddPerformanceMetricDialog ( props ) {

    const  classes = useStyles ();
    const { t } = useTranslation ();

    const [schema, setSchema] = useState ();
    const [instance, setInstance] = useState ();
    const [disabled, setDisabled] = useState ( true );
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [grid, setGrid] = useState();
    const [material, setMaterial] = useState([]);
    const [processCode, setProcessCode] = useState();
    const [machineCode, setMachineCode] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    } );

    const {

        id,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "PerformanceMetric" );

            let column = {
                name: "materialName",
                type: "varchar(50)",
                formItemType: "text",
                formOrder: 4,
                readonly: true,
                disabled: true,
                width: "100%"
            }

            schema.columns.push ( column );    

            if ( mounted ) {
                
                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                nofifyWarning ( msg );
                return false;

            }
            
        }

        return true;

    }

    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };


    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setMaterial(grid.selectedItems[0]);

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setMaterial(grid.selectedItems[0]);
                        
                    }

                });

            }

        })();

    }, [grid]);

    useEffect ( () => {

        ( async () => {
        
            let processPerHour = 0;

            if ( machineCode ) {

                if ( material && processCode ) {
    
                    let result = await apiClient.get ( `api/PerformanceMetric/${material.materialCode}/${material.materialCodeVer}/${processCode}/${machineCode}` );
    
                    if ( result?.data?.data?.length > 0 ) {
    
                        processPerHour = parseFloat ( result.data.data[0].processPerHour );
    
                    }
    
                }
    
            } else {

                if ( material && processCode ) {
    
                    let result = await apiClient.get ( `api/PerformanceMetric/${material.materialCode}/${material.materialCodeVer}/${processCode}` );
    
                    if ( result?.data?.data?.length > 0 ) {
    
                        processPerHour = parseFloat ( result.data.data[0].processPerHour );
    
                    }
    
                }
    
            }
    
            setFormData ( ( formData ) => {

                let newFormData = {
    
                    ...formData,
                    processCode: processCode,
                    processPerHour: processPerHour
    
                };
    
                return newFormData;
    
            } );
    
        } ) ();

    }, [material, processCode, machineCode ] );
    

    useEffect ( () => {

        ( async () => {
            
            if ( material ) {

                let result;

                result = await getList ( `VwBopProcess/${material.materialCode}/${material.materialCodeVer}` );

                if ( result.data.length > 0 ) {

                    let processCode = result?.data[0]?.processCode;
                    setProcessCode ( processCode );

                }

            }

            setFormData ( ( formData ) => {

                let newFormData = {
    
                    ...formData,
                    materialCode: material.materialCode,
                    materialCodeVer: material.materialCodeVer,
                    materialName: material.materialName,
    
                };
    
                setSchema ( ( schema ) => {
    
                    let newSchema = cloneDeep ( schema );
    
                    if ( newSchema ) {
    
                        for ( let column of newSchema.columns ) {
                     
                            switch ( column.name ) {
        
                                case "processCode":
        
                                    column.codeInfo.tableName = `VwBopProcess/${material.materialCode}/${material.materialCodeVer}`;
    
                                    break;
        
                                default:
        
                                    break;
        
                            }
        
                        }
        
                    }
    
                    return newSchema;
    
                } );
        
                return newFormData;
    
            } );

        } ) ();


    }, [material] );

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            if ( result.status === 201 ) {

                onSuccess ( t ( "success.insertSuccess" ) );

            } else {

                onSuccess ( t ( "success.updateSuccess", { updateCount: 1 } ) );

            }

        }

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                materialCode: material.materialCode,
                materialCodeVer: material.materialCodeVer,
                materialName: material.materialName,
                processCode: undefined,
                machineCode: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );

        setKeepOpen ( ( keepOpen ) => {

            setDisabled ( true );

            if ( !keepOpen ) {

                setInstance ( ( instance ) => {                 
                    instance.hide();
                    return instance;
                } );

            }

            return keepOpen;

        } );

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, formData ) => {

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = "/api/PerformanceMetric";

        let apiData = { ...formData };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {

                deleted[formItem] = apiData[formItem].deletedFiles;
                    
                for ( const file of apiData[formItem].activeFiles ) {

                    if ( file.type === "url" ) continue;

                    form.append ( formItem, file.file );

                }

            } else {

                if ( apiData[formItem] !== undefined ) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                materialCode: material.materialCode,
                materialCodeVer: material.materialCodeVer,
                materialName: material.materialName,
                processCode: undefined,
                machineCode: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );

        setDisabled ( true );
        instance.hide();

    };

    const  checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>

            </>

        )

    }

    const onChange = ( formData ) => {

        setProcessCode ( formData.processCode );
        setMachineCode ( formData.machineCode );
        
        setFormData ( formData );

        setDisabled ( false );

    }

    const onGridInitialized = ( grid ) => {

        setGrid(grid);

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setDisabled ( true );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                materialCode: material.materialCode,
                materialCodeVer: material.materialCodeVer,
                materialName: material.materialName,
                processCode: undefined,
                machineCode: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );
        
        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.PerformanceMetric.create" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.5} >

                        <BasicCrudScreenType
                            id="VwBopMaterial"
                            embededInOtherScreenType
                            headerTitle={t("term.inputMaterial")}
                            table="VwBopMaterial"
                            view="VwBopMaterial"
                            noCreate
                            noEdit
                            noDelete
                            noExcel

                            onInitialized={onGridInitialized}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.5} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id="performance-metric-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChange}
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[ t("common.add")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default AddPerformanceMetricDialog;
