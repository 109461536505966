import { useState } from "react";
import { useTranslation } from "react-i18next";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function ShipmentButton(props) {

  const { t } = useTranslation();
  const { id, schema, grid, refresh, onSuccess, shipmentDetailId, customizeErrorMsgCallback, ...others } = props;
  
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState();  

  const onOpen = () => {

    if ( grid ) {

        setFormData ( {...(grid.selectedItems[0])} );

    }
    
    setOpen ( true );

};

  const onClose = () => {

    setOpen(false);

  };

  const onUpdateSuccess = (msg) => {

    if (onSuccess) {

      onSuccess(msg);

    }

    refresh.refresh();

  };

  const modifyUrlCallback = (url) => {

    let modifiedUrl = `${url}/${shipmentDetailId}`;
    
    return modifiedUrl;

  };


  return (
    <>
      <CreateRecordButtonLayout
        id={id + "create-button"}
        onClick={onOpen}
        createButtonName ="shipment"
        {...others}
      />
      <AutoFormDialog
        id={id + "create-dialog"}
        mode="create"
        formId={id + "create-form"}
        title={schema ? t(`dialog.title.${schema.name}.${'shipment'}`) : ""}
        schema={schema}
        initialData={formData}
        modifyUrlCallback = {modifyUrlCallback}
        open={open}
        onClose={onClose}
        onSuccess={onUpdateSuccess}
        customizeErrorMsgCallback ={customizeErrorMsgCallback}
      />
    </> 
  );
}

export default ShipmentButton;
