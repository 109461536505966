import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function PopDirectShipButton ( props ) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, updateMaster, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [schema, setSchema] = useState();

    useEffect(() => {

        let schema = {

            name: "PopDirectShipDetail",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {

                    name: "directShipName",
                    type: "varchar(255)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 10,
                    formItemType: "text",

                },
                {

                    name: "companyCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 20,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysUdtCompany",
                        nameColumn: "companyName",
                        valueColumn: "companyCode",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    }

                },
                {

                    name: "shipDate",
                    type: "DATE",
                    nullable: "NO",
                    default: null,
                    formOrder: 30,
                    formItemType: "date",

                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if ( refresh ) {

            refresh.refresh ();

        }

        if ( updateMaster ) {

            updateMaster ();

        }

    };

    const modifyUrlCallback = ( url ) => {

        return url + "/ship";

    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"popDirectShip-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"popDirectShip-dialog"}
                mode="create"
                formId={id+"popDirectShip-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.popDirectShip` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                modifyUrlCallback={modifyUrlCallback}
                noKeepOpen
            />
        </>

    );
}

export default PopDirectShipButton;

