/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import EguMstMaterialUpdateDialog from "../dialogTypes/EguMstMaterialUpdateDialog";
import defineConf from "../../config/defineConf";

function EguMstMaterialUpdateButton (props) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSuccess, grid, ...others} = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();
    const [tableSchema, setTableSchema] = useState();

    useEffect (() => {
        function tableSchemaSetting() {
            let defaultSchema = schema;
            defaultSchema.columns.forEach((column) => columnSetting(column));
            setTableSchema(schema);
        }

        function columnSetting(column) {
            switch(column.name) {
                case "materialTypeCode" :
                    column.readonly = true;

                    break;

                default :
            }
        }

        tableSchemaSetting();
    }, [schema]);
    
    const onOpen = () => {
        if (grid) {
            setFormData ({...(grid.selectedItems[0])});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    const checkShowCallback = (formData, column) => {

        if( parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.HALF_FINISHED_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.RAW_MATERIAL ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.WIP_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.SUBSIDIARY_MATERIAL ) {

            formData.mediumUnitQty = 0;
            formData.smallUnitQty = 0;
            
            switch(column.name) {

                case "mediumUnitQty" : 
                case "smallUnitQty" : 

                    return false;

                default :
                    return true;
            }

        } else {

            switch(column.name) {

                default :
                return true;
            }

        }


    }

    return (
        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <EguMstMaterialUpdateDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={t(`dialog.title.${schema.name}.update`)}
                schema={tableSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                checkShowCallback = {checkShowCallback}
            />
        </>
    );
}

export default EguMstMaterialUpdateButton

