/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({

    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },

}));

function MomSysInspectionGroupByMaterialDialog ( props ) {

    const  classes = useStyles ();
    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [keepOpen, setKeepOpen] = useState(false);

    const [materialsGrid, setMaterialsGrid] = useState();
    const [inspectionGrid, setInspectionGrid] = useState();
    

    const [selectMaterials, setSelectMaterials] = useState();
    const [selectInspectionGroups, setSelectInspectionGroups] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });


    const {

        id,
        schema,
        process,
        moveable,
        resizable,
        fullWidth,
        maxWidth,
        refresh,
        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;
    useEffect(() => {

        (async () => {

            if (materialsGrid) {

                materialsGrid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectMaterials([...grid.selectedItems]);
                    } else {

                        setSelectMaterials([]);

                    };

                }, 10));

                materialsGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setSelectMaterials([...grid.selectedItems]);

                    } else {

                        setSelectMaterials([]);

                    };

                }, 10));
            }
        })()
        
    }, [materialsGrid]);

    useEffect(() => {

        (async () => {

            if (inspectionGrid) {

                inspectionGrid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setSelectInspectionGroups([...grid.selectedItems])

                    } else {

                        setSelectInspectionGroups([]);

                    };

                }, 10));

                inspectionGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setSelectInspectionGroups([...grid.selectedItems])

                    } else {

                        setSelectInspectionGroups([]);

                    };

                }, 10));
            }
        })()
        
    }, [inspectionGrid]);

    const notifyFailure = (msg) => {
        console.error ( msg );
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onDialogInitialized = ( instance ) => {
        setInstance(instance);
        if ( onInitialized ) {
            onInitialized ( instance );
        }
    };

    const onApiSuccess = (result) => {
        if ( onSuccess ) {
            onSuccess ( t ( "success.insertSuccess", { table: "MomSysMaterialByCompany" } ) );
        }


        setKeepOpen ( ( keepOpen ) => {
            if ( !keepOpen ) {
                instance.hide();
            }

            return keepOpen;
        } );

        if (onSuccess) {
            setTimeout ( onSuccess(result.data), 0 );
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );
    };

    const onOk = (selectMaterials, selectInspectionGroups) => {
        // build base api url
        let url = "/api/MomSysInspectionGroupByMaterial";

        let apiData = { materials : [...selectMaterials], inspectionGroups : [...selectInspectionGroups] };
        let form = new FormData ();
        let json = {};
        let deleted = {};
        for ( const formItem in apiData ) {
            json[formItem] = apiData[formItem];
        }

        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    const onCancel = () => {

        instance.hide();

    };

    const checkKeepOpen = () => {

        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>

            </>

        )

    }


    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.MomSysInspectionGroupByMaterial.create" ) }
                </DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.35} >

                        <BasicCrudScreenType
                            id="MomSysZvwMaterial"
                            view="MomSysZvwMaterial"
                            headerTitle={t("term.item")}
                            embededInOtherScreenType
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid)=> setMaterialsGrid(grid)}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.65} >

                        <BasicCrudScreenType
                            id="MomSysInspectionGroup"
                            view="MomSysInspectionGroup"
                            headerTitle={t("term.momSysInspectionGroup")}
                            embededInOtherScreenType
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid)=> setInspectionGrid(grid)}
                        />
                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[ t("common.add")]}
                buttonDisabled={[false, false]}
                onOk={() => onOk(selectMaterials, selectInspectionGroups)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default MomSysInspectionGroupByMaterialDialog;
