import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MaterialIoManage(props) {
  return (
    <BasicCrudScreenType
      noCreate
      noDelete
      noExcel
      noEdit
      table="MaterialInOutput"
      view="VwMaterialInOutput"
    />
  );
}

export default MaterialIoManage;
