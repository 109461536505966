import React, { useEffect, useState } from "react";
import defineConf from "../../config/defineConf";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysCustomerSupplyInputRegisterButton from "../input/MomSysCustomerSupplyInputRegisterButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import MomSysCustomerSupplyInputDoInputButton from "../input/MomSysCustomerSupplyInputDoInputButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import AccessControl from "../functional/AccessControl";
import getSchema from "../../lib/api/getSchema";
import PrintReportButton from "../input/PrintReportButton";

function MomSysCustomerSupplyInput(props) {
    const id = "MomSysCustomerSupplyInput";
    const table = "MomSysCustomerSupplyInput";
    const view = "MomSysVwCustomerSupplyInput";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [CustomerSupplyInputSchema, setCustomerSupplyInputSchema] = useState();
    const [CustomerSupplyInputGrid, setCustomerSupplyInputGrid] = useState();
    const [CustomerSupplyInputApi, setCustomerSupplyInputApi] = useState();
    const [CustomerSupplyInputDataIsEmpty, setCustomerSupplyInputDataIsEmpty] = useState();
    const [CustomerSupplyInputSelect, setCustomerSupplyInputSelect] = useState();
    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setCustomerSupplyInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {

        const path = `customerSupplyInput/${CustomerSupplyInputSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);

    }, [CustomerSupplyInputSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (CustomerSupplyInputGrid && mounted) {
                CustomerSupplyInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyInputSelect();
                        }
                    }, 10)
                );

                CustomerSupplyInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyInputSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [CustomerSupplyInputGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id={id}
                view={view}
                headerTitle={t("term.MomSysCustomerSupplyInput")}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setCustomerSupplyInputGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setCustomerSupplyInputApi(api);
                }}
                onLoadData={(data) => {
                    setCustomerSupplyInputDataIsEmpty(data.length < 1 ? true : false);
                }}
                onAppendButtonInFrontOfSearch={() => (
                    <BasicCrudScreenActionItemLayout>

                        <AccessControl
                            accessTarget={CustomerSupplyInputSchema && CustomerSupplyInputSchema.name}
                            accessMethod="POST"
                        >
                            <MomSysCustomerSupplyInputRegisterButton
                                id={id}
                                schema={CustomerSupplyInputSchema}
                                refresh={CustomerSupplyInputApi}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={CustomerSupplyInputSchema && CustomerSupplyInputSchema.name}
                            accessMethod={
                                CustomerSupplyInputSchema &&
                                (CustomerSupplyInputSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <UpdateRecordButton
                                id={id}
                                schema={CustomerSupplyInputSchema}
                                grid={CustomerSupplyInputGrid}
                                refresh={CustomerSupplyInputApi}
                                disabled={CustomerSupplyInputDataIsEmpty || CustomerSupplyInputSelect?.inputDate}
                                modifyFormDataCallback={(formData) => {
                                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                        formData.defectiveCode = "INS000";
                                    }
                                }}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={CustomerSupplyInputSchema && CustomerSupplyInputSchema.name}
                            accessMethod="DELETE"
                        >
                            <DeleteSingleRecordButton
                                id={id}
                                schema={CustomerSupplyInputSchema}
                                grid={CustomerSupplyInputGrid}
                                refresh={CustomerSupplyInputApi}
                                makeItemName={(row) =>
                                    `${t("columnName.CustomerSupplyInputId")}: ${row.customerSupplyInputId}`
                                }
                                disabled={CustomerSupplyInputDataIsEmpty || CustomerSupplyInputSelect?.inputDate}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                color="secondary"
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={CustomerSupplyInputSchema && CustomerSupplyInputSchema.name}
                            accessMethod={
                                CustomerSupplyInputSchema &&
                                (CustomerSupplyInputSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <MomSysCustomerSupplyInputDoInputButton
                                id={`${id}-input-button`}
                                schema={CustomerSupplyInputSchema}
                                grid={CustomerSupplyInputGrid}
                                refresh={CustomerSupplyInputApi}
                                disabled={
                                    !CustomerSupplyInputSelect ||
                                    //CustomerSupplyInputSelect?.qcResult !== defineConf.qcResult.QC_OK ||
                                    CustomerSupplyInputSelect?.wareHouseName
                                }
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={CustomerSupplyInputSchema && CustomerSupplyInputSchema.name}
                            accessMethod="SAVETOEXCEL"
                        >
                            <SaveToExcelButton
                                id={id}
                                view={view}
                                grid={CustomerSupplyInputGrid}
                                color="primary"
                                exportFormatItem={() => { }}
                                disabled={CustomerSupplyInputDataIsEmpty}
                            />
                        </AccessControl>

                        <PrintReportButton
                            noLayout
                            url={reportUrl}
                            disabled={!CustomerSupplyInputSelect?.lotNo}
                        >
                            {t("buttonName.lotInfoPrint")}
                        </PrintReportButton>

                    </BasicCrudScreenActionItemLayout>
                )}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </div>
    );
}

export default MomSysCustomerSupplyInput;
