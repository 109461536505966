import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function ConsumablesIoManage(props) {
  return (
    <BasicCrudScreenType
      noCreate
      noDelete
      noExcel
      noEdit
      table="ConsumablesInOutput"
      view="VwConsumablesInOutput"
    />
  );
}

export default ConsumablesIoManage;
