import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function UpdateRecordButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(()=> {

        if( schema ) {

            let modifiedSchema = cloneDeep(schema);
        
            for (const column of modifiedSchema.columns) {
                
                switch(column.name) {

                    case "typeBuyer" :
                        column.hideInForm = true;

                    break;

                    case "typeSupplyer" :
                        column.hideInForm = true;

                        break;

                    case "typeOutSourcing" :
                        column.hideInForm = true;

                        break;

                    default :
                        break;

                }
            }
            setModifiedSchema(modifiedSchema);
        }

    },[schema])

    const onOpen = () => {
        if(grid) {
            setFormData ( {...(grid.selectedItems[0])} );
        }

        if(grid.rows.visibleLength > 0) {
            setOpen(true);
        } else { 
            onFailure("등록된 자사정보 가 없습니다.");
        }
    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUpdateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={t ( `dialog.title.MomSysUdtOwnCompany.update`) }
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>

    );
}

export default UpdateRecordButton;

