import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

function PopProductShipLotView(props) {
    const { shipmentSelect } = props;
    const id = "PopProductShipLotView";

    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [shipLotSelect, setShipLotSelect] = useState();
    const [reportUrl, setReportUrl] = useState();

    const { t } = useTranslation();

    useEffect(() => {

        const path = `popProductShipDetail/${shipLotSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }
        setReportUrl ( url );

    }, [shipLotSelect]);    

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setShipLotSelect(grid.selectedItems[0]);
                    } else {
                        setShipLotSelect([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setShipLotSelect(grid.selectedItems[0]);
                    } else {
                        setShipLotSelect([]);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentSelect]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    return (
        <BasicCrudScreenType 
            id="PopProductShipDetail" 
            table="PopVwProductShipDetail"
            view="PopVwProductShipDetail" 
            headerTitle={t("term.shipDetailLog")}
            onApiInitialized={onApiInitialized}
            onInitialized={onInitialized}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <PopCrudScreenActionItemLayout>
                        <PrintReportButton
                            noLayout
                            url={reportUrl}
                            disabled={!shipLotSelect?.lotNo}
                        >
                            {t("buttonName.lotInfoPrint")}
                        </PrintReportButton>
                    </PopCrudScreenActionItemLayout>
                    
                </>
            )}
        />
    );
}
export default PopProductShipLotView;
