import { useState, useEffect } from 'react';

import EguSalesStatusSummaryTable from './EguSalesStatusSummaryTable';

function EguSalesStatusSummaryPage ( props ) {

    const { salesStatus, onComplete } = props;

    const [, setTimer] = useState();

    useEffect ( () => {

        if ( salesStatus ) {

            setTimer ( ( timer ) => {

                if ( ! timer ) {
    
                    let newTimer = setTimeout ( () => {

                        if ( onComplete ) {
    
                            setTimer ( undefined );
                            onComplete ( salesStatus.start + salesStatus.length, salesStatus.length, salesStatus.interval );
    
                        }
    
                    }, salesStatus.interval * 1000 );
    
                    return newTimer;
    
                };
    
                return timer;
    
            } );

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesStatus] );

    return <EguSalesStatusSummaryTable data={salesStatus?.data} />;
}

export default EguSalesStatusSummaryPage;
