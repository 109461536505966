import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "16px",
        paddingLeft: "32px",
        paddingBottom: "16px"
    },
  }));

function AutoFormGroupLayout ( props ) {

    const { children, others } = props;

    const classes = useStyles();

    return (

        <div className={classes.root} {...others} >
            { children }
        </div>

    );
}

export default AutoFormGroupLayout;

  