import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";

import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";

import EguLabDataSheetDetailCreateButton from "../input/EguLabDataSheetDetailCreateButton";

function EguLabDataSheetDetail(props) {

    const { selectMst, setApiDetail } = props;

    const [tableSchema, setTableSchema] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [isEmpty, setIsEmpty] = useState();
    const [no, setNo] = useState(0);

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {

        if (api) {

            api.refresh();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMst?.eguLabDataSheetId]);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguLabDataSheetDetail");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);


    const onLoadData = (data) => {
        if (data) {
            setNo(data.length)
        }
        setIsEmpty(data.length < 1 ? true : false)

    };

    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {
        setApiDetail(api)
        setApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;

    }

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <EguLabDataSheetDetailCreateButton
                            id="EguLabDataSheetDetail"
                            selectMst={selectMst}
                            no={no}
                            schema={tableSchema}
                            color="primary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            disabled={selectMst?.approvalState ? true : false || !selectMst?.eguLabDataSheetId}
                            refresh={api}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id="EguLabDataSheetDetail"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={selectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguLabDataSheetDetail"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={selectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id="EguLabDataSheetDetail"
                            grid={grid}
                            view="EguLabDataSheetDetail"
                            color="primary"
                            disabled={isEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <>
            <BasicCrudScreenType
                id="EguLabDataSheetDetail"
                table="EguLabDataSheetDetail"
                view="EguLabDataSheetDetail"
                headerTitle={t("term.experimentDetail")}
                noCreate
                noEdit
                noDelete
                noExcel
                onLoadData={onLoadData}
                onInitialized={onInitialized}
                onApiInitialized={onApiInitialized}
                onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                onModifyViewUrl={(url) => url + '/' + selectMst?.eguLabDataSheetId}
                exportFormatItem={() => { }}
            />
        </>

    );
}

export default EguLabDataSheetDetail;
