import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function AdditionalWorkDayManage ( props ) {

    return <BasicCrudScreenType id="AdditionalWorkDay" table="AdditionalWorkDay" view="AdditionalWorkDay" noEdit noExcel />

}

export default AdditionalWorkDayManage;

