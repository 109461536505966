/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useHistory } from "react-router-dom";
/* eslint-disable react/jsx-no-target-blank */
import { Button, TextField, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
    unstable_createMuiStrictModeTheme,
    ThemeProvider,
    makeStyles,
} from "@material-ui/core/styles";import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import urlencode from "urlencode";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

let theme = unstable_createMuiStrictModeTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: "#18202c",
            },
        },
        MuiButton: {
            label: {
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                bottom: 8,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
                margin: "0 16px",
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
            textColorPrimary: {
                color: theme.palette.primary.contrastText
            },
            textColorSecondary: {
                color: theme.palette.secondary.contrastText
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 16,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const useStyles = makeStyles((theme) => ({
    background: {
        background: "white",
        resize: "both",
        padding: 0,
        margin: 0,
        width: "100vw",
        height: "100vh",
    },
    loginBox: {
        position: "absolute",
        backgroundColor: "white",
        width: "900px",
        top: "50%",
        left: "50%",
        marginTop: "-280px",
        marginBottom: 0,
        marginLeft: "-450px",
        marginRight: 0,
        padding: 0,
    },
    loginForm: {
        width: "100%",
        gridColumn: "2/3",
        gridRow: "2/3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
    },
    textField: {
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
    },
    gridContainer: {
        display: "grid",
        gridGap: 0,
        gridTemplateColumns: "450px 450px",
        gridTemplateRows: "450px auto",
    },
    left: {
        width: "450px",
        height: "450px",
        gridColumn: "1/2",
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        gridTemplateRows: "15% 70% 15%",
    },
    right: {
        backgroundColor: "red",
        height: "450px",
        gridColumn: "2/3",
    },
    footer: {
        gridColumn: "1/3 ",
        gridRow: "2/3",
        height: "110px",
        marginTop: "auto",
    },
    copyright: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
    logo: {
        gridRow: "1/2",
        gridColumn: "2/3",
        padding: 0,
    },
    logoImage: {
        marginLeft: "-15px",
        width: "190px",
    },
    image: {
        marginLeft: "40px",
        width: "420px",
    },
    button: {
        marginTop: "20px",
        width: "50%",
    },
    freepik: {
        position: "absolute",
        top: "395px",
        left: "745px",
    },
    anchor: {
        color: "gray",
        textDecoration: "none",
        fontSize: "9px",
    },
    checkAndLink: {
        gridColumn: "2/3",
        gridoRow: "3/4",
        display: "grid",
        gridTemplateColumns: "36% 32% 32%",
        height: "20px",
        marginTop: "10px",
    },
    checkBox: {
        gridColumn: "1/2",
    },
    findPassword: {
        gridColumn: "2/3",
        alignItems: "center",
    },
    customerService: {
        gridColumn: "3/4",
        alignItems: "center",
    },
    companyInfo: {
        textAlign: "center",
        margin: "3px",
        fontSize: "12px",
    },
}));

function ChangePassword ( props ) {

    const location = useLocation ();
    const history = useHistory ();

    const classes = useStyles();

    const { t } = useTranslation();

    const [ , setDialog ] = useState ();
    const [ password, setPassword ] = useState ();
    const [ passwordCheck, setPasswordCheck ] = useState ();
    const [ notification, setNotification ] = useState(false);
    const [ tenant, setTenant ] = useState ();
    const [ username, setUsername ] = useState ();
    const [ authToken, setAuthToken ] = useState ();

    const onConfirmDialogInitialized = ( dialog ) => {

        setDialog ( dialog );

    };

    const onChangePassword = ( e ) => {

        setPassword ( e.target.value );

    };

    const onChangePasswordCheck = ( e ) => {

        setPasswordCheck ( e.target.value );

    };

    const onConfirmed = ( tenant, username, authToken, password ) => {

        ( async () => {

            try {

                await apiClient.post ( "/auth/resetPassword", {

                    tenant: tenant,
                    username: username,
                    authToken: authToken,
                    password: password
    
                } );

                setTimeout ( () => {

                    setNotification({
                        severity: "success",
                        msg: t("success.passwordChanged"),
                        open: true,
                    });

                    setTimeout ( () => history.push ( "/" ), 2000 );;

                }, 200 );
                
            } catch ( reason ) {

                setTimeout ( () => {

                    setNotification({
                        severity: "error",
                        msg: t("error.expiredToken"),
                        open: true,
                    });
                    
                    setTimeout ( () => history.push ( "/" ), 6000 );;

                }, 200 );

            }
    
        } ) ();

    };

    const onSubmit = ( password, passwordCheck ) => {

        if ( password === passwordCheck ) {

            setDialog ( ( dialog ) => {

                dialog.show ();
    
                return dialog;
    
            } );
    
        } else {

            setNotification({
                severity: "warning",
                msg: t("error.passwordNotMatch"),
                open: true,
            });
    
        }

    };

    useEffect ( () => {

        let [ , tenant, username, authToken ] = urlencode.decode( location.hash ).split ("#");

        setTenant ( tenant );
        setUsername ( username );
        setAuthToken ( authToken );

        if ( !tenant || tenant.length === 0 || !username || username.length === 0 || !authToken || authToken.length === 0 ) {

            setTimeout ( () => {

                setNotification({
                    severity: "error",
                    msg: t("error.noSuchUser"),
                    open: true,
                } );
    
                setTimeout ( () => history.push ( "/" ), 6000 );;
    
            }, 200 );

        }

        ( async () => {

            try {

                await apiClient.post ( "/auth/verifyResetPasswordToken", {

                    tenant: tenant,
                    username: username,
                    authToken: authToken,
    
                } );
        
            } catch ( reason ) {

                setTimeout ( () => {

                    setNotification({
                        severity: "error",
                        msg: t("error.expiredToken"),
                        open: true,
                    });
                    
                    setTimeout ( () => history.push ( "/" ), 6000 );;

                }, 200 );

            }
    
        } ) ();

    }, [location.hash] )


    const onCloseNotification = () => {
        
        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    return (


        <ThemeProvider theme={theme}>

            <div className={classes.background}>
                <div className={classes.loginBox}>
                    <div className={classes.gridContainer}>
                        <div className={classes.freepik}>
                            <a href="http://www.freepik.com" className={classes.anchor} target="_blank" >
                                Image Designed by Freepik
                            </a>
                        </div>
                        <div className={classes.left}>
                            <div className={classes.logo}>
                                <img src="/images/logo.png" alt="ariatech logo" className={classes.logoImage} />
                            </div>
                            <form id="loginForm" className={classes.loginForm} autoComplete="off" >
                                <TextField

                                    className={classes.textField}
                                    id="company"
                                    disabled={true}
                                    data-testid="company"
                                    name="company"
                                    label={t("term.company")}
                                    readonly
                                    value={tenant}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField

                                    className={classes.textField}
                                    id="username"
                                    disabled={true}
                                    data-testid="username"
                                    name="username"
                                    label={t("term.userName")}
                                    readonly
                                    value={username}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField

                                    className={classes.textField}
                                    id="new-password"
                                    data-testid="new-password"
                                    name="new-password"
                                    label={t("term.newPassword")}
                                    type="password"
                                    onChange={onChangePassword}
                                    value={password}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        tabIndex: "1"
                                    }}
                                    autoFocus

                                />
                                <TextField

                                    className={classes.textField}
                                    id="password-check"
                                    data-testid="password-check"
                                    name="password-check"
                                    label={t("columnName.passwordCheck")}
                                    type="password"
                                    onChange={onChangePasswordCheck}
                                    value={passwordCheck}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        tabIndex: "2"
                                    }}

                                />
                                <Button
                                    className={classes.button}
                                    id="login"
                                    disabled={false}
                                    data-testid="login"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    tabIndex="3"
                                    onClick={ () => onSubmit ( password, passwordCheck ) }
                                >

                                    {t("buttonName.changePassword")}

                                </Button>
                            </form>
                        </div>
                        <div className={classes.marginRight}>
                            <img src="/images/password.jpg" className={classes.image} alt="change password form" />
                        </div>
                        <div className={classes.footer}>
                            <div>
                                <br />
                                <p className={classes.companyInfo}>
                                    {t("ariatech.zip")} {t("ariatech.address")}  {t("ariatech.companyName")} {t("ariatech.ceo")} {t("ariatech.bizLicNo")}
                                </p>
                                <p className={classes.companyInfo}>
                                    {t("ariatech.email")} {t("ariatech.tel")} {t("ariatech.fax")}
                                </p>
                                <br />
                            </div>
                            <div className={classes.copyright}>
                                Copyright © &nbsp;
                                <a href="https://ariatech.kr/" style={{ textDecoration: "none" }}>
                                    {t("ariatech.copyright")}
                                </a>{" "}
                                &nbsp; 2022
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmDialog

                    id={"reset-password-confirm-dialog"}
                    title={ t("dialog.title.ConfirmResetPassword") }
                    msg={ t("dialog.msg.ConfirmResetPassword") }
                    onOk={ () => onConfirmed ( tenant, username, authToken, password ) }
                    onInitialized={ onConfirmDialogInitialized }

                />

                <Snackbar
                    open={notification.open}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        { notification.msg }
                    </Alert>    
                </Snackbar>
            </div>


        </ThemeProvider>

    );
}

export default ChangePassword;

