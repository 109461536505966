import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { debounce } from "lodash";

import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import EguOutSourcingMaterialReturnDetailRegisterButton from "../input/EguOutSourcingMaterialReturnDetailRegisterButton";
import EguOutSourcingMaterialReturnDetailUpdateButton from "../input/EguOutSourcingMaterialReturnDetailUpdateButton";

function EguOutSourcingMaterialReturnDetail(props) {
    const { outSourcingMaterialReturnMstSelect, onDetailApi, onDetailEmpty } = props;

    const id = "EguOutSourcingMaterialReturnDetail";
    const table = "EguOutSourcingMaterialReturnDetail";
    const view = "EguVwOutSourcingMaterialReturnDetail";

    const [outSourcingMaterialReturnDetailSchema, setOutSourcingMaterialReturnDetailSchema] = useState();
    const [outSourcingMaterialReturnDetailGrid, setOutSourcingMaterialReturnDetailGrid] = useState();
    const [outSourcingMaterialReturnDetailData, setOutSourcingMaterialReturnDetailData] = useState();
    const [outSourcingMaterialReturnDetailApi, setOutSourcingMaterialReturnDetailApi] = useState();
    const [outSourcingMaterialReturnDetailIsEmpty, setOutSourcingMaterialReturnDetailIsEmpty] = useState();
    const [, setEguOutSourcingMaterialReturnDetailSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setOutSourcingMaterialReturnDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (outSourcingMaterialReturnDetailApi) {
            outSourcingMaterialReturnDetailApi.refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outSourcingMaterialReturnMstSelect.materialReturnId]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outSourcingMaterialReturnDetailGrid && mounted) {
                outSourcingMaterialReturnDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingMaterialReturnDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingMaterialReturnDetailSelect();
                        }
                    },10)
                );

                outSourcingMaterialReturnDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingMaterialReturnDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingMaterialReturnDetailSelect();
                        }
                    },10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outSourcingMaterialReturnDetailGrid]);

    /***
    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outSourcingMaterialReturnMstSelect && outSourcingMaterialReturnDetailData && mounted) {
                const { data: componentList } = await getSpecific(
                    "EguVwOutSourcingPurchaseDetailInOutMaterial",
                    outSourcingMaterialReturnMstSelect.outSourcingMaterialCode,
                    outSourcingMaterialReturnMstSelect.outSourcingMaterialCodeVer,
                    outSourcingMaterialReturnMstSelect.startProcessCode,
                    outSourcingMaterialReturnMstSelect.endProcessCode,
                    outSourcingMaterialReturnMstSelect.outSourcingQty
                );

                for (const item of componentList) {
                    const mat = outSourcingMaterialReturnDetailData.find(
                        (v) => v.materialCode === item.inOutMaterialCode && v.materialCodeVer === item.inOutMaterialVer
                    );

                    if (mat) {
                        mat.componentQty = item.componentQty;
                    }
                }

                if (outSourcingMaterialReturnDetailGrid) {
                    outSourcingMaterialReturnDetailGrid.refresh(false); // false : refresh grid content only
                }
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outSourcingMaterialReturnMstSelect, outSourcingMaterialReturnDetailData, outSourcingMaterialReturnDetailGrid]);
    ***/

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.eguOutSourcingMaterialReturnDetail")}
            table={table}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={(url) => `${url}/${outSourcingMaterialReturnMstSelect.materialReturnId}`}
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setOutSourcingMaterialReturnDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                if (onDetailApi) {
                    onDetailApi(api);
                }

                setOutSourcingMaterialReturnDetailApi(api);
            }}
            onLoadData={(data) => {
                const isDetailEmpty = data.length < 1 ? true : false;

                setOutSourcingMaterialReturnDetailData(data);
                setOutSourcingMaterialReturnDetailIsEmpty(isDetailEmpty);

                if (onDetailEmpty) {
                    onDetailEmpty(isDetailEmpty);
                }
            }}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <EguOutSourcingMaterialReturnDetailRegisterButton
                        id={id}
                        schema={outSourcingMaterialReturnDetailSchema}
                        refresh={outSourcingMaterialReturnDetailApi}
                        disabled={
                            outSourcingMaterialReturnMstSelect?.inputDate ? true : false ||
                            outSourcingMaterialReturnMstSelect?.length <= 0 ? true : false
                        }
                        outSourcingMaterialReturnMstSelect={outSourcingMaterialReturnMstSelect}
                        onSuccess={onSuccess}
                    />

                    <EguOutSourcingMaterialReturnDetailUpdateButton
                        id={id}
                        schema={outSourcingMaterialReturnDetailSchema}
                        grid={outSourcingMaterialReturnDetailGrid}
                        refresh={outSourcingMaterialReturnDetailApi}
                        disabled={
                            outSourcingMaterialReturnMstSelect?.inputDate || outSourcingMaterialReturnDetailIsEmpty
                        }
                        onSuccess={onSuccess}
                    />

                    <DeleteSingleRecordButton
                        id={id}
                        schema={outSourcingMaterialReturnDetailSchema}
                        grid={outSourcingMaterialReturnDetailGrid}
                        refresh={outSourcingMaterialReturnDetailApi}
                        makeItemName={(row) => {
                            return `${t("columnName.returnedMaterialId")}: ${row.returnedMaterialId}`;
                        }}
                        color="secondary"
                        disabled={
                            outSourcingMaterialReturnMstSelect?.inputDate || outSourcingMaterialReturnDetailIsEmpty
                        }
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default EguOutSourcingMaterialReturnDetail;
