import apiClient from "../../lib/common/apiClient";

let optionCache;
let cacheExpireTime;

async function getOptions () {

    let current = new Date ();

    if ( !cacheExpireTime || current.getTime () > cacheExpireTime ) {

        let result = await apiClient.get("/api/OptionList");

        optionCache = result.data;
        cacheExpireTime = ( new Date () ).getTime () + ( ( process.env.NODE_ENV === 'production' ) ? ( 1000 * 60 ) : 0 );

    }

    return optionCache;

}

async function getOption ( path, name ) {

    let options = await getOptions ();

    if ( options[path] && options[path][name] ) {

        return options[path][name];

    }

    return undefined;

}

export default getOption;
export { getOption, getOptions };
