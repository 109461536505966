import { makeStyles } from "@material-ui/styles";
import { Toolbar, Grid } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
    root: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "5px",
        paddingRight: "5px",
        borderBottom: "1px solid rgb(212, 212, 212)",
    },
}));

function BasicCrudActionsLayout ( props ) {

    const classes = useStyle ();

    const { children, className, ...others } = props;

    return (

        <Toolbar className={classes.root} >

            <Grid container spacing={1} alignItems="stretch" style={{flexWrap: "nowrap"}}>

                {
                    React.Children.map(children, ( child => 
                        React.cloneElement(child, others) ) )
                }

            </Grid>

        </Toolbar>

    );

}

export default BasicCrudActionsLayout;

