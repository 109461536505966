import apiClient from "../common/apiClient";

async function getSpecific() {

    let path = "";

    for ( let arg of arguments ) {

        path += "/" + arg;
        
    }

    let response = await apiClient.get(`/api${path}`);

    return response.data;

}

export default getSpecific;