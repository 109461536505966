function getLocalizationConf() {
    return {
        useDbResource: false,
        dbResourceName: "TranslationResources",
        languageColumn: "lang",
        resourceColumn: "resource",
        useLanguageDetection: true,
        fallBackLanguages: ["kr", "en"],
        serverLoggingLangauge: "en",
        useKeySeparator: true,
        interpolation: {
            escapeValue: false,
        },
        cookieName: "lang",
        debug: true,
        resources: {
            "en":
            {
                "translation":
                {
                    "popupMenu":
                    {
                        "Approval":
                        {
                            "request": "Request",
                            "approve": "Approve",
                            "reject": "Reject",
                            "withdraw": "Withdraw",
                            "cancel": "Cancel"
                        }
                    },
                    "tabMenu":
                    {
                        "tab":
                        {
                            "day": "Day",
                            "week": "Week",
                            "month": "Month",
                            "custom": "User Custom"
                        }
                    },
                    "menu":
                    {
                        "MenuManage": "Menu Manage",
                        "UserManage": "User Manage",
                        "MenuItemManage": "Menu Item Manage",
                        "MenuSetManage": "Menu Set Manage",
                        "TopMenuManage": "Top Menu Manage",
                        "SubMenuManage": "Sub Menu Manage",
                        "RoleManage": "Role Manage",
                        "RoleSetManage": "Role Set Manage",
                        "RoleSetDetail": "Role Set Detail Manage",
                        "System": "System",
                        "Profile": "Profile",
                        "Approval": "Approval Manage",
                        "ApprovalManage": "Approval Manage",
                        "ApprovalTypeManage": "Approval Type Manage",
                        "ApproverManage": "Approver Manage",
                        "ApprovalPathManage": "Approval Path Manage",
                        "ApprovalHistory": "Approval History",
                        "ApprovalPathDetailManage": "Approval Path Detail Manage",
                        "InventoryManage": "Inventory Manage",
                        "MaterialIoManage": "Material Input Output Record",
                        "MaterialInventoryManage": "Material Inventory Manage",
                        "BaseInfo": "BaseInfo",
                        "Item": "Item",
                        "ItemUnit": "ItemUnit",
                        "ItemType": "ItemType",
                        "ItemSpec": "ItemSpec",
                        "Vendor": "Vendor",
                        "Business": "Business",
                        "Po": "Po",
                        "So": "So Manage",
                        "Estimate": "Estimate Manage",
                        "Shipment": "Shipment Manage",
                        "ReturnedItem": "Returned Item Manage",
                        "ProduceManage": "Produce Manage",
                        "WorkerPerformance": "Worker Performance",
                        "ProductPerformance": "Product Performance",
                        "ConsumablesManage": "Consumables Manage",
                        "ConsumablesIoManage": "Consumables Input Output Record",
                        "ConsumablesInventoryManage": "Consumables Inventory Manage",
                        "Customer": "Customer",
                        "ProductManage": "Product Manage",
                        "ProductIoManage": "Product Input Output Record",
                        "ProductInventoryManage": "Product Inventory",
                        "AccessControlManage": "Access Control",
                        "AccessControlTarget": "Access Control Target",
                        "AccessControlRule": "Access Control Rule",
                        "Tenant": "Tenant Management",
                        "DbConf": "DB Configuration Management",
                        "Namespace": "Namespace Management",
                        "SupportedLanguage": "Supported Language Management",
                        "Translation": "Translation Management",
                        "TenantLogo": "Tenant Logo Image",
                        "Logout": "Logout",
                        "TenantOption": "Tenant Option",
                        "MomSysBomBopMgmt": "Process Information",
                        "MomSysBomBop": "Bom Bop Information Manage",
                        "MomSysMasterDataMgmt": "Master Infomation",
                        "MomSysProcessInfo": "Process Information",
                        "MomSysProcess": "Process Manage",
                        "MomSysDecisionCode": "Common Code",
                        "MomSysCompany": "Company Manage",
                        "MomSysWorkStation": "WorkStation",
                        "MomSysWareHouse": "WareHouse",
                        "MomSysMaterialInfo": "Material Information",
                        "MomSysMachineMainType": "Machine Maintenance Type",
                        "MomSysMachine": "Machine Management",
                        "MomSysMachineManage": "Machine Management",
                        "MomSysMachineMaintenance": "Machine Maintenance History",
                        "MomSysNotice": "Notice",
                        "MomSysBusinessMgmt": "Business Management",
                        "MomSysOrderBuyer": "Order Buyer Master",
                        "MomSysOrderPurchase": "Order Purchase Master",
                        "MomSysShipByOrderBuyer": "Ship By OrderBuyer Master",
                        "MomSysCompanyCollection": "Company Collection",
                        "MomSysSales": "Sales",
                        "MomSysCollections": "Collections",
                        "MomSysCollectionsReport": "Collections Report",
                        "MomSysMaterialMgmt": "Material Managemnet",
                        "MomSysMaterialError": "Material Error",
                        "MomSysMaterialInput": "Material Input",
                        "MomSysStockTakingMst": "Material StockTaking",
                        "MomSysMaterialShip": "Material Ship",
                        "MomSysMaterialOutSourcing": "Material OutSourcing",
                        "MomSysMaterialBackShip": "Material BackShip",
                        "MomSysMergeSplit": "Merge/Split",
                        "MomSysProductionMgmt": "Production Infomation",
                        "MomSysProductionStatus": "Production Status",
                        "MomSysSoProduction": "SO Production",
                        "MomSysMatProduction": "Product Production",
                        "MomSysLotTransHistory": "LOT Transaction History",
                        "MomSysLotTrace": "Trace LOT Transaction",
                        "MomSysProductionErrorHistory": "Production Error History",
                        "MomSysProductionCancelHistory": "Production Cancel History",
                        "MomSysEditMaterialStatus": "Material Management",
                        "MomSysMaterialStatus": "Material Status",
                        "MomSysBopHistory": "BOP History",
                        "MomSysBomHistory": "BOM History",
                        "MomSysPurchase": "Purchase Management",
                        "MomSysProductStatus": "Product Status",
                        "MomSysCodeGenerate": "Code Generate",
                        "MomSysMaterialByCompany": "Material By Company",
                        "MomSysLotManage": "LOT Management",
                        "MomSysProcessTime": "Process Time",
                        "MomSysProductionTime": "Production Time",
                        "MomSysOwnCompany": "Own Company",
                        "MomSysAdvancePayment": "Advance Payment",
                        "MomSysProductMgmt": "Performance Management",
                        "MomSysQualityMgmt": "Quality Management",
                        "MomSysPerformanceMgmt": "Performance Management",
                        "MomSysProductInput": "Product Input",
                        "MomSysProductShip": "Product Ship",
                        "MomSysProductStockMgmt": "Product Stock Management",
                        "MomSysInputTest": "Input Test",
                        "MomSysFrequentTest": "Frequent Test",
                        "MomSysSalesAnalysis": "Sales Analysis",
                        "MomSysPurchaseAnalysis": "Purchase Analysis",
                        "MomSysCostAnalysis": "Cost Analysis",
                        "MomSysPerformanceIndex": "Performance Index",
                        "MomSysOutSourcingMgmt": "OutSourcing Management",
                        "MomSysOutSourcingStatus": "OutSourcing Status",
                        "MomSysOutSourcingInput": "OutSourcing Input",
                        "MomSysOutSourcingShip": "OutSourcing Ship",
                        "MomSysOutSourcingOrderPurchase": "OutSourcing OrderPurchase",
                        "MomSysSalesCollections": "Sales Collections",
                        "MomSysPurchaseExpense": "Purchase Expense",
                        "EguMaterialInput": "Material Input",
                        "TKInsertMgmt": "Insert Management",
                        "LocalizationManagement": "Localization Management",
                        "RoleSetTranslation": "Role Set Translation",
                        "TenantTranslation": "Tenant Translation",
                        "TranslationSearch": "Translation Search",
                        "Company": "\bCompany",
                        "MomSysMaterialType": "Material Type",
                        "MomSysLineNo": "Line No",
                        "MomSysExpense": "Expense",
                        "MomSysProcessReport": "Process Report",
                        "MomSysProductionReport": "Production Report",
                        "TkUglOrderBuyerMst": "Order Buyer Mst",
                        "MomSysMaterialStockMgmt": "Material Stock Management",
                        "ConfigMgmt": "Config Manage",
                        "RoleSetOption": "Role Set Option",
                        "DefaultOption": "Default Option",
                        "InBox": "InBox",
                        "Trash": "Trash",
                        "MsgSent": "Message Sent",
                        "TkUglOrderPurchaseMst": "Order Purchase Mst",
                        "MomSysDelivery": "MomSysDelivery",
                        "MomSysShipment": "MomSysShipment",
                        "EguUglOrderBuyerMst": "Order Buyer Mst",
                        "EguUglOrderPurchaseMst": "Order Purchase Mst",
                        "MomSysBackShip": "Back Ship",
                        "EguMaterialFakeInput": "EguMaterialFakeInput",
                        "EGuMaterialStatus": "EguMaterialStatus",
                        "EguProductStatus": "EguProductStatus",
                        "EguMaterialStatus": "EguMaterialStatus",
                        "EguProductShip": "productShip",
                        "TKShipment": "TKShipment",
                        "MomSysProductInputStatus": "productInputStatus",
                        "PopNotice": "PopNotice",
                        "PopProductManage": "Pop Product Manage",
                        "PopProduce": "Pop Produce",
                        "PopLead": "Pop Lead",
                        "PopWood": "Pop Wood",
                        "PopEtc": "Pop Etc",
                        "MomSysDefectiveStatus": "Defective Status",
                        "MomSysSelfInspection": "Self Inspection",
                        "MomSysInputInspection": "Input Inspection",
                        "MomSysInspectionEntity": "Inspection Entity Management",
                        "MaterialInputStatus": "materialInputStatus",
                        "EguMaterialInputStatus": "EguMaterialInputStatus",
                        "MomSysProductionPlan": "Production Plan",
                        "EguItemInfo": "Item Info",
                        "EguItemByCompany": "Item By Company",
                        "EguResearchMgmt": "ResearchManagement",
                        "EguProductSample": "ProductSample",
                        "EguSubMaterialSample": "SubMaterial Sample",
                        "MomSysLotStateChange": "MomSysLotStateChange",
                        "EguLotStateChange": "EguLotStateChange",
                        "MomSysMergeSplitHistory": "MomSysMergeSplitHistory",
                        "EguMergeSplitHistory": "EguMergeSplitHistory",
                        "DeleteLink": "Delete Link",
                        "DeleteTask": "Delete Task",
                        "AssignWorker": "Assign Worker",
                        "AssignMachine": "Assign Machine",
                        "SplitTask": "Split Task",
                        "OrderTask": "Order Task",
                        "ModifyDuration": "Modify Task Duration",
                        "CancelOrder": "Cancel Order",
                        "MomSysWageRate": "Wage Rate Management",
                        "EguOutSourcingMgmt": "EguOutSourcingMgmt",
                        "EguOutSourcingShip": "EguOutSourcingShip",
                        "EguOutSourcingPurchase": "OutSourcing Purchase",
                        "AddMilestone": "Add Milestone",
                        "ChangeMilestoneName": "Change Milestone Name",
                        "PerformanceMetric": "Performance Metric",
                        "EguOutSourcingFakeInput": "EguOutSourcingFakeInput",
                        "OpenAll": "Open All",
                        "CloseAll": "Close All",
                        "EguOutSourcingInputInspection": "OutSourcing Input Inspection",
                        "CapacityManage": "Capacity Manage",
                        "HolidayManage": "Holiday Manage",
                        "AdditionalWorkDayManage": "Additional Work Day Manage",
                        "WorkDayManage": "Work Day Manage",
                        "WorkerDayOfWeekCapacityManage": "Worker Day of Week Capacity Manage",
                        "EguStandardInfoSaveManagemet": "Standard Info Save",
                        "EguItemExcelUpload": "Item Info",
                        "EguCustomerExcelUpload": "Customer Info",
                        "EguUserExcelUpload": "User Info",
                        "WorkerDateCapacityManage": "Worker Date Capacity Manage",
                        "MachineDateCapacityManage": "Machine Date Capacity",
                        "DefaultWorkerDayOfWeekCapacityManage": "Day of Week Capacity",
                        "DefaultMachineCapacityManage": "Default Machine Capacity",
                        "EguOutSourcingMaterialReturn": "OutSourcing Material Return",
                        "EguPopOtherWork": "Other Work",
                        "Dashboard": "Dashboard",
                        "EguSystemManage": "EGU System Management",
                        "EguAdministratorAccountManage": "EGU Administrator Account Management",
                        "EguUserInformationManage": "EGU User Information Management",
                        "EguUserGroupManage": "EGU User Group Management",
                        "EguMachineRental": "Machine Rental Management",
                        "MrpMaterialList": "MRP Material List",
                        "ManagementIndicator": "Management Indicator",
                        "EguProductionStatus": "Production Status",
                        "EguSalesStatus": "Sales Status",
                        "EguProductionMonitoring": "Production Monitoring",
                        "EguSalesMonitoring": "Sales Monitoring",
                        "EguLaborCost": "EguLaborCost",
                        "EguCostInfoMgmt": "EguCostInfoMgmt",
                        "EguIngredientRegistration": "EguIngredientRegistration",
                    },
                    "tableName":
                    {
                        "MenuItem": "Menu Item",
                        "MenuSet": "Menu Set",
                        "Role": "Role",
                        "SubMenuItems": "Sub Menu Items",
                        "TopMenuItems": "Top Menu Items",
                        "User": "User",
                        "WorkTime": "Work Time",
                        "VwSubMenuItems": "Sub Menu Items",
                        "VwTopMenuItems": "Top Menu Items",
                        "VwUser": "User",
                        "ApprovalType": "Approval Type",
                        "Approver": "Approver",
                        "ApprovalPath": "Approval Path",
                        "MaterialInput": "Material Input",
                        "MaterialOutput": "Material Output",
                        "MaterialInventory": "Material Inventory",
                        "Item": "Item",
                        "ItemUnit": "Item Unit",
                        "ItemType": "Item Type",
                        "ItemSpec": "Item Spec",
                        "Vendor": "Vendor",
                        "Po": "Po",
                        "So": "So",
                        "PoDetail": "Po Detail",
                        "EstimateBase": "Estimate Base",
                        "EstimateVersion": "Estimate Version",
                        "EstimateDetail": "Estimate Detail",
                        "VwEstimateBase": "Estimate Base",
                        "VwEstimateVersion": "Estimate Version",
                        "VwEstimateDetail": "Estimate Detail",
                        "ReturnedItem": "Returned Item",
                        "Shipment": "Shipment",
                        "ShipmentDetail": "Shipment Detail",
                        "MomSysUdtLineNo": "Line No",
                        "MomSysUdtWorkStation": "WorkStation",
                        "MomSysUdtWareHouse": "WareHouse",
                        "MomSysUglOrderBuyerMst": "Order Buyer Master",
                        "MomSysUglOrderBuyerDetail": "Order Buyer Detail",
                        "MomSysUglOrderPurchaseMst": "Order Purchase Master",
                        "MomSysUglOrderPurchaseDetail": "Order Purchase Detail",
                        "MomSysUglCompanyCollection": "Company Collection",
                        "MomSysSales": "Sales",
                        "MomSysSalesDetail": "Sales Detail",
                        "MomSysCollections": "Collections",
                        "MomSysCollectionsDetail": "Collections Detail",
                        "MomSysCollectionsReport": "Collections Report",
                        "MomSysUdtBillOfProcess": "BOP Process",
                        "MomSysUdtBillOfMaterial": "BOM Material",
                        "MomSysCodeGenerate": "Code Generate",
                        "MomSysZvwMaterial": "Material",
                        "MomSysUglStockTakingMst": "Stock Taking",
                        "MomSysPurchaseDetail": "Purchase Detail",
                        "MomSysExpenseDetail": "Expense Detail",
                        "TenantTranslation": "Tenant Translation",
                        "RoleSetTranslation": "Role Set Translation",
                        "SoDetail": "Sales Order Detail",
                        "TenantOption": "Tenant Option",
                        "RoleSetOption": "Role Set Option",
                        "DefaultOption": "Default Option",
                        "MomSysPurchase": "Purchase",
                        "MomSysShipment": "tableName.MomSysShipment",
                        "MomSysShipmentDetail": "tableName.MomSysShipmentDetail",
                        "TkUglOrderBuyerMst": "Order Buyer",
                        "TkUglOrderBuyerDetail": "Order Buyer Detail",
                        "TkUglOrderPurchaseDetail": "Order Purchase Detail",
                        "MomSysDelivery": "Delivery",
                        "MomSysDeliveryDetail": "DeliveryDetail",
                        "OutputMaterialList": "Output Material List",
                        "MomSysUglOrderBuyerDetailDialog": "MomSys Ugl Order Buyer Detail Dialog",
                        "MomSysUglOrderPurchaseDetailDialog": "MomSys Ugl Order Purchase Detail Dialog",
                        "MomSysWageRate": "Wage Rate",
                        "EguPopNotice": "EguPopNotice",
                        "EguOutSourcingPurchase": "OutSourcing Purchase",
                        "EguOutSourcingPurchaseDetail": "OutSourcing Purchase Detail",
                        "Holiday": "Holiday",
                        "EguUglOrderBuyerMst": "Order Buyer",
                        "EguUglOrderBuyerDetail": "Order Buyer Detail",
                        "EguUglOrderPurchaseMst": "Order Purchase",
                        "EguUglOrderPurchaseDetail": "Order Purchase Detail",
                        "MomSysBackShip": "BackShip",
                        "MomSysBackShipDetail": "BackShip Detail",
                        "EguMstMaterial": "Material",
                        "MomSysUdtCompany": "Customer",
                        "MomSysUdtDecisionCode": "Bad Code",
                        "MomSysUdtMaterialType": "Material Type",
                        "MomSysMaterialByCompany": "Material By Company",
                        "MomSysSalesCollections": "Sales Collections",
                        "MomSysPurchaseExpense": "Purchase Expense",
                        "AdvancePayment": "AdvancePayment",
                        "EguPopOtherWork": "Other Work",
                        "EguPopOtherWorkDetail": "Other Work Detail",
                        "EguMachineRental": "Machine Rental",
                        "MomSysMstMaterial": "Standard Item",
                        "EguCostInfoMgmt": "EguCostInfoMgmt",
                        "EguLaborCost": "EguLaborCost",
                        "EguProductSample": "EguProductSample",
                        "EguLabDataSheet": "EguLabDataSheet",
                        "EguLabDataSheetDetail": "EguLabDataSheetDetail",
                        "EguSubMaterialSample": "EguSubMaterialSample",
                        "EguIngredientRegistrationCaption": "EguIngredientRegistrationCaption",
                        "EguIngredientRegistration": "EguIngredientRegistration",
                        "EguVwIngredientList": "EguVwIngredientList",
                    },
                    "columnName":
                    {
                        "menuItemId": "Menu Item",
                        "menuItemName": "Menu Item Name",
                        "menuPath": "Menu Path",
                        "topMenuPath": "Top Menu Path",
                        "menuType": "Menu Type",
                        "iconType": "Icon Type",
                        "iconName": "Icon Name",
                        "description": "Description",
                        "help": "Help",
                        "menuSetId": "Menu Set",
                        "menuSetName": "Menu Set Name",
                        "roleId": "Role Id",
                        "roleName": "Role Name",
                        "roleSetId": "Role Set",
                        "roleSetName": "Role Set Name",
                        "alias": "Alias",
                        "topMenuItemId": "Top Menu Item",
                        "topMenuItemName": "Top Menu Item",
                        "subMenuItemId": "Sub Menu Item",
                        "subMenuItemName": "Sub Menu Item",
                        "order": "Order",
                        "username": "Username",
                        "password": "Password",
                        "passwordCheck": "Password Check",
                        "fullName": "Full Name",
                        "firstName": "First Name",
                        "lastName": "Last Name",
                        "workId": "Work Id",
                        "startTime": "Start Time",
                        "endTime": "End Time",
                        "createdAt": "Created Time",
                        "approvalTypeId": "Approval Type ID",
                        "approvalTypeName": "Approval Type",
                        "tableName": "Table Name",
                        "ordered": "Use Approval Order",
                        "approver": "Approver",
                        "approverFirstName": "Approver First Name",
                        "approverLastName": "Approver Last Name",
                        "approverFullName": "Approver Name",
                        "approvalPathId": "Approval Path ID",
                        "approvalPathName": "Approval Path Name",
                        "owner": "Owner",
                        "materialInventoryId": "Material Inventory Id",
                        "materialName": "Material Name",
                        "materialInventoryAmount": "Material Inventroy Amount",
                        "materialInputId": "Material Input Id",
                        "materialCode": "Material Code",
                        "materialInOutputAmount": "Material InOutput Amount",
                        "materialInOutputDate": "Material Input Output Date",
                        "consumablesCode": "Cousumables Code",
                        "consumablesInOutputAmount": "InOutput Amount",
                        "consumablesInOutputDate": "InOutput Date",
                        "consumablesInventoryAmount": "Inventory Amount",
                        "reasonAdjustment": "reason Adjustment",
                        "ioState": "Input Output State",
                        "itemUnitId": "ItemUnit Id",
                        "itemUnitName": "ItemUnit Name",
                        "itemTypeId": "ItemType Id",
                        "itemTypeName": "ItemType Name",
                        "itemSpecId": "ItemSpec Id",
                        "itemSpecName": "ItemSpec Name",
                        "itemId": "Item Id",
                        "itemName": "Item Name",
                        "itemCode": "Item Code",
                        "vendorId": "Vendor Id",
                        "vendorName": "Vendor Name",
                        "telephone": "Telephone",
                        "headPerson": "Head Person",
                        "majorAddress": "Major Address",
                        "minorAddress": "Minor Address",
                        "regUser": "Registered Username",
                        "workerName": "workerName",
                        "performanceAmount": "Performance Amount",
                        "performanceDate": "Performance Date",
                        "performanceWorkingTime": "Performance Working Time",
                        "customerId": "customer Id",
                        "customerCompanyName": "Customer Company Name",
                        "customerCompanyTel": "Customer Comapny Tel",
                        "customerCeoName": "Customer Ceo Name",
                        "customerManagerName": "Customer Manager Name",
                        "customerManagerTel": "Customer Manager Tel",
                        "poId": "Po Id",
                        "poName": "Po Name",
                        "poNumber": "Po Number",
                        "deliveryDate": "Delivery Date",
                        "cost": "Cost",
                        "poDocument": "Po Document",
                        "registDate": "Regist Date",
                        "register": "Register",
                        "registerName": "Register Name",
                        "quantity": "Quantity",
                        "basePoId": "base Po Id",
                        "poDetailId": "Po Detail Id",
                        "soId": "So Id",
                        "soName": "So Name",
                        "soNumber": "So Number",
                        "soDocument": "so Document",
                        "productOutputCheck": "product Oututput check",
                        "estimateBaseId": "Estimate Base Id",
                        "estimateId": "Estimate Id",
                        "estimateItemId": "Estimate Item Id",
                        "outerEstimateNumber": "Outer Estimate Number",
                        "estimateNumber": "Estimate Number",
                        "versionMajor": "Major Version",
                        "versionMinor": "Minor Version",
                        "baseVersionMajor": "Base Major Version",
                        "estimateName": "Estimate Name",
                        "creater": "Creater",
                        "updatedAt": "Update Time",
                        "lastWriter": "Last Writer",
                        "lastWriterName": "Last Writer Name",
                        "count": "Count",
                        "unitPrice": "Unit Price",
                        "totalPrice": "Total Price",
                        "estimatePrice": "Estimate Price",
                        "shipmentState": "Shipment State",
                        "shipmentDate": "Shipment Date",
                        "shipmentDetailState": "Shipment Detail State",
                        "shipmentManagerName": "shipment Mamager Name",
                        "shipmentDetailBeforeQuantity": "Shipment Detail Before Quantity",
                        "shipmentDetailQuantity": "Shipment Detail Quantity",
                        "soQuantity": "So Quantity",
                        "productCode": "Product Code",
                        "productName": "Product Name",
                        "productInOutputAmount": "Product InOutput Amount",
                        "proudctInventoryAmount": "Proudct Inventory Amount",
                        "productInOutputDate": "Proudct Input Output Date",
                        "returnedItem": "Returned Item",
                        "returnedItemId": "Returned Item Id",
                        "returnedItemCount": "Returned Item Count",
                        "codeGenerateId": "Code Generate Id",
                        "codeGenerateName": "Code Generate Name",
                        "prefix": "Prefix",
                        "suffix": "Suffix",
                        "addDate": "Add Date",
                        "delimiter": "Delimiter",
                        "numberOfDigits": "Number Of Digits",
                        "seq": "Sequence",
                        "eventId": "Event ID",
                        "eventTime": "Event Time",
                        "event": "Event",
                        "approvalStateId": "Approval State ID",
                        "approvalState": "Approval State",
                        "approvalDetailId": "Approval Detail ID",
                        "requestItem": "Approval Request Item Detail",
                        "requestMsg": "Approval Request Item",
                        "requester": "Approval Requster",
                        "requesterFullName": "Approval Requster Name",
                        "requesterFirstName": "Approval Requster First Name",
                        "requesterLastName": "Approval Requster Last Name",
                        "requestTime": "Approval Request Time",
                        "approveTime": "Approve Time",
                        "rejectTime": "Reject Time",
                        "withdrawTime": "Withdraw Time",
                        "cancelTime": "Cancel Time",
                        "approvalPathOwner": "Approval Path Owner",
                        "approvalPathOwnerFirstName": "Approval Path Owner First Name",
                        "approvalPathOwnerLastName": "Approval Path Owner Last Name",
                        "approvalPathOwnerFullName": "Approval Path Owner Name",
                        "approverApproveTime": "Approver Approve Time",
                        "approverRejectTime": "Approver Reject Time",
                        "approverCancelTime": "Approver Cancel Time",
                        "accessControlTargetId": "Access Control Target",
                        "accessControlTargetName": "Access Control Target Name",
                        "defaultAccess": "Default Access Rule",
                        "defaultUiConf": "Default UI Configuration",
                        "accessRule": "Access Rule",
                        "action": "Action",
                        "tenantId": "Tenant",
                        "tenantName": "TenantName",
                        "companyName": "CompanyName",
                        "optionPath": "Tenant Option Path",
                        "optionName": "Tenant Option Name",
                        "optionValue": "Tenant Option Value",
                        "email": "Email",
                        "photo": "Photo",
                        "phone": "Phone",
                        "status": "Status",
                        "namespace": "Namespace",
                        "namespaceId": "Namespace",
                        "dbConfId": "DB Configuration",
                        "dbConfName": "DB Configuration Name",
                        "dbConf": "DB Configuration",
                        "language": "Language",
                        "languageId": "Language",
                        "resources": "Translation Resources",
                        "hideUi": "Hide UI",
                        "size": "Size",
                        "image": "Image",
                        "processCode": "Process Code ",
                        "processName": "Process Name",
                        "companyCode": "Company Code",
                        "companyAlias": "Company Alias",
                        "bankCode": "Bank Name",
                        "bankAccount": "Bank Account",
                        "typeOwn": "Own",
                        "typeBuyer": "Buyer",
                        "typeSupplyer": "Supplyer",
                        "typeOutSourcing": "Out Sourcing",
                        "workStationCode": "Work Station Code",
                        "workStationName": "Work Station Name",
                        "lineNo": "Line No",
                        "materialTypeCode": "Material Type Code",
                        "materialTypeName": "Material Type Name",
                        "wareHouseCode": "WareHouse Code",
                        "wareHouseName": "WareHouse Name",
                        "wareHouseType": "WareHouse Type",
                        "decisionCode": "Code",
                        "decisionType": "Type",
                        "decisionName": "Name",
                        "img": "Image",
                        "machineMainType": "Machine Maintenance Type",
                        "machineMainDesc": "Maintenance Description",
                        "timeStart": "Maintenance Start Time",
                        "timeEnd": "Maintenance End Time",
                        "machineCode": "Machine Code",
                        "machineName": "Machine Name",
                        "available": "Available",
                        "model": "Model",
                        "serialNumber": "Serial Number",
                        "vendor": "Vendor",
                        "noticeNo": "Notice Number",
                        "visible": "Visible",
                        "notice": "Notice",
                        "orderBuyerId": "Order Buyer Id",
                        "orderBuyerName": "Order Buyer Name",
                        "orderBuyerState": "Order Buyer State",
                        "approved": "Approved",
                        "approvedShip": "Approved Ship",
                        "delivered": "Delivered",
                        "paymentMethod": "Payment Method",
                        "plCode": "PL Code",
                        "downPayment": "Down Payment",
                        "middlePayment": "Middle Payment",
                        "balancePayment": "Balance Payment",
                        "orderBuyerDate": "Order Buyer Date",
                        "dueDate": "Due Date",
                        "lotNo": "Lot No",
                        "workState": "Work State",
                        "qtyQueued": "Qty Queued",
                        "qtyCreate": "Qty Create",
                        "qtyCurrent": "Qty Current",
                        "inputInspectNo": "InSpect No",
                        "transLogId": "Trans Log ID",
                        "orderPurchaseId": "Order Purchase ID",
                        "orderPurchaseDate": "Order Purchase Date",
                        "companyBarCode": "company Barcode",
                        "companyRegNo": "company RegNo",
                        "shipLotNo": "Ship Lot No",
                        "qty": "Quantity",
                        "qtyShip": "Quantity Ship",
                        "qtyInput": "Quantity Input",
                        "outSourcing": "outSourcing",
                        "qtyTrans": "Quantity Trans",
                        "qtyNext": "Quantity Next",
                        "shipQty": "Ship Quantity",
                        "backShipQty": "BackShip Quantity",
                        "createdBy": "Created By",
                        "updatedBy": "Updated By",
                        "transaction": "Transaction",
                        "createdByName": "Creater Full Name",
                        "createdByFullName": "Creater Full Name",
                        "createdByFirstName": "Creater First Name",
                        "createdByLastName": "Creater Last Name",
                        "updatedByFullName": "Last Updater Full Name",
                        "updatedByFirstName": "Last Updater First Name",
                        "updatedByLastName": "Last Updater Last Name",
                        "bopMaterialCode": "Product Material Code",
                        "bopMaterialCodeVer": "Product Material Version",
                        "bopMaterialName": "Product Material Name",
                        "bopMaterialQty": "Prodcut Material Qty",
                        "bomMaterialCode": "Product Material Code",
                        "bomMaterialCodeVer": "Product Material Version",
                        "bomMaterialName": "Product Material Name",
                        "inOutType": "IN/OUT Type",
                        "inOutMaterialCode": "Material Code",
                        "inOutMaterialVer": "Material Version",
                        "inOutMaterialName": "Material Name",
                        "componentCode": "Component Material Code",
                        "componentCodeVer": "Component Material Version",
                        "componentName": "Component Material Name",
                        "componentQty": "Component Material Qty",
                        "processOrder": "Process Order",
                        "lotNoUnion": "Union Lot",
                        "qtyUnion": "Union Qty",
                        "lotNoVictim": "Victim Lot",
                        "qtyVictim": "Victim Qty",
                        "lotNoOrigin": "Origin Lot",
                        "qtyOrigin": "Origin Qty",
                        "lotNoCreate": "Create Lot",
                        "failureCode": "Failure Code",
                        "failLotNo": "Fail Lot No",
                        "orderOutTransId": "Order Out Trans Id",
                        "sideDebit": "Side Debit",
                        "sideDebitAdv": "Side Debit Adv",
                        "sideCredit": "Side Credit",
                        "sideCreditAdv": "Side Credit Adv",
                        "infoType": "Info Type",
                        "str_f": " OrderBuyerId",
                        "str_t": "Change OrderBuyerId",
                        "inWareHouseCode": "Inpute Warehouse",
                        "outWareHouseCode": "Output Warehouse",
                        "memo": "description",
                        "qtyOrdered": "Ordered Qty",
                        "qtyProcessing": "Processing Qty",
                        "qtyPurchased": "Purchased Qty",
                        "qtyProduced": "Produced Qty",
                        "qtyInStock": "Qty in Stock",
                        "qtyShipped": "Shipped Qty",
                        "qtyBackShipped": "Back Shipped Qty",
                        "qtyRequired": "Required Qty",
                        "qtyToProduce": "Qty need to be Produced",
                        "qtySpare": "Spare Qty",
                        "wareHouseTo": "WareHouse To",
                        "wareHouseFrom": "WareHouse From",
                        "stockTakingId": "StockTakingId",
                        "criTransLogId": "CriTransLogId",
                        "hold": "Hold",
                        "applyDate": "apply Date",
                        "applyUser": "apply User",
                        "salesId": "Sales Id",
                        "salesDate": "Sales Date",
                        "taxBill": "Tax Bill",
                        "orderBuyerCost": "Order Buyer Cost",
                        "salesCost": "Sales Cost",
                        "salesBalance": "Sales Balance",
                        "collectionsId": "Collections Id",
                        "collectionsDate": "Collections Date",
                        "receipt": "Receipt",
                        "collectionsCost": "Collections Cost",
                        "collectionsBalance": "Collections Balance",
                        "advancePayment": "Advance Payment",
                        "salesCollectionsBalance": "Sales Collections Balance",
                        "orderBuyerCollectionsComplete": "Order Buyer Collections Complete",
                        "salesCollectionsComplete": "Sales Collections Complete",
                        "expenseCost": "Expense Cost",
                        "expenseBalance": "Expense Balance",
                        "expenseDate": "Expense Date",
                        "bonus": "Bonus",
                        "loss": "Loss",
                        "prevProcessCode": "Previous Process Code",
                        "prevProcessName": "Previous Process Name",
                        "nextProcessCode": "Next Process Code",
                        "nextProcessName": "Next Process Name",
                        "closed": "Closed",
                        "outsourcing": "Outsourcing",
                        "productLotNo": "Lot No",
                        "consumedLotNo": "Lot No",
                        "consumeTime": "Consume Time",
                        "qtySafetyStock": "Safety Stock Qty ",
                        "qtySpareStock": "Pile Stock Qty",
                        "generatedCode": "Generated Code",
                        "inputString": "Input String",
                        "codeSeq": "Code Seq",
                        "initCriteria": "Initialization Criteria",
                        "abbreviations": "Abbreviations",
                        "bopMaterialAbbreviations": "Product Abbreviations",
                        "inOutMaterialAbbreviations": "Abbreviations",
                        "mainCustomer": "main/sub Customer",
                        "minPurchaseQty": "min Purchase Unit",
                        "category": "Category",
                        "worker": "Worker",
                        "processTime": "Process Time",
                        "queueTime": "Queue Time",
                        "holdTime": "Hold Time",
                        "processCount": "Process Count",
                        "productionTime": "Production Time",
                        "avgProcessPerHour": "AVG Process Per Hour",
                        "minProcessPerHour": "MIN Process Per Hour",
                        "maxProcessPerHour": "MAX Process Per Hour",
                        "stdProcessPerHour": "Process Per Hour STD Deviation",
                        "avgProcessTime": "AVG Process Time",
                        "minProcessTime": "MIN Process Time",
                        "maxProcessTime": "MAX Process Time",
                        "stdProcessTime": "Process Time STD Deviation",
                        "avgQueueTime": "AVG Queue Time",
                        "minQueueTime": "MIN Queue Time",
                        "maxQueueTime": "MAX Queue Time",
                        "stdQueueTime": "Queue Time STD Deviation",
                        "avgHoldTime": "AVG Hold Time",
                        "minHoldTime": "MIN Hold Time",
                        "maxHoldTime": "MAX Hold Time",
                        "stdHoldTime": "Hold Time STD Deviation",
                        "avgProductionPerHour": "AVG Production Per Hour",
                        "minProductionPerHour": "MIN Production Per Hour",
                        "maxProductionPerHour": "MAX Production Per Hour",
                        "stdProductionPerHour": "Production Per Hour STD Deviation",
                        "avgProductionTime": "AVG Production Time",
                        "minProductionTime": "MIN Production Time",
                        "maxProductionTime": "MAX Production Time",
                        "stdProductionTime": "Production Time STD Deviation",
                        "sumProcessQty": "Total Process Qty",
                        "sumProductionQty": "Total Production Qty",
                        "productionCategory": "Production Category",
                        "orderBuyerDoc": "Order Buyer Document",
                        "backShipAvailableQty": "Back Ship Available Qty",
                        "autoMerge": "Auto Merge",
                        "rental": "Rental",
                        "translation": "Translation Data",
                        "translationPath": "Translation Path",
                        "translationValue": "Translation Value",
                        "translationPathId": "Translation Path",
                        "consumablesName": "Consumable Name",
                        "productInventoryAmount": "Product Inventory Amount",
                        "approvalDetailState": "Approval Detail State",
                        "fax": "Fax",
                        "materialCodeVer": "Material Code Version",
                        "priceInter": "Internal Price",
                        "priceExter": "External Price",
                        "barcodeShip": "Shipping Barcode Lot No",
                        "orderCost": "Order Cost",
                        "purchaseCost": "Purchase Cost",
                        "purchaseBalance": "Purchase Balance",
                        "purchaseDate": "Purchase Date",
                        "workOrder": "workOrder",
                        "designer": "designer",
                        "orderer": "orderer",
                        "buyer": "buyer",
                        "ceoPhone": "CEO Phone",
                        "ceoName": "CEO Name",
                        "salesPerson": "Sales Person",
                        "salesPersonPhone": "Sales Person Phone",
                        "salesPersonEmail": "Sales Person Email",
                        "joinCompanyDate": "joinCompanyDate",
                        "lastCompanyDate": "Last Company Date",
                        "address": "Address",
                        "belong": "belong",
                        "jobGrade": "jobGrade",
                        "dutyResponsibility": "Duty Responsibility",
                        "tenureStatus": "tenureStatus",
                        "applyByFullName": "applyByFullName",
                        "qtyConsumed": "Qty Consumed",
                        "sendTime": "Send Time",
                        "sender": "Sender",
                        "senderName": "Sender Name",
                        "message": "Message",
                        "readTime": "Read Time",
                        "msgRead": "Read",
                        "deleteTime": "Delete Time",
                        "receiver": "Receiver",
                        "receiverName": "Receiver Name",
                        "qtyStock": "Qty Stock",
                        "qtyDueDiligence": "Qty DueDiligence",
                        "standard": "Standard",
                        "shipmentOrderDate": "shipmentOrderDate",
                        "shipmentNo": "shipmentNo",
                        "nsMaterialCode": "Non Standard Material Code",
                        "nsMaterialCodeVer": "Non Standard Material Code Version",
                        "shipmentQty": "shipmentQty",
                        "shipmentId": "columnName.shipmentId",
                        "shipmentDetailId": "columnName.shipmentDetaild",
                        "orderPurchaseDoc": "Order Purchase Doc",
                        "shipId": "ship Id",
                        "shipState": "ship State",
                        "orderBuyerDetailId": "Order Buyer Detail Id",
                        "orderPurchaseDetailId": "Order Purchase Detail Id",
                        "deliveryDoc": "deliveryDoc",
                        "deliveryState": "deliveryState",
                        "shipDate": "shipDate",
                        "stockTakingDoc": "stockTakingDoc",
                        "partNo": "Part No",
                        "partSeq": "Part Seq",
                        "opCode": "Op Code",
                        "cutCode": "Cut Code",
                        "opDeg": "Op Deg",
                        "partSpec": "Part Spec",
                        "partSize": "Part Size",
                        "partMaterial": "Part Material",
                        "materialUnitCost": "Material Unit Cost",
                        "kg": "KG",
                        "materialCost": "Material Cost",
                        "chamferingCost": "Chamfering Cost",
                        "grindingCost": "Grinding Cost",
                        "processUnitCost": "Process Unit Cost",
                        "customOrderPurchaseId": "Custom Order Purchase Id",
                        "backShipDate": "Back Ship Date",
                        "qtyBackShip": "Qty Back Ship",
                        "orderPurchaseName": "Order Purchase Name",
                        "submitDoc": "Submit Document",
                        "fakeInputDate": "fakeInputDate",
                        "tradingDoc": "tradingDoc",
                        "fakeInputUser": "fakeInputUser",
                        "backShipReason": "Back Ship Reason",
                        "deliveryId": "Delivery Id",
                        "shipmentLot": "shipmentLot",
                        "stockTakingName": "stockTakingName",
                        "stockTakingDate": "stockTakingDate",
                        "deliveryRequiredQty": "deliveryRequiredQty",
                        "inputState": "Input State",
                        "produceStatus": "Produce Status",
                        "takeOverStatus": "Take Over Status",
                        "plannedWorker": "Planned Worker",
                        "realWorker": "Real Worker",
                        "inputInspectionId": "Input Inspection Identifier",
                        "defectiveCode": "Defective Code",
                        "inspectionDate": "Inspection Date",
                        "qcResult": "QC Result",
                        "inputDate": "Input Date",
                        "inspectorName": "Inspector Name",
                        "inspector": "Inspector",
                        "qcManager": "QC Manager",
                        "requiredQty": "requiredQty",
                        "inputQty": "inputQty",
                        "inspectionItemId": "Inspection Item Id",
                        "inspectionItemName": "Inspection Item Name",
                        "inspectionCriteriaId": "Inspection Criteria Id",
                        "inspectionCriteriaName": "Inspection Criteria Name",
                        "inspectionGroupId": "Inspection Group Id",
                        "inspectionGroupName": "Inspection Group Name",
                        "contents": "Contents",
                        "selfInspectionId": "Self Inspection Identifier",
                        "requestDate": "Request Date",
                        "targetLotNo": "Target Lot No",
                        "targetLotCreatedAt": "Lot Created DateTime",
                        "productionQty": "Production Quantity",
                        "inspectionRequesterName": "Inspection Requester Name",
                        "samplingDate": "Sampling DateTime",
                        "samplingLoc": "Sampling Location",
                        "samplingType": "Sampling Type",
                        "samplingQty": "Sampling Quantity",
                        "productionPart": "Production Part Name",
                        "inspectionRequestDate": "Inspection Request Date",
                        "inspectionRequester": "Inspection Requester",
                        "determinant": "Inspection Determinant",
                        "workTime": "Work Time",
                        "consumeQty": "Consume Qty",
                        "failOccurDate": "Failure Occurrence Date",
                        "qcManagerName": "QC Manager Name",
                        "failQty": "Fail Qty",
                        "plannedStartTime": "Planned Start Time",
                        "plannedQty": "Planned Qty",
                        "resultQty": "Result Qty",
                        "resultLotNo": "Result Lot No",
                        "duration": "Duration",
                        "outputQty": "Output Qty",
                        "srcLotNo": "Src Lot No",
                        "otherFeatures": "Other Features",
                        "qtyFakeInput": "qtyFakeInput",
                        "unitCost": "Unit Cost",
                        "defectiveCodeName": "Defective Code Name",
                        "failureCodeName": "Failure Code Name",
                        "preTime": "Pre Process Time",
                        "postTime": "Post Process Time",
                        "deliveryPlace": "Delivery Place",
                        "materialFakeInputId": "Material Fake Input ID",
                        "title": "title",
                        "sellUnitCost": "Sell Unit Cost",
                        "materialEnglishName": "English Name",
                        "division": "division",
                        "totalSales": "totalSales",
                        "taskName": "Task Name",
                        "taskType": "Task Type",
                        "produce": "Produce",
                        "bomMaterialQty": "Product Qty",
                        "inciName": "INCI Name",
                        "casNo": "CAS No",
                        "totalPurchase": "totalPurchase",
                        "deliveryCompanyName": "Delivery Company Name",
                        "deliveryCompanyCode": "Delivery Company Code",
                        "customerDescription": "description",
                        "wageRate": "Wage Rate",
                        "personnelExpenses": "Personnel Expenses",
                        "depreciation": "Depreciation\n",
                        "rentalFee": "Rental Fee\n",
                        "electricityBill": "Electricity Bill\n",
                        "waterSewageFee": "Water Sewage Fee\n",
                        "communicationCost": "Communication Cost\n",
                        "vehicleMaintenance": "Vehicle Maintenance",
                        "fuelCost": "Fuel Cost\n",
                        "otherCosts": "Other Costs\n",
                        "taskCount": "Task Count",
                        "splitMethod": "Split By",
                        "splitSingle": "Split Single Task",
                        "outSourcingShipId": "outSourcingShipId",
                        "outSourcingShipDate": "outSourcingShipDate",
                        "outSourcingShipManager": "outSourcingShipManager",
                        "durationInHours": "Duration",
                        "taskStartDate": "Task Start Date",
                        "workers": "Workers",
                        "machines": "Machine",
                        "startProcessName": "Start Process",
                        "endProcessName": "End Process",
                        "outSourcingId": "OutSourcing Id",
                        "outSourcingName": "OutSourcing Name",
                        "outSourcingDate": "OutSourcing Date",
                        "outSourcingStatus": "OutSourcing Status",
                        "realDeliveryDate": "Real Delivery Date",
                        "outSourcingCost": "OutSourcing Cost",
                        "outSourcingQty": "OutSourcing Qty",
                        "orderBuyerMaterialName": "Order Buyer Material Name",
                        "outSourcingMaterialName": "OutSourcing Material Name",
                        "startProcessCode": "Start Process",
                        "endProcessCode": "End Process",
                        "progress": "Progress",
                        "taskStartTime": "Task Start Time",
                        "taskEndTime": "Task End Time",
                        "plannedDuration": "Planned Duration",
                        "milestoneDate": "Date",
                        "milestoneName": "Milestone Name",
                        "processPerHour": "Process Per Hour",
                        "outSourcingFakeInputId": "outSourcingFakeInputId",
                        "updateBy": "Update By",
                        "fakeInputQty": "fake input qty",
                        "extLotNo": "extLotNo",
                        "recurring": "Recurring",
                        "date": "Date",
                        "outSourcingInputInspectionId": "OutSourcing Input Inspection Id",
                        "shipWareHouseCode": "shipWareHouseCode",
                        "orderTime": "Order Time",
                        "dayOfWeek": "Day of Week",
                        "capacity": "Capacity",
                        "materialRecovery": "Material Recovery",
                        "excelFile": "Excel File",
                        "outSourcingDetailId": "OutSourcing Detail Id",
                        "returnedQty": "Returned Quantity",
                        "returnedDate": "Returned Date",
                        "mainCode": "Main Code",
                        "outSourcingShipState": "outSourcingShipState",
                        "outSourcingShipQty": "outSourcingShipQty",
                        "consumeLotNo": "consumeLotNo",
                        "startDate": "Start Date",
                        "endDate": "End Date",
                        "itemLocationCode": "Item Location Code",
                        "backShipId": "Back Ship Id",
                        "workerFullName": "Worker Full Name",
                        "otherWorkDate": "Other Work Date",
                        "otherWorkId": "Other Work Id",
                        "startDateTime": "Start Date Time",
                        "endDateTime": "End Date Time",
                        "workDetail": "Work Detail",
                        "otherWorkDetailId": "Other Work Detail Id",
                        "purchaseDetailId": "Purchase Detail Id",
                        "expenseId": "Expense Id",
                        "outSourcingMaterialQty": "OutSourcing Material Quantity",
                        "materialCapacity": "Material Capacity",
                        "failRatio": "Failure Ratio",
                        "soQty": "Sales Order Qty",
                        "process": "Process",
                        "process1": "Lead",
                        "process2": "Wood",
                        "process3": "Molding",
                        "process4": "Packaging",
                        "productFamily": "Product Family",
                        "controlNumber": "Control Number",
                        "outQuantity": "Out Quantity",
                        "inQuantity": "In Quantity",
                        "remainingQuantity": "Remaining Quantity",
                        "reason": "Reason",
                        "customerName": "Customer Name",
                        "customerManager": "Customer Manager",
                        "outDate": "Out Date",
                        "inDate": "In Date",
                        "returnDescription": "Return Description",
                        "pay": "pay",
                        "bonusPay": "bonusPay",
                        "otherPay": "otherPay",
                        "employeeBenefits": "employeeBenefits",
                        "travelExpenses": "travelExpenses",
                        "businessExpenese": "businessExpenese",
                        "utilitiesExpenses": "utilitiesExpenses",
                        "publicTax": "publicTax",
                        "premium": "premium",
                        "vehicleMaintenanceCost": "vehicleMaintenanceCost",
                        "researchGrant": "researchGrant",
                        "transportCost": "transportCost",
                        "packagingCost": "packagingCost",
                        "officeSuppliesCost": "officeSuppliesCost",
                        "suppliesCost": "suppliesCost",
                        "fees": "fees",
                        "outsourcingFee": "outsourcingFee",
                        "depreciationExpense": "depreciationExpense",
                        "storageFee": "storageFee",
                        "exportCost": "exportCost",
                        "otherCost": "otherCost",
                        "directMaterialCost": "directMaterialCost",
                        "directLaborCost": "directLaborCost",
                        "baseYearMonth": "baseYearMonth",
                        "state": "State",
                        "basePurchasingTime": "Base Purchasing Required Time (day)",
                        "adhocPurchasingTimeQty": "Unit Quantity for adhoc Purchasing Required Time",
                        "adhocPurchasingTime": "Adhoc Purchasing Required Time Per Unit Quantity (day)",
                        "baseProducingTime": "Base Producing Required Time (sec)",
                        "adhocProducingTime": "Adhoc Producing Required Time Per Unit Quantity (sec)",
                        "adhocProducingTimeQty": "Unit Quantity for adhoc Producing Required Time",
                        "mainSupplyCompanyName": "Main Supply Company Name",
                        "subSupplyCompanyName": "Subsidiary Supply Company Name",
                        "firstLackingDate": "First Lacking Date",
                        "firstLackingQty": "First Lacking Quantity",
                        "totalLackingQty": "Total Lacking Quantity",
                        "percent": "percent",
                        "cumulativeSales": "cumulativeSales",
                        "sales": "sales",
                        "productMaterialCode": "Ordered Product Material Code",
                        "productMaterialCodeVer": "Ordered Product Material Code Ver",
                        "materialPlanMaterialCode": "Material Plan Material Code",
                        "materialPlanMaterialCodeVer": "Material Plan Material Code Ver",
                        "mrpPlannedQty": "MRP Planned Qty",
                        "etcCost": "Etc Cost",
                        "totalCost": "Total Cost",
                        "profit": "Profit",
                        "eventProcessCode": "Event Process Code",
                        "eventMaterialCode": "Event Material Code",
                        "eventMaterialCodeVer": "Event Material Code Ver",
                        "eventQty": "Event Quantity",
                        "netQty": "Net Quantity",
                        "eventType": "Event Type",
                        "purchase": "purchase",
                        "cumulativePurchase": "cumulativePurchase",
                        "deliveryQty": "deliveryQty",
                        "repairFee": "repairFee",
                        "educationCosts": "educationCosts",
                        "bookPrintingFee": "bookPrintingFee",
                        "meetingFee": "meetingFee",
                        "advertisingCost": "advertisingCost",
                        "salesCommission": "salesCommission",
                        "currentDevelopmentCost": "currentDevelopmentCost",
                        "interestExpense": "interestExpense",
                        "saleDiscount": "saleDiscount",
                        "otherLossCosts": "otherLossCosts",
                        "donation": "donation",
                        "total": "total",
                        "severancePay": "severancePay",
                        "indirectLaborCost": "indirectLaborCost",
                        "salesManagementCost": "indirectLaborCost",
                        "employType": "employType",
                        "directLaborCostPer": "directLaborCostPer",
                        "indirectLaborCostPer": "indirectLaborCostPer",
                        "researchGrantPer": "researchGrantPer",
                        "salesManagementCostPer": "salesManagementCostPer",
                        "costInfoId": "costInfoId",
                        "laborCostId": "laborCostId",
                        "viewMinPurchaseQty": "min Purchase Qty",
                        "viewCost": "cost",
                        "viewMainCompanyName": "main Company",
                        "productSampleNo": "productSampleNo",
                        "receiptNo": "receiptNo",
                        "receiptDate": "receiptDate",
                        "client": "client",
                        "requests": "requests",
                        "sampling": "sampling",
                        "samplingDescription": "samplingDescription",
                        "manager": "manager",
                        "sampleState": "sampleState",
                        "sendDate": "sendDate",
                        "reviewCount": "reviewCount",
                        "labNo": "labNo",
                        "type": "type",
                        "reviewNo": "reviewNo",
                        "longLabNo": "LabNo",
                        "sendDescription": "sendDescription",
                        "passJudgment": "passJudgment",
                        "judgmentDate": "judgmentDate",
                        "reviewContent": "reviewContent",
                        "confirmProductName": "confirmProductName",
                        "experimentDate": "experimentDate",
                        "experimentManager": "experimentManager",
                        "experimentState": "experimentState",
                        "experimentNo": "experimentNo",
                        "experimentQty": "experimentQty",
                        "experimentContent": "experimentContent",
                        "reviewState": "reviewState",
                        "no": "No",
                        "code": "Code",
                        "item": "Item",
                        "standardAmount": "standardAmount",
                        "expectedAmount": "expectedAmount",
                        "roundOneAmount": "roundOneAmount",
                        "roundTwoAmount": "roundTwoAmount",
                        "roundThreeAmount": "roundThreeAmount",
                        "roundFourAmount": "roundFourAmount",
                        "roundFiveAmount": "roundFiveAmount",
                        "totalAmount": "totalAmount",
                        "subMaterialSampleNo": "subMaterialSampleNo",
                        "content": "content",
                        "targetSample": "targetSample",
                        "materialSpecifications": "materialSpecifications",
                        "postProcessing": "postProcessing",
                        "printing": "printing",
                        "etc": "etc",
                        "sampleDescription": "sampleDescription",
                        "receiveDate": "receiveDate",
                        "subMaterialState": "subMaterialState",
                        "sampleNo": "sampleNo",
                        "orderState": "orderState",
                        "processingCompanyName": "processingCompanyName",
                        "rawMaterialName": "Raw Material Name",
                        "inciNameOfTheIngredient": "INCI Name Of The Ingredient",
                        "koreanIngredientName": "Korean Ingredient Name",
                        "exactConcentrationOfTheIngredientIntoTheFinishedProduct": "Exact Concentration Of The Ingredient Into The Finished Product",
                        "exactConcentrationOfTheIngredientIntoTheRawMaterial": "Exact Concentration Of The Ingredient Into The Raw Material",
                        "purposeOfUse": "Purpose Of Use",
                        "writer": "writer",
                        "writeAt": "writeAt",
                        "reviewRound": "reviewRound",
                        "experimentRound": "experimentRound",
                        "ingredientRegistrationTableDoc": "ingredientRegistrationTableDoc",
                        "experimentManagerName": "experimentManagerName",
                        "registrant": "registrant",
                        "processingCompany": "processingCompany",
                        "productSampleId": "productSampleId",
                        "eguLabDataSheetId": "eguLabDataSheetId",
                        "eguLabDataSheetDetailId": "eguLabDataSheetDetailId",
                        "subMaterialSampleId": "subMaterialSampleId",
                        "captionId": "captionId",
                        "eguIngredientRegistrationId": "eguIngredientRegistrationId",
                        "extLotNoBarCode":"extLotNoBarCode",
                        "wareHouseBarCode":"wareHouseBarCode"
                    },
                    "httpStatus":
                    {
                        "100": "Continue",
                        "101": "Switching Protocol",
                        "102": "Processing",
                        "103": "Early Hints",
                        "200": "OK",
                        "201": "Created",
                        "202": "Accepted",
                        "203": "None-Authorotive Information",
                        "204": "No Content",
                        "205": "Reset Content",
                        "206": "Partial Content",
                        "207": "Multi-Status",
                        "208": "Already Reported",
                        "226": "IM Used",
                        "300": "Multiple Choice",
                        "301": "Moved  Permanently",
                        "302": "Found",
                        "303": "See Other",
                        "304": "Not Modified",
                        "305": "Use Proxy",
                        "306": "Switch Proxy",
                        "307": "Temporary Redirect",
                        "308": "Permanent Redirect",
                        "400": "Bad Request",
                        "401": "Unauthorized",
                        "402": "Payment Required",
                        "403": "Forbidden",
                        "404": "Not Found",
                        "405": "Method Not Allowed",
                        "406": "Not Acceptable",
                        "407": "Proxy Authentication Required",
                        "408": "Request Timeout",
                        "409": "Conflict",
                        "410": "Gone",
                        "411": "Length Required",
                        "412": "Precondition Failed",
                        "413": "Payload Too Large",
                        "414": "URI Too Long",
                        "415": "Unsupported Media Type",
                        "416": "Requested Range Not Satisfiable",
                        "417": "Expectation Failed",
                        "418": "I'm a teapot",
                        "421": "Misdirected Request",
                        "422": "Unprocessable Entity",
                        "423": "Locked",
                        "424": "Failed Dependency",
                        "426": "Upgrade Required",
                        "428": "Precondition Required",
                        "429": "Too Many Requests",
                        "431": "Request Header Fields Too Large",
                        "451": "Unavailable For Legal Reasons",
                        "500": "Internal Server Error",
                        "501": "Not Implemented",
                        "502": "Bad Gateway",
                        "503": "Service Unavailable",
                        "504": "Gateway Timeout",
                        "505": "HTTP Version Not Supported",
                        "506": "Variant Alsos Negotiates",
                        "507": "Insufficient Storage",
                        "508": "Loop Detected",
                        "510": "Not Extended",
                        "511": "Network Authentication Required"
                    },
                    "info":
                    {
                        "applicationStarted": "Application Started Normaly",
                        "startingServerWithConfig": "Start Server with Configuration : ",
                        "reactContentsServingAppLoaded": "React Contents Serving App loaded",
                        "staticContentsServingAppLoaded": "Static Contents Serving App loaded",
                        "generalApiServingAppLoaded": "General API Serving App loaded",
                        "authApiServingAppLoaded": "Authentication API Serving App loaded",
                        "filesAppLoaded": "File Serving App Loaded",
                        "corsEnabled": "CORS is enabled",
                        "serverListening": "Server Listening on {{port}}",
                        "poolCreatedWithConfig": "MySql Conncetion Pool Created for Following Connection Information : ",
                        "sqlExecuted": "SQL executed : ",
                        "result": "Result : ",
                        "preExecCustomApiLoaded": "Pre Executing Custom API Module {{file}} Loaded",
                        "postExecCustomApiLoaded": "Post Executing Custom API Module {{file}} Loaded",
                        "noAuthApiLoaded": "No Auth API 모듈 {{file}} Loaded",
                        "deleteCanceled": "Delete Canceled",
                        "createTenantConnectionPool": "Creating Connection Pool for Tenant {{tenant}}",
                        "startingCluster": "Starting Cluster",
                        "numberOfCpu": "Number of CPUs : {{numOfCpus}}",
                        "forkNewWorker": "Fork New Worker",
                        "masterRunning": "Master Process {{pid}} Started",
                        "workerRunning": "Worker Process {{pid}} Started",
                        "workerCount": "Execute {{workerCount}} Workers"
                    },
                    "warning":
                    {
                        "formDataValidationFail": "Form Data Validation Failed : {{msg}}",
                        "partialDeleteFailure": "{{success}} Records Deleted\n{{fail}} Records Delete Failed\nReason : {{reason}}",
                        "noChange": "변경된 사항이 없습니다",
                        "invalidConsumedQty": "Consumed Qty cannot be larger than In Stock Qty",
                        "invalidQty": "Invalid Qty",
                        "canNotDeleteOrderedTask": "Can Not Delete Ordered Task",
                        "outOfStock": "out of stock",
                        "notSplitOnTheLine": "notSplitOnTheLine",
                        "numberRequired": "Only numbers are allowed",
                        "totalIsNotZero": "totalIsNotZero",
                        "totalAddHundredPercent": "totalAddHundredPercent"
                    },
                    "error":
                    {
                        "verifyAuthTokenFail": "verify authToken failed",
                        "authTokenMissing": "authToken missing",
                        "jsonBodyForNewRowRequired": "JSON body for new row is required",
                        "primaryKeyRequired": "Primary key {{key}} is required",
                        "queryFailed": "SQL Query Failed",
                        "methodNotAllowed": "{{method}} {{url}} Not Allowd",
                        "jsonParsingFailed": "JSON Parsing Failed",
                        "applicationStartFailed": "Application Start Failed",
                        "identityOrCrendentialMismatch": "Identity or Credential Mismatched",
                        "identityOrCrendentialMissing": "Identity or Credential Missing",
                        "userNotFound": "User {{user}} Not Found",
                        "userNameMissing": "username Missing",
                        "getConnectionFailed": "Get Connection from Connection Pool Failed",
                        "beginTransactionFailed": "Begin Transaction Failed",
                        "notFound": "Not Found",
                        "insertFailed": "Insert Failed",
                        "alreadyExist": "Already Exist",
                        "columnRequired": "{{column}}is required",
                        "userNameTooLong": "Username Too Long",
                        "passwordLengthError": "Wrong Password Length : {{msg}}",
                        "passwordTooShort": "Password Too Short",
                        "passwordTooLong": "Password Too Long",
                        "passwordNotMatch": "Password Not Matched",
                        "inputValueTooLong": "Input Value Too Long",
                        "numberRequired": "Number is required",
                        "floatRequired": "Float Number is required",
                        "emailRequired": "Invalid e-mail format",
                        "insertFailure": "Insert Failed - Check for duplicate : {{reason}}",
                        "updateFailure": "Update Failed : {{reason}}",
                        "deleteFailure": "Delete Failed - Check item used else where : {{reason}}",
                        "approvaPathNotSelected": "Approval Path Not Selected",
                        "approvalRequestFailure": "Approval Request Failed - Check for duplicate request : {{reason}}",
                        "approveApprovalFailure": "Approve Approval Request Failed : {{reason}}",
                        "rejectApprovalFailure": "Reject Approval Request Failed : {{reason}}",
                        "cancelApprovalFailure": "Cancel Approval Failed : {{reason}}",
                        "withdrawApprovalFailure": "Withdraw Approval Failed : {{reason}}",
                        "approvalTargetNotSelected": "Approval Target Not Selected",
                        "approvalAlreadyRequested": "Approval Already Requested",
                        "approvalRequesterNotMatch": "Approval Requester and Approval Target Creater Not Mached",
                        "approvalStateNotExist": "Approval State Not Exist",
                        "cannotApprovePendingApproval": "Approval Pended by Prior Approver",
                        "cannotApproveApprovedApproval": "Already Approved Approval Request",
                        "cannotApproveRejectedApproval": "Cannot Approve Rejected Approval Request",
                        "cannotApproveWithdrawnApproval": "Cannot Approve Withdrawn Approval Request",
                        "cannotApproveCancelledApproval": "Cannot Approve Cancelled Approval Request",
                        "cannotRejectPendingApproval": "Approval Pended by Prior Approver",
                        "cannotRejectApprovedApproval": "Cannot Reject Approved Approval Request",
                        "cannotRejectRejectedApproval": "Already Rejected Approval Request",
                        "cannotRejectWithdrawnApproval": "Cannot Reject Withdrawn Approval Request",
                        "cannotRejectCancelledApproval": "Cannot Reject Cancelled Approval Request",
                        "cannotWithdrawApprovedApproval": "Cannot Withdraw Approved Approval Request",
                        "cannotWithdrawRejectedApproval": "Cannot Withdraw Rejected Approval Request",
                        "cannotWithdrawWithdrawnApproval": "Already Withdrawn Approval Request",
                        "cannotWithdrawCancelledApproval": "Cannot Withdraw Cancelled Approval Request",
                        "cannotCancelPendingApproval": "Cannot Cancel Pending Approval Request",
                        "cannotCancelPartiallyApprovedApproval": "Cannot Cancel Partially Approved Approval Request",
                        "cannotCancelRejectedApproval": "Cannot Cancel Rejected Approval Request",
                        "cannotCancelWithdrawnApproval": "Cannot Cancel Withdrawn Approval Request",
                        "cannotCancelCancelledApproval": "Already Cancelled Approval",
                        "noApprovalRequest": "No Approval Request",
                        "approvalDetailStateMismatch": "Approval State and Approval Detail State Not Matched : approvalPathId [{{approvalPathId}}], approvalDetailId [{{approvalDetailId}}]",
                        "updateApprovalDetailFailed": "Update Approval Detail Failed",
                        "updateApprovalStateFailed": "Update Approval State Failed",
                        "invalidApprover": "Invalid Approver",
                        "invalidApprovalState": "Invalid Approval State",
                        "invalidAppovalTargetTableName": "Invalid Approval Target Table Name",
                        "inventoryAmountNotEnough": "Inventory Amount Not Enough",
                        "notAuthorizedApi": "User {{username}} tried to access unauthorized API {{url}} with method {{method}}",
                        "notAuthorizedFile": "User {{username}} tried to access unauthorized File {{url}}",
                        "fileUploadFailure": "File Upload Failure",
                        "fileDeleteFailure": "File Delete Failure",
                        "shipmentDetailFailure": "So quanitiy or inventory quanitiy checked.",
                        "codeGenerateFailure": "Not Found codeGenerate Name [{{codeGenerateName}}]",
                        "codeGenerateUpdateFailure": "Update Code Generate Failed",
                        "soFailure": "soNumber [{{soNumber}}] is a duplicate.",
                        "soDetailFailure": "The item exists.",
                        "poFailure": "poNumber [{{poNumber}}] is a duplicate.",
                        "poDetailFailure": "The item exists.",
                        "noHelp": "No Help Document for Current Screen",
                        "forkWorkerFailed": "Fork New Cluster Worker Failed. Terminates Cluster",
                        "workerDisconnected": "Worker Process {{pid}} Disconnected",
                        "disconnectFromMaster": "Worker Process {{pid}} Disconnected From Master Process. Terminate Worker Process",
                        "workerStartFailed": "Worker Process {{pid}} Start Failed",
                        "verifyCsrfTokenFailed": "Verify CSRF Token Failed",
                        "fixMachineFailureFailed": "Fix Machine Failure Failed : {{reason}}",
                        "bopDataMissing": "BOP Data Missing",
                        "failToReadLotNo": "Read LotNo Failed",
                        "failToReadMaterialInfo": "Read Material Info Failed",
                        "failToBackShipMatQty": "BackShip qty is higher than ship qty ",
                        "failToShipOrderBuyerIdNotExist": "No Order Number for LotNo",
                        "failToOrderBuyerIdNotAvailable": "Order Information Not Valid",
                        "failToOrderBuyerIdNotApproved": "Order Information has not Approved.",
                        "failToOrderBuyerIdShipNotApproved": "Order Information has not Ship approved",
                        "failToWipLotCheckProcessOutSourcingIn": "Can not find Outsourcing History",
                        "failToOrderBuyerIdSame": "Can not Change same orderBuyerId",
                        "cannotFindTransLogId": "Cannot find Trans Log Id",
                        "cannotFindInputInspectNo": "Cannot find Input Inspect",
                        "qtyLessThanQtyInput": "Qty Less Than QtyInput",
                        "alreadyInputItem": "Already Input Item",
                        "failToSplitAvailable": "Can not lot division Production site ",
                        "failToSplitOutSourcingFail": "Split target lot must not be outsourced ",
                        "failToSplitQtyunderZero": "Split lot qty is larger than  current qty",
                        "materialLoopDetected": "Material Loop Detected (ProductionMaterial: {{materialCode}}/{{materialCodeVer}},  Duplicated Material: {{duplicatedMaterialCode}}/{{duplicatedMaterialCodeVer}})",
                        "failToMergeAvailable": "We can combine the materials only when they're the same",
                        "failToMergeOutSourcing": "Both lots should not be outsourced.",
                        "failToMergeAvailableLineDiff": "combine materials from the same line.",
                        "failToMergeAvailableOrderBuyerId": "The order numbers of the two lots are different.",
                        "failToMergeAvailableWhDiff": "only combine the materials from the same warehouse.",
                        "failToMoveSameWareHouse": "no need to move to the same warehouse.",
                        "failToMoveLineNo": "Line information is required when moving to the production site.",
                        "failToWipLotCheckProcessOutSourcingCompanyNoExist": "The company's name is not registered.",
                        "emptyOrNegativeProductionQty": "The production quantity should be greater than zero.",
                        "failToShipWareHouseNotProper": "can only be shipped from the warehouse.",
                        "failToShipMatClosed": "The material of the product is not valid.",
                        "failToShipMatNotProper": "There is no product you want to ship in this order.",
                        "failToCancelByShipMergeSplit": "Cannot cancel shiped/merged/splitted material.",
                        "failToCancelByWorkState": "Only produced material can be canceled.",
                        "bopNotFound": "Cannot find BOP for Material.",
                        "bopAlreadyExist": "BOP Already Exist for given Material.",
                        "processNotFound": "Cannot find Process.",
                        "bopOutMaterialNotFound": "Cannot find Material from BOP.",
                        "failToStockTakingMustAdjustQtyLow": "To reflect the quantity of Lot, it must be less than the original quantity.",
                        "failToStockTakingNotList": "There's no information on the inventory revision list.",
                        "materialNotOnProductionLine": "Material not on Production Line",
                        "productionStartFail": "Production for Lot No {{lotNo}} Start Failed",
                        "completeProcessFail": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Complete Failed",
                        "startProcessFail": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Start Failed",
                        "holdProcessFail": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Hold Failed",
                        "releaseHoldFail": "Holded Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Release Failed",
                        "cancelProductionFail": "Production for Lot No {{lotNo}} Cancel Failed",
                        "positiveFloatRequired": "Positive Float Number Required",
                        "failAndLossQtyExceedsProductionQty": "Faield and Loss Qty Exceeds Production Qty",
                        "duplicateMaterialSelected": "Duplicated Material Lots Selected",
                        "materialsForProductionNotSelected": "Materials for Production Not Selected.",
                        "lotNoNotFound": "Lot No {{lotNo}} Not Found.",
                        "notProcessingLot": "Lot No {{lotNo}} is not in Processing.",
                        "lotNoMismatch": "Request Body Lot No {{lotNo}} is not Matched with Request URL.",
                        "missingLot": "Lot No {{lotNo}} Missing",
                        "missingLotTransaction": "Transaction {{transaction}} of Lot No {{lotNo}} Not Exist.",
                        "cannotStartProcessingOnLot": "Cannot Start Processing on Lot No {{lotNo}}.",
                        "noMoreProcess": "No More Process",
                        "noBomBopTree": "No BOM/BOP Tree",
                        "qtyMustMultiplyPurchaseQty": "Qty Less Than Min Purchase Qty",
                        "failToMoveSameWareHouseOrLineNo": "no need to move to the same WareHouse Or LineNo",
                        "correctableDateExceeded": "The correctable date has been exceeded",
                        "failToShipQty": "Ship Quantity Cannot be less than Shipment Quantity",
                        "undefinedError": "Undefined Error : {{reason}}",
                        "importTranslationDataFailed": "Import Translation Data Failed : {{reason}}.",
                        "duplicateEstimateVersion": "Duplicate esimation version.",
                        "baseMajorVersionNotExist": "Not existing Base Major Version {{baseVersionMajor}}.",
                        "baseMajorVersionRequired": "Base Major Version Information Required.",
                        "apporvalTargetNotFound": "Approval Target Not Found",
                        "onlyLastApproverCouldCancelApproval": "Only Last Approved Approver Can Cancel Approval",
                        "noProductTree": "No product can be produced using given material",
                        "markReadFailed": "Mark Message as Read Failed",
                        "markReadAllFailed": "Mark All Messages as Read Failed",
                        "unknownOperation": "Unknown Operation",
                        "markUnreadFailed": "Mark Messages as Not Read Failed",
                        "moveToTrashFailed": "Delete Messages Failed",
                        "messageNotFound": "Message Not Found",
                        "recoverMessage": "Recover Message Failed",
                        "deleteMessage": "Delete Message Failed",
                        "emptyTrash": "Delete Message Failed",
                        "invalidFileExtension": "Invalid File Extension {{extension}}",
                        "sendResetPasswordEmailFailed": "Send Email for Reset Password Failed.",
                        "noSuchUser": "User Not Exist",
                        "expiredToken": "Authoization Expired",
                        "qtyLossMoreThanQty": "qtyLoss More Than Qty",
                        "failToGetOrderBuyerDetailInfo": "Fail to get OrderBuyer Detail Information",
                        "failToGetOrderPurchaseDetailInfo": "Fail to get OrderPurchase Detail Information",
                        "orderBuyerNotApproved": "Order Buyer({{orderBuyerId}}) Not Approved",
                        "failToQtyBackShip": "Qty Back Ship Cannot be greater than Back Ship Available Qty",
                        "momSysInspectionNotFound": "{{inspectionId}} not found in MomSysInspection",
                        "inputFailure": "Input Failed - Check for duplicate : {{reason}}",
                        "eguInputInspectionNotFound": "{{inputInspectionId}} not found in EguInputInspection",
                        "eguVwInputInspectionNotFound": "{{inputInspectionId}} not found in EguVwInputInspection",
                        "MomSysUglOrderPurchaseMstNotFound": "{{orderPurchaseId}} not found in MomSysUglOrderPurchaseMst",
                        "notAllowedFileType": "Not Allowed File Type",
                        "eguSelfInspectionNotFound": "{{selfInspectionId}} not found in EguSelfInspection",
                        "materialTakeOverInsufficientQuantity": "materialTakeOverInsufficientQuantity",
                        "selectLotMaterialsConsumeQtyRequiredQtyLack": "selectLotMaterialsConsumeQtyRequiredQtyLack",
                        "momSysInspectionRsltNotFound": "{{inspectionId}}/{{inspectionItemId}}/{{inspectionCriteriaId} not found in MomSysInspectionRslt",
                        "momSysVwInspectionRsltNotFound": "{{inspectionId}} not found in MomSysVwInspectionRslt",
                        "qtyLessThanMinPurchaseQty": "Qty Less Than Min Purchase Qty",
                        "beforeProcessNotCompleted": "beforeProcessCode {{processCode}} NotCompleted",
                        "missingRequestData": "Missing Request Data",
                        "confirmProductnameNotExist": "confirmProductnameNotExist",
                        "duplicateOrderBuyerDetail": "Duplicate Order Buyer Detail(MaterialCode: {{MaterialCode}}, MaterialCodeVer: {{MaterialCodeVer}})",
                        "MomSysUglOrderBuyerMstNotFound": "Sales order {{orderBuyerId}} Not Exist",
                        "duplicateOrderPurchaseDetail": "Duplicate Order Purchase Detail(MaterialCode: {{MaterialCode}}, MaterialCodeVer: {{MaterialCodeVer}})",
                        "createProductionPlanFail": "Create Production Plan Failed",
                        "lotNotFound": "Lot No {{lotNo}} is not found.",
                        "updatePlanFailed": "Update Plan {{taskName}} Failed",
                        "planNotFound": "Plan {{planId}} Not Found",
                        "deleteLinkFailed": "Delete Link Failed",
                        "createLinkFailed": "Create Link Failed",
                        "taskNotFound": "Task {{taskId}} Not Found",
                        "unknownTaskType": "Unknown Task Type {{taskType}}",
                        "deleteTaskFailed": "Delete Task Failed",
                        "assignWorkerFailed": "Assign Worker Failed",
                        "assignMachineFailed": "Assign Machine Failed",
                        "positiveNumberWithoutSignRequired": "Positive Number without Sign Required",
                        "splitQtyTooLarge": "Split Qty must Lesser than Original Qty",
                        "splitDurationTooLarge": "Split Duration must be Lesser Than Original Duration",
                        "splitTaskFailed": "Split Task Failed",
                        "changeTaskDurationFailed": "Change Task Duration Failed",
                        "orderTaskFailed": "Order Task Failed",
                        "alreadyOrderedPlan": "Already Ordered Plan",
                        "alreadyStartedPlan": "Can't Cancel Started Task",
                        "notOrderdedPlan": "Can't cancel Not Ordered Plan",
                        "cancelOrderFailed": "Cancel Order Failed",
                        "milestoneAlreadyExists": "Milestone already exist",
                        "createMilestoneFailed": "Create Milestone Failed",
                        "updateNotSupportedTaskType": "Update Not Supported Task Type",
                        "changeMilestoneNameFailed": "Change Milestone Name Failed",
                        "InsufficientInventoryQty": "Insufficient inventory quantity for item name: {{materialName}}, item code: {{materialCode}}, item version: {{materialCodeVer}}.",
                        "qtyLotOfNotFakeInputQty": "qtyLotOfNotFakeInputQty",
                        "notBeforeProcessCompleted": "NotBeforeProcessCompleted",
                        "materialsForProductionNotTakeOver": "materialsForProductionNotTakeOver",
                        "LotMaterialsConsumeQtyRequiredQtyLack": "LotMaterialsConsumeQtyRequiredQtyLack",
                        "wrongProcessOrder": "The process order is wrong.(Start Process Order: {{startProcessOrder}}, End Process Order: {{endProcessOrder}})",
                        "outSourcingNotOrdered": "The process was not ordered.(Process Order : {{processOrder}}, Process Code : {{processCode}}, Process Name : {{processName}})",
                        "outSourcingCompletedProcess": "This is a completed process.(Process Order : {{processOrder}}, Process Code : {{processCode}}, Process Name : {{processName}})",
                        "outSourcingNotPlanedOutSourcing": "No outsourcing is planned for the process.(Process Order : {{processOrder}}, Process Code : {{processCode}}, Process Name : {{processName}})",
                        "outSourcingAlreadyRegisteredProcess": "This is already a registered process.(Process Order : {{processOrder}}, Process Code : {{processCode}}, Process Name : {{processName}})",
                        "outSourcingNotRegisteredPlan": "The process not registered in the plan.(Process Order : {{processOrder}}, Process Code : {{processCode}}, Process Name : {{processName}})",
                        "canNotUpdateOrderedTask": "Can not Update Ordered Task",
                        "stockQtyLessThanOutSourcingQty": "Stock Qty is Less Than OutSourcing Qty(Stock Qty : {{qtyCurrent}}, OutSourcing Qty : {{outSourcingQty}})",
                        "fakeInputQtyShouldBeBigoutSourcingQty": "The subscription quantity must be greater than the outsourced order quantity.",
                        "alphaNumericRequired": "Only AlphaNumeric Character Allowed",
                        "alphaNumericRequiredOrTooLong": "Not AlphaNumeric or Too Long",
                        "noAvailableMaterialFound": "No Available Material Found.(materialCode : {{materialCode}}, materialCodeVer : {{materialCodeVer}})",
                        "materialLoopDetectedInProductTree": "Material Loop Detected ({{materialCode}}/{{materialCodeVer}})",
                        "invalidUserName": "Invalid Username",
                        "createConnectionFailed": "Create DB Connection Failed",
                        "notHyphenOrNumeric": "Hyphen Or Numeric Input Error",
                        "NotExistLabDataSheet": "NotExistLabDataSheet",
                        "NotExistLabDataSheetDetail": "NotExistLabDataSheetDetail",
                        "batchAddPerformanceMetricFailure": "Batch Add Performance Metric Failed : {{reason}}",
                        "noDataToAdd": "No Data to Add",
                        "allShip": "Order number: {{orderBuyerId}}'s entire shipment failed.",
                        "materialInsufficient": "Insufficient quantity of material code: {{materialCode}}, material version: {{materialCodeVer}}, material name: {{materialName}}.",
                        "outSourcingAllShip": "Outsourcing Shipment Number: {{outSourcingShipId}}'s full shipment failed.",
                        "shipAvailableLotNotFonud": "Material name: {{materialName}}, material code: {{{materialCode}}} There is no shipping LOT for material version {{materialCodeVer}}."
                    },
                    "success":
                    {
                        "insertSuccess": "Record Inserted",
                        "updateSuccess": "{{updateCount}} Records Updated",
                        "deleteSuccess": "{{deleteCount}} Records Deleted",
                        "approvalRequestSuccess": "Approval Requested",
                        "approveApprovalSuccess": "Approval Request(s) Approved",
                        "rejectApprovalSuccess": "Approval Request(s) Rejected",
                        "cancelApprovalSuccess": "Approval Cancelled",
                        "withdrawApprovalSuccess": "Approval Request(s) Withdrawn",
                        "fileDownloadSuccess": "File Download Success",
                        "fileDownloadFailure": "File Download Failure : {{reason}}",
                        "fixMachineFailureSuccess": "Fix Machine Failure Success",
                        "moveUpProcess": "Move Up Process Success: {{processName}}[{{processCode}}]",
                        "moveDownProcess": "Move Down Process Success: {{processName}}[{{processCode}}]",
                        "productionStartSuccess": "Production for Lot No {{lotNo}} Started",
                        "completeProcessSuccess": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Completed",
                        "startProcessSuccess": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Started",
                        "holdProcessSuccess": "Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Holded",
                        "releaseHoldProcessSuccess": "Holded Process {{processName}}[{{processCode}}] for Lot No {{lotNo}} Released",
                        "cancelProductionSuccess": "Production for Lot No {{lotNo}} Canceled",
                        "importTranslationDataSuccess": "Translation Data Imported",
                        "fakeInputMaterialSuccess": "Incoming Material pre-warehousing success.",
                        "markReadAllSuccess": "Mark All Messages as Read Success",
                        "markUnreadSuccess": "Mark Messages as Not Read Success",
                        "moveToTrashSuccess": "Messages are deleted successfully",
                        "recoverMessage": "Message recovered",
                        "deleteMessage": "Message deleted permanently",
                        "emptyTrash": "All Messages in Trash Bascket Deleted",
                        "sendEmailForResetPassword": "Send Email for Reset Password Success.",
                        "passwordChanged": "Password Changed!",
                        "verifyResetPasswordToken": "Reset Password Token is authorized.",
                        "resetPassword": "Reset Password Success",
                        "insertSuccessExcel": "TEST",
                        "applyExcelSuccess": "applyExcelSuccess",
                        "splitLot": "The {{lotNo}} lot number was separated and lot {{createdLotNo}} was created.",
                        "mergeLot": "{{victimLot}} LOT has been merged into {{unionLot}} LOT.",
                        "moveLot": "{{lotNo}} LOT Moved from warehouse {{wareHouseTo}} to warehouse {{wareHouseFrom}}}",
                        "adaptLot": "The order number of LOT {{lotNo}} has been changed from {{beforeOrderBuyerId}} to {{afterOrderBuyerId}",
                        "shipLot": "Order number: {{orderBuyerId}}, Shipment number: {{shipmentId}}, {{lotNo}} LOT, {{materialName}} The product has been shipped.",
                        "backShipLot": "{{orderBuyerId}} order number, {{lotNo}} LOT, {{materialName}}} The product has been returned.",
                        "stockTaking": "{{stockTakingId}} Actual application completed.",
                        "inputSuccess": "Input Success",
                        "noticeUpdateSuccess": "Notice No.{{noticeNo}} Notice has been corrected.",
                        "outputMaterial": "\nReturn has been completed",
                        "qcRequest": "Request QC from {{receiver}}",
                        "createProductionPlanSuccess": "Production Plan Created",
                        "delteLinkSuccess": "Link Deleted",
                        "createLinkSuccess": "Link Created",
                        "deleteTaskSuccess": "Task Deleted",
                        "assignWorkerSuccess": "Worker Assigned",
                        "assignMachineSuccess": "Machine Assigned",
                        "splitTaskSuccess": "Task Splitted",
                        "changeTaskDurationSuccess": "Task Duration Changed",
                        "orderTaskSuccess": "Task Ordered",
                        "cancelOrderSuccess": "Task Order Cancelled",
                        "createMilestoneSuccess": "Milestone Created",
                        "changeMilestoneNameSuccess": "Milestone Name Changed",
                        "adaptSuccess": "Adaptation Success",
                        "outSourcingShip": "outSourcingShip",
                        "outSourcingShipDetail": "outSourcingShipDetail",
                        "batchAddPerformanceMetricSuccess": "Batch Add Performance Metric Success",
                        "shipAll": "Product with order number: {{orderBuyerId}} and ship number: {{shipmentId}}} is completely shipped.",
                        "outSourcingAllShip": "All materials with outsourced shipping number: {{outSourcingShipId}}} have been shipped."
                    },
                    "term":
                    {
                        "company": "company",
                        "userName": "username",
                        "password": "password",
                        "login": "Login",
                        "none": "None",
                        "reason": "Reason",
                        "approvalPhase":
                        {
                            "request": "Requested",
                            "approver": "Approver",
                            "approved": "Approved",
                            "cancelled": "Cancelled"
                        },
                        "keepDialogOpen": "Keep Dialog Open",
                        "inputMaterial": "Input Material",
                        "outputMaterial": "Output Material",
                        "process": "Process",
                        "bop": "BOP",
                        "bom": "BOM",
                        "so": "Order Buyer",
                        "soDetail": "Order Buyer Detail",
                        "po": "Order Purchase",
                        "poDetail": "Order Purchase Detail",
                        "shipByOrderBuyerDetail": "Ship By OrderBuyer Detail",
                        "inWareHouse": "Input Warehouse",
                        "outWareHouse": "Out Warehouse",
                        "outputList": "Output List",
                        "outputStatus": "Output Status",
                        "mergeList": "Merge List",
                        "splitList": "Split List",
                        "mergeLotSelect": "Merge Lot Select",
                        "stockTakingList": "Stock Taking List",
                        "stockTaking": "Stock Taking List",
                        "quantityNotEqualsMaterialList": "Quantity Not Equals MaterialList",
                        "hold": "Processing Holded",
                        "processing": "Processing",
                        "inputLots": "Consumed Lots",
                        "sales": "Sales",
                        "purchase": "Purchase",
                        "collections": "Collections",
                        "advancePayment": "Advance Payment",
                        "lotSelectionType": "Lot Selection Type",
                        "failedLotList": "불량 목록",
                        "productInfo": "Product Info",
                        "productList": "Product List",
                        "soProduct": "Order Buyer Detail Product",
                        "materialList": "Material List",
                        "materialInfo": "material Info",
                        "lot": "LOT",
                        "lotHistory": "LOT History",
                        "componentLots": "Component LOTs",
                        "productLots": "Product LOTs",
                        "qty": "Qty",
                        "all": "All",
                        "ownCompany": "Own Company",
                        "partialConsignment": "Partial Consignment",
                        "orderProcessing": "Order Processing",
                        "srcMaterial": "Source Material",
                        "dstMaterial": "Destination Material",
                        "fakeMaterialInputStatus": "Fake Material Input Status",
                        "soSpareStockStatus": "So Spare Stock Status",
                        "tenant": "Tenant",
                        "namespace": "Namespace",
                        "translationPath": "Translation Path",
                        "translationValue": "Translation Value",
                        "shipStatus": "Shipment Status",
                        "backShipList": "Backship List",
                        "backShipStatus": "Backship Status",
                        "delivered": "Delivered",
                        "notDelivered": "Not Delivered",
                        "materialStatus": "Material Status",
                        "expense": "Expense",
                        "productStatus": "ProductStatus",
                        "roleSet": "Role Set",
                        "msgList": "Message List",
                        "message": "Message",
                        "productProductionStatus": "Product Production Status",
                        "productProductionNoInputList": "Product Production No Input List",
                        "yesterday": "Yesterday",
                        "today": "Today",
                        "prevWeek": "Previous Week",
                        "currentWeek": "Current Week",
                        "prevMonth": "Previous Month",
                        "currentMonth": "Current Month",
                        "storeCompanyAndUserId": "Store Company and User ID",
                        "findPassword": "Find Password",
                        "standard": "standard",
                        "nonStandard": "nonStandard",
                        "customerService": "Customer Service",
                        "shipment": "shipment",
                        "shipmentDetail": "shipmentDetail",
                        "newPassword": "New Password",
                        "nsSplitList": "nonStandardSplitList",
                        "nsMergeList": "noneStandardMergeList",
                        "standardProductList": "stadnardProductList",
                        "standardProductInfo": "standardProductInfo",
                        "nonStandardProductList": "nonStadnardProductList",
                        "nonStandardProductInfo": "nonStandardProductInfo",
                        "nonStandardMaterialInfo": "nonStandardMaterialInfo",
                        "standardMaterialInfo": "standardMaterialInfo",
                        "ship": "ship",
                        "shipDetail": "ship Detail",
                        "standardShipPossibleLot": "standardShipPossibleLot",
                        "nonStandardShipPossibleLot": "nonStandardShipPossibleLot",
                        "MomSysDelivery": "MomSysDelivery",
                        "MomSysDeliveryDetail": "MomSysDeliveryDetail",
                        "fakeInput": "fakeInput",
                        "fakeInputDetail": "fakeInputDetail",
                        "backShip": "Back Ship",
                        "backShipDetail": "Back Ship Detail",
                        "standardBackShipPossibleLot": "Standard Back Ship Possible Lot",
                        "nonStandardBackShipPossibleLot": "Non Standard Back Ship Possible Lot",
                        "standardLogShip": "StandardLogShip",
                        "nonStandardLogShip": "NonStandardLogShip",
                        "shipmentAll": "shipmentAll",
                        "EguPopProduce": "Pop Produce",
                        "EguPopLead": "Pop Lead",
                        "EguPopWood": "Pop Wood",
                        "EguPopEtc": "Pop Etc",
                        "EguPopProduceDetail": "Pop Produce Detail",
                        "EguPopLeadDetail": "Pop Lead Detail",
                        "EguPopWoodDetail": "Pop Wood Detail",
                        "EguPopEtcDetail": "Pop Etc Detail",
                        "eguInputInspection": "Input Inspection",
                        "eguSelfInspection": "Self Inspection",
                        "eguInputInspectionDetail": "Input Inspection Detail",
                        "takeOverMaterial": "takeOverMaterial",
                        "worker": "Worker",
                        "lineNo": "Line No",
                        "machine": "Machine",
                        "workerUnassigned": "Worker Unassigned",
                        "lineNoUnassigned": "Line No Unassigned",
                        "machineUnassigned": "Machine Unassigned",
                        "momSysInspectionCriteria": "Inspection Criteria",
                        "momSysInspectionGroup": "Inspection Group",
                        "momSysInspectionItem": "Inspection Item",
                        "momSysInspectionList": "Inspection Group Specific Information",
                        "inspectionTargetLot": "Inspection Target Lot",
                        "momSysInspectionRslt": "Inspection Rslt",
                        "todayNotice": "todayNotice",
                        "capacity": "Capacity",
                        "workload": "Workload",
                        "workresult": "Work Result",
                        "resource": "Resource",
                        "startDate": "Start Date",
                        "unassigned": "Unassigned",
                        "MomSysVwLotProcessFail": "LOT Production Process Failure",
                        "EguVwInputInspectionFail": "Input Inspection Failure",
                        "EguVwSelfInspectionFail": "Self Inspection Failure",
                        "eguInputMaterialStatusByInspection": "EguInputMaterialStatusByInspection",
                        "taskName": "Task Name",
                        "plannedStartDate": "계획 작업 시작 시간",
                        "durationInHours": "Duration(hours)",
                        "ordered": "Ordered",
                        "completed": "Completed",
                        "notOrdered": "Not Ordered",
                        "notCompleted": "Not Completed",
                        "year": "Year",
                        "month": "Month",
                        "selectOrdered": "Select Ordered",
                        "selectCompleted": "Select Completed",
                        "production": "Production",
                        "worktime": "Work Time",
                        "eguInputList": "Input List",
                        "selectProductionItem": "Select Production Item",
                        "thisYear": "thisYear ({{year}})",
                        "lastYear": "last Year ({{year}})",
                        "twoYearsAgo": "twoYearsAgo ({{year}})",
                        "EguPopEtcFilling": "Filling",
                        "EguPopEtcMolding": "Molding",
                        "EguPopEtcPacking": "Packing",
                        "assigned": "Assigned",
                        "notAssigned": "Not Assigned",
                        "outSourcingShip": "outSourcingShip",
                        "outSourcingShipDetail": "outSourcingShipDetail",
                        "eguOutSourcingPurchase": "OutSourcing Purchase",
                        "addPlan": "Add Plan",
                        "started": "Task Started",
                        "orderedStatus": "Ordered",
                        "hour": "hours",
                        "notStarted": "Not Started",
                        "eguOutSourcingPurchaseDetail": "OutSourcing Purchase Detail",
                        "addMilestone": "Add Milestone",
                        "week": "Week",
                        "day": "Day",
                        "scale": "Scale",
                        "showMonthCount": "Show Month Count",
                        "showGrid": "Show Grid",
                        "prevYear": "Previous Year",
                        "eguOutSourcingInputInspection": "OutSourcing Input Inspection & Input",
                        "batchUpdate": "Batch Update",
                        "manual": "Manual",
                        "auto": "Auto",
                        "min": "Min",
                        "max": "Max",
                        "avg": "Average",
                        "eguOutSourcingPurchaseOutSourced": "OutSourcing Purchase OutSourced\n",
                        "partialOrdered": "Partially Ordered",
                        "eguOutSourcingPurchaseDetailInOutMaterial": "OutSourcing Purchase Detail InOut Material",
                        "eguOutSourcingMaterialReturn": "OutSourcing Material Return",
                        "materialReturnTarget": "Material Return Target",
                        "outSourcingLogShip": "outSourcingLogShip",
                        "productionMaterial": "Production Material",
                        "outSourcingFakeInput": "outSourcingFakeInput",
                        "eguOrderPurchaseMstExcelDownLoadFileNamePrefix": "OrderPurchase_",
                        "EguPopOtherWork": "Other Work",
                        "EguPopOtherWorkDetail": "Other Work Detail",
                        "eguOutSourcingMaterialReturnDetail": "OutSourcing Material Return Detail",
                        "materialReturnDetailTarget": "Material Return Target Detail",
                        "productionStatus": "Production Status",
                        "salesStatus": "Sales Status",
                        "managementIndicator": "Management Indicator",
                        "CostInfo": "CostInfo",
                        "machineRental": "Rental",
                        "mrpMaterialList": "MRP : Material List",
                        "mrpMaterialEventData": "MRP : Material Event Data",
                        "mrpMaterialTarget": "MRP Material Target",
                        "manufacturingOverhead": "manufacturingOverhead",
                        "salesAndManagementCost": "salesAndManagementCost",
                        "laborCostDetail": "laborCostDetail",
                        "laborCost": "laborCost",
                        "productSample": "productSample",
                        "experiment": "experiment",
                        "experimentDetail": "experimentDetail",
                        "subMaterialSample": "subMaterialSample",
                        "confirmProduct": "confirmProduct",
                        "eguIngredientRegistration": "eguIngredientRegistration",
                        "popNoOrderPurchaseInputHisory": "popNoOrderPurchaseInputHisory"
                    },
                    "common":
                    {
                        "ok": "OK",
                        "cancel": "Cancel",
                        "create": "Create",
                        "edit": "Edit",
                        "delete": "Delete",
                        "apply": "Apply",
                        "search": "Search",
                        "clear": "Clear",
                        "add": "Add",
                        "order": "Order",
                        "cancelOrder": "Cancel Order",
                        "today": "Today",
                        "assignResource": "Assign Resource",
                        "openAll": "Open All",
                        "closeAll": "Close All",
                        "batchAdd": "Batch Add"
                    },
                    "inputTitle":
                    {
                        "displayMode": "DisplayMode",
                        "dataTableSearch": "Search",
                        "dataTableItemCount": "ItemCount"
                    },
                    "dataTableFilter":
                    {
                        "inputTitle":
                        {
                            "filterOpr": "Opr",
                            "filterOpr2": "Opr 2",
                            "filterValue": "Value",
                            "filterValue2": "Value 2",
                            "filterAndOr": "And/Or"
                        },
                        "andOrValue":
                        {
                            "and": "and",
                            "or": "or"
                        },
                        "booleanValue":
                        {
                            "notSet": "(not set)",
                            "true": "true",
                            "false": "false"
                        },
                        "filterOperator":
                        {
                            "oprNotSet": "(not set)",
                            "stringEQ": "same with",
                            "stringNE": "not same with",
                            "stringBW": "starts with",
                            "stringEW": "ends with",
                            "stringCT": "contains",
                            "stringNC": "not contains",
                            "numberEQ": "==",
                            "numberNE": "!=",
                            "numberGT": ">",
                            "numberGE": ">=",
                            "numberLT": "<",
                            "numberLE": "<=",
                            "booleanEQ": "==",
                            "booleanNE": "!=",
                            "dateEQ": "==",
                            "dateGE": "after",
                            "dateLE": "before"
                        },
                        "buttonName":
                        {
                            "filterSubmit": "Filter Submit",
                            "filterReset": "Filter Reset"
                        },
                        "pagination":
                        {
                            "start": " Start",
                            "end": " End"
                        }
                    },
                    "buttonName":
                    {
                        "appendApprover": "Append",
                        "insertApprover": "Insert",
                        "delApprover": "Remove",
                        "create": "Create",
                        "update": "Update",
                        "delete": "Delete",
                        "searchFilter": "SearchFilters",
                        "excelDownload": "Excel Download",
                        "materialInput": "Input",
                        "materialOutput": "Output",
                        "adjustment": "Adjustment",
                        "approval": "Approval",
                        "approvalRequest": "Request Approval",
                        "approvalWithdrawal": "Withdraw Approval",
                        "approvalRejection": "Reject Approval",
                        "approvalCancel": "Cancel Approval",
                        "approveApproval": "Approve Approval",
                        "shipment": "Shipment",
                        "backShip": "Back Ship",
                        "estimateDownload": "Estimate Document Download",
                        "registerMachineFailure": "Register Failure/Repair",
                        "fixMachineFailure": "Fix Failure/Repair",
                        "addProcess": "Append Process",
                        "deleteProcess": "Delete Process",
                        "moveUpProcess": "Move Process Order Up",
                        "moveDownProcess": "Move Process Order Down",
                        "addMaterial": "Add Material",
                        "updateMaterial": "Edit Material",
                        "delMaterial": "Delete Material",
                        "outSourcing": "outSourcing",
                        "outSourcingIn": "outSourcingIn",
                        "updateOrderBuyerId": "Edit orderBuyerId",
                        "splitLot": "Split Lot",
                        "MergeLot": "Merge Lot",
                        "MoveLot": "Move Lot",
                        "cancel": "Cancel",
                        "produce": "Produce",
                        "materialInputInspect": "Material Input Inspect",
                        "cancelProduce": "Cancel Produce",
                        "newStockTaking": "New StockTaking",
                        "applyStockTaking": "apply StockTaking",
                        "startProcessing": "Start Processing",
                        "completeProcessing": "Processing Completed",
                        "holdProcessing": "Hold Processing",
                        "releaseHoldProcessing": "Release Hold Processiong",
                        "cancelProduction": "Cancel Production",
                        "addFailedLot": "Add Failed Lot",
                        "soSpareStock": "Order Buyer Spare Stock",
                        "changeSpareStock": "Change Spare Stock",
                        "createMaterial": "Create Material",
                        "createProcess": "Create Process",
                        "copyBomBop": "Copy BOM/BOP",
                        "copy": "Copy",
                        "fakeInput": "fake Input",
                        "rental": "rental",
                        "createStock": "create Stock",
                        "addTranslationPath": "Add",
                        "deleteTranslationPath": "Delete",
                        "addNamespace": "Add",
                        "deleteNamespace": "Delete",
                        "modifyTranslationValue": "Modify",
                        "export": "Export",
                        "import": "Import",
                        "mergeLot": "Merge Lot",
                        "moveLot": "Move Lot",
                        "copyPath": "Copy Translation Path",
                        "productInput": "productInput",
                        "markReadAll": "Mark as read all",
                        "markUnread": "Mark as not read",
                        "recover": "Recover",
                        "empty": "Empty",
                        "createWithExcel": "Create With Excel",
                        "changePassword": "Change Password",
                        "uploadAndApplyExcel": "uploadAndApplyExcel",
                        "shipmentCreateAll": "shipmentCreateAll",
                        "stockApplyExcel": "stockApplyExcel",
                        "produceStart": "ProduceStart",
                        "produceEnd": "produceEnd",
                        "input": "Input",
                        "QCRequest": "QC Request",
                        "InputMaterial": "InputMaterial",
                        "outputMaterial": "Output Material",
                        "eguSelfInspectionDownload": "EGU Self Inspection File Download",
                        "momSysVwLotProcessFail": "LOT Production Process Failure",
                        "eguVwInputInspectionFail": "Input Inspection Failure",
                        "eguVwSelfInspectionFail": "Self Inspection Failure",
                        "output": "Output",
                        "excelUpload": "Excel Upload",
                        "excelFormDownload": "Excel Form Download",
                        "excelUploadError": "Excel Upload Error",
                        "excelUploadSuccess": "Excel Upload Success",
                        "machineRentalOutUpdate": "Out Update",
                        "machineRentalOutCreate": "Out Create",
                        "machineRentalInUpdate": "In Update",
                        "allShip": "allShip",
                        "outSourcingAllShip": "outSourcingAllShip"
                    },
                    "date":
                    {
                        "localeName": "en",
                        "dateFormat": "yyyy/MM/dd",
                        "dateTimeFormat": "yyyy/MM/dd HH:mm",
                        "timeFormat": "HH:mm",
                        "monthFormat": "yyyy-MM"
                    },
                    "form":
                    {
                        "userForm":
                        {
                            "groupName":
                            {
                                "essential": "Essential Info",
                                "name": "Names",
                                "password": "Password",
                                "photo": "Photo"
                            }
                        },
                        "menuSetForm":
                        {
                            "groupName":
                            {
                                "name": "Menu Set Name",
                                "description": "Description"
                            }
                        },
                        "menuItemForm":
                        {
                            "groupName":
                            {
                                "essential": "Essential Info",
                                "description": "Description"
                            },
                            "selectItems":
                            {
                                "menuType":
                                {
                                    "menu": "Menu",
                                    "popmenu": "POP Menu",
                                    "title": "Title",
                                    "dividersmall": "Divider(Small)",
                                    "divider": "Divider"
                                },
                                "iconType":
                                {
                                    "materialUi": "Material UI"
                                }
                            }
                        },
                        "approvalTypeForm":
                        {
                            "selectItems":
                            {
                                "ordered":
                                {
                                    "ordered": "Use",
                                    "notOrdered": "Do Not Use"
                                }
                            }
                        },
                        "materialInventoryForm":
                        {
                            "selectItems":
                            {
                                "ioState":
                                {
                                    "input": "Input",
                                    "output": "Output"
                                }
                            }
                        },
                        "momSysMaterialByCompanyForm":
                        {
                            "selectItems":
                            {
                                "mainCustomer":
                                {
                                    "main": "Main Customer",
                                    "sub": "Sub Customer"
                                }
                            }
                        }
                    },
                    "dialog":
                    {
                        "title":
                        {
                            "user":
                            {
                                "create": "Create User",
                                "edit": "Edit User Information",
                                "delete": "Delete User"
                            },
                            "User":
                            {
                                "create": "Create User",
                                "update": "Edit User Information",
                                "delete": "Delete User"
                            },
                            "Profile":
                            {
                                "update": "Edit User Profile"
                            },
                            "Role":
                            {
                                "create": "Create Role",
                                "update": "Edit Role Information",
                                "delete": "Delete Role"
                            },
                            "RoleSet":
                            {
                                "create": "Create Role Set",
                                "update": "Edit Role Set",
                                "delete": "Delete Role Set"
                            },
                            "RoleSetDetail":
                            {
                                "create": "Create Role Set Detail",
                                "update": "Edit Role Set Detail",
                                "delete": "Delete Role Set Detail"
                            },
                            "MenuSet":
                            {
                                "create": "Create Menu Set",
                                "update": "Edit Menu Set Information",
                                "delete": "Delete Menu Set"
                            },
                            "MenuItem":
                            {
                                "create": "Create Menu Item",
                                "update": "Edit Menu Item Information",
                                "delete": "Delete Menu Item"
                            },
                            "TopMenuItems":
                            {
                                "create": "Create Top Menu Item",
                                "update": "Edit Top Menu Item Information",
                                "delete": "Delete Top Menu Item"
                            },
                            "SubMenuItems":
                            {
                                "create": "Create Sub Menu Item",
                                "update": "Edit Sub Menu Item Information",
                                "delete": "Delete Sub Menu Item"
                            },
                            "ApprovalType":
                            {
                                "create": "Create Approval Type",
                                "update": "Edit Approval Type",
                                "delete": "Delete Approval Type"
                            },
                            "Approver":
                            {
                                "create": "Create Approver",
                                "update": "Edit Approver",
                                "delete": "Delete Approver"
                            },
                            "ApprovalPath":
                            {
                                "create": "Create Approval Path",
                                "update": "Edit Approval Path",
                                "delete": "Delete Approval Path",
                                "appendApprover": "Append Approver",
                                "insertApprover": "Insert Approver",
                                "deleteApprover": "Delete Approver",
                                "manage": "Manage Approval Path"
                            },
                            "ApprovalPathNode":
                            {
                                "create": "Create Approval Path Node",
                                "update": "Edit Approval Path Node",
                                "delete": "Delete Approval Path Node"
                            },
                            "Approval":
                            {
                                "request": "Request Approval",
                                "status": "Approval Status",
                                "approve": "Approve Approval Request",
                                "reject": "Reject Approval Request",
                                "withdraw": "Withdraw Approval Request",
                                "cancel": "Cancel Approval"
                            },
                            "MaterialInOutput":
                            {
                                "input": "Material Input",
                                "output": "Material Output",
                                "adjustment": "Material adjustment"
                            },
                            "grid":
                            {
                                "columnPicker": "Select Visible Columns"
                            },
                            "Item":
                            {
                                "create": "Create Item",
                                "update": "Edit Item",
                                "delete": "Delete Item"
                            },
                            "ItemUnit":
                            {
                                "create": "Create Item Unit",
                                "update": "Edit Item Unit",
                                "delete": "Delete Item Unit"
                            },
                            "ItemType":
                            {
                                "create": "Create Item Type",
                                "update": "Edit Item Type",
                                "delete": "Delete Item Type"
                            },
                            "ItemSpec":
                            {
                                "create": "Create Item Spec",
                                "update": "Edit Item Spec",
                                "delete": "Delete Item Spec"
                            },
                            "Vendor":
                            {
                                "create": "Create Vendor",
                                "update": "Edit Vendor",
                                "delete": "Delete Vendor"
                            },
                            "Customer":
                            {
                                "create": "Create Customer",
                                "update": "Edit Customer",
                                "delete": "Delete Customer"
                            },
                            "Po":
                            {
                                "create": "Create Po",
                                "update": "Edit Po",
                                "delete": "Delete Po"
                            },
                            "PoDetail":
                            {
                                "create": "Create Po Detail",
                                "update": "Edit Po Detail",
                                "delete": "Delete Po Detail"
                            },
                            "So":
                            {
                                "create": "Create So",
                                "update": "Edit So",
                                "delete": "Delete So"
                            },
                            "SoDetail":
                            {
                                "create": "Create So Detail",
                                "update": "Edit So Detail",
                                "delete": "Delete So Detail"
                            },
                            "EstimateBase":
                            {
                                "create": "Create EstimateBase",
                                "update": "Edit EstimateBase",
                                "delete": "Delete EstimateBase"
                            },
                            "Estimate":
                            {
                                "create": "Create Estimate",
                                "approve": "Approval Request",
                                "delete": "Delete Estimate"
                            },
                            "EstimateItem":
                            {
                                "create": "Create EstimateItem",
                                "update": "Edit EstimateItem",
                                "delete": "Delete EstimateItem"
                            },
                            "WorkerPerformance":
                            {
                                "create": "Create Performance",
                                "update": "Update Performance",
                                "delete": "Delete Performance"
                            },
                            "Shipment":
                            {
                                "create": "Create Shipment",
                                "delete": "Delete Shipment"
                            },
                            "ConsumablesInOutput":
                            {
                                "input": "Consumables Input",
                                "output": "Consumables Output",
                                "adjustment": "Consumables adjustment"
                            },
                            "ProductInOutput":
                            {
                                "input": "Product Input",
                                "output": "Product Output",
                                "dueDiligence": "Product DueDiligence"
                            },
                            "AccessControlTarget":
                            {
                                "create": "Create Access Control Target",
                                "update": "Update Access Control Target",
                                "delete": "Delete Access Control Target"
                            },
                            "AccessControlRule":
                            {
                                "create": "Create Access Control Rule",
                                "update": "Update Access Control Rule",
                                "delete": "Delete Access Control Rule"
                            },
                            "ReturnedItem":
                            {
                                "create": "Create Returned Item",
                                "update": "Update Returned Item",
                                "delete": "Delete Returned Item"
                            },
                            "Tenant":
                            {
                                "create": "Create Tenant",
                                "update": "Update Tenant",
                                "delete": "Delete Tenant"
                            },
                            "DbConf":
                            {
                                "create": "Create DB Configuration",
                                "update": "Update DB Configuration",
                                "delete": "Delete DB Configuration"
                            },
                            "Namespace":
                            {
                                "create": "Create Namespace",
                                "update": "Update Namespace",
                                "delete": "Delete Namespace"
                            },
                            "SupportedLanguage":
                            {
                                "create": "Create Supported Language",
                                "update": "Update Supported Language",
                                "delete": "Delete Supported Language"
                            },
                            "Translation":
                            {
                                "create": "Create Translation",
                                "update": "Update Translation",
                                "delete": "Delete Translation"
                            },
                            "TenantLogo":
                            {
                                "create": "Create Tenant Logo Imamge",
                                "update": "Update Tenant Logo Imamge",
                                "delete": "Delete Tenant Logo Imamge"
                            },
                            "MomSysUdtLineNo":
                            {
                                "create": "Create Line No",
                                "update": "Update Line No",
                                "delete": "Delete Line No"
                            },
                            "MomSysUdtWorkStation":
                            {
                                "create": "Create WorkStation",
                                "update": "Update WorkStation",
                                "delete": "Delete WorkStation"
                            },
                            "MomSysUdtWareHouse":
                            {
                                "create": "Create WareHouse",
                                "update": "Update WareHouse",
                                "delete": "Delete WareHouse"
                            },
                            "MomSysMstProcess":
                            {
                                "create": "Create Process",
                                "update": "Update Process",
                                "delete": "Delete Process"
                            },
                            "MomSysUdtCompany":
                            {
                                "create": "Create Company",
                                "update": "Update Company",
                                "delete": "Delete Company"
                            },
                            "MomSysUdtMaterialType":
                            {
                                "create": "Material Type Create",
                                "update": "Material Type Update",
                                "delete": "Material Type Delete"
                            },
                            "MomSysMstMaterial":
                            {
                                "create": "Material Create",
                                "update": "Material Update",
                                "delete": "Material Delete",
                                "excelUpload": "Excel Upload"
                            },
                            "MomSysUdtMachineMainType":
                            {
                                "create": "Machine Maintenance Type Create",
                                "update": "Machine Maintenance Type Update",
                                "delete": "Machine Maintenance Type Delete"
                            },
                            "MomSysMstMachine":
                            {
                                "create": "Machine Create",
                                "update": "Machine Update",
                                "delete": "Machine Delete",
                                "registerFailure": "Register Machine Failure",
                                "fixFaiure": "Fix Machine Failure",
                                "fixFailure": "Fix Machine Failure"
                            },
                            "MomSysUglMachineMain":
                            {
                                "create": "Machine Maintenance History Create",
                                "update": "Machine Maintenance History Update",
                                "delete": "Machine Maintenance History Delete"
                            },
                            "MomSysUglOrderBuyerMst":
                            {
                                "create": "Order Buyer Create",
                                "update": "Order Buyer Update",
                                "delete": "Order Buyer Delete"
                            },
                            "MomSysUglOrderBuyerDetail":
                            {
                                "create": "Order Buyer Detail Create",
                                "update": "Order Buyer Detail Update",
                                "delete": "Order Buyer Detail Delete"
                            },
                            "MomSysUglOrderPurchaseMst":
                            {
                                "spareStockCreate": "Spare Stock Create",
                                "create": "Order Purchase Create",
                                "update": "Order Purchase Update",
                                "delete": "Order Purchase Delete"
                            },
                            "MomSysUglOrderPurchaseDetail":
                            {
                                "create": "Order Purchase Detail Create",
                                "update": "Order Purchase Detail Update",
                                "delete": "Order Purchase Detail Delete",
                                "materialInputInspect": "Material Input Inspect"
                            },
                            "MomSysLogShip":
                            {
                                "ship": "ship",
                                "backShip": "back Ship"
                            },
                            "MomSysUdtBillOfProcess":
                            {
                                "create": "Create BOP Process",
                                "delete": "Delete BOP Process"
                            },
                            "MomSysUglCompanyCollection":
                            {
                                "create": "Company Collection Create",
                                "update": "Company Collection Update",
                                "delete": "Company Collection Delete"
                            },
                            "MomSysSalesDetail":
                            {
                                "create": "Sales Detail Create",
                                "update": "Sales Detail Update",
                                "delete": "Sales Detail Delete"
                            },
                            "MomSysCollectionsDetail":
                            {
                                "create": "Collections Detail Create",
                                "update": "Collections Detail Update",
                                "delete": "Collections Detail Delete"
                            },
                            "MomSysVwAdvancePayment":
                            {
                                "create": "Advance Payment Create",
                                "update": "Advance Payment Update",
                                "delete": "Advance Payment Delete"
                            },
                            "MomSysLogOutSourcing":
                            {
                                "outSourcing": "outSourcing",
                                "outSourcingIn": "Incoming Outsourcing Product"
                            },
                            "MomSysUdtBillOfMaterial":
                            {
                                "create": "Add BOM Material",
                                "update": "Update BOM Material",
                                "delete": "Delete BOM Material"
                            },
                            "MomSysLogAdapt":
                            {
                                "updateOrderBuyerId": "Update OrderBuyer Id",
                                "changeSpareStock": "Save Spare Stock"
                            },
                            "MomSysLogSplit":
                            {
                                "splitLot": "Split Lot",
                                "mergeLot": "Merge Lot"
                            },
                            "MomSysLogMove":
                            {
                                "moveLot": "move Lot"
                            },
                            "Produce":
                            {
                                "startProduction": "Start Production",
                                "startProcess": "Start Process",
                                "completeProcess": "Complete Process",
                                "holdProcess": "Hold Process",
                                "releaseHoldProcess": "Hold Process",
                                "cancelProduction": "Release Hold Process",
                                "produceStart": "start produce",
                                "qcRequest": "QC Request",
                                "outputMaterial": "Output Material"
                            },
                            "MomSysLogWorkEdit":
                            {
                                "cancelProduce": "Cancel Procude"
                            },
                            "MomSysUglStockTakingMst":
                            {
                                "create": "Create StockTaking",
                                "stockApplyExcel": "stockApplyExcel"
                            },
                            "MomSysUglStockTakingDetail":
                            {
                                "createStock": "Create Stock",
                                "update": "Update Stock"
                            },
                            "MomSysLogAdjust":
                            {
                                "applyStockTaking": "apply StockTaking"
                            },
                            "MomSysPurchaseDetail":
                            {
                                "create": "purchase create",
                                "update": "purchase update",
                                "delete": "purchase delete"
                            },
                            "MomSysBomBopTree": "BOM/BOP Tree",
                            "MomSysProductTree": "Product Tree",
                            "MomSysCodeGenerate":
                            {
                                "create": "code create",
                                "update": "code update",
                                "delete": "code delete"
                            },
                            "MomSysMaterialByCompany":
                            {
                                "create": "Material By Company create",
                                "update": "Material By Company update",
                                "delete": "Material By Company delete"
                            },
                            "CopyBomBop": "BOM/BOP 복사",
                            "EguMaterialFakeInput":
                            {
                                "fakeInput": "fake Input",
                                "create": "materialFakeInput Create",
                                "update": "EguMaterialFakeInputUpdate"
                            },
                            "ImportTranslation": "Import Translation",
                            "ExportTranslation": "Export Translation",
                            "CopyTranslation": "Copy Translation",
                            "TenantTranslation":
                            {
                                "create": "Create Tenant Translation",
                                "delete": "Delete Tenant Translation"
                            },
                            "RoleSetTranslation":
                            {
                                "create": "Create Role Set Translation",
                                "delete": "Delete Role Set Translation"
                            },
                            "MomSysExpenseDetail":
                            {
                                "create": "Create Expense Detail",
                                "update": "Update Expense",
                                "delete": "Delete Expense"
                            },
                            "ShipmentDetail":
                            {
                                "shipment": "Product Shipment"
                            },
                            "Performance":
                            {
                                "create": "Register Production Result",
                                "update": "Update Production Result",
                                "delete": "Delete Production Result"
                            },
                            "TenantOption":
                            {
                                "create": "Create Tenant Option",
                                "update": "Update Tenant Option",
                                "delete": "Delete Tenant Option"
                            },
                            "TranslationPath":
                            {
                                "create": "Create Translation Path"
                            },
                            "RoleSetOption":
                            {
                                "create": "Create Role Set Option",
                                "update": "Update Role Set Option",
                                "delete": "Delete Role Set Option"
                            },
                            "DefaultOption":
                            {
                                "create": "Create Default Option",
                                "update": "Update Default Option",
                                "delete": "Delete Default Option"
                            },
                            "TranslationValue":
                            {
                                "update": "Update Translation Value"
                            },
                            "Message":
                            {
                                "confirm":
                                {
                                    "markReadAll": "Mark All Messages as Read",
                                    "markUnread": "Mark Messages as Not Read",
                                    "moveToTrash": "Move Messages To Trash",
                                    "recover": "Recover Message",
                                    "empty": "Empty Trash Basket"
                                }
                            },
                            "Filter": "Filter Condition",
                            "ConfirmFindPassword": "Find Password",
                            "ConfirmResetPassword": "Reset Password",
                            "MomSysShipment":
                            {
                                "create": "MomSysShipment Create",
                                "update": "MomSysShipment Update",
                                "delete": "MomSysShipment Delete"
                            },
                            "MomSysShipmentDetail":
                            {
                                "createAll": "MomSysShipmentDetail CreateAll",
                                "create": "MomSysShipmentDetail Create",
                                "update": "MomSysShipmentDetail Update",
                                "delete": "MomSysShipmentDetail Delete"
                            },
                            "MomSysDelivery":
                            {
                                "create": "MomSysDelivery Create",
                                "update": "MomSysDelivery Update",
                                "delete": "MomSysDelivery Delete"
                            },
                            "TkUglOrderBuyerMst":
                            {
                                "create": "Order Buyer Create",
                                "update": "Order Buyer Update",
                                "delete": "Order Buyer Delete"
                            },
                            "TkUglOrderBuyerDetail":
                            {
                                "create": "Order Buyer Detail Create",
                                "update": "Order Buyer Detail Update",
                                "delete": "Order Buyer Detail Delete"
                            },
                            "TkUglOrderPurchaseMst":
                            {
                                "create": "Order Buyer Create",
                                "update": "Order Purchase Update",
                                "delete": "Order Purchase Delete"
                            },
                            "MomSysBackShip":
                            {
                                "backShip": "create backShip",
                                "create": "Back Ship Create",
                                "update": "Back Ship Update",
                                "delete": "Back Ship Delete"
                            },
                            "TkUglOrderPurchaseDetail":
                            {
                                "create": "Order Purchase Detail Create",
                                "update": "Order Purchase Detail Update",
                                "delete": "Order Purchase Detail Delete"
                            },
                            "MomSysBackShipDetail":
                            {
                                "create": "Back Ship Detail Create"
                            },
                            "MomSysShip":
                            {
                                "create": "create ship",
                                "ship": "create ship",
                                "update": "MomSysShip.update",
                                "allShip": "all ship"
                            },
                            "PopProduce":
                            {
                                "startProduction": "popStartProduction"
                            },
                            "EguInputInspection":
                            {
                                "register": "Input Inspection Register",
                                "update": "Input Inspection Update",
                                "input": "Input by Input Inspection"
                            },
                            "MomSysInspectionItem":
                            {
                                "create": "InspectionItem Create",
                                "update": "Inspection Item Update",
                                "delete": "Inspection Item Delete"
                            },
                            "MomSysInspectionCriteria":
                            {
                                "create": "Inspection Criteria Create",
                                "update": "Inspection Criteria Update",
                                "delete": "Inspection Criteria Delete"
                            },
                            "MomSysInspectionGroup":
                            {
                                "create": "Inspection Group Create",
                                "update": "Inspection Group Update",
                                "delete": "Inspection Group Delete"
                            },
                            "MomSysInspectionList":
                            {
                                "create": "Inspection Group Specific Information Add",
                                "delete": "Inspection Group Specific Information Delete"
                            },
                            "MomSysUdtOwnCompany":
                            {
                                "update": "MomSysUdt?OwnCompany update",
                                "create": "Company Create"
                            },
                            "MomSysNsMstMaterial":
                            {
                                "create": "MomSysNsMstMaterial Create",
                                "update": "MomSysNsMstMaterial Update",
                                "delete": "MomSysNsMstMaterial Delete"
                            },
                            "EguSelfInspection":
                            {
                                "register": "Self Inspection Register",
                                "update": "Self Inspection Update",
                                "moveLot": "Move Lot"
                            },
                            "MomSysInspectionRslt":
                            {
                                "register": "Inspection Result Register",
                                "update": "Inspection Result Update",
                                "delete": "Inspection Result Delete"
                            },
                            "outputMaterial":
                            {
                                "create": "Output Material"
                            },
                            "MomSysUdtDecisionCode":
                            {
                                "create": "Decision Code",
                                "update": "Decision Code",
                                "delete": "Decision Code"
                            },
                            "EguMaterialFakeInputDetail":
                            {
                                "update": "EguMaterialFakeInputDetaili Update",
                                "delete": "EguMaterialFakeInputDetail Delete"
                            },
                            "ProductionPlan":
                            {
                                "createProductionPlan": "Create Production Plan",
                                "assignWorker": "Assign Worker for Tast {{taskName}}",
                                "assignMachine": "Assign Machine for Task {{taskName}}",
                                "splitTask": "Split Task for Task {{taskName}} ( {{duration}} hours )",
                                "orderTask": "Order Task",
                                "cancelOrder": "Cancel Order",
                                "addMilestone": "Add Milestone",
                                "changeTaskDuration": "Change Task {{taskName}}",
                                "changeMilestoneName": "Change Milestone Name ({{taskName}})"
                            },
                            "ConfirmDeleteTask": "Delete Task",
                            "EguMstMaterial":
                            {
                                "create": "Item Save",
                                "update": "Item Update",
                                "excelUpload": "Excel Upload"
                            },
                            "MomSysWageRate":
                            {
                                "create": "Wage Rate Create",
                                "update": "Wage Rate Update",
                                "delete": "Wage Rate Delete"
                            },
                            "EguPopNotice":
                            {
                                "delete": "EguPopNoticeDelete"
                            },
                            "EguOutSourcingShip":
                            {
                                "create": "EguOutSourcingShip Create",
                                "update": "EguOutSourcingShip Update"
                            },
                            "materialInput": "materialInput",
                            "PerformanceMetric":
                            {
                                "create": "Add Performance Metric",
                                "edit": "Edit Performance Metric",
                                "batchUpdate": "Batch Update"
                            },
                            "EguOutSourcingFakeInput":
                            {
                                "create": "create EguOutSourcingFakeInput create",
                                "update": "EguOutSourcingFakeInput.update"
                            },
                            "EguOutSourcingInputInspection":
                            {
                                "input": "Input By OutSourcing Input Inspection",
                                "register": "OutSourcing Input Inspection Register",
                                "update": "OutSourcing Input Inspection Update"
                            },
                            "Holiday":
                            {
                                "create": "Create Holiday",
                                "update": "Update Holiday",
                                "delete": "Delete Holiday"
                            },
                            "EguOutSourcingPurchase":
                            {
                                "create": "OutSourcing Purchase Create",
                                "delete": "OutSourcing Purchase Delete"
                            },
                            "AdditionalWorkDay":
                            {
                                "create": "Create Additional Work Day",
                                "update": "\bUpdate Additional Work Day",
                                "delete": "Delete Additional Work Day"
                            },
                            "WorkDay":
                            {
                                "create": "Create Work Day",
                                "delete": "Delete Work Day"
                            },
                            "WorkerDayOfWeekCapacity":
                            {
                                "create": "Create Worker Day of Week Capacity",
                                "update": "Update Worker Day of Week Capacity",
                                "delete": "Delete Worker Day of Week Capacity"
                            },
                            "WorkerDateCapacity":
                            {
                                "create": "Create Worker Date Capacity",
                                "update": "Update Worker Date Capacity",
                                "delete": "Delete Worker Date Capacity"
                            },
                            "DefaultWorkerDayOfWeekCapacity":
                            {
                                "create": "Create Day of Week Capacity",
                                "update": "Update Day of Week Capacity",
                                "delete": "Delete Day of Week Capacity"
                            },
                            "DefaultMachineCapacity":
                            {
                                "create": "Create Default Machine Capacity",
                                "update": "Update Default Machine Capacity",
                                "delete": "Delete Default Machine Capacity"
                            },
                            "MachineDateCapacity":
                            {
                                "create": "Create Machine Date Capacity",
                                "update": "Update Machine Date Capacity",
                                "delete": "Delete Machine Date Capacity"
                            },
                            "EguOutSourcingPurchaseDetail":
                            {
                                "create": "OutSourcing Purchase Detail Create",
                                "update": "OutSourcing Purchase Detail Update",
                                "delete": "OutSourcing Purchase Detail Delete"
                            },
                            "EguOutSourcingMaterialReturn":
                            {
                                "register": "OutSourcing Material Return Register",
                                "input": "OutSourcing Material Return Input",
                                "update": "OutSourcing Material Return Update",
                                "delete": "OutSourcing Material Return Delete"
                            },
                            "EguPopOtherWork":
                            {
                                "create": "Other Work Create",
                                "update": "Other Work Update",
                                "delete": "Other Work Delete"
                            },
                            "EguPopOtherWorkDetail":
                            {
                                "create": "Other Work Detail Create",
                                "update": "Other Work Detail Update",
                                "delete": "Other Work Detail Delete"
                            },
                            "EguOutSourcingMaterialReturnDetail":
                            {
                                "register": "OutSourcing Material Return Detail Register",
                                "update": "OutSourcing Material Return Detail Update",
                                "delete": "OutSourcing Material Return Detail Delete"
                            },
                            "MomSysMsgMachine":
                            {
                                "registerFailure": "Machine Info Save"
                            },
                            "eguMachineRental":
                            {
                                "outCreate": "Output Create",
                                "outUpdate": "Output Update",
                                "inUpdate": "Input Update"
                            },
                            "EguMachineRental":
                            {
                                "delete": "Machine Rental Delete"
                            },
                            "mrpMaterialList":
                            {
                                "register": "MRP : Material List Register"
                            },
                            "MrpMaterialList":
                            {
                                "update": "MRP : Material List Update",
                                "delete": "MRP : Material List Delete"
                            },
                            "BatchAddPerformanceMetric": "Batch Add Performance Metric",
                            "EguOutSourcingShipDetail":
                            {
                                "allShip": "EguOutSourcingShipDetail allShip"
                            },
                            "EguCostInfoMgmt":
                            {
                                "create": "EguCostInfoMgmt Create",
                                "update": "EguCostInfoMgmt update",
                                "delete": "EguCostInfoMgmt delete"
                            },
                            "EguLaborCost":
                            {
                                "create": "EguLaborCost create",
                                "update": "EguLaborCost update",
                                "delete": "EguLaborCost delete"
                            },
                            "EguProductSample":
                            {
                                "create": "EguProductSampleCreate",
                                "update": "EguProductSampleUpdate",
                                "delete": "EguProductSampleDelete",
                            },
                            "EguLabDataSheet":
                            {
                                "create": "EguLabDataSheetCreate",
                                "update": "EguLabDataSheetUpdate",
                                "delete": "EguLabDataSheetDelete",
                            },
                            "EguLabDataSheetDetail":
                            {
                                "create": "EguLabDataSheetDetailCreate",
                                "delete": "EguLabDataSheetDetailDelete",
                                "update": "EguLabDataSheetDetailUpdate",
                            },
                            "EguSubMaterialSample":
                            {
                                "create": "EguSubMaterialSampleCreate",
                                "update": "EguSubMaterialSampleUpdate",
                                "delete": "EguSubMaterialSampleDelete",
                            },
                            "EguIngredientRegistrationCaption":
                            {
                                "create": "EguIngredientRegistrationCaptionCreate",
                                "delete": "EguIngredientRegistrationCaptionDelete",
                                "update": "EguIngredientRegistrationCaptionUpdate",
                            },
                            "EguIngredientRegistration":
                            {
                                "create": "EguIngredientRegistrationCreate",
                                "update": "EguIngredientRegistrationUpdate",
                                "delete": "EguIngredientRegistrationDelete",
                            },
                            "PopNoOrderPurchaseInput":
                            {
                                "create": "PopNoOrderPurchaseInputCreate"
                            }
                        },
                        "msg":
                        {
                            "confirm":
                            {
                                "delete": "Do you really want to delete {{type}} {{instance}}?",
                                "approve": "Do you really want to approve request?",
                                "reject": "Do you really want to reject request?",
                                "witdraw": "Do you really want to withdraw request?",
                                "cancel": "Do you really want to cancel approval?",
                                "fixMachine": "Do you really want to fix machine failure {{machine}}?",
                                "moveUpProcess": "Do you really want to Move Up Order of Process {{process}}?",
                                "moveDownProcess": "Do you really want to Move Down Order of Process {{process}}?",
                                "holdProcessing": "Do you really want to Hold Processing of process [{{processCode}}: {{processName}}] on LotNo [{{lotNo}}]?",
                                "releaseHoldProcessing": "Do you really want to Release Hold Processing of process [{{processCode}}: {{processName}}] on LotNo [{{lotNo}}]?",
                                "cancelProduction": "Do you really want to Cancel Production of LotNo [{{lotNo}}]?",
                                "changeSpareStock": "Do you really want to Change SpareStock of LotNo [{{lotNo}}]?",
                                "fakeInput": "Do you really want to FakeInput [{{material}}]?",
                                "importTranslation": "Do you really want to Import Translation Data?",
                                "withdraw": "Do you really want to withdraw approval request?",
                                "startProduce": "Are you sure you want to start the operation with the order number: {{orderBuyerId}}, the process name: {{processName}}, and the product name: {{materialName})?",
                                "outputMaterial": "Do you want to return the material?",
                                "allShip": "Order number: {{orderBuyerId}} Are you sure you want to ship completely?",
                                "outSourcingAllShip": "Outsourcing Shipment Number: {{outSourcingShipId}} Are you sure you want to ship all of them?"
                            },
                            "Message":
                            {
                                "confirm":
                                {
                                    "markReadAll": "Do you really want to mark all messages as read?",
                                    "markUnread": "Do you really want to mark messages as not read?",
                                    "moveToTrash": "Do you really want to delete messages?",
                                    "recover": "Do you really want to recover message?",
                                    "delete": "Do you really want to delete message permanently?",
                                    "empty": "Do you really want to delete all messages in trash bascket?"
                                }
                            },
                            "ConfirmFindPassword": "When you press the OK button, an email containing a link to the password change screen will be sent.",
                            "ConfirmResetPassword": "Do you really want to change to new password?",
                            "ConfirmDeleteTask": "Do you really want to delete task {{taskName}}?",
                            "ConfirmBatchAddPerformanceMetric": "Do you really want to add performance metric all together?"
                        }
                    },
                    "label":
                    {
                        "outputSize": "Ouput Row Size",
                        "approvalPaths": "Approval Paths"
                    },
                    "toolTips":
                    {
                        "help": "Help",
                        "alert": "Alert - {{msg}}",
                        "profile": "Profile",
                        "msg":
                        {
                            "noAlert": "No Alert",
                            "alertCount": "{{count}} Alerts Exist"
                        },
                        "approvalPathEdit":
                        {
                            "appendApprover": "Add Approver after Last Approver",
                            "insertApprover": "Add Approver in front of Selected Approver",
                            "delApprover": "Remove Approver"
                        }
                    },
                    "selectItems":
                    {
                        "approvalState":
                        {
                            "approvalPending": "Approval Pending",
                            "partiallyApproved": "Partially Approved",
                            "totallyApproved": "Approval Complete",
                            "approvalRejected": "Approval Rejected",
                            "approvalWithdrawn": "Approval Withdrawn",
                            "approvalCancelled": "Approval Cancelled"
                        },
                        "approvalDetailState":
                        {
                            "pending": "Pending",
                            "approved": "Approved",
                            "rejected": "Rejected",
                            "cancelled": "Canceled"
                        },
                        "approvalEvent":
                        {
                            "request": "Request",
                            "approve": "Approve",
                            "reject": "Reject",
                            "withdraw": "Withdraw",
                            "cancel": "Cancel",
                            "approved": "Approved"
                        },
                        "approvalOrdered":
                        {
                            "use": "Use",
                            "doNotUse": "Not Use"
                        },
                        "accessControl":
                        {
                            "deny": "Deny Access",
                            "allow": "Allow Access"
                        },
                        "hideUi":
                        {
                            "hide": "Hide",
                            "show": "Show"
                        },
                        "tenantStatus":
                        {
                            "active": "Active",
                            "inactive": "Inactive"
                        },
                        "machineAvailable":
                        {
                            "available": "Available",
                            "notAvailable": "Not Available(Failure)"
                        },
                        "logoSize":
                        {
                            "small": "Small",
                            "large": "Large"
                        },
                        "yesNo":
                        {
                            "yes": "Yes",
                            "no": "No"
                        },
                        "initCriteria":
                        {
                            "day": "day",
                            "week": "week",
                            "month": "month",
                            "year": "year"
                        },
                        "productionCategory":
                        {
                            "ownCompany": "Own Company",
                            "partialConsignment": "Partial Consignment",
                            "orderProcessing": "Order Processing"
                        },
                        "sourcingState":
                        {
                            "inSourcing": "IN",
                            "outSourcing": "OUT"
                        },
                        "orderBuyerState":
                        {
                            "enroll": "Enroll",
                            "hold": "Hold",
                            "cancel": "Cancel"
                        },
                        "msgRead":
                        {
                            "read": "Read",
                            "notRead": "Not Read"
                        },
                        "notShip": "Not Ship",
                        "partialShip": "Partial Ship",
                        "completeShip": "Complete Ship",
                        "notDelivery": "notDelivery",
                        "partialDelivery": "partialDelivery",
                        "completeDelivery": "completeDelivery",
                        "stockTaking":
                        {
                            "All": "ALL"
                        },
                        "qcResult":
                        {
                            "OK": "Normal",
                            "NOK": "Defective"
                        },
                        "produceStatus":
                        {
                            "unworked": "Unworked",
                            "working": "Working",
                            "holding": "Holding",
                            "completion": "Completion"
                        },
                        "takeOverStatus":
                        {
                            "unacquired": "Unacquired",
                            "partial": "Partial",
                            "completion": "Completion"
                        },
                        "orderedSelection":
                        {
                            "all": "All",
                            "notOrdered": "Not Ordered",
                            "ordered": "Ordered"
                        },
                        "completedSelection":
                        {
                            "all": "All",
                            "completed": "Completed",
                            "notCompleted": "Not Completed"
                        },
                        "outSourcingStatus":
                        {
                            "shipComplete": "Ship Complete",
                            "fakeInputComplete": "Fake Input Complete",
                            "inputInspectionComplete": "Input Inspection Complete",
                            "inputComplete": "Input Complete"
                        },
                        "dayOfWeek":
                        {
                            "sunday": "Sunday",
                            "monday": "Monday",
                            "tuesday": "Tuesday",
                            "wednesday": "Wednesday",
                            "thursday": "Thursday",
                            "friday": "Friday",
                            "saturday": "Saturday"
                        },
                        "productFamily1": "Eye Liner",
                        "productFamily2": "Eye Brow",
                        "productFamily3": "Lip Stick",
                        "productFamily4": "Eye Shadow",
                        "productFamily5": "Mascara",
                        "productFamily6": "Lip Gloss",
                        "productFamily7": "Lip Balm",
                        "productFamily8": "Etc",
                        "mrpEventType":
                        {
                            "consume": "Consume",
                            "production": "Production",
                            "inputByPurchase": "Input By Purchase"
                        },
                        "sampleComplete": "sampleComplete",
                        "sampleCancel": "sampleCancel",
                        "sampleEtc": "sampleEtc",
                        "sampleStop": "sampleStop",
                        "sampleHold": "sampleHold",
                        "suitable": "suitable",
                        "inadequate": "inadequate",
                        "sampleEmpty": "sampleEmpty",
                    },
                    "notification":
                    {
                        "msg":
                        {
                            "approvalRequestMsg": "Approval for {{msg}} is requested by {{requester}}",
                            "approvalPermitMsg": "Approval for {{msg}} is permitted by {{approver}}",
                            "approvalRejectMsg": "Approval for {{msg}} is rejected by {{approver}}",
                            "approvalWithdrawMsg": "Approval for {{msg}} is withdrawn by {{requester}}",
                            "approvalCancelMsg": "Approval for {{msg}} is cancelled by {{approver}}",
                            "passwordChangeEmailSent": "E-mail containing link to password change screen is sent.",
                            "qcRequestMsg": "The {{sender}} requested QC for ({{materialInfo}}) production",
                            "qtyExceededWarningMsg": "The {{materialName}} is overused in the {{process}}."
                        }
                    },
                    "approvalRequestMsg":
                    {
                        "msg":
                        {
                            "po": "{{poName}}({{poNumber}}) Po case",
                            "estimate": "{{estimateNumber}} V{{versionMajor}}.{{versionMinor}} Estimate Approval Request",
                            "momSysSalesDetail": "{{orderBuyerId}} Sales",
                            "momSysCollectionsDetail": "{{orderBuyerId}} Collections",
                            "momSysPurchaseDetail": "{{orderPurchaseId}} Purchase",
                            "momSysExpenseDetail": "{{orderPurchaseId}} Expense",
                            "so": "{{soName}}({{soNumber}}) So Case",
                            "stockTaking": "{{stockTakingId}} Stock Taking Request",
                            "momSysShipment": "({{shipmentNo}}) Shipment",
                            "eguInputInspection": "{{inputInspectionId}} Input Inspection Approval Request",
                            "popProcess": "{{processName}}({{orderBuyerId}}, {{materialName}}) Pop Process",
                            "eguSelfInspection": "{{selfInspectionId}} Self Inspection Approval Request",
                            "momSysBackShip": "{{orderBuyerId}}({{orderBuyerName}}) BackShip",
                            "eguPopOtherWork": "{{workerFullName}}, {{otherWorkDate}} OtherWork",
                            "eguOutSourcingPurchase": "{{outSourcingId}}, {{outSourcingName}} Outsourcing Purchase",
                            "eguLabDataSheet": "{{eguLabDataSheetId}} Lab",
                            "eguIngredientRegistrationCaption": "{{captionId}} confirm sample"
                        }
                    },
                    "infoMsg":
                    {
                        "dragAndDropFileOrClickHere": "Drag and Drop File or Click Here",
                        "noMoreFileAllowed": "No more file can be added",
                        "noData": "No Data"
                    },
                    "groupByItem":
                    {
                        "lotNo": "LOT",
                        "material": "Material",
                        "process": "Process",
                        "lineNo": "Line No",
                        "machine": "Machine",
                        "worker": "Worker",
                        "companyName": "companyName\n",
                        "division": "division",
                        "orderBuyerId": "orderBuyerId",
                        "companyCode": "companyCode"
                    },
                    "test": "Test-en",
                    "testInterpoation": "test interPolation value1 : {{value1}} value2 : {{value2}}",
                    "testValue1": "testValue1",
                    "testValue2": "testValue2",
                    "msg":
                    {
                        "translationPathNotSelected": "Translation Path Not Selected",
                        "translationPathCopied": "Translation Path Copied to Clipboard"
                    },
                    "workState":
                    {
                        "QUEUED": "QUEUED",
                        "CLOSED": "CLOSED",
                        "FINISHED": "FINISHED",
                        "PROCESSING": "PROCESSING",
                        "COMPLETED": "COMPLETED",
                        "FAILED": "FAILED"
                    },
                    "ariatech":
                    {
                        "zip": "(zip:14057)",
                        "address": "401 Simin-daero, Dongan-gu, Anyang-si, Gyeonggi-do No. 1011 (Gwanyang-dong, Daeryung Techno Town 15th)",
                        "companyName": "Company : ARIATECH",
                        "ceo": "CEO : Ko Hong-Gi",
                        "bizLicNo": "Business license number : 718-86-00667",
                        "email": "e-mail : mom-support@ariatech.kr",
                        "tel": "Tel : 070-5102-8300",
                        "fax": "Fax : 031-389-6082",
                        "copyright": "Ariatech Inc. All rights reserved."
                    },
                    "email":
                    {
                        "changePassword":
                        {
                            "html": "<!DOCTYPE html>\n<html>\n  <head>\n    <title>Reset Password</title>\n    <meta charset=\"UTF-8\" />\n    <style>\n      .container {\n        display: grid;\n        grid-template-rows: auto auto auto auto;\n        gap : 15px;\n        width: 500px;\n        margin: auto;\n      }\n\n      .logoSection {\n        grid-row: 1 / 2;\n      }\n\n      .logo {\n        width: 150px;\n        margin-left: -10px;\n      }\n\n      .headerSection {\n        grid-row: 2 / 3;\n        height: 7rem;\n        border-bottom: 1px solid LightGrey;\n      }\n\n      .contentSection {\n        grid-row: 3 / 4;\n        height: 6rem;\n        margin-top: 1rem;\n      }\n\n      .linkSection {\n        grid-row: 4 / 5;\n      }\n\n      .headerText, .contentText {\n        line-height: 0.8rem;\n      }\n\n      .link {\n        text-decoration: none;\n      }\n    </style>\n  </head>\n  <body>\n    <div class=\"container\">\n      <div class=\"logoSection\">\n        <img class=\"logo\" src=\"https://radix-mrm-public.s3.ap-northeast-2.amazonaws.com/logo.png\" alt=\"Araitech\" />\n      </div>\n      <div class=\"headerSection\">\n        <h2 class=\"headerText\">E-mail for reset password of</h2>\n        <h2 class=\"headerText\">Ariatech Smart Factory</h2>\n      </div>\n      <div class=\"contentSection\">\n        <p class=\"contentText\">Connect to password reset screen by clicking below link.</p>\n        <p class=\"contentText\">The expiration period of link is {{expiresIn}} hours.</p>\n      </div>\n      <div class=\"linkSection\">\n        <a class=\"link\" href=\"{{url}}/changePassword/#{{tenant}}#{{username}}#{{token}}\" target=\"_blank\">Reset Password<a>\n      </div>\n    </div>\n  </body>\n</html>",
                            "subject": "Reset Password",
                            "sender": "mom-support@ariatech.kr",
                            "replyTo": "mom-support@ariatech.kr",
                            "text": "E-mail for reset password of\nAriatech Smart Factory\n\n\nConnect to password reset screen by clicking below link.\nThe expiration period of link is {{expiresIn}} hours.\n\n\nURL for Reset Password : {{url}}/changePassword#{{tenant}}#{{username}}#{{token}}\n\n"
                        }
                    },
                    "shipState":
                    {
                        "completeShip": "complete ship",
                        "partShip": "part ship",
                        "noneShip": "none ship"
                    },
                    "unit":
                    {
                        "productivity": " EA/H"
                    },
                    "rowName":
                    {
                        "subTotal": "subTotal"
                    }
                }
            },
            "kr":
            {
                "translation":
                {
                    "popupMenu":
                    {
                        "Approval":
                        {
                            "request": "승인 요청",
                            "approve": "승인 허가",
                            "reject": "승인 반려",
                            "withdraw": "승인 철회",
                            "cancel": "승인 취소"
                        }
                    },
                    "tabMenu":
                    {
                        "tab":
                        {
                            "day": "금일",
                            "week": "금주",
                            "month": "금월",
                            "custom": "사용자 지정"
                        }
                    },
                    "menu":
                    {
                        "MenuManage": "메뉴 관리",
                        "UserManage": "사용자 관리",
                        "MenuItemManage": "메뉴 항목 관리",
                        "MenuSetManage": "메뉴 세트 관리",
                        "TopMenuManage": "상위 메뉴 관리",
                        "SubMenuManage": "하위 메뉴 관리",
                        "RoleManage": "역할 관리",
                        "RoleSetManage": "역할 세트 관리",
                        "RoleSetDetail": "역할 세트 상세",
                        "System": "시스템",
                        "Profile": "프로파일",
                        "Approval": "승인 관리",
                        "ApprovalManage": "승인 관리",
                        "ApprovalTypeManage": "승인 유형 관리",
                        "ApproverManage": "승인자 관리",
                        "ApprovalPathManage": "승인 경로 관리",
                        "ApprovalHistory": "승인 이력 조회",
                        "ApprovalPathDetailManage": "승인 경로 상세 관리",
                        "InventoryManage": "자재 관리",
                        "MaterialIoManage": "자재 입출고 이력",
                        "MaterialInventoryManage": "자재 재고 관리",
                        "ConsumablesManage": "소모품 재고 관리",
                        "ConsumablesIoManage": "소모품 입출고 이력",
                        "ConsumablesInventoryManage": "소모품 재고 관리",
                        "BaseInfo": "기준 정보",
                        "Item": "품목",
                        "ItemUnit": "품목 단위",
                        "ItemType": "품목 구분",
                        "ItemSpec": "품목 규격",
                        "Company": "거래처 정보",
                        "Business": "영업 관리",
                        "Po": "발주 관리",
                        "So": "수주 관리",
                        "Estimate": "견적 관리",
                        "Shipment": "출하 관리",
                        "ReturnedItem": "반품 관리",
                        "ProduceManage": "생산 관리",
                        "WorkerPerformance": "작업자 실적 관리",
                        "ProductPerformance": "제품 실적 조회",
                        "Customer": "고객사 관리",
                        "ProductManage": "제품 관리",
                        "ProductIoManage": "제품 입출고 이력",
                        "ProductInventoryManage": "제품 재고 관리",
                        "AccessControlManage": "접근 제어 관리",
                        "AccessControlTarget": "접근 제어 대상",
                        "AccessControlRule": "접근 제어 규칙",
                        "Tenant": "Tenant 관리",
                        "DbConf": "DB 설정 관리",
                        "Namespace": "Namespace 관리",
                        "SupportedLanguage": "지원 언어 관리",
                        "Translation": "용어/번역 관리",
                        "TenantLogo": "Tenant 로고 이미지",
                        "Logout": "로그아웃",
                        "TenantOption": "Tenant별 옵션",
                        "MomSysBomBopMgmt": " 공정 정보 관리",
                        "MomSysMasterDataMgmt": "기준 정보 관리",
                        "MomSysProcessInfo": "공정 정보 관리",
                        "MomSysProcess": "프로세스 관리",
                        "MomSysDecisionCode": "불량 코드",
                        "MomSysBomBop": "BOM/BOP 관리",
                        "MomSysCompany": "거래처 정보",
                        "MomSysMaterialType": "품목 구분",
                        "MomSysLineNo": "라인",
                        "MomSysWorkStation": "작업대",
                        "MomSysWareHouse": "창고",
                        "MomSysMaterialInfo": "품목",
                        "MomSysMachineMainType": "설비 유지보수 유형",
                        "MomSysMachine": "설비 관리",
                        "MomSysMachineManage": "설비 관리",
                        "MomSysMachineMaintenance": "설비고장/수리이력",
                        "MomSysNotice": "공지 사항",
                        "MomSysBusinessMgmt": "영업 관리",
                        "MomSysOrderBuyer": "수주 관리",
                        "MomSysOrderPurchase": "발주 관리",
                        "MomSysShipByOrderBuyer": "출하 관리",
                        "MomSysCompanyCollection": "입금/지불 관리",
                        "MomSysSales": "매출 관리",
                        "MomSysCollections": "수금 관리",
                        "MomSysCollectionsReport": "수금/미수금 관리",
                        "MomSysMaterialMgmt": "자재 관리",
                        "MomSysMaterialError": "불량 자재 현황",
                        "MomSysMaterialInput": "입고 현황",
                        "MomSysMaterialShip": "출고 관리",
                        "MomSysMaterialOutSourcing": "아웃 소싱 이력",
                        "MomSysMaterialBackShip": "반품 관리",
                        "MomSysMergeSplit": "병합/분할 이력",
                        "MomSysProductionMgmt": "생산 관리",
                        "MomSysProductionStatus": "생산 현황",
                        "MomSysSoProduction": "수주별 생산",
                        "MomSysMatProduction": "제품별 생산",
                        "MomSysLotTransHistory": "LOT 처리 이력",
                        "MomSysLotTrace": "LOT 처리 추적",
                        "MomSysProductionErrorHistory": "불량 이력",
                        "MomSysProductionCancelHistory": "취소 이력",
                        "MomSysEditMaterialStatus": "LOT 관리",
                        "MomSysMaterialStatus": "자재 현황",
                        "MomSysBopHistory": "BOP 이력",
                        "MomSysBomHistory": "BOM 이력",
                        "MomSysStockTakingMst": "재고 관리",
                        "MomSysPurchase": "매입 관리",
                        "MomSysExpense": "지출 관리",
                        "MomSysProductStatus": "제품 현황",
                        "MomSysCodeGenerate": "코드 생성 규칙",
                        "MomSysMaterialByCompany": "거래처별 품목",
                        "MomSysLotManage": "LOT 관리",
                        "MomSysProcessTime": "공정 시간",
                        "MomSysProductionTime": "생산 시간",
                        "MomSysProcessReport": "작업 실적",
                        "MomSysProductionReport": "생산 실적",
                        "MomSysOwnCompany": "자사 정보",
                        "MomSysAdvancePayment": "선수금 관리",
                        "MomSysProductMgmt": "제품 관리",
                        "MomSysQualityMgmt": "품질 관리",
                        "MomSysPerformanceMgmt": "성과 관리",
                        "MomSysProductInput": "제품 입고",
                        "MomSysProductShip": "제품 출고",
                        "MomSysProductStockMgmt": "제품 재고 관리",
                        "MomSysInputTest": "입고 검사",
                        "MomSysFrequentTest": "수시 검사",
                        "MomSysSalesAnalysis": "매출 분석",
                        "MomSysPurchaseAnalysis": "매입 분석",
                        "MomSysCostAnalysis": "원가 분석",
                        "MomSysPerformanceIndex": "성과 지표",
                        "MomSysOutSourcingMgmt": "외주 관리",
                        "MomSysOutSourcingStatus": "외주가공 현황",
                        "MomSysOutSourcingInput": "외주가공 입고",
                        "MomSysOutSourcingShip": "외주가공 출고",
                        "MomSysOutSourcingOrderPurchase": "외주가공 발주",
                        "MomSysSalesCollections": "매출/수금 관리",
                        "MomSysPurchaseExpense": "매입/지출 관리",
                        "EguMaterialInput": "자재 입고 관리",
                        "TKInsertMgmt": "인서트 관리",
                        "LocalizationManagement": "지역화 관리",
                        "RoleSetTranslation": "역할 세트별 번역",
                        "TenantTranslation": "Tenant별 번역",
                        "TranslationSearch": "용어/번역 검색",
                        "Vendor": "거래처",
                        "TkUglOrderBuyerMst": "수주 관리",
                        "MomSysMaterialStockMgmt": "자재 재고 관리",
                        "ConfigMgmt": "설정 관리",
                        "RoleSetOption": "역할 세트별 옵션",
                        "DefaultOption": "기본 옵션",
                        "InBox": "받은 메시지",
                        "Trash": "삭제 메시지",
                        "MsgSent": "보낸 메시지",
                        "TkUglOrderPurchaseMst": "발주 관리",
                        "MomSysDelivery": "납품 관리",
                        "MomSysShipment": "출하 관리",
                        "EguUglOrderBuyerMst": "수주 관리",
                        "EguUglOrderPurchaseMst": "발주 관리",
                        "MomSysBackShip": "반품 관리",
                        "EguMaterialFakeInput": "가입고 관리",
                        "EGuMaterialStatus": "자재 현황 조회",
                        "EguProductStatus": "제품 현황",
                        "EguMaterialStatus": "자재 현황",
                        "EguProductShip": "제품 출고",
                        "TKShipment": "출하 관리",
                        "MomSysProductInputStatus": "제품 생산 현황",
                        "PopNotice": "공지사항",
                        "PopProductManage": "생산현장 관리",
                        "PopProduce": "제조",
                        "PopLead": "제심",
                        "PopWood": "우드",
                        "PopEtc": "충진/몰딩/포장",
                        "MomSysDefectiveStatus": "불량 이력",
                        "MomSysSelfInspection": "자주 검사\n",
                        "MomSysInputInspection": "입고 검사",
                        "EguPopTemp": "작업지시 임시 등록 페이지",
                        "MomSysInspectionEntity": "검사 정보  관리",
                        "MaterialInputStatus": "자재 입고 현황",
                        "EguMaterialInputStatus": "자재 입고 현황",
                        "MomSysProductionPlan": "생산 계획",
                        "EguItemInfo": "품목 정보",
                        "EguItemByCompany": "거래처별 품목",
                        "EguResearchMgmt": "연구 관리",
                        "EguProductSample": "샘플 생산 관리",
                        "EguSubMaterialSample": "부자재 샘플 관리",
                        "MomSysLotStateChange": "LOT 관리",
                        "EguLotStateChange": "LOT 관리",
                        "MomSysMergeSplitHistory": "병합/분할 이력",
                        "EguMergeSplitHistory": "병합/분할 이력",
                        "DeleteLink": "링크 삭제",
                        "DeleteTask": "작업 삭제",
                        "AssignWorker": "작업자 지정",
                        "AssignMachine": "설비 지정",
                        "SplitTask": "작업 분할",
                        "OrderTask": "작업 지시",
                        "ModifyDuration": "작업 시간 변경",
                        "CancelOrder": "작업 지시 취소",
                        "MomSysWageRate": "임율 관리",
                        "EguOutSourcingMgmt": "외주 관리",
                        "EguOutSourcingShip": "외주 출고 관리",
                        "EguOutSourcingPurchase": "외주 발주 관리",
                        "AddMilestone": "이정표 추가",
                        "ChangeMilestoneName": "이정표 이름 변경",
                        "PerformanceMetric": "생산 효율",
                        "EguOutSourcingFakeInput": "외주 가입고 관리",
                        "OpenAll": "모두 펼치기",
                        "CloseAll": "모두 닫기",
                        "EguOutSourcingInputInspection": "외주 입고 검사 관리",
                        "CapacityManage": "가용 시간 관리",
                        "HolidayManage": "휴일 관리",
                        "AdditionalWorkDayManage": "추가 근무일 관리",
                        "WorkDayManage": "근무 요일 관리",
                        "WorkerDayOfWeekCapacityManage": "요일 별 작업자 근무 시간",
                        "EguStandardInfoSaveManagemet": "기준 정보 저장",
                        "EguItemExcelUpload": "품목 정보 관리",
                        "EguCustomerExcelUpload": "거래처 정보 관리",
                        "EguUserExcelUpload": "직원 정보 관리",
                        "WorkerDateCapacityManage": "일 별 작업자 근무 시간",
                        "MachineDateCapacityManage": "일 별 설비 가용 시간",
                        "DefaultWorkerDayOfWeekCapacityManage": "요일 별 기본 근무 시간",
                        "DefaultMachineCapacityManage": "설비 별 기본 가용 시간",
                        "EguOutSourcingMaterialReturn": "외주 자재 회수",
                        "EguPopOtherWork": "기타 작업",
                        "Dashboard": "대시보드",
                        "EguSystemManage": "시스템 관리",
                        "EguAdministratorAccountManage": "관리자 계정 관리",
                        "EguUserInformationManage": "사용자 정보 관리",
                        "EguUserGroupManage": "사용자 그룹 관리",
                        "EguMachineRental": "설비 입출 관리",
                        "MrpMaterialList": "자재 소요량 관리",
                        "ManagementIndicator": "경영 지표",
                        "EguProductionStatus": "생산 현황",
                        "EguSalesStatus": "영업 현황",
                        "EguProductionMonitoring": "생산 현황 모니터렁",
                        "EguSalesMonitoring": "영업 현황 모니터링",
                        "EguLaborCost": "인건비 관리",
                        "EguCostInfoMgmt": "원가 정보 관리",
                        "EguIngredientRegistration": "제품 성분표 관리",
                    },
                    "tableName":
                    {
                        "MenuItem": "메뉴 항목",
                        "MenuSet": "메뉴 세트",
                        "Role": "역할",
                        "SubMenuItems": "하위 메뉴",
                        "TopMenuItems": "상위 메뉴",
                        "User": "사용자 정보",
                        "WorkTime": "작업 시간",
                        "VwSubMenuItems": "하위 메뉴",
                        "VwTopMenuItems": "상위 메뉴",
                        "VwUser": "사용자",
                        "ApprovalType": "승인 유형",
                        "Approver": "승인자",
                        "ApprovalPath": "승인 경로",
                        "MaterialInput": "자제 입고",
                        "MaterialOutput": "자제 출고",
                        "MaterialInventory": "자제 재고",
                        "Item": "품목",
                        "ItemUnit": "품목 단위",
                        "ItemType": "품목 구분",
                        "ItemSpec": "품목 규격",
                        "Vendor": "거래처",
                        "Po": "발주",
                        "PoDetail": "발주 상세",
                        "So": "수주",
                        "SoDetail": "수주 상세",
                        "EstimateBase": "견적 기본",
                        "EstimateVersion": "견적 버전",
                        "EstimateDetail": "견적 상세",
                        "VwEstimateBase": "견적 기본",
                        "VwEstimateVersion": "견적 버전",
                        "VwEstimateDetail": "견적 상세",
                        "ReturnedItem": "반품",
                        "Shipment": "출하",
                        "ShipmentDetail": "출하 상세",
                        "MomSysUdtLineNo": "라인 번호 정보",
                        "MomSysUdtWorkStation": "작업대 정보",
                        "MomSysUdtWareHouse": "창고 정보",
                        "MomSysUdtBillOfProcess": "BOP 공정",
                        "MomSysUglOrderBuyerMst": "수주",
                        "MomSysUglOrderBuyerDetail": "수주 상세정보",
                        "MomSysUglOrderPurchaseMst": "발주",
                        "MomSysUglOrderPurchaseDetail": "발주 상세정보",
                        "MomSysUglCompanyCollection": "입금/지불 정보",
                        "MomSysSales": "매출 관리",
                        "MomSysSalesDetail": "매출",
                        "MomSysCollections": "매출수금 관리",
                        "MomSysCollectionsDetail": "수금",
                        "MomSysCollectionsReport": "수금/미수금",
                        "MomSysUdtBillOfMaterial": "BOM 자재",
                        "MomSysCodeGenerate": "코드 생성 규칙",
                        "MomSysZvwMaterial": "자재 목록",
                        "MomSysUglStockTakingMst": "재고 조사",
                        "MomSysPurchaseDetail": "매입",
                        "MomSysExpenseDetail": "지출",
                        "TenantTranslation": "Tenant별 번역",
                        "RoleSetTranslation": "역할 세트별 번역",
                        "TenantOption": "Tenant별 옵션",
                        "RoleSetOption": "역할 세트별 옵션",
                        "DefaultOption": "기본 옵션",
                        "MomSysPurchase": "매입",
                        "MomSysShipment": "출하",
                        "MomSysShipmentDetail": "출하 상세 정보",
                        "TkUglOrderBuyerMst": "수주",
                        "TkUglOrderBuyerDetail": "수주 상세",
                        "TkUglOrderPurchaseDetail": "발주 상세",
                        "MomSysDelivery": "납품",
                        "MomSysDeliveryDetail": "납품 상세 정보",
                        "OutputMaterialList": "반납 자재 목록",
                        "MomSysUglOrderBuyerDetailDialog": "품목 목록",
                        "MomSysUglOrderPurchaseDetailDialog": "품목",
                        "MomSysWageRate": "임율 관리 정보",
                        "EguPopNotice": "공지사항",
                        "EguOutSourcingPurchase": "외주 발주",
                        "EguOutSourcingPurchaseDetail": "외주 발주 상세",
                        "Holiday": "휴일",
                        "EguUglOrderBuyerMst": "수주 관리",
                        "EguUglOrderBuyerDetail": "수주 상세 관리",
                        "EguUglOrderPurchaseMst": "발주 관리",
                        "EguUglOrderPurchaseDetail": "발주 상세 관리",
                        "MomSysBackShip": "반품 관리",
                        "MomSysBackShipDetail": "반품 상세 관리",
                        "EguMstMaterial": "품목 정보",
                        "MomSysUdtCompany": "거래처 정보",
                        "MomSysUdtDecisionCode": "불량 코드 정보",
                        "MomSysUdtMaterialType": "품목 구분 정보",
                        "MomSysMaterialByCompany": "거래처별 품목 정보",
                        "MomSysSalesCollections": "매출 수금 관리",
                        "MomSysPurchaseExpense": "매입 지출 관리",
                        "AdvancePayment": "선수금 관리",
                        "EguPopOtherWork": "기타 작업",
                        "EguPopOtherWorkDetail": "기타 작업 상세",
                        "EguMachineRental": "장비",
                        "MomSysMstMaterial": "표준 품목",
                        "EguCostInfoMgmt": "원가 정보",
                        "EguLaborCost": "인건비",
                        "EguProductSample": "제품 샘플 이력",
                        "EguLabDataSheet": "실험 정보",
                        "EguLabDataSheetDetail": "실험 정보 상세",
                        "EguSubMaterialSample": "부자재 샘플이력",
                        "EguIngredientRegistrationCaption": "확정 샘플 성분표 이력",
                        "EguIngredientRegistration": "제품 성분표",
                        "EguVwIngredientList": "성분 리스트",
                    },
                    "columnName":
                    {
                        "menuItemId": "메뉴 항목",
                        "menuItemName": "메뉴 항목 명",
                        "menuPath": "메뉴 경로",
                        "topMenuPath": "상위 메뉴 경로",
                        "menuType": "메뉴 유형",
                        "iconType": "아이콘 유형",
                        "iconName": "아이콘 명",
                        "description": "비고",
                        "help": "도움말",
                        "menuSetId": "메뉴 세트",
                        "menuSetName": "메뉴 세트 명",
                        "roleId": "역할",
                        "roleName": "역할",
                        "roleSetId": "역할 세트",
                        "roleSetName": "역할 세트 명",
                        "alias": "별칭",
                        "topMenuItemId": "상위 메뉴",
                        "topMenuItemName": "상위 메뉴",
                        "subMenuItemId": "하위 메뉴",
                        "subMenuItemName": "하위 메뉴",
                        "order": "순서",
                        "username": "사용자 ID",
                        "password": "비밀번호",
                        "passwordCheck": "비밀번호 확인",
                        "fullName": "표시이름",
                        "firstName": "이름",
                        "lastName": "성",
                        "workId": "작업 ID",
                        "startTime": "시작 일시",
                        "endTime": "종료 일시",
                        "createdAt": "등록일시",
                        "approvalTypeId": "승인 유형 ID",
                        "approvalTypeName": "승인 유형",
                        "tableName": "테이블명",
                        "ordered": "승인 순서 사용",
                        "approver": "승인자",
                        "approverFirstName": "승인자 이름",
                        "approverLastName": "승인자 성",
                        "approverFullName": "승인자 명",
                        "approvalPathId": "승인 경로 ID",
                        "approvalPathName": "승인 경로명",
                        "owner": "소유자",
                        "materialInventoryId": "재고 번호",
                        "materialName": "품목 명",
                        "materialInventoryAmount": "재고 수량",
                        "materialInputId": "입고 번호",
                        "materialCode": "품목 코드",
                        "materialInOutputAmount": "입출고 수량",
                        "materialInOutputDate": "입출고 일자",
                        "consumablesCode": "소모품 코드",
                        "consumablesName": "소모품 명",
                        "consumablesInOutputAmount": "입출고 수량",
                        "consumablesInOutputDate": "입출고 일자",
                        "consumablesInventoryAmount": "재고 수량",
                        "reasonAdjustment": "조정 사유",
                        "ioState": "입출고상태",
                        "itemUnitId": "품목 단위 코드",
                        "itemUnitName": "품목 단위",
                        "itemTypeId": "품목 구분 코드",
                        "itemTypeName": "품목 구분",
                        "itemSpecId": "품목 규격 코드",
                        "itemSpecName": "품목 규격",
                        "itemName": "품목 명",
                        "itemCode": "품목 코드",
                        "regUser": "등록 사용자 ID",
                        "itemId": "품목 명",
                        "workerName": "작업자 명",
                        "performanceAmount": "작업 수량",
                        "performanceDate": "작업 일자",
                        "performanceWorkingTime": "작업 시간 (시간)",
                        "customerId": "고객사 명",
                        "customerCompanyName": "고객사 명",
                        "customerCompanyTel": "고객사 전화번호",
                        "customerCeoName": "대표자",
                        "customerManagerName": "담당자",
                        "customerManagerTel": "담당자 전화번호",
                        "vendorId": "거래처 명",
                        "vendorName": "거래처 명",
                        "telephone": "전화번호",
                        "headPerson": "대표자",
                        "majorAddress": "주소",
                        "minorAddress": "상세주소",
                        "poId": "발주 명",
                        "poName": "발주 명",
                        "poNumber": "발주 번호",
                        "deliveryDate": "납기 일자",
                        "cost": "금액 (원)",
                        "poDocument": "발주서",
                        "registDate": "등록 일자",
                        "register": "등록자",
                        "registerName": "등록자명",
                        "quantity": "수량",
                        "basePoId": "복사할 발주 번호",
                        "poDetailId": "발주 상세 명",
                        "soId": "수주 명",
                        "soName": "수주 명",
                        "soNumber": "수주 번호",
                        "soDocument": "견적서",
                        "productOutputCheck": "제품 출하 선택",
                        "estimateBaseId": "견적 Base ID",
                        "estimateId": "견적 ID",
                        "estimateItemId": "견적 아이템 ID",
                        "outerEstimateNumber": "외부 견적 번호",
                        "estimateNumber": "견적 번호",
                        "versionMajor": "Major 버전",
                        "versionMinor": "Minor 버전",
                        "baseVersionMajor": "기본 Major 버전",
                        "estimateName": "견적 이름",
                        "creater": "등록자",
                        "updatedAt": "수정 시간",
                        "lastWriter": "수정자",
                        "lastWriterName": "수정자명",
                        "count": "갯수",
                        "unitPrice": "단가",
                        "totalPrice": "총 가격",
                        "estimatePrice": "견적 가격",
                        "shipmentState": "출하 상태",
                        "shipmentDate": "출하 일자",
                        "shipmentManagerName": "출하 담당자",
                        "shipmentDetailState": "출하 상태",
                        "shipmentDetailBeforeQuantity": "기출하량",
                        "shipmentDetailQuantity": "출하량",
                        "soQuantity": "수주 수량",
                        "productCode": "제품 코드",
                        "productName": "제품 명",
                        "productInOutputAmount": "입출고 수량",
                        "productInventoryAmount": "재고 수량",
                        "productInOutputDate": "입출고 일자",
                        "returnedItem": "반품",
                        "returnedItemId": "반품 명",
                        "returnedItemCount": "반품량",
                        "codeGenerateId": "코드 생성 규칙 ID",
                        "codeGenerateName": "코드 생성 규칙 명",
                        "prefix": "접두사",
                        "suffix": "접미사",
                        "addDate": "날짜 추가",
                        "delimiter": "구분자",
                        "numberOfDigits": "자리수",
                        "seq": "현재 일련번호",
                        "eventId": "이벤트 ID",
                        "eventTime": "이벤트 시간",
                        "event": "이벤트명",
                        "approvalStateId": "승인 상태",
                        "approvalState": "승인 상태",
                        "approvalDetailId": "승인 상세 ID",
                        "requestItem": "승인 요청 항목 상세",
                        "requestMsg": "승인 요청 항목",
                        "requester": "승인 요청자",
                        "requesterFullName": "승인 요청자 명",
                        "requesterFirstName": "승인 요청자 이름",
                        "requesterLastName": "승인 요청자 성",
                        "requestTime": "승인 요청 시간",
                        "approveTime": "승인 시간",
                        "rejectTime": "반려 시간",
                        "withdrawTime": "철회 시간",
                        "cancelTime": "취소 시간",
                        "approvalPathOwner": "승인 경로 소유자",
                        "approvalPathOwnerFirstName": "승인 경로 소유자 이름",
                        "approvalPathOwnerLastName": "승인 경로 소유자 성",
                        "approvalPathOwnerFullName": "승인 경로 소유자 명",
                        "approverApproveTime": "승인자 승인 시간",
                        "approverRejectTime": "승인자 반려 시간",
                        "approverCancelTime": "승인자 취소 시간",
                        "approvalDetailState": "승인 상세 상태",
                        "accessControlTargetId": "접근 대상",
                        "accessControlTargetName": "접근 대상",
                        "defaultAccess": "기본 접근 규칙",
                        "defaultUiConf": "기본 UI 설정",
                        "accessRule": "접근 규칙",
                        "action": "접근 방법",
                        "tenantId": "Tenant",
                        "tenantName": "Tenant명",
                        "companyName": "거래처",
                        "optionPath": "경로",
                        "optionName": "옵션 명",
                        "optionValue": "옵션 값",
                        "email": "이메일",
                        "photo": "사진",
                        "phone": "전화번호",
                        "status": "상태",
                        "namespace": "네임스페이스",
                        "namespaceId": "네임스페이스",
                        "dbConfId": "DB 설정명",
                        "dbConfName": "DB 설정명",
                        "dbConf": "DB 설정",
                        "language": "지원 언어",
                        "languageId": "지원 언어",
                        "resources": "용어/번역 자원",
                        "hideUi": "UI 감춤",
                        "size": "크기",
                        "image": "이미지",
                        "processCode": "공정 코드",
                        "processName": "공정 명",
                        "workStationCode": "작업대 코드",
                        "workStationName": "작업대 명",
                        "lineNo": "라인 번호",
                        "companyCode": "거래처 코드",
                        "companyAlias": "회사 별칭",
                        "fax": "팩스",
                        "bankCode": "은행 명",
                        "bankAccount": "계좌 번호",
                        "typeOwn": "본인 소유",
                        "typeBuyer": "고객사",
                        "typeSupplyer": "구매 업체",
                        "typeOutSourcing": "외주 업체",
                        "materialTypeCode": "품목 구분 코드",
                        "materialTypeName": "품목 구분",
                        "wareHouseCode": "창고 코드",
                        "wareHouseName": "창고 명",
                        "wareHouseType": "창고 유형",
                        "materialCodeVer": "품목 버전",
                        "priceInter": "내부 가격",
                        "priceExter": "외부가격",
                        "lotNo": "Lot 번호",
                        "workState": "상태",
                        "qtyQueued": "입고 수량",
                        "qtyCreate": "생성 수량",
                        "qtyCurrent": "현재 수량",
                        "inputInspectNo": "InSpect No",
                        "transLogId": "Trans Log ID",
                        "orderPurchaseId": "발주 번호",
                        "orderPurchaseDate": "발주 일자",
                        "companyBarCode": "회사 바코드",
                        "companyRegNo": "사업자등록번호",
                        "decisionCode": "의사결정 코드",
                        "decisionType": "의사결정 유형",
                        "decisionName": "의사결정 명",
                        "img": "사진",
                        "machineMainType": "설비 유지보수 유형",
                        "machineMainDesc": "유지보수 내용",
                        "timeStart": "유지 보수 시작 시간",
                        "timeEnd": "유지 보수 종료 시간",
                        "machineCode": "설비코드",
                        "machineName": "설비명",
                        "available": "상태",
                        "model": "모델",
                        "vendor": "제조사",
                        "serialNumber": "일련번호",
                        "noticeNo": "공지 번호",
                        "visible": "VISIBLE",
                        "notice": "NOTICE",
                        "orderBuyerId": "수주 번호",
                        "orderBuyerName": "수주명",
                        "orderBuyerState": "수주 상태",
                        "approved": "승인 완료",
                        "approvedShip": "Approved Ship",
                        "delivered": "출고 완료",
                        "paymentMethod": "대금 지급 방법",
                        "plCode": "PL Code",
                        "downPayment": "계약금",
                        "middlePayment": "중도금",
                        "balancePayment": "잔금",
                        "orderBuyerDate": "수주 일자",
                        "dueDate": "납기요청일자",
                        "qty": "수량",
                        "qtyShip": "출고 수량",
                        "qtyInput": "입고 수량",
                        "barcodeShip": "출고 바코드 LOT 번호",
                        "shipLotNo": "출하 Lot 번호",
                        "outSourcing": "위탁",
                        "qtyTrans": "처리 수량",
                        "qtyNext": "다음 수량",
                        "shipQty": "출고 수량",
                        "backShipQty": "반품 수량",
                        "createdBy": "등록자",
                        "updatedBy": "수정자",
                        "transaction": "트랜잭션",
                        "createdByName": "등록자",
                        "createdByFullName": "등록자",
                        "createdByFirstName": "등록자 이름",
                        "createdByLastName": "등록자 성",
                        "updatedByFullName": "수정자명",
                        "updatedByFirstName": "수정자 이름",
                        "updatedByLastName": "수정자 성",
                        "bopMaterialCode": "생산 품목 코드",
                        "bopMaterialCodeVer": "생산 품목버전",
                        "bopMaterialName": "생산 품목명",
                        "bopMaterialQty": "생산 품목 자재 수량",
                        "bomMaterialCode": "생산 자재 코드",
                        "bomMaterialCodeVer": "생산 자재 버전",
                        "bomMaterialName": "생산 자재 명",
                        "inOutType": "생산/소비 구분",
                        "inOutMaterialCode": "자재 코드",
                        "inOutMaterialVer": "자재 버전",
                        "inOutMaterialName": "자재 명",
                        "componentCode": "자재 코드",
                        "componentCodeVer": "자재 버전",
                        "componentName": "자재 명",
                        "componentQty": "소요 수량",
                        "processOrder": "공정 순서",
                        "lotNoUnion": "병합 Lot",
                        "qtyUnion": "병합 수량",
                        "lotNoVictim": "피병합 Lot",
                        "qtyVictim": "피병합 수량",
                        "lotNoOrigin": "피분할 Lot",
                        "qtyOrigin": "피분할 수량",
                        "lotNoCreate": "생성 Lot",
                        "failureCode": "불량 코드",
                        "failLotNo": "불량 Lot 번호",
                        "orderOutTransId": "Order Out Trans Id",
                        "sideDebit": "Side Debit",
                        "sideDebitAdv": "Side Debit Adv",
                        "sideCredit": "Side Credit",
                        "sideCreditAdv": "Side Credit Adv",
                        "infoType": "유형 선택",
                        "str_f": "현재 수주번호",
                        "str_t": "변경할 수주번호",
                        "inWareHouseCode": "입고 창고",
                        "outWareHouseCode": "출고 창고",
                        "memo": "설명",
                        "qtyOrdered": "수주 수량",
                        "qtyProcessing": "재공 수량",
                        "qtyPurchased": "구매 수량",
                        "qtyProduced": "생산 수량",
                        "qtyInStock": "재고 수량",
                        "qtyShipped": "출하 수량",
                        "qtyBackShipped": "반품 수량",
                        "qtyRequired": "필요 수량",
                        "qtyToProduce": "생산 필요 수량",
                        "qtySpare": "여유 수량",
                        "wareHouseTo": "창고 선택",
                        "wareHouseFrom": "현재 창고",
                        "stockTakingId": "실사 번호",
                        "criTransLogId": "CriTransLogId",
                        "hold": "작업보류",
                        "applyDate": "적용 일자",
                        "applyUser": "적용자",
                        "salesId": "매출",
                        "salesDate": "매출 일자",
                        "taxBill": "세금 계산서",
                        "orderBuyerCost": "수주가",
                        "salesCost": "매출액",
                        "salesBalance": "매출 잔액",
                        "orderCost": "발주 금액",
                        "purchaseCost": "매입 금액",
                        "purchaseBalance": "매입 잔액",
                        "collectionsId": "수금",
                        "collectionsDate": "수금 일자",
                        "receipt": "영수증",
                        "collectionsCost": "수금액",
                        "collectionsBalance": "수금 잔액",
                        "advancePayment": "선수금",
                        "purchaseDate": "매입 일자",
                        "salesCollectionsBalance": "미수금",
                        "orderBuyerCollectionsComplete": "수주가 수금 완료",
                        "salesCollectionsComplete": "매출액 수금 완료",
                        "expenseCost": "지출 금액",
                        "expenseBalance": "지출 잔액",
                        "expenseDate": "지출 일자",
                        "bonus": "초과 생산",
                        "loss": "손실",
                        "prevProcessCode": "이전 공정 코드",
                        "prevProcessName": "이전 공정 명",
                        "nextProcessCode": "다음 공정 코드",
                        "nextProcessName": "다음 공정 명",
                        "closed": "종료",
                        "outsourcing": "아웃소싱",
                        "productLotNo": "Lot 번호",
                        "consumedLotNo": "Lot 번호",
                        "consumeTime": "투입 시간",
                        "qtySafetyStock": "안전 재고 수량",
                        "qtySpareStock": "비축 재고 수량",
                        "generatedCode": "생성된 코드",
                        "inputString": "입력 문자열",
                        "codeSeq": "코드 일련번호",
                        "initCriteria": "초기화 기준",
                        "abbreviations": "약어",
                        "bopMaterialAbbreviations": "생산 품목 자재 약어",
                        "inOutMaterialAbbreviations": "약어",
                        "mainCustomer": "주/부 거래처",
                        "category": "유형",
                        "minPurchaseQty": "최소 구매 수량",
                        "worker": "작업자",
                        "processTime": "공정 시간",
                        "queueTime": "소요 시간",
                        "holdTime": "보류 시간",
                        "processCount": "공정 수",
                        "productionTime": "생산 시간",
                        "avgProcessPerHour": "시간 당 평균 공정 수행량",
                        "minProcessPerHour": "시간 당 최소 공정 수행량",
                        "maxProcessPerHour": "시간 당 최대 공정 수행량",
                        "stdProcessPerHour": "시간 당 공정 수행량 표준 편차",
                        "avgProcessTime": "평균 공정 수행 시간",
                        "minProcessTime": "최소 공정 수행 시간",
                        "maxProcessTime": "최대 공정 수행 시간",
                        "stdProcessTime": "공정 수행 시간 표준 편차",
                        "avgProductionPerHour": "시간 당 평균 생산량",
                        "minProductionPerHour": "시간 당 최소 생산량",
                        "maxProductionPerHour": "시간 당 최대 생산량",
                        "stdProductionPerHour": "시간 당 생산량 표준 편차",
                        "avgProductionTime": "평균 생산 소요 시간",
                        "minProductionTime": "최소 생산 소요 시간",
                        "maxProductionTime": "최대 생산 소요 시간",
                        "stdProductionTime": "생산 소요 시간 표준 편차",
                        "avgQueueTime": "평균 소요 시간",
                        "minQueueTime": "최소 소요 시간",
                        "maxQueueTime": "최대 소요 시간",
                        "stdQueueTime": "소요 시간 표준 편차",
                        "avgHoldTime": "평균 보류 시간",
                        "minHoldTime": "최소 보류 시간",
                        "maxHoldTime": "최대 보류 시간",
                        "stdHoldTime": "보류 시간 표준 편차",
                        "sumProcessQty": "총 공정 작업 수",
                        "sumProductionQty": "총 생산 수",
                        "productionCategory": "생산구분",
                        "orderBuyerDoc": "고객 발주서",
                        "backShipAvailableQty": "반품 가능 수량",
                        "autoMerge": "자동 병합",
                        "rental": "대여 여부",
                        "translation": "번역 데이터",
                        "translationPath": "번역 경로",
                        "translationValue": "번역 값",
                        "translationPathId": "번역 경로",
                        "proudctInventoryAmount": "재고 수량",
                        "workOrder": "작업번호",
                        "designer": "설계자",
                        "orderer": "주문자",
                        "buyer": "구매자",
                        "ceoPhone": "대표자 전화번호",
                        "ceoName": "대표자 이름",
                        "salesPerson": "영업사원 명",
                        "salesPersonPhone": "영업사원 전화번호",
                        "salesPersonEmail": "영업사원 이메일",
                        "joinCompanyDate": "입사일",
                        "lastCompanyDate": "퇴사일",
                        "address": "주소",
                        "belong": "소속",
                        "jobGrade": "직급",
                        "dutyResponsibility": "직책",
                        "tenureStatus": "재직 여부",
                        "applyByFullName": "적용자",
                        "qtyConsumed": "소비 수량",
                        "sendTime": "보낸 시간",
                        "sender": "보낸 사람",
                        "senderName": "보낸 사람 이름",
                        "message": "메시지",
                        "readTime": "읽은 시간",
                        "msgRead": "읽음",
                        "deleteTime": "삭제 시간",
                        "receiver": "받는 사람",
                        "receiverName": "받는 사람 이름",
                        "qtyStock": "시스템 재고수량",
                        "qtyDueDiligence": "실사 수량",
                        "standard": "표준품",
                        "shipmentOrderDate": "출하지시일자",
                        "shipmentNo": "출하 지시 번호",
                        "nsMaterialCode": "품목 코드",
                        "nsMaterialCodeVer": "품목 버전",
                        "shipmentQty": "출하지시수량",
                        "shipmentId": "출하 식별자",
                        "shipmentDetailId": "출하 상세 정보 식별자",
                        "orderPurchaseDoc": "발주서",
                        "shipId": "출고 번호",
                        "shipState": "출고 상태",
                        "orderBuyerDetailId": "수주 상세 번호",
                        "orderPurchaseDetailId": "발주 상세 번호",
                        "deliveryDoc": "납품확인서",
                        "deliveryState": "납품상태",
                        "shipDate": "출고일자",
                        "stockTakingDoc": "재고 엑셀 문서",
                        "partNo": "Part No",
                        "partSeq": "Part Seq",
                        "opCode": "OP Code",
                        "cutCode": "Cut Code",
                        "opDeg": "OP Deg",
                        "partSpec": "Spec",
                        "partSize": "Size",
                        "partMaterial": "Material",
                        "materialUnitCost": "소재 단가",
                        "kg": "KG",
                        "materialCost": "소재비",
                        "chamferingCost": "면삭비",
                        "grindingCost": "연마비",
                        "processUnitCost": "가공단가",
                        "customOrderPurchaseId": "고객발주번호",
                        "backShipDate": "반품일자",
                        "qtyBackShip": "반품 수량",
                        "orderPurchaseName": "발주명",
                        "submitDoc": "제출 서류",
                        "fakeInputDate": "가입고 일시",
                        "tradingDoc": "거래 명세표",
                        "fakeInputUser": "가입고 등록자",
                        "backShipReason": "반품사유",
                        "deliveryId": "납기 번호",
                        "shipmentLot": "출하 LOT 번호",
                        "stockTakingName": "실사 명",
                        "stockTakingDate": "실사 일자",
                        "deliveryRequiredQty": "납품 요구 수량",
                        "inputState": "입고 완료",
                        "produceStatus": "작업 상태",
                        "takeOverStatus": "인수 상태",
                        "plannedWorker": "작업자",
                        "realWorker": "최종 작업자",
                        "inputInspectionId": "검사 번호",
                        "defectiveCode": "불량 코드",
                        "inspectionDate": "검사 일시",
                        "qcResult": "검사 결과",
                        "inputDate": "입고 일자",
                        "inspectorName": "검사자",
                        "inspector": "검사자",
                        "qcManager": "QC 담당자",
                        "requiredQty": "필요 수량",
                        "inputQty": "인수 수량",
                        "inspectionItemId": "검사 항목 ID",
                        "inspectionItemName": "검사 항목 명",
                        "inspectionCriteriaId": "검사 기준 ID",
                        "inspectionCriteriaName": "검사 기준 명",
                        "inspectionGroupId": "검사 그룹 ID",
                        "inspectionGroupName": "검사 그룹 명",
                        "contents": "내용",
                        "selfInspectionId": "검사 번호",
                        "requestDate": "요청 일자",
                        "targetLotNo": "Lot 번호",
                        "targetLotCreatedAt": " Lot 생산 일자",
                        "productionQty": "생산량",
                        "inspectionRequesterName": "검사 의뢰자",
                        "samplingDate": "시료 채취 일자",
                        "samplingLoc": "시료 채취 장소",
                        "samplingType": "시료 채취 방법",
                        "samplingQty": "시료 수량",
                        "productionPart": " 생산 부서명",
                        "inspectionRequestDate": "검사 의뢰 일자",
                        "inspectionRequester": "검사 의뢰자",
                        "determinant": "검사 판정",
                        "workTime": "실제 작업 시간",
                        "consumeQty": "투입 수량",
                        "failOccurDate": "불량 발생 일시",
                        "qcManagerName": "QC 담당자 이름",
                        "failQty": "불량 수량",
                        "plannedStartTime": "계획 시작 일자",
                        "plannedQty": "지시 수량",
                        "resultQty": "생산 수량",
                        "resultLotNo": "작업 Lot No",
                        "duration": "작업 시간",
                        "outputQty": "반납 수량",
                        "srcLotNo": "LotNo",
                        "otherFeatures": "공정이상조치사항 및 특이사항",
                        "qtyFakeInput": "가입고 수량",
                        "unitCost": "단가",
                        "defectiveCodeName": "불량명",
                        "failureCodeName": "불량명",
                        "preTime": "작업 준비 시간 (분)",
                        "postTime": "작업 후 시간 (분)",
                        "deliveryPlace": "납품 장소",
                        "materialFakeInputId": "가입고 ID",
                        "title": "제목",
                        "sellUnitCost": "단가",
                        "materialEnglishName": "품목 영문명",
                        "division": "년월",
                        "totalSales": "매출",
                        "taskName": "작업명",
                        "taskType": "작업 유형",
                        "produce": "생산 여부",
                        "bomMaterialQty": "생산 수량",
                        "inciName": "INCI 명",
                        "casNo": "CAS 번호",
                        "totalPurchase": "매입",
                        "deliveryCompanyName": "납품 회사",
                        "deliveryCompanyCode": "납품 회사",
                        "customerDescription": "비고",
                        "wageRate": "금액 (원)/시간",
                        "personnelExpenses": "인건비",
                        "depreciation": "감각상각비",
                        "rentalFee": "임대료",
                        "electricityBill": "전기료",
                        "waterSewageFee": "상하수도 요금",
                        "communicationCost": "통신비",
                        "vehicleMaintenance": "차량유지비",
                        "fuelCost": "유류대",
                        "otherCosts": "기타 비용",
                        "taskCount": "작업 수",
                        "splitMethod": "분할 방법",
                        "splitSingle": "단일 작업 분할",
                        "outSourcingShipId": "외주 출고 번호",
                        "outSourcingShipDate": "출고 일자",
                        "outSourcingShipManager": "출고 담당자",
                        "durationInHours": "작업 시간",
                        "taskStartDate": "작업 시작일",
                        "workers": "작업자",
                        "machines": "설비",
                        "startProcessName": "시작 공정",
                        "endProcessName": "끝 공정",
                        "outSourcingId": "외주 발주 번호",
                        "outSourcingName": "외주 발주명",
                        "outSourcingDate": "외주 발주 일시",
                        "outSourcingStatus": "외주 진행 상태",
                        "realDeliveryDate": "실제 납기 일자",
                        "outSourcingCost": "외주 발주 금액",
                        "outSourcingQty": "발주 수량",
                        "orderBuyerMaterialName": "수주 품명",
                        "outSourcingMaterialName": "외주 발주 품명",
                        "startProcessCode": "시작 공정",
                        "endProcessCode": "끝 공정",
                        "progress": "진행률",
                        "taskStartTime": "작업 시작 시간",
                        "taskEndTime": "작업 종료 시간",
                        "plannedDuration": "계획 작업 시간",
                        "milestoneDate": "날짜",
                        "milestoneName": "이정표명",
                        "processPerHour": "시간 당 공정 수행량",
                        "outSourcingFakeInputId": "외주 가입고 번호",
                        "updateBy": "수정 기준 값",
                        "fakeInputQty": "가입고 수량",
                        "extLotNo": "내부 관리 LOT 번호",
                        "recurring": "반복",
                        "date": "날짜",
                        "outSourcingInputInspectionId": "외주 발주 검사 번호",
                        "shipWareHouseCode": "출고 창고 코드 번호",
                        "orderTime": "작업 지시 시간",
                        "dayOfWeek": "요일",
                        "capacity": "근무(가용) 시간",
                        "materialRecovery": "자재 회수 여부",
                        "excelFile": "엑셀 파일",
                        "outSourcingDetailId": "외주 발주 상세 번호",
                        "returnedQty": "회수 수량",
                        "returnedDate": "회수 일자",
                        "mainCode": "코드",
                        "outSourcingShipState": "외주 출고 상태",
                        "outSourcingShipQty": "출고 수량",
                        "consumeLotNo": "출고 자재 LOT 번호",
                        "startDate": "사용 시작 일시",
                        "endDate": "사용 종료 일시",
                        "itemLocationCode": "품목 위치 코드",
                        "backShipId": "반품 번호",
                        "workerFullName": "작업자 명",
                        "otherWorkDate": "작업일",
                        "otherWorkId": "기타 작업 번호",
                        "startDateTime": "작업 시작 일시",
                        "endDateTime": "작업 종료 일시",
                        "workDetail": "작업 내용",
                        "otherWorkDetailId": "기타 작업 상세 번호",
                        "purchaseDetailId": "매입",
                        "expenseId": "지출",
                        "outSourcingMaterialQty": "사급 자재 수량",
                        "materialCapacity": "용량",
                        "failRatio": "불량률",
                        "soQty": "수주 수량",
                        "process": "공정",
                        "process1": "제심",
                        "process2": "우드",
                        "process3": "몰딩",
                        "process4": "포장",
                        "productFamily": "제품 군",
                        "controlNumber": "관리 번호",
                        "outQuantity": "출고 수량",
                        "inQuantity": "입고 수량",
                        "remainingQuantity": "나머지 수량",
                        "reason": "사유",
                        "customerName": "거래처 명",
                        "customerManager": "거래처 담당자",
                        "outDate": "출고 일자",
                        "inDate": "입고 일자",
                        "returnDescription": "반납 내용",
                        "pay": "급여",
                        "bonusPay": "상여금",
                        "otherPay": "잡금",
                        "employeeBenefits": "복리후생비",
                        "travelExpenses": "여비교통비",
                        "businessExpenese": "접대비",
                        "utilitiesExpenses": "수도광열비",
                        "publicTax": "세금과 공과",
                        "premium": "보험료",
                        "vehicleMaintenanceCost": "차량 유지비",
                        "researchGrant": "연구비",
                        "transportCost": "운반비",
                        "packagingCost": "포장비",
                        "officeSuppliesCost": "사무용품비",
                        "suppliesCost": "소모품비",
                        "fees": "지급 수수료",
                        "outsourcingFee": "외주비",
                        "depreciationExpense": "감가상각비",
                        "storageFee": "보관료",
                        "exportCost": "수출제비용",
                        "otherCost": "잡비",
                        "directMaterialCost": "직접 재료비",
                        "directLaborCost": "직접 노무비",
                        "baseYearMonth": "기준년월",
                        "state": "상태",
                        "basePurchasingTime": "기본 구매 소요 시간 (일)",
                        "adhocPurchasingTimeQty": "추가 구매 소요 시간 단위량",
                        "adhocPurchasingTime": "수량별 추가 구매 소요 시간 (일)",
                        "baseProducingTime": "기본 생산 소요 시간 (초)",
                        "adhocProducingTime": "수량별 추가 생산 소요 시간 (초)",
                        "adhocProducingTimeQty": "추가 생산 소요 시간 단위량",
                        "mainSupplyCompanyName": "주) 공급업체 명",
                        "subSupplyCompanyName": "부) 공급업체 명",
                        "firstLackingDate": "최초 부족 일자",
                        "firstLackingQty": "최초 부족 수량",
                        "totalLackingQty": "총 부족 수량",
                        "percent": "비율 (%)",
                        "cumulativeSales": "누적 매출액",
                        "sales": "매출액",
                        "productMaterialCode": "수주 제품 품목 코드",
                        "productMaterialCodeVer": "수주 제품 품목 버전",
                        "materialPlanMaterialCode": "생산 계획 품목 코드",
                        "materialPlanMaterialCodeVer": "\n생산 계획 품목 버전",
                        "mrpPlannedQty": "생산 계획 수량",
                        "etcCost": "간접비 및 판관비",
                        "totalCost": "총 원가",
                        "profit": "이익",
                        "eventProcessCode": "이벤트 공정 코드",
                        "eventMaterialCode": "이벤트 품목 코드",
                        "eventMaterialCodeVer": "이벤트 품목 버전",
                        "eventQty": "이벤트 수량",
                        "netQty": "Net 수량",
                        "eventType": "이벤트 종류",
                        "purchase": "매입액",
                        "cumulativePurchase": "누적 매입액",
                        "deliveryQty": "납품 수량",
                        "repairFee": "수선비",
                        "educationCosts": "교육 훈련비",
                        "bookPrintingFee": "도서 인쇄비",
                        "meetingFee": "회의비",
                        "advertisingCost": "광고 선전비",
                        "salesCommission": "판매 수수료",
                        "currentDevelopmentCost": "경상 개발비",
                        "interestExpense": "이자 비용",
                        "saleDiscount": "매출 할인",
                        "otherLossCosts": "잡손실",
                        "donation": "기부금",
                        "total": "합계",
                        "severancePay": "퇴직금",
                        "indirectLaborCost": "간접 노무비",
                        "salesManagementCost": "판관비",
                        "employType": "직원 유형",
                        "directLaborCostPer": "직접 노무비 비율 ( % )",
                        "indirectLaborCostPer": "간접 노무비 비율 ( % )",
                        "researchGrantPer": "연구비 비율 ( % )",
                        "salesManagementCostPer": "판관비 비율 ( % )",
                        "costInfoId": "원가 정보 식별자",
                        "laborCostId": "인건비 식별자",
                        "viewMinPurchaseQty": "최소 구매 수량",
                        "viewCost": "금액 (원)",
                        "viewMainCompanyName": "주 거래처",
                        "productSampleNo": "일련번호",
                        "receiptNo": "접수번호",
                        "receiptDate": "접수일",
                        "client": "의뢰인",
                        "requests": "요청사항",
                        "sampling": "샘플링",
                        "samplingDescription": "샘플 방법",
                        "manager": "담당자",
                        "sampleState": "샘플완료여부",
                        "sendDate": "발송일",
                        "reviewCount": "품평횟수",
                        "labNo": "labNo",
                        "type": "type",
                        "reviewNo": "품평번호",
                        "longLabNo": "LabNo",
                        "sendDescription": "발송내용",
                        "passJudgment": "적부판정",
                        "judgmentDate": "판정일",
                        "reviewContent": "품평내용",
                        "confirmProductName": "확정제품명",
                        "experimentDate": "실험일",
                        "experimentManager": "실험자",
                        "experimentState": "실험결과",
                        "experimentNo": "실험번호",
                        "experimentQty": "실헝량(g)",
                        "experimentContent": "실험내용",
                        "reviewState": "품평 결과",
                        "no": "No",
                        "code": "Code",
                        "item": "Item",
                        "standardAmount": "기준함량",
                        "expectedAmount": "예정함량",
                        "roundOneAmount": "1차투입량",
                        "roundTwoAmount": "2차 투입량",
                        "roundThreeAmount": "3차 투입량",
                        "roundFourAmount": "4차 투입량",
                        "roundFiveAmount": "5차 투입량",
                        "totalAmount": "총 투입량",
                        "subMaterialSampleNo": "일련번호",
                        "content": "내용",
                        "targetSample": "타겟샘플",
                        "materialSpecifications": "자재사양",
                        "postProcessing": "후가공",
                        "printing": "인쇄",
                        "etc": "기타",
                        "sampleDescription": "비고 (샘플 관련)",
                        "receiveDate": "수신일",
                        "subMaterialState": "완료여부",
                        "sampleNo": "샘플 번호",
                        "orderState": "발주여부",
                        "processingCompanyName": "가공업체",
                        "koreanIngredientName": "국문성분명",
                        "writer": "작성자",
                        "writeAt": "작성일",
                        "reviewRound": "품평 회차",
                        "experimentRound": "실험 회차",
                        "ingredientRegistrationTableDoc": "제품 성분표",
                        "experimentManagerName": "실험자",
                        "registrant": "작성자",
                        "processingCompany": "가공업체",
                        "productSampleId": "제품 샘플 이력 식별자",
                        "eguLabDataSheetId": "실험 정보 식별자",
                        "eguLabDataSheetDetailId": "실험 정보 상세 식별자",
                        "subMaterialSampleId": "부자재 샘플이력 식별자",
                        "captionId": "확정 샘플 성분표 이력 식별자",
                        "eguIngredientRegistrationId": "제품 성분표 식별자",
                        "extLotNoBarCode": "내부 관리 LOT 바코드",
                        "wareHouseBarCode": "창고 바코드",
                    },
                    "httpStatus":
                    {
                        "100": "계속",
                        "101": "프로토콜 전환",
                        "102": "처리중",
                        "103": "Early Hints",
                        "200": "성공",
                        "201": "작성됨",
                        "202": "허용됨",
                        "203": "신뢰할 수 없는 정보",
                        "204": "콘텐츠 없음",
                        "205": "콘텐츠 재설정",
                        "206": "일부 콘텐츠",
                        "207": "다중 상태",
                        "208": "이미 보고됨",
                        "226": "IM Used",
                        "300": "여러 선택 항목",
                        "301": "영구 이동",
                        "302": "임시 이동",
                        "303": "See Other",
                        "304": "수정되지 않음",
                        "305": "프록시 사용",
                        "306": "Switch Proxy",
                        "307": "임시 리다이렉션",
                        "308": "영구 리다이렉션",
                        "400": "잘못된 요청",
                        "401": "권한 없음",
                        "402": "결제 필요",
                        "403": "금지됨",
                        "404": "찾을 수 없음",
                        "405": "허용되지 않는 방법",
                        "406": "허용되지 않음",
                        "407": "프록시 인증 필요",
                        "408": "요청 시간 초과",
                        "409": "충돌",
                        "410": "사라짐",
                        "411": "길이 필요",
                        "412": "사전 조건 실패",
                        "413": "요청 속성이 너무 큼",
                        "414": "요청 URI가 너무 긺",
                        "415": "지원되지 않는 미디어 유형",
                        "416": "처리할 수 없는 요청 범위",
                        "417": "예상 실패",
                        "418": "I'm a teapot",
                        "421": "잘못 전환된 요청",
                        "422": "처리할 수 없는 엔티티",
                        "423": "잠김",
                        "424": "실패된 의존성",
                        "426": "업그레이드 필요",
                        "428": "전제 조건 필요",
                        "429": "너무 많은 요청",
                        "431": "요청 헤더가 너무 큼",
                        "451": "법적인 이유로 이용 불가",
                        "500": "내부 서버 오류",
                        "501": "구현되지 않음",
                        "502": "불량 게이트웨이",
                        "503": "서비스를 사용할 수 없음",
                        "504": "게이트웨이 시간 초과",
                        "505": "HTTP 버전이 지원되지 않음",
                        "506": "Variant Alsos Negotiates",
                        "507": "용량 부족",
                        "508": "루프 감지됨",
                        "510": "확장되지 않음",
                        "511": "네트워크 인증 필요"
                    },
                    "info":
                    {
                        "applicationStarted": "Application이 정상적으로 시작됨",
                        "startingServerWithConfig": "아래 설정으로 서버 시작 중 : ",
                        "reactContentsServingAppLoaded": "React 콘텐츠 서빙 App 로드 완료",
                        "staticContentsServingAppLoaded": "스태틱 콘텐츠 서빙 App 로드 완료",
                        "generalApiServingAppLoaded": "일반 API 서빙 App 로드 완료",
                        "authApiServingAppLoaded": "인증 API 서빙 App 로드 완료",
                        "filesAppLoaded": "파일 서빙 App 로드 완료",
                        "corsEnabled": "CORS 활성화됨",
                        "serverListening": "서버가 포트 {{port}}에서 동작 중",
                        "poolCreatedWithConfig": "MySql 연결 Pool이 다음 설정으로 생성됨 : ",
                        "sqlExecuted": "SQL이 실행됨 : ",
                        "result": "결과 : ",
                        "preExecCustomApiLoaded": "사전 실행 Custom API 모듈 {{file}} 로드 완료",
                        "postExecCustomApiLoaded": "사후 실행 Custom API 모듈 {{file}} 로드 완료",
                        "noAuthApiLoaded": "No Auth API 모듈 {{file}} 로드 완료",
                        "deleteCanceled": "삭제가 취소되었습니다",
                        "createTenantConnectionPool": "Tenant {{tenant}}를(을) 위한 Connection Pool을 생성합니다",
                        "startingCluster": "Cluster를 시작합니다",
                        "numberOfCpu": "CPU 개수 : {{numOfCpus}}",
                        "forkNewWorker": "새로운 Worker를 Fork합니다",
                        "masterRunning": "마스터 프로세스 {{pid}}가 시작 되었습니다",
                        "workerRunning": "Worker 프로세스 {{pid}}가 시작 되었습니다",
                        "workerCount": "{{workerCount}} 개의 Worker를 실행 합니다"
                    },
                    "warning":
                    {
                        "formDataValidationFail": "입력 데이터에 오류가 있습니다 : {{msg}}",
                        "partialDeleteFailure": "{{success}}개의 레코드가 삭제 되었습니다\n{{fail}}개의 레코드 삭제에 실패하였습니다\n원인 : {{reason}}",
                        "noChange": "변경된 사항이 없습니다",
                        "invalidConsumedQty": "소비 수량은 재고 수량 보다 클 수 없습니다.",
                        "invalidQty": "수량은 0보다 커야 합니다",
                        "canNotDeleteOrderedTask": "지시된 작업은 삭제할 수 없습니다",
                        "outOfStock": "재고가 부족합니다.",
                        "notSplitOnTheLine": "생산 중 품목을 분리할 수 없습니다. 전체 수량을 인수해 주세요.\n(자주 검사를 시행하거나 전체 수량을 인수하십시오.)",
                        "numberRequired": "숫자만 입력 가능합니다.",
                        "totalIsNotZero": "합계는 0일 수 없습니다.",
                        "totalAddHundredPercent": "비율의 총합은 100% 이어야 합니다."
                    },
                    "error":
                    {
                        "verifyAuthTokenFail": "인증 토큰 검증 실패",
                        "authTokenMissing": "인증 토큰 없음",
                        "jsonBodyForNewRowRequired": "새 레코드를 위한 JSON 바디가 필요함",
                        "primaryKeyRequired": "기본 키 {{key}}가(이) 필요함",
                        "queryFailed": "SQL 쿼리 실패",
                        "methodNotAllowed": "{{method}} {{url}} 허용되지 않음",
                        "jsonParsingFailed": "JSON 파싱 실패",
                        "applicationStartFailed": "Application 기동 실패",
                        "identityOrCrendentialMismatch": "아이디 또는 패스워드 불일치",
                        "identityOrCrendentialMissing": "아이디 또는 패스워드 누락",
                        "userNotFound": "사용자 {{user}}가 없습니다",
                        "userNameMissing": "아이디 누락",
                        "getConnectionFailed": "DB 연결 Pool에서 연결 획득 실패",
                        "beginTransactionFailed": "Transaction 개시  실패",
                        "notFound": "찾을 수 없음",
                        "insertFailed": "추가 실패",
                        "alreadyExist": "이미 존재하는 레코드",
                        "columnRequired": "{{column}}는(은) 필수 항목 입니다",
                        "userNameTooLong": "아이디 길이가 너무 깁니다",
                        "passwordLengthError": "비밀번호 길이 오류 : {{msg}}",
                        "passwordTooShort": "비밀번호가 너무 짧습니다",
                        "passwordTooLong": "비밀번호가 너무 깁니다",
                        "passwordNotMatch": "비밀번호가 일치하지 않습니다",
                        "inputValueTooLong": "입력 값이 너무 깁니다",
                        "numberRequired": "숫자를 입력해야 합니다",
                        "floatRequired": "숫자를 입력해야 합니다",
                        "emailRequired": "잘못된 이메일 형식입니다",
                        "insertFailure": "레코드 생성에 실패하였습니다 - 중복 레코드인지 확인하세요: {{reason}}",
                        "updateFailure": "래코드 저장에 실패하였습니다 : {{reason}}",
                        "deleteFailure": "레코드 삭제에 실패하였습니다 - 다른 곳에서 사용 중인지 확인하세요 : {{reason}}",
                        "approvaPathNotSelected": "승인 경로가 선택되지 않았습니다",
                        "approvalRequestFailure": "승인 요청이 실패하였습니다 - 승인 요청이 중복되었는지 확인하세요 : {{reason}}",
                        "approveApprovalFailure": "승인 요청 허가가 실패하였습니다 : {{reason}}",
                        "rejectApprovalFailure": "승인 요청 거절이 실패하였습니다 : {{reason}}",
                        "cancelApprovalFailure": "승인 취소가 실패하였습니다 : {{reason}}",
                        "withdrawApprovalFailure": "승인 요청 철회가 실패하였습니다 : {{reason}}",
                        "approvalTargetNotSelected": "승인 대상이 선택되지 않았습니다",
                        "approvalAlreadyRequested": "이미 승인이 요청된 대상입니다 {{item}}",
                        "approvalRequesterNotMatch": "승인 요청자가 승인 대상 작성자와 일치하지 않습니다",
                        "approvalStateNotExist": "승인 상태가 존재하지 않습니다",
                        "cannotApprovePendingApproval": "사전 승인자에 의해 승인이 유보된 승인 요청입니다",
                        "cannotApproveApprovedApproval": "이미 승인된 승인 요청입니다",
                        "cannotApproveRejectedApproval": "반려된 승인 요청을 승인할 수 없습니다",
                        "cannotApproveWithdrawnApproval": "철회된 승인 요청을 승인할 수 없습니다",
                        "cannotApproveCancelledApproval": "승인 취소된 승인 요청을 승인할 수 없습니다",
                        "cannotRejectPendingApproval": "사전 승인자에 의해 승인이 유보된 승인 요청입니다",
                        "cannotRejectApprovedApproval": "승인된 승인 요청을 거절할 수 없습니다",
                        "cannotRejectRejectedApproval": "이미 반려된 승인 요청입니다",
                        "cannotRejectWithdrawnApproval": "철회된 승인 요청을 거절할 수 없습니다",
                        "cannotRejectCancelledApproval": "승인 취소된 승인 요청을 거절할 수 없습니다",
                        "cannotWithdrawApprovedApproval": "승인된 승인 요청을 철회할 수 없습니다",
                        "cannotWithdrawRejectedApproval": "반려된 승인 요청을 철회할 수 없습니다",
                        "cannotWithdrawWithdrawnApproval": "이미 철회한 승인 요청입니다",
                        "cannotWithdrawCancelledApproval": "이미 승인되었다가 취소된 승인 요청을 철회할 수 없습니다",
                        "cannotCancelPendingApproval": "승인 대기 중인 승인 요청을 취소할 수 없습니다",
                        "cannotCancelPartiallyApprovedApproval": "부분 승인된 승인 요청을 취소할 수 없습니다",
                        "cannotCancelRejectedApproval": "반려된 승인 요청을 취소할 수 없습니다",
                        "cannotCancelWithdrawnApproval": "철회된 승인 요청을 취소할 수 없습니다",
                        "cannotCancelCancelledApproval": "이미 취소된 승인 입니다",
                        "noApprovalRequest": "승인 요청이 없습니다",
                        "approvalDetailStateMismatch": "승인 상태와 승인 상세 상태가 일치하지 않습니다 : approvalPathId [{{approvalPathId}}], approvalDetailId [{{approvalDetailId}}]",
                        "updateApprovalDetailFailed": "승인 상세 갱신에 실패했습니다",
                        "updateApprovalStateFailed": "승인 상태 갱신에 실패했습니다",
                        "invalidApprover": "정상적인 승인자가 아닙니다",
                        "invalidApprovalState": "비정상적인 승인 상태입니다",
                        "invalidAppovalTargetTableName": "잘못된 승인 대상 테이블 입니다",
                        "inventoryAmountNotEnough": "재고 수량 보다 출고 수량이 더 많습니다.",
                        "notAuthorizedApi": "사용자 {{username}}이 허용되지 않은 API {{url}}에 Method {{method}}로 접근을 시도하였습니다",
                        "notAuthorizedFile": "사용자 {{username}}이 허용되지 않은 파일 {{url}}에 접근을 시도하였습니다",
                        "duplicateEstimateVersion": "존재하는 견적 버전입니다.",
                        "baseMajorVersionNotExist": "존재하지 않는 기본 Major Version {{baseVersionMajor}} 입니다.",
                        "baseMajorVersionRequired": "기본 Major Version 정보가 필요합니다.",
                        "fileUploadFailure": "파일 업로드가 실패하였습니다",
                        "fileDeleteFailure": "파일 삭제에 실패하였습니다",
                        "shipmentDetailFailure": "수주 수량 및 재고 수량을 확인해주세요.",
                        "codeGenerateFailure": "코드 생성 규칙 명 [{{codeGenerateName}}] 을 찾을 수 없습니다.",
                        "codeGenerateUpdateFailure": "코드를 업데이트 할 수 없습니다.",
                        "soFailure": "수주 번호 [{{soNumber}}] 가 중복입니다.",
                        "soDetailFailure": "존재하는 품목 입니다.",
                        "poFailure": "발주 번호 [{{poNumber}}] 가 중복입니다.",
                        "poDetailFailure": "존재하는 품목 입니다.",
                        "noHelp": "현재 화면에 대한 도움말이 없습니다",
                        "forkWorkerFailed": "새로운 Cluster Worker Fork에 실패하였습니다. Cluster를 종료합니다",
                        "workerDisconnected": "Worker 프로세스 {{pid}}가 종료되었습니다",
                        "disconnectFromMaster": "Worker 프로세스 {{pid}}가 Master 프로세스로부터 연결이 단절되었습니다. Worker 프로세스를 종료합니다",
                        "workerStartFailed": "Worker 프로세스 {{pid}}가 기동 실패하였습니다",
                        "verifyCsrfTokenFailed": "CSRF Token 검증이 실패하였습니다",
                        "fixMachineFailureFailed": "설비 수리에 실패하였습니다 : {{reason}}",
                        "bopDataMissing": "누락된 BOP 데이터가 있습니다",
                        "failToReadLotNo": "해당 LOT번호가 없습니다.",
                        "failToReadMaterialInfo": "해당 Material 정보가 없습니다.",
                        "failToBackShipMatQty": "반품 수량이 출하 수량보다 많습니다.",
                        "failToShipOrderBuyerIdNotExist": "해당 LOT의 주문 번호가 없습니다.",
                        "failToShipWareHouseNotProper": "출고 창고에서만 출고가 가능합니다.",
                        "failToShipMatClosed": "해당 제품의 자재가 유효 하지 않습니다.",
                        "failToShipMatNotProper": "해당 주문에 출고하고자 하는 제품이 없습니다.",
                        "failToOrderBuyerIdNotAvailable": "해당 주문정보가 유효 하지 않습니다.",
                        "failToOrderBuyerIdNotApproved": "해당 주문정보에 대한 승인이 되지 않았습니다.",
                        "failToOrderBuyerIdShipNotApproved": "해당 주문정보에 대한 출하 승인이 되지 않았습니다.",
                        "failToWipLotCheckProcessOutSourcingIn": "해당 LOT의 최신 아웃소싱이력을 찾을 수 업습니다.",
                        "failToOrderBuyerIdSame": "동일 주문정보로 변경되지 않습니다.",
                        "cannotFindTransLogId": "해당 Trans Log Id 를 찾을 수 없습니다.",
                        "cannotFindInputInspectNo": "해당 Input Inspect 를 찾을 수 없습니다.",
                        "qtyLessThanQtyInput": "입고 수량이 주문 수량보다 적습니다.",
                        "alreadyInputItem": "이미 입고 완료된 품목입니다.",
                        "failToSplitAvailable": "생산 현장에서 LOT 분할을 할 수 없습니다.",
                        "failToSplitOutSourcingFail": "분리대상 LOT은 아웃 소싱 상태가 아니여야 합니다.",
                        "failToSplitQtyunderZero": "분리 수량이 가용한 수량보다 많습니다.",
                        "materialLoopDetected": "자재 루프가 감지되었습니다 (생산 품목: {{materialCode}}/{{materialCodeVer}},  \b중복 자재: {{duplicatedMaterialCode}}/{{duplicatedMaterialCodeVer}})",
                        "failToMergeAvailable": "자재, 자재버전이 같아야 병합 가능합니다.",
                        "failToMergeOutSourcing": "두개 LOT은 전부 아웃소싱 상태가 아니여야 합니다.",
                        "failToMergeAvailableLineDiff": "동일 라인의 자재만 병합이 가능합니다.",
                        "failToMergeAvailableOrderBuyerId": "두개 LOT의 주문 번호가 다릅니다.",
                        "failToMergeAvailableWhDiff": "동일 창고의 자재만 병합이 가능합니다",
                        "failToMoveSameWareHouse": "동일창고로 이동할 필요가 없습니다",
                        "failToMoveLineNo": "생산현장 이동시 라인정보가 필요합니다.",
                        "failToWipLotCheckProcessOutSourcingCompanyNoExist": "해당 기업명칭이 등록 되어 있지 않습니다.",
                        "emptyOrNegativeProductionQty": "생산 수량은 0보다 커야 합니다",
                        "failToCancelByShipMergeSplit": "출하/병합/분할된 자제는 생산 취소 할 수 없습니다.",
                        "failToCancelByWorkState": "원자재는 수정 할 수 없습니다.",
                        "bopNotFound": "자재 생산을 위한 BOP를 찾을 수 없습니다.",
                        "bopAlreadyExist": "자재의 BOP가 이미 존재합니다.",
                        "processNotFound": "공정을 찾을 수 없습니다.",
                        "bopOutMaterialNotFound": "BOP에서 생산 자재를 찾을 수 없습니다.",
                        "failToStockTakingMustAdjustQtyLow": "Lot 의 수량을 반영 하려면 원 수량보다 적어야 합니다.",
                        "failToStockTakingNotList": "재고조사 수정리스트에 정보가 없습니다.",
                        "materialNotOnProductionLine": "생산에 필요한 자재가 생산 라인에 없습니다.",
                        "productionStartFail": "Lot No {{lotNo}}의 생산 시작이 실패하였습니다",
                        "completeProcessFail": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 완료가 실패 하였습니다",
                        "startProcessFail": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 시작이 실패 하였습니다",
                        "holdProcessFail": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업 보류가 실패 하였습니다",
                        "releaseHoldFail": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업 재개가 실패 하였습니다",
                        "cancelProductionFail": "Lot No {{lotNo}}의 생산 취소가 실패 하였습니다",
                        "positiveFloatRequired": "0보다 큰 값이 필요합니다",
                        "failAndLossQtyExceedsProductionQty": "손실 및 불량 수량은 생산 수량을 초과할 수 없습니다",
                        "duplicateMaterialSelected": "중복된 자재가 선택되었습니다",
                        "materialsForProductionNotSelected": "생산에 필요한 자재가 선택되지 않았습니다.",
                        "lotNoNotFound": "Lot No {{lotNo}}를(을) 찾을 수 없습니다.",
                        "notProcessingLot": "Lot No {{lotNo}}는 생산 중이 아닙니다.",
                        "lotNoMismatch": "요청 데이터의 Lot No {{lotNo}}가 요청 URL과 일치하지 않습니다.",
                        "missingLot": "Lot No {{lotNo}}가 존재하지 않습니다.",
                        "missingLotTransaction": "Lot No {{lotNo}}의 {{transaction}} Transaction이 존재하지 않습니다.",
                        "cannotStartProcessingOnLot": "Lot No {{lotNo}} 에서는 공정을 시작할 수 없습니다.",
                        "noMoreProcess": "더 이상 공정이 없습니다",
                        "noBomBopTree": "BOM/BOP Tree 가 없습니다",
                        "qtyMustMultiplyPurchaseQty": "입력한 수량이 최소 구매 수량의 배수여야 합니다.",
                        "failToMoveSameWareHouseOrLineNo": "동일 창고이거나 동일 라인으로 이동할 필요가 없습니다.",
                        "correctableDateExceeded": "수정 가능한 날짜를 초과하였습니다.",
                        "failToShipQty": "출고 수량이 출하 지시 수량보다 많을 수 없습니다.",
                        "undefinedError": "정의되지 않은 에러 : {{reason}}",
                        "importTranslationDataFailed": "변역 데이터를 가져오지 못했습니다 : {{reason}}.",
                        "apporvalTargetNotFound": "승인 대상이 존재하지 않습니다.",
                        "onlyLastApproverCouldCancelApproval": "승인 취소는 최종 승인자만 수행할 수 있습니다",
                        "noProductTree": "생산할 수 있는 품목이 없습니다",
                        "markReadFailed": "메시지를 읽은 상태로 표시하는데 실패하였습니다.",
                        "markReadAllFailed": "모든 메시지를 읽음 상태로 표시하는데 실패하였습니다.",
                        "unknownOperation": "알 수 없는 동작입니다",
                        "markUnreadFailed": "메시지를 읽지 않음으로 표시하는데 실패했습니다.",
                        "moveToTrashFailed": "메시지 삭제에 실패하였습니다",
                        "messageNotFound": "메시지가 존재하지 않습니다",
                        "recoverMessage": "메시지 복구에 실패하였습니다.",
                        "deleteMessage": "메시지 삭제에 실패하였습니다.",
                        "emptyTrash": "메시지 삭제에 실패하였습니다.",
                        "invalidFileExtension": "잘못된 파일 확장자입니다. {{extension}}",
                        "sendResetPasswordEmailFailed": "비밀번호 재설정을 위한 이메일 전송에 실패하였습니다.",
                        "noSuchUser": "존재하지 않는 사용자입니다.",
                        "expiredToken": "유효기간이 경과한 접근입니다.",
                        "qtyLossMoreThanQty": "손실 수량이 현 수량보다 많습니다",
                        "failToGetOrderBuyerDetailInfo": "수주 세부 정보를 가져올 수 없습니다",
                        "failToGetOrderPurchaseDetailInfo": "발주 세부 정보를 가져올 수 없습니다",
                        "orderBuyerNotApproved": "수주({{orderBuyerId}})가 승인 완료되지 않았습니다.",
                        "failToQtyBackShip": "반품 수량이 반품 가능 수량보다 많을 수 없습니다.",
                        "momSysInspectionNotFound": "{{inspectionId}}를 MomSysInspection에서 찾을 수 없습니다",
                        "inputFailure": "입고 동작이 실패하였습니다 - 중복 레코드인지 확인하세요 : {{reason}}",
                        "eguInputInspectionNotFound": "{{inputInspectionId}}를 EguInputInspection에서 찾을 수 없습니다",
                        "eguVwInputInspectionNotFound": "{{inputInspectinoId}}를 EguVwInputInspection에서 찾을 수 없습니다",
                        "MomSysUglOrderPurchaseMstNotFound": "{{orderPurchaseId}}를 MomSysUglOrderPurchaseMst 에서 찾을 수 없습니다",
                        "notAllowedFileType": "허용되지 않는 파일 유형입니다",
                        "eguSelfInspectionNotFound": "{{selfInspectionId}}를 EguSelfInspection에서 찾을 수 없습니다",
                        "materialTakeOverInsufficientQuantity": "생산에 필요한 자재들의 인수 수량이 부족합니다.",
                        "selectLotMaterialsConsumeQtyRequiredQtyLack": "선택한 LOT의 자재들의 소비 수량의 합계가  필요 수량 보다 부족합니다.",
                        "momSysInspectionRsltNotFound": "{{inspectionId}}/{{inspectionItemId}}/{{inspectionCriteriaId}}를 MomSysInspectionRslt에서 찾을 수 없습니다",
                        "momSysVwInspectionRsltNotFound": "{{inspectionId}}를 MomSysVwInspectionRslt에서 찾을 수 없습니다",
                        "qtyLessThanMinPurchaseQty": "입력한 수량이 최소 구매 수량보다 적습니다.",
                        "beforeProcessNotCompleted": "생산 계획의 이전 공정 {{processCode}} 이(가) 완료 되지 않았습니다.",
                        "missingRequestData": "요청 데이터가 누락되었습니다",
                        "confirmProductnameNotExist": "확정 제품명이 존재하지 않습니다.",
                        "duplicateOrderBuyerDetail": "이미 등록된 데이터입니다.(품목 코드: {{materialCode}}, 품목 버전: {{materialCodeVer}})",
                        "MomSysUglOrderBuyerMstNotFound": "수주 {{orderBuyerId}}가(이) 존재하지 않습니다",
                        "duplicateOrderPurchaseDetail": "이미 등록된 데이터입니다.(품목 코드: {{materialCode}}, 품목 버전: {{materialCodeVer}})",
                        "createProductionPlanFail": "생산 계획 생성에 실패하였습니다",
                        "lotNotFound": "Lot No {{lotNo}}는 찾을 수 없습니다.",
                        "updatePlanFailed": "작업 {{taskName}} 수정에 실패하였습니다",
                        "planNotFound": "생산 계획 {{planId}}가 존재하지 않습니다",
                        "deleteLinkFailed": "링크 삭제에 실패하였습니다",
                        "createLinkFailed": "링크 생성에 실패하였습니다",
                        "taskNotFound": "작업 {{taskId}}는 존재하지 않습니다",
                        "unknownTaskType": "알 수 없는 작업 유형입니다 {{taskType}}",
                        "deleteTaskFailed": "작업 삭제가 실패 하였습니다",
                        "assignWorkerFailed": "작업자 지정에 실패하였습니다",
                        "assignMachineFailed": "설비 지정이 실패하였습니다",
                        "positiveNumberWithoutSignRequired": "부호 없는 양수를 입력해야 합니다",
                        "splitQtyTooLarge": "분할 수량은 원래 수량 보다 작아야 합니다",
                        "splitTaskFailed": "작업 분할이 실패하였습니다",
                        "changeTaskDurationFailed": "작업 시간이 변경되었습니다",
                        "orderTaskFailed": "작업 지시에 실패하였습니다",
                        "alreadyOrderedPlan": "이미 지시된 작업입니다",
                        "alreadyStartedPlan": "시작된 작업은 지시를 취소할 수 없습니다",
                        "notOrderdedPlan": "지시되지 않은 작업은 취소할 수 없습니다",
                        "cancelOrderFailed": "작업 지시 취소가 실패하였습니다",
                        "milestoneAlreadyExists": "동일 이름의 이정표가 이미 존재합니다",
                        "createMilestoneFailed": "이정표 생성이 실패하였습니다",
                        "updateNotSupportedTaskType": "수정이 지원되지 않는 작업 유형입니다",
                        "changeMilestoneNameFailed": "이정표 이름 변경에 실패하였습니다",
                        "InsufficientInventoryQty": "해당 품목 명 : {{materialName}} , 품목 코드 : {{materialCode}}, 품목 버전 : {{materialCodeVer}} 의 재고 수량이 부족 합니다.",
                        "qtyLotOfNotFakeInputQty": "가입고 수량이 발주 수량보다 적을 수 없습니다.",
                        "notBeforeProcessCompleted": "해당 외주 발주의 시작 공정의 이전 공정이 완료되지 않았습니다.\n",
                        "materialsForProductionNotTakeOver": "생산에 필요한 모든 자재가 인수 되지 않았습니다.",
                        "LotMaterialsConsumeQtyRequiredQtyLack": " LOT의 자재들의 소비 수량의 합계가  필요 수량 보다 부족합니다.",
                        "wrongProcessOrder": "공정 순서가 잘못되었습니다.(첫 공정 순서: {{startProcessOrder}}, 마지막 공정 순서: {{endProcessOrder}})",
                        "outSourcingNotOrdered": "지시되지 않은 공정입니다.(공정 순서 : {{processOrder}}, 공정 코드 : {{processCode}}, 공정명 : {{processName}})",
                        "outSourcingCompletedProcess": "완료된 공정입니다.(공정 순서 : {{processOrder}}, 공정 코드 : {{processCode}}, 공정명 : {{processName}})",
                        "outSourcingNotPlanedOutSourcing": "아웃소싱 계획이 되어 있지 않은 공정입니다.(공정 순서 : {{processOrder}}, 공정 코드 : {{processCode}}, 공정명 : {{processName}})",
                        "outSourcingAlreadyRegisteredProcess": "이미 등록된 공정입니다.(공정 순서 : {{processOrder}}, 공정 코드 : {{processCode}}, 공정명 : {{processName}})",
                        "outSourcingNotRegisteredPlan": "계획에 등록되지 않은 공정입니다.(공정 순서 : {{processOrder}}, 공정 코드 : {{processCode}}, 공정명 : {{processName}})",
                        "canNotUpdateOrderedTask": "지시된 작업은 수정할 수 없습니다",
                        "stockQtyLessThanOutSourcingQty": "재고 수량이 발주 수량보다 적습니다.(총 재고 수량 : {{qtyCurrent}}, 발주 수량 : {{outSourcingQty}})",
                        "fakeInputQtyShouldBeBigoutSourcingQty": "가입고 수량은 외주 발주 수량보다 크거나 같아야 합니다.",
                        "alphaNumericRequired": "알파벳과 숫자만 허용됩니다",
                        "alphaNumericRequiredOrTooLong": "알파벳 또는 숫자가 아니거나  너무 긴 입력입니다",
                        "noAvailableMaterialFound": "사용가능한 자재가 없습니다.(자재 코드 : {{materialCode}}, 자재 버전 : {{materialCodeVer}})",
                        "materialLoopDetectedInProductTree": "자재 루프가 감지되었습니다 ({{materialCode}}/{{materialCodeVer}})",
                        "invalidUserName": "잘못된 사용자 ID입니다",
                        "createConnectionFailed": "DB Connection 생성 실패",
                        "notHyphenOrNumeric": "하이픈( - ) 또는 숫자만 입력 가능합니다.",
                        "NotExistLabDataSheet": "실험 정보가 없습니다.",
                        "NotExistLabDataSheetDetail": "실험 상세 정보가 없습니다.",
                        "batchAddPerformanceMetricFailure": "생산 효율 일괄 등록에 실패하였습니다 : {{reason}}",
                        "noDataToAdd": "추가할 데이터가 없습니다",
                        "allShip": "수주 번호 : {{orderBuyerId}}의 전체 출고를 실패하였습니다.",
                        "materialInsufficient": "자재 코드 : {{materialCode}}, 자재 버전 : {{materialCodeVer}}, 자재 명 : {{materialName}} 의 수량이 부족합니다.",
                        "outSourcingAllShip": "외주 출고 번호 :{{outSourcingShipId}}의  전체 출고를 실패했습니다.",
                        "shipAvailableLotNotFonud": "자재 명 : {{materialName}} ,자재 코드 :{{materialCode}} 자재 버전 {{materialCodeVer}} 의 출고 가능한 LOT이 없습니다."
                    },
                    "success":
                    {
                        "insertSuccess": "레코드가 생성 되었습니다",
                        "updateSuccess": "{{updateCount}} 개의 래코드가 저장 되었습니다",
                        "deleteSuccess": "{{deleteCount}} 개의 레코드가 삭제 되었습니다",
                        "approvalRequestSuccess": "승인이 요청되었습니다",
                        "approveApprovalSuccess": "승인 요청이 허가 되었습니다",
                        "rejectApprovalSuccess": "승인 요청이 거절 되었습니다",
                        "cancelApprovalSuccess": "승인이 취소 되었습니다",
                        "withdrawApprovalSuccess": "승인 요청이 철회 되었습니다",
                        "fileDownloadSuccess": "파일 다운로드가 성공하였습니다",
                        "fileDownloadFailure": "파일 다운로드가 실패하였습니다 : {{reason}}",
                        "fixMachineFailureSuccess": "설비 수리가 완료되었습니다",
                        "moveUpProcess": "공정 {{processName}}[{{processCode}}] 순서를 위로 이동하였습니다",
                        "moveDownProcess": "공정 {{processName}}[{{processCode}}] 순서를 아래로 이동하였습니다",
                        "productionStartSuccess": "Lot No {{lotNo}}의 생산이 시작되었습니다",
                        "completeProcessSuccess": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 완료 되었습니다",
                        "startProcessSuccess": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 시작 되었습니다",
                        "holdProcessSuccess": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 보류 되었습니다",
                        "releaseHoldProcessSuccess": "Lot No {{lotNo}}의 공정 {{processName}}[{{processCode}}] 의 작업이 재개 되었습니다",
                        "cancelProductionSuccess": "Lot No {{lotNo}}의 생산이 취소 되었습니다",
                        "fakeInputMaterialSuccess": " 가입고 레코드가 등록 되었습니다.",
                        "importTranslationDataSuccess": "변역 데이터를 가져왔습니다.",
                        "markReadAllSuccess": "모든 메시지를 읽음 상태로 표시 하였습니다.",
                        "markUnreadSuccess": "메시지를 읽지 않음으로 표시하였습니다.",
                        "moveToTrashSuccess": "메시지가 삭제되었습니다.",
                        "recoverMessage": "메시지가 복구되었습니다",
                        "deleteMessage": "메시지가 완전히 삭제되었습니다",
                        "emptyTrash": "모든 메시지가 삭제되었습니다.",
                        "sendEmailForResetPassword": "비밀번호 재설정을 위한 이메일을 전송했습니다.",
                        "passwordChanged": "비밀번호가 변경되었습니다.",
                        "verifyResetPasswordToken": "유효한 비밀번호 재설정 토큰입니다.",
                        "resetPassword": "비밀번호가 재설정 되었습니다.",
                        "insertSuccessExcel": "성공 {{data}}",
                        "applyExcelSuccess": "엑셀 적용 되었습니다.",
                        "splitLot": "{{lotNo}} LOT이 분리되어 {{createdLotNo}} LOT이 생성 되었습니다.\n\n",
                        "mergeLot": "{{unionLot}} LOT 에 {{victimLot}} LOT 이 병합 되었습니다.",
                        "moveLot": "{{LotNo}} LOT 이 {{wareHouseTo}} 창고에서 {{wareHouseFrom}} 창고로 이동되었습니다.",
                        "adaptLot": "{{lotNo}} LOT 의 수주 번호가 {{beforeOrderBuyerId}}에서 {{afterOrderBuyerId}}로 변경 되었습니다.",
                        "shipLot": "수주 번호 :{{orderBuyerId}} , 출하 번호 :{{shipmentId}}, {{lotNo}} LOT, {{materialName}} 제품이(가)  출고 처리 되었습니다.",
                        "backShipLot": "{{orderBuyerId}} 수주 번호, {{lotNo}} LOT, {{materialName}} 제품이(가) 반품 처리 되었습니다. ",
                        "stockTaking": "{{stockTakingId}} 실사 적용 되었습니다.",
                        "inputSuccess": "입고 성공",
                        "noticeUpdateSuccess": "{{noticeNo}}번 공지사항이 수정되었습니다.",
                        "outputMaterial": "자재 반납을 완료 하였습니다.",
                        "qcRequest": "{{receiver}}에게 QC를 요청했습니다.",
                        "createProductionPlanSuccess": "생산 계획이 생성되었습니다",
                        "delteLinkSuccess": "링크가 삭제 되었습니다",
                        "createLinkSuccess": "링크가 생성되었습니다",
                        "deleteTaskSuccess": "작업이 삭제 되었습니다",
                        "assignWorkerSuccess": "작업자가 지정되었습니다",
                        "assignMachineSuccess": "설비가 지정되었습니다",
                        "splitTaskSuccess": "작업이 분할되었습니다",
                        "changeTaskDurationSuccess": "작업 시간이 변경되었습니다",
                        "orderTaskSuccess": "작업이 지시 되었습니다",
                        "cancelOrderSuccess": "작업 지시가 취소되었습니다",
                        "createMilestoneSuccess": "이정표가 생성되었습니다",
                        "changeMilestoneNameSuccess": "이정표 이름이 변경되었습니다",
                        "adaptSuccess": "적용 성공",
                        "outSourcingShip": "외주 발주 번호 {{outSourcingId}} 출고 등록 되었습니다.",
                        "outSourcingShipDetail": "자재 명 : {{materialName}} ,자재 코드 :{{materialCode}} 자재 버전 {{materialCodeVer}} 출고 처리 되었습니다.",
                        "batchAddPerformanceMetricSuccess": "생상 효율 일괄 등록에 성공하였습니다",
                        "shipAll": "수주 번호 : {{orderBuyerId}} , 출하 번호 : {{shipmentId}} 의 제품이 전체 출고 되었습니다.",
                        "outSourcingAllShip": "외주 출고 번호 :{{outSourcingShipId}}의 자재들이 전체 출고 되었습니다."
                    },
                    "term":
                    {
                        "company": "회사",
                        "userName": "아이디",
                        "password": "비밀번호",
                        "login": "로그인",
                        "none": "없음",
                        "reason": "원인",
                        "approvalPhase":
                        {
                            "request": "승인요청",
                            "approver": "승인자",
                            "approved": "승인완료",
                            "cancelled": "승인취소"
                        },
                        "keepDialogOpen": "창을 연 상태로 유지",
                        "inputMaterial": "자재",
                        "outputMaterial": "생산 품목",
                        "process": "공정",
                        "bop": "BOP",
                        "bom": "BOM",
                        "so": "수주",
                        "soDetail": "수주 상세정보",
                        "po": "발주",
                        "poDetail": "발주 상세정보",
                        "shipByOrderBuyerDetail": "출고 정보",
                        "shipList": "출고리스트",
                        "shipStatus": "출고 현황",
                        "backShipList": "반품리스트",
                        "backShipStatus": "반품 현황",
                        "inWareHouse": "입고 창고",
                        "outWareHouse": "출고 창고",
                        "mergeList": "표준품 병합 이력",
                        "splitList": "표준품 분할 이력",
                        "mergeLotSelect": "병합할 LOT 선택",
                        "delivered": "완료",
                        "notDelivered": "미완료",
                        "stockTakingList": "실사 리스트",
                        "materialStatus": "자재 현황",
                        "quantityNotEqualsMaterialList": "변경된 자재 리스트",
                        "hold": "작업보류",
                        "processing": "작업중",
                        "inputLots": "소비 자재",
                        "sales": "매출",
                        "purchase": "매입",
                        "collections": "수금",
                        "advancePayment": "선수금",
                        "lotSelectionType": "LOT 선택 유형",
                        "failedLotList": "불량 목록",
                        "expense": "지출",
                        "productInfo": "제품 정보",
                        "productList": "제품 리스트",
                        "soProduct": "수주 품목",
                        "materialList": "자재 리스트",
                        "materialInfo": "자재 정보",
                        "lot": "LOT",
                        "lotHistory": "LOT 처리 이력",
                        "componentLots": "구성 정보 (투입된 자재)",
                        "productLots": "투입 정보 (투입 내역)",
                        "qty": "수량",
                        "all": "전체",
                        "ownCompany": "자사",
                        "partialConsignment": "부분위탁",
                        "orderProcessing": "임가공",
                        "srcMaterial": "원천 BOM/BOP 자재",
                        "dstMaterial": "대상 BOM/BOP 자재",
                        "fakeMaterialInputStatus": "가입고 자재 현황",
                        "soSpareStockStatus": "비축 재고 현황",
                        "tenant": "Tenant",
                        "namespace": "네임스페이스",
                        "translationPath": "번역 경로",
                        "translationValue": "번역 값",
                        "outputList": "출고 목록",
                        "outputStatus": "출고 상태",
                        "stockTaking": "재고 조사 목록",
                        "productStatus": "제품 현황",
                        "roleSet": "역할 세트",
                        "msgList": "메시지 목록",
                        "message": "메시지 내용",
                        "productProductionStatus": "제품 생산 현황",
                        "productProductionNoInputList": "제품 생산 후 미입고 리스트",
                        "yesterday": "전일",
                        "today": "금일",
                        "prevWeek": "전주",
                        "currentWeek": "금주",
                        "prevMonth": "전월",
                        "currentMonth": "금월",
                        "storeCompanyAndUserId": "아이디 저장",
                        "findPassword": "비밀번호 찾기",
                        "standard": "표준 품목",
                        "nonStandard": "비표준 품목",
                        "customerService": "고객 센터",
                        "shipment": "출하",
                        "shipmentDetail": "출하 상세정보",
                        "newPassword": "새 비밀번호",
                        "nsSplitList": "비표준품 분할 이력",
                        "nsMergeList": "비표준품 병합 이력",
                        "standardProductList": "표준 제품 리스트",
                        "standardProductInfo": "표준 제품 정보",
                        "nonStandardProductList": "비표준 제품 리스트",
                        "nonStandardProductInfo": "비표준 제품 정보",
                        "nonStandardMaterialInfo": "비표준 자재 정보",
                        "standardMaterialInfo": "표준 자재 정보",
                        "ship": "출고",
                        "shipDetail": "출고 상세 정보",
                        "standardShipPossibleLot": "표준 품목 출고 가능한 LOT",
                        "nonStandardShipPossibleLot": "비표준 품목 출고 가능한 LOT",
                        "MomSysDelivery": "납품",
                        "MomSysDeliveryDetail": "납품 상세",
                        "fakeInput": "가입고",
                        "fakeInputDetail": "가입고 상세정보",
                        "backShip": "반품",
                        "backShipDetail": "반품 상세",
                        "standardBackShipPossibleLot": "표준 품목 반품 가능한 LOT",
                        "nonStandardBackShipPossibleLot": "비표준 품목 반품 가능한 LOT",
                        "standardLogShip": "표준품 출고 이력",
                        "nonStandardLogShip": "비표준품 출고 이력",
                        "shipmentAll": "수주물량 전체 출하",
                        "EguPopProduce": "제조",
                        "EguPopLead": "제심",
                        "EguPopWood": "우드",
                        "EguPopEtc": "충진/몰딩/포장",
                        "EguPopProduceDetail": "제조 상세정보",
                        "EguPopLeadDetail": "제심 상세정보",
                        "EguPopWoodDetail": "우드 상세정보",
                        "EguPopEtcDetail": "충진/몰딩/포장 상세정보",
                        "eguInputInspection": "입고 검사",
                        "eguSelfInspection": "자주 검사",
                        "eguInputInspectionDetail": "입고 검사 상세 정보",
                        "takeOverMaterial": "필요 자재 별 인수 수량",
                        "worker": "작업자",
                        "lineNo": "생산 라인",
                        "machine": "설비",
                        "workerUnassigned": "작업자 미할당",
                        "lineNoUnassigned": "생산라인 미할당",
                        "machineUnassigned": "설비 미할당",
                        "momSysInspectionCriteria": "검사 기준",
                        "momSysInspectionGroup": "검사 그룹",
                        "momSysInspectionItem": "검사 항목",
                        "momSysInspectionList": "검사 그룹 세부 정보",
                        "inspectionTargetLot": "검사 대상 Lot",
                        "momSysInspectionRslt": "검사 결과 상세",
                        "todayNotice": "오늘의 공지",
                        "capacity": "가용 시간",
                        "workload": "계획 시간",
                        "workresult": "실적 시간",
                        "resource": "자원",
                        "startDate": "시작일",
                        "unassigned": "미할당",
                        "MomSysVwLotProcessFail": "LOT 생산 공정 불량 이력",
                        "EguVwInputInspectionFail": "자재 불량 이력",
                        "EguVwSelfInspectionFail": "벌크/제품 불량 이력",
                        "eguInputMaterialStatusByInspection": "입고 검사 별 자재 입고 현황",
                        "taskName": "작업명",
                        "durationInHours": "작업 시간",
                        "ordered": "지시",
                        "completed": "완료",
                        "notOrdered": "미지시",
                        "notCompleted": "미완료",
                        "year": "년도",
                        "month": "월",
                        "selectOrdered": "지시 여부",
                        "selectCompleted": "완료 여부",
                        "production": "생산",
                        "worktime": "작업 실적",
                        "eguInputList": "입고 리스트",
                        "selectProductionItem": "생산 품목 선택",
                        "thisYear": "올해 ({{year}})",
                        "lastYear": "작년 ({{year}})",
                        "twoYearsAgo": "재작년 ({{year}})",
                        "EguPopEtcFilling": "충진",
                        "EguPopEtcMolding": "몰딩",
                        "EguPopEtcPacking": "포장",
                        "assigned": "지정",
                        "notAssigned": "미지정",
                        "outSourcingShip": "외주 출고",
                        "outSourcingShipDetail": "외주 출고 상세정보",
                        "eguOutSourcingPurchase": "외주 발주",
                        "addPlan": "계획 추가",
                        "started": "작업중",
                        "orderedStatus": "작업 지시",
                        "hour": "시간",
                        "notStarted": "미시작",
                        "eguOutSourcingPurchaseDetail": "외주 발주 상세",
                        "addMilestone": "이정표 추가",
                        "week": "주",
                        "day": "일",
                        "scale": "최소 단위",
                        "showMonthCount": "표시 월 수",
                        "showGrid": "테이블 표시",
                        "prevYear": "전년",
                        "eguOutSourcingInputInspection": "외주 입고 검사 및 입고",
                        "batchUpdate": "일괄 수정",
                        "manual": "수동",
                        "auto": "자동",
                        "min": "최소값",
                        "max": "최대값",
                        "avg": "평균값",
                        "eguOutSourcingPurchaseOutSourced": "외주 발주 생산 품목",
                        "partialOrdered": "부분 지시",
                        "eguOutSourcingPurchaseDetailInOutMaterial": "외주 발주 자재 품목",
                        "eguOutSourcingMaterialReturn": "외주 자재 회수",
                        "materialReturnTarget": "자재 회수 대상",
                        "outSourcingLogShip": "외주 출고 이력",
                        "productionMaterial": "생산 자재",
                        "outSourcingFakeInput": "외주 가입고",
                        "eguOrderPurchaseMstExcelDownLoadFileNamePrefix": "발주서_",
                        "EguPopOtherWork": "기타 작업",
                        "EguPopOtherWorkDetail": "기타 작업 상세",
                        "eguOutSourcingMaterialReturnDetail": "외주 자재 회수 상세",
                        "materialReturnDetailTarget": "자재 회수 대상 상세",
                        "productionStatus": "생산 현황",
                        "salesStatus": "영업 현황",
                        "managementIndicator": "경영 지표",
                        "CostInfo": "원가 정보",
                        "machineRental": "장비 대여 및 반납",
                        "mrpMaterialList": "자재 소요량 관리 대상 리스트",
                        "mrpMaterialEventData": "자재 소요량 관리 : 이벤트 정보",
                        "mrpMaterialTarget": "자재 소요량 관리 대상",
                        "manufacturingOverhead": "간접비",
                        "salesAndManagementCost": "판관비",
                        "laborCostDetail": "인건비 상세",
                        "laborCost": "인건비",
                        "productSample": "제품 샘플 이력",
                        "experiment": "실험 정보",
                        "experimentDetail": "실험 정보 상세",
                        "subMaterialSample": "부자재 샘플이력",
                        "confirmProduct": "확정 샘플 성분표 이력",
                        "eguIngredientRegistration": "제품 성분표",
                        "popNoOrderPurchaseInputHisory": "무발주 입고 이력"
                    },
                    "common":
                    {
                        "ok": "확인",
                        "cancel": "취소",
                        "create": "등록",
                        "edit": "수정",
                        "delete": "삭제",
                        "apply": "적용",
                        "search": "검색",
                        "clear": "초기화",
                        "add": "추가",
                        "order": "작업 지시",
                        "cancelOrder": "작업 지시 취소",
                        "today": "오늘",
                        "assignResource": "자원 할당",
                        "openAll": "모두 펼치기",
                        "closeAll": "모두 닫기",
                        "batchAdd": "일괄 등록"
                    },
                    "inputTitle":
                    {
                        "displayMode": "출력 모드",
                        "dataTableSearch": "검색",
                        "dataTableItemCount": "출력 아이템 갯수"
                    },
                    "dataTableFilter":
                    {
                        "inputTitle":
                        {
                            "filterOpr": "연산자",
                            "filterOpr2": "연산자 2",
                            "filterValue": "값",
                            "filterValue2": "값 2",
                            "filterAndOr": "그리고/또는"
                        },
                        "andOrValue":
                        {
                            "and": "그리고",
                            "or": "또는"
                        },
                        "booleanValue":
                        {
                            "notSet": "(미설정)",
                            "true": "참",
                            "false": "거짓"
                        },
                        "filterOperator":
                        {
                            "oprNotSet": "(미설정)",
                            "stringEQ": "일치",
                            "stringNE": "불일치",
                            "stringBW": "... 로 시작",
                            "stringEW": "... 로 끝남",
                            "stringCT": "... 포함",
                            "stringNC": "... 미포함",
                            "numberEQ": "==",
                            "numberNE": "!=",
                            "numberGT": ">",
                            "numberGE": ">=",
                            "numberLT": "<",
                            "numberLE": "<=",
                            "booleanEQ": "==",
                            "booleanNE": "!=",
                            "dateEQ": "==",
                            "dateGE": "이후",
                            "dateLE": "이전"
                        },
                        "buttonName":
                        {
                            "filterSubmit": "필터 적용",
                            "filterReset": "필터 리셑"
                        },
                        "pagination":
                        {
                            "start": "처음",
                            "end": "끝"
                        }
                    },
                    "buttonName":
                    {
                        "appendApprover": "추가",
                        "insertApprover": "삽입",
                        "delApprover": "삭제",
                        "create": "등록",
                        "update": "수정",
                        "delete": "삭제",
                        "searchFilter": "검색 필터",
                        "excelDownload": "엑셀 다운로드",
                        "approval": "승인",
                        "approvalRequest": "승인 요청",
                        "approvalWithdrawal": "승인 철회",
                        "approvalRejection": "승인 반려",
                        "approvalCancel": "승인 취소",
                        "approveApproval": "승인 허가",
                        "materialInput": "입고",
                        "materialOutput": "출고",
                        "adjustment": "조정",
                        "shipment": "출하",
                        "backShip": "반품",
                        "estimateDownload": "견적서 다운로드",
                        "registerMachineFailure": "고장/수리 등록",
                        "fixMachineFailure": "수리 완료",
                        "addProcess": "공정 추가",
                        "deleteProcess": "공정 삭제",
                        "moveUpProcess": "공정 순서 위로",
                        "moveDownProcess": "공정 순서 아래로",
                        "addMaterial": "자재 추가",
                        "updateMaterial": "자재 수정",
                        "delMaterial": "자재 삭제",
                        "outSourcing": "아웃소싱",
                        "outSourcingIn": "제품반입",
                        "updateOrderBuyerId": "수주번호 변경",
                        "splitLot": "Lot 분할",
                        "mergeLot": "Lot 병합",
                        "moveLot": "창고 이동",
                        "cancel": "취소",
                        "produce": "생산",
                        "materialInputInspect": "입고 검사",
                        "cancelProduce": "생산 취소",
                        "newStockTaking": "신규 조사",
                        "applyStockTaking": "재고 적용",
                        "startProcessing": "공정 시작",
                        "completeProcessing": "공정 완료",
                        "holdProcessing": "작업 보류",
                        "releaseHoldProcessing": "작업 재개",
                        "cancelProduction": "생산 취소",
                        "addFailedLot": "불량 추가",
                        "soSpareStock": "비축 재고 등록",
                        "changeSpareStock": "비축 재고 변경",
                        "createMaterial": "자재 등록",
                        "createProcess": "공정 등록",
                        "copyBomBop": "BOM/BOP 복사",
                        "copy": "복사",
                        "fakeInput": "가입고",
                        "rental": "대여",
                        "createStock": "품목 재고 등록",
                        "addTranslationPath": "추가",
                        "deleteTranslationPath": "삭제",
                        "addNamespace": "추가",
                        "deleteNamespace": "삭제",
                        "modifyTranslationValue": "수정",
                        "export": "내보내기",
                        "import": "가져오기",
                        "MergeLot": "병합",
                        "MoveLot": "창고 이동",
                        "copyPath": "번역 경로 복사",
                        "productInput": "제품 입고",
                        "markReadAll": "모두 읽음",
                        "markUnread": "읽지 않음",
                        "recover": "복구",
                        "empty": "비우기",
                        "createWithExcel": "엑셀 파일 일괄 등록",
                        "changePassword": "비밀번호 변경",
                        "uploadAndApplyExcel": "엑셀 적용 및 업로드",
                        "shipmentCreateAll": "출하 전체 등록",
                        "stockApplyExcel": "재고 엑셀 적용",
                        "produceStart": "작업 시작",
                        "produceEnd": "작업 종료",
                        "input": "입고",
                        "QCRequest": "QC 의뢰",
                        "InputMaterial": "자재 인수",
                        "outputMaterial": "자재 반납",
                        "eguSelfInspectionDownload": "검사 성적서 다운로드",
                        "momSysVwLotProcessFail": "LOT 생산 공정 불량 이력",
                        "eguVwInputInspectionFail": "자재 불량 이력",
                        "eguVwSelfInspectionFail": "벌크/제품 불량 이력",
                        "output": "출고",
                        "excelUpload": "엑셀 업로드",
                        "excelFormDownload": "엑셀 양식 다운로드",
                        "excelUploadError": "엑셀 업로드 에러",
                        "excelUploadSuccess": "엑셀 업로드 성공",
                        "machineRentalOutUpdate": "출고 수정",
                        "machineRentalOutCreate": "출고 등록",
                        "machineRentalInUpdate": "입고 수정",
                        "allShip": "일괄 출고",
                        "outSourcingAllShip": "일괄 외주 출고"
                    },
                    "date":
                    {
                        "localeName": "ko",
                        "dateFormat": "yyyy/MM/dd",
                        "dateTimeFormat": "yyyy/MM/dd HH:mm",
                        "timeFormat": "HH:mm",
                        "monthFormat": "yyyy-MM"
                    },
                    "form":
                    {
                        "userForm":
                        {
                            "groupName":
                            {
                                "essential": "필수 정보",
                                "name": "이름",
                                "password": "비밀번호",
                                "photo": "사진"
                            }
                        },
                        "menuSetForm":
                        {
                            "groupName":
                            {
                                "name": "메뉴세트명",
                                "description": "설명"
                            }
                        },
                        "menuItemForm":
                        {
                            "groupName":
                            {
                                "essential": "필수 정보",
                                "description": "설명"
                            },
                            "selectItems":
                            {
                                "menuType":
                                {
                                    "menu": "메뉴",
                                    "popmenu": "POP 메뉴",
                                    "title": "제목",
                                    "dividersmall": "구분자(small)",
                                    "divider": "구분자"
                                },
                                "iconType":
                                {
                                    "materialUi": "Material UI"
                                }
                            }
                        },
                        "approvalTypeForm":
                        {
                            "selectItems":
                            {
                                "ordered":
                                {
                                    "ordered": "사용",
                                    "notOrdered": "미사용"
                                }
                            }
                        },
                        "materialInventoryForm":
                        {
                            "selectItems":
                            {
                                "ioState":
                                {
                                    "input": "실사 입고",
                                    "output": "실사 출고"
                                }
                            }
                        },
                        "momSysMaterialByCompanyForm":
                        {
                            "selectItems":
                            {
                                "mainCustomer":
                                {
                                    "main": "주 거래처",
                                    "sub": "부 거래처"
                                }
                            }
                        }
                    },
                    "dialog":
                    {
                        "title":
                        {
                            "user":
                            {
                                "create": "사용자 등록",
                                "edit": "사용자 정보 수정",
                                "delete": "사용자 삭제"
                            },
                            "User":
                            {
                                "create": "사용자 등록",
                                "update": "사용자 정보 수정",
                                "delete": "사용자 삭제"
                            },
                            "Profile":
                            {
                                "update": "사용자 프로파일 수정"
                            },
                            "Role":
                            {
                                "create": "역할 등록",
                                "update": "역할 정보 수정",
                                "delete": "역할 삭제"
                            },
                            "RoleSet":
                            {
                                "create": "역할 세트 등록",
                                "update": "역할 세트 수정",
                                "delete": "역할 세트 삭제"
                            },
                            "RoleSetDetail":
                            {
                                "create": "역할 세트 상세 등록",
                                "update": "역할 세트 상세 수정",
                                "delete": "역할 세트 상세 삭제"
                            },
                            "MenuSet":
                            {
                                "create": "메뉴 세트 등록",
                                "update": "메뉴 세트 정보 수정",
                                "delete": "메뉴 세트 삭제"
                            },
                            "MenuItem":
                            {
                                "create": "메뉴 항목 등록",
                                "update": "메뉴 항목 정보 수정",
                                "delete": "메뉴 항목 삭제"
                            },
                            "TopMenuItems":
                            {
                                "create": "상위 메뉴 항목 등록",
                                "update": "상위 메뉴 항목 수정",
                                "delete": "상위 메뉴 항목 삭제"
                            },
                            "SubMenuItems":
                            {
                                "create": "하위 메뉴 항목 등록",
                                "update": "하위 메뉴 항목 수정",
                                "delete": "하위 메뉴 항목 삭제"
                            },
                            "ApprovalType":
                            {
                                "create": "승인 유형 등록",
                                "update": "승인 유형 수정",
                                "delete": "승인 유형 삭제"
                            },
                            "Approver":
                            {
                                "create": "승인자 등록",
                                "update": "승인자 수정",
                                "delete": "승인자 삭제"
                            },
                            "ApprovalPath":
                            {
                                "create": "승인 경로 등록",
                                "update": "승인 경로 수정",
                                "delete": "승인 경로 삭제",
                                "appendApprover": "승인자 추가",
                                "insertApprover": "승인자 삽입",
                                "deleteApprover": "승인자 삭제",
                                "manage": "승인 경로 관리"
                            },
                            "ApprovalPathNode":
                            {
                                "create": "승인 경로 상세 등록",
                                "update": "승인 경로 상세 수정",
                                "delete": "승인 경로 상세 삭제"
                            },
                            "Approval":
                            {
                                "request": "승인 요청",
                                "status": "승인 상태",
                                "approve": "승인 허가",
                                "reject": "승인 반려",
                                "withdraw": "승인 철회",
                                "cancel": "승인 취소"
                            },
                            "MaterialInOutput":
                            {
                                "input": "자재 입고",
                                "output": "자재 출고",
                                "adjustment": "자재 조정"
                            },
                            "grid":
                            {
                                "columnPicker": "출력 행 선택"
                            },
                            "Item":
                            {
                                "create": "품목 등록",
                                "update": "품목 수정",
                                "delete": "품목 삭제"
                            },
                            "ItemUnit":
                            {
                                "create": "품목 단위 등록",
                                "update": "품목 단위 수정",
                                "delete": "품목 단위 삭제"
                            },
                            "ItemType":
                            {
                                "create": "품목 구분 등록",
                                "update": "품목 구분 수정",
                                "delete": "품목 구분 삭제"
                            },
                            "ItemSpec":
                            {
                                "create": "품목 규격 등록",
                                "update": "품목 규격 수정",
                                "delete": "품목 규격 삭제"
                            },
                            "Vendor":
                            {
                                "create": "거래처 등록",
                                "update": "거래처 수정",
                                "delete": "거래처 삭제"
                            },
                            "Customer":
                            {
                                "create": "고객사 등록",
                                "update": "고객사 수정",
                                "delete": "고객사 삭제"
                            },
                            "Po":
                            {
                                "create": "발주 등록",
                                "update": "발주 수정",
                                "delete": "발주 삭제"
                            },
                            "PoDetail":
                            {
                                "create": "발주 상세 등록",
                                "update": "발주 상세 수정",
                                "delete": "발주 상세 삭제"
                            },
                            "So":
                            {
                                "create": "수주 등록",
                                "update": "수주 수정",
                                "delete": "수주 삭제"
                            },
                            "SoDetail":
                            {
                                "create": "수주 상세 등록",
                                "update": "수주 상세 수정",
                                "delete": "수주 상세 삭제"
                            },
                            "EstimateBase":
                            {
                                "create": "견적 기본 정보 등록",
                                "update": "견적 기본 정보 수정",
                                "delete": "견적 기본 정보 삭제"
                            },
                            "Estimate":
                            {
                                "create": "견적 버전 정보 등록",
                                "approve": "견적 버전 정보 승인 요청.",
                                "delete": "견적 버전 정보 삭제"
                            },
                            "EstimateItem":
                            {
                                "create": "견적 아이템 정보 등록",
                                "update": "견적 아이템 정보 수정",
                                "delete": "견적 아이템 정보 삭제"
                            },
                            "WorkerPerformance":
                            {
                                "create": "작업자 실적 등록",
                                "update": "작업자 실적 수정",
                                "delete": "작업자 실적 삭제"
                            },
                            "Shipment":
                            {
                                "create": "출하 등록",
                                "delete": "출하 삭제"
                            },
                            "Performance":
                            {
                                "create": "생산 실적 등록",
                                "update": "생산 실적 수정",
                                "delete": "생산 실적 삭제"
                            },
                            "ConsumablesInOutput":
                            {
                                "input": "소모품 입고",
                                "output": "소모품 출고",
                                "adjustment": "소모품 조정"
                            },
                            "ProductInOutput":
                            {
                                "input": "제품 입고",
                                "output": "제품 출고",
                                "dueDiligence": "제품 실사"
                            },
                            "AccessControlTarget":
                            {
                                "create": "접근 대상 등록",
                                "update": "접근 대상 수정",
                                "delete": "접근 대상 삭제"
                            },
                            "AccessControlRule":
                            {
                                "create": "접근 규칙 등록",
                                "update": "접근 규칙 수정",
                                "delete": "접근 규칙 삭제"
                            },
                            "ReturnedItem":
                            {
                                "create": "반품 등록",
                                "update": "반품 수정",
                                "delete": "반품 삭제"
                            },
                            "ShipmentDetail":
                            {
                                "shipment": "제품 출하"
                            },
                            "Tenant":
                            {
                                "create": "Tenant 등록",
                                "update": "Tenant 수정",
                                "delete": "Tenant 삭제"
                            },
                            "DbConf":
                            {
                                "create": "DB 설정 등록",
                                "update": "DB 설정 수정",
                                "delete": "DB 설정 삭제"
                            },
                            "Namespace":
                            {
                                "create": "Namespace 등록",
                                "update": "Namespace 수정",
                                "delete": "Namespace 삭제"
                            },
                            "SupportedLanguage":
                            {
                                "create": "지원 언어 등록",
                                "update": "지원 언어 수정",
                                "delete": "지원 언어 삭제"
                            },
                            "Translation":
                            {
                                "create": "용어/번역 등록",
                                "update": "용어/번역 수정",
                                "delete": "용어/번역 삭제"
                            },
                            "TenantLogo":
                            {
                                "create": "Tenant 로고 이미지 등록",
                                "update": "Tenant 로고 이미지 수정",
                                "delete": "Tenant 로고 이미지 삭제"
                            },
                            "MomSysUdtLineNo":
                            {
                                "create": "Line No 등록",
                                "update": "Line No 수정",
                                "delete": "Line No 삭제"
                            },
                            "MomSysUdtWorkStation":
                            {
                                "create": "작업대 등록",
                                "update": "작업대 수정",
                                "delete": "작업대 삭제"
                            },
                            "MomSysUdtWareHouse":
                            {
                                "create": "창고 등록",
                                "update": "창고 수정",
                                "delete": "창고 삭제"
                            },
                            "MomSysMstProcess":
                            {
                                "create": "공정 정보 등록",
                                "update": "공정 정보 수정",
                                "delete": "공정 정보 삭제"
                            },
                            "MomSysUdtCompany":
                            {
                                "create": "거래처 등록",
                                "update": "거래처 수정",
                                "delete": "거래처 삭제"
                            },
                            "MomSysUdtMaterialType":
                            {
                                "create": "품목 구분 등록",
                                "update": "품목 수정",
                                "delete": "품목 삭제"
                            },
                            "MomSysMstMaterial":
                            {
                                "create": "품목 등록",
                                "update": "품목 수정",
                                "delete": "품목 삭제",
                                "excelUpload": "엑셀 업로드"
                            },
                            "MomSysUdtMachineMainType":
                            {
                                "create": "설비 유지보수 유형 등록",
                                "update": "설비 유지보수 유형 수정",
                                "delete": "설비 유지보수 유형 삭제"
                            },
                            "MomSysMstMachine":
                            {
                                "create": "설비 등록",
                                "update": "설비 수정",
                                "delete": "설비 삭제",
                                "registerFailure": "설비 고장 등록",
                                "fixFailure": "고장 수리 완료"
                            },
                            "MomSysUglMachineMain":
                            {
                                "create": "설비고장/수리이력 등록",
                                "update": "설비고장/수리이력 수정",
                                "delete": "설비고장/수리이력 삭제"
                            },
                            "MomSysUglOrderBuyerMst":
                            {
                                "create": "수주 등록",
                                "update": "수주 수정",
                                "delete": "수주 삭제"
                            },
                            "MomSysUglOrderBuyerDetail":
                            {
                                "create": "수주 상세 등록",
                                "update": "수주 상세 수정",
                                "delete": "수주 상세 삭제"
                            },
                            "MomSysUglOrderPurchaseMst":
                            {
                                "spareStockCreate": "비축 재고 등록",
                                "create": "발주 등록",
                                "update": "발주 수정",
                                "delete": "발주 삭제"
                            },
                            "MomSysUglOrderPurchaseDetail":
                            {
                                "create": "발주 상세 등록",
                                "update": "발주 상세 수정",
                                "delete": "발주 상세 삭제",
                                "materialInputInspect": "입고 검사"
                            },
                            "MomSysLogShip":
                            {
                                "ship": "출고",
                                "backShip": "반품 생성"
                            },
                            "MomSysUdtBillOfProcess":
                            {
                                "create": "BOP 공정 추가",
                                "delete": "BOP 공정 삭제"
                            },
                            "MomSysUglCompanyCollection":
                            {
                                "create": "입금/지불 등록",
                                "update": "입금/지불 수정",
                                "delete": "입금/지불 삭제"
                            },
                            "MomSysSalesDetail":
                            {
                                "create": "매출 등록",
                                "update": "매출 수정",
                                "delete": "매출 삭제"
                            },
                            "MomSysCollectionsDetail":
                            {
                                "create": "수금 등록",
                                "update": "수금 수정",
                                "delete": "수금 삭제"
                            },
                            "MomSysVwAdvancePayment":
                            {
                                "create": "선수금 등록",
                                "update": "선수금 수정",
                                "delete": "선수금 삭제"
                            },
                            "MomSysLogOutSourcing":
                            {
                                "outSourcing": "아웃소싱",
                                "outSourcingIn": "제품반입"
                            },
                            "MomSysUdtBillOfMaterial":
                            {
                                "create": "BOM 자재 추가",
                                "update": "BOM 자재 수정",
                                "delete": "BOM 자재 삭제"
                            },
                            "MomSysLogAdapt":
                            {
                                "updateOrderBuyerId": "수주 번호 변경",
                                "changeSpareStock": "비축 재고 변경"
                            },
                            "MomSysLogSplit":
                            {
                                "splitLot": "Lot 분할",
                                "mergeLot": "Lot 병합"
                            },
                            "MomSysLogMove":
                            {
                                "moveLot": "창고 이동"
                            },
                            "Produce":
                            {
                                "startProduction": "생산 시작",
                                "startProcess": "공정 시작",
                                "completeProcess": "공정 완료",
                                "holdProcess": "공정 작업 보류",
                                "releaseHoldProcess": "공정 작업 재개",
                                "cancelProduction": "생산 취소",
                                "produceStart": "작업 시작",
                                "qcRequest": "QC 의뢰",
                                "outputMaterial": "자재 반납"
                            },
                            "MomSysLogWorkEdit":
                            {
                                "cancelProduce": "생산 취소"
                            },
                            "MomSysUglStockTakingMst":
                            {
                                "create": "실사 등록",
                                "stockApplyExcel": "실사 엑셀 등록"
                            },
                            "MomSysUglStockTakingDetail":
                            {
                                "createStock": "재고 등록",
                                "update": "재고 변경",
                                "delete": "재고 삭제"
                            },
                            "MomSysLogAdjust":
                            {
                                "applyStockTaking": "재고 적용"
                            },
                            "MomSysPurchaseDetail":
                            {
                                "create": "매입 등록",
                                "update": "매입 수정",
                                "delete": "매입 삭제"
                            },
                            "MomSysExpenseDetail":
                            {
                                "create": "지출 등록",
                                "update": "지출 수정",
                                "delete": "지출 삭제"
                            },
                            "MomSysBomBopTree": "BOM/BOP Tree",
                            "MomSysProductTree": "생산 품목 Tree",
                            "MomSysCodeGenerate":
                            {
                                "create": "코드 생성 규칙",
                                "update": "코드 규칙 수정",
                                "delete": "코드 규칙 삭제"
                            },
                            "MomSysMaterialByCompany":
                            {
                                "create": "거래처별 품목 등록",
                                "update": "거래처별 품목 수정",
                                "delete": "거래처별 품목 삭제"
                            },
                            "CopyBomBop": "BOM/BOP 복사",
                            "EguMaterialFakeInput":
                            {
                                "fakeInput": "가입고",
                                "create": "가입고 등록",
                                "update": "가입고 수정"
                            },
                            "ImportTranslation": "번역 데이터 가져오기",
                            "ExportTranslation": "번역 데이터 내보내기",
                            "CopyTranslation": "번역 데이터 복사",
                            "TenantTranslation":
                            {
                                "create": "Tenant별 번역 등록",
                                "delete": "Tenant별 번역 삭제"
                            },
                            "RoleSetTranslation":
                            {
                                "create": "역할 세트별 번역 등록",
                                "delete": "역할 세트별 번역 삭제"
                            },
                            "TenantOption":
                            {
                                "create": "Tenant별 옵션 등록",
                                "update": "Tenant별 옵션 수정",
                                "delete": "Tenant별 옵션 삭제"
                            },
                            "TranslationPath":
                            {
                                "create": "번역 경로 등록"
                            },
                            "RoleSetOption":
                            {
                                "create": "역할 세트별 옵션 생성",
                                "update": "역할 세트별 옵션 수정",
                                "delete": "역할 세트별 옵션 삭제"
                            },
                            "DefaultOption":
                            {
                                "create": "기본 옵션 등록",
                                "update": "기본 옵션 수정",
                                "delete": "기본 옵션 삭제"
                            },
                            "TranslationValue":
                            {
                                "update": "번역 값 수정"
                            },
                            "Message":
                            {
                                "confirm":
                                {
                                    "markReadAll": "모두 읽음으로 표시",
                                    "markUnread": "읽지 않음으로 표시",
                                    "moveToTrash": "휴지통으로 이동",
                                    "recover": "메시지 복구",
                                    "empty": "휴지통 비우기"
                                }
                            },
                            "Filter": "필터 조건",
                            "ConfirmFindPassword": "비밀번호 찾기",
                            "ConfirmResetPassword": "비밀번호 재설정",
                            "MomSysShipment":
                            {
                                "create": "출하 등록",
                                "update": "출하 수정",
                                "delete": "출하 삭제"
                            },
                            "MomSysShipmentDetail":
                            {
                                "createAll": "출하 상세 전체 등록",
                                "create": "출하 상세 등록",
                                "update": "출하 상세 수정",
                                "delete": "출하 상세 삭제"
                            },
                            "MomSysDelivery":
                            {
                                "create": "납품 등록",
                                "update": "납품 수정",
                                "delete": "납품 삭제"
                            },
                            "TkUglOrderBuyerMst":
                            {
                                "create": "수주 생성",
                                "update": "수주 수정",
                                "delete": "수주 삭제"
                            },
                            "TkUglOrderBuyerDetail":
                            {
                                "create": "수주 상세 생성",
                                "update": "수주 상세 수정",
                                "delete": "수주 상세 삭제"
                            },
                            "TkUglOrderPurchaseMst":
                            {
                                "create": "발주 생성",
                                "update": "발주 수정",
                                "delete": "발주 삭제"
                            },
                            "MomSysBackShip":
                            {
                                "backShip": "반품 생성",
                                "create": "반품 등록",
                                "update": "반품 수정",
                                "delete": "반품 삭제"
                            },
                            "TkUglOrderPurchaseDetail":
                            {
                                "create": "발주 상세 생성",
                                "update": "발주 상세 수정",
                                "delete": "발주 상세 삭제"
                            },
                            "MomSysBackShipDetail":
                            {
                                "create": "반품"
                            },
                            "MomSysShip":
                            {
                                "create": "출고 등록",
                                "ship": "출고 등록",
                                "update": "출고 수정",
                                "allShip": "일괄 출고 등록"
                            },
                            "PopProduce":
                            {
                                "startProduction": "작업 시작"
                            },
                            "EguInputInspection":
                            {
                                "register": "입고 검사 등록",
                                "update": "입고 검사 수정",
                                "input": "입고 검사로 인한 입고"
                            },
                            "MomSysInspectionItem":
                            {
                                "create": "검사 항목 등록",
                                "update": "검사 항목 수정",
                                "delete": "검사 항목 삭제"
                            },
                            "MomSysInspectionCriteria":
                            {
                                "create": "검사 기준 등록",
                                "update": "검사 기준 수정",
                                "delete": "검사 기준 삭제"
                            },
                            "MomSysInspectionGroup":
                            {
                                "create": "검사 그룹 등록",
                                "update": "검사 그룹 수정",
                                "delete": "검사 그룹 삭제"
                            },
                            "MomSysInspectionList":
                            {
                                "create": "검사 그룹 세부 정보 추가",
                                "delete": "검사 그룹 세부 정보 삭제"
                            },
                            "MomSysUdtOwnCompany":
                            {
                                "update": "자사정보 수정",
                                "create": "자사 정보 등록"
                            },
                            "MomSysNsMstMaterial":
                            {
                                "create": "비표준품 품목 생성",
                                "update": "비표준품 품목 수정",
                                "delete": "비표준품 품목 삭제"
                            },
                            "EguSelfInspection":
                            {
                                "register": "자주 검사 등록",
                                "update": "자주 검사 수정",
                                "moveLot": "창고 이동"
                            },
                            "MomSysInspectionRslt":
                            {
                                "register": "검사 결과 등록",
                                "update": "검사 결과 수정",
                                "delete": "검사 결과 삭제"
                            },
                            "outputMaterial":
                            {
                                "create": "자재 반납"
                            },
                            "MomSysUdtDecisionCode":
                            {
                                "create": "의사결정 코드 등록",
                                "update": "의사결정 코드 수정",
                                "delete": "의사결정 코드 삭제"
                            },
                            "EguMaterialFakeInputDetail":
                            {
                                "update": "가입고 수정",
                                "delete": "가입고 삭제"
                            },
                            "ProductionPlan":
                            {
                                "createProductionPlan": "생산 계획 추가",
                                "assignWorker": "{{taskName}} 작업자 지정",
                                "assignMachine": "{{taskName}} 설비 지정",
                                "splitTask": "{{taskName}} 작업 분할 ( {{duration}} 시간 )",
                                "orderTask": "작업 지시",
                                "cancelOrder": "작업 지시 취소",
                                "addMilestone": "이정표 추가",
                                "changeTaskDuration": "작업 {{taskName}} 작업 시간 변경",
                                "changeMilestoneName": "이정표 이름 변경 ({{taskName}})"
                            },
                            "ConfirmDeleteTask": "작업 삭제",
                            "EguMstMaterial":
                            {
                                "create": "품목 등록",
                                "update": "품목 수정",
                                "excelUpload": "엑셀 업로드"
                            },
                            "MomSysWageRate":
                            {
                                "create": "임율 등록",
                                "update": "임율 수정",
                                "delete": "임율 삭제"
                            },
                            "EguPopNotice":
                            {
                                "delete": "공지사항 삭제"
                            },
                            "EguOutSourcingShip":
                            {
                                "create": "외주 출고 등록",
                                "update": "외주 출고 수정"
                            },
                            "materialInput": "자재 인수",
                            "PerformanceMetric":
                            {
                                "create": "생산 효율 추가",
                                "edit": "생산 효율 수정",
                                "batchUpdate": "일괄 수정"
                            },
                            "EguOutSourcingFakeInput":
                            {
                                "create": "외주 가입고 등록",
                                "update": "외주 가입고 수정"
                            },
                            "EguOutSourcingInputInspection":
                            {
                                "input": "외주 발주 입고 검사로 인한 입고",
                                "register": "외주 발주 입고 검사 등록",
                                "update": "외주 발주 입고 검사 수정"
                            },
                            "Holiday":
                            {
                                "create": "휴일 등록",
                                "update": "휴일 수정",
                                "delete": "휴일 삭제"
                            },
                            "EguOutSourcingPurchase":
                            {
                                "create": "외주 발주 등록",
                                "delete": "외주 발주 삭제"
                            },
                            "AdditionalWorkDay":
                            {
                                "create": "추가 근무일 등록",
                                "update": "추가 근무일 수정",
                                "delete": "추가 근무일 삭제"
                            },
                            "WorkDay":
                            {
                                "create": "근무 요일 등록",
                                "delete": "근무 요일 삭제"
                            },
                            "WorkerDayOfWeekCapacity":
                            {
                                "create": "요일 별 작업자 근무 시간 등록",
                                "update": "요일 별 작업자 근무 시간 수정",
                                "delete": "요일 별 작업자 근무 시간 삭제"
                            },
                            "WorkerDateCapacity":
                            {
                                "create": "일 별 작업자 근무 시간 등록",
                                "update": "일 별 작업자 근무 시간 수정",
                                "delete": "일 별 작업자 근무 시간 삭제"
                            },
                            "DefaultWorkerDayOfWeekCapacity":
                            {
                                "create": "요일 별 기본 근무 시간 등록",
                                "update": "요일 별 기본 근무 시간 수정",
                                "delete": "요일 별 기본 근무 시간 삭제"
                            },
                            "DefaultMachineCapacity":
                            {
                                "create": "설비 별 기본 가용 시간 등록",
                                "update": "설비 별 기본 가용 시간 수정",
                                "delete": "설비 별 기본 가용 시간 삭제"
                            },
                            "MachineDateCapacity":
                            {
                                "create": "일 별 설비 가용 시간 등록",
                                "update": "일 별 설비 가용 시간 수정",
                                "delete": "일 별 설비 가용 시간 삭제"
                            },
                            "EguOutSourcingPurchaseDetail":
                            {
                                "create": "외주 발주 상세 등록",
                                "update": "외주 발주 상세 수정",
                                "delete": "외주 발주 상세 삭제"
                            },
                            "EguOutSourcingMaterialReturn":
                            {
                                "register": "외주 발주 사급 자재 회수 등록",
                                "input": "외주 자재 회수 입고",
                                "update": "외주 발주 사급 자재 회수 수정",
                                "delete": "외주 발주 사급 자재 회수 삭제"
                            },
                            "EguPopOtherWork":
                            {
                                "create": "기타 작업 등록",
                                "update": "기타 작업 수정",
                                "delete": "기타 작업 삭제"
                            },
                            "EguPopOtherWorkDetail":
                            {
                                "create": "기타 작업 상세 등록",
                                "update": "기타 작업 상세 수정",
                                "delete": "기타 작업 상세 삭제"
                            },
                            "EguOutSourcingMaterialReturnDetail":
                            {
                                "register": "외주 발주 사급 자재 회수 상세 등록",
                                "update": "외주 발주 사급 자재 회수 상세 수정",
                                "delete": "외주 발주 사급 자재 회수 상세 삭제"
                            },
                            "MomSysMsgMachine":
                            {
                                "registerFailure": "고장/수리 등록"
                            },
                            "eguMachineRental":
                            {
                                "outCreate": "출고 등록",
                                "outUpdate": "출고 수정",
                                "inUpdate": "입고 수정"
                            },
                            "EguMachineRental":
                            {
                                "delete": "설비 입출 삭제"
                            },
                            "mrpMaterialList":
                            {
                                "register": "자재 소요량 관리 대상 리스트 등록"
                            },
                            "MrpMaterialList":
                            {
                                "update": "자재 소요량 관리 대상 리스트 수정",
                                "delete": "자재 소요량 관리 대상 리스트 삭제"
                            },
                            "BatchAddPerformanceMetric": "생산 효율 일괄 등록",
                            "EguOutSourcingShipDetail":
                            {
                                "allShip": "일괄 외주 출고 등록"
                            },
                            "EguCostInfoMgmt":
                            {
                                "create": "원가 정보 등록",
                                "update": "원가 정보 수정",
                                "delete": "원가 정보 삭제"
                            },
                            "EguLaborCost":
                            {
                                "create": "인건비 등록",
                                "update": "인건비 수정",
                                "delete": "인건비 삭제"
                            },
                            "EguProductSample":
                            {
                                "create": "샘플 생산 등록",
                                "update": "제품 샘플 이력 수정",
                                "delete": "제품 샘플 이력 삭제",
                            },
                            "EguLabDataSheet":
                            {
                                "create": "실험 정보 등록",
                                "update": "실험 정보 수정",
                                "delete": "실험 정보 삭제",
                            },
                            "EguLabDataSheetDetail":
                            {
                                "create": "실험 정보 상세 등록",
                                "delete": "실험 정보 상세 삭제",
                                "update": "실험 정보 상세 수정",
                            },
                            "EguSubMaterialSample":
                            {
                                "create": "부자재 샘플 등록",
                                "update": "부자재 샘플 이력 수정",
                                "delete": "부자재 샘플 이력 삭제",
                            },
                            "EguIngredientRegistrationCaption":
                            {
                                "create": "확정 샘플 성분표 이력 등록",
                                "delete": "확정 샘플 성분표 이력 삭제",
                                "update": "확정 샘플 성분표 이력 수정",
                            },
                            "EguIngredientRegistration":
                            {
                                "create": "제품 성분표 등록",
                                "update": "재품 성분표 수정",
                                "delete": "제품 성분표 삭제",
                            },
                            "PopNoOrderPurchaseInput":
                            {
                                "create": "무발주 입고",
                            }
                        },
                        "msg":
                        {
                            "confirm":
                            {
                                "delete": "{{type}} {{instance}}를(을) 삭제 하시겠습니까?",
                                "approve": "승인 요청을 허가 하시겠습니까?",
                                "reject": "승인 요청을 거절 하시겠습니까?",
                                "withdraw": "승인 요청을 철회 하시겠습니까?",
                                "cancel": "승인을 취소 하시겠습니까?",
                                "fixMachine": "설비 {{machine}}의 수리를 완료 하시겠습니까?",
                                "moveUpProcess": "공정 {{process}}의 순서를 위로 올리겠습니까?",
                                "moveDownProcess": "공정 {{process}}의 순서를 아래로 내리겠습니까?",
                                "holdProcessing": "LotNo {{lotNo}}의 생산 공정 [{{processCode}}: {{processName}}]의 작업을 보류 하시겠습니까?",
                                "releaseHoldProcessing": "LotNo {{lotNo}}의 생산 공정 [{{processCode}}: {{processName}}]의 작업을 재개 하시겠습니까?",
                                "cancelProduction": "LotNo {{lotNo}}의 생산을 취소 하시겠습니까?",
                                "changeSpareStock": "LotNo {{lotNo}}를 비축 재고로 변경 하시겠습니까?",
                                "fakeInput": "자재 명 {{materialName}}를 가입고 처리 하시겠습니까?",
                                "importTranslation": "번역 데이터를 가져오시겠습니까?",
                                "witdraw": "반려 요청을 하시겠습니까?",
                                "startProduce": "수주 번호 : {{orderBuyerId}}, 공정 명 : {{processName}} , 제품 명 :  {{materialName}}을(를) 작업 시작 하시겠습니까?",
                                "outputMaterial": "자재 반납 하시겠습니까?",
                                "allShip": "수주 번호 : {{orderBuyerId}} 전체 출고 하시겠습니까? ",
                                "outSourcingAllShip": "외주 출고 번호 : {{outSourcingShipId}} 전체 출고 하시겠습니까? "
                            },
                            "Message":
                            {
                                "confirm":
                                {
                                    "markReadAll": "모든 메시지를 읽음 상태로 표시하겠습니까?",
                                    "markUnread": "메시지를 읽지 않음 상태로 표시하겠습니까?",
                                    "moveToTrash": "메시지를 삭제하시겠습니까?",
                                    "recover": "메시지를 복구하시겠습니까?",
                                    "delete": "메시지를 완전히 삭제하시겠습니까?",
                                    "empty": "휴지통에 있는 모든 메시지를 완전히 삭제하시겠습니까?"
                                }
                            },
                            "ConfirmFindPassword": "확인 버튼을 누르면 비밀번호 변경 화면으로 연결되는 링크가 포함된 이메일이 전송됩니다.",
                            "ConfirmResetPassword": "비밀번호를 새로운 비밀번호로 변경 하시겠습니까?",
                            "ConfirmDeleteTask": "작업 {{taskName}}을(를) 삭제 하시겠습니까?",
                            "ConfirmBatchAddPerformanceMetric": "생산 효율을 일괄 등록하시겠습니까?"
                        }
                    },
                    "label":
                    {
                        "outputSize": "출력 열 수",
                        "approvalPaths": "승인 경로"
                    },
                    "toolTips":
                    {
                        "help": "도움말",
                        "alert": "알림 - {{msg}}",
                        "profile": "프로파일",
                        "msg":
                        {
                            "noAlert": "알림 없음",
                            "alertCount": "{{count}}개의 알림이 있음"
                        },
                        "approvalPathEdit":
                        {
                            "appendApprover": "마지막 승인자 뒤에 승인자 추가",
                            "insertApprover": "선택된 승인자 앞에 승인자 추가",
                            "delApprover": "승인자 삭제"
                        }
                    },
                    "selectItems":
                    {
                        "approvalState":
                        {
                            "approvalPending": "승인 대기",
                            "partiallyApproved": "부분 승인",
                            "totallyApproved": "승인 완료",
                            "approvalRejected": "승인 반려",
                            "approvalWithdrawn": "승인 철회",
                            "approvalCancelled": "승인 취소"
                        },
                        "approvalDetailState":
                        {
                            "pending": "대기",
                            "approved": "승인",
                            "rejected": "반려",
                            "cancelled": "취소"
                        },
                        "approvalEvent":
                        {
                            "request": "요청",
                            "approve": "승인",
                            "reject": "반려",
                            "withdraw": "철회",
                            "cancel": "취소",
                            "approved": "승인 완료"
                        },
                        "approvalOrdered":
                        {
                            "use": "사용",
                            "doNotUse": "미사용"
                        },
                        "accessControl":
                        {
                            "deny": "접근 거부",
                            "allow": "접근 허용"
                        },
                        "hideUi":
                        {
                            "hide": "감춤",
                            "show": "보임"
                        },
                        "tenantStatus":
                        {
                            "active": "활성",
                            "inactive": "비활성"
                        },
                        "machineAvailable":
                        {
                            "available": "이상 없음",
                            "notAvailable": "고장(수리중)"
                        },
                        "logoSize":
                        {
                            "small": "작은 로고 이미지",
                            "large": "큰 로고 이미지"
                        },
                        "yesNo":
                        {
                            "yes": "예",
                            "no": "아니오"
                        },
                        "initCriteria":
                        {
                            "day": "일",
                            "week": "주",
                            "month": "월",
                            "year": "년"
                        },
                        "productionCategory":
                        {
                            "ownCompany": "자사",
                            "partialConsignment": "부분위탁",
                            "orderProcessing": "임가공"
                        },
                        "sourcingState":
                        {
                            "inSourcing": "IN",
                            "outSourcing": "OUT"
                        },
                        "orderBuyerState":
                        {
                            "enroll": "등록",
                            "hold": "보류",
                            "cancel": "취소"
                        },
                        "msgRead":
                        {
                            "read": "읽음",
                            "notRead": "읽지 않음"
                        },
                        "notShip": "미출하",
                        "partialShip": "부분출하",
                        "completeShip": "출하완료",
                        "notDelivery": "미납",
                        "partialDelivery": "부분납품",
                        "completeDelivery": "납품완료",
                        "stockTaking":
                        {
                            "All": "전체 창고"
                        },
                        "qcResult":
                        {
                            "OK": "적합",
                            "NOK": "불량"
                        },
                        "produceStatus":
                        {
                            "unworked": "미작업",
                            "working": "작업중",
                            "holding": "작업중단",
                            "completion": "작업완료"
                        },
                        "takeOverStatus":
                        {
                            "unacquired": "미인수",
                            "partial": "부분인수",
                            "completion": "인수완료"
                        },
                        "orderedSelection":
                        {
                            "all": "전체",
                            "notOrdered": "미지시",
                            "ordered": "지시"
                        },
                        "completedSelection":
                        {
                            "all": "전체",
                            "completed": "완료",
                            "notCompleted": "미완료"
                        },
                        "outSourcingStatus":
                        {
                            "shipComplete": "자재 출고 완료",
                            "fakeInputComplete": "가입고 완료",
                            "inputInspectionComplete": "입고 검사 완료",
                            "inputComplete": "입고 완료"
                        },
                        "dayOfWeek":
                        {
                            "sunday": "일요일",
                            "monday": "월요일",
                            "tuesday": "화요일",
                            "wednesday": "수요일",
                            "thursday": "목요일",
                            "friday": "금요일",
                            "saturday": "토요일"
                        },
                        "productFamily1": "아이라이너",
                        "productFamily2": "아이브로우",
                        "productFamily3": "립스틱",
                        "productFamily4": "아이새도우",
                        "productFamily5": "마스카라",
                        "productFamily6": "립글로우즈",
                        "productFamily7": "립밤",
                        "productFamily8": "기타",
                        "mrpEventType":
                        {
                            "consume": "소모",
                            "production": "생산",
                            "inputByPurchase": "발주 입고"
                        },
                        "sampleComplete": "완료",
                        "sampleCancel": "취소",
                        "sampleEtc": "기타",
                        "sampleStop": "중지",
                        "sampleHold": "보류",
                        "suitable": "적합",
                        "inadequate": "부적합",
                        "sampleEmpty": "없음",
                    },
                    "notification":
                    {
                        "msg":
                        {
                            "approvalRequestMsg": "{{requester}}에게서 {{msg}}에 대한 승인이 요청되었습니다",
                            "approvalPermitMsg": "{{approver}}에게서 {{msg}}에 대한 승인이 허가되었습니다",
                            "approvalRejectMsg": "{{approver}}에게서 {{msg}}에 대한 승인이 반려되었습니다",
                            "approvalWithdrawMsg": "{{requester}}에게서 {{msg}}에 대한 승인이 철회되었습니다",
                            "approvalCancelMsg": "{{approver}}에게서 {{msg}}에 대한 승인이 취소되었습니다",
                            "passwordChangeEmailSent": "비밀번호 변경 화면으로 연결되는 링크가 포함된 이메일이 발송되었습니다.",
                            "qcRequestMsg": "{{sender}} 에게서 ({{materialInfo}}) 생산에 대한 QC가 요청되었습니다",
                            "qtyExceededWarningMsg": "{{process}}에서 {{materialName}}가 초과 사용되었습니다."
                        }
                    },
                    "approvalRequestMsg":
                    {
                        "msg":
                        {
                            "po": "{{poName}}({{poNumber}}) 발주건",
                            "so": "{{soName}}({{soNumber}}) 수주건",
                            "estimate": "{{estimateNumber}} V{{versionMajor}}.{{versionMinor}} 견적 승인 요청",
                            "stockTaking": "{{stockTakingId}} 재고 조사 요청",
                            "momSysSalesDetail": "{{orderBuyerId}} 매출",
                            "momSysCollectionsDetail": "{{orderBuyerId}} 수금",
                            "momSysPurchaseDetail": "{{orderPurchaseId}} 매입",
                            "momSysExpenseDetail": "{{orderPurchaseId}} 지출",
                            "momSysShipment": "({{shipmentNo}}) 출하",
                            "eguInputInspection": "{{inputInspectionId}} 입고 검사 승인 요청",
                            "popProcess": "{{processName}}({{orderBuyerId}}, {{materialName}}) Pop 작업",
                            "eguSelfInspection": "{{selfInspectionId}} 자주 검사 승인 요청",
                            "momSysBackShip": "{{orderBuyerId}}({{orderBuyerName}}) 반품",
                            "eguPopOtherWork": "{{workerFullName}}, {{otherWorkDate}} 기타작업",
                            "eguOutSourcingPurchase": "{{outSourcingId}}, {{outSourcingName}} 외주 발주",
                            "eguLabDataSheet": "{{eguLabDataSheetId}} 연구",
                            "eguIngredientRegistrationCaption": "{{captionId}} 확정 샘플"
                        }
                    },
                    "infoMsg":
                    {
                        "dragAndDropFileOrClickHere": "파일을 끌어 놓거나 클릭하세요",
                        "noMoreFileAllowed": "더 이상 파일을 추가할 수 없습니다",
                        "noData": "데이터가 없습니다"
                    },
                    "groupByItem":
                    {
                        "lotNo": "LOT",
                        "material": "자재",
                        "process": "공정",
                        "lineNo": "생산 라인",
                        "machine": "설비",
                        "worker": "작업자",
                        "companyName": "거래처",
                        "division": "구분",
                        "orderBuyerId": "수주",
                        "companyCode": "거래처 코드"
                    },
                    "msg":
                    {
                        "translationPathNotSelected": "번역 경로가 선택되지 않았습니다",
                        "translationPathCopied": "번역 경로가 클립 보드에 복사되었습니다."
                    },
                    "workState":
                    {
                        "QUEUED": "입고 완료",
                        "CLOSED": "재고 소진",
                        "FINISHED": "생산 완료",
                        "PROCESSING": "작업 중",
                        "COMPLETED": "공정 완료",
                        "FAILED": "불량"
                    },
                    "ariatech":
                    {
                        "zip": "(우:14057)",
                        "address": "경기도 안양시 동안구 시민대로 401 1011호 ( 관양동, 대륭테크노타운15차 )",
                        "companyName": "회사명 : 아리아텍",
                        "ceo": "대표이사 : 고홍기",
                        "bizLicNo": "사업자등록번호 : 718-86-00667",
                        "email": "이메일 : mom-support@ariatech.kr",
                        "tel": "전화 : 070-5102-8300",
                        "fax": "팩스 : 031-389-6082",
                        "copyright": "주식회사 아리아텍 All rights reserved"
                    },
                    "email":
                    {
                        "changePassword":
                        {
                            "html": "<!DOCTYPE html>\n<html>\n  <head>\n    <title>비밀번호 재설정</title>\n    <meta charset=\"UTF-8\" />\n    <style>\n      .container {\n        display: grid;\n        grid-template-rows: auto auto auto auto;\n        gap : 15px;\n        width: 500px;\n        margin: auto;\n      }\n\n      .logoSection {\n        grid-row: 1 / 2;\n      }\n\n      .logo {\n        width: 150px;\n        margin-left: -10px;\n      }\n\n      .headerSection {\n        grid-row: 2 / 3;\n        height: 7rem;\n        border-bottom: 1px solid LightGrey;\n      }\n\n      .contentSection {\n        grid-row: 3 / 4;\n        height: 6rem;\n        margin-top: 1rem;\n      }\n\n      .linkSection {\n        grid-row: 4 / 5;\n      }\n\n      .headerText, .contentText {\n        line-height: 0.8rem;\n      }\n\n      .link {\n        text-decoration: none;\n      }\n    </style>\n  </head>\n  <body>\n    <div class=\"container\">\n      <div class=\"logoSection\">\n        <img class=\"logo\" src=\"https://radix-mrm-public.s3.ap-northeast-2.amazonaws.com/logo.png\" alt=\"Araitech\" />\n      </div>\n      <div class=\"headerSection\">\n        <h2 class=\"headerText\">아리아텍 스마트 팩토리 비밀번호</h2>\n        <h2 class=\"headerText\">재설정 이메일입니다</h2>\n      </div>\n      <div class=\"contentSection\">\n        <p class=\"contentText\">아래의 링크를 클릭하여 비밀번호 재설정 화면에 접속하세요.</p>\n        <p class=\"contentText\">링크의 유효 기간은 {{expiresIn}} 시간 입니다</p>\n      </div>\n      <div class=\"linkSection\">\n        <a class=\"link\" href=\"{{url}}/changePassword/#{{tenant}}#{{username}}#{{token}}\" target=\"_blank\">비밀번호 재설정<a>\n      </div>\n    </div>\n  </body>\n</html>",
                            "subject": "비밀번호 재설정",
                            "sender": "mom-support@ariatech.kr",
                            "replyTo": "mom-support@ariatech.kr",
                            "text": "E-mail for reset password of\nAriatech Smart Factory\n\n\nConnect to password reset screen by clicking below link.\nThe expiration period of link is {{expiresIn}} hours.\n\n\nURL for Reset Password : {{url}}/changePassword#{{tenant}}#{{username}}#{{token}}\n\n"
                        }
                    },
                    "shipState":
                    {
                        "completeShip": "출고완료",
                        "partShip": "부분 출고",
                        "noneShip": "미출고"
                    },
                    "unit":
                    {
                        "productivity": " 개/시간"
                    },
                    "rowName":
                    {
                        "subTotal": "소계"
                    }
                }
            }
                    
        }
    };
}

let localizationConf = getLocalizationConf();

module.exports = localizationConf;

