
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { useEffect, useState } from "react";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import SaveToExcelButton from "../input/SaveToExcelButton";
import getNewDateObj from "../../lib/common/getNewDateObj";
import DateSelectForm from "../form/input/DateSelectForm";

const CustomMergeManager = function (grid) {
    wjcGrid.MergeManager.apply(this, arguments);
}
CustomMergeManager.prototype = new wjcGrid.MergeManager();

CustomMergeManager.prototype.getMergedRange = function (panel, r, c, clip) {

    if (panel.cellType === wjcGrid.CellType.TopLeft) {
        let range = new wjcGrid.CellRange(0, 0, panel.rows.length - 1, panel.columns.length - 1);

        return range;

    } else {

        return wjcGrid.MergeManager.prototype.getMergedRange.apply(this, arguments);

    }
};

function MaterialYieldStatus(props) {

    const today = new Date();

    const [grid, setGrid] = useState();

    const [api, setApi] = useState();

    const [isEmpty, setIsEmpty] = useState(true);

    const [searchValues, setSearchValues] = useState({
        materialType: "",
        endTime: getNewDateObj(today),
        startTime: getNewDateObj(today.setDate(today.getDate() - 1)),
    });

    const [timer, setTimer] = useState();

    useEffect(() => {

        if (searchValues && api) {

            api.refresh();
        }


    }, [searchValues, api])

    const { t } = useTranslation();

    useEffect(() => {

        if (grid) {

            grid.formatItem.addHandler((grid, e) => {

                if (grid._cols.length > 0) {

                    if (e.panel.cellType === wjcGrid.CellType.TopLeft) {
                        if (e.col === 0) {

                            wjCore.setCss(e.cell, {
                                background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%" stroke="gray" /></svg>'), #eee`,
                                display: "flex",
                                flexDirection: "column",
                            });

                            e.cell.innerHTML = `<span>제품</span><span>자재</span>`;
                            wjCore.setCss(e.cell.children[0], {
                                textAlign: "right",

                            });

                            wjCore.setCss(e.cell.children[1], {
                                textAlign: "left",
                                marginTop: "auto"
                            });
                        }

                    }
                }

                if (e.panel === grid.rowHeaders && e.col === 0) {
                    e.cell.textContent = `${grid.rows[e.row].dataItem.materialName}/${grid.rows[e.row].dataItem.materialCodeVer}`;
                }
            });
        }
        return (() => {
            setTimer((timer) => {
                clearTimeout(timer);
                return;
            })
        })
    }, [grid]);

    const onGridInitialized = (grid) => {
        grid.headersVisibility = "All";
        grid.rowHeaders.columns.minSize = 180;
        grid.rowHeaders.minHeight = 72;
        grid.selectionMode = "Row";
        grid.allowSorting = false;
        grid.allowPinning = false;
        grid.itemsSourceChanged.addHandler((grid, e) => setTimer((timer) => {
            clearTimeout(timer);
            return setTimeout(() => {
                grid.autoSizeColumns();

                // let rng = grid.viewRange;
                // for (let i = rng.leftCol; i <= rng.rightCol; i++) {
                //     grid.autoSizeColumn(i);
                // }
            }, 100)
        }));
        grid.mergeManager = new CustomMergeManager(grid);
        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onModifyUrl = (url) => {

        let newUrl = `${url}/${searchValues.startTime}/${searchValues.endTime}`
        return newUrl;
    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onChangeCondition1Value = (value) => {

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: value,

            };
            return searchValues;
        })


    }
    const onChangeCondition2Value = (value) => {

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                endTime: value,

            };
            return searchValues;
        })
    };

    const onTodayClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);


        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onYesterdayClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setDate(now.getDate() - 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setDate(now.getDate() - 1);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onPrevWeekClick = () => {

        let now = new Date();
        let start = new Date(now);

        let day = now.getDay();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }

        start.setDate(now.getDate() - 7 - day );
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        
        let end = new Date( start );

        end.setDate(start.getDate() + 6);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onCurrentWeekClick = () => {

        let now = new Date();
        let start = new Date(now);

        let day = now.getDay();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }

        start.setDate ( now.getDate () - day) ;
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        let end = new Date( start );

        end.setDate ( start.getDate () + 6 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onPrevMonthClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setMonth(now.getMonth() - 1);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setMonth(now.getMonth());
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onCurrentMonthClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setMonth(now.getMonth());
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setMonth(now.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };


    const exportFormatItem = (args) => {
        const textContent = args.getFormattedCell().textContent;

        if (textContent === "제품자재") {

            args.xlsxCell.value = "";

        } else {

            args.xlsxCell.value = textContent;

        }

    };


    return (
        <BasicCrudScreenType
            id="MaterialYieldStatus"
            table="MaterialYieldStatus"
            view="MaterialYieldStatus"
            viewSchemaUrl={`/api/MaterialYieldStatus/schema/${searchValues?.startTime}/${searchValues?.endTime}`}
            noCreate
            noSearch
            noEdit
            noDelete
            noExcel
            noFilter
            noPagination
            onModifyViewUrl={(url) => onModifyUrl(url)}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onLoadData={onLoadData}
            onAppendHeaderAtFirst={() => (
                <>
                    <BasicCrudScreenActionItemLayout>

                        <div style={{ display: "inline-block" }} >
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "7px",
                                    marginRight: "2px",
                                }}
                            >
                                시작 일 :
                            </span>

                            <DateSelectForm
                                id={"MaterialYieldStatus-condition1-value"}
                                value={searchValues.startTime}
                                onChange={onChangeCondition1Value}
                            />

                        </div>

                        <div style={{ display: "inline-block" }} >

                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "7px",
                                    marginRight: "2px",
                                }}
                            >
                                ~ &nbsp;&nbsp;종료 일 :
                            </span>

                            <DateSelectForm
                                id={"MaterialYieldStatus-condition2-value"}
                                value={searchValues.endTime}
                                onChange={onChangeCondition2Value}
                            />

                        </div>
                        <Button style={{ paddingBottom: "10px" }} onClick={onYesterdayClick}>{t("term.yesterday")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onTodayClick}>{t("term.today")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onPrevWeekClick}>{t("term.prevWeek")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onCurrentWeekClick}>{t("term.currentWeek")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onPrevMonthClick}>{t("term.prevMonth")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onCurrentMonthClick}>{t("term.currentMonth")}</Button>

                    </BasicCrudScreenActionItemLayout>
                </>
            )}

            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <SaveToExcelButton
                            id="MaterialYieldStatus"
                            grid={grid}
                            view="MaterialYieldStatus"
                            color="primary"
                            disabled={isEmpty}
                            exportFormatItem={exportFormatItem}
                            includeRowHeaders
                            fileName={"원료수율 " +
                                searchValues.startTime.getFullYear() + "-" + ( searchValues.startTime.getMonth() + 1 ).toString().padStart(2, '0') + "-" + searchValues.startTime.getDate().toString().padStart(2, '0') + "~" +
                                searchValues.endTime.getFullYear() + "-" + ( searchValues.endTime.getMonth() + 1 ).toString().padStart(2, '0') + "-" + searchValues.endTime.getDate().toString().padStart(2, '0')
                            }
                        />
                    </BasicCrudScreenActionItemLayout>
                </>
            )}
        />
    );

}

export default MaterialYieldStatus;
