import { useState, useEffect } from 'react';
import { FlexChart, FlexChartAxis, FlexChartLegend, FlexChartSeries } from '@grapecity/wijmo.react.chart';
import { FlexChartAnimation } from '@grapecity/wijmo.react.chart.animation';
import  format from "format-number";

function DashWijmoProductivityChart ( props ) {
        
    const { palette, productivityReport } = props;

    const [ years, setYears ] = useState ( [] );
    const [ data, setData ] = useState ([]);

    useEffect ( () => {
        
        if ( productivityReport ) {
        
            let yearMap = {};
            let years = [];

        
            for ( let row of productivityReport ) {
    
                if ( ! yearMap[row.year] ) {
    
                    yearMap[row.year.toString()] = true;
                    years.push(row.year.toString());
    
                }
    
            }

            setYears ( years );

            let data = [
                {
                    month: productivityReport[0].month,
                    [productivityReport[0].year.toString()]: productivityReport[0].productionPerHour,
                    [productivityReport[3].year.toString()]: productivityReport[3].productionPerHour,
                },
                {
                    month: productivityReport[1].month,
                    [productivityReport[1].year.toString()]: productivityReport[1].productionPerHour,
                    [productivityReport[4].year.toString()]: productivityReport[4].productionPerHour,
                },
                {
                    month: productivityReport[2].month,
                    [productivityReport[2].year.toString()]: productivityReport[2].productionPerHour,
                    [productivityReport[5].year.toString()]: productivityReport[5].productionPerHour,
                },
            ];
    
            setData ( data );

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ productivityReport ] );

    const makeTooltip = ( hitTestInfo ) => {

        let year = hitTestInfo.name;
        let month = hitTestInfo.x;
        let value = hitTestInfo.y;

        if ( value >= 100000000 ) {

            value = format({ round: 2, suffix: ' 억'})(value / 100000000);

        } else if ( value >= 10000 ) {

            value = format({ round: 2, suffix: ' 만'})(value / 10000);

        } else {

            value = format({ round: 2 })(value);        

        }
        
        return `<b>${year}년 ${month}월 : </b> <span>${value} 개/시간</span>`;

    }

    return (

        <FlexChart bindingX="month" selectionMode="Point" itemsSource={data} palette={palette}>

            <FlexChartLegend position="Top">
            </FlexChartLegend>

            {
                years.map ( ( year ) => {

                    return (
                        <FlexChartSeries key={year} name={year} binding={year} tooltipContent={makeTooltip}>
                        </FlexChartSeries>
                    );

                } )
            }

            <FlexChartAxis wjProperty="axisY" min={0} itemFormatter={(_,label) => {

                if ( !label ) {

                    return label;

                }


                let value = label.val; 

                if ( value >= 100000000 ) {

                    value = format({ round: 2, suffix: ' 억'})(value / 100000000);

                } else if ( value >= 10000 ) {

                    value = format({ round: 2, suffix: ' 만'})(value / 10000);

                } else {

                    value = format({ round: 2 })(value);        

                }

                label.text = value;

                return label;

            } } >
            </FlexChartAxis>

            <FlexChartAnimation></FlexChartAnimation>

        </FlexChart>

    );
}

export default DashWijmoProductivityChart;
