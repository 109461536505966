/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, FormHelperText, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";

import getList from "../../../lib/api/getList";

const CustomPaper = (props) => {

        return <Paper square={true} {...props} />;
        
};
  
function FilterFormItemAutocomplete ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, label, helperText, InputLabelProps, onChange, ...others } = props;

    const [selectItems, setSelectItems] = useState ([]);

    const codeInfo = column.codeInfo;

    useEffect ( () => {
        
        let mounted = true;

        ( async () => {

            try {
                
                let apiResult = await getList ( codeInfo.tableName );

                if ( mounted ) {

                    let selectItems = apiResult.data.map ( ( result ) => {

                        if ( Array.isArray ( codeInfo.nameColumn ) ) {
    
                            let name = "";
                            let first = true;
    
                            for ( const columnName of codeInfo.nameColumn ) {
    
                                if ( !first && result[columnName] ) {
    
                                    name += ( codeInfo.seperator || ", " );
    
                                }
    
                                first = false;
    
                                name += result[columnName] || "";
    
                            }
    
                            return { name: name, value: result[codeInfo.valueColumn] };
    
                        } else {
    
                            return { name: result[codeInfo.nameColumn], value: result[codeInfo.valueColumn] };
    
                        }
    
                    } );
    
                    if ( codeInfo.sortByName ) {
    
                        selectItems.sort ( ( itemA, itemB ) => ( ( '' + itemA.name ).localeCompare ( itemB.name ) ) )
    
                    }
    
                    if ( codeInfo.useEmptyValue ) {
    
                        setSelectItems ( [ { name: t ( codeInfo.emptyName ), value: "" }, ...selectItems ] );
    
                    } else {
    
                        setSelectItems ( selectItems );
                    }
                    
                }


            } catch ( reason ) {

                console.error ( `Get Code List from Table "${ codeInfo.tableName }" Failed` );

            }

        } )();

        return ( () => {
            mounted = false;
        } );

    }, [] );

    const selectProps = {
        onChange: ( _event, option ) => {

            if ( onChange ) {

                onChange ( option ? option.value : "" );

            }

        },
        ...others
    };

    if ( column.displayEmpty ) {

        selectProps.displayEmpty = true;

    }

    if ( column.filter.style ) {

        selectProps.style = column.filter.style;
        
    }

    return (
        selectItems.length
        ?
        <FormControl className={className} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Autocomplete
                id={ id }
                style={{paddingTop: "16px"}}
                PaperComponent={CustomPaper}
                { ...selectProps }
                options={ selectItems }
                getOptionLabel={( option ) => option.name }
                autoSelect={true}
                renderInput={ ( params ) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "chrome-off";
                    return <TextField {...params} autoFocus={props.autoFocus}  inputProps={inputProps}/>
                } }
                value={selectItems.length > 0 ? selectItems.find( ( item ) => item.value === selectProps.value ) : { name: "", value: "" } }
            />
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
        :
        <></>
    );

}

export default FilterFormItemAutocomplete;
