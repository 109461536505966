/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useEffect, useState } from "react";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';

function DataGridToolTipColumn ( props ) {
    const { schema, ...others } = props;
    const [cellTemplate, setCellTemplate] = useState ((ctx) => {
        return <></>;
    });

    useEffect(() => {
        setCellTemplate(() => ((ctx) => {
            return (
                <div>
                    {ctx.item[schema.name]}
                </div>
            );
        }));

        if(others) {
            let tooltip = new Tooltip();
            others.$parent.control.formatItem.addHandler((flex, element) => {
                if (element.panel === flex.cells) {
                    let item = flex.rows[element.row].dataItem
                    let binding = flex.columns[element.col].binding
                    let data = item[schema.name] ? item[schema.name] : null;
                    toggleClass(element.cell, 'wj-has-notes', binding === schema.name && data !== null );
    
                    if(binding === schema.name && data !== null) {
                        tooltip.setTooltip(element.cell, `<pre>${JSON.stringify(JSON.parse(data), null, 2)}</pre>`);
                    }
                }
            })
        }
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridToolTipColumn;
