import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysNsMstMaterial from "./MomSysNsMstMaterial";
import MomSysMstMaterial from "./MomSysMstMaterial";

function MomSysMstMaterialInfo ( props ) {

    const [ height, setHeight ] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <MomSysMstMaterial id="MomSysMstMaterial" />
                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>
                    <MomSysNsMstMaterial id="MomSysNsMstMaterial" />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysMstMaterialInfo;
