import { useState, useEffect } from "react";

import AutoFilterFormItem from "./AutoFilterFormItem";

import AutoFilterFormRangeWoOpLayout from "../layout/AutoFilterFormRangeWoOpLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";

function AutoFilterFormRangeWoOp ( props ) {

    const { id, tabIndex, column, filterValue, onChange } = props;

    const [ filter, setFilter ] = useState(

        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "3", value: "" },
            condition2: { operator: "5", value: "" }
        
        }

    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    const onChangeCondition1Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };
            
            newFilter.condition1.operator = "3";
            newFilter.condition1.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition2Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.operator = "5";
            newFilter.condition2.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    return (

        <AutoFilterFormRangeWoOpLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem                    
                        id={id+"-condition1-value"}
                        column={column}
                        value={filter.condition1.value}
                        autoFocus={tabIndex===1}
                        onChange={onChangeCondition1Value}
                />

            </AutoFilterFormValueItemLayout>

            <span style={{display: "inline-block", paddingTop: "32px", paddingLeft: "4px", paddingRight: "4px"}}>~</span>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem
                        id={id+"-condition2-value"}
                        label=" "
                        column={column}
                        value={filter.condition2.value}
                        onChange={onChangeCondition2Value}
                />

            </AutoFilterFormValueItemLayout>
            
        </AutoFilterFormRangeWoOpLayout>

    );

}

export default AutoFilterFormRangeWoOp;

