import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function CreateMaterialInputButton(props) {
    const { t } = useTranslation();
    const { id, schema, refresh, onSuccess, createButtonName, profileName, ...others } = props;
    const [open, setOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        if (schema) {
            let modifiedSchema = cloneDeep(schema);
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "ioState":
                        column.hideOnCreate = true;
                        column.disabled = true;
                        column.defaultValue = "입고";
                        break;

                    case "materialInOutputDate":
                        column.hideOnCreate = true;
                        column.disabled = true;
                        break;

                    case "username":
                        column.defaultValue = profileName;
                        break;

                    case "reasonAdjustment":
                        column.hideOnCreate = true;

                        break;

                    default:
                        break;
                }
                setModifiedSchema(modifiedSchema);
            }
        }
    }, [schema, profileName]);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }
        refresh.refresh();
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.${"input"}`) : ""}
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default CreateMaterialInputButton;
