import { useTranslation } from "react-i18next";
import { useState } from "react";

import apiClient from "../../lib/common/apiClient";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function MomSysFailLotTerminateButton ( props ) {

    const { t } = useTranslation();

    const { id, grid, refresh, makeItemName, onSuccess, onFailure, onPartialFailure, modifyUrlCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const schema = {
        name : "MomSysFailLotManagement",
        keys : ["lotNo"],
    }

    const onOpen = () => {

        let deleteData = [];
        let deleteItemStr = "[";

        let firstRow = true;

        for ( const item of grid.selectedItems ) {

            if ( !firstRow ) {

                deleteItemStr += ", ";

            }

            if ( makeItemName ) {

                deleteItemStr += makeItemName( item );

            } else {

                let itemDetail = "{";

                let firstCol = true;

                for ( let key of schema.keys ) {

                    if ( ! firstCol ) {

                        itemDetail += ", ";

                    }

                    itemDetail += ( t(`columnName.${key}`) + ": " + item[key] );

                    firstCol = false;

                }

                itemDetail += "}";

                deleteItemStr += itemDetail;

            }

            firstRow = false;

            deleteData.push ( {...item} );

        }

        deleteItemStr += "]"
        
        setConfirmMsg ( t( "dialog.msg.confirm.failLotTerminate", { type: t(`tableName.${schema.name}`), instance: deleteItemStr } ) );
        setConfirmData ( deleteData );

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onApiSuccess = (result) => {

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (refresh) {

            refresh.refresh();

        }

    };

    const onApiFailure = (reason) => {

        if (onFailure) {

            onFailure ( t ( "error.deleteFailure", { reason: reason.response ? reason.response.data : reason } ))

        }

    };

    const onOk = ( requestData ) => {

        const url = "/api/" + schema.name + "/failLotTerminate";

        apiClient
            .post(url, requestData)
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    return (

        <>
            <CreateRecordButtonLayout id={id+"-failLotSplit-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"failLotSplit-dialog"}
                title={ schema ? t ( `dialog.title.${schema.name}.failLotTerminate` ) : "" }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>

    );

}

export default MomSysFailLotTerminateButton;

