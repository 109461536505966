import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function MomSysBackShipFailureButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.backShip")}</ActionButtonLayout>;

}

export default MomSysBackShipFailureButtonLayout;

