import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MachineDateCapacityManage ( props ) {

    return <BasicCrudScreenType id="VwMachineDateCapacity" table="MachineDateCapacity" view="VwMachineDateCapacity" noExcel />

}

export default MachineDateCapacityManage;

