import { useState } from 'react';
import { FlexGrid } from '@grapecity/wijmo.react.grid';

import "bootstrap/dist/css/bootstrap.min.css";
import "@grapecity/wijmo.styles/themes/wijmo.theme.material.css";
import "../layout/DataGrid.css";

import ColumnPickerDialog from '../dialog/ColumnPickerDialog';

function DataGrid ( props ) {

    const [grid, setGrid] = useState();
    const [, setColumnPicker] = useState();

    const {

        id,
        schema,
        gridRef,
        isReadOnly,
        autoGenerateColumns,
        allowSorting,
        allowPinning,
        showMarquee,
        selectionMode,
        columnGroups,

        onInitialized,
        onBeginningEdit,

        addColumnPicker,

        children,
        ...others

    } = props;

    const onGridInitialized = ( grid ) => {

        grid.showColumnPicker = () => {

            setColumnPicker ( ( columnPicker ) => {
                columnPicker.show ();
                return columnPicker;
            } );

        }

        setGrid ( grid );

        if ( onInitialized ) {

            onInitialized ( grid );

        }

    };

    const gridProps = {

        isReadOnly: ( isReadOnly === undefined ) ? true : isReadOnly,
        autoGenerateColumns: autoGenerateColumns === undefined ? false : autoGenerateColumns,
        allowPinning: allowPinning === undefined ?  "SingleColumn" : allowPinning,
        allowSorting: allowSorting === undefined ? "MultiColumn" : allowSorting,
        showMarquee: showMarquee === undefined ? false : showMarquee,
        selectionMode: selectionMode === undefined ? "ListBox" : selectionMode,

        initialized: onGridInitialized,

        ...others

    };

    const onColumnPickerInitialized = ( columnPicker ) => {

        setColumnPicker ( columnPicker );

    };

    return (

        <FlexGrid ref={gridRef} columnGroups={columnGroups} { ...gridProps } style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, padding: 0, margin: 0}}>
            <ColumnPickerDialog id={id+"columnPicker"} grid={grid} schema={schema} onInitialized={onColumnPickerInitialized} />
            { children }
        </FlexGrid>        

    );

}

export default DataGrid;
