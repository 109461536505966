import { useRecoilValue } from "recoil";
import authProfileState from "../../states/authProfileState";

import { useEffect, useState } from "react";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import { useTranslation } from "react-i18next";
import layoutConf from "../../config/layoutConf";

import getSchema from "../../lib/api/getSchema";

import SoDetail from "./SoDetail";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import { CellType } from '@grapecity/wijmo.grid';


function So (props) {

  const [grid, setGrid] = useState();

  const [soId, setSoId] = useState();

  const [soCost , setSoCost] = useState();

  const [height, setHeight] = useState(0);

  const [refresh, setRefresh] = useState();

  const [soRefresh, setSoRefresh] = useState();

  const [ approvalCtx, setApprovalCtx ] = useState ();
  
  const [ schema, setSchema ] = useState ();

  const { t } = useTranslation ();

  const [approvalStateId, setApprovalStateId] = useState();

  const [ data, setData ] = useState();

  const authProfile = useRecoilValue ( authProfileState );

  const handleResize = () => {

    setHeight(document.documentElement.offsetHeight - 96);

  };

  useEffect(() => {

    window.addEventListener("resize", handleResize);
    handleResize();

    let mounted = true;

    (async() =>{

      let schema = await getSchema("So");

      if ( mounted ) {
        
        setSchema(schema);

      }

    })();

    return ( () => {
            
        mounted = false;
        window.removeEventListener("resize", handleResize);
    
    } );

  },[]);
  useEffect(() => {

    (async () => {

      if (grid) {
        grid.selectionChanged.addHandler((grid, event) => {
          setSoId(grid.selectedItems[0] && grid.selectedItems[0].soId);
          setApprovalStateId(grid.selectedItems[0] && grid.selectedItems[0].approvalState );
          setSoCost(grid.selectedItems[0] && grid.selectedItems[0].cost);

          if (refresh) {
            
            refresh.refresh();
            
          }

        });
        
        grid.itemsSourceChanged.addHandler((grid, event) => {
          if (grid.selectedItems[0]) {
            setSoId(grid.selectedItems[0].soId);
            setApprovalStateId(grid.selectedItems[0].approvalState);
            setSoCost(grid.selectedItems[0].approvalState);

            if (refresh) {

              refresh.refresh();

            }
          }
        });
      }

    })();

  }, [grid, refresh]);



  useEffect ( () => {
        
    let approvalCtx = {

        approvalTypeId: 3,
        tableName: "So",
        statusColumn: "approvalStateId",
        schema: schema,
        onValidateApprovalItem: ( item ) => {

            if ( item.register !== authProfile.username ) {

                return [ false, t( "error.approvalRequesterNotMatch" ) ];
    
                
            }
    
            return [ true, "" ];
    
        },
        makeRequestItemDescription: ( item ) => {
            return `${t("approvalRequestMsg.msg.so", {soName : item.soName, soNumber : item.soNumber })}`; // Use Translation on Production Code

        }
    
    };

    setApprovalCtx ( approvalCtx );

}, [schema, authProfile , t] );

  const onInitialized = (grid) => {

    setGrid(grid);

  };


  const onApiInitialized = (api) => {

    setRefresh(api);

  };


  const onSoApiInitialized = (api) => {

    setSoRefresh(api);

  };

  const exportFormatItem = ( args ) => {

    let { panel, col, row, xlsxCell } = args;

    if ( panel.cellType === CellType.Cell ) {

        switch ( panel.columns[col].binding ) {

            case "approvalState":

                switch (data[row][panel.columns[col].binding]) {

                    case 1:

                        xlsxCell.value = t ("selectItems.approvalState.approvalPending")

                        break;

                    case 2:

                        xlsxCell.value = t ("selectItems.approvalState.partiallyApproved")

                        break;

                    case 3:

                        xlsxCell.value = t ("selectItems.approvalState.totallyApproved")

                        break;
                    
                    case 4:

                        xlsxCell.value = t ("selectItems.approvalState.approvalRejected")

                        break;

                    case 5:

                        xlsxCell.value = t ("selectItems.approvalState.approvalWithdrawn")

                        break;

                    case 6:

                        xlsxCell.value = t ("selectItems.approvalState.approvalCancelled")

                        break;

                    default:
                        break;
                }

                break;

            default:

                break;

        }

    }

}

const onLoadData = ( data ) => {

    setData ( data );

}



  return (
    <div style={{ height: height }}>

      <ReflexContainer orientation="horizontal">

        <ReflexElement flex={0.6}>

          <BasicCrudScreenType
            id="So"
            table="So"
            view="VwSo"
            onInitialized={onInitialized}
            onApiInitialized={onSoApiInitialized}
            approvalCtx={approvalCtx}
            onLoadData={onLoadData}
            exportFormatItem={exportFormatItem}
            disableDelete = {approvalStateId ? true : false}
            disableEdit = {approvalStateId ? true : false}
          />

        </ReflexElement>

        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

        <ReflexElement>
          {soId ?
           (
            <SoDetail
              id="SoDetail"
              soGrid = {grid}
              soId={soId}
              soCost = {soCost}
              onApiInitialized={onApiInitialized}
              soRefresh = {soRefresh}
              approvalStateId ={approvalStateId}
              exportFormatItem={() => {}}
            />
          ) 
          :
           (
            <></>
          )
          }
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}
export default So;
