import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// import "react-quill/dist/quill.snow.css";

import getSpecific from "../../lib/api/getSpecific";
import parse from 'html-react-parser';

function EguPopFullScreenLayout() {

    const params = useParams();

    const [content, setContent] = useState('');
    const [full, setFull] = useState(false);

    useEffect(() => {
        if (params && params.noticeNo) {

            (async () => {
                let result = await getSpecific(`EguPopNotice/${params.noticeNo}`);
                let content = { title: result.title, notice: result.notice };
                if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                    content.notice = result.notice.replaceAll("/files", "http://localhost:8080/files")

                }
                setContent(content)

            })()
        }
    }, [params])


    useEffect(() => {

        const id = setInterval(async () => {
            let result = await getSpecific(`EguPopNotice/${params.noticeNo}`);
            let content = { title: result.title, notice: result.notice };
            if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                content.notice = result.notice.replaceAll("/files", "http://localhost:8080/files")

            }
            setContent(content)

        }, 3 * 100000);
        return () => {

            clearInterval(id)
        }
    }, [params])


    const click = useCallback(() => {
        if (full) {
            window.document.exitFullscreen();
            setFull(false)
        } else {
            window.document.documentElement.requestFullscreen();
            setFull(true)
        }
        window.document.documentElement.removeEventListener('click', click)
    }, [full])

    useEffect(() => {

        (async () => {

            window.document.documentElement.addEventListener('click', click)

        })()

    }, [click])


    return (
        <>
            {content ?
                <div className="ql-snow">
                    <div className="ql-editor"
                        style={{ width: '100%', height: '100%', padding: '5% 10% 0% 10%', fontSize: '20px', overflow: 'hidden' }}
                    >
                        {parse(content.notice)}
                    </div>
                </div>
                : <></>
            }
        </>
    )
}


export default EguPopFullScreenLayout;