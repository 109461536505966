function getGaConf() {

    if ( process.env.NODE_ENV === 'production' ) {
    
        return ( {

            trackingId: "G-SPY9JE70VK"

        } );

    } else {
    
        return ( {

            trackingId: "G-4D7D0P677C"

        } );

    }

}

let gaConf = getGaConf();

module.exports = gaConf;
