import { useLocation, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import menuInfoState from "../../states/menuInfoState";

function MenuRedirector ( props ) {

    const menuInfo = useRecoilValue ( menuInfoState );

    const location = useLocation ();
    const history = useHistory ();

    for ( const menuItem of menuInfo.subMenuItems.slice().sort ( ( a, b ) => ( a.order - b.order )) ) {

        let topMenuPath = `/${menuItem.topMenuPath}`;
        let subMenuPath = `/${menuItem.menuPath}` 

        if ( topMenuPath === location.pathname || subMenuPath === location.pathname ) {

            history.push ( `/${menuItem.topMenuPath}/${menuItem.menuPath}` );
            return <></>

        }

        
    }

    return <></>;

}

export default MenuRedirector;

