import DialogHeader from "./DialogHeader";
import DialogCloseButton from "./DialogCloseButton";

function DialogHeaderWithClose ( props ) {

    const { children, instance, ...others } = props;

    return (
        <DialogHeader {...others} >
            { children }
            <DialogCloseButton instance={instance} />
        </DialogHeader>
    );

}

export default DialogHeaderWithClose;

