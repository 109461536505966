/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";

import Icon from "../../presentation/Icon";


// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridIconColumn ( props ) {

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            return (
                <>
                    <span>{ ctx.item[schema.name] }<Icon fontSize="small">{ ctx.item[schema.name] }</Icon></span>
                </>
            );


        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridIconColumn;

