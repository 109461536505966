import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysBusinessDetailCreateButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, onSuccess, createButtonName, modifyUrlCallback, customizeErrorMsgCallback, title, setInitialDataCallback, checkItemValidationCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );
            
        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ title ? title : schema ? t ( `dialog.title.${schema.name}.create` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback ={modifyUrlCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                checkItemValidationCallback={checkItemValidationCallback}
            />
        </>

    );
}

export default MomSysBusinessDetailCreateButton;

