import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

function AutoFormGroupLayout(props) {

    const { children, ...others } = props;

    const classes = useStyles();

    return (

        <div className={classes.root} {...others} >
            {children}
        </div>

    );
}

export default AutoFormGroupLayout;

