import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    }
}));

function TopMenuTitleItemLayout ( props ) {

    const { t } = useTranslation();
    const classes = useStyles();

    return (

        <ListItem className={classes.categoryHeader}>
            <ListItemText
                classes={{
                    primary: classes.categoryHeaderPrimary,
                }}
            >
                {t("menu." + props.menuItem.menuItemName)}
            </ListItemText>
        </ListItem>    

    );
    
}

export default TopMenuTitleItemLayout;
