import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import SupportButton from "../input/SupportButton";
import HelpButton from "../input/HelpButton";
import ProfileButton from "../input/ProfileButton";
import NotificationButton from "../input/NotificationButton";
import MessageButton from "../input/MessageButton";
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


import "./TopPaneLayout.css";
import OptionListLoader from "../functional/OptionListLoader";

const lightColor = "rgba(255, 255, 255, 0.7)";

const useStyles = makeStyles((theme) => ({
    primaryBar: {
        paddingLeft: 0
    },
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: "none",
        color: lightColor,
        "&:hover": {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    openIconButton: {
        position:'absolute',
        opacity: 100,
        marginLeft: 10,
        
        float: 'left',
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    openIconHide: {        
        opacity: 0,
        transition: theme.transitions.create(['opacity', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    sideArrowButton: {
        position:'absolute',
        left: -10,
        opacity: 100,
        
        transition: theme.transitions.create(['opacity', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    arrowIconHide: {
        opacity: 0,
        transition: theme.transitions.create(['opacity', 'left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${180}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    leftIconButton: {
        position:'absolute',
        width:22,
        height:22,
        color:'white',
        
        backgroundColor:'#B34EAD',
        opacity:'0.8',
        borderRadius:'50%',
        left:'-2px',
    },
    menuGrid: {
        marginLeft: 20,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }, 
    shiftMenuGrid: {
        marginLeft: -10,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
    
}));

function TopPaneLayout(props) {

    const classes = useStyles();

    const { children } = props;

    const [ useMessage, setUseMessage ] = useState(false);

    const onApprovalOptionLoaded = ( optionMap ) => {
        
        if ( optionMap && optionMap["useMessage"] ) {

            if ( optionMap["useMessage"].toLowerCase() === "true" ) {

                setUseMessage ( true );
    
            } else {
    
                setUseMessage ( false );
    
            }
    
        } else {

            setUseMessage ( true );

        }

    }

    return (
        <>
            <OptionListLoader optionPath="approval" onLoaded={onApprovalOptionLoaded} />
            <AppBar color="primary" position="fixed" elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.sideMenuOpen,
                })}
            >
                <Toolbar className={classes.primaryBar} style={{marginLeft: "0px", height: "48px"}}>
                    <IconButton
                        color="inherit"
                        
                        aria-label="open drawer"
                        onClick={props.handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.openIconButton, {
                            [classes.openIconHide]: props.sideMenuOpen,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    
                    <IconButton 
                        className={clsx(classes.sideArrowButton, {
                            [classes.arrowIconHide]: !props.sideMenuOpen,
                        })}
                        onClick={props.handleDrawerClose}
                    >
                         <ChevronLeftIcon className={classes.leftIconButton} />
                    </IconButton>
                    
                    <Grid container nowrap spacing={1} alignItems="center" 
                        className={clsx(classes.menuGrid, {
                            [classes.shiftMenuGrid]: props.sideMenuOpen,
                        })}
                    >
                        <Grid item xs>
                            {children}
                        </Grid>
                        <Grid item>
                            <span></span>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item>
                                <SupportButton />
                            </Grid>
                            <Grid item>
                                <HelpButton />
                            </Grid>
                            <Grid item>
                            {
                                useMessage
                                ?
                                <MessageButton />
                                :
                                <NotificationButton /> 
                            }
                            </Grid>
                            <Grid item>
                                <ProfileButton />
                            </Grid>
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );

}

export default TopPaneLayout;

