/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import localizationConf from "../../config/localizationConf";
import authProfileState from "../../states/authProfileState";
import menuInfoState from "../../states/menuInfoState";
import accessControlListState from "../../states/accessControlListState";
import rootRedirectPathState from "../../states/rootRedirectPathState";
import topMenuSelectionState from "../../states/topMenuSelectionState";
import subMenuSelectionState from "../../states/subMenuSelectionState";
import tenantState from "../../states/tenantState";
import tenantStatusState from "../../states/tenantStatusState";

import getSpecific from "../../lib/api/getSpecific";

import logout from "../../lib/auth/logout";
import getMenuInfo from "../../lib/api/getMenuInfo";
import getAccessControList from "../../lib/api/getAccessControlList";

import findRootRedirectPath from "../../lib/utils/findRootRedirectPath";
import makeSelectionByPath from "../../lib/utils/makeSelectionByPath";

import Login from "../auth/Login";

import { useTranslation } from "react-i18next";


function LoginScreen ( props ) {

    const { tenant } = props;

    const [ , setAuthProfile ] = useRecoilState(authProfileState);
    const [ menuInfo, setMenuInfo ] = useRecoilState(menuInfoState);
    const setRootRedirectPath = useSetRecoilState(rootRedirectPathState);
    const [ , setTopMenuSelection ] = useRecoilState(topMenuSelectionState);
    const setSubMenuSelection = useSetRecoilState(subMenuSelectionState);
    const setAccessControlList = useSetRecoilState(accessControlListState);
    const setTenant = useSetRecoilState(tenantState);
    const setTenantStatus = useSetRecoilState(tenantStatusState);

    let location = useLocation ();
    const history = useHistory ();

    const { i18n } = useTranslation();

    const [ , setCookie ] = useCookies( [localizationConf.cookieName] );

    useEffect(() => {

        if ( ! i18n.language ) {

            i18n.changeLanguage("kr");
            setCookie(localizationConf.cookieName, "kr", {path: "/"});

        } else {

            setCookie(localizationConf.cookieName, i18n.language, {path: "/"});

        };

        let selection = makeSelectionByPath ( menuInfo, location.pathname );

        setTopMenuSelection ( selection.top );
        setSubMenuSelection ( selection.sub );

    }, [menuInfo, location]);

    const onLogin = ( authProfile ) => {
                
        setAuthProfile ( authProfile );
        setTenant ( authProfile.tenant );
        setTenantStatus ( authProfile.tenantStatus );

        getMenuInfo( authProfile.menuSetId ).then((menuInfo) => {

            setMenuInfo ( menuInfo );
            let rootRedirectPath = findRootRedirectPath( null, menuInfo );
            setRootRedirectPath ( rootRedirectPath );

            let pathUnits = location.pathname.split ( "/" );
            let path = pathUnits[pathUnits.length - 1];

            if ( ! menuInfo.subMenuItems.find ( ( menuItem ) => menuItem.menuPath === path ) ) {

                history.push ( rootRedirectPath );

            }

        });

        getAccessControList ( authProfile.roleId ).then((accessControlList) => {

            setAccessControlList ( accessControlList );

        }).catch (( reason ) => {

            console.error ( "Get Access Control List Failed : ", reason );

        });

    }
    
    const onLogOut = () => {

        let newAuthProfile = { loggedIn: false };

        setAuthProfile ( ( profile ) => {
                        
            logout();
            return newAuthProfile;

        } );

    }

    const onLoginFailed = ( reason ) => {

        console.error ( reason );
        alert ( "Login Failed!!!" );

    }
    
    const onUpdateProfile = ( newAuthProfile ) => {

        ( async () => {

            let resources = await getSpecific ( `TranslationData` );

            for ( const lang in resources ) {
    
                for ( const namespace in resources[lang] ) {
    
                    i18n.removeResourceBundle ( lang, namespace );
                    i18n.addResourceBundle ( lang, namespace, resources[lang][namespace], true, true );
    
                }
                
            }
    
        } ) ();


        setAuthProfile ( newAuthProfile );
        setTenant ( newAuthProfile.tenant );
        setTenantStatus ( newAuthProfile.tenantStatus );

        getMenuInfo( newAuthProfile.menuSetId ).then((menuInfo) => {
            
            setMenuInfo ( menuInfo );
            let rootRedirectPath = findRootRedirectPath( null, menuInfo );
            setRootRedirectPath ( rootRedirectPath );

        });

        getAccessControList ( newAuthProfile.roleId ).then((accessControlList) => {

            setAccessControlList ( accessControlList );

        }).catch (( reason ) => {

            console.error ( "Get Access Control List Failed : ", reason );

        });

    }

    return (

        <Login tenant={tenant} onLogin={onLogin} onLogOut={onLogOut} onLoginFailed={onLoginFailed} onUpdateProfile={onUpdateProfile} />

    );

}

export default LoginScreen;