import apiClient from "../common/apiClient";

async function getNoAuthList( tableName ) {

    let response = await apiClient.get(`/noAuth/${tableName}`);

    return response.data;

}

export default getNoAuthList;