import apiClient from "../common/apiClient";

async function post() {

    let path = "";

    for ( let argIdx = 0; argIdx < ( arguments.length - 1 ); argIdx++ ) {

        path += "/" + arguments[argIdx];
        
    }

    let response = await apiClient.post(`/api${path}`, arguments[arguments.length - 1]);

    return response.data;

}

export default post;