import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";
import authProfileState from "../../states/authProfileState";

import getSchema from "../../lib/api/getSchema";

import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import ApprovalButton from "../input/ApprovalButton";
import EguIngredientRegistrationCaptionCreateButton from "../input/EguIngredientRegistrationCaptionCreateButton";

function EguIngredientRegistrationCaption(props) {

    const { getData } = props;

    const [tableSchema, setTableSchema] = useState();

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [approvalCtx, setApprovalCtx] = useState();
    const [isEmpty, setIsEmpty] = useState();

    // const [position, setPosition] = useState({ row: undefined, col: undefined });

    const [onSelectMst, setOnSelectMst] = useState();


    const [, setNotification] = useRecoilState(notificationState);

    const authProfile = useRecoilValue(authProfileState);

    const { t } = useTranslation();

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);

                    }
                }, 10));

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);
                    }
                });
            }
        })();

    }, [grid]);

    useEffect(() => {

        let approvalCtx = {
            approvalTypeId: 21,
            tableName: "EguIngredientRegistrationCaption",
            statusColumn: "approvalStateId",
            schema: tableSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.eguIngredientRegistrationCaption", {
                    captionId: item.captionId,
                })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);

    }, [tableSchema, authProfile, t]);



    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguIngredientRegistrationCaption");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        })

    }, []);


    useEffect(() => {

        if (getData) {

            getData(onSelectMst)

        }

    }, [onSelectMst, getData])

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)
        if (data.length < 1) {

            setOnSelectMst((onSelectMst) => {

                let newSelect = {
                    ...onSelectMst,
                    captionId: null
                }
                return newSelect;
            })
        }
    };



    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if (api) {

            api.refresh();

        }
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {

        notifySuccess(msg);
    };

    const doHandleBackendErrorMsg = (reason) => {

        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;

    }

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <EguIngredientRegistrationCaptionCreateButton
                            id="EguIngredientRegistrationCaption"
                            schema={tableSchema}
                            color="primary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            refresh={api}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id="EguIngredientRegistrationCaption"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguIngredientRegistrationCaption"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="APPROVAL"
                    >
                        <ApprovalButton
                            id="EguIngredientRegistrationCaption"
                            grid={grid}
                            refresh={api}
                            approvalCtx={approvalCtx}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </AccessControl>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id="EguIngredientRegistrationCaption"
                            grid={grid}
                            view="EguVwIngredientRegistrationCaption"
                            color="primary"
                            disabled={isEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <>
            <BasicCrudScreenType
                id="EguIngredientRegistrationCaption"
                table="EguIngredientRegistrationCaption"
                view="EguVwIngredientRegistrationCaption"
                headerTitle={t("term.confirmProduct")}
                noCreate
                noEdit
                noDelete
                noExcel
                onLoadData={onLoadData}
                onInitialized={onInitialized}
                onApiInitialized={onApiInitialized}
                onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                exportFormatItem={() => { }}
            />
        </>
    );
}

export default EguIngredientRegistrationCaption;
