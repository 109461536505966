import { Typography } from '@material-ui/core';
import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import { useTranslation } from "react-i18next";

function Message ( props ) {

    const { t } = useTranslation();
    const { msg } = props;

    return (

        <div>
            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                    <span
                        color="inherit"
                        align="center"
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            marginTop: "8px"
                        }}>
                        { t("term.message") }
                    </span>

                </BasicCrudActionsLayout>
                
            </BasicCrudScreenHeaderLayout>

            <a href={ msg ? `/${msg.topMenuPath}/${msg.menuPath}` : "" } style={{textDecoration: "none"}}><Typography variant="body1" style={{ margin: "16px"}} >{ msg ? msg.message : "" }</Typography></a>
            
        </div>

    )
}

export default Message;
