import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function CustomButtonLayout ( props ) {

    const { buttonName } = props;

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t(buttonName)}</ActionButtonLayout>;

}

export default CustomButtonLayout;

