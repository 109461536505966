import DialogCloseButtonLayout from "../layout/DialogCloseButtonLayout";

function DialogCloseButton ( props ) {

    const { instance, onClose } = props;

    const onClick = ( instance ) => {

        instance.hide ();

        if ( onClose ) {

            onClose ();

        }

    };

    return <DialogCloseButtonLayout onClick={()=>onClick(instance)} {...props} />;

}

export default DialogCloseButton;

