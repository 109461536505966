import { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import ColorPickerPopUp from "./ColorPickerPopUp";

function ColorBox ( props ) {

    const { color } = props;

    const style = {
        backgroundColor: color,
        border: "1px solid rgba(0,0,0,0.6)",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
    };

    const innerCircle = {
        borderRadius: "50%",
        border: "1px solid rgba(255,255,255,0.6)",
        width: "18px",
        height: "18px",
    }

    return <div style={style}><div style={innerCircle}/></div>

}

function ColorPickerButton ( props ) {

    const { value, onChange } = props;

    const [ color, setColor ] = useState ( value || "#ff0000" );
    const [ anchor, setAnchor ] = useState ();
    const [ open, setOpen ] = useState ();

    useEffect ( () => {

        setColor ( value || "#ff0000" );

    }, [value] )

    const onOpenColorPicker = ( event ) => {


        setAnchor ( event.currentTarget );
        setOpen ( true );

    };

    const onCloseColorPicker = () => {

        setOpen ( false );
        setAnchor ( null );
    }

    return (
        <>
            <IconButton onClick={onOpenColorPicker} {...props}>
                <ColorBox color={color} />
            </IconButton>
            <ColorPickerPopUp open={open} anchorEl={anchor} onClose={onCloseColorPicker} onChange={onChange} value={color} />
        </>
    );

};

export default ColorPickerButton;