import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { cloneDeep } from "lodash";

import authProfileState from "../../states/authProfileState";
import defineConf from "../../config/defineConf";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function PopOutSourcingMaterialReturnDoInputButton(props) {
    const { id, schema, onSelectMst, refreshFtn, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;

    const { t } = useTranslation();

    const authProfile = useRecoilValue(authProfileState);

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (onSelectMst) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    outSourcingId: onSelectMst.outSourcingId,
                    outSourcingDetailId: onSelectMst.outSourcingDetailId,

                    materialCode: onSelectMst.materialCode,
                    materialCodeVer: onSelectMst.materialCodeVer,
                    materialName: onSelectMst.materialName,
                    materialTypeName: onSelectMst.materialTypeName,

                    consumeLotNo: onSelectMst.consumeLotNo,

                    outSourcingMaterialQty: onSelectMst.outSourcingMaterialQty,
                    outSourcingShipTotalQty: onSelectMst.outSourcingShipTotalQty,
                    outSourcingShipQty: onSelectMst.outSourcingShipQty,
                };

                return newFormData;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst]);

    const onClose = () => {
        setOpen(false);
    };

    const onInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refreshFtn) {
            refreshFtn();
        }
    };

    return (
        <React.Fragment>
            <ActionButtonLayout id={id + "-input-button"} onClick={onOpen} {...others}>
                {t("buttonName.input")}
            </ActionButtonLayout>
            <AutoFormDialog
                id={id + "-input-dialog"}
                mode="create" // Note!!
                formId={id + "-input-form"}
                title={t(`dialog.title.PopOutSourcingMaterialReturn`)}
                schema={schema}
                modifyUrlCallback={(url) => `/api/PopOutSourcingMaterialReturn/doInput`}
                initialData={formData}
                setInitialDataCallback={(newFormData) => {
                    for (const item in formData) {
                        newFormData[item] = formData[item];
                    }
                }}
                open={open}
                onClose={onClose}
                onSuccess={onInputSuccess}
                onFailure={onFailure}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </React.Fragment>
    );
}

export default PopOutSourcingMaterialReturnDoInputButton;
