import { useEffect, useState } from "react";
import { Divider } from "@mui/material";

import apiClient from "../../lib/common/apiClient";
import parse from "html-react-parser";

function MomSysPopNoticeDisplay(props) {
    const { orderBuyerDetailId, processCode, onSetContent, refreshToggle } = props;

    const [content, setContent] = useState("");

    useEffect(() => {
        if (orderBuyerDetailId && processCode) {
            (async () => {
                apiClient
                    .get(`/api/SimpleHtmlEditor/MomSysPopNotice/${orderBuyerDetailId}-${processCode}`)
                    .then((result) => {
                        let content = { title: result.data.title, html: result.data.html };
                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            content.html = result.data.html.replaceAll("/files", "http://localhost:8080/files")

                        }
                        setContent(content);

                        if (onSetContent) {
                            onSetContent(content);
                        }
                    })
                    .catch((reason) => {
                        setContent(null);

                        if (onSetContent) {
                            onSetContent(null);
                        }
                    });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBuyerDetailId, processCode, refreshToggle]);

    return (
        <>
            {content ? (
                <div className="ql-snow">
                    <div
                        className="ql-editor"
                        style={{
                            width: "100%",
                            height: "100%",
                            padding: "1% 1% 1% 1%",
                            fontSize: "30px",
                            overflow: "hidden",
                        }}
                    >
                        {parse(content.title ?? "")}
                    </div>
                    <Divider></Divider>
                    <div
                        className="ql-editor"
                        style={{
                            width: "100%",
                            height: "100%",
                            padding: "1% 1% 1% 1%",
                            fontSize: "20px",
                            overflow: "hidden",
                        }}
                    >
                        {parse(content.html ?? "")}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default MomSysPopNoticeDisplay;
