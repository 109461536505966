/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";

// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridAvatarPhotoColumn ( props ) {

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            let url = ctx.item[schema.name];

            if ( schema.maxFiles !== 1 ) {

                let urlList = url?JSON.parse ( url ):[];

                url = urlList && urlList[0];

            }

            return (
                <>
                    <span>{url?<Avatar src={( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' ) ? url : "http://localhost:8080" + url} style={{marginLeft: "auto", marginRight: "auto", height: "22px", width: "22px"}}/>:<></>}</span>
                </>
            );


        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridAvatarPhotoColumn;

