import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysMaterialByCompanyExcelUploadErrorDet(props) {
    const { id, selectMstGrid } = props;
    const [api, setApi] = useState();
    const [, setGrid] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMstGrid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onModifyUrl = (url) => {
        return `${url}/${selectMstGrid.companyCode}`;
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="MomSysMaterialByCompany"
                view="MomSysVwMaterialByCompanyExcelUploadErrorDet"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                onModifyViewUrl={(url) => onModifyUrl(url)}
                headerTitle={t("term.standard")}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default MomSysMaterialByCompanyExcelUploadErrorDet;
