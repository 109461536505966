import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function ApprovalPathDetailManage ( props ) {

    return (

        <BasicCrudScreenType id="ApprovalPathNode" table="ApprovalPathNode" view="VwApprovalPathNode" />

    );


}

export default ApprovalPathDetailManage;
