/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { Grid, TextField, InputAdornment, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import getSchema from "../../lib/api/getSchema";
import EguItemExcelUploadButton from "../input/EguItemExcelUploadButton";
import EguItemExcelFormDownloadButton from "../input/EguItemExcelFormDownloadButton";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import { cloneDeep } from "lodash";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import ExcelUploadSuccess from './ExcelUploadSuccess';
import ExcelUploadError from './ExcelUploadError';

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    }
}));

function EguItemExcelUpload(props) {
    const classes = useStyle();

    const [schema, setSchema] = useState();
    const [modifiedSchema, setModefiedSchema] = useState();
    const [tab, setTab] = useState("successView");
    const [api, setApi] = useState(null);
    const [, setSearch] = useState();
    const [, setSearchVal] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const {t} = useTranslation();

    useEffect(() => {
        let mounted = true;

        async function modifiedSchemaSetting() {
            let schema = await getSchema("EguMstMaterial");
            if(mounted) {
                let modifiedSchema = cloneDeep(schema);
                modifiedSchema.columns.forEach(column => columnSetting(column));
                setSchema(schema);
                setModefiedSchema(modifiedSchema);
            }
        }

        function columnSetting(column) {
            if(column.key === "PRI") {
                // 기본키 값이 없으면 안되서 필요없는 기본키값 추가
                column.defaultValue = "pri";
            }
            if(column.name !== "excelFile") {
                column.hideInForm = true;
                column.required = false;
            }
        }
        modifiedSchemaSetting();

        return(() => {
            mounted = false;
        });
    }, []);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };
    
    const onTabChanged = (tab) => {
        setTab(tab);
    };
    
    const onSearchInitialized = (search) => {
        setSearch(search);
        setSearchVal((searchVal) => {
            if(searchVal) {
                search.applySearch(searchVal);
            }
            return searchVal;
        } );
    }

    const onChangeSearch = (e) => {
        setSearch((search) => {
            if(search) {
                setSearchVal(e.target.value);
                search.applySearch(e.target.value);
            }
            return search;
        });
    };

    return (
        <BasicTabScreenType
            id="itemExcelUpload"
            tabs={["successView", "errorView"]}
            tabLabels={["엑셀 업로드 성공", "엑셀 업로드 실패"]}
            tabsStyle={{
                left: 0,
                margin: "0px",
                padding:"0px",
                height: "48px",
                width: "450px"
            }}
            onTabChanged={onTabChanged}
            value={tab}
            rightGridItems={
                [
                    <Grid item>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="GET">
                            <EguItemExcelFormDownloadButton
                                id="EguItemExcelFormDownload"
                                schema={schema}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={modifiedSchema && modifiedSchema.name} accessMethod="POST">
                            <EguItemExcelUploadButton
                                id="EguItemExcelUpload"
                                createButtonName="excelUpload"
                                schema={modifiedSchema}
                                refresh={api}
                                onSuccess={onSuccess}
                                modifyUrlCallback={(url) => `${url}?excelUpload`}
                            />
                        </AccessControl>
                    </Grid>,
                    <Grid item>
                        <TextField
                            id={"material-excel-upload-search"}
                            className={classes.root}
                            placeholder={t("common.search")}
                            onChange={onChangeSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>                    
                                )
                            }}
                            style={{
                                bottom: "2px",
                                width: "120px"                        
                            }}
                        />
                    </Grid>
                ]
            }
        >
            <EguItemExcelUploadView
                viewName={tab}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                api={api}
            />
        </BasicTabScreenType>
    );
}

function EguItemExcelUploadView(props) {
    const { viewName, onSearchInitialized, onApiInitialized } = props;
    const excelUploadItemViewName = "EguVwItemExcelUploadSuccess";
    const excelUploadItemErrorViewName = "EguVwItemExcelUploadError";

    switch(viewName) {
        case "successView":
            return <ExcelUploadSuccess 
                id='EguItemExcelUploadSuccess'
                table={excelUploadItemViewName}
                view={excelUploadItemViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                fixedOrder={[{column: "createdAt", order: "DESC"}]}
            />

        case "errorView":
            return <ExcelUploadError
                id='EguItemExcelUploadError'
                table={excelUploadItemErrorViewName}
                view={excelUploadItemErrorViewName}
                onSearchInitialized={onSearchInitialized}
                onApiInitialized={onApiInitialized}
                fixedOrder={[{column: "createdAt", order: "DESC"}]}
            />

        default:
            return <></>;
    }
}

export default EguItemExcelUpload;
