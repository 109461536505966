import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { cloneDeep, debounce } from "lodash";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import defineConf from "../../config/defineConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import layoutConf from "../../config/layoutConf";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function PsmMaterialListRegisterDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState(); // dialog instance
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [psmMaterialTargetGrid, setPsmMaterialTargetGrid] = useState();
    const [psmMaterialTargetSelect, setPsmMaterialTargetSelected] = useState();
    const [, setPsmMaterialTargetInstance] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    if (schema && !schema.columnMap) {
        let columnMap = {};

        for (const column of schema.columns) {
            columnMap[column.name] = column;
        }

        schema.columnMap = columnMap;
    }

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (psmMaterialTargetGrid && mounted) {
                psmMaterialTargetGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialTargetSelected(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialTargetSelected();
                        }
                    }, 10)
                );

                psmMaterialTargetGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialTargetSelected(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialTargetSelected();
                        }
                    }, 10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [psmMaterialTargetGrid]);

    useEffect(() => {
        if (psmMaterialTargetSelect) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    materialCode: psmMaterialTargetSelect.materialCode,
                    materialCodeVer: psmMaterialTargetSelect.materialCodeVer,
                    materialTypeName: psmMaterialTargetSelect.materialTypeName,
                };

                for (const column of schema.columns) {
                    if (column?.defaultValue) {
                        newFormData[column.name] = column.defaultValue;
                    }
                }

                setOkButtonDisabled(false);

                return newFormData;
            });
        }
    }, [psmMaterialTargetSelect, schema]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                instance.hide();

                if (onSuccess) {
                    setTimeout(() => onSuccess(result.data), 0);
                }
            } else {
                onNotifySuccess(result.data);

                if (onSuccess) {
                    setTimeout(() => onSuccess(result.data), 0);
                }
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("edit", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url;

        url = "/api/PsmMaterialList";

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData(null);

        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setTimeout(() => {
            setOkButtonDisabled(false);

            setFormData(formData);
        }, 0);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData(null);

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.PsmMaterialList.register")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.65}>
                        <BasicCrudScreenType
                            id="PsmVwMaterialTarget"
                            embededInOtherScreenType
                            view="PsmVwMaterialTarget"
                            headerTitle={t("term.psmMaterialTarget")}
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setPsmMaterialTargetGrid(grid);
                            }}
                            onApiInitialized={(instance) => {
                                setPsmMaterialTargetInstance(instance);
                            }}
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                    <ReflexElement flex={0.35}>
                        <div style={{ padding: "16px" }}>
                            <AutoForm
                                id="psm-material-register-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                //labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PsmMaterialListRegisterDialog;
