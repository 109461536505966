import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import { cloneDeep } from "lodash";
import getSpecific from "../../lib/api/getSpecific";

function EguLabDataSheetCreateButton(props) {

    const { t } = useTranslation();

    const { id, schema, refresh, onSelectMst, onSuccess, createButtonName, customizeErrorMsgCallback, ...others } = props;

    const [no, setNo] = useState();
    const [open, setOpen] = useState(false);
    const [initSchema, setInitSchema] = useState(schema);

    const getLabDataSheet = useCallback(async () => {

        let result = await getSpecific('eguLabDataSheet', onSelectMst?.longLabNo)

        if (result?.data.length === 0) {

            setNo(1)

        } else {

            setNo(result?.data[result?.data.length - 1].experimentRound + 1)

        }

    }, [onSelectMst?.longLabNo])

    useEffect(() => {

        getLabDataSheet();

    }, [getLabDataSheet])

    useEffect(() => {

        setInitSchema((schema) => {

            let modifySchema =
                cloneDeep(schema);// we need change initFormData when mode is 'create' 

            if (modifySchema) {
                for (const column of modifySchema.columns) {
                    switch (column.name) {
                        case "labNo":
                            column.defaultValue = onSelectMst?.longLabNo;
                            column.required = true;

                            break;
                        case "reviewRound":
                            column.defaultValue = onSelectMst?.reviewCount;
                            column.required = true;

                            break;
                        case "experimentRound":
                            column.defaultValue = no;
                            column.required = true;

                            break;
                        default:
                            break;
                    }
                }

            }

            let newSchema = {
                ...modifySchema,
            }

            return newSchema
        })
    }, [onSelectMst, schema, no])

    const onOpen = () => {

        getLabDataSheet();
        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        getLabDataSheet();

        refresh.refresh();

    };

    const checkShowCallback = (formData, column) => {
        switch (column.name) {
            case "reviewState":
            case "reviewContent":
                return false
            default:
                break;
        }
        return true
    }

    // const modifyFormDataCallback = (newFormData, name, value) => {
    //     console.log(newFormData, name, value)
    // }

    return (

        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={initSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkShowCallback={checkShowCallback}
            // modifyFormDataCallback={modifyFormDataCallback}
            />
        </>

    );
}

export default EguLabDataSheetCreateButton;

