import { useRecoilValue } from "recoil";

import topMenuSelectionState from "../../../states/topMenuSelectionState";

import SubMenuLayout from "../layout/SubMenuLayout";
import SubMenuItem from "./SubMenuItem";

function SubMenu ( props ) {

    const topMenuSelection = useRecoilValue(topMenuSelectionState);

    return (

        <SubMenuLayout>

            {
                props.menuItems
                ?
                    props.menuItems.filter(

                        // filter by top menu selection

                        ( menuItem ) => menuItem.topMenuItemId === topMenuSelection

                    ).slice().sort( 

                        // order by menu order
                        
                        ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order

                    ).map( ( menuItem ) => {

                        return <SubMenuItem key={menuItem.subMenuItemId} menuItem={menuItem} />

                    } )
                :
                    <></>

            }

        </SubMenuLayout>

    )

}

export default SubMenu;
