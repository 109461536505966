/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysLotTransHistory ( props ) {

    const [, setGrid] = useState();


    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        setGrid(grid);
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwSgtLotTransLog"
            table="MomSysVwSgtLotTransLog"
            view="MomSysVwSgtLotTransLog"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
        />                                

    );

}

export default MomSysLotTransHistory;
