/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import uiConf from "../../config/uiConf";
import authProfileState from "../../states/authProfileState";
import getList from "../../lib/api/getList";

import NotificationList from "./NotificationList";

const useStyles = makeStyles ( () => ( {
    iconButton: {
        padding: 4,
    },
} ) );

function NotificationButton ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    const [ , setTimer ] = useState ();
    const [ anchor, setAnchor ] = useState ();
    const [ openList, setOpenList ] = useState (false);

    const authProfile = useRecoilValue( authProfileState );

    const [ notifications, setNotifications  ] = useState([]);

    useEffect ( () => {

        let mounted = true;

        const getNotifications = async () => {

            let result = await getList ( `VwApprovalNotification/${authProfile.username}` );
    
            if ( mounted ) {
                
                setNotifications ( result.data );
    
                setTimer ( ( timer ) => {
        
                    if ( timer ) {
        
                        clearTimeout ( timer );
        
                    }
                    
                    return ( setTimeout ( () => {
        
                        getNotifications ();
        
                    }, uiConf.notificationFetchDuration ) );
        
                } );
    
            }
    
        }
    
        getNotifications ();

        return ( () => {
            mounted = false;
        } );
    
    }, [] );

    const onOpenList = ( event ) => {

        setNotifications ( ( notifications ) => {

            if ( notifications.length > 0 ) {

                setAnchor ( event.currentTarget );
                setOpenList ( true );
    
            }

            return notifications;
    
        } );

    };

    const onCloseList = () => {

        setOpenList ( false );
        setAnchor ( null );
    }

    return (

        <>

            <Tooltip title={ t("toolTips.alert", { msg: t("toolTips.msg.alertCount", { count: notifications.length } ) } ) }>
                <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={onOpenList}
                >
                    <Badge
                        color="error"
                        badgeContent={ notifications.length }
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <NotificationList id="notification-list" open={openList} anchorEl={anchor} notifications={notifications} onClose={onCloseList} />

        </>

    );

}

export default NotificationButton;


