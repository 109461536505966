import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";


import apiClient from "../../lib/common/apiClient";



import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";



const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysCustomerSupplyOrderBuyDetailCreateDialog(props) {
    const classes = useStyles();

    const { t } = useTranslation();

    const {
        id,
        mode,
        schema,
        onSelectMst,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,
        eguUglOrderBuyerDetailSelect,

        ...others
    } = props;

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [momSysVwPreShipmentDetailGrid, setMomSysVwPreShipmentDetailGrid] = useState();
    const [momSysVwPreShipmentDetailApi, setMomSysVwPreShipmentDetailApi] = useState();
    const [, setMomSysVwPreShipmentDetailSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysVwPreShipmentDetailGrid) {
                momSysVwPreShipmentDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwPreShipmentDetailSelect(grid.selectedItems[0]);
                            setOkButtonDisabled(false);
                        } else {
                            setMomSysVwPreShipmentDetailSelect();
                            setOkButtonDisabled(true);
                        }
                    }, 10)
                );

                momSysVwPreShipmentDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwPreShipmentDetailSelect(grid.selectedItems[0]);
                            setOkButtonDisabled(false);
                        } else {
                            setMomSysVwPreShipmentDetailSelect();
                            setOkButtonDisabled(true);
                        }
                    }, 10)
                );
            }
        })();
    }, [momSysVwPreShipmentDetailGrid]);


    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    const onCancel = () => {
        setOkButtonDisabled(true);

        dialogInstance.hide();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };



    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            } else {
                if (momSysVwPreShipmentDetailApi) {
                    momSysVwPreShipmentDetailApi.refresh();
                }
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };


    const onOk = (schema, formData) => {

        const url = `/api/MomSysCustomerSupplyOrderBuyDetail`;

        const form = new FormData();

        const json = momSysVwPreShipmentDetailGrid.itemsSource.items;
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onModifyViewSchema = (schema) => {
        if (schema) {

            for (const column of schema.columns) {

                switch (column.name) {

                    case "customerSupplyCost":

                        column.onChange = (ctx, customerSupply) => {
                            setMomSysVwPreShipmentDetailGrid((grid) => {
                                if (grid) {
                                    ctx.item.customerSupplyCost = customerSupply
                                    grid.refresh(false)
                                }
                                return grid
                            })
                        };

                        break;
                    case "customerSupply":

                        column.onChange = (ctx, customerSupply) => {
                            setMomSysVwPreShipmentDetailGrid((grid) => {
                                if (grid) {
                                    ctx.item.customerSupply = customerSupply
                                    grid.refresh(false)
                                }
                                return grid
                            })
                        };

                        break;

                    default:

                        break;

                }

            }

        }

    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="md"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={1} data-cy="Material">
                            <BasicCrudScreenType
                                id={formId}
                                embededInOtherScreenType
                                view="MomSysVwCustomerSupplyOrderBuyDetailMaterial"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={(grid) => {
                                    grid.selectionMode = "Row";
                                    grid.childItemsPath = "children";
                                    setMomSysVwPreShipmentDetailGrid(grid);
                                }}
                                onApiInitialized={(api) => {
                                    setMomSysVwPreShipmentDetailApi(api);
                                }}
                                onModifyViewUrl={(url) => `/api/VwPrePopCustomerSupplyInput/${eguUglOrderBuyerDetailSelect?.orderBuyerId}/${eguUglOrderBuyerDetailSelect?.materialCode}/${eguUglOrderBuyerDetailSelect?.materialCodeVer}`}
                                onModifyViewSchema={onModifyViewSchema}
                                noFilter
                                noSearch
                                noPagination
                            />
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement>

                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysCustomerSupplyOrderBuyDetailCreateDialog;
