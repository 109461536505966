/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguMstMaterialCreateDialog from "../dialogTypes/EguMstMaterialCreateDialog";

import defineConf from "../../config/defineConf";

function EguMstMaterialCreateButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, selectMstGrid, onSuccess, ...others} = props;
    const [open, setOpen] = useState(false);
    const [tableSchema, setTableSchema] = useState();

    useEffect (() => {
        function tableSchemaSetting() {
            setTableSchema(schema);
        }
        
        tableSchemaSetting();
    }, [schema]);
    
    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };


    const checkShowCallback = (formData, column) => {

        if( parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.HALF_FINISHED_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.RAW_MATERIAL ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.WIP_PRODUCT ||
            parseInt(formData.materialTypeCode) === defineConf.materialTypeCode.SUBSIDIARY_MATERIAL ) {

            formData.mediumUnitQty = null;
            formData.smallUnitQty = null;
            
            switch(column.name) {

                case "mediumUnitQty" : 
                case "smallUnitQty" : 

                    return false;

                default :
                    return true;
            }

        } else {

            switch(column.name) {

                default :
                return true;
            }

        }


    }

    return (
        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <EguMstMaterialCreateDialog
                id={id+"append-dialog"}
                title={t(`dialog.title.${schema.name}.create`)}
                schema={tableSchema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                checkShowCallback = {checkShowCallback}
            />
        </>
    );
}

export default EguMstMaterialCreateButton;

