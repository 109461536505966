import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysBopHistory ( props ) {

    return <BasicCrudScreenType
        id="MomSysBopHistory" view="MomSysVwBopHistory"
        noCreate
        noEdit
        noDelete
        noExcel
    />

}

export default MomSysBopHistory;

