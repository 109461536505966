/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";

import "../layout/DataGridApprovalStateColumn.css";
import ApprovalStatusDialog from "../../dialogTypes/ApprovalStatusDialog";


function DataGridApprovalStateColumn ( props ) {

    const { schema, ...others } = props;

    const [ , setDialog ] = useState ();
    const [ approvalStateId, setApprovalStateId ] = useState ();
    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    const { t } = useTranslation();

    const onClick = ( ctx ) => {

        setDialog ( ( dialog ) => {

            if ( dialog ) {


                setTimeout ( () => setApprovalStateId ( undefined ), 0 ); // Approval State Id 변경 없이 상태만 변경되었을 때 상태 변경을 반영하기 위해 강제로 state id를 undefined로 전환했다가 다시 정상적인 id로 변경
                setTimeout ( () => setApprovalStateId ( ctx.item["approvalStateId"]  ), 0 ); // settimeout을 사용한 이유는 settimeout과 같은 async 함수 호출 없이 연속적인 state update를 실행하면 실제 state update는 최종 값으로 한 번만 실행되기 때문
                dialog.show ();
                
            }

            return dialog;

        } );

    }

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            if ( schema ) {

                let selectMap = {};
                
                for ( const item of schema.selectItems ) {

                    selectMap[item.value] = item.name
 
                }; 

                return (
                    <>
                        <a><span className="approvalStateColumnCell" onClick={() => onClick ( ctx )} >{ t ( selectMap[ctx.item[schema.name]] ) }</span></a>
                    </>
                );

            } else {

                return <></>;

            }

        } ) );

    }, [schema] );

    const onDialogInitialized = ( dialog ) => {

        setDialog ( dialog );
        
    }

    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
            <ApprovalStatusDialog approvalStateId={approvalStateId} onInitialized={onDialogInitialized} />
        </DataGridColumn>

    );

}

export default DataGridApprovalStateColumn;

