/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

function GetQueryString ( props ) {

    const {

        $filter,
        $orderBy,
        $start,
        $length,
        $search,
        $queries,

        onQueryChanged,

    } = props;

    const buildQuery = ( props ) => {

        let first = true;
        let queryString = "";

        for ( const name in props ) {

            if ( name === "$queries" ) {

                continue;

            }

            if ( ! first ) {

                queryString += "&";

            }

            if ( props[name] === "" || props[name] === undefined || props[name] === null ) {

                continue;

            }

            first = false;

            queryString += name + "=" + props[name];

        }

        if ( props && props["$queries"] ) {

            let queries = props["$queries"];

            for ( const name in queries ) {

                if ( ! first ) {

                    queryString += "&";

                }

                if ( queries[name] === "" || queries[name] === undefined || queries[name] === null ) {

                    continue;

                }

                first = false;

                queryString += name + "=" + queries[name];

            }
        }

        return queryString;

    };

    useEffect ( () => {
        
        let query = buildQuery ( {

            $filter,
            $orderBy,
            $start,
            $length,
            $search,
            $queries,

        } );

        if ( onQueryChanged ) {
    
            onQueryChanged ( query );
    
        }

    }, [ $filter, $orderBy, $start, $length, $search, $queries ] );

    return <></>;

}

export default GetQueryString;
