import MaterialDialogTitle from "./MaterialDialogTitle";

function WijmoDialogTitle ( props ) {

    return <MaterialDialogTitle {...props} />

}

export default WijmoDialogTitle;

