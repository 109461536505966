import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    root: {
    },
}));

function BasicCrudScreenHeaderLayout ( props ) {

    const classes = useStyle();

    const { children, className, ...others } = props;
    
    return (

        <header className={classes.root}>
            {
                React.Children.map(children, ( child => 
                    React.cloneElement(child, others) ) )
            }
        </header>
    )

}


export default BasicCrudScreenHeaderLayout;

