import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    toolbar: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "1px solid rgb(212, 212, 212)",
    },
    picker: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0
    },
}));

function AutoFilterColumnPickerLayout ( props ) {

    const classes = useStyle();

    const { children, ...others } = props;

    return (
        <div className={ classes.toolbar } >
        {
            children &&
            React.Children.map(children, ( ( child ) => (
                    React.isValidElement ( child )
                    ?
                    React.cloneElement(child, {className: classes.picker, ...others} )
                    :
                    child
            ) ) )
        }
        </div>
    );

}

export default AutoFilterColumnPickerLayout;
