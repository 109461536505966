/* eslint-disable react-hooks/exhaustive-deps */
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useRecoilState } from "recoil";

import uiConf from "../../config/uiConf";
import notificationState from "../../states/notificationState";

function Notification ( props ) {

    const [ notification, setNotification ] = useRecoilState(notificationState);

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    return (
        <div>

            <Snackbar
                open={notification.open}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    { notification.msg }
                </Alert>    
            </Snackbar>

        </div>
    
    );
}


export default Notification;

