import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

import EguIngredientRegistrationCaptionCreateDialog from "../dialogTypes/EguIngredientRegistrationCaptionCreateDialog";

function EguIngredientRegistrationCaptionCreateButton(props) {

    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <EguIngredientRegistrationCaptionCreateDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                refresh={refresh}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>

    );
}

export default EguIngredientRegistrationCaptionCreateButton;

