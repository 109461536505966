/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysSoProductionSo ( props ) {

    const { t } = useTranslation();

    const { embededInOtherScreenType, onSelect, onApiInitialized } = props;

    const [grid, setGrid] = useState();

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        if ( onSelect ) {

                            onSelect(grid.selectedItems[0]);

                        }

                    } else {

                            if ( onSelect ) {

                                onSelect ( undefined );

                            }

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        if ( onSelect ) {

                            onSelect(grid.selectedItems[0]);

                        }

                    } else {

                        if ( onSelect ) {

                            onSelect ( undefined );

                        }
                        
                    }

                });

            }

        })();

    }, [grid]);

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwSoInventorySummary"
            embededInOtherScreenType={embededInOtherScreenType}
            headerTitle={t("term.so")}
            table="MomSysUglOrderBuyerMst"
            view="MomSysVwOrderBuyerInventorySummary"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysSoProductionSo;
