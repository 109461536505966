import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import EguOutSourcingMaterialReturnRegisterButton from "../input/EguOutSourcingMaterialReturnRegisterButton";
import EguOutSourcingMaterialReturnUpdateButton from "../input/EguOutSourcingMaterialReturnUpdateButton";
import EguOutSourcingMaterialReturnInputButton from "../input/EguOutSourcingMaterialReturnInputButton";
import EguOutSourcingMaterialReturnDetail from "./EguOutSourcingMaterialReturnDetail";

function EguOutSourcingMaterialReturn(props) {
    const id = "EguOutSourcingMaterialReturn";
    const table = "EguOutSourcingMaterialReturn";
    const view = "EguVwOutSourcingMaterialReturn";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [outSourcingMaterialReturnSchema, setOutSourcingMaterialReturnSchema] = useState();

    const [outSourcingMaterialReturnGrid, setOutSourcingMaterialReturnGrid] = useState();
    const [outSourcingMaterialReturnApi, setOutSourcingMaterialReturnApi] = useState();
    const [outSourcingMaterialReturnDataIsEmpty, setOutSourcingMaterialReturnDataIsEmpty] = useState();
    const [outSourcingMaterialReturnSelect, setOutSourcingMaterialReturnSelect] = useState();
    const [outSourcingMaterialReturnDetailDataIsEmpty, setOutSourcingMaterialReturnDetailDataIsEmpty] = useState(true);

    const [detailApi, setDetailApi] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setOutSourcingMaterialReturnSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outSourcingMaterialReturnGrid && mounted) {
                outSourcingMaterialReturnGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingMaterialReturnSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingMaterialReturnSelect([]);
                        }
                    }, 10)
                );

                outSourcingMaterialReturnGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingMaterialReturnSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingMaterialReturnSelect([]);
                        }
                    }, 10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outSourcingMaterialReturnGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDoInputSuccess = (msg) => {
        notifySuccess(msg);

        if (detailApi) {
            detailApi.refresh();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.eguOutSourcingMaterialReturn")}
                        onLoadData={(data) => {
                            setOutSourcingMaterialReturnDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setOutSourcingMaterialReturnGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setOutSourcingMaterialReturnApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <EguOutSourcingMaterialReturnRegisterButton
                                    id={id}
                                    schema={outSourcingMaterialReturnSchema}
                                    refresh={outSourcingMaterialReturnApi}
                                    onSuccess={onSuccess}
                                />

                                <EguOutSourcingMaterialReturnUpdateButton
                                    id={id}
                                    schema={outSourcingMaterialReturnSchema}
                                    grid={outSourcingMaterialReturnGrid}
                                    refresh={outSourcingMaterialReturnApi}
                                    disabled={
                                        outSourcingMaterialReturnSelect?.length <= 0 ? true : false  || 
                                        outSourcingMaterialReturnSelect?.inputDate
                                    }
                                    onSuccess={onSuccess}
                                />

                                <DeleteSingleRecordButton
                                    id={id}
                                    schema={outSourcingMaterialReturnSchema}
                                    grid={outSourcingMaterialReturnGrid}
                                    refresh={outSourcingMaterialReturnApi}
                                    makeItemName={(row) => {
                                        return `${t("columnName.materialReturnId")}: ${row.materialReturnId}`;
                                    }}
                                    color="secondary"
                                    disabled={
                                        outSourcingMaterialReturnSelect?.length <= 0 ? true : false  || 
                                        outSourcingMaterialReturnSelect?.inputDate ||
                                        !outSourcingMaterialReturnDetailDataIsEmpty
                                    }
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />

                                <EguOutSourcingMaterialReturnInputButton
                                    id={id}
                                    schema={outSourcingMaterialReturnSchema}
                                    grid={outSourcingMaterialReturnGrid}
                                    refresh={outSourcingMaterialReturnApi}
                                    disabled={
                                        !outSourcingMaterialReturnSelect ||
                                        outSourcingMaterialReturnSelect?.inputDate ||
                                        outSourcingMaterialReturnDetailDataIsEmpty
                                    }
                                    onSuccess={onDoInputSuccess}
                                />
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    {outSourcingMaterialReturnSelect ? (
                        <EguOutSourcingMaterialReturnDetail
                            outSourcingMaterialReturnMstSelect={outSourcingMaterialReturnSelect}
                            onDetailEmpty={setOutSourcingMaterialReturnDetailDataIsEmpty}
                            onDetailApi={setDetailApi}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguOutSourcingMaterialReturn;
