function AutoFilterFormValueEqualLayout ( props ) {

    const { children } = props;

    return (
        <>
        { children }
        </>
    );

}

export default AutoFilterFormValueEqualLayout;

