import { useState } from "react";
import AutoForm from "../form/functional/AutoForm";

function MomSysBonusLoss(props) {

    const { onBonusLossChange } = props;

    const [ formData, setFormData ] = useState();

    const schema = {

        name: "BonusLoss",
        type: "BASE TABLE",
        searchAll: false,

        "columns": [

            {

                name: "bonus",
                type: "float",
                nullable: "NO",
                key: "",
                default: null,
                formOrder: 1,
                formItemType: "text",
                validation: {
                    msg: "error.positiveFloatRequired",
                    type: "conditions",
                    operator: "and",
                    value: [
                        {
                            msg: "error.floatRequired",
                            type: "regexp",
                            value: "^[+-]?([0-9]*[.])?[0-9]+$"
                        },
                        {
                            msg: "error.positiveFloatRequired",
                            type: "value",
                            operator: "gt",
                            value: 0.0
                        }    
                    ]    
                }

            },
            {

                name: "loss",
                type: "float",
                nullable: "NO",
                key: "",
                default: null,
                formOrder: 2,
                formItemType: "text",
                validation: {
                    msg: "error.positiveFloatRequired",
                    type: "conditions",
                    operator: "and",
                    value: [
                        {
                            msg: "error.floatRequired",
                            type: "regexp",
                            value: "^[+-]?([0-9]*[.])?[0-9]+$"
                        },
                        {
                            msg: "error.positiveFloatRequired",
                            type: "value",
                            operator: "gt",
                            value: 0.0
                        }    
                    ]    
                }

            },

        ]

    }

    const onChangeForm = (formData, newFormData) => {

        if ( onBonusLossChange ) {
                
            onBonusLossChange(newFormData);

        }

        setFormData ( newFormData );

    }

    return (

        <div
            style={{
                padding: "16px"
            }}                    
        >

            <AutoForm
                id="input-bonus-loss-form"
                schema={schema}
                mode="edit"
                initialData={formData}
                onChange={ ( newFormData ) => onChangeForm( formData, newFormData ) }
            />

        </div>

    )

}

export default MomSysBonusLoss;
