import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import * as dateFnsLocales from "date-fns/locale";
import { useTranslation } from 'react-i18next';
import getNewDateObj from "../../../lib/common/getNewDateObj";

function FilterFormItemTime ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, InputLabelProps, onChange, value, ...others } = props;

    let dateFieldProps = {
        onChange: ( date ) => {

            onChange ( date );
            
        },
        ...others
    };

    if ( value !== "" ) {

        dateFieldProps.value = getNewDateObj ( value );

    } else {
            
        dateFieldProps.value = null;
    
    }


    if ( column.filter.InputLabelProps ) {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps, ...column.filter.InputLabelProps };

    } else {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps };

    }

    if ( column.filter.placeholder ) {

        dateFieldProps.placeholder = t ( column.filter.placeholder );

    }

    if ( column.filter.variant ) {

        dateFieldProps.variant = column.filter.variant;

    }

    if ( column.filter.inputProps ) {

        dateFieldProps.inputProps = column.filter.inputProps;

    }

    if ( column.filter.InputProps ) {

        dateFieldProps.InputProps = column.filter.InputProps;

    }

    if ( column.filter.style ) {

        dateFieldProps.style = column.filter.style;

    }

    return (
        <MuiPickersUtilsProvider locale={dateFnsLocales[t("date.localeName")]} utils={DateFnsUtils}>
            <FormControl className={className} style={{width: "180px"}} {...others}>
                <KeyboardTimePicker
                    variant="inline"
                    format={t("date.timeFormat")}
                    margin="normal"
                    helperText={helperText}
                    {...InputLabelProps}
                    {...dateFieldProps}
                    autoOk={true}
                    style={{marginTop: "0px"}}
                />
            </FormControl>
        </MuiPickersUtilsProvider>

    );

}

export default FilterFormItemTime;
