import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

import defineConf from "../../config/defineConf";

function EguInputInspectionUpdateButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        let modifiedSchema = cloneDeep(schema);

        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "qcResult":
                        column.required = false;
                        //column.readonly = true;

                        break;

                    default:
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [schema]);

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        refresh.refresh();
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                modifyFormDataCallback={(formData) => {
                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                        formData.defectiveCode = "INS000";
                    }
                }}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    );
}

export default EguInputInspectionUpdateButton;
