/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";

function EguPopOtherWorkDetail ( props ) {

    const {
        id,
        onSelectMst,
        onDetailEmpty,
    } = props;

    const { t } = useTranslation ();

    const [ grid, setGrid ] = useState();

    const [ tableSchema, setTableSchema ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const [ detailApi, setDetailApi ] = useState();

    const [ , setNotification ] = useRecoilState(notificationState);

    useEffect ( () => {

        (async () => {

            let schema = await getSchema("EguPopOtherWorkDetail");

            schema.columns.push ( 
                { 
                    name: "workerFullName", 
                    type: "varchar(50)", 
                    defaultValue: onSelectMst.workerFullName, 
                    disabled : true,
                    formGroup : 0, 
                },
                { 
                    name: "otherWorkDate", 
                    type: "datetime(6)",
                    defaultValue: onSelectMst.otherWorkDate, 
                    disabled : true,
                    formGroup : 0,
                    formItemType : "date",
                }
            );

            for (const column of schema.columns) {
    
                switch (column.name) {
    
                case "otherWorkId":
    
                    column.defaultValue = onSelectMst.otherWorkId;
                    column.disabled = true;
    
                    break;
    
                default:
    
                    break;
                }
            }

            setTableSchema(schema);
    
            if ( detailApi ) {
    
                detailApi.refresh ();
    
            }

        } ) ();

    }, [onSelectMst.otherWorkId] )

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = ( msg ) => {

        notifySuccess ( msg );
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const notifyWarning = (msg) => {
        
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const onPartialFailure = ( msg ) => {

        notifyWarning ( msg );

    };

    const onModifyUrl = ( url, otherWorkId ) => {

        return url + "/" + otherWorkId;

    };

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onLoadData = (data) => {

        let isEmpty = data.length < 1 ? true : false;

        setIsEmpty(isEmpty);

        onDetailEmpty(isEmpty);

    };

    const onApiInitialized = ( api ) => {

        setDetailApi ( api );

    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    
    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                    <CreateRecordButton
                        id={id}
                        schema={tableSchema}
                        refresh={detailApi}
                        onSuccess={onSuccess}
                        doHandleBackendErrorMsg={doHandleBackendErrorMsg}
                        disabled={onSelectMst?.approvalState}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                    <UpdateRecordButton
                        id={id}
                        schema={tableSchema}
                        grid={grid}
                        refresh={detailApi}
                        disabled={onSelectMst?.approvalState || isEmpty}
                        onSuccess={onSuccess}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                    <DeleteRecordButton
                        id={id}
                        schema={tableSchema}
                        grid={grid}
                        refresh={detailApi}
                        color="secondary"
                        disabled={onSelectMst?.approvalState || isEmpty}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onPartialFailure={onPartialFailure}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="SAVETOEXCEL">
                    <SaveToExcelButton
                        id={id}
                        grid={grid}
                        view="EguVwPopOtherWorkDetail" 
                        color="primary"
                        exportFormatItem={() => {}}
                        disabled={isEmpty}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
    
        );

    };

    return (
    
        <BasicCrudScreenType 
            id={id}
            headerTitle={t("term.EguPopOtherWorkDetail")}
            table="EguPopOtherWorkDetail" 
            view="EguVwPopOtherWorkDetail" 
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={( url ) => onModifyUrl ( url, onSelectMst.otherWorkId )}
            onInitialized = {onInitialized}
            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={onLoadData}
        />
    
    );

}

export default EguPopOtherWorkDetail;
