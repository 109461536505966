/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function MaterialsForNextProcess ( props ) {

    const { t } = useTranslation ();

    const { lotInfo, onMaterialChecked } = props;

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "None";

        grid.itemsSourceChanged.addHandler((grid, event) => {

            let materialInStock = true;

            for ( const row of grid.rows ) {

               if ( row.dataItem.qtyInStock < row.dataItem.qtyRequired ) {

                   materialInStock = false;

               }

               if ( onMaterialChecked ) {

                    onMaterialChecked ( materialInStock );

               }
    
            }
    
        } );

    }

    const onModifyUrl = ( lotInfo, url ) => {
    
        console.log ( "onModifyUrl", lotInfo, url );
        let newUrl;

        newUrl = `${url}/${lotInfo?lotInfo.lotNo:null}/${lotInfo.nextProcessCode}`;

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwMaterialsForNextProcessOnLine"
            headerTitle={t("term.inputMaterial")}
            table="MomSysVwMaterialsForNextProcessOnLine"
            view="MomSysVwMaterialsForNextProcessOnLine"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewUrl={ ( url ) => onModifyUrl ( lotInfo, url ) }
            onInitialized={onGridInitialized}
        />

    );

};

function CompleteProcessDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();

    const [formData, setFormData] = useState({});
    const [schema, setSchema] = useState();
    const [process, setProcess] = useState();

    const [materialInStock, setMaterialInStock] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        lotInfo,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    useEffect ( () => {

        let schema = {

            name: "Production",
            type: "BASE TABLE",
            searchAll: false,
            formGroups: [
                "soInfo",
                "bobMaterialInfo",
                "lotInfo",
                "processInfo",
            ],

            columns: [

                {
                    
                    name: "orderBuyerId",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "companyName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 2,
                    formItemType: "text",

                },

                {
                    
                    name: "bopMaterialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 3,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialCodeVer",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 1,
                    formOrder: 4,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 5,
                    formItemType: "text",

                },
                {

                    name: "lotNo",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 2,
                    formOrder: 6,
                    formItemType: "text",

                },
                {

                    name: "lineNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 7,
                    formItemType: "text",

                },
                {

                    name: "qty",
                    type: "float",
                    nullable: "NO",
                    key: "",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 8,
                    formItemType: "text",

                },
                {

                    name: "productDate",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formGroup: 2,
                    formOrder: 10,
                    formItemType: "date",
                },
                {

                    name: "prevProcessCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 3,
                    formOrder: 10,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysVwOrderedBillOfProcess/${lotInfo?lotInfo.bopMaterialCode:undefined}/${lotInfo?lotInfo.bopMaterialCodeVer:undefined}`,
                        nameColumn: ["processCode", "processName"],
                        valueColumn: "processCode",
                        seperator: ": ",
                        useEmptyValue: false,
                        sortByName: true,
                    }

                },
                {

                    name: "nextProcessCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formGroup: 3,
                    formOrder: 11,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysVwOrderedBillOfProcess/${lotInfo?lotInfo.bopMaterialCode:undefined}/${lotInfo?lotInfo.bopMaterialCodeVer:undefined}`,
                        nameColumn: ["processCode", "processName"],
                        valueColumn: "processCode",
                        seperator: ": ",
                        useEmptyValue: false,
                        sortByName: true,
                    }

                },
                
            ]

        };

        setSchema ( schema );

        if ( lotInfo ) {
                
            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: lotInfo.orderBuyerId,
                    companyName: lotInfo.companyName,

                    bopMaterialCode: lotInfo.bopMaterialCode,
                    bopMaterialCodeVer: lotInfo.bopMaterialCodeVer,
                    bopMaterialName: lotInfo.bopMaterialName,

                    lotNo: lotInfo.lotNo,
                    lineNo: lotInfo.lineNo,
                    qty:parseFloat(lotInfo.qty),

                    prevProcessCode: lotInfo.processCode,
                    nextProcessCode: lotInfo.nextProcessCode,

                };

                return newFormData;

            } );

        } else {

            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: undefined,
                    companyName: undefined,

                    bopMaterialCode: undefined,
                    bopMaterialCodeVer: undefined,
                    bopMaterialName: undefined,

                    lotNo: undefined,
                    lineNo: undefined,
                    qty: undefined,

                    prevProcessCode: undefined,
                    nextProcessCode: undefined,

                };

                return newFormData;

            } );


        }

    }, [lotInfo])

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = ( result) => {

        if ( onSuccess ) {

            onSuccess ( result.data );

        }

        instance.hide();

    };

    const onApiFailure = (lotInfo, reason) => {

        notifyFailure ( t ( "error.productionStartFail", { lotNo: lotInfo.lotNo } ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };


    const onOk = ( lotInfo, formData, materialInStock ) => {
        
        let url = `/api/Production/${lotInfo.lotNo}/start`;

        if ( !materialInStock ) {

            console.error ( t("error.materialNotOnProductionLine") );
            notifyWarning ( t ("error.materialNotOnProductionLine") );

            return;

        }

        let apiData = {
            lotNo: lotInfo.lotNo,
            processCode: formData.nextProcessCode,
            productDate : formData.productDate,
        };

        // build base api url
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            json[formItem] = apiData[formItem];

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        
        apiClient
            .put(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then ( ( result ) => {
                onApiSuccess ( result );
            } )
            .catch ( ( reason ) => {
                onApiFailure ( lotInfo, reason );
            } );
            
    }

    const onCancel = () => {

        instance.hide();
        
        setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    productDate : null

                };

                return newFormData;

        });

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        setProcess ( undefined );

        if ( onClose ) {

            onClose ();

        }

        setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    productDate : null

                };

                return newFormData;

        });
        
    };

    const onChange = ( formData, newFormData ) => {


        if ( formData.procesCode !== newFormData.processCode ) {

            setProcess ( formData.processCode );

        }

        setFormData ( newFormData );

    }

    const onMaterialChecked = ( materialInStock ) => {

        setMaterialInStock ( materialInStock );

    };
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.Produce.startProcess" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "520px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.6} >

                        <MaterialsForNextProcess lotInfo={lotInfo} process={process} onMaterialChecked={onMaterialChecked} />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.4} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id="product-and-next-process-info-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={ ( newFormData ) => onChange( formData, newFormData ) }
                            />

                        </div>


                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("buttonName.startProcessing")]}
                onOk={() => onOk( lotInfo, formData, materialInStock )}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CompleteProcessDialog;
