/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TreeView, TreeItem } from '@material-ui/lab';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import getSpecific from "../../lib/api/getSpecific";

const useStyles = makeStyles({
    root: {
        height: 240,
        flexGrow: 1,
    },
} );

function BomNodes ( props ) {

    const { t } = useTranslation();

    const { bom, level } = props;

    if ( !bom ) {

        return null;

    }

    return ( bom.map ( ( material ) => (

        material.bop && material.bop.length > 0
        ?
        <TreeItem key={`${material.materialCode}/${material.materialCodeVer}/${level}`} nodeId={`${material.materialCode}/${material.materialCodeVer}/${level}`} label={`${t("term.inputMaterial")} : ${material.materialName}[${material.materialCode}/${material.materialCodeVer}] ${t("term.qty")} [${material.qty}]`}>
            <BopNodes bop={material.bop} level={level+1} />
        </TreeItem>
        :
        <TreeItem key={`${material.materialCode}/${material.materialCodeVer}/${level}`} nodeId={`${material.materialCode}/${material.materialCodeVer}/${level}`} label={`${t("term.inputMaterial")} : ${material.materialName}[${material.materialCode}/${material.materialCodeVer}] ${t("term.qty")} [${material.qty}]`} />

     ) ) );

}

function BopNodes ( props ) {

    const { t } = useTranslation();

    const { bop, level } = props;

    if ( !bop ) {

        return null;

    }

    return ( bop.map ( ( process ) => (

        process.bom && process.bom.length > 0
        ?
        <TreeItem key={`${process.processCode}/${level}`} nodeId={`${process.processCode}/${level}`} label={`${t("term.process")} : ${process.processName}[${process.processCode}]`}>
            <BomNodes bom={process.bom} level={level} />
        </TreeItem>
        :
        <TreeItem key={`${process.processCode}/${level}`} nodeId={`${process.processCode}/${level}`} label={`${t("term.process")} : ${process.processName}[${process.processCode}]`}/>

     ) ) );


}

function MomSysBomBopTree ( props ) {

    const { t } = useTranslation();

    const classes = useStyles();

    const { product, onError } = props;

    const [bop, setBop] = useState();
    const [code, setCode ] = useState();
    const [ver, setVer] = useState();
    const [name, setName] = useState();
    const [qty, setQty] = useState(1.0);
    const [expanded, setExpanded] = useState([]);

    const getBomExpanded = ( bom, level ) => {

        let expanded = [];

        if ( !bom ) {
                
                return expanded;
    
        }

        for ( const material of bom ) {

            expanded.push ( `${material.materialCode}/${material.materialCodeVer}/${level}` );
            expanded = [...expanded, ...getBopExpanded ( material.bop, level + 1 )];

        }

        return expanded;

    }
    const getBopExpanded = ( bop, level ) => {

        let expanded = [];

        if ( !bop ) {

            return expanded;

        }

        for ( const process of bop ) {

            expanded.push ( `${process.processCode}/${level}` );
            expanded = [...expanded, ...getBomExpanded ( process.bom, level )];

        }

        return expanded;

    }

    const getExpanded = ( tree ) => {

        let expanded = [];

        expanded.push ( `${tree.materialCode}/${tree.materialCodeVer}/0` );

        expanded = [...expanded, ...getBopExpanded ( tree.bop, 1 )]

        return expanded;
    }
 
    useEffect ( () => {

        ( async () => {

            try {

                let bomBopTree = await getSpecific ( "MomSysBomBopTree", product.materialCode, product.materialCodeVer, product.qty ? parseFloat(product.qty) : 1.0 );

                setExpanded ( getExpanded ( bomBopTree ) );

                setCode ( bomBopTree.materialCode );
                setVer ( bomBopTree.materialCodeVer );
                setName ( bomBopTree.materialName );
                setBop ( bomBopTree.bop );
                setQty ( product.qty || 1.0);

            } catch ( reason ) {

                if ( onError ) {

                    onError ( reason );

                }

            }

        } ) ();

    }, [product] )

    const onNodeToggle = ( event, nodeIds ) => {

        setExpanded ( nodeIds );

    };

    return (
        product && code
        ?
        (
            bop
            ?
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={onNodeToggle}
            >
                {
                    name
                    ?
                    <TreeItem key={`${code}/${ver}/0`} nodeId={`${code}/${ver}/0`} label={`${t("term.outputMaterial")} : ${name}[${code}/${ver}] ${t("term.qty")} [${parseFloat(qty)}]`}>
                        <BopNodes bop={bop} level={1}/>
                    </TreeItem>
                    :
                    <></>
                }
            </TreeView>
            :
            <Typography color="inherit" variant="h6" style={{margin: "auto", position: "absolute", top: "50%", left: "35%", transform: "translateY(-50%)"}}>{ t("error.noBomBopTree") }</Typography>
        )
        :
        <></>
    );

}

export default MomSysBomBopTree;

