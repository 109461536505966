import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysMaterialStatusDetail from "./MomSysMaterialStatusDetail";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysProductTreeButton from "../input/MomSysProductTreeButton";

function MomSysMaterialStatus ( props ) {

    const [ mstGrid, setMstGrid ] = useState();
    
    const [ height, setHeight ] = useState(0);
    
    const [material, setMaterial] = useState();

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };
    useEffect( () => {

        ( async () => {

            if (mstGrid) {
    
                mstGrid.selectionChanged.addHandler((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        setMaterial(() => {

                            return (
                                {
                                    ...material,
                                    qty : material.qtyInStock
                                });
                        });
                    }
                });
                
                mstGrid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        setMaterial(() => {

                            return (
                                {
                                    ...material,
                                    qty : material.qtyInStock
                                });
                        });
                        
                    } else {

                        setMaterial(undefined);

                    }
                    
                    
                });

                mstGrid.loadedRows.addHandler((flex, e) => {
                    /* let rows  = e.getRow(); */

                    for(const row of flex.rows) {

                        if(row.dataItem.qtySpareStock < row.dataItem.qtySafetyStock) {

                            row.cssClass = `failed-stock-apply`

                        }
                    }
                })

            }

        } ) ()

    },[mstGrid]);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);



    const onMstInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setMstGrid(grid);

    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="vertical">

            <ReflexElement flex={0.5}>

                <ReflexContainer orientation="horizontal">

                    <ReflexElement flex={0.5}>

                        <BasicCrudScreenType 
                            id="MomSysVwInventorySummary" 
                            view="MomSysVwInventorySummary" 
                            headerTitle={t("term.materialList")}
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={onMstInitialized}
                            onModifyViewUrl = {(url) => `${url}/material`}
                            onAppendButtonAtFirst={() =>
                                <>

                                    <BasicCrudScreenActionItemLayout>

                                        <MomSysProductTreeButton color="primary" material={material} />

                                    </BasicCrudScreenActionItemLayout>

                                </>
                            }
                            
                        />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                    <ReflexElement flex={0.5}>

                            {
                                (material)
                                ?
                                <MomSysMaterialStatusDetail
                                    id="MomSysVwMaterialStatus" 
                                    view="MomSysVwMaterialStatus" 
                                    material = {material}
                                />
                                :
                                <></>
                            }

                    </ReflexElement>

                </ReflexContainer>

            </ReflexElement>

            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }}/>


            <ReflexElement flex={0.5}>

                <MomSysMaterialStatusDetail
                    id="MomSysNsZvwSgtWipLot" 
                    view="MomSysNsZvwSgtWipLot"
                    nonStandard = {true}
                />

            </ReflexElement>

            </ReflexContainer>
        </div>

    );
}

export default MomSysMaterialStatus;
