import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import uiConf from "../../config/uiConf";

import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenContentLayout from "../screenTypes/layout/BasicCrudScreenContentLayout";
import BasicCrudScreenFooterLayout from "../screenTypes/layout/BasicCrudScreenFooterLayout";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import SimpleHtmlEditor from "../input/SimpleHtmlEditor";
import apiClient from "../../lib/common/apiClient";

function SimpleHtmlEditorScreen ( props ) {

    const { t } = useTranslation();

    const {

        noheader,
        haveHtmlTitle,

        cancelDisabled,

        dimensions,

        title,
        path,
        memoKey,
        onSuccess,
        onFailure,
        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,
        customizeErrorMsgCallback,

        onCancel,

    } = props;

    const [ height, setHeight ] = useState(0);

    const [ htmlChanged, setHtmlChanged ] = useState(false);
    const [ titleChanged, setTitleChanged ] = useState(false);
    const [ , setTimer ] = useState();
    const [ , setApiPath ] = useState("");
    const [ , setNewValue ] = useState("");
    const [ , setEditor ] = useState();
    const [ value, setValue ] = useState("");
    const [ titleValue, setTitleValue ] = useState("");
    const [ notification, setNotification ] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    useEffect ( () => {

        let width = dimensions?.width ?? 0;
        let height = dimensions?.height ?? 0;

        if ( width > 830 ) {
            setHeight ( height + 22 );
        } else if ( width > 440 ) {
            setHeight ( height );
        } else if ( width > 297 ) {
            setHeight ( height - 26 );
        } else if ( width > 287 ) {
            setHeight ( height - 50 );
        } else if ( width > 244 ) {
            setHeight ( height - 73 );
        } else if ( width > 175 ) {
            setHeight ( height - 97 );
        } else {
            setHeight ( height - 122 );
        }

    }, [ dimensions ] );

    useEffect ( () => {

        let mounted = true;

        if ( path && memoKey ) {

            let apiPath = `/api/SimpleHtmlEditor/${path}/${memoKey}`;

            setApiPath ( { path, key: memoKey } );

            ( async () => {

                if ( mounted ) {

                    try {

                        let response = await apiClient.get ( apiPath );
                        let htmlTitle = response?.data?.title ?? "";
                        let html = response?.data?.html ?? "";
                        let images = response?.data?.images ? JSON.parse ( response.data.images ) : "";

                        if ( process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            html = html.replaceAll ( 'src="/files', 'src="http://localhost:8080/files' );

                        }

                        setHtmlChanged ( false );
                        setTitleChanged ( false );
                

                        setValue ( {

                            title: htmlTitle,
                            html: html,
                            images: images,
                            deleted: [],
                            imagesMap: {}

                        } );

                        setTitleValue ( htmlTitle );

                    } catch ( reason ) {

                        console.error ( "Load Editor Content Failed", reason );
                        
                        setValue ( {

                            title: "",
                            html: "",
                            images: [],
                            deleted: [],
                            imagesMap: {}

                        } );

                        setTitleValue ( "" );
    
                    }
    
                }
    
            } ) ();

        }

        return ( () => {

            mounted = false;

        } );

    }, [ path, memoKey ] );

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onChange = ( newValue ) => {

        setValue ( ( value ) => {

            let html = value?.html ?? "";
            let newHtml = newValue?.html ?? "";

            if ( html === "<p><br></p>" ) {

                html = "";

            }

            if ( newHtml === "<p><br></p>" ) {

                newHtml = "";

            }

            setHtmlChanged ( html !== newHtml );
            setNewValue ( ( prevValue ) => {

                let value = {
                    title: prevValue.title,
                    ...newValue
                };

                return value;

            } );

            return value;

        } );



    };

    const onChangeTitle = ( event ) => {

        setTimer ( ( timer ) => {

            if ( timer ) {

                clearTimeout ( timer );

            }

            let newTitle = event.target.value;
            setTitleValue ( newTitle );

            return setTimeout ( () => {

                setValue ( ( value ) => {

                    let title = value?.title ?? "";

                    setTitleChanged ( title !== newTitle );

                    setNewValue ( ( prevValue ) => {
    
                        let value = {
                            ...prevValue,
                            title: newTitle
                        };
        
                        return value;
        
                    } );
         
    
                    return value;
                    
                } );

            }, 500 );

        } );

    };

    const onInitialized = ( editor ) => {

        setEditor ( editor );

    };

    const onApiSuccess = (result, path, key) => {

        setHtmlChanged ( false );
        setTitleChanged ( false );

        notifySuccess(t("success.updateSuccess", { updateCount: 1 }));

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }


        ( async () => {

            try {

                let response = await apiClient.get ( `/api/SimpleHtmlEditor/${path}/${key}` );
                let htmlTitle = response?.data?.title ?? "";
                let html = response?.data?.html ?? "";
                let images = response?.data?.images ? JSON.parse ( response.data.images ) : "";

                if ( process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                    html = html.replaceAll ( 'src="/files', 'src="http://localhost:8080/files' );

                }

                setValue ( {

                    title: htmlTitle,
                    html: html,
                    images: images,
                    deleted: [],
                    imagesMap: {}

                } );

            } catch ( reason ) {

                console.error ( "Load Editor Content Failed", reason );

                setValue ( {

                    title: "",
                    html: "",
                    images: [],
                    deleted: [],
                    imagesMap: {}

                } );

            }

        } ) ();

    };

    const onApiFailure = (reason) => {

        let msg;

        if (customizeErrorMsgCallback) {

            msg = customizeErrorMsgCallback ( reason );

        } else {

            msg = t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
    
        }

        if (onFailure) {

            onFailure(reason, notifyFailure);

        } else {

            notifyFailure ( msg );

        }

    };
    const onOkClicked = () => {

        setApiPath ( ( apiPath ) => {

            setNewValue ( ( newValue ) => {

                setValue ( ( value ) => {

                    value.html = newValue.html;

                    return value;

                } );

                let form = new FormData();
                let json = {};
                let deleted = {};
                let imagesMap = {};

                for ( let fileName in newValue.imagesMap ) {

                    let normalizedFileName = fileName.normalize("NFC");
                    imagesMap[normalizedFileName] = {}
                    imagesMap[normalizedFileName].url = newValue.imagesMap[fileName].url;

                    form.append ( "images", newValue.imagesMap[fileName].file );

                }

                json.path = apiPath?.path;
                json.key = apiPath?.key;
                json.title = newValue.title;
                json.html = newValue.html;

                deleted.images = newValue.deleted;

                form.append("deleted", JSON.stringify(deleted));
                form.append("json", JSON.stringify(json));
                form.append("imagesMap", JSON.stringify(imagesMap));

                apiClient
                .put(`/api/SimpleHtmlEditor/${path}/${memoKey}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => onApiSuccess(result, apiPath.path, apiPath.key))
                .catch((reason) => onApiFailure(reason));

                return newValue;

            } );

            return apiPath;

        } );

    };

    const onCancelClicked = () => {

        setValue ( ( value ) => {

            setNewValue ( value );
            setTitleValue ( value.title );

            setEditor ( ( editor ) => {

                if ( editor ) {

                    editor.setHtml ( value.html );
                    setHtmlChanged ( false );
                    setTitleChanged ( false );
                }

                return editor;

            } );

            return value;

        } );

        if (onCancel) {
            onCancel();
        }
    };

    return (

        noheader
        ?
        <div>

            {
                haveHtmlTitle
                ?
                <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="제목을 입력하세요"
                    value={titleValue}
                    margin="dense"
                    onChange={onChangeTitle}
                />
                :
                <></>
            }

            <SimpleHtmlEditor
                style={{
                    position: "absolute",
                    top: haveHtmlTitle?"52px":"0px",
                    left: "0px",
                    width: "100%",
                    height: dimensions?`${height - 118 - ( haveHtmlTitle ? 52 : 0 )}px`:'100%',
                    border: "none"
                }}
                value={value}
                onChange={onChange}
                onInitialized={onInitialized}
            />

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <BasicCrudScreenFooterLayout>

                <ToolbarFlexSpacer xs />

                <BasicCrudScreenActionItemLayout>

                    <ActionButtonLayout id="save-html-change" color="primary" disabled={!(titleChanged||htmlChanged)} onClick={onOkClicked}>{t("buttonName.save")}</ActionButtonLayout>
                    <ActionButtonLayout id="cancel-html-change" color="secondary" disabled={!(titleChanged||htmlChanged||cancelDisabled)} onClick={onCancelClicked}>{t("buttonName.cancel")}</ActionButtonLayout>

                </BasicCrudScreenActionItemLayout>


            </BasicCrudScreenFooterLayout>
            
        </div>
        :
        <div>
            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                    <span
                        color="inherit"
                        align="center"
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            marginTop: "8px"
                        }}>
                        { title }
                    </span>

                </BasicCrudActionsLayout>
                
            </BasicCrudScreenHeaderLayout>

            <BasicCrudScreenContentLayout>

                {
                    haveHtmlTitle
                    ?
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="제목을 입력하세요"
                        value={titleValue}
                        margin="dense"
                        onChange={onChangeTitle}
                    />
                    :
                    <></>
                }

                <SimpleHtmlEditor
                    style={{
                        position: "absolute",
                        top: haveHtmlTitle?"52px":"0px",
                        left: "0px",
                        width: "100%",
                        height: dimensions?`${height - 166 - ( haveHtmlTitle ? 52 : 0)}px`:'100%',
                        border: "none"
                    }}
                    value={value}
                    onChange={onChange}
                    onInitialized={onInitialized}
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>

            </BasicCrudScreenContentLayout>

            <BasicCrudScreenFooterLayout>

                <ToolbarFlexSpacer xs />

                <BasicCrudScreenActionItemLayout>

                    <ActionButtonLayout id="save-html-change" color="primary" disabled={!(titleChanged||htmlChanged)} onClick={onOkClicked}>{t("buttonName.save")}</ActionButtonLayout>
                    <ActionButtonLayout id="cancel-html-change" color="secondary" disabled={!(titleChanged||htmlChanged||cancelDisabled)} onClick={onCancelClicked}>{t("buttonName.cancel")}</ActionButtonLayout>

                </BasicCrudScreenActionItemLayout>


            </BasicCrudScreenFooterLayout>
            
        </div>

    );

}

export default SimpleHtmlEditorScreen;
