import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import uiConf from "../../config/uiConf";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenFooterLayout from "../screenTypes/layout/BasicCrudScreenFooterLayout";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import SimpleHtmlEditor from "../input/SimpleHtmlEditor";
import apiClient from "../../lib/common/apiClient";
import { makeStyles } from "@material-ui/styles";
import { ReflexContainer, ReflexElement } from "react-reflex";

import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguPopNoticeUpdate(props) {

    const classes = useStyles();

    const { t } = useTranslation();

    const {
        onSuccess,
        onFailure,
        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,
        customizeErrorMsgCallback,

    } = props;

    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [visible, setVisible] = useState(false);

    const [htmlChanged, setHtmlChanged] = useState(false);
    const [titleChanged, setTitleChanged] = useState(false);
    const [, setTimer] = useState();
    const [, setNewValue] = useState("");
    const [, setEditor] = useState();
    const [value, setValue] = useState("");
    const [titleValue, setTitleValue] = useState("");
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const params = useParams();

    useEffect(() => {

        let mounted = true;

        if (params.noticeNo) {

            let apiPath = `/api/EguPopNotice/${params.noticeNo}`;

            (async () => {

                if (mounted) {

                    try {

                        let response = await apiClient.get(apiPath);
                        let htmlTitle = response?.data?.title ?? "";
                        let html = response?.data?.notice ?? "";
                        let images = response?.data?.images ? JSON.parse(response.data.images) : "";

                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            html = html.replaceAll('src="/files', 'src="http://localhost:8080/files');

                        }

                        setVisible(() => {

                            if (response) {

                                if (response.data.visible) {

                                    return true

                                } else {

                                    return false

                                }
                            }
                        })

                        setHtmlChanged(false);
                        setTitleChanged(false);


                        setValue({

                            title: htmlTitle,
                            html: html,
                            images: images,
                            deleted: [],
                            imagesMap: {}

                        });

                        setNewValue({

                            title: htmlTitle,
                            html: html,
                            images: images,
                            deleted: [],
                            imagesMap: {}

                        });

                        setTitleValue(htmlTitle);

                    } catch (reason) {

                        console.error("Load Editor Content Failed", reason);

                        setValue({

                            title: "",
                            html: "",
                            images: [],
                            deleted: [],
                            imagesMap: {}

                        });

                        setTitleValue("");

                    }

                }

            })();

        }

        return (() => {

            mounted = false;

        });

    }, [params]);

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onChange = (newValue) => {

        setValue((value) => {

            let html = value?.html ?? "";
            let newHtml = newValue?.html ?? "";

            if (html === "<p><br></p>") {

                html = "";

            }

            if (newHtml === "<p><br></p>") {

                newHtml = "";

            }

            setHtmlChanged(html !== newHtml);
            setNewValue((prevValue) => {

                let value = {
                    title: prevValue.title,
                    ...newValue
                };

                return value;

            });

            return value;

        });



    };

    const onChangeTitle = (event) => {

        setTimer((timer) => {

            if (timer) {

                clearTimeout(timer);

            }

            let newTitle = event.target.value;
            setTitleValue(newTitle);

            return setTimeout(() => {

                setValue((value) => {

                    let title = value?.title ?? "";

                    setTitleChanged(title !== newTitle);

                    setNewValue((prevValue) => {

                        let value = {
                            ...prevValue,
                            title: newTitle
                        };

                        return value;

                    });


                    return value;

                });

            }, 500);

        });

    };

    const onInitialized = (editor) => {

        setEditor(editor);

    };

    const onApiSuccess = (result, noticeNo) => {

        if (window.opener) {

            window.opener.postMessage({ message: "Success", t: result.data }, "*")
            window.close();
        }

        setHtmlChanged(false);
        setTitleChanged(false);

        notifySuccess(t("success.updateSuccess", { updateCount: 1 }));

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        (async () => {

            try {

                let response = await apiClient.get(`/api/SimpleHtmlEditor/${noticeNo}`);
                let htmlTitle = response?.data?.title ?? "";
                let html = response?.data?.notice ?? "";
                let images = response?.data?.images ? JSON.parse(response.data.images) : "";

                if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                    html = html.replaceAll('src="/files', 'src="http://localhost:8080/files');

                }

                setValue({

                    title: htmlTitle,
                    html: html,
                    images: images,
                    deleted: [],
                    imagesMap: {}

                });

            } catch (reason) {

                console.error("Load Editor Content Failed", reason);

                setValue({

                    title: "",
                    html: "",
                    images: [],
                    deleted: [],
                    imagesMap: {}

                });

            }

        })();

    };

    const onApiFailure = (reason) => {

        window.opener.postMessage(
            {
                message: "Failure",
                t: t("error.insertFailure", {
                    reason: reason.response ? reason.response.data : JSON.stringify(reason),
                }),
            },
            "*"
        );

        

        if (customizeErrorMsgCallback) {

            customizeErrorMsgCallback(reason);

        } else {

            t("error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) });

        }

        if (onFailure) {

            onFailure(reason, notifyFailure);

        } else {

            notifyFailure(
                t("error.insertFailure", {
                    reason: reason.response ? reason.response.data : JSON.stringify(reason),
                })
            );

        }

    };


    const onOkClicked = () => {

        setNewValue((newValue) => {

            setValue((value) => {
                if (!value) {
                    value = {}
                }
                value.html = newValue.html;

                return value;

            });

            let form = new FormData();
            let json = {};
            let deleted = {};
            let imagesMap = {};

            for (let fileName in newValue.imagesMap) {

                let normalizedFileName = fileName.normalize("NFC");
                imagesMap[normalizedFileName] = {}
                imagesMap[normalizedFileName].url = newValue.imagesMap[fileName].url;

                form.append("images", newValue.imagesMap[fileName].file);

            }

            json.title = newValue.title ?? "공지사항";
            json.notice = newValue.html;
            json.visible = visible;
            deleted.images = newValue.deleted;

            form.append("deleted", JSON.stringify(deleted));
            form.append("json", JSON.stringify(json));
            form.append("imagesMap", JSON.stringify(imagesMap));

            apiClient
                .put(`/api/EguPopNotice/${params.noticeNo}`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => onApiSuccess(result, params.noticeNo))
                .catch((reason) => onApiFailure(reason));

            return newValue;

        });

    };

    const onCancelClicked = () => {

        setValue((value) => {

            setNewValue(value);
            setTitleValue(value.title);

            setEditor((editor) => {

                if (editor) {

                    editor.setHtml(value.html);
                    setHtmlChanged(false);
                    setTitleChanged(false);
                }

                return editor;

            });

            return value;

        });

        window.close();
    };

    return (
        <div style={{ height: height }}>
            <div style={{ height: height - 52 }}>
                <ReflexContainer>
                    <ReflexElement

                        flex={1}>
                        <div style={{ height: "100%" }}>

                            <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="제목을 입력하세요"
                                value={titleValue}
                                margin="dense"
                                onChange={onChangeTitle}
                            />
                            <SimpleHtmlEditor
                                style={{
                                    position: "relative",
                                    top: "0px",
                                    left: "0px",
                                    width: "100%",
                                    height: '80%',
                                    border: "none",
                                }}
                                value={value}
                                onChange={onChange}
                                onInitialized={onInitialized}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>
            </div>

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <BasicCrudScreenFooterLayout>
                <FormControlLabel

                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={visible}
                            color="primary"
                            onChange={() => setVisible((visible) => {
                                setHtmlChanged(true)
                                return !visible
                            })}
                            name="checkVisible"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.todayNotice")}</span>}
                />
                <ToolbarFlexSpacer xs />

                <BasicCrudScreenActionItemLayout>

                    <ActionButtonLayout id="save-html-change" color="primary" disabled={!(titleChanged || htmlChanged)} onClick={onOkClicked}>{t("buttonName.save")}</ActionButtonLayout>
                    <ActionButtonLayout id="cancel-html-change" color="secondary" disabled={false} onClick={onCancelClicked}>{t("buttonName.cancel")}</ActionButtonLayout>

                </BasicCrudScreenActionItemLayout>


            </BasicCrudScreenFooterLayout>

        </div>

    );

}

export default EguPopNoticeUpdate;
