import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function CreateEstimateRecordButton(props) {
    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, estimateNumber, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        setOpen(false);

        if (onSuccess) {
            onSuccess(msg);
        }

        refresh.refresh();
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback={(url) => url + "/" + estimateNumber}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    );
}

export default CreateEstimateRecordButton;
