import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

function FilterFormItemCondition ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, label, InputLabelProps, onChange, ...others } = props;

    const stringConditions = [
        {
            name: t("term.none"),
            value: ""
        },
        {
            name: t("dataTableFilter.filterOperator.stringEQ"),
            value: "0"
        },
        {
            name: t("dataTableFilter.filterOperator.stringNE"),
            value: "1"
        },
        {
            name: t("dataTableFilter.filterOperator.stringBW"),
            value: "6"
        },
        {
            name: t("dataTableFilter.filterOperator.stringEW"),
            value: "7"
        },
        {
            name: t("dataTableFilter.filterOperator.stringCT"),
            value: "8"
        },
        {
            name: t("dataTableFilter.filterOperator.stringNC"),
            value: "9"
        }
    ];

    const numericConditions = [
        {
            name: t("term.none"),
            value: ""
        },
        {
            name: t("dataTableFilter.filterOperator.numberEQ"),
            value: "0"
        },
        {
            name: t("dataTableFilter.filterOperator.numberNE"),
            value: "1"
        },
        {
            name: t("dataTableFilter.filterOperator.numberGT"),
            value: "2"
        },
        {
            name: t("dataTableFilter.filterOperator.numberGE"),
            value: "3"
        },
        {
            name: t("dataTableFilter.filterOperator.numberLT"),
            value: "4"
        },
        {
            name: t("dataTableFilter.filterOperator.numberLE"),
            value: "5"
        }
    ];

    const getConditionItems = () => {

        if ( column.filter.conditions ) {

            return ( column.filter.conditions.map ( ( condition ) => ( { name: t(condition.name), value: condition.value } ) ) );

        }

        if (            
            column.type.startsWith ( "char" )       ||
            column.type.startsWith ( "varchar" )    ||
            column.type.startsWith ( "text" )       ||
            column.type.startsWith ( "tinytext" )   ||
            column.type.startsWith ( "mediumtext" ) ||
            column.type.startsWith ( "longtext" )
        ) {

            return stringConditions;

        } else {

            return numericConditions

        }

    };

    const selectProps = {
        defaultValue: "",
        displayEmpty: false,
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        ...others
    };

    if ( column.filter.conditionStyle ) {

        selectProps.style = column.filter.conditionStyle;

    }

    return (
        <FormControl className={className}  {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
                { ...selectProps }
            >
                {
                    getConditionItems().map ( ( item ) => <MenuItem key={item.name} value={ t ( item.value ) }>{item.name}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FilterFormItemCondition;
