/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import uiConf from "../../config/uiConf";
import getList from "../../lib/api/getList";

import MessageList from "./MessageList";

const useStyles = makeStyles ( () => ( {
    iconButton: {
        padding: 4,
    },
} ) );

function MessageButton ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    const [ , setTimer ] = useState ();
    const [ anchor, setAnchor ] = useState ();
    const [ openList, setOpenList ] = useState (false);

    const [ messages, setMessages  ] = useState([]);

    useEffect ( () => {

        let mounted = true;

        const getMessages = async () => {

            let result = await getList ( "VwMsgInBox/unread" );
    
            if ( mounted ) {
    
                setMessages ( result.data );
    
                setTimer ( ( timer ) => {
        
                    if ( timer ) {
        
                        clearTimeout ( timer );
        
                    }
                    
                    return ( setTimeout ( () => {
        
                        getMessages ();
        
                    }, uiConf.messageFetchDuration ) );
        
                } );
    
            }
    
        }
    
        getMessages ();

        return ( () => {
            mounted = false;
        } );
    
    }, [] );

    const onOpenList = ( event ) => {

        setMessages ( ( messages ) => {

            if ( messages.length > 0 ) {

                setAnchor ( event.currentTarget );
                setOpenList ( true );
    
            }

            return messages;
    
        } );

    };

    const onCloseList = () => {

        setOpenList ( false );
        setAnchor ( null );
    }

    const onMsgRead = ( messageRead ) => {

        setMessages ( ( messages ) => messages.filter ( ( message ) => message.messageId !== messageRead.messageId ) );
        
    }

    return (

        <>

            <Tooltip title={ t("toolTips.alert", { msg: t("toolTips.msg.alertCount", { count: messages.length } ) } ) }>
                <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={onOpenList}
                >
                    <Badge
                        color="error"
                        badgeContent={ messages.length }
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <NotificationsIcon />

                    </Badge>
                </IconButton>
            </Tooltip>
            <MessageList id="message-list" open={openList} anchorEl={anchor} messages={messages} onClose={onCloseList} onRead={onMsgRead} />

        </>

    );

}

export default MessageButton;


