/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from '@material-ui/lab';
import { useEffect, useState } from 'react';

import PaginationLayout from "../layout/PaginationLayout";

function PaginationControl ( props ) {

    const { pagination, ouputSizeRef, count, size, onChange, ...others } = props;

    const [ page, setPage ] = useState(1);
    const [ pageCount, setPageCount ] = useState(1);

    useEffect ( () => {

        if ( pagination ) {
            
            pagination.set ( page );

        }

    }, [pagination] );

    useEffect ( () => {

        let pageCount = Math.ceil ( count / size );

        setPageCount ( pageCount );
        
        setPage ( ( page ) => {

            if ( page > pageCount ) {

                if ( pagination ) {

                    pagination.set ( pageCount );

                }

                return pageCount;

            }

            return page;
        })

    }, [count, size])

    const onChangePage = ( event, page ) => {

        setPage ( page );

        if ( pagination ) {

            pagination.set ( page );

        }

        if ( onChange ) {

            onChange ( page );

        }
        
    }

    return (

        count > size
        ?
        <PaginationLayout {...others} >

            <Pagination count={ pageCount } page={page <= 0 ? 1 : page} onChange={onChangePage} {...others} />

        </PaginationLayout>
        :
        <></>

    );

  }

  export default PaginationControl;

