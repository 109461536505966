function isChecked ( value ) {

    switch ( value ) {

        case true:
        case "true":

            return true;

        case false:
        case "false":
        case "":

            return false;

        default:

            if ( value === 0 ) {

                return false;

            }

            return true;

    }
    
}

export default isChecked;
