import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { AccountTreeOutlined } from "@material-ui/icons";

import MomSysProductTreeDialog from "../dialogTypes/MomSysProductTreeDialog";

function MomSysProductTreeButton ( props ) {

    const { material } = props;

    const [, setDialog] = useState();

    const showProductTree = () => {

        if ( material ) {

            setDialog ( ( dialog ) => {

                if ( dialog ) {
    
                    dialog.show ();
    
                }
    
                return ( dialog );
    
            } );
    
        }
    }

    const onInitializeDialog = ( dialog ) => {

        setDialog ( dialog );

    }

    return (

        <>

            <IconButton {...props}>
                <AccountTreeOutlined onClick={showProductTree} />
            </IconButton>
            <MomSysProductTreeDialog id={"bombop-info-bombop-tree-dialog"} material={material} onInitialized={onInitializeDialog} />

        </>

    )


}

export default MomSysProductTreeButton;
