import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function CopyBomBopButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.copyBomBop")}</ActionButtonLayout>;

}

export default CopyBomBopButtonLayout;

