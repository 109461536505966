/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { debounce, cloneDeep } from "lodash";
import getNewDateObj from "../../lib/common/getNewDateObj";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },
    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function selectTargetRowMstDialog(grid, materialObj) {
    const targetRow = grid.rows.find((row) => 
        row.dataItem.materialCode === materialObj.materialCode 
        && row.dataItem.materialCodeVer === materialObj.materialCodeVer
    );
    if(targetRow) grid.select(targetRow.index, -1);
}

function selectTargetRowDetailDialog(grid, extLotNo) {
    const targetRow = grid.rows.find((row) => 
        row.dataItem.extLotNo === extLotNo 
    );
    if(targetRow) grid.select(targetRow.index, -1);
}

function sortData(data) {
    const cloneData = cloneDeep(data);

    cloneData.sort((a,b) => {
        if(a.expirationDate === null && b.expirationDate && a.manufacturedDate === null && b.manufacturedDate === null) {
            return Number(a.lotNo) - Number(b.lotNo);
        }
        if(getNewDateObj(a.expirationDate) === getNewDateObj(b.expirationDate)) {
            if(getNewDateObj(a.manufacturedDate) === getNewDateObj(b.manufacturedDate)) {
                return Number(a.lotNo) - Number(b.lotNo);
            }
            else if((a.manufacturedDate) === null) return 1; 
            else if((b.manufacturedDate) === null) return -1; 
            else return getNewDateObj(a.manufacturedDate) - getNewDateObj(b.manufacturedDate);
        } 
        else if((a.expirationDate) === null) return 1; 
        else if((b.expirationDate) === null) return -1; 
        else return getNewDateObj(a.expirationDate) - getNewDateObj(b.expirationDate)}
    )
    return cloneData;
}

function makeTargetRows(detailData, value) {
    let sum = 0;
    let arr = [];
    if(isNaN(value)) return arr;
    for(let data of detailData) {
        if(sum >= Number(value)) break;
        sum += Number(data.qtyCurrent);
        arr.push(data.extLotNo);
    }
    return arr;
}

function PopDirectShipDetailCreateDialog(props) {

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [keepOpen, setKeepOpen] = useState(false);

    const {

        // Dialog Properties

        id,
        title,
        mode,
        platform,
        resizable,
        moveable,
        fullWidth,
        maxWidth,
        onInitialized,
        noKeepOpen,

        // Form Properties

        formId,
        schema,
        initialData,
        setInitialDataCallback,
        modifyFormDataCallback,
        checkShowCallback,
        checkDisabledCallback,
        checkFormValidationCallback,
        checkItemValidationCallback,
        modifyUrlCallback,

        // customize error message callback

        customizeErrorMsgCallback,

        // AutoFormDialog Properties

        onClose,
        onChange,
        onSuccess,
        onFailure,

        // Do Not Notify Using Snackbar by Dialog itself

        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,

        doNotUseKeyAsUrlWhenUpdate,

        barcodeCallback,

        selectMst,

        ...others

    } = props;

    const [tableSchema, setTableSchema] = useState(schema);
    const [dialogMstGrid, setDialogMstGrid] = useState();
    const [dialogDetailGrid, setDialogDetailGrid] = useState();
    const [selectDialogMst, setSelectDialogMst] = useState();
    const [selectDialogDetail, setSelectDialogDetail] = useState();
    const [dialogDetailApi, setDialogDetailApi] = useState();

    const [detailData, setDetailData] = useState();
    const [rowsToColor, setRowsToColor] = useState([]);

    const focusRefs = useRef([]);

    useEffect(() => {

        if (schema) {

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        setTableSchema(schema);

    }, [schema]);

    useEffect(() => {
        (async () => {
            if (dialogMstGrid) {
                dialogMstGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectDialogMst(grid.selectedItems[0]);
                        }else {
                            setSelectDialogMst([]);
                        }
                    },10)
                );

                dialogMstGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            selectTargetRowMstDialog(dialogMstGrid, selectMst);
                            setSelectDialogMst(grid.selectedItems[0]);
                        }else {
                            setSelectDialogMst([]);
                        }

                    },500)
                );
                
            }
        })();
    }, [dialogMstGrid]);

    useEffect(() => {
        (async () => {
            if (dialogDetailGrid) {
                dialogDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectDialogDetail(grid.selectedItems[0]);
                        }else {
                            setSelectDialogDetail([]);
                        }
                    },100)
                );

                dialogDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectDialogDetail(grid.selectedItems[0]);
                        }else {
                            setSelectDialogDetail([]);
                        }

                    },500)
                );
            }
        })();
    }, [dialogDetailGrid]);

    useEffect(() => {

        if(dialogDetailGrid) {

            for(let row of dialogDetailGrid.rows) {

                row.cssClass = null;

                for(let extLotNo of rowsToColor) {

                    if(row.dataItem.extLotNo === extLotNo) row.cssClass = `qty-equals-blue`;

                }

            }

        }

    }, [rowsToColor])

    useEffect(() => {
        if (dialogDetailApi) {
            dialogDetailApi.refresh();
        }
    }, [selectDialogMst]);

    useEffect(() => {
        if (selectDialogDetail && detailData) {
            (async () => {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        barCodeExtLotNo: null,
                        extLotNo: selectDialogDetail?.extLotNo,
                        qty: selectDialogDetail?.qtyCurrent,
                        lotNo: selectDialogDetail?.lotNo,
                        materialCode: selectDialogDetail?.materialCode,
                        materialCodeVer: selectDialogDetail?.materialCodeVer,
                        materialName: selectDialogDetail?.materialName,
                        qtyCurrent: selectDialogDetail?.qtyCurrent,
                        manufacturedDate: selectDialogDetail?.manufacturedDate,
                        expirationDate: selectDialogDetail?.expirationDate,
                    };

                    return newFormData;
                });
                setRowsToColor(makeTargetRows(detailData, selectDialogDetail.qtyCurrent));
            })();
        }
    }, [selectDialogDetail, detailData]);

    const notifyWarning = (msg) => {

        console.warn(msg);

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "warning",
                msg: msg,
                open: true,
            });

        }

    };

    const checkItemValidataion = (formData, schema) => {


        for (const column of schema.columns) {

            let [valid, msg] = checkValidation(mode, column, column.validation, formData[column.name] || "");

            if (valid && checkItemValidationCallback) {

                [valid, msg] = checkItemValidationCallback(formData, column);

            }

            if (!valid) {

                notifyWarning(msg);
                return false;

            }

        }

        return true;

    }

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseDialog = () => {

        setFormData(null);

        setInteraction(false);

        if (onClose) {

            onClose();

        }

    }

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChangeFormData = (formData) => {

        setInteraction(true);

        setFormData(formData);

        if (onChange) {

            onChange(formData);

        }

    };

    const onApiSuccess = (result, schema) => {

        notifySuccess(t("success.insertSuccess", { table: schema.name }));

        setKeepOpen((keepOpen) => {

            if (!keepOpen) {

                instance.hide();

            }

            return keepOpen;

        });

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

    };

    const onApiFailure = (reason) => {

        let msg;

        if (customizeErrorMsgCallback) {

            msg = customizeErrorMsgCallback ( reason );

        } else {

            msg = t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
    
        }

        if (onFailure) {

            onFailure(reason, notifyFailure);

        } else {

            notifyFailure ( msg );

        }

    };

    const onOk = (mode, formData, schema) => {

        if (!checkItemValidataion(formData, schema)) {

            console.error("Form Validation Failed");

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (valid && checkFormValidationCallback) {

                [valid, msg] = checkFormValidationCallback(formData);

            }

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }

        }
        else if (checkFormValidationCallback) {

            const [valid, msg] = checkFormValidationCallback(formData);

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }
                
        // build base api url

        let url = "/api/" + schema.name;

        if (modifyUrlCallback) {

            url = modifyUrlCallback(url);

        }

        let apiData = { ...formData };

        let form = new FormData();
        let json = {};
        let deleted = {};

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }

            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result, schema))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {

        setFormData(null);

        instance.hide();

    };

    const checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>

            </>

        )

    }

    const onBarcodeCallback = (barcodeInfo) => {

        selectTargetRowMstDialog(dialogMstGrid, barcodeInfo);

        setTimeout(() => selectTargetRowDetailDialog(dialogDetailGrid, barcodeInfo.extLotNo), 500);

        focusRefs.current["qty"].focus();

    };

    const onLoadData = (data) => {

        setDetailData(sortData(data));

    }

    const onModifyFormDataCallback = ( newFormData, name, value ) => {

        if(name === "qty") {

            setRowsToColor(makeTargetRows(detailData, value));

        }

    }

    return (
        <Dialog
            id={id}
            title={title}
            platform={platform ? platform : uiConf.dialogPlatform}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <Divider />

            <DialogContent  style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.5}>
                                <BasicCrudScreenType
                                    id="MomSysZvwMaterial"
                                    headerTitle={t("tableName.MomSysZvwMaterial")}
                                    table="MomSysZvwMaterial"
                                    view="MomSysZvwMaterial"
                                    noCreate
                                    noEdit
                                    noDelete
                                    noExcel
                                    noPagination
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setDialogMstGrid(grid);
                                    }}
                                    embededInOtherScreenType
                                />
                            </ReflexElement>

                            <ReflexSplitter style={{ height: "1px", color: "lightgray" }} />

                            <ReflexElement flex={0.5}>
                                <BasicCrudScreenType
                                    id="MomSysZvwMaterialOutputSgtWipLot"
                                    view="MomSysZvwMaterialOutputSgtWipLot"
                                    headerTitle={t("term.standardShipPossibleLot")}
                                    noCreate
                                    noEdit
                                    noDelete
                                    noExcel
                                    noFilter
                                    noPagination
                                    embededInOtherScreenType
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setDialogDetailGrid(grid);
                                    }}
                                    onApiInitialized={(api) => {
                                        setDialogDetailApi(api);
                                    }}
                                    onModifyViewUrl={(url) => `${url}/${selectDialogMst?.materialCode}/${selectDialogMst?.materialCodeVer}`}
                                    onLoadData={onLoadData}
                                />
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id={formId}
                                schema={tableSchema}
                                mode={"edit"}
                                initialData={formData}
                                setInitialDataCallback={setInitialDataCallback}
                                modifyFormDataCallback={onModifyFormDataCallback}
                                checkShowCallback={checkShowCallback}
                                checkDisabledCallback={checkDisabledCallback}
                                checkValidationCallback={checkItemValidationCallback}
                                onChange={onChangeFormData}
                                barcodeCallback={onBarcodeCallback}
                                focusRefs={focusRefs}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Divider />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <DialogActionsOkCancel
                firstActionComponent={noKeepOpen ? () => <></> : checkKeepOpen}
                labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(mode, formData, tableSchema)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PopDirectShipDetailCreateDialog;
