import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MrpMaterialEventData(props) {
    const { mrpMaterialListSelect, onEventDataLoad } = props;

    const view = "MrpVwMaterialEventData";

    const [, setMrpMaterialEventDataSelect] = useState();
    const [, setMrpMaterialEventDataIsEmpty] = useState();
    const [mrpMaterialEventDataList, setMrpMaterialEventDataList] = useState([]);
    const [mrpMaterialEventDataGrid, setMrpMaterialEventDataGrid] = useState();
    const [, setMrpMaterialEventDataApi] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        setMrpMaterialEventDataApi((api) => {
            if (api) {
                api.refresh();
            }

            return api;
        });
    }, [mrpMaterialListSelect.materialCode, mrpMaterialListSelect.materialCodeVer]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (mrpMaterialEventDataGrid && mounted) {
                mrpMaterialEventDataGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMrpMaterialEventDataSelect(grid.selectedItems[0]);
                        }
                    },10)
                );

                mrpMaterialEventDataGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMrpMaterialEventDataSelect(grid.selectedItems[0]);
                        }
                    },10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [mrpMaterialEventDataGrid]);

    useEffect(() => {
        if (
            mrpMaterialListSelect &&
            mrpMaterialEventDataGrid &&
            mrpMaterialEventDataList &&
            mrpMaterialEventDataList?.length > 0 &&
            mrpMaterialListSelect.materialCode === mrpMaterialEventDataList[0].eventMaterialCode &&
            mrpMaterialListSelect.materialCodeVer === mrpMaterialEventDataList[0].eventMaterialCodeVer
        ) {
            let netQty = mrpMaterialListSelect.qtyCurrent;

            let netQtyBelowSafetyStockFlag = false;

            /***
            for (const item of mrpMaterialEventDataList) {
                netQty += item.eventQty;
                item.netQty = netQty;
            }
            ***/

            mrpMaterialEventDataGrid.rows.forEach((row, idx) => {
                netQty += row.dataItem.eventQty;
                row.dataItem.netQty = netQty;

                if (row.dataItem.netQty < mrpMaterialListSelect.qtySafetyStock) {
                    if (netQtyBelowSafetyStockFlag === false) {
                        row.cssClass = `failed-stock-apply`;
                        netQtyBelowSafetyStockFlag = true;
                    }
                } else {
                    row.cssClass = null;
                    if (netQtyBelowSafetyStockFlag === true) {
                        netQtyBelowSafetyStockFlag = false;
                    }
                }
            });

            if (onEventDataLoad) {
                onEventDataLoad(mrpMaterialEventDataList);
            }

            /*** 2022/08/16 itkim : grid.refresh() is useless
            mrpMaterialEventDataGrid.refresh(false); // false : refresh grid content only
            ***/

            /***
            if (onUpdateMaster) {
                console.log("[MrpMaterialEventData] Call onUpdateMaster()...");
                onUpdateMaster(mrpMaterialEventDataList);
            }
            ***/
        }
    }, [mrpMaterialListSelect, mrpMaterialEventDataGrid, mrpMaterialEventDataList, onEventDataLoad]);

    return (
        <BasicCrudScreenType
            id="MrpVwMaterialEventData"
            headerTitle={t("term.mrpMaterialEventData")}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            noPagination
            fixedOrder={[{column: "eventTime", order: "ASC"}]}
            onModifyViewUrl={(url) =>
                `${url}/${mrpMaterialListSelect.materialCode}/${mrpMaterialListSelect.materialCodeVer}`
            }
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                grid.allowSorting = false;
                setMrpMaterialEventDataGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMrpMaterialEventDataApi(api);
            }}
            onLoadData={(data) => {
                setMrpMaterialEventDataIsEmpty(data.length < 1 ? true : false);
                setMrpMaterialEventDataList(data);
            }}
        />
    );
}

export default MrpMaterialEventData;
