/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import getSchema from "../../lib/api/getSchema";
import MomSysMaterialByCompanyExcelFormDownloadButton from "../input/MomSysMaterialByCompanyExcelFormDownloadButton";
import MomSysMaterialByCompanyExcelUploadButton from "../input/MomSysMaterialByCompanyExcelUploadButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import MomSysMaterialByCompanyExcelUploadSuccessDet from '../input/MomSysMaterialByCompanyExcelUploadSuccessDet';
import MomSysMaterialByCompanyExcelUploadErrorDet from '../input/MomSysMaterialByCompanyExcelUploadErrorDet';

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import { cloneDeep } from "lodash";

function MomSysMaterialByCompanyExcelUpload(props) {
    const excelUploadSuccessMstViewName = "MomSysVwMaterialByCompanyExcelUploadSuccessMst";
    const excelUploadErrorViewName = "MomSysVwMaterialByCompanyExcelUploadErrorMst";

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [modifiedSchema, setModefiedSchema] = useState();
    const [screenName, setScreenName] = useState(excelUploadSuccessMstViewName);
    const [height, setHeight] = useState(0);
    const [selectMstGrid, setSelectMstGrid] = useState([]);
    const setNotification = useSetRecoilState(notificationState);

    const {t} = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function modifiedSchemaSetting() {
            let schema = await getSchema("MomSysMaterialByCompany");
            if(mounted) {
                let modifiedSchema = cloneDeep(schema);
                modifiedSchema.columns.forEach(column => columnSetting(column));
                setSchema(schema);
                setModefiedSchema(modifiedSchema);
            }
        }

        function columnSetting(column) {
            if(column.key === "PRI") {
                // 기본키 값이 없으면 안되서 필요없는 기본키값 추가
                column.defaultValue = "pri";
            }

            if(column.name !== "excelFile") {
                column.hideInForm = true;
                column.required = false;
            }
        }

        modifiedSchemaSetting();

        return (() => {
            mounted = false;
        });

    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectMstGrid(grid.selectedItems[0]);
                    } else {
                        setSelectMstGrid([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectMstGrid(grid.selectedItems[0]);
                    } else {
                        setSelectMstGrid([]);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }
    
    const onClickExcelUploadSuccessButton = () => {
        setScreenName(excelUploadSuccessMstViewName);
    }

    const onClickExcelUploadErrorButton = () => {
        setScreenName(excelUploadErrorViewName);
    }

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    function isSuccessView() {
        if(screenName === excelUploadSuccessMstViewName) {
            return "outlined";
        }
    }

    function isErrorView() {
        if(screenName === excelUploadErrorViewName) {
            return "outlined";
        }
    }

    const onAppendButtonInFrontOfSearch = () => (
        <BasicCrudScreenActionItemLayout>
            <ActionButtonLayout
                id="MomSysExcelUploadSuccessButton"
                onClick={onClickExcelUploadSuccessButton}
                variant={isSuccessView()}
            >
                {t("buttonName.excelUploadSuccess")}
            </ActionButtonLayout>

            <ActionButtonLayout
                id="MomSysExcelUploadErrorButton"
                onClick={onClickExcelUploadErrorButton}
                variant={isErrorView()}
            >
                {t("buttonName.excelUploadError")}
            </ActionButtonLayout>

            <AccessControl accessTarget={schema && schema.name} accessMethod="GET">
                <MomSysMaterialByCompanyExcelFormDownloadButton
                    id = "MomSysMaterialByCompanyExcelUploadFormDownLoad"
                    schema = {schema}
                    refresh = {api}
                    grid = {grid}
                    onSuccess = {onSuccess}
                />
            </AccessControl>

            <AccessControl accessTarget={modifiedSchema && modifiedSchema.name} accessMethod="POST">
                <MomSysMaterialByCompanyExcelUploadButton
                    id = "MomSysMaterialByCompanyExcelUpload"
                    createButtonName = "excelUpload"
                    schema = {modifiedSchema}
                    refresh = {api}
                    onSuccess = {onSuccess}
                    modifyUrlCallback={(url) => `${url}?excelUpload`}
                />
            </AccessControl>
        </BasicCrudScreenActionItemLayout>
    );

    return (
        <>
            {screenName === excelUploadSuccessMstViewName ? (
                <div style={{height: height}}>
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement flex={0.5} data-cy="MaterialByCompanyExcelUploadSuccessMst">
                            <BasicCrudScreenType
                                id="MomSysMaterialByCompanyExcelUploadSuccessMst"
                                table="MomSysMaterialByCompany"
                                view="MomSysVwMaterialByCompanyExcelUploadSuccessMst"
                                onApiInitialized={onApiInitialized}
                                onInitialized={onInitialized}
                                headerTitle={t("term.company")}
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />

                        <ReflexElement data-cy="MaterialByCompanyExcelUploadSuccessDet">
                            {
                                (selectMstGrid.companyCode)
                                ?
                                <MomSysMaterialByCompanyExcelUploadSuccessDet 
                                    id="MomSysMaterialByCompanyExcelUploadSuccessDet" 
                                    selectMstGrid={selectMstGrid}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {screenName === excelUploadErrorViewName ? (
                <div style={{height: height}}>
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement flex={0.5} data-cy="MaterialByCompanyExcelUploadErrorMst">
                            <BasicCrudScreenType
                                id="MomSysMaterialByCompanyExcelUploadErrorMst"
                                table="MomSysVwMaterialByCompanyExcelUploadErrorMst"
                                view="MomSysVwMaterialByCompanyExcelUploadErrorMst"
                                onApiInitialized={onApiInitialized}
                                onInitialized={onInitialized}
                                headerTitle={t("term.company")}
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />

                        <ReflexElement data-cy="MaterialByCompanyExcelUploadErrorDet">
                            {
                                (selectMstGrid.companyCode)
                                ?
                                <MomSysMaterialByCompanyExcelUploadErrorDet 
                                    id="MomSysMaterialByCompanyExcelUploadErrorDet" 
                                    selectMstGrid={selectMstGrid}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </div>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </>
    );
}

export default MomSysMaterialByCompanyExcelUpload;
