/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";


function HoldReleaseCancelProcessDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();

    const [formData, setFormData] = useState({});
    const [schema, setSchema] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        lotInfo,
        action,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    useEffect ( () => {

        let schema = {

            name: "Production",
            type: "BASE TABLE",
            searchAll: false,
            formGroups: [
                "soInfo",
                "bobMaterialInfo",
                "lotInfo",
                "processInfo",
                "description",
            ],

            columns: [

                {
                    
                    name: "orderBuyerId",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "companyName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 2,
                    formItemType: "text",

                },
                {
                    
                    name: "bopMaterialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 3,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialCodeVer",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 1,
                    formOrder: 4,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 5,
                    formItemType: "text",

                },
                {

                    name: "lotNo",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 2,
                    formOrder: 6,
                    formItemType: "text",

                },
                {

                    name: "lineNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 7,
                    formItemType: "text",

                },
                {

                    name: "qty",
                    type: "float",
                    nullable: "NO",
                    key: "",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 8,
                    formItemType: "text",

                },
                {

                    name: "processCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 3,
                    formOrder: 10,
                    formItemType: "text",

                },
                {

                    name: "processName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 3,
                    formOrder: 11,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(50)",
                    default: null,
                    formGroup: 4,
                    formOrder: 12,
                    formItemType: "text",

                },
                
            ]

        };

        setSchema ( schema );
        
    }, [] );

    useEffect ( () => {
        
        if ( lotInfo ) {
                
            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: lotInfo.orderBuyerId,
                    companyName: lotInfo.companyName,

                    bopMaterialCode: lotInfo.bopMaterialCode,
                    bopMaterialCodeVer: lotInfo.bopMaterialCodeVer,
                    bopMaterialName: lotInfo.bopMaterialName,

                    processCode: lotInfo.processCode,
                    processName: lotInfo.processName,

                    lotNo: lotInfo.lotNo,
                    lineNo: lotInfo.lineNo,
                    qty: lotInfo.qty,

                    description: undefined,

                };

                return newFormData;

            } );

        } else {

            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: undefined,
                    companyName: undefined,

                    bopMaterialCode: undefined,
                    bopMaterialCodeVer: undefined,
                    bopMaterialName: undefined,

                    lotNo: undefined,
                    lineNo: undefined,
                    qty: undefined,

                    description: undefined,

                };

                return newFormData;

            } );

        }

    }, [lotInfo])

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = ( result ) => {

        if ( onSuccess ) {

            onSuccess ( result.data );

        }

        instance.hide();

    };

    const onApiFailure = (lotInfo, reason) => {

        notifyFailure ( t ( "error.productionStartFail", { lotNo: lotInfo.lotNo } ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = ( lotInfo, action ) => {
        
        let url;
        
        switch ( action ) {

            case "hold":

                url = `/api/Production/${lotInfo.lotNo}/hold`;
                break;

            case "release":

                url = `/api/Production/${lotInfo.lotNo}/release`;
                break;

            case "cancel":

                url = `/api/Production/${lotInfo.lotNo}`;
                break;

            default:

                url = "unknownAction";
                break;

        }

        let apiData = {
            lotNo: lotInfo.lotNo,
        };

        // build base api url
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            json[formItem] = apiData[formItem];

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        
        switch ( action ) {

            case "hold":
            case "release":

                apiClient
                    .put(url, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then ( ( result ) => {
                        onApiSuccess ( result );
                    } )
                    .catch ( ( reason ) => {
                        onApiFailure ( lotInfo, reason );
                    } );

                break;

            case "cancel":

                apiClient
                    .del(url)
                    .then ( ( result ) => {
                        onApiSuccess ( result );
                    } )
                    .catch ( ( reason ) => {
                        onApiFailure ( lotInfo, reason );
                    } );

                break;

            default:

                alert ( "Unknown Action" );
                break;

        }
            
    }

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }

    };

    const getDialogTitle = ( action ) => {

        switch ( action ) {

            case "hold":

                return t ( "dialog.title.Produce.holdProcess" );

            case "release":

                return t ( "dialog.title.Produce.releaseHoldProcess" );
                
            case "cancel":

                return t ( "dialog.title.Produce.cancelProduction" );

            default:

                return "Unknown Action";

        }

    }

    const getButtonLabel = ( action ) => {

        switch ( action ) {

            case "hold":

                return t ( "buttonName.holdProcessing" );

            case "release":

                return t ( "buttonName.releaseHoldProcessing" );
                
            case "cancel":

                return t ( "buttonName.cancelProduction" );
            
            default:

                return "Unknown Action";

        }

    }

    const onChange = ( formData ) => {

        setFormData ( formData );

    };
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { getDialogTitle ( action ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent>

                <div
                    style={{
                        padding: "16px"
                    }}                    
                >

                    <AutoForm
                        id="hold-release-cancel-info-form"
                        schema={schema}
                        mode="edit"
                        initialData={formData}
                        onChange={onChange}
                    />

                </div>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ getButtonLabel ( action ) ]}
                onOk={() => onOk( lotInfo, action)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default HoldReleaseCancelProcessDialog;
