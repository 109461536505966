import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { useEffect, useState } from "react";

import { cloneDeep, debounce } from "lodash";

import AccessControl from "../functional/AccessControl";
import DeleteRecordButton from "../input/DeleteRecordButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import EguLaborCostCreateButton from "../input/EguLaborCostCreateButton";
import EguLaborCostUpdateButton from "../input/EguLaborCostUpdateButton";
import getSpecific from "../../lib/api/getSpecific";

function EguLaborCost() {

    const { t } = useTranslation();

    const [height, setHeight] = useState();

    const [grid, setGrid] = useState();
    const [detailGrid, setDetailGrid] = useState();
    const [api, setApi] = useState();
    const [detailApi, setDetailApi] = useState();
    const [onSelectMst, setOnSelectMst] = useState();
    const [, setOnSelectDetail] = useState();
    const [inputValue, setInputValue] = useState();
    const [deleteItem, setDeleteItem] = useState();

    const [isEmpty, setIsEmpty] = useState();
    const [schemam, setSchema] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguLaborCost");

            if (mounted) {
                setSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onInitialized = (grid) => {
        grid.selectionMode = "Row";
        setGrid(grid);

    };

    const onDetailInitialized = (grid) => {

        setDetailGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onDetailApiInitialized = (api) => {

        setDetailApi(api);

    };

    useEffect(() => {

        if (detailApi) {
            detailApi.refresh();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.baseYearMonth])

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);

                    }
                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);
                    }
                }, 10));




            }
        })();

    }, [grid]);

    useEffect(() => {

        (async () => {

            if (detailGrid) {

                detailGrid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectDetail(grid.selectedItems[0]);

                    }
                },10));

                detailGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectDetail(grid.selectedItems[0]);
                    }
                },10));
            }
        })();

    }, [detailGrid]);

    const onUpdateMaster = () => {

        if (grid) {
            (async () => {
                let isData = false;
                let position = grid.itemsSource.items.length;

                if (inputValue) {

                    let baseYearMonth;

                    if (typeof (inputValue?.baseYearMonth) === "string") {
                        baseYearMonth = inputValue?.baseYearMonth;
                    } else {
                        const year = inputValue?.baseYearMonth?.getFullYear();
                        const month = inputValue?.baseYearMonth?.getMonth() + 1;
                        const date = inputValue?.baseYearMonth?.getDate();
                        baseYearMonth = (`${year}-${month >= 10 ? month : '0' + month}-${date >= 10 ? date : '0' + date}`);
                    }
                    for (let [index, data] of grid.itemsSource.items?.entries()) {
                        if (data.baseYearMonth === baseYearMonth) {
                            isData = true;
                            position = index;
                            break;
                        }
                    }
                    if (isData) {
                        grid.select(position, 0)
                        grid.onSelectionChanged()
                        try {

                            let result = await getSpecific("EguVwLaborCost", grid.selectedItems[0]?.baseYearMonth);

                            Object.assign(grid.selectedItems[0], result);

                        } catch (reason) {
                            console.error(reason)
                        }



                    } else {
                        try {

                            let result = await getSpecific("EguVwLaborCost", baseYearMonth);


                            let data = cloneDeep(grid.itemsSource.items);
                            let collectionViewData = data.sort((a, b) => {
                                if (a.baseYearMonth === b.baseYearMonth) return 0;
                                return a.baseYearMonth > b.baseYearMonth ? 1 : -1;
                            });
                            collectionViewData.unshift({
                                ...result,
                                baseYearMonth: baseYearMonth
                            });
                            grid.collectionView.sourceCollection = collectionViewData;
                            for (let [index, data] of grid.itemsSource.items?.entries()) {
                                if (data.baseYearMonth === baseYearMonth) {
                                    isData = true;
                                    position = index;
                                    break;
                                }
                            }
                            grid.select(position, 0)
                            grid.onSelectionChanged()
                        } catch (reason) {
                            console.error(reason)
                        }
                    }

                    grid.invalidate(false);

                }
                if (deleteItem) {
                    if (api) {
                        api.refresh();
                        setInputValue(null)
                        setDeleteItem(null)

                    }
                }

            })();

        }
    }

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {

        onUpdateMaster();

        notifySuccess(msg);
    };


    const onModifyUrl = (url) => {
        return url + "/baseYearMonth/" + onSelectMst?.baseYearMonth;

    }

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const getFormData = (newFormData) => {
        if (newFormData?.baseYearMonth) {

            setInputValue(newFormData)
        }
    }

    const makeItemName = (item) => {
        setDeleteItem(item)
        return `${item?.baseYearMonth}일자 정보`
    }
    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={schemam && schemam.name}
                        accessMethod="POST"
                    >
                        <EguLaborCostCreateButton
                            id="EguLaborCost"
                            schema={schemam}
                            grid={detailGrid}
                            refresh={detailApi}
                            // disabled={isEmpty}
                            onSuccess={onSuccess}
                            getFormData={getFormData}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={schemam && schemam.name}
                        accessMethod={
                            schemam && (schemam.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <EguLaborCostUpdateButton
                            id="EguLaborCost"
                            schema={schemam}
                            grid={detailGrid}
                            refresh={detailApi}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={schemam && schemam.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguLaborCost"
                            schema={schemam}
                            grid={detailGrid}
                            refresh={detailApi}
                            color="secondary"
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            makeItemName={makeItemName}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id="EguVwLaborCost"
                        table="EguVwLaborCost"
                        view="EguVwLaborCost"
                        headerTitle={t("term.laborCost")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        exportFormatItem={() => { }}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    <BasicCrudScreenType
                        id="EguLaborCost"
                        table="EguLaborCost"
                        view="EguLaborCost"
                        headerTitle={t("term.laborCostDetail")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        exportFormatItem={() => { }}
                        onModifyViewUrl={(url) => onModifyUrl(url)}
                        onApiInitialized={onDetailApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        onInitialized={onDetailInitialized}
                        onLoadData={onLoadData}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>

    );
}

export default EguLaborCost;
