
/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import { useRecoilValue, useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import authProfileState from "../../states/authProfileState";
import { useTranslation } from "react-i18next";
import notificationState from "../../states/notificationState";

import layoutConf from "../../config/layoutConf";
import MomSysStockTakingDetail from "./MomSysStockTakingDetail";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ApprovalButton from "../input/ApprovalButton";
import MomSysApplyStockTakingButton from "../input/MomSysApplyStockTakingButton";
import CreateStockTakingButton from "../input/CreateStockTakingButton.js.js";
import {debounce} from "lodash";
import defineConf from "../../config/defineConf";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysMaterialStockTakingMst (props ) {

    const { t } = useTranslation ();

    const [ grid, setGrid ] = useState();

    const [ height, setHeight ] = useState(0);

    const [mstRefresh , setMstRefresh ] = useState ();
    
    const [ approvalCtx, setApprovalCtx ] = useState ();

    const authProfile = useRecoilValue ( authProfileState );

    const [ stockTakingMstSchema, setStockTakingMstSchema ] = useState ();

    const [ logAdjustSchema, setLogAdjustSchema ] = useState ();
    
    const [ onSelectMst, setOnSelectMst ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        handleResize();

        let mounted = true;

        (async() => {

          let schema ;

          schema = await getSchema("MomSysUglStockTakingMst");

          if ( mounted ) {

            setStockTakingMstSchema(schema);

          }

          schema = await getSchema("MomSysLogAdjust");

          if ( mounted ) {
            
            setLogAdjustSchema(schema);

          }
    
        })();
    
        return ( () => {

            mounted = false;
            window.removeEventListener('resize', handleResize);

        } );

      },[]);

      useEffect(() => {

        ( async () => {

            if (grid) {
                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };
                    
                }, 10));
                
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));
            }

        } ) ()


    }, [grid] )

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    }

    const onMstApiInitialized = ( api ) => {

        setMstRefresh ( api );

    }

    useEffect ( () => {
        
        let approvalCtx = {
    
            approvalTypeId: 7,
            tableName: "MomSysUglStockTakingMst",
            statusColumn: "approved",
            schema: stockTakingMstSchema,
            onValidateApprovalItem: ( item ) => {

                if ( item.createdBy !== authProfile.username ) {
    
                    return [ false, t( "error.approvalRequesterNotMatch" ) ];
        
                    
                }
        
                return [ true, "" ];
        
            },
            makeRequestItemDescription: ( item ) => {
                return `${t("approvalRequestMsg.msg.stockTaking", {stockTakingId : item.stockTakingId})}`; // Use Translation on Production Code
    
            }
        
        };
    
        setApprovalCtx ( approvalCtx );
    
    }, [stockTakingMstSchema] );

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType 
                        id="MomSysUglStockTakingMst"
                        table = "MomSysUglStockTakingMst"
                        view="MomSysZvwStockTakingMst" 
                        headerTitle = {t("term.stockTakingList")}
                        onInitialized={onInitialized}
                        onApiInitialized={onMstApiInitialized}
                        onLoadData = {onLoadData}
                        noEdit
                        noDelete
                        noCreate
                        noExcel
                        onModifyViewUrl={( url ) =>  `${url}/material`}
                        onAppendButtonInFrontOfSearch = {
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                    <CreateStockTakingButton
                                        id= "MomSysUglStockTakingMst"
                                        schema={stockTakingMstSchema}
                                        refresh={mstRefresh}
                                        onSuccess={onSuccess}
                                        stockTakingType = {defineConf.stockTakingType.MATERIAL}
                                    />
                                    <UpdateRecordButton
                                        id= "MomSysUglStockTakingMst"
                                        schema={stockTakingMstSchema}
                                        grid={grid}
                                        refresh={mstRefresh}
                                        disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteRecordButton
                                        id="MomSysUglStockTakingMst"
                                        schema={stockTakingMstSchema}
                                        grid={grid}
                                        refresh={mstRefresh}
                                        color="secondary"
                                        disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                    <ApprovalButton
                                        id= "MomSysUglProductStockTakingMst"
                                        grid={grid}
                                        refresh={mstRefresh}
                                        approvalCtx={approvalCtx}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        disabled = {isEmpty}
                                        />
                                    <MomSysApplyStockTakingButton
                                        id={"MomSysUglProductStockTakingMst"}
                                        schema={logAdjustSchema}
                                        grid={grid}
                                        refresh={mstRefresh}
                                        disabled = {!Boolean(onSelectMst?.approvalState === defineConf.approvalState.APPROVED) || Boolean(onSelectMst?.applyDate)}
                                        stockTakingId ={onSelectMst?.stockTakingId}
                                        onSuccess={notifySuccess}
                                    />

                                </BasicCrudScreenActionItemLayout>
                                </>
                                )
                            }
                        
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    {
                        onSelectMst ?
                        
                        <MomSysStockTakingDetail
                            id = "MomSysStockTakingDetail"
                            mstSchema = {stockTakingMstSchema}
                            stockTakingId = {onSelectMst.stockTakingId}
                            approvalState = {onSelectMst.approvalState}
                            materialType = {"material"}
                        />
                        :
                        <></>
                    }
                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysMaterialStockTakingMst;
