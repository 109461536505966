import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";

import notificationState from "../../states/notificationState";

import AccessControl from "../functional/AccessControl";

import EguPopNoticeCreateButton from "../input/EguPopNoticeCreateButton";
import EguPopNoticeUpdateButton from "../input/EguPopNoticeUpdateButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysNotice(props) {

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [schema, setSchema] = useState();

    const [updateDisabled, setUpdateDisabled] = useState(false)

    const [content, setContent] = useState();

    const [height, setHeight] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        handleResize();

        let mounted = true;

        (async () => {
            let schema = await getSchema("EguPopNotice");
            if (mounted) {
                setSchema(schema);
            }
        })();

        return (() => {

            mounted = false;
            window.removeEventListener('resize', handleResize);

        });

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        let data = grid.selectedItems[0]
                        let content = { title: data.title, notice: data.notice, noticeNo: data.noticeNo };
                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            content.notice = data.notice.replaceAll("/files", "http://localhost:8080/files")

                        }
                        setContent(content);

                    };

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        let data = grid.selectedItems[0]
                        let content = { title: data.title, notice: data.notice, noticeNo: data.noticeNo };
                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            content.notice = data.notice.replaceAll("/files", "http://localhost:8080/files")

                        }
                        setContent(content);

                    };

                });

                grid.addEventListener(grid.hostElement, "dblclick", () => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setContent(grid.selectedItems[0]);

                        window.open(`/newWindow/EguPopNotice/${grid.selectedItems[0].noticeNo}`, 'Notice', '_blank, width=' + window.screen.width + ', height=' + window.screen.height)
                        // setOpen(true);
                    }
                });
            }

        })()


    }, [grid])

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if (api) {
            api.refresh();
        }
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onLoadData = (data) => {
        setUpdateDisabled(false)
        if (data.length < 1) {
            setUpdateDisabled(true)
            setContent(null)
        }
    };

    const onAppendButtonAtFirst = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <EguPopNoticeCreateButton id="EguPopNotice" api={api} schema={schema} color="primary" createButtonName="create" onSuccess={onSuccess} />
                    </AccessControl>
                    <AccessControl accessTarget={schema && schema.name}
                        accessMethod={
                            schema && (schema.updateWithPatch ? "PATCH" : "PUT")
                        }>
                        <EguPopNoticeUpdateButton id="EguPopNotice" api={api} schema={schema} color="primary" createButtonName="create" onSuccess={onSuccess}
                            disabled={updateDisabled}
                            noticeNo={content && content.noticeNo} />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id="EguPopNotice"
                        table="EguPopNotice"
                        view="EguVwPopNotice"
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        noCreate
                        noEdit
                        onAppendButtonAtFirst={onAppendButtonAtFirst}
                        onLoadData={onLoadData}
                    />
                </ReflexElement>
                <ReflexSplitter />
                <ReflexElement data-cy="Detail">
                    {content ?
                        <div className="ql-snow">
                            <div className="ql-editor"
                                style={{ width: '100%', height: '100%', padding: '5% 10% 0% 10%', fontSize: '20px', overflow: 'hidden' }}
                            >
                                {parse(content.notice)}
                            </div>
                        </div>
                        : <></>
                    }
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysNotice;
