/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormLabel } from "@material-ui/core";
import { cloneDeep } from "lodash";

import checkCondition from "../../../lib/utils/checkCondition";
import checkValidation from "../../../lib/utils/checkValidation";
import AutoFormLayout from "../layout/AutoFormLayout";
import AutoFormGroupLayout from "../layout/AutoFormGroupLayout";
import AutoFormItem from "./AutoFormItem";
import AutoFormItemLayout from "../layout/AutoFormItemLayout";

import uiConf from "../../../config/uiConf";

const checkIsEmptyValue = (value) => {
    if (value === null || value === undefined || value === "") {
        return true;
    }

    return false;
}


function AutoForm ( props ) {

    const { t } = useTranslation ();
    const { id, schema, mode, initialData, noAutoFocus, onChange, setInitialDataCallback, modifyFormDataCallback, checkShowCallback, checkDisabledCallback, checkValidationCallback, barcodeCallback, focusRefs, ...others } = props;
    const [ formData, setFormData ] = useState( initialData );
    const [ orderedColumns, setOrderedColumns ] = useState([]);
    const [ interaction, setInteraction ] = useState( false );

    useEffect ( () => {

        setFormData ( ( prevFormData ) => {

            if ( schema ) {

                let newFormData = prevFormData ? cloneDeep( prevFormData ) : {};

                for ( const column of schema.columns ) {

                    if ( mode === "create" ) {

                        if ( column.formItemType === "file" ) {

                            newFormData[column.name] = {

                                deletedFiles: [],
                                activeFiles: []

                            }

                        } else {

                            newFormData[column.name] = column.defaultValue;

                        }

                    } else {
    
                        if ( column.password ) {

                            newFormData[column.name] = "";

                        }

                    }

                }

                // modify form data with setInitialDataCallback if exist

                if ( setInitialDataCallback ) {

                    setInitialDataCallback ( newFormData );

                }

                return newFormData;
            
            } else {

                return {};

            }

        } );

        let orderedColumns = schema ? schema.columns.slice() : [];

        if ( schema ) {

            if ( schema.useFormOrder ) {

                if ( schema.formGroups && schema.formGroups.length > 0 ) {

                    orderedColumns.sort( ( columnA, columnB ) => ( ( columnA.formGroup ? columnA.formGroup : 0 ) - ( columnB.formGroup ? columnB.formGroup : 0 ) ) ? ( ( columnA.formGroup ? columnA.formGroup : 0 ) - ( columnB.formGroup ? columnB.formGroup : 0 ) ) : ( ( columnA.formOrder ? columnA.formOrder : 0 ) - ( columnB.formOrder ? columnB.formOrder : 0 ) )) ;

                } else {
  
                    orderedColumns.sort( ( columnA, columnB ) => ( columnA.formOrder ? columnA.formOrder : 0 ) - ( columnB.formOrder ? columnB.formOrder : 0 ) );

                }

            }
            else {

                if ( schema.formGroups && schema.formGroups.length > 0 ) {

                    orderedColumns.sort( ( columnA, columnB ) => ( columnA.formGroup - columnB.formGroup ) );

                }
            }

        }

        setOrderedColumns ( orderedColumns );
    
    }, [schema] );

    useEffect ( () => {

        if ( mode === "edit" && schema ) {

            // transform file type form data here

            let formData = initialData ? cloneDeep(initialData) : {};

            for ( const column of schema.columns ) {

                if ( column.formItemType === "file" ) {

                    if ( column.maxFiles === undefined || column.maxFiles > 1 ) {

                        formData[column.name] = {
    
                            deletedFiles: [],
                            activeFiles: ( formData[column.name] && formData[column.name].length > 0 ) ? JSON.parse ( formData[column.name] ).map ( ( url ) => ( {
                                type: "url",
                                url: url
                            } ) ) : []
                            
                        }
    
                    } else {
    
                        formData[column.name] = {
    
                            deletedFiles: [],
                            activeFiles:
                                ( formData[column.name] && formData[column.name].length > 0 )
                                ?
                                [
                                    {
                                        type: "url",
                                        url: formData[column.name]
                                    }
                                ]
                                :
                                []
    
                        }
                    }
                }
    
            }

            setFormData ( formData );

        }
        
    }, [initialData] );

    const onChangeFormItem = ( name, value ) => {
       
        setFormData ( ( prevFormData ) => {
            
            let newFormData = { ...prevFormData, [name]: value };

            if ( modifyFormDataCallback ) {

                modifyFormDataCallback ( newFormData, name, value );

            }
            
            setTimeout ( () => onChange ( newFormData ), 0 ); // to improve latency

            return newFormData;
            
        } );
        setInteraction ( true );

    };

    let tabIndex = 0;

    return (

        <AutoFormLayout {...others} >
        {
            ( ( schema && schema.formGroups && schema.formGroups.length > 0 ) ? schema.formGroups : [""] )
                .map ( ( formGroupName, index ) => {

                    return (

                        <div key={index}>
                        
                        { index === 0 ? <></> : ( uiConf.displayRulerBetweenFormGroup ? <hr></hr> : <></> ) } {/* Horizontal Ruler between Form Groups */}
                    { ( uiConf.displayFormGroupName && formGroupName !== "" ) ? <FormLabel component="legend">{ t ( formGroupName ) }</FormLabel> : <></> } {/* FormGroup Name */}
                        
                        <AutoFormGroupLayout  {...others} >
                        
                        {   
                            orderedColumns
                                .filter ( ( column ) =>  {
                                    
                                    if ( column.hideInForm || ( mode === "create" && column.hideOnCreate ) || ( mode === "edit" && column.hideOnEdit ) ) {

                                        return false;

                                    }

                                    if ( column.requiredOn ) {

                                        column.required = checkCondition ( formData, column.requiredOn );
                                        
                                    }

                                    if ( column.hideOn ) {

                                        let hideByCondition = checkCondition ( formData, column.hideOn );

                                        if ( hideByCondition ) {

                                            return false;

                                        }

                                    }

                                    if ( column.formGroup === undefined) {

                                        return true;

                                    }

                                    if ( column.formGroup !== index ) {
                                       
                                        return false;
                                    }

                                    return true;

                                } )
                                .filter ( ( column ) => ( checkShowCallback ? checkShowCallback ( formData, column ) : true ) )
                                .map ( ( column ) => {

                                    let readonly = column.readonly || column.key === "PRI";

                                    let disabled = column.disabled;

                                    if ( ! disabled && column.disableOn ) {

                                        let disabledByCondition = checkCondition ( formData, column.disableOn );

                                        if ( disabledByCondition ) {

                                            disabled = true;
                                        }

                                    }

                                    if ( ! disabled && checkDisabledCallback ) {

                                        disabled = checkDisabledCallback ( formData, column );

                                    }

                                    let [ valid, helperText ] = checkValidation ( mode, column, column.validation, checkIsEmptyValue(formData[column.name]) ?  "" : formData[column.name]);

                                    // ignore password legth validation when edit mode

                                    if ( mode === "edit" && column.password && ( formData[column.name] || "" ) === "" ) {

                                        [ valid, helperText ] = [ true, "" ];

                                    }

                                    if ( valid && checkValidationCallback ) {

                                        [ valid, helperText ] = checkValidationCallback ( formData, column );

                                    }

                                    if ( ! ( disabled || ( mode === "edit" && readonly ) ) ) {

                                        tabIndex++;

                                    }

                                    return (

                                        <AutoFormItemLayout key={column.name} column={column}>

                                            <AutoFormItem

                                                id = { id + "-" + column.name }
                                                label = { t ( `columnName.${column.displayName || column.name}` ) }
                                                name = { column.name }

                                                disabled = { disabled || ( mode === "edit" && readonly ) }

                                                error = { interaction && ! valid }
                                                helperText = { interaction && helperText }

                                                column = { column }
                                                value = { formData && ( checkIsEmptyValue(formData[column.name]) ? ( checkIsEmptyValue(column.defaultValue) ? "" : column.defaultValue ) : formData[column.name] ) }

                                                onChange = { ( mode === "edit" && readonly )  ? () => {} : ( value ) => onChangeFormItem ( column.name, value ) }
                                                
                                                autoFocus = { ( tabIndex === 1 ) && ( ! noAutoFocus ) }

                                                barcodeCallback = {barcodeCallback}
                                                focusRef = {(el)=> {
                                                    if (typeof focusRefs !== 'undefined') {
                                                        focusRefs.current[column.name]=el
                                                    }
                                                }}
                                                focusRefCurrent = {focusRefs?.current[column.name]}
                                                
                                            />

                                        </AutoFormItemLayout>

                                    );

                                } )

                        }

                        </AutoFormGroupLayout>

                        </div>

                    );

                } )
        }
        </AutoFormLayout>

    )

}

export default AutoForm;

