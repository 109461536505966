import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {  cloneDeep } from "lodash";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import MomSysShipmentDetailCreateButton from "../input/MomSysShipmentDetailCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import DeleteRecordButton from "../input/DeleteRecordButton";


import notificationState from "../../states/notificationState";

import apiClient from "../../lib/common/apiClient";
import getSchema from "../../lib/api/getSchema";
import defineConf from "../../config/defineConf";

function MomSysShipmentDetail(props) {
    const { onSelectMst, onUpdateMaster, onDetailEmpty } = props;

    const id = "MomSysShipmentDetail";
    const table = "MomSysShipmentDetail";
    const view = "MomSysVwShipmentDetail";

    const [momSysShipmentDetailSchema, setMomSysShipmentDetailSchema] = useState();
    const [momSysShipmentDetailUpdateSchema, setMomSysShipmentDetailUpdateSchema] = useState();
    const [momSysShipmentDetailGrid, setMomSysShipmentDetailGrid] = useState();
    const [momSysShipmentDetailApi, setMomSysShipmentDetailApi] = useState();
    const [momSysShipmentDetailDataIsEmpty, setMomSysShipmentDetailDataIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        if (momSysShipmentDetailApi) {
            momSysShipmentDetailApi.refresh();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.shipmentNo]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysShipmentDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (momSysShipmentDetailSchema) {
            const updateSchema = cloneDeep(momSysShipmentDetailSchema);

            if (onSelectMst?.approvalState) {
                for (const column of updateSchema.columns) {
                    switch (column.name) {
                        case "shipmentQty":
                        case "shipmentMediumUnitQty":
                        case "shipmentSmallUnitQty":
                            break;

                        default:
                            column.readonly = true;
                            break;
                    }
                }
            } else {
                for (const column of updateSchema.columns) {
                    switch (column.name) {
                        case "shipmentQty":
                        case "shipmentMediumUnitQty":
                        case "shipmentSmallUnitQty":
                        case "description":
                        case "shipmentLotNoByItem":
                            break;

                        default:
                            column.readonly = true;
                            break;
                    }
                }
            }

            setMomSysShipmentDetailUpdateSchema(updateSchema);
        }
    }, [momSysShipmentDetailSchema, onSelectMst]);

    const stateUpdate = () => {
        apiClient.patch(`/api/${table}/shipmentState/${onSelectMst.orderBuyerId}`).then(onUpdateMaster);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        stateUpdate();

        notifyWarning(msg);

        if (momSysShipmentDetailApi) {
            momSysShipmentDetailApi.refresh();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        stateUpdate();

        notifySuccess(msg);

        if (momSysShipmentDetailApi) {
            momSysShipmentDetailApi.refresh();
        }
    };

    return (
        <BasicCrudScreenType
            id={id}
            table={table}
            view={view}
            headerTitle={t("term.shipmentDetail")}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={(data) => {
                setMomSysShipmentDetailDataIsEmpty(data.length < 1 ? true : false);

                if (onDetailEmpty) {
                    onDetailEmpty(data.length < 1 ? true : false);
                }
            }}
            onInitialized={(grid) => {
                setMomSysShipmentDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMomSysShipmentDetailApi(api);
            }}
            exportFormatItem={() => {}}
            onModifyViewUrl={(url) => `${url}/${onSelectMst?.shipmentNo}`}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    {/* <AccessControl
                        accessTarget={momSysShipmentDetailSchema && momSysShipmentDetailSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysShipmentDetailCreateAllButton
                            id={id}
                            schema={momSysShipmentDetailSchema}
                            color="primary"
                            createButtonName="shipmentCreateAll"
                            disabled={
                                onSelectMst?.approvalState ||
                                onSelectMst?.deliveryState === defineConf.deliveryState.COMPLETE_DELIVERY
                            }
                            onSuccess={onSuccess}
                            onSelectMst={onSelectMst}
                        />
                    </AccessControl> */}

                    <AccessControl
                        accessTarget={momSysShipmentDetailSchema && momSysShipmentDetailSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysShipmentDetailCreateButton
                            id={id}
                            schema={momSysShipmentDetailSchema}
                            color="primary"
                            createButtonName="create"
                            disabled={
                                onSelectMst?.approvalState ||
                                onSelectMst?.deliveryState === defineConf.deliveryState.COMPLETE_DELIVERY ||
                                onSelectMst.length <= 0 ? true : false
                            }
                            onSuccess={onSuccess}
                            onSelectMst={onSelectMst}
                            grid={momSysShipmentDetailGrid}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysShipmentDetailSchema && momSysShipmentDetailSchema.name}
                        accessMethod={
                            momSysShipmentDetailSchema && (momSysShipmentDetailSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id={id}
                            schema={momSysShipmentDetailUpdateSchema}
                            grid={momSysShipmentDetailGrid}
                            disabled={
                                //onSelectMst?.approvalState ||
                                onSelectMst?.deliveryState === defineConf.deliveryState.COMPLETE_DELIVERY ||
                                momSysShipmentDetailDataIsEmpty
                            }
                            onSuccess={onSuccess}
                            checkFormValidationCallback={(formData) => {
                                if (String(formData.shipmentQty).includes(".")) {
                                    return [false, t("error.invalidQtyNumber")];
                                }

                                return [true, ""];
                            }}
                            modifyFormDataCallback={(formData, name) => {
                                let numQty = Number(formData.shipmentQty);

                                if (name === "shipmentMediumUnitQty") {
                                    numQty =
                                        Number(formData.shipmentMediumUnitQty) /
                                        momSysShipmentDetailGrid.selectedItems[0].materialMediumUnitQty;
                                } else if (name === "shipmentSmallUnitQty") {
                                    numQty =
                                        Number(formData.shipmentSmallUnitQty) /
                                        momSysShipmentDetailGrid.selectedItems[0].materialSmallUnitQty;
                                }

                                formData.shipmentQty = numQty;
                                formData.shipmentMediumUnitQty =
                                    momSysShipmentDetailGrid.selectedItems[0].materialMediumUnitQty * numQty;
                                formData.shipmentSmallUnitQty =
                                    momSysShipmentDetailGrid.selectedItems[0].materialSmallUnitQty * numQty;
                            }}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysShipmentDetailSchema && momSysShipmentDetailSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id={id}
                            schema={momSysShipmentDetailSchema}
                            grid={momSysShipmentDetailGrid}
                            color="secondary"
                            disabled={
                                onSelectMst?.approvalState ||
                                onSelectMst?.deliveryState === defineConf.deliveryState.COMPLETE_DELIVERY ||
                                momSysShipmentDetailDataIsEmpty
                            }
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysShipmentDetailSchema && momSysShipmentDetailSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id={id}
                            grid={momSysShipmentDetailGrid}
                            view={view}
                            color="primary"
                            disabled={momSysShipmentDetailDataIsEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default MomSysShipmentDetail;
