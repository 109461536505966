/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysUdtCompanyPhoneCreateDialog from "../dialogTypes/MomSysUdtCompanyPhoneCreateDialog";

function MomSysUdtCompanyPhoneCreateButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, mstGridData, onSuccess, ...others} = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    useEffect (() => {
        let mounted = true;
        async function schemaSetting() {
            let defaultSchema = schema;
            if(mounted) {
                defaultSchema.columns.forEach((column) => columnSetting(column));
            }
        }

        function columnSetting(column) {
            switch(column.name) {
                case "companyPhoneCode" :
                    column.defaultValue = 0;

                    break;
                case "companyCode" :
                    column.defaultValue = mstGridData.companyCode

                    break;

                default :
            }
        }

        schemaSetting();
        
        return (() => {
            mounted = false;
        });
    }, [mstGridData]);

    const onOpen = () => {
        if(mstGridData) {
            setFormData({...mstGridData});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <MomSysUdtCompanyPhoneCreateDialog
                id={id+"append-dialog"}
                title={t(`dialog.title.${schema.name}.create`)}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default MomSysUdtCompanyPhoneCreateButton;

