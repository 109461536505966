import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function WorkDayManage ( props ) {

    return <BasicCrudScreenType id="VwWorkerDayOfWeekCapacity" table="WorkerDayOfWeekCapacity" view="VwWorkerDayOfWeekCapacity" noExcel />

}

export default WorkDayManage;

