import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import "react-reflex/styles.css";

import { CellType } from "@grapecity/wijmo.grid";

import notificationState from "../../states/notificationState";

function EstimateBase(props) {
    const { id, onInitialized } = props;

    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState();

    const [grid, setGrid] = useState();

    // Elements of estimateBaseInstance
    const [estimateBaseViewListInstance, setEstimateBaseViewListInstance] = useState();
    const [estimateNumber, setEstimateNumber] = useState();

    // eslint-disable-next-line no-unused-vars
    const [notification, setNotification] = useRecoilState(notificationState);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyError = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    useEffect(() => {
        if (grid) {
            grid.selectionChanged.addHandler((grid, event) => {
                setEstimateNumber(grid.selectedItems[0] && grid.selectedItems[0].estimateNumber);
            });

            grid.itemsSourceChanged.addHandler((grid, event) => {
                if (grid.selectedItems[0]) {
                    setEstimateNumber(grid.selectedItems[0].estimateNumber);
                }
            });
        }
    }, [grid]);

    useEffect(() => {
        let estimateBaseInstance = {
            viewListInstance: estimateBaseViewListInstance,
            estimateNumber: estimateNumber,
        };

        if (onInitialized) {
            onInitialized(estimateBaseInstance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimateBaseViewListInstance, estimateNumber]);

    const exportFormatItem = (args) => {
        // eslint-disable-next-line no-unused-vars
        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                default:
                    break;
            }
        }
    };

    return (
        <BasicCrudScreenType
            id={id}
            table="EstimateBase"
            view="VwEstimateBase"
            onInitialized={(grid) => {
                setGrid(grid);
            }}
            onApiInitialized={(instance) => {
                setEstimateBaseViewListInstance(instance);
            }}
            onLoadData={(data) => setData(data)}
            exportFormatItem={exportFormatItem}
            onNotifySuccess={notifySuccess}
            onNotifyError={notifyError}
            embededInOtherScreenType
        ></BasicCrudScreenType>
    );
}

export default EstimateBase;
