import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AddRoleSetNamespaceButton from "../input/AddRoleSetNamespaceButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";

function RoleSetNamespace ( props ) {

    const { t } = useTranslation();

    const { roleSet } = props;

    const [ grid, setGrid ] = useState();
    const [ api, setApi ] = useState ();
    const [ schema, setSchema ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    const setNotification = useSetRecoilState(notificationState);

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "RoleSetTranslation" );

            if ( mounted ) {
                setSchema ( schema );
            }

        } ) ();

        return ( () => {
            mounted = false;
        } );
        
    }, [] );

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [api] );

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [roleSet] );

    const onGridInitialized = ( ( grid ) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );

            } else {

                setDisabled ( true );

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );

            } else {

                setDisabled ( true );

            }

        } );
        
        setGrid ( grid );

    } );

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const makeItemName = ( row ) => {

        return `${t("columnName.roleSetName")}: ${row.roleSetName}, ${t("columnName.namespace")}: ${row.namespace}`;

    };

    const onModifyViewUrl = ( roleSet, url ) => {

        return `${url}/${roleSet?roleSet.roleSetId:undefined}`;

    }

    return <BasicCrudScreenType

        id="VwRoleSetTranslation"
        table="VwRoleSetTranslation"
        view="VwRoleSetTranslation"

        headerTitle={t("term.namespace")}

        noCreate
        noEdit
        noDelete
        noExcel
        
        noFilter
        
        onAppendButtonInFrontOfSearch={() =>

            <>

                <BasicCrudScreenActionItemLayout>

                    <AddRoleSetNamespaceButton
                        id="AddTranslationPath"
                        roleSet={roleSet}
                        refresh={api}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />

                </BasicCrudScreenActionItemLayout>

                <BasicCrudScreenActionItemLayout>

                    <DeleteSingleRecordButton
                        id="DeleteRoleSetNamespace"
                        disabled={disabled}
                        color="secondary"
                        grid={grid}
                        title={t("buttonName.deleteNamespace")}
                        schema={schema}
                        refresh={api}
                        makeItemName={makeItemName}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />

                </BasicCrudScreenActionItemLayout>

            </>

        }

        onInitialized={onGridInitialized}
        onApiInitialized={onApiInitialized}
        onModifyViewUrl={ ( url ) => onModifyViewUrl ( roleSet, url ) }

    />

}

export default RoleSetNamespace;

