/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import FilterApplyButtonLayout from "../layout/FilterApplyButtonLayout";

function FilterApplyButton ( props ) {

    const { filter, schema, onInitialized, onApply, onFilterChangeValue, ...others } = props;

    const [ , setInstance ] = useState();
    const [ , setFilterValue ] = useState();

    useEffect ( () => {

        let myInstance = {

            set: ( filterValue ) => {
                
                if ( filterValue ) {

                    setFilterValue ( [...filterValue] )

                } else {

                    let newFilterValue = [];

                    for ( const column of schema.columns ) {
            
                        if ( column.filter ) {
        
                            newFilterValue.push ( {
        
                                binding: column.name,
                                type: "condition",
                                and: true,
                                condition1: { operator: "", value: "" },
                                condition2: { operator: "", value: "" }
        
                            } );
        
                        }
                    }
    
                    return newFilterValue;
                        
                }

            }

        };

        setInstance ( myInstance );

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

    }, [] );

    const onClick = () => {

        setFilterValue ( ( filterValue ) => {
            filter.set ( filterValue );

            if ( onApply ) {
                
                onApply ( filterValue );

            }
            if(onFilterChangeValue) {
                onFilterChangeValue(filterValue);
            }

            return filterValue;

        } );

    }

    return <FilterApplyButtonLayout onClick={onClick} {...others} />

}

export default FilterApplyButton;

