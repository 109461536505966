import { useEffect,  useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import { useRecoilValue } from "recoil";
import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import authProfileState from "../../states/authProfileState";

import uiConf from "../../config/uiConf";
import layoutConf from '../../config/layoutConf';
import apiClient from '../../lib/common/apiClient';
import getSpecific from '../../lib/api/getSpecific';
import getSchema from '../../lib/api/getSchema';
import post from "../../lib/api/post";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import OrderBy from '../functional/OrderBy';
import Pagination from '../functional/Pagination';
import GetListApi from '../functional/GetListApi';
import AutoDataGrid from '../grid/functional/AutoDataGrid';
import PaginationControl from '../input/PaginationControl';

import ToolbarFlexSpacer from '../layout/ToolbarFlexSpacer';
import Button from '../input/Button';

import BasicCrudScreenHeaderLayout from '../screenTypes/layout/BasicCrudScreenHeaderLayout';
import BasicCrudScreenFooterLayout from '../screenTypes/layout/BasicCrudScreenFooterLayout';
import BasicCrudActionsLayout from '../screenTypes/layout/BasicCrudActionsLayout';
import BasicCrudScreenActionItemLayout from '../screenTypes/layout/BasicCrudScreenActionItemLayout';
import BasicCrudScreenContentLayout from '../screenTypes/layout/BasicCrudScreenContentLayout';

import ApprovalPath from "../presentation/ApprovalPath";

import ApprovalPathEditDialog from './ApprovalPathEditDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    }
}));

function ApprovalRequestDialog ( props ) {

    const { t } = useTranslation ();

    const authProfile = useRecoilValue ( authProfileState );

    const {

        id,

        approvalCtx,
        selectedItems,

        defaultOutputSize,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onSuccess,
        onApprovedCallback,

        onClose,
        onInitialized,

        customizeErrorMsgCallback,
        
        ...others

    } = props;

    const size = defaultOutputSize || uiConf.defaultOutputSize || 50;

    const [ , setInstance ] = useState ();
    const [ , setApi ] = useState ();
    const [ pagination, setPagination ] = useState();
    const [ count, setCount ] = useState();
    const [ grid, setGrid ] = useState();
    const [ schema, setSchema ] = useState ();
    const [ approvalPathId, setApprovalPathId] = useState();
    const [ approvalPath, setApprovalPath ] = useState ();
    const [ approvalPathNodes, setApprovalPathNodes ] = useState ();
    const [ approvalPathEditDialog, setApprovalPathEditDialog ] = useState ();
    const [ notification, setNotification ] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const classes = useStyles ();

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "VwApprovalPath" );

            for ( const column of schema.columns ) {

                switch ( column.name ) {

                    case "approvalPathName":

                        column.grid = {...column.grid, width: 550 };

                        break;

                    default:

                        column.grid = {...column.grid, hide: true };

                        break;

                }

            }

            if ( mounted ) {

                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted =false;

        } );

    }, [] );

    useEffect(

        () => {
            if (grid) {

                grid.headersVisibility = 1;

                grid.selectionChanged.addHandler((grid, event) => {

                    setApprovalPathId(
                        grid.selectedItems[0] && grid.selectedItems[0].approvalPathId
                    );

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems[0]) {
                        setTimeout ( () => setApprovalPathId( undefined ), 0 );
                        setTimeout ( () => setApprovalPathId(grid.selectedItems[0].approvalPathId), 0 );
                    }
                    
                });
            }
        },
        [grid]

    );

    useEffect ( () => {

        if ( approvalPathId ) {

            ( async () => {

                let approvalPath = await getSpecific ( "ApprovalPath", approvalPathId );

                setApprovalPath ( approvalPath );

                let apiResult = await apiClient.get ( `/api/VwApprovalPathNode/${approvalPathId}` );

                let approvalPathNodes = apiResult.data.data;

                approvalPathNodes.sort ( ( nodeA, nodeB ) => ( nodeA.order - nodeB.order ) );

                setApprovalPathNodes ( approvalPathNodes );


            } ) ();

        }

    }, [approvalPathId] );


    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onGridInitialized = grid => {

        setGrid(grid);

    };

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );
        
        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onPaginationInitialized = ( pagination ) => {

        setPagination ( pagination );

    };

    const onApprovalPathEditDialogInitialized = ( dialog ) => {

        setApprovalPathEditDialog ( dialog );

    }

    const onCountChange = ( count ) => {

        setCount ( count );

    };

    const onFailureWithReason = ( reason ) => {

        notifyFailure ( reason.response ? reason.response.data : reason );

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        if ( onClose ) {

            onClose ();
            
        }

    };

    const onOk = () => {

        setGrid ( ( grid ) => {

            ( async () => {

                let selectedItem = grid.selectedItems[0];

                if ( !selectedItem ) {

                    notifyFailure ( t("error.approvaPathNotSelected") );
                    return;

                }

                let requestData = {
                    approvalTypeId: approvalCtx.approvalTypeId,
                    tableName: approvalCtx.tableName,
                    requestItems: selectedItems,
                    statusColumn: approvalCtx.statusColumn,
                    approvalPathId: selectedItem.approvalPathId,
                    requestMsgs: selectedItems.map ( ( item ) => approvalCtx.makeRequestItemDescription ( item ) ),
                };

                post ( "ApprovalRequest", requestData )
                    .then ( ( result ) => {

                        if ( onSuccess ) {

                            onSuccess ();

                        }

                        if(onApprovedCallback) {
                            setTimeout(() => onApprovedCallback(), 0);
                        }

                        setInstance ( ( instance ) => {

                            instance.hide ();

                            return instance;

                        } );

                    } )
                    .catch ( ( reason ) => {

                        if ( customizeErrorMsgCallback ) {

                            notifyFailure(customizeErrorMsgCallback(reason));
                
                        } else {
                
                            notifyFailure ( t ( "error.approvalRequestFailure", { reason: reason } ) );

                        }

                    } );

            } )();
    
            return grid;

        } );

    };

    const onCancel = () => {

        setInstance ( ( instance ) => {

            instance.hide ();

            return instance;

        } );

    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onApprovalPathEditClose = () => {

        setApi ( ( api ) => {

            api.refresh ();
            return ( api );

        } );

    };

    return (

        <Dialog
            id={id || "approval-request"}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.approvalRequestDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            {
                uiConf.modalWithCloseButton
                ?
                <DialogHeaderWithClose>
                    <DialogTitle>
                        { t ( "dialog.title.Approval.request" ) }
                    </DialogTitle>
                </DialogHeaderWithClose>
                :
                <DialogHeader>
                    <DialogTitle>
                        { t ( "dialog.title.Approval.request" ) }
                    </DialogTitle>
                </DialogHeader>
            }

            <DialogContent className={classes.root} dividers data-cy="ApprovalRequestDialog">

                <div style={{height:400}}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.7}>

                            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">


                                <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                                    <ToolbarFlexSpacer xs />

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="primary" onClick={() => approvalPathEditDialog.show ()}>{t ( "dialog.title.ApprovalPath.manage" )}</Button>

                                    </BasicCrudScreenActionItemLayout>

                                </BasicCrudActionsLayout>

                                
                            </BasicCrudScreenHeaderLayout>

                            <BasicCrudScreenContentLayout>

                                <OrderBy schema={schema} grid={grid} >

                                    <Pagination size={size} onInitialized={onPaginationInitialized}>

                                        <GetListApi table={`VwApprovalPathIncludeNoOwner/${authProfile.username}`} onCountChange={onCountChange} onFailure={onFailureWithReason} onInitialized={onApiInitialized} >


                                            <AutoDataGrid id={(id || "approval-request")+"-data-grid"} alternatingRowStep={0} schema={schema} onInitialized={onGridInitialized} />


                                        </GetListApi>

                                    </Pagination>

                                </OrderBy>

                            </BasicCrudScreenContentLayout>

                            <BasicCrudScreenFooterLayout className="basicCrudScreenFooterLayout">

                                <ToolbarFlexSpacer xs />

                                <PaginationControl
                                    id={(id || "approval-request")+"-pagination-control"}
                                    className="pagintationConterol"
                                    color="primary"
                                    style={{leftMargin:0, rightMargin:0, marginTop: "12px"}}
                                    pagination={pagination}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    size={size}
                                    count={count}
                                />

                                <ToolbarFlexSpacer xs />

                            </BasicCrudScreenFooterLayout>

                        </ReflexElement>

                        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                        <ReflexElement style={{overflow: "hidden"}}>
                            <ApprovalPath id={(id || "approval-request")+"-approval-path"} noSelect approvalPath={approvalPath} approvalPathNodes={approvalPathNodes} />
                        </ReflexElement>

                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>

                </div>

            </DialogContent>

            <DialogActionsOkCancel
                labels={[t("buttonName.approvalRequest"),t("common.cancel")]}
                onOk={onOk}
                onCancel={onCancel}
            />

            <> {/* Dialog Component에 의해 property가 바뀌는 것을 막기 위해 ApprovalPAthEditDialog를 Dialog의 직접 자손이 되지 않도록 한다 */}
                <ApprovalPathEditDialog requester={authProfile.username} onClose={onApprovalPathEditClose} onInitialized={onApprovalPathEditDialogInitialized} />
            </>

        </Dialog>

    );

}

export default ApprovalRequestDialog;
