import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function RoleManage ( props ) {

    const { onSelect } = props;

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

            } else {


                if ( onSelect ) {

                    onSelect ( undefined );

                }

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

            } else {

                if ( onSelect ) {

                    onSelect ( undefined );

                }

            }
        } );

    }

    return <BasicCrudScreenType id="Role" table="Role" view="VwRole" onInitialized={onGridInitialized} />

}

export default RoleManage;
