/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ModifyTranslationValueButtonLayout from "../layout/ModifyTranslationValueButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSchema from "../../lib/api/getSchema";

function ModifyTranslationValueButton ( props ) {

    const { t } = useTranslation();

    const { id, translationValue, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ schema, setSchema ] = useState ();
    const [ formData, setFormData ] = useState ();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onModifySuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }
        
        refresh.refresh ();

    };

    useEffect ( () => {
        
        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "TranslationValue" );

            if ( mounted && schema ) {

                let column;

                column = {};

                column.name = "namespace";
                column.formGroup = 0;
                column.formOrder = 1;
                column.type = "text";
                column.readonly = true;     
                column.disabled = true;
                
                schema.columns.push ( column );

                column.name = "translationPath";
                column.formGroup = 0;
                column.formOrder = 2;
                column.type = "text";
                column.readonly = true;     
                column.disabled = true;
                
                schema.columns.push ( column );

                column = {};

                column.name = "language";
                column.formGroup = 0;
                column.formOrder = 3;
                column.type = "text";
                column.readonly = true;     
                column.disabled = true;
                
                schema.columns.push ( column );

                setSchema ( schema );

            }

        } ) ();
        

        return ( () => {
            mounted = false;
        } );

    }, [] );

    useEffect ( () => {

        if ( translationValue ) {

        setFormData( {

            namespaceId: translationValue.namespaceId,
            namespace: translationValue.namespace,
            translationPathId :  translationValue.translationPathId,
            translationPath: translationValue.translationPath,
            languageId : translationValue.languageId,
            language: translationValue.language,
            translationValue: translationValue.translationValue,

        } );

        } else {

            setFormData ( {} );
            
        }

    }, [ translationValue ] );


    return (

        <>
            <ModifyTranslationValueButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={ schema ? t ( `dialog.title.TranslationValue.update` )  : "" }
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onModifySuccess}
            />
        </>

    );
    
}

export default ModifyTranslationValueButton;

