import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateEstimateItemRecordButton from "../input/CreateEstimateItemRecordButton";

import AccessControl from "../functional/AccessControl";

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import "react-reflex/styles.css";

import { CellType } from "@grapecity/wijmo.grid";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

function EstimateDetail(props) {
    const { id, estimateId, onInitialized, estimateVersionViewListInstance } = props;

    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState();

    const [schema, setSchema] = useState();
    const [grid, setGrid] = useState();

    // estimateDetailInstance elements
    const [estimateDetailViewListInstance, setEstimateDetailViewListInstance] = useState();
    const [estimateItemId, setEstimateItemId] = useState();

    const [notification, setNotification] = useRecoilState(notificationState);

    const [disabled, setDisabled] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        (async () => {
            let schema = await getSchema("EstimateItem");
            if ( mounted ) {
                setSchema(schema);
            }
        })();
        return ( () => {
            mounted = false;
        } );
    }, []);

    useEffect(() => {
        if (grid) {
            grid.selectionChanged.addHandler((grid, event) => {
                setEstimateItemId(grid.selectedItems[0] && grid.selectedItems[0].estimateItemId);
            });

            grid.itemsSourceChanged.addHandler((grid, event) => {
                if (grid.selectedItems[0]) {
                    setEstimateItemId(grid.selectedItems[0].estimateItemId);
                }
            });
        }
    }, [grid]);

    useEffect(() => {
        if (estimateDetailViewListInstance) {
            estimateDetailViewListInstance.refresh();
        }
    }, [estimateDetailViewListInstance, estimateId]);

    useEffect(() => {
        if (notification.open) {
            if (estimateVersionViewListInstance) {
                estimateVersionViewListInstance.refresh();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification.open, estimateVersionViewListInstance]);

    useEffect(() => {

        if ( estimateId ) {

            setDisabled ( false );

        } else {

            setDisabled ( true );

        }

        let estimateDetailInstance = {
            viewListInstance: estimateDetailViewListInstance,
            estimateItemId: estimateItemId,
        };

        if (onInitialized) {
            onInitialized(estimateDetailInstance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimateId, estimateDetailViewListInstance, estimateItemId]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyError = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;

        return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;

    }

    const onAppendHeaderAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={"EstimateItem"} accessMethod="POST">
                    <CreateEstimateItemRecordButton
                        id={id}
                        schema={schema}
                        disabled={disabled}
                        estimateId={estimateId}
                        refresh={estimateDetailViewListInstance}
                        onSuccess={notifySuccess}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    ></CreateEstimateItemRecordButton>
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        );
    };

    const exportFormatItem = (args) => {
        // eslint-disable-next-line no-unused-vars
        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                default:
                    break;
            }
        }
    };

    return (
        <BasicCrudScreenType
            id={id}
            table="EstimateItem"
            view="VwEstimateDetail"
            disableCreate={disabled}
            disableEdit={disabled || !estimateItemId}
            disableDelete={disabled || !estimateItemId}
            disableExcel={disabled || !estimateItemId}
            disableApproval={disabled || !estimateItemId}
            onInitialized={(grid) => {
                setGrid(grid);
            }}
            onApiInitialized={(instance) => {
                setEstimateDetailViewListInstance(instance);
            }}
            onLoadData={(data) => setData(data)}
            exportFormatItem={exportFormatItem}
            onModifyViewUrl={(url) => url + "/" + estimateId}
            onNotifySuccess={notifySuccess}
            onNotifyError={notifyError}
            onAppendHeaderAtFirst={onAppendHeaderAtFirst}
            noCreate
            embededInOtherScreenType
        ></BasicCrudScreenType>
    );
}

export default EstimateDetail;
