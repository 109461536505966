/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import getSchema from "../../lib/api/getSchema";

const useStyles = makeStyles((theme) => ({

    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },

}));

function MomSysMaterialByCompanyDialog ( props ) {

    const  classes = useStyles ();
    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [grid, setGrid] = useState();
    const [material, setMaterial] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const [tableSchema, setTableSchema] = useState();
    const [schema2, setSchema2] = useState();
    const [, setApi] = useState();

    const {

        id,
        schema,
        process,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,
        refresh,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect( ()=> {
        
        let mounted = true;

        async function schemaSetting() {

            let schema = await getSchema("MomSysVwMaterialByCompanyCreate");

            if ( mounted ) {

                setSchema2(schema);
                setTableSchema(schema);
    
            }
        }

        schemaSetting();

        return ( () => {

            mounted = false;

        } );

    },[]);

    const checkItemValidation = ( schema, formData ) => {
        for ( const column of schema.columns ) {
            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );
            if ( !valid ) {
                nofifyWarning ( msg );
                return false;
            }
        }

        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn (msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error ( msg );
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };


    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMaterial(grid.selectedItems[0]);
                    }
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMaterial(grid.selectedItems[0]);
                    }
                });
            }
        })();
    }, [grid]);
    
    useEffect (() => {
        setFormData ( ( formData ) => {
            let newFormData = {
                ...formData,
                materialCode: material.materialCode,
                materialCodeVer: material.materialCodeVer,
                companyCode: material.companyCode,
                dialogMaterialName: material.dialogMaterialName,
                materialName: material.materialName,
                itemSpecName: material.itemSpecName,
                itemUnitName: material.itemUnitName,
                viewMainCompanyName: material.viewMainCompanyName,
                viewCost: toNumber(material.viewCost),
                viewMinPurchaseQty: toNumber(material.viewMinPurchaseQty),
                mainCustomer : 1
            };

            return newFormData;
        } );
    }, [material] );

    function toNumber(number) {
        if(number === null || number === "" || number === undefined){
            return "0";
        }
        return numberComma(number);
    }

    function numberComma(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const onDialogInitialized = ( instance ) => {
        setInstance(instance);
        if ( onInitialized ) {
            onInitialized ( instance );
        }
    };

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const refreshed = (msg) => {
        if (refresh) {
            refresh.refresh();
        }
    };

    const onApiSuccess = (result) => {
        if ( onSuccess ) {
            onSuccess ( t ( "success.insertSuccess", { table: "MomSysMaterialByCompany" } ) );
        }

        setFormData ( ( formData ) => {
            let newFormData = {
                ...formData,
                cost: 0,
                minPurchaseQty: 0
            }
            return newFormData;
        } );

        setKeepOpen ( ( keepOpen ) => {
            if ( !keepOpen ) {
                instance.hide();
            }

            return keepOpen;
        } );

        refreshed();
        if (onSuccess) {
            setTimeout ( onSuccess(result.data), 0 );
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );
    };

    const onOk = () => {
        // mainCustomer : 주거래처 (1), 부거래처 (2)
        if(Number(formData['mainCustomer']) === 1){
            checkMainCompanyAndSave();
            return;
        }
        save(schema, formData, false);
    }

    function checkMainCompanyAndSave() {
        let url = `/api/MomSysMaterialByCompany/${material.materialCode}/${material.materialCodeVer}`;

        apiClient
            .get(url)
            .then((result) => mainCompanyIsNotEmpty(toBoolean(result.data.data[0].mainCompanyIsNotEmpty)))
            .then((overlap) => save(schema, formData, overlap));
    }

    function toBoolean(number){
        if(number > 0){
            return true;
        }
        return false;
    }

    function mainCompanyIsNotEmpty(result) {
        if(result){
            notifyFailure("해당 품목은 주 거래처가 있습니다.");
            return true;
        }
    }

    function save(schema, formData, overlap) {
        if (overlap) {
            return;
        }
        
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        // build base api url
        let url = "/api/MomSysMaterialByCompany";
        let apiData = { ...formData };
        let form = new FormData ();
        let json = {};
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema && !schema.columnMap) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        for ( const formItem in apiData ) {
            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {
                deleted[formItem] = apiData[formItem].deletedFiles;
                for ( const file of apiData[formItem].activeFiles ) {
                    if ( file.type === "url" ) continue;
                    form.append ( formItem, file.file );
                }
            } else {
                if ( apiData[formItem] !== undefined ) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                companyCode: undefined,
                description: undefined,
                cost: undefined,
                mainCustomer: undefined,
                minPurchaseQty: undefined,
            }

            return newFormData;

        } );

        instance.hide();

    };

    const  checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>

            </>

        )

    }

    const onChange = ( formData ) => {
        
        setFormData ( formData );

        setInteraction ( true );

    }

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setInteraction ( false );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                companyCode: undefined,
                customerDescription: undefined,
                cost: undefined,
                mainCustomer: undefined,
            }

            return newFormData;

        } );
        
        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.MomSysMaterialByCompany.create" ) }
                </DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.55} >

                        <BasicCrudScreenType
                            id="MomSysVwMaterialByCompanyCreate"
                            embededInOtherScreenType
                            headerTitle={t("term.inputMaterial")}
                            view="MomSysVwMaterialByCompanyCreate"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={onGridInitialized}
                            onApiInitialized={onApiInitialized}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.45} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id="MomSysVwMaterialByCompanyCreateDialog"
                                schema={tableSchema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChange}
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[ t("common.add")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(schema2, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default MomSysMaterialByCompanyDialog;
