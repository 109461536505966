import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function PopProductShipLotCreateDialogDet(props) {
    const { shipmentSelect, dialogMstSelect, setDialogDetSelect, setDialogDetGrid, shipLotGrid } = props;
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                setDialogDetGrid(grid);
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setDialogDetSelect(grid.selectedItems[0]);
                    } else {
                        setDialogDetSelect([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setDialogDetSelect(grid.selectedItems[0]);
                    } else {
                        setDialogDetSelect([]);
                    };
                });

                grid.loadedRows.addHandler((flex, e) => {
                    let remaindQty = 0;
                    
                    let loop = shipLotGrid.rows.length;
                    for(let i = 0; i < loop; i++) {
                        remaindQty += shipLotGrid.rows[i]._data.shipQty;
                    }

                    let shipQtyValue = Number(shipmentSelect.shipmentQty) - Number(remaindQty);

                    for(const row of flex.rows) {
                        if(shipQtyValue !== 0 && row._data.qtyCurrent <= shipQtyValue) {
                            row.cssClass = `qty-equals-blue`;
                            shipQtyValue -= row._data.qtyCurrent;
                        }
                        
                        if (shipQtyValue !== 0 && row._data.qtyCurrent > shipQtyValue){
                            row.cssClass = `qty-equals-blue`;
                            shipQtyValue = 0;
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogMstSelect]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onModifyViewUrl = (url) => {
        return url + '/' + shipmentSelect.orderBuyerId + '/' + dialogMstSelect.materialCode + '/' + dialogMstSelect.materialCodeVer;
    }

    return (
        <>
            <BasicCrudScreenType
                id="PopProductShipLotCreateDet"
                table="PopVwProductShipLotDet"
                view="PopVwProductShipLotDet"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.itemByLotNo")}
                onModifyViewUrl={(url) => onModifyViewUrl(url)}
                embededInOtherScreenType
                noCreate
                noEdit
                noDelete
                noExcel
                noPagination
            />
        </>
    );
}

export default PopProductShipLotCreateDialogDet;
