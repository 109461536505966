import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import MomSysOutSourcingInButtonLayout from '../layout/MomSysOutSourcingInButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysOutSourcingInButton (props) {

    const {id, onSelect, grid, schema, refresh, onSuccess, standard, customizeErrorMsgCallback, ...others} = props;
    
    const [modifiedSchema, setModifiedSchema] = useState();

    const [formData, setFormData] = useState();

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    useEffect ( () => {

        if(schema) {
            
            let modifiedSchema = cloneDeep(schema);

            for ( const column of modifiedSchema.columns ) {
    
                switch ( column.name ) {
                   
                    case "companyCode" :
                        
                        column.hideInForm = true;

                        break;

                    default :

                        break;
                }

            }

            setModifiedSchema ( modifiedSchema );
        }

    }, [schema] );

    const onOpen = () => {

        if(grid) {

            setFormData ( {...(grid.selectedItems[0])} );

        }

        setOpen ( true );

    };

    const setInitialDataCallback = ( formData ) => {

        if ( !formData ) {

            formData = {};
            
        }

        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.qty = onSelect.qtyCurrent;
    }

    const onClose = () => {

        setOpen ( false );

    };

    const onOutSourcingInSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };

    const modifyUrlCallback = (url) => {

        let modifiedUrl;

        if(standard) {

            modifiedUrl = `/api/Material/${onSelect.lotNo}/inSourcing`;

        } else {

            modifiedUrl = `/api/NsMaterial/${onSelect.lotNo}/inSourcing`;
        }

       return modifiedUrl;

    }

    return (
        <>
        <MomSysOutSourcingInButtonLayout id = {id+"-outSourcingIn-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"outSourcingIn-dialog"}
                mode="create"
                formId={id+"outSourcingIn-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'outSourcingIn'}`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onOutSourcingInSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback = {setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                noKeepOpen
            />
        </>
    )

}

export default MomSysOutSourcingInButton;