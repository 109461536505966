import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

import defineConf from "../../config/defineConf";

function MomSysInspectionRsltUpdateButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);

        setFormData();
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <UpdateRecordButtonLayout id={`${id}-update-button`} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                modifyFormDataCallback={(formData) => {
                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                        formData.defectiveCode = "INS000";
                    }
                }}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    );
}

export default MomSysInspectionRsltUpdateButton;
