import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AutoFilterFormItem from "./AutoFilterFormItem";
import FilterFormItemGtGeCondition from "../input/FilterFormItemGtGeCondition";
import FilterFormItemLtLeCondition from "../input/FilterFormItemLtLeCondition";

import AutoFilterFormRangeLayout from "../layout/AutoFilterFormRangeLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";
import AutoFilterFormValueConditionItemLayout from "../layout/AutoFilterFormValueConditionItemLayout";

function AutoFilterFormRange ( props ) {

    const { t } = useTranslation ();

    const { id, tabIndex, column, filterValue, onChange } = props;

    const [ filter, setFilter ] = useState(

        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "", value: "" },
            condition2: { operator: "", value: "" }
        
        }

    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    const onChangeCondition1Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition1Operator = ( condition ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.operator = condition;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition2Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition2Operator = ( condition ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.operator = condition;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    return (

        <AutoFilterFormRangeLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem                    
                        id={id+"-condition1-value"}
                        column={column}
                        value={filter.condition1.value}
                        autoFoucs={tabIndex===1}
                        onChange={onChangeCondition1Value}
                />

            </AutoFilterFormValueItemLayout>
            
            <AutoFilterFormValueConditionItemLayout column={column} >

                <FilterFormItemGtGeCondition
                        id={id+"-condition1-operator"}
                        column={column}
                        label={t("dataTableFilter.inputTitle.filterOpr")}
                        value={filter.condition1.operator}
                        onChange={onChangeCondition1Operator}
                />

            </AutoFilterFormValueConditionItemLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem
                        id={id+"-condition2-value"}
                        column={column}
                        value={filter.condition2.value}
                        onChange={onChangeCondition2Value}
                />

            </AutoFilterFormValueItemLayout>
            
            <AutoFilterFormValueConditionItemLayout column={column} >

                <FilterFormItemLtLeCondition
                        id={id+"-condition2-operator"}
                        column={column}
                        label={t("dataTableFilter.inputTitle.filterOpr2")}
                        value={filter.condition2.operator}
                        onChange={onChangeCondition2Operator}
                />

            </AutoFilterFormValueConditionItemLayout>

        </AutoFilterFormRangeLayout>

    );

}

export default AutoFilterFormRange;

