import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import EguOutSourcingFakeInputButton from "../input/EguOutSourcingFakeInputButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import EguUpdateOutSourcingFakeInputButton from "../input/EguUpdateOutSourcingFakeInputButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import defineConf from "../../config/defineConf";

function EguOutSourcingFakeInput(props) {
    const id = "EguOutSourcingFakeInput";
    const table = "EguOutSourcingFakeInput";
    const view = "EguVwOutSourcingFakeInput";

    const { t } = useTranslation();

    const [schema, setSchema] = useState();

    const [eguOutSourcingFakeInputGrid, setEguOutSourcingFakeInputGrid] = useState();
    const [eguOutSourcingFakeInputApi, setEguOutSourcingFakeInputApi] = useState();
    const [eguOutSourcingFakeInputDataIsEmpty, setEguOutSourcingFakeInputDataIsEmpty] = useState();
    const [eguOutSourcingFakeInputSelect, setEguOutSourcingFakeInputSelect] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        (async () => {
            if (eguOutSourcingFakeInputGrid) {
                eguOutSourcingFakeInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingFakeInputSelect();
                        }
                    }, 10)
                );

                eguOutSourcingFakeInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingFakeInputSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingFakeInputSelect();
                        }
                    }, 10)
                );
            }
        })();
    }, [eguOutSourcingFakeInputGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <BasicCrudScreenType
            id={id}
            view={view}
            headerTitle={t("term.outSourcingFakeInput")}
            noExcel
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setEguOutSourcingFakeInputGrid(grid);
            }}
            onApiInitialized={(api) => {
                setEguOutSourcingFakeInputApi(api);
            }}
            onLoadData={(data) => {
                setEguOutSourcingFakeInputDataIsEmpty(data.length < 1 ? true : false);
            }}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <EguOutSourcingFakeInputButton
                        id={id}
                        schema={schema}
                        refresh={eguOutSourcingFakeInputApi}
                        onSuccess={onSuccess}
                    />

                    <EguUpdateOutSourcingFakeInputButton
                        id={id}
                        schema={schema}
                        grid={eguOutSourcingFakeInputGrid}
                        refresh={eguOutSourcingFakeInputApi}
                        disabled={
                            eguOutSourcingFakeInputDataIsEmpty ||
                            eguOutSourcingFakeInputSelect?.qcResult === defineConf.qcResult.QC_OK
                        }
                        onSuccess={onSuccess}
                    />

                    <DeleteSingleRecordButton
                        id={id}
                        schema={schema}
                        grid={eguOutSourcingFakeInputGrid}
                        refresh={eguOutSourcingFakeInputApi}
                        makeItemName={(row) => {
                            return ` - ${t("columnName.outSourcingFakeInputId")}: ${row.outSourcingFakeInputId}`;
                        }}
                        color="secondary"
                        disabled={
                            eguOutSourcingFakeInputDataIsEmpty ||
                            eguOutSourcingFakeInputSelect?.qcResult === defineConf.qcResult.QC_OK
                        }
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default EguOutSourcingFakeInput;
