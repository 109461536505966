import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import "./gridEvent.css"

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import ExceptionInButton from "../input/ExceptionInButton";
import ExceptionOutButton from "../input/ExceptionOutButton";
import { useTranslation } from "react-i18next";
import ExceptionInOutCancelButton from "../input/ExceptionInOutCancelButton";

function ExceptionInOut ( props ) {
    const id = "ExceptionInOut";

    const {t} = useTranslation();
    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("ExceptionInOut");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
        grid.selectionMode = "Row";
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const isDisabled = () => {
        let disabled = true;
        if(selectGrid) {
            disabled = false;
            if(selectGrid.type === 'cancel') {
                disabled = true;
            }
        }

        return disabled;
    }

    return <BasicCrudScreenType 
        id={id}
        table="ExceptionInOut" 
        view="ExceptionInOutView"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        noCreate
        noEdit
        noDelete
        noExcel
        onAppendButtonInFrontOfSearch={() => (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <ExceptionInButton
                            id = "ExceptionIn"
                            schema = {schema}
                            refresh = {api}
                            onSuccess = {onSuccess}
                            createButtonName = {"exceptionIn"}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <ExceptionOutButton
                            id = "ExceptionOut"
                            schema = {schema}
                            refresh = {api}
                            onSuccess = {onSuccess}
                            createButtonName = {"exceptionOut"}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <ExceptionInOutCancelButton
                            id={id}
                            schema={schema}
                            title={t("term.ExceptionInOutCancel")}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            onSelectGrid={selectGrid}
                            disabled={isDisabled()}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )} 
    />;
}

export default ExceptionInOut;
