import DialogActions from "./DialogActions";
import DialogActionButton from "./DialogActionButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { getOption } from "../../../lib/api/getOptions";

function DialogActionsOkCancel ( props ) {

    const { t } = useTranslation ();

    const { buttonDisabled, labels, onOk, onCancel, ...others } = props;

    const [ replaceDialogActionButtonLabel, setReplaceDialogActionButtonLabel ] = useState ( true );

    useEffect ( () => {

        let mounted = true;

        ( async () => {

                let replaceDialogActionButtonLabel = await getOption ( "default", "replaceDialogActionButtonLabel" );

                if ( mounted ) {
                    
                    if ( replaceDialogActionButtonLabel.toLowerCase() === "true" ) {

                        setReplaceDialogActionButtonLabel ( true );
    
                    } else {
    
                        setReplaceDialogActionButtonLabel ( false );
    
                    }
    
                }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    return (
        <DialogActions {...others} >
            <DialogActionButton disabled={buttonDisabled && buttonDisabled[0]} data-cy="OkButton" primary onClick={onOk} {...others}>{ ( replaceDialogActionButtonLabel && labels && labels[0] ) || t("common.ok")}</DialogActionButton>                    
            <DialogActionButton disabled={buttonDisabled && buttonDisabled[1]} data-cy="CancelButton" onClick={onCancel} {...others}>{ ( replaceDialogActionButtonLabel && labels && labels[1] ) || t("common.cancel")}</DialogActionButton>                    
        </DialogActions>
    );

}

export default DialogActionsOkCancel;

