import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysCodeGenerateCopyDialog from "../dialogTypes/MomSysCodeGenerateCopyDialog";

function MomSysCodeGenerateCopyButton ( props ) {

    const { t } = useTranslation();

    const { id, grid, refresh, onClose, onSuccess, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, modifyUrlCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [ schema, setSchema ] = useState(false);

    useEffect(() => {

        let schema = {

            name: "MomSysCodeGenerate",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {

                    name: "targetDB",
                    type: "varchar(255)",
                    nullable: "NO",
                    key: "",
                    default: null,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "VwTenant",
                        nameColumn: "tenantName",
                        valueColumn: "tenantId",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true
                    },
                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen ( true );

    };

    const onDialogClose = () => {

        setOpen ( false );

        if ( onClose ) {

            onClose ();

        }

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <MomSysCodeGenerateCopyDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.create` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onDialogClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                modifyUrlCallback={modifyUrlCallback}
                noKeepOpen={true}
                grid={grid}
            />
        </>

    );
}

export default MomSysCodeGenerateCopyButton;

