/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import getSchema from "../../lib/api/getSchema";
import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysMaterialByCompanyUpdateDialog ( props ) {
    const classes = useStyles();

    const {id, mode, schema, initialData, title, formId, moveable, 
           resizable, fullWidth, onInitialized, onSuccess, onClose, 
           onDialogUpdateDetail, grid, ...others} = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [instance, setInstance] = useState();
    const [tableSchema, setTableSchema] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const { t } = useTranslation();

    useEffect( ()=> {

        let mounted = true;

        async function tableSchemaSetting() {

            let schema = await getSchema("MomSysVwMaterialByCompanyUpdate");

            schema.columns.forEach(column => columnSetting(column));

            if ( mounted ) {
                setTableSchema(schema);
            }
            
        }

        function columnSetting(column) {
            if(column.name === "companyCode") {
                column.readonly = true;
            }
        }

        tableSchemaSetting();

        return ( () => {
            return mounted;
        } );

    }, []);

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        if (!keepOpen) {
            instance.hide();
        }

        if (onSuccess) {
            setTimeout(onSuccess(result.data), 0);
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );
    }

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );
            if ( !valid ) {
                nofifyWarning ( msg );
                return false;
            }
        }
        return true;
    }

    const onOk = () => {
        // mainCustomer : 주거래처 (1), 부거래처 (2)
        if(formData['mainCustomer'] === '1'){
            checkMainCompanyAndUpdate();
            return;
        }
        update(schema, formData, false);
    };

    function checkMainCompanyAndUpdate() {
        let url = `/api/MomSysMaterialByCompany/${formData.materialCode}/${formData.materialCodeVer}`;

        apiClient
            .get(url)
            .then((result) => mainCompanyIsNotEmpty(toBoolean(result.data.data[0].mainCompanyIsNotEmpty)))
            .then((overlap) => update(schema, formData, overlap))
    }

    function toBoolean(number) {
        if(number > 0){
            return true;
        }
        return false;
    }

    function mainCompanyIsNotEmpty(result) {
        if(result){
            notifyFailure("해당 품목은 주 거래처가 있습니다.");
            return true;
        }
    }

    function update(schema, formData, overlap) {
        if(overlap) {
            return;
        }

        if(!checkItemValidation(schema, formData)) {
            console.error('Form Validation Failed');
            return;
        }

        if(schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData, schema.validation
            );

            if(!valid) {
                nofifyWarning(t("warning.formDataValidationFail", {msg: msg}));
                return;
            }
        }

        // build base api url
        let url = '/api/MomSysMaterialByCompany';
        let apiData = { ...formData };
        let form = new FormData ();
        let json = {};
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema && !schema.columnMap) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        for ( const formItem in apiData ) {
            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {
                deleted[formItem] = apiData[formItem].deletedFiles;
                for ( const file of apiData[formItem].activeFiles ) {
                    if ( file.type === "url" ) continue;
                    form.append ( formItem, file.file );
                }
            } else {
                if ( apiData[formItem] !== undefined ) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .put(url, form, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    const onChange = (formData) => {
        setFormData(formData);
        setDisabled(false);
    };

    const onCloseDialog = () => {
        if(onClose){
            onClose();
        }
    }

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onCancel = () => {
        instance.hide();
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return(
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="md"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />
                    <DialogContent>
                        <AutoForm
                            id="MomSysMaterialByCompanyUpdateDialog"
                            schema={tableSchema}
                            mode="edit"
                            initialData={initialData}
                            onChange={onChange}
                        />
                        <Snackbar
                            open={notification.open && notification.severity !== "success"}
                            autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                            onClose={onCloseNotification}
                        >
                            <Alert
                                onClose={onCloseNotification}
                                variant="filled"
                                severity={notification.severity}
                            >
                                {notification.msg}
                            </Alert>
                        </Snackbar>
                    </DialogContent>
                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>);
}

export default MomSysMaterialByCompanyUpdateDialog;
