import React, { useEffect, useState } from "react";

import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";

import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysInspectionCriteria(props) {
    const [height, setHeight] = useState(0);

    const [inspectionCriteriaSchema, setInspectionCriteriaSchema] = useState();

    const [inspectionCriteriaGrid, setInspectionCriteriaGrid] = useState();
    const [inspectionGroupGrid, setInspectionGroupGrid] = useState();

    const [inspectionCriteriaIsEmpty, setInspectionCriteriaIsEmpty] = useState();

    const [inspectionCriteriaApi, setInspectionCriteriaApi] = useState();
    const [inspectionListApi, setInspectionListApi] = useState();

    const [inspectionGroupSelect, onInspectionGroupSelect] = useState();

    const { t } = useTranslation();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            if (mounted) {


                let inspectionCriteriaSchema = await getSchema("MomSysInspectionCriteria");
                setInspectionCriteriaSchema(inspectionCriteriaSchema);


            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {

        setInspectionListApi((api) => {
            if (api) {
                api.refresh();
            }

            return api;
        });

    }, [inspectionGroupSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (inspectionGroupGrid && mounted) {
                inspectionGroupGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });

                inspectionGroupGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });
            }
        })();

        return () => {
            mounted = false;
        };
    }, [inspectionGroupGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>

            <BasicCrudScreenType
                id={"MomSysInspectionCriteria"}
                headerTitle={t("term.momSysInspectionCriteria")}
                table={"MomSysInspectionCriteria"}
                view={"MomSysVwInspectionCriteria"}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setInspectionCriteriaGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setInspectionCriteriaApi(api);
                }}
                onLoadData={(data) => {
                    setInspectionCriteriaIsEmpty(data.length < 1 ? true : false);
                }}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <React.Fragment>
                        <BasicCrudScreenActionItemLayout>
                            <CreateRecordButton
                                id={"MomSysInspectionCriteria"}
                                schema={inspectionCriteriaSchema}
                                refresh={inspectionCriteriaApi}
                                onSuccess={onSuccess}
                            />
                            <UpdateRecordButton
                                id={"MomSysInspectionCriteria"}
                                schema={inspectionCriteriaSchema}
                                grid={inspectionCriteriaGrid}
                                refresh={inspectionCriteriaApi}
                                disabled={inspectionCriteriaIsEmpty}
                                onSuccess={onSuccess}
                            />
                            <DeleteRecordButton
                                id={"MomSysInspectionCriteria"}
                                schema={inspectionCriteriaSchema}
                                grid={inspectionCriteriaGrid}
                                refresh={inspectionCriteriaApi}
                                color="secondary"
                                disabled={inspectionCriteriaIsEmpty}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />
                        </BasicCrudScreenActionItemLayout>
                    </React.Fragment>
                )}
            />

        </div>
    );
}

export default MomSysInspectionCriteria;
