import { useState } from "react";
import { useTranslation } from "react-i18next";

import FixMachineFailureButtonLayout from "../layout/FixMachineFailureButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import apiClient from "../../lib/common/apiClient";

function FixMachineFailureButton ( props ) {

    const { t } = useTranslation();

    const { id, grid, refresh, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {

        let machine = grid.selectedItems[0];

        let id = machine.notAvailableId;
        let machineName = machine.machineName;

        setConfirmMsg ( t( "dialog.msg.confirm.fixMachine", { machine: machineName } ) );
        setConfirmData ( id );

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUpdateOk = ( id ) => {

        let url = `/api/FixMachineFailure/${id}`;

        apiClient.put ( url )
            .then ( ( result ) => {

                    onSuccess ( t ( "success.fixMachineFailureSuccess" ) );


            })
            .catch ( ( reason ) => {

                onFailure ( t ( "error.fixMachineFailureFailed", { reason: reason.response ? reason.response.data : reason } ))

            });

    }

    return (

        <>
            <FixMachineFailureButtonLayout id={id+"-fix-machine-failure-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"-fix-machine-failure-confirm-dialog"}
                title={ t ( "dialog.title.MomSysMstMachine.fixFailure" ) }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onUpdateOk}
                onCancel={onClose}
            />
        </>

    );
}

export default FixMachineFailureButton;

