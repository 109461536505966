/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function EguFakeInputOrderBuyerDetail(props) {
    const { id, onSelectMst } = props;

    const { t } = useTranslation();

    const [detailApi, setDetailApi] = useState();

    const onModifyUrl = (url, orderPurchaseId) => {
        return url + "/" + orderPurchaseId;
    };

    useEffect(() => {
        if (detailApi) {
            detailApi.refresh();
        }
    }, [onSelectMst.orderPurchaseId]);

    const onApiInitialized = (api) => {
        setDetailApi(api);
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.poDetail")}
            view="EguVwOrderPurchaseDetail"
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={(url) => onModifyUrl(url, onSelectMst.orderPurchaseId)}
            onInitialized={(grid) => (grid.selectionMode = "None")}
            noCreate
            noEdit
            noDelete
            noExcel
            embededInOtherScreenType
        />
    );
}

export default EguFakeInputOrderBuyerDetail;
