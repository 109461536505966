import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysBomHistory ( props ) {

    return <BasicCrudScreenType
        id="MomSysBomHistory" view="MomSysVwBomHistory"
        noCreate
        noEdit
        noDelete
        noExcel
    />

}

export default MomSysBomHistory;

