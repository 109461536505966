import { useState, useEffect } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";

function BatchHistoryDetail (props){

    const { t } = useTranslation();
    const { history } = props;

    const [ api, setApi ] = useState ( undefined ); 
    const [ executionId, setExecutionId ] = useState ( undefined );

    useEffect ( () => {

        if ( history ) {

            setExecutionId ( history?.historyId );
    
        }

    }, [ history ] );

    useEffect ( () => {

        if ( api && executionId ) {

            api.refresh ();

        }

    }, [ executionId, api ] );

    const onModifyViewUrl = ( url, executionId ) => {

        if ( executionId ) {

            return url + "/" + executionId;

        }

        return url + "/undefined";
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    return <BasicCrudScreenType
        id="BatchDetail"
        headerTitle={t("term.batchDetail")}
        table="BatchDetail"
        view="BatchDetail"
        noCreate
        noEdit
        noDelete
        noExcel
        onModifyViewUrl={( url ) => onModifyViewUrl ( url, executionId ) }
        onApiInitialized={onApiInitialized}
    />;

}
export default BatchHistoryDetail;