/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import gaConf from "../../config/gaConf";

import localizationConf from "../../config/localizationConf";
import authProfileState from "../../states/authProfileState";
import menuInfoState from "../../states/menuInfoState";
import topMenuSelectionState from "../../states/topMenuSelectionState";
import subMenuSelectionState from "../../states/subMenuSelectionState";

import makeSelectionByPath from "../../lib/utils/makeSelectionByPath";

import LoginRouter from "./LoginRouter";

import AppLayout from "../layout/AppLayout";
import TopPaneLayout from "../layout/TopPaneLayout";
import SidePaneLayout from "../layout/SidePaneLayout";
import MainPaneLayout from "../layout/MainPaneLayout";

import ComponentRouter from "./ComponentRouter";

import TopMenu from "../menu/functional/TopMenu";
import SubMenu from "../menu/functional/SubMenu";
import { useTranslation } from "react-i18next";
import NewWindowRouter from "./newWindowRouter";
import NewWindowLayout from "../layout/newWindowLayout";

function MesApp () {

    const authProfile = useRecoilValue( authProfileState );
    const menuInfo = useRecoilValue( menuInfoState );
    const setSubMenuSelection = useSetRecoilState(subMenuSelectionState);
    const [ topMenuSelection, setTopMenuSelection ] = useRecoilState(topMenuSelectionState);
    const [ sideMenuOpen, setSideMenuOpen] = React.useState(true);

    const { i18n } = useTranslation();

    const [ , setGaInitialized] = useState(false);
    const [ , setCookie ] = useCookies( [localizationConf.cookieName] );

    let location = useLocation ();

    useEffect(() => {

        // Initialize Google Analytics

        if ( gaConf.trackingId ) {

            ReactGA.initialize(gaConf.trackingId);
            setGaInitialized(true);
    
        }

    }, [] );    

    useEffect(() => {

        if ( ! i18n.language ) {

            i18n.changeLanguage("kr");
            setCookie(localizationConf.cookieName, "kr", {path: "/"});

        } else {

            setCookie(localizationConf.cookieName, i18n.language, {path: "/"});
            
        };

        let selection = makeSelectionByPath ( menuInfo, location.pathname );

        setTopMenuSelection ( selection.top );
        setSubMenuSelection ( selection.sub );

        setGaInitialized ( ( gaInitialized ) => {
                
            if ( !gaInitialized ) {

                // Initialize Google Analytics

                ReactGA.initialize(gaConf.trackingId);
                gaInitialized = true;
                
            }

            // Send page view
            
            ReactGA.set({ page: location.pathname });
            ReactGA.send("pageview");

            return gaInitialized;
    
        } );

    }, [menuInfo, location]);

    const handleDrawerOpen = () => {
        setSideMenuOpen(true);
    };
    
    const handleDrawerClose = () => {
        setSideMenuOpen(false);
    };

    return (

        <>
            
            {
                (location && location.pathname.split('/')[1] === 'newWindow')
                    ?
                    <NewWindowLayout>
                    <NewWindowRouter />
                    </NewWindowLayout>
                    :
                
                ( authProfile && authProfile.loggedIn )
                ?
                    (location && location.pathname.split('/')[2] === 'dashboard')
                    ?
                    <ComponentRouter/>
                    :
                    <AppLayout sideMenuOpen={sideMenuOpen}>
                        <TopPaneLayout className="topPaneLayout" handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}  sideMenuOpen={sideMenuOpen}>
                            <SubMenu  topMenuId={topMenuSelection} menuItems={menuInfo.subMenuItems}/>
                        </TopPaneLayout>
                        <SidePaneLayout className="sidePaneLayout" handleDrawerClose={handleDrawerClose} sideMenuOpen={sideMenuOpen}>
                            <TopMenu menuItems={menuInfo.topMenuItems}/>
                        </SidePaneLayout>
                        <MainPaneLayout  className="mainPaneLayout" >
                            <ComponentRouter/>
                        </MainPaneLayout >
                    </AppLayout>
                :
                    <LoginRouter />

            }

        </>

    );

}

export default MesApp;