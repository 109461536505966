import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import apiClient from "../../lib/common/apiClient";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function MomSysProductionCancelButton(props) {
    const { id, onSelectMst, refresh, onSuccess, onFailure, modifyUrlCallback, ...others } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setOpen(true);

        setConfirmMsg(t("dialog.msg.confirm.productionCancel", {lotNo: onSelectMst.lotNo}));
        setConfirmData(onSelectMst);
    };

    const onBackShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const onOk = (data) => {
        let url = `/api/MomSysProductionCancel/${onSelectMst.lotNo}`;

        if(modifyUrlCallback) {

            url = modifyUrlCallback(url);

        }

        let form = new FormData();

        let deleted = {};

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(data));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                onBackShipSuccess(result.data);
            })

            .catch((reason) => {
                onFailure(
                    t("error.productionCancel") +
                        ` : ${reason.response ? reason.response.data : JSON.stringify(reason)}`
                );
            });
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-production-cancel-button"}
                onClick={onOpen}
                createButtonName={"productionCancel"}
                {...others}
            />
            <ConfirmDialog
                id={id + "-production-cancel-confirm-dialog"}
                title={t("dialog.title.MomSysProduction.cancel")}
                open={open}
                msg={confirmMsg}
                onClose={onClose}
                onCancel={onClose}
                data={confirmData}
                onOk={onOk}
            />
        </>
    );
}

export default MomSysProductionCancelButton;
