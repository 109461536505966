import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DefaultWorkerDayOfWeekCapacityManage ( props ) {

    return <BasicCrudScreenType id="DefaultWorkerDayOfWeekCapacity" table="DefaultWorkerDayOfWeekCapacity" view="DefaultWorkerDayOfWeekCapacity" noExcel />

}

export default DefaultWorkerDayOfWeekCapacityManage;

