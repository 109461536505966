import axios from "axios";
import apiClientConf from "../../config/apiClientConf";
import setCookie  from "set-cookie";
import Tokens from "csrf";
class ApiClient {

    constructor () {

        this.instance = axios.create( apiClientConf.common );

        let tokens = new Tokens ();

        this.secret = tokens.secretSync ();
        this.token = tokens.create ( this.secret );
    
    }

    get ( url, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.get,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.get ( url, options );

    }

    post ( url, data, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.post,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.post ( url, data, options );

    }

    put ( url, data, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.put,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.put ( url, data, options );

    }

    patch ( url, data, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.patch,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.patch ( url, data, options );

    }

    del ( url, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.delete,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.delete ( url, options );

    }

    bulkDel ( url, data, options ) {

        options = {
            ...apiClientConf.commonOptions,
            ...apiClientConf.methodOptions.delete,
            ...options,
        }

        setCookie ( "CSRF_TOKEN", this.secret, {path: "/"} );
        options.headers = { ...options.headers, _csrf: this.token }

        return this.instance.request ( { url, ...options, data, method: "delete" } );

    }

}

export default new ApiClient();
