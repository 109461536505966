import urlencode from "urlencode";

function getUploadedFileOriginalName ( uploadedFileName ) {

    let searchFrom = 0;
    let count = 0;

    while ( true ) {

        searchFrom = uploadedFileName.indexOf ( "-", searchFrom ) + 1;
        count++;

        if ( count === 7 ) break;

    }

    return urlencode.decode ( uploadedFileName.slice ( searchFrom ) );

}

export default getUploadedFileOriginalName;

