import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function AttendanceManagement ( props ) {
    const id = "attendance";

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("AttendanceManagement");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const viewDate = (date) => {
        let now = date ? new Date(date) : new Date();
        let fixedDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 15, 0, 0));

        let utcYear = fixedDate.getUTCFullYear();
        let utcMonth = getSingleNumberAddZero(fixedDate.getUTCMonth() + 1);
        let utcDate = getSingleNumberAddZero(fixedDate.getUTCDate() - 1);
        let utcHours = getSingleNumberAddZero(fixedDate.getUTCHours());
        let utcMinutes = getSingleNumberAddZero(fixedDate.getUTCMinutes());
        let utcSeconds = getSingleNumberAddZero(fixedDate.getUTCSeconds());

        return `${utcYear}-${utcMonth}-${utcDate} ${utcHours}:${utcMinutes}:${utcSeconds}`;
    }

    const getSingleNumberAddZero = (value) => {
        return value.toString().padStart(2, "0");
    }

    return <BasicCrudScreenType 
        id={id} 
        table="AttendanceManagement" 
        view="AttendanceManagementView" 
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized} 
        noCreate
        noEdit
        noDelete
        noExcel
        onAppendButtonInFrontOfSearch={() => (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <CreateRecordButton
                            id={id}
                            schema={schema}
                            refresh={api}
                            onSuccess={onSuccess}
                            setInitialDataCallback={(formData) => {
                                if ("date" in formData) {
                                    formData.date = viewDate();
                                }
                            }}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                        <UpdateRecordButton 
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            disabled={selectGrid ? false : true}
                            onSuccess={notifySuccess}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <DeleteRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            disabled={selectGrid ? false : true}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )} 
    />;
}

export default AttendanceManagement;