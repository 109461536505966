import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function ExcelUploadSuccess (props){
    const { id, table, view, onSearchInitialized, onApiInitialized, fixedOrder} = props;

    const onGridInitialized = (grid) => {
        grid.allowSorting = false;
    };

    return (
        <BasicCrudScreenType
            id={id}
            table={table}
            view={view}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onInitialized={onGridInitialized}
            noHeader
            fixedOrder={fixedOrder}
        />
    );
}
export default ExcelUploadSuccess;