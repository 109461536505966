import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import { debounce, cloneDeep } from "lodash";

import { CellType } from "@grapecity/wijmo.grid";

import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";

import AccessControl from "../functional/AccessControl";

import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import ApprovalButton from "../input/ApprovalButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import CreateRecordButton from "../input/CreateRecordButton";
import OrderPurchaseInputConfirmButton from "../input/OrderPurchaseInputConfirmButton";

import notificationState from "../../states/notificationState";

import getSpecific from "../../lib/api/getSpecific";
import getSchema from "../../lib/api/getSchema";

import EguUglOrderPurchaseDetail from "./EguUglOrderPurchaseDetail";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import authProfileState from "../../states/authProfileState";

function EguUglOrderPurchaseMst(props) {
    const id = "EguUglOrderPurchaseMst";
    const table = "MomSysUglOrderPurchaseMst";
    const view = "EguVwOrderPurchaseMst";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [eguUglOrderPurchaseMstSchema, setEguUglOrderPurchaseMstSchema] = useState();
    const [eguUglOrderPurchaseMstUpdateSchema, setEguUglOrderPurchaseMstUpdateSchema] = useState();
    const [eguUglOrderPurchaseMstGrid, setEguUglOrderPurchaseMstGrid] = useState();
    const [eguUglOrderPurchaseMstSelect, setEguUglOrderPurchaseMstSelect] = useState();
    const [eguUglOrderPurchaseMstApi, setEguUglOrderPurchaseMstApi] = useState();
    const [eguUglOrderPurchaseMstData, setEguUglOrderPurchaseMstData] = useState();
    const [eguUglOrderPurchaseMstDataIsEmpty, setEguUglOrderPurchaseMstDataIsEmpty] = useState();
    const [eguUglOrderPurchaseDetailDataIsEmpty, setEguUglOrderPurchaseDetailDataIsEmpty] = useState();

    const [approvalCtx, setApprovalCtx] = useState();

    const authProfile = useRecoilValue(authProfileState);

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                for (const column of schema.columns) {
                    switch (column.name) {
                        case "submitDoc":
                            column.hideInForm = false;

                            break;

                        case "orderPurchaseDoc":
                        case "orderPurchaseDocFood":
                        case "downPayment":
                        case "middlePayment":
                            column.hideInForm = true;

                            break;

                        default:
                            break;
                    }
                }

                setEguUglOrderPurchaseMstSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (eguUglOrderPurchaseMstSchema) {
            const updateSchema = cloneDeep(eguUglOrderPurchaseMstSchema);

            for (const column of updateSchema.columns) {
                switch (column.name) {
                    case "inputCost":
                        column.hideInForm = false;
                        break;

                    default:
                        break;
                }
            }

            setEguUglOrderPurchaseMstUpdateSchema(updateSchema);
        }
    }, [eguUglOrderPurchaseMstSchema]);

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 8,
            tableName: table,
            statusColumn: "approved",
            schema: eguUglOrderPurchaseMstSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.po", { poName: item.plCode, poNumber: item.orderPurchaseId })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eguUglOrderPurchaseMstSchema]);

    useEffect(() => {
        (async () => {
            if (eguUglOrderPurchaseMstGrid) {
                eguUglOrderPurchaseMstGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderPurchaseMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderPurchaseMstSelect([]);
                        }
                    }, 10)
                );

                eguUglOrderPurchaseMstGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderPurchaseMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderPurchaseMstSelect([]);
                        }
                    }, 10)
                );

                eguUglOrderPurchaseMstGrid.loadedRows.addHandler(
                    debounce((grid, event) => {
                        for (let row of grid.rows) {
                            if (row.dataItem.inputState !== defineConf.orderPurchaseInputState.COMPLETED) {
                                if (new Date(row.dataItem.dueDate) < new Date()) {
                                    row.cssClass = `failed-stock-apply`;
                                } else {
                                    row.cssClass = null;
                                }
                            }
                        }
                    }),
                    10
                );
            }
        })();
    }, [eguUglOrderPurchaseMstGrid]);

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (eguUglOrderPurchaseMstData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (eguUglOrderPurchaseMstData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onUpdateMaster = () => {
        if (eguUglOrderPurchaseMstGrid) {
            (async () => {
                const updateTarget = eguUglOrderPurchaseMstGrid.selectedItems[0];

                let purchaseMst = await getSpecific("EguVwOrderPurchaseMst", updateTarget.orderPurchaseId);

                updateTarget.cost = purchaseMst.inputCost ? purchaseMst.inputCost : purchaseMst.cost;
                updateTarget.inputState = purchaseMst.inputState;
                eguUglOrderPurchaseMstGrid.refresh(false); // grid.refresh(false) : grid에 즉시 반영
            })();
        }
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        headerTitle={t("term.po")}
                        table={table}
                        view={view}
                        onInitialized={(grid) => {
                            setEguUglOrderPurchaseMstGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguUglOrderPurchaseMstApi(api);
                        }}
                        onLoadData={(data) => {
                            setEguUglOrderPurchaseMstData(data);

                            setEguUglOrderPurchaseMstDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        exportFormatItem={exportFormatItem}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod="POST"
                                >
                                    <CreateRecordButton
                                        id={id}
                                        schema={eguUglOrderPurchaseMstSchema}
                                        refresh={eguUglOrderPurchaseMstApi}
                                        setInitialDataCallback={(formData) => {
                                            if ("orderPurchaseDate" in formData) {
                                                formData.orderPurchaseDate = new Date();
                                            }
                                        }}
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod={
                                        eguUglOrderPurchaseMstSchema &&
                                        (eguUglOrderPurchaseMstSchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <UpdateRecordButton
                                        id={id}
                                        schema={eguUglOrderPurchaseMstUpdateSchema}
                                        grid={eguUglOrderPurchaseMstGrid}
                                        refresh={eguUglOrderPurchaseMstApi}
                                        disabled={
                                            (eguUglOrderPurchaseMstSelect?.approvalState ? true : false) ||
                                            eguUglOrderPurchaseMstDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod="DELETE"
                                >
                                    <DeleteRecordButton
                                        id={id}
                                        schema={eguUglOrderPurchaseMstSchema}
                                        grid={eguUglOrderPurchaseMstGrid}
                                        refresh={eguUglOrderPurchaseMstApi}
                                        color="secondary"
                                        disabled={
                                            (eguUglOrderPurchaseMstSelect?.approvalState ? true : false) ||
                                            eguUglOrderPurchaseMstDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        onPartialFailure={onNotifyWarning}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod="APPROVAL"
                                >
                                    <ApprovalButton
                                        id={id}
                                        grid={eguUglOrderPurchaseMstGrid}
                                        refresh={eguUglOrderPurchaseMstApi}
                                        approvalCtx={approvalCtx}
                                        disabled={
                                            eguUglOrderPurchaseMstDataIsEmpty || eguUglOrderPurchaseDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod={
                                        eguUglOrderPurchaseMstSchema &&
                                        (eguUglOrderPurchaseMstSchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <OrderPurchaseInputConfirmButton
                                        id={id}
                                        schema={eguUglOrderPurchaseMstSchema}
                                        grid={eguUglOrderPurchaseMstGrid}
                                        refresh={eguUglOrderPurchaseMstApi}
                                        disabled={
                                            eguUglOrderPurchaseMstSelect?.approvalState !==
                                            defineConf.approvalState.APPROVED ||
                                            eguUglOrderPurchaseMstSelect?.inputState ===
                                            defineConf.orderPurchaseInputState.COMPLETED ||
                                            eguUglOrderPurchaseDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderPurchaseMstSchema && eguUglOrderPurchaseMstSchema.name}
                                    accessMethod="SAVETOEXCEL"
                                >
                                    <SaveToExcelButton
                                        id={id}
                                        grid={eguUglOrderPurchaseMstGrid}
                                        view={view}
                                        color="primary"
                                        exportFormatItem={exportFormatItem}
                                        disabled={eguUglOrderPurchaseMstDataIsEmpty}
                                    />
                                </AccessControl>
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    {eguUglOrderPurchaseMstSelect ? (
                        <EguUglOrderPurchaseDetail
                            onSelectMst={eguUglOrderPurchaseMstSelect}
                            onUpdateMaster={onUpdateMaster}
                            onDetailEmpty={(isEmpty) => {
                                setEguUglOrderPurchaseDetailDataIsEmpty(isEmpty);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguUglOrderPurchaseMst;
