import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import React from "react";
import { useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import AccessControl from "../functional/AccessControl";
import { debounce } from "lodash";
import getSchema from "../../lib/api/getSchema";
import PopNoOrderPurchaseInputDoInputButton from "../input/PopNoOrderPurchaseInputDoInputButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

function PopNoOrderPurchaseInput(props) {
    const id = "PopNoOrderPurchaseInput";
    const table = "MomSysNoOrderPurchaseInput";
    const view = "MomSysVwNoOrderPurchaseInput";

    const { t } = useTranslation();
    const [height, setHeight] = useState(0);

    
    const [momSysZvwMaterialGrid, setMomSysZvwMaterialGrid] = useState();
    const [, setMomSysZvwMaterialApi] = useState();
    const [, setMomSysZvwMaterialSelect] = useState();

    const [noOrderPurchaseInputSchema, setNoOrderPurchaseInputSchema] = useState();
    const [noOrderPurchaseInputGrid, setNoOrderPurchaseInputGrid] = useState();
    const [noOrderPurchaseInputApi, setNoOrderPurchaseInputApi] = useState();
    const [noOrderPurchaseInputSelect, setNoOrderPurchaseInputSelect] = useState();
    const [, setNoOrderPurchaseInputDataIsEmpty] = useState();

    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                
                schema.name = "PopNoOrderPurchaseInput"
                
                setNoOrderPurchaseInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {

        const path = `popNoOrderPurchaseInputLot/${noOrderPurchaseInputSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl ( url );

    }, [noOrderPurchaseInputSelect]);    

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (noOrderPurchaseInputGrid && mounted) {
                noOrderPurchaseInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setNoOrderPurchaseInputSelect(grid.selectedItems[0]);
                        } else {
                            setNoOrderPurchaseInputSelect();
                        }
                    },10)
                );

                noOrderPurchaseInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) { 
                            setNoOrderPurchaseInputSelect(grid.selectedItems[0]);
                        } else {
                            setNoOrderPurchaseInputSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [noOrderPurchaseInputGrid]);

    useEffect(() => {
        (async () => {
            if (momSysZvwMaterialGrid) {
                momSysZvwMaterialGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysZvwMaterialSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysZvwMaterialSelect();
                        }
                    },10)
                );

                momSysZvwMaterialGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysZvwMaterialSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysZvwMaterialSelect();
                        }
                    },10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [momSysZvwMaterialGrid]);

    const onModifyViewSchema = (schema) => {
        if (schema) {
            schema['orderBy'] = [{column: "createdAt", order: "desc"}]
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    return (
        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <BasicCrudScreenType
                            id="MomSysZvwMaterial"
                            headerTitle={t("tableName.MomSysZvwMaterial")}
                            view="MomSysZvwMaterial"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setMomSysZvwMaterialGrid(grid);
                            }}
                            onApiInitialized={(api) => {
                                setMomSysZvwMaterialApi(api);
                            }}
                            // embededInOtherScreenType
                            onAppendButtonInFrontOfSearch={() => (
                                <PopCrudScreenActionItemLayout>
                                    <AccessControl
                                        accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                                        accessMethod={
                                            noOrderPurchaseInputSchema &&
                                            (noOrderPurchaseInputSchema.updateWithPatch ? "PATCH" : "PUT")
                                        }
                                    >
                                        <PopNoOrderPurchaseInputDoInputButton
                                            id={id}
                                            schema={noOrderPurchaseInputSchema}
                                            grid={momSysZvwMaterialGrid}
                                            refresh={noOrderPurchaseInputApi}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl>
                                </PopCrudScreenActionItemLayout>
                            )}
                        />
                </ReflexElement>
                <ReflexSplitter />
                <ReflexElement flex={0.5} >
                    
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.popNoOrderPurchaseInputHisory")}
                        // fixedOrder={[{column: "createdAt", order: "desc"}]}
                        
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setNoOrderPurchaseInputGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setNoOrderPurchaseInputApi(api);
                        }}
                        onLoadData={(data) => {
                            setNoOrderPurchaseInputDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>
                                <PrintReportButton
                                    noLayout
                                    url={reportUrl}
                                    disabled={!noOrderPurchaseInputSelect?.lotNo}
                                >
                                    {t("buttonName.lotInfoPrint")}
                                </PrintReportButton>
                            </PopCrudScreenActionItemLayout>
                        )}
                        onModifyViewSchema={onModifyViewSchema}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement> 
            </ReflexContainer>
        </div>
    )

}

export default PopNoOrderPurchaseInput;