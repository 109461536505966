/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useRecoilValue } from "recoil";

import authProfileState from "../../states/authProfileState";
import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function PopOutSourcingShipDetailCreateDialog(props) {
    const {
        id,
        schema,
        popOutSourcingShipTargetSelect,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const authProfile = useRecoilValue(authProfileState);

    const focusRefs = useRef([]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        // Barcode로 엉뚱한 품목을 읽어들인 경우 체크
        if (
            popOutSourcingShipTargetSelect.materialCode !== formData.materialCode ||
            popOutSourcingShipTargetSelect.materialCodeVer !== formData.materialCodeVer
        ) {
            const msg = t("error.materialMismatch", {
                materialCode: popOutSourcingShipTargetSelect.materialCode,
                materialCodeVer: popOutSourcingShipTargetSelect.materialCodeVer,
            });

            notifyWarning(msg);
            return false;
        }

        return true;
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();

                if (onSuccess) {
                    setTimeout(() => onSuccess(result.data), 0);
                }
            } else {
                if (onSuccess) {
                    setTimeout(() => onSuccess(result.data), 0);
                } else {
                    notifySuccess(result.data);
                }
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(t(reason.response.data));
    };

    const barcodeCallback = (barcodeInfo) => {
        let toBeShippedQty =
            popOutSourcingShipTargetSelect.outSourcingMaterialQty -
            popOutSourcingShipTargetSelect.outSourcingShipTotalQty;

        if (toBeShippedQty < 0) {
            toBeShippedQty = 0;
        }

        setFormData((formData) => {
            let newFormData = {
                ...formData,

                outSourcingId: popOutSourcingShipTargetSelect.outSourcingId,
                outSourcingDetailId: popOutSourcingShipTargetSelect.outSourcingDetailId,
                shipManager: authProfile.username,

                materialCode: barcodeInfo.materialCode,
                materialCodeVer: barcodeInfo.materialCodeVer,
                materialName: barcodeInfo.materialName,
                materialTypeName: barcodeInfo.materialTypeName,
                itemSpecName: barcodeInfo.itemSpecName,
                itemUnitName: barcodeInfo.itemUnitName,
                manufacturedDate: barcodeInfo.manufacturedDate,
                expirationDate: barcodeInfo.expirationDate,
                jaHo: barcodeInfo.jaHo,

                lotNo: barcodeInfo.lotNo,
                outSourcingShipQty: toBeShippedQty > barcodeInfo.qtyCurrent ? barcodeInfo.qtyCurrent : toBeShippedQty,
            };

            for (const column of schema.columns) {
                if (column?.defaultValue !== undefined) {
                    newFormData[column.name] = column.defaultValue;
                }
            }

            setOkButtonDisabled(false);

            return newFormData;
        });

        focusRefs.current["outSourcingShipQty"].focus();
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url;

        url = `/api/${schema.name}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData(null);

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);
        setOkButtonDisabled(false);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData(null);

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t(`dialog.title.${schema.name}.create`)}</DialogTitle>
            </DialogHeaderWithClose>
            <Divider />

            <DialogContent>
                <div
                    style={{
                        padding: "16px",
                    }}
                >
                    <AutoForm
                        id="pop-outsourcing-ship-detail-create-form"
                        schema={schema}
                        mode="edit"
                        initialData={formData}
                        onChange={onChangeFormData}
                        barcodeCallback={barcodeCallback}
                        focusRefs={focusRefs}
                    />
                </div>
                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <Divider />
            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PopOutSourcingShipDetailCreateDialog;
