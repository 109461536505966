import {useEffect, useState} from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysOwnCompany from './MomSysOwnCompany';
import MomSysOwnCompanyPhone from './MomSysOwnCompanyPhone';
import MomSysOwnCompanyBankAccount from './MomSysOwnCompanyBankAccount';
import MomSysOwnCompanyBranch from './MomSysOwnCompanyBranch';

function MomSysOwnCompanyManager ( props ) {
    const [height, setHeight] = useState(0);
    const [mstGridData, setMstGridData] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const masterGridSetting = (data) => {
        setMstGridData(data)
    } 

    return (
        <div style={{height: height}}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement data-cy="OwnCompany" flex={0.35}>
                    <MomSysOwnCompany
                        id="MomSysOwnCompany"
                        masterGridSetting={masterGridSetting}
                    />
                </ReflexElement>
                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />
                <ReflexElement>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.5}>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement data-cy="OwnCompanyPhone" flex={0.5}>
                                    {
                                        (mstGridData)
                                        ?
                                        <MomSysOwnCompanyPhone
                                            id="MomSysOwnCompanyPhone"
                                            mstGridData={mstGridData}
                                        />
                                        :
                                        <></>
                                    }
                                </ReflexElement>
                                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />
                                <ReflexElement data-cy="OwnCompanyBankAccount">
                                    {
                                        (mstGridData)
                                        ?
                                        <MomSysOwnCompanyBankAccount
                                            id="MomSysOwnCompanyBankAccount"
                                            mstGridData={mstGridData}
                                        />
                                        :
                                        <></>
                                    }
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />
                        <ReflexElement data-cy="OwnCompanyBranch" >
                            {
                                (mstGridData)
                                ?
                                <MomSysOwnCompanyBranch
                                    id="MomSysOwnCompanyBranch"
                                    mstGridData={mstGridData}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );

}

export default MomSysOwnCompanyManager;
