import { useState, useEffect } from "react";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguOutSourcingShipDialog from "../dialogTypes/EguOutSourcingShipDialog";

function EguOutSourcingShipButton(props) {
    const { id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-create-button"}
                onClick={onOpen}
                createButtonName={createButtonName}
                {...others}
            />
            <EguOutSourcingShipDialog
                id={id + "-create-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default EguOutSourcingShipButton;
