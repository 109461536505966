import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { CookiesProvider } from "react-cookie";

import MesApp from "./components/apps/MesApp";

function App() {
  return (
    <BrowserRouter>
        <RecoilRoot>
        <CookiesProvider>
            <MesApp />
        </CookiesProvider>
        </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
