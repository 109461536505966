import { useState, useEffect } from 'react';

import EguProductionMonitoring from './EguProductionMonitoring';
import EguSalesMonitoring from './EguSalesMonitoring';
import EguManagementIndicator from './EguManagementIndicator';

function DashboardScreen ( props ) {

    const { index, handle, onComplete } = props;

    switch ( index ) {

        case 1:

            return <EguManagementIndicator onComplete={onComplete}  handle={handle} />;

        case 2:

            return <EguSalesMonitoring onComplete={onComplete}  handle={handle} />;

        default:
            
            return <EguProductionMonitoring onComplete={onComplete}  handle={handle} />;

    }

}

function EguDashboard ( props ) {

    let doc = document.documentElement;

    function openFullScreenMode() {
        if (doc.requestFullscreen)
            doc.requestFullscreen();
        else if (doc.webkitRequestFullscreen) // Chrome, Safari (webkit)
            doc.webkitRequestFullscreen();
        else if (doc.mozRequestFullScreen) // Firefox
            doc.mozRequestFullScreen();
        else if (doc.msRequestFullscreen) // IE or Edge
            doc.msRequestFullscreen();        
    }

    function closeFullScreenMode() {
        if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.webkitExitFullscreen) // Chrome, Safari (webkit)
            document.webkitExitFullscreen();
        else if (document.mozCancelFullScreen) // Firefox
            document.mozCancelFullScreen();
        else if (document.msExitFullscreen) // IE or Edge
            document.msExitFullscreen();
    }

    const [index, setIndex] = useState ( 0 );
    const [handle, setHandle] = useState ({
        active: false,
        enter: openFullScreenMode,
        exit: closeFullScreenMode
    });

    function fullScreenChanged ( event ) {

        if (document.fullscreenElement) {

            setHandle ( {
                active: true,
                enter: openFullScreenMode,
                exit: closeFullScreenMode
            } );

        } else {

            setHandle ( {
                active: false,
                enter: openFullScreenMode,
                exit: closeFullScreenMode
            } );

        }

    }

    useEffect ( () => {

        document.addEventListener('fullscreenchange', fullScreenChanged);

        return ( () => {

            document.removeEventListener('fullscreenchange', fullScreenChanged );

        } );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onComplete = () => {

        setIndex ( ( index ) =>  ( index + 1 ) % 3 );

    };

    return (
        <DashboardScreen index={index} handle={handle} onComplete={onComplete} />
    );

}

export default EguDashboard;
