/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import getSchema from "../../lib/api/getSchema";
import EguMaterialFakeInputDetailButton from "../input/EguMaterialFakeInputDetailButton";

import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import DeleteRecordButton from "../input/DeleteRecordButton";
import EguUpdateMaterialFakeInputDetailButton from "../input/EguUpdateMaterialFakeInputDetailButton";
import defineConf from "../../config/defineConf";


function EguMaterialFakeInputDetail(props) {
    const id = "EguMaterialFakeInputDetail";
    const table = "EguMaterialFakeInputDetail";
    const view = "EguVwMaterialFakeInputDetail";

    const { t } = useTranslation();

    const { onSelectMst, onDetailEmpty } = props;

    const [materialFakeInputDetailSchema, setMaterialFakeInputDetailSchema] = useState();
    const [materialFakeInputDetailGrid, setMaterialFakeInputDetailGrid] = useState();
    const [materialFakeInputDetailApi, setMaterialFakeInputDetailApi] = useState();
    const [materialFakeInputDetailDataIsEmpty, setMaterialFakeInputDetailDataIsEmpty] = useState();
    const [materialFakeInputDetailSelect, setMaterialFakeInputDetailSelect] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMaterialFakeInputDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (materialFakeInputDetailApi) {
            materialFakeInputDetailApi.refresh();
        }
    }, [onSelectMst?.materialFakeInputId]);

    useEffect(() => {
        (async () => {
            if (materialFakeInputDetailGrid) {
                materialFakeInputDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMaterialFakeInputDetailSelect(grid.selectedItems[0]);
                        } else {
                            setMaterialFakeInputDetailSelect();
                        }
                    },10)
                );

                materialFakeInputDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMaterialFakeInputDetailSelect(grid.selectedItems[0]);
                        } else {
                            setMaterialFakeInputDetailSelect();
                        }
                    },10)
                );
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialFakeInputDetailGrid]);

    const onSuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if (materialFakeInputDetailApi) {
            materialFakeInputDetailApi.refresh();
        }
    };

    const onFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <BasicCrudScreenType
            noCreate
            noEdit
            noDelete
            noExcel
            id={id}
            table={table}
            view={view}
            headerTitle={t("term.fakeInputDetail")}
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setMaterialFakeInputDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMaterialFakeInputDetailApi(api);
            }}
            onLoadData={(data) => {
                setMaterialFakeInputDetailDataIsEmpty(data.length < 1 ? true : false);
                if (onDetailEmpty) {
                    onDetailEmpty(data.length < 1 ? true : false);
                }
            }}
            onModifyViewUrl={(url) => `${url}/${onSelectMst.materialFakeInputId}`}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <EguMaterialFakeInputDetailButton
                        id={id}
                        schema={materialFakeInputDetailSchema}
                        fakeInputSelect={onSelectMst}
                        refresh={materialFakeInputDetailApi}
                        disabled={
                            [defineConf.qcResult.QC_OK, defineConf.qcResult.QC_NOK].includes(onSelectMst?.qcResult) ||
                            onSelectMst.length <= 0 ? true : false
                        }
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />

                    <EguUpdateMaterialFakeInputDetailButton
                        id={id}
                        schema={materialFakeInputDetailSchema}
                        grid={materialFakeInputDetailGrid}
                        onSuccess={onSuccess}
                        disabled={
                            materialFakeInputDetailDataIsEmpty ||
                            materialFakeInputDetailDataIsEmpty || materialFakeInputDetailSelect?.inspectQty !== 0
                            // [defineConf.qcResult.QC_OK, defineConf.qcResult.QC_NOK].includes(onSelectMst?.qcResult)
                        }
                    />

                    <DeleteRecordButton
                        id={id}
                        schema={materialFakeInputDetailSchema}
                        grid={materialFakeInputDetailGrid}
                        refresh={materialFakeInputDetailApi}
                        color="secondary"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onPartialFailure={onNotifyWarning}
                        disabled={
                            materialFakeInputDetailDataIsEmpty ||
                            materialFakeInputDetailDataIsEmpty || materialFakeInputDetailSelect?.inspectQty !== 0
                            // [defineConf.qcResult.QC_OK, defineConf.qcResult.QC_NOK].includes(onSelectMst?.qcResult)
                        }
                    />
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default EguMaterialFakeInputDetail;
