import React from "react";
import DialogActionsLayout from "../layout/DialogActionsLayout";

function DialogActions ( props ) {

    const { firstActionComponent, lastActionComponent, children, close, ...others } = {...props};

    return (
        <DialogActionsLayout {...props}>
            {
                firstActionComponent
                ?
                firstActionComponent ()
                :
                <></>
            }
            {
                React.Children.map( children, (child => 
                    React.cloneElement( child, others )
                ))
            }
            {
                lastActionComponent
                ?
                lastActionComponent ()
                :
                <></>
            }
        </DialogActionsLayout>
    )
}

export default DialogActions;
