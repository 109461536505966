/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import EguPopProductManage from "./EguPopProductManage";
import apiClient from "../../lib/common/apiClient";

function PopProductManageProcess(props) {
    const [tab, setTab] = useState();
    const [processCategoryCode, setProcessCategoryCode] = useState([]);
    const [processCategoryName, setProcessCategoryName] = useState([]);
    const [processCategory, setProcessCategory] = useState({});

    useEffect(() => {
        let mounted = true;

        (async () => {
            if(mounted) {
                let result = await apiClient.get("/api/mainProcessView");
                let sortNumber = [];
                result.data.data.push({
                    no: 998,
                    processCategoryCode: "PopEtc",
                    processCategoryName: "기타"
                },
                {
                    no: 999,
                    processCategoryCode: "PopAll",
                    processCategoryName: "전체"
                });
                for(const item of result.data.data) {
                    sortNumber.push(String(item.no).padStart(3, "0"));
                }

                sortNumber.sort();

                let code = [];
                let name = [];
                for(const number of sortNumber) {
                    for(const item of result.data.data) {
                        if(number === String(item.no).padStart(3, "0")) {
                            code.push(item.processCategoryCode);
                            name.push(item.processCategoryName);
                        }
                    }
                }

                // 중복 데이터 제거
                let processCategoryCode = code.filter((item, index) => code.indexOf(item) === index);
                let processCategoryName = name.filter((item, index) => name.indexOf(item) === index);

                setProcessCategoryCode(processCategoryCode);
                setProcessCategoryName(processCategoryName);
                setProcessCategory(result.data.data);

                setTab(code[0]);
            }
        })();

        return () => {
            mounted = false;
        }
    }, []);

    const onTabChanged = (tab) => {
        setTab(tab);
    };

    return (
        <BasicTabScreenType
            id="popProductManageProcess"
            tabs={processCategoryCode}
            tabLabels={processCategoryName}
            tabsStyle={{
                left: 0,
                margin: "0px",
                padding:"0px",
                height: "48px",
                width: "1200px"
            }}
            onTabChanged={onTabChanged}
            value={tab}
        >
            <EguPopProductManageTab viewName={tab} processCategory={processCategory} />
        </BasicTabScreenType>
    );
}

function EguPopProductManageTab(props) {
    const { viewName, processCategory } = props;

    let id;
    let name;
    for(const item in processCategory) {
        if(processCategory[item].processCategoryCode === viewName) {
            id = processCategory[item].processCategoryCode;
            name = processCategory[item].processCategoryName;
        }
    }

    if(id === undefined) {
        return <></>
    }

    return <EguPopProductManage id={id} name={name} />;
}

export default PopProductManageProcess;
