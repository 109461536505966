import {  useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import MomSysChagneSpareStockButtonLayout from "../layout/MomSysChangeSpareStockButtonLayout";

import apiClient from "../../lib/common/apiClient";

function MomSysChangeSpareStockButton (props) {

    const {id, standard, grid, refresh, onSuccess, onFailure, ...others} = props;

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();
    
    const onOpen = () => {

        setOpen ( true );

        let data = grid.selectedItems[0];

        setConfirmMsg ( t( "dialog.msg.confirm.changeSpareStock", { lotNo: data.lotNo } ) );
        setConfirmData(data);

    };

    const onChangeSpareStockSuccess = (msg) => {

        if(onSuccess) {

            onSuccess(msg);

        }

        refresh.refresh();
    }

    const onOk = (data) => {
        
        let url;

        if(standard) {

            url = `/api/Material/${data.lotNo}/adapt`;
            
        } else {
            
            url = `/api/NsMaterial/${data.lotNo}/adapt`;

        }

        let form = new FormData();

        let deleted = {};

        data.str_f = data.orderBuyerId;
        data.str_t = "SO-SPARE-STOCK";

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(data));

        apiClient
        .post(url, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((result) => {
            onChangeSpareStockSuccess ( result.data );
        
        })

        .catch ( ( reason ) => {

            onFailure ( t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

        });
    }

    const onClose = () => {

        setOpen ( false );

    };


    return (
        <>
        <MomSysChagneSpareStockButtonLayout id = {id+"-spareStock-button"} onClick = {onOpen} {...others}/>
        <ConfirmDialog
                 id={id+"-change-spare-stock-confirm-dialog"}
                 title={ t ( "dialog.title.MomSysLogAdapt.changeSpareStock" ) }
                 open={open}
                 msg={confirmMsg}
                 onClose={onClose}
                 onCancel={onClose}
                 data={confirmData}
                 onOk={onOk}
            />
        </>
    )

}

export default MomSysChangeSpareStockButton;