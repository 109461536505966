/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CopyBomBopButtonLayout from "../layout/CopyBomBopButtonLayout";
import CopyBomBopDialog from "../dialogTypes/MomSysCopyBomBopDialog";

function CopyBomBopButton ( props ) {

    const { t } = useTranslation();
    const { schema, process, refresh, onSuccess, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            refresh.refresh ();
            onSuccess ( msg );

        }

    };

    return (

        <>
            <CopyBomBopButtonLayout id={"copy-bom-bop-button"} onClick={onOpen} {...others} />
            <CopyBomBopDialog
                id={"copy-bom-bop-dialog"}
                title={ t ( "dialog.title.CopyBomBop" ) }
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default CopyBomBopButton;

