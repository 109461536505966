
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { CellType } from '@grapecity/wijmo.grid';
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import SaveToExcelButton from "../input/SaveToExcelButton";
import BasicCrudScreenType from '../screenTypes/functional/BasicCrudScreenType';
import PopOrderPurchaseInputButton from '../input/PopOrderPurchaseInputButton';

const id = "OrderPurchaseInput"
const view = "VwPopOrderPurchaseInput"

function OrderPurchaseInput(props) {
    
    const { t } = useTranslation();

    const [grid, setGrid] = useState();

    const [height, setHeight] = useState(0);

    const [mstApi, setMstApi] = useState();
    const [detailApi, setDetailApi] = useState();

    const [data, setData] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        handleResize();

        return ( () => {
            window.removeEventListener('resize', handleResize);
        } );


    }, []);

    const onLoadData = (data) => {

        setData(data);
        setIsEmpty(data.length < 1 ? true : false)

    }

    const onInitialized = grid => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setMstApi(api);

    };

    const exportFormatItem = (args) => {

        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {

            switch (panel.columns[col].binding) {

                case "approvalState":

                    switch (data[row][panel.columns[col].binding]) {

                        case 1:

                            xlsxCell.value = t("selectItems.approvalState.approvalPending")

                            break;

                        case 2:

                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved")

                            break;

                        case 3:

                            xlsxCell.value = t("selectItems.approvalState.totallyApproved")

                            break;

                        case 4:

                            xlsxCell.value = t("selectItems.approvalState.approvalRejected")

                            break;

                        case 5:

                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn")

                            break;

                        case 6:

                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled")

                            break;

                        default:
                            break;
                    }

                    break;

                default:

                    break;

            }

        }

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                <BasicCrudScreenType 
                    id ={id}
                    headerTitle = {t("term.poDetail")}
                    view = "VwPopOrderPurchaseInput" 
                    onApiInitialized = {onApiInitialized}
                    onInitialized = {onInitialized}
                    onLoadData = {onLoadData}
                    noExcel
                    onAppendButtonInFrontOfSearch={
                        () => (
                            <>
                                <BasicCrudScreenActionItemLayout>
                                    <PopOrderPurchaseInputButton
                                        id={id}
                                        grid = {grid}
                                        refresh={mstApi}
                                        detailRefresh = {detailApi}
                                        onSuccess={onSuccess}
                                        disabled = {isEmpty}
                                    />
                                    <SaveToExcelButton
                                        id={id}
                                        grid={grid}
                                        view={view}
                                        color="primary"
                                        exportFormatItem={exportFormatItem}
                                        disabled={isEmpty}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </>
                        )
                    }
                />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <BasicCrudScreenType 
                        id ={"VwPopOrderPurchaseInputDetail"}
                        headerTitle = {t("term.orderPurchaseInputDetail")}
                        view = "VwPopOrderPurchaseInputDetail" 
                        onApiInitialized = {(api) => setDetailApi(api)}
                        noExcel
                    />
                </ReflexElement>
            </ReflexContainer>

        </div>

    );

}

export default OrderPurchaseInput;
