import MaterialDialogHeader from "../input/MaterialDialogHeader";
import WijmoDialogHeader from "../input/WijmoDialogHeader";

import uiConf from "../../../config/uiConf";

function DialogHeaderLayout ( props ) {

    const { moveable, platform, onInitialized, ...others } = props;

    if ( platform ) {

        switch ( platform ) {

            case "material-ui":
    
                return <MaterialDialogHeader {...others} />
    
            case "wijmo":
    
                return <WijmoDialogHeader {...others} />

            default:
    
                return <MaterialDialogHeader {...others} />
    
        }

    } else {

        switch ( uiConf.dialogPlatform ) {

            case "material-ui":
    
                return <MaterialDialogHeader {...others} />
    
            case "wijmo":
    
                return <WijmoDialogHeader {...others} />
        
            default:

                return <MaterialDialogHeader {...others} />

        }

    }

}

export default DialogHeaderLayout;
