import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";
import { useRecoilState, useRecoilValue } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import authProfileState from "../../states/authProfileState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ApprovalButton from "../input/ApprovalButton";
import getSchema from "../../lib/api/getSchema";
import EguInputInspectionRegisterButton from "../input/EguInputInspectionRegisterButton";
import EguInputInspectionUpdateButton from "../input/EguInputInspectionUpdateButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import EguInputInspectionInputButton from "../input/EguInputInspectionInputButton";
import EguInputInspectionDetail from "./EguInputInspectionDetail";

function EguInputInspection(props) {
    const id = "EguInputInspection";
    const table = "EguInputInspection";
    const view = "EguVwInputInspection";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [inputInspectionSchema, setInputInspectionSchema] = useState();
    const [inputInspectionGrid, setInputInspectionGrid] = useState();
    const [inputInspectionApi, setInputInspectionApi] = useState();
    const [inputInspectionDataIsEmpty, setInputInspectionDataIsEmpty] = useState();
    const [inputInspectionSelect, setInputInspectionSelect] = useState();

    const [inputInspectionDetailApi, setInputInspectionDetailApi] = useState();
    const [inputInspectionDetailDataIsEmpty, setInputInspectionDetailDataIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const authProfile = useRecoilValue(authProfileState);

    const [approvalCtx, setApprovalCtx] = useState();

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setInputInspectionSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 14,
            tableName: table,
            statusColumn: "approvalStateId",
            schema: inputInspectionSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },

            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.eguInputInspection", {
                    inputInspectionId: item.inputInspectionId,
                })}`;
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputInspectionSchema]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (inputInspectionGrid && mounted) {
                inputInspectionGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setInputInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setInputInspectionSelect();
                        }
                    }),
                    10
                );

                inputInspectionGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setInputInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setInputInspectionSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [inputInspectionGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDoInputSuccess = (msg) => {
        notifySuccess(msg);

        if (inputInspectionDetailApi) {
            inputInspectionDetailApi.refresh();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.eguInputInspection")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setInputInspectionGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setInputInspectionApi(api);
                        }}
                        onLoadData={(data) => {
                            setInputInspectionDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <EguInputInspectionRegisterButton
                                        id={`${id}-register-button`}
                                        schema={inputInspectionSchema}
                                        refresh={inputInspectionApi}
                                        onSuccess={onSuccess}
                                    />

                                    <EguInputInspectionUpdateButton
                                        id={`${id}-update-button`}
                                        schema={inputInspectionSchema}
                                        grid={inputInspectionGrid}
                                        refresh={inputInspectionApi}
                                        disabled={inputInspectionSelect?.approvalState || inputInspectionDataIsEmpty}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteSingleRecordButton
                                        id={id}
                                        schema={inputInspectionSchema}
                                        grid={inputInspectionGrid}
                                        refresh={inputInspectionApi}
                                        makeItemName={(row) => {
                                            return ` - ${t("columnName.inputInspectionId")}: ${row.inputInspectionId}`;
                                        }}
                                        color="secondary"
                                        disabled={
                                            inputInspectionSelect?.approvalState || !inputInspectionDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />

                                    <ApprovalButton
                                        id={`${id}-approval-button`}
                                        grid={inputInspectionGrid}
                                        refresh={inputInspectionApi}
                                        approvalCtx={approvalCtx}
                                        disabled={!inputInspectionSelect || inputInspectionDetailDataIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />

                                    <EguInputInspectionInputButton
                                        id={`${id}-input-button`}
                                        schema={inputInspectionSchema}
                                        grid={inputInspectionGrid}
                                        refresh={inputInspectionApi}
                                        disabled={
                                            !inputInspectionSelect ||
                                            inputInspectionDetailDataIsEmpty ||
                                            inputInspectionSelect?.approvalState !==
                                                defineConf.approvalState.APPROVED ||
                                            //inputInspectionSelect?.qcResult !== defineConf.qcResult.QC_OK ||
                                            inputInspectionSelect?.wareHouseName
                                        }
                                        onSuccess={onDoInputSuccess}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    {inputInspectionSelect ? (
                        <EguInputInspectionDetail
                            inputInspectionSelect={inputInspectionSelect}
                            onSetDetailApi={setInputInspectionDetailApi}
                            onSetDetailDataEmpty={setInputInspectionDetailDataIsEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguInputInspection;
