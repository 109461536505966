import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function VwMsgSent ( props ) {

    const { onSelect, onInitialized, ...others } = props;

    const onGridInitialized = (grid) => {

        grid.selectionMode = "ListBox";
        
        if (grid) {

            grid.selectionChanged.addHandler((grid, event) => {

                if ( grid.selectedItems ) {

                    if ( onSelect ) {

                        onSelect ( grid.selectedItems );

                    }

                }

            });

            grid.itemsSourceChanged.addHandler((grid, event) => {

                if ( grid.selectedItems ) {

                    if ( onSelect ) {

                        onSelect ( grid.selectedItems );
                        
                    }

                }

            });

        }

        if ( onInitialized ) {

            onInitialized ( grid );

        }

    };

    return <BasicCrudScreenType id="VwMsgSent" table="VwMsgSent" view="VwMsgSent" {...others} onInitialized={onGridInitialized} />

}

export default VwMsgSent;

