import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useRecoilState } from "recoil";
import layoutConf from "../../config/layoutConf";
import getSchema from "../../lib/api/getSchema";

import notificationState from "../../states/notificationState";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysDirectShipDetail from "./MomSysDirectShipDetail";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import MomSysDirectShipDoShipButton from "../input/MomSysDirectShipDoShipButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import SaveToExcelButton from "../input/SaveToExcelButton";
import { debounce, cloneDeep } from "lodash";


function MomSysDirectShip(props) {
    const id = "MomSysDirectShip";
    const table = "MomSysDirectShip";
    const view = "MomSysVwDirectShip";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [momSysDirectShipSchema, setMomSysDirectShipSchema] = useState();
    const [momSysDirectShipGrid, setMomSysDirectShipGrid] = useState();
    const [momSysDirectShipApi, setMomSysDirectShipApi] = useState();
    const [momSysDirectShipDataIsEmpty, setMomSysDirectShipDataIsEmpty] = useState();
    const [momSysDirectShipSelect, setMomSysDirectShipSelect] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    const [momSysDirectShipDetailDataIsEmpty, setMomSysDirectShipDetailDataIsEmpty] = useState();
    const [momSysDirectShipDetailData, setMomSysDirectShipDetailData] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysDirectShipSchema(schema);

                let modifiedSchema = cloneDeep(schema);

                if (modifiedSchema) {
                    for (const column of modifiedSchema.columns) {
                        switch (column.name) {
                            case "directShipName":
                                column.disabled = true;

                                break;

                            default:
                                break;
                        }
                    }

                    setModifiedSchema(modifiedSchema);
                }
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysDirectShipGrid) {
                momSysDirectShipGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipSelect([]);
                        }
                    },10)
                );

                momSysDirectShipGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipSelect([]);
                        }
                    },10)
                );
            }
        })();
    }, [momSysDirectShipGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (msg) => {
        notifyFailure(msg.response ? msg.response.data : msg);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view={view}
                        headerTitle={t("term.directShip")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setMomSysDirectShipGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMomSysDirectShipApi(api);
                        }}
                        onLoadData={(data) => {
                            setMomSysDirectShipDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noDelete
                        noEdit
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <CreateRecordButton
                                    id={id}
                                    schema={momSysDirectShipSchema}
                                    refresh={momSysDirectShipApi}
                                    onSuccess={onSuccess}
                                />

                                <UpdateRecordButton
                                    id={id}
                                    schema={modifiedSchema}
                                    grid={momSysDirectShipGrid}
                                    refresh={momSysDirectShipApi}
                                    disabled={momSysDirectShipDataIsEmpty || momSysDirectShipSelect?.shipDate}
                                    onSuccess={onSuccess}
                                />

                                <DeleteSingleRecordButton
                                    id={id}
                                    schema={momSysDirectShipSchema}
                                    grid={momSysDirectShipGrid}
                                    refresh={momSysDirectShipApi}
                                    makeItemName={(row) => {
                                        return `${t("columnName.directShipId")}: ${row.directShipId}`;
                                    }}
                                    color="secondary"
                                    disabled={
                                        momSysDirectShipDataIsEmpty ||
                                        momSysDirectShipSelect?.shipDate ||
                                        !momSysDirectShipDetailDataIsEmpty
                                    }
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />

                                <MomSysDirectShipDoShipButton
                                    id={id}
                                    schema={momSysDirectShipSchema}
                                    grid={momSysDirectShipGrid}
                                    refresh={momSysDirectShipApi}
                                    disabled={
                                        momSysDirectShipDataIsEmpty ||
                                        momSysDirectShipDetailDataIsEmpty ||
                                        (() => {
                                            if (momSysDirectShipDetailData) {
                                                return momSysDirectShipDetailData.some((v) => v.qty > v.shipQty);
                                            }

                                            return false;
                                        })() ||
                                        momSysDirectShipSelect?.shipDate
                                    }
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />

                                <SaveToExcelButton
                                    id={id}
                                    view={view}
                                    grid={momSysDirectShipGrid}
                                    color="primary"
                                    exportFormatItem={() => {}}
                                    disabled={momSysDirectShipDataIsEmpty}
                                />
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement fle={0.5} data-cy="Detail">

                        <MomSysDirectShipDetail
                            onSelectMst={momSysDirectShipSelect}
                            onDetailEmpty={setMomSysDirectShipDetailDataIsEmpty}
                            onDetailData={setMomSysDirectShipDetailData}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />

                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysDirectShip;
