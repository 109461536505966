import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function ApprovalHistory ( props ) {

    return <BasicCrudScreenType
        id="ApprovalHistory" view="VwApprovalHistory"
        noCreate
        noEdit
        noDelete
        noExcel
    />

}

export default ApprovalHistory;

