import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysPurchaseAnalysisGrid from './MomSysPurchaseAnalysisGrid';

function MomSysPurchaseAnalysis ( props ) {

    const [height, setHeight] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);


    }, []);

    return(

        <div style={{ height: height }} >

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.5}>

                <MomSysPurchaseAnalysisGrid
                    id="MomSysVwPurchaseAnalysisFirst"
                />
                </ReflexElement>

                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                <ReflexElement flex={0.5}>

                <MomSysPurchaseAnalysisGrid
                    id="MomSysVwPurchaseAnalysisSecond"
                />

                </ReflexElement>

            </ReflexContainer>
        </div>
    ) 

}

export default MomSysPurchaseAnalysis;