
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import VwPopOrderPurchaseMaterialFakeInput from "./VwPopOrderPurchaseMaterialFakeInput";
import VwPopOrderPurchaseInput from './VwPopOrderPurchaseInput';
import { debounce } from 'lodash';
import PopOrderPurchaseMaterialFakeInputButton from "../input/PopOrderPurchaseMaterialFakeInputButton";
import PopOrderPurchaseInputButton from "../input/PopOrderPurchaseInputButton";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    },
    
    button : {
        paddingTop: "12px",
    }

}));

function PopOrderPurchaseView(props) {

    const {tab, onSearchInitialized, onInitialized, onApiInitialized, onLoadData } = props;

    switch (tab) {

        case "fakeInput":

        return (

            <VwPopOrderPurchaseMaterialFakeInput 
                view = "VwPopOrderPurchaseMaterialFakeInput" 
                onSearchInitialized={onSearchInitialized}
                onApiInitialized = {onApiInitialized}
                onInitialized= {onInitialized}
                onLoadData = {onLoadData}
                
            />
            );

        case "orderPurchaseInput":

        return (
            <VwPopOrderPurchaseInput 
                view = "VwPopOrderPurchaseInput" 
                onSearchInitialized={onSearchInitialized}
                onApiInitialized = {onApiInitialized}
                onInitialized = {onInitialized}
                onLoadData = {onLoadData}
            />
            );
        default:
            return <></>;
    }
}

function PopOrderPurchaseInput(props) {

    const classes= useStyle();

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [grid, setGrid] = useState();
    const [detailGrid, setDetailGrid] = useState();

    const [api, setApi] = useState();
    const [detailApi, setDetailApi] = useState();

    const [tab, setTab] = useState("fakeInput");
    const [empty, setEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const [ , setSearch ] = useState ();
    const [ , setSearchVal ] = useState ();

    const [detailSelect, setDetailSelect] = useState();

    const [reportUrl, setReportUrl] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {

        const path = `inputInspectionDetail/${detailSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "staging") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl ( url );

    }, [detailSelect]);

    useEffect(() => {
        (async () => {
            if (detailGrid) {
                detailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDetailSelect(grid.selectedItems[0]);
                        } else {
                            setDetailSelect([]);
                        }
                    }),
                    10
                );

                detailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDetailSelect(grid.selectedItems[0]);
                        } else {
                            setDetailSelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailGrid]);

    const onChangeSearch = ( e ) => {

        setSearch ( ( search ) => {

            if ( search ) {

                setSearchVal ( e.target.value );
                search.applySearch ( e.target.value );

            }

            return search;

        } );

    };

    const onSearchInitialized = ( search ) => {

        setSearch ( search );

        setSearchVal ( ( searchVal ) => {

            if ( searchVal ) {

                search.applySearch ( searchVal );
                
            }

            return searchVal;

        } );

    }
    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    const onTabChanged = (tab) => {

        setTab(tab);

    };

    const onApiInitialized = (api) => {
        setApi(api);
    }
    const onDetailApiInitialized = (api) => {

        setDetailApi(api);
    }

    const onInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);
    }
    const onDetailInitialized = (grid) => {

        grid.selectionMode = "Row";

        setDetailGrid(grid);
    }
    const onLoadData = (data) => {

        setEmpty(data.length < 1 ? true : false);
    }

    return (

        <div style={{ height: height }} >
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicTabScreenType
                        id="popMaterialInputFakeInput"
                        tabs={["fakeInput", "orderPurchaseInput"]}
                        tabLabels={["가입고 리스트", "발주 리스트"]}
                        tabsStyle={{
                            left: 0,
                            margin: "0px",
                            padding:"0px",
                            height: "48px",
                            width: "450px"
                        }}
                        onTabChanged={onTabChanged}
                        value={tab}
                        rightGridItems={
                            [
                                <Grid item>
                                    <PopCrudScreenActionItemLayout>
                                    {
                                        tab === "fakeInput" ?
                                        
                                        <PopOrderPurchaseMaterialFakeInputButton
                                            id="PopOrderPurchaseMaterialFakeInput"
                                            grid = {grid}
                                            className={classes.button}
                                            refresh={api}
                                            detailRefresh = {detailApi}
                                            onSuccess={onSuccess}
                                            disabled = {empty}
                                        />
                                        :

                                        <PopOrderPurchaseInputButton
                                            id="PopOrderPurchaseInput"
                                            grid = {grid}
                                            className={classes.button}
                                            refresh={api}
                                            detailRefresh = {detailApi}
                                            onSuccess={onSuccess}
                                            disabled = {empty}
                                        />
                                    }
                                    </PopCrudScreenActionItemLayout>
                                </Grid>,
                                <Grid item>
                                    <TextField
                                        id={"PopOrderPurchaseInput-search-text"}
                                        className={classes.root}
                                        placeholder={t("common.search")}
                                        onChange={ onChangeSearch }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>                    
                                            )
                                        }}
                                        style={{
                                            bottom: "2px",
                                            width: "120px"                        
                                        }}
                                    />
                                </Grid>
                        ]}>
                        <PopOrderPurchaseView
                            tab = {tab}
                            onSearchInitialized = {onSearchInitialized}
                            onInitialized = {onInitialized}
                            onApiInitialized = {onApiInitialized}
                            onLoadData = {onLoadData}
                        />
                    </BasicTabScreenType>
            </ReflexElement>
            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />
                <ReflexElement data-cy="Detail">
                    <BasicCrudScreenType
                        id="VwPopOrderPurchaseInputDetail"
                        view={"VwPopOrderPurchaseInputDetail"}
                        headerTitle={t("term.orderPurchaseInputDetail")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onApiInitialized={onDetailApiInitialized}
                        onInitialized={onDetailInitialized}
                        fixedOrder={[{column: "createdAt", order: "desc"}]}
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>
                                <PrintReportButton
                                    noLayout
                                    url={reportUrl}
                                    disabled={!detailSelect?.lotNo}
                                >
                                    {t("buttonName.lotInfoPrint")}
                                </PrintReportButton>
                            </PopCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default PopOrderPurchaseInput;
