/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function EguOutSourcingFakeInputPurchaseDetail(props) {
    const { id, onSelectMst } = props;

    const { t } = useTranslation();

    const [outSourcingPurchaseDetailApi, setOutSourcingPurchaseDetailApi] = useState();

    useEffect(() => {
        if (outSourcingPurchaseDetailApi) {
            outSourcingPurchaseDetailApi.refresh();
        }
    }, [onSelectMst.outSourcingId]);

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.eguOutSourcingPurchaseDetail")}
            view="EguVwOutSourcingPurchaseDetail"
            onApiInitialized={(api) => {
                setOutSourcingPurchaseDetailApi(api);
            }}
            onModifyViewUrl={(url) => `${url}/${onSelectMst.outSourcingId}`}
            onInitialized={(grid) => {
                grid.selectionMode = "None";
            }}
            noCreate
            noEdit
            noDelete
            noExcel
            embededInOtherScreenType
        />
    );
}

export default EguOutSourcingFakeInputPurchaseDetail;
