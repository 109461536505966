import React from "react";
import { makeStyles } from "@material-ui/core";

function AutoFilterFormValueItemLayout ( props ) {

    const { children, column, ...others } = props;

    let width;

    if ( column && column.filter.value && column.filter.value.width ) {

        width = column.filter.value.width;

    } else {

        width = "15%";

    }

    const useStyles = makeStyles ( ( theme ) => ( {

        root: {
    
            margin: "1%",
            width: width,
    
        },
    
      } ) );
    
    const classes = useStyles ();

    const styledProps = {

        className: classes.root,
        ...others

    };

    return (
        <>
        {
            children &&
            React.Children.map ( children, ( ( child ) => (
                    React.isValidElement ( child )
                    ?
                    React.cloneElement( child, styledProps )
                    :
                    child
            ) ) )

        }
        </>
    );
}

export default AutoFilterFormValueItemLayout;
