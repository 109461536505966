/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InsertApproverButtonLayout from "../layout/InsertApproverButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function InsertApproverButton ( props ) {

    const { t } = useTranslation();

    const { id, approvalPathNode, schema, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ modifiedSchema, setModifiedSchema ] = useState ();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

    };

    const modifyUrlCallback = ( url ) => {

        let modifiedUrl = `${url}/${approvalPathNode.approvalPathId}/${approvalPathNode.approver}`;

        return modifiedUrl;

    }

    useEffect ( () => {

        if ( schema && approvalPathNode ) {

            let modifiedSchema = schema;

            for ( const column of modifiedSchema.columns ) {

                switch ( column.name ) {
    
                    case "approvalPathId":
    
                        column.hideOnCreate = true;
                        column.defaultValue = approvalPathNode.approvalPathId;
                        setFormData ( ( formData ) => ({...formData, approvalPathId: approvalPathNode.approvalPathId }) );
    
                        break;
    
                    case "order":
    
                        column.hideOnCreate = true;
                        column.defaultValue = approvalPathNode.order;
                        setFormData ( ( formData ) => ({...formData, order: approvalPathNode.order }) );
    
                        break;
    
                    default:
    
                        break;
    
                }
    
                setModifiedSchema ( modifiedSchema );

            }

    
        }

    }, [schema, approvalPathNode] );

    return (

        <>
            <InsertApproverButtonLayout id={id+"insert-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"insert-dialog"}
                mode="create"
                formId={id+"append-form"}
                title={ t ( "dialog.title.ApprovalPath.insertApprover" ) }
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback={modifyUrlCallback}
            />
        </>

    );
}

export default InsertApproverButton;

