/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import SaveToExcelButton from "../input/SaveToExcelButton";
import GroupByColumnPicker from "../input/GroupByColumnPicker";
import DateRangeSelector from "../input/DateRangeSelector";

function MomSysProcessReport ( props ) {

    const [grid, setGrid] = useState();
    const [orderBy, setOrderBy] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [view, setView] = useState("MomSysVwProcessReport");
    const [disableExcelButton, setDisableExcelButton] = useState(false);

    const [groupBy, setGroupBy] = useState([

        { name: "lotNo", value: true },
        { name: "material", value: true },
        { name: "process", value: true },
        { name: "lineNo", value: true },
        { name: "machine", value: true },
        { name: "worker", value: true },

    ] );

    const checkItemSelected = ( groupBy, name ) => {

        let selected = groupBy[groupBy.findIndex ( ( item ) => item.name === name )].value;

        return selected;

    }

    const [groupByFields, setGroupByFields] = useState ( [

        "lotNo",
        "transLogId",
        "materialCode",
        "materialCodeVer",
        "materialName",
        "processCode",
        "processName",
        "lineNo",
        "machineCode",
        "machineName",
        "worker",
        "workerName",

    ] );

    const onDateRangeChanged = ( from, to ) => {

        setFrom ( from );
        setTo ( to );

    }

    const onGroupByChanged = ( groupBy, orderBy ) => {

        let selectedCount = 0;

        for ( const item of groupBy ) {

            if ( item.value ) {

                selectedCount++;

            }

        }

        if ( selectedCount === 0 )
        {
            return;

        }

        let newGroupBy = [...groupBy];
        let newGroupByFields = [];

        for ( const item of groupBy ) {
                
            if ( item.name === "lotNo" && item.value ) {

                newGroupBy = [

                    { name: "lotNo", value: true },
                    { name: "material", value: true },
                    { name: "process", value: true },
                    { name: "lineNo", value: true },
                    { name: "machine", value: true },
                    { name: "worker", value: true },            

                ];
    
                newGroupByFields = [
    
                    "lotNo",
                    "transLogId",
                    "materialCode",
                    "materialCodeVer",
                    "materialName",
                    "processCode",
                    "processName",
                    "lineNo",
                    "machineCode",
                    "machineName",
                    "worker",
                    "workerName",
                ];
                
                break;
    
            } else {

                switch ( item.name ) {

                    case "material":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "materialCode" );
                            newGroupByFields.push ( "materialCodeVer" );
                            newGroupByFields.push ( "materialName" );

                        }

                        break;

                    case "process":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "processCode" );
                            newGroupByFields.push ( "processName" );
    
                        }

                        break;
    
                    case "lineNo":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "lineNo" );
    
                        }
    
                        break;

                    case "machine":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "machineCode" );
                            newGroupByFields.push ( "machineName" );
    
                        }
    
                        break;
    
                    case "worker":

                        if ( item.value ) {

                            newGroupByFields.push ( "worker" );
                            newGroupByFields.push ( "workerName" );
    
                        }
    
                        break;
        
                    default:

                        break;

                }

            }


        }

        // Group By 변경 후 컬럼 순서가 이상해 보이는 것을 막기 위해 Column Order 초기화

        let gridStorageKey = "grid-MomSysVwProcessReport-data-grid-layout";
        localStorage.removeItem ( gridStorageKey );

        // clear order by info to avoid internal server error by order by not included column

        orderBy.setOrderBy ( [] );

        // To make set state instantly

        setTimeout( () => setGroupBy ( newGroupBy ), 0 );
        setTimeout( () => setGroupByFields ( newGroupByFields ), 0 );;

        // To make schema updated

        setTimeout ( () => setView ( undefined ), 0 );
        setTimeout ( () => setView ( "MomSysVwProcessReport" ), 0 );

    }

    const makeQueries = ( from, to, groupByFields ) => {

        let queries = {
            $from: from,
            $to: to,
            $groupBy: JSON.stringify(groupByFields)
        };

        return queries;

    }

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        setGrid(grid);

        grid.itemsSourceChanged.addHandler((grid, event) => {

            if ( grid.rows.length > 0 ) {

                setDisableExcelButton ( false );
                
            } else {

                setDisableExcelButton ( true );

            }

        } );
        
    };

    const onOrderByInitialized = (orderBy) => {

        setOrderBy ( orderBy );

    }

    const onModifyViewSchema = ( groupBy, schema ) => {

        for ( const column of schema.columns ) {

            switch ( column.name ) {

                case "lotNo":
                case "transLogId":
                case "processTime":
                case "queueTime":
                case "holdTime":

                    column.grid.hide = checkItemSelected ( groupBy, "lotNo" ) ? false : true;
                    
                    break;

                case "qty":
                case "createdAt":

                    column.grid.hide = checkItemSelected ( groupBy, "lotNo" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "lineNo":

                    column.grid.hide = checkItemSelected ( groupBy, "lineNo" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "materialCode":
                case "materialCodeVer":
                case "materialName":

                    column.grid.hide = checkItemSelected ( groupBy, "material" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "processCode":
                case "processName":

                    column.grid.hide = checkItemSelected ( groupBy, "process" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "machineCode":
                case "machineName":

                    column.grid.hide = checkItemSelected ( groupBy, "machine" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "worker":
                case "workerName":

                    column.grid.hide = checkItemSelected ( groupBy, "worker" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "avgProcessPerHour":
                case "minProcessPerHour":
                case "maxProcessPerHour":
                case "stdProcessPerHour":
                case "avgProcessTime":
                case "minProcessTime":
                case "maxProcessTime":
                case "stdProcessTime":
                case "avgQueueTime":
                case "minQueueTime":
                case "maxQueueTime":
                case "stdQueueTime":
                case "avgHoldTime":
                case "minHoldTime":
                case "maxHoldTime":
                case "stdHoldTime":
                case "sumProcessQty":

                    column.grid.hide = checkItemSelected ( groupBy, "lotNo" ) ? true : false;

                    break;

                default:

                    break;

            }

        }

    };

    return (

        <BasicCrudScreenType
            id="MomSysVwProcessReport"
            table="MomSysVwProcessReport"
            view={view}
            $queries={ makeQueries ( from, to, groupByFields ) }
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendHeaderAtFirst={ () =>

                <>                
                    <DateRangeSelector onChange={onDateRangeChanged}/>
                    <div style={{marginLeft: "32px"}} />
                    <GroupByColumnPicker color="primary" groupByColumns={ groupBy } onChange={( groupBy ) => onGroupByChanged ( groupBy, orderBy ) } />

                </>

            }
            onAppendButtonInFrontOfSearch={ () =>

                <BasicCrudScreenActionItemLayout>

                    <AccessControl accessTarget="MomSysVwProcessReport" accessMethod="SAVETOEXCEL">
                        <SaveToExcelButton
                            id="MomSysVwProcessReport"
                            grid={grid}
                            view={view}
                            color="primary"
                            disabled={disableExcelButton}
                        />
                    </AccessControl>

                </BasicCrudScreenActionItemLayout>

            }
            onInitialized={onGridInitialized}
            onOrderByInitialized={onOrderByInitialized}
            onModifyViewSchema={ ( schema ) => onModifyViewSchema ( groupBy, schema ) }
        />                                

    );

}

export default MomSysProcessReport;
