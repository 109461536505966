import { CellType } from '@grapecity/wijmo.grid';
import { useState } from 'react';

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MenuItemManage ( props ) {

    const [ data, setData ] = useState();

    const exportFormatItem = ( args ) => {

        let { panel, col, row, xlsxCell } = args;

        if ( panel.cellType === CellType.Cell ) {

            let cell;

            switch ( panel.columns[col].binding ) {

                case "iconName":

                    xlsxCell.value = data[row][panel.columns[col].binding];

                    break;

                default:

                    cell = args.getFormattedCell ();

                    xlsxCell.value = cell.textContent;

                    break;
        
            }
    
        }

    }

    const onLoadData = ( data ) => {

        setData ( data );

    }

    return <BasicCrudScreenType id="MenuItem" table="MenuItem" view="MenuItem" onLoadData={onLoadData} exportFormatItem={exportFormatItem} />;

}

export default MenuItemManage;
