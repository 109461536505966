import FilterFormItemCode from "../input/FilterFormItemCode";
import FilterFormItemAutocomplete from "../input/FilterFormItemAutocomplete";
import FilterFormItemSelect from "../input/FilterFormItemSelect";
import FilterFormItemDate from "../input/FilterFormItemDate";
import FilterFormItemTime from "../input/FilterFormItemTime";
import FilterFormItemDateTime from "../input/FilterFormItemDateTime";
import FilterFormItemText from "../input/FilterFormItemText";
import FilterFormItemNumber from "../input/FilterFormItemNumber";
import FilterFormItemFloat from "../input/FilterFormItemFloat";
import { useTranslation } from "react-i18next";
import FilterFormItemMonth from "../input/FilterFormItemMonth";
import FilterFormItemColor from "../input/FilterFormItemColor";

function AutoFilterFormItem ( props ) {

    const { t } = useTranslation ();
    const { label, column } = props;

    const formItemProps = {
        label: label ? label : t ( `columnName.${column.displayName||column.name}` ),
        name: column.name,
        InputLabelProps: {
            style: {
                fontSize: "large",
                fontWeight: "bold"
            },
        },
        ...props
    };
    
    if ( column.formItemType ) {

        switch ( column.formItemType ) {

            case "number":

                return <FilterFormItemNumber {...formItemProps} />

            case "float":

                return <FilterFormItemFloat {...formItemProps} />

            case "text":

                return <FilterFormItemText {...formItemProps} />

            case "date":

                return <FilterFormItemDate {...formItemProps} />

            case "time":

                return <FilterFormItemTime {...formItemProps} />

            case "datetime":

                return <FilterFormItemDateTime {...formItemProps} />
            
            case "month":
                
                return <FilterFormItemMonth {...formItemProps} />

            case "code":

                return <FilterFormItemCode {...formItemProps} />

            case "autocomplete":

                return <FilterFormItemAutocomplete {...formItemProps} />

            case "select":

                return <FilterFormItemSelect {...formItemProps} />

            case "color":

                return <FilterFormItemColor {...formItemProps} />

            default:

                return <FilterFormItemText {...formItemProps} />

        }

    } else {

        if (            
            column.type.startsWith ( "char" )       ||
            column.type.startsWith ( "varchar" )    ||
            column.type.startsWith ( "text" )       ||
            column.type.startsWith ( "tinytext" )   ||
            column.type.startsWith ( "mediumtext" ) ||
            column.type.startsWith ( "longtext" )   ||
            column.type.startsWith ( "bigint" )     
        ) {
    
            return <FilterFormItemText {...formItemProps} />

        } else if (            
            column.type.startsWith ( "int" )        ||
            column.type.startsWith ( "smallint" )   ||
            column.type.startsWith ( "tinyint" )    ||
            column.type.startsWith ( "mediumint" )  ||
            column.type.startsWith ( "numeric" )
        ) {

            return <FilterFormItemNumber {...formItemProps} />

        } else if (            
            column.type.startsWith ( "float" )      ||
            column.type.startsWith ( "double" )     ||
            column.type.startsWith ( "decimal" )
        ) {

            return <FilterFormItemFloat {...formItemProps} />

        } else if ( column.type === "date" ) {

            return <FilterFormItemDate { ...formItemProps} />

        } else if ( column.type.startsWith ( "time" ) ) {

            return <FilterFormItemTime { ...formItemProps} />

        } else if ( column.type.startsWith ( "datetime" ) ) {

            return <FilterFormItemDateTime { ...formItemProps} />

        } else if ( column.type.startsWith ( "timestamp" ) ) {

            return <FilterFormItemDateTime { ...formItemProps} />

        }

    }

    console.log ( "AutoFilterFormItem type not defined", column.type );

    return <FilterFormItemText {...formItemProps} />

}

export default AutoFilterFormItem;


