import EstimateBase from "./EstimateBase";
import EstimateVersion from "./EstimateVersion";
import EstimateDetail from "./EstimateDetail";

import React, { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

function EstimateMgmt(props) {
    const [height, setHeight] = useState(0);

    const [estimateBaseInstance, setEstimateBaseInstance] = useState({
        viewListInstance: undefined,
        estimateNumber: undefined,
    });
    const [estimateVersionInstance, setEstimateVersionInstance] = useState({
        viewListInstance: undefined,
        estimateId: undefined,
    });
    // eslint-disable-next-line no-unused-vars
    const [estimateDetailInstance, setEstimateDetailInstance] = useState({
        viewListInstance: undefined,
        estimateItemId: undefined,
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const onEstimateBaseInitialized = (instance) => {
        setEstimateBaseInstance((oldInstance) => ({ ...oldInstance, ...instance }));
    };

    const onEstimateVersionInitialized = (instance) => {
        setEstimateVersionInstance((oldInstance) => ({ ...oldInstance, ...instance }));
    };

    const onEstimateDetailInitialized = (instance) => {
        setEstimateDetailInstance((oldInstance) => ({ ...oldInstance, ...instance }));
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <EstimateBase id={"EstimateBase"} onInitialized={onEstimateBaseInitialized}></EstimateBase>
                        </ReflexElement>

                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                        <ReflexElement>
                                <EstimateVersion
                                    id={"EstimateVersion"}
                                    estimateNumber={estimateBaseInstance.estimateNumber}
                                    onInitialized={onEstimateVersionInitialized}
                                ></EstimateVersion>
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                        <EstimateDetail
                            id={"EstimateDetail"}
                            estimateId={estimateVersionInstance.estimateId}
                            onInitialized={onEstimateDetailInitialized}
                            estimateVersionViewListInstance={estimateVersionInstance.viewListInstance}
                        />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EstimateMgmt;
