/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";


import tenantState from "../../states/tenantState";

import LoginScreen from "../screens/Login";
import ChangePassword from "../screens/ChangePassword";

function LoginRouter ( props ) {

    const [ tenant, setTenant ] = useRecoilState(tenantState);

    const location = useLocation ();

    useEffect ( () => {

        if ( location.pathname === "/" ) {

            setTenant ();

        }

       let parsed = location.pathname.split ("/");

       if ( parsed[2] === "login"  ) {

            setTenant ( parsed[1] );

       }

    }, [location.pathname])

    return (
        <>
            <Switch>

                <Route path="/changePassword" render={() => <ChangePassword />} />

                <Route path="/" render={() => <LoginScreen tenant={tenant}/>} />

                {/* Default Redirect */}

                <Route path="*">
                    <Redirect to="/" />
                </Route>

            </Switch>
        </>
    );
}

export default LoginRouter;
