import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import AccessControl from "../functional/AccessControl";

import MomSysPopNoticeDisplay from "./MomSysPopNoticeDisplay";
import MomSysPopNoticeCreateButton from "../input/MomSysPopNoticeCreateButton";
import MomSysPopNoticeUpdateButton from "../input/MomSysPopNoticeUpdateButton";
import MomSysPopNoticeDeleteButton from "../input/MomSysPopNoticeDeleteButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysPopNotice(props) {
    const id = "MomSysPopNotice";
    const table = "MomSysPopNotice";
    const view = "MomSysVwPopNotice";

    const [momSysPopNoticeSchema, setMomSysPopNoticeSchema] = useState();
    const [momSysPopNoticeGrid, setMomSysPopNoticeGrid] = useState();
    const [momSysPopNoticeApi, setMomSysPopNoticeApi] = useState();
    const [, setMomSysPopNoticeDataIsEmpty] = useState();
    const [momSysPopNoticeSelect, setMomSysPopNoticeSelect] = useState();

    const [momSysPopNoticeContent, setMomSysPopNoticeContent] = useState();
    const [momSysPopNoticeRefreshToggle, setMomSysPopNoticeRefreshToggle] = useState(false);

    const [height, setHeight] = useState();

    const { t } = useTranslation();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setMomSysPopNoticeSchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysPopNoticeGrid) {
                momSysPopNoticeGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysPopNoticeSelect(grid.selectedItems[0]);
                            console.log("momSysPopNoticeSelect = ", momSysPopNoticeGrid.selectedItems[0]);
                        }
                    },10)
                );

                momSysPopNoticeGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysPopNoticeSelect(grid.selectedItems[0]);
                            console.log("momSysPopNoticeSelect = ", momSysPopNoticeGrid.selectedItems[0]);
                        }
                    },10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [momSysPopNoticeGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if (momSysPopNoticeApi) {
            momSysPopNoticeApi.refresh();
        }
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "failure",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setMomSysPopNoticeGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMomSysPopNoticeApi(api);
                        }}
                        onLoadData={(data) => {
                            setMomSysPopNoticeDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    {momSysPopNoticeSelect ? (
                        <>
                            <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">
                                {/*<ToolbarFlexSpacer xs />*/}
                                <BasicCrudScreenActionItemLayout>
                                    <AccessControl
                                        accessTarget={momSysPopNoticeSchema && momSysPopNoticeSchema.name}
                                        accessMethod="POST"
                                    >
                                        <MomSysPopNoticeCreateButton
                                            id={id}
                                            schema={momSysPopNoticeSchema}
                                            onSetRefreshToggle={() => {
                                                setMomSysPopNoticeRefreshToggle((refreshToggle) => !refreshToggle);
                                            }}
                                            onSelectMst={momSysPopNoticeSelect}
                                            disabled={momSysPopNoticeContent}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl>

                                    <AccessControl
                                        accessTarget={momSysPopNoticeSchema && momSysPopNoticeSchema.name}
                                        accessMethod={
                                            momSysPopNoticeSchema &&
                                            (momSysPopNoticeSchema.updateWithPatch ? "PATCH" : "PUT")
                                        }
                                    >
                                        <MomSysPopNoticeUpdateButton
                                            id={id}
                                            schema={momSysPopNoticeSchema}
                                            onSetRefreshToggle={() => {
                                                setMomSysPopNoticeRefreshToggle((refreshToggle) => !refreshToggle);
                                            }}
                                            onSelectMst={momSysPopNoticeSelect}
                                            disabled={!momSysPopNoticeContent}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl>

                                    <AccessControl
                                        accessTarget={momSysPopNoticeSchema && momSysPopNoticeSchema.name}
                                        accessMethod="DELETE"
                                    >
                                        <MomSysPopNoticeDeleteButton
                                            id={id}
                                            schema={momSysPopNoticeSchema}
                                            makeItemName={(row) => {
                                                return ` - ${t("columnName.orderBuyerDetailId")}: ${row.orderBuyerDetailId
                                                    }, ${t("columnName.processCode")}: ${row.processCode}`;
                                            }}
                                            onSetRefreshToggle={() => {
                                                setMomSysPopNoticeRefreshToggle((refreshToggle) => !refreshToggle);
                                            }}
                                            onSelectMst={momSysPopNoticeSelect}
                                            disabled={!momSysPopNoticeContent}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                        />
                                    </AccessControl>
                                </BasicCrudScreenActionItemLayout>
                            </BasicCrudActionsLayout>
                            <MomSysPopNoticeDisplay
                                orderBuyerDetailId={momSysPopNoticeSelect.orderBuyerDetailId}
                                processCode={momSysPopNoticeSelect.processCode}
                                onSetContent={setMomSysPopNoticeContent}
                                refreshToggle={momSysPopNoticeRefreshToggle}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysPopNotice;
