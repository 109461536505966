/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysShipActionButton from "../input/MomSysShipActionButton";
import getSchema from "../../lib/api/getSchema";
import MomSysAllShipButton from "../input/MomSysAllShipButton ";
import defineConf from "../../config/defineConf";
import MomSysAllShipButtonWithShipDate from "../input/MomSysAllShipButtonWithShipDate";

function MomSysShipDetailOfOrderBuyer(props) {
    const { onUpdateMaster, onSelectMst, onSuccess, onFailure, shipState } = props;

    const { t } = useTranslation();
    const [vwShipDetailApi, setVwShipDetailApi] = useState();
    const [momSysLogShipSchema, setMomSysLogShipSchema] = useState();
    useEffect(() => {
        
        let mounted = true;
        (async () => {
            let schema = await getSchema("MomSysLogShip");
            if (mounted) {
                setMomSysLogShipSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (vwShipDetailApi) {
            vwShipDetailApi.refresh();
        }
    }, [onSelectMst?.shipId]);

    return (
        <BasicCrudScreenType
            id="MomSysVwShipDetailOfOrderBuyer"
            view="MomSysVwShipDetailOfOrderBuyer"
            headerTitle={t("term.shipDetail")}
            onApiInitialized={(api) => {
                setVwShipDetailApi(api);
            }}
            onModifyViewUrl={(url) => `${url}/detail/${onSelectMst?.shipId}`}
            noExcel
            onInitialized={(grid) => (grid.selectionMode = "None")}
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysAllShipButtonWithShipDate
                            id="MomSysShipDetail"
                            refresh={vwShipDetailApi}
                            onShipSelectMst={onSelectMst}
                            onUpdateMaster={onUpdateMaster}
                            disabled={
                                onSelectMst?.length < 1 ? true : false ||
                                shipState === defineConf.shipState.SHIP_COMPLETE
                            }
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </BasicCrudScreenActionItemLayout>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysShipActionButton
                            id="MomSysShipDetail"
                            schema={momSysLogShipSchema}
                            refresh={vwShipDetailApi}
                            onShipSelectMst={onSelectMst}
                            onUpdateMaster={onUpdateMaster}
                            disabled={
                                onSelectMst?.length < 1 ? true : false ||
                                shipState === defineConf.shipState.SHIP_COMPLETE
                            }
                            onSuccess={onSuccess}
                        />
                    </BasicCrudScreenActionItemLayout>
                </>
            )}
        />
    );
}

export default MomSysShipDetailOfOrderBuyer;
