import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import ProcessCategoryCreateButton from "../input/ProcessCategoryCreateButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import ProcessCategoryUpdateButton from "../input/ProcessCategoryUpdateButton";

function ProcessCategoryDetail(props) {
    const { id, selectMstGrid } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [onSelectGrid, setOnSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;

        async function schemaSetting() {
            let schema = await getSchema("ProcessCategory");

            if ( mounted ) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMstGrid]);
    
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
        if(api) {
            api.refresh();
        };
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onModifyUrl = (url) => {
        return `api/ProcessCategoryView/${selectMstGrid.tenantId}`;
    };

    const removeModifyUrlCallback = (url) => {
        return `${url}/${selectMstGrid.tenantName}/${selectMstGrid.tenantId}/${onSelectGrid.processCategoryCode}/${onSelectGrid.processCategoryName}`;
    };
    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="ProcessCategory"
                view="ProcessCategoryView"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                onModifyViewUrl={(url) => onModifyUrl(url)}
                headerTitle={t("term.processCategory")}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <ProcessCategoryCreateButton
                                    id="ProcessCategoryCreate"
                                    schema={schema}
                                    refresh={api}
                                    selectMstGrid={selectMstGrid}
                                    onSuccess={onSuccess}
                                    disabled={selectMstGrid ? false : true}
                                />
                            </AccessControl>
                            
                            <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                                <ProcessCategoryUpdateButton
                                    id="ProcessCategoryUpdate"
                                    schema={schema}
                                    refresh={api}
                                    onSelectGrid={onSelectGrid}
                                    onSuccess={onSuccess}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>

                            <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                <DeleteRecordButton
                                    id={id}
                                    schema={schema}
                                    grid={grid}
                                    refresh={api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                    modifyUrlCallback={(url) => removeModifyUrlCallback(url)}
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default ProcessCategoryDetail;
