import { useState } from "react";
import DialogActionButtonLayout from "../layout/DialogActionButtonLayout";

function DialogActionButton(props) {

    const { instance, primary, close, onClick } = props;

    const [, setTimer] = useState(null);

    const onClickButton = (event) => {

        setTimer((timer) => {

            if (timer) {

                return timer;

            }


            if (close) {

                instance.hide();

            }

            if (onClick) {

                onClick(event);

            }

            let newTimer = setTimeout(() => {
                setTimer(null)
            }, 500);

            return newTimer;

        });


    }

    return <DialogActionButtonLayout color={primary ? "primary" : "secondary"} {...props} onClick={onClickButton} />

}

export default DialogActionButton;

