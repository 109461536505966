import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import MomSysMaterialByCompanyCreateButton from "../input/MomSysMaterialByCompanyCreateButton";
import MomSysMaterialByCompanyUpdateButton from "../input/MomSysMaterialByCompanyUpdateButton";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import DeleteRecordButton from "../input/DeleteRecordButton";
import DataAllSaveToExcelButton from "../input/DataAllSaveToExcelButton";

function MomSysMaterialByCompany ( props ) {
    const id = "MomSysVwMaterialByCompany";

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect( ()=> {

        let mounted = true;

        (async ()=> {
            
            let schema = await getSchema("MomSysMaterialByCompany");

            if ( mounted ) {

                setSchema(schema);

            }

        })();

        return ( () => {

            mounted = false;

        } );

    },[]);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        grid.selectionMode = "Row";
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <BasicCrudScreenType
            id={id}
            table="MomSysMaterialByCompany"
            view="MomSysVwMaterialByCompanyUpdate" 
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
               <>
                    <BasicCrudScreenActionItemLayout>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <MomSysMaterialByCompanyCreateButton
                                id = "MomSysMaterialByCompanyCreate"
                                schema = {schema}
                                refresh = {api}
                                onSuccess = {onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                            <MomSysMaterialByCompanyUpdateButton
                                id = "MomSysMaterialByCompanyUpdate"
                                schema = {schema}
                                refresh = {api}
                                grid = {grid}
                                onSuccess = {onSuccess}
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                            <DataAllSaveToExcelButton
                                id={id}
                                grid={grid}
                                view="MomSysVwMaterialByCompanyUpdate"
                                excelFileName="거래처별 품목"
                                color="primary"
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>
                    </BasicCrudScreenActionItemLayout>
               </> 
            )}
        />
    );

}

export default MomSysMaterialByCompany;
