import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";
import notificationState from "../../states/notificationState";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import VwMsgTrash from "./VwMsgTrash";
import Message from "./Message";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import apiClient from "../../lib/common/apiClient";

function Trash ( props ) {

    const { t } = useTranslation();

    const [ msg, setMsg ] = useState ( );
    const [ height, setHeight ] = useState(0);
    const [ disabled, setDisabled ] = useState(false);

    const [ , setApi ] = useState ( );
    const [ , setGrid ] = useState ( );
    const [ , setSelection ] = useState( "" );
    const [ , setConfirmDialog ] = useState( );

    const [ confirmTitle, setConfirmTitle ] = useState( "" );
    const [ confirmMsg, setConfirmMsg ] = useState( "" );
    const [ action, setAction ] = useState({
        operation: undefined, // "markReadAll", "markUnread", "delete"
        data: undefined
    } );

    const setNotification = useSetRecoilState(notificationState);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectMsg = ( selection, grid ) => {


        if ( selection.length > 0 ) {

            setDisabled ( false );

        } else {

            setDisabled ( true );

        }

        if ( selection && selection[0] ) {

            setMsg ( selection[0] );

        } else {

            setMsg ( undefined );

        }

        setSelection ( selection );

    };

    const onGridInitialized = ( grid ) => {

        setGrid ( grid );

    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onConfirmDialogInitialized = ( dialog ) => {

        setConfirmDialog ( dialog );

    };

    const deleteMsg = ( selection ) => {

        apiClient.bulkDel ( `api/TrashMsg`, selection )
            .then ( ( result ) => {

                    setApi ( ( api ) => {

                        if ( api ) {
                            
                            api.refresh ( false );
        
                        }

                        return api;

                    } );


                    notifySuccess ( t ( "success.deleteMessage" ) );

                } )
                .catch ( ( reason ) => {

                    notifyFailure  ( t ( "error.deleteMessage" ) );

                } );

    };

    const emptyTrash = () => {

        apiClient.put ( `api/TrashMsg/empty`, {} )
            .then ( ( result ) => {

                    setApi ( ( api ) => {

                        if ( api ) {
                            
                            api.refresh ( false );
        
                        }

                        return api;

                    } );


                    notifySuccess ( t ( "success.emptyTrash" ) );

                } )
                .catch ( ( reason ) => {

                    notifyFailure  ( t ( "error.emptyTrash" ) );

                } );

    };

    const recover = ( selection ) => {

        apiClient.put ( `api/TrashMsg/recover`, selection )
            .then ( ( result ) => {

                    setApi ( ( api ) => {

                        if ( api ) {
                            
                            api.refresh ( false );
        
                        }

                        return api;

                    } );

                    notifySuccess ( t ( "success.recoverMessage" ) );

                } )
                .catch ( ( reason ) => {

                    notifyFailure  ( t ( "error.recoverMessage" ) );

                } );

    };

    const onRecover = () => {

        setSelection ( ( selection ) => {
            
            setAction ( {
                operation: "recover",
                data: selection
            } );
    
            setConfirmTitle ( t("dialog.title.Message.confirm.recover") );
            setConfirmMsg ( t ("dialog.msg.Message.confirm.recover") );
            setConfirmDialog ( ( dialog ) => {
                
                dialog.show ();
                return dialog;
    
            } );

            return selection;
    
        } );

    }

    const onDelete = ( selection ) => {

        setSelection ( ( selection ) => {

            setAction ( {
                operation: "delete",
                data: selection
            } );

            setConfirmTitle ( t("dialog.title.Message.confirm.delete") );
            setConfirmMsg ( t ("dialog.msg.Message.confirm.delete") );
            setConfirmDialog ( ( dialog ) => {
                
                dialog.show ();
                return dialog;

            } );

            return selection;

        } );

    };

    const onEmpty = (  ) => {

        setAction ( {
            operation: "empty",
        } );

        setConfirmTitle ( t("dialog.title.Message.confirm.empty") );
        setConfirmMsg ( t ("dialog.msg.Message.confirm.empty") );
        setConfirmDialog ( ( dialog ) => {
            
            dialog.show ();
            return dialog;

        } );

    };

    const onConfirmed = ( action ) => {

        if ( action ) {

            switch ( action.operation ) {

                case "recover":

                    recover ( action.data );
                    
                    break;

                case "delete":

                    deleteMsg ( action.data );
                    break;

                case "empty":

                    emptyTrash ( );
                    break;

                default:

                    notifyFailure ( t ( "error.unknownOperation" ) );
                    break;

            }

        }

    };

    return (

        <div style={{ height: height } }>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={ 0.5 } >

                    <VwMsgTrash

                        headerTitle={ t ( "term.msgList" ) }

                        noCreate
                        noEdit
                        noDelete
                        noExcel

                        onAppendButtonInFrontOfSearch = {

                            ( ) => (

                                <>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="primary" disabled={disabled} onClick={ onRecover }>{ t ( "buttonName.recover" ) }</Button>

                                    </BasicCrudScreenActionItemLayout>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="secondary" disabled={disabled} onClick={ onDelete }>{ t ( "buttonName.delete" ) }</Button>

                                    </BasicCrudScreenActionItemLayout>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="secondary" disabled={disabled} onClick={ onEmpty }>{ t ( "buttonName.empty" ) }</Button>

                                    </BasicCrudScreenActionItemLayout>

                                </>

                            )

                        }

                        onSelect={ onSelectMsg }
                        onInitialized={ onGridInitialized }
                        onApiInitialized={ onApiInitialized }

                    />

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <Message msg={ msg } />

                </ReflexElement>

            </ReflexContainer>

            <ConfirmDialog

                id={"inbox-confirm-dialog"}
                title={ confirmTitle }
                msg={ confirmMsg }
                data={ action }
                onOk={ ( action ) => onConfirmed ( action ) }
                onInitialized={ onConfirmDialogInitialized }

            />

        </div>

    );
}

export default Trash;

