/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

import MomSysLotListForProcess from "../screens/MomSysLotListForProcess";
import MomSysBonusLoss from "../screens/MomSysBonusLoss";
import MomSysFailedLot from "../screens/MomSysFailedLot";


function CompleteProcessDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();

    const [formData, setFormData] = useState({});
    const [schema, setSchema] = useState();

    const [lotGrid, setLotGrid] = useState();
    const [failedLotList, setFailedLotList] = useState ([]);
    const [lotSelectionType, setLotSelectionType] = useState ("auto");
    const [bonus, setBonus] = useState (0);
    const [loss, setLoss] = useState (0);
    const [materials, setMaterials] = useState([]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        lotInfo,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    useEffect ( () => {

        let schema = {

            name: "Production",
            type: "BASE TABLE",
            searchAll: false,
            formGroups: [
                "soInfo",
                "bobMaterialInfo",
                "lotInfo",
                "machineInfo",
            ],

            columns: [

                {
                    
                    name: "orderBuyerId",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "companyName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 0,
                    formOrder: 2,
                    formItemType: "text",

                },

                {
                    
                    name: "bopMaterialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 3,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialCodeVer",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 1,
                    formOrder: 4,
                    formItemType: "text",

                },
                {

                    name: "bopMaterialName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 1,
                    formOrder: 5,
                    formItemType: "text",

                },


                {

                    name: "lotNo",
                    type: "int(11)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: "0",
                    formGroup: 2,
                    formOrder: 6,
                    formItemType: "text",

                },
                {

                    name: "lineNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 7,
                    formItemType: "text",

                },
                {

                    name: "machineCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formGroup: 3,
                    formOrder: 8,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysMstMachine",
                        nameColumn: ["machineCode", "machineName"],
                        valueColumn: "machineCode",
                        seperator: ": ",
                        sortByName: true,
                    },
    
                },
                {

                    name: "processCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 10,
                    formItemType: "text",

                },
                {

                    name: "processName",
                    type: "varchar(50)",
                    nullable: "NO",
                    key: "PRI",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 11,
                    formItemType: "text",

                },
                {

                    name: "qty",
                    type: "float",
                    nullable: "NO",
                    key: "",
                    readonly: true,
                    default: null,
                    formGroup: 2,
                    formOrder: 12,
                    formItemType: "text",
                    validation: {
                        msg: "error.floatRequired",
                        type: "regexp",
                        value: "^[+-]?([0-9]*[.])?[0-9]+$"
                    },

                },

                {

                    name: "productDate",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formGroup: 3,
                    formOrder: 15,
                    formItemType: "date",
                },
                
            ]

        };

        setSchema ( schema );
        
    }, [] );

    useEffect ( () => {

        let mounted = true;

        if ( lotInfo ) {

            ( async () => {

                let result = await apiClient.get ( `/api/MomSysVwMaterialsForProcessOnLine/${lotInfo.lotNo}`);

                if ( mounted ) {

                    if ( result.data.data.length > 0 ) {

                        setMaterials ( result.data.data )
    
                    } else {
    
                        setMaterials ( [] );
    
                    }

                }

            } ) ();
                
            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: lotInfo.orderBuyerId,
                    companyName: lotInfo.companyName,

                    bopMaterialCode: lotInfo.bopMaterialCode,
                    bopMaterialCodeVer: lotInfo.bopMaterialCodeVer,
                    bopMaterialName: lotInfo.bopMaterialName,

                    lotNo: lotInfo.lotNo,
                    lineNo: lotInfo.lineNo,
                    machineCode: lotInfo.machineCode,
                    machineName: lotInfo.machineName,
                    processCode: lotInfo.processCode,
                    processName: lotInfo.processName,
                    qty: lotInfo.qty,

                };

                return newFormData;

            } );

        } else {

            setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    orderBuyerId: undefined,
                    companyName: undefined,

                    bopMaterialCode: undefined,
                    bopMaterialCodeVer: undefined,
                    bopMaterialName: undefined,

                    lotNo: undefined,
                    lineNo: undefined,
                    machineCode: undefined,
                    machineName: undefined,
                    processCode: undefined,
                    processName: undefined,
                    qty: undefined,

                };

                return newFormData;

            } );


        }

        return ( () => {

            mounted = false;

        } );

    }, [lotInfo])

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (lotInfo, result) => {

        if ( onSuccess ) {

            onSuccess ( result.data );

        }

        instance.hide();

    };

    const onApiFailure = (lotInfo, reason) => {

        notifyFailure ( t ( "error.productionStartFail", { lotNo: lotInfo.lotNo } ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const checkItemValidataion = ( schema, lotInfo, lotSelectionType, lotGrid, failedLotList, bonus, loss, materials, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                notifyWarning ( msg );
                return false;

            }
            
        }

        if ( formData.qty <= 0 ) {

            notifyWarning ( t ( "error.emptyOrNegativeProductionQty") );
            return false;


        }

        let failedQty = 0;

        for ( const lot of failedLotList ) {

            failedQty += lot.qty;

        }

        if ( ( failedQty + ( loss - bonus )  ) > formData.qty ) {

            notifyWarning ( t ( "error.failAndLossQtyExceedsProductionQty" ) );
            return false;

        }

        let materialMap = {};

        if ( lotGrid && lotSelectionType === "manual" ) {

            for ( const lot of lotGrid.selectedItems ) {

                let key = `[${lot.materialCode}][${lot.materialCodeVer}]`;

                if ( materialMap[key] ) {

                    notifyWarning ( t ( "error.duplicateMaterialSelected" ) );
                    return false;

                } else {

                    materialMap[key] = { materialCode: lot.materialCode, materialCodeVer: lot.materialCodeVer, qty: parseInt(lot.qtyInstack), consumed: parseInt(lot.qtyConsumed) };

                }

            }

            for ( const material of materials ) {

                let key = `[${material.materialCode}][${material.materialCodeVer}]`;
    
                if ( ! materialMap[key] ) {
    
                    notifyWarning ( t ( "error.materialsForProductionNotSelected" ) );
                    return false;
    
                }
    
                if ( materialMap[key].qty < material.qtyRequired ) {
    
                    notifyWarning ( t ( "error.materialNotOnProductionLine" ) );
                    return false;
    
                }
    
            }

        }


        return true;

    };

    const onOk = ( schema, lotInfo, lotSelectionType, lotGrid, failedLotList, bonus, loss, materials, formData ) => {
        
        if ( ! checkItemValidataion ( schema, lotInfo, lotSelectionType, lotGrid, failedLotList, bonus, loss, materials, formData ) ) {

            console.error ( "Form Validation Failed" );
            return;

        }

        if ( schema.validation ) {

            let [valid, msg] = checkConditionWithMsg (

                formData,
                schema.validation

            );

            if (!valid) {

                notifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        let inputLotList = [];

        if ( lotGrid && lotSelectionType === "manual" ) {

            for ( const lot of lotGrid.selectedItems ) {

                inputLotList.push ( { lotNo: lot.lotNo, qty: parseInt(lot.qtyConsumed) } );

            }
        }

        let url = `/api/Production/${lotInfo.lotNo}/complete`;

        let parsedBonus = parseFloat ( bonus );
        let parsedLoss = parseFloat ( loss );

        parsedBonus = isNaN ( parsedBonus ) ? 0 : parsedBonus;
        parsedLoss = isNaN ( parsedLoss ) ? 0 : parsedLoss;

        let newBonus;
        let newLoss;

        if ( ( parsedBonus - parsedLoss ) >= 0 ) {

            newBonus = parsedBonus - parsedLoss;
            newLoss = 0;
            
        } else {

            newBonus = 0;
            newLoss = parsedLoss - parsedBonus;

        }

        let apiData = {
            lotNo: lotInfo.lotNo,
            lotSelectionType: lotSelectionType,
            machineCode: formData.machineCode,
            inputLotList: inputLotList,
            failedLotList: failedLotList,
            bonus: newBonus,
            loss: newLoss,
            productDate : formData.productDate,
        };

        // build base api url
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            json[formItem] = apiData[formItem];

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        
        apiClient
            .put(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then ( ( result ) => {
                onApiSuccess ( lotInfo, result );
            } )
            .catch ( ( reason ) => {
                onApiFailure ( lotInfo, reason );
            } );
            
    }

    const onCancel = () => {

        instance.hide ();

        setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    productDate : null

                };

                return newFormData;

        });

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }

        setFormData ( ( formData ) => {

                let newFormData = {

                    ...formData,

                    productDate : null

                };

                return newFormData;

        });
        
    };

    const onChangeMachineCode = ( formData ) => {

        setFormData( {
            ...formData,
            machineCode: formData.machineCode
        } );

    };

    const onChangeFailedLotList = (failedLotList) => {

        setFailedLotList ( failedLotList );

    };

    const onLotSelectionTypeChanged = (lotSelectionType) => {

        setLotSelectionType ( lotSelectionType );

    };

    const onLotGridInitialized = (lotGrid) => {

        setLotGrid ( lotGrid );

    };

    const onBonusLossChange = (bonusLoss) => {

        let { bonus, loss } = bonusLoss;

        setBonus ( bonus );
        setLoss ( loss );

    };

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.Produce.completeProcess" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "710px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.6} >

                        <ReflexContainer orientation="horizontal">

                            <ReflexElement flex={0.6} >

                                <MomSysLotListForProcess lotInfo={lotInfo} notifyWarning={notifyWarning} onLotSelectionTypeChanged={onLotSelectionTypeChanged} onInitialized={onLotGridInitialized}/>

                            </ReflexElement>

                            <ReflexSplitter
                                style={{border: "none",height: "1px",color:"lightgray"}}
                            />

                            <ReflexElement flex={0.4} >

                                <MomSysFailedLot notifyWarning={notifyWarning} onChangeFailedLotList={onChangeFailedLotList} />

                            </ReflexElement>

                        </ReflexContainer>

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.4} >

                        <ReflexContainer orientation="horizontal">

                            <ReflexElement flex={0.83}>

                                <div
                                    style={{
                                        padding: "16px"
                                    }}                    
                                >

                                    <AutoForm
                                        id="bop-out-material-warehouse-form"
                                        schema={schema}
                                        mode="edit"
                                        initialData={formData}
                                        onChange={onChangeMachineCode}
                                    />

                                </div>

                            </ReflexElement>

                            <ReflexSplitter
                                style={{border: "none",height: "1px",color:"lightgray"}}
                            />

                            <ReflexElement flex={0.27} minSize={96} maxSize={96}>

                                <MomSysBonusLoss onBonusLossChange={onBonusLossChange} />
                                
                            </ReflexElement>

                        </ReflexContainer>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("buttonName.completeProcessing")]}
                onOk={() => onOk(schema, lotInfo, lotSelectionType, lotGrid, failedLotList, bonus, loss, materials, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CompleteProcessDialog;
