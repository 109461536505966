/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { Checkbox } from "@material-ui/core";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";


// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridCheckboxColumn ( props ) {

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            return (
                <>
                    <Checkbox
                        color="info"
                        checked={ ctx.item[schema.name] }
                        style={{
                            height: "22px",
                        }}
                        onChange = { ( event ) => {
                            if ( schema.onChange ) {
                                schema.onChange ( ctx, event.target.checked );
                            }
                        } }
                    />
                </>
            );


        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridCheckboxColumn;

