/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSpecific from "../../../lib/api/getSpecific";
import apiClient from "../../../lib/common/apiClient";

import ApprovalPath from "../../presentation/ApprovalPath";

import uiConf from "../../../config/uiConf";
import getSchema from "../../../lib/api/getSchema";

import notificationState from "../../../states/notificationState";
import BasicCrudScreenTypeLayout from "../layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../layout/BasicCrudScreenHeaderLayout";
import BasicCrudScreenContentLayout from "../layout/BasicCrudScreenContentLayout";
import BasicCrudScreenFooterLayout from "../layout/BasicCrudScreenFooterLayout";
import BasicCrudActionsLayout from "../layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../layout/BasicCrudScreenActionItemLayout";

import AddApproverButton from "../../input/AddApproverButton";
import InsertApproverButton from "../../input/InsertApproverButton";
import DeleteApproverButton from "../../input/DeleteApproverButton";

const useStyles = makeStyles((theme) => ({
    label: {
        marginTop: "16px"
    }
}));

function ApprovalPathEditScreenType ( props ) {

    let defaultId = "approval-path";

    const { t } = useTranslation ();

    const classes = useStyles ();

    const {

        id,
        approvalPathId

    } = props;

    const [ schema, setSchema ] = useState ();
    const [ approvalPath, setApprovalPath ] = useState ();
    const [ approvalPathNodes, setApprovalPathNodes ] = useState ();
    const [ selected, setSelected ] = useState ();

    const setNotification = useSetRecoilState(notificationState);

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "ApprovalPathNode" );

            if ( mounted ) {
                
                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    useEffect ( () => {

        let mounted = true;

        if ( approvalPathId ) {

            ( async () => {

                let approvalPath = await getSpecific ( "ApprovalPath", approvalPathId );

                if ( mounted ) {
                    setApprovalPath ( approvalPath );
                }

                let apiResult = await apiClient.get ( `/api/VwApprovalPathNode/${approvalPathId}` );

                if ( mounted ) {
                    let approvalPathNodes = apiResult.data.data;

                    approvalPathNodes.sort ( ( nodeA, nodeB ) => ( nodeA.order - nodeB.order ) );
    
                    setApprovalPathNodes ( approvalPathNodes );
    
                    setSelected ( approvalPathNodes[0] );
                }


            } ) ();

        }

        return ( () => {

            mounted = false;

        } );

    }, [approvalPathId] );

    const onApproverSelected = ( selected ) => {

        setSelected ( selected );

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };
    
    const notifyInfo = (msg) => {
    
        setNotification({
            severity: "info",
            msg: msg,
            open: true,
        });
            
    };
    
    const notifyError = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const notifyWarning = (msg) => {
    
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );

        if ( approvalPathId ) {

            ( async () => {

                let approvalPath = await getSpecific ( "ApprovalPath", approvalPathId );

                setApprovalPath ( approvalPath );

                let apiResult = await apiClient.get ( `/api/VwApprovalPathNode/${approvalPathId}` );

                let approvalPathNodes = apiResult.data.data;
                
                approvalPathNodes.sort ( ( nodeA, nodeB ) => ( nodeA.order - nodeB.order ) );

                setApprovalPathNodes ( approvalPathNodes );

            } ) ();

        }
    
    };
    
    const onFailureWithReason = ( reason ) => {
    
        notifyError ( reason.response ? reason.response.data : reason );
    
    };
    
    const onFailureWithMsg = ( reason ) => {
    
        notifyError ( reason.response ? reason.response.data : reason );
    
    };

    return (

        <div>

            <BasicCrudScreenTypeLayout>


                <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">


                    <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                        <BasicCrudScreenActionItemLayout>

                            <AddApproverButton
                                id={id || defaultId}
                                color="primary"
                                schema={schema}
                                approvalPathId={approvalPathId}
                                approverCount={approvalPathNodes ? approvalPathNodes.length : 0}
                                onSuccess={onSuccess}
                                onFailure={onFailureWithMsg}
                            />

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <InsertApproverButton
                                id={id || defaultId}
                                color="primary"
                                schema={schema}
                                approvalPathNode={selected}
                                disabled={approvalPathNodes && approvalPathNodes.length===0}
                                onSuccess={onSuccess}
                                onFailure={onFailureWithMsg}
                            />

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <DeleteApproverButton
                                id={id || defaultId}
                                approvalPathNode={selected}
                                color="secondary"
                                disabled={approvalPathNodes && approvalPathNodes.length===0}
                                onSuccess={onSuccess}
                                onFailure={onFailureWithMsg}
                            />

                        </BasicCrudScreenActionItemLayout>

                    </BasicCrudActionsLayout>

                    
                </BasicCrudScreenHeaderLayout>
                                

                <BasicCrudScreenContentLayout className="basicCrudScreenContentLayout">


                    <ApprovalPath approvalPath={approvalPath} approvalPathNodes={approvalPathNodes} onApproverSelected={onApproverSelected} />


                </BasicCrudScreenContentLayout>

            </BasicCrudScreenTypeLayout>

        </div>

    );

}

export default ApprovalPathEditScreenType;
