import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import notificationState from "../../states/notificationState";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"

function ProcessCategoryMaster(props) {
    const { id, masterGridSetting } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [onSelectGrid, setOnSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("Tenant");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
        
    }, []);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="Tenant"
                view="Tenant"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.tenant")}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default ProcessCategoryMaster;
