import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import notificationState from "../../states/notificationState";
import layoutConf from "../../config/layoutConf";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import getSchema from "../../lib/api/getSchema";
import PopOutSourcingInputDoInputButton from "../input/PopOutSourcingInputDoInputButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

const id = "VwPopOutSourcingInput";
const table = "PopOutSourcingInput";

function PopOutSourcingInput(props) {

    const [height, setHeight] = useState(0);
    const { t } = useTranslation();

    const [popOutSourcingInputSchema, setPopOutSourcingInputSchema] = useState();
    const [popVwOutSourcingInputTargetListGrid, setPopVwOutSourcingInputTargetListGrid] = useState();
    const [popVwOutSourcingInputTargetListApi, setPopVwOutSourcingInputTargetListApi] = useState();
    const [popVwOutSourcingInputTargetListSelect, setPopVwOutSourcingInputTargetListSelect] = useState();

    const [popVwOutSourcingInputGrid, setPopVwOutSourcingInputGrid] = useState();
    const [popVwOutSourcingInputApi, setPopVwOutSourcingInputApi] = useState();
    const [popVwOutSourcingInputSelect, setPopVwOutSourcingInputSelect] = useState();

    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setPopOutSourcingInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        const path = `popOutSourcingInputLot/${popVwOutSourcingInputSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [popVwOutSourcingInputSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (popVwOutSourcingInputTargetListGrid && mounted) {
                popVwOutSourcingInputTargetListGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputTargetListSelect([]);
                        }
                    }),
                    10
                );

                popVwOutSourcingInputTargetListGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputTargetListSelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [popVwOutSourcingInputTargetListGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (popVwOutSourcingInputGrid && mounted) {
                popVwOutSourcingInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputSelect([]);
                        }
                    }),
                    10
                );

                popVwOutSourcingInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputSelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [popVwOutSourcingInputGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Target">
                    <BasicCrudScreenType
                        id={id + "-Target"}
                        view="VwPopOutSourcingInput"
                        headerTitle={t("term.popVwOutSourcingInputTargetList")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopVwOutSourcingInputTargetListGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopVwOutSourcingInputTargetListApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <PopCrudScreenActionItemLayout>
                                    <PopOutSourcingInputDoInputButton
                                        id={id}
                                        schema={popOutSourcingInputSchema}
                                        onSelectMst={popVwOutSourcingInputTargetListSelect}
                                        refreshFtn={() => {
                                            popVwOutSourcingInputTargetListApi.refresh();

                                            if (popVwOutSourcingInputApi) {
                                                popVwOutSourcingInputApi.refresh();
                                            }
                                        }}
                                        disabled={popVwOutSourcingInputTargetListSelect?.length <= 0}
                                        onSuccess={onSuccess}
                                        onFarilur={onFailure}
                                    />
                                </PopCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4} data-cy="History">
                    <BasicCrudScreenType
                        id={"VwPopOutSourcingInputHistory"}
                        view="VwPopOutSourcingInputHistory"
                        headerTitle={t("term.popVwOutSourcingInputHistory")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopVwOutSourcingInputGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopVwOutSourcingInputApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <PopCrudScreenActionItemLayout>
                                    <PrintReportButton
                                        noLayout
                                        url={reportUrl}
                                        disabled={!popVwOutSourcingInputSelect?.lotNo}
                                    >
                                        {t("buttonName.lotInfoPrint")}
                                    </PrintReportButton>
                                </PopCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default PopOutSourcingInput;
