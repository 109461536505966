import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguPopQCRequestButton(props) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, onSelectMst, onTrigger, ...others } = props;

    const [open, setOpen] = useState(false);

    const [schema, setSchema] = useState();

    const [callBack, setCallback] = useState();

    useEffect(() => {

        let schema = {

            name: "EguPopProductManage",
            type: "BASE TABLE",
            searchAll: false,
            formGroups: [
                "disabled",
                "able",
            ],

            columns: [

                {

                    name: "planId",
                    type: "BIGINT(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    hideInForm : true,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",

                },
                {

                    name: "orderBuyerId",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",

                },
                {

                    name: "materialName",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 2,
                    formItemType: "text",

                },
                {

                    name: "qcManager",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formGroup: 1,
                    formOrder: 5,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "User",
                        nameColumn: ["fullName", "username"],
                        valueColumn: "username",
                        seperator: ": ",
                        sortByName: true,
                    },

                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen(true);
        onTrigger(true)
    };

    const onClose = () => {

        setOpen(false);
        onTrigger(false)
    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    const modifyUrlCallback = (url) => {

        return `${url}/${onSelectMst.resultLotNo}/qcRequest`;

    }

    useEffect(() => {

        setCallback(() => (formData) => {

            if (!formData) {

                formData = {};

            }

            formData.planId = onSelectMst.planId;
            formData.orderBuyerId = onSelectMst.orderBuyerId;
            formData.materialName = onSelectMst.materialName;

        });

    }, [onSelectMst])

    return (

        <>
            <CreateRecordButtonLayout id={id + "-qcRequest-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <AutoFormDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={t(`dialog.title.Produce.qcRequest`)}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyUrlCallback={modifyUrlCallback}
                setInitialDataCallback={callBack}
            />
        </>

    );
}

export default EguPopQCRequestButton;

