import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguPopTempButton ( props ) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, modifyUrlCallback, category, onSelectMst, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [ schema, setSchema ] = useState(false);

    const [ callBack, setCallback ] = useState();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    useEffect ( () => {

        let soSchema = {

            name: "OrderBuyerMstPlan",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {
                    
                    name: "taskId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 2,
                    formItemType: "text",

                },
                {
                    
                    name: "orderBuyerId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysUglOrderBuyerMst",
                        nameColumn: ["orderBuyerName", "orderBuyerId"],
                        valueColumn: "orderBuyerId",
                        seperator: ": ",
                        sortByName: true,
                    },
                },
                {
                    
                    name: "taskName",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 2,
                    formItemType: "text",

                },

                {
                    
                    name: "taskType",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 3,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "text",

                },
                
            ]

        };

        let soDetailSchema = {

            name: "OrderBuyerDetailPlan",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {
                    
                    name: "planId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "parentPlanId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "OrderBuyerMstPlan",
                        nameColumn: ["planId", "orderBuyerId"],
                        valueColumn: "planId",
                        seperator: ": ",
                        sortByName: true,
                    },
                },
                // {
                    
                //     name: "type",
                //     type: "varchar(50)",
                //     nullable: "NO",
                //     default: null,
                //     formOrder: 2,
                //     formItemType: "text",

                // },
                {
                    
                    name: "orderBuyerDetailId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formOrder: 3,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysZvwOrderBuyerDetail/${onSelectMst?.orderBuyerId}`,
                        nameColumn: ["orderBuyerDetailId", "materialName", "materialCode", "materialCodeVer"],
                        valueColumn: "orderBuyerDetailId",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "taskName",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 4,
                    formItemType: "text",

                },

                {
                    
                    name: "taskType",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 5,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    formOrder: 6,
                    formItemType: "text",

                },
                
            ]

        };

        let materialSchema = {

            name: "materialPlan",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {
                    
                    name: "planId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "parentPlanId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "OrderBuyerDetailPlan",
                        nameColumn: ["planId", "orderBuyerDetailId"],
                        valueColumn: "planId",
                        seperator: ": ",
                        sortByName: true,
                    },
                },
                {
                    
                    name: "materialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 2,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysZvwOrderBuyerDetail/${onSelectMst?.orderBuyerId}`,
                        // nameColumn: ["orderBuyerDetailId", "materialCode", "materialCodeVer"],
                        nameColumn: ["materialCode",],
                        valueColumn: "materialCode",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "materialCodeVer",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 3,
                    formItemType: "text",

                },
                {
                    
                    name: "plannedQty",
                    type: "int(11)",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "number",

                },
                {
                    
                    name: "taskName",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 5,
                    formItemType: "text",

                },

                {
                    
                    name: "taskType",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 6,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    formOrder: 7,
                    formItemType: "text",

                },
                
            ]

        };

        let processSchema = {

            name: "processPlan",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {
                    
                    name: "planId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "parentPlanId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MaterialPlan",
                        nameColumn: ["planId", "materialCode", "materialCodeVer", "plannedQty"],
                        valueColumn: "planId",
                        seperator: ": ",
                        sortByName: true,
                    },
                },
                {
                    
                    name: "bopMaterialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 2,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysZvwBillOfProcess/${onSelectMst?.materialCode}/${onSelectMst?.materialCodeVer}`,
                        nameColumn: ["bopMaterialCode"],
                        valueColumn: "bopMaterialCode",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "bopMaterialCodeVer",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formOrder: 3,
                    formItemType: "text",

                },
                {
                    
                    name: "processCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysZvwBillOfProcess/${onSelectMst?.materialCode}/${onSelectMst?.materialCodeVer}`,
                        nameColumn: ["processCode", "processName"],
                        valueColumn: "processCode",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "lineNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysUdtLineNo",
                        nameColumn: ["lineNo"],
                        valueColumn: "lineNo",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "machineCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysMstMachine",
                        nameColumn: ["machineCode", "machineName"],
                        valueColumn: "machineCode",
                        seperator: ": ",
                        sortByName: true,
                    },

                },
                {
                    
                    name: "plannedStartTime",
                    type: "datetime",
                    nullable: "NO",
                    default: null,
                    formOrder: 4,
                    formItemType: "datetime",
                },
                {
                    
                    name: "plannedQty",
                    type: "int(11)",
                    nullable: "NO",
                    default: null,
                    formOrder: 5,
                    formItemType: "number",

                },
                {
                    
                    name: "taskName",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 5,
                    formItemType: "text",

                },

                {
                    
                    name: "taskType",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 6,
                    formItemType: "text",

                },
                {

                    name: "description",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    formOrder: 7,
                    formItemType: "text",

                },
                
            ]

        };

        let workerSchema = {

            name: "ProcessPlanWorkerAssignment",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                {
                    
                    name: "planId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    required: true,
                    formOrder: 1,
                    formItemType: "text",

                },
                {
                    
                    name: "username",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 2,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "User",
                        nameColumn: ["fullName"],
                        valueColumn: "username",
                        seperator: ": ",
                        sortByName: true,
                    },
                },
                
            ]

        };

        if(category === "so") {

            setSchema ( soSchema );

        }else if (category === "soDetail") {

            setSchema ( soDetailSchema );

        }else if (category === "material") {

            setSchema ( materialSchema );

        }else if (category === "process") {

            setSchema ( processSchema );

        } else {

            setSchema ( workerSchema );

        }
        
    }, [onSelectMst, category] );

    useEffect ( () => {

        if(category === "so") {

            

        }else if (category === "soDetail") {

            setCallback ( () => ( formData ) => {

                if ( !formData ) {
    
                    formData = {};
                    
                }
    
                formData.parentPlanId = onSelectMst.taskId;
    
            });

        }else if (category === "material") {

            setCallback ( () => ( formData ) => {

                if ( !formData ) {
    
                    formData = {};
                    
                }
    
                formData.parentPlanId = onSelectMst.soDetailPlanId;
                formData.materialCode = onSelectMst.materialCode;
                formData.materialCodeVer = onSelectMst.materialCodeVer;
    
            });

        }else if (category === "process") {
            
            setCallback ( () => ( formData ) => {

                if ( !formData ) {
    
                    formData = {};
                    
                }
    
                formData.parentPlanId = onSelectMst.materialPlanId;
                formData.bopMaterialCode = onSelectMst.materialCode;
                formData.bopMaterialCodeVer = onSelectMst.materialCodeVer;
    
            });

        } else {

            setCallback ( () => ( formData ) => {

                if ( !formData ) {
    
                    formData = {};
                    
                }
    
                formData.planId = onSelectMst.processPlanId;
    
            });

        }

    }, [onSelectMst, category])

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.create` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyUrlCallback={modifyUrlCallback}
                setInitialDataCallback={callBack}
            />
        </>

    );
}

export default EguPopTempButton;