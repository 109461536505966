/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";

const printFloat = (x, decimalScale, ceiling) => {
    let precision = 4; // default precision

    if (decimalScale >= 1 && decimalScale <= 3) {
        precision = decimalScale;
    }

    let integerPart;
    let floatPartStr;

    if (!ceiling) {
        x = Math.round(x * 100000) / 100000;

        integerPart = parseInt(x);
        floatPartStr = (x - integerPart).toFixed(precision); // toFixed returns String
    } else {
        const exponent = Math.pow(10, precision);
        integerPart = parseInt(x);
        let tmp = parseInt((x - integerPart) * (exponent * 10)) / 10;
        floatPartStr = String(Math.ceil(tmp) / exponent);
    }

    if (!!Number(floatPartStr)) {
        return integerPart.toLocaleString("kr-KR") + "." + floatPartStr.slice(-precision);
    } else {
        return integerPart.toLocaleString("kr-KR");
    }
};

function DataGridNumberColumn(props) {
    const { schema, ...others } = props;

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    useEffect(() => {
        if (schema) {
            if (schema?.grid?.type === "number") {
                setCellTemplate(() => (ctx) => {
                    if (ctx.item[schema.name]) {
                        return (
                            <span>
                                {printFloat(ctx.item[schema.name], schema?.grid?.decimalScale, schema?.grid?.ceiling)}
                            </span>
                        );
                    } else {
                        if (schema.grid.doNotDisplayZeroOnNull) {
                            return <span></span>;
                        } else {
                            return <span>0</span>;
                        }
                    }
                });
            }
        }
    }, [schema]);

    return (
        <DataGridColumn schema={schema} align={"right"} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridNumberColumn;
