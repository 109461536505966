import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import EguMstMaterial from "./EguMstMaterial";
import EguMstMaterialFile from "./EguMstMaterialFile";

function EguItemInfo ( props ) {
    const [height, setHeight] = useState(0);
    const [selectMstGrid, setSelectMstGrid] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const masterGridSetting = (data) => {
        setSelectMstGrid(data)
    } 

    return (
        <div style={{height: height}}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Material">
                    <EguMstMaterial 
                        id="EguMstMaterial" 
                        masterGridSetting={masterGridSetting}
                    />
                </ReflexElement>

                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />

                <ReflexElement data-cy="MaterialFile">
                    {
                        (selectMstGrid)
                        ?
                        <EguMstMaterialFile 
                            id="EguMstMaterialFile" 
                            selectMstGrid={selectMstGrid}
                        />
                        :
                        <></>
                    }
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguItemInfo;
