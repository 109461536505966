import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { useTranslation } from "react-i18next";

import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import MomSysOutSourcingButton from "../input/MomSysOutSourcingButton";
import MomSysOutSourcingInButton from "../input/MomSysOutSourcingInButton";
import MomSysUpdateOrderBuyerIdButton from "../input/MomSysUpdateOrderBuyerIdButton";
import MomSysSplitLotButton from "../input/MomSysSplitLotButton";
import MomSysMergeLotButton from "../input/MomSysMergeLotButton";
import MomSysMoveLotButton from "../input/MomSysMoveLotButton";
import MomSysCancelProduceButton from "../input/MomSysCancelProduceButton ";
import MomSysChangeSpareStockButton from "../input/MomSysChangeSpareStockButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysLotStateChange ( props ) {

    const { t } = useTranslation();

    const [outSourcingState, setOutSourcingState] = useState();
    const [orderBuyerId, setOrderBuyerId] = useState();
    const [workState, setWorkState] = useState();

    const [nsOutSourcingState, setNsOutSourcingState] = useState();
    const [nsOrderBuyerId, setNsOrderBuyerId] = useState();
    const [, setNsWorkState] = useState();

    const [grid, setGrid] = useState();
    const [nsGrid, setNsGrid] = useState();

    const [refresh, setRefresh] = useState();
    const [nsRefresh, setNsRefresh] = useState();

    const [outSourcingSchema, setOutSourcingSchema] = useState();
    const [adaptSchema, setAdaptSchema] = useState();
    const [splitSchema, setSplitSchema] = useState();
    const [moveSchema, setMoveSchema] = useState();
    const [mergeSchema, setMergeSchema] = useState();
    const [workEditSchema, setWorkEditSchema] = useState();

    const [onSelect, setOnSelect] = useState();
    const [onNsSelect, setNsOnSelect] = useState();

    const [ isEmpty, setIsEmpty ] = useState();
    const [ isNsEmpty, setIsNsEmpty ] = useState();

    const [ , setNotification ] = useRecoilState(notificationState);

    const [ height, setHeight ] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };
    
    useEffect(() => {

        let mounted = true;

        window.addEventListener('resize', handleResize);
        handleResize();

        ( async () => {

            let schema;

            schema = await getSchema("MomSysLogOutSourcing");

            if ( mounted ) {

                setOutSourcingSchema(schema);

            }

            schema = await getSchema("MomSysLogAdapt");

            if ( mounted ) {

                setAdaptSchema(schema);

            }

            schema = await getSchema("MomSysLogSplit");

            if ( mounted ) {

                setSplitSchema(schema);

            }

            schema = await getSchema("MomSysLogMerge");

            if ( mounted ) {

                setMergeSchema(schema);

            }

            schema = await getSchema("MomSysLogMove");

            if ( mounted ) {

                setMoveSchema(schema);

            }

            schema = await getSchema("MomSysLogWorkEdit");

            if ( mounted ) {

                setWorkEditSchema(schema);

            }

        } ) ();

        return ( () => {

            mounted = false;
            window.removeEventListener('resize', handleResize);

        } );

    },[])

    useEffect(() => {

        (async () => {
            
          if (grid) {
              
            grid.selectionChanged.addHandler((grid, event) => {

                setOutSourcingState(grid.selectedItems[0] && grid.selectedItems[0].outSourcing);
                setOrderBuyerId(grid.selectedItems[0] && grid.selectedItems[0].orderBuyerId);
                setWorkState(grid.selectedItems[0] && grid.selectedItems[0].workState);
                setOnSelect(grid.selectedItems[0]);
                
            });
            
            grid.itemsSourceChanged.addHandler((grid, event) => {
                
                setOutSourcingState(grid.selectedItems[0] && grid.selectedItems[0].outSourcing);
                setOrderBuyerId(grid.selectedItems[0] && grid.selectedItems[0].orderBuyerId);
                setWorkState(grid.selectedItems[0] && grid.selectedItems[0].workState);
                setOnSelect(grid.selectedItems[0]);
            });
          }
    
        })();

    }, [grid]);

    useEffect(() => {

        (async () => {
            
          if (nsGrid) {
              
            nsGrid.selectionChanged.addHandler((grid, event) => {

                setNsOutSourcingState(grid.selectedItems[0] && grid.selectedItems[0].outSourcing);
                setNsOrderBuyerId(grid.selectedItems[0] && grid.selectedItems[0].orderBuyerId);
                setNsWorkState(grid.selectedItems[0] && grid.selectedItems[0].workState);
                setNsOnSelect(grid.selectedItems[0]);
                
            });
            
            nsGrid.itemsSourceChanged.addHandler((grid, event) => {
                
                setNsOutSourcingState(grid.selectedItems[0] && grid.selectedItems[0].outSourcing);
                setNsOrderBuyerId(grid.selectedItems[0] && grid.selectedItems[0].orderBuyerId);
                setNsWorkState(grid.selectedItems[0] && grid.selectedItems[0].workState);
                setNsOnSelect(grid.selectedItems[0]);
            });
          }
    
        })();

    }, [nsGrid]);

    const onNotifySuccess = (msg) => {

        setNotification({
    
          severity: "success",
          msg: msg,
          open: true,
    
        });
    };

    const onFailure = ( msg ) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    }

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid ( grid );

    }
    const onNsInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setNsGrid ( grid );

    }

    const onApiInitialized = (api) => {

        setRefresh (api)

    }

    const onNsApiInitialized = (api) => {

        setNsRefresh (api)

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    
    }

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onNsLoadData = ( data ) => {

        setIsNsEmpty(data.length < 1 ? true : false)

    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
                <>
                {
                    outSourcingState
                    ?
                    <BasicCrudScreenActionItemLayout>
                        <MomSysOutSourcingInButton
                            id = "MomSysOutSourcingInButton"
                            onSuccess = {onNotifySuccess}
                            schema = {outSourcingSchema}
                            onSelect = {onSelect}
                            grid = {grid}
                            refresh = {refresh}
                            standard = {true}
                            disabled = {isEmpty}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                        />
                    </BasicCrudScreenActionItemLayout>
                    :
                    <>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysOutSourcingButton
                            id = "MomSysOutSourcingButton"
                            onSuccess = {onNotifySuccess}
                            schema = {outSourcingSchema}
                            onSelect = {onSelect}
                            grid = {grid}
                            refresh = {refresh}
                            standard = {true}
                            disabled = {isEmpty}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                        />
                    </BasicCrudScreenActionItemLayout>
                    </>
                }
                    <BasicCrudScreenActionItemLayout>
                        <MomSysUpdateOrderBuyerIdButton
                            id = "MomSysUpdateOrderBuyerIdButton"
                            onSuccess = {onNotifySuccess}
                            grid = {grid}
                            schema = {adaptSchema}
                            onSelect = {onSelect}
                            standard = {true}
                            refresh = {refresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(outSourcingState) || isEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysSplitLotButton
                            id = "MomSysSplitLotButton"
                            onSuccess = {onNotifySuccess}
                            grid = {grid}
                            onSelect = {onSelect}
                            schema = {splitSchema}
                            refresh = {refresh}
                            standard = {true}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(outSourcingState) || isEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysMergeLotButton
                            id = "MomSysMergeLotButton"
                            onSuccess = {onNotifySuccess}
                            schema = {mergeSchema}
                            onSelect = {onSelect}
                            grid = {grid}
                            refresh = {refresh}
                            standard = {true}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(outSourcingState) || isEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysMoveLotButton
                            id = "MomSysMoveWareHouseButton"
                            onSuccess = {onNotifySuccess}
                            onSelect = {onSelect}
                            grid = {grid}
                            schema = {moveSchema}
                            refresh = {refresh}
                            standard = {true}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(outSourcingState) || isEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysChangeSpareStockButton
                            id = "MomSysQtyInStockButton"
                            onSuccess = {onNotifySuccess}
                            grid = {grid}
                            standard = {true}
                            refresh = {refresh}
                            onFailure = {onFailure}
                            disabled = { isEmpty || Boolean(outSourcingState) || orderBuyerId === "SO-SPARE-STOCK" ? true : false}
                            
                        />
                    </BasicCrudScreenActionItemLayout>
                    
                    <BasicCrudScreenActionItemLayout>
                        <MomSysCancelProduceButton
                            id = "MomSysMoveWareHouseButton"
                            onSuccess = {onNotifySuccess}
                            color="secondary"
                            grid = {grid}
                            onSelect = {onSelect}
                            schema = {workEditSchema}
                            refresh = {refresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {!(["PROCESSING","COMPLETED","FINISHED"].includes(workState))}
                        />
                    </BasicCrudScreenActionItemLayout>
                </>
        )
    }
    const onNsAppendButtonInFrontOfSearch = () => {

        return (
                <>
                {
                    nsOutSourcingState
                    ?
                    <BasicCrudScreenActionItemLayout>
                        <MomSysOutSourcingInButton
                            id = "MomSysNsOutSourcingInButton"
                            onSuccess = {onNotifySuccess}
                            schema = {outSourcingSchema}
                            onSelect = {onNsSelect}
                            grid = {nsGrid}
                            refresh = {nsRefresh}
                            disabled = {isNsEmpty}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                        />
                    </BasicCrudScreenActionItemLayout>
                    :
                    <>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysOutSourcingButton
                            id = "MomSysNsOutSourcingButton"
                            onSuccess = {onNotifySuccess}
                            schema = {outSourcingSchema}
                            onSelect = {onNsSelect}
                            grid = {nsGrid}
                            refresh = {nsRefresh}
                            disabled = {isNsEmpty}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                        />
                    </BasicCrudScreenActionItemLayout>
                    </>
                }

                    <BasicCrudScreenActionItemLayout>
                        <MomSysUpdateOrderBuyerIdButton
                            id = "MomSysNsUpdateOrderBuyerIdButton"
                            onSuccess = {onNotifySuccess}
                            grid = {nsGrid}
                            schema = {adaptSchema}
                            onSelect = {onNsSelect}
                            refresh = {nsRefresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(nsOutSourcingState) || isNsEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysSplitLotButton
                            id = "MomSysNsSplitLotButton"
                            onSuccess = {onNotifySuccess}
                            grid = {nsGrid}
                            onSelect = {onNsSelect}
                            schema = {splitSchema}
                            refresh = {nsRefresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(nsOutSourcingState) || isNsEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysMergeLotButton
                            id = "MomSysNsMergeLotButton"
                            onSuccess = {onNotifySuccess}
                            grid = {nsGrid}
                            schema = {mergeSchema}
                            onSelect = {onNsSelect}
                            refresh = {nsRefresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(nsOutSourcingState) || isNsEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysMoveLotButton
                            id = "MomSysNsMoveWareHouseButton"
                            onSuccess = {onNotifySuccess}
                            grid = {nsGrid}
                            onSelect = {onNsSelect}
                            schema = {moveSchema}
                            refresh = {nsRefresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {Boolean(nsOutSourcingState) || isNsEmpty}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <MomSysChangeSpareStockButton
                            id = "MomSysNsQtyInStockButton"
                            onSuccess = {onNotifySuccess}
                            grid = {nsGrid}
                            refresh = {refresh}
                            onFailure = {onFailure}
                            disabled = {  isNsEmpty || Boolean(nsOutSourcingState) || nsOrderBuyerId === "SO-SPARE-STOCK" ? true : false}
                        />
                    </BasicCrudScreenActionItemLayout>

                    {/* <BasicCrudScreenActionItemLayout>
                        <MomSysCancelProduceButton
                            id = "MomSysMoveWareHouseButton"
                            onSuccess = {onNotifySuccess}
                            color="secondary"
                            lotNo = {nsLotNo}
                            grid = {nsGrid}
                            schema = {workEditSchema}
                            refresh = {refresh}
                            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                            disabled = {!(["PROCESSING","COMPLETED","FINISHED"].includes(workState))}
                        />
                    </BasicCrudScreenActionItemLayout> */}
                </>
        )
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysZvwMaterialStateSgtWipLot"
                        view="MomSysZvwMaterialStateSgtWipLot"
                        headerTitle = {t("term.standard")}
                        noExcel
                        onInitialized = {onInitialized}
                        onApiInitialized = {onApiInitialized}
                        onAppendButtonInFrontOfSearch = {onAppendButtonInFrontOfSearch}
                        onLoadData={onLoadData}
                    />


                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysNsZvwMaterialStateSgtWipLot"
                        view="MomSysNsZvwMaterialStateSgtWipLot"
                        headerTitle = {t("term.nonStandard")}
                        noExcel
                        onInitialized = {onNsInitialized}
                        onApiInitialized = {onNsApiInitialized}
                        onAppendButtonInFrontOfSearch = {onNsAppendButtonInFrontOfSearch}
                        onLoadData={onNsLoadData}
                    />

                </ReflexElement>

            </ReflexContainer>

        </div>
    );
}

export default MomSysLotStateChange;
    