/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";

import getList from "../../../lib/api/getList";

function FilterFormItemCode ( props ) {

    const { id, className, column, label, helperText, InputLabelProps, onChange, ...others } = props;

    const [selectItems, setSelectItems] = useState ([]);

    const codeInfo = column.codeInfo;

    useEffect ( () => {
        
        let mounted = true;

        ( async () => {

            try {
                
                let apiResult = await getList ( codeInfo.tableName );

                if ( mounted ) {
                
                    let selectItems = apiResult.data.map ( ( result ) => {

                        if ( Array.isArray ( codeInfo.nameColumn ) ) {
    
                            let name = "";
                            let first = true;
    
                            for ( const columnName of codeInfo.nameColumn ) {
    
                                if ( !first && result[columnName] ) {
    
                                    name += ( codeInfo.seperator || ", " );
    
                                }
    
                                first = false;
    
                                name += result[columnName] || "";
    
                            }
    
                            return { name: name, value: result[codeInfo.valueColumn] };
    
                        } else {
    
                            return { name: result[codeInfo.nameColumn], value: result[codeInfo.valueColumn] };
    
                        }
    
                    } );
    
                    if ( codeInfo.sortByName ) {
    
                        selectItems.sort ( ( itemA, itemB ) => ( ( '' + itemA.name ).localeCompare ( itemB.name ) ) )
    
                    }
    
                    setSelectItems ( selectItems );

                }


            } catch ( reason ) {

                console.error ( `Get Code List from Table "${ codeInfo.tableName }" Failed` );

            }

        } )();

        return ( () => {
            mounted = false;
        } );

    }, [] );

    const selectProps = {
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        ...others
    };

    if ( column.displayEmpty ) {

        selectProps.displayEmpty = true;

    }

    if ( column.filter.style ) {
        selectProps.style = column.filter.style;
    }

    return (
        selectItems.length
        ?
        <FormControl className={className} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
                { ...selectProps }
            >
                {
                    codeInfo.useEmptyValue
                    ?
                        <MenuItem value="">{ i18n.t ( codeInfo.emptyName ) }</MenuItem>
                    :
                        []
                }
                {
                    selectItems.map ( ( item ) => <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
        :
        <></>
    );

}

export default FilterFormItemCode;
