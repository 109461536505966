/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Hidden } from "@material-ui/core";

import BasicCrudScreenTypeLayout from "../layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../layout/BasicCrudScreenHeaderLayout";
import BasicCrudScreenContentWithoutFooterLayout from "../layout/BasicCrudScreenContentWithoutFooterLayout";
import BasicCrudScreenFooterLayout from "../layout/BasicCrudScreenFooterLayout";
import BasicCrudActionsLayout from "../layout/BasicCrudActionsLayout";

import AutoDataGrid from "../../grid/functional/AutoDataGrid";

function BasicStaticGridScreenType ( props ) {

    const {

        id,
                
        embededInOtherScreenType,
        
        headerTitle,
        headerTitleStyle,

        schema,
        itemsSource,
        
        onInitialized,        
        
        onAppendHeaderAtFirst,
        onAppendButton,
        onAppendHeaderAtLast,

        onAppendContentAtFirst,
        onAppendContentAtLast,

        onAppendFooter,

        ...others
    
    } = props;


    const onGridInitialized = ( grid ) => {
        
        if ( onInitialized ) {

            onInitialized ( grid );

        }

    };

    return (

        <div>

            <BasicCrudScreenTypeLayout>


                <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">


                    <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

       
                        {/* Action Button ids and Dialog ids are assigned inside Action Button Component */}

                        {
                            onAppendHeaderAtFirst
                            ?
                            onAppendHeaderAtFirst ()
                            :
                            <></>
                        }

                        {
                            headerTitle
                            ?
                            <span
                                color="inherit"
                                align="center"
                                style={headerTitleStyle || {
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    marginLeft: "8px",
                                    marginTop: "20px"
                                }}>
                                { headerTitle }
                            </span>
                            :
                            <></>
                        }

                        <Hidden xsDown>

                        {
                            onAppendButton
                            ?
                            onAppendButton ()
                            :
                            <></>
                        }

                        </Hidden>

                        {
                            onAppendHeaderAtLast
                            ?
                            onAppendHeaderAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudActionsLayout>
                    
                </BasicCrudScreenHeaderLayout>
                                

                <BasicCrudScreenContentWithoutFooterLayout className="basicCrudScreenContentLayout">

                    {
                        onAppendContentAtFirst
                        ?
                        onAppendContentAtFirst ()
                        :
                        <></>
                    }

                    <AutoDataGrid id={id+"-data-grid"} schema={schema} itemsSource={itemsSource} onInitialized={onGridInitialized} { ...others } />

                    {
                        onAppendContentAtLast
                        ?
                        onAppendContentAtLast ()
                        :
                        <></>
                    }

                </BasicCrudScreenContentWithoutFooterLayout>
                    
                {
                    onAppendFooter
                    ?
                    <BasicCrudScreenFooterLayout className="basicCrudScreenFooterLayout" embededInOtherScreenType={embededInOtherScreenType}>
                    onAppendFooter ()
                    </BasicCrudScreenFooterLayout>
                    :
                    <></>
                }

            </BasicCrudScreenTypeLayout>
            
        </div>

    );

}

export default BasicStaticGridScreenType;
