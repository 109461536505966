import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import getSchema from "../../lib/api/getSchema";

import layoutConf from "../../config/layoutConf";

function EguResearchMaterialConsume() {

    const [, setTableSchema] = useState();

    const [height, setHeight] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();

    const [, setDetailGrid] = useState();
    const [detailApi, setDetailApi] = useState();

    const [, setIsEmpty] = useState();

    const [onSelectMst, setOnSelectMst] = useState();

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguIngredientRegistration");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);

    useEffect(() => {

        if (api) {

            api.refresh();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.captionId]);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onDetailInitialized = (grid) => {

        setDetailGrid(grid);

    };

    const onDetailApiInitialized = (api) => {

        setDetailApi(api);

    };

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);

                    }
                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);
                    }
                }, 10));
            }
        })();

    }, [grid]);

    useEffect(() => {

        if (detailApi) {

            detailApi.refresh();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.productSampleNo])

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <BasicCrudScreenType
                        id="EguProductSample"
                        table="EguProductSample"
                        view="EguVwProductSamples"
                        headerTitle={t("term.productSample")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={onLoadData}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <BasicCrudScreenType
                        id="EguVwResearchMaterialConsume"
                        table="EguVwResearchMaterialConsume"
                        view="EguVwResearchMaterialConsume"
                        headerTitle={t("term.eguVwResearchMaterialConsume")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onDetailInitialized}
                        onApiInitialized={onDetailApiInitialized}
                        onModifyViewUrl={(url) => url + '/' + onSelectMst?.productSampleNo}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguResearchMaterialConsume;
