import { useState, useEffect } from "react";
import { Grid, Toolbar } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";

import layoutConf from "../../config/layoutConf";

import DateRangeSelectorWithButtons from "../input/DateRangeSelectorWithButtons";

import UserLotProductionReport from "./UserLotProductionReport";
import UserLotConsumptionReport from "./UserLotConsumptionReport";
import SimpleHtmlEditorScreen from "./SimpleHtmlEditorScreen";
import SimpleHtmlViewerScreen from "./SimpleHtmlViewerScreen";

import UserLotProductionReportExcelButton from "../input/UserLotProductionReportExcelButton";

function ReportView ( props ) {

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);
    const { startDate, endDate } = props;

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 144);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={0.7} data-cy="dailyLotProductionReport">

                            <UserLotProductionReport startDate={startDate} endDate={endDate} />

                        </ReflexElement>
                                
                        <ReflexSplitter
                            style={{ width: layoutConf.reflex.splitterWidth }}
                        />

                        <ReflexElement flex={0.3} data-cy="dailyLotProductionMemo" minSize={520} propagateDimensions={true}>

                            {
                                startDate.getTime () === endDate.getTime ()
                                ?
                                <SimpleHtmlEditorScreen
                                    title={t("title.dailyLotProductionReportMemo")}
                                    path="dailyLotProductionReportMemo"
                                    memoKey={`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`}
                                />
                                :
                                <SimpleHtmlViewerScreen
                                    title={t("title.dailyLotProductionReportMemo")}
                                    path="dailyLotProductionReportMemo"
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            }

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={0.7} data-cy="dailyLotConsumptionReport">

                            <UserLotConsumptionReport startDate={startDate} endDate={endDate} />

                        </ReflexElement>
                                
                        <ReflexSplitter
                            style={{ width: layoutConf.reflex.splitterWidth }}
                        />

                        <ReflexElement flex={0.3} data-cy="dailyLotConsumptionMemo" minSize={520} propagateDimensions={true}>

                            {
                                startDate.getTime () === endDate.getTime ()
                                ?
                                <SimpleHtmlEditorScreen
                                    title={t("title.dailyLotConsumptionReportMemo")}
                                    path="dailyLotConsumptionReportMemo"
                                    memoKey={`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`}
                                />
                                :
                                <SimpleHtmlViewerScreen
                                    title={t("title.dailyLotProductionReportMemo")}
                                    path="dailyLotConsumptionReportMemo"
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            }

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );    
}
function UserLotProductionConsumptionReport ( props ) {

    const [ startDate, setStartDate ] = useState(new Date());
    const [ endDate, setEndDate ] = useState(new Date());

    const onDateRangeChanged = ( from, to ) => {

        setStartDate ( from );
        setEndDate ( to );

    };

    return (
        <>
            <div id="material-in-out-report">

                <Toolbar style={ {
                    top: "0px",
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "0px",
                    height: "48px",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    backgroundColor: "rgb(255,255,255)",
                    borderBottom: "1px solid rgb(212, 212, 212)",
                } }>
                    <Grid item>
                        <DateRangeSelectorWithButtons
                            disableCheckAll
                            maxRange={61}
                            startDate={startDate}
                            endDate={endDate}
                            style={{
                                marginTop: "5px"
                            }}
                            yesterday prevWeek prevMonth today currentWeek currentMonth
                            onChange={onDateRangeChanged}
                        />                                
                    </Grid>
                    <Grid item xs>
                        <span></span>
                    </Grid>
                    <Grid item>
                        <UserLotProductionReportExcelButton startDate={startDate} endDate={endDate} />
                    </Grid>
                </Toolbar>

            </div>
            
            <ReportView startDate={startDate} endDate={endDate} />

        </>
        
    );

}

export default UserLotProductionConsumptionReport;
