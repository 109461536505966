import { atom } from "recoil";

// sub menu selection state

const subMenuSelectionState = atom({
        
    key: "subMenuSelectionState",
    default: 1
    
});

export default subMenuSelectionState;
