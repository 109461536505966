import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOk from "../dialog/functional/DialogActionsOk";
import { Typography } from "@material-ui/core";
import DialogTitle from "../dialog/functional/DialogTitle";

function AlertDialog ( props ) {

    const {

        id,
        msg,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,

        ...others

    } = props;

    const onDialogInitialized = ( instance ) => {

        if ( onInitialized ) {

            onInitialized ( instance );
        }

    };


    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.confirmDialogMaxWidth}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeader>
                <DialogTitle />
            </DialogHeader>
            <DialogContent>
                <Typography color="inherit" variant="body2" align="center" >{ msg }</Typography>
            </DialogContent>

            <DialogActionsOk close />

        </Dialog>
    );

}

export default AlertDialog;
