import { useTranslation } from "react-i18next";

import Button from "../input/Button";

function FilterCancelButtonLayout ( props ) {

    const { t } = useTranslation();

    return <Button {...props}>{t("common.cancel")}</Button>;

}

export default FilterCancelButtonLayout;

