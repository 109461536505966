/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import getNewDateObj from "../../lib/common/getNewDateObj";

function PopBackShipDetail ( props ) {

    const {
        id,
        table,
        view,
        onSelect,
        onDetailApi,
        mstIsEmpty,
        detailIsEmpty
    } = props;

    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);

    const [schema, setSchema] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [isEmpty, setIsEmpty] = useState();

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema(table);

            if (mounted) {

                setSchema(schema);

            }

        })();

        return () => {

            mounted = false;

        };

    }, []);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    const notifyWarning = (msg) => {

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const onNotifyWarning = (msg) => {

        notifyWarning(msg);

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const onInitialized = ( grid ) => {

        setGrid(grid);

    };

    const onApiInitialized = ( api ) => {

        setApi(api);

        onDetailApi(api);

    };

    const onLoadData = (data) => {

        let isEmpty = data.length < 1 ? true : false;

        setIsEmpty(isEmpty);

        if(detailIsEmpty) {

            detailIsEmpty(isEmpty);

        }

    };

    const onInitialDataCallback = ( formData, onSelect ) => {

        formData.deliveryId = onSelect.deliveryId;
        formData.shipDetailId = onSelect.shipDetailId;
        formData.orderBuyerDetailId = onSelect.orderBuyerDetailId;
        formData.shippedLotNo = onSelect.lotNo;
        formData.qtyBackShip = onSelect.shipQty - onSelect.backShipQty;
        formData.backShipDate = getNewDateObj(new Date());

    }

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <CreateRecordButton
                            id={id}
                            schema={schema}
                            refresh={api}
                            setInitialDataCallback={(formData) => onInitialDataCallback(formData, onSelect)}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            disabled={onSelect?.shipQty === onSelect?.qtyBackShip || mstIsEmpty}
                        />
                    </AccessControl>
                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch ? "PATCH" : "PUT")}>
                        <UpdateRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <DeleteRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                    
                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    return (

        <BasicCrudScreenType 
            id={id} 
            headerTitle={t("term.backShipDetail")}
            table={table}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={onLoadData}
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
        />

    );

}

export default PopBackShipDetail;
