import { useRecoilValue } from "recoil";
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from "@material-ui/styles";

import menuInfoState from "../../../states/menuInfoState";
import topMenuSelectionState from "../../../states/topMenuSelectionState";
import subMenuSelectionState from "../../../states/subMenuSelectionState";

import "./SubMenuLayout.css";

const useStyles = makeStyles ( () => ( {

    tabs: {
        margin: 0
    }

} ) );

function SubMenuLayout ( props ) {

    const classes = useStyles ();

    const { children } = props;

    const topMenuSelection = useRecoilValue(topMenuSelectionState);
    const subMenuSelection = useRecoilValue(subMenuSelectionState);
    const menuInfo = useRecoilValue(menuInfoState);

    const getSubMenuTabIndex = ( menuInfo, topMenuSelection, subMenuSelection ) => {

        let index = menuInfo.subMenuItems.filter( ( menuItem ) => menuItem.topMenuItemId === topMenuSelection )
            .sort( ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )
            .findIndex( ( menuItem ) => menuItem.subMenuItemId === subMenuSelection );

        if ( index < 0 )
            index = 0;

        return index;

    }

    return (

        <div data-cy="SubMenu">
            <Tabs className={classes.tabs} value={getSubMenuTabIndex(menuInfo, topMenuSelection, subMenuSelection)} textColor="inherit" variant="scrollable" scrollButtons="on">
                {
                    children
                }
            </Tabs>
        </div>

    );

}

export default SubMenuLayout;
