import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import getSpecific from "../../lib/api/getSpecific";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { CellType } from "@grapecity/wijmo.grid";

import { debounce, } from "lodash";
import defineConf from "../../config/defineConf";
import MomSysCustomerSupplyOrderBuyDetail from "./MomSysCustomerSupplyOrderBuyDetail";

function MomSysCustomerSupplyOrderBuy(props) {
    const id = "MomSysCustomerSupplyOrderBuyerMst";
    const table = "MomSysUglOrderBuyerMst";
    const view = "EguVwOrderBuyerMst";

    const [height, setHeight] = useState(0);

    const [eguUglOrderBuyerMstGrid, setEguUglOrderBuyerMstGrid] = useState();
    const [, setEguUglOrderBuyerMstApi] = useState();
    const [eguUglOrderBuyerMstData, setEguUglOrderBuyerMstData] = useState();
    const [, setEguUglOrderBuyerMstDataIsEmpty] = useState();
    const [, setEguUglOrderBuyerDetailDataIsEmpty] = useState();
    const [eguUglOrderBuyerMstSelect, setEguUglOrderBuyerMstSelect] = useState({});

    const { t } = useTranslation();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (eguUglOrderBuyerMstGrid) {
                eguUglOrderBuyerMstGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerMstSelect({});
                        }
                    }, 10)
                );

                eguUglOrderBuyerMstGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerMstSelect({});
                        }
                    }, 10)
                );
            }
        })();
    }, [eguUglOrderBuyerMstGrid]);

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (eguUglOrderBuyerMstData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (eguUglOrderBuyerMstData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };



    const onUpdateMaster = () => {
        if (eguUglOrderBuyerMstGrid) {
            const updateTarget = eguUglOrderBuyerMstGrid.selectedItems[0];

            (async () => {
                const buyerMst = await getSpecific(view, updateTarget.orderBuyerId);

                updateTarget.cost = buyerMst.cost;
                eguUglOrderBuyerMstGrid.refresh(false); // grid.refresh(false) : grid.content only, grid에 즉시 반영
            })();
        }
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement data-cy="Master" flex={0.6}>
                    <BasicCrudScreenType
                        id={id}
                        headerTitle={t("term.so")}
                        table={table}
                        view={view}
                        onInitialized={(grid) => {
                            setEguUglOrderBuyerMstGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguUglOrderBuyerMstApi(api);
                        }}
                        onLoadData={(data) => {
                            setEguUglOrderBuyerMstData(data);
                            setEguUglOrderBuyerMstDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        exportFormatItem={exportFormatItem}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4}>

                    <MomSysCustomerSupplyOrderBuyDetail
                        onSelectMst={eguUglOrderBuyerMstSelect}
                        onUpdateMaster={onUpdateMaster}
                        onDetailEmpty={(isEmpty) => {
                            setEguUglOrderBuyerDetailDataIsEmpty(isEmpty);
                        }}
                    />

                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysCustomerSupplyOrderBuy;
