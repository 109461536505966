import { useState, useEffect } from "react";
import { Colorpicker, i18n as dhxLocale } from "dhx-suite-package";
import { useTranslation } from "react-i18next";

import "../layout/DhtmlxColorPicker.css";

function DhtmlxColorPicker ( props ) {

    const { t } = useTranslation ();

    const [colorPicker, setColorPicker] = useState();

    const { id, value, onChange } = props;

    useEffect ( () => {

        let customColors = localStorage.getItem ( "dhtmlx.customColors" );

        try {

            customColors = JSON.parse ( customColors );

        } catch ( reason ) {

            customColors = [];

        }

        dhxLocale.setLocale ( "colorpicker", {
            cancel: t ( "cancel" ),
            select: t ( "select" ),
            rightClickToDelete: t ( "rightClickToDelete" ),
            customColors: t ( "customColors" ),
            addNewColor: t ( "addNewColor" ),
        } );

        let colorPicker = new Colorpicker ( id, {
            css: "dhtmlx_color_picker dhx_widget--bordered",
        } );

        if ( customColors?.length > 0 ) {

            colorPicker.setCustomColors ( customColors );

        }

        setColorPicker ( colorPicker );

        colorPicker.events.on ( "change", function ( color ) {

            let customColors = colorPicker.getCustomColors ();

            localStorage.setItem ( "dhtmlx.customColors", JSON.stringify ( customColors ) );

            if ( onChange ) {

                onChange ( color );

            }

        });

        return ( () => {

            setColorPicker ( ( colorPicker ) => {

                colorPicker.destructor ();

            } );

        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    useEffect ( () => {

        if ( value && colorPicker ) {

            colorPicker.setValue ( value );
            return colorPicker;

        }

    }, [ value, colorPicker ] );

    return <div id={id} style={{height: "max-content", border: "none"}} />

}

export default DhtmlxColorPicker;
