import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { useEffect, useState } from "react";
import notificationState from "../../states/notificationState";
import { useRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import DeleteRecordButton from "../input/DeleteRecordButton";
import AccessControl from "../functional/AccessControl";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import EguCostInfoCreateButton from "../input/EguCostInfoCreateButton";
import EguCostInfoUpdateButton from "../input/EguCostInfoUpdateButton";

function EguCostInfoMgmt() {


    const [tableSchema, setTableSchema] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [detailApi, setDetailApi] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const { t } = useTranslation();

    const [height, setHeight] = useState();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguCostInfoMgmt");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };


    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onDetailApiInitialized = (api) => {

        setDetailApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        if (detailApi) {
            detailApi.refresh();
        }
        notifySuccess(msg);
    };


    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <EguCostInfoCreateButton
                            id="EguCostInfoMgmt"
                            schema={tableSchema}
                            color="primary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            refresh={api}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <EguCostInfoUpdateButton
                            id="EguCostInfoMgmt"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguCostInfoMgmt"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };



    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id="EguCostInfoMgmt"
                        table="EguCostInfoMgmt"
                        view="EguVwCostInfoMgmt"
                        headerTitle={t("term.salesAndManagementCost")}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onLoadData={onLoadData}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <BasicCrudScreenType
                        id="EguCostInfoMgMt"
                        table="EguCostInfoMgmt"
                        view="EguVwCostInfoSubMgmt"
                        headerTitle={t("term.manufacturingOverhead")}
                        onApiInitialized={onDetailApiInitialized}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>

    );
}

export default EguCostInfoMgmt;
