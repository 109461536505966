import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function MomSysPopNoticeUpdateButton(props) {
    const { id, schema, onSelectMst, onSuccess, refresh, onSetRefreshToggle, ...others } = props;

    const { t } = useTranslation();

    const [openWindow, setOpenWindow] = useState();

    const [, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const close = useCallback(() => {
        if (refresh) {
            refresh.refresh();
        }

        if (onSetRefreshToggle) {
            onSetRefreshToggle();
        }

        if (openWindow) {
            openWindow.removeEventListener("beforeunload", close);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openWindow, refresh]);

    useEffect(() => {
        (async () => {
            if (openWindow) {
                openWindow.addEventListener("beforeunload", close);
            }
        })();
    }, [openWindow, refresh, close]);

    const onOpen = () => {
        let newWindow = window.open(
            `/newWindow/MomSysPopNoticeUpdateDialog/${onSelectMst.orderBuyerDetailId}/${onSelectMst.processCode}`,
            "update",
            `_blank, width=${window.screen.width / 2}, height=${window.screen.height / 2}`
        );

        const receiveMessage = async (e) => {
            const result = e.data.message;

            switch (result) {
                case "Success":
                    if (onSuccess) {
                        onSuccess(e.data.t);
                        window.removeEventListener("message", receiveMessage);
                    }
                    break;

                case "Failure":
                    notifyFailure(e.data.t);
                    window.removeEventListener("message", receiveMessage);
                    break;

                case "SuccessKeepOpen":
                    if (onSuccess) {
                        onSuccess(e.data.t);
                    }
                    break;

                case "FailureKeepOpen":
                    notifyFailure(e.data.t);
                    break;

                default:
                    break;
            }
        };

        window.addEventListener("message", receiveMessage, false);

        setOpenWindow(newWindow);
    };

    return (
        <ActionButtonLayout id={id + "-update-button"} onClick={onOpen} {...others}>
            {t("buttonName.updateNotice")}
        </ActionButtonLayout>
    );
}

export default MomSysPopNoticeUpdateButton;
