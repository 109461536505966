/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import PopOutSourcingShipDetailCreateButton from "../input/PopOutSourcingShipDetailCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";

function PopOutSourcingShipDetail(props) {
    const { title, popOutSourcingShipTargetSelect, onDetailEmpty } = props;

    const id = "PopOutSourcingShipDetail";
    const table = "PopOutSourcingShipDetail";
    const view = "PopVwOutSourcingShipDetail";

    const { t } = useTranslation();

    const [popOutSourcingShipDetailSchema, setPopOutSourcingShipDetailSchema] = useState();

    const [popOutSourcingShipDetailGrid, setPopOutSourcingShipDetailGrid] = useState();
    const [popOutSourcingShipDetailSelect, setPopOutSourcingShipDetailSelect] = useState();
    const [popOutSourcingShipDetailApi, setPopOutSourcingShipDetailApi] = useState();
    const [popOutSourcingShipDetailDataIsEmpty, setPopOutSourcingShipDetailDataIsEmpty] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setPopOutSourcingShipDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (popOutSourcingShipDetailApi) {
            popOutSourcingShipDetailApi.refresh();
        }
    }, [popOutSourcingShipTargetSelect.outSourcingDetailId]);

    useEffect(() => {
        (async () => {
            if (popOutSourcingShipDetailGrid) {
                popOutSourcingShipDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setPopOutSourcingShipDetailSelect();
                        }
                    }, 10)
                );

                popOutSourcingShipDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setPopOutSourcingShipDetailSelect();
                        }
                    }, 10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popOutSourcingShipDetailGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t(title)}
            table={table}
            view={view}
            onModifyViewUrl={(url) => `${url}/${popOutSourcingShipTargetSelect.outSourcingDetailId}`}
            onInitialized={(grid) => {
                setPopOutSourcingShipDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setPopOutSourcingShipDetailApi(api);
            }}
            onLoadData={(data) => {
                const isEmpty = data.length < 1 ? true : false;
                setIsEmpty(isEmpty);

                setPopOutSourcingShipDetailDataIsEmpty(isEmpty);

                if (onDetailEmpty) {
                    onDetailEmpty(isEmpty);
                }
            }}
            onAppendButtonInFrontOfSearch={() => (
                <PopCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={popOutSourcingShipDetailSchema && popOutSourcingShipDetailSchema.name}
                        accessMethod="POST"
                    >
                        <PopOutSourcingShipDetailCreateButton
                            id={id}
                            schema={popOutSourcingShipDetailSchema}
                            refresh={popOutSourcingShipDetailApi}
                            popOutSourcingShipTargetSelect={popOutSourcingShipTargetSelect}
                            disabled={
                                popOutSourcingShipTargetSelect.outSourcingMaterialQty <=
                                popOutSourcingShipTargetSelect.outSourcingShipTotalQty
                            }
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={popOutSourcingShipDetailSchema && popOutSourcingShipDetailSchema.name}
                        accessMethod={
                            popOutSourcingShipDetailSchema &&
                            (popOutSourcingShipDetailSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id={id}
                            grid={popOutSourcingShipDetailGrid}
                            schema={popOutSourcingShipDetailSchema}
                            refresh={popOutSourcingShipDetailApi}
                            modifySchemaCallback={(schema) => {
                                for (const column of schema.columns) {
                                    switch (column.name) {
                                        case "extLotNo":
                                            column.disabled = true;

                                            break;

                                        default:
                                            break;
                                    }
                                }
                            }}
                            disabled={isEmpty ||
                                popOutSourcingShipTargetSelect.outSourcingMaterialQty <=
                                popOutSourcingShipTargetSelect.outSourcingShipTotalQty
                            }
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={popOutSourcingShipDetailSchema && popOutSourcingShipDetailSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteSingleRecordButton
                            id={id}
                            schema={popOutSourcingShipDetailSchema}
                            grid={popOutSourcingShipDetailGrid}
                            refresh={popOutSourcingShipDetailApi}
                            makeItemName={(row) => {
                                return ` - ${t("columnName.popOutSourcingShipDetailId")}: ${
                                    row.popOutSourcingShipDetailId
                                }`;
                            }}
                            color="secondary"
                            disabled={isEmpty ||
                                popOutSourcingShipTargetSelect.outSourcingMaterialQty <=
                                popOutSourcingShipTargetSelect.outSourcingShipTotalQty
                            }
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            
                        />
                    </AccessControl>
                </PopCrudScreenActionItemLayout>
            )}
            noCreate
            noEdit
            noDelete
            noExcel
        />
    );
}

export default PopOutSourcingShipDetail;
