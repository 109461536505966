import React, { useEffect, useState } from "react";
import defineConf from "../../config/defineConf";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysNoOrderPurchaseInputRegisterButton from "../input/MomSysNoOrderPurchaseInputRegisterButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import MomSysNoOrderPurchaseInputDoInputButton from "../input/MomSysNoOrderPurchaseInputDoInputButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import AccessControl from "../functional/AccessControl";
import getSchema from "../../lib/api/getSchema";
import PrintReportButton from "../input/PrintReportButton";

function MomSysNoOrderPurchaseInput(props) {
    const id = "MomSysNoOrderPurchaseInput";
    const table = "MomSysNoOrderPurchaseInput";
    const view = "MomSysVwNoOrderPurchaseInput";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [noOrderPurchaseInputSchema, setNoOrderPurchaseInputSchema] = useState();
    const [noOrderPurchaseInputGrid, setNoOrderPurchaseInputGrid] = useState();
    const [noOrderPurchaseInputApi, setNoOrderPurchaseInputApi] = useState();
    const [noOrderPurchaseInputDataIsEmpty, setNoOrderPurchaseInputDataIsEmpty] = useState();
    const [noOrderPurchaseInputSelect, setNoOrderPurchaseInputSelect] = useState();
    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setNoOrderPurchaseInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const path = `noOrderPurchaseInput/${noOrderPurchaseInputSelect?.noOrderPurchaseInputId}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "staging") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [noOrderPurchaseInputSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (noOrderPurchaseInputGrid && mounted) {
                noOrderPurchaseInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setNoOrderPurchaseInputSelect(grid.selectedItems[0]);
                        } else {
                            setNoOrderPurchaseInputSelect();
                        }
                    }, 10)
                );

                noOrderPurchaseInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setNoOrderPurchaseInputSelect(grid.selectedItems[0]);
                        } else {
                            setNoOrderPurchaseInputSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [noOrderPurchaseInputGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id={id}
                view={view}
                headerTitle={t("term.momSysNoOrderPurchaseInput")}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setNoOrderPurchaseInputGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setNoOrderPurchaseInputApi(api);
                }}
                onLoadData={(data) => {
                    setNoOrderPurchaseInputDataIsEmpty(data.length < 1 ? true : false);
                }}
                onAppendButtonInFrontOfSearch={() => (
                    <BasicCrudScreenActionItemLayout>
                        <AccessControl
                            accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                            accessMethod="POST"
                        >
                            <MomSysNoOrderPurchaseInputRegisterButton
                                id={id}
                                schema={noOrderPurchaseInputSchema}
                                refresh={noOrderPurchaseInputApi}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                            accessMethod={
                                noOrderPurchaseInputSchema &&
                                (noOrderPurchaseInputSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <UpdateRecordButton
                                id={id}
                                schema={noOrderPurchaseInputSchema}
                                grid={noOrderPurchaseInputGrid}
                                refresh={noOrderPurchaseInputApi}
                                disabled={noOrderPurchaseInputDataIsEmpty || noOrderPurchaseInputSelect?.inputDate}
                                modifyFormDataCallback={(formData) => {
                                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                        formData.defectiveCode = "INS000";
                                    }
                                }}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                            accessMethod="DELETE"
                        >
                            <DeleteSingleRecordButton
                                id={id}
                                schema={noOrderPurchaseInputSchema}
                                grid={noOrderPurchaseInputGrid}
                                refresh={noOrderPurchaseInputApi}
                                makeItemName={(row) =>
                                    `${t("columnName.noOrderPurchaseInputId")}: ${row.noOrderPurchaseInputId}`
                                }
                                disabled={noOrderPurchaseInputDataIsEmpty || noOrderPurchaseInputSelect?.inputDate}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                color="secondary"
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                            accessMethod={
                                noOrderPurchaseInputSchema &&
                                (noOrderPurchaseInputSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <MomSysNoOrderPurchaseInputDoInputButton
                                id={`${id}-input-button`}
                                schema={noOrderPurchaseInputSchema}
                                grid={noOrderPurchaseInputGrid}
                                refresh={noOrderPurchaseInputApi}
                                disabled={
                                    !noOrderPurchaseInputSelect ||
                                    //noOrderPurchaseInputSelect?.qcResult !== defineConf.qcResult.QC_OK ||
                                    noOrderPurchaseInputSelect?.wareHouseName
                                }
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl
                            accessTarget={noOrderPurchaseInputSchema && noOrderPurchaseInputSchema.name}
                            accessMethod="SAVETOEXCEL"
                        >
                            <SaveToExcelButton
                                id={id}
                                view={view}
                                grid={noOrderPurchaseInputGrid}
                                color="primary"
                                exportFormatItem={() => {}}
                                disabled={noOrderPurchaseInputDataIsEmpty}
                            />
                        </AccessControl>

                        <PrintReportButton
                            url={reportUrl}
                            noLayout
                            disabled={!noOrderPurchaseInputSelect?.noOrderPurchaseInputId}
                        >
                            {t("buttonName.lotInfoPrint")}
                        </PrintReportButton>
                    </BasicCrudScreenActionItemLayout>
                )}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </div>
    );
}

export default MomSysNoOrderPurchaseInput;
