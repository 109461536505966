import { useTranslation } from "react-i18next";
import Avatar from "../../presentation/Avatar";

import '../layout/Gantt.css';

function GanttWorkerColumn ( props ) {

    const { t } = useTranslation ();
    const { gantt, task } = props;

    if ( task.type !== gantt.config.types.task ) {

        return <></>;

    }

    let store = gantt.getDatastore ("resource" );
    let workers = task.workers

    if ( ! workers || !workers.length ) {

        return <div className="gantt_tree_content">{t ( "term.unassigned" )}</div>;

    }

    if ( workers.length === 1 ) {

        let worker = store.getItem ( workers[0].resource_id );
        return <div className="gantt_tree_content">{worker.text.length > 0 ? worker.text : worker.username}</div>;

    }

    let avatars = workers.map ( ( assignment ) => {

        let store = gantt.getDatastore ("resource" );
        let worker = store.getItem ( assignment.resource_id );

        if ( ! worker ) {

            return <></>;

        }

        return <Avatar
            key={worker.id}
            photo={worker.photo}
            email={worker.email}
            className="owner-label"
            style={{
                display: "inline-block",
                marginTop: "8px",
                height: "25px",
                width: "25px"
            }}
        />;
    
    } );

    return (
        <>
            { avatars }
        </>
    )
}

export default GanttWorkerColumn;
