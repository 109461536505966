import { FilterList } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

function OpenFilterButton ( props ) {

    return (

        <IconButton {...props}>
            <FilterList />
        </IconButton>
        
    );

}

export default OpenFilterButton;

