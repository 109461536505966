function getLayoutConf () {

    return (
    
        {
            topPane: {
                minSize: 50,
                maxSize: 100,
                defaultSize: 60,
                allowResize: false
            },
            sidePane: {
                minSize: 60,
                maxSize: 256,
                defaultSize: 180,
                allowResize: false
            },
            baseComponent: {
                modalDialog: "material-ui",
                modalessDialog: "wijmo"
            },
            reflex: {
                splitterHeight: "5px",
                splitterWidth: "5px",
            }
        }

    );

}

let layoutConf = getLayoutConf();

module.exports = layoutConf;