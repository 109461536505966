import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import isChecked from "../../../lib/utils/isChecked";

function FormItemCheckbox ( props ) {

    const { column, className, error, value, helperText, onChange, ...others } = props;

    const { t } = useTranslation ();

    const checkboxProps = {
        color: "primary",
        onChange: ( event ) => {
            onChange ( event.target.checked );
        },
        ...others
    };

    const style = column.style;

    return (
        <FormControlLabel
            className={className}
            style={style}
            control={ <Checkbox checked={ isChecked ( value ) } { ...checkboxProps } /> }
            label={ t ( `columnName.${column.displayName||column.name}` ) }
        />        
    );

}

export default FormItemCheckbox;
