/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function MomSysSelectStandardMaterial ( props ) {

    const {
        materialCode, materialCodeVer, orderBuyerId, shipQty, onInitialized, onLoadData
    } = props;

    const [detailRefresh, setDetailRefresh] = useState();
    
    const { t } = useTranslation ();


    useEffect(()=>{

        if ( detailRefresh ) {

            detailRefresh.refresh ();

        }

    },[materialCode, materialCodeVer, orderBuyerId, shipQty])

    const onApiInitialized = ( api ) => {

        setDetailRefresh ( api );

    };

    return (
        <BasicCrudScreenType 
            id="MomSysZvwMaterialOutputSgtWipLot"
            view="MomSysZvwMaterialOutputSgtWipLot"
            headerTitle={t("term.standardShipPossibleLot")}
            onInitialized = {onInitialized}
            onApiInitialized={onApiInitialized}
            onModifyViewUrl = {(url) => `${url}/${orderBuyerId}/${materialCode}/${materialCodeVer}`}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData = {onLoadData}
            embededInOtherScreenType
        />
    )

}

export default MomSysSelectStandardMaterial;
