/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../lib/common/apiClient";
import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function ExceptionInOutCancelButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, grid, onSuccess, onFailure, onNotifyWarning, onSelectGrid, ...others} = props;
    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState([]);

    const onOpen = () => {
        let deleteData = [];
        let deleteItemStr = "[";
        let firstRow = true;
        for(const item of grid.selectedItems) {
            if(!firstRow) {
                deleteItemStr += ", ";
            }

            deleteItemStr += "{";
            deleteItemStr += (t(`columnName.exceptionInOutId`) + ": " + item['exceptionInOutId']);
            deleteItemStr += ", ";
            deleteItemStr += (t(`columnName.materialCode`) + ": " + item['materialCode']);
            deleteItemStr += "}";

            firstRow = false;

            deleteData.push({...item});
        }
        deleteItemStr += "]";

        setConfirmMsg(t("dialog.msg.confirm.exceptionInOutCancel", {type: t(`tableName.${schema.name}`), instance: deleteItemStr}));
        setConfirmData(deleteData);
        setOpen(true);
    };

    const materialFileDeleteSuccess = (msg) => {
        if(onSuccess) {
            onSuccess("1" + msg);
        }
        refresh.refresh();
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOk = (data) => {
        let url = `/api/ExceptionInOut/cancel`;

        //autoFormDialog columnMap 생성부분 
        if (schema) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        apiClient
            .post(url, JSON.stringify({exceptionInOutId: onSelectGrid.exceptionInOutId}), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((result) => {materialFileDeleteSuccess(result.data)})
            .catch((reason) => {
                console.error(reason);                
                onFailure(t("error.deleteFailure", {reason: reason.response ? reason.response.data : reason}));
            });
    }

    return (
        <>
            <DeleteRecordButtonLayout id={id+"delete-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"delete-confirm-dialog"}
                title={t( `dialog.title.ExceptionInOut.cancel`)}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>
    );
}

export default ExceptionInOutCancelButton;

