/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useTranslation } from "react-i18next";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import defineConf from "../../../config/defineConf";

function DataGridPsmEventTypeColumn(props) {
    const { schema, ...others } = props;

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    const { t } = useTranslation();

    useEffect(() => {
        setCellTemplate(() => (ctx) => {
            let eventType = Number(ctx.item.eventType);

            switch (eventType) {
                case defineConf.psmEventType.SHIP:
                    return <span>{t(`selectItems.psmEventType.ship`)}</span>;

                case defineConf.psmEventType.PRODUCTION:
                    return <span>{t(`selectItems.psmEventType.production`)}</span>;

                case defineConf.psmEventType.INPUT_BY_PURCHASE:
                    return <span>{t(`selectItems.psmEventType.inputByPurchase`)}</span>;

                default:
                    return <span>{eventType}</span>;
            }
        });
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridPsmEventTypeColumn;
