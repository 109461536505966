import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function RegisterMachineFailureButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.registerMachineFailure")}</ActionButtonLayout>;

}

export default RegisterMachineFailureButtonLayout;

