import { useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";


import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import getSpecific from "../../lib/api/getSpecific";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguSubMaterialSampleCreateDialog(props) {

    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,
        initialData,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,
        maxWidth,
        platform,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        ...others
    } = props;

    const [disabled, setDisabled] = useState(true);
    const [keepOpen, setKeepOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [initData, setInitData] = useState({ subMaterialSampleNo: 'subMaterialSample', reviewCount: 1 });
    const [instance, setInstance] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkShowCallback = (formData, column) => {
        switch (column.name) {
            case "subMaterialState":
            case "sendDate":
            case "sendDescription":
            case "passJudgment":
            case "judgmentDate":
            case "reviewContent":
                return false
            default:
                break;
        }
        return true
    }

    const { t } = useTranslation();

    const onCloseDialog = () => {
        setDisabled(true);
        setInitData(() => {

            let newInit = {
                subMaterialSampleNo: 'subMaterialSample',
                productSampleNo: undefined,
                receiptNo: undefined,
                companyCode: undefined,
                reviewCount: 1,
                materialName: undefined

            }
            return newInit;
        })

        if (onClose) {

            onClose();

        }
    };

    const onCancel = () => {

        setInitData(() => {

            let newInit = {
                subMaterialSampleNo: 'subMaterialSample',
                productSampleNo: undefined,
                receiptNo: undefined,
                companyCode: undefined,

                reviewCount: 1,
                materialName: undefined
            }

            return newInit;
        })

        setDisabled(true);

        instance.hide();

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChange = (formData) => {
        setDisabled(false)
        setFormData(formData)

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const refreshed = (msg) => {

        setInitData(() => {
            let newInit = {
                subMaterialSampleNo: 'subMaterialSample',
                productSampleNo: undefined,
                receiptNo: undefined,
                companyCode: undefined,
                client: undefined,
                reviewCount: 1,
                materialName: undefined

            }
            return newInit;
        })
        notifySuccess(msg);



        if (refresh) {

            refresh.refresh();
        }

    };


    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        refreshed()
        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (!keepOpen) {

            instance.hide();


        }


    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {

        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {

                nofifyWarning(msg);
                return false;

            }
        }

        return true;
    };

    const onOk = (schema, formData) => {

        if (!checkItemValidation(schema, formData)) {

            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {

                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }

        let url = `/api/EguSubMaterialSample`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        if (schema && !schema.columnMap) { //autoFormDialog columnMap 생성부분 

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }
            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const modifyFormDataCallback = async (formData, name, value) => {

        if (name === 'receiptNo' && value) {


            let result = await getSpecific('EguVwSubMaterialSample', value)

            let data = result?.data[result?.data?.length - 1];
            setInitData((init) => {
                let newInit = {
                    ...init,
                    subMaterialSampleNo: 'subMaterialSample',
                    receiptNo: data?.receiptNo,
                    companyCode: data?.companyCode,
                    manager: data?.manager,
                    reviewCount: data?.reviewCount + 1,
                    targetSample: data?.targetSample,
                    processingCompany: data?.processingCompany,

                }
                return newInit;
            })

            setFormData((init) => {
                let newInit = {
                    ...init,
                    subMaterialSampleNo: 'subMaterialSample',
                    receiptNo: data?.receiptNo,
                    companyCode: data?.companyCode,
                    manager: data?.manager,
                    reviewCount: data?.reviewCount + 1,
                    targetSample: data?.targetSample,
                    processingCompany: data?.processingCompany,

                }
                return newInit;
            })
        }
    }

    return (
        <>
            <Dialog
                id={id}
                title={title}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                {uiConf.modalWithCloseButton ? (
                    <DialogHeaderWithClose>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeaderWithClose>
                ) : (
                    <DialogHeader>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeader>
                )}

                <DialogContent dividers>
                    <AutoForm
                        id={formId}
                        schema={schema}
                        // mode={mode ? mode : uiConf.defaultFormMode}
                        mode='edit'
                        initialData={initData}
                        onChange={onChange}
                        modifyFormDataCallback={modifyFormDataCallback}
                        checkShowCallback={checkShowCallback}
                    />

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                />
            </Dialog>
        </>
    );
}

export default EguSubMaterialSampleCreateDialog;
