import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce, cloneDeep } from "lodash";

import MomSysShipmentDetail from "./MomSysShipmentDetail";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import authProfileState from "../../states/authProfileState";
import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";
import apiClient from "../../lib/common/apiClient";

import MomSysShipmentCreateButton from "../input/MomSysShipmentCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import ApprovalButton from "../input/ApprovalButton";

import layoutConf from "../../config/layoutConf";

function MomSysShipment() {
    const id = "MomSysShipment";
    const table = "MomSysShipment";
    const view = "MomSysVwShipment";

    const { t } = useTranslation();

    const [height, setHeight] = useState();

    const [momSysShipmentSchema, setMomSysShipmentSchema] = useState();
    const [momSysShipmentUpdateSchema, setMomSysShipmentUpdateSchema] = useState();
    const [momSysShipmentGrid, setMomSysShipmentGrid] = useState();
    const [momSysShipmentApi, setMomSysShipmentApi] = useState();
    const [momSysShipmentSelect, setMomSysShipmentSelect] = useState();
    const [momSysShipmentDataIsEmpty, setMomSysShipmentDataIsEmpty] = useState();
    const [momSysShipmentDetailDataIsEmpty, setMomSysShipmentDetailDataIsEmpty] = useState();

    const [, setPosition] = useState({ row: 0, col: 0 });

    const [approvalCtx, setApprovalCtx] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const authProfile = useRecoilValue(authProfileState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysShipmentSchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (momSysShipmentSchema) {
            const updateSchema = cloneDeep(momSysShipmentSchema);

            if (momSysShipmentSelect?.approvalState) {
                for (const column of updateSchema.columns) {
                    switch (column.name) {
                        case "shipmentOrderDate":
                        case "shipDueDate":
                            break;

                        default:
                            column.readonly = true;
                            break;
                    }
                }
            } else {
            }

            setMomSysShipmentUpdateSchema(updateSchema);
        }
    }, [momSysShipmentSchema, momSysShipmentSelect]);

    useEffect(() => {
        (async () => {
            if (momSysShipmentGrid) {
                momSysShipmentGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysShipmentSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysShipmentSelect([]);
                        }
                    }, 10)
                );

                momSysShipmentGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysShipmentSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysShipmentSelect([]);
                        }

                    }, 10)
                );
            }
        })();
    }, [momSysShipmentGrid]);


    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 13,
            tableName: "MomSysShipment",
            statusColumn: "approvalStateId",
            schema: momSysShipmentSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.momSysShipment", {
                    shipmentNo: item.shipmentNo,
                })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);
    }, [momSysShipmentSchema, authProfile, t]);

    const onUpdateMaster = () => {
        if (momSysShipmentGrid) {
            let col = momSysShipmentGrid.selection.col;
            let row = momSysShipmentGrid.selection.row;

            setPosition({ row, col });
        }

        if (momSysShipmentApi) {
            momSysShipmentApi.refresh();
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        setPosition({ row: 0, col: 0 })
        notifySuccess(msg);
    };

    const stateUpdate = (msg) => {
        apiClient
            .patch(`/api/MomSysShipmentDetail/shipmentState/${momSysShipmentSelect.orderBuyerId}`)
            .then(onSuccess(msg));
    };

    const collectionViewChange = (collectionView) => {
        
        setPosition((position) => {
            collectionView.currentItem = null;
            collectionView.currentPosition = position?.row ?? -1;
            return position
        })
    }

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        headerTitle={t("term.shipment")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        exportFormatItem={() => { }}
                        onLoadData={(data) => {
                            setMomSysShipmentDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onInitialized={(grid) => {
                            setMomSysShipmentGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMomSysShipmentApi(api);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <AccessControl
                                    accessTarget={momSysShipmentSchema && momSysShipmentSchema.name}
                                    accessMethod="POST"
                                >
                                    <MomSysShipmentCreateButton
                                        id={id}
                                        schema={momSysShipmentSchema}
                                        createButtonName="create"
                                        onSuccess={onSuccess}
                                        refresh={momSysShipmentApi}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={momSysShipmentSchema && momSysShipmentSchema.name}
                                    accessMethod={
                                        momSysShipmentSchema && (momSysShipmentSchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <UpdateRecordButton
                                        id={id}
                                        schema={momSysShipmentUpdateSchema}
                                        grid={momSysShipmentGrid}
                                        refresh={momSysShipmentApi}
                                        disabled={
                                            //momSysShipmentSelect?.approvalState ||
                                            momSysShipmentDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={momSysShipmentSchema && momSysShipmentSchema.name}
                                    accessMethod="DELETE"
                                >
                                    <DeleteRecordButton
                                        id={id}
                                        schema={momSysShipmentSchema}
                                        grid={momSysShipmentGrid}
                                        refresh={momSysShipmentApi}
                                        color="secondary"
                                        disabled={momSysShipmentSelect?.approvalState || momSysShipmentDataIsEmpty}
                                        onSuccess={stateUpdate}
                                        onFailure={onFailure}
                                        onPartialFailure={onNotifyWarning}
                                    />
                                </AccessControl>
                                <AccessControl
                                    accessTarget={momSysShipmentSchema && momSysShipmentSchema.name}
                                    accessMethod="APPROVAL"
                                >
                                    <ApprovalButton
                                        id={id}
                                        grid={momSysShipmentGrid}
                                        refresh={momSysShipmentApi}
                                        approvalCtx={approvalCtx}
                                        disabled={momSysShipmentDataIsEmpty || momSysShipmentDetailDataIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>
                                <AccessControl
                                    accessTarget={momSysShipmentSchema && momSysShipmentSchema.name}
                                    accessMethod="SAVETOEXCEL"
                                >
                                    <SaveToExcelButton
                                        id={id}
                                        grid={momSysShipmentGrid}
                                        view={view}
                                        disabled={momSysShipmentDataIsEmpty}
                                    />
                                </AccessControl>
                            </BasicCrudScreenActionItemLayout>
                        )}
                        collectionViewChange={collectionViewChange}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    {momSysShipmentSelect ? (
                        <MomSysShipmentDetail
                            onSelectMst={momSysShipmentSelect}
                            onUpdateMaster={onUpdateMaster}
                            onDetailEmpty={(isEmpty) => {
                                setMomSysShipmentDetailDataIsEmpty(isEmpty);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysShipment;
