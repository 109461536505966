import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function UpdateRecordButton ( props ) {
    const { t } = useTranslation();
    const { id, schema, grid, refresh, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;
    const [open, setOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(()=> {
        if(schema) {
            let modifiedSchema = cloneDeep(schema);
            for (const column of modifiedSchema.columns) {
                switch(column.name) {
                    case "companyCode" : 
                        column.readonly = false;
                        break;

                    case "companyName" : 
                        column.readonly = false;
                        break;

                    case "typeOwn" : 
                        column.defaultValue = "1";
                        break;

                    case "typeBuyer" :
                        column.hideInForm = true;
                        break;

                    case "typeSupplyer" :
                        column.hideInForm = true;
                        break;

                    case "typeOutSourcing" :
                        column.hideInForm = true;
                        break;

                    default :
                        break;
                }
            }
            setModifiedSchema(modifiedSchema);
        }
    },[schema]);

    const onOpen = () => {
        if(grid.rows.visibleLength === 0) {
            setOpen(true);
        } else { 
            onFailure("자사정보는 하나만 등록 가능합니다.");
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                formId={id+"create-form"}
                title={t (`dialog.title.MomSysUdtOwnCompany.create`) }
                schema={modifiedSchema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    );
}

export default UpdateRecordButton;

