/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useTranslation } from "react-i18next";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import "../layout/DataGridOrderPurchaseInputStateColumn.css";
import defineConf from "../../../config/defineConf";

function DataGridOrderPurchaseInputStateColumn(props) {
    const { schema, ...others } = props;

    const { t } = useTranslation();

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    useEffect(() => {
        setCellTemplate(() => (ctx) => {
            const state = ctx.item.inputState; // See EguVwOrderPurchaseMst
            const dueDateOver = new Date(ctx.item.dueDate) < new Date();

            switch (state) {
                case defineConf.orderPurchaseInputState.NOT_COMPLETED:
                    return (
                        <span className={dueDateOver ? "dueDate-over" : ""}>
                            {t(`term.orderPurchaseInputState.notCompleted`)}
                        </span>
                    );

                case defineConf.orderPurchaseInputState.PARTIALLY_COMPLETED:
                    return (
                        <span className={dueDateOver ? "dueDate-over" : ""}>
                            {t(`term.orderPurchaseInputState.partiallyCompleted`)}
                        </span>
                    );

                case defineConf.orderPurchaseInputState.COMPLETED:
                    return <span>{t(`term.orderPurchaseInputState.completed`)}</span>;

                default:
                    return <span></span>;
            }
        });
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridOrderPurchaseInputStateColumn;
