import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function DeleteRecordButtonLayout ( props ) {

    const { t } = useTranslation();
    const { title } = props;

    return <ActionButtonLayout {...props}>{title || t("buttonName.delete")}</ActionButtonLayout>;

}

export default DeleteRecordButtonLayout;

