import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function UpdateRecordButton(props) {
    const { t } = useTranslation();

    const {
        id,
        schema,
        grid,
        refresh,
        onSuccess,
        modifyUrlCallback,
        modifyFormDataCallback,
        modifySchemaCallback,
        checkFormValidationCallback,
        customizeErrorMsgCallback,
        doNotUseKeyAsUrlWhenUpdate,
        ...others
    } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState(schema);

    useEffect(() => {
        setModifiedSchema(schema);

        if (modifySchemaCallback) {
            const newSchema = cloneDeep(schema);

            if (newSchema) {
                modifySchemaCallback(newSchema, { ...grid.selectedItems[0] });

                setModifiedSchema(newSchema);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                modifyUrlCallback={modifyUrlCallback}
                modifyFormDataCallback={modifyFormDataCallback}
                checkFormValidationCallback={checkFormValidationCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                doNotUseKeyAsUrlWhenUpdate={doNotUseKeyAsUrlWhenUpdate}
            />
        </>
    );
}

export default UpdateRecordButton;
