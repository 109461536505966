import { useState } from "react";
import { useTranslation } from "react-i18next";

import del from "../../lib/api/del";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function MomSysPopNoticeDeleteButton(props) {
    const { id, schema, onSelectMst, onSuccess, onFailure, refresh, onSetRefreshToggle, makeItemName, ...others } =
        props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();

    const onOpen = () => {
        setConfirmMsg(
            t("dialog.msg.confirm.delete", {
                type: t(`tableName.${schema.name}`),
                instance: makeItemName(onSelectMst),
            })
        );

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onDeleteOk = (requestData) => {
        let url = `SimpleHtmlEditor/${schema.name}/`;
        let first = true;
        for (let key of schema.keys) {
            if (!first) {
                url += "-"
            }
            first = false;
            url += onSelectMst[key];
        }

        del(url)
            .then((result) => {
                if (refresh) {
                    refresh.refresh();
                }

                if (onSetRefreshToggle) {
                    onSetRefreshToggle();
                }

                onSuccess(t("success.deleteSuccess", { table: schema.name, deleteCount: 1 }));
            })
            .catch((reason) => {
                console.error(reason);
                onFailure(t("error.deleteFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    };

    return (
        <>
            <ActionButtonLayout id={id + "-delete-button"} onClick={onOpen} {...others}>
                {t("buttonName.deleteNotice")}
            </ActionButtonLayout>
            <ConfirmDialog
                id={id + "-delete-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.delete`) : ""}
                msg={confirmMsg}
                data={null}
                open={open}
                onClose={onClose}
                onOk={onDeleteOk}
                onCancel={onClose}
            />
        </>
    );
}

export default MomSysPopNoticeDeleteButton;
