/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";

import getList from "../../../lib/api/getList";
import getNoAuthList from "../../../lib/api/getNoAuthList";

function FormItemCode ( props ) {

    const { id, className, column, label, helperText, noAuth, InputLabelProps, onChange, ...others } = props;

    const [codeInfo, setCodeInfo] = useState ();
    const [selectItems, setSelectItems] = useState ([]);

    useEffect ( () => {
        
        ( async () => {

            const codeInfo = column.codeInfo;

            setCodeInfo ( codeInfo );

            try {
                
                let apiResult = noAuth? await getNoAuthList ( codeInfo.tableName ) : await getList ( codeInfo.tableName );

                let selectItems = apiResult.data.map ( ( result ) => {

                    if ( Array.isArray ( codeInfo.nameColumn ) ) {

                        let name = "";
                        let first = true;

                        for ( const columnName of codeInfo.nameColumn ) {

                            if ( !first && result[columnName] ) {

                                name += ( codeInfo.seperator || ", " );

                            }

                            first = false;

                            name += result[columnName] || "";

                        }

                        return { name: name, value: result[codeInfo.valueColumn] };

                    } else {

                        return { name: result[codeInfo.nameColumn], value: result[codeInfo.valueColumn] };

                    }

                } );

                if ( codeInfo.sortByName ) {

                    selectItems.sort ( ( itemA, itemB ) => ( ( '' + itemA.name ).localeCompare ( itemB.name ) ) )

                }

                setSelectItems ( selectItems );

            } catch ( reason ) {

                console.error ( `Get Code List from Table "${ codeInfo.tableName }" Failed` );

            }

        } )();

    }, [column] );

    const selectProps = {
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        ...others
    };

    if ( column.displayEmpty ) {

        selectProps.displayEmpty = true;

    }

    if ( column.style ) {
        selectProps.style = column.style;
    }
    

    return (
        <FormControl className={className} required={column.required} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
                { ...selectProps }
                value={selectItems.length > 0 ? selectProps.value : ""}
            >
                {
                    codeInfo.useEmptyValue
                    ?
                        <MenuItem value="">{ i18n.t ( codeInfo.emptyName ) }</MenuItem>
                    :
                        []
                }
                {
                    selectItems.map ( ( item ) => <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
    );

}

export default FormItemCode;
