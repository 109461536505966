/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysSgtVwLotTransLog ( props ) {

    const { t } = useTranslation();
    const { lotInfo } = props;

    const [, setApi] = useState();

    useEffect ( () => {
        
        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [lotInfo] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        
    };

    const onApiInitialized = (api) => {

        setApi ( api );

    }

    const onModifySchema = (schema) => {

        for (let column of schema.columns) {

            switch ( column.name ) {

                case "lotNo":
                case "materialCode":
                case "materialCodeVer":
                case "materialName":
                case "qtyCurrent":
                case "qtyNext":

                    column.grid.hide = true;

                    break;

                default:

                    break;

            }

        }
        
    }

    const onModifyUrl = ( lotInfo, url ) => {
        
        let newUrl;

        if ( lotInfo ) {

            newUrl = `${url}/${lotInfo.lotNo}`;

        } else {

            newUrl = `${url}/undefined`;

        }

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwSgtLotTransLog"
            headerTitle={t("term.lotHistory")}
            table="MomSysVwSgtLotTransLog"
            view="MomSysVwSgtLotTransLog"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewSchema={onModifySchema}
            onModifyViewUrl={( url ) => onModifyUrl ( lotInfo, url )}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysSgtVwLotTransLog;
