import React, { useEffect, useState } from "react";

import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";

import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysInspectionItem(props) {
    const [height, setHeight] = useState(0);

    const [inspectionItemSchema, setInspectionItemSchema] = useState();

    const [inspectionItemGrid, setInspectionItemGrid] = useState();
    const [inspectionGroupGrid, setInspectionGroupGrid] = useState();


    const [inspectionItemIsEmpty, setInspectionItemIsEmpty] = useState();

    const [inspectionItemApi, setInspectionItemApi] = useState();

    const [inspectionGroupSelect, onInspectionGroupSelect] = useState();

    const { t } = useTranslation();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            if (mounted) {
                let inspectionItemSchema = await getSchema("MomSysInspectionItem");
                setInspectionItemSchema(inspectionItemSchema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (inspectionGroupSelect && mounted) {
                let schema = await getSchema("MomSysInspectionList");

                for (let column of schema.columns) {
                    switch (column.name) {
                        case "inspectionGroupId":
                            column.defaultValue = inspectionGroupSelect.inspectionGroupId;
                            break;
                        default:
                            break;
                    }
                }

            }
        })();

        return () => {
            mounted = false;
        };
    }, [inspectionGroupSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (inspectionGroupGrid && mounted) {
                inspectionGroupGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });

                inspectionGroupGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        onInspectionGroupSelect(grid.selectedItems[0]);
                    }
                });
            }
        })();

        return () => {
            mounted = false;
        };
    }, [inspectionGroupGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id={"MomSysInspectionItem"}
                headerTitle={t("term.momSysInspectionItem")}
                table={"MomSysInspectionItem"}
                view={"MomSysVwInspectionItem"}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setInspectionItemGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setInspectionItemApi(api);
                }}
                onLoadData={(data) => {
                    setInspectionItemIsEmpty(data.length < 1 ? true : false);
                }}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <React.Fragment>
                        <BasicCrudScreenActionItemLayout>
                            <CreateRecordButton
                                id={"MomSysInspectionItem"}
                                schema={inspectionItemSchema}
                                refresh={inspectionItemApi}
                                onSuccess={onSuccess}
                            />
                            <UpdateRecordButton
                                id={"MomSysInspectionItem"}
                                schema={inspectionItemSchema}
                                grid={inspectionItemGrid}
                                refresh={inspectionItemApi}
                                disabled={inspectionItemIsEmpty}
                                onSuccess={onSuccess}
                            />
                            <DeleteRecordButton
                                id={"MomSysInspectionItem"}
                                schema={inspectionItemSchema}
                                grid={inspectionItemGrid}
                                refresh={inspectionItemApi}
                                color="secondary"
                                disabled={inspectionItemIsEmpty}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />
                        </BasicCrudScreenActionItemLayout>
                    </React.Fragment>
                )}
            />

        </div>
    );
}

export default MomSysInspectionItem;
