import { useTranslation } from "react-i18next";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import ExcelIcon from "../presentation/ExcelIcon";

function SaveToExcelButtonLayout ( props ) {

    const { t } = useTranslation();

    const { ...others } = props;

    return (

      <ActionButtonLayout
        startIcon={<ExcelIcon />}

        {...others}
      >
        { t ( "buttonName.excelDownload" ) }
      </ActionButtonLayout>

    );
}

export default SaveToExcelButtonLayout;

