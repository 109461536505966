import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useSetRecoilState } from "recoil";
import layoutConf from "../../config/layoutConf";
import notificationState from "../../states/notificationState";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { debounce } from "lodash";
import "react-reflex/styles.css";

import PopOutSourcingShipDoShipButton from "../input/PopOutSourcingShipDoShipButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PopOutSourcingShipDetailHistory from "./PopOutSourcingShipDetailHistory";
import PopOutSourcingShipDetail from "./PopOutSourcingShipDetail";

function PopOutSourcingShip(props) {
    const id = "PopOutSourcingShip";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [popOutSourcingShipTargetGrid, setPopOutSourcingShipTargetGrid] = useState();
    const [popOutSourcingShipTargetApi, setPopOutSourcingShipTargetApi] = useState();
    const [popOutSourcingShipTargetSelect, setPopOutSourcingShipTargetSelect] = useState();

    const [popOutSourcingShipDetailHistoryApi, setPopOutSourcingShipDetailHistoryApi] = useState();

    const [popOutSourcingShipDetailDataIsEmpty, setPopOutSourcingShipDetailDataIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (popOutSourcingShipTargetGrid) {
                popOutSourcingShipTargetGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopOutSourcingShipTargetSelect(grid.selectedItems[0]);
                        } else {
                            setPopOutSourcingShipTargetSelect();
                        }
                    }, 10)
                );

                popOutSourcingShipTargetGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopOutSourcingShipTargetSelect(grid.selectedItems[0]);
                        } else {
                            setPopOutSourcingShipTargetSelect();
                        }
                    }, 10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popOutSourcingShipTargetGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (msg) => {
        notifyFailure(msg.response ? msg.response.data : msg);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        view="PopVwOutSourcingShipTargetList"
                        headerTitle={t("term.popOutSourcingShipTargetList")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopOutSourcingShipTargetGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopOutSourcingShipTargetApi(api);
                        }}
                        noCreate
                        noExcel
                        noEdit
                        noDelete
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>
                                <PopOutSourcingShipDoShipButton
                                    id={id}
                                    grid={popOutSourcingShipTargetGrid}
                                    refreshFtn={() => {
                                        popOutSourcingShipTargetApi.refresh();

                                        if (popOutSourcingShipDetailHistoryApi) {
                                            popOutSourcingShipDetailHistoryApi.refresh();
                                        }
                                    }}
                                    disabled={
                                        popOutSourcingShipDetailDataIsEmpty ||
                                        popOutSourcingShipTargetSelect?.outSourcingMaterialQty <=
                                            popOutSourcingShipTargetSelect?.outSourcingShipTotalQty
                                    }
                                    selectedGrid={popOutSourcingShipTargetSelect}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />
                            </PopCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.5} data-cy="Detail">
                            <PopOutSourcingShipDetailHistory
                                title={t("term.popOutSourcingShipDetailHistory")}
                                onPopOutSourcingShipDetailHistoryApi={setPopOutSourcingShipDetailHistoryApi}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                        <ReflexElement flex={0.5} data-cy="DetailLot">
                            {popOutSourcingShipTargetSelect ? (
                                <PopOutSourcingShipDetail
                                    title={t("term.popOutSourcingShipDetail")}
                                    popOutSourcingShipTargetSelect={popOutSourcingShipTargetSelect}
                                    onDetailEmpty={setPopOutSourcingShipDetailDataIsEmpty}
                                />
                            ) : (
                                <></>
                            )}
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default PopOutSourcingShip;
