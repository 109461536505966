import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function AddApproverButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.appendApprover")}</ActionButtonLayout>;

}

export default AddApproverButtonLayout;

