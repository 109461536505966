import { FlexGrid } from '@grapecity/wijmo.react.grid';
import AutoDataGridColumn from '../../grid/functional/AutoDataGridColumn';
import checkValidation from '../../../lib/utils/checkValidation';

function InputGridType(props) {
    const {
        id,
        schema,
        items,
        onInitialized,
        allowSchemaValidation,
        ...others
    } = props;

    const attachValidator = (grid, schema) => {

        grid.itemValidator = (row, col) => {
            let item = grid.rows[row].dataItem;
            let prop = grid.columns[col].binding;

            let [valid, msg] = checkValidation ( "create", schema.columnMap[prop], schema.columnMap[prop]?.validation, item[prop] );
            if(!valid) return msg;
            
        }

    }

    const onGridInitialized = (grid) => {

        if ( allowSchemaValidation ) {

            attachValidator(grid, schema);
            
        }

        if ( onInitialized ) {

            onInitialized(grid);

        }

    }

    return (
        <FlexGrid 
            initialized={onGridInitialized}
            itemsSource={items}
            {...others}
        >
            {
                schema?.columns
                    .filter((column) => column.grid && column.grid.type === 'editor')
                    .sort((columnA, columnB) => columnA.formOrder - columnB.formOrder)
                    .map((column) => <AutoDataGridColumn key={id + column.name} schema={column} />)
            }
        </FlexGrid>
    )
}

export default InputGridType;