/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import "react-quill/dist/quill.snow.css";

Quill.register("modules/imageResize", ImageResize);

const Image = Quill.import("formats/image");

function preserveSizeFormat(node, delta) {

    const match = node.className.match(/ql-size-(.*)/);
    const fontSize = node.style["font-size"];

    let regex = /\d+[.][\d]|\d+/; // 크기
    let regex1 = /[^\s\d.-]+/g; // 단위

    const ratio = fontSize.match(regex);
    const point = fontSize.match(regex1);

    if ( match || fontSize ) {

        delta.map ( function (op) {

            if ( ! op.attributes ) {

                op.attributes = {};

            }

            // grab the size from `ql-size-{x}`

            if (match) {

                op.attributes.size = match[1];

            } else if (fontSize) {

                let size;

                if (point[0].toLowerCase() === "px" || point[0].toLowerCase() === "pt") {

                    if (parseFloat(ratio) <= 10) {

                        size = "small";

                    } else if (parseFloat(ratio) <= 13) {

                        size = false;

                    } else if (parseFloat(ratio) <= 18) {

                        size = "large";

                    } else {

                        size = "huge";

                    }

                } else {

                    if (parseFloat(ratio) <= 0.75) {

                        size = "small";

                    } else if (parseFloat(ratio) <= 0.8125) {

                        size = false;

                    } else if (parseFloat(ratio) <= 1.5) {

                        size = "large";

                    } else {

                        size = "huge";

                    }

                }


                op.attributes.size = size;

            }

            return op;

        } );
    }

    return delta;

}

function SimpleHtmlEditor(props) {

    const quill = useRef(null);

    const { id, value, containerStyle, onChange, onInitialized, ...others } = props;

    const [html, setHtml] = useState("");
    const [, setImages] = useState([]);
    const [, setDeleted] = useState([]);
    const [, setImagesMap] = useState({});

    const [, setHeader] = useState();
    const [, setTimer] = useState();
    const [, setInstance] = useState();

    useEffect ( () => {

        if ( onInitialized ) {

            let instance = {

                setHtml: ( html ) => {
                        
                    setHtml(html);
        
                }
    
            };

            setInstance ( instance );
            onInitialized ( instance );
    
        }

    }, [] );

    useEffect ( () => {

        if ( value ) {

            setHtml(value.html);
            setImages(value.images);
    
        }

    }, [ value ] );

    const imageHandler = () => {

        let count = 0;

        const input = document.createElement("input");

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.addEventListener("change", async () => {

            setImagesMap ( ( imagesMap ) => {

                if ( count > 0 ) {
                        
                    // prevent multiple calls

                    return imagesMap;

                }

                count++;

                if ( !imagesMap ) {

                    imagesMap = {};

                };

                let file = input.files[0];
                let url;

                if ( imagesMap[file.name] ) {

                    url = imagesMap[file.name].url; // reuse existing url for same file name

                } else {
    
                    url = window.URL.createObjectURL(file);
    
                }

                Image.sanitize = (url) => url

                const editor = quill.current.getEditor()
                const range = editor.getSelection();

                imagesMap[file.name] = {};
                imagesMap[file.name].url = url;
                imagesMap[file.name].file = file;

                editor.insertEmbed(range.index, "image", url);
                editor.setSelection(range.index + 1);
    
                return imagesMap;

            } );


        });

    };

    const modules = useMemo(() => {

        return {

            clipboard: {

                matchers: [
                    [
                        Node.ELEMENT_NODE,
                        function (node, delta) {
                            let lastHeader =
                                delta?.ops[0]?.attributes?.header ||
                                quill?.current?.lastDeltaChangeSet?.ops[
                                    quill?.current?.lastDeltaChangeSet?.ops.length - 1
                                ]?.attributes?.header;

                            setHeader((pre) => {
                                lastHeader = lastHeader || pre;
                                return lastHeader;
                            });

                            delta.map ( function (op) {

                                if ( ! op.attributes ) {

                                    op.attributes = {};

                                }

                                op.attributes.header = lastHeader;

                                return op;

                            });

                            return delta;

                        },
                    ],
                    [
                        "span",
                        preserveSizeFormat
                    ],
                ],

            },

            keyboard: {

                bindings: {

                    "header enter": {
                        key: "Enter",
                        format: ["header", "size", "color"],
                        handler(range, context) {
                            return true;
                        },
                    },

                },
            },

            toolbar: {

                container:
                    [

                        ["image"],
                        [{ size: [] }],
                        [{ font: [] }],
                        [{ header: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],

                        [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
                        ["clean"],

                    ],
                handlers: {
                    image: imageHandler,
                },

            },

            imageResize: {

                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize", "Toolbar"],

            },

        };

    }, [quill]);

    let formats = [
        "image",
        "size",
        "font",
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "align",
        "color",
        "background",
    ];

    const onChangeHtml = ( content, delta, source, editor ) => {

        setImages ( ( images ) => {

            setDeleted ( ( deleted ) => {

                setImagesMap ( ( imagesMap ) => {

                    let html = editor.getHTML ();
                    setHtml ( html );

                    setTimer ( ( timer ) => {

                        if ( timer ) {
            
                            clearTimeout ( timer );
            
                        }

                        return setTimeout ( () => {
            
                            let html = editor.getHTML ();

                            for ( let image of images ) {

                                if ( html.indexOf ( image ) < 0 ) {

                                    deleted.push ( image );

                                }

                            }

                            for ( let deletedImage of deleted ) {

                                let idx = images.indexOf ( deletedImage );

                                if ( idx >= 0 ) {
                                        
                                        images.splice ( idx, 1 );
    
                                }

                            }

                            for ( let fileName in imagesMap ) {

                                let blobUrl = imagesMap[fileName].url;

                                if ( html.indexOf ( blobUrl ) < 0 ) {

                                    delete imagesMap[fileName];

                                }

                            }

                        
                            let newValue = {
                                html,
                                deleted,
                                imagesMap
                            };
        
                            if ( onChange ) {
    
                                onChange ( newValue );
    
                            }
            
                        }, 500 );
            
                    } );
    
                    return imagesMap;

                } );

                return deleted;

            } );

            return images;

        } );

    };

    return (

        <ReactQuill
            ref={quill}
            modules={modules}
            formats={formats}
            value={html}
            onChange={onChangeHtml}
            {...others}
        />

    );

}

export default SimpleHtmlEditor;
