import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function AddRoleSetNamespaceButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.addNamespace")}</ActionButtonLayout>;

}

export default AddRoleSetNamespaceButtonLayout;

