/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function FormItemCheckboxGroup ( props ) {

    const { t } = useTranslation ();

    const { column, className, error, label, value, helperText, onChange, ...others } = props;

    const makeCheckStates = ( value ) => {

        let checkStates = [];

        for ( const item of column.checkItems ) {

            checkStates.push ( value & item.value ? true : false );

        }

        return checkStates;

    }

    const makeCheckValue = ( checkStates ) => {

        let value = 0;

        for ( const index in column.checkItems ) {

            if ( checkStates[index] ) {

                value |= column.checkItems[index].value;

            }

        }

        return value;

    }

    const onChangeCheckValue = ( index, value ) => {

        setCheckStates ( ( checkStates ) => {

            let newStates = checkStates ? checkStates.slice() : [];
            
            newStates[index] = value;
            
            let newValue = makeCheckValue ( newStates );

            onChange ( newValue );

            return newStates;

        } );

    }

    const [checkValueStates, setCheckStates] = useState( makeCheckStates ( value ) );

    useEffect ( () => {

        setCheckStates ( makeCheckStates ( value ) );

    }, [ value ] );


    const checkboxProps = {
        color: "primary",
        ...others
    };

    return (
        <FormControl component="fieldset" required={column.required} error={error} style={ column.style && ( column.style.group || {} ) }>
            <FormLabel component="legend">{ label }</FormLabel>
            <FormGroup>
                {
                    column.checkItems
                    ?
                    column.checkItems.map ( ( item, index ) => (
                        <FormControlLabel
                            key={ item.name }
                            style={ column.style && ( column.style.item || {} ) }
                            control={
                                <Checkbox
                                    checked={ checkValueStates[index] }
                                    name={item.name}
                                    onChange={
                                        ( e ) => onChangeCheckValue ( index, e.target.checked )
                                    }
                                    { ...checkboxProps }
                                />
                            }
                            label={ t ( item.name ) }
                        />        
                    ) )
                    :
                    <></>
                }
            </FormGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );

}

export default FormItemCheckboxGroup;
