import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ExecuteBatchJobDialog from "../dialogTypes/ExecuteBatchJobDialog";
import Button from "../input/Button";

function BatchJobSchedule (props){

    const { t } = useTranslation();
    const [ open, setOpen ] = useState ( false );
    const [ job, setJob ] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        /*
            if (api) {

                api.refresh();

            }
        */

    };
    
    const onGridInitialized = ( grid ) => {

        grid.selectionChanged.addHandler ( ( grid, event ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setJob ( grid.selectedItems[0] );

            }

        } );

        grid.itemsSourceChanged.addHandler ( (grid, event) => {
 
            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setJob ( grid.selectedItems[0] );
                
            }

        } );
    
    };

    const onClickExecute = () => {

        setJob ( ( job ) => {

            if ( job ) {

                setOpen ( true );

            }

            return job;

        } );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onSuccess = ( name ) => {

        notifySuccess ( t ( `success.executeTaskSuccess`, { name } ) );
        
    };

    return (
        <>
            <BasicCrudScreenType
                noExcel
                id="BatchJobs"
                table="BatchJobs"
                view="BatchJobs"
                onInitialized = { onGridInitialized }
                onAppendButtonAtLast={ () => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <Button                            
                                id="executeBatchJobButton"
                                data-cy="executeBatchJobButton"
                                color="primary"
                                disabled={!job}
                                onClick={ onClickExecute }
                            >
                                {t("buttonName.execute")}
                            </Button>
                        </BasicCrudScreenActionItemLayout>
                    </>
                ) }
            />;
            <ExecuteBatchJobDialog
                id={"execute-batchjob-dialog"}
                title={ t ( `dialog.title.executeBatchJob` ) }
                job={job}
                open={open}
                onClose={onClose}
                onSuccess={onSuccess}
                onCancel={onClose}
            />
        </>
    );

}

export default BatchJobSchedule;
