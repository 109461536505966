function findRootRedirectPath ( topMenuItemId, menuInfo ) {

    if ( !menuInfo || menuInfo.topMenuItems.length === 0 || menuInfo.subMenuItems.length === 0 ) {
        return "";
    }

    if ( topMenuItemId ) {

        let subMenuItem = menuInfo.subMenuItems.filter( ( menuItem ) => menuItem.topMenuItemId === topMenuItemId )
            .slice().sort( ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )[0];

        return subMenuItem ? "/" + subMenuItem.topMenuPath + "/" + subMenuItem.menuPath : "/";

    } else {

        let topMenuItem = menuInfo.topMenuItems.slice().sort( ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )[0];
        let subMenuItem = menuInfo.subMenuItems.filter( ( menuItem ) => menuItem.topMenuItemId === topMenuItem.topMenuItemId )
            .slice().sort( ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )[0];

        return subMenuItem ? "/" + subMenuItem.topMenuPath + "/" + subMenuItem.menuPath : "/";

    }

}

export default findRootRedirectPath;