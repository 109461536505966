import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysShipmentDetailCreateDialog(props) {
    const classes = useStyles();

    const { t } = useTranslation();

    const {
        id,
        mode,
        schema,
        onSelectMst,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        grid,

        onClose,

        onDialogUpdateDetail,

        ...others
    } = props;

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [momSysVwPreShipmentDetailGrid, setMomSysVwPreShipmentDetailGrid] = useState();
    const [momSysVwPreShipmentDetailApi, setMomSysVwPreShipmentDetailApi] = useState();
    const [momSysVwPreShipmentDetailSelect, setMomSysVwPreShipmentDetailSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysVwPreShipmentDetailGrid) {
                momSysVwPreShipmentDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwPreShipmentDetailSelect(grid.selectedItems[0]);
                            setOkButtonDisabled(false);
                        } else {
                            setMomSysVwPreShipmentDetailSelect();
                            setOkButtonDisabled(true);
                        }
                    }, 10)
                );

                momSysVwPreShipmentDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwPreShipmentDetailSelect(grid.selectedItems[0]);
                            setOkButtonDisabled(false);
                        } else {
                            setMomSysVwPreShipmentDetailSelect();
                            setOkButtonDisabled(true);
                        }
                    }, 10)
                );
            }
        })();
    }, [momSysVwPreShipmentDetailGrid]);

    useEffect(() => {
        if (momSysVwPreShipmentDetailSelect && onSelectMst) {
            const shipmentRemainQty =
                momSysVwPreShipmentDetailSelect.deliveryRequiredQty - momSysVwPreShipmentDetailSelect.shipmentQty;

            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    shipmentId: onSelectMst.shipmentId,
                    shipmentNo: onSelectMst.shipmentNo,
                    orderBuyerDetailId: momSysVwPreShipmentDetailSelect.orderBuyerDetailId,
                    orderBuyerId: onSelectMst.orderBuyerId,
                    orderBuyerName: onSelectMst.orderBuyerName,

                    materialCode: momSysVwPreShipmentDetailSelect.materialCode,
                    materialCodeVer: momSysVwPreShipmentDetailSelect.materialCodeVer,
                    materialName: momSysVwPreShipmentDetailSelect.materialName,

                    itemUnitName: momSysVwPreShipmentDetailSelect.itemUnitName,
                    itemSpecName: momSysVwPreShipmentDetailSelect.itemSpecName,

                    materialMediumUnitQty: momSysVwPreShipmentDetailSelect.materialMediumUnitQty,
                    materialSmallUnitQty: momSysVwPreShipmentDetailSelect.materialSmallUnitQty,

                    deliveryRequiredQty: momSysVwPreShipmentDetailSelect.deliveryRequiredQty, // 납품 요구 수량
                    deliveryRequiredMediumUnitQty: momSysVwPreShipmentDetailSelect.deliveryRequiredMediumUnitQty,
                    deliveryRequiredSmallUnitQty: momSysVwPreShipmentDetailSelect.deliveryRequiredSmallUnitQty,

                    qtyInStock: momSysVwPreShipmentDetailSelect.qtyInStock, // 재고 수량
                    mediumUnitQtyInStock: momSysVwPreShipmentDetailSelect.mediumUnitQtyInStock,
                    smallUnitQtyInStock: momSysVwPreShipmentDetailSelect.smallUnitQtyInStock,

                    shipmentQty: shipmentRemainQty, // 출하 지시 수량
                    shipmentMediumUnitQty: shipmentRemainQty * momSysVwPreShipmentDetailSelect.materialMediumUnitQty,
                    shipmentSmallUnitQty: shipmentRemainQty * momSysVwPreShipmentDetailSelect.materialSmallUnitQty,
                };

                return newFormData;
            });
        }
    }, [onSelectMst, momSysVwPreShipmentDetailSelect]);

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    const onCancel = () => {
        setOkButtonDisabled(true);

        dialogInstance.hide();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onApiSuccess = (result) => {

        if (!keepOpen) {
            dialogInstance.hide();
        } else {
            if (momSysVwPreShipmentDetailApi) {
                momSysVwPreShipmentDetailApi.refresh();
            }
        }

        if (onSuccess) {
            setTimeout(() => onSuccess(result.data), 0);
        } else {
            onNotifySuccess(result.data);
        }

    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");
            return;
        }

        if (isValidMaterial()) {
            return false;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        /*---------------------------------------------------------------*/
        if (String(formData.shipmentQty).includes(".")) {
            notifyWarning(t("warning.formDataValidationFail", { msg: t("error.invalidQtyNumber") }));
            return;
        }
        /*---------------------------------------------------------------*/

        const url = `/api/${schema.name}`;

        const apiData = { ...formData };

        const form = new FormData();

        const json = {};
        const deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const isValidMaterial = () => {
        if (isEmpty(grid)) {
            return false;
        }

        let loop = grid.rows.length;
        for (let i = 0; i < loop; i++) {
            let data = grid.rows[i]._data;
            if (data.materialCode === formData.materialCode && data.materialCodeVer === formData.materialCodeVer) {
                notifyWarning(t("warning.duplicatMaterial"));
                return true;
            }
        }
        return false;
    }

    const isEmpty = (value) => {
        if (value === undefined || value === "undefined" || value === "" || value === null) {
            return true;
        }
        return false;
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <BasicCrudScreenType
                                id={formId}
                                embededInOtherScreenType
                                headerTitle={t("tableName.MomSysUglOrderBuyerDetail")}
                                view="MomSysVwPreShipmentDetail"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={(grid) => {
                                    grid.selectionMode = "Row";
                                    setMomSysVwPreShipmentDetailGrid(grid);
                                }}
                                onApiInitialized={(api) => {
                                    setMomSysVwPreShipmentDetailApi(api);
                                }}
                                onModifyViewUrl={(url) => `${url}/${onSelectMst.orderBuyerId}`}
                            />
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement>
                            <div
                                style={{
                                    padding: "32px",
                                }}
                            >
                                <AutoForm
                                    id={`${schema.name}create-form`}
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChangeFormData}
                                    modifyFormDataCallback={(formData, name) => {
                                        let numQty = Number(formData.shipmentQty);

                                        if (name === "shipmentMediumUnitQty") {
                                            numQty =
                                                Number(formData.shipmentMediumUnitQty) / formData.materialMediumUnitQty;
                                        } else if (name === "shipmentSmallUnitQty") {
                                            numQty =
                                                Number(formData.shipmentSmallUnitQty) / formData.materialSmallUnitQty;
                                        }

                                        formData.shipmentQty = numQty;
                                        formData.shipmentMediumUnitQty = formData.materialMediumUnitQty * numQty;
                                        formData.shipmentSmallUnitQty = formData.materialSmallUnitQty * numQty;
                                    }}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysShipmentDetailCreateDialog;
