import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: "8px",
    }
}));

function BasicCrudScreenActionItemLayout ( props ) {

    const classes = useStyle();

    const { children, ...others } = props;


        return (

            <Grid item>
                {
                    React.Children.map(children, ( child => 
                        React.cloneElement(child, { className: classes.root, ...others}) ) )
                }
            </Grid>


        );

}

export default BasicCrudScreenActionItemLayout;

