
/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import notificationState from "../../states/notificationState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PopMaterialFakeInputButton from "../input/PopMaterialFakeInputButton";
import tenantState from "../../states/tenantState";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import EguUpdateMaterialFakeInputDetailButton from "../input/EguUpdateMaterialFakeInputDetailButton";
import getSchema from "../../lib/api/getSchema";
import { debounce } from 'lodash';
import PopMaterialFakeInputDetailDeleteButton from './../input/PopMaterialFakeInputDetailDeleteButton';


function PopMaterialFakeInput(props) {

    const id = "popMaterialFakeInput"

    const view = "VwPopOrderPurchaseNotMaterialFakeInput"

    const [grid, setGrid] = useState();

    const [detailGrid, setDetailGrid] = useState();
    
    const [detailSelect, setDetailSelect] = useState();

    const [mstEmpty, setMstEmpty] = useState();
    const [detailEmpty, setDetailEmpty] = useState();

    const { t } = useTranslation();

    const [schema, setSchema] = useState();

    const [detailSchema, setDetailSchema] = useState();

    const [mstApi, setMstApi] = useState();
    const [detailApi, setDetailApi] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const tenantValue = useRecoilValue(tenantState);

    const [height, setHeight] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        let mounted = true;

        window.addEventListener('resize', handleResize);
        handleResize();

        if(mounted) {

            (async () => {

                let schema = {

                    name: "PopMaterialFakeInput",
                    type: "BASE TABLE",
                    searchAll: false,
                    formGroups: [
                        "info",
                        "values"
                    ],
        
                    columns: [
                        
                        {
                            name: "orderPurchaseId",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 1,
                            formItemType: "text",
        
                        },
                        {
                            name: "orderPurchaseDetailId",
                            type: "varchar(50)",
                            hideInForm : true,
                            formGroup: 0,
                            formOrder: 1,
                            formItemType: "text",
        
                        },
                        {
                            name: "orderBuyerId",
                            type: "varchar(50)",
                            hideInForm : true,
                            formGroup: 0,
                            formOrder: 1,
                            formItemType: "text",
        
                        },
                        {
                            name: "orderPurchaseName",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 2,
                            formItemType: "text",
        
                        },
                        {
                            name: "materialCode",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 3,
                            formItemType: "text",
        
                        },
                        {
                            name: "materialCodeVer",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 4,
                            formItemType: "text",
        
                        },
                        {
                            name: "materialName",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 5,
                            formItemType: "text",
        
                        },
                        {
                            name: "qtyOrderPurchase",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 6,
                            formItemType: "text",
        
                        },
                        {
                            name: "qtyInput",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 6,
                            formItemType: "text",
        
                        },
                        {
                            name: "notInputPoQty",
                            type: "varchar(50)",
                            disabled: true,
                            formGroup: 0,
                            formOrder: 7,
                            formItemType: "text",
        
                        },
                        {
                            name: "qtyFakeInput",
                            type: "float",
                            required : true,
                            formGroup: 1,
                            formOrder: 1,
                            formItemType: "float",
        
                        },
                        {
                            name: "fakeInputDate",
                            type: "DATETIME(6)",
                            formGroup: 1,
                            formOrder: 2,
                            formItemType: "datetime",
        
                        },
                        {
                            name: "expirationDate",
                            type: "DATETIME(6)",
                            // required : true,
                            formGroup: 1,
                            formOrder: 3,
                            formItemType: "date",
                        },
                        {
                            name: "manufacturedDate",
                            type: "DATETIME(6)",
                            // required: true,
                            formGroup: 1,
                            formOrder: 4,
                            formItemType: "date",
                        },
                        {
                            name: "jaHo",
                            type: "varchar(50)",
                            formGroup: 1,
                            formOrder: 5,
                            formItemType: "text",
                            hideInForm : !(["YEMATDEV","YEMAT"].includes(tenantValue))
                        }
                    ]
                }

                setSchema(schema);

                let detailSchema = await getSchema("EguMaterialFakeInputDetail");
    
                setDetailSchema(detailSchema);
            })();

        }


        return ( () => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        } );


    }, []);

    useEffect(() => {
        (async () => {
            if (detailGrid) {
                detailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDetailSelect(grid.selectedItems[0]);
                        } else {
                            setDetailSelect([]);
                        }
                    },10)
                );

                detailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setDetailSelect(grid.selectedItems[0]);
                        } else {
                            setDetailSelect([]);
                        }
                    },10)
                );
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailGrid]);


    const onInitialized = grid => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setMstApi(api);

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

        if(detailApi) {

            detailApi.refresh();
        }

    };

    const onDetailSuccess = (msg) => {

        notifySuccess(msg);

        if(mstApi) {

            mstApi.refresh();
        }

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };
    
    return (

         <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6} data-cy="Master">

                    <BasicCrudScreenType
                        id={id}
                        headerTitle={t("term.notInputPo")}
                        view={view}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={(data) => {
                            setMstEmpty(data.length < 1 ? true : false);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>
                                <PopMaterialFakeInputButton
                                    id = {id}
                                    grid = {grid}
                                    refresh = {mstApi}
                                    schema = {schema}
                                    onSuccess = {onSuccess}
                                    onFailure = {onFailure}
                                    disabled={mstEmpty}
                                />
                            </PopCrudScreenActionItemLayout>
                            
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">

                    <BasicCrudScreenType
                        id="EguMaterialFakeInputDetail"
                        headerTitle={t("term.fakeInputDetail")}
                        view="VwPopMaterialFakeInputDetail"
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setDetailGrid(grid);
                        }}
                        onApiInitialized={(api)=> setDetailApi(api)}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={(data) => {
                            setDetailEmpty(data.length < 1 ? true : false);
                        }}
                        fixedOrder={[{column: "createdAt", order: "desc"}]}
                        onAppendButtonInFrontOfSearch={() => (
                            <>
                                <PopCrudScreenActionItemLayout>
                                    <EguUpdateMaterialFakeInputDetailButton
                                        id="EguMaterialFakeInputDetail"
                                        schema={detailSchema}
                                        grid={detailGrid}
                                        onSuccess={onDetailSuccess}
                                        refresh = {detailApi}
                                        disabled={
                                            //하나의 가입고 Detail의 품목이 한번이라도 입고 검사 되지않은 경우만 수정삭제 가능  
                                            detailEmpty || detailSelect?.inspectQty !== 0
                                        }
                                    />

                                </PopCrudScreenActionItemLayout>
                                <PopCrudScreenActionItemLayout>

                                    <PopMaterialFakeInputDetailDeleteButton
                                        id="PopMaterialFakeInput"
                                        schema={detailSchema}
                                        grid={detailGrid}
                                        refresh={detailApi}
                                        color="secondary"
                                        onSuccess={onDetailSuccess}
                                        onFailure={onFailure}
                                        onPartialFailure={onNotifyWarning}
                                        disabled={
                                            //하나의 가입고 Detail의 품목이 한번이라도 입고 검사 되지않은 경우만 수정삭제 가능  
                                            detailEmpty || detailSelect?.inspectQty !== 0
                                        }
                                    />
                                </PopCrudScreenActionItemLayout>
                            </>
                            
                        )}
                    />

                </ReflexElement>

                </ReflexContainer>

        </div>
    );
}

export default PopMaterialFakeInput;
