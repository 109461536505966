function makeSelectionByPath ( menuInfo, path ) {

    let selection = {
        top: 1,
        sub: 1
    };

    let [ ,topMenuPath, subMenuPath ] = path.split("/");

    let topMenuItem = menuInfo.topMenuItems.find( ( menuItem ) => menuItem.menuPath === topMenuPath );

    if ( !topMenuItem )
        return selection;
    
    selection.top = topMenuItem.topMenuItemId;

    let subMenuItem;
    
    if ( !subMenuPath )
    {

        subMenuItem = menuInfo.subMenuItems.filter( ( menuItem ) => menuItem.topMenuItemId === selection.top )
            .slice().sort( ( menuItemA, menuItemB ) => menuItemA.order - menuItemB.order )[0];
        
    } else {

        subMenuItem = menuInfo.subMenuItems.find ( ( menuItem ) => menuItem.menuPath === subMenuPath );

    }

    if ( subMenuItem ) {

        selection.sub = subMenuItem.subMenuItemId;

    }

    return selection;

}

export default makeSelectionByPath;
