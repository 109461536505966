/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import PopOutSourcingShipDetailCreateDialog from "../dialogTypes/PopOutSourcingShipDetailCreateDialog";

function PopOutSourcingShipDetailCreateButton(props) {
    const { id, schema, refresh, popOutSourcingShipTargetSelect, onSuccess, ...others } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} {...others} />
            <PopOutSourcingShipDetailCreateDialog
                id={id + "create-dialog"}
                title={t(`dialog.title.${schema.name}.create`)}
                schema={schema}
                popOutSourcingShipTargetSelect={popOutSourcingShipTargetSelect}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default PopOutSourcingShipDetailCreateButton;
