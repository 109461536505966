import { Children, cloneElement } from "react";
import { useRecoilValue } from "recoil";

import accessControlListState from "../../states/accessControlListState";
import tenantStatusState from "../../states/tenantStatusState";

import checkAuthorization from "../../lib/auth/checkAuthorization";
import checkHideUi from "../../lib/auth/checkHideUi";

function AccessControl ( props ) {

    const { accessTarget, accessMethod, children, ...others } = props;

    let accessControlList = useRecoilValue ( accessControlListState );
    let tenantStatus = useRecoilValue (  tenantStatusState );

    let disabled = ! ( tenantStatus === "1" && checkAuthorization ( accessControlList, accessTarget, accessMethod ) );
    
    let hideUi = checkHideUi ( accessControlList, accessTarget, accessMethod );

    return (
        hideUi
        ?
        <></>
        :
        <>
            {
                disabled
                ?
                Children.map(children, (child => 
                    cloneElement(child, {...others, disabled: true  } )
                ))
                :
                Children.map(children, (child => 
                    cloneElement(child, {...others  } )
                ))
            }
        </>
    );

}

export default AccessControl;

