/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({

    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },

}));

function AddBomMaterialDialog ( props ) {

    const  classes = useStyles ();
    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [disabled, setDisabled] = useState ( true );
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [grid, setGrid] = useState();
    const [material, setMaterial] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        schema,
        process,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                nofifyWarning ( msg );
                return false;

            }
            
        }

        return true;

    }

    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };


    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setMaterial(grid.selectedItems[0]);

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setMaterial(grid.selectedItems[0]);
                        
                    }

                });

            }

        })();

    }, [grid]);
    
    useEffect ( () => {
        
        if ( process ) {
                
            setFormData ( {
                bomMaterialCode: process.bopMaterialCode,
                bomMaterialCodeVer: process.bopMaterialCodeVer,
                bomMaterialName: process.bopMaterialName,
                processCode: process.processCode,
                processName: process.processName,
            } );

        } else {

            setFormData ( {} );

        }

    }, [process])

    useEffect ( () => {

        setFormData ( ( formData ) => {

            let newFormData = {

                ...formData,
                componentCode: material.materialCode,
                componentCodeVer: material.materialCodeVer,
                componentName: material.materialName,

            };

            return newFormData;

        } );

    }, [material] );

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.insertSuccess", { table: "MomSysUdtBillOfMaterial" } ) );

        }

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
                outWareHouseCode: undefined,
            }

            return newFormData;

        } );

        setKeepOpen ( ( keepOpen ) => {

            setDisabled ( true );

            if ( !keepOpen ) {

                instance.hide();

            }

            return keepOpen;

        } );

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, formData ) => {

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = "/api/MomSysUdtBillOfMaterial";

        let apiData = { ...formData };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {

                deleted[formItem] = apiData[formItem].deletedFiles;
                    
                for ( const file of apiData[formItem].activeFiles ) {

                    if ( file.type === "url" ) continue;

                    form.append ( formItem, file.file );

                }

            } else {

                if ( apiData[formItem] !== undefined ) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
                outWareHouseCode: undefined,
            }

            return newFormData;

        } );

        setDisabled ( true );
        instance.hide();

    };

    const  checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>

            </>

        )

    }

    const onChange = ( formData ) => {

        setFormData ( formData );

        setDisabled ( false );

    }

    const onGridInitialized = ( grid ) => {

        setGrid(grid);

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setDisabled ( true );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
                outWareHouseCode: undefined,
            }

            return newFormData;

        } );
        
        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.MomSysUdtBillOfMaterial.create" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.4} data-cy="BomMaterial">

                        <BasicCrudScreenType
                            id="MomSysZvwMaterial"
                            embededInOtherScreenType
                            headerTitle={t("term.inputMaterial")}
                            table="MomSysZvwMaterial"
                            view="MomSysZvwMaterial"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={onGridInitialized}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.6} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id="bop-out-material-warehouse-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChange}
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[ t("common.add")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default AddBomMaterialDialog;
