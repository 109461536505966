import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import MomSysOutSourcingButtonLayout from '../layout/MomSysOutSourcingButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysOutSourcingButton (props) {

    const {id, onSelect, grid, schema, refresh, standard, onSuccess, customizeErrorMsgCallback, ...others} = props;
    const [modifiedSchema, setModifiedSchema] = useState();

    const [formData, setFormData] = useState();

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);


    useEffect ( () => {

        if(schema) {
            
            let modifiedSchema = cloneDeep(schema);
    
            setModifiedSchema ( modifiedSchema );

        }


    }, [schema] );

    const setInitialDataCallback = ( formData ) => {

        if ( !formData ) {

            formData = {};
            
        }

        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.qty = onSelect.qtyCurrent;
    }

    const onOpen = () => {

        if(grid) {

            setFormData ( {...(grid.selectedItems[0])} );
        }

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onOutSourcingSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };

    const modifyUrlCallback = (url) => {

       let modifiedUrl;

        if(standard) {

            modifiedUrl = `/api/Material/${onSelect.lotNo}/outSourcing`;
        
        } else {

            modifiedUrl = `/api/NsMaterial/${onSelect.lotNo}/outSourcing`;
        }

       return modifiedUrl;

    }


    return (
        <>
        <MomSysOutSourcingButtonLayout id = {id+"-outSourcing-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"outSourcing-dialog"}
                mode="create"
                formId={id+"outSourcing-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'outSourcing'}`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onOutSourcingSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback= {setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                noKeepOpen
            />
        </>
    )

}

export default MomSysOutSourcingButton;