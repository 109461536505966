/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import getNewDateObj from "../../lib/common/getNewDateObj";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function CancelOrderDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState ({});

    const [, setTask] = useState ();
    const [workerMap, setWorkerMap] = useState ({});
    const [machineMap, setMachineMap] = useState ({});

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        task,
        planData,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {
        
        if ( task && workerMap && machineMap ) {

            setFormData ( ( formData ) => {

                let workers = "";

                if ( task.workers ) {

                    for ( let resource of task.workers ) {

                        let worker = workerMap[resource.resource_id];
    
                        if ( workers.length > 0 ) {
    
                            workers += ", ";
    
                        }
    
                        if ( worker ) {
                                
                                workers += worker.text;
        
                        }
    
                    }
        
                }

                let machines = "";

                if ( task.machines ) {

                    for ( let resource of task.machines ) {

                        let machine = machineMap[resource.resource_id];
    
                        if ( machines.length > 0 ) {
    
                            machines += ", ";
    
                        }
    
                        if ( machine ) {
                                
                            machines += machine.text;
        
                        }
    
                    }
    
                }

                let startDate = getNewDateObj ( task.plannedStartTime );
                let startDateString = `${startDate.getFullYear ()} / ${startDate.getMonth () + 1} / ${startDate.getDate ()}`;

                formData = {

                    taskName: task.taskName,
                    taskStartDate: startDateString,
                    durationInHours: task.durationInHours,
                    workers: workers,
                    machines: machines,
                    outsourcing: false,
    
                }

                return formData;

            } );
    
        }

        setTask ( task );

    }, [task, workerMap, machineMap] );

    useEffect ( () => {

        if ( planData ) {

            // create new worker map having key of resource id

            let workerMap = {};

            for ( let index in planData.workerMap ) {

                workerMap[planData.workerMap[index].id] = planData.workerMap[index];

            }

            setWorkerMap ( workerMap );

            // create new machine map having key of resource id

            let machineMap = {};

            for ( let index in planData.machineMap ) {

                machineMap[planData.machineMap[index].id] = planData.machineMap[index];

            }

            setMachineMap ( machineMap );

        }

    }, [planData] );

    const schema = {

        name: "OrderTask",
        type: "BASE TABLE",
        searchAll: false,

        columns: [
            {
                name: "taskName",
                formOrder: 1,
                formItemType: "text",
                readonly: true,
                visible: true,
            },
            {
                name: "taskStartDate",
                formOrder: 2,
                formItemType: "text",
                readonly: true,
                visible: true,
            },
            {
                name: "workers",
                formOrder: 3,
                formItemType: "text",
                readonly: true,
                visible: true,
            },
            {
                name: "machines",
                formOrder: 4,
                formItemType: "text",
                readonly: true,
                visible: true,
            },

        ]

    };
    
    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result ) => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

        if ( onSuccess ) {

            setTask ( ( task ) => {

                onSuccess ( t ( "success.cancelOrderSuccess"), task.id );
                return task;

            } );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.cancelOrderFailed" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setFormData ( ( formData ) => {


            setTask ( ( task ) => {
    
                let planId = task.id;
    
                let apiData = {
                };
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};
    
                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );

                apiClient
                    .put(`/api/EguPlan/${planId}/cancel`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => onApiSuccess(result))
                    .catch((reason) => onApiFailure(apiData, reason));
            
                return task;
    
            } );

            return formData;

        } );


    };

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const onChangeForm = ( formData ) => {

        setFormData ( formData );

    };

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="xs"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.cancelOrder" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent>

                <AutoForm
                    id="order-task-form"
                    initialData={formData}
                    schema={schema}
                    mode="edit"
                    onChange={ onChangeForm }
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CancelOrderDialog;
