import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { AccountTree } from "@material-ui/icons";

import MomSysBomBopTreeDialog from "../dialogTypes/MomSysBomBopTreeDialog";

function MomSysBomBopTreeButton ( props ) {

    const { product } = props;

    const [, setDialog] = useState();

    const showBomBopTree = () => {

        if ( product ) {

            setDialog ( ( dialog ) => {

                if ( dialog ) {
    
                    dialog.show ();
    
                }
    
                return ( dialog );
    
            } );
    
        }
    }

    const onInitializeDialog = ( dialog ) => {

        setDialog ( dialog );

    }

    return (

        <>

            <IconButton {...props}>
                <AccountTree onClick={showBomBopTree} />
            </IconButton>
            <MomSysBomBopTreeDialog id={"bombop-info-bombop-tree-dialog"} product={product} onInitialized={onInitializeDialog} />

        </>

    )


}

export default MomSysBomBopTreeButton;
