import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguOutSourcingPurchaseDetailUpdateButton(props) {

    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, onChangeInOutType, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();
    const [modifiedSchema, setModifiedSchema] = useState();

    const onOpen = () => {

        if ( grid ) {

            setFormData({ ...(grid.selectedItems[0]) });

            if ( schema ) {
        
                setModifiedSchema ( onChangeInOutType ( schema, grid.selectedItems[0]?.inOutType ) );

            }

        }

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onUpdateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        refresh.refresh();

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>

    );
}

export default EguOutSourcingPurchaseDetailUpdateButton;

