/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { ComboBox, AutoComplete } from '@grapecity/wijmo.input';
import { DataMap } from '@grapecity/wijmo.grid';
import getSpecific from "../../lib/api/getSpecific";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },
    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function PopSelfInspectionDialog(props) {

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [keepOpen, setKeepOpen] = useState(false);

    const {

        // Dialog Properties

        id,
        title,
        mode,
        platform,
        resizable,
        moveable,
        fullWidth,
        maxWidth,
        onInitialized,
        noKeepOpen,

        // Form Properties

        formId,
        schema,
        initialData,
        setInitialDataCallback,
        modifyFormDataCallback,
        checkShowCallback,
        checkDisabledCallback,
        checkFormValidationCallback,
        checkItemValidationCallback,
        modifyUrlCallback,

        // customize error message callback

        customizeErrorMsgCallback,

        // AutoFormDialog Properties

        onClose,
        onChange,
        onSuccess,
        onFailure,

        // Do Not Notify Using Snackbar by Dialog itself

        doNotNotify,
        doNotNotifyFailure,
        doNotNotifySuccess,

        doNotUseKeyAsUrlWhenUpdate,

        barcodeCallback,
        focusRefs,
        inspectionGroup,
        onSelect,

        ...others

    } = props;

    const [tableSchema, setTableSchema] = useState(schema);

    const [grid, setGrid] = useState();

    const [decisionCode, setDecisionCode] = useState();

    const [decisionCodeMap, setDecisionCodeMap] = useState();

    const [items, setItems] = useState();

    useEffect(() => {

        let mounted = true;

        (async () => {

            const result = await getSpecific("MomSysVwInspectionDecisionCode");

            let items = [];

            for(const row of result.data) {

                if(row.decisionCode === "INSPECT000") {

                    items.push({qcResult: 0, decisionCode: row.decisionCode, decisionName: row.decisionName});

                    continue;

                }

                items.push({qcResult: 1, decisionCode: row.decisionCode, decisionName: row.decisionName});

            }

            const dataMap = new DataMap(items, "decisionCode", "decisionName");

            if (mounted) {

                setDecisionCode(items);

                setDecisionCodeMap(dataMap);

            }

        })();

        return (() => {

            mounted = false;

        });

    }, [])

    useEffect(() => {

        if (schema) {

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        setTableSchema(schema);

    }, [schema]);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.loadedRows.addHandler(debounce((flex, e) => {
                    let defectiveCode = flex.getColumn("defectiveCode");
                    if(defectiveCode) {
                        defectiveCode.dataMap = decisionCodeMap;
                        // defectiveCode.editor = new AutoComplete(document.createElement('div'), {
                        //     itemsSource: decisionCode,
                        //     selectedValuePath: 'decisionCode',
                        //     displayMemberPath: 'decisionName',
                        // });
                    }
                    setItems(flex?.itemsSource?.items);
                }, 100));

                grid.cellEditEnded.addHandler((flex, e) => {
                    const row = e.getRow();
                    const col = e.getColumn();
                    if (col.binding === "qcResult") {
                        const qcResult = row.dataItem.qcResult;
                        const value = decisionCode.filter((code) => code.qcResult == qcResult);
                        if(value.length > 0) {
                            row.dataItem.defectiveCode = value[0].decisionCode;
                        }
                    }
                    setItems(flex.itemsSource.items);
                });

                grid.beginningEdit.addHandler((flex, e) => {
                    const row = e.getRow();
                    const col = e.getColumn();
                    if (col.binding === "defectiveCode") {
                        const qcResult = row.dataItem.qcResult;
                        if (qcResult == null) {
                            e.cancel = true;
                        } else {
                            decisionCodeMap.getDisplayValues = (item) => {
                                let result = decisionCode.filter((code) => code.qcResult == qcResult);
                                return result.map((code) => code.decisionName);
                            }
                        }
                    }
                })
            }
        })();
    }, [grid]);

    useEffect(() => {
        if (onSelect) {
            setFormData((formData) => {
                const newFormData = {
                    ...formData,
                    lotNo: onSelect.lotNo,
                    inspectionGroupId: inspectionGroup.inspectionGroupId,
                };

                if(schema) {
                    for (const column of schema.columns) {
                        if (column?.defaultValue !== undefined) {
                            newFormData[column.name] = column.defaultValue;
                        }
                    }
    
                    return newFormData;
                }
            });
        }
    }, [onSelect, inspectionGroup, schema]);

    const notifyWarning = (msg) => {

        console.warn(msg);

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "warning",
                msg: msg,
                open: true,
            });

        }

    };

    const checkItemValidataion = (formData, schema) => {


        for (const column of schema.columns) {

            let [valid, msg] = checkValidation(mode, column, column.validation, formData[column.name] || "");

            if (valid && checkItemValidationCallback) {

                [valid, msg] = checkItemValidationCallback(formData, column);

            }

            if (!valid) {

                notifyWarning(msg);
                return false;

            }

        }

        return true;

    }

    const notifyFailure = (msg) => {

        if (!(doNotNotify || doNotNotifyFailure)) {

            setNotification({
                severity: "error",
                msg: msg,
                open: true,
            });

        }

    };

    const notifySuccess = (msg) => {

        if (doNotNotifySuccess === false) {

            setNotification({
                severity: "success",
                msg: msg,
                open: true,
            });

        }

    };

    const onCloseDialog = () => {

        setInteraction(false);

        if (onClose) {

            onClose();

        }

    }

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChangeFormData = (formData) => {

        setTimeout(() => {

            setInteraction(true);

            setFormData(formData);

            if (onChange) {

                onChange(formData);

            }

        }, 0);

    };

    const onApiSuccess = (result, schema) => {

        switch (mode) {

            case "edit":

                notifySuccess(t("success.updateSuccess", { updateCount: 1 }));
                break;

            default:

                notifySuccess(t("success.insertSuccess", { table: schema.name }));
                break;

        }

        setKeepOpen((keepOpen) => {

            if (!keepOpen) {

                instance.hide();

            }

            return keepOpen;

        });

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

    };

    const onApiFailure = (reason) => {

        let msg;

        if (customizeErrorMsgCallback) {

            msg = customizeErrorMsgCallback ( reason );

        } else {

            switch (mode) {

                case "edit":
    
                    msg = t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
                    break;
    
                default:
    
                    msg = t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) } );
                    break;
    
            }
    
        }

        if (onFailure) {

            onFailure(reason, notifyFailure);

        } else {

            notifyFailure ( msg );

        }

    };

    const onOk = (mode, formData, schema, items) => {

        if (!checkItemValidataion(formData, schema)) {

            console.error("Form Validation Failed");

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (valid && checkFormValidationCallback) {

                [valid, msg] = checkFormValidationCallback(formData);

            }

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }

        }
        else if (checkFormValidationCallback) {

            const [valid, msg] = checkFormValidationCallback(formData);

            if (!valid) {

                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }
                
        // build base api url

        let url = "/api/" + schema.name;

        if (modifyUrlCallback) {

            url = modifyUrlCallback(url);

        }

        switch (mode) {

            case "edit":

                // build api url for put or patch

                if (!doNotUseKeyAsUrlWhenUpdate) {

                    for (const column of schema.columns) {

                        if (column.key === "PRI") {

                            url += "/" + formData[column.name];

                        }

                    }

                }

                if (uiConf.updateWithPatch || schema.updateWithPatch) {

                    let apiData = {};

                    // transform api data for file type from here

                    if (initialData) {

                        // build api data only for modified columns

                        for (const columnName in formData) {

                            if (initialData[columnName] !== formData[columnName] && (!(schema.columnMap && schema.columnMap[columnName] && schema.columnMap[columnName].doNotSubmitOnApi))) {

                                apiData[columnName] = formData[columnName];

                            }

                        }

                    } else {

                        apiData = { ...formData };

                    }

                    // transform api data for file type to here

                    let form = new FormData();
                    let json = {};
                    let deleted = {};
                    let updateCount = 0;

                    for (const formItem in apiData) {

                        if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                            deleted[formItem] = apiData[formItem].deletedFiles;

                            if (deleted[formItem]) {
                                updateCount++;
                            }

                            for (const file of apiData[formItem].activeFiles) {

                                if (file.type === "url") continue;

                                form.append(formItem, file.file);
                                updateCount++;

                            }

                        } else {

                            if (apiData[formItem] !== undefined) {

                                json[formItem] = apiData[formItem];
                                updateCount++;

                            }

                        }

                    }

                    if (!updateCount) {

                        notifyWarning(t("warning.noChange"));
                        setInteraction(false);
                        return;

                    }

                    (Object.keys(deleted).length !== 0) && form.append("deleted", JSON.stringify(deleted));
                    form.append("json", JSON.stringify(json));

                    apiClient
                        .patch(url, form, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then((result) => onApiSuccess(result, schema))
                        .catch((reason) => onApiFailure(reason));

                } else {

                    let apiData = {};

                    if (initialData) {

                        // build api data only for modified columns

                        for (const columnName in formData) {

                            if (schema.columnMap && !schema.columnMap[columnName]) {

                                continue;

                            }

                            if (!(schema.columnMap && schema.columnMap[columnName] && schema.columnMap[columnName].doNotSubmitOnApi)) {

                                apiData[columnName] = formData[columnName];

                            }

                        }

                    } else {

                        apiData = { ...formData };

                    }

                    let form = new FormData();
                    let json = {};
                    let deleted = {}

                    for (const formItem in apiData) {

                        if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                            deleted[formItem] = apiData[formItem].deletedFiles;

                            for (const file of apiData[formItem].activeFiles) {

                                if (file.type === "url") continue;

                                form.append(formItem, file.file);

                            }

                        } else {

                            if (apiData[formItem] !== undefined) {

                                json[formItem] = apiData[formItem];

                            }

                        }

                    }

                    form.append("deleted", JSON.stringify(deleted));
                    form.append("json", JSON.stringify(json));

                    apiClient
                        .put(url, form, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then((result) => onApiSuccess(result, schema))
                        .catch((reason) => onApiFailure(reason));

                }

                break;

            default:

                let apiData = { ...formData, items };

                let form = new FormData();
                let json = {};
                let deleted = {};

                for (const formItem in apiData) {

                    if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                        deleted[formItem] = apiData[formItem].deletedFiles;

                        for (const file of apiData[formItem].activeFiles) {

                            if (file.type === "url") continue;

                            form.append(formItem, file.file);

                        }

                    } else {

                        if (apiData[formItem] !== undefined) {

                            json[formItem] = apiData[formItem];

                        }

                    }

                }

                form.append("deleted", JSON.stringify(deleted));
                form.append("json", JSON.stringify(json));

                apiClient
                    .post(url, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => onApiSuccess(result, schema))
                    .catch((reason) => onApiFailure(reason));

                break;

        }
    };

    const onCancel = () => {

        instance.hide();

    };

    const onGridInitialized = ( grid ) => {
        
        grid.allowSorting = false;
        grid.allowPinning = false;
        grid.allowDragging = false;
        grid.isReadOnly = false;

        setGrid(grid);

    }

    const checkKeepOpen = () => {

        return (
            <>

                <FormControlLabel
                    control={

                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>

            </>

        )

    }

    return (
        <Dialog
            id={id}
            title={title}
            platform={platform ? platform : uiConf.dialogPlatform}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            disableEnforceFocus={true}
            {...others}
        >
            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}  data-cy="SelfInspectionTemplate">
                        <BasicCrudScreenType
                            id="VwPopSelfInspectionTemplate"
                            headerTitle={t("term.momSysInspectionList")}
                            view="VwPopSelfInspectionTemplate"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            noSearch
                            noFilter
                            noPagination
                            onInitialized={onGridInitialized}
                            onModifyViewUrl={(url) => url + "/" + inspectionGroup.inspectionGroupId}
                            embededInOtherScreenType
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id={formId}
                                schema={tableSchema}
                                mode={"edit"}
                                initialData={formData}
                                setInitialDataCallback={setInitialDataCallback}
                                modifyFormDataCallback={modifyFormDataCallback}
                                checkShowCallback={checkShowCallback}
                                checkDisabledCallback={checkDisabledCallback}
                                checkValidationCallback={checkItemValidationCallback}
                                onChange={onChangeFormData}
                                barcodeCallback={barcodeCallback}
                                focusRefs={focusRefs}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={noKeepOpen ? () => <></> : checkKeepOpen}
                labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(mode, formData, tableSchema, items)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PopSelfInspectionDialog;
