/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function SelectProductionItem ( props ) {

    const { t } = useTranslation();

    const { so, embededInOtherScreenType, onChange, onNotifyError, notifyWarning } = props;

    const [, setGrid] = useState();
    const [, setApi] = useState();
    const [, setTimer] = useState();

    useEffect (() => {
        
        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [so]);

    const updateMaterial = ( row, qty ) => {

        for ( let child of row.children ) {


            row.qty = ( row.componentQty / row.bomMaterialQty ) * qty;

            updateProcess ( child, row.qty );

        }

    };

    const updateProcess = ( row, qty ) => {

        for ( let child of row.children ) {

            updateMaterial ( child, qty );

        }

    }

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Cell";
        grid.childItemsPath = "children";
        
        grid.isReadOnly = false;

        grid.itemsSourceChanged.addHandler ( ( grid, event ) => {

            for ( let row of grid.rows ) {

                if ( row._data.taskType === 'materialPlan' ) {

                    row.isReadOnly = false;

                }
            }

            if ( onChange ) {

                onChange ( grid.rows );

            }

        } );

        grid.cellEditEnding.addHandler ( ( grid, event ) => {


            if ( grid.activeEditor.value <= 0 ) {

                notifyWarning ( t ( "warning.invalidQty" ) );
                event.cancel = true;

            } else {

                let row = grid.rows[event.row]._data;

                for ( let child of row.children ) {

                    updateProcess ( child, grid.activeEditor.value );

                }

                setTimer ( ( timer ) => {

                    if ( timer ) {

                        clearTimeout ( timer );
                         
                    }

                    let newTimer = setTimeout ( () => notifyChange ( grid.rows )  , 500 );

                    return newTimer;
        
                } );

            }

        } );
        
        setGrid ( grid );
        
    };

    const onApiInitialized = (api) => {

        setApi ( api );

    };

    const notifyChange = (rows) => {

        if ( onChange ) {

            onChange ( rows );

        }

    };

    const updateProduce = ( item, produce, doNotUpdateChild ) => {
        
        item.produce = produce;

        if ( ! doNotUpdateChild ) {

            for ( let child of item.children ) {

                updateProduce ( child, produce );

            }

        }
        
        setTimer ( ( timer ) => {

            if ( timer ) {

                clearTimeout ( timer );
                 
            }

            setGrid ( ( grid ) => {
                
                for ( let row of grid.rows ) {

                    if ( row._data.children.includes ( item ) ) {

                        let planCount = 0;

                        for ( let child of row._data.children ) {


                            if ( child.produce ) {

                                planCount++;

                            }

                            if ( item.taskType === 'processPlan' || row._data.taskType === 'orderBuyerDetailPlan' ) {
            
                                if ( planCount ) {

                                    updateProduce ( row._data, true, true );
    
                                } else {
    
                                    updateProduce ( row._data, false, true );
    
                                }
    
                            }

                        }
                    }

                }

                if ( grid ) {
                    
                    let timer = setTimeout ( () => notifyChange ( grid.rows )  , 500 );

                    setTimer ( timer );

                }

                return grid;

            } )

        } );

    };

    const onModifyViewSchema = ( schema ) => {

        if (schema) {

            for (const column of schema.columns) {

                switch (column.name) {

                    case "produce":

                        column.onChange = ( ctx, produce ) => {

                            setGrid ( ( grid ) => {

                                updateProduce ( ctx.item, produce );
                                grid.refresh (false);

                                return grid;

                            } );

                        };

                        break;

                    default:

                        break;
                        
                }

            }

        }

    };

    const onModifyUrl = ( so, url ) => {
        
        let newUrl;

        if ( so ) {

            newUrl = `${url}/${so.orderBuyerId}`;

        } else {

            newUrl = `${url}/undefined`;

        }

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="ProductionPlanSkeleton"
            embededInOtherScreenType={embededInOtherScreenType}
            headerTitle={t("term.selectProductionItem")}
            headerTitleStyle={{
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "8px",
                marginTop: "8px",
            }}
            table="ProductionPlanSkeleton"
            view="ProductionPlanSkeleton"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onModifyViewSchema={onModifyViewSchema}
            onModifyViewUrl={( url ) => onModifyUrl ( so, url )}
            onNotifyError={onNotifyError}
        />                                

    );

}

export default SelectProductionItem;
