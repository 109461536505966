import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActions from "../dialog/functional/DialogActions";
import DialogActionButton from "../dialog/functional/DialogActionButton";

import MomSysBomBopTree from "../screens/MomSysBomBopTree";

function MomSysBomBopTreeDialog ( props ) {

    const { t } = useTranslation();

    const {

        id,
        product,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,

        ...others

    } = props;

    const [, setInstance] = useState ();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onError = ( reason ) => {

        setNotification({
            severity: "error",
            msg: reason.response ? reason.response.data : reason,
            open: true,
        });

    };

    const doHandleFileStream = (result) => {
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `BOM BOP File for ${product.materialCode} ${product.materialCodeVer} ${product.materialName}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const url = `/api/BomBopFileDownload/${product?.materialCode}/${product?.materialCodeVer}`;

    const fileDownload = () => {
        apiClient
            .get(url, { responseType: "blob" }) // Note : responseType := "blob" !!
            .then((result) => {
                doHandleFileStream(result);
            })
    }

    return (
        <Dialog
            id={id}
            title={t("dialog.title.MomSysBomBopTree")}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onInitialized={onDialogInitialized}
            {...others}
        >

            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {t("dialog.title.MomSysBomBopTree")}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {t("dialog.title.MomSysBomBopTree")}
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent style={{height: "400px"}} dividers >
                <MomSysBomBopTree product={product} onError={onError} />
            </DialogContent>

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <DialogActions {...others} >
                <DialogActionButton primary close={true} onClick={fileDownload} {...others}>{ "다운로드"}</DialogActionButton>                    
                <DialogActionButton primary close={true} {...others}>{ t("common.ok")}</DialogActionButton>                    
        </DialogActions>

        </Dialog>
    );

}

export default MomSysBomBopTreeDialog;
