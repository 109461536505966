import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import CreateCompanyCollectionButton from "../input/CreateCompanyCollectionButton";
import UpdateCompanyCollectionButton from "../input/UpdateCompanyCollectionButton";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";

function MomSysCompanyCollection ( props ) {

    const id = "MomSysUglCompanyCollection"

    const [ tableSchema, setTableSchema ] = useState();

    const [ grid, setGrid ] = useState();

    const [ infoType, setInfoType ] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const [ refresh, setRefresh ] = useState ();

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema(id);

            if ( mounted ) {
                setTableSchema(schema);
            }

        })();

        return ( () => {

            mounted = false;

        } );
    
      }, [] );

      useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler((grid, event) => {
                    setInfoType(grid.selectedItems[0] && grid.selectedItems[0].infoType);

                });
    
                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if(grid.selectedItems[0]) {
                        setInfoType(grid.selectedItems[0].infoType);

                    }
                });

            }

        } ) ()


    }, [grid] )

    const modifyFormDataCallback = async ( newFormData, name, value ) => {

        if ( name === 'infoType' ) {

            let schema = await getSchema(id);

            switch (value) {

                // 선금입금
                case 1:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = value;
                
                                break;

                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideDebitAdv":
                            case "sideCredit":

                                column.hideInForm = true;
                
                                break;
                            
                            case "companyCode":
                            case "sideCreditAdv":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }

                    setTableSchema(schema);

                    break;
                    
                // 선금지불
                case 2:
                    
                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = value;
                
                                break;

                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideCredit":
                            case "sideCreditAdv":

                                column.hideInForm = true;
                
                                break;

                            case "companyCode":
                            case "sideDebitAdv":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setTableSchema(schema);
                    
                    break;

                // 입금
                case 3:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = value;
                
                                break;

                            case "companyCode":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideDebitAdv":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;
                                
                            case "orderBuyerId":
                            case "sideCredit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setTableSchema(schema);
                    
                    break;

                // 지불
                case 4:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = value;
                
                                break;

                            case "companyCode":
                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "sideDebitAdv":
                            case "sideCredit":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;

                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setTableSchema(schema);
                    
                    break;

                // 아웃소싱지불
                case 5:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = value;
                
                                break;

                            case "companyCode":
                            case "orderBuyerId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebitAdv":
                            case "sideCredit":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;
                            
                            case "orderOutTransId":
                            case "sideDebit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setTableSchema(schema);
                    
                    break;
            
                default:
                    break;
            }
            
        }

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const onSuccess = ( msg ) => {

        notifySuccess ( msg );

    };

    const onApiInitialized = ( viewApi ) => {

        setRefresh ( viewApi );

    };

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onAppendButtonAtFirst = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>

                    <CreateCompanyCollectionButton
                        id={id}
                        schema={tableSchema}
                        refresh={refresh}
                        onSuccess={onSuccess}
                        modifyFormDataCallback={modifyFormDataCallback}
                    />

                    <UpdateCompanyCollectionButton
                        id = {id}
                        schema = {tableSchema}
                        grid ={grid}
                        refresh = {refresh}
                        onSuccess ={onSuccess}
                        infoType={infoType}
                    />
        
            </BasicCrudScreenActionItemLayout>
    
        );
    };

    return (

        <BasicCrudScreenType 
            id={id} 
            table={id} 
            view="MomSysZvwCompanyCollection" 
            noCreate
            noEdit
            onAppendButtonAtFirst={onAppendButtonAtFirst}
            onApiInitialized={onApiInitialized}
            onInitialized = {onInitialized}
        />

    )

}

export default MomSysCompanyCollection;
