import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysFailLotSplitButton from "../input/MomSysFailLotSplitButton";
import MomSysFailLotMoveButton from "../input/MomSysFailLotMoveButton";
import MomSysFailLotDecompositionButton from "../input/MomSysFailLotDecompositionButton";
import MomSysFailLotTerminateButton from "../input/MomSysFailLotTerminateButton";
import { debounce } from "lodash";

function MomSysFailLotManagement ( props ) {

    const { t } = useTranslation();

    const id = "MomSysFailLotManagement";
    const view = "MomSysVwFailLotManagement";

    const [ , setNotification ] = useRecoilState(notificationState);
    const [ grid, setGrid ] = useState();
    const [ refresh, setRefresh ] = useState();
    const [ onSelect, setOnSelect ] = useState([]);
    const [ isEmpty, setIsEmpty ] = useState();

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems.length > 0) {
                            setOnSelect(grid.selectedItems);
                        } else {
                            setOnSelect([]);
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems.length > 0) {
                            setOnSelect(grid.selectedItems);
                        } else {
                            setOnSelect([]);
                        }
                    },10)
                );
            }

        })();

    }, [grid]);

    const onNotifySuccess = (msg) => {

        setNotification({

          severity: "success",
          msg: msg,
          open: true,

        });
    };

    const onNotifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onInitialized = ( grid ) => {

        setGrid ( grid );

    }

    const onApiInitialized = (api) => {

        setRefresh (api)

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;

    }

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <MomSysFailLotSplitButton
                        id = "MomSysFailLotSplitButton"
                        createButtonName = {"failLotSplit"}
                        onSelect = {onSelect[0]}
                        refresh = {refresh}
                        disabled = {isEmpty || onSelect.length > 1}
                        onSuccess = {onNotifySuccess}
                        onFailure = {onNotifyFailure}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                    <MomSysFailLotMoveButton
                        id = "MomSysFailLotMoveButton"
                        createButtonName = {"failLotMove"}
                        items = {onSelect}
                        refresh = {refresh}
                        disabled = {isEmpty}
                        onSuccess = {onNotifySuccess}
                        onFailure = {onNotifyFailure}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                    <MomSysFailLotDecompositionButton
                        id = "MomSysFailLotDecompositionButton"
                        createButtonName = {"failLotDecomposition"}
                        onSelect = {onSelect}
                        refresh = {refresh}
                        disabled = {isEmpty || onSelect.length > 1}
                        onSuccess = {onNotifySuccess}
                        onFailure = {onNotifyFailure}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                    <MomSysFailLotTerminateButton
                        id = "MomSysFailLotTerminateButton"
                        createButtonName = {"failLotTerminate"}
                        color = "secondary"
                        grid = {grid}
                        refresh = {refresh}
                        disabled = {isEmpty}
                        onSuccess = {onNotifySuccess}
                        onFailure = {onNotifyFailure}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                </BasicCrudScreenActionItemLayout>
            </>
        )

    }

    return (
        <BasicCrudScreenType 
            id={id} 
            view={view} 
            headerTitle = {t("term.failLotList")}
            noCreate 
            noEdit 
            noDelete 
            noExcel 
            onLoadData={onLoadData}
            onInitialized = {onInitialized}
            onApiInitialized = {onApiInitialized}
            onAppendButtonInFrontOfSearch = {onAppendButtonInFrontOfSearch}
        />
    )

}

export default MomSysFailLotManagement;
