function checkAuthorization ( accessControlList, accessTarget, accessMethod ) {

    if ( !accessControlList || !accessTarget || !accessMethod ) {

        return true;

    }

    if ( accessControlList[accessTarget] ) {

        if ( accessMethod.toLowerCase() in (accessControlList[accessTarget]) ) {

            // use access rule if role and method exist in ACL

            return accessControlList[accessTarget][accessMethod.toLowerCase()].accessRule;

        } else {

            // use default access rule if role and method not exist in ACL

            return accessControlList[accessTarget].defaultAccess;

        }

    }

    // Allow Access if Access Target not exist in ACL

    return true;

}

export default checkAuthorization;

