import React from "react";
import {
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Typography,
    Popover,
    makeStyles,
} from "@material-ui/core";
import Avatar from "../presentation/Avatar";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import uiConf from "../../config/uiConf";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "36ch",
        maxHeight: "400px",
        backgroundColor: theme.palette.background.paper,
    },
    msg: {
        display: "inline",
        wordWarp: true,
    },
    avatar: {
        width: 32,
        height: 32,
    },
}));

function NotificationList(props) {

    const classes = useStyles ();

    const { t } = useTranslation ();

    const history = useHistory();

    const { id, open, anchorEl, notifications, onClose } = props;

    const onNotificationClick = ( notification ) => {

        onClose ();
        history.push ( "/" + notification.topMenuPath + "/" + notification.menuPath );

    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                square: true
            }}
        >
            <List
                className={classes.root}
            >
                {

                    notifications
                        .slice()
                        .sort ( ( notiA, notiB ) => {

                            if ( uiConf.notificationSortCompare ) {

                                return uiConf.notificationSortCompare ( notiA, notiB, uiConf.notificationSortColumn, uiConf.notificationSortOrder );

                            } else {

                                return 0;

                            }

                        } )
                        .map ( ( notification, index ) => (

                            <div key={"list-item-"+notification.approvalDetailId}>

                                {
                                    index > 0
                                    ?
                                    <Divider key={"divider-"+notification.approvalDetailId} light component="li" />
                                    :
                                    <></>                            
                                }

                                <ListItem  alignItems="flex-start" onClick={ () => onNotificationClick ( notification ) }>
                                    <ListItemAvatar>
                                        <Avatar
                                            photo={notification.requesterPhoto}
                                            email={notification.requesterEmail}
                                            className={classes.avatar}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.msg}
                                                    color="textPrimary"
                                                >
                                                    { t ( "notification.msg.approvalRequestMsg", { requester: notification.requesterFullName, msg: notification.requestMsg } ) }
                                                </Typography>
                                            </>
                                        }
                                    />

                                </ListItem>
                            </div>

                    ) )

                }

            </List>
        </Popover>
    );
}

export default NotificationList;
