import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import { Typography } from "@material-ui/core";

const LOCAL_URL = "http://localhost:8080";
const PDF_URL = "/PreViewPdf";
const EMPTY = "";

function PreViewDialog(props) {
    const { id, url, moveable, resizable, fullWidth, maxWidth, onInitialized, ...others } = props;
    const onDialogInitialized = (instance) => {
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogContent style={{marginLeft: "auto", marginRight: "auto"}}>
                {getFileData(url)}
            </DialogContent>
        </Dialog>
    );
}

function getFileData(fileUrl) {
    if(fileUrl) {
        return getPreViewFile(fileUrl);
    }
    return getNoImage();
}

function getPreViewFile(fileUrl) {
    return getExtensionByHtmlTag(getProductionAndLocalFileUrl(fileUrl));
}

function getProductionAndLocalFileUrl(fileUrl) {
    if(isProduction()) {
        return getProductionFileUrl(fileUrl);
    }
    return getLocalFileUrl(fileUrl);
}

function isProduction() {
    return ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' );
}

function getProductionFileUrl(fileUrl) {
    switch(getUpperCaseExtension(fileUrl)) {
        case "PDF":
            return PDF_URL + fileUrl;

        case "JPG": case "JPEG": case "PNG":  case "BMP": case "GIF":
            return fileUrl;

        default :
            return EMPTY;
    }
}

function getLocalFileUrl(fileUrl) {
    switch(getUpperCaseExtension(fileUrl)) {
        case "PDF":
            return LOCAL_URL + PDF_URL + fileUrl;

        case "JPG": case "JPEG": case "PNG":  case "BMP": case "GIF":
            return LOCAL_URL + fileUrl;

        default :
            return EMPTY;
    }
}

function getUpperCaseExtension(fileUrl) {
    return fileUrl.substring(fileUrl.lastIndexOf(".") + 1).toUpperCase();
}

function getExtensionByHtmlTag(fileUrl) {
    switch(getUpperCaseExtension(fileUrl)) {
        case "PDF":
            return getEmbedTag(fileUrl);

        case "JPG": case "JPEG": case "PNG":  case "BMP": case "GIF":
            return getImgTag(fileUrl);

        default :
            return EMPTY;
    }
}

function getEmbedTag(fileUrl) {
    return <embed src={fileUrl} style={{marginLeft: "auto", marginRight: "auto", height: "700px", width: "900px"}}/>
}

function getImgTag(fileUrl) {
    return <img alt={fileUrl} src={fileUrl} style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}/>
}

function getNoImage() {
    return <Typography color="inherit" variant="body2" align="center" >No Image</Typography>;
}

export default PreViewDialog;
