import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function WeeklyMaterialInOutReport (props){

    const table = "WeeklyMaterialInOutReport";
    const view = "WeeklyMaterialInOutReport";

    const { date, setView, setExcelFileName, onSearchInitialized } = props;

    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if ( date && api ) {

            setView(view);
            setExcelFileName("주간");

            api.refresh ();

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api, date ] );

    const onModifyUrl = ( url ) => {

        let newUrl = `${url}/${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`        

        return newUrl;
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {
        grid.allowSorting = false;
    };

    return (
    
        <BasicCrudScreenType
            id="WeeklyMaterialInOutReport"
            noHeader
            table={table}
            view={view}
            viewSchemaUrl={`/api/WeeklyMaterialInOutReportSchema/${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`}
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onInitialized={onGridInitialized}
        />

    );

}
export default WeeklyMaterialInOutReport;