/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysVwSgtLot ( props ) {

    const { t } = useTranslation();
    const { onSelect } = props;

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        grid.selectionChanged.addHandler((grid, event) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect(grid.selectedItems[0]);

                }

            } else {

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }

            }

        });

        grid.itemsSourceChanged.addHandler((grid, event) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect(grid.selectedItems[0]);

                }

            } else {

                if ( onSelect ) {

                    onSelect ( undefined );

                }
                
            }

        });

    };

    return (

        <BasicCrudScreenType
            id="MomSysVwTraceableLot"
            headerTitle={t("term.lot")}
            table="MomSysVwTraceableLot"
            view="MomSysVwTraceableLot"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
        />                                

    );

}

export default MomSysVwSgtLot;
