/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState, useRef } from "react";

import uiConf from '../../config/uiConf';

import WebSocketClient from "../functional/WebSocketClient";
import prettyLog from "../../lib/utils/prettyLog";

function filter ( search, filterStr, text, json ) {
    
    let filtered = false;

    if ( search !== undefined && search.length > 0) {

        if ( ! text.toLowerCase().includes ( search.toLowerCase () ) ) {

            filtered = true;

        }

    }

    let filters;

    try {

        filters = JSON.parse ( filterStr );

    } catch ( reason ) {

        console.error ( "Error parsing filter JSON [" + filterStr + "]", reason );

    }

    if ( filters !== undefined && Array.isArray ( filters ) && filters.length > 0 ) {

        for ( let filter of filters ) {

            switch ( filter.binding ) {

                case "logLevel":

                    if ( json.level !== filter.condition1.value ) {

                        filtered = true;

                    }

                    break;

                case "srcFile":

                    if ( ! json.file.toLowerCase ().includes ( filter.condition1.value.toLowerCase () ) ) {

                        filtered = true;

                    }

                    break;

                case "srcLine":

                    if ( json.line !== parseInt ( filter.condition1.value ) ) {

                        filtered = true;

                    }

                    break;

                case "logMsg":

                    if ( ! json.msg.toLowerCase ().includes ( filter.condition1.value.toLowerCase () ) ) {

                        filtered = true;

                    }

                    break;
                    
                default:

                    break;

            }

        }

    }

    return filtered;

}

function PrettyLog ( props ) {

    const { logData } = props;

    const [ log, setLog ] = useState ( logData );

    useEffect ( () => {

        let log = prettyLog ( "string", "jsx", logData );
        setLog ( log );

    }, [logData] );

    return log;

}

function LogViewer ( props ) {

    const viewerRef = useRef ();

    const { data, size, $search, $filter, onLogOpen, onLogClose, onInitialized } = props;

    const [ logs, setLogs ] = useState ( [] );

    const [, setSocket ] = useState ();
    const [ , setInstance ] = useState ( null );
    const [ , setTimer ] = useState ();
    const [ , setSearch ] = useState ( "" );
    const [ , setFilters ] = useState ( [] );
    const [ , setBufferSize ] = useState ( size ?? 25 );

    useEffect ( () => {

        let instance = {

            clear: () => {

                setLogs ( [] );

            },

            copy: () => {

                setLogs ( ( logs ) => {

                    let logText = "";

                    for ( let log of logs ) {

                        let logLine = prettyLog ( "string", "string", log );
                        logText += logLine;

                    }

                    navigator.clipboard.writeText ( logText );

                    return logs;

                } );

            },

            connect: () => {

                setSocket ( ( socket ) => {
                
                    if ( socket ) {

                        socket.connect ();

                    }

                    return socket;

                } );

            },

            disconnect: () => {

                setSocket ( ( socket ) => {

                    if ( socket ) {

                        socket.disconnect ();

                    }

                    return socket;

                } );

            }

        };

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    }, [] );

    useEffect ( () => {

        if ( data ) {

            setLogs ( data );

        }

    }, [data] );

    useEffect ( () => {

        const  bufferSize = size ?? uiConf.InfiniteScrollView?.bufferSize ?? 25;

        setLogs ( ( logs ) => {

            while ( logs.length > bufferSize ) {
    
                logs.shift ();

            }

            setBufferSize ( bufferSize );

            return logs;

        } );

    }, [size] );

    useEffect ( () => {

        setSearch ( $search );
        setFilters ( $filter );

    }, [$search, $filter] );


    const onOpen = ( event ) => {

        console.log ( "Connected to Web Socket Log Server", event );

        if ( onLogOpen ) {

            onLogOpen ();

        }

    };

    const onClose = ( error ) => {

        console.error ( "Connection to Web Socket Log Server Closed", error );
        
        if ( onLogClose ) {

            onLogClose ();

        }

    };

    const onError = ( error ) => {

        console.error ( "Error on Connection to Web Socket Log Server", error );

    };

    const onMessage = ( event ) => {            
        
        let processCount = 0;

        setSearch ( ( search ) => {

            if ( search !== undefined ) {

                setFilters ( ( filters ) => {


                    if ( filters !== undefined ) {
            
                        setLogs ( ( logs ) => {
                
                            let line = event.data;
                
                            try {
    
                                if ( processCount === 0 ) {

                                    let json = JSON.parse ( line );
    
                                    let filtered = filter ( search, filters, line, json );
    
                                    if ( ! filtered ) {

                                        logs.push ( line );

                                    }

                                    processCount++;

                                }
                        
                            } catch ( reason ) {

                                console.error (
    `
    ====================================================================================================
    Error parsing JSON log message:
    ${line}
    ====================================================================================================
`
                                );

                            }

    
                            setBufferSize ( ( bufferSize ) => {

                                while ( logs.length > bufferSize ) {
    
                                    logs.shift ();
        
                                }
                                    
                                return bufferSize;

                            } );
    
                            if ( processCount > 0 ) {

                                setTimer ( ( timer ) => {
    
                                    if ( ! timer ) {
        
                                        clearTimeout ( timer );
        
                                    }
        
                                    timer = setTimeout ( () => {
        
                                        let logCount = logs.length;
        
                                        if ( logCount > 0 ) {
        
                                            scrollToBottom ();
        
                                        }
        
                                    }, 300 );
        
                                    return timer;
        
                                } );

                            }

                            return logs;

                        } );

                    }

                    return filters;

                } );

            }

            return search;

        } );

    };

    const onSocketInitialized = ( instance ) => {

        setSocket ( instance );

    };

    const scrollToBottom = () => {

        let prevScrollTop = viewerRef.current?.scrollTop;

        if ( viewerRef.current?.scrollTop === prevScrollTop ) {

            viewerRef.current?.scrollBy ( { top: viewerRef.current.scrollHeight + 100, behavior: "smooth" } );

        }

    }

    return (

        <>
            {
                data
                ?
                <></>
                :
                <WebSocketClient url="log" onOpen={onOpen} onClose={onClose} onError={onError} onMessage={ onMessage } onInitialized={onSocketInitialized} />
            }
            <div id="log-viewer" ref={viewerRef} style={{position: "absolute", top: 0, left:0, right: 0, bottom: 0, overflow: "auto"}}>
            {
                logs.map (

                    ( log, index ) =>
                        <Fragment key={index}>
                            <PrettyLog logData={log} />
                        </Fragment>

                )
            }
            </div>
        </>

    );

}

export default LogViewer;
