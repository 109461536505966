import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Selector } from '@grapecity/wijmo.grid.selector';

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import ToolbarFlexSpacer  from "../layout/ToolbarFlexSpacer";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import FormItemRadio from "../form/input/FormItemRadio";

function MomSysLotListForProcess ( props ) {

    const { t } = useTranslation ();

    const { lotInfo, notifyWarning, onLotSelectionTypeChanged, onInitialized } = props;

    const [disabled, setDisabled] = useState ( true );
    const [selectionType, setSelectionType] = useState ( "auto" );
    // const [, setSelector] = useState();
    const [, setApi] = useState ();

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [lotInfo] );

    useEffect ( () => {

        switch ( selectionType ) {

            case "manual":

                setDisabled ( false );
                break;

            default:

                setDisabled ( true );
                break;
                
        }
    
    }, [selectionType] );

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "ListBox";

        grid.isReadOnly = false;

        grid.cellEditEnding.addHandler ( ( sender, e ) => {

            let row = sender.rows[e.row]._data;

            if ( sender.activeEditor.value > row.qtyInStock ) {

                notifyWarning ( t ( "warning.invalidConsumedQty" ) );
                e.cancel = true;

            }

        } );

        if ( onInitialized ) {

            onInitialized ( grid );

        }

    }

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onModifyUrl = ( lotInfo, url ) => {
    
        let newUrl;

        newUrl = `${url}/${lotInfo?lotInfo.lotNo:null}`;

        return newUrl;
        
    };

    const onChange = ( value ) => {

        setSelectionType ( value );

        if ( onLotSelectionTypeChanged ) {

            onLotSelectionTypeChanged ( value );

        }

    }

    const getRadioButtons = ( selectionType ) => {

        return (
            () => (

                <>

                    <ToolbarFlexSpacer xs />

                    <BasicCrudScreenActionItemLayout>

                        <FormItemRadio
                            id="LotSelectionType"
                            label={""}
                            value={selectionType}
                            column={
                                {
                                    name: "",
                                    row: true,
                                    formControlStyle: { margin: 0, paddingTop: 0},
                                    selectItems: [
                                        {
                                            name: t("term.auto"),
                                            value: "auto"
                                        },
                                        {
                                            name: t("term.manual"),
                                            value: "manual"
                                        },
                                    ]
                                }
                            }
                            onChange={onChange}
                        />

                    </BasicCrudScreenActionItemLayout>

                </>

            )
        )
    }

    return (

        <BasicCrudScreenType
            id="InputLots"
            headerTitle={t("term.inputLots")}
            table="MomSysVwLotsForProcessOnLine"
            view="MomSysVwLotsForProcessOnLine"
            disableGrid={disabled}
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onAppendButtonInFrontOfSearch={ getRadioButtons ( selectionType) }
            onModifyViewUrl={ ( url ) => onModifyUrl ( lotInfo, url ) }
            onInitialized={onGridInitialized}
            onApiInitialized={ onApiInitialized }
        />

    );

};

export default MomSysLotListForProcess;
