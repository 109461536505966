import React from "react";
import ReactDOM from 'react-dom';

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import uiConf from "../../config/uiConf";

import ReportViewer from "../input/ReportViewer";
import apiClient from "../../lib/common/apiClient";

function PrintReportButton ( props ) {

    const { id, noLayout, url, title, reportName, reportData, reportOptions, children, ...others } = props;

    const onClick = () => {

        let newWindow;

        let left = ( window.screen.width / 2 ) - ( uiConf.reportWindowWidth / 2 );
        let top = ( window.screen.height / 2 ) - ( uiConf.reportWindowHeight / 2 );

        if ( url ) {

            apiClient
                .get ( url )
                .then ( ( response ) => {
                    
                    let blob = new Blob ( [ response.data ], {
                        type: "text/html;charset=utf-8;"
                    } );
    
                    let url = window.URL.createObjectURL ( blob );
                    newWindow = window.open ( url, "", `width=${uiConf.reportWindowWidth},height=${uiConf.reportWindowHeight}, top=${top}, left=${left}` );

                } );

        } else {

            if ( reportName && reportData ) {

                newWindow = window.open ( "/reportViewer.html", "", `popup=true name=${title} width=${uiConf.reportWindowWidth},height=${uiConf.reportWindowHeight}, top=${top}, left=${left} noscroll=true` );        

                if ( newWindow ) {
        
                    newWindow.onload = function () {
        
                        ReactDOM.render (

                            <ReportViewer
                                id={id}
                                title={title ?? reportName}
                                reportName={reportName}
                                reportData={JSON.stringify ( reportData )}
                                options={reportOptions}
                            />,

                            newWindow.document.getElementById ( 'root' )

                        );
            
                        newWindow.document.title = title;
                        newWindow.focus();
        
                    }
            
                }
                
            }
    
        }

    };

    return (

        <>
            {
                noLayout
                ?
                <ActionButtonLayout
                    color="primary"
                    onClick={onClick}
                    {...others}
                >
                {
                    children &&
                    React.Children.map(children, ( ( child ) => (
                        React.isValidElement ( child )
                        ?
                        React.cloneElement(child, others )
                        :
                        child
                    ) ) )
                }
                </ActionButtonLayout>
                :
                <BasicCrudScreenActionItemLayout>
                    <ActionButtonLayout
                        color="primary"
                        onClick={onClick}
                    >
                    {
                        children &&
                        React.Children.map(children, ( ( child ) => (
                            React.isValidElement ( child )
                            ?
                            React.cloneElement(child, others )
                            :
                            child
                        ) ) )
                    }
                    </ActionButtonLayout>
                </BasicCrudScreenActionItemLayout>
            }
        </>

    );

}

export default PrintReportButton;
