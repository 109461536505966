import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DbLocks ( props ) {

    return <BasicCrudScreenType
        id="DbLocks"
        noCreate
        noEdit
        noDelete
        noExcel
        noFilter
        table="VwDbLocks"
        view="VwDbLocks"
    />

}

export default DbLocks;
