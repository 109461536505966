import React from "react";
import {
    Popover,
    makeStyles,
} from "@material-ui/core";
import DhtmlxColorPicker from "./DhtmlxColorPicker";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "238px",
        height: "254px",
        backgroundColor: theme.palette.background.paper,
    },
}));


function ColorPickerPopUp(props) {

    const classes = useStyles();

    const { open, value, anchorEl, onClose, onChange } = props;

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                square: true
            }}
        >
            <div className={classes.root}>
                <DhtmlxColorPicker id="color-picker" value={value} onChange={onChange} />
            </div>

        </Popover>
    );
}

export default ColorPickerPopUp;
