import FormItemCheckbox from "../input/FormItemCheckbox";
import FormItemCheckboxGroup from "../input/FormItemCheckboxGroup";
import FormItemCode from "../input/FormItemCode";
import FormItemAutocomplete from "../input/FormItemAutocomplete";
import FormItemDate from "../input/FormItemDate";
import FormItemDateTime from "../input/FormItemDateTime";
import FormItemRadio from "../input/FormItemRadio";
import FormItemSelect from "../input/FormItemSelect";
import FormItemSlider from "../input/FormItemSlider";
import FormItemText from "../input/FormItemText";
import FormItemNumber from "../input/FormItemNumber";
import FormItemTime from "../input/FormItemTime";
import FormItemIcon from "../input/FormItemIcon";
import FormItemFile from "../input/FormItemFile";
import FormItemFloat from "../input/FormItemFloat";
import FormItemMonth from "../input/FormItemMonth";
import FormItemColor from "../input/FormItemColor";
import FormItemAutoCompleteSolo from "../input/FormItemAutocompleteSolo";
import FormItemBarcode from "../input/FormItemBarcode";
import FormItemYear from "../input/FormItemYear";

function AutoFormItem(props) {

    const { column } = props;

    let formItemProps = {
        InputLabelProps: {
            style: {
                fontSize: "large",
                fontWeight: "bold"
            },
        },
        ...props
    };

    if (column.formItemType) {

        switch (column.formItemType) {

            case "text":

                return <FormItemText {...formItemProps} />

            case "number":

                return <FormItemNumber {...formItemProps} />

            case "float":

                return <FormItemFloat {...formItemProps} />

            case "date":

                return <FormItemDate {...formItemProps} />

            case "time":

                return <FormItemTime {...formItemProps} />

            case "datetime":

                return <FormItemDateTime {...formItemProps} />

            case "month":

                return <FormItemMonth {...formItemProps} />

            case "year":

                return <FormItemYear {...formItemProps} />

            case "code":

                return <FormItemCode {...formItemProps} />

            case "autocomplete":

                return <FormItemAutocomplete {...formItemProps} />

            case "autocompleteSolo":

                return <FormItemAutoCompleteSolo {...formItemProps} />

            case "select":

                return <FormItemSelect {...formItemProps} />

            case "slider":

                return <FormItemSlider {...formItemProps} />

            case "checkbox":

                return <FormItemCheckbox {...formItemProps} />

            case "checkboxGroup":

                return <FormItemCheckboxGroup {...formItemProps} />

            case "radio":

                return <FormItemRadio {...formItemProps} />

            case "icon":

                return <FormItemIcon {...formItemProps} />

            case "file":

                return <FormItemFile {...formItemProps} />

            case "color":

                return <FormItemColor {...formItemProps} />

            case "barcode":

                return <FormItemBarcode {...formItemProps} />

            default:

                return <FormItemText {...formItemProps} />

        }

    } else {

        if (
            column.type.startsWith("char") ||
            column.type.startsWith("varchar") ||
            column.type.startsWith("text") ||
            column.type.startsWith("tinytext") ||
            column.type.startsWith("mediumtext") ||
            column.type.startsWith("bigint") ||
            column.type.startsWith("longtext")
        ) {

            return <FormItemText {...formItemProps} />

        } else if (
            column.type.startsWith("int") ||
            column.type.startsWith("smallint") ||
            column.type.startsWith("tinyint") ||
            column.type.startsWith("mediumint") ||
            column.type.startsWith("numeric")
        ) {

            return <FormItemNumber {...formItemProps} />

        } else if (
            column.type.startsWith("float") ||
            column.type.startsWith("double") ||
            column.type.startsWith("decimal")
        ) {

            return <FormItemFloat {...formItemProps} />

        } else if (column.type === "date") {

            return <FormItemDate {...formItemProps} />

        } else if (column.type.startsWith("time")) {

            return <FormItemTime {...formItemProps} />

        } else if (column.type.startsWith("datetime")) {

            return <FormItemDateTime {...formItemProps} />

        } else if (column.type.startsWith("timestamp")) {

            return <FormItemDateTime {...formItemProps} />

        }

    }

}

export default AutoFormItem;
