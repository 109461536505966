import i18n from "i18next";

function checkConditionWithMsg ( target, condition ) {


    let result = true;
    let msg = i18n.t ( condition.msg, {
        name : condition.name,
        value: condition.value,
        target: target ? target[condition.name] : undefined
    } );

    switch ( condition.type ) {

        case "conditions":

            switch ( condition.operator ) {

                case "and":

                    {

                        let result1, msg1;
                        let result2, msg2;

                        [ result1, msg1 ] = checkConditionWithMsg ( target, condition.value[0] );
                        [ result2, msg2 ] = checkConditionWithMsg ( target, condition.value[1] );

                        result = result1 && result2;

                        if ( ! result ) {

                            msg = i18n.t ( condition.msg, { name: condition.name, msg1: msg1, msg2: msg2 } );

                        }

                    }

                    break;                

                case "or":

                    {

                        let result1, msg1;
                        let result2, msg2;

                        [ result1, msg1 ] = checkConditionWithMsg ( target, condition.value[0] );
                        [ result2, msg2 ] = checkConditionWithMsg ( target, condition.value[1] );

                        result = result1 || result2;

                        if ( ! result ) {

                            msg =  i18n.t ( condition.msg, { name: condition.name, msg1: msg1, msg2: msg2 } );

                        }

                    }

                    break;

                case "xor":

                    {

                        let result1, msg1;
                        let result2, msg2;

                        [ result1, msg1 ] = checkConditionWithMsg ( target, condition.value[0] );
                        [ result2, msg2 ] = checkConditionWithMsg ( target, condition.value[1] );

                        result = ( result1 ? 1 : 0 ) ^ ( result2 ? 1 : 0 );

                        if ( ! result ) {

                            msg =  i18n.t ( condition.msg, { name: condition.name, msg1: msg1, msg2: msg2 } );

                        }

                    }

                    break;

                case "not":

                    [ result, msg ] = checkConditionWithMsg ( target, condition.value );
                    result = !result;

                    if ( ! result ) {

                        msg =  i18n.t ( condition.msg, { msg: msg } );

                    }

                    break;

                default:

                    break;

            }

            break;

        case "value":

            switch ( condition.operator ) {

                case "lt":

                    result = target[condition.name] < condition.value;
                    break;

                case "le":

                    result = target[condition.name] <= condition.value;
                    break;

                case "ne":

                    result = target[condition.name] !== condition.value;
                    break;

                case "eq":

                    result = target[condition.name] === condition.value;
                    break;

                case "ge":

                    result = target[condition.name] >= condition.value;
                    break;

                case "gt":

                    result = target[condition.name] > condition.value;
                    break;

                case "contain":

                    result = target[condition.name].includes(condition.value);
                    break;

                case "begin with":

                    result = target[condition.name].startsWith(condition.value);
                    break;

                case "end with":

                    result = target[condition.name].endsWith(condition.value);
                    break;

                case "in":

                    result = target[condition.name] in (condition.values);
                    break;

                default:

                    break;

            }

            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: condition.value } );

            }

            break;

        case "regexp":

            let regexp = new RegExp ( condition.regexp );

            result = regexp.test ( target[condition.name] );

            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: condition.value } );

            }

            break;

        case "length":

            switch ( condition.operator ) {

                case "lt":

                    result = target[condition.name].length < condition.value;
                    break;

                case "le":

                    result = target[condition.name].length <= condition.value;
                    break;

                case "eq":

                    result = target[condition.name].length === condition.value;
                    break;

                case "ge":

                    result = target[condition.name].length >= condition.value;
                    break;

                case "gt":

                    result = target[condition.name].length > condition.value;
                    break;

                default:

                    break;

            }
            
            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: condition.value } );

            }

            break;

        default:

            result = true;
            msg = "";

            break;


    }

    return [ result, msg ];

}

export default checkConditionWithMsg;

