import {  useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguUpdateLotInfoButton(props) {
    const { t } = useTranslation();

    const {
        id,
        schema,
        grid,
        refresh,
        onSuccess,
        modifyUrlCallback,
        modifyFormDataCallback,
        checkFormValidationCallback,
        customizeErrorMsgCallback,
        ...others
    } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "update-dialog"}
                mode="edit"
                formId={id + "update-form"}
                title={t(`dialog.title.lot.update`) }
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                modifyUrlCallback={modifyUrlCallback}
                modifyFormDataCallback={modifyFormDataCallback}
                checkFormValidationCallback={checkFormValidationCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                noKeepOpen
            />
        </>
    );
}

export default EguUpdateLotInfoButton;
