/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import MomSysSoDetailProductWithBop from "./MomSysSoDetailProductWithBop";
import MomSysSoProductionProduct from "./MomSysSoProductionProduct";
import MomSysSoProductionMaterial from "./MomSysSoProductionMaterial";

function MomSysMatProduction(props) {

    const [soDetail, setSoDetail] = useState();
    const [product, setProduct] = useState();
    const [, setSoDetailApi] = useState();
    const [, setProductApi] = useState();
    const [, setMaterialApi] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);


    useEffect(() => {

        setProductApi ( ( productApi ) => {

            if ( productApi ) {

                productApi.refresh ();
    
            }

            return productApi;

        } );

    }, [soDetail] );

    useEffect(() => {

        setMaterialApi ( ( materialApi ) => {

            if ( materialApi ) {

                materialApi.refresh ();
    
            }

            return materialApi;

        } );

    }, [product] );

    const onSelectSoDetail = ( soDetail ) => {

        setSoDetail ( soDetail );

    };

    const onSelectProduct = ( product ) => {

        setProduct ( product );

    };

    const onSoDetailApiInitialized = ( soDetailApi ) => {

        setSoDetailApi ( soDetailApi );

    };

    const onProductApiInitialized = ( productApi ) => {

        setProductApi ( productApi );

    };

    const onMaterialApiInitialized = ( materialApi ) => {

        setMaterialApi ( materialApi );

    };

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.5}>

                <MomSysSoDetailProductWithBop
                    onSelect={onSelectSoDetail}
                    onApiInitialized={onSoDetailApiInitialized}                            
                />

                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterWidth }}
                />

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5}>

                            <MomSysSoProductionProduct
                                soDetail={soDetail}
                                onSelect={onSelectProduct}
                                onApiInitialized={onProductApiInitialized}

                            />

                            </ReflexElement>

                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterHeight }}
                        />

                        <ReflexElement flex={0.5}>

                            <MomSysSoProductionMaterial
                                soDetail={soDetail}
                                product={product}
                                onApiInitialized={onMaterialApiInitialized}

                            />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysMatProduction;
