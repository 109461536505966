import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function PopSelfInspectionButtonLayout ( props ) {

    const {createButtonName, ...others} = props;

    const { t } = useTranslation();

    return (
        createButtonName
        ?
        <ActionButtonLayout {...others}>{createButtonName}</ActionButtonLayout>
        :
        <ActionButtonLayout {...others}>{t("buttonName.create")}</ActionButtonLayout>
    );

}

export default PopSelfInspectionButtonLayout;

