/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysDirectShipDetailLotCreateButton from "../input/MomSysDirectShipDetailLotCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import { debounce } from "lodash";


function MomSysDirectShipDetailLot(props) {
    const { onSelectMst, onSelectDetail, onUpdateDetail, momSysDirectShipDetailDataIsEmpty } = props;

    const id = "MomSysDirectShipDetailLot";
    const table = "MomSysDirectShipDetailLot";
    const view = "MomSysVwDirectShipDetailLot";

    const { t } = useTranslation();

    const [momSysDirectShipDetailLotSchema, setMomSysDirectShipDetailLotSchema] = useState();
    const [momSysDirectShipDetailLotGrid, setMomSysDirectShipDetailLotGrid] = useState();
    const [momSysDirectShipDetailLotApi, setMomSysDirectShipDetailLotApi] = useState();
    const [momSysDirectShipDetailLotDataIsEmpty, setMomSysDirectShipDetailLotDataIsEmpty] = useState();
    const [, setMomSysDirectShipDetailLotSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysDirectShipDetailLotSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (momSysDirectShipDetailLotApi) {
            momSysDirectShipDetailLotApi.refresh();
        }
    }, [onSelectDetail?.directShipDetailId]);

    useEffect(() => {
        (async () => {
            if (momSysDirectShipDetailLotGrid) {
                momSysDirectShipDetailLotGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipDetailLotSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipDetailLotSelect();
                        }
                    },10)
                );

                momSysDirectShipDetailLotGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDirectShipDetailLotSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDirectShipDetailLotSelect();
                        }
                    },10)
                );
            }
        })();
    }, [momSysDirectShipDetailLotGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (momSysDirectShipDetailLotApi) {
            momSysDirectShipDetailLotApi.refresh();
        }

        if (onUpdateDetail) {
            onUpdateDetail();
        }
    };

    return (
        <BasicCrudScreenType
            id={id}
            view={view}
            headerTitle={t("term.directShipDetailLot")}
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setMomSysDirectShipDetailLotGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMomSysDirectShipDetailLotApi(api);
            }}
            onLoadData={(data) => {
                setMomSysDirectShipDetailLotDataIsEmpty(data.length < 1 ? true : false);
            }}
            onModifyViewUrl={(url) => `${url}/${onSelectDetail?.directShipDetailId}`}
            noCreate
            noEdit
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysDirectShipDetailLotCreateButton
                            id={id}
                            schema={momSysDirectShipDetailLotSchema}
                            onSelectDetail={onSelectDetail}
                            onSuccess={onSuccess}
                            disabled={onSelectMst?.shipDate || momSysDirectShipDetailDataIsEmpty }
                        />

                        <UpdateRecordButton
                            id={id}
                            schema={momSysDirectShipDetailLotSchema}
                            grid={momSysDirectShipDetailLotGrid}
                            disabled={momSysDirectShipDetailLotDataIsEmpty || onSelectMst?.shipDate}
                            onSuccess={onSuccess}
                            modifyFormDataCallback={(formData, name) => {
                                let numQty = Number(formData.qty);

                                if (name === "mediumUnitQty") {
                                    numQty = Number(formData.mediumUnitQty) / formData.materialMediumUnitQty;
                                } else if (name === "smallUnitQty") {
                                    numQty = Number(formData.smallUnitQty) / formData.materialSmallUnitQty;
                                }

                                formData.qty = numQty;
                                formData.mediumUnitQty = formData.materialMediumUnitQty * numQty;
                                formData.smallUnitQty = formData.materialSmallUnitQty * numQty;
                            }}
                        />

                        <DeleteSingleRecordButton
                            id={id}
                            schema={momSysDirectShipDetailLotSchema}
                            grid={momSysDirectShipDetailLotGrid}
                            makeItemName={(row) => {
                                return `${t("columnName.directShipDetailLotId")}: ${row.directShipDetailLotId}`;
                            }}
                            color="secondary"
                            disabled={momSysDirectShipDetailLotDataIsEmpty || onSelectMst?.shipDate}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </BasicCrudScreenActionItemLayout>
                </>
            )}
        />
    );
}

export default MomSysDirectShipDetailLot;
