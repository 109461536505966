import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },

    dialogTitle : {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    }
}));

function MomSysUglStockTakingMstDialog(props) {
    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,

        title,
        formId,
        platform,
        moveable,
        resizable,
        fullWidth,
        maxWidth,
        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        appendCheckBox,

        ...others
    } = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [instance, setInstance] = useState();
    const [isSelectMaterialForm, setIsSelectMaterialForm] = useState(false);

    const [formSchema, setFormSchema] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const { t } = useTranslation();

    useEffect(() => {

        if (schema) {

            if(isSelectMaterialForm) {

                let schema = {

                    name: "MomSysUglStockTakingMst",
                    type: "BASE TABLE",
                    searchAll: false,

                    columns: [

                        {
                            
                            name: "stockTakingName",
                            type: "varchar(50)",
                            nullable: "NO",
                            key: "PRI",
                            formOrder : 10,
                            required : true,
                            formItemType: "text",
        
                        },
                        {
                            
                            name: "materialCode",
                            type: "varchar(255)",
                            nullable: "NO",
                            required : true,
                            formOrder: 20,
                            formItemType : "autocomplete",
                            codeInfo : {
                                tableName: "MomSysZvwMaterial",
                                nameColumn: ["materialCode","materialName"],
                                valueColumn: "materialCode",
                                useEmptyValue: true,
                                emptyName: "term.none",
                                sortByName: true,
                            },
        
                        },
                        {
                            
                            name: "description",
                            type: "varchar(255)",
                            nullable: "NO",
                            formOrder: 30,
                            formItemType: "text",
        
                        },
        
                        {
                            
                            name: "stockTakingDate",
                            type: "datetime(6)",
                            nullable: "NO",
                            formOrder: 40,
                            formItemType: "date",
        
                        },
                    ]
                }
                setFormSchema(schema);
                
            } else {
                
                setFormSchema(schema);
            }
        }

    }, [schema, isSelectMaterialForm])

    const onCloseDialog = () => {
        setDisabled(true);

        setFormData({})

        if (onClose) {
            onClose();
        }
    };

    const onCancel = () => {

        setFormData({});

        setDisabled(true);

        instance.hide();
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChange = (formData) => {

        if(isSelectMaterialForm) {

            setFormData({...formData, wareHouseCode : undefined });


        } else {

            setFormData({...formData, materialCode : undefined });

        }

        setDisabled(false);
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {

        setKeepOpen((keepOpen) => {

            if (!keepOpen) {
                instance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);

            } else {

                notifySuccess(result.data);
            }

            return keepOpen;
        });

        setDisabled(false);
    };

    const onApiFailure = (reason) => {

        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response
                    ? reason.response.data
                    : JSON.stringify(reason),
            })
        );

        setDisabled(false);
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation(
                "create",
                column,
                column.validation,
                formData[column.name] || ""
            );

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {

        setDisabled(true);

        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning(
                    t("warning.formDataValidationFail", { msg: msg })
                );
                return;
            }
        }


        let url = `/api/MomSysUglStockTakingMst`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }

            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() =>
                                setKeepOpen((keepOpen) => !keepOpen)
                            }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={
                        <span className={classes.label}>
                            {t("term.keepDialogOpen")}
                        </span>
                    }
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle 
                        className = {classes.dialogTitle}
                    >
                        {title}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.keepOpenSwitch}
                                    checked={isSelectMaterialForm}
                                    color="primary"
                                    onChange={() =>
                                        setIsSelectMaterialForm((selectMaterialForm) => !selectMaterialForm)
                                    }
                                    name="checkKeepOpen"
                                    size="small"
                                />
                            }
                            label={
                                <span className={classes.label}>
                                    {t("term.selectMaterialForm")}
                                </span>
                            }
                        />
                    </DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent dividers>
                    <AutoForm
                        id="MomSysUglStockTakingMst"
                        schema={formSchema}
                        mode="create"
                        initialData={formData}
                        onChange={onChange}
                        setInitialDataCallback ={(formData)=>{
                            formData.stockTakingType = 0;
                            formData.stockTakingDate = new Date();
                        }}
                    />

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysUglStockTakingMstDialog;