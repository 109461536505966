/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";

function CopyBomBopDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [srcMaterial, setSrcMaterial] = useState([]);
    const [dstMaterial, setDstMaterial] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };    

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

            setInstance ( ( instance ) => {

                instance.hide();
                return instance;
    
            } );
    
        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

        setInstance ( ( instance ) => {

            instance.hide();
            return instance;

        } );

    };

    const onOk = ( srcMaterial, dstMaterial ) => {

        // build base api url

        let url = `/api/MomSysUdtBillOfProcess/${srcMaterial.materialCode}/${srcMaterial.materialCodeVer}/copy`;

        let apiData = {
            srcMaterialCode: srcMaterial.bopMaterialCode,
            srcMaterialCodeVer: srcMaterial.bopMaterialCodeVer,
            dstMaterialCode: dstMaterial.bopMaterialCode,
            dstMaterialCodeVer: dstMaterial.bopMaterialCodeVer,
        };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( apiData[formItem] !== undefined ) {

                json[formItem] = apiData[formItem];

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        instance.hide();

    };

    const onSrcGridInitialized = ( grid ) => {

        if (grid) {

            grid.selectionChanged.addHandler((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    let material = {...grid.selectedItems[0]};
                    material.materialCode = material.bopMaterialCode;
                    material.materialCodeVer = material.bopMaterialCodeVer;
                    setSrcMaterial(material);

                }

            });

            grid.itemsSourceChanged.addHandler((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    let material = {...grid.selectedItems[0]};
                    material.materialCode = material.bopMaterialCode;
                    material.materialCodeVer = material.bopMaterialCodeVer;
                    setSrcMaterial(material);
                    
                }

            });

        }

    };

    const onDstGridInitialized = ( grid ) => {

        if (grid) {

            grid.selectionChanged.addHandler((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    setDstMaterial(grid.selectedItems[0]);

                }

            });

            grid.itemsSourceChanged.addHandler((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    setDstMaterial(grid.selectedItems[0]);
                    
                }

            });

        }

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.CopyBomBop" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "600px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement>

                        <BasicCrudScreenType
                            id="MomSysVwMaterialsWithBop"
                            headerTitle={t("term.srcMaterial")}
                            table="MomSysVwMaterialsWithBop"
                            view="MomSysVwMaterialsWithBop"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onAppendButtonAtFirst={() =>
                                    <>

                                        <BasicCrudScreenActionItemLayout>

                                            <MomSysBomBopTreeButton color="primary" product={srcMaterial} />

                                        </BasicCrudScreenActionItemLayout>

                                    </>

                                }
                            onInitialized={onSrcGridInitialized}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement>

                        <BasicCrudScreenType
                            id="MomSysVwMaterialsWithoutBop"
                            headerTitle={t("term.dstMaterial")}
                            table="MomSysVwMaterialsWithoutBop"
                            view="MomSysVwMaterialsWithoutBop"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={onDstGridInitialized}
                        />

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("buttonName.copy")]}
                buttonDisabled={[!srcMaterial || !dstMaterial, false]}
                onOk={() => onOk(srcMaterial, dstMaterial)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CopyBomBopDialog;
