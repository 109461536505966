import { useState, useEffect } from "react";

import AutoFilterFormItem from "./AutoFilterFormItem";
import AutoFilterFormValueEqualLayout from "../layout/AutoFilterFormValueEqualLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";

function AutoFilterFormValueEqual ( props ) {

    const { id, tabIndex, column, filterValue, onChange } = props;

    const [ filter, setFilter ] = useState(

        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "0", value: "" },
            condition2: { operator: "", value: "" }
        
        }

    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    const onChangeValue = ( value ) => {

        if ( value === "" ) {

            setFilter ( ( filter ) => {
            
                let newFilter = {...filter };
    
                newFilter.condition1.value = value;
                newFilter.condition1.operator = "";
    
                if ( onChange ) {
    
                    setTimeout ( () => onChange ( newFilter ), 0 );
    
                }
    
                return newFilter;
    
            } );
    
        } else {

            setFilter ( ( filter ) => {
            
                let newFilter = {...filter };
    
                newFilter.condition1.value = value;
                newFilter.condition1.operator = "0";
    
                if ( onChange ) {
    
                    setTimeout ( () => onChange ( newFilter ), 0 );
    
                }
    
                return newFilter;
    
            } );
    
        }

    };

    return (

        <AutoFilterFormValueEqualLayout>

            <AutoFilterFormValueItemLayout column={column}>

                <AutoFilterFormItem                    
                            id={id+"-condition1-value"}
                            column={column}
                            value={filter.condition1.value}
                            autoFocus={tabIndex === 1}
                            onChange={onChangeValue}
                />

            </AutoFilterFormValueItemLayout>

        </AutoFilterFormValueEqualLayout>

    );

}

export default AutoFilterFormValueEqual;

