import DialogContentLayout from "../layout/DialogContentLayout";

function DialogContent ( props ) {

    const { moveable, ...others } = props;

    return (
        <DialogContentLayout {...others} />
    )
}

export default DialogContent;
