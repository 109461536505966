import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function MomSysCancelProduceButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.cancelProduce")}</ActionButtonLayout>;

}

export default MomSysCancelProduceButtonLayout;

