import React from "react";
import Button from "../input/Button";

function ActionButtonLayout ( props ) {

    const { children, ...others } = props;

    return (

        <Button color="primary" {...props}>
        {
                children &&
                React.Children.map(children, ( ( child ) => (
                        React.isValidElement ( child )
                        ?
                        React.cloneElement(child, others )
                        :
                        child
                ) ) )


        }
        </Button>

    );

}

export default ActionButtonLayout;

