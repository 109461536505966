/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import authProfileState from "../../states/authProfileState";
import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import logout from "../../lib/auth/logout";

import Avatar from "../presentation/Avatar";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles ( () => ( {
    iconButton: {
        padding: 4,
    },
    avatar: {
        width: 32,
        height: 32,
    }
} ) );


function ProfileButton ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    const [ anchor, setAnchor ] = useState ();
    const [ openMenu, setOpenMenu ] = useState (false);
    const [ openDialog, setOpenDialog ] = useState (false);

    const [authProfile, setAuthProfile ] = useRecoilState( authProfileState );
    const setNotification = useSetRecoilState(notificationState);

    const [ schema, setSchema ] = useState();
    const [ profile, setProfile ] = useState();

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "User" );

            if ( mounted ) {
            
                schema.columnMap = {};

                for ( const column of schema.columns ) {
    
                    schema.columnMap[column.name] = column;
    
                }
    
                schema.name = "User";
    
                let passwordCheckColumn =  { ...schema.columnMap["password"], name: "passwordCheck", formOrder: 3, doNotSubmitOnApi: true };
    
                schema.columns.push ( passwordCheckColumn );
                schema.columnMap["passwordCheck"] = passwordCheckColumn;
    
                schema.formGroups = [
                    "form.userForm.groupName.essential",
                    "form.userForm.groupName.name",
                    "form.userForm.groupName.password"
                ];
    
                schema.columnMap["password"].formGroup = 2;
                schema.columnMap["passwordCheck"].formGroup = 2;
                schema.columnMap["passwordCheck"].required = false;
                schema.columnMap["passwordCheck"].validation = undefined;
    
                schema.columnMap["roleId"].readonly = true;
            
                setSchema ( schema );

            }
    
        } ) ();

        return ( () => {
            mounted = false;
        } );
    
    }, [] );

    const checkItemValidation = ( formData, column ) => {

        if ( column.name === "passwordCheck" ) {

            if ( ( formData["password"] || "" ) !== ( formData["passwordCheck"] || "" ) ) {

                return [ false, t( "error.passwordNotMatch" ) ];

            }

        }

        return [ true, "" ];

    }

    const onUpdateSuccess = ( msg ) => {

        setNotification( {

            severity: "ssuccess",
            msg: msg,
            open: true,

        } );

    };

    const onOpenMenu = ( event ) => {

        setAnchor ( event.currentTarget );
        setOpenMenu ( true );

    };

    const onCloseMenu = () => {

        setOpenMenu ( false );
        setAnchor ( null );

    };
    const onOpenDialog = () => {

        onCloseMenu ();

        ( async () => {

            try {

                let profile = await getSpecific ( "Profile" );
                setProfile ( profile );

            } catch ( reason ) {

                setNotification( {

                    severity: "error",
                    msg: reason.response.data,
                    open: true,

                } );

            }

            setOpenDialog ( true );

        } ) ();

    };

    const onCloseDialog = () => {

        setOpenDialog ( false );

    };

    const onLogout = () => {
       
        setAuthProfile ( ( profile ) => {
            
            ( async () => {

                await logout( profile );
                setAuthProfile ({ loggedIn: false });
    
            } ) ();
    
            return profile;

        } );

    };

    return (

        <>

        <Tooltip title={t("toolTips.profile")}>
            <IconButton
                color="inherit"
                className={classes.iconButton}
                onClick={onOpenMenu}
            >
                <Avatar
                    photo={authProfile.photo}
                    email={authProfile.email}
                    className={classes.avatar}
                />
            </IconButton>
        </Tooltip>

        <Menu
            id="profile-menu"
            anchorEl={anchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={openMenu}
            onClose={onCloseMenu}
            PaperProps={{
                square: true
            }}
        >

            <MenuItem onClick={onOpenDialog}>{t("menu.Profile")}</MenuItem>
            <MenuItem onClick={onLogout}>{t("menu.Logout")}</MenuItem>

        </Menu>

        <AutoFormDialog
            id={"profile-update-dialog"}
            mode="edit"
            formId={"profile-update-form"}
            title={ t ( `dialog.title.Profile.update` ) }
            schema={schema}
            initialData={profile}
            open={openDialog}
            onClose={onCloseDialog}
            onSuccess={onUpdateSuccess}
            checkItemValidationCallback={checkItemValidation}
        />

        </>

    );

}

export default ProfileButton;


