import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import notificationState from "../../states/notificationState";

import layoutConf from "../../config/layoutConf";

import MomSysShipButton from "../input/MomSysShipButton";
import { CellType } from '@grapecity/wijmo.grid';
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import MomSysShipDetail from "./MomSysShipDetail";
import SaveToExcelButton from "../input/SaveToExcelButton";
import {debounce} from "lodash";

function MomSysProductShip ( props ) {

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [shipGrid, setShipGrid ] = useState ();

    const [shipSchema , setShipSchema] = useState();

    const [shipRefresh, setShipRefresh ] = useState ();

    const [onSelectMst, setOnSelectMst] = useState();
    const [ , setNotification ] = useRecoilState(notificationState);

    const [ isEmpty, setIsEmpty ] = useState();

    const [ data, setData ] = useState();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);
    
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);    
        handleResize(); 

        let mounted = true;

        ( async () => {

            let schema = await getSchema("MomSysShip");

            if ( mounted ) {

                setShipSchema(schema);

            }

        } ) ();

        return ( () => {

            mounted = false;
            window.removeEventListener("resize", handleResize);

        } );

      },[]);

      useEffect(() => {

        ( async () => {
            
            if (shipGrid) {

                shipGrid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0]);

                    } else {

                        setOnSelectMst([]);
                    } 

                },10));
                
                shipGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {
                    
                        setOnSelectMst(grid.selectedItems[0]);

                    } else {
                        
                        setOnSelectMst([]);
                    }
                },10));
            }

        } ) ()


      },[shipGrid]);


    const exportFormatItem = ( args ) => {

        let { panel, col, row, xlsxCell } = args;

        if ( panel.cellType === CellType.Cell ) {

            switch ( panel.columns[col].binding ) {

                case "approvalState":

                    switch (data[row][panel.columns[col].binding]) {

                        case 1:

                            xlsxCell.value = t ("selectItems.approvalState.approvalPending")

                            break;

                        case 2:

                            xlsxCell.value = t ("selectItems.approvalState.partiallyApproved")

                            break;

                        case 3:

                            xlsxCell.value = t ("selectItems.approvalState.totallyApproved")

                            break;
                        
                        case 4:

                            xlsxCell.value = t ("selectItems.approvalState.approvalRejected")

                            break;

                        case 5:

                            xlsxCell.value = t ("selectItems.approvalState.approvalWithdrawn")

                            break;

                        case 6:

                            xlsxCell.value = t ("selectItems.approvalState.approvalCancelled")

                            break;

                        default:
                            break;
                    }

                    break;

                default:

                    break;

            }

        }

    }

    const notifySuccess = (msg) => {

        setNotification({
    
          severity: "success",
          msg: msg,
          open: true,
    
        });
      };
    
      const onNotifySuccess = (msg) => {
    
        notifySuccess(msg);

      };


    const onShipInitialized = ( grid ) => {

        grid.selectionMode = "Row";
        
        setShipGrid ( grid );
        
    }
    

    const onShipApiInitialized = ( api ) => {

        setShipRefresh ( api );

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        
        return ` ${response.data} `;
    
    }

    const onAppendButtonInFrontOfSearch = () => {

        return (

            <>
            <BasicCrudScreenActionItemLayout>
                
                <MomSysShipButton
                    id = "MomSysShipButton"
                    schema = {shipSchema}
                    refresh = {shipRefresh}
                    customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    onSuccess = {onNotifySuccess}
                
                />

                <SaveToExcelButton
                    id="EguProductShip"
                    grid={shipGrid}
                    view="MomSysVwShip"
                    color="primary"
                    exportFormatItem={exportFormatItem}
                    disabled={isEmpty}
                />
            </BasicCrudScreenActionItemLayout>

            </>

        );

    }

    const onUpdateMaster = () => {

        setShipGrid ( ( grid ) => {

            if ( grid ) {

                ( async () => {

                    let shipMst = await getSpecific("MomSysVwShip", grid.selectedItems[0].shipId );
                    grid.selectedItems[0].shipState = shipMst.shipState;
                    grid.refresh(false);
    
                } ) ();

            }

            return grid;

        })

    }

    const onLoadData = ( data ) => {

        setData ( data );

        setIsEmpty(data.length < 1 ? true : false)

    }

    return (
        <div style={{ height: height }}>

                <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType
                        id="MomSysShip"
                        table = "MomSysShip"
                        view="MomSysVwShip"
                        noCreate
                        noDelete
                        noEdit
                        noExcel
                        headerTitle={t("term.ship")}
                        onLoadData={onLoadData}
                        onInitialized={onShipInitialized}
                        onApiInitialized = {onShipApiInitialized}
                        onAppendButtonInFrontOfSearch = {onAppendButtonInFrontOfSearch}
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    {
                        onSelectMst ?

                            <ReflexElement>

                                <MomSysShipDetail
                                    onSelectMst = {onSelectMst}
                                    onUpdateMaster = {onUpdateMaster}
                                    isEmpty = {isEmpty}
                                    onSuccess = {onNotifySuccess}
                                />

                            </ReflexElement>
                        :
                        <></>
                    }
                </ReflexElement>
            </ReflexContainer>
        </div>
    )

}

export default MomSysProductShip;