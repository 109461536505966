import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import apiClient from "../../lib/common/apiClient";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function EguOutSourcingAllShipButton(props) {
    const { id, schema, outSourcingShipMst, refresh, onSuccess, onFailure, onUpdateMaster, ...others } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setOpen(true);

        setConfirmMsg(t("dialog.msg.confirm.outSourcingAllShip", { outSourcingId: outSourcingShipMst.outSourcingId }));

        setConfirmData(outSourcingShipMst);
    };

    const onOutSourcingAllShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const onOk = (data) => {
        let url;

        url = `/api/${schema.name}/all`;

        let form = new FormData();

        let deleted = {};

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(data));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                onOutSourcingAllShipSuccess(result.data);
            })

            .catch((reason) => {
                onFailure(
                    t("error.outSourcingAllShip", { outSourcingId: outSourcingShipMst.outSourcingId }) +
                        ` : ${reason.response ? reason.response.data : JSON.stringify(reason)}`
                );
            });
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-all-ship-button"}
                onClick={onOpen}
                createButtonName={"outSourcingAllShip"}
                {...others}
            />
            <ConfirmDialog
                id={id + "-all-outSourcing-ship-confirm-dialog"}
                title={t("dialog.title.EguOutSourcingShipDetail.allShip")}
                open={open}
                msg={confirmMsg}
                onClose={onClose}
                onCancel={onClose}
                data={confirmData}
                onOk={onOk}
            />
        </>
    );
}

export default EguOutSourcingAllShipButton;
