import { useState, useEffect } from 'react';

import EguProductionStatusTable from './EguProductionStatusSummaryTable';

function EguProductionStatusSummaryPage ( props ) {

    const { productionStatus, onComplete } = props;

    const [, setTimer] = useState();

    useEffect ( () => {

        if ( productionStatus ) {

            setTimer ( ( timer ) => {

                if ( ! timer ) {
    
                    let newTimer = setTimeout ( () => {

                        if ( onComplete ) {
    
                            setTimer ( undefined );
                            onComplete ( productionStatus.start + productionStatus.length, productionStatus.length, productionStatus.interval );
    
                        }
    
                    }, productionStatus.interval * 1000 );
    
                    return newTimer;
    
                };
    
                return timer;
    
            } );

        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productionStatus] );

    return <EguProductionStatusTable data={productionStatus?.data} />;

}

export default EguProductionStatusSummaryPage;
