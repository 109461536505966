/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataGrid from "../input/DataGrid";
import AutoDataGridColumn from "./AutoDataGridColumn";

function AutoDataGrid ( props ) {

    const { t } = useTranslation ();

    const { id, schema, onUpdatedView, onInitialized, children, ...others } = props;
    
    const [ columnGroups, setColumnGroups ] = useState ();
    const [ grid, setGrid ] = useState ();
    const [ orderedColumns, setOrderedColumns ] = useState([]);
    const [ , setStorageKey ] = useState();
    const [ , setColumnMap ] = useState ();

    const convertColumnGroups = ( gridColumnGroups, schemaColumnGroups, columnMap ) => {

        let stack = [];

        stack.push ( { grid: gridColumnGroups, schema: schemaColumnGroups } );

        while ( stack.length > 0 ) {

            let { grid, schema } = stack.pop ();

            grid.binding = schema.name;

            let column = columnMap[schema.name];

            if ( column ) {

                if ( column.grid ) {

                    if ( column.grid.width !== undefined ) {

                        grid.width = column.grid.width;

                    }

                }

            }

            if ( schema.doNotTranslate ) {

                grid.header = schema.name;

            } else if (schema?.grid?.header) { 

                grid.header = schema?.grid?.header
                
            } else {

                grid.header = t ( `columnName.${schema.displayName || schema.name}` );

            }

            grid.name = schema.name;

            grid.allowDragging = false;
    
            if ( schema.dataType ) {

                grid.dataType = schema.dataType;

            }

            if (schema.align) {

                grid.align = schema.align;

            }

            if (schema?.grid?.format) {

                grid.format = schema?.grid?.format;

            }

            if ( schema.columns ) {
    
                grid.align = "center";
                grid.columns = [];
    
                for ( let column of schema.columns ) {
    
                    let child = {};

                    grid.columns.push ( child );
                    stack.push ( { grid: child, schema: column } );
    
                }
        
            }
    
        }
    
    };

    useEffect ( () => {

        let orderedColumns = schema ? schema.columns.slice().filter ( ( column ) => ( column.grid ? ! column.grid.hide : true ) ) : [];

        // make column map;

        let columnMap = {};

        for ( const column of orderedColumns ) {

            columnMap[column.name] = column;

        }
        
        setColumnMap ( columnMap );

        if ( schema && grid ) {

            let storageKey = `grid-${id}-layout`;

            setStorageKey ( storageKey );

            // restore column layout

            let layoutString = localStorage.getItem ( storageKey );

            let layout = JSON.parse ( layoutString );

            if ( layout ) {

                let layoutColumns = layout.columns;

                for ( const index in layoutColumns ) {

                    let column = columnMap[layoutColumns[index].name];

                    if ( column ) {

                        if ( column.grid ) {

                            column.grid.width = layoutColumns[index].width;
                            column.grid.columnOrder = index;
                            column.grid.visible = layoutColumns[index].visible;
    
                        } else {

                            column.grid = {};
                            column.grid.width = layoutColumns[index].width;
                            column.grid.columnOrder = index;
                            column.grid.visible = layoutColumns[index].visible;

                        }

                    }

                }
                    
            }

            if ( schema.columnGroups ) {

                let columnGroups = [];

                for ( let schemaColumnGroup of schema.columnGroups ) {

                    let columnGroup = {};

                    convertColumnGroups ( columnGroup, schemaColumnGroup, columnMap );

                    columnGroups.push ( columnGroup );

                }

                setColumnGroups ( columnGroups );

            }

        }

        setOrderedColumns ( orderedColumns );

    }, [grid, schema] );

    const saveLayout = ( grid ) => {

        if ( grid.columnLayout ) {

            let layout = JSON.parse ( grid.columnLayout );

            if ( layout.columns.length > 0 ) {

                setStorageKey ( ( storageKey ) => {

                    localStorage.setItem ( storageKey, grid.columnLayout );
    
                    return ( storageKey );
    
                })
    
            }

        }

    };

    const onAutoGridInitialized = ( grid ) => {

        grid.headersVisibility = "Column";

        grid.formatItem.addHandler(function (grid, event) {

            if (event.panel === grid.columnHeaders) {

                setColumnMap ( ( columnMap ) => {

                    var col = grid.columns[event.col];

                    if ( columnMap[grid.columns[event.col].name]?.doSearch ) {
                        event.cell.innerHTML = '<span class="do-search"></span>' + event.cell.innerHTML;
                    }

                    if ( columnMap[grid.columns[event.col].name]?.filter ) {
                        event.cell.innerHTML = '<span class="do-filter"></span>' + event.cell.innerHTML;
                    }
                    
                    // 오름차순
                    if (col.currentSort === "+") {
                        event.cell.innerHTML = '<span class="wj-glyph-up"></span>' + event.cell.innerHTML?.replace('<span class="wj-glyph-up"></span>','');
                        //내림차순이면
                    } else if (col.currentSort === "-") {
                        event.cell.innerHTML =
                            '<span class="wj-glyph-down"></span>' + event.cell.innerHTML?.replace('<span class="wj-glyph-down"></span>','');
                    }
                    
                    return columnMap;
    
                } );
    
            }

        });

        grid.copying.addHandler ( ( grid, event ) => {

            setColumnMap ( ( columnMap ) => {

                let column = columnMap[grid.columns[event.col].name];

                let value = grid.getCellData( event.row, event.col ) 

                if ( column.grid.type === 'select' || column.grid.type === 'approvalState' ) {

                    let found = column.selectItems.find ( ( item ) => parseInt ( item.value ) === parseInt ( value ) );
                    
                    value = found ? t( found.name ) : value;

                }

                navigator.clipboard.writeText  ( value );
    
                return columnMap;

            } );

            return false;

        }) ;

        setGrid ( grid );

        if ( onInitialized ) {

            onInitialized ( grid );

        }

    };

    const onDataGridUpdatedView = ( grid, e ) => {

        if ( grid ) 
        {

            setTimeout ( () => {

                saveLayout ( grid );
                    
                if ( onUpdatedView ) {
    
                    onUpdatedView ( grid, e );
    
                }
    
            }, 0 )
    
        }

    };

    return (

        <>
            <DataGrid id={id} schema={schema} columnGroups={columnGroups} onInitialized={onAutoGridInitialized} onUpdatedView={ ( e ) => onDataGridUpdatedView ( grid, e ) } {...others}>

                {

                    orderedColumns
                        .slice()
                        .sort( ( columnA, columnB ) => ( ( columnA.grid && columnB.grid ) ? columnA.grid.columnOrder - columnB.grid.columnOrder : 0 ) )
                        .map ( ( column ) => <AutoDataGridColumn key={id + column.name} schema={column} /> )

                }

            </DataGrid>
        </>

    );
}

export default AutoDataGrid;

