import { FormControl, FormHelperText, InputLabel, Slider } from "@material-ui/core";

function FormItemSlider ( props ) {

    const { id, className, column, error, helperText, InputLabelProps, onChange, ...others } = props;

    const sliderProps = {
        valueLabelDisplay: "auto",
        onChange: ( event, newValue ) => {
            onChange ( newValue );
        },
        ...others
    };

    if ( column.displayEmpty ) {

        sliderProps.displayEmpty = true;

    }

    if ( column.min ) {
        sliderProps.min = column.min;
    }

    if ( column.max ) {
        sliderProps.max = column.max;
    }

    if ( column.valueLabelDisplay ) {
        sliderProps.valueLabelDisplay = column.valueLabelDisplay;
    }

    if ( column.style ) {
        sliderProps.style = column.style;
    }

    return (
        <FormControl className={className} error={error} required={column.required} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                style={{ marginTop: "17px" }}
                {...InputLabelProps}
            >
                { column.name }
            </InputLabel>
            <Slider
                color={error?"secondary":"primary"}
                style={{ marginTop: "35px" }}
                {...sliderProps} />
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FormItemSlider;
