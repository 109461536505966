/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useEffect, useState } from "react";
import { DataMap } from '@grapecity/wijmo.grid';
import { AutoComplete } from '@grapecity/wijmo.input';
import getList from '../../../lib/api/getList';

function DataGridDataMapColumn ( props ) {

    const { schema, ...others } = props;

    const [dataMap, setDataMap] = useState();

    const [editor, setEditor] = useState();

    const [data, setData] = useState([]);

    useEffect(() => {

        (async () => {

            let info;

            if(schema.dataMapInfo.tableName) {

                let result = await getList(schema.dataMapInfo.tableName);

                info = result.data;

            } else {

                info = schema.dataMapInfo.items;

            }
        
            setData(info);
        
        })();
        
    }, [schema.dataMapInfo])

    useEffect ( () => {

        let dataMap = new DataMap(data, schema.dataMapInfo.selectedValuePath, schema.dataMapInfo.displayMemberPath);

        if(schema.dataMapInfo.editor === "autoComplete") {
            let editor = new AutoComplete(document.createElement('div'), {
                itemsSource: data,
                ...schema.dataMapInfo.editorProps,
            })
            setEditor(editor);
        }

        setDataMap(dataMap);

    }, [data] );

    return (

        <DataGridColumn 
            schema={schema} 
            dataMap={dataMap} 
            editor={editor}
            dataMapEditor={schema.dataMapInfo.dataMapEditor} 
            {...others} 
        />

    );

}

export default DataGridDataMapColumn;

