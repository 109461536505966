function getApiClientConf () {

    return (
    
        {
            commonOptions: {
                baseURL: ( process.env.NODE_ENV === 'production' ||  process.env.NODE_ENV === 'staging' ) ? "/" : "http://localhost:8080",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json"
                }
            },
            methodOptions: {
                get : {
                    headers: {
                    },
                    withCredentials: true
                },
                post : {
                    headers: {
                    },
                    withCredentials: true
                },
                put : {
                    headers: {
                    },
                    withCredentials: true
                },
                patch : {
                    headers: {
                    },
                    withCredentials: true
                },
                del : {
                    headers: {
                    },
                    withCredentials: true
                },
                delete : {
                    headers: {
                    },
                    withCredentials: true
                },
                head : {
                    headers: {
                    }
                }
            },
            pathOptions: {

                // per path options here

            }
        }

    );

}

let apiClientConf = getApiClientConf();

module.exports = apiClientConf;