import { useState } from "react";
import { useTranslation } from "react-i18next";

import MomSysCancelProduceButtonLayout from '../layout/MomSysCancelProduceButtonLayout ';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysCancelProduceButton (props) {

    const {id, onSelect, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others} = props;

    const [ open, setOpen ] = useState(false);

    const { t } = useTranslation();
    
    const onOpen = () => {

        setOpen ( true );
    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCancelSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };

    const modifyUrlCallback = (url) => {

        let modifiedUrl = `/api/Production/${onSelect.lotNo}/cancel`;
 
        return modifiedUrl;
 
    }

     const setInitialDataCallback = ( formData ) => {
            
        if ( !formData ) {

            formData = {};
            
        }

        formData.lotNo = onSelect.lotNo;

    }

    return (
        <>
        <MomSysCancelProduceButtonLayout id = {id+"-cancelProduce-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"cancelProduce-dialog"}
                mode="create"
                formId={id+"cancelProduce-form"}
                title={schema ? t(`dialog.title.${schema.name}.${'cancelProduce'}`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCancelSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback ={setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    )

}

export default MomSysCancelProduceButton;