import { Route, Redirect, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import rootRedirectPathState from "../../states/rootRedirectPathState";

import MenuItemManage from "../screens/MenuItemManage";
import MenuSetManage from "../screens/MenuSetManage";
import TopMenuItemManage from "../screens/TopMenuItemManage";
import SubMenuItemManage from "../screens/SubMenuItemManage";
import UserManage from "../screens/UserManage";
import RoleManage from "../screens/RoleManage";
import RoleSet from "../screens/RoleSet";
import RoleSetDetail from "../screens/RoleSetDetail";
import ApprovalTypeManage from "../screens/ApprovalTypeManage";
import ApproverManage from "../screens/ApproverManage";
import ApprovalPathManage from "../screens/ApprovalPathManage";
import ApprovalHistory from "../screens/ApprovalHistory";
import ApprovalPathDetailManage from "../screens/ApprovalPathDetailManage";
import ItemUnit from "../screens/ItemUnit";
import ItemType from "../screens/ItemType";
import ItemSpec from "../screens/ItemSpec";
import Item from "../screens/Item";
import Vendor from "../screens/Vendor";
import Customer from "../screens/Customer";
import Po from "../screens/Po";
import So from "../screens/So";
import EstimateMgmt from "../screens/EstimateMgmt";
import Shipment from "../screens/Shipment";
import ReturnedItem from "../screens/ReturnedItem";
import WorkerPerformanceManage from "../screens/WorkerPerformanceManage";
import ProductPerformanceManage from "../screens/ProductPerformanceManage";
import MaterialIoManage from "../screens/MaterialIoManage";
import MaterialInventoryManage from "../screens/MaterialInventoryManage";
import ConsumablesIoManage from "../screens/ConsumablesIoManage";
import ConsumablesInventoryManage from "../screens/ConsumablesInventoryManage";
import ProductInOutput from "../screens/ProductInOutput";
import ProductInventory from "../screens/ProductInventory";
import AccessControlTarget from "../screens/AccessControlTarget";
import AccessControlRule from "../screens/AccessControlRule";
import Tenant from "../screens/Tenant";
import DbConf from "../screens/DbConf";
import Namespace from "../screens/Namespace";
import Translation from "../screens/Translation";
import TenantLogo from "../screens/TenantLogo";
import TenantFileTemplateMgmt from "../screens/TenantFileTemplateMgmt";
import TenantReportFile from "../screens/TenantReportFile";
import SupportedLanguage from "../screens/SupportedLanguage";
import MomSysDecisionCode from "../screens/MomSysDecisionCode";

import MenuRedirector from "../functional/MenuRedirector";

/* Mom Sys Screens */
import MomSysMstProcess from "../screens/MomSysMstProcess";
import MomSysBomBopInfo from "../screens/MomSysBomBopInfo";

import MomSysUdtLineNo from "../screens/MomSysUdtLineNo";
import MomSysUdtWorkStation from "../screens/MomSysUdtWorkStation";
import MomSysUdtWareHouse from "../screens/MomSysUdtWareHouse";
import WarehouseCategory from "../screens/WarehouseCategory";
import MomSysUdtCompanyManager from "../screens/MomSysUdtCompanyManager";
import MomSysUdtMaterialType from "../screens/MomSysUdtMaterialType";
import MomSysMstMaterialInfo from "../screens/MomSysMstMaterialInfo";
import MomSysMachineMainType from "../screens/MomSysMachineMainType";
import MomSysMachine from "../screens/MomSysMachine";
import MomSysMachineMain from "../screens/MomSysMachineMain";
import MomSysUglOrderBuyerMst from "../screens/MomSysUglOrderBuyerMst";
import MomSysUglOrderPurchaseMst from "../screens/MomSysUglOrderPurchaseMst";
import MomSysCompanyCollection from "../screens/MomSysCompanyCollection";
import MomSysSales from "../screens/MomSysSales";
import MomSysCollections from "../screens/MomSysCollections";
import MomSysCollectionsReport from "../screens/MomSysCollectionsReport";
import MomSysMaterialError from "../screens/MomSysMaterialError";
import MomSysMaterialInput from "../screens/MomSysMaterialInput";
import MomSysMaterialOutSourcing from "../screens/MomSysMaterialOutSourcing";
import MomSysMaterialBackShip from "../screens/MomSysMaterialBackShip";
import MomSysMergeSplitHistory from "../screens/MomSysMergeSplitHistory";
import MomSysProductionErrorHistory from "../screens/MomSysProductionErrorHistory";
import MomSysLotStateChange from "../screens/MomSysLotStateChange";
import MomSysBopHistory from "../screens/MomSysBopHistory";
import MomSysBomHistory from "../screens/MomSysBomHistory";
import MomSysSoProduction from "../screens/MomSysSoProduction";
import MomSysMatProduction from "../screens/MomSysMatProduction";
import MomSysStockTakingMst from "../screens/MomSysStockTakingMst";
import MomSysProductionStatus from "../screens/MomSysProductionStatus";
import MomSysPurchase from "../screens/MomSysPurchase";
import MomSysExpense from "../screens/MomSysExpense";
import MomSysExpenseReport from "../screens/MomSysExpenseReport";
import MomSysProductStatus from "../screens/MomSysProductStatus";
import MomSysMaterialStatus from "../screens/MomSysMaterialStatus";
import MomSysCodeGenerate from "../screens/MomSysCodeGenerate";
import MomSysLotTransHistory from "../screens/MomSysLotTransHistory";
import MomSysLotTrace from "../screens/MomSysLotTrace";
import MomSysProductionCancelHistory from "../screens/MomSysProductionCancelHistory";
import MomSysMaterialByCompany from "../screens/MomSysMaterialByCompany";
import MomSysProcessTime from "../screens/MomSysProcessTime";
import MomSysProductionTime from "../screens/MomSysProductionTime";
import MomSysProcessReport from "../screens/MomSysProcessReport";
import MomSysProductionReport from "../screens/MomSysProductionReport";
import MomSysOwnCompanyManager from "../screens/MomSysOwnCompanyManager";
import MomSysCollectionsDetail from "../screens/MomSysCollectionsDetail";
import DefaultOption from "../screens/DefaultOption";
import RoleSetOption from "../screens/RoleSetOption";
import TenantOption from "../screens/TenantOption";
import EguMaterialFakeInput from "../screens/EguMaterialFakeInput";
import TenantTranslation from "../screens/TenantTranslation";
import RoleSetTranslation from "../screens/RoleSetTranslation";
import TranslationValue from "../screens/TranslationValue";
import MomSysSalesCollections from "../screens/MomSysSalesCollections";
import MomSysPurchaseExpense from "../screens/MomSysPurchaseExpense";
import MomSysProductStockTakingMst from "../screens/MomSysProductStockTakingMst";
import MomSysMaterialStockTakingMst from "../screens/MomSysMaterialStockTakingMst";
import MomSysProductInputStatus from "../screens/MomSysProductInputStatus";
import InBox from "../screens/InBox";
import MsgSent from "../screens/MsgSent";
import Trash from "../screens/Trash";
import MomSysShipment from "../screens/MomSysShipment";
import MomSysDelivery from "../screens/MomSysDelivery";
import EguUglOrderBuyerMst from "../screens/EguUglOrderBuyerMst";
import EguUglOrderPurchaseMst from "../screens/EguUglOrderPurchaseMst";
import MomSysBackShip from "../screens/MomSysBackShip";
import EguMaterialStatus from "../screens/EguMaterialStatus";
import EguProductStatus from "../screens/EguProductStatus";
import WarehouseStockStatus from "../screens/WarehouseStockStatus";
import EguProductShip from "../screens/EguProductShip";
import MomSysProductShip from "../screens/MomSysProductShip";
import MomSysDirectShip from "../screens/MomSysDirectShip";
import EguDefectiveStatus from "../screens/EguDefectiveStatus";
import EguSelfInspection from "../screens/EguSelfInspection";
import EguInputInspection from "../screens/EguInputInspection";
import MomSysNoOrderPurchaseInput from "../screens/MomSysNoOrderPurchaseInput";

import EguPopNotice from "../screens/EguPopNotice";
import EguPopProductManage from "../screens/EguPopProductManage";
import EguPopTemp from "../screens/EguPopTemp";
import MomSysNotice from "../screens/MomSysNotice";
import MomSysPopNotice from "../screens/MomSysPopNotice";
import ProductionPlan from "../screens/ProductionPlan";
import EguMaterialInputStatus from "../screens/EguMaterialInputStatus";
import EguItemInfo from "../screens/EguItemInfo";
import EguProductSample from "../screens/EguProductSample";
import EguLotStateChange from "../screens/EguLotStateChange";
import EguMergeSplitHistory from "../screens/EguMergeSplitHistory";
import MomSysSalesAnalysis from "../screens/MomSysSalesAnalysis";
import EguSubMaterialSample from "../screens/EguSubMaterialSample";
import EguIngredientRegistration from "../screens/EguIngredientRegistration";
import MomSysPurchaseAnalysis from "../screens/MomSysPurchaseAnalysis";
import MomSysWageRate from "../screens/MomSysWageRate";
import EguOutSourcingPurchase from "../screens/EguOutSourcingPurchase";
import EguOutSourcingShip from "../screens/EguOutSourcingShip";
import EguOutSourcingFakeInput from "../screens/EguOutSourcingFakeInput";
import EguOutSourcingInputInspection from "../screens/EguOutSourcingInputInspection";
import EguOutSourcingMaterialReturn from "../screens/EguOutSourcingMaterialReturn";
import VwPerformanceMetric from "../screens/VwPerformanceMetric";
import HolidayManage from "../screens/Holiday";
import AdditionalWorkDayManage from "../screens/AdditionalWorkDay";
import WorkDayManage from "../screens/WorkDay";
import WorkerDayOfWeekCapacityManage from "../screens/WorkerDayOfWeekCapacity";
import WorkerDateCapacityManage from "../screens/WorkerDateCapacity";
import MachineDateCapacityManage from "../screens/MachineDateCapacity";
import DefaultWorkerDayOfWeekCapacityManage from "../screens/DefaultWorkerDayOfWeekCapacity";
import DefaultMachineCapacityManage from "../screens/DefaultMachineCapacity";
import EguItemExcelUpload from "../screens/EguItemExcelUpload";
import EguCustomerExcelUpload from "../screens/EguCustomerExcelUpload";
import EguUserExcelUpload from "../screens/EguUserExcelUpload";
import EguPopOtherWork from "../screens/EguPopOtherWork";
import MomSysCostAnalysis from "../screens/MomSysCostAnalysis";
import EguManagementIndicator from "../screens/EguManagementIndicator";
import EguSubMaterialInfo from "../screens/EguSubMaterialInfo";
import EguItemInfoManagement from "../screens/EguItemInfoManagement";
import EguCustomerInfo from "../screens/EguCustomerInfo";
import EguMachineRental from "../screens/EguMachineRental";
import MrpMaterialList from "../screens/MrpMaterialList";
import PsmMaterialList from "../screens/PsmMaterialList";
import EguProductionStatus from "../screens/EguProductionStatus";
import EguSalesStatus from "../screens/EguSalesStatus";
import EguAdminAccountManage from "../screens/EguAdminAccountManage";
import EguLaborCost from "../screens/EguLaborCost";
import EguProductFamily from "../screens/EguProductFamily";
import MomSysStockMove from "../screens/MomSysStockMove";
import MomSysUploadBomBopFile from "../screens/MomSysUploadBomBopFile";
import MomSysMaterialByCompanyExcelUpload from "../screens/MomSysMaterialByCompanyExcelUpload";

import EguDashboard from "../screens/EguDashboard";
import EguProductionMonitoring from "../screens/EguProductionMonitoring";
import EguSalesMonitoring from "../screens/EguSalesMonitoring";
import EguCostInfoMgmt from "../screens/EguCostInfoMgmt";

import LoginNotice from "../screens/LoginNotice";
import EguResearchInputMaterial from "../screens/EguResearchInputMaterial";
import EguResearchMaterialConsume from "../screens/EguResearchMaterialConsume";
import EguStockExcelUpload from "../screens/EguStockExcelUpload";
import BatchSchedule from "../screens/BatchSchedule";
import BatchHistory from "../screens/BatchHistory";

import MaterialInOutReport from "../screens/MaterialInOutReport";
import ProductInOutReport from "../screens/ProductInOutReport";
import UserInOutReport from "../screens/UserInOutReport";

import UserLotProductionConsumptionReport from "../screens/UserLotProductionConsumptionReport";
import PopProductShip from "../screens/PopProductShip";
import PopStockMove from "../screens/PopStockMove";
import PopLotPackingSplit from "../screens/PopLotPackingSplit";

import PopOutSourcingShip from "../screens/PopOutSourcingShip";
import PopOutSourcingInput from "../screens/PopOutSourcingInput";
import PopOutSourcingMaterialReturn from "../screens/PopOutSourcingMaterialReturn";
import MomSysCustomerSupplyInput from "../screens/MomSysCustomerSupplyInput";
import PopDirectShip from "../screens/PopDirectShip";
import PopMaterialFakeInput from "../screens/PopMaterialFakeInput";
import PopNoOrderPurchaseInput from "../screens/PopNoOrderPurchaseInput";

import VwDeprecatedMaterials from "../screens/VwDeprecatedMaterials";
import PopOrderPurchaseInput from "../screens/PopOrderPurchaseInput";
import PopCustomerSupplyInput from "../screens/PopCustomerSupplyInput";
import DbTask from "../screens/DbTask";
import DbProcess from "../screens/DbProcess";
import DbLocks from "../screens/DbLocks";
import DbLockWaits from "../screens/DbLockWaits";
import DbTransactions from "../screens/DbTransactions";
import MomSysCustomerSupplyOrderBuy from "../screens/MomSysCustomerSupplyOrderBuy";
import PopBackShip from "../screens/PopBackShip";
import PopSelfInspection from "../screens/PopSelfInspection";
import LogSearch from "../screens/LogSearch";
import LogViewerWithHeader from "../screens/LogViewerWithHeader";
import JobQueueView from "../screens/JobQueue";
import JobQueueAdminView from "../screens/JobQueueAdminView";
import AttendanceManagement from "../screens/AttendanceManagement";
import OriginSeaweedStock from "../screens/OriginSeaweedStock";
import SelfInspection from "../screens/SelfInspection";
import MomSysFailLotManagement from "../screens/MomSysFailLotManagement";
import MomSysFailLotTerminateHistory from "../screens/MomSysFailLotTerminateHistory";
import MomSysRawMaterialBackShip from "../screens/MomSysRawMaterialBackShip";
import MomSysProductBackShip from "../screens/MomSysProductBackShip";

// Test Screens
import ReportViewerTest from "../screens/ReportViewerTest";
import MaterialYieldStatus from "../screens/MaterialYieldStatus";
import MaterialPutStatus from "../screens/MaterialPutStatus";
import SummarizeDailyStock from "../screens/SummarizeDailyStock";

// GeneralReport
import GeneralReport from "../screens/GeneralReport";
import GeneralReportManage from "../screens/GeneralReportManage";
import GeneralReportTenantManage from "../screens/GeneralReportTenantManage";

// ExtMaterialAttr
import ExtMaterialAttrManage from "../screens/ExtMaterialAttrManage";
import ExtMaterialAttrTenantManage from "../screens/ExtMaterialAttrTenantManage";

import MomSysProductionCancel from "../screens/MomSysProductionCancel";

import MomSysShipRecord from "../screens/MomSysShipRecord";
import MomSysInputRecord from "../screens/MomSysInputRecord";
import MomSysInspectionGroupByMaterial from "../screens/MomSysInspectionGroupByMaterial";
import MomSysInspectionItem from "../screens/MomSysInspectionItem";
import MomSysInspectionCriteria from "../screens/MomSysInspectionCriteria";
import MomSysInspectionGroup from "../screens/MomSysInspectionGroup";
import MomSysMachineStateMgmt from './../screens/MomSysMachineStateMgmt';
import MomSysMachineStateRecord from "../screens/MomSysMachineStateRecord";

import InspectionList from "../screens/InspectionList";
import ProcessCategory from "../screens/ProcessCategory";
import MainProcessManage from "../screens/MainProcessManage";
import PopProductManageProcess from "../screens/PopProductManageProcess";
import MomSysShipOfOrderBuyer from "../screens/MomSysShipOfOrderBuyer";
import OrderPurchaseInput from "../screens/OrderPurchaseInput";
import ProcessCategoryManage from "../screens/ProcessCategoryManage";
import PopProductShipOfOrderBuyer from "../screens/PopProductShipOfOrderBuyer";
import OutSourcingInput from "../screens/OutSourcingInput";
import ExceptionInOut from "../screens/ExceptionInOut";

function ComponentRouter(props) {
    const rootRedirectPath = useRecoilValue(rootRedirectPathState);

    return (
        <>
            <Route exact path="/">
                <Redirect to={rootRedirectPath} />
            </Route>
            <MenuRedirector />
            <Switch>
                {/* Screens */}

                <Route path="*/menuItem" render={() => <MenuItemManage />} />
                <Route path="*/menuSet" render={() => <MenuSetManage />} />
                <Route path="*/topMenuItem" render={() => <TopMenuItemManage />} />
                <Route path="*/subMenuItem" render={() => <SubMenuItemManage />} />

                {/* top menu path가 sub menu path로도 사용되는 경우에는 해당 sub menu path를 가장 아래 두어야 함. role을 유저 아래로 선택하는 경우 /user/role path도 /user/user로 라우팅됨 */}

                <Route path="*/role" render={() => <RoleManage />} />
                <Route path="*/roleset" render={() => <RoleSet />} />
                <Route path="*/rolesetdetail" render={() => <RoleSetDetail />} />
                <Route path="*/user" render={() => <UserManage />} />

                <Route path="*/approvalType" render={() => <ApprovalTypeManage />} />
                <Route path="*/approver" render={() => <ApproverManage />} />
                <Route path="*/approvalPath" render={() => <ApprovalPathManage />} />
                <Route path="*/approvalHistory" render={() => <ApprovalHistory />} />
                <Route path="*/approvalPathDetail" render={() => <ApprovalPathDetailManage />} />

                <Route path="*/materialIo" render={() => <MaterialIoManage />} />
                <Route path="*/materialInventory" render={() => <MaterialInventoryManage />} />
                <Route path="*/consumablesIo" render={() => <ConsumablesIoManage />} />
                <Route path="*/consumablesInventory" render={() => <ConsumablesInventoryManage />} />

                <Route path="*/item" render={() => <Item />} />
                <Route path="*/itemUnit" render={() => <ItemUnit />} />
                <Route path="*/itemType" render={() => <ItemType />} />
                <Route path="*/itemSpec" render={() => <ItemSpec />} />
                <Route path="*/vendor" render={() => <Vendor />} />
                <Route path="*/customer" render={() => <Customer />} />

                <Route path="*/po" render={() => <Po />} />
                <Route path="*/so" render={() => <So />} />
                <Route path="*/estimate" render={() => <EstimateMgmt />} />
                <Route path="*/shipment" render={() => <Shipment />} />
                <Route path="*/returnedItem" render={() => <ReturnedItem />} />

                <Route path="*/workerPerformance" render={() => <WorkerPerformanceManage />} />
                <Route path="*/productPerformance" render={() => <ProductPerformanceManage />} />

                <Route path="*/productIo" render={() => <ProductInOutput />} />
                <Route path="*/productInventory" render={() => <ProductInventory />} />

                <Route path="*/accessControlTarget" render={() => <AccessControlTarget />} />
                <Route path="*/accessControlRule" render={() => <AccessControlRule />} />

                <Route path="*/dbconf" render={() => <DbConf />} />
                <Route path="*/namespace" render={() => <Namespace />} />
                <Route path="*/language" render={() => <SupportedLanguage />} />
                <Route path="*/translation" render={() => <Translation />} />
                <Route path="*/tenantLogo" render={() => <TenantLogo />} />
                <Route path="*/tenantFileTemplateMgmt" render={() => <TenantFileTemplateMgmt />} />
                <Route path="*/tenantReportFile" render={() => <TenantReportFile />} />

                <Route path="*/defaultOption" render={() => <DefaultOption />} />
                <Route path="*/roleSetOption" render={() => <RoleSetOption />} />
                <Route path="*/tenantOption" render={() => <TenantOption />} />
                <Route path="*/tenant" render={() => <Tenant />} />
                <Route path="*/tenantTranslation" render={() => <TenantTranslation />} />
                <Route path="*/roleSetTranslation" render={() => <RoleSetTranslation />} />
                <Route path="*/translationSearch" render={() => <TranslationValue />} />
                <Route path="*/inBox" render={() => <InBox />} />
                <Route path="*/msgSent" render={() => <MsgSent />} />
                <Route path="*/trash" render={() => <Trash />} />

                <Route path="*/batchSchedule" render={() => <BatchSchedule />} />
                <Route path="*/batchHistory" render={() => <BatchHistory />} />

                {/* Mom Sys Screens*/}

                <Route path="*/decisionCode" render={() => <MomSysDecisionCode />} />

                <Route path="*/momSysProcess" render={() => <MomSysMstProcess />} />
                <Route path="*/momSysbombop" render={() => <MomSysBomBopInfo />} />
                <Route path="*/uploadBomBopFile" render={() => <MomSysUploadBomBopFile />} />

                <Route path="*/momSysLineNo" render={() => <MomSysUdtLineNo />} />
                <Route path="*/momSysWorkStation" render={() => <MomSysUdtWorkStation />} />
                <Route path="*/momSysWareHouse" render={() => <MomSysUdtWareHouse />} />
                <Route path="*/WarehouseCategory" render={() => <WarehouseCategory />} />
                <Route path="*/momSysCompany" render={() => <MomSysUdtCompanyManager />} />
                <Route path="*/momSysMaterialType" render={() => <MomSysUdtMaterialType />} />
                <Route path="*/momSysMaterialInfo" render={() => <MomSysMstMaterialInfo />} />
                <Route path="*/machineMainType" render={() => <MomSysMachineMainType />} />
                <Route path="*/machine" render={() => <MomSysMachine />} />
                <Route path="*/machineMaintenance" render={() => <MomSysMachineMain />} />
                <Route path="*/momSysMachineStateRecord" render={() => <MomSysMachineStateRecord />} />
                <Route path="*/momSysMachineStateMgmt" render={() => <MomSysMachineStateMgmt />} />
                <Route path="*/momSysNotice" render={() => <MomSysNotice />} />
                <Route path="*/momSysPopNotice" render={() => <MomSysPopNotice />} />
                <Route path="*/momSysOrderBuyer" render={() => <MomSysUglOrderBuyerMst />} />
                <Route path="*/momSysCustomerSupplyOrderBuy" render={() => <MomSysCustomerSupplyOrderBuy />} />
                <Route path="*/momSysOrderPurchase" render={() => <MomSysUglOrderPurchaseMst />} />
                <Route path="*/momSysShipment" render={() => <MomSysShipment />} />
                <Route path="*/momSysDelivery" render={() => <MomSysDelivery />} />
                <Route path="*/momSysCompanyCollection" render={() => <MomSysCompanyCollection />} />
                <Route path="*/momSysSales" render={() => <MomSysSales />} />
                <Route path="*/momSysCollections" render={() => <MomSysCollections />} />
                <Route path="*/momSysCollectionsReport" render={() => <MomSysCollectionsReport />} />
                <Route path="*/momSysMaterialError" render={() => <MomSysMaterialError />} />
                <Route path="*/momSysMaterialInput" render={() => <MomSysMaterialInput />} />
                <Route path="*/momSysMaterialOutSourcing" render={() => <MomSysMaterialOutSourcing />} />
                <Route path="*/momSysMaterialBackShip" render={() => <MomSysMaterialBackShip />} />
                <Route path="*/momSysMergeSplitHistory" render={() => <MomSysMergeSplitHistory />} />
                <Route path="*/momSysProductionErrorHistory" render={() => <MomSysProductionErrorHistory />} />
                <Route path="*/momSysMaterialStatus" render={() => <MomSysMaterialStatus />} />
                <Route path="*/momSysBopHistory" render={() => <MomSysBopHistory />} />
                <Route path="*/momSysBomHistory" render={() => <MomSysBomHistory />} />
                <Route path="*/momSysSoProduction" render={() => <MomSysSoProduction />} />
                <Route path="*/momSysMatProduction" render={() => <MomSysMatProduction />} />
                <Route path="*/momSysStockTakingMst" render={() => <MomSysStockTakingMst />} />
                <Route path="*/momSysProductionStatus" render={() => <MomSysProductionStatus />} />
                <Route path="*/momSysPurchase" render={() => <MomSysPurchase />} />
                <Route path="*/momSysExpense" render={() => <MomSysExpense />} />
                <Route path="*/MomSysExpenseReport" render={() => <MomSysExpenseReport />} />
                <Route path="*/momSysProductStatus" render={() => <MomSysProductStatus />} />
                <Route path="*/warehouseStockStatus" render={() => <WarehouseStockStatus />} />
                <Route path="*/MomSysLotStateChange" render={() => <MomSysLotStateChange />} />
                <Route path="*/momSysCodeGenerate" render={() => <MomSysCodeGenerate />} />
                <Route path="*/momSysLotTransHistory" render={() => <MomSysLotTransHistory />} />
                <Route path="*/momSysLotTrace" render={() => <MomSysLotTrace />} />
                <Route path="*/momSysProductionCancelHistory" render={() => <MomSysProductionCancelHistory />} />
                <Route path="*/momSysMaterialByCompany" render={() => <MomSysMaterialByCompany />} />
                <Route path="*/momSysProcessTime" render={() => <MomSysProcessTime />} />
                <Route path="*/momSysProductionTime" render={() => <MomSysProductionTime />} />
                <Route path="*/momSysProcessReport" render={() => <MomSysProcessReport />} />
                <Route path="*/momSysProductionReport" render={() => <MomSysProductionReport />} />
                <Route path="*/momSysOwnCompany" render={() => <MomSysOwnCompanyManager />} />
                <Route path="*/momSysAdvancePayment" render={() => <MomSysCollectionsDetail advancePayment={true} />} />
                <Route path="*/momSysSalesCollections" render={() => <MomSysSalesCollections />} />
                <Route path="*/momSysPurchaseExpense" render={() => <MomSysPurchaseExpense />} />
                <Route path="*/momSysBackShip" render={() => <MomSysBackShip />} />
                <Route path="*/momSysProductShip" render={() => <MomSysProductShip />} />
                <Route path="*/momSysProductionPlan" render={() => <ProductionPlan />} />
                <Route path="*/momSysSalesAnalysis" render={() => <MomSysSalesAnalysis />} />
                <Route path="*/momSysPurchaseAnalysis" render={() => <MomSysPurchaseAnalysis />} />
                <Route path="*/momSysWageRate" render={() => <MomSysWageRate />} />
                <Route path="*/performanceMetric" render={() => <VwPerformanceMetric />} />
                <Route path="*/holiday" render={() => <HolidayManage />} />
                <Route path="*/additionalWorkDay" render={() => <AdditionalWorkDayManage />} />
                <Route path="*/workDay" render={() => <WorkDayManage />} />
                <Route path="*/workerDayOfWeekCapacity" render={() => <WorkerDayOfWeekCapacityManage />} />
                <Route path="*/workerDateCapacity" render={() => <WorkerDateCapacityManage />} />
                <Route path="*/machineDateCapacity" render={() => <MachineDateCapacityManage />} />
                <Route path="*/dayOfWeekCapacity" render={() => <DefaultWorkerDayOfWeekCapacityManage />} />
                <Route path="*/defaultMachineCapacity" render={() => <DefaultMachineCapacityManage />} />
                <Route path="*/momSysCostAnalysis" render={() => <MomSysCostAnalysis />} />
                <Route path="*/momSysStockMove" render={() => <MomSysStockMove />} />
                <Route path="*/materialInOutReport" render={() => <MaterialInOutReport />} />
                <Route path="*/productInOutReport" render={() => <ProductInOutReport />} />
                <Route path="*/userInOutReport" render={() => <UserInOutReport />} />
                <Route path="*/lotProductionConsumptionReport" render={() => <UserLotProductionConsumptionReport />} />
                <Route path="*/materialPutStatus" render={() => <MaterialPutStatus />} />
                <Route path="*/deprecatedMaterials" render={() => <VwDeprecatedMaterials />} />
                <Route path="*/dbProcess" render={() => <DbProcess />} />
                <Route path="*/dbLocks" render={() => <DbLocks />} />
                <Route path="*/dbLockWaits" render={() => <DbLockWaits />} />
                <Route path="*/dbTransactions" render={() => <DbTransactions />} />
                <Route path="*/database" render={() => <DbTask />} />
                <Route path="*/GeneralReport" render={() => <GeneralReport />} />
                <Route path="*/GeneralReportTenantManage" render={() => <GeneralReportTenantManage />} />
                <Route path="*/GeneralReportManage" render={() => <GeneralReportManage />} />
                <Route path="*/ExtMaterialAttrTenantManage" render={() => <ExtMaterialAttrTenantManage />} />
                <Route path="*/ExtMaterialAttrManage" render={() => <ExtMaterialAttrManage />} />
                

                {/* Egu Sys Screens*/}

                <Route path="*/eguMaterialFakeInput" render={() => <EguMaterialFakeInput />} />
                <Route path="*/momSysProductStockMgmt" render={() => <MomSysProductStockTakingMst />} />
                <Route path="*/momSysMaterialStockMgmt" render={() => <MomSysMaterialStockTakingMst />} />
                <Route path="*/momSysProductInputStatus" render={() => <MomSysProductInputStatus />} />
                <Route path="*/eguUglOrderBuyerMst" render={() => <EguUglOrderBuyerMst />} />
                <Route path="*/eguUglOrderPurchaseMst" render={() => <EguUglOrderPurchaseMst />} />
                <Route path="*/eguMaterialStatus" render={() => <EguMaterialStatus />} />
                <Route path="*/eguProductStatus" render={() => <EguProductStatus />} />
                <Route path="*/eguProductShip" render={() => <EguProductShip />} />
                <Route path="*/momSysShipOfOrderBuyer" render={() => <MomSysShipOfOrderBuyer />} />
                <Route path="*/momSysDirectShip" render={() => <MomSysDirectShip />} />
                <Route path="*/eguMaterialInputStatus" render={() => <EguMaterialInputStatus />} />
                <Route path="*/eguItemInfo" render={() => <EguItemInfo />} />
                <Route path="*/eguLotStateChange" render={() => <EguLotStateChange />} />
                <Route path="*/eguMergeSplitHistory" render={() => <EguMergeSplitHistory />} />

                <Route path="*/momSysDefectiveStatus" render={() => <EguDefectiveStatus />} />
                <Route path="*/momSysInputInspection" render={() => <EguInputInspection />} />
                <Route path="*/momSysNoOrderPurchaseInput" render={() => <MomSysNoOrderPurchaseInput />} />
                <Route path="*/momSysCustomerSupplyInput" render={() => <MomSysCustomerSupplyInput />} />
                <Route path="*/momSysSelfInspection" render={() => <EguSelfInspection />} />
                <Route path="*/momSysInspectionGroupByMaterial" render={() => <MomSysInspectionGroupByMaterial />} />
                <Route path="*/momSysInspectionItem" render={() => <MomSysInspectionItem />} />
                <Route path="*/momSysInspectionCriteria" render={() => <MomSysInspectionCriteria />} />
                <Route path="*/momSysInspectionGroup" render={() => <MomSysInspectionGroup />} />
                <Route path="*/momSysFailLotManagement" render={() => <MomSysFailLotManagement />} />
                <Route path="*/momSysFailLotTerminateHistory" render={() => <MomSysFailLotTerminateHistory />} />
                <Route path="*/momSysRawMaterialBackShip" render={() => <MomSysRawMaterialBackShip />} />
                <Route path="*/momSysProductBackShip" render={() => <MomSysProductBackShip />} />

                <Route path="*/momSysShipRecord" render={() => <MomSysShipRecord />} />
                <Route path="*/momSysInputRecord" render={() => <MomSysInputRecord />} />

                <Route path="*/eguOutSourcingPurchase" render={() => <EguOutSourcingPurchase />} />
                <Route path="*/eguOutSourcingShip" render={() => <EguOutSourcingShip />} />
                <Route path="*/eguOutSourcingFakeInput" render={() => <EguOutSourcingFakeInput />} />
                <Route path="*/eguOutSourcingInputInspection" render={() => <EguOutSourcingInputInspection />} />
                <Route path="*/eguOutSourcingMaterialReturn" render={() => <EguOutSourcingMaterialReturn />} />

                <Route path="*/popSelection" render={() => <EguPopProductManage id="PopSelection" />} />
                <Route path="*/popProduce" render={() => <EguPopProductManage id="PopProduce" />} />
                <Route path="*/popPackaging" render={() => <EguPopProductManage id="PopPackaging" />} />
                <Route path="*/popOutSourcingShip" render={() => <PopOutSourcingShip />} />
                <Route path="*/popOutSourcingInput" render={() => <PopOutSourcingInput />} />
                <Route path="*/popOutSourcingMaterialReturn" render={() => <PopOutSourcingMaterialReturn />} />

                <Route path="*/popMachining" render={() => <EguPopProductManage id="PopMachining" />} />
                <Route path="*/popBendingProcessing" render={() => <EguPopProductManage id="PopBendingProcessing" />} />
                <Route path="*/popWeldingOrPress" render={() => <EguPopProductManage id="PopWeldingOrPress" />} />
                <Route
                    path="*/popPaintingOrPlatingOrPrinting"
                    render={() => <EguPopProductManage id="PopPaintingOrPlatingOrPrinting" />}
                />
                <Route path="*/popAssembly" render={() => <EguPopProductManage id="PopAssembly" />} />

                <Route path="*/popAll" render={() => <EguPopProductManage id="PopAll" />} />
                <Route path="*/workRecords" render={() => <EguPopProductManage id="WorkRecords" />} />

                <Route path="*/popProductShip" render={() => <PopProductShipOfOrderBuyer />} />
                <Route path="*/popProductShipOfOrderBuyer" render={() => <PopProductShipOfOrderBuyer />} />
                <Route path="*/popStockMove" render={() => <PopStockMove />} />
                <Route path="*/popDirectShip" render={() => <PopDirectShip />} />
                <Route path="*/popBackShip" render={() => <PopBackShip />} />
                <Route path="*/popSelfInspection" render={() => <PopSelfInspection />} />
                <Route path="*/popMaterialFakeInput" render={() => <PopMaterialFakeInput />} />
                <Route path="*/popOrderPurchaseInput" render={() => <PopOrderPurchaseInput />} />
                <Route path="*/popNoOrderPurchaseInput" render={() => <PopNoOrderPurchaseInput />} />
                <Route path="*/popCustomerSupplyInput" render={() => <PopCustomerSupplyInput />} />

                <Route path="*/popLotPackingSplit" render={() => <PopLotPackingSplit />} />

                <Route path="*/popNotice" render={() => <EguPopNotice />} />
                <Route path="*/eguPopOtherWork" render={() => <EguPopOtherWork />} />
                <Route path="*/eguProductSample" render={() => <EguProductSample />} />
                <Route path="*/eguSubMaterialSample" render={() => <EguSubMaterialSample />} />
                <Route path="*/eguIngredientRegistration" render={() => <EguIngredientRegistration />} />
                <Route path="*/eguResearchInputMaterial" render={() => <EguResearchInputMaterial />} />
                <Route path="*/eguResearchMaterialConsume" render={() => <EguResearchMaterialConsume />} />
                <Route path="*/eguItemExcelUpload" render={() => <EguItemExcelUpload />} />
                <Route path="*/eguCustomerExcelUpload" render={() => <EguCustomerExcelUpload />} />
                <Route path="*/eguUserExcelUpload" render={() => <EguUserExcelUpload />} />
                <Route path="*/eguProductFamily" render={() => <EguProductFamily />} />

                <Route path="*/eguAdministratorAccountManage" render={() => <EguAdminAccountManage />} />
                <Route path="*/eguUserInformationManage" render={() => <UserManage />} />
                <Route path="*/eguUserGroupManage" render={() => <RoleManage />} />
                <Route path="*/eguSubMaterialInfo" render={() => <EguSubMaterialInfo />} />
                <Route path="*/eguItemInfoManagement" render={() => <EguItemInfoManagement />} />
                <Route path="*/eguCustomerInfo" render={() => <EguCustomerInfo />} />
                <Route path="*/eguMachineRental" render={() => <EguMachineRental />} />
                <Route path="*/eguLaborCost" render={() => <EguLaborCost />} />
                <Route path="*/eguCostInfoMgmt" render={() => <EguCostInfoMgmt />} />

                <Route path="*/eguProductionMonitoring" render={() => <EguProductionMonitoring />} />
                <Route path="*/eguSalesMonitoring" render={() => <EguSalesMonitoring />} />
                <Route path="*/eguSalesStatus" render={() => <EguSalesStatus />} />
                <Route path="*/eguProductionStatus" render={() => <EguProductionStatus />} />
                <Route path="*/managementIndicator" render={() => <EguManagementIndicator />} />
                <Route path="*/dashboard" render={() => <EguDashboard />} />
                <Route path="*/eguStockExcelUpload" render={() => <EguStockExcelUpload />} />
                <Route
                    path="*/MomSysMaterialByCompanyExcelUpload"
                    render={() => <MomSysMaterialByCompanyExcelUpload />}
                />
                <Route path="*/materialYieldStatus" render={() => <MaterialYieldStatus />} />
                <Route path="*/mrpMaterialList" render={() => <MrpMaterialList />} />
                <Route path="*/psmMaterialList" render={() => <PsmMaterialList />} />
                <Route path="*/jobQueueView" render={() => <JobQueueView />} />
                <Route path="*/JobQueueAdminView" render={() => <JobQueueAdminView />} />

                <Route path="*/summarizeDailyStock" render={() => <SummarizeDailyStock />} />
                <Route path="*/attendanceManagement" render={() => <AttendanceManagement />} />
                <Route path="*/originSeaweedStock" render={() => <OriginSeaweedStock />} />

                <Route path="*/momSysProductionCancel" render={() => <MomSysProductionCancel />} />
                <Route path="*/selfInspection" render={() => <SelfInspection />} />
                <Route path="*/inspectionList" render={() => <InspectionList />} />
                <Route path="*/processCategory" render={() => <ProcessCategory />} />
                <Route path="*/mainProcessManage" render={() => <MainProcessManage />} />
                <Route path="*/popProductManageProcess" render={() => <PopProductManageProcess />} />
                <Route path="*/orderPurchaseInput" render={() => <OrderPurchaseInput />} />
                <Route path="*/processCategoryManage" render={() => <ProcessCategoryManage />} />
                <Route path="*/outSourcingInput" render={() => <OutSourcingInput />} />
                <Route path="*/exceptionInOut" render={() => <ExceptionInOut />} />
                
                {/* Log Management Screens*/}

                <Route path="*/logSearch" render={() => <LogSearch />} />
                <Route path="*/logViewer" render={() => <LogViewerWithHeader />} />

                {/* Egu Sys Temp Screens*/}

                <Route path="*/eguPopTemp" render={() => <EguPopTemp />} />

                {/* LoginNotice Screen */}
                <Route path="*/loginNotice" render={() => <LoginNotice />} />

                {/* Report Viewer Test Screens*/}
                <Route path="*/reportViewerTest" render={() => <ReportViewerTest />} />

                {/* Default Redirect */}

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </>
    );
}

export default ComponentRouter;
