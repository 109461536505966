/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import defineConf from "../../config/defineConf";

function PopOutSourcingShipDetailHistory(props) {
    const { title, onPopOutSourcingShipDetailHistoryApi } = props;

    const id = "PopOutSourcingDetailShipHistory";
    const view = "PopVwOutSourcingShipDetailHistory";

    const { t } = useTranslation();

    const [, setPopOutSourcingShipDetailHistoryGrid] = useState();
    const [popOutSourcingShipDetailHistoryApi, setPopOutSourcingShipDetailHistoryApi] = useState();

    useEffect(() => {
        if (popOutSourcingShipDetailHistoryApi) {
            popOutSourcingShipDetailHistoryApi.refresh();
        }
    }, []);

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t(title)}
            view={view}
            onInitialized={(grid) => {
                grid.selectionMode = "None";
                setPopOutSourcingShipDetailHistoryGrid(grid);
            }}
            onApiInitialized={(api) => {
                setPopOutSourcingShipDetailHistoryApi(api);

                if (onPopOutSourcingShipDetailHistoryApi) {
                    onPopOutSourcingShipDetailHistoryApi(api);
                }
            }}
            noCreate
            noEdit
            noDelete
            noExcel
        />
    );
}

export default PopOutSourcingShipDetailHistory;
