import MaterialDialogActions from "../input/MaterialDialogActions";
import WijmoDialogActions from "../input/WijmoDialogActions";

import uiConf from "../../../config/uiConf";

function DialogActionsLayout ( props ) {

    const { platform, onInitialized, ...others } = props;

    if ( platform ) {

        switch ( platform ) {

            case "material-ui":
    
                return <MaterialDialogActions {...others} />
    
            case "wijmo":
    
                return <WijmoDialogActions {...others} />

            default:
    
                return <MaterialDialogActions {...others} />
    
        }

    } else {

        switch ( uiConf.dialogPlatform ) {

            case "material-ui":
    
                return <MaterialDialogActions {...others} />
    
            case "wijmo":
    
                return <WijmoDialogActions {...others} />

            default:

                return <MaterialDialogActions {...others} />
    
        }

    }

}

export default DialogActionsLayout;
