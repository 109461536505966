/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import CreateRecordButton from "../input/CreateRecordButton";

import AddProcessButton from "../input/AddProcessButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import GridActionButton from "../input/GridActionButton";

function MomSysBop ( props ) {

    const { t } = useTranslation();

    const { product, onSelect, onChange } = props;

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [bopSchema, setBopSchema] = useState();
    const [processSchema, setProcessSchema] = useState();
    const [disableDelButton, setDisableDelButton] = useState(false);
    const [enableMoveUp, setEnableMoveUp] = useState(false);
    const [enableMoveDown, setEnableMoveDown] = useState(false);

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let bopSchema = await getSchema ( "MomSysUdtBillOfProcess" );

            if ( mounted ) {
                setBopSchema ( bopSchema );
            }

            let processSchema = await getSchema ( "MomSysMstProcess" );

            if ( mounted ) {
                setProcessSchema ( processSchema );
            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        let mounted = true;

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( mounted ) {

                        if ( grid.selectedItems && grid.selectedItems[0] ) {

                            setDisableDelButton ( false );
    
                            if ( grid.selectedItems[0].processOrder === 1 ) {
    
                                setEnableMoveUp ( false );
    
                            } else {
    
                                setEnableMoveUp ( true );
    
                            }
    
                            if ( grid.selectedItems[0].processOrder === grid.rows.length ) {
    
                                setEnableMoveDown ( false );
    
                            } else {
    
                                setEnableMoveDown ( true );
    
                            }
    
                            if ( onSelect ) {
    
                                onSelect(grid.selectedItems[0]);
    
                            }
    
                        } else {
                                
                                setEnableMoveUp ( false );
                                setEnableMoveDown ( false );
                                setDisableDelButton ( true );
    
                                if ( onSelect ) {
    
                                    onSelect ( undefined );
    
                                }
    
                        }
    
                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {

                            setDisableDelButton ( false );
    
                            if ( grid.selectedItems[0].processOrder === 1 ) {
    
                                setEnableMoveUp ( false );
    
                            } else {
    
                                setEnableMoveUp ( true );
    
                            }
    
                            if ( grid.selectedItems[0].processOrder === grid.rows.length ) {
    
                                setEnableMoveDown ( false );
    
                            } else {
    
                                setEnableMoveDown ( true );
    
                            }
    
                            if ( onSelect ) {
    
                                onSelect(grid.selectedItems[0]);
    
                            }
    
                        } else {
                                
                                setEnableMoveUp ( false );
                                setEnableMoveDown ( false );
                                setDisableDelButton ( true );
    
                                if ( onSelect ) {
    
                                    onSelect ( undefined );
    
                                }
                                
                        }

                    }

                });

            }

        })();

        return ( () => {

            mounted = false;

        } );

    }, [grid]);

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [product] );

    const onGridInitialized = (grid) => {

        grid.allowSorting = false; // disable sorting - must be ordered by process order
        grid.selectionMode = "Row";

        setGrid(grid);
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    const onModifyUrl = (url) => {
        
        if ( product ) {

            return url + "/" + product.materialCode + "/" + product.materialCodeVer;

        }

        return url + "/undefined/undefined";
        
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );

        if ( onChange ) {

            onChange();

        }
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const makeItemName = ( row ) => {

        return `${row.processName}[${row.processCode}]`;

    };

    const setMoveUpMsg = ( grid ) => {
        
        return t( "dialog.msg.confirm.moveUpProcess", { process: `${grid.selectedItems[0].processName}[${grid.selectedItems[0].processCode}]`} );

    }

    const setMoveDownMsg = ( grid ) => {

        return t( "dialog.msg.confirm.moveDownProcess", { process: `${grid.selectedItems[0].processName}[${grid.selectedItems[0].processCode}]`} );

    }

    const setMoveUpUrl = ( grid ) => {

        return `/api/MomSysMoveUpProcess/${grid.selectedItems[0].bopMaterialCode}/${grid.selectedItems[0].bopMaterialCodeVer}/${grid.selectedItems[0].processCode}`;

    }

    const setMoveDownUrl = ( grid ) => {

        return `/api/MomSysMoveDownProcess/${grid.selectedItems[0].bopMaterialCode}/${grid.selectedItems[0].bopMaterialCodeVer}/${grid.selectedItems[0].processCode}`;

    }

    const onSuccessMoveUp = ( grid, api ) => {

        api.refresh ();
        onSuccess ( t("success.moveUpProcess", { processCode: grid.selectedItems[0].processCode, processName: grid.selectedItems[0].processName } ) );

    }

    const onSuccessMoveDown = ( grid, api ) => {

        api.refresh ();
        onSuccess ( t("success.moveDownProcess", { processCode: grid.selectedItems[0].processCode, processName: grid.selectedItems[0].processName } ) );

    }
    
    return (

        <BasicCrudScreenType
            id="MomSysZvwBillOfProcess"
            headerTitle={t("term.bop")}
            table="MomSysUdtBillOfProcess"
            view="MomSysVwOrderedBillOfProcess"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            fixedOrder={[{column: "processOrder", order: "ASC"}]}
            onAppendHeaderAtLast={() =>

                <>

                    <BasicCrudScreenActionItemLayout>

                        <AddProcessButton
                            id="MomSysUdtBillOfProcess"
                            disabled={!product}
                            product={product}
                            refresh={api}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <DeleteSingleRecordButton
                            id="MomSysUdtBillOfProcess"
                            disabled={disableDelButton}
                            color="secondary"
                            grid={grid}
                            title={t("buttonName.deleteProcess")}
                            schema={bopSchema}
                            refresh={api}
                            makeItemName={makeItemName}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <GridActionButton                            
                            id="MomSysUdtBillOfProcess-moveup"
                            disabled={!enableMoveUp}
                            setMsg={
                                () => setMoveUpMsg(grid)
                            }
                            setUrl={
                                () => setMoveUpUrl(grid)
                            }
                            onSuccess={
                                () => onSuccessMoveUp(grid, api)
                            }
                            onFailure={onFailure}
                            title={t("buttonName.moveUpProcess")}

                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <GridActionButton
                            id="MomSysUdtBillOfProcess-movedown"
                            disabled={!enableMoveDown}
                            setMsg={
                                () => setMoveDownMsg(grid)
                            }
                            setUrl={
                                () => setMoveDownUrl(grid)
                            }
                            onSuccess={
                                () => onSuccessMoveDown(grid, api)
                            }
                            onFailure={onFailure}
                            title={t("buttonName.moveDownProcess")}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <AccessControl accessTarget="MomSysMstProcess" accessMethod="POST">
                            <CreateRecordButton
                                id={"bom-bop-info-process-create-button"}
                                disabled={!product}
                                schema={processSchema}
                                onSuccess={onSuccess}
                                createButtonName="createProcess"
                            />
                        </AccessControl>

                    </BasicCrudScreenActionItemLayout>

                </>

            }
            onModifyViewUrl={onModifyUrl}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysBop;
