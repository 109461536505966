import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import { Typography } from "@material-ui/core";

function ImageViewerDialog ( props ) {

    const {

        id,
        url,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,

        ...others

    } = props;

    const onDialogInitialized = ( instance ) => {

        if ( onInitialized ) {

            onInitialized ( instance );
        }

    };


    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : uiConf.confirmDialogMaxWidth}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogContent
                style={{marginLeft: "auto", marginRight: "auto"}}
            >
            {
                url
                ?
                <img
                    alt={url}
                    src={
                        ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' )
                        ?
                        url
                        :
                        "http://localhost:8080" + url
                    }
                    style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}
                />
                :
                <Typography color="inherit" variant="body2" align="center" >No Image</Typography>
            }
            </DialogContent>

        </Dialog>
    );

}

export default ImageViewerDialog;
