import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";
import { CollectionView } from "@grapecity/wijmo";
import { cloneDeep } from "lodash";

import BasicStaticGridScreenType from "../screenTypes/functional/BasicStaticGridScreenType";
import checkValidation from "../../lib/utils/checkValidation";
import getSpecific from "../../lib/api/getSpecific";

function EguFailedLot(props) {

    const { t } = useTranslation();

    const { notifyWarning, onChangeFailedLotList } = props;

    const [ grid, setGrid ] = useState();
    const [ formData, setFormData ] = useState();
    const [ failedLotList, setFailedLotList ] = useState([]);
    const [ collectionView, setCollectionView ] = useState();

    const[ schema, setSchema] = useState();

    useEffect(()=> {

        setFormData({failureCode : '', decisionName : '', qty : ''})
        
        const schema = {
    
            name: "FailedLot",
            type: "BASE TABLE",
            searchAll: false,
    
            columns: [
    
                {
                    name: "failureCode",
                    type: "varchar(50)",
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "MomSysVwProcessingFailureCode",
                        nameColumn: ["decisionCode","decisionName"],
                        valueColumn: "decisionCode",
                        seperator: ": ",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    },
                    grid: {
                        columnOrder: 1,
                    },
                    visible: true,
                    width: "100%",
                    validation: false
                },
                {
                    name: "decisionName",
                    type: "varchar(50)",
                    hideInForm : true,
                    grid: {
                        columnOrder: 2,
                    },
                    visible: true,
                    width: "100%",
                    validation: false
                },
                {
                    name: "qty",
                    type: "float",
                    formOrder: 3,
                    formItemType: "float",
                    
                    grid: {
                        type : "number",
                        columnOrder: 3,
                    },
                    visible: true,
                    width: "100%",
                },
    
            ]
    
        };
        setSchema(schema);
    },[])


    useEffect(() => {

        let collectionView = new CollectionView(failedLotList);

        setCollectionView(collectionView);
        
    }, [failedLotList]);

    const onChangeForm = async ( changeFormData ) => {

        if(changeFormData.failureCode !== "") {

            if(changeFormData.failureCode !== formData?.failureCode) {

                let result = await getSpecific("MomSysUdtDecisionCode", changeFormData.failureCode);

                setFormData({...changeFormData , decisionName : result.decisionName });

            } else  {
                setFormData({...changeFormData });
            }
        }

    };

    const addFailedLot = ( formData ) => {

        if ( ! checkItemValidataion ( formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (formData.failureCode && formData.qty) {

            setFailedLotList ( ( failedLotList ) => {
    
                let lotList = cloneDeep ( failedLotList );
                
                let lotInList = false;
    
                for ( const lot of lotList ) {
    
                    if ( lot.failureCode === formData.failureCode ) {
                        lot.qty += parseFloat ( formData.qty );
                        lotInList = true;
    
                    }
    
                }
    
                if ( !lotInList ) {
    
                    lotList.push ( { ...formData, qty: parseFloat ( formData.qty ) } );
    
                }
                
                if ( onChangeFailedLotList ) {
    
                    onChangeFailedLotList ( lotList );
        
                }
    
                return lotList;
    
            } );
        }

    };

    const deleteFailedLot = ( ( grid, failedLotList ) => {

        let lotList = cloneDeep ( failedLotList );

        if ( ! grid || ! grid.selectedItems ) {

            return;

        }

        for ( const item of grid.selectedItems ) {

            lotList = lotList.filter ( ( lot ) => lot.failureCode !== item.failureCode );

        }

        setFailedLotList ( lotList );

        if ( onChangeFailedLotList ) {

            onChangeFailedLotList ( lotList );

        }

    } );

    const checkItemValidataion = ( formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "edit", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                if ( notifyWarning ) {

                    notifyWarning ( msg );

                }

                return false;

            }
            
        }

        return true;

    }

    const onGridInitialized = ( grid ) => {

        setGrid ( grid );

    }

    return (
        <>
            <BasicStaticGridScreenType                        
                id="product-fail-lot-list"
                // headerTitle={t("term.productfailedLotList")}
                schema={schema}
                itemsSource={collectionView}
                onInitialized={onGridInitialized}
            />
        </>
    )
}

export default EguFailedLot;
