import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import EguPopCompleteProcessDialog from "../dialogTypes/EguPopCompleteProcessDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function EguPopProduceEndButton(props) {

    const { id, schema, onSelectMst, items, onSuccess, onFailure, customizeErrorMsgCallback, onDetailSuccess, onTrigger, ...others } = props;

    const [open, setOpen] = useState(false);

    const [lotInfo, setLotInfo] = useState();

    useEffect(() => {

        if (onSelectMst) {
            
            setLotInfo(() => {
                let selectMstData = cloneDeep(onSelectMst);
                let lotInfo = selectMstData;

                lotInfo.lotNo = selectMstData.resultLotNo;
                lotInfo.bopMaterialName = selectMstData.materialName;

                delete lotInfo.resultLotNo;
                delete lotInfo.materialName;

                return lotInfo
            });

        }

    }, [onSelectMst]);

    const onProductionCompleteSuccess = (msg) => {

        if (onSuccess) {
            onSuccess(msg);
        }

    }


    const onOpen = () => {

        setOpen(true);
        onTrigger(true)
    }

    const onClose = () => {

        setOpen(false)
        onTrigger(false)
    }

    return (
        <>
            <CreateRecordButtonLayout createButtonName="produceEnd" id={`${id}-end-button`} onClick={onOpen} {...others} />
            <EguPopCompleteProcessDialog
                id = {id}
                lotInfo={lotInfo}
                mode="edit"
                open={open}
                onClose={onClose}
                onSuccess={onProductionCompleteSuccess}
                onFailure={onFailure} />
        </>
    )
}

export default EguPopProduceEndButton;