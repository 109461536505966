import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";

function MomSysMstProcess ( props ) {
    const id = 'MomSysMstProcess';

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysMstProcess");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return <BasicCrudScreenType 
        id={id}
        table="MomSysMstProcess" 
        view="MomSysZvwProcess"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        noCreate
        noEdit
        noDelete
        noExcel
        onAppendButtonInFrontOfSearch={() => (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <CreateRecordButton
                            id={id}
                            schema={schema}
                            refresh={api}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                        <UpdateRecordButton 
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            disabled={selectGrid ? false : true}
                            onSuccess={notifySuccess}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <DeleteRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            disabled={selectGrid ? false : true}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                        <SaveToExcelButton
                            id={id}
                            grid={grid}
                            view="EguVwMaterial"
                            color="primary"
                            disabled={selectGrid ? false : true}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )} 
    />;
}

export default MomSysMstProcess;
