import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysExpenseReport ( props ) {

    const onInitialized = (grid) => {

        grid.selectionMode = 'Row';

    };

    return (
        <BasicCrudScreenType 
            id="MomSysExpenseReport" 
            table="MomSysVwExpenseReport" 
            view="MomSysVwExpenseReport" 
            noCreate
            noEdit
            noDelete
            onInitialized={onInitialized}
            exportFormatItem={() => {}}
        />
    );

}

export default MomSysExpenseReport;
