import InventoryScreenType from "../screenTypes/functional/InventoryScreenType";

function ConSumablesInventoryManage(props) {

  return(
    <InventoryScreenType id ="ConsumablesInventory" table ="ConsumablesInOutput" view ="VwConsumablesInventory"/> 

  )
}
export default ConSumablesInventoryManage;
