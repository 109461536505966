import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem } from "@material-ui/core";

import apiClient from "../../lib/common/apiClient";
import defineConf from "../../config/defineConf";

import ApprovalButtonLayout from "../layout/ApprovalButtonLayout";
import ApprovalRequestDialog from "../dialogTypes/ApprovalRequestDialog";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

const useStyles = makeStyles(() => ({
    listItemText: {
        fontSize: "small"
    },
}));

function ApprovalButton ( props ) {

    const classes = useStyles ();

    const { t } = useTranslation ();

    const { id, grid, refresh, approvalCtx, onSuccess, onFailure, onApprovedCallback, onMenuOpen, onMenuClose, onDialogOpen, onDialogClose, customizeErrorMsgCallback, ...others } = props;


    const [ , setRequestDialog ] = useState ();
    const [ , setConfirmDialog ] = useState ();
    const [ operation, setOperation ] = useState ();
    const [ selectedItems, setSelectedItems ] = useState ();
    const [ approvalStateIds, setApprovalStateIds ] = useState ();
    const [ anchor, setAnchor ] = useState ();
    const [ openMenu, setOpenMenu ] = useState (false);

    const onOpenRequestDialog = () => {
        
        onCloseMenu ();

        // Check for no item selected

        if ( !grid ) {

            onFailure ( t("error.approvalTargetNotSelected") );
            return;

        }

        let selectedItems = grid.selectedItems;

        if ( !selectedItems || selectedItems.length < 1 ) {

            onFailure ( t("error.approvalTargetNotSelected") );
            return;

        }

        // Custom item validation

        for ( const selectedItem of selectedItems ) {

            // Do Custom Validation

            if ( approvalCtx.onValidateApprovalItem ) {

                let [ valid, msg ] = approvalCtx.onValidateApprovalItem ( selectedItem );

                if ( !valid ) {

                    onFailure ( msg );
                    return;

                }

            }

            // Check for already requested item
            
            if ( selectedItem.approvalState > 0 ) {

                onFailure ( t ( "error.approvalAlreadyRequested", { item: approvalCtx.makeRequestItemDescription ( selectedItem ) } ) );
                return;

            }

        }

        setSelectedItems ( selectedItems );

        setRequestDialog ( ( requestDialog ) => {

            if ( onDialogOpen ) {

                onDialogOpen ();

            }

            requestDialog.show ();
            return ( requestDialog );

        } )

    };

    const onOpenConfirmDialog = ( operation ) => {
        
        onCloseMenu ();

        let approvalStateIds = [];

        // Check for no item selected

        if ( !grid ) {

            onFailure ( t( "error.approvalTargetNotSelected" ) );
            return;

        }

        let selectedItems = grid.selectedItems;

        if ( !selectedItems || selectedItems.length < 1 ) {

            onFailure ( t( "error.approvalTargetNotSelected" ) );
            return;

        }

        // Custom item validation

        for ( const selectedItem of selectedItems ) {

            // Check for already approved and operation is not a cancel
            
            if ( selectedItem.approvalState === defineConf.approvalState.APPROVED && operation !== "cancel" ) {

                onFailure ( `${ t ( "error.cannotApproveApprovedApproval" ) } : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                return;

            }

            // Check for not requested approval

            if ( !selectedItem.approvalState ) {

                onFailure ( `${ t ( "error.noApprovalRequest" ) } : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                return;

            }


            // Check for not approved and operation is a cancel

            if ( selectedItem.approvalState !== defineConf.approvalState.APPROVED && operation === "cancel" ) {

                switch ( selectedItem.approvalState ) {

                    case defineConf.approvalState.PENDING:

                        onFailure ( `${ t ( "error.cannotCancelPendingApproval" ) } : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return;

                    
                    case defineConf.approvalState.PARTIALLY_APPROVED:

                        /*
                        
                        부분 승인된 대상에 대한 승인 취소 허용
                        onFailure ( `${ t ( "error.cannotCancelPartiallyApprovedApproval" ) } : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return;

                        */

                        break;

                    case defineConf.approvalState.APPROVED:

                        break;

                    case defineConf.approvalState.REJECTED:

                        onFailure ( `${t ( "error.cannotCancelRejectedApproval" )} : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return;

                    case defineConf.approvalState.WITHDRAWN:

                        onFailure ( `${t ( "error.cannotCancelWithdrawnApproval" )} : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return;

                    case defineConf.approvalState.CANCELLED:

                        onFailure ( `${t ( "error.cannotCancelCancelledApproval" )} : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return;

                    default:

                        onFailure ( `${t ( "error.invalidApprovalState" )} : ${ approvalCtx.makeRequestItemDescription ( selectedItem ) }` );
                        return

                }

            }

            approvalStateIds.push ( selectedItem.approvalStateId );

        }

        setApprovalStateIds ( approvalStateIds );

        setOperation ( operation );
        setConfirmDialog ( ( dialog ) => {

            if ( onDialogOpen ) {

                onDialogOpen ();

            }

            dialog.show ();
            return ( dialog );

        } )

    };

    const onRequestDialogInitialized = ( dialog ) => {

        setRequestDialog ( dialog );

    };

    const onConfirmDialogInitialized = ( dialog ) => {

        setConfirmDialog ( dialog );

    }

    const onApprovalRequested = () => {

        if ( refresh ) {

            refresh.refresh ();

        }

        if ( onSuccess ) {

            onSuccess ( t ( "success.approvalRequestSuccess") );

        }
        
    };

    const onOpenMenu = ( event ) => {

        if ( onMenuOpen ) {

            onMenuOpen ();

        }

        setAnchor ( event.currentTarget );
        setOpenMenu ( true );

    };

    const onCloseMenu = () => {

        if ( onMenuClose ) {

            onMenuClose ();

        }

        setOpenMenu ( false );
        setAnchor ( null );

    };

    const getConfirmDialogTitle = ( operation ) => {

        switch ( operation ) {

            case "approve":
                
                return t ( "dialog.title.Approval.approve" );

            case "reject":
                
                return t ( "dialog.title.Approval.reject" );

            case "withdraw":
                
                return t ( "dialog.title.Approval.withdraw" );

            case "cancel":
                
                return t ( "dialog.title.Approval.cancel" );

            default:

                return t ( "dialog.title.Approval.approve" );

        }

    };

    const getConfirmDialogMsg = ( operation ) => {

        switch ( operation ) {

            case "approve":
                
                return t ( "dialog.msg.confirm.approve" );

            case "reject":
                
                return t ( "dialog.msg.confirm.reject" );

            case "withdraw":
                
                return t ( "dialog.msg.confirm.withdraw" );

            case "cancel":
                
                return t ( "dialog.msg.confirm.cancel" );

            default:

                return t ( "dialog.msg.confirm.approve" );

        }

    };

    const getSuccessMsg = ( operation ) => {

        switch ( operation ) {

            case "approve":
                
                return t ( "success.approveApprovalSuccess" );

            case "reject":
                
                return t ( "success.rejectApprovalSuccess" );

            case "withdraw":
                
                return t ( "success.withdrawApprovalSuccess" );

            case "cancel":
                
                return t ( "success.cancelApprovalSuccess" );

            default:

                return t ( "success.approveApprovalSuccess" );

        }

    };

    const getFailureMsgCode = ( operation ) => {

        switch ( operation ) {

            case "approve":
                
                return "error.approveApprovalFailure";

            case "reject":
                
                return "error.rejectApprovalFailure";

            case "withdraw":
                
                return "error.withdrawApprovalFailure";

            case "cancel":
                
                return "error.cancelApprovalFailure";

            default:

                return "error.approveApprovalFailure";

        }

    };
    const onConfirmed = ( operation ) => {

        let url = "/api/ApproveApproval";

        switch ( operation ) {

            case "approve":
                
                url = "/api/ApproveApproval";
                break;

            case "reject":
                
                url = "/api/RejectApproval";
                break;

            case "withdraw":
                
                url = "/api/WithdrawApproval";
                break;

            case "cancel":
                
                url = "/api/CancelApproval";
                break;

            default:


        }

        setSelectedItems ( )

        setApprovalStateIds ( ( approvalStateIds ) => {

            let requestData = {
                approvalStateIds: [
                    ...approvalStateIds,
                ],
                tableName: approvalCtx.tableName,
            }
    
            apiClient.put ( url, requestData )
                .then ( ( result ) => {
    
                    if(refresh) {
                        refresh.refresh();
                    }

                    onSuccess ( getSuccessMsg ( operation ) );

                    if(onApprovedCallback && operation === "approve") {
                        setTimeout(() => onApprovedCallback(), 0);
                    }
    
                })
                .catch((reason) => {
                    
                    if (customizeErrorMsgCallback) {

                        onFailure(customizeErrorMsgCallback(reason));
                
                    } else {

                        onFailure(t(getFailureMsgCode(operation), { reason: reason.response ? reason.response.data : reason }))

                    }
                    
                });

            return approvalStateIds;
    
        } );

    };

    const onCloseDialog = () => {

        if ( onDialogClose ) {

            onDialogClose ();

        }

    };

    return (

        <>
            <ApprovalButtonLayout id={id?id+"-request-button":"approval-request-button"} data-cy="ApprovalButton" onClick={onOpenMenu} {...others} />
            <Menu
                id="approval-menu"
                data-cy="ApprovalMenu"
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={openMenu}
                onClose={onCloseMenu}
                PaperProps={{
                    square: true
                }}
            >

                <MenuItem className={classes.listItemText} data-cy="Request" onClick={onOpenRequestDialog}>{t("popupMenu.Approval.request")}</MenuItem>
                <MenuItem className={classes.listItemText} data-cy="Approve" onClick={() => onOpenConfirmDialog ( "approve" ) }>{t("popupMenu.Approval.approve")}</MenuItem>
                <MenuItem className={classes.listItemText} data-cy="Reject" onClick={() => onOpenConfirmDialog ( "reject" ) }>{t("popupMenu.Approval.reject")}</MenuItem>
                <MenuItem className={classes.listItemText} data-acy="Withdraw" onClick={() => onOpenConfirmDialog ( "withdraw" ) }>{t("popupMenu.Approval.withdraw")}</MenuItem>
                <MenuItem className={classes.listItemText} data-cy="Cancel" onClick={() => onOpenConfirmDialog ( "cancel" ) }>{t("popupMenu.Approval.cancel")}</MenuItem>

            </Menu>
            <ApprovalRequestDialog
                id={id?id+"-request-dialog":"approval-request-dialog"}
                approvalCtx={approvalCtx}
                selectedItems={selectedItems}
                onInitialized={onRequestDialogInitialized}
                onSuccess={onApprovalRequested}
                onApprovedCallback={onApprovedCallback}
                onClose={onCloseDialog}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
            <ConfirmDialog
                id={id?id+"-confirm-dialog":"approve-approval-confirm-dialog"}
                title={ getConfirmDialogTitle ( operation ) }
                msg={ getConfirmDialogMsg ( operation ) }
                data={approvalStateIds}
                onOk={ ( data ) => onConfirmed ( operation ) }
                onInitialized={onConfirmDialogInitialized}
                conClose={onCloseDialog}
            />
        </>

    );

}

export default ApprovalButton;

