import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import defineConf from "../../config/defineConf";
import { useRecoilValue } from 'recoil';
import authProfileState from "../../states/authProfileState";

function PopOrderPurchaseMaterialFakeInputDialog(props) {
    const {
        id,
        moveable,
        resizable,
        fullWidth,
        maxWidth,
        selectedItems,
        onClose,
        onInitialized,
        initialData,
        onSuccess,
        ...others
    } = props;

    const [schema, setSchema] = useState();

    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [formData, setFormData] = useState({});

    const authProfile = useRecoilValue(authProfileState);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [oldPrice, setOldPrice] = useState();

    useEffect(()=> {

        let schema = {

            name: "PopOrderPurchaseMaterialFakeInput",
            type: "BASE TABLE",
            searchAll: false,
            formGroups: [
                "info",
                "values",
            ],
            columns: [
    
                {
                    name: "inputInspectionId",
                    type: "varchar(50)",
                    nullable: "NO",
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",
                    defaultValue : "eguInspectionIdCode",
                    hideInForm : true
    
                },
                {
                    name: "orderPurchaseId",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 1,
                    formItemType: "text",
    
                },
                {
                    name: "orderPurchaseName",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 2,
                    formItemType: "text",
    
                },
                {
                    name: "materialCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 3,
                    formItemType: "text",
    
                },
                {
                    name: "materialCodeVer",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 4,
                    formItemType: "text",
    
                },
                {
                    name: "materialName",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 5,
                    formItemType: "text",
    
                },
                {
                    name: "description",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 7,
                    formItemType: "text",
    
                },
                {
                    name: "qtyFakeInput",
                    type: "varchar(50)",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 8,
                    formItemType: "text",
    
                },
    
                {
                    name: "manufacturedDate",
                    type: "date",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 10,
                    formItemType: "date",
    
                },
    
                {
                    name: "expirationDate",
                    type: "date",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 15,
                    formItemType: "date",
    
                },
    
                {
                    name: "fakeInputDate",
                    type: "date",
                    nullable: "NO",
                    readonly: true,
                    disabled :true,
                    formGroup: 0,
                    formOrder: 20,
                    formItemType: "datetime",
    
                },
                {
                    name: "inspector",
                    type: "varchar(50)",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 1,
                    formItemType: "autocomplete",
                    codeInfo : {
                        tableName: "User",
                        nameColumn: ["username", "fullName"],
                        valueColumn: "username",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    }
    
                },
                {
                    name: "inspectionDate",
                    type: "date",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 5,
                    formItemType: "datetime",
    
                },
                {
                    name: "qcResult",
                    type: "bingint(20)",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 10,
                    required : true,
                    formItemType : "select",
                    selectItems : [
                        { name: "selectItems.qcResult.OK", value: defineConf.qcResult.QC_OK },
                        { name: "selectItems.qcResult.NOK", value: defineConf.qcResult.QC_NOK },
                    ],
                },
                {
                    name: "defectiveCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 15,
                    required : true,
                    formItemType : "autocomplete",
                    codeInfo : {
                        tableName: "MomSysVwInputAndInspectionDecisionCode",
                        nameColumn: ["decisionCode", "decisionName"],
                        valueColumn: "decisionCode",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    },
                },
                {
                    name: "wareHouseCode",
                    type: "varchar(50)",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 20,
                    required : true,
                    formItemType : "autocomplete",
                    codeInfo : {
                        tableName: "MomSysVwInputOrFailureWareHouse",
                        nameColumn: ["wareHouseType", "wareHouseName", "wareHouseCode"],
                        useParenthesis: [false, false, true],
                        openParenthesis: " (",
                        closeParenthesis: ")",
                        valueColumn: "wareHouseCode",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    }
                },
                {
                    name: "price",
                    type: "double",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 21,
                    formItemType : "number",
                },
                {
                    name: "amount",
                    type: "double",
                    nullable: "NO",
                    formGroup: 1,
                    formOrder: 22,
                    formItemType : "number",
                },
            ]
        }

        setSchema(schema);

    },[]);

    useEffect(()=> {

        if(initialData) {
            setOldPrice(initialData.unitCost);

            setFormData((formData) => {

                let newFormData = {
                    ...initialData,
                    ...formData,
                    qcResult :defineConf.qcResult.QC_OK,
                    inspectionDate : new Date(),
                    inspector: authProfile.username,
                    inputInspectionId: "eguInspectionIdCode",
                    price: initialData.unitCost,
                    amount: initialData.qtyFakeInput * initialData.unitCost
                }
    
                return newFormData
    
            }); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initialData])

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {

        onNotifySuccess(result.data);

        dialogInstance.hide();

        setTimeout(() => onSuccess(result.data), 0);
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };  

    const checkItemValidation = (schema, formData) => {
        
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("edit", column, column.validation, formData[column.name]);

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }
        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url;

        url = `/api/${schema.name}`;

        let apiData = { ...formData };
        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData(null);

        dialogInstance.hide();
    };

    const onChangeFormData = (formData) => {
        let price = formData.price;

        if(oldPrice !== price) {
            setOldPrice(price);

            setFormData({
                ...formData, 
                amount: (formData.qtyFakeInput * formData.price).toFixed(4)
            });
        } else {
            setFormData({...formData});
        }
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData();

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.PopOrderPurchaseMaterialFakeInput.input")}</DialogTitle>
            </DialogHeaderWithClose>
            <Divider />
            <DialogContent>
                <div style={{ padding: "16px" }}>
                    <AutoForm
                        id="pop-orderpurchase-material-fakeinput-form"
                        schema={schema}
                        mode="edit"
                        initialData={formData}
                        onChange={onChangeFormData}
                        modifyFormDataCallback={(formData) => {
                            if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                formData.defectiveCode = "INS000";
                            }
                        }}
                    />
                </div>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                //labels={[t("common.add")]}
                buttonDisabled={[false, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PopOrderPurchaseMaterialFakeInputDialog;
