/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import EguUpdateLotInfoButton from "../input/EguUpdateLotInfoButton";
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";

function MomSysMaterialStatusDetail ( props ) {

    const {

        id,
        view,
        material,
        
    } = props;


    const [api, setApi] = useState();

    const { t } = useTranslation ();

    const [schema, setSchema] = useState();

    const [grid, setGrid] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(()=> {

        let schema = {

            name: "MomSysSgtLot",
            type: "BASE TABLE",
            searchAll: false,
            formGroups : ["info"],
            useFormOrder : true,

            columns: [

                {

                    name : "lotNo",
                    type : "bigInt(10)",
                    nullable : "NO",
                    key: "PRI",
                    disabled : true,
                    formOrder: 1,
                    formItemType : "text",

                },
                // {

                //     name : "extLotNo",
                //     type : "varchar(50)",
                //     nullable : "NO",
                //     formOrder: 2,
                //     formItemType : "text",
                    

                // },
                {

                    name : "expirationDate",
                    type : "datetime(6)",
                    nullable : "NO",
                    formOrder: 3,
                    formItemType : "date",
                    

                },
                {

                    name : "manufacturedDate",
                    type : "datetime(6)",
                    nullable : "NO",
                    formOrder: 4,
                    formItemType : "date",
                }
            ]

        }

        setSchema(schema);

    },[]);


    useEffect(()=>{

        if ( api ) {

            api.refresh ();

        }

    },[material])


    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);
    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    return (
        <BasicCrudScreenType 
            id={id}
            table={id}
            view={view}
            headerTitle={t("term.standardMaterialInfo")}
            onInitialized = {onInitialized}
            onApiInitialized={onApiInitialized}
            onModifyViewUrl ={ (url) => (`${url}/material/${material.materialCode}/${material.materialCodeVer}`)}
            onLoadData={onLoadData}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch = {
                ()=> (
                    <>
                        <BasicCrudScreenActionItemLayout>

                            <EguUpdateLotInfoButton
                                schema= {schema}
                                grid = {grid}
                                refresh = {api}
                                disabled = {isEmpty}
                                onSuccess={onSuccess}
                            />


                        </BasicCrudScreenActionItemLayout>
                    </>
                )
            }
        />
    )

}

export default MomSysMaterialStatusDetail;
