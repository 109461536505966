import { useEffect, useState } from "react";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguOutSourcingShipActionDialog from "../dialogTypes/EguOutSourcingShipActionDialog";

function EguOutSourcingShipActionButton(props) {
    const {
        id,
        schema,
        outSourcingShipMst,
        refresh,
        onUpdateMaster,
        onSuccess,
        createButtonName,
        customizeErrorMsgCallback,
        ...others
    } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-create-button"}
                onClick={onOpen}
                createButtonName="materialOutput"
                {...others}
            />
            <EguOutSourcingShipActionDialog
                id={id + "-shipAction-dialog"}
                schema={schema}
                outSourcingShipMst={outSourcingShipMst}
                open={open}
                onClose={onClose}
                onSuccess={onShipSuccess}
            />
        </>
    );
}

export default EguOutSourcingShipActionButton;
