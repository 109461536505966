/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function SplitTaskDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState ({splitMethod: "qty"});

    const [, setTask] = useState ();

    const [actionButtonsDisabled, setActionButtonsDisabled] = useState ([true, false]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        task,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,
        setIsSplitTask,

        ...others

    } = props;

    useEffect ( () => {

        setFormData ( ( formData ) => {

            if ( task ) {

                formData = {

                    splitMethod: "qty",
                    qty: task.qty,
                    duration: task.durationInHours
    
                }
    
                checkButtonEnabled ( task, formData );
    
            }

            return formData;

        } );
        
        setTask ( task );

    }, [task] );

    const schema = {

        name: "SplitTask",
        type: "BASE TABLE",
        searchAll: false,

        columns: [

            {
                name: "splitMethod",
                required: true,
                formOrder: 1,
                formItemType: "radio",
                row: true,
                formControlStyle: { margin: 0, paddingTop: 0},
                selectItems: [
                    {
                        name: t ( "columnName.qty" ),
                        value: "qty"
                    },
                    {
                        name: t ( "columnName.duration" ),
                        value: "duration"
                    },
                ],
                defaultValue: "taskCount",
                visible: true,
                width: "100%",
            },
            {
                name: "qty",
                type: "float",
                formOrder: 2,
                formItemType: "text",
                visible: true,
            },
            {
                name: "duration",
                type: "number",
                formOrder: 3,
                formItemType: "text",
                visible: true,
            },
            {
                name: "splitSingle",
                type: "number",
                formOrder: 4,
                formItemType: "checkbox",
                defaultValue: 0,
                visible: true,
            },

        ]

    };

    const checkDisabledCallback = ( formData, column ) => {

        switch ( column.name ) {

            case "splitMethod":
            case "splitSingle":

                return false;

            default:

                return formData.splitMethod !== column.name;

        }

    }

    const checkButtonEnabled = ( task, formData ) => {

        if ( task && formData ) {

            switch ( formData.splitMethod ) {

                case "qty":

                    setActionButtonsDisabled ([ !formData.qty || parseFloat ( formData.qty ) >= task.qty || parseFloat ( formData.qty ) <= 0 || isNaN ( formData.qty ), false ]);
                    break;
    
                case "duration":
    
                    setActionButtonsDisabled ([ !formData.duration || parseInt ( formData.duration ) >= task.durationInHours || parseInt ( formData.duration ) < 1 || isNaN ( formData.duration ), false ]);
                    break;
    
                default:
    
                    setActionButtonsDisabled ([ true, false ]);
                    break;

            }
    
        } else {

            setActionButtonsDisabled ([ true, false ]);

        }

        return;

    }

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };
    
    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result ) => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

        if ( onSuccess ) {

            onSuccess ( t ( "success.splitTaskSuccess") );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.splitTaskFailed" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setFormData ( ( formData ) => {


            setTask ( ( task ) => {

                switch ( formData.splitMethod ) {

                    case "qty":
    
                        if ( parseFloat ( formData.qty ) >= parseFloat ( task.qty ) ) {

                            notifyWarning ( t ( "error.splitQtyTooLarge" ) );
                            return task;

                        }

                        break;
    
                    case "duration":

                        if ( parseInt ( formData.duration ) >= parseInt ( task.durationInHours ) ) {

                            notifyWarning ( t ( "error.splitDurationTooLarge" ) );
                            return task;
                            
                        }

                        break;
        
                    default:
    
                        return task;
    
                }

                let planId = task.id;
    
                let apiData = formData;
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};
    
                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );

                apiClient
                    .put(`/api/EguPlan/${planId}/split`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => onApiSuccess(result))
                    .catch((reason) => onApiFailure(apiData, reason));
            
                return task;
    
            } );

            return formData;

        } );

        setIsSplitTask(true);

    }

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const onChangeForm = ( formData ) => {

        setTask ( ( task ) => {

            checkButtonEnabled ( task, formData );
            return task;

        } );

        setFormData ( formData );

    }

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="xs"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.splitTask", { taskName: task?.taskName, duration: task?.durationInHours } ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent>

                <AutoForm
                    id="split-task-form"
                    initialData={formData}
                    schema={schema}
                    mode="edit"
                    checkDisabledCallback={checkDisabledCallback}
                    onChange={ onChangeForm }
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                buttonDisabled={ actionButtonsDisabled }
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default SplitTaskDialog;
