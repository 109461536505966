import { FlexPie, FlexChartLegend, FlexPieDataLabel } from '@grapecity/wijmo.react.chart';
import { FlexChartAnimation } from '@grapecity/wijmo.react.chart.animation';

import format from 'format-number';

function DashWijmoPieChart (props) {

    const innerRadius = 0.5;

    const { name, value, data, palette, innerText, innerTextStyle } = props;

    const getLabelContent = ( item ) => {

        if ( item.value >= 100000000 ) {

            return format({ round: 2, suffix: ' 억'})(item.value / 100000000);

        }

        if ( item.value >= 10000 ) {

            return format({ round: 2, suffix: ' 만'})(item.value / 10000);

        }

        return format({})(item.value);

    };

    const makeTooltip = ( hitTestInfo ) => {

        let name = hitTestInfo.name;
        let value = hitTestInfo.value; 

        if ( value >= 100000000 ) {

            value = format({ round: 2, suffix: ' 억'})(value / 100000000);

        } else if ( value >= 10000 ) {

            value = format({ round: 2, suffix: ' 만'})(value / 10000);

        } else {

            value = format({ round: 2 })(value);        

        }

        return `<b>${name} : </b> <span>${value}</span>`;

    }

    return (

        data && data.length > 0
        ?
        <FlexPie bindingName={name} binding={value} innerRadius={innerRadius} itemsSource={data} palette={palette} tooltipContent={makeTooltip} innerText={innerText} innerTextStyle={innerTextStyle}>
            <FlexChartLegend position="Top">
            </FlexChartLegend>
            <FlexPieDataLabel position="Outside" content={getLabelContent}></FlexPieDataLabel>
            <FlexChartAnimation animationMode="All" easing="EaseInQuad" duration={400}>
            </FlexChartAnimation>
        </FlexPie>
        :
        <></>
    );
}

export default DashWijmoPieChart;
