import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";

import notificationState from "../../states/notificationState";

import AccessControl from "../functional/AccessControl";

import CreateRecordButton from "../input/CreateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import UpdateRecordButton from "../input/UpdateRecordButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysNsMstMaterial(props) {
    const { id } = props;

    const [schema, setSchema] = useState();
    const [Api, setApi] = useState();
    const [grid, setGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        
        let mounted = true;

        (async () => {
            
            let schema = await getSchema("MomSysNsMstMaterial");

            if ( mounted ) {

                setSchema(schema);

            }

        })();

        return ( () => {

            mounted = false;

        } );

    }, []);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="MomSysNsMstMaterial"
                view="MomSysNsZvwMaterial"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                noCreate
                noEdit
                noDelete
                noExcel
                headerTitle={t("term.nonStandard")}
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="POST"
                            >
                                <CreateRecordButton
                                    id={id}
                                    schema={schema}
                                    refresh={Api}
                                    onSuccess={onSuccess}
                                />
                            </AccessControl>

                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod={
                                    schema &&
                                    (schema.updateWithPatch ? "PATCH" : "PUT")
                                }
                            >
                                <UpdateRecordButton
                                    id={id}
                                    schema={schema}
                                    grid={grid}
                                    refresh={Api}
                                    onSuccess={onSuccess}
                                />
                            </AccessControl>

                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="DELETE"
                            >
                                <DeleteRecordButton
                                    id={id}
                                    schema={schema}
                                    grid={grid}
                                    refresh={Api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                />
                            </AccessControl>

                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="SAVETOEXCEL"
                            >
                                <SaveToExcelButton
                                    id={id}
                                    grid={grid}
                                    view="MomSysNsZvwMaterial"
                                    color="primary"
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default MomSysNsMstMaterial;
