import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysCreateStockTakingDialog from "../dialogTypes/MomSysCreateStockTakingDialog";

function MomSysCreateStockTakingDetailButton ( props ) {

    const { id, schema, refresh, onSuccess, createButtonName, stockTakingId ,customizeErrorMsgCallback, ...others } = props;

    const [modifiedSchema, setModifiedSchema] = useState();

    const [selectMaterial, setSelectMaterial] = useState([]);

    const [ open, setOpen ] = useState(false);

    useEffect(() => {

        if(schema) {

            let modifiedSchema = cloneDeep(schema);

            delete modifiedSchema.formGroups;
            for(const column of modifiedSchema.columns) {

                switch(column.name) {

                    case "stockTakingId" : 
                    case "lotNo" : 
                    case "workState" :
                    case "qty" :
                        column.hideInForm = true;
                        break;

                    case "wareHouseCode" :
                        column.hideInForm = false;
                        column.formGroup = 0;
                        column.formOrder = 10;
                        break;

                    case "qtyDueDiligence" :
                        column.formGroup = 0;
                        column.formOrder = 20;
                        break;

                    case "description" :
                        column.formGroup = 0;
                        column.formOrder = 30;
                        break;

                    case "manufacturedDate":
                        column.formOrder = 23;
                        column.formGroup = 0;
                        column.formItemType = "date";
                        break;

                    case "expirationDate":
                        column.formOrder = 26;
                        column.formGroup = 0;
                        column.formItemType = "date";
                        break;

                    // case "extLotNo":
                    //     column.formOrder = 28;
                    //     column.formGroup = 0;
                    //     column.formItemType = "autocompleteSolo";
                    //     column.readonly = false;

                    //     column.codeInfo = {
                    //         tableName: `MomSysVwExistExtLotNo/${selectMaterial.materialCode}/${selectMaterial.materialCodeVer}`,
                    //         nameColumn:  "extLotNo",
                    //         valueColumn: "extLotNo",
                    //         useEmptyValue: false,
                    //         sortByName: true,
                    //     }
                    //     break;

                    default :
                        break;
                }

            }

            setModifiedSchema(modifiedSchema);

        }



    },[schema,selectMaterial])

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    const onSelectMaterial = (material) => {

        setSelectMaterial(material)

    }

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <MomSysCreateStockTakingDialog
                id={id+"create-dialog"}
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                stockTakingId = {stockTakingId}
                selectMaterial = {onSelectMaterial}
            />
        </>

    );
}

export default MomSysCreateStockTakingDetailButton;

