/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { CollectionView } from "@grapecity/wijmo";
import { cloneDeep } from "lodash";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import getList from "../../lib/api/getList";
import getSchema from "../../lib/api/getSchema";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import BasicStaticGridScreenTypeWithoutFooter from "../screenTypes/functional/BasicStaticGridScreenTypeWithoutFooter";

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
      maringTop: "5px",
      marginBottom: "5px"
    },
}));

function AssignWorkerDialog ( props ) {

    const classes = useStyles();

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();

    const [, setTask] = useState ();
    const [, setOrgAssigned] = useState ([]);
    const [assigned, setAssigned] = useState ([]);
    const [notAssigned, setNotAssigned] = useState ([]);

    const [assignedSchema, setAssignedSchema] = useState ();
    const [notAssignedSchema, setNotAssignedSchema] = useState ();
    
    const [assignedCollectionView, setAssignedCollectionView] = useState ();
    const [notAssignedCollectionView, setNotAssignedCollectionView] = useState ();
    
    const [, setAssignedGrid] = useState ();
    const [, setNotAssignedGrid] = useState ();

    const [actionButtonsDisabled, setActionButtonsDisabled] = useState ([true, false]);
    const [assignDisabled, setAssignDisabled] = useState (true);
    const [unAssignDisabled, setUnAssignDisabled] = useState (true);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        task,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {

        let mounted = true;

        ( async () => {
        
            let assignedSchema = await getSchema("VwProcessPlanWorkerAssignment");

            if ( mounted ) {

                setAssignedSchema ( assignedSchema )

            }
    
            let notAssignedSchema = await getSchema("VwProcessPlanNotAssignedWorker");

            if ( mounted ) {
                
                setNotAssignedSchema ( notAssignedSchema )

            }
    
        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    useEffect ( () => {

        if ( task ) {

            ( async () => {

                let result;
    
                result = await getList ( `VwProcessPlanWorkerAssignment/${task.id}` );

                let assigned = result.data.sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                if ( assigned.length > 0 ) {

                    setUnAssignDisabled ( false );
                    setOrgAssigned ( cloneDeep ( assigned ) );

                }

                setAssigned ( assigned );
                        
                result = await getList ( `VwProcessPlanNotAssignedWorker/${task.id}` );

                let notAssigned = result.data.sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                if ( notAssigned.length > 0 ) {

                    setAssignDisabled ( false );

                }

                setNotAssigned ( notAssigned );
    
            } ) ();
    
        }

        setTask ( task );

    }, [task] );

    useEffect ( () => {

        let collectionView = new CollectionView(assigned)
        setAssignedCollectionView ( collectionView );                                        
        checkActionButtonDisabled ();

    }, [assigned]);

    useEffect ( () => {

        let collectionView =  new CollectionView ( notAssigned );
        setNotAssignedCollectionView ( collectionView );

    }, [notAssigned]);

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onAssignedGridInitialized = ( grid ) => {

        setAssignedGrid ( grid );

    };

    const onNotAssignedGridInitialized = ( grid ) => {

        setNotAssignedGrid ( grid );

    };

    const onApiSuccess = (result, taskId, workers) => {
        
        setOrgAssigned ([]);
        setAssigned ([]);

        if ( onSuccess ) {

            onSuccess ( t ( "success.assignWorkerSuccess"), taskId, workers );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.assignWorkerFailed" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setTask ( ( task ) => {
        
            setAssigned ( ( assigned ) => {

                let planId = task.id;
    
                let apiData = {
                    workers: assigned.map ( ( worker ) => worker.username ),
                };
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};
    
                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );
                
                apiClient
                    .put(`/api/EguPlan/${planId}/workers`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => onApiSuccess(result, planId, apiData.workers))
                    .catch((reason) => onApiFailure(apiData, reason));
    
                return assigned;
    
            } );
    
            return task;

        } );

    }

    const onCancel = () => {

        setOrgAssigned ([]);
        setAssigned ([]);

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const checkActionButtonDisabled = () => {

        setOrgAssigned ( ( orgAssigned ) => {

            setAssigned ( ( assigned ) => {

                if ( orgAssigned.length !== assigned.length ) {

                    setActionButtonsDisabled ( [false, false] );

                } else {

                    let disabled = true;

                    for ( let i = 0; i < orgAssigned.length; i++ ) {

                        if ( orgAssigned[i].username !== assigned[i].username ) {

                            disabled = false;
                            break;

                        }

                    }

                    setActionButtonsDisabled ( [disabled, false] );

                }

                return assigned;

            } );

            return orgAssigned;

        } );

    };

    const onClickAssign = () => {

        setNotAssignedGrid ( ( grid ) => {

            let selectedItem = grid.selectedItems[0];

            setNotAssigned ( ( notAssigned ) => {

                let idx = notAssigned.findIndex ( ( item ) => ( item.username === selectedItem.username ) );

                let newNotAssigned = notAssigned.slice ( 0, idx ).concat ( notAssigned.slice ( idx + 1 ) ).sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                if ( newNotAssigned.length  === 0 ) {

                    setAssignDisabled ( true );

                } else {
                    
                    setAssignDisabled ( false );

                }

                setAssigned ( ( assigned ) => {

                    let newAssigned = [...assigned];

                    newAssigned.push ( selectedItem );

                    if ( newAssigned.length  === 0 ) {

                        setUnAssignDisabled ( true );

                    } else {

                        setUnAssignDisabled ( false );

                    }

                    return newAssigned.sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                } );

                return newNotAssigned;

            } );

            return grid;

        } );

    }
    
    const onClickUnAssign = () => {

        setAssignedGrid ( ( grid ) => {

            let selectedItem = grid.selectedItems[0];

            setAssigned ( ( assigned ) => {

                let idx = assigned.findIndex ( ( item ) => ( item.username === selectedItem.username ) );

                let newAssigned = assigned.slice ( 0, idx ).concat ( assigned.slice ( idx + 1 ) ).sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                if ( newAssigned.length  === 0 ) {

                    setUnAssignDisabled ( true );

                } else {

                    setUnAssignDisabled ( false );

                }

                setNotAssigned ( ( notAssigned ) => {

                    let newNotAssigned = [...notAssigned];

                    newNotAssigned.push ( selectedItem );

                    if ( newNotAssigned.length  === 0 ) {

                        setAssignDisabled ( true );
    
                    } else {
                        
                        setAssignDisabled ( false );
    
                    }

                    return newNotAssigned.sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                } );

                return newAssigned;

            } );

            return grid;

        } );

    }

    const onClickUnAssignAll = () => {

        setAssignedGrid ( ( grid ) => {

            setAssigned ( ( assigned ) => {

                setNotAssigned ( ( notAssigned ) => {

                    let newNotAssigned = [...notAssigned];

                    for ( let item of assigned ) {

                        newNotAssigned.push ( item );

                    }

                    if ( newNotAssigned.length  === 0 ) {

                        setAssignDisabled ( true );
    
                    } else {
                        
                        setAssignDisabled ( false );
    
                    }

                    return newNotAssigned.sort ( ( a, b ) => a.username.localeCompare ( b.username ) );

                } );

                setUnAssignDisabled ( true );

                return []

            } );

            return grid;

        } );
    }

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.assignWorker", { taskName: task?.taskName } ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "710px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.45} >

                        <BasicStaticGridScreenTypeWithoutFooter                        
                            id="not-assigned-grid"
                            headerTitle={t("term.notAssigned")}
                            headerTitleStyle={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginLeft: "8px",
                                marginTop: "8px",
                            }}
                            allowSorting={0}
                            schema={notAssignedSchema}
                            itemsSource={notAssignedCollectionView}
                            onInitialized={onNotAssignedGridInitialized}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement>

                        <ReflexContainer orientation="vertical">

                            <ReflexElement flex={0.18}>

                                <div className={classes.outerContainer}>
                                    <div className={classes.buttonsContainer}>

                                        <ActionButtonLayout
                                            className={classes.button}
                                            color="primary"
                                            variant="outlined"
                                            disabled={assignDisabled}
                                            onClick={onClickAssign}
                                        >
                                            &gt;
                                        </ActionButtonLayout>

                                        <ActionButtonLayout
                                            className={classes.button}
                                            color="primary"
                                            variant="outlined"
                                            disabled={unAssignDisabled}
                                            onClick={onClickUnAssign}
                                        >
                                            &lt;
                                        </ActionButtonLayout>

                                        <ActionButtonLayout
                                            className={classes.button}
                                            color="primary"
                                            variant="outlined"
                                            disabled={unAssignDisabled}
                                            onClick={onClickUnAssignAll}
                                        >
                                            &lt;&lt;
                                        </ActionButtonLayout>

                                    </div>
                                </div>


                            </ReflexElement>

                            <ReflexSplitter
                                style={{border: "none",width: "1px",color:"lightgray"}}
                            />

                            <ReflexElement>

                                <BasicStaticGridScreenTypeWithoutFooter                        
                                    id="assigned-grid"
                                    embededInOtherScreenType
                                    headerTitle={t("term.assigned")}
                                    headerTitleStyle={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        marginLeft: "8px",
                                        marginTop: "8px",
                                    }}
                                    allowSorting={0}
                                    schema={assignedSchema}
                                    itemsSource={assignedCollectionView}
                                    onInitialized={onAssignedGridInitialized}
                                />

                            </ReflexElement>
                        
                        </ReflexContainer>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                buttonDisabled={ actionButtonsDisabled }
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default AssignWorkerDialog;
