import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as dateFnsLocales from "date-fns/locale";
import { useTranslation } from 'react-i18next';
import getNewDateObj from "../../../lib/common/getNewDateObj";

function FormItemMonth(props) {

    const { t } = useTranslation();
    const { id, className, column, helperText, InputLabelProps, onChange, value, ...others } = props;

    let dateFieldProps = {
        onChange: (date) => {

            date?.setHours(10, 0, 0, 0);
            date?.setDate(15)
            onChange(date);

        },
        ...others
    };

    if ( value !== "" ) {

        dateFieldProps.value = getNewDateObj ( value );

    } else {
            
        dateFieldProps.value = null;
    
    }


    if (column.InputLabelProps) {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps, ...column.InputLabelProps };

    } else {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps };

    }

    if (column.placeholder) {

        dateFieldProps.placeholder = t(column.placeholder);

    }

    if (column.defaultValue) {

        dateFieldProps.defaultValue = column.defaultValue;

    }

    if (column.variant) {

        dateFieldProps.variant = column.variant;

    }

    if (column.inputProps) {

        dateFieldProps.inputProps = column.inputProps;

    }

    if (column.InputProps) {

        dateFieldProps.InputProps = column.InputProps;

    }

    if (column.style) {

        dateFieldProps.style = column.style;

    }

    if (column.disableFuture) {

        dateFieldProps.disableFuture = column.disableFuture;

    }

    if (column.disablePast) {

        dateFieldProps.disablePast = column.disablePast;

    }

    if (column.minDate) {

        dateFieldProps.minDate = column.minDate;

    }

    if (column.maxDate) {

        dateFieldProps.maxDate = column.maxDate;

    }

    if (column.required) {

        dateFieldProps.required = column.required;

    }

    return (
        <MuiPickersUtilsProvider locale={dateFnsLocales[t("date.localeName")]} utils={DateFnsUtils}>
            <FormControl className={className} {...others}>
                <KeyboardDatePicker
                    autoOk
                    views={["year", "month"]}
                    variant="inline"
                    disableToolbar
                    format={t("date.monthFormat")}
                    margin="normal"
                    helperText={helperText}
                    {...InputLabelProps}
                    {...dateFieldProps}
                    style={{ marginTop: "0px" }}
                />
            </FormControl>
        </MuiPickersUtilsProvider>

    );

}

export default FormItemMonth;
