import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    Snackbar,
    Divider
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";


import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";


import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import layoutConf from "../../config/layoutConf";

import MomSysBop from "../screens/MomSysBop";
import getSchema from "../../lib/api/getSchema";

function EguIngredientRegistrationAutoBomInput(props) {


    const {
        id,
        mode,

        refresh,
        selectMst,
        initialData,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,
        maxWidth,
        platform,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,
        customizeErrorMsgCallback,

        ...others
    } = props;

    const [, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [instance, setInstance] = useState();

    const [onSelectMst, setOnSelectMst] = useState();

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();

    const [height, setHeight] = useState(0);

    const [process, setProcess] = useState();

    const [schema, setSchema] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });


    const onGridInitialized = (grid) => {
        grid.selectionMode = "Row";

        setGrid(grid);
    };

    const onApiInitialized = (api) => {
        setApi(api);
    };
    useEffect(() => {

        let mounted = true;

        const getSchemaInfo = async () => {

            let schema = await getSchema("MomSysUdtBillOfMaterial");

            if (mounted) {

                for (let column of schema.columns) {

                    switch (column.name) {

                        case "description":

                            column.width = "94%";
                            break;

                        default:

                            break;

                    }

                }

                let bomMaterialNameColumn = {};

                bomMaterialNameColumn.name = "bomMaterialCode";

                bomMaterialNameColumn.formGroup = 0;
                bomMaterialNameColumn.formOrder = 3;

                bomMaterialNameColumn.type = "text";

                bomMaterialNameColumn.readonly = true;
                bomMaterialNameColumn.disabled = true;

                schema.columns.push(bomMaterialNameColumn);

                let processCodeColumn = {};

                processCodeColumn.name = "processCode";

                processCodeColumn.formGroup = 1;
                processCodeColumn.formOrder = 4;

                processCodeColumn.type = "text";

                processCodeColumn.readonly = true;
                processCodeColumn.disabled = true;

                schema.columns.push(processCodeColumn);

                let processNameColumn = {};

                processNameColumn.name = "processName";

                processNameColumn.formGroup = 1;
                processNameColumn.formOrder = 5;

                processNameColumn.type = "text";

                processNameColumn.readonly = true;
                processNameColumn.disabled = true;

                schema.columns.push(processNameColumn);

                setSchema(schema);

            }

        };

        getSchemaInfo();

        return (() => {
            mounted = false;
        });

    }, []);

    useEffect(() => {
        if (selectMst) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,
                    captionId: selectMst?.captionId,
                }
                return newFormData
            })
        }
    }, [selectMst])

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst(grid.selectedItems[0]);
                    }
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst(grid.selectedItems[0]);
                    }
                });
            }
        })();
    }, [grid]);

    useEffect(() => {

        setFormData((init) => {

            let newInit = {
                ...init,
                bomMaterialCode: process?.bopMaterialCode,
                bomMaterialCodeVer: process?.bopMaterialCodeVer,
                processCode: process?.processCode,
            }

            return newInit;
        })

    }, [process])


    const { t } = useTranslation();

    const onCloseDialog = () => {

        setDisabled(true);

        if (onClose) {

            onClose();

        }
    };

    const onCancel = () => {

        setDisabled(true);

        instance.hide();

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const refreshed = (msg) => {

        notifySuccess(msg);

        if (refresh) {

            refresh.refresh();
        }

        if (api) {

            api.refresh();

        }
        if (instance) {

            instance.hide();
        }
    };


    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    // const nofifyWarning = (msg) => {
    //     console.warn(msg);

    //     setNotification({
    //         severity: "warning",
    //         msg: msg,
    //         open: true,
    //     });
    // };

    const onApiSuccess = (result) => {

        refreshed()

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

    };

    const onApiFailure = (reason) => {
        if (customizeErrorMsgCallback) {
            notifyFailure(customizeErrorMsgCallback(reason))
        } else {

            notifyFailure(
                t("error.insertFailure", {
                    reason: reason.response ? reason.response.data : JSON.stringify(reason),
                })
            );
        }
    };

    // const checkItemValidation = (schema, formData) => {

    //     for (const column of schema.columns) {
    //         let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

    //         if (!valid) {

    //             nofifyWarning(msg);
    //             return false;

    //         }
    //     }

    //     return true;
    // };

    const onOk = (schema, formData) => {

        let url = `/api/EguIngredientRegistration/AutoBom/${selectMst?.captionId}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        if (schema && !schema.columnMap) { //autoFormDialog columnMap 생성부분 

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }
            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onSelectProcess = (select) => {
        setProcess(select)
    }

    return (
        <>
            <Dialog
                id={id}
                title={title}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                {uiConf.modalWithCloseButton ? (
                    <DialogHeaderWithClose>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeaderWithClose>
                ) : (
                    <DialogHeader>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeader>
                )}

                <Divider />

                <DialogContent style={{ height: height }}>
                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={0.5}>

                            <ReflexContainer orientation="horizontal">

                                <ReflexElement>

                                    <BasicCrudScreenType
                                        id="MomSysZvwProduceableMaterial"
                                        headerTitle={t("term.outputMaterial")}
                                        table="MomSysZvwProduceableMaterial"
                                        view="MomSysZvwProduceableMaterial"
                                        noCreate
                                        noEdit
                                        noDelete
                                        noExcel
                                        onInitialized={onGridInitialized}
                                        onApiInitialized={onApiInitialized}
                                    />

                                </ReflexElement>

                            </ReflexContainer>

                        </ReflexElement>

                        <ReflexSplitter
                            style={{ width: layoutConf.reflex.splitterWidth }}
                        />

                        <ReflexElement>

                            <ReflexContainer orientation="horizontal">

                                <ReflexElement>

                                    {/* <BasicCrudScreenType
                                        id="MomSysZvwBillOfProcess"
                                        headerTitle={t("term.bop")}
                                        table="MomSysUdtBillOfProcess"
                                        view="MomSysVwOrderedBillOfProcess"
                                        noCreate
                                        noEdit
                                        noDelete
                                        noExcel
                                        onModifyUrl={onModifyUrl}
                                    /> */}
                                    <MomSysBop product={onSelectMst}
                                        onSelect={onSelectProcess}
                                    // onChange={onChangeProcess}
                                    />

                                </ReflexElement>

                            </ReflexContainer>

                        </ReflexElement>

                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActionsOkCancel
                    labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                    buttonDisabled={[false, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                />
            </Dialog>
        </>
    );
}

export default EguIngredientRegistrationAutoBomInput;
