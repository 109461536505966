/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import SaveToExcelButton from "../input/SaveToExcelButton";
import GroupByColumnPicker from "../input/GroupByColumnPicker";
import DateRangeSelector from "../input/DateRangeSelector";

function MomSysProductionTime ( props ) {

    const [grid, setGrid] = useState();
    const [orderBy, setOrderBy] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [view, setView] = useState("MomSysVwProductionTime");
    const [disableExcelButton, setDisableExcelButton] = useState(false);

    const [groupBy, setGroupBy] = useState([

        { name: "lotNo", value: true },
        { name: "material", value: true },
        { name: "process", value: true },
        { name: "machine", value: true },
        { name: "worker", value: true },

    ] );

    const [groupByFields, setGroupByFields] = useState ( [

        "lotNo",
        "materialCode",
        "materialCodeVer",
        "materialName",
        "processCode",
        "processName",
        "machineCode",
        "machineName",
        "worker",   
        "workerName",   

    ] );

    const onDateRangeChanged = ( from, to ) => {

        setFrom ( from );
        setTo ( to );

    }

    const checkItemSelected = ( groupBy, name ) => {

        return groupBy[groupBy.findIndex ( ( item ) => item.name === name )].value;

    }

    const onGroupByChanged = ( groupBy, orderBy ) => {

        let selectedCount = 0;

        for ( const item of groupBy ) {

            if ( item.value ) {

                selectedCount++;

            }

        }

        if ( selectedCount === 0 ) return;

        let newGroupBy = [...groupBy];
        let newGroupByFields = [];

        for ( const item of groupBy ) {
                
            if ( item.name === "lotNo" && item.value ) {

                newGroupBy = [

                    { name: "lotNo", value: true },
                    { name: "material", value: true },
                    { name: "process", value: true },
                    { name: "machine", value: true },
                    { name: "worker", value: true },            

                ];
    
                newGroupByFields = [
    
                    "lotNo",
                    "materialCode",
                    "materialCodeVer",
                    "materialName",
                    "processCode",
                    "processName",
                    "machineCode",
                    "machineName",
                    "worker",   
                    "workerName",   
                ];
                
                break;
    
            } else {

                switch ( item.name ) {

                    case "material":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "materialCode" );
                            newGroupByFields.push ( "materialCodeVer" );
                            newGroupByFields.push ( "materialName" );

                        }

                        break;

                    case "process":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "processCode" );
                            newGroupByFields.push ( "processName" );
    
                        }

                        break;
    
                    case "machine":
    
                        if ( item.value ) {

                            newGroupByFields.push ( "machineCode" );
                            newGroupByFields.push ( "machineName" );
    
                        }
    
                        break;
    
                    case "worker":

                        if ( item.value ) {

                            newGroupByFields.push ( "worker" );
                            newGroupByFields.push ( "workerName" );
    
                        }
    
                        break;
        
                    default:

                        break;

                }

            }

        }

        let gridStorageKey = "grid-MomSysVwProductionTime-data-grid-layout";
        localStorage.removeItem ( gridStorageKey );

        orderBy.setOrderBy ( [] );

        setTimeout( () => setGroupBy ( newGroupBy ), 0 );
        setTimeout( () => setGroupByFields ( newGroupByFields ), 0 );;

        setTimeout ( () => setView ( undefined ), 0 );
        setTimeout ( () => setView ( "MomSysVwProductionTime" ), 0 );

    }

    const makeQueries = ( from, to, groupByFields ) => {

        let queries = {
            $from: from,
            $to: to,
            $groupBy: JSON.stringify(groupByFields)
        };

        return queries;
    }

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        setGrid(grid);
        
        grid.itemsSourceChanged.addHandler((grid, event) => {

            if ( grid.rows.length > 0 ) {

                setDisableExcelButton ( false );
                
            } else {

                setDisableExcelButton ( true );

            }

        } );

    };

    const onOrderByInitialized = (orderBy) => {

        setOrderBy ( orderBy );

    }
    
    const onModifyViewSchema = ( groupBy, schema ) => {

        let column;

        column = {

            name: "avgProductionPerHour",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 14,
            }

        }

        schema.columns.push( column );

        column = {

            name: "minProductionPerHour",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 15,
            }

        }

        schema.columns.push( column );

        column = {

            name: "maxProductionPerHour",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 16,
            }

        }

        schema.columns.push( column );

        column = {

            name: "stdProductionPerHour",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 17,
            }

        }

        schema.columns.push( column );            

        column = {

            name: "avgProductionTime",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 18,
            }

        }

        schema.columns.push( column );        

        column = {

            name: "minProductionTime",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 19,
            }

        }

        schema.columns.push( column );        

        column = {

            name: "maxProductionTime",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 20,
            }

        }

        schema.columns.push( column );        

        column = {

            name: "stdProductionTime",
            type: "float",
            grid: {
                hide: checkItemSelected ( groupBy, "lotNo" ) ? true : false,
                columnOrder: 21,
            }

        }

        schema.columns.push( column );        

        for ( const column of schema.columns ) {

            switch ( column.name ) {

                case "lotNo":

                    column.grid.columnOrder = 1;
                    column.grid.hide = checkItemSelected ( groupBy, "lotNo" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "qty":
                case "productionTime":
                case "processCount":
                case "createdAt":

                    column.grid.hide = checkItemSelected ( groupBy, "lotNo" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "materialCode":
                case "materialCodeVer":
                case "materialName":

                    column.grid.hide = checkItemSelected ( groupBy, "material" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "processCode":
                case "processName":

                    column.grid.hide = checkItemSelected ( groupBy, "process" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "machineCode":
                case "machineName":

                    column.grid.hide = checkItemSelected ( groupBy, "machine" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                case "worker":
                case "workerName":

                    column.grid.hide = checkItemSelected ( groupBy, "worker" ) ? false : true;

                    if ( column.grid.hide ) {

                        if ( column.filter ) {

                            column.filterBackup = column.filter;
                            column.filter = undefined;
    
                        }

                    } else {

                        if ( column.filterBackup ) {

                            column.filter = column.filterBackup;
                            column.filterBackup = undefined;
    
                        }

                    }

                    break;

                default:

                    break;

            }

        }

    };

    return (

        <BasicCrudScreenType
            id="MomSysVwProductionTime"
            table="MomSysVwProductionTime"
            view={view}
            $queries={ makeQueries ( from, to, groupByFields ) }
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendHeaderAtFirst={ () =>
                <>

                    <DateRangeSelector onChange={onDateRangeChanged}/>
                    <div style={{marginLeft: "32px"}} />
                    <GroupByColumnPicker color="primary" groupByColumns={ groupBy } onChange={( groupBy ) => onGroupByChanged ( groupBy, orderBy ) } />

                </>
            }
            onAppendButtonInFrontOfSearch={ () =>
                <BasicCrudScreenActionItemLayout>

                    <AccessControl accessTarget="MomSysVwProductionTime" accessMethod="SAVETOEXCEL">
                        <SaveToExcelButton
                            id="MomSysVwProductionTime"
                            grid={grid}
                            view={view}
                            color="primary"
                            disabled={disableExcelButton}
                        />
                    </AccessControl>

                </BasicCrudScreenActionItemLayout>
            }
            onInitialized={onGridInitialized}
            onOrderByInitialized={onOrderByInitialized}
            onModifyViewSchema={ ( schema ) => onModifyViewSchema ( groupBy, schema ) }
        />                                

    );

}

export default MomSysProductionTime;
