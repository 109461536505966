import { useState, useEffect } from 'react';
import { FlexChart, FlexChartAxis, FlexChartLegend, FlexChartSeries } from '@grapecity/wijmo.react.chart';
import { FlexChartAnimation } from '@grapecity/wijmo.react.chart.animation';

import format from 'format-number';

import "@grapecity/wijmo.styles/themes/wijmo.theme.material.css";

function DashWijmoAnnualSalesChart ( props ) {
        
    const { palette, salesReport } = props;

    const [ data, setData ] = useState ([]);

    useEffect ( () => {
        
        if ( salesReport ) {
        
            let data = [];

            for ( let index = 0; index < 24; index += 2 ) {

                data.push ( {
                    index: salesReport.data[index].index,
                    year: salesReport.data[index].year,
                    month: salesReport.data[index].month,
                    salesPrevYear: salesReport.data[index].sales,
                    accumulatedSalesPrevYear: salesReport.data[index].accumulatedSales,
                    salesCurrentYear: salesReport.data[index+1].sales,
                    accumulatedSalesCurrentYear: salesReport.data[index+1].accumulatedSales,
                } );

            }

            setData ( data );

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesReport] );

    const makeTooltip = ( hitTestInfo ) => {

        let year = hitTestInfo.item.year;
        let month = hitTestInfo.item.month;

        let value = hitTestInfo.value; 

        if ( value >= 100000000 ) {

            value = format({ round: 2, suffix: ' 억'})(value / 100000000);

        } else if ( value >= 10000 ) {

            value = format({ round: 2, suffix: ' 만'})(value / 10000);

        } else {

            value = format({ round: 2 })(value);        

        }

        return `<b>${year}년 ${month}월 : </b> <span>${value}</span>`;

    }

    return (

        <FlexChart bindingX="index" itemsSource={data} palette={palette} tooltipContent={makeTooltip}>

            <FlexChartLegend position="Top">
            </FlexChartLegend>

            <FlexChartSeries binding="salesPrevYear" name="전년 동월 매출" >
            </FlexChartSeries>

            <FlexChartSeries binding="salesCurrentYear" name="금년 매출" >
            </FlexChartSeries>

            <FlexChartSeries binding="accumulatedSalesPrevYear" name="전년 동월 누적 매출" chartType="LineSymbols" >
            </FlexChartSeries>

            <FlexChartSeries binding="accumulatedSalesCurrentYear" name="금년 누적 매출" chartType="LineSymbols" >
            </FlexChartSeries>

            <FlexChartAxis wjProperty="axisY" itemFormatter={(_,label) => {

                if ( !label ) {

                    return label;

                }

                let value = label.val; 

                if ( value >= 100000000 ) {

                    value = format({ round: 2, suffix: ' 억'})(value / 100000000);

                } else if ( value >= 10000 ) {

                    value = format({ round: 2, suffix: ' 만'})(value / 10000);

                } else {

                    value = format({ round: 2 })(value);        

                }

                label.text = value;

                return label;

            } } >
            </FlexChartAxis>

            <FlexChartAxis wjProperty="axisX" majorUnit={1} itemFormatter={(_,label) => {

                if ( !label ) {

                    return label;

                }

                let offset = ( salesReport?.currentMonth + parseInt ( label.val ) ) % 12;

                if ( offset === 0 ) {

                    label.text = 12;

                } else {
                        
                        label.text = offset;
    
                }

                return label;

            } } >

            </FlexChartAxis>

            <FlexChartAnimation></FlexChartAnimation>

        </FlexChart>

    );
}

export default DashWijmoAnnualSalesChart;
