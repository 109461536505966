/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysSoProductionMaterial ( props ) {

    const { t } = useTranslation();

    const { soDetail, product, onApiInitialized } = props;

    const [, setGrid] = useState();


    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        setGrid(grid);
        
    };

    const onModifyUrl = ( soDetail, product, url ) => {        

        return `${url}/${soDetail?soDetail.orderBuyerId:null}/${product?product.materialCode:null}/${product?product.materialCodeVer:null}/${product?product.qtyRequired:null}`;

    };

    return (

        <BasicCrudScreenType
            id="MomSysSoProductionMaterial"
            headerTitle={t("term.inputMaterial")}
            headerTitleStyle={{
                fontSize:   "16px",
                fontWeight: "bold",
                marginLeft: "8px",
                marginTop:  "8px"
            }}
            table="MomSysSgtLot"
            view="MomSysVwMaterialsForProduct"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            onInitialized={onGridInitialized}
            onModifyViewUrl={( url ) => onModifyUrl ( soDetail, product, url )}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysSoProductionMaterial;
