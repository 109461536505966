import apiClient from "../common/apiClient";

async function checkLogin() {

    let response = await apiClient.get("/auth/checkLogin");

    return response.data;

}

export default checkLogin;