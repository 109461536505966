
import MomSysSelectStandardMaterial from "../screens/MomSysSelectStandardMaterial";

function MomSysSelectStandardShipDetail(props) {

  const { onSelect, onInitialized,  onLoadData } = props;
  
  return (
      <MomSysSelectStandardMaterial
        materialCode = {onSelect.materialCode}
        materialCodeVer = {onSelect.materialCodeVer}
        orderBuyerId = {onSelect.orderBuyerId}
        shipQty = {onSelect.shipQty}
        onInitialized = {onInitialized}
        onLoadData = {onLoadData}
      />
  );
}

export default MomSysSelectStandardShipDetail;
