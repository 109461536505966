import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DbLockWaits ( props ) {

    return <BasicCrudScreenType
        id="DbLockWaits"
        noCreate
        noEdit
        noDelete
        noExcel
        noFilter
        table="VwDbLockWaits"
        view="VwDbLockWaits"
    />

}

export default DbLockWaits;
