/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../lib/common/apiClient";
import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function EguMstMaterialFileDeleteButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, grid, onSuccess, onFailure, onNotifyWarning, ...others} = props;
    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState([]);

    const onOpen = () => {
        let deleteData = [];
        let deleteItemStr = "[";
        let firstRow = true;
        for(const item of grid.selectedItems) {
            if(!firstRow) {
                deleteItemStr += ", ";
            }

            deleteItemStr += "{";
            deleteItemStr += (t(`columnName.id`) + ": " + item['id']);
            deleteItemStr += ", ";
            deleteItemStr += (t(`columnName.materialCode`) + ": " + item['materialCode']);
            deleteItemStr += "}";

            firstRow = false;

            deleteData.push({...item});
        }
        deleteItemStr += "]";

        setConfirmMsg(t("dialog.msg.confirm.delete", {type: t(`tableName.${schema.name}`), instance: deleteItemStr}));
        setConfirmData(deleteData);
        setOpen(true);
    };

    const materialFileDeleteSuccess = (msg) => {
        if(onSuccess) {
            onSuccess(msg);
        }
        refresh.refresh();
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOk = (data) => {
        let url = `/api/EguMstMaterialFile/delete`;
        let json = {};
        let apiData = {...data};
        let form = new FormData();

        for ( const formItem in apiData ) {
            if ( apiData[formItem] !== undefined ) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form)
            .then((result) => {materialFileDeleteSuccess(result.data)})
            .catch((reason) => {
                console.error(reason);                
                onFailure(t("error.deleteFailure", {reason: reason.response ? reason.response.data : reason}));
            });

    }

    return (
        <>
            <DeleteRecordButtonLayout id={id+"delete-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"delete-confirm-dialog"}
                title={schema ? t (`dialog.title.${schema.name}.delete`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>
    );
}

export default EguMstMaterialFileDeleteButton;

