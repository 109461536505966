import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PopSelfInspectionButtonLayout from "../layout/PopSelfInspectionButtonLayout";
import PopSelfInspectionDialog from "../dialogTypes/PopSelfInspectionDialog";

function PopSelfInspectionButton ( props ) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, inspectionGroup, index, onSelect, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [schema, setSchema] = useState();

    useEffect(() => {

        let schema = {

            name: "PopSelfInspectionDetail",
            type: "BASE TABLE",
            formGroups: ["disable", "value"],
            searchAll: false,

            columns: [

                {

                    name: "selfInspectionId",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 10,
                    formGroup: 0,
                    formItemType: "text",
                    defaultValue: "eguInspectionIdCode",

                },
                {

                    name: "lotNo",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    disabled: true,
                    formOrder: 20,
                    formGroup: 0,
                    formItemType: "text",

                },
                {

                    name: "inspectionGroupId",
                    type: "int(11)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    disabled: true,
                    hideInForm: true,
                    formOrder: 30,
                    formGroup: 0,
                    formItemType: "text",

                },
                {

                    name: "inspectionRequester",
                    type: "VARCHAR(255)",
                    nullable: "NO",
                    default: null,
                    required: false,
                    formOrder: 60,
                    formGroup: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "User",
                        nameColumn: ["username", "fullName"],
                        valueColumn: "username",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    }

                },
                {

                    name: "inspector",
                    type: "VARCHAR(255)",
                    nullable: "NO",
                    default: null,
                    required: false,
                    formOrder: 70,
                    formGroup: 1,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: "User",
                        nameColumn: ["username", "fullName"],
                        valueColumn: "username",
                        useEmptyValue: true,
                        emptyName: "term.none",
                        sortByName: true,
                    }

                },
                {

                    name: "inspectionRequestDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    default: null,
                    required: false,
                    formOrder: 80,
                    formGroup: 1,
                    formItemType: "date",

                },
                {

                    name: "samplingDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    default: null,
                    required: false,
                    formOrder: 90,
                    formGroup: 1,
                    formItemType: "date",

                },
                {

                    name: "samplingQty",
                    type: "DOUBLE",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formOrder: 100,
                    formGroup: 1,
                    formItemType: "float",
                    defaultValue: 1,

                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if ( refresh ) {

            refresh.refresh ();

        }

    };

    return (

        <>
            <PopSelfInspectionButtonLayout id={id + "-InspectionGroup" + index + "-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <PopSelfInspectionDialog
                id={id+"-selfInspection-dialog"}
                mode="create"
                formId={id+"-selfInspection-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.selfInspection` ) : "" }
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                inspectionGroup={inspectionGroup}
                onSelect={onSelect}
                noKeepOpen
            />
        </>

    );
}

export default PopSelfInspectionButton;

