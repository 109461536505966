import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysCollectionsDetail from "./MomSysCollectionsDetail";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import SaveToExcelButton from "../input/SaveToExcelButton";
import getSpecific from "../../lib/api/getSpecific";
import MomSysSalesDetail from "./MomSysSalesDetail";
import {debounce} from "lodash";
import apiClient from "../../lib/common/apiClient";

function MomSysSalesCollections ( props ) {

    const [ onSelectMst, setOnSelectMst ] = useState();

    const [ grid, setGrid ] = useState();

    const [ height, setHeight ] = useState(0);

    const [ isExceedOrderBuyerCost, setIsExceedOrderBuyerCost ] = useState(0);
    const [ isExceedSalesCost, setIsExceedSalesCost ] = useState(0);

    const { t } = useTranslation ();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        ( async () => {

            const isExceedOrderBuyerCost = (await apiClient.get('/api/getTenantOptionValue/MomSysSalesCollections.salesCost/isExceedOrderBuyerCost')).data?.optionValue === "true";

            const isExceedSalesCost = (await apiClient.get('/api/getTenantOptionValue/MomSysSalesCollections.collectionsCost/isExceedSalesCost')).data?.optionValue === "true";

            setIsExceedOrderBuyerCost(isExceedOrderBuyerCost);

            setIsExceedSalesCost(isExceedSalesCost);
            
        } ) ()

        return () => window.removeEventListener('resize', handleResize);
    
    }, []);

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));
    
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };
                }, 10));
            }

        } ) ()

    }, [grid] )

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onUpdateMaster = () => {

        setGrid ( ( grid ) => {

            if ( grid ) {

                ( async () => {

                    let collectionsMst = await getSpecific("MomSysVwSalesCollections", grid.selectedItems[0].orderBuyerId );

                    grid.selectedItems[0].orderBuyerCost = collectionsMst.orderBuyerCost;
                    grid.selectedItems[0].salesCost = collectionsMst.salesCost;
                    grid.selectedItems[0].salesBalance = collectionsMst.salesBalance;
                    grid.selectedItems[0].advancePaymentCost = collectionsMst.advancePaymentCost;
                    grid.selectedItems[0].collectionsCost = collectionsMst.collectionsCost;
                    grid.selectedItems[0].collectionsBalance = collectionsMst.collectionsBalance;
                    grid.refresh(false);
    
                } ) ();

            }

            return grid;

        })

    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6} data-cy="Master">

                    <BasicCrudScreenType 
                        id="MomSysSalesCollections" 
                        headerTitle={t("term.so")}
                        table="MomSysVwSalesCollections" 
                        view="MomSysVwSalesCollections" 
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onInitialized}
                        onAppendButtonInFrontOfSearch = {
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                    <SaveToExcelButton
                                        id="MomSysSalesCollections"
                                        grid={grid}
                                        view="MomSysVwSalesCollections" 
                                        color="primary"
                                        exportFormatItem={() => {}}
                                    />
                                </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>

                    {
                    (onSelectMst)
                    ?
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.5} data-cy="SalesDetail">
                            <MomSysSalesDetail
                                id="MomSysSalesDetail" 
                                table="MomSysSalesDetail" 
                                view="MomSysVwSalesDetail" 
                                onUpdateMaster={onUpdateMaster}
                                onSelectMst={onSelectMst}
                                isExceedOrderBuyerCost={isExceedOrderBuyerCost}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                        <ReflexElement flex={0.5} data-cy="CollectionsDetail">
                            <MomSysCollectionsDetail 
                                id="MomSysCollectionsDetail" 
                                table="MomSysCollectionsDetail" 
                                view="MomSysVwCollectionsDetail" 
                                onUpdateMaster={onUpdateMaster}
                                onSelectMst={onSelectMst}
                                isExceedSalesCost={isExceedSalesCost}
                            />
                        </ReflexElement>
                    </ReflexContainer>
                    :
                    <></>
                    }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysSalesCollections;
