import { useState } from "react";

import CreateRecordButton from "./CreateRecordButton";
import EguOutSourcingFakeInputDialog from "../dialogTypes/EguOutSourcingFakeInputDialog";

function EguOutSourcingFakeInputButton(props) {
    const { id, refresh, schema, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButton id={id} onClick={onOpen} {...others} />
            <EguOutSourcingFakeInputDialog
                id={id + "-outSourcing-fakeInput-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onShipSuccess}
            />
        </>
    );
}

export default EguOutSourcingFakeInputButton;
