/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PoDetail from "./PoDetail"

import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';

import layoutConf from "../../config/layoutConf";
import { useRecoilValue } from "recoil";
import getSchema from "../../lib/api/getSchema";
import authProfileState from "../../states/authProfileState";
import { useTranslation } from "react-i18next";
import { CellType } from '@grapecity/wijmo.grid';
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreatePoButton from "../input/CreatePoButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import { cloneDeep } from "lodash";
import AccessControl from "../functional/AccessControl";

function Po ( props ) {

    const {
        id,
    } = props;

    const [ grid, setGrid ] = useState();

    const [ poId, setPoId ] = useState();

    const [ poCost , setPoCost ] = useState();

    const [ height, setHeight ] = useState(0);

    const [ refresh, setRefresh ] = useState ();
    
    const [ poRefresh, setPoRefresh ] = useState ();

    const { t } = useTranslation ();
    
    const authProfile = useRecoilValue ( authProfileState );

    const [ schema, setSchema ] = useState ();
    
    const [ updateSchema, setUpdateSchema ] = useState ();
    
    const [ approvalCtx, setApprovalCtx ] = useState ();

    const [ approvalStateId, setApprovalStateId ] = useState();

    const [ data, setData ] = useState();

    const [ , setNotification ] = useRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect ( () => {

        let mounted = true;

        window.addEventListener('resize', handleResize);
        handleResize();
    
        ( async () => {

            let schema = await getSchema ( "Po" );

            if ( mounted ) {
                
                setSchema ( schema );

                let updateSchema = cloneDeep(schema);
    
                for (const column of updateSchema.columns) {
        
                    switch (column.name) {
        
                    case "basePoId":
        
                        column.hideInForm = true;
        
                        break;
        
                    default:
        
                        break;
                    }
                }
    
                setUpdateSchema ( updateSchema );
    
            }

        } ) ();

        return ( () => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        } );

    }, [] );

    useEffect ( () => {
        
        let approvalCtx = {

            approvalTypeId: 2,
            tableName: "Po",
            statusColumn: "approvalStateId",
            schema: schema,
            onValidateApprovalItem: ( item ) => {

                if ( item.register !== authProfile.username ) {

                    return [ false, t( "error.approvalRequesterNotMatch" ) ];
        
                    
                }
        
                return [ true, "" ];
        
            },
            makeRequestItemDescription: ( item ) => {

                return `${ t ( "approvalRequestMsg.msg.po", { poName : item.poName, poNumber : item.poNumber } ) }`; // Use Translation on Production Code

            }
        
        };

        setApprovalCtx ( approvalCtx );
    
    }, [schema] );

    useEffect(() => {

        ( async () => {
            

            if (grid) {
    
                grid.selectionChanged.addHandler((grid, event) => {
                    setPoId(grid.selectedItems[0] && grid.selectedItems[0].poId);
                    setApprovalStateId(grid.selectedItems[0] && grid.selectedItems[0].approvalState);
                    setPoCost(grid.selectedItems[0] && grid.selectedItems[0].cost);

                    if ( refresh ) {
                        refresh.refresh ();
                    }
                });
    
                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if(grid.selectedItems[0]) {
                        setPoId(grid.selectedItems[0].poId);
                        setApprovalStateId(grid.selectedItems[0].approvalState);
                        setPoCost(grid.selectedItems[0].approvalState);

                        if ( refresh ) {
                            refresh.refresh ();
                        }
                    }
                });
            }

        } ) ()


    }, [grid, refresh] )

    const onInitialized = grid => {

        setGrid(grid);

    }

    const onApiInitialized = ( api ) => {

        setRefresh ( api );

    }

    const onPoApiInitialized = ( api ) => {

        setPoRefresh ( api );

    }

    const exportFormatItem = ( args ) => {

        let { panel, col, row, xlsxCell } = args;

        if ( panel.cellType === CellType.Cell ) {

            switch ( panel.columns[col].binding ) {

                case "approvalState":

                    switch (data[row][panel.columns[col].binding]) {

                        case 1:

                            xlsxCell.value = t ("selectItems.approvalState.approvalPending")

                            break;

                        case 2:

                            xlsxCell.value = t ("selectItems.approvalState.partiallyApproved")

                            break;

                        case 3:

                            xlsxCell.value = t ("selectItems.approvalState.totallyApproved")

                            break;
                        
                        case 4:

                            xlsxCell.value = t ("selectItems.approvalState.approvalRejected")

                            break;

                        case 5:

                            xlsxCell.value = t ("selectItems.approvalState.approvalWithdrawn")

                            break;

                        case 6:

                            xlsxCell.value = t ("selectItems.approvalState.approvalCancelled")

                            break;

                        default:
                            break;
                    }

                    break;

                default:

                    break;

            }

        }

    }

    const onLoadData = ( data ) => {

        setData ( data );

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );

    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;

        return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;

    }

    const onAppendHeaderAtFirst = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                    <CreatePoButton
                        id={id}
                        schema={schema}
                        refresh ={poRefresh}
                        onSuccess = {onNotifySuccess}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                </AccessControl>
                <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                    <UpdateRecordButton
                        id = {id}
                        schema = {updateSchema}
                        grid ={grid}
                        disabled={Number.isInteger(approvalStateId) ? true : false || poCost === `0`}
                        refresh = {poRefresh}
                        onSuccess ={onNotifySuccess}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
    
        );
    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType 
                        id="Po" 
                        table="Po" 
                        view="VwPo" 
                        onInitialized={onInitialized}
                        onApiInitialized={onPoApiInitialized}
                        approvalCtx={approvalCtx}
                        onLoadData={onLoadData}
                        exportFormatItem={exportFormatItem}
                        disableEdit={approvalStateId ? true : false}
                        disableDelete={approvalStateId ? true : false}
                        onAppendHeaderAtFirst={onAppendHeaderAtFirst}
                        noCreate
                        noEdit
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>

                    {
                    (poId)
                    ?
                    <PoDetail 
                        id={id || "PoDetail"} 
                        poId={poId}
                        poCost = {poCost}
                        onApiInitialized={onApiInitialized}
                        poRefresh={poRefresh}
                        approvalStateId ={approvalStateId}
                        exportFormatItem={() => {}}
                    />
                    :
                    <></>
                    }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default Po;
