import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysStockMoveCreateButton from "../input/MomSysStockMoveCreateButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import MomSysStockMoveCancelButton from "../input/MomSysStockMoveCancelButton";
import authProfileState from "../../states/authProfileState";
import ApprovalButton from "../input/ApprovalButton";
import MomSysStockMoveButton from "../input/MomSysStockMoveButton";
import defineConf from "../../config/defineConf";

function MomSysStockMove(props) {

    const id = "MomSysStockMove";
    const table = "MomSysStockMove";
    const view = "MomSysVwStockMove"

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const [onSelectGrid, setOnSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    const [approvalCtx, setApprovalCtx] = useState();

    const authProfile = useRecoilValue(authProfileState);
    
    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 22,
            tableName: "MomSysStockMove",
            statusColumn: "approvalStateId",
            schema: schema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.momSysStockMove", {
                    shipmentNo: item.id,
                })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, authProfile]);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysStockMove");
            if (mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

        if (data.length < 1) {
            setOnSelectGrid(null)
        }

    };

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table={table}
                view={view}
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.stockMoveView")}
                noCreate
                noEdit
                noDelete
                noExcel
                onLoadData={onLoadData}
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <MomSysStockMoveCreateButton
                                    id="MomSysStockMoveCreate"
                                    schema={schema}
                                    refresh={api}
                                    onSuccess={onSuccess}
                                />
                            </AccessControl>

                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="POST"
                            >
                                <MomSysStockMoveCancelButton
                                    id="MomSysShipRecord"
                                    schema={schema}
                                    color="primary"
                                    createButtonName="create"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    refresh={api}
                                    grid={grid}
                                    disabled={isEmpty}
                                />
                            </AccessControl>
                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="APPROVAL"
                            >
                                <ApprovalButton
                                    id="MomSysStockMoveApproved"
                                    grid={grid}
                                    refresh={api}
                                    approvalCtx={approvalCtx}
                                    disabled={isEmpty}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />
                            </AccessControl>
                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="POST"
                            >
                                <MomSysStockMoveButton
                                    id="MomSysStockMove"
                                    schema={schema}
                                    color="primary"
                                    createButtonName="create"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    refresh={api}
                                    grid={grid}
                                    disabled={isEmpty || grid?.selectedItems[0]?.approvalState !== defineConf.approvalState.APPROVED || grid?.selectedItems[0]?.moveState}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                                <SaveToExcelButton
                                    id="MomSysStockMoveExcelDownload"
                                    grid={grid}
                                    view="EguVwMaterial"
                                    color="primary"
                                    disabled={onSelectGrid ? false : true}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default MomSysStockMove;
