import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysDeliveryCreateDialog(props) {
    const {
        id,
        mode,
        schema,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [momSysVwPreDeliveryGrid, setMomSysVwPreDeliveryGrid] = useState();
    const [momSysVwPreDeliveryApi, setMomSysVwPreDeliveryApi] = useState();
    const [momSysVwPreDeliverySelect, setMomSysVwPreDeliverySelect] = useState();

    const [momSysVwPreDeliveryDetailApi, setMomSysVwPreDeliveryDetailApi] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysVwPreDeliveryGrid) {
                momSysVwPreDeliveryGrid.selectionChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMomSysVwPreDeliverySelect(grid.selectedItems[0]);
                    } else {
                        setMomSysVwPreDeliverySelect();
                    }
                },10));

                momSysVwPreDeliveryGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setMomSysVwPreDeliverySelect(grid.selectedItems[0]);
                    } else {
                        setMomSysVwPreDeliverySelect();
                    }
                },10));

                momSysVwPreDeliveryGrid.refreshed.addHandler((grid, event) => {
                    if (grid.selectedRows.length === 0) {
                        setOkButtonDisabled(true);
                    }
                });
            }
        })();
    }, [momSysVwPreDeliveryGrid]);

    useEffect(() => {
        if (momSysVwPreDeliverySelect) {
            (async () => {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        orderBuyerId: momSysVwPreDeliverySelect.orderBuyerId,
                        orderBuyerName: momSysVwPreDeliverySelect.orderBuyerName,
                        companyName: momSysVwPreDeliverySelect.companyName,
                        dueDate: momSysVwPreDeliverySelect.dueDate,

                        shipId: momSysVwPreDeliverySelect.shipId,

                        shipmentId: momSysVwPreDeliverySelect.shipmentId,
                        shipmentNo: momSysVwPreDeliverySelect.shipmentNo,
                        shipmentOrderDate: momSysVwPreDeliverySelect.shipmentOrderDate,

                        deliveryDate: new Date(),
                    };

                    for (const column of schema.columns) {
                        if (column?.defaultValue !== undefined) {
                            newFormData[column.name] = column.defaultValue;
                        }
                    }

                    return newFormData;
                });

                setOkButtonDisabled(false); /* audomatically activate ok button */
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [momSysVwPreDeliverySelect]);

    useEffect(() => {
        if (momSysVwPreDeliveryDetailApi) {
            momSysVwPreDeliveryDetailApi.refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [momSysVwPreDeliverySelect?.shipId]);

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    const onCancel = () => {
        setMomSysVwPreDeliverySelect();

        setOkButtonDisabled(true);

        dialogInstance.hide();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                notifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        let url = `/api/${schema.name}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        if (schema && !schema.columnMap) {
            //autoFormDialog columnMap 생성부분

            let columnMap = {};

            for (const column of schema.columns) {
                columnMap[column.name] = column;
            }

            schema.columnMap = columnMap;
        }

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .then(() => {
                if (momSysVwPreDeliveryApi) {
                    momSysVwPreDeliveryApi.refresh();
                }
            })
            .catch((reason) => onApiFailure(reason));
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeaderWithClose>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeaderWithClose>

                <Divider />

                <DialogContent style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement>
                                    <BasicCrudScreenType
                                        id={formId}
                                        embededInOtherScreenType
                                        headerTitle={t("term.ship")}
                                        view="MomSysVwPreDelivery"
                                        noCreate
                                        noEdit
                                        noDelete
                                        noExcel
                                        noFilter
                                        onInitialized={(grid) => {
                                            grid.selectioMode = "Row";

                                            setMomSysVwPreDeliveryGrid(grid);
                                        }}
                                        onApiInitialized={(api) => {
                                            setMomSysVwPreDeliveryApi(api);
                                        }}
                                    />
                                </ReflexElement>

                                <ReflexSplitter />

                                <ReflexElement>
                                    {momSysVwPreDeliverySelect ? (
                                        <BasicCrudScreenType
                                            id={formId}
                                            embededInOtherScreenType
                                            headerTitle={t("term.shipDetail")}
                                            view="MomSysVwPreDeliveryDetail"
                                            noCreate
                                            noEdit
                                            noDelete
                                            noExcel
                                            noFilter
                                            onInitialized={(grid) => {
                                                grid.selectionMode = "None";
                                            }}
                                            onApiInitialized={(api) => {
                                                setMomSysVwPreDeliveryDetailApi(api);
                                            }}
                                            onModifyViewUrl={(url) => `${url}/${momSysVwPreDeliverySelect?.shipId}`}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>

                        <ReflexSplitter />

                        <ReflexElement>
                            <div
                                style={{
                                    padding: "32px",
                                }}
                            >
                                <AutoForm
                                    id="MomSysDelivery"
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChangeFormData}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysDeliveryCreateDialog;
