/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "react-reflex/styles.css";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";


function WarehouseLotList ( props ) {

    const { t } = useTranslation();

    const { warehouse, material } = props;
    const [api, setApi] = useState();

    const onModifyUrl = (url) => {
        
        let modifiedUrl = url;

        if ( warehouse ) {

            modifiedUrl =  modifiedUrl + "/" + warehouse.wareHouseCode;

        } else {

            modifiedUrl = modifiedUrl + "/undefined";

        }

        if ( material ) {

            modifiedUrl =  modifiedUrl + "/" + material.materialCode + "/" + material.materialCodeVer;

        } else {

            modifiedUrl = modifiedUrl + "/undefined/undefined";

        }

        return modifiedUrl;
        
    };

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [warehouse, material] );


    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    return (

        <BasicCrudScreenType
            id="VwWarehouseLotList"
            headerTitle={t("term.lotList")}
            table="VwWarehouseLotList"
            view="VwWarehouseLotList"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default WarehouseLotList;
