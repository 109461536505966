/* eslint-disable react-hooks/exhaustive-deps */
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import { useEffect, useState } from 'react';

import layoutConf from '../../config/layoutConf';

import BasicCrudScreenType from '../screenTypes/functional/BasicCrudScreenType';
import ApprovalPathEditScreenType
    from '../screenTypes/functional/ApprovalPathEditScreenType';

function ApprovalPathManage(props) {

    const {

        id,

        embededInOtherScreenType,

        requester,

        noCreate,
        noDelete,
        noSearch,
        noFilter,

        fixedHeight,

        onNotifySuccess,
        onNotifyInfo,
        onNotifyWarning,
        onNotifyError
    
    } = props;

    const [grid, setGrid] = useState();
    const [approvalPathId, setApprovalPathId] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {

        if ( !fixedHeight ) {
        
            setHeight(document.documentElement.offsetHeight - 96);

        }

    };

    useEffect(() => {

        if ( !fixedHeight ) {

            window.addEventListener('resize', handleResize);

            handleResize();
    
            return () => window.removeEventListener('resize', handleResize);
    
    
        }
        
    }, []);

    useEffect(

        () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    setApprovalPathId(
                        grid.selectedItems[0] && grid.selectedItems[0].approvalPathId
                    );
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems[0]) {
                        setApprovalPathId(grid.selectedItems[0].approvalPathId);
                    }
                });
            }
        },
        [grid]

    );

    const onInitialized = grid => {

        setGrid(grid);

    };

    const onModifyTableSchema = ( schema ) => {

        for ( const column of schema.columns ) {

            switch ( column.name ) {

                case "owner":

                    column.defaultValue = requester;
                    column.disabled = true;

                    break;

                default:

                    break;

            }

        }

    };

    const onModifyViewSchema = ( schema ) => {

        for ( const column of schema.columns ) {

            if ( requester ) {

                switch ( column.name ) {

                    case "approvalPathName":

                        column.grid = {...column.grid, width: 554 };

                        break;

                    default:

                        column.grid = {...column.grid, hide: true };

                        break;

                }

            } else {

                switch ( column.name ) {

                    default:
    
                        break;
                        
                }
    
            }

        }

    };

    const onModifyViewUrl = ( url ) => {

        if ( requester ) {

            url += "/" + requester;

        }

        return url;

    }

    return (

        <div style={{ height: fixedHeight || height }} >
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType

                        id={id||"ApprovalPath"}

                        embededInOtherScreenType={embededInOtherScreenType}

                        table="ApprovalPath"
                        view="VwApprovalPath"

                        noCreate={noCreate}
                        noEdit
                        noDelete={noDelete}
                        noExcel
                        noSearch={noSearch}
                        noFilter={noFilter}

                        onModifyTableSchema={onModifyTableSchema}
                        onModifyViewSchema={onModifyViewSchema}

                        onModifyViewUrl={onModifyViewUrl}

                        onInitialized={onInitialized}

                        onNotifySuccess={onNotifySuccess}
                        onNotifyInfo={onNotifyInfo}
                        onNotifyWarning={onNotifyWarning}
                        onNotifyError={onNotifyError}
                        
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    <ApprovalPathEditScreenType id={id+"Edit"||"ApprovalPathEdit"} approvalPathId={approvalPathId} />
                </ReflexElement>
            </ReflexContainer>
        </div>
        
    );
}

export default ApprovalPathManage;
