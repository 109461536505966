/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import SaveToExcelButton from "../input/SaveToExcelButton";
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import MomSysBusinessDetailCreateButton from "../input/MomSysBusinessDetailCreateButton";
import MomSysBusinessDetailUpdateButton from "../input/MomSysBusinessDetailUpdateButton";
import MomSysBusinessDetailDeleteButton from "../input/MomSysBusinessDetailDeleteButton";
import ApprovalButton from "../input/ApprovalButton";
import authProfileState from "../../states/authProfileState";
import { useRecoilValue } from "recoil";
import defineConf from "../../config/defineConf";

function MomSysExpenseDetail ( props ) {

    const {

        id,
        table,
        view,
        onUpdateMaster,
        onSelectMst,

    } = props;

    const [ grid, setGrid ] = useState();

    const [ tableSchema, setTableSchema ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const [ detailApi, setDetailApi ] = useState();

    const [ onSelectDetail, setOnSelectDetail ] = useState();

    const authProfile = useRecoilValue ( authProfileState );

    const { t } = useTranslation ();

    const setNotification = useSetRecoilState(notificationState);

    const [ approvalCtx, setApprovalCtx ] = useState ();

    useEffect(() => {

        (async () => {

            let schema = await getSchema("MomSysExpenseDetail");

            for (const column of schema.columns) {
    
                switch (column.name) {
    
                case "orderPurchaseId":
    
                    column.defaultValue = onSelectMst.orderPurchaseId;
                    column.disabled = true;
    
                    break;

                default:
    
                    break;
                }
            }

            setTableSchema(schema);

            if ( detailApi ) {
    
                detailApi.refresh ();
    
            }
        
        })();

    }, [onSelectMst.orderPurchaseId] );

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler((grid, event) => {

                    if( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectDetail(grid.selectedItems[0])

                    } else {

                        setOnSelectDetail([]);

                    };

                });
    
                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectDetail(grid.selectedItems[0])

                    } else {

                        setOnSelectDetail([]);

                    };

                });

            }

        } ) ()

    }, [grid] )

    useEffect ( () => {
        
        let approvalCtx = {

            approvalTypeId: 12,
            tableName: table,
            statusColumn: "approvalStateId",
            schema: tableSchema,
            onValidateApprovalItem: ( item ) => {

                if ( item.createdBy !== authProfile.username ) {

                    return [ false, t( "error.approvalRequesterNotMatch" ) ];
                    
                }
        
                return [ true, "" ];
        
            },
            makeRequestItemDescription: ( item ) => {

                return `${ t ( "approvalRequestMsg.msg.momSysExpenseDetail", { orderPurchaseId : item.orderPurchaseId } ) }`; // Use Translation on Production Code

            }
        
        };

        setApprovalCtx ( approvalCtx );
    
    }, [authProfile, t, table, tableSchema] );

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onSuccess = ( msg ) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {

            onUpdateMaster();

        }

    }

    const onNotifySuccess = ( msg ) => {

        onSuccess ( msg );
        
        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };

    const onFailure = ( msg ) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    }

    const notifyWarning = (msg) => {
        
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {
            
            onUpdateMaster ();

        }
        
    };

    const onPartialFailure = ( msg ) => {

        notifyWarning ( msg );

        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };

    const onModifyViewUrl = ( url, onSelectMst ) => {

        return url + "/" + onSelectMst.orderPurchaseId;

    };

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onApiInitialized = ( api ) => {

        setDetailApi ( api );

    };

    return (
        <BasicCrudScreenType 
            id={id}
            headerTitle={t("term.expense")}
            table={table}
            view={view}
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onLoadData={onLoadData}
            onModifyViewUrl={( url ) => onModifyViewUrl ( url, onSelectMst )}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch = {
                () => (
                    <>
                    <BasicCrudScreenActionItemLayout>
                        <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                            <MomSysBusinessDetailCreateButton
                                id={id}
                                schema={tableSchema}
                                onSuccess={onNotifySuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                            <MomSysBusinessDetailUpdateButton
                                id={id}
                                schema={tableSchema}
                                grid={grid}
                                disabled={onSelectDetail?.approvalStateId || isEmpty}
                                onSuccess={onNotifySuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                            <MomSysBusinessDetailDeleteButton
                                id={id}
                                schema={tableSchema}
                                grid={grid}
                                color="secondary"
                                disabled={onSelectDetail?.approvalStateId || isEmpty}
                                onSuccess={onNotifySuccess}
                                onFailure={onFailure}
                                onPartialFailure={onPartialFailure}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="APPROVAL">
                            <ApprovalButton
                                id={id}
                                grid={grid}
                                refresh={detailApi}
                                approvalCtx={approvalCtx}
                                disabled={onSelectDetail?.approvalState === defineConf.approvalState.APPROVED || isEmpty}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="SAVETOEXCEL">
                            <SaveToExcelButton
                                id={id}
                                grid={grid}
                                view={view}
                                color="primary"
                                exportFormatItem={() => {}}
                                disabled={isEmpty}
                            />
                        </AccessControl>
                    </BasicCrudScreenActionItemLayout>
                    </>
                )
            }
        
        />
    )

}

export default MomSysExpenseDetail;
