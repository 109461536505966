/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useTranslation } from "react-i18next";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import defineConf from "../../../config/defineConf";
import "../layout/DataGridQcResultColumn.css";

function DataGridQcResultColumn(props) {
    const { schema, ...others } = props;

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    const { t } = useTranslation();

    useEffect(() => {
        setCellTemplate(() => (ctx) => {
            let qcResult = parseInt(ctx.item.qcResult);

            switch (qcResult) {
                case defineConf.qcResult.QC_OK:
                    return <span className="qc-ok">{t(`selectItems.qcResult.OK`)}</span>;

                case defineConf.qcResult.QC_NOK:
                    return <span className="qc-nok">{t(`selectItems.qcResult.NOK`)}</span>;

                default:
                    return <span></span>;
            }
        });
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridQcResultColumn;
