import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import getSchema from "../../lib/api/getSchema";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";
import EguInputInspectionDetailRegisterButton from "../input/EguInputInspectionDetailRegisterButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";

function EguInputInspectionDetail(props) {
    const { inputInspectionSelect, onSetDetailApi, onSetDetailDataEmpty, onSuccess, onFailure } = props;

    const id = "EguInputInspectionDetail";
    const table = "EguInputInspectionDetail";
    const view = "EguVwInputInspectionDetail";

    const { t } = useTranslation();

    const [inputInspectionDetailSchema, setInputInspectionDetailSchema] = useState();
    const [inputInspectionDetailGrid, setInputInspectionDetailGrid] = useState();
    const [inputInspectionDetailApi, setInputInspectionDetailApi] = useState();
    const [inputInspectionDetailDataIsEmpty, setInputInspectionDetailDataIsEmpty] = useState();
    const [inputInspectionDetailSelect, setInputInspectionDetailSelect] = useState();
    const [reportUrl, setReportUrl] = useState();

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setInputInspectionDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (inputInspectionDetailApi) {
            inputInspectionDetailApi.refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputInspectionSelect.inputInspectionId]);

    useEffect(() => {
        const path = `inputInspectionDetail/${inputInspectionDetailSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;
        if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "staging") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [inputInspectionDetailSelect]);

    useEffect(() => {
        (async () => {
            if (inputInspectionDetailGrid) {
                inputInspectionDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setInputInspectionDetailSelect(grid.selectedItems[0]);
                        } else {
                            setInputInspectionDetailSelect([]);
                        }
                    }),
                    10
                );

                inputInspectionDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                        setInputInspectionDetailSelect(grid.selectedItems[0]);
                        } else {
                            setInputInspectionDetailSelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputInspectionDetailGrid]);

    return (
        <BasicCrudScreenType
            id={id}
            view={view}
            headerTitle={t("term.eguInputInspectionDetail")}
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setInputInspectionDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setInputInspectionDetailApi(api);

                if (onSetDetailApi) {
                    onSetDetailApi(api);
                }
            }}
            onLoadData={(data) => {
                const dataIsEmpty = data.length < 1 ? true : false;
                setInputInspectionDetailDataIsEmpty(dataIsEmpty);

                if (onSetDetailDataEmpty) {
                    onSetDetailDataEmpty(dataIsEmpty);
                }
            }}
            onModifyViewUrl={(url) => `${url}/${inputInspectionSelect.inputInspectionId}`}
            onAppendButtonInFrontOfSearch={() => (
                <React.Fragment>
                    <BasicCrudScreenActionItemLayout>
                        <EguInputInspectionDetailRegisterButton
                            id={id}
                            inputInspectionSelect={inputInspectionSelect}
                            schema={inputInspectionDetailSchema}
                            refresh={inputInspectionDetailApi}
                            onSuccess={onSuccess}
                            disabled={
                                inputInspectionSelect.length <= 0 ? true : false || inputInspectionSelect.approvalState
                            }
                        />

                        <UpdateRecordButton
                            id={id}
                            grid={inputInspectionDetailGrid}
                            schema={inputInspectionDetailSchema}
                            refresh={inputInspectionDetailApi}
                            disabled={
                                inputInspectionSelect.approvalState ||
                                inputInspectionDetailSelect?.lotNo ||
                                inputInspectionDetailDataIsEmpty
                            }
                        />

                        <DeleteSingleRecordButton
                            id={id}
                            schema={inputInspectionDetailSchema}
                            grid={inputInspectionDetailGrid}
                            refresh={inputInspectionDetailApi}
                            makeItemName={(row) => {
                                return ` - ${t("columnName.inputInspectionDetailId")}: ${row.inputInspectionDetailId}`;
                            }}
                            color="secondary"
                            disabled={
                                inputInspectionSelect.approvalState ||
                                inputInspectionDetailSelect?.lotNo ||
                                inputInspectionDetailDataIsEmpty
                            }
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />

                        <PrintReportButton noLayout url={reportUrl} disabled={!inputInspectionDetailSelect?.lotNo}>
                            {t("buttonName.lotInfoPrint")}
                        </PrintReportButton>
                    </BasicCrudScreenActionItemLayout>
                </React.Fragment>
            )}
        />
    );
}

export default EguInputInspectionDetail;
