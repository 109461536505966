import { useEffect, useState } from "react";
import getSchema from "../../lib/api/getSchema";
import { useSetRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import AccessControl from "../functional/AccessControl";
import EguAdminAccountCreateButton from "../input/EguAdminAccountCreateButton";
import EguAdminAccountUpdateButton from "../input/EguAdminAccountUpdateButton";

function UserManage ( props ) {
    const [grid, setGrid] = useState();
    const [schema, setSchema] = useState ();
    const [refresh, setRefresh] = useState ();
    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        let mounted = true;

        async function schemaSetup() {
            
            let schema = await getSchema("User");

            if ( mounted ) {

                setSchema(schema);

            }

        }

        schemaSetup();

        return ( () => {

            mounted = false;

        } );

    },[]);

    const onInitialized = ( grid ) => {
        grid.selectionMode = "Row";
        setGrid ( grid );
    }

    const onApiInitialized = ( api ) => {
        setRefresh ( api );
    }

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const onSuccess = ( msg ) => {
        notifySuccess ( msg );
    };

    const onAppendButtonAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                    <EguAdminAccountCreateButton
                        id = "EguAdminAccountCreate"
                        schema = {schema}
                        refresh = {refresh}
                        grid = {grid}
                        onSuccess = {onSuccess}
                    />
                </AccessControl>

                <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                    <EguAdminAccountUpdateButton
                        id = "EguAdminAccountUpdate"
                        schema = {schema}
                        refresh = {refresh}
                        grid = {grid}
                        onSuccess = {onSuccess}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        )
    }

    return (
        <>
            <BasicCrudScreenType 
                id="User" 
                table="User" 
                view="EguVwAdminAccountManage" 
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                noCreate
                noEdit
                onAppendButtonAtFirst = {onAppendButtonAtFirst}
            />        
        </>
    ) 

}

export default UserManage;

