import { useState, useEffect } from "react";

import ReactHtmlParser from "react-html-parser";

import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import BasicCrudScreenContentLayout from "../screenTypes/layout/BasicCrudScreenContentLayout";

import apiClient from "../../lib/common/apiClient";

function SimpleHtmlViwerScreen ( props ) {

    const {

        title,
        path,
        startDate,
        endDate,

    } = props;
    const [ , setHeight ] = useState(0);
    const [ , setApiPath ] = useState("");
    const [ html, setHtml ] = useState("");

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect ( () => {

        let mounted = true;

        if ( title && path && startDate && endDate ) {

            let apiPath = `/api/SimpleHtmlEditor/${path}/${startDate.toISOString()}/${endDate.toISOString()}`;

            setApiPath ( { path, startDate, endDate } );

            ( async () => {

                if ( mounted ) {

                    try {

                        let response = await apiClient.get ( apiPath );

                        let html = response?.data ?? "";

                        if ( process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            html = html.replaceAll ( 'src="/files', 'src="http://localhost:8080/files' );

                        }

                        setHtml ( html );

                    } catch ( reason ) {

                        console.error ( "Load Editor Content Failed", reason );                        

                        let html = '<h1>Error Loading HTML</h1>';
                        setHtml ( html );
                        
                    }
    
                }
    
            } ) ();

        }

        return ( () => {

            mounted = false;

        } );

    }, [ title, path, startDate, endDate ] );

    return (

        <div>

            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                    <span
                        color="inherit"
                        align="center"
                        style={ {
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            marginTop: "8px"
                        } }
                    >
                        { title }
                    </span>

                </BasicCrudActionsLayout>
                
            </BasicCrudScreenHeaderLayout>

            <div
                className="basicCrudScreenContentLayout"
                style={ {
                    position: "absolute",
                    top: "48px",
                    bottom: "0px",
                    left: 0,
                    right: 0,
                    padding: 0,
                    margin: 0,
                    overflow: "hidden",
                    borderTop: "1px solid rgb(212, 212, 212)"
                } }
            >

                <div
                    style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        height: '100%',
                        border: "none",
                        overflow: "auto"
                    }}
                >
                    {
                        ReactHtmlParser ( html )
                    }
                </div>

            </div>
            
        </div>

    );

}

export default SimpleHtmlViwerScreen;
