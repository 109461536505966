import { useTranslation } from "react-i18next";


import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";
import apiClient from "../../lib/common/apiClient";
import notificationState from "../../states/notificationState";
import { useRecoilState } from "recoil";

function UserLotProductionReportExcelButton(props) {
    const { t } = useTranslation();

    const { id, startDate, endDate, disabled, ...others } = props;

    const [, setNotification] = useRecoilState(notificationState);

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("success.fileDownloadFailure", { reason })
        );
    };

    const saveToExcel = () => {
        let url = `/api/UserLotProductionReportExcelDownload/excelDownload/${startDate.getFullYear()}/${startDate.getMonth() + 1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth() + 1}/${endDate.getDate()}`

        apiClient
            .get(url, { responseType: "blob" })
            .then((result) => {
                const blob = new Blob([result.data]);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                if ( startDate.getTime () === endDate.getTime () ) {
                    link.download = `일일 생산 관리 일지 ${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}.xlsx`;
                } else {
                    link.download = `생산 보고서 ${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()} ~ ${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}.xlsx`;
                }
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch(async (reason) => {
                const blob = reason.response.data
                const msg = await blob.text();
                onApiFailure(msg)
            })
    };

    return (
        <>
            <SaveToExeclButtonLayout id={id + "save-to-execl-button"} onClick={saveToExcel} disabled={disabled} {...others} />
        </>
    );
}

export default UserLotProductionReportExcelButton;
