/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import MomSysSoProductionSo from "../screens/MomSysSoProductionSo";
import SelectProductionItem from "../screens/SelectProductionItem";

function CreateProductionPlanDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [so, setSo] = useState ();
    const [, setPlans] = useState ([]);
    const [buttonDisabled, setButtonDisabled] = useState ([true, false]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.createProductionPlanSuccess"), result?.data?.taskId );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;
        })

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.createProductionPlanFail" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const convertProcessPlans = ( plans ) => {

        let result = [];

        for ( let plan of plans ) {;

            if ( plan.produce ) {

                let processPlan = {
    
                    taskType: "processPlan",
                    processCode: plan.processCode,
                    processName: plan.processName,
                    materialCode: plan.materialCode,
                    materialCodeVer: plan.materialCodeVer,
                    children: convertMaterialPlans ( plan.children ),
    
                };
    
                result.push ( processPlan );
    
            }

        }

        return result;

    };
    
    const convertMaterialPlans = ( plans ) => {

        let result = [];

        for ( let plan of plans ) {

            if ( plan.produce ) {

                let materialPlan = {

                    taskType: "materialPlan",
                    materialCode: plan.materialCode,
                    materialCodeVer: plan.materialCodeVer,
                    materialName: plan.materialName,
                    qty: plan.qty,
                    children: convertProcessPlans ( plan.children ),
    
                };
    
                result.push ( materialPlan );
    
            }

        }

        return result;

    };

    const convertOrderBuyerDetailPlans = ( plans ) => {

        let result = [];

        for ( let plan of plans ) {

            if ( plan._data.taskType === 'orderBuyerDetailPlan' && plan._data.produce ) {

                let orderBuyerDetailPlan = {

                    taskType: "orderBuyerDetailPlan",
                    orderBuyerDetailId: plan._data.orderBuyerDetailId,
                    materialCode: plan._data.materialCode,
                    materialCodeVer: plan._data.materialCodeVer,
                    materialName: plan._data.materialName,
                    children: convertMaterialPlans ( plan._data.children ),
    
                };
    
                result.push ( orderBuyerDetailPlan );
    
            }

        }

        return result;
    };

    const onOk = () => {

        setSo ( ( so ) => {

            setPlans ( ( plans ) => {

                if ( plans.length <= 0 ) {
                    return plans;
                }

                let apiData = {
                    taskType: "orderBuyerMstPlan",
                    orderBuyerId: so.orderBuyerId,
                    children: convertOrderBuyerDetailPlans ( plans )
                };
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};

                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );
                
                apiClient
                .post("/api/EguPlan", form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(apiData, reason));

                return plans;
    
            } );
    
            return so;

        } );        
            
    }

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }

    };

    const onSelectSo = ( so ) => {

        setSo ( so );

    }

    const onChangeProductionPlan = ( plans ) => {

        setPlans ( plans );

        if ( plans.length > 0 ) {

            let planCount = 0;

            for ( let plan of plans ) {

                if ( plan._data.produce ) {

                    planCount++;

                }

            }

            setButtonDisabled ( [!planCount, false] );

        } else {

            setButtonDisabled ( [true, false] );

        }

    }

    const onNotifyError = ( msg ) => {

        notifyFailure ( msg );
        
    }

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.createProductionPlan" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "710px"}} data-cy="createProductionPlanDialog">

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.5} data-cy="orderBuyerMst">

                        <MomSysSoProductionSo
                            embededInOtherScreenType
                            onSelect={onSelectSo}
                        />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.5} data-cy="planTasks">

                        <SelectProductionItem so={so} embededInOtherScreenType onChange={onChangeProductionPlan} onNotifyError={onNotifyError} notifyWarning={notifyWarning} />

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                buttonDisabled={ buttonDisabled }
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CreateProductionPlanDialog;
