/* eslint-disable react/jsx-no-target-blank */
import { Button, TextField, Checkbox, FormControlLabel, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
    unstable_createMuiStrictModeTheme,
    ThemeProvider,
    makeStyles,
} from "@material-ui/core/styles";import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import uiConf from "../../config/uiConf";
import getNoAuthSpecific from "../../lib/api/getNoAuthSpecific";
import isChecked from "../../lib/utils/isChecked";

import apiClient from "../../lib/common/apiClient";

let theme = unstable_createMuiStrictModeTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: "#18202c",
            },
        },
        MuiButton: {
            label: {
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                bottom: 8,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
                margin: "0 16px",
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
            textColorPrimary: {
                color: theme.palette.primary.contrastText
            },
            textColorSecondary: {
                color: theme.palette.secondary.contrastText
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 16,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const useStyles = makeStyles((theme) => ({
    background: {
        background: "white",
        resize: "both",
        padding: 0,
        margin: 0,
        width: "100vw",
        height: "100vh",
    },
    loginBox: {
        position: "absolute",
        backgroundColor: "white",
        width: "900px",
        top: "50%",
        left: "50%",
        marginTop: "-280px",
        marginBottom: 0,
        marginLeft: "-450px",
        marginRight: 0,
        padding: 0,
    },
    loginForm: {
        width: "100%",
        gridColumn: "2/3",
        gridRow: "2/3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
    },
    textField: {
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
    },
    gridContainer: {
        display: "grid",
        gridGap: 0,
        gridTemplateColumns: "450px 450px",
        gridTemplateRows: "450px auto",
    },
    left: {
        width: "450px",
        height: "450px",
        gridColumn: "1/2",
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        gridTemplateRows: "15% 70% 15%",
    },
    right: {
        backgroundColor: "red",
        height: "450px",
        gridColumn: "2/3",
    },
    footer: {
        gridColumn: "1/3 ",
        gridRow: "2/3",
        height: "110px",
        marginTop: "auto",
    },
    copyright: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
    },
    logo: {
        gridRow: "1/2",
        gridColumn: "2/3",
        padding: 0,
        margin: 0
    },
    logoImage: {
        marginLeft: "-15px",
        width: "190px",
    },
    logoText: {
        fontSize: "40px",
        fontWeight: "700",
        padding: 0,
        margin: 0,
        marginTop: "24px"
    },
    image: {
        width: "450px",
    },
    button: {
        marginTop: "20px",
        width: "50%",
    },
    freepik: {
        position: "absolute",
        top: "395px",
        left: "745px",
    },
    anchor: {
        color: "gray",
        textDecoration: "none",
        fontSize: "9px",
    },
    checkAndLink: {
        gridColumn: "2/3",
        gridoRow: "3/4",
        display: "grid",
        gridTemplateColumns: "36% 32% 32%",
        height: "20px",
        marginTop: "10px",
    },
    checkBox: {
        gridColumn: "1/2",
    },
    checkBoxLabel: {
        marginLeft: "-8px",
    },
    findPassword: {
        gridColumn: "2/3",
        alignItems: "center",
    },
    customerService: {
        gridColumn: "3/4",
        alignItems: "center",
    },
    notice: {
        textAlign: "center",
        margin: "3px",
        fontSize: "16px",
        fontWeight: "500",
        color: "#ff0000",
    },
    companyInfo: {
        textAlign: "center",
        margin: "3px",
        fontSize: "12px",
    },
}));

export default function LoginLayout(props) {

    const classes = useStyles();

    const { t } = useTranslation();
    const { tenant, username, password, disabled, storeCompanyId, onChangeCompanyId, onSubmit, onChangeTenant, onChangeUserName, onChangePassword } = props;

    const [, setTenantInfo] = useState();
    const [, setLogoUrl] = useState();
    const [, setStoreId] = useState();
    const [, setDialog] = useState();
    const [, setTimer] = useState();
    const [tenantName, setTenantName] = useState(tenant);
    const [notification, setNotification] = useState(false);
    const [loginNotification, setLoginNotification] = useState();

    useEffect ( () => {

        ( async () => {

            try {

                let loginNotification = await getNoAuthSpecific("LoginNoticeToShow");
                setLoginNotification ( loginNotification );

            } catch ( reason ) {

                setLoginNotification ( undefined );

            }

        } ) ();

    }, [])

    useEffect(() => {

        setStoreId ( storeCompanyId );

    }, [storeCompanyId])

    useEffect(() => {
        if (tenant) {
            getNoAuthSpecific("VwTenant", tenant).then((tenantInfo) => {
                setTenantInfo(tenantInfo);

                if (tenantInfo.largeImage) {
                    setLogoUrl(
                        ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' )
                            ? `/noAuth/TenantLogoImage/${tenant}/large`
                            : `http://localhost:8080/noAuth/TenantLogoImage/${tenant}/large`
                    );
                }
            });

            setTenantName(tenant);
        }
    }, [tenant]);

    const onChangeTenantName = (e) => {

        let tenantName = e.target.value;

        setTenantName(tenantName);

        setTimer((timer) => {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                onChangeTenant(tenantName);
            }, 500);

            return timer;
        });
    };

    const onChangeCheckbox = ( e ) => {

        let checked = e.target.checked;

        if ( onChangeCompanyId ) {

            onChangeCompanyId ( checked );

        }

    };

    const toggleStoreCompanyAndUserId = () => {

        setStoreId ( ( storeId ) => {

            if ( onChangeCompanyId ) {

                onChangeCompanyId ( !storeId );
    
            }
    
            return storeId;

        } );

    }

    const openFindPasswordDialog = () => {

        setDialog ( ( dialog ) => {

            dialog.show ();

            return dialog;

        } );
    }
    const onConfirmDialogInitialized = ( dialog ) => {

        setDialog ( dialog );

    };

    const onConfirmed = ( tenant, username ) => {

        ( async () => {

            try {

                await apiClient.post ( "/auth/sendResetPasswordEmail", {

                    tenant: tenant,
                    username: username
    
                } );
    
                setNotification({
                    severity: "success",
                    msg: t("notification.msg.passwordChangeEmailSent"),
                    open: true,
                });
    
            } catch ( reason ) {

                setNotification({
                    severity: "error",
                    msg: t("error.sendResetPasswordEmailFailed"),
                    open: true,
                });

            }
    
        } ) ();

    }

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    return (

        <ThemeProvider theme={theme}>

            <div className={classes.background}>
                <div className={classes.loginBox}>
                    <div className={classes.gridContainer}>
                        <div className={classes.freepik}>
                            <a href="http://www.freepik.com" className={classes.anchor} target="_blank" >
                                Image Designed by Freepik
                            </a>
                        </div>
                        <div className={classes.left}>
                            <div className={classes.logo}>
                                {/* <img src="/images/logo.png" alt="ariatech logo" className={classes.logoImage} /> */}
                                <h1 className={classes.logoText}>Radix-MOM</h1>
                            </div>
                            <form id="loginForm" className={classes.loginForm} autoComplete="off" >
                                <TextField

                                    className={classes.textField}
                                    id="company"
                                    data-testid="company"
                                    name="company"
                                    label={t("term.company")}
                                    disabled={disabled}
                                    onChange={onChangeTenantName}
                                    value={tenantName}
                                    inputProps={{
                                        tabIndex: "1"
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoFocus

                                />
                                <TextField

                                    className={classes.textField}
                                    id="username"
                                    data-testid="username"
                                    name="username"
                                    label={t("term.userName")}
                                    disabled={disabled}
                                    onChange={onChangeUserName}
                                    value={username || ""}
                                    inputProps={{
                                        tabIndex: "2"
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}

                                />
                                <TextField

                                    className={classes.textField}
                                    id="password"
                                    data-testid="password"
                                    name="password"
                                    label={t("term.password")}
                                    type="password"
                                    disabled={disabled}
                                    onChange={onChangePassword}
                                    value={password || ""}
                                    inputProps={{
                                        tabIndex: "3"
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}

                                />
                                <Button
                                    className={classes.button}
                                    id="login"
                                    disabled={disabled}
                                    data-testid="login"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    tabIndex= "4"
                                    onClick={onSubmit}
                                >
                                    {t("term.login")}
                                </Button>
                            </form>
                            <div className={classes.checkAndLink}>
                                <FormControlLabel
                                    classes={{
                                        label: classes.checkBoxLabel,
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            checked={isChecked(storeCompanyId)}
                                            tabIndex= "5"
                                            onChange={onChangeCheckbox}
                                        />
                                    }
                                    size="smaller"
                                    label={
                                        <Button
                                            color="primary"
                                            tabIndex= "6"
                                            onClick={toggleStoreCompanyAndUserId}
                                        >
                                            {t("term.storeCompanyAndUserId")}
                                        </Button>
                                    }
                                    color="primary"
                                />
                                <Button className={classes.findPassword} color="primary" tabIndex="7" onClick={openFindPasswordDialog} >
                                    {t("term.findPassword")}
                                </Button>
                                <Button className={classes.customerService} color="primary" tabIndex="8" >
                                    {t("term.customerService")}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.marginRight}>
                            <img src="/images/login.jpg" className={classes.image} alt="loginFormImage" />
                        </div>
                        <div className={classes.footer}>
                            {
                                loginNotification
                                ?
                                <div
                                    style={{
                                        textAlign: loginNotification?.textAlign || "center",
                                        color: loginNotification?.color || "#ff0000",
                                        fontSize: loginNotification?.fontSize || "16px",
                                        fontFamily: loginNotification?.fontFamily || undefined,
                                        fontWeight: loginNotification?.fontWeight || "500",
                                    }}
                                >
                                    { loginNotification.text }
                                </div>
                                :
                                <></>
                            }
                            <div>
                                <br />
                                <p className={classes.companyInfo}>
                                    {t("ariatech.zip")} {t("ariatech.address")}  {t("ariatech.companyName")} {t("ariatech.ceo")} {t("ariatech.bizLicNo")}
                                </p>
                                <p className={classes.companyInfo}>
                                    {t("ariatech.email")} {t("ariatech.tel")} {t("ariatech.fax")}
                                </p>
                                <br />
                            </div>
                            <div className={classes.copyright}>
                                Copyright © &nbsp;
                                <a href="https://ariatech.kr/" style={{ textDecoration: "none" }}>
                                    {t("ariatech.copyright")}
                                </a>{" "}
                                &nbsp; 2022
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmDialog

                id={"find-password-confirm-dialog"}
                title={ t("dialog.title.ConfirmFindPassword") }
                msg={ t("dialog.msg.ConfirmFindPassword") }
                onOk={ () => onConfirmed ( tenant, username ) }
                onInitialized={ onConfirmDialogInitialized }

            />

            <Snackbar
                open={notification.open}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    { notification.msg }
                </Alert>    
            </Snackbar>

        </ThemeProvider>

    );
}
