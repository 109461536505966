import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import {  debounce } from "lodash";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysInspectionRsltRegisterButton from "../input/MomSysInspectionRsltRegisterButton";
import MomSysInspectionRsltUpdateButton from "../input/MomSysInspectionRsltUpdateButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysInspectionRslt(props) {
    const { inspectionSelect, onUpdateMaster, onInspectionRsltEmpty } = props;

    const id = "MomSysInspectionRslt";
    const table = "MomSysInspectionRslt";
    const view = "MomSysVwInspectionRslt";

    const [momSysInspectionRsltSchema, setMomSysInspectionRsltSchema] = useState();

    const [momSysInspectionRsltGrid, setMomSysInspectionRsltGrid] = useState();
    const [momSysInspectionRsltApi, setMomSysInspectionRsltApi] = useState();
    const [momSysInspectionRsltisEmpty, setMomSysInspectionRsltIsEmpty] = useState();
    const [, setMomSysInspectionRsltSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysInspectionRsltSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (momSysInspectionRsltApi) {
            momSysInspectionRsltApi.refresh();
        }
    }, [inspectionSelect.inspectionId, momSysInspectionRsltApi]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (momSysInspectionRsltGrid && mounted) {
                momSysInspectionRsltGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysInspectionRsltSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysInspectionRsltSelect();
                        }
                    }, 10)
                );

                momSysInspectionRsltGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysInspectionRsltSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysInspectionRsltSelect();
                        }
                    }, 10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [momSysInspectionRsltGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (onUpdateMaster) {
            onUpdateMaster();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.momSysInspectionRslt")}
            table={table}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={(url) => `${url}/${inspectionSelect.inspectionId}`}
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                setMomSysInspectionRsltGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMomSysInspectionRsltApi(api);
            }}
            onLoadData={(data) => {
                setMomSysInspectionRsltIsEmpty(data.length < 1 ? true : false);

                if (onInspectionRsltEmpty) {
                    onInspectionRsltEmpty(data.length < 1 ? true : false);
                }
            }}
            onAppendButtonInFrontOfSearch={() => (
                <React.Fragment>
                    <BasicCrudScreenActionItemLayout>
                        <MomSysInspectionRsltRegisterButton
                            id={id}
                            schema={momSysInspectionRsltSchema}
                            inspectionSelect={inspectionSelect}
                            refresh={momSysInspectionRsltApi}
                            disabled={inspectionSelect?.length <= 0 ? true : false || inspectionSelect?.approvalState}
                            onSuccess={onSuccess}
                        />

                        <MomSysInspectionRsltUpdateButton
                            id={id}
                            schema={momSysInspectionRsltSchema}
                            grid={momSysInspectionRsltGrid}
                            refresh={momSysInspectionRsltApi}
                            disabled={inspectionSelect?.approvalState || momSysInspectionRsltisEmpty}
                            onSuccess={onSuccess}
                        />

                        <DeleteRecordButton
                            id={id}
                            schema={momSysInspectionRsltSchema}
                            grid={momSysInspectionRsltGrid}
                            refresh={momSysInspectionRsltApi}
                            color="secondary"
                            disabled={inspectionSelect?.approvalState || momSysInspectionRsltisEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </BasicCrudScreenActionItemLayout>
                </React.Fragment>
            )}
        />
    );
}

export default MomSysInspectionRslt;
