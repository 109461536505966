/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, FormHelperText, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";

import getList from "../../../lib/api/getList";

const CustomPaper = (props) => {

    return <Paper square={true} {...props} />;

};

function FormItemAutoCompleteSolo(props) {

    const { t } = useTranslation();

    const { id, className, column, label, helperText, InputLabelProps, onChange, ...others } = props;

    const [selectItems, setSelectItems] = useState([]);
    const [currentValue, setCurrentValue] = useState({ name: "", value: 0 });

    useEffect(() => {

        let mounted = true;

        (async () => {

            const codeInfo = column.codeInfo;

            try {

                let apiResult = await getList(codeInfo.tableName);

                if (mounted) {

                    let selectItems = apiResult.data.map((result) => {
                        if (Array.isArray(codeInfo.nameColumn)) {
                            let name = "";
                            let first = true;
                            let colIndex = 0;

                            for (const columnName of codeInfo.nameColumn) {

                                if (codeInfo.useParenthesis) {

                                    if (codeInfo.useParenthesis[colIndex]) {

                                        if (result[columnName]?.toString()) {

                                            name += codeInfo.openParenthesis;
                                            if (codeInfo.useTranslation && codeInfo.useTranslation[colIndex]) {
                                                name += t(`autoComplete.value.${result[columnName]}`)
                                            } else {
                                                name += result[columnName]
                                            }
                                            name += codeInfo.closeParenthesis;

                                        } else {

                                            name += "";

                                        }

                                    } else {

                                        if (!first && result[columnName]?.toString()) {

                                            name += (codeInfo.seperator || ", ");

                                        }

                                        first = false;
                                        if (codeInfo.useTranslation && codeInfo.useTranslation[colIndex]) {
                                            name += t(`autoComplete.value.${result[columnName]}`)
                                        } else {
                                            name += result[columnName] ?? "";
                                        }

                                    }

                                } else {

                                    if (!first && result[columnName]?.toString()) {

                                        name += (codeInfo.seperator || ", ");

                                    }

                                    first = false;
                                    if (codeInfo.useTranslation && codeInfo.useTranslation[colIndex]) {
                                        name += t(`autoComplete.value.${result[columnName]}`)
                                    } else {
                                        name += result[columnName] ?? "";
                                    }

                                }

                                colIndex++;

                            }
                            return { name: name, value: result[codeInfo.valueColumn] };

                        } else {

                            return { name: codeInfo.useTranslation ? t(`autoComplete.value.${result[codeInfo.nameColumn]}`) : result[codeInfo.nameColumn], value: result[codeInfo.valueColumn] };
                        }

                    });

                    if (codeInfo.sortByName) {

                        selectItems.sort((itemA, itemB) => (('' + itemA.name).localeCompare(itemB.name)))

                    }

                    if (codeInfo.useEmptyValue) {

                        setSelectItems([{ name: t(codeInfo.emptyName), value: "" }, ...selectItems]);

                    } else {

                        setSelectItems(selectItems);
                    }

                }



            } catch (reason) {

                console.error(`Get Code List from Table "${codeInfo.tableName}" Failed`);

            }

        })();

        return (() => {
            mounted = false;
        });

    }, [column]);


    const selectProps = {

        onInputChange: (_event, option) => {

            let findFlag = false;

            findFlag = selectItems.find((item) => {

                if (item.name === option) {

                    onChange(item.value)
                    return true
                } else {
                    return false
                }

            })

            if (!findFlag) {

                onChange(option)
            }

        },

        ...others
    };

    if (column.displayEmpty) {

        selectProps.displayEmpty = true;

    }

    if (column.style) {

        selectProps.style = column.style;

    }

    if (column.getOptionDisabled) {

        selectProps.getOptionDisabled = column.getOptionDisabled;

    }

    useEffect(() => {
        if (selectProps && selectItems?.length > 0) {
            let value = selectProps?.value;

            if (column.codeInfo?.displayOnlyValue) {
                let currentValue = selectItems.find((item) => item.value === value) ? selectItems.find((item) => item.value === value) : { name: value, value: value }

                setCurrentValue({ name: currentValue?.value?.toString(), value: currentValue?.value })
            } else {

                setCurrentValue(selectItems.find((item) => item.value === value) ? selectItems.find((item) => item.value === value) : { name: value?.toString(), value: value })
            }
        }
    }, [selectProps?.value, selectItems, column])

    return (
        <FormControl className={className} required={column.required} {...others} >
            <InputLabel
                id={id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                {label}
            </InputLabel>
            <Autocomplete
                freeSolo
                // selectOnFocus
                id={id}
                style={{ paddingTop: "16px" }}
                PaperComponent={CustomPaper}
                {...selectProps}
                options={selectItems}
                getOptionLabel={(option) => (option.name)}
                // autoSelect={true}
                renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "chrome-off";
                    return <TextField {...params} autoFocus={props.autoFocus} inputProps={inputProps} />
                }}
                tabIndex={props.inputProps?.tabIndex}
                value={currentValue}
            />

            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );

}

export default FormItemAutoCompleteSolo;
