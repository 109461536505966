import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopStockMoveCreateButton from "../input/PopStockMoveCreateButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import MomSysStockMoveButton from "../input/MomSysStockMoveButton";
import defineConf from "../../config/defineConf";
import { debounce } from 'lodash';

function PopStockMove(props) {
    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [isEmpty, setIsEmpty] = useState(false);

    const [select, setSelect] = useState({});

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysStockMove");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);
    
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelect(grid.selectedItems[0]);
                        } else {
                            setSelect({});
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelect(grid.selectedItems[0]);
                        } else {
                            setSelect({});
                        }
                    },10)
                );
            }
        })();
    }, [grid]);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    
    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        console.log(reason)
        console.log(reason.response)
        notifyFailure(reason.response ? reason.response.data : reason);
        if(api){
            api.refresh();
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };
    return (
        <>
            <BasicCrudScreenType
                id="MomSysStockMoveView"
                table="MomSysStockMove"
                view="MomSysVwStockMove"
                onLoadData={onLoadData}
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.stockMoveView")}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <PopCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <PopStockMoveCreateButton
                                    id="MomSysStockMoveCreate"
                                    schema={schema}
                                    refresh={api}
                                    onSuccess={onSuccess}
                                    createButtonName="warehouseMoveImmediately"
                                />
                            </AccessControl>
                            <AccessControl
                                accessTarget={schema && schema.name}
                                accessMethod="POST"
                            >
                                <MomSysStockMoveButton
                                    id="MomSysStockMove"
                                    schema={schema}
                                    color="primary"
                                    createButtonName="create"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    refresh={api}
                                    grid={grid}
                                    disabled={isEmpty || select?.approvalState !== defineConf.approvalState.APPROVED || select?.moveState}
                                />
                            </AccessControl>
                        </PopCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default PopStockMove;
