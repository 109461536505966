/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysInspectionGroupByMaterialDialog from "../dialogTypes/MomSysInspectionGroupByMaterialDialog";

function MomSysInspectionGroupByMaterialCreateButton ( props ) {

    const { t } = useTranslation();
    const { id, refresh, onSuccess, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );
            
        }
        
        refresh.refresh ();
    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <MomSysInspectionGroupByMaterialDialog
                id={id+"append-dialog"}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default MomSysInspectionGroupByMaterialCreateButton;