/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";

function MomSysSoDetailProductWithBop ( props ) {

    const { t } = useTranslation();

    const { onSelect, onApiInitialized } = props;

    const [grid, setGrid] = useState();
    const [product, setProduct] = useState();

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let soDetail = grid.selectedItems[0];

                        if ( onSelect ) {

                            onSelect(soDetail);
                            setProduct({materialCode: soDetail.materialCode, materialCodeVer: soDetail.materialCodeVer, materialName: soDetail.materialName, qty: soDetail.qtyToProduce});

                        }

                    } else {

                            if ( onSelect ) {

                                onSelect ( undefined );

                            }

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let soDetail = grid.selectedItems[0];

                        if ( onSelect ) {

                            onSelect(soDetail);
                            setProduct({materialCode: soDetail.materialCode, materialCodeVer: soDetail.materialCodeVer, materialName: soDetail.materialName, qty: soDetail.qtyToProduce});

                        }

                    } else {

                        if ( onSelect ) {

                            onSelect ( undefined );

                        }
                        
                    }

                });

            }

        })();

    }, [grid]);

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwSoProductWithBopInventorySummary"
            headerTitle={t("term.soProduct")}
            table="MomSysVwSoProductWithBopInventorySummary"
            view="MomSysVwSoProductWithBopInventorySummary"
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonAtFirst={() =>
                <>

                    <BasicCrudScreenActionItemLayout>

                        <MomSysBomBopTreeButton color="primary" product={product} />

                    </BasicCrudScreenActionItemLayout>


                </>

            }
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysSoDetailProductWithBop;
