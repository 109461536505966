import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import { cloneDeep } from "lodash";

function MomSysPoDetailDeliveredButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, onSuccess, createButtonName, customizeErrorMsgCallback, onSelectMst, onSelectDetail, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [ updatedSchema, setUpdatedSchema ] = useState();

    const [ callBack, setCallback ] = useState();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = async ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

    };
    
    useEffect(() => {

        let updateSchema = cloneDeep(schema);

        for (const column of updateSchema.columns) {
    
            switch (column.name) {

            case "materialCode":
            case "materialCodeVer":
            case "qty":

                column.disabled = true;
                column.formGroup = 0;

                break;

            case "standard":
            case "itemSpecName":
            case "cost":

                column.required = false;
                column.hideInForm = true;

                break;

            case "qtyInput":

                column.required = true;
                column.hideInForm = false;

                break;

            default:

                break;
            }
        }

        let orderBuyerIdColumn = [];
        let decisionCodeColumn = [];
        let companyBarCodeColumn = [];
        let imgColumn = [];
        let companyCodeColumn = [];

        orderBuyerIdColumn.name = "orderBuyerId";
        orderBuyerIdColumn.formItemType = "text";
        orderBuyerIdColumn.hideInForm = true;

        decisionCodeColumn.name = "decisionCode";
        decisionCodeColumn.required = true;
        decisionCodeColumn.formOrder = 75;
        decisionCodeColumn.formGroup = 1;
        decisionCodeColumn.formItemType = "autocomplete";
        decisionCodeColumn.codeInfo = {
            tableName: `MomSysVwDecisionCodeIN`,
            nameColumn: ["decisionCode", "decisionName"],
            valueColumn: "decisionCode",
            useEmptyValue: false,
            sortByName: true,
        }

        companyBarCodeColumn.name = "companyBarCode";
        companyBarCodeColumn.formOrder = 120;
        companyBarCodeColumn.formGroup = 1;
        companyBarCodeColumn.formItemType = "text";

        imgColumn.name = "img";
        imgColumn.formOrder = 200;
        imgColumn.formGroup = 1;
        imgColumn.formItemType = "file";
        imgColumn.maxFiles = 1;
        imgColumn.width = "100%";

        companyCodeColumn.name = "companyCode";
        companyCodeColumn.formItemType = "text";
        companyCodeColumn.defaultValue = onSelectMst.companyCode;
        companyCodeColumn.hideInForm = true;

        updateSchema.columns.push(orderBuyerIdColumn)
        updateSchema.columns.push(decisionCodeColumn)
        updateSchema.columns.push(companyBarCodeColumn)
        updateSchema.columns.push(imgColumn)
        updateSchema.columns.push(companyCodeColumn)

        setUpdatedSchema(updateSchema)

    }, [schema, onSelectMst.companyCode])

    useEffect(() => {

        setCallback ( () => ( formData ) => {

            if ( !formData ) {

                formData = {};
                
            }

            formData.orderBuyerId = onSelectMst.orderBuyerId;
            formData.materialCode = onSelectDetail.materialCode;
            formData.materialCodeVer = onSelectDetail.materialCodeVer;
            formData.materialName = onSelectDetail.materialName;
            formData.qty = onSelectDetail.qty;
            formData.qtyInput = onSelectDetail.qty;
            formData.inputState = onSelectDetail.inputState;
            formData.orderPurchaseDetailId = onSelectDetail.orderPurchaseDetailId;
            formData.standard = onSelectDetail.standard;
            formData.orderBuyerDetailId = onSelectDetail.orderBuyerDetailId;

        });

    }, [onSelectDetail, onSelectMst.orderBuyerId])

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.${createButtonName}` ) : "" }
                schema={updatedSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={callBack}
            />
        </>

    );
}

export default MomSysPoDetailDeliveredButton;

