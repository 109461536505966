import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysProductBackShipDialog from "../dialogTypes/MomSysProductBackShipDialog";

function MomSysProductBackShipButton (props) {

    const {id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, ...others} = props;

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    const modifyUrlCallback = (url) => {

        return url + "/backShip"

    }

    const checkItemValidation = ( formData, column ) => {

        if(column.name === "qtyBackShip") {

            if(formData["shipQty"] - formData["backShipTotalQty"] < formData["qtyBackShip"]) {

                return [ false, t( "error.inputQtyMustBeLessThanOrEqualSumOfShipQtyAndBackShipQty" ) ];
            }
        }

        return [ true, "" ];

    }

    return (
        <>
            <CreateRecordButtonLayout id = {id+"-productBackShip-button"} onClick = {onOpen} createButtonName ={createButtonName} {...others}/>
            <MomSysProductBackShipDialog
                id={id+"productBackShip-dialog"}
                mode="create"
                formId={id+"productBackShip-form"}
                title={schema ? t(`dialog.title.${schema.name}.${'productBackShip'}`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidation}
            />
        </>
    )

}

export default MomSysProductBackShipButton;