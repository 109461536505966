import { atom } from "recoil";

// curruent user's menu set information

const menuInfoState = atom({
        
    key: "menuInfoState",
    default: {
        topMenuItems: [],
        subMenuItems: []
    }
    
});

export default menuInfoState;
