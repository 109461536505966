import React from "react";
import { makeStyles } from "@material-ui/core";

function FilterFormItemAndOrLayout ( props ) {

    const { children, column, ...others } = props;

    let width;

    if ( column && column.filter.condition && column.filter.condition.width ) {

        width = column.filter.condition.width;

    } else {

        width = "10%";

    }


    const useStyles = makeStyles ( ( theme ) => ( {

        root: {
    
            // margin: theme.spacing(1),
            margin: "1%",
            width: width,
    
        },
    
      } ) );
    
    const classes = useStyles ();

    const styledProps = {

        className: classes.root,
        ...others

    };

    return (
        <>
        {
            children &&
            React.Children.map ( children, ( ( child ) => (
                    React.isValidElement ( child )
                    ?
                    React.cloneElement( child, styledProps )
                    :
                    child
            ) ) )

        }
        </>
    );
}

export default FilterFormItemAndOrLayout;
