import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function WorkerDateCapacityManage ( props ) {

    return <BasicCrudScreenType id="VwWorkerDateCapacity" table="WorkerDateCapacity" view="VwWorkerDateCapacity" noExcel />

}

export default WorkerDateCapacityManage;

