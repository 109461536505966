import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSchema from "../../lib/api/getSchema";

function UpdateCompanyCollectionButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, infoType, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [ formData, setFormData ] = useState();

    const [ modifySchema, setModifySchema ] = useState();

    useEffect(() => {

        ( async () => {

            let schema = await getSchema(id);

            switch (infoType) {

                // 선금입금
                case 1:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = infoType;
                
                                break;

                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideDebitAdv":
                            case "sideCredit":

                                column.hideInForm = true;
                
                                break;
                            
                            case "companyCode":
                            case "sideCreditAdv":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }

                    setModifySchema(schema);

                    break;
                    
                // 선금지불
                case 2:
                    
                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = infoType;
                
                                break;

                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideCredit":
                            case "sideCreditAdv":

                                column.hideInForm = true;
                
                                break;

                            case "companyCode":
                            case "sideDebitAdv":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setModifySchema(schema);
                    
                    break;

                // 입금
                case 3:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = infoType;
                
                                break;

                            case "companyCode":
                            case "orderOutTransId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":
                            case "sideDebitAdv":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;
                                
                            case "orderBuyerId":
                            case "sideCredit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setModifySchema(schema);
                    
                    break;

                // 지불
                case 4:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = infoType;
                
                                break;

                            case "companyCode":
                            case "orderBuyerId":
                            case "orderOutTransId":
                            case "sideDebitAdv":
                            case "sideCredit":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;

                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setModifySchema(schema);
                    
                    break;

                // 아웃소싱지불
                case 5:

                    for (const column of schema.columns) {
            
                        switch (column.name) {
            
                            case "infoType":

                                column.defaultValue = infoType;
                
                                break;

                            case "companyCode":
                            case "orderBuyerId":
                            case "orderPurchaseId":
                            case "materialCode":
                            case "materialCodeVer":
                            case "sideDebitAdv":
                            case "sideCredit":
                            case "sideCreditAdv":
                                
                                column.hideInForm = true;
                                
                                break;
                            
                            case "orderOutTransId":
                            case "sideDebit":

                                column.hideInForm = false;
                
                                break;

                        default:
            
                            break;
                        }
                    }
        
                    setModifySchema(schema);
                    
                    break;
            
                default:

                    break;
            }

        } ) ()

    }, [id, infoType])

    const onOpen = () => {

        if ( grid ) {

            setFormData ( {...(grid.selectedItems[0])} );

        }
        
        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUpdateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    return (

        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.update` )  : "" }
                schema={modifySchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>

    );
}

export default UpdateCompanyCollectionButton;

