/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import Button from "../input/Button";

import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import DateRangeSelectorWithButtons from "../input/DateRangeSelectorWithButtons";
import AddPerformanceMetricDialog from "../dialogTypes/AddPerformanceMetricDialog";
import ModifyPerformanceMetricDialog from "../dialogTypes/ModifyPerformanceMetricDialog";
import BatchUpdatePerformanceMetricDialog from "../dialogTypes/BatchUpdatePerformanceMetricDialog";
import DeleteRecordButton from "../input/DeleteRecordButton";
import apiClient from "../../lib/common/apiClient";
import getSchema from "../../lib/api/getSchema";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function VwPerformanceMetric ( props ) {

    const [, setAddPerformanceMetricDialog] = useState();
    const [, setModifyPerformanceMetricDialog] = useState();
    const [, setBatchUpdatePerformanceMetricDialog] = useState();

    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [schema, setSchema] = useState();
    const [queries, setQueries] = useState();
    const [open, setOpen] = useState();

    const [disabled, setDisabled] = useState ( true );
    const [selected, setSelected] = useState ();

    const { t } = useTranslation();

    useEffect ( () => {

        let mounted = true;

        ( async () => {
        
            let schema = await getSchema ( "PerformanceMetric" );

            if ( mounted ) {

                setSchema ( schema );

            }

        } ) ();

        return ( () => {
                
            mounted = false;
    
        } );

    }, [] );

    const setNotification = useSetRecoilState(notificationState);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        } );
        
    };

    const notifyWarning = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };
    
    const onSuccess = ( msg ) => {

        notifySuccess ( msg );

        setApi( ( api ) => {

            api.refresh ();
            return api;

        } );
    
    };
    
    const onFailure = ( msg ) => {

        notifyFailure ( msg );

        setApi( ( api ) => {

            api.refresh ();
            return api;

        } );
    
    };

    const onPartialFailure = ( msg ) => {

        notifyWarning ( msg );

        setApi( ( api ) => {

            api.refresh ();
            return api;

        } );
        
    };

    const makeQueries = ( from, to ) => {

        let queries = {
            $from: from,
            $to: to,
        };

        return queries;

    };

    const onDateRangeChanged = ( from, to ) => {

        let queris = makeQueries ( from, to );
        setQueries ( queris ); 
    };


    const onApiInitialized = ( instance ) => {

        setApi ( instance );

    };

    const onGridInitialized = (grid) => {

        if ( grid ) {

            grid.selectionChanged.addHandler ( ( grid, event ) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    let selected = grid.selectedItems[0];

                    setSelected ( selected );
                    setDisabled ( false );

                } else {

                    setSelected ( undefined );
                    setDisabled ( true );
                    
                }

            } );

            grid.itemsSourceChanged.addHandler ( ( grid, event ) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    let selected = grid.selectedItems[0];

                    setSelected ( selected );
                    setDisabled ( false );
                    
                } else {

                    setSelected ( undefined );
                    setDisabled ( true );

                }

            } );
        }

        setGrid ( grid );
        
    };

    const onAddPerformanceMetricDialgInitialized = (dialog) => {

        setAddPerformanceMetricDialog ( dialog );

    };

    const onModifyPerformanceMetricDialgInitialized = (dialog) => {

        setModifyPerformanceMetricDialog ( dialog );

    };

    const onBatchUpdatePerformanceMetricDialgInitialized = (dialog) => {

        setBatchUpdatePerformanceMetricDialog ( dialog );

    };
    const addPerformanceMetric = () => {

        setAddPerformanceMetricDialog ( ( dialog ) => {

            dialog.show ();
            return dialog;

        } );

    };

    const modifyPerformanceMetric = () => {

        setModifyPerformanceMetricDialog ( ( dialog ) => {

            dialog.show ();
            return dialog;

        } );

    };

    const batchAdd = () => {
                
        setOpen ( true );

    };

    
    const batchUpdate = () => {

        setBatchUpdatePerformanceMetricDialog ( ( dialog ) => {

            dialog.show ();
            return dialog;

        } );

    }

    const onCloseBatchAddConfirmDialog = () => {

        setOpen ( false );

    }
    

    const onBatchAddOk = ( requestData ) => {

        let url = "/api/PerformanceMetric/batch";

        let form = new FormData ();

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }


    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.batchAddPerformanceMetricSuccess", { table: "PerformanceMetric" } ) );

        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.batchAddPerformanceMetricFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };


    const makeDeleteItemName = ( item ) => {


        return `${item.materialCode}/${item.materialCodeVer} ${item.materialName} ${item.processCode} ${item.processName} ${item.machineCode} ${item.machineName}`;

    };

    return (

        <>
            
            <BasicCrudScreenType

                id="VwPerformanceMetric"

                table="PerformanceMetric"
                view="VwPerformanceMetric"

                $queries={ queries }

                noCreate
                noEdit
                noDelete
                noExcel

                onAppendHeaderAtFirst={ () =>
                    <>                
                        <DateRangeSelectorWithButtons maxRange={730} prevWeek prevMonth prevYear onChange={onDateRangeChanged}/>
                    </>
                }

                onAppendButtonInFrontOfSearch = {

                    ( ) => (

                        <>

                            <BasicCrudScreenActionItemLayout>

                                <AccessControl accessTarget="PerformanceMetric" accessMethod="POST">
                                    <Button color="primary" onClick={addPerformanceMetric} >{ t ( "common.add" ) }</Button>
                                </AccessControl>

                            </BasicCrudScreenActionItemLayout>

                            <BasicCrudScreenActionItemLayout>

                                <AccessControl accessTarget="PerformanceMetric" accessMethod="PUT">
                                    <Button color="primary" disabled={disabled} onClick={modifyPerformanceMetric} >{ t ( "common.edit" ) }</Button>
                                </AccessControl>

                            </BasicCrudScreenActionItemLayout>

                            <BasicCrudScreenActionItemLayout>

                                <AccessControl accessTarget="PerformanceMetric" accessMethod="POST">
                                    <Button color="primary" onClick={batchAdd} >{ t ( "common.batchAdd" ) }</Button>
                                </AccessControl>

                            </BasicCrudScreenActionItemLayout>

                            <BasicCrudScreenActionItemLayout>

                                <AccessControl accessTarget="PerformanceMetric" accessMethod="PUT">
                                    <Button color="primary" disabled={disabled} onClick={batchUpdate} >{ t ( "term.batchUpdate" ) }</Button>
                                </AccessControl>

                            </BasicCrudScreenActionItemLayout>

                            <BasicCrudScreenActionItemLayout>

                                <AccessControl accessTarget="PerformanceMetric" accessMethod="DELETE">
                                    <DeleteRecordButton
                                        id={"delete-performance-metric"}
                                        schema={schema}
                                        grid={grid}
                                        refresh={api}
                                        color="secondary"
                                        disabled={disabled}
                                        makeItemName={ makeDeleteItemName }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        onPartialFailure={onPartialFailure}
                                    />
                                </AccessControl>

                            </BasicCrudScreenActionItemLayout>

                        </>

                    )

                }

                onInitialized={onGridInitialized}
                onInstanceInitialized={onApiInitialized}
                
            />                                

            <AddPerformanceMetricDialog
                onInitialized={onAddPerformanceMetricDialgInitialized}
                onSuccess={onSuccess}
            />

            <ModifyPerformanceMetricDialog
                performanceMetric={selected}
                onInitialized={onModifyPerformanceMetricDialgInitialized}
                onSuccess={onSuccess}
            />

            <BatchUpdatePerformanceMetricDialog
                onInitialized={onBatchUpdatePerformanceMetricDialgInitialized}
                onSuccess={onSuccess}
            />

            <ConfirmDialog
                id={"batch-add-performance-metric-confirm-dialog"}
                title={ schema ? t ( `dialog.title.BatchAddPerformanceMetric` ) : "" }
                msg={ t ( "dialog.msg.ConfirmBatchAddPerformanceMetric" ) }
                open={open}
                onClose={onCloseBatchAddConfirmDialog}
                onOk={onBatchAddOk}
                onCancel={onCloseBatchAddConfirmDialog}
            />

        </>

    );

}

export default VwPerformanceMetric;
