import i18n from "i18next"
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import localizationConf from "../../config/localizationConf";

// import getList from "../api/getList";
import getNoAuthSpecific from "../api/getNoAuthSpecific";

async function initLocalization () {

    await i18n
        .use( languageDetector )
        .use( initReactI18next )
        .init( {
            resources: localizationConf.resources,
            fallbackLng: localizationConf.fallBackLanguages,
            interpolation: localizationConf.interpolation,
            keySeperator: localizationConf.useKeySeparator,
            detection: {
                order: ["cookie"],
                lookupCookie: localizationConf.cookieName
            }
        } );

    let resources = await getNoAuthSpecific ( "DefaultLocalizationData" );

    for ( const lang in resources ) {

        for ( const namespace in resources[lang] ) {

            i18n.removeResourceBundle ( lang, namespace );
            i18n.addResourceBundle ( lang, namespace, resources[lang][namespace], true, true );

        }
        
    }

    await i18n
        .use( languageDetector )
        .use( initReactI18next )
        .init( {
            resources: resources,
            fallbackLng: localizationConf.fallBackLanguages,
            interpolation: localizationConf.interpolation,
            keySeperator: localizationConf.useKeySeparator,
            detection: {
                order: ["cookie"],
                lookupCookie: localizationConf.cookieName
            }
        } );
    
}

export default initLocalization;
