import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import apiClient from "../../lib/common/apiClient";

function MaterialInOutReTotalizationButton ( props ) {

    const {date, setOpenProgress, refresh, ...others} = props;

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation ();

    const successNotification = (msg) => {

        setNotification({
            severity : "success",
            msg : msg,
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.reTotalizationFailed",{reason: reason.response ? reason.response.data : reason}), 
            open : true
        });
    };
    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onRecalculationClick = (seletedDate) => {

        let msg ;
        
        let year = seletedDate.getFullYear();
        let month = seletedDate.getMonth() + 1;
        let day = seletedDate.getDate();

        let today = new Date();

        if(new Date(year, month -1, day) > new Date(today.getFullYear(), today.getMonth(), today.getDate())){
            nofifyWarning(t("warning.inputDateNotOverToday"))
            return;
        }

        setOpenProgress(true);


        msg = t("success.daliyReTotalizationSuccess", {year, month, day});

        let url = "/api/BatchReTotalization"

        let apiData = { 
            selectedDate : seletedDate,
        };

        let form = new FormData();
        form.append("json", JSON.stringify(apiData));

        apiClient
            .post(url, form,{
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {

                successNotification(msg);
                if(refresh) {
                    refresh.refresh();

                }
                setOpenProgress(false);
            })
            .catch((reason) => {
                setOpenProgress(false);
                failureNotification(reason)}
            );
    }

    return (
        <>
            <ActionButtonLayout
                id={"reTotalization-button"}
                color="primary"
                onClick = {()=> onRecalculationClick(date)}
                {...others}
            >
                {t("buttonName.reTotalization")}
            </ActionButtonLayout>
        </>
    );
}

export default MaterialInOutReTotalizationButton;
