/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguMstMaterialCreateButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSuccess, grid, ...others} = props;
    const [open, setOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();
    const [formData, setFormData] = useState();
    
    useEffect (() => {
        async function modifiedSchemaSetup() {
            let modifiedSchema = cloneDeep(schema);

            modifiedSchema.columns.forEach(column => setColumn(column));

            setModifiedSchema(modifiedSchema);
        }

        function setColumn(column) {
            disabled(column);
            formItemType(column);
            defaultValue(column);
        }

        function disabled(column) {
            if(column.name === "roleId") {
                column.disabled = true;
            }
        }
        
        function formItemType(column) {
            if(column.name === 'roleId') {
                column.formItemType = "select";
                column.selectItems = roleIdItems(); 
            }
        }

        function roleIdItems() {
            return [
                {
                    name: "admin",
                    value: '1'
                }
            ];
        }
        
        function defaultValue(column) {
            if(column.name === "roleId") {
                column.defaultValue = '1';
            }

            if(column.name === "roleName") {
                column.defaultValue = "admin";
            }
        }

        modifiedSchemaSetup();
    }, [schema]);

    const onOpen = () => {
        if(grid) {
            setFormData({...(grid.selectedItems[0])});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog 
                id={id+"update-dialog"}
                mode="edit"
                title={t(`dialog.title.${schema.name}.update`)}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                grid={grid}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default EguMstMaterialCreateButton;

