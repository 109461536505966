import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import MomSysUpdateOrderBuyerIdButtonLayout from '../layout/MomSysUpdateOrderBuyerIdButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysUpdateOrderBuyerIdButton(props) {

    const { id, onSelect, grid, schema, standard, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [modifiedSchema, setModifiedSchema] = useState();

    const [formData, setFormData] = useState();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    useEffect(() => {

        if (schema) {

            let modifiedSchema = cloneDeep(schema);

            let lotNo = {};

            lotNo.name = "lotNo";

            lotNo.readonly = true;
            lotNo.disabled = true;
            lotNo.formGroup = 0;
            lotNo.formOrder = 2;
            lotNo.formItemType = "text";

            modifiedSchema.columns.push(lotNo);
            let materialCodeColumn = {};

            materialCodeColumn.name = "materialCode";

            materialCodeColumn.readonly = true;
            materialCodeColumn.disabled = true;
            materialCodeColumn.formGroup = 0;
            materialCodeColumn.formOrder = 3;
            materialCodeColumn.formItemType = "text";

            modifiedSchema.columns.push(materialCodeColumn);

            let materialCodeVerColumn = {};

            materialCodeVerColumn.name = "materialCodeVer";

            materialCodeVerColumn.readonly = true;
            materialCodeVerColumn.disabled = true;
            materialCodeVerColumn.formGroup = 0;
            materialCodeVerColumn.formOrder = 4;
            materialCodeVerColumn.formItemType = "text";

            modifiedSchema.columns.push(materialCodeVerColumn);

            let materialNameColumn = {};

            materialNameColumn.name = "materialName";

            materialNameColumn.readonly = true;
            materialNameColumn.disabled = true;
            materialNameColumn.formGroup = 0;
            materialNameColumn.formOrder = 5;
            materialNameColumn.formItemType = "text";

            modifiedSchema.columns.push(materialNameColumn);

            let qtyColumn = {};

            qtyColumn.name = "qty";

            qtyColumn.readonly = true;
            qtyColumn.disabled = true;
            qtyColumn.formGroup = 0;
            qtyColumn.formOrder = 6;
            qtyColumn.formItemType = "text";

            modifiedSchema.columns.push(qtyColumn);
            setModifiedSchema(modifiedSchema);
        }


    }, [schema]);

    const onOpen = () => {

        if (grid) {

            setFormData({ ...(grid.selectedItems[0]) });

        }

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onAdaptSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        refresh.refresh();

    };

    const setInitialDataCallback = (formData) => {

        if (!formData) {

            formData = {};

        }

        formData.str_f = onSelect.orderBuyerId;
        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.materialName = onSelect.materialName;
        formData.qty = onSelect.qtyCurrent;
    }


    const modifyUrlCallback = (url) => {

        let modifiedUrl;

        if (standard) {

            modifiedUrl = `/api/Material/${onSelect.lotNo}/adapt`;

        } else {

            modifiedUrl = `/api/NsMaterial/${onSelect.lotNo}/adapt`;
        }

        return modifiedUrl;

    }

    const checkItemValidation = (formData, column) => {

        if (column.name === "str_t") {

            if ((formData["str_t"] || "") === (formData["str_f"] || "")) {

                return [false, t("error.failToOrderBuyerIdSame")];

            }

        }

        return [true, ""];

    }



    return (
        <>
            <MomSysUpdateOrderBuyerIdButtonLayout id={id + "-updateOrderBuyerId-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "updateOrderBuyerId-dialog"}
                mode="create"
                formId={id + "updateOrderBuyerId-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'updateOrderBuyerId'}`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onAdaptSuccess}
                modifyUrlCallback={modifyUrlCallback}
                setInitialDataCallback={setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidation}
                noKeepOpen
            />
        </>
    )

}

export default MomSysUpdateOrderBuyerIdButton;