/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ImportTranslationButtonLayout from "../layout/ImportTranslationButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

import apiClient from "../../lib/common/apiClient";

function ImportTranslationButton ( props ) {

    const { t } = useTranslation();

    const { id, namespace, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onImportOk = ( msg ) => {

        apiClient.post ( "api/TranslationData" )
            .then ( ( response ) => {
             
                if ( onSuccess ) {

                    onSuccess ( t ( "success.importTranslationDataSuccess" ) );

                }

            } )
            .catch ( ( reason ) => {

                if ( onFailure ) {

                    onFailure ( t ( "error.importTranslationDataFailed", { reason } ) );

                }

            } );

            onClose ();

    }

    return (

        <>
            <ImportTranslationButtonLayout id={id+"import-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"-delete-confirm-dialog"}
                title={ t("dialog.title.ImportTranslation") }
                msg={ t("dialog.msg.confirm.importTranslation") }
                open={open}
                onClose={onClose}
                onOk={onImportOk}
                onCancel={onClose}
            />
        </>

    );
    
}

export default ImportTranslationButton;

