import { useState,useEffect } from "react";

import Box from "@material-ui/core/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as dateFnsLocales from "date-fns/locale";


import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";

import {getFormatDate, getMonthDate, getWeekDate} from '../../lib/common/dateFormat';


function ProductPerformanceManage(props) {

  const [value, setValue] = useState('1');
  const {t} = useTranslation();

  const [refresh, setRefresh] = useState();

  const [startSelectedDate, setStartSelectedDate] = useState(new Date());
  const [endSelectedDate, setEndSelectedDate] = useState(new Date());
  
  const handleStartDateChange = (date) => {

    date.setHours(0,0,0,0);
    setStartSelectedDate(date)

  }

  const handleEndDateChange = (date) => {

    date.setHours(0,0,0,0);
    setEndSelectedDate(date)

  }


  const handleTabChange = (event, newValue) => {

    setValue(newValue);
    switch(newValue) {

        case '1' :

          setStartSelectedDate(new Date());
          setEndSelectedDate(new Date());

        break;
        
        case '2' :

        let weekDate = getWeekDate();
        
        setStartSelectedDate(new Date(weekDate[0]));
        setEndSelectedDate(new Date(weekDate[1]));

        break;
        
        case '3' : 
        let monthDate = getMonthDate();

        setStartSelectedDate(new Date(monthDate[0]));
        setEndSelectedDate(new Date(monthDate[1]));

        break;

        default :

        break;
    }

  }


  useEffect( ()=> {

    if(refresh) {

      refresh.refresh();

    }

  },[startSelectedDate, endSelectedDate, refresh]);


  const onApiInitialized = (api) => {

    setRefresh(api);

  };

  const onModifyUrl = (url) => {

    let formatStartDate = getFormatDate(startSelectedDate);
    let formatEndDate = getFormatDate(endSelectedDate);

    return url + "/" + formatStartDate + "/" + formatEndDate;

  };

  return (
    <>
    <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}  style ={{display : 'inline-block'}}>
                <TabList onChange={handleTabChange}>
                    <Tab label={t("tabMenu.tab.day")} value="1" />
                    <Tab label={t("tabMenu.tab.week")} value="2" />
                    <Tab label={t("tabMenu.tab.month")} value="3" />
                    <Tab label={t("tabMenu.tab.custom")} value="4"/>
                </TabList>
            </Box>
            <Box style = {{float : 'right', paddingTop : '5px'}}>
              <MuiPickersUtilsProvider locale={dateFnsLocales[t("date.localeName")]} utils={DateFnsUtils}>
                  <FormControl style={{width: "180px"}}>
                      <KeyboardDatePicker
                          variant="inline"
                          disableToolbar
                          format={t("date.dateFormat")}
                          margin="normal"
                          onChange={handleStartDateChange}
                          value = {startSelectedDate}
                          label = {t("columnName.performanceDate")}
                          style={{marginTop: "0px"}}
                          disabled = {value !== '4' ? true : false }
                          />
                  </FormControl>
              </MuiPickersUtilsProvider>
              <span style = {{fontSize : "30px"}}>~</span>
              <MuiPickersUtilsProvider locale={dateFnsLocales[t("date.localeName")]} utils={DateFnsUtils}>
                  <FormControl  style={{width: "180px", marginLeft : "15px"}}>
                      <KeyboardDatePicker
                          variant="inline"
                          disableToolbar
                          format={t("date.dateFormat")}
                          margin="normal"
                          onChange={handleEndDateChange}
                          value = {endSelectedDate}
                          label = {t("columnName.performanceDate")}
                          style={{marginTop: "0px"}}
                          disabled = {value !== '4' ? true : false }
                      />
                  </FormControl>
              </MuiPickersUtilsProvider>
          </Box>
              <TabPanel value={value} style={{ padding: "0px" } }>
                  <BasicCrudScreenType
                      id="ProductPerformance"
                      view="VwProductPerformance"
                      noFilter
                      noEdit
                      noCreate
                      noSearch
                      noDelete
                      onApiInitialized = {onApiInitialized}
                      onModifyViewUrl={(url) => onModifyUrl(url)}
                  />
              </TabPanel>
          </TabContext>
    </Box>
  </>
  );
}

export default ProductPerformanceManage;
