/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";

import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AddMaterialButton from "../input/AddMaterialButton";
import UpdateMaterialButton from "../input/UpdateMaterialButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import MomSysProductTreeButton from "../input/MomSysProductTreeButton";

function MomSysBom ( props ) {

    const { t } = useTranslation();

    const { process } = props;

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [material, setMaterial] = useState();
    const [disabled, setDisabled] = useState(false);
    const [schema, setSchema] = useState();

    const setNotification = useSetRecoilState(notificationState);

    
    useEffect(() => {

        let mounted = true;

        const getSchemaInfo = async () => {

            let schema = await getSchema( "MomSysUdtBillOfMaterial" );
    
            if ( mounted ) {

                for (let column of schema.columns){
    
                    switch(column.name) {
        
                        case "description":
                        
                            column.width = "94%";
                            break;
                            
                        default:
        
                            break;
        
                    }
        
                }
        
                let bomMaterialNameColumn = {};
        
                bomMaterialNameColumn.name = "bomMaterialName";
        
                bomMaterialNameColumn.formGroup = 0;
                bomMaterialNameColumn.formOrder = 3;
        
                bomMaterialNameColumn.type = "text";
        
                bomMaterialNameColumn.readonly = true;
                bomMaterialNameColumn.disabled = true; 
        
                schema.columns.push ( bomMaterialNameColumn );
        
                let processCodeColumn = {};
        
                processCodeColumn.name = "processCode";
        
                processCodeColumn.formGroup = 1;
                processCodeColumn.formOrder = 4;
        
                processCodeColumn.type = "text";
        
                processCodeColumn.readonly = true;
                processCodeColumn.disabled = true; 
        
                schema.columns.push ( processCodeColumn );
        
                let processNameColumn = {};
        
                processNameColumn.name = "processName";
        
                processNameColumn.formGroup = 1;
                processNameColumn.formOrder = 5;
        
                processNameColumn.type = "text";
        
                processNameColumn.readonly = true;
                processNameColumn.disabled = true; 
        
                schema.columns.push ( processNameColumn );
        
                let componentNameColumn = {};
        
                componentNameColumn.name = "componentName";
        
                componentNameColumn.formGroup = 2;
                componentNameColumn.formOrder = 10; 
                    
                componentNameColumn.type = "text";
        
                componentNameColumn.readonly = true;
                componentNameColumn.disabled = true; 
        
                schema.columns.push ( componentNameColumn );
        
                let wareHouseCodeColumn = {};
        
                wareHouseCodeColumn.name = "inWareHouseCode";
        
                wareHouseCodeColumn.required = false;
        
                wareHouseCodeColumn.formGroup = 3;
                wareHouseCodeColumn.formOrder = 12;
        
                wareHouseCodeColumn.type = "text";
        
                wareHouseCodeColumn.formItemType = "autocomplete";
        
                wareHouseCodeColumn.codeInfo = {
                    tableName: "MomSysVwLineTypeWareHouse",
                    nameColumn: ["wareHouseCode","wareHouseName"],
                    valueColumn: "wareHouseCode",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                    seperator: ": ",
                }
        
                schema.columns.push ( wareHouseCodeColumn );
        
                setSchema(schema);

            }

        };

        getSchemaInfo ();

        return ( () => {
            mounted = false;
        } );

    }, []);

    useEffect(() => {

        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        material.materialCode = material.inOutMaterialCode;
                        material.materialCodeVer = material.inOutMaterialVer;
                        material.materialName = material.inOutMaterialName;
                        material.qty = material.componentQty;

                        setMaterial(material);
                        setDisabled ( false );

                    } else {

                        setDisabled ( true );
                        setMaterial(undefined);
                        
                    }

                } );

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let material = grid.selectedItems[0];

                        material.materialCode = material.inOutMaterialCode;
                        material.materialCodeVer = material.inOutMaterialVer;
                        material.materialName = material.inOutMaterialName;
                        material.qty = material.componentQty;

                        setMaterial(material);
                        setDisabled ( false );
                        
                    } else {

                        setDisabled ( true );
                        setMaterial(undefined);

                    }

                } );
            }
        } ) ();

    }, [grid]);

    useEffect ( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [process] )

    const onGridInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onModifyUrl = ( process, url ) => {
        
        let newUrl;

        if ( process ) {

            newUrl = url + "/" + process.bopMaterialCode + "/" + process.bopMaterialCodeVer + "/" + process.processCode;

        } else {

            newUrl = url + "/undefined/undefined/undefined";

        }

        return newUrl;
        
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const makeItemName = ( item ) => {

        return `${item.inOutMaterialName}(${item.inOutMaterialCode}/${item.inOutMaterialVer})`;

    };

    return (

        <BasicCrudScreenType
            id="MomSysZvwBopMaterial"
            headerTitle={t("term.bom")}
            table="MomSysUdtBopMaterial"
            view="MomSysVwBopInMaterial"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            onAppendButtonAtFirst={() =>
                <>

                    <BasicCrudScreenActionItemLayout>

                        <MomSysProductTreeButton color="primary" material={material} />

                    </BasicCrudScreenActionItemLayout>

                </>

            }
            onAppendHeaderAtLast={() =>

                <>

                    <BasicCrudScreenActionItemLayout>

                        <AddMaterialButton
                            id="MomSysUdtBopMaterial"
                            schema={schema}
                            process={process}
                            refresh={api}
                            disabled={!process}
                            onSuccess={onSuccess}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <UpdateMaterialButton
                            id="MomSysUdtBopMaterial"
                            schema={schema}
                            material={material}
                            refresh={api}
                            disabled={disabled}
                            onSuccess={onSuccess}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <DeleteRecordButton
                            id="MomSysUdtBopMaterial"
                            title={t("buttonName.delMaterial")}
                            schema={schema}
                            color="secondary"
                            grid={grid}
                            refresh={api}
                            disabled={disabled}
                            makeItemName={makeItemName}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                        
                    </BasicCrudScreenActionItemLayout>

                </>

            }
            onModifyViewUrl={( url ) => onModifyUrl ( process, url )}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />

    );

}

export default MomSysBom;
