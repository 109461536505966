import { useState } from "react";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function Namespace ( props ) {

    const { onSelect, ...others } = props;

    const [ , setApi ] = useState ();
    const [ disabled, setDisabled ] = useState ( true );

    const onInitialized = ( ( grid ) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                let namespace = grid.selectedItems[0];

                if ( namespace.namespaceId === 1 ) {

                    setDisabled ( true );

                } else {

                    setDisabled ( false );

                }

                if ( onSelect ) {

                    onSelect ( namespace );

                }

            } else {

                if ( onSelect ) {

                    onSelect ( undefined );

                }

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                let namespace = grid.selectedItems[0];

                if ( namespace.namespaceId === 1 ) {

                    setDisabled ( true );

                } else {

                    setDisabled ( false );

                }

                if ( onSelect ) {

                    onSelect ( namespace );

                }

            } else {

                if ( onSelect ) {

                    onSelect ( undefined );

                }

            }
        } );

    } );

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    return <BasicCrudScreenType
        id="Namespace"
        table="Namespace"
        view="VwNamespace"
        disableEdit={ disabled }
        disableDelete={ disabled }

        onInitialized={onInitialized} {...others}
        onApiInitialized={onApiInitialized}
    />

}

export default Namespace;

