/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguAdminAccountCreateButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSuccess, grid, ...others} = props;
    const [open, setOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect (() => {
        async function modifiedSchemaSetup() {
            let modifiedSchema = cloneDeep(schema);

            modifiedSchema.columns.forEach(column => setColumn(column));

            setModifiedSchema(modifiedSchema);
            console.log(modifiedSchema);
        }

        function setColumn(column) {
            disabled(column);
            formItemType(column);
            defaultValue(column);
        }

        function disabled(column) {
            if(column.name === "roleId") {
                column.disabled = true;
            }
        }

        function formItemType(column) {
            if(column.name === 'roleId') {
                column.formItemType = "select";
                column.selectItems = roleIdItems(); 
            }
        }

        function roleIdItems() {
            return [
                {
                    name: "관리자",
                    value: '11'
                }
            ];
        }

        function defaultValue(column) {
            if(column.name === "roleId") {
                column.defaultValue = '11';
            }

            if(column.name === "roleName") {
                column.defaultValue = "관리자";
            }
        }

        modifiedSchemaSetup();
    }, [schema]);
    
    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id = {id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title = {t(`dialog.title.${schema.name}.create`)}
                schema = {modifiedSchema}
                open = {open}
                onClose = {onClose}
                onSuccess = {onCreateSuccess}
            />
        </>
    );
}

export default EguAdminAccountCreateButton;

