/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CollectionView } from "@grapecity/wijmo";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import getList from "../../lib/api/getList";
import getSchema from "../../lib/api/getSchema";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicStaticGridScreenType from "../screenTypes/functional/BasicStaticGridScreenType";

function AssignMachineDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();

    const [machines, setMachines] = useState ([]);
    const [schema, setSchema] = useState ();    
    const [collectionView, setCollectionView] = useState ();
    const [grid, setGrid] = useState ();

    const [, setTask] = useState ();

    const [actionButtonsDisabled, setActionButtonsDisabled] = useState ([true, false]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        task,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {

        let mounted = true;

        ( async () => {
        
            let schema = await getSchema("MomSysZvwMachine");

            if ( mounted ) {

                setSchema ( schema )

            }

            let result = await getList ( `MomSysZvwMachine` );

            let machines = result.data.sort ( ( a, b ) => a.machineName.localeCompare ( b.machineName ) );

            machines.unshift ( {
                machineCode: "unassigned",
                machineName: t ( "term.unassigned" )
            } );

            if ( mounted ) {

                setMachines ( machines );

            }
        
        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    useEffect ( () => {

        if ( task ) {

            setGrid ( ( grid ) => {

                if ( grid && task ) {

                    setMachines ( ( machines ) => {
    
                        let selected = 0;

                        for ( let idx in machines ) {
    
                            if ( task.machineCode === machines[idx].machineCode ) {

                                selected = idx;
        
                                break;
    
                            }
    
                        }
                        
                        try {

                            let row = grid.rows[selected]
                            grid.selectedRows = [row];

                        } catch ( reason ) {

                            console.error ( reason );

                        }
                                
                        return machines;
    
                    } );
    
                };
    
                return grid;
    
            } )
        }
    
        setTask ( task );

    }, [task, grid] );

    useEffect ( () => {

        let collectionView = new CollectionView(machines)

        setCollectionView ( collectionView );                                        
        checkActionButtonDisabled ();

    }, [machines]);

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onGridInitialized = ( grid ) => {

        setGrid ( grid );

        if ( grid ) {

            grid.selectionChanged.addHandler ( ( grid ) => {

                checkActionButtonDisabled ();
    
            }) ;
    
        }

    };

    const onApiSuccess = ( result, taskId, machineCode ) => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

        if ( onSuccess ) {

            onSuccess ( t ( "success.assignMachineSuccess"), taskId, machineCode );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.assignMachineFailed" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setTask ( ( task ) => {

            setGrid ( ( grid ) => {

                let planId = task.id;
                let selectedItem = grid.selectedItems[0];
        
                let apiData = {
                    machineCode: selectedItem.machineCode,
                    machineName: selectedItem.machineName
                };
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};
    
                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );
                
                apiClient
                    .put(`/api/EguPlan/${planId}/machine`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => onApiSuccess(result, task.id, selectedItem.machineCode))
                    .catch((reason) => onApiFailure(apiData, reason));
    
                return grid;
                
            } );
    
            return task;

        } );

    }

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const checkActionButtonDisabled = () => {


        setTask ( ( task ) => {

            if ( !task ) {

                return task;

            }

            setGrid ( ( grid ) => {

                let disabled = true;

                let selectedItem = grid?.selectedItems ? grid.selectedItems[0] : undefined;

                if ( task?.machineCode !== selectedItem?.machineCode ) {

                    disabled = false;

                }

                if ( ! task?.machineCode && selectedItem?.machineCode === "unassigned" ) {

                    disabled = true;

                }

                setActionButtonsDisabled ( [disabled, false] );

                return grid;

            } );

            return task;

        } );

    };

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="sm"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.assignMachine", { taskName: task?.taskName } ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "400px"}}>

                <BasicStaticGridScreenType                        
                    id="machines-grid"
                    embededInOtherScreenType
                    headerTitle={t("term.machine")}
                    headerTitleStyle={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginLeft: "8px",
                        marginTop: "8px",
                    }}
                    allowSorting={0}
                    schema={schema}
                    itemsSource={collectionView}
                    onInitialized={onGridInitialized}
                />


                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                buttonDisabled={ actionButtonsDisabled }
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default AssignMachineDialog;
