
/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import authProfileState from "../../states/authProfileState";
import { useRecoilValue } from "recoil";
import getSchema from "../../lib/api/getSchema";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import MomSysUglOrderPurchaseDetail from "./MomSysUglOrderPurchaseDetail";
import layoutConf from "../../config/layoutConf";
import { CellType } from '@grapecity/wijmo.grid';
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import ApprovalButton from "../input/ApprovalButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import getSpecific from "../../lib/api/getSpecific";
import CreateRecordButton from "../input/CreateRecordButton";
import {debounce} from "lodash";

function MomSysUglOrderPurchaseMst ( props ) {

    const id = "MomSysUglOrderPurchaseMst"

    const table = "MomSysUglOrderPurchaseMst"

    const view = "MomSysZvwOrderPurchaseMst"

    const [ grid, setGrid ] = useState();

    const [ onSelectMst, setOnSelectMst ] = useState();

    const [ height, setHeight ] = useState(0);

    const authProfile = useRecoilValue ( authProfileState );

    const { t } = useTranslation ();
    
    const [ approvalCtx, setApprovalCtx ] = useState ();

    const [ schema, setSchema ] = useState ();

    const [ mstApi, setMstApi ] = useState ();

    const [ data, setData ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const [ isDetailEmpty, setIsDetailEmpty ] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect ( () => {

        let mounted = true;

        window.addEventListener('resize', handleResize);
        handleResize();
    
        ( async () => {

            let schema = await getSchema ( table );

            if ( mounted ) {
                setSchema ( schema );
            }

        } ) ();

        return ( () => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        } );

    }, [] );

    useEffect ( () => {
        
        let approvalCtx = {

            approvalTypeId: 8,
            tableName: table,
            statusColumn: "approved",
            schema: schema,
            onValidateApprovalItem: ( item ) => {

                if ( item.createdBy !== authProfile.username ) {

                    return [ false, t( "error.approvalRequesterNotMatch" ) ];
                    
                }
        
                return [ true, "" ];
        
            },
            makeRequestItemDescription: ( item ) => {

                return `${ t ( "approvalRequestMsg.msg.po", { poName : item.plCode, poNumber : item.orderPurchaseId } ) }`; // Use Translation on Production Code

            }
        
        };

        setApprovalCtx ( approvalCtx );
    
    }, [schema] );

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));
    
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));

            }

        } ) ()

    }, [grid] )

    const onLoadData = ( data ) => {

        setData ( data );

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onInitialized = grid => {

        setGrid(grid);

    };

    const onApiInitialized = ( api ) => {

        setMstApi ( api );

    };

    const exportFormatItem = ( args ) => {

        let { panel, col, row, xlsxCell } = args;

        if ( panel.cellType === CellType.Cell ) {

            switch ( panel.columns[col].binding ) {

                case "approvalState":

                    switch (data[row][panel.columns[col].binding]) {

                        case 1:

                            xlsxCell.value = t ("selectItems.approvalState.approvalPending")

                            break;

                        case 2:

                            xlsxCell.value = t ("selectItems.approvalState.partiallyApproved")

                            break;

                        case 3:

                            xlsxCell.value = t ("selectItems.approvalState.totallyApproved")

                            break;
                        
                        case 4:

                            xlsxCell.value = t ("selectItems.approvalState.approvalRejected")

                            break;

                        case 5:

                            xlsxCell.value = t ("selectItems.approvalState.approvalWithdrawn")

                            break;

                        case 6:

                            xlsxCell.value = t ("selectItems.approvalState.approvalCancelled")

                            break;

                        default:
                            break;
                    }

                    break;

                default:

                    break;

            }

        }

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const notifyWarning = (msg) => {

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const onNotifyWarning = ( msg ) => {

        notifyWarning ( msg );

    };

    const onUpdateMaster = () => {

        setGrid ( ( grid ) => {

            if ( grid ) {

                ( async () => {

                    let purchaseMst = await getSpecific("MomSysZvwOrderPurchaseMst", grid.selectedItems[0].orderPurchaseId );

                    grid.selectedItems[0].cost = purchaseMst.cost
                    grid.selectedItems[0].inputState = purchaseMst.inputState
                    grid.selectedItems[0].balancePayment = purchaseMst.balancePayment
                    grid.refresh(false);
    
                } ) ();

            }

            return grid;

        })

    }

    const onDetailEmpty = ( isEmpty ) => {

        setIsDetailEmpty ( isEmpty );

    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType 
                        id={id} 
                        headerTitle={t("term.po")}
                        table={table}
                        view={view}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onLoadData={onLoadData}
                        exportFormatItem={exportFormatItem}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch = {
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                    {/* <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                        <MomSysOrderPurchaseMstSpareButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            createButtonName={'soSpareStock'}
                                        />
                                    </AccessControl>

                                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                        <MomSysOrderPurchaseMstButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl> */}
                                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                        <CreateRecordButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl>

                                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                                        <UpdateRecordButton
                                            id={id}
                                            schema={schema}
                                            grid={grid}
                                            refresh={mstApi}
                                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                                            onSuccess={onSuccess}
                                        />
                                    </AccessControl>

                                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                        <DeleteRecordButton
                                            id={id}
                                            schema={schema}
                                            grid={grid}
                                            refresh={mstApi}
                                            color="secondary"
                                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            onPartialFailure={onNotifyWarning}
                                        />
                                    </AccessControl>

                                    <AccessControl accessTarget={schema && schema.name} accessMethod="APPROVAL">
                                        <ApprovalButton
                                            id={id}
                                            grid={grid}
                                            refresh={mstApi}
                                            approvalCtx={approvalCtx}
                                            disabled={isEmpty || isDetailEmpty}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                        />
                                    </AccessControl>

                                    <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL">
                                        <SaveToExcelButton
                                            id={id}
                                            grid={grid}
                                            view={view}
                                            color="primary"
                                            exportFormatItem={exportFormatItem}
                                            disabled={isEmpty}
                                        />
                                    </AccessControl>
                                </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>

                    {
                    (onSelectMst)
                    ?
                    <MomSysUglOrderPurchaseDetail 
                        id="MomSysUglOrderPurchaseDetail"
                        onSelectMst={onSelectMst}
                        onUpdateMaster={onUpdateMaster}
                        onDetailEmpty={onDetailEmpty}
                    />
                    :
                    <></>
                    }

                </ReflexElement>

            </ReflexContainer>

        </div>
        
    );

}

export default MomSysUglOrderPurchaseMst;
