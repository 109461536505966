/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProduceButtonLayout from "../layout/ProduceButtonLayout";
import ProduceDialog from "../dialogTypes/MomSysProduceDialog";

function ProduceButton ( props ) {

    const { t } = useTranslation();

    const { id, product, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState(product);

    useEffect(() => {

        setFormData ( product );

    }, [ product ]);


    const onOpen = ( formData ) => {

        setTimeout ( () => {

            setFormData ();

        }, 0 );

        setTimeout ( () => {

            setFormData ( formData );

        }, 0 );

        setTimeout ( () => setOpen ( true ), 0 );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }
        
    };


    return (

        <>
            <ProduceButtonLayout id={id+"append-button"} onClick={() => onOpen(formData)} {...others} />
            <ProduceDialog
                id={id+"append-dialog"}
                mode="create"
                formId={id+"append-form"}
                product={formData}
                title={ t ( "dialog.title.Produce.startProduction" ) }
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default ProduceButton;

