import { useState , useEffect } from 'react';

import * as wjInput from '@grapecity/wijmo.react.input';

import "./WijmoDialog.css";

function WijmoDialog ( props ) {

    const [ instance , setInstance ] = useState (null);

    const { modaless, moveable, children, onInitialized } = props;

    useEffect ( () => {

        if ( props.show ) {
        
            if ( instance ) instance.show ();

        } else {

            if ( instance ) instance.hide ();

        }

    }, [ instance, props.show ] );

    const onPopupInitialized = ( instance ) => {

        let myInstance = {

            show: () => {

                instance.show(!modaless);

                if ( props.onOpen ) {
            
                    props.onOpen ();
        
                }
        
            },
            hide: () => {

                instance.hide ();
        
                if ( props.onClose ) {
            
                    props.onClose ();
        
                }
        
            }
        }

        setInstance ( myInstance );

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

    };

    return (
        <div className="container-fluid">
            <wjInput.Popup
                modal={!modaless}
                position={6}
                isDraggable={moveable}
                isResizable={false}
                initialized={onPopupInitialized}
            >
                { children }
            </wjInput.Popup>
        </div>
    );

}

export default WijmoDialog;
