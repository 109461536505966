import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import "./TopMenuDividerItem.css";

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(2),
    }
}));

function TopMenuDividerItemLayout ( props ) {

    const classes = useStyles();

    return <Divider className={classes.divider} />;
    
}

export default TopMenuDividerItemLayout;
