/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import ImageViewerDialog from "../../dialogTypes/ImageViewerDialog";

function DataGridImageColumn ( props ) {

    const { schema, ...others } = props;

    const [ , setDialog ] = useState ();
    const [ url, setUrl ] = useState ();
    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    const onClick = ( ctx ) => {

        setUrl ( ctx.item[schema.name] );
        
        setDialog ( ( dialog ) => {

            if ( dialog ) {

                dialog.show ();
                
            }

            return dialog;

        } );

    }
    const onDialogInitialized = ( dialog ) => {

        setDialog ( dialog );
        
    }

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            let url = ctx.item[schema.name];

            if ( schema.maxFiles !== 1 ) {

                let urlList = url?JSON.parse ( url ):[];

                url = urlList && urlList[0];

            }

            return (
                <>
                    <span>
                    {
                        url
                        ?
                        <img
                            alt={ctx.item[schema.name]}
                            src={
                                ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' )
                                ?
                                url
                                :
                                "http://localhost:8080" + url
                            }
                            style={{marginLeft: "auto", marginRight: "auto", height: "22px"}}
                            onClick={() => onClick(ctx)}
                        />
                        :
                        <></>
                    }
                    </span>
                </>
            );


        } ) );

    }, [schema] );


    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
            <ImageViewerDialog url={url} onInitialized={onDialogInitialized} />
        </DataGridColumn>

    );

}

export default DataGridImageColumn;

