import { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import MomSysSplitLotButtonLayout from '../layout/MomSysSplitLotButtonLayout';
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysSplitLotButton (props) {

    const {id, onSelect, grid, schema, refresh, standard, onSuccess, customizeErrorMsgCallback, ...others} = props;

    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    useEffect(() => {

        if(schema) {

            let modifiedSchema = cloneDeep(schema);

            let lotNo = {};
            
            lotNo.name = "lotNo";
            
            lotNo.readonly = true;
            lotNo.disabled = true;
            lotNo.formGroup = 0;
            lotNo.formOrder = 1;
            lotNo.formItemType = "text";
            
            modifiedSchema.columns.push (lotNo);

            let qtyCurrent = {};
            
            qtyCurrent.name = "qtyCurrent";
            
            qtyCurrent.readonly = true;
            qtyCurrent.disabled = true;
            qtyCurrent.formGroup = 0;
            qtyCurrent.formOrder = 5;
            qtyCurrent.formItemType = "text";
            
            modifiedSchema.columns.push (qtyCurrent);

            let materialNameColumn = {};
            
            materialNameColumn.name = "materialName";
            
            materialNameColumn.readonly = true;
            materialNameColumn.disabled = true;
            materialNameColumn.formGroup = 0;
            materialNameColumn.formOrder = 4;
            materialNameColumn.formItemType = "text";
            
            modifiedSchema.columns.push (materialNameColumn);

            let qtyColumn = {};
            
            qtyColumn.name = "qty";
            
            qtyColumn.required = true;
            qtyColumn.formGroup = 1;
            qtyColumn.formOrder = 1;
            qtyColumn.formItemType = "float";
            
            modifiedSchema.columns.push (qtyColumn);

            setModifiedSchema(modifiedSchema);

        }

    },[schema]);
    
    const onOpen = () => {
        
        if(grid) {
            
            setFormData ( {...(grid.selectedItems[0])} );

        }

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onSplitLotSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };

    const setInitialDataCallback = ( formData ) => {
            
        if ( !formData ) {

            formData = {};
            
        }

        formData.lotNo = onSelect.lotNo;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.materialName = onSelect.materialName;
        formData.qtyCurrent = onSelect.qtyCurrent;

    }


    const modifyUrlCallback = (url) => {

       let modifiedUrl;

        if(standard) {

            modifiedUrl = `/api/Material/${onSelect.lotNo}/split`;

        } else {

            modifiedUrl = `/api/NsMaterial/${onSelect.lotNo}/split`;
        }
        
        return modifiedUrl;

    }

    const checkItemValidation = ( formData, column ) => {

        if(column.name === "qty") {

            if(formData["qtyCurrent"] <= formData["qty"]) {

                return [ false, t( "error.failToSplitQtyunderZero" ) ];
            }
        }

        return [ true, "" ];

    }



    return (
        <>
        <MomSysSplitLotButtonLayout id = {id+"-splitLot-button"} onClick = {onOpen} {...others}/>
        <AutoFormDialog
                id={id+"splitLot-dialog"}
                mode="create"
                formId={id+"splitLot-form"}
                title={modifiedSchema ? t(`dialog.title.${modifiedSchema.name}.${'splitLot'}`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onSplitLotSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback ={setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidation}
                noKeepOpen
            />
        </>
    )

}

export default MomSysSplitLotButton;