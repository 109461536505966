import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function WorkDayManage ( props ) {

    return <BasicCrudScreenType id="WorkDay" table="WorkDay" view="WorkDay" noEdit noExcel />

}

export default WorkDayManage;

