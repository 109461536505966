import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SimpleScreenTypeWithApi from "../screenTypes/functional/SimpleScreenTypeWithApi";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LogViewer from "./LogViewer";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";



function LogSearch ( props ) {

    const { t } = useTranslation();

    const [ tab, setTab ] = useState ( "DEBUG" );

    const [ , setViewer ] = useState ();
    const [ , setApi ] = useState ();

    const tabs = [
        "DEBUG",
        "INFO",
        "ACCESS",
        "ERROR",
        "SECURITY",
    ];

    const tabLabels = [
        "DEBUG",
        "INFO",
        "ACCESS",
        "ERROR",
        "SECURITY",
    ];

    const tabWidths = [
        "80px",
        "80px",
        "80px",
        "80px",
        "100px",
    ];

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [tab] );

    const  onModifyViewUrl = ( url ) => {

        return url + "/" + tab.toLowerCase ();
    
    }

    const onModifyViewSchema = ( schema ) => {


        schema.name = "LogSearchFilter";
        schema.type = "VIEW";
        schema.searchAll = true;
        schema.filterFormGroups = [
            "value",
            "range",
        ];

        schema.columns = [
            {
                name: "logTime",
                type: "datetime(6)",
                formItemType: "datetime",
                filter: {
                    type: "range",
                    formOrder: 2,
                    formGroup : 1,
                }
            },
            {
                name: "srcFile",
                type: "varchar(255)",
                formItemType: "text",
                filter: {
                    type: "valueEqual",
                    formOrder: 2,
                    formGroup : 0,
                }
            },
            {
                name: "srcLine",
                type: "varchar(255)",
                formItemType: "text",
                filter: {
                    type: "valueEqual",
                    formOrder: 3,
                    formGroup : 0,
                }
            },
            {
                name: "logMsg",
                type: "varchar(255)",
                formItemType: "text",
                filter: {
                    type: "valueEqual",
                    formOrder: 4,
                    formGroup : 0,
                }
            },
        ];

        schema.useFilterFormOrder = true;

    };

    const onCopy = () => {

        setViewer ( ( viewer ) => {

            if ( viewer ) {
                    
                viewer.copy();

            }

            return viewer;

        } );
        
    }

    const onTabClicked = ( tab ) => {
        
        setTab ( tab );

    };

    const onInitialized = ( viewer ) => {

        setViewer ( viewer );

    }

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    return (
        <SimpleScreenTypeWithApi
            id="LogSearch"
            useTable
            table="LogSearch"
            view="LogSearch"
            tableSchemaUrl=""
            viewSchemaUrl=""
            onModifyViewSchema={onModifyViewSchema}
            onModifyViewUrl={onModifyViewUrl}
            onApiInitialized={onApiInitialized}

            onAppendButtonAtFirst={ () =>
                <Tabs
                    value={tab}
                    textColor={"inherit"}
                    variant={"scrollable"}
                    scrollButtons={"off"}
                    style={{
                        left: 0,
                        margin: "0px",
                        padding:"0px",
                        height: "48px",
                        width: "500px",
                        marginTop: "8px",
                        marginLeft: "15px",
                    }}
                >
                    {
                        tabs.map ( ( tabItem, index ) =>
                        
                            <Tab
                                className={` classes.selectedTab : classes.notSelectedTab}`}
                                textColor={"primary"}
                                data-cy={`tab-${tabItem}`}
                                onClick={()=>onTabClicked(tabItem)}
                                style={
                                    tabItem === tab
                                    ?
                                    {
                                        borderTop: "0px solid rgb(128, 128, 128)",
                                        borderLeft: "0px solid rgb(128, 128, 128)",
                                        borderRight: "0px solid rgb(128, 128, 128)",
                                        borderRadius: "10px 10px 0px 0px",
                                        margin: "0px",
                                        marginTop: "2px",
                                        width: tabWidths[index],
                                        minHeight: "42px",
                                        opacity: 1,
                                        backgroundColor: "#7280ce"
                                    }                                                    
                                    :
                                    {
                                        margin: "0px",
                                        marginTop: "2px",
                                        padding: "0px",
                                        width: tabWidths[index],
                                        minHeight: "42px",
                                    }                                                    
                                }
                                label={
                                    <span
                                        style={
                                            tabItem === tab
                                            ?
                                            {
                                                color: "white"
                                            }
                                            :
                                            {
                                                color: "black"
                                            }
                                        }
                                    >
                                        {
                                            tabLabels
                                            ?
                                            t(tabLabels[index])
                                            :tabItem
                                        }
                                    </span>
                                }
                            />
                        )
                    }
                </Tabs>

            }
            onAppendButtonInFrontOfSearch={ () =>     
                <>
        
                    <BasicCrudScreenActionItemLayout>
        
                        <ActionButtonLayout id="copyLog" onClick={onCopy}>{t("buttonName.copyLog")}</ActionButtonLayout>
        
                    </BasicCrudScreenActionItemLayout>
        
                </>
            }            
        >
            <LogViewer onInitialized={onInitialized} />
        </SimpleScreenTypeWithApi>
    );

}

export default LogSearch;
