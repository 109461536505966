import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import ShipmentButton from "../input/ShipmentButton";
import getSchema from "../../lib/api/getSchema";

import { useTranslation } from "react-i18next";

import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";

import AccessControl from '../functional/AccessControl' ;

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function ShipmentDetail(props) {

  const { id,  shipmentId, onApiInitialized, shipmentRefresh } = props;

  const [tableSchema, setTableSchema] = useState();

  const [shipmentDetailId, setShipmentDetailId] = useState();

  const [itemId, setItemId] = useState();

  const [shipmentDetailState, setShipmentDetailState] = useState();

  const [grid, setGrid] = useState();

  const [ , setNotification ] = useRecoilState(notificationState);

  const { t } = useTranslation();

  useEffect(() => {

    (async () => {

      if (grid) {
        grid.selectionChanged.addHandler((grid, event) => {
          setItemId(grid.selectedItems[0] && grid.selectedItems[0].itemId);
          setShipmentDetailId(grid.selectedItems[0] && grid.selectedItems[0].shipmentDetailId);
          setShipmentDetailState(grid.selectedItems[0] && grid.selectedItems[0].shipmentDetailState);
        });
        
        grid.itemsSourceChanged.addHandler((grid, event) => {
          if (grid.selectedItems[0]) {
            setItemId(grid.selectedItems[0] && grid.selectedItems[0].itemId);
            setShipmentDetailId(grid.selectedItems[0] && grid.selectedItems[0].shipmentDetailId);
            setShipmentDetailState(grid.selectedItems[0] && grid.selectedItems[0].shipmentDetailState);
          }
        })
      }
    })();

  }, [grid]);

  useEffect(() =>{

    (async () =>{
      
      let schema = await getSchema("ShipmentDetail");


      let productOutputCheck = {
          name : "productOutputCheck",
          formItemType : "checkbox",
      }

      schema.columns.push(productOutputCheck);

      
        for(const column of schema.columns) {
            switch(column.name){
  
              case "itemId" : 
                column.defaultValue = itemId;
                column.disabled = true;

                break;

              default :
              
                break;
            }  
        }
          
      setTableSchema(schema);

      })();

  },[itemId]);

  const notifySuccess = (msg) => {

    setNotification({

      severity: "success",
      msg: msg,
      open: true,

    });
  };

  const onSuccess = (msg) => {
    
    notifySuccess(msg);

  };


  const onInitialized = (grid) => {
    setGrid(grid);

  };

  const doHandleBackendErrorMsg = (reason) => {
    // reason.response := { data, status, statusText, headers, config, request }
    // data : user error message
    // status : http status code
    // statusText : http status code text
    const response = reason.response;
    return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;

  };

  const onModifyUrl = (url, shipmentId) => {
    
    return url + "/" + shipmentId;

  };

  const onAppendHeaderAtFirst = () => {
    return (
      <BasicCrudScreenActionItemLayout>
        <AccessControl accessTarget ={tableSchema} accessMethod = "POST">
          <ShipmentButton
            schema = {tableSchema}
            grid = {grid}
            refresh = {shipmentRefresh}
            onSuccess ={onSuccess}
            shipmentDetailId = {shipmentDetailId}
            disabled ={shipmentDetailState === "출하완료" ? true : false }
            customizeErrorMsgCallback = {doHandleBackendErrorMsg}
          />
        </AccessControl>
     </BasicCrudScreenActionItemLayout>
    )
  }


  return (
    <BasicCrudScreenType
      id={id}
      table="ShipmentDetail"
      view="VwShipmentDetail"
      onApiInitialized={onApiInitialized}
      onModifyViewUrl={(url) => onModifyUrl(url, shipmentId)}
      onInitialized={onInitialized}
      onAppendHeaderAtFirst = {onAppendHeaderAtFirst}
      exportFormatItem={() => {}}
      noCreate
      noEdit
      noDelete
    />
  );
}
export default ShipmentDetail;
