/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import SaveToExcelButton from "../input/SaveToExcelButton";
import MomSysBackShipDetailButton from "../input/MomSysBackShipDetailButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysBackShipDetail(props) {
    const { id, onSelectMst, onDetailEmpty } = props;

    const { t } = useTranslation();

    const table = "MomSysBackShipDetail";
    const view = "MomSysVwBackShipDetail";

    const [momSysBackShipDetailSchema, setMomSysBackShipDetailSchema] = useState();

    const [momSysBackShipDetailGrid, setMomSysBackShipDetailGrid] = useState();
    const [momSysBackShipDetailApi, setMomSysBackShipDetailApi] = useState();
    const [momSysBackShipDetailDataIsEmpty, setMomSysBackShipDetailDataIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysBackShipDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (momSysBackShipDetailApi) {
            momSysBackShipDetailApi.refresh();
        }
    }, [onSelectMst.backShipId]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (momSysBackShipDetailApi) {
            momSysBackShipDetailApi.refresh();
        }
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    };

    return (
        <BasicCrudScreenType
            id={id}
            headerTitle={t("term.backShipDetail")}
            view={view}
            onModifyViewUrl={(url) => `${url}/${onSelectMst.backShipId}`}
            onInitialized={(grid) => {
                setMomSysBackShipDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMomSysBackShipDetailApi(api);
            }}
            onLoadData={(data) => {
                const isEmpty = data.length < 1 ? true : false;

                setMomSysBackShipDetailDataIsEmpty(isEmpty);

                if (onDetailEmpty) {
                    onDetailEmpty(isEmpty);
                }
            }}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={momSysBackShipDetailSchema && momSysBackShipDetailSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysBackShipDetailButton
                            id={id}
                            schema={momSysBackShipDetailSchema}
                            onSelectMst={onSelectMst}
                            disabled={onSelectMst?.backShipStatus || onSelectMst?.length <= 0 ? true : false }
                            onSuccess={onSuccess}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysBackShipDetailSchema && momSysBackShipDetailSchema.name}
                        accessMethod={
                            momSysBackShipDetailSchema && (momSysBackShipDetailSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id={id}
                            schema={momSysBackShipDetailSchema}
                            grid={momSysBackShipDetailGrid}
                            disabled={onSelectMst?.backShipStatus || momSysBackShipDetailDataIsEmpty}
                            onSuccess={onSuccess}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysBackShipDetailSchema && momSysBackShipDetailSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id={id}
                            schema={momSysBackShipDetailSchema}
                            grid={momSysBackShipDetailGrid}
                            color="secondary"
                            disabled={onSelectMst?.backShipStatus || momSysBackShipDetailDataIsEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysBackShipDetailSchema && momSysBackShipDetailSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id={id}
                            grid={momSysBackShipDetailGrid}
                            view={view}
                            color="primary"
                            exportFormatItem={() => {}}
                            disabled={momSysBackShipDetailDataIsEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
        />
    );
}

export default MomSysBackShipDetail;
