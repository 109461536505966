import { useEffect, useState } from "react";
import "./gridEvent.css"
import { useTranslation } from "react-i18next";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function JobQueue(props) {
    const id = "JobQueueView";

    const [, setSchema] = useState();
    const [, setApi] = useState();
    const [grid, setGrid] = useState();
    const [, setIsEmpty] = useState();
    const {t} = useTranslation ();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("JobQueue");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.loadedRows.addHandler((flex, e) => {                    
                    for(const row of flex.rows) {
                        let rowData = row._data;
                        row._data.moduleName = t('menu.' + rowData.moduleName);
                    }
                });
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onLoadData = (data) => {
        setIsEmpty(data.length < 1 ? true : false);
    };

    return <BasicCrudScreenType 
        id={id}
        table="JobQueue" 
        view="JobQueueView"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        noCreate
        noEdit
        noDelete
        noExcel
        onLoadData={onLoadData}
    />;
}

export default JobQueue;
