/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import patch from "../../lib/api/patch";
import notificationState from "../../states/notificationState";

import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudActionsLayout from "../screenTypes/layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenContentLayout from "../screenTypes/layout/BasicCrudScreenContentLayout";
import BasicCrudScreenFooterLayout from "../screenTypes/layout/BasicCrudScreenFooterLayout";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import AutoForm from "../form/functional/AutoForm";

function MomSysBopWarehouse ( props ) {

    const { t } = useTranslation();

    const { product, onInitialized } = props;

    const [wareHouseChanged, setWareHouseChanged] = useState(false);
    const [, setNewFormData] = useState();
    const [bopMaterialSchema, setBopMaterialSchema] = useState();
    const [bopOutMaterial, setBopOutMaterial] = useState();
    const [warehouseFormRefresh, setWarehouseFormRefresh] = useState(true);

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        let mounted =true;

        const getSchemaInfo = async () => {

            let bopMaterialSchema = await getSchema( "MomSysUdtBopMaterial" );

            if ( mounted ) {
                setBopMaterialSchema(bopMaterialSchema);
            }
    
        };
    
        const refreshWarehouseForm = async ( product ) => {

            if ( product ) {
    
                try {
    
                    let  bopOutMaterial = await getSpecific("MomSysUdtBopMaterialByInOutType", product.materialCode, product.materialCodeVer, "OUT" );
        
                    if ( mounted ) {
                        setTimeout ( () => setBopOutMaterial ( undefined ), 0 );
                        setTimeout ( () => setBopOutMaterial ( bopOutMaterial ), 0 );
                        }
        
                } catch ( reason ) {

                    if ( mounted ) {
                        setBopOutMaterial ( undefined );
                    }
                    
                }
        
            }
    
        }

        let instance = {
            refresh: refreshWarehouseForm,
        };

        getSchemaInfo ();
        refreshWarehouseForm ( product );

        setTimeout(()=>{
            if ( mounted ) {
                setWarehouseFormRefresh(false)
            }
        }, 0);
        setTimeout(()=>{
            if ( mounted ) {
                setWarehouseFormRefresh(true)
            }
        },0);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

        return ( () => {
            mounted = false;
        } );

    }, [product]);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onWareHouseChanged = ( formData ) => {

        setWareHouseChanged(true);
        setNewFormData(formData);

    }

    const onCancelWareHouseChange = () => {

        setTimeout(()=>{setWarehouseFormRefresh(false)}, 0);
        setTimeout(()=>{setWarehouseFormRefresh(true)},0);

        setWareHouseChanged(false);

    }

    const updateWarehouse = () => {

        setNewFormData ( ( newFormData ) => {

            setBopOutMaterial( ( bopOutMaterial ) => {

                (async () => {

                    let newBopOutMaterial = { ...bopOutMaterial, ...newFormData };

                    try {

                        await patch(`MomSysUdtBopMaterial/${bopOutMaterial.bopMaterialCode}/${bopOutMaterial.bopMaterialCodeVer}/${bopOutMaterial.processCode}/${bopOutMaterial.inOutType}/${bopOutMaterial.elementIndex}`, {wareHouseCode: newFormData.wareHouseCode});
                        setBopOutMaterial(newBopOutMaterial);
                        notifySuccess(t("success.updateSuccess",{updateCount: 1}));

                    } catch ( reason ) {

                        setTimeout(()=>{setWarehouseFormRefresh(false)}, 0);
                        setTimeout(()=>{setWarehouseFormRefresh(true)},0);

                        notifyFailure ( t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );
                
                    }

                })();
    
                return bopOutMaterial;

            } );

            return newFormData;

        })

        setWareHouseChanged(false);

    }

    return (

        <div>
            <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

                    <span
                        color="inherit"
                        align="center"
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            marginTop: "8px"
                        }}>
                        { t("term.outWareHouse") }
                    </span>

                </BasicCrudActionsLayout>
                
            </BasicCrudScreenHeaderLayout>

            {

                bopOutMaterial
                ?
                <>
                    <BasicCrudScreenContentLayout>

                        {

                            warehouseFormRefresh
                            ?
                            <AutoForm
                                id="bop-out-material-warehouse-form"
                                schema={bopMaterialSchema}
                                mode="edit"
                                noAutoFocus
                                initialData={bopOutMaterial}
                                onChange={onWareHouseChanged}
                            />
                            :
                            <></>

                        }

                    </BasicCrudScreenContentLayout>

                    <BasicCrudScreenFooterLayout>

                        <ToolbarFlexSpacer xs />

                        <BasicCrudScreenActionItemLayout>

                            <ActionButtonLayout id="edit-bop-out-warehouse" color="primary" disabled={!wareHouseChanged} onClick={updateWarehouse}>{t("buttonName.update")}</ActionButtonLayout>
                            <ActionButtonLayout id="cancel-bop-out-warehouse" color="secondary" disabled={!wareHouseChanged} onClick={onCancelWareHouseChange}>{t("buttonName.cancel")}</ActionButtonLayout>

                        </BasicCrudScreenActionItemLayout>


                    </BasicCrudScreenFooterLayout>

                </>
                :
                <span><h6 style={{marginTop: "20px", marginLeft: "8px"}}>{t("infoMsg.noData")}</h6></span>
                
            }
            
        </div>


    );

}

export default MomSysBopWarehouse;
