import "./MainPaneLayout.css";

function MainPaneLayout ( props ) {

    const { children } = props;

    return (

        <div className="mainPanelLayout">
        { children }
        </div>

    )
}

export default MainPaneLayout;
