import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";


import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";

import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import getSpecific from "../../lib/api/getSpecific";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguLabDataSheetDetailCreateDialog(props) {

    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,
        selectMst,
        initialData,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,
        maxWidth,
        platform,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,
        customizeErrorMsgCallback,

        ...others
    } = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [no, setNo] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [initData, setInitData] = useState({ no: no + 1 });
    const [instance, setInstance] = useState();

    const [onSelectMst, setOnSelectMst] = useState();

    const [grid, setGrid] = useState();
    const [api, setApi] = useState();

    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const onGridInitialized = (grid) => {
        grid.selectionMode = "Row";

        setGrid(grid);
    };

    const onApiInitialized = (api) => {
        setApi(api);
    };

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst(grid.selectedItems[0]);
                    }
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst(grid.selectedItems[0]);
                    }
                });
            }
        })();
    }, [grid]);

    useEffect(() => {

        if (onSelectMst) {
            (async () => {

                setInitData((formData) => {
                    let newFormData = {
                        ...formData,
                        code: onSelectMst.materialCode,
                        materialCodeVer: onSelectMst.materialCodeVer,
                        item: onSelectMst.materialName,
                    };

                    return newFormData;
                });

            })();
        }

    }, [onSelectMst]);

    const setNoAftergetNo = useCallback(() => {

        (async () => {
            let result = await getSpecific('eguLabDataSheetDetail', selectMst?.eguLabDataSheetId)

            if (result?.data.length === 0) {

                setNo(1)

            } else {

                setNo(result?.data[result?.data.length - 1].no + 1)

            }
        })();

    }, [selectMst?.eguLabDataSheetId])

    useEffect(() => {

        setNoAftergetNo();

        setInitData((init) => {

            let newInit = {
                ...init,
                eguLabDataSheetId: selectMst?.eguLabDataSheetId,
            }

            return newInit;
        })

    }, [selectMst?.eguLabDataSheetId, setNoAftergetNo])


    useEffect(() => {
        if (no) {

            setInitData((init) => {

                let newInit = {
                    ...init,
                    no: no ? no : 1,
                }

                return newInit;
            })
        }

    }, [no])

    const { t } = useTranslation();

    const onCloseDialog = () => {

        if (no) {
            setInitData({ no: no, eguLabDataSheetId: selectMst?.eguLabDataSheetId })
        }
        setDisabled(true);

        if (onClose) {

            onClose();

        }
    };

    const onCancel = () => {

        if (no) {
            setInitData({ no: no, eguLabDataSheetId: selectMst?.eguLabDataSheetId })
        }

        setDisabled(true);

        instance.hide();

    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }

    };

    const onChange = (formData) => {
        setDisabled(false)
        setFormData(formData)

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const refreshed = (msg) => {

        notifySuccess(msg);

        if (refresh) {

            refresh.refresh();
        }

        if (api) {

            api.refresh();

        }
    };


    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        console.warn(msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {

        setNoAftergetNo();

        refreshed()

        if (no) {
            setInitData({ no: no + 1, eguLabDataSheetId: selectMst?.eguLabDataSheetId })
        }

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (!keepOpen) {

            instance.hide();


        }

    };

    const onApiFailure = (reason) => {

        if (customizeErrorMsgCallback) {

            notifyFailure(customizeErrorMsgCallback(reason))


        } else {

            notifyFailure(
                t("error.insertFailure", {
                    reason: reason.response ? reason.response.data : JSON.stringify(reason),
                })
            );
        }
    };

    const checkItemValidation = (schema, formData) => {

        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {

                nofifyWarning(msg);
                return false;

            }
        }

        return true;
    };

    const onOk = (schema, formData) => {

        let total = 0;

        for (let value in formData) {

            if (value.includes('Amount') && value !== 'totalAmount' && value !== 'expectedAmount' && value !== 'standardAmount') {

                total += parseFloat(formData[value]);
            }
        }

        formData.totalAmount = parseFloat(total)

        if (!checkItemValidation(schema, formData)) {

            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {

                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;

            }
        }

        let url = `/api/EguLabDataSheetDetail`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        if (schema && !schema.columnMap) { //autoFormDialog columnMap 생성부분 

            let columnMap = {};

            for (const column of schema.columns) {

                columnMap[column.name] = column;

            }

            schema.columnMap = columnMap;

        }

        for (const formItem in apiData) {

            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {

                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {

                    if (file.type === "url") continue;

                    form.append(formItem, file.file);

                }
            } else {

                if (apiData[formItem] !== undefined) {

                    json[formItem] = apiData[formItem];

                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                title={title}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                {uiConf.modalWithCloseButton ? (
                    <DialogHeaderWithClose>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeaderWithClose>
                ) : (
                    <DialogHeader>
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                    </DialogHeader>
                )}

                <DialogContent style={{ height: height }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement>
                            <BasicCrudScreenType
                                id={formId}
                                embededInOtherScreenType
                                headerTitle={t(
                                    "tableName.EguVwPreLabDataSheetDetail"
                                )}
                                table="EguVwPreLabDataSheetDetail"
                                view="EguVwPreLabDataSheetDetail"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                noOutputSize
                                noFilter
                                onApiInitialized={onApiInitialized}
                                onInitialized={onGridInitialized}
                            />
                        </ReflexElement>

                        <ReflexSplitter />

                        <ReflexElement>
                            <div
                                style={{
                                    padding: "32px",
                                }}
                            >
                                <AutoForm
                                    id={formId}
                                    schema={schema}
                                    mode='edit'
                                    initialData={initData}
                                    onChange={onChange}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[mode === "edit" ? t("common.edit") : t("common.create")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                />
            </Dialog>
        </>
    );
}

export default EguLabDataSheetDetailCreateDialog;
