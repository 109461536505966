import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";
import notificationState from "../../states/notificationState";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import VwMsgInBox from "./VwMsgInBox";
import Message from "./Message";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import apiClient from "../../lib/common/apiClient";

function InBox ( props ) {

    const { t } = useTranslation();

    const [ msg, setMsg ] = useState ( );
    const [ height, setHeight ] = useState(0);
    const [ disabled, setDisabled ] = useState(false);

    const [ , setApi ] = useState ( );
    const [ , setGrid ] = useState ( );
    const [ , setTimer ] = useState ( );
    const [ , setSelection ] = useState( "" );
    const [ , setConfirmDialog ] = useState( );

    const [ confirmTitle, setConfirmTitle ] = useState( "" );
    const [ confirmMsg, setConfirmMsg ] = useState( "" );
    const [ action, setAction ] = useState({
        operation: undefined, // "markReadAll", "markUnread", "delete"
        data: undefined
    } );

    const setNotification = useSetRecoilState(notificationState);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectMsg = ( selection, grid ) => {

        // onSelect는 grid의 기본 소팅 순서 지정에 따라 여러번 호출 되게 됨
        // 타이머를 사용하지 않을 경우 화면에 나타나지 않았던 복수의 메시지에 대해 읽음 표시를 할 가능성이 높은
        // 따라서 호출 즉시 읽음 표시를 호출하는 대신 일정 시간 후에 호출하도록 하고 기존에 호출되지 않은 타이머는 clear하여
        // 초기 여러번의 자동 호출에 대해 가장 마지막에 선택된 메시지에 대해서만 읽음 표시를 하도록 함

        if ( selection.length > 0 ) {

            setDisabled ( false );

        } else {

            setDisabled ( true );

        }

        if ( selection && selection[0] ) {

            setMsg ( selection[0] );

            setTimer ( ( timer ) => {

                if ( timer ) {
                    
                    clearTimeout ( timer );

                }

                timer = setTimeout ( () => {

                    markRead ( selection[0] );
    
                }, 500 );

                return timer;
    
            } );

        } else {

            setMsg ( undefined );

        }

        setSelection ( selection );

    };

    const onGridInitialized = ( grid ) => {

        setGrid ( grid );

    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onConfirmDialogInitialized = ( dialog ) => {

        setConfirmDialog ( dialog );

    };

    const markRead = ( msg ) => {

        if ( parseInt ( msg.msgRead ) === 0 ) {

            apiClient.put ( `api/InBoxMsg/${msg.messageId}/markRead`, {} )
                .then ( () => {

                    setGrid ( ( grid ) => {

                        if ( grid ) {
                            
                            msg.msgRead = 1;
                            grid.refresh ();
        
                        }

                        return grid;
    
                    } );

                } )
                .catch ( () => {

                    notifyFailure ( t ( "error.markReadFailed" ) );;

                } );

        }

    };

    const markReadAll = () => {

            apiClient.put ( `api/InBoxMsg/markReadAll`, {} )
                .then ( () => {

                    setApi ( ( api ) => {

                        if ( api ) {
                            
                            api.refresh ();
        
                        }

                        return api;
    
                    } );

                    notifySuccess ( t ( "success.markReadAllSuccess" ) );

                } )
                .catch ( () => {

                    notifyFailure ( t ( "error.markReadAllFailed" ) );

                } );

    };

    const markUnread = ( selection ) => {

        apiClient.put ( `api/InBoxMsg/markUnread`, selection )
            .then ( ( result ) => {

                    setGrid ( ( grid ) => {

                        if ( grid ) {
                            
                            for ( const msg of selection ) {

                                msg.msgRead = 0;

                            }

                            grid.refresh ( false );
        
                        }

                        return grid;

                    } );

                    notifySuccess ( t ( "success.markUnreadSuccess" ) );

                } )
                .catch ( ( reason ) => {

                    notifyFailure  ( t ( "error.markUnreadFailed" ) );

                } );

    };

    const moveToTrash = ( selection ) => {

        apiClient.put ( `api/InBoxMsg/moveToTrash`, selection )
            .then ( ( result ) => {

                    setApi ( ( api ) => {

                        if ( api ) {
                            
                            api.refresh ( false );
        
                        }

                        return api;

                    } );

                    notifySuccess ( t ( "success.moveToTrashSuccess" ) );

                } )
                .catch ( ( reason ) => {

                    notifyFailure  ( t ( "error.moveToTrashFailed" ) );

                } );

    };

    const onReadAll = () => {

        setAction ( {
            operation: "markReadAll"
        } );

        setConfirmTitle ( t("dialog.title.Message.confirm.markReadAll") );
        setConfirmMsg ( t ("dialog.msg.Message.confirm.markReadAll") );
        setConfirmDialog ( ( dialog ) => {
            
            dialog.show ();
            return dialog;

        } );

    }

    const onUnread = () => {

        setSelection ( ( selection ) => {
            
            setAction ( {
                operation: "markUnread",
                data: selection
            } );
    
            setConfirmTitle ( t("dialog.title.Message.confirm.markUnread") );
            setConfirmMsg ( t ("dialog.msg.Message.confirm.markUnread") );
            setConfirmDialog ( ( dialog ) => {
                
                dialog.show ();
                return dialog;
    
            } );

            return selection;
    
        } );

    }

    const onDelete = ( selection ) => {

        setSelection ( ( selection ) => {

            setAction ( {
                operation: "delete",
                data: selection
            } );

            setConfirmTitle ( t("dialog.title.Message.confirm.moveToTrash") );
            setConfirmMsg ( t ("dialog.msg.Message.confirm.moveToTrash") );
            setConfirmDialog ( ( dialog ) => {
                
                dialog.show ();
                return dialog;

            } );

            return selection;

        } );

    };

    const onConfirmed = ( action ) => {

        if ( action ) {

            switch ( action.operation ) {

                case "markReadAll":

                    markReadAll ();
                    break;

                case "markUnread":

                    markUnread ( action.data );
                    
                    break;

                case "delete":

                    moveToTrash ( action.data );
                    break;

                default:

                    notifyFailure ( t ( "error.unknownOperation" ) );
                    break;

            }

        }

    };

    return (

        <div style={{ height: height } }>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={ 0.5 } >

                    <VwMsgInBox

                        headerTitle={ t ( "term.msgList" ) }

                        noCreate
                        noEdit
                        noDelete
                        noExcel

                        onAppendButtonInFrontOfSearch = {

                            ( ) => (

                                <>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="primary" disabled={disabled} onClick={ onReadAll }>{ t ( "buttonName.markReadAll" ) } </Button>

                                    </BasicCrudScreenActionItemLayout>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="primary" disabled={disabled} onClick={ onUnread }>{ t ( "buttonName.markUnread" ) }</Button>

                                    </BasicCrudScreenActionItemLayout>

                                    <BasicCrudScreenActionItemLayout>

                                        <Button color="secondary" disabled={disabled} onClick={ onDelete }>{ t ( "buttonName.delete" ) }</Button>

                                    </BasicCrudScreenActionItemLayout>

                                </>

                            )

                        }

                        onSelect={ onSelectMsg }
                        onInitialized={ onGridInitialized }
                        onApiInitialized={ onApiInitialized }

                    />

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <Message msg={ msg } />

                </ReflexElement>

            </ReflexContainer>

            <ConfirmDialog

                id={"inbox-confirm-dialog"}
                title={ confirmTitle }
                msg={ confirmMsg }
                data={ action }
                onOk={ ( action ) => onConfirmed ( action ) }
                onInitialized={ onConfirmDialogInitialized }

            />

        </div>

    );
}

export default InBox;

