import { useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function Tenant ( props ) {

    const { onSelect, ...others } = props;
    
    const [, setGrid] = useState();
    const [disabled, setDisabled] = useState(false);

    const modifyTableSchema = ( schema ) => {

        schema.formGroups = ["tenantInfo", "accountInfo"];

        for ( const column of schema.columns ) {

            column.formGroup = 0;
            
        }

        let userNameColumn = {

            name: "username",
            required: true,
            ignoreRequiredOnEdit: true,
            hideOnEdit: true,
            formGroup: 1,
            formOrder: 13,    
            formItemType: "text",
            validation: {
                msg: "error.userNameTooLong",
                type: "length",
                operator: "le",
                value: 255
            }

        }

        schema.columns.push ( userNameColumn );

        let passwordColumn = {

            name: "password",
            required: true,
            ignoreRequiredOnEdit: true,
            hideOnEdit: true,
            formGroup: 1,
            formOrder: 14,            
            formItemType: "text",
            password: true,
            validation: {
                msg: "error.passwordLengthError",
                type: "conditions",
                operator: "and",
                value: [
                    {
                        msg: "error.passwordTooShort",
                        type: "length",
                        operator: "ge",
                        value: 4
                    },
                    {
                        msg: "error.passwordTooLong",
                        type: "length",
                        operator: "le",
                        value: 255
                    }
                ]
            }

        }

        schema.columns.push ( passwordColumn );

        let roleIdColumn = {

            name: "roleId",
            
            formGroup: 1,
            formOrder: 15,
            
            required: true,
            ignoreRequiredOnEdit: true,
            hideOnEdit: true,

            formItemType: "autocomplete",
            displayEmpty: false,
            codeInfo: {
                tableName: "Role",
                nameColumn: "roleName",
                valueColumn: "roleId",
                useEmptyValue: false,
                sortByName: true,
            }

        }

        schema.columns.push ( roleIdColumn );

    }

    const onGridInitialized = ( grid ) => {


        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

                if ( grid.selectedItems[0].tenantName === "ariatech" ) {

                    setDisabled ( true );
                    
                } else {

                    setDisabled ( false );

                }

            } else {


                if ( onSelect ) {

                    onSelect ( undefined );

                }

                setDisabled ( true );

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

                if ( grid.selectedItems[0].tenantName === "ariatech" ) {

                    setDisabled ( true );
                    
                } else {

                    setDisabled ( false );

                }

            } else {

                if ( onSelect ) {

                    onSelect ( undefined );

                }

                setDisabled ( true );

            }
        } );

        setGrid ( grid );

    }
    
    return <BasicCrudScreenType
        id="Tenant"
        table="Tenant"
        view="VwTenant"
        disableDelete={disabled}
        onModifyTableSchema={modifyTableSchema}
        onInitialized={onGridInitialized}
        {...others}        
    />

}

export default Tenant;
