import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function ExcelUploadError (props){
    const { id, table, view, onSearchInitialized, onApiInitialized } = props;

    const onGridInitialized = (grid) => {
        grid.allowSorting = false;
    };

    return (
        <BasicCrudScreenType
            id={id}
            table={table}
            view={view}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onInitialized={onGridInitialized}
            noHeader
            fixedOrder={[{column: "createdAt", order: "DESC"}]}
        />
    );
}
export default ExcelUploadError;