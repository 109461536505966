import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import { debounce } from "lodash";

import "../grid/layout/DataGridMomSysOrderPurchase.css";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import StopIcon from '@material-ui/icons/Stop';

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

// function selectTargetRowMstDialog(grid) {
//     const targetRow = grid.rows.find((row) => 
//         !(row.dataItem.mainCustomer === null || row.dataItem.mainCustomer === undefined)
//     );
//     if(targetRow) {
//         grid.selectionMode = "Row";
//         grid.select(targetRow.index, -1);
//     }
//     else grid.selectionMode = "None";
// }

function MomSysOrderPurchaseDetailDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,
        formId,

        onDialogUpdateDetail,
        onSelectMst,
        modifyUrlCallback,

        customizeErrorMsgCallback,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [momSysVwMaterialsToPurchaseGrid, setMomSysVwMaterialsToPurchaseGrid] = useState();
    const [, setMomSysVwMaterialsToPurchaseApi] = useState();
    const [momSysVwMaterialsToPurchaseSelect, setMomSysVwMaterialsToPurchaseSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (column.name === "qty" && momSysVwMaterialsToPurchaseSelect) {
                if (formData["qty"] < momSysVwMaterialsToPurchaseSelect.minPurchaseQty) {
                    valid = false;

                    msg = t("error.qtyLessThanMinPurchaseQty");
                }
            }

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = async (msg) => {
        notifySuccess(msg);

        if (onDialogUpdateDetail) {
            onDialogUpdateDetail();
        }
    };

    useEffect(() => {
        (async () => {
            if (momSysVwMaterialsToPurchaseGrid) {
                momSysVwMaterialsToPurchaseGrid.selectionChanging.addHandler((grid, e) => {
                    const row = e.getRow();
                    if (row && (row.dataItem.mainCustomer === null || row.dataItem.mainCustomer === undefined)) {
                        e.cancel = true;
                    }
                });

                momSysVwMaterialsToPurchaseGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwMaterialsToPurchaseSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysVwMaterialsToPurchaseSelect([]);
                        }
                    }, 100)
                );

                momSysVwMaterialsToPurchaseGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        // selectTargetRowMstDialog(grid);
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwMaterialsToPurchaseSelect(grid.selectedItems[0]);
                        } else {
                            setMomSysVwMaterialsToPurchaseSelect([]);
                        }
                    }, 10)
                );
            }
        })();
    }, [momSysVwMaterialsToPurchaseGrid, t]);

    useEffect(() => {
        if (onSelectMst && momSysVwMaterialsToPurchaseSelect) {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    orderPurchaseId: onSelectMst.orderPurchaseId,

                    orderBuyerDetailId: momSysVwMaterialsToPurchaseSelect.orderBuyerDetailId,

                    materialCode: momSysVwMaterialsToPurchaseSelect.materialCode,
                    materialCodeVer: momSysVwMaterialsToPurchaseSelect.materialCodeVer,
                    materialName: momSysVwMaterialsToPurchaseSelect.materialName,
                    itemSpecName: momSysVwMaterialsToPurchaseSelect.itemSpecName,

                    unitCost: momSysVwMaterialsToPurchaseSelect.sellUnitCost,
                    qty:
                        momSysVwMaterialsToPurchaseSelect.qtyRequired <
                            momSysVwMaterialsToPurchaseSelect.minPurchaseQty ||
                            !momSysVwMaterialsToPurchaseSelect.qtyRequired
                            ? momSysVwMaterialsToPurchaseSelect.minPurchaseQty
                            : momSysVwMaterialsToPurchaseSelect.qtyRequired,

                    qtyRequired: momSysVwMaterialsToPurchaseSelect.qtyRequired,
                    qtyInStock: momSysVwMaterialsToPurchaseSelect.qtyInStock,
                    sellUnitCost: momSysVwMaterialsToPurchaseSelect.sellUnitCost,
                    minPurchaseQty: momSysVwMaterialsToPurchaseSelect.minPurchaseQty,
                };

                for (const column of schema.columns) {
                    if (column?.defaultValue !== undefined) {
                        newFormData[column.name] = column.defaultValue;
                    }
                }

                return newFormData;
            });

            if (
                momSysVwMaterialsToPurchaseSelect.mainCustomer === null ||
                momSysVwMaterialsToPurchaseSelect.mainCustomer === undefined
            ) {
                for (const column of schema.columns) {
                    switch (column.name) {
                        case "qty":
                        case "unitCost":
                        case "description":
                            column.disabled = true;
                            setOkButtonDisabled(true);

                            break;

                        default:
                            break;
                    }
                }
                // nofifyWarning(t("warning.notRegistedMaterialByCompany"));
            } else {
                for (const column of schema.columns) {
                    switch (column.name) {
                        case "qty":
                        case "unitCost":
                        case "description":
                            column.disabled = false;
                            setOkButtonDisabled(false);

                            break;

                        default:
                            break;
                    }
                }
                // onCloseNotification();
            }
        }
    }, [schema, onSelectMst, momSysVwMaterialsToPurchaseSelect, t]);

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        if (customizeErrorMsgCallback) {
            notifyFailure(customizeErrorMsgCallback(reason));
        } else {
            notifyFailure(
                t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
            );
        }
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url = `/api/${schema.name}`;

        if (modifyUrlCallback) {
            url = modifyUrlCallback(url);
        }

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setMomSysVwMaterialsToPurchaseSelect();

        setOkButtonDisabled(true);

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    const onAppendButtonAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>

                <MomSysBomBopTreeButton color="primary" disabled={!momSysVwMaterialsToPurchaseSelect} product={momSysVwMaterialsToPurchaseSelect} />

            </BasicCrudScreenActionItemLayout>
        )
    }

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <div style={{ marginTop: "14px", marginRight: "20px" }}>

                <StopIcon fontSize="large" color="disabled" />

                <span>{t("term.notRegistedMaterialByCompany")}</span>

            </div>
        )
    }


    const collectionViewChange = (collectionView) => {
        let notExist = true;
        if (collectionView) {
            for (let [index, item] of collectionView.items.entries()) {
                if (!(item.mainCustomer === null || item.mainCustomer === undefined)) {
                    notExist = false;
                    collectionView.currentItem = null;
                    collectionView.currentPosition = index ?? -1;
                    break;
                }
            }
            if (notExist) {
                collectionView.currentItem = null;
                collectionView.currentPosition = -1;
            }
        }

    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.5} data-cy="Material">
                        <BasicCrudScreenType
                            id="MomSysVwMaterialsToPurchase"
                            headerTitle={t("tableName.MomSysUglOrderPurchaseDetailDialog")}
                            view="MomSysVwMaterialsToPurchase"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            noFilter
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setMomSysVwMaterialsToPurchaseGrid(grid);

                                grid.formatItem.addHandler((flex, e) => {
                                    for (const row of flex.rows) {
                                        if (
                                            row.dataItem.mainCustomer === null ||
                                            row.dataItem.mainCustomer === undefined
                                        ) {
                                            row.cssClass = `not-regist-materialByCompany`;
                                        } else {
                                            row.cssClass = `regist-materialByCompany`;
                                        }
                                    }
                                });
                            }}
                            onApiInitialized={(api) => {
                                setMomSysVwMaterialsToPurchaseApi(api);
                            }}
                            onModifyViewUrl={(url) => `${url}/${onSelectMst.orderBuyerId}/${onSelectMst.companyCode}`}
                            onAppendButtonAtFirst={onAppendButtonAtFirst}
                            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                            embededInOtherScreenType
                            collectionViewChange={collectionViewChange}
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.5}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="order-purchase-detail-create-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysOrderPurchaseDetailDialog;
