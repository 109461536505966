/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import { Icon } from "@material-ui/core";

// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridBooleanColumn ( props ) {

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect ( () => {

        
        setCellTemplate ( () => ( ( ctx ) => {

            if ( parseInt(ctx.item[schema.name]) === 1 || ctx.item[schema.name] === 'true' ) {
                
                return <Icon style={{fontSize: "14px", marginTop: "4px", marginLeft: "12px"}}>check</Icon>

            } else {

                return <span></span>

            }
            
        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridBooleanColumn;

