import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { getOption } from '../../lib/api/getOptions';
import { makeStyles } from "@material-ui/styles";
import { Palettes } from "@grapecity/wijmo.chart";
import IconButton from "../input/IconButton";
import Icon from "../presentation/Icon";

import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';

import getSpecific from "../../lib/api/getSpecific";

import DashWijmoPieChart from "../chart/DashWijmoPieChart";
import DashWijmoAnnualSalesChart from "../chart/DashWijmoAnnualSalesChart";
import DashWijmoProductivityChart from "../chart/DashWijmoProductivityChart";

import "../layout/Chart.css";

const useStyles = makeStyles((theme) => ({

    root: {
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    mainContainer: {
        position: "relative",
        display: "grid",
        width: "100%",
        height: "100%",
        gridTemplateRows: "48px 5.5fr 4.5fr",
    },
    mainTitleContainer: {
        position: "relative",
        width: "100%",
        borderBottom: "1px solid #e0e0e0",
        gridRowStart: 1,
        gridRowEnd: 2,
    },
    title: {
        position: "absolute",
        fontSize: "16px",
        fontWeight: "bold",
        left: "8px",
        top: "16px",
    },
    date: {
        position: "absolute",
        fontSize: "16px",
        fontWeight: "bold",
        right: "16px",
        top: "16px",
    },
    fullScreen: {
        position: "absolute",
        fontSize: "16px",
        fontWeight: "bold",
        right: "16px",
        top: "13px",
    },
    dateWithFullScreen: {
        position: "absolute",
        fontSize: "16px",
        fontWeight: "bold",
        right: "50px",
        top: "16px",
    },
    topContainer: {
        display: "grid",
        width: "100%",
        height: "100%",
        gridRowStart: 2,
        gridRowEnd: 3,
        gridTemplateColumns: "3fr 3fr 4fr",
        gridTemplateRows: "1fr",
        gap: "10px",
        padding: "10px",
        borderTop: "1px solid #e0e0e0",
    },
    bottomContainer: {
        display: "grid",
        width: "100%",
        height: "100%",
        gridRowStart:3,
        gridRowEnd: 4,
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "10px",
        padding: "10px",
        paddingTop: 0,
    },
    itemContainer: {
        display: "grid",
        width: "100%",
        height: "100%",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "48px 1fr",
        border: "1px solid #e0e0e0",
    },
    itemTitleContainer: {
        position: "relative",
        width: "100%",
        gridRowStart: 1,
        gridRowEnd: 2,
        borderBottom: "1px solid #e0e0e0",
    },
    itemTitle: {
        position: "absolute",
        color: "inherit",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
        left: "8px",
        top: "16px",
    },
    itemSummary1: {
        position: "absolute",
        color: "inherit",
        textAlign: "center",
        fontSize: "16px",
        right: "180px",
        top: "16px",
    },
    itemSummary2: {
        position: "absolute",
        color: "inherit",
        textAlign: "center",
        fontSize: "16px",
        right: "16px",
        top: "16px",
    },
    chart: {
        position: "relative",
        width: "100%",
        gridRowStart: 2,
        gridRowEnd: 3,
        marginTop: "auto",
        marginBottom: "auto",
    },

}));

function EguManagementIndicator ( props ) {
    
    const { t } = useTranslation();
    const { handle, onComplete } = props;

    const classes = useStyles();

    const [, setTimer] = useState();

    const [hide, setHide] = useState();
    const [time, setTime] = useState("");

    // Production Per Hour Indicators

    const [leadNow, setLeadNow] = useState();
    const [woodNow, setWoodNow] = useState();
    const [moldingNow, setMoldingNow] = useState();
    const [packagingNow, setPackagingNow] = useState();

    const [soData, setSoData] = useState();
    const [salesData, setSalesData] = useState();

    const [salesReport, setSalesReport] = useState();
    const [leadData, setLeadData] = useState();
    const [woodData, setWoodData] = useState();
    const [moldingData, setMoldingData] = useState();
    const [packagingData, setPackagingData] = useState();

    const paperProps = {
        elevation: 3,
        square: true
    };

    const handleResize = () => {

        setHide ( ( timer ) => {

            if ( timer ) {

                clearTimeout ( timer );

            }

            let newTimer = setTimeout ( () => {

                setHide ( undefined );

            }, 100 );

            return newTimer;

        } );

    };
    
    useEffect ( () => {

        let mounted = true;

        ( async () => {

            window.addEventListener("resize", handleResize );

            let currentTime = new Date ();

            let time = `${currentTime.getFullYear()}년 ${currentTime.getMonth() + 1}월`;

            setTime ( time );

            let productivityReport = await getSpecific ( "EguProductivityReport" );

            if ( mounted ) {

                setLeadNow ( productivityReport["제심"][5].productionPerHour );
                setWoodNow ( productivityReport["우드"][5].productionPerHour );
                setMoldingNow ( productivityReport["몰딩"][5].productionPerHour );
                setPackagingNow ( productivityReport["포장"][5].productionPerHour );
    
                setLeadData ( productivityReport["제심"] );
                setWoodData ( productivityReport["우드"] );
                setMoldingData ( productivityReport["몰딩"] );
                setPackagingData ( productivityReport["포장"] );
    
            }

            let soData = await getSpecific ( "EguMonthlySalesOrderReport" );

            if ( mounted ) {
                setSoData ( soData );
            }

            let salesData = await getSpecific ( "EguMonthlySalesReport" );

            if ( mounted ) {
                setSalesData ( salesData );
            }

            let salesReport = await getSpecific ( "EguAnnualSalesReport" );

            if ( mounted ) {
                setSalesReport ( salesReport );
            }

            let interval = await getOption ( "dashboard", "managementIndicatorDisplayInterval" );

            interval = interval !== undefined ? parseInt(interval) : 5;

            if ( mounted ) {

                setTimer ( ( timer ) => {

                    if ( ! timer ) {
        
                        let newTimer = setTimeout ( () => {
        
                            if ( mounted && onComplete ) {
        
                                setTimer ( undefined );
                                onComplete ();
        
                            }
        
                        }, interval * 1000 );
        
                        return newTimer;
        
                    };
        
                    return timer;
        
                } );
            }


            return ( () => {
                mounted = false;
            } );
    
        } ) ();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    return (

        <div className={classes.root}>

            <div className={classes.mainContainer}>

                <div className={classes.mainTitleContainer}>

                    <div className={classes.title}>
                        { t ("term.managementIndicator") }
                    </div>

                    <div className={handle?classes.dateWithFullScreen:classes.date}>
                        { time }
                    </div>

                    {
                        handle
                        ?
                        (
                            handle?.active
                            ?
                            <IconButton size="small" className={classes.fullScreen} onClick={handle?.exit}>
                                <Icon>fullscreen_exit</Icon>
                            </IconButton>
                            :
                            <IconButton size="small" className={classes.fullScreen} onClick={handle?.enter}>
                                <Icon>fullscreen</Icon>
                            </IconButton>
                        )
                        :
                        <></>

                    }

                </div>

                <div className={classes.topContainer}>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                수주
                            </div>
                            {/*
                            <div className={classes.itemSummary2}>
                                { soData?soData.total:"" }
                            </div>
                            */}
                        </div>
                        <div className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoPieChart name="company" value="value" data={soData?.data} palette={Palettes.light} innerText={soData?soData.total:""}/>
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                매출
                            </div>
                            {/*
                            <div className={classes.itemSummary2}>
                                {salesData?salesData.total:""}
                            </div>
                            */}
                            </div>
                        <div className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoPieChart name="productFamily" value="value" data={salesData?.data} palette={Palettes.zen} innerText={salesData?salesData.total:""} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                누적
                            </div>
                            <div className={classes.itemSummary1}>
                                수주 : {salesReport?salesReport.totalSalesOrderAmount:""}
                            </div>
                            <div className={classes.itemSummary2}>
                                매출 : {salesReport?salesReport.totalSalesAmount:""}
                            </div>
                        </div>
                        <div className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoAnnualSalesChart salesReport={salesReport} palette={Palettes.coral} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                </div>

                <div className={classes.bottomContainer}>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                제심
                            </div>
                            <div className={classes.itemSummary2}>
                                <NumberFormat
                                    value={leadNow}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={ t("unit.productivity") }
                                />   
                            </div>
                        </div>
                        <div id="leadBarChart" className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoProductivityChart element="leadBarChart" productivityReport={leadData} palette={Palettes.modern} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                우드
                            </div>
                            <div className={classes.itemSummary2}>
                                <NumberFormat
                                    value={woodNow}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={ t("unit.productivity") }
                                />   
                            </div>
                        </div>
                        <div id="woodBarChart" className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoProductivityChart element="woodBarChart" productivityReport={woodData} palette={Palettes.organic} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                몰딩
                            </div>
                            <div className={classes.itemSummary2}>
                                <NumberFormat
                                    value={moldingNow}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={ t("unit.productivity") }
                                />   
                            </div>
                        </div>
                        <div id="moldingBarChart" className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoProductivityChart element="moldingBarChart" productivityReport={moldingData} palette={Palettes.slate} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                    <Paper className={`${classes.itemContainer}`} {...paperProps}>
                        <div className={classes.itemTitleContainer}>
                            <div className={classes.itemTitle}>
                                포장
                            </div>
                            <div className={classes.itemSummary2}>
                                <NumberFormat
                                    value={packagingNow}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={ t("unit.productivity") }
                                />   
                            </div>
                        </div>
                        <div id="packagingBarChart" className={classes.chart}>
                            {
                                !hide
                                ?
                                <DashWijmoProductivityChart element="packagingBarChart" productivityReport={packagingData} palette={Palettes.cocoa} />
                                :
                                <></>
                            }
                        </div>
                    </Paper>

                </div>

            </div>
        </div>
    )

}

export default EguManagementIndicator;
