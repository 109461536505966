/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function CreateMilestoneDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState ({});

    const [, setTask] = useState ();

    const [actionButtonsDisabled, setActionButtonsDisabled] = useState ([true, false]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        task,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {
        
        if ( task ) {

            let formData = {

                parentPlanId: task.id,
                milestoneDate: new Date (),
                milestoneName: undefined,

            }

            setFormData ( formData );
            checkButtonEnabled ( formData );

        }


        setTask ( task );

    }, [task] );

    const schema = {

        name: "ChangeDuration",
        type: "BASE TABLE",
        searchAll: false,

        columns: [

            {
                name: "parentPlanId",
                formOrder: 3,
                formItemType: "text",
                hideInForm: true,
            },
            {
                name: "milestoneDate",
                formOrder: 3,
                formItemType: "date",
            },
            {
                name: "milestoneName",
                formOrder: 3,
                formItemType: "text",
            },

        ]

    };

    const checkButtonEnabled = ( formData ) => {

        if ( formData && formData.milestoneDate && formData.milestoneName ) {
    
            setActionButtonsDisabled ([ false, false ]);

        } else {

            setActionButtonsDisabled ([ true, false ]);

        }

        return;

    }
    
    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result ) => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

        if ( onSuccess ) {

            onSuccess ( t ( "success.createMilestoneSuccess") );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        console.error ( apiData );
        notifyFailure ( t ( "error.createMilestoneFailed" ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setFormData ( ( formData ) => {
    
            let apiData = formData;
                
            let form = new FormData ();
            let json = apiData;
            let deleted = {};

            form.append ( "deleted", JSON.stringify ( deleted ) );
            form.append ( "json", JSON.stringify ( json ) );

            apiClient
                .post(`/api/EguPlan/milestone`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(apiData, reason));
        
            return formData;

        } );


    }

    const onCancel = () => {

        instance.hide ();

    };


    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const onChangeForm = ( formData ) => {

        checkButtonEnabled ( formData );
        setFormData ( formData );

    }

    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="sm"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ProductionPlan.addMilestone", { taskName: task?.taskName, duration: task?.durationInHours } ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent>

                <AutoForm
                    id="split-task-form"
                    initialData={formData}
                    schema={schema}
                    mode="edit"
                    onChange={ onChangeForm }
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                buttonDisabled={ actionButtonsDisabled }
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default CreateMilestoneDialog;
