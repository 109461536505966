/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import PopLotPackingSplitDialog from "../dialogTypes/PopLotPackingSplitDialog";

function PopLotPackingSplitButton(props) {
    const { id, schema, api, selectGrid, onUpdateMaster, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (selectGrid) {
            setFormData({...(selectGrid), splitDate: new Date()});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    
    const onAllShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }

        if (api) {
            api.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-all-lotPackingSplit-button"}
                onClick={onOpen}
                createButtonName={"lotPackingSplit"}
                {...others}
            />
            <PopLotPackingSplitDialog
                id={id+"append-dialog"}
                schema={schema}
                open={open}
                formData={formData}
                onClose={onClose}
                onSuccess={onAllShipSuccess}
            />
        </>
    );
}

export default PopLotPackingSplitButton;

