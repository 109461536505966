import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopLotPackingSplitButton from "../input/PopLotPackingSplitButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";

function PopLotPackingSplit(props) {
    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("PopVwLotPackingSplit");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid([]);
                    };
                });
            }
        })();
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <>
            <BasicCrudScreenType
                id="PopLotPackingSplit"
                table="PopVwLotPackingSplit"
                view="PopVwLotPackingSplit"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <PopCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <PopLotPackingSplitButton
                                    id="PopLotPackingSplitButton"
                                    schema={schema}
                                    api={api}
                                    selectGrid={selectGrid}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    createButtonName={"lotPackingSplit"}
                                />
                            </AccessControl>
                        </PopCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default PopLotPackingSplit;
