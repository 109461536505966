import { useState } from "react";
import ApplyStockTakingDialog from "../dialogTypes/ApplyStockTakingDialog";
import MomSysApplyStockTakingButtonLayout from "../layout/MomSysApplyStockTakingButtonLayout";

function MomSysApplyStockTakingButton(props) {

  const { id, schema, grid, mstRefresh, detailRefresh, onSuccess, shipmentDetailId, ...others } = props;
  
  const [formData, setFormData] = useState();
  const [open, setOpen] = useState(false);

  const onOpen = () => {

    if (grid) {

      setFormData({ ...(grid.selectedItems[0]) });

    }

    setOpen ( true );

};

  const onClose = () => {

    setOpen(false);

  };

  const onUpdateSuccess = (msg) => {

    if (onSuccess) {

      onSuccess(msg);

    }
    if(mstRefresh && detailRefresh) {

      mstRefresh.refresh();
      detailRefresh.refresh();
    }

  };

  return (
    <>
      <MomSysApplyStockTakingButtonLayout id={id + "apply-button"} onClick={onOpen} {...others}/>
      <ApplyStockTakingDialog
          id={id + "apply-dialog"}
          selectedItems = {grid?.selectedItems[0]}
          schema={schema}
          initialData={formData}
          open={open}
          onClose={onClose}
          onSuccess={onUpdateSuccess}
      />
    </> 
  );
}

export default MomSysApplyStockTakingButton;
