import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { debounce, cloneDeep } from "lodash";

import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";
import AccessControl from "../functional/AccessControl";
import UpdateRecordButton from "../input/UpdateRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysDeliveryDetail(props) {
    const { onUpdateMaster, shipId } = props;

    const view = "MomSysVwDeliveryDetail";

    const [, setHeight] = useState();

    const [momSysDeliveryDetailSchema, setMomSysDeliveryDetailSchema] = useState();
    const [modifiedUpdateSchema, setModifiedUpdateSchema] = useState();

    const [momSysDeliveryDetailApi, setMomSysDeliveryDetailApi] = useState();
    const [momSysDeliveryDetailGrid, setMomSysDeliveryDetailGrid] = useState();
    const [momSysDeliveryDetailSelect, setMomSysDeliveryDetailSelect] = useState();
    const [momSysDeliveryDetailDataIsEmpty, setMomSysDeliveryDetailDataIsEmpty] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            const schema = await getSchema(view);

            if (mounted) {
                if (schema) {
                    setMomSysDeliveryDetailSchema(schema);

                    const modifiedSchema = await cloneDeep(schema);

                    for (const column of modifiedSchema.columns) {
                        switch (column.name) {
                            case "userInputDeliveryQty":
                            case "userInputDeliveryUnitPrice":
                            case "userInputDeliveryPrice":
                                column.hideInForm = false;
                                break;

                            case "shipQty":
                            case "shipMediumUnitQty":
                            case "shipSmallUnitQty":
                            case "deliveryQty":
                            case "deliveryMediumUnitQty":
                            case "deliverySmallUnitQty":
                            case "deliveryUnitPrice":
                            case "deliveryPrice":
                                column.hideInForm = false;
                                column.disabled = true;
                                break;

                            default:
                                column.disabled = true;
                                break;
                        }
                    }

                    setModifiedUpdateSchema(modifiedSchema);
                }
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysDeliveryDetailGrid) {
                momSysDeliveryDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDeliveryDetailSelect(grid.selectedItems[0]);
                        }
                    },10)
                );

                momSysDeliveryDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDeliveryDetailSelect(grid.selectedItems[0]);
                        }
                    },10)
                );
            }
        })();
    }, [momSysDeliveryDetailGrid]);

    useEffect(() => {
        if (momSysDeliveryDetailApi) {
            momSysDeliveryDetailApi.refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipId]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (momSysDeliveryDetailApi) {
            momSysDeliveryDetailApi.refresh();
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }
    };

    return (
        <BasicCrudScreenType
            id="MomSysDeliveryDetail"
            table="MomSysShipDetail"
            view={view}
            headerTitle={t("term.MomSysDeliveryDetail")}
            onModifyViewUrl={(url) => `${url}/${shipId}`}
            onInitialized={(grid) => {
                grid.selectionMode = "Row";

                setMomSysDeliveryDetailGrid(grid);
            }}
            onApiInitialized={(api) => {
                setMomSysDeliveryDetailApi(api);
            }}
            onLoadData={(data) => {
                setMomSysDeliveryDetailDataIsEmpty(data.length < 1 ? true : false);
            }}
            onAppendButtonInFrontOfSearch={() => (
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={momSysDeliveryDetailSchema && momSysDeliveryDetailSchema.name}
                        accessMethod={
                            momSysDeliveryDetailSchema && (momSysDeliveryDetailSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id="MomSysDeliveryDetail"
                            schema={modifiedUpdateSchema}
                            grid={momSysDeliveryDetailGrid}
                            modifyUrlCallback={(url) =>
                                `/api/MomSysShipDetail/${momSysDeliveryDetailSelect.shipDetailId}`
                            }
                            modifyFormDataCallback={(formData, name) => {
                                if (momSysDeliveryDetailSelect) {
                                    const userInputDeliveryQty = Number(formData.userInputDeliveryQty);
                                    formData.deliveryQty = userInputDeliveryQty
                                        ? userInputDeliveryQty
                                        : formData.shipQty;
                                    formData.deliveryMediumUnitQty =
                                        formData.deliveryQty * formData.materialMediumUnitQty;
                                    formData.deliverySmallUnitQty =
                                        formData.deliveryQty * formData.materialSmallUnitQty;

                                    const userInputDeliveryUnitPrice = Number(formData.userInputDeliveryUnitPrice);
                                    formData.deliveryUnitPrice = userInputDeliveryUnitPrice
                                        ? userInputDeliveryUnitPrice
                                        : formData.unitCost;

                                    const userInputDeliveryPrice = Number(formData.userInputDeliveryPrice);
                                    formData.deliveryPrice = userInputDeliveryPrice
                                        ? userInputDeliveryPrice
                                        : formData.deliveryQty * formData.deliveryUnitPrice;
                                }
                            }}
                            checkFormValidationCallback={(formData) => {
                                const userInputDeliveryQty = Number(formData.userInputDeliveryQty);
                                if (userInputDeliveryQty > Number(formData.shipQty)) {
                                    return [false, t("error.failToDeliveryQty")];
                                }

                                return [true, ""];
                            }}
                            disabled={momSysDeliveryDetailDataIsEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={momSysDeliveryDetailSchema && momSysDeliveryDetailSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id="MomSysDeliveryDetail"
                            grid={momSysDeliveryDetailGrid}
                            view={view}
                            disabled={momSysDeliveryDetailDataIsEmpty}
                            color="primary"
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            )}
            noCreate
            noEdit
            noDelete
            noExcel
        />
    );
}

export default MomSysDeliveryDetail;
