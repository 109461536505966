import { useState } from "react";
import { InputAdornment } from "@material-ui/core";

import ColorPickerButton from "../../input/ColorPickerButton";
import FilterFormItemText from "./FilterFormItemText";

function FilterFormItemColor ( props ) {

    const { InputProps, onChange, ...others } = props;

    const [ color, setColor ] = useState ( props.value );

    const onChangeText = ( value ) => {

        setColor ( value );

        if ( onChange ) {

            onChange ( value );

        }

    };

    const onChangeColor = ( value ) => {

        setColor ( value );

        if ( onChange ) {

            onChange ( value );

        }

    };

    return (
        <FilterFormItemText
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        {
                            color
                            ?
                            <ColorPickerButton value={color} onChange={onChangeColor} />
                            :
                            <ColorPickerButton value={"#ffffff"} onChange={onChangeColor} />
                        }
                    </InputAdornment>                    
                )
            }}
            onChange={onChangeText}
            {...others}
        />
    );

}

export default FilterFormItemColor;

