import { IconButton } from "@material-ui/core";
import Icon from "../presentation/Icon";

function IconButtonLayout ( props ) {

    const { onClick, ...others } = props;

    return (
        <IconButton {...props}>
            <Icon iconType="material-ui" {...others} />
        </IconButton>
    );

};

export default IconButtonLayout;