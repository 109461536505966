import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from '../screenTypes/functional/BasicCrudScreenType';

function InspectionList ( props ) {
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [, setOnSelectGrid] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        grid.selectionMode = "Row";
        setGrid(grid);
    };
    return (
        <>
            <BasicCrudScreenType
                id="InspectionList"
                table="InspectionListView"
                view="InspectionListView"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.inspectionListRead")}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default InspectionList;
