import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FlexGridXlsxConverter } from "@grapecity/wijmo.grid.xlsx";

import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";

import getSchema from "../../lib/api/getSchema";

function SaveToExcelButton(props) {
    const { t } = useTranslation();

    const { id, grid, view, fileName, exportFormatItem, includeRowHeaders, ...others } = props;

    const [viewSchema, setViewSchema] = useState();
    const [dateDataArray, setDateDataArray] = useState([]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(view);

            if (mounted) {
                setViewSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, [view]);

    useEffect(() => {
        if (viewSchema) {
            const arr = [];
            for (const column of viewSchema.columns) {
                if (column?.grid?.type?.startsWith("date")) {
                    arr.push(column.name);
                }
            }

            setDateDataArray(arr);
        }
    }, [viewSchema]);

    const exportFormatItemWithDate = (args) => {
        const textContent = args.getFormattedCell().textContent;
        const { panel, col } = args;

        if (dateDataArray.includes(panel.columns[col].binding)) {
            args.xlsxCell.value = textContent;
        } else if (exportFormatItem) {
            exportFormatItem(args);
        } else {
            args.xlsxCell.value = textContent;
        }
    };

    const saveToExcel = () => {
        FlexGridXlsxConverter.saveAsync(
            grid,
            {
                includeColumnHeaders: true,
                includeCellStyles: true,
                includeRowHeaders: includeRowHeaders,
                formatItem: exportFormatItemWithDate,
                //formatItem: exportFormatItem
                //    ? exportFormatItem
                //    : (args) => {
                //          let cell = args.getFormattedCell();
                //          args.xlsxCell.value = cell.textContent;
                //      },
            },
            fileName || t(`tableName.${id}`) + ".xlsx"
        );
    };

    return (
        <>
            <SaveToExeclButtonLayout id={id + "save-to-execl-button"} onClick={saveToExcel} {...others} />
        </>
    );
}

export default SaveToExcelButton;
