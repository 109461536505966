/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import MomSysMaterialByCompanyUpdateDialog from "../dialogTypes/MomSysMaterialByCompanyUpdateDialog";

function MomSysMaterialByCompanyButton ( props ) {
    const { t } = useTranslation();
    const { id, schema, grid, refresh, onSuccess, ...others } = props;
    const [ open, setOpen ] = useState(false);
    const [formData, setFormData] = useState();
    const [tableSchema, setTableSchema] = useState();

    useEffect ( () => {
        setTableSchema(schema);
    }, [schema] );
    
    const onOpen = () => {
        if ( grid ) {
            setFormData ( {...(grid.selectedItems[0])} );
        }
        setOpen ( true );
    };

    const onClose = () => {
        setOpen ( false );
    };

    const onUpdateSuccess = ( msg ) => {
        if ( onSuccess ) {
            onSuccess ( msg );
        }
        refresh.refresh ();
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id+"update-button"} onClick={onOpen} {...others} />
            <MomSysMaterialByCompanyUpdateDialog
                id={id+"update-dialog"}
                mode="edit"
                formId={id+"update-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.update` )  : "" }
                schema={tableSchema}
                initialData={formData}
                open={open}
                grid={grid}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
            />
        </>
    );
}

export default MomSysMaterialByCompanyButton;
