import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import RegisterMachineFailureButton from "../input/RegisterMachineFailureButton";
import FixMachineFailureButton from "../input/FixMachineFailureButton";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import { cloneDeep } from 'lodash';
import MachineStateCodeUpdateButton from "../input/MachineStateCodeUpdateButton";

function MomSysMachine(props) {

    const id = "MomSysMstMachine";

    const [disableRegisterButton, setDisableRegisterButton] = useState();
    const [disableFixButton, setDisableFixButton] = useState(true);
    const [machine, setMachine] = useState();
    const [grid, setGrid] = useState();
    const [refresh, setRefresh] = useState();

    const [schema, setSchema] = useState();
    const [machineStateCodeSchema, setMachineStateCodeSchema] = useState();

    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        if (grid) {

            grid.selectionChanged.addHandler((grid) => {

                setMachine(grid.selectedItems[0]);

            });

            grid.itemsSourceChanged.addHandler((grid, event) => {

                if (grid.selectedItems[0]) {

                    setMachine(grid.selectedItems[0]);

                }

            });

        }

    }, [grid]);

    useEffect(() => {

        if (machine) {

            if (String(machine.available) === "0") {

                setDisableRegisterButton(true);

            } else {

                setDisableRegisterButton(false);

            }

            if (String(machine.available) === "1") {

                setDisableFixButton(true);

            } else {

                setDisableFixButton(false);

            }

        } else {

            setDisableRegisterButton(true);

        }

    }, [machine]);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysMstMachine");
            let machineStateCodeSchema = cloneDeep(schema);

            if (machineStateCodeSchema) {
                machineStateCodeSchema.formGroups = [
                    "essential",
                ];
                machineStateCodeSchema.updateWithPatch = false;
                for (const column of machineStateCodeSchema.columns) {
                    switch (column.name) {
                        case "machineStateCode":
                            column.hideInForm = false;
                            break;

                        default:
                            column.formGroups=0
                            column.hideInForm = true;
                            break;
                    }
                }

                setMachineStateCodeSchema(machineStateCodeSchema);
            }

            if (mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onInitialized = (grid) => {

        setGrid(grid);

    }

    const onApiInitialized = (api) => {

        setRefresh(api);

    }

    const onSuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        setRefresh((refresh) => {

            refresh.refresh();

            return refresh;
        })

    }

    const onFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    }

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <BasicCrudScreenType
            id={id}
            table="MomSysMstMachine"
            view="MomSysZvwMachine"
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <CreateRecordButton
                                id={id}
                                schema={schema}
                                refresh={refresh}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch ? "PATCH" : "PUT")}>
                            <UpdateRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={refresh}
                                disabled={selectGrid ? false : true}
                                onSuccess={notifySuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={refresh}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && "UPDATE_STATE"}>
                            <MachineStateCodeUpdateButton
                                id={id}
                                schema={machineStateCodeSchema}
                                grid={grid}
                                refresh={refresh}
                                disabled={selectGrid ? false : true}
                                onSuccess={notifySuccess}
                            />
                        </AccessControl>
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <RegisterMachineFailureButton
                            id="MomSysMstMachine"
                            grid={grid}
                            refresh={refresh}
                            disabled={disableRegisterButton}
                        />
                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <FixMachineFailureButton
                            id="MomSysMstMachine"
                            grid={grid}
                            disabled={disableFixButton}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </BasicCrudScreenActionItemLayout>
                </>
            )}

        />

    )

}

export default MomSysMachine;
