function AutoFilterFormGenericLayout ( props ) {

    const { children } = props;

    return (
        <div>
        { children }
        </div>
    );

}

export default AutoFilterFormGenericLayout;

