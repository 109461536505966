import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function MomSysUpdateOrderBuyerIdButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.updateOrderBuyerId")}</ActionButtonLayout>;

}

export default MomSysUpdateOrderBuyerIdButtonLayout;

