function getFormatDate(date) {

    let year = date.getFullYear();
    let month = (1+ date.getMonth());
    
    month = month >= 10 ? month : '0' + month;
    
    let day = date.getDate();
  
    day = day >= 10 ? day : '0' + day;
  
    return year + '-' + month + '-' + day;
    
  }
  
function getWeekDate() {

    let currentDay = new Date();  
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate  = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let week = [];

    for(let i = 0; i < 7; i++) {

        let resultDay = new Date(theYear, theMonth, theDate + (i -theDayOfWeek));
        let yyyy = resultDay.getFullYear();
        let mm = Number(resultDay.getMonth()) + 1;
        let dd = resultDay.getDate();
        
        mm = String(mm).length === 1 ? '0' + mm : mm;
        dd = String(dd).length === 1 ? '0' + dd : dd;
        
        week[i] = yyyy + '-' + mm + '-' + dd;

    }

    let formatWeekDate = [week[0],week[6]];

    return formatWeekDate;
    
}

function getMonthDate() {

    let date = new Date();

    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let formatWeekDate = [firstDay,lastDay];

    return formatWeekDate;

}

function getWeekNumberOfMonth(date) {
    const currentDate = date.getDate();
    const firstDay = new Date(date.setDate(1)).getDay();
  
    return Math.ceil((currentDate + firstDay) / 7);
};

export {getFormatDate, getMonthDate, getWeekDate, getWeekNumberOfMonth};