import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import Button from "../input/Button";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import ImportTranslationButton from "../input/ImportTranslationButton";
import ModifyTranslationValueButton from "../input/ModifyTranslationValueButton";

import AlertDialog from "../dialogTypes/AlertDialog";

function TranslationValue ( props ) {

    const { t } = useTranslation();

    const { id, modify, namespace, translationPath } = props;

    const [ api, setApi ] = useState ();
    const [ msg, setMsg ] = useState ();
    const [ schema, setSchema ] = useState();
    const [ translationValue, setTranslationValue ] = useState();
    const [ disabled, setDisabled ] = useState(false);
    const [ , setDialog ] = useState ();
    const [ , setGrid ] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "TranslationPath" );

            if ( mounted ) {

                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );
        
    }, [] );

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [api] );

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [namespace] );
    
    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [translationPath] );

    const onGridInitialized = ( ( grid ) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );
                setTranslationValue ( grid.selectedItems && grid.selectedItems[0] );

            } else {

                setDisabled ( true );
                setTranslationValue ( undefined );

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );
                setTranslationValue ( grid.selectedItems && grid.selectedItems[0] );

            } else {

                setDisabled ( true );
                setTranslationValue ( undefined );

            }

            setGrid ( grid );

        } );

    } );

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onAlertInitialized = ( dialog ) => {

        setDialog ( dialog );

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const onModifyViewUrl = ( modify, namespace, translationPath, url ) => {

        if ( modify ) {

            return `${url}/${namespace?namespace.namespaceId:undefined}/${translationPath?translationPath.translationPathId:undefined}`;

        } else {

            return url;
        }
        
    }

    const onCopyPath = () => {

        setGrid ( ( grid ) => {

            if ( grid && grid.selectedItems[0] ) {

                navigator.clipboard.writeText ( grid.selectedItems[0].translationPath );

                setMsg ( t ( "msg.translationPathCopied" ) );

                setDialog ( ( dialog ) => {


                    dialog.show ();

                    return dialog;

                } );

            } else {

                setMsg ( t ( "msg.translationPathNotSelected") );

                setDialog ( ( dialog ) => {


                    dialog.show ();

                    return dialog;

                } );
            }

            return grid;

        } );
    }

    const onModifyViewSchema = ( modify, schema ) => {

        if ( modify ) {

            for ( const column of schema.columns ) {

                switch ( column.name) {

                    case "namespace":
        
                        column.grid.hide = true;
        
                        break;
        
        
                    case "translationPath":
        
                        column.grid.hide = true;
        
                        break;

                    default:

                        break;

                }

            }

        }
    }

    return (

        <>

            <BasicCrudScreenType

                id={id+"TranslationValue"}
                table="VwTranslationValue"
                view="VwTranslationValue"

                headerTitle={t("term.translationValue")}
                
                noCreate
                noEdit
                noDelete
                noExcel

                noSearch={modify}
                noFilter={modify}
                
                onAppendHeaderAtLast={ () =>

                    modify
                    ?
                    <>

                        <BasicCrudScreenActionItemLayout>

                            <ModifyTranslationValueButton
                                id="TranslationValue"
                                disabled={disabled}
                                translationValue={translationValue}
                                title={t("buttonName.modifyTranslationValue")}
                                schema={schema}
                                refresh={api}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />

                        </BasicCrudScreenActionItemLayout>

                    </>
                    :
                    <></>

                }

                onAppendButtonInFrontOfSearch={() =>

                    modify
                    ?
                    <></>
                    :
                    <>

                        <BasicCrudScreenActionItemLayout>

                            <Button color="primary" onClick={onCopyPath} >{ t ( "buttonName.copyPath" ) }</Button>

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <ImportTranslationButton
                                id="ImportTranslation"
                                color="secondary"
                                namespace={namespace}
                                refresh={api}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />

                        </BasicCrudScreenActionItemLayout>

                    </>

                }

                onInitialized={onGridInitialized}
                onApiInitialized={onApiInitialized}
                onModifyViewSchema={ ( schema ) => onModifyViewSchema ( modify, schema ) }
                onModifyViewUrl={ ( url ) => onModifyViewUrl ( modify, namespace, translationPath, url ) }

            />
            <AlertDialog msg={msg} onInitialized={onAlertInitialized} />

        </>

    )

}

export default TranslationValue;

