/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import getSchema from "../../lib/api/getSchema";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguCustomerExcelUploadButton ( props ) {

    const {t} = useTranslation();

    const {id, refresh, onSuccess, onFailure, ...others} = props;

    const [open, setOpen] = useState(false);
    const [schema, setSchema] = useState();

    useEffect ( () => {

        let mounted = true;

        ( async () => {
            
            let schema = await getSchema ( "MomSysBomBopFile" );

            if ( mounted ) {

                setSchema(schema);

            }
        
            return ( () => {

                mounted = false;

            } );

        } ) ();

    }, [] );

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onUploadSuccess = (msg) => {

        if (onSuccess) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    const onUploadFailure = (msg) => {

        onClose ();

        if (onFailure) {

            onFailure ( msg );

        }

        refresh.refresh ();

    };

    return (
        <>
            <CreateRecordButtonLayout id={id} onClick={onOpen} createButtonName ="excelUpload" {...others} />
            <AutoFormDialog
                id={"bom-bop-file-upload-dialog"}
                formId={"bom-bop-file-upload-form"}
                title={t(`dialog.title.bomBopFileUpload`)}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onUploadSuccess}
                onFailure={onUploadFailure}
            />
        </>
    );
}

export default EguCustomerExcelUploadButton;

