import React, { useState } from "react";

import CreateRecordButton from "./CreateRecordButton";
import EguInputInspectionDetailRegisterDialog from "../dialogTypes/EguInputInspectionDetailRegisterDialog";

function EguInputInspectionDetailRegisterButton(props) {
    const { id, refresh, schema, inputInspectionSelect, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);



    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onRegisterSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <React.Fragment>
            <CreateRecordButton id={id} onClick={onOpen} {...others} />
            <EguInputInspectionDetailRegisterDialog
                id={id + "-register-dialog"}
                schema={schema}
                inputInspectionSelect={inputInspectionSelect}
                open={open}
                onClose={onClose}
                onSuccess={onRegisterSuccess}
            />
        </React.Fragment>
    );
}

export default EguInputInspectionDetailRegisterButton;
