import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "../../presentation/Icon";

import "./TopMenuSelectItem.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: "rgba(255, 255, 255, 0.7)",
        "&:hover,&:focus": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
    },
    itemActiveItem: {
        color: "#4fc3f7",
    },
    itemPrimary: {
        fontSize: "inherit",
    },
    itemIcon: {
        minWidth: "auto",
        marginRight: theme.spacing(2),
    }
}));

function TopMenuSelectItem ( props ) {

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <ListItem
            button
            className={clsx(
                classes.item,
                props.selected &&
                    classes.itemActiveItem
            )}
            onClick={props.onClick}
        >
            <ListItemIcon className={classes.itemIcon}>
                <Icon iconType={props.menuItem.iconType}>{ props.menuItem.iconName }</Icon>
            </ListItemIcon>
            <ListItemText
                classes={{
                    primary: classes.itemPrimary,
                }}
                data-cy-top-menu-item={t("menu." + props.menuItem.menuItemName)}
            >
                {t("menu." + props.menuItem.menuItemName)}
            </ListItemText>
        </ListItem>
    );

    
}

export default TopMenuSelectItem;
