import { useState } from "react";
import { cloneDeep } from "lodash";

import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActions from "../dialog/functional/DialogActions";

import AutoFilterForm from "../filter/functional/AutoFilterForm";
import FilterApplyButton from "../input/FilterApplyButton";
import FilterClearButton from "../input/FilterClearButton";
import FilterCancelButton from "../input/FilterCancelButton";

function FilterDialog ( props ) {

    const { id, title, schema, filter, filterValue, moveable, resizable, fullWidth, maxWidth, onInitialized, onFormInitialized, onApply, onClear, onCancel, onFilterChangeValue, ...others } = props;

    const [ , setInstance ] = useState ();
    const [ filterApplyButton, setFilterApplyButton ] = useState ();

    const onApplyFilter = ( filterValue ) => {

        if ( onApply ) {

            onApply ( filterValue );

        }

        setInstance ( ( instance ) => {

            instance.hide ();
            return instance;

        } );

    };

    const onClearFilter = () => {

        if ( onClear ) {

            onClear ();

        }

    }

    const closeFilterDialog = () => {

        setInstance ( ( instance ) => {

            instance.hide ();
            return instance;

        } );

    }

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onFilterFormInitialized = ( filterForm ) => {

        if ( onFormInitialized ) {

            onFormInitialized ( filterForm );

        }
    };

    const onFilterApplyButtonInitialized = ( button ) => {

        setFilterApplyButton ( button );

    };

    return (

        <Dialog
            id={id}
            title={title}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : "md"}
            onInitialized={onDialogInitialized}
            onClose={onCancel}
            {...others}
        >

            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            ) }

            <DialogContent style={{ padding: 0, marginLeft: 0, marginRight: 0}} dividers >
                <AutoFilterForm
                    id={id+"-filter-form"}
                    className="autoFilterForm"
                    schema={schema}
                    filterValue={filterValue?cloneDeep(filterValue):filterValue} // object deep copy - 내부에서 객체 멤버가 수정되어 버그 발생
                    filterApplyButton={filterApplyButton}
                    onInitialized={onFilterFormInitialized}
                />
            </DialogContent>

            <DialogActions {...others} >
                <FilterApplyButton
                    id={id+"-filter-apply-button"}
                    className="filterApplyButton"
                    schema={schema}
                    filter={filter}
                    color="primary"
                    onApply={onApplyFilter}
                    onInitialized={onFilterApplyButtonInitialized}
                    onFilterChangeValue={onFilterChangeValue}
                />
                <FilterClearButton
                    id={id+"-filter-clear-button"}
                    className="filterClearButton"
                    color="primary"
                    onClear={onClearFilter}
                />
                <FilterCancelButton
                    id={id+"-filter-cancel-button"}
                    className="filterCancelButton"
                    color="secondary"
                    onCancel={closeFilterDialog}
                />                                
            </DialogActions>

        </Dialog>

    );

}

export default FilterDialog;
