import { DialogContent } from "@material-ui/core";

function WijmoDialogContent ( props ) {

//    return <div className="modal-body" {...props} />
    return <DialogContent {...props} />

}

export default WijmoDialogContent;
