import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import RoleSet from "./RoleSet";
import RoleSetNamespace from "./RoleSetNamespace";


function RoleSetTranslation ( props ) {

    const { t } = useTranslation();

    const [ height, setHeight ] = useState ( 0 );
    const [ roleSet, setRoleSet ] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectRoleSet = ( roleSet ) => {

        setRoleSet ( roleSet );

    };


    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement>

                    <RoleSet headerTitle={t("term.roleSet")} noCreate noEdit noDelete noExcel onSelect={onSelectRoleSet} />

                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterWidth }}
                />

                <ReflexElement>

                    <RoleSetNamespace roleSet={roleSet} />

                </ReflexElement>

            </ReflexContainer>

        </div>

    )

}

export default RoleSetTranslation;
