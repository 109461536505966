/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import MuiAvatar from "@material-ui/core/Avatar";
import gravatar from "gravatar";

function Avatar ( props ) {

    const { photo, email, ...others } = props;
    const [avatarPhoto, setAvatarPhoto] = useState ();
    
    useEffect ( () => {

        if ( photo && photo[0] === "[" ) {

            let list = JSON.parse ( photo );

            setAvatarPhoto ( list[0] );

        } else {

            setAvatarPhoto ( photo );

        }

    }, [photo] );

    return (
        photo
        ?
        <MuiAvatar src={( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? avatarPhoto : "http://localhost:8080" + avatarPhoto} {...others} />
        :
        (
            email
            ?
            <MuiAvatar src={ gravatar.url(email, {protocol: 'http', s: '200'})} {...others} />
            :
            <MuiAvatar {...others} />                
        )
    );

}

export default Avatar;
