/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";

import DownloadBomBopFileUploadTemplateButton from "../input/DownloadBomBopFileUploadTemplateButton";
import UploadBomBopFileButton from "../input/UploadBomBopFileButton";

function MomSysBomBopFileMaster ( props ) {

    const { t } = useTranslation();

    const { onSelect } = props;

    const [grid, setGrid] = useState();
    const [, setFile] = useState();
    const [api, setApi] = useState();

    const setNotification = useSetRecoilState ( notificationState );

    useEffect( () => {

        let mounted = true;

        ( async () => {

            if (grid) {

                grid.selectionChanged.addHandler( ( grid ) => {

                    if ( mounted ) {

                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                let file = grid.selectedItems[0];

                                onSelect ( file );
                                setFile ( file );
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );
                                setFile ( undefined );

                            }
    
                        }
    
                    }

                } );

                grid.itemsSourceChanged.addHandler( ( grid ) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                let file = grid.selectedItems[0];
                                
                                onSelect ( file );
                                setFile ( file );
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );
                                setFile ( undefined );

                            }
                                
                        }

                    }

                } );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [grid] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        setGrid(grid);
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );

        setApi ( ( api ) => {

            api.refresh ();

            return api;

        } );
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };
    
    return (

        <BasicCrudScreenType
            id="MomSysVwBomBopFileMst"
            headerTitle={t("term.bomBopFile")}
            table="MomSysBomBopFile"
            view="MomSysVwBomBopFile"
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() =>

                <>

                    <BasicCrudScreenActionItemLayout>

                        <AccessControl accessTarget="MomSysBomBopFile" accessMethod="GET">

                            <DownloadBomBopFileUploadTemplateButton id="DownloadBomBopFileTemplateButton"/>

                        </AccessControl>


                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <AccessControl accessTarget="MomSysBomBopFile" accessMethod="POST">

                            <UploadBomBopFileButton
                                id="UploadBomBopFileButton"
                                refresh={api}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />

                        </AccessControl>

                    </BasicCrudScreenActionItemLayout>

                </>

            }
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysBomBopFileMaster;
