import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";
import apiClient from '../../lib/common/apiClient';
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";

function MaterialInOutSaveExcelButton ( props ) {

    const {date, view, excelFileName, searchValue, filterValue, ...others} = props;
    const [, setSchema] = useState();
    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);
    const [today, setToday] = useState();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema(view);
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function settingToday() {
            let year = date.getFullYear();
            let month = checkSingleNumber((date.getMonth() + 1));
            let day = checkSingleNumber(date.getDate());
            let today = year + "-" + month + "-" + day;
            setToday(today);
        }

        function checkSingleNumber(value) {
            if(value < 10) {
                return "0" + value;
            }
            return value;
        }

        settingToday();
    }, [date]);

    const successNotification = (result) => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result) => {
        const targetFileName = today + ' ' + excelFileName + ' 자재 수불부.xlsx';
        
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    const onSaveToExcel = () => {

        let url = `/api/${view}/excelDownload/${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;

        let query = false;

        if ( searchValue ) {

            url += '?$search=' + searchValue;
            query = true;

        }

        if ( filterValue ) {

            try {

                let $filter;

                if ( filterValue.length > 0 ) {

                    $filter = JSON.stringify ( filterValue );

                }

                if ( query ) {

                    url += '&';

                } else {

                    url += '?';
                    
                }   
    
                url += `$filter=${$filter}`;
    
            } catch ( reason ) {
                
            }

        }

        apiClient
            .get(url, { responseType: "blob" })
            .then((result) => {
                doHandleFileStream(result);
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <SaveToExeclButtonLayout id={"save-to-execl-button"} color="primary" onClick = {onSaveToExcel} {...others}/>
        </>
    );
}

export default MaterialInOutSaveExcelButton;
