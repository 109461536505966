/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import DbTaskMaster from "./DbTaskMaster";
import DbTaskDetail from "./DbTaskDetail";

function DbTask(props) {

    const [master, setMaster] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectMaster = ( master ) => {

        setMaster ( master );

    }

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5} data-cy="Master">

                    <DbTaskMaster onSelect={onSelectMaster}/>

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement data-cy="Detail">

                    <DbTaskDetail master={master}/>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default DbTask;
