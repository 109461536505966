import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguOutSourcingPurchaseDetailDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,
        formId,

        onDialogUpdateDetail,
        modifyUrlCallback,

        customizeErrorMsgCallback,
        onSelectMst,
        onChangeInOutType,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [tableSchema, setTableSchema] = useState();
    const [eguOutSourcingPurchaseDetailGrid, setEguOutSourcingPurchaseDetailGrid] = useState();
    const [eguOutSourcingPurchaseDetailSelect, setEguOutSourcingPurchaseDetailSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    useEffect(() => {
        (async () => {
            if (eguOutSourcingPurchaseDetailGrid && schema) {
                eguOutSourcingPurchaseDetailGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        const selectedItems = grid.selectedItems[0];

                        setEguOutSourcingPurchaseDetailSelect(selectedItems);
                        setTableSchema(onChangeInOutType(schema, selectedItems?.inOutType));
                    } else {
                        setEguOutSourcingPurchaseDetailSelect();
                    }
                });

                eguOutSourcingPurchaseDetailGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        const selectedItems = grid.selectedItems[0];

                        setEguOutSourcingPurchaseDetailSelect(selectedItems);
                        setTableSchema(onChangeInOutType(schema, selectedItems?.inOutType));
                    } else {
                        setEguOutSourcingPurchaseDetailSelect();
                    }
                });
            }
        })();
    }, [eguOutSourcingPurchaseDetailGrid, schema, onChangeInOutType]);

    useEffect(() => {
        setFormData((formData) => {
            const newFormData = {
                ...formData,

                // API EguVwOutSourcingPurchaseDetailInOutMaterial/:outSourcingMaterialCode/:outSourcingMaterialCodeVer/:startProcessCode/:endProcessCode/:outSourcingQty
                // 에 의해 componentQty에 해당 outSourcingMaterial 외주에 필요한 해당 자재의 총 수량이 계산되어 리턴된다.

                outSourcingId: onSelectMst?.outSourcingId,
                materialCode: eguOutSourcingPurchaseDetailSelect?.inOutMaterialCode,
                materialCodeVer: eguOutSourcingPurchaseDetailSelect?.inOutMaterialVer,
                materialName: eguOutSourcingPurchaseDetailSelect?.inOutMaterialName,
                inOutType: eguOutSourcingPurchaseDetailSelect?.inOutType,
                bomRequiredQty: eguOutSourcingPurchaseDetailSelect?.componentQty,
                outSourcingMaterialQty: eguOutSourcingPurchaseDetailSelect?.componentQty,
            };

            for (let column of schema.columns) {
                if (column.defaultValue !== undefined) {
                    newFormData[column.name] = column.defaultValue;
                }
            }

            return newFormData;
        });

        setOkButtonDisabled(false);
    }, [onSelectMst, eguOutSourcingPurchaseDetailSelect, schema]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);

        if (onDialogUpdateDetail) {
            onDialogUpdateDetail();
        }
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        if (customizeErrorMsgCallback) {
            notifyFailure(customizeErrorMsgCallback(reason));
        } else {
            notifyFailure(
                t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
            );
        }
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url = `/api/${schema.name}`;

        if (modifyUrlCallback) {
            url = modifyUrlCallback(url);
        }

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setEguOutSourcingPurchaseDetailSelect();

        setOkButtonDisabled(true);

        setFormData();

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setEguOutSourcingPurchaseDetailSelect();

        setOkButtonDisabled(true);

        setFormData();

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}>
                        <BasicCrudScreenType
                            id="EguVwOutSourcingPurchaseDetailInOutMaterial"
                            view="EguVwOutSourcingPurchaseDetailInOutMaterial"
                            embededInOtherScreenType
                            headerTitle={t("term.eguOutSourcingPurchaseDetailInOutMaterial")}
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setEguOutSourcingPurchaseDetailGrid(grid);
                            }}
                            onModifyViewUrl={(url) => {
                                return (
                                    url +
                                    "/" +
                                    onSelectMst.outSourcingMaterialCode +
                                    "/" +
                                    onSelectMst.outSourcingMaterialCodeVer +
                                    "/" +
                                    onSelectMst.startProcessCode +
                                    "/" +
                                    onSelectMst.endProcessCode +
                                    "/" +
                                    onSelectMst.outSourcingQty
                                );
                            }}
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="out-sourcing-purchase-detail-create-form"
                                schema={tableSchema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguOutSourcingPurchaseDetailDialog;
