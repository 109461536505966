/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "react-reflex/styles.css";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import WareHouseStockStatusSaveExcelButton from "../input/WareHouseStockStatusSaveExcelButton";

function WarehouseMaterialList ( props ) {

    const { t } = useTranslation();

    const { warehouse, onSelect } = props;

    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [searchVal, setSearchVal] = useState();
    const [filterVal, setFilterVal] = useState ();

    useEffect(() => {

        let mounted = true;

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( mounted ) {

                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                onSelect(grid.selectedItems[0]);
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );

                            }
    
                        }
    
                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            if ( onSelect ) {
    
                                onSelect(grid.selectedItems[0]);
    
                            }
    
                        } else {
    
                            if ( onSelect ) {

                                onSelect ( undefined );

                            }
                                
                        }

                    }

                });

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [grid]);

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [warehouse] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        setGrid(grid);
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    const onModifyUrl = (url) => {
        
        if ( warehouse ) {

            return url + "/" + warehouse.wareHouseCode;

        }

        return url + "/undefined";
        
    };

    const onSearchChangeValue = (value) => {
        setSearchVal(value);
    }

    const onFilterChangeValue = (value) => {
        setFilterVal(value);
    }
    
    return (

        <BasicCrudScreenType
            id="VwWarehouseInventorySummary"
            headerTitle={t("term.materialList")}
            table="VwWarehouseInventorySummary"
            view="VwWarehouseInventorySummary"
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={onModifyUrl}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onSearchChangeValue={onSearchChangeValue}
            onFilterChangeValue={onFilterChangeValue}
            onAppendButtonInFrontOfSearch={
                () => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <WareHouseStockStatusSaveExcelButton 
                                wareHouse={warehouse}
                                searchValue={searchVal}
                                filterValue={filterVal}
                            />
                        </BasicCrudScreenActionItemLayout>
                    </>
                )
            }
        />                                

    );

}

export default WarehouseMaterialList;
