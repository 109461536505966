import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";

import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import EguProductSampleCreateRecordButton from "../input/EguProductSampleCreateRecordButton";
import EguProductSampleUpdateButton from "../input/EguProductSampleUpdateButton";

import layoutConf from "../../config/layoutConf";

import EguLabDataSheet from "./EguLabDataSheet";

function EguProductSample() {

    const [tableSchema, setTableSchema] = useState();

    const [height, setHeight] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [isEmpty, setIsEmpty] = useState();


    const [onSelectMst, setOnSelectMst] = useState();


    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        let mounted = true;

        (async () => {

            window.addEventListener("resize", handleResize);

            handleResize();

            let schema = await getSchema("EguProductSample");

            if (mounted) {
                setTableSchema(schema);

            }

        })();

        return (() => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        });

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);

                    }
                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);
                    }
                }, 10));
            }
        })();

    }, [grid]);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

        if (data.length < 1) {
            setOnSelectMst(null)
        }

    };

    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <EguProductSampleCreateRecordButton
                            id="EguProductSample"
                            schema={tableSchema}
                            color="primary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            refresh={api}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <EguProductSampleUpdateButton
                            id="EguProductSample"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguProductSample"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={onSelectMst?.approvalState ? true : false || isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id="EguProductSample"
                            grid={grid}
                            view="EguVwProductSamples"
                            color="primary"
                            disabled={isEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <BasicCrudScreenType
                        id="EguProductSample"
                        table="EguProductSample"
                        view="EguVwProductSamples"
                        headerTitle={t("term.productSample")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={onLoadData}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <EguLabDataSheet
                        onSelectMst={onSelectMst}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguProductSample;
