import React from "react";

function BasicCrudScreenTypeLayout ( props ) {

    const { children } = props;


    let headerLayout;
    let contentLayout;
    let footerLayout;

    for (let idx in children) {

        if ( children[idx]?.props && children[idx]?.props.className ) {

            if ( children[idx]?.props.className.indexOf("basicCrudScreenHeaderLayout") >= 0) {
                headerLayout = children[idx];
            }
    
            if ( children[idx]?.props.className.indexOf("basicCrudScreenContentLayout") >= 0) {
                contentLayout = children[idx];
            }
    
            if ( children[idx]?.props.className.indexOf("basicCrudScreenFooterLayout") >= 0) {
                footerLayout = children[idx];
            }
    
        }
        
    }

    return (

        <div>
            <div>
                {
                    headerLayout
                    ?
                    React.cloneElement(headerLayout)
                    :
                    <></>
                }
            </div>
            <div>
                {
                    contentLayout
                    ?
                    React.cloneElement(contentLayout)
                    :
                    <></>
                }
            </div>
            <div>
                {
                    footerLayout
                    ?
                    React.cloneElement(footerLayout)
                    :
                    <></>
                }
            </div>
        </div>

    );


}

export default BasicCrudScreenTypeLayout;

