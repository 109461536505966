/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import layoutConf from "../../config/layoutConf";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import getSpecific from "../../lib/api/getSpecific";
import { debounce } from "lodash";

import "../grid/layout/DataGridPopColumn.css";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguOutSourcingShipActionDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,

        outSourcingShipMst,
        onDialogUpdateDetail,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(true);

    const [outSourcingShipDetailGrid, setOutSourcingShipDetailGrid] = useState();
    const [outSourcingShipDetailSelect, setOutSourcingShipDetailSelect] = useState();

    const [possibleShipLotGrid, setPossibleShipLotGrid] = useState();
    const [possibleShipLotApi, setPossibleShipLotApi] = useState();
    const [, setPossibleShipLotData] = useState();
    const [, setPossibleShipLotDataIsEmpty] = useState();
    const [possibleShipLotSelect, setPossibleShipLotSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    useEffect(() => {
        (async () => {
            if (outSourcingShipDetailGrid) {
                outSourcingShipDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingShipDetailSelect();
                        }
                    }, 10)
                );

                outSourcingShipDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingShipDetailSelect();
                        }
                    }, 10)
                );

                outSourcingShipDetailGrid.loadedRows.addHandler((flex, event) => {
                    for (let row of flex.rows) {
                        if (
                            Number(row.dataItem.outSourcingMaterialQty) === Number(row.dataItem.outSourcingShipTotalQty)
                        ) {
                            row.cssClass = `not-regist-materialByCompany`;
                        }
                    }
                });
            }
        })();
    }, [outSourcingShipDetailGrid]);

    useEffect(() => {
        if (possibleShipLotGrid) {
            (async () => {
                possibleShipLotGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipLotSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipLotSelect();
                        }
                    }, 10)
                );

                possibleShipLotGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipLotSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipLotSelect();
                        }
                    }, 10)
                );

                if (outSourcingShipDetailSelect) {
                    possibleShipLotGrid.loadedRows.addHandler(
                        debounce((flexGrid, event) => {
                            flexGrid.rows.forEach((v) => {
                                v.cssClass = null;
                            });

                            let toBeShippedQty =
                                Number(outSourcingShipDetailSelect.outSourcingMaterialQty) -
                                Number(outSourcingShipDetailSelect.outSourcingShipTotalQty);

                            for (const row of flexGrid.rows) {
                                if (toBeShippedQty <= 0) {
                                    break;
                                }

                                const qtyCurrent = Number(row.dataItem.qtyCurrent);

                                row.cssClass = "qty-equals-blue";
                                toBeShippedQty -= qtyCurrent;
                            }
                        }),
                        10
                    );
                }
            })();
        }
    }, [outSourcingShipDetailSelect, possibleShipLotGrid]);

    useEffect(() => {
        if (outSourcingShipDetailSelect && possibleShipLotApi) {
            possibleShipLotApi.refresh();
        }
    }, [outSourcingShipDetailSelect]);

    useEffect(() => {
        if (outSourcingShipDetailSelect && possibleShipLotSelect) {
            const toBeShippedQty =
                Number(outSourcingShipDetailSelect.outSourcingMaterialQty) -
                Number(outSourcingShipDetailSelect.outSourcingShipTotalQty);
            const qtyCurrent = Number(possibleShipLotSelect.qtyCurrent);
            const wareHouseCode = possibleShipLotSelect.wareHouseCode;

            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    orderBuyerId: outSourcingShipMst.orderBuyerId,
                    outSourcingId: outSourcingShipMst.outSourcingId,
                    outSourcingShipId: outSourcingShipMst.outSourcingShipId,

                    materialCode: outSourcingShipDetailSelect.materialCode,
                    materialCodeVer: outSourcingShipDetailSelect.materialCodeVer,
                    materialName: outSourcingShipDetailSelect.materialName,
                    inOutType: outSourcingShipDetailSelect.inOutType,

                    outSourcingMaterialQty: outSourcingShipDetailSelect.outSourcingMaterialQty, // 외주 발주 등록 시, 설정한 사급 자재 수량
                    outSourcingShipTotalQty: outSourcingShipDetailSelect.outSourcingShipTotalQty, // 여러 외주 출고에 대한 sum
                    outSourcingShipQty: toBeShippedQty > qtyCurrent ? qtyCurrent : toBeShippedQty,
                    lotNo: outSourcingShipMst.lotNo, // productLotNo
                    processCode: outSourcingShipMst.startProcessCode,

                    consumeLotNo: possibleShipLotSelect.lotNo,

                    wareHouseCode: wareHouseCode
                };

                return newFormData;
            });

            if (toBeShippedQty === 0) {
                schema.columns
                    .filter((column) => column.name === "outSourcingShipQty")
                    .forEach((column) => {
                        column.disabled = true;
                        column.required = false;
                    });

                setOkButtonDisabled(true);
            } else {
                schema.columns
                    .filter((column) => column.name === "outSourcingShipQty")
                    .forEach((column) => {
                        column.disabled = false;
                        column.required = true;
                    });

                setOkButtonDisabled(false);
            }
        } else {
            if (schema) {
                schema.columns
                    .filter((column) => column.name === "outSourcingShipQty")
                    .forEach((column) => {
                        column.disabled = true;
                        column.required = false;
                    });
            }

            setOkButtonDisabled(true);
        }
    }, [outSourcingShipDetailSelect, possibleShipLotSelect]);

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (column.name === "outSourcingShipQty") {
                if (
                    Number(formData["outSourcingShipQty"]) +
                    Number(outSourcingShipDetailSelect.outSourcingShipTotalQty) >
                    formData["outSourcingMaterialQty"]
                ) {
                    valid = false;

                    msg = t("error.failToShipQtyThanOutSourcingMaterialQty");
                } else if (Number(formData["outSourcingShipQty"].toFixed(5)) === 0) {
                    valid = false;

                    msg = t("error.positiveFloatRequired");
                }
            }

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            } else {
                if (outSourcingShipDetailGrid) {
                    const updateTarget = outSourcingShipDetailGrid.selectedItems[0];

                    (async () => {
                        const shipDetailRslt = await getSpecific(
                            "EguVwOutSourcingPurchaseDetailForAllShip/specific",
                            updateTarget.outSourcingId,
                            updateTarget.materialCode,
                            updateTarget.materialCodeVer
                        );

                        updateTarget.outSourcingShipTotalQty = shipDetailRslt.data[0].outSourcingShipTotalQty;
                        outSourcingShipDetailGrid.refresh(false); // content update only. grid에 즉시 반영

                        //setTimeout(() => setOutSourcingShipDetailSelect(undefined), 0);
                        //setTimeout(() => setOutSourcingShipDetailSelect(updateTarget), 0);
                    })();
                }

                if (possibleShipLotApi) {
                    possibleShipLotApi.refresh();
                }
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url = `/api/${schema.name}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }
        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setFormData();

        setOkButtonDisabled(true);

        setOutSourcingShipDetailSelect();

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setFormData();

        setOkButtonDisabled(true);

        setOutSourcingShipDetailSelect();

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.MomSysShipDetail.create")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.65}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.5}>
                                <BasicCrudScreenType
                                    id="EguVwOutSourcingPurchaseDetailForAllShip"
                                    view="EguVwOutSourcingPurchaseDetailForAllShip"
                                    headerTitle={t("term.shipDetail")}
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setOutSourcingShipDetailGrid(grid);
                                    }}
                                    onModifyViewUrl={(url) => `${url}/all/${outSourcingShipMst.outSourcingId}`}
                                    embededInOtherScreenType
                                    noExcel
                                />
                            </ReflexElement>

                            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                            <ReflexElement flex={0.5}>
                                {outSourcingShipDetailSelect ? (
                                    <BasicCrudScreenType
                                        id="EguVwOutSourcingSelectMaterialList"
                                        view="EguVwOutSourcingSelectMaterialList"
                                        headerTitle={t("term.standardShipPossibleLot")}
                                        onInitialized={(grid) => {
                                            grid.selectionMode = "Row";
                                            setPossibleShipLotGrid(grid);
                                        }}
                                        onApiInitialized={(api) => {
                                            setPossibleShipLotApi(api);
                                        }}
                                        onLoadData={(data) => {
                                            setPossibleShipLotData(data);
                                            setPossibleShipLotDataIsEmpty(data.length < 1 ? true : false);
                                        }}
                                        onModifyViewUrl={(url) =>
                                            outSourcingShipDetailSelect.inOutType === "IN"
                                                ? `${url}/IN/NULL/${outSourcingShipDetailSelect.materialCode}/${outSourcingShipDetailSelect.materialCodeVer}`
                                                : `${url}/OUT/${outSourcingShipMst.outSourcingId}/${outSourcingShipDetailSelect.materialCode}/${outSourcingShipDetailSelect.materialCodeVer}`
                                        }
                                        noCreate
                                        noEdit
                                        noDelete
                                        noExcel
                                        embededInOtherScreenType
                                    />
                                ) : (
                                    <></>
                                )}
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                    <ReflexElement flex={0.35}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="order-purchase-detail-create-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} // [okButtonDisabled, cancelButtonDisabled]
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguOutSourcingShipActionDialog;
