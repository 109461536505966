import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(1),
    }
}));

function TopMenuDividerSmallItemLayout ( props ) {

    const classes = useStyles();

    return <Divider className={classes.divider} />;
    
}

export default TopMenuDividerSmallItemLayout;
