import { useEffect, useState } from "react";
import "./gridEvent.css"

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function JobQueue(props) {
    const id = "jobQueueAdminView";

    const [, setSchema] = useState();
    const [api, setApi] = useState();
    const [, setGrid] = useState();
    const [, setIsEmpty] = useState();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("JobQueue");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);
    
    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onLoadData = (data) => {
        setIsEmpty(data.length < 1 ? true : false);
    }

    return <BasicCrudScreenType 
        id={id}
        table="JobQueue" 
        view="JobQueueAdminView"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        noCreate
        noEdit
        noDelete
        noExcel
        onLoadData={onLoadData}
    />;
}

export default JobQueue;
