/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import getNewDateObj from "../../lib/common/getNewDateObj";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysBackShipDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,
        formId,
        refresh,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [momSysVwDeliveryGrid, setMomSysVwDeliveryGrid] = useState();
    const [momSysVwDeliverySelect, setMomSysVwDeliverySelect] = useState();

    const [momSysVwDeliveryDetailApi, setMomSysVwDeliveryDetailApi] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        (async () => {
            if (momSysVwDeliveryGrid) {
                momSysVwDeliveryGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwDeliverySelect(grid.selectedItems[0]);
                        } else {
                            setMomSysVwDeliverySelect();
                        }
                    },10)
                );

                momSysVwDeliveryGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysVwDeliverySelect(grid.selectedItems[0]);
                        } else {
                            setMomSysVwDeliverySelect();
                        }
                    },10)
                );
            }
        })();
    }, [momSysVwDeliveryGrid]);

    useEffect(() => {
        if (momSysVwDeliverySelect) {
            (async () => {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        orderBuyerId: momSysVwDeliverySelect.orderBuyerId,
                        orderBuyerName: momSysVwDeliverySelect.orderBuyerName,
                        companyName: momSysVwDeliverySelect.companyName,
                        dueDate: momSysVwDeliverySelect.dueDate,
                        deliveryId: momSysVwDeliverySelect.deliveryId,
                        deliveryNo: momSysVwDeliverySelect.deliveryNo,
                        deliveryDate: momSysVwDeliverySelect.deliveryDate,
                        deliveryState: momSysVwDeliverySelect.deliveryState,

                        backShipDate: getNewDateObj(new Date()),
                    };

                    for (const column of schema.columns) {
                        if (column?.defaultValue !== undefined) {
                            newFormData[column.name] = column.defaultValue;
                        }
                    }

                    return newFormData;
                });
            })();
        }
    }, [momSysVwDeliverySelect]);

    useEffect(() => {
        if (momSysVwDeliveryDetailApi) {
            momSysVwDeliveryDetailApi.refresh();
        }
    }, [momSysVwDeliverySelect]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);

        if (refresh) {
            refresh.refresh();
        }
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url = `/api/${schema.name}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setFormData();
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.6}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.5}>
                                <BasicCrudScreenType
                                    id="MomSysVwDelivery"
                                    embededInOtherScreenType
                                    headerTitle={t("tableName.MomSysDelivery")}
                                    view="MomSysVwDelivery"
                                    noCreate
                                    noEdit
                                    noDelete
                                    noExcel
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setMomSysVwDeliveryGrid(grid);
                                    }}
                                    onModifyViewUrl={(url) => `${url}/year/1`} // 1년 이내로 한정
                                />
                            </ReflexElement>

                            <ReflexSplitter style={{ height: "1px", color: "lightgray" }} />

                            <ReflexElement flex={0.5}>
                                {momSysVwDeliverySelect ? (
                                    <BasicCrudScreenType
                                        id="MomSysVwDeliveryDetail"
                                        embededInOtherScreenType
                                        headerTitle={t("tableName.MomSysDeliveryDetail")}
                                        view="MomSysVwDeliveryDetail"
                                        noCreate
                                        noEdit
                                        noDelete
                                        noExcel
                                        noFilter
                                        onInitialized={(grid) => {
                                            grid.selectionMode = "None";
                                        }}
                                        onApiInitialized={(api) => {
                                            setMomSysVwDeliveryDetailApi(api);
                                        }}
                                        onModifyViewUrl={(url) => `${url}/${momSysVwDeliverySelect.shipId}`}
                                    />
                                ) : (
                                    <></>
                                )}
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.4}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="backship-create-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysBackShipDialog;
