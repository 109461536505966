import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function VwPopOrderPurchaseInput ( props ) {

    const {view , onSearchInitialized, onInitialized, onApiInitialized,onLoadData } = props;

    return <BasicCrudScreenType id={view} view={view} onSearchInitialized = {onSearchInitialized} onInitialized = {onInitialized} onApiInitialized = {onApiInitialized} onLoadData = {onLoadData}/>

}

export default VwPopOrderPurchaseInput;

