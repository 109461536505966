/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";

const useStyles = makeStyles ( () => ( {
    iconButton: {
        padding: 4,
    },
} ) );

function SupportButton ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();


    const openSupport = () => {

        window.open( t("url.support") );

    };


    return (

        <>
            <Tooltip title={t("toolTips.support")}>
                <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={openSupport}
                >
                    <HeadsetMicIcon />
                </IconButton>
            </Tooltip>
        </>

    )
}

export default SupportButton;

