/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import getNewDateObj from "../../../lib/common/getNewDateObj";

function DataGridDateSimpleColumn(props) {
    const { schema, ...others } = props;

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    useEffect(() => {
        if (schema) {
            if (schema?.grid?.type === "dateSimple") {
                setCellTemplate(() => (ctx) => {
                    if (ctx.item[schema.name]) {
                        const date = getNewDateObj(ctx.item[schema.name])

                        let delimiter = "-";
                        if (schema.grid?.dateDelimiter) {
                            delimiter = schema.grid.dateDelimiter;
                        }

                        const val =
                            date.getFullYear() +
                            delimiter +
                            ("0" + (date.getMonth() + 1)).slice(-2) +
                            delimiter +
                            ("0" + date.getDate()).slice(-2);

                        return <span>{val}</span>;
                    } else {
                        return <span></span>;
                    }
                });
            }
        }
    }, [schema]);

    return (
        <DataGridColumn schema={schema} {...others}>
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    );
}

export default DataGridDateSimpleColumn;
