import React from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";

import uiConf from "../../config/uiConf";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "12px",
        width: "10ch",
        align: "right",
    }
}));

function OutputSizeSelect ( props ) {

    const classes = useStyle();

    const {
        id,
        defaultValue,
        outputSize,
        outputSizes,
        onChange,

    } = props;

    const onChangeValue = ( event ) => {

        let value = event.target.value;

        if ( outputSize ) {

            outputSize.set ( value);

        }

        if ( onChange ) {

            onChange ( value );

        }

    }

    return (

        <Select
            id={ id }
            className={classes.root}
            defaultValue={defaultValue??uiConf.defaultOutputSize}
            MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
            onChange={onChangeValue}
        >

            {
                outputSizes
                ?
                outputSizes.map ( ( size ) => <MenuItem key={size} value={ size }>{ size }</MenuItem> )
                :
                uiConf.outputSizes.map ( ( size ) => <MenuItem key={size} value={ size }>{ size }</MenuItem> )
            }

        </Select>

    );
};

export default OutputSizeSelect;

