
/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import getSchema from "../../lib/api/getSchema";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import EguPopTempButton from "../input/EguPopTempButton";

function EguPopTemp(props) {

    const id = "EguPopTempView"

    const table = "EguPopTempView"

    const view = "EguPopTempView"

    const [grid, setGrid] = useState();

    const [onSelectMst, setOnSelectMst] = useState();

    const [height, setHeight] = useState(0);

    const [schema, setSchema] = useState();

    const [mstApi, setMstApi] = useState();

    const [, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        let mounted = true;

        (async () => {

            let schema = await getSchema(table);

            if ( mounted ) {
                setSchema(schema);
            }

        })();

        return ( () => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        } );

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0])

                    };

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0])

                    };

                });
            }

        })()


    }, [grid])

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onInitialized = grid => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setMstApi(api);

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onLoadData={onLoadData}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={
                            () => (
                                <>
                                    <BasicCrudScreenActionItemLayout>
                                        
                                        <EguPopTempButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            modifyUrlCallback={(url) => `/api/EguPopTemp/so`}
                                            category={"so"}
                                            createButtonName={"수주"}
                                            onSelectMst={onSelectMst}
                                            
                                        />

                                        <EguPopTempButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            modifyUrlCallback={(url) => `/api/EguPopTemp/soDetail`}
                                            category={"soDetail"}
                                            createButtonName={"수주상세"}
                                            onSelectMst={onSelectMst}
                                            disabled={!onSelectMst?.orderBuyerId}
                                        />

                                        <EguPopTempButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            modifyUrlCallback={(url) => `/api/EguPopTemp/material`}
                                            category={"material"}
                                            createButtonName={"자재"}
                                            onSelectMst={onSelectMst}
                                            disabled={!onSelectMst?.soDetailPlanId}
                                        />

                                        <EguPopTempButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            modifyUrlCallback={(url) => `/api/EguPopTemp/process`}
                                            category={"process"}
                                            createButtonName={"공정"}
                                            onSelectMst={onSelectMst}
                                            disabled={!onSelectMst?.materialPlanId}
                                        />

                                        <EguPopTempButton
                                            id={id}
                                            schema={schema}
                                            refresh={mstApi}
                                            onSuccess={onSuccess}
                                            modifyUrlCallback={(url) => `/api/EguPopTemp/worker`}
                                            category={"worker"}
                                            createButtonName={"작업자"}
                                            onSelectMst={onSelectMst}
                                            disabled={!onSelectMst?.processPlanId}
                                        />

                                    </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                {/* <ReflexElement>

                    {
                        (onSelectMst?.orderBuyerId)
                            ?
                            <MomSysUglOrderBuyerDetail
                                id="MomSysUglOrderBuyerDetail"
                                onSelectMst={onSelectMst}
                                onUpdateMaster={onUpdateMaster}
                            />
                            :
                            <></>
                    }

                </ReflexElement> */}

            </ReflexContainer>

        </div>

    );

}

export default EguPopTemp;
