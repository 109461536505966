import { Route, Redirect, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import rootRedirectPathState from "../../states/rootRedirectPathState";

import MenuRedirector from "../functional/MenuRedirector";

import EguPopFullScreenLayout from "../screens/EguPopFullScreenLayout";
import EguPopNoticeWrite from "../screens/EguPopNoticeWrite";
import EguPopNoticeUpdate from "../screens/EguPopNoticeUpdate";

import MomSysPopNoticeWrite from "../screens/MomSysPopNoticeWrite";
import MomSysPopNoticeUpdate from "../screens/MomSysPopNoticeUpdate";

function NewWindowRouter(props) {
    const rootRedirectPath = useRecoilValue(rootRedirectPathState);

    return (
        <>
            <Route exact path="/">
                <Redirect to={rootRedirectPath} />
            </Route>
            <MenuRedirector />
            <Switch>
                {/* Screens */}

                <Route path="*/EguPopNotice/:noticeNo" render={() => <EguPopFullScreenLayout />} />
                <Route path="*/EguPopNoticeCreateDialog" render={() => <EguPopNoticeWrite />} />
                <Route path="*/EguPopNoticeUpdateDialog/:noticeNo" render={() => <EguPopNoticeUpdate />} />

                <Route
                    path="*/MomSysPopNoticeCreateDialog/:orderBuyerDetailId/:processCode"
                    render={() => <MomSysPopNoticeWrite />}
                />
                <Route
                    path="*/MomSysPopNoticeUpdateDialog/:orderBuyerDetailId/:processCode"
                    render={() => <MomSysPopNoticeUpdate />}
                />

                {/* Default Redirect */}

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </>
    );
}

export default NewWindowRouter;
