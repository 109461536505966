import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguPopProduceStartDialog from "../dialogTypes/EguPopProduceStartDialog";

function EguPopProduceStartButton(props) {

    const { t } = useTranslation();
    const { id, onSelectMst, onSuccess, onFailure, onTrigger, ...others } = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(onSelectMst);


    useEffect(() => {

        setFormData(onSelectMst);

    }, [onSelectMst]);

    const onOpen = () => {

        setOpen(true);
        onTrigger(true)
    };

    const onClose = () => {

        setOpen(false);
        onTrigger(false)
    };
    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={`${id}-start-button`} onClick={onOpen} createButtonName="produceStart" {...others} />
            <EguPopProduceStartDialog
                id={id + "append-dialog"}
                mode="create"
                formId={id + "append-form"}
                product={formData}
                title={t("dialog.title.Produce.startProduction")}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default EguPopProduceStartButton;

