import { useState } from "react";

import CreateRecordButton from "./CreateRecordButton";
import EguMaterialFakeInputDialog from "../dialogTypes/EguMaterialFakeInputDialog";

function EguMaterialFakeInputButton(props) {
    const { id, refresh, schema, onSuccess, customizeErrorMsgCallback, ...others } = props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFakeInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButton id={id} onClick={onOpen} {...others} />
            <EguMaterialFakeInputDialog
                id={id + "-create-fakeInput-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onFakeInputSuccess}
            />
        </>
    );
}

export default EguMaterialFakeInputButton;
