function AutoFilterFormDateTimeRangeWithButtonsLayout ( props ) {

    const { children } = props;

    return (
        <div>
            { children }
        </div>
    );

}

export default AutoFilterFormDateTimeRangeWithButtonsLayout;

