import { useState } from "react";

import PopOrderPurchaseInputDialog from "../dialogTypes/PopOrderPurchaseInputDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import getExpirationDate from "../../lib/utils/getExpirationDate";
function PopOrderPurchaseInputButton(props) {

    const {
        id,
        grid,
        refresh,
        detailRefresh,
        onSuccess,
        ...others
    } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0],
                fakeInputDate : new Date(),
                manufacturedDate: new Date(),
                expirationDate: getExpirationDate(grid.selectedItems[0].standardExpirationDate, new Date()), 
            } );
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onPopOrderPurchaseInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh && detailRefresh) {
            refresh.refresh();
            detailRefresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout id={id + "create-button"} createButtonName = "popOrderPurchaseInput" onClick={onOpen} {...others} />
            <PopOrderPurchaseInputDialog
                id={id + "orderpuchase-input-dialog"}
                formId={id + "orderpuchase-input-form"}
                initialData={formData}
                open={open}
                onClose={onClose}
                grid = {grid}
                onSuccess={onPopOrderPurchaseInputSuccess}
            />
        </>
    );
}

export default PopOrderPurchaseInputButton;
