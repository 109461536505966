import FilterClearButtonLayout from "../layout/FilterClearButtonLayout";

function FilterClearButton ( props ) {

    const { filter, onClear, ...others } = props;

    const onClick = () => {

        if ( onClear ) {
            
            onClear (  );

        }

    }

    return <FilterClearButtonLayout onClick={onClick} {...others} />

}

export default FilterClearButton;

