/* eslint-disable react-hooks/exhaustive-deps */
import { Children, cloneElement, useEffect, useState } from "react";
import { cloneDeep } from "lodash";

function Filter ( props ) {

    const { onChange, onInitialized, children, ...others } = props;

    const [ filter, setFilter ] = useState ( [] );

    useEffect ( () => {

        let myInstance = {
            
            set: ( filter ) => {

                if ( !filter ) {
                    
                    return;
                    
                }

                let filtered = filter.slice().map ( ( item ) => {

                    let newItem = cloneDeep( item );

                    if ( newItem.condition1.operator === "" && newItem.condition2.operator !== "" ) {

                        newItem.condition1.operator = newItem.condition2.operator;
                        newItem.condition1.value = newItem.condition2.value;
                        newItem.condition2.operator = "";
                        newItem.condition2.value = "";

                    }

                    return newItem;

                } ).filter ( ( item ) => {

                    if ( item.binding === "" ) return false;
                    if ( item.condition1.operator === "" ) return false;

                    return true;
                    
                } );

                setFilter ( filtered );

            }

        }

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

    }, [] );

    return (
        <>
            {
                Children.map(children, (child => 
                    cloneElement(child, {...others, $filter: JSON.stringify( filter ) } )
                ))
            }
        </>
    );

}

export default Filter;

