import checkConditionWithMsg from "./checkConditionWithMsg";

function checkCondition ( target, condition ) {

    let [ result ] = checkConditionWithMsg ( target, condition );

    return result;

}

export default checkCondition;
