import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysPurchaseDetail from "./MomSysPurchaseDetail";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import SaveToExcelButton from "../input/SaveToExcelButton";
import getSpecific from "../../lib/api/getSpecific";
import {debounce} from "lodash";

function MomSysPurchase ( props ) {

    const [ onSelectMst, setOnSelectMst ] = useState();

    const [ grid, setGrid ] = useState();

    const [ height, setHeight ] = useState(0);

    const { t } = useTranslation ();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    }, []);

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));
    
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));

            }

        } ) ()

    }, [grid] )

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onUpdateMaster = () => {

        setGrid ( ( grid ) => {

            if ( grid ) {

                ( async () => {

                    let purchaseMst = await getSpecific("MomSysVwPurchase", grid.selectedItems[0].orderPurchaseId );
        
                    grid.selectedItems[0].orderCost = purchaseMst.orderCost;
                    grid.selectedItems[0].purchaseCost = purchaseMst.purchaseCost;
                    grid.selectedItems[0].purchaseBalance = purchaseMst.purchaseBalance;
                    grid.refresh(false);

                } ) ();

            }

            return grid;

        })

    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType 
                        id="MomSysPurchase" 
                        headerTitle={t("term.po")}
                        view="MomSysVwPurchase" 
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onInitialized}
                        onAppendButtonInFrontOfSearch = {
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                    <SaveToExcelButton
                                        id="MomSysPurchase"
                                        grid={grid}
                                        view="MomSysVwPurchase" 
                                        color="primary"
                                        exportFormatItem={() => {}}
                                    />
                                </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                        {
                        (onSelectMst)
                        ?
                        <MomSysPurchaseDetail 
                            id="MomSysPurchaseDetail" 
                            table="MomSysPurchaseDetail" 
                            view="MomSysVwPurchaseDetail" 
                            onUpdateMaster={onUpdateMaster}
                            onSelectMst={onSelectMst}
                        />
                        :
                        <></>
                        }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysPurchase;
