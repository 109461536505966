/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import UpdateRecordButton from "../input/UpdateRecordButton";
import MomSysCreateStockTakingDetailButton from "../input/MomSysCreateStockTakingDetailButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import MomSysStockTakingDetailSaveExcelButton from "../input/MomSysStockTakingDetailSaveExcelButton";
import MomSysStockTakingDetailApplyExcelButton from "../input/MomSysStockTakingDetailApplyExcelButton";
import {debounce} from "lodash";

function MomSysStockTakingDetail(props) {

  const { id, stockTakingId, mstSchema, approvalState, mstEmpty, onDetailApiInitialized } = props;
  
  const [grid, setGrid] = useState();

  const [schema, setSchema] = useState();

  const [detailRefresh, setDetailRefresh] = useState();
  
  const [ isEmpty, setIsEmpty ] = useState();

  const [ , setNotification ] = useRecoilState(notificationState);

  const [ onSelectDetail, setOnSelectDetail ] = useState();

  useEffect(()=>{

    let mounted = true;

    ( async ()=>{

        let schema = await getSchema("MomSysUglStockTakingDetail");
        if ( mounted ) {

            setSchema(schema);

        }
      
    } ) ();

    return ( () => {
            
            mounted = false;
    
    } );

  },[])

  useEffect(()=>{

    if ( detailRefresh ) {

      detailRefresh.refresh ();

    }

  },[stockTakingId])

 
  useEffect(() => {

    ( async () => {

        if (grid) {

            grid.selectionChanged.addHandler(debounce((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                  setOnSelectDetail(grid.selectedItems[0])

                }

            }, 10));

            grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                  setOnSelectDetail(grid.selectedItems[0])

                }

            }, 10));


            grid.loadedRows.addHandler(debounce((flex, e) => {

              for(const row of flex.rows) {
                  if(row.dataItem.qtyStock !== row.dataItem.qtyDueDiligence) {
                      
                      row.cssClass = `changed-stock`

                  }

                  if(row.dataItem.failedStockTaking){
                    
                      row.cssClass = `failed-stock-apply`
                  }
              }
            }, 10));

        }

    } ) ()


}, [grid] )
  

  const onInitialized = (grid) => {

    grid.selectionMode = "Row";

    setGrid(grid);

  };

  const onApiInitialized = ( api ) => {

    setDetailRefresh ( api );
    
    if(onDetailApiInitialized) {
      
      onDetailApiInitialized(api);

    }


  }

  const notifySuccess = (msg) => {

    setNotification({

      severity: "success",
      msg: msg,
      open: true,

    });
  };

  const onSuccess = (msg) => {

    notifySuccess(msg);

  };

  const notifyFailure = (msg) => {
    
    setNotification({
        severity: "error",
        msg: msg,
        open: true,
    });
  };

  const onFailure = ( reason ) => {
    
    notifyFailure ( reason.response ? reason.response.data : reason );

  };

  const onLoadData = ( data ) => {

    setIsEmpty(data.length < 1 ? true : false)

}

  return (
    <BasicCrudScreenType
      id={id}
      table="MomSysUglStockTakingDetail"
      view= "MomSysZvwStockTakingDetail"
      onApiInitialized={onApiInitialized}
      onInitialized = {onInitialized}
      embededInOtherScreenType
      onLoadData = {onLoadData}
      noCreate
      noEdit
      noDelete
      noExcel
      onModifyViewUrl = { (url) => (url + "/" + stockTakingId)}
      onAppendButtonInFrontOfSearch = {
        () => (
          <>
          <BasicCrudScreenActionItemLayout>
              
              <MomSysCreateStockTakingDetailButton
                id={"MomSysUglStockTakingDetail"}
                schema={schema}
                grid={grid}
                refresh={detailRefresh}
                disabled = {Boolean(approvalState) || mstEmpty}
                createButtonName = {"createStock"}
                onSuccess={onSuccess}
                stockTakingId = {stockTakingId}
              />
              <UpdateRecordButton
                id={"MomSysUglStockTakingDetail"}
                schema={schema}
                grid={grid}
                refresh={detailRefresh}
                disabled = {Boolean(approvalState) || isEmpty}
                onSuccess={onSuccess}
              />
              <DeleteRecordButton
                id={"MomSysUglStockTakingDetail"}
                schema={schema}
                grid={grid}
                refresh={detailRefresh}
                disabled = {onSelectDetail?.lotNo !== "NEWSTOCK" || Boolean(approvalState)}
                onSuccess={onSuccess}
                color="secondary"
              />
              <MomSysStockTakingDetailApplyExcelButton
                stockTakingId = {stockTakingId}
                onSuccess = {onSuccess}
                mstSchema = {mstSchema}
                grid = {grid}
                onSelect = {onSelectDetail}
                refresh={detailRefresh}
                disabled = {Boolean(approvalState) || isEmpty}
              />
              
              <MomSysStockTakingDetailSaveExcelButton
                stockTakingId = {stockTakingId}
                onSuccess={onSuccess}
                onFailure = {onFailure}
                disabled = {Boolean(approvalState) || isEmpty}
                grid = {grid}
                view = "MomSysZvwStockTakingDetail"
              />

          </BasicCrudScreenActionItemLayout>
          </>
      )}
    />
  );
}

export default MomSysStockTakingDetail;
