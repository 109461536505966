import { useState, useEffect } from "react";
import { Grid, TextField, InputAdornment, makeStyles, Backdrop, CircularProgress } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

import FormItemDate from "../form/input/FormItemDate";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";

import DailyProductInOutReport from "./DailyProductInOutReport";
import WeeklyProductInOutReport from "./WeeklyProductInOutReport";
import MonthlyProductInOutReport from "./MonthlyProductInOutReport";

import ProductInOutSaveExcelButton from "../input/ProductInOutSaveExcelButton";
import ProductInOutTotalSaveExcelButton from "../input/ProductInOutTotalSaveExcelButton";
import MaterialInOutReTotalizationButton from "../input/MaterialInOutReTotalizationButton";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ProductInOutReportTabContent (props) {

    const { tab, date, setView, setExcelFileName, onSearchInitialized, apiInitialized } = props;

    switch ( tab ) {

        case "daily":

            return <DailyProductInOutReport 
                date={date} 
                setView={setView} 
                onSearchInitialized={onSearchInitialized} 
                setExcelFileName={setExcelFileName}
                apiInitialized = {apiInitialized}
            />;

        case "weekly":

            return <WeeklyProductInOutReport 
                date={date} 
                setView={setView} 
                onSearchInitialized={onSearchInitialized}
                setExcelFileName={setExcelFileName}
            />;

        case "monthly":

            return <MonthlyProductInOutReport 
                date={date} 
                setView={setView} 
                onSearchInitialized={onSearchInitialized}
                setExcelFileName={setExcelFileName}
            />;

        default:

            return <></>;

    }

}

function ProductInOutReport (props) {

    const classes=useStyle();

    const { t } = useTranslation();

    const [ tab, setTab ] = useState ( "daily" );
    const [ date, setDate ] = useState ( new Date() );
    const [ view, setView ] = useState();
    const [ excelFileName, setExcelFileName] = useState();
    const [ inOutTotalExcelFileButton, setInOutTotalExcelFileButton] = useState();
    const [ , setSearch ] = useState ();
    const [ , setSearchVal ] = useState ();

    const [openProgress, setOpenProgress] = useState(false);

    const [api , setApi ] = useState ();

    useEffect(() => {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate() -1;
        setDate(new Date(year, month, day));
    }, []);

    useEffect(() => {
        setInOutTotalExcelFileButton(
            <span
                style={{
                    display: "inline-block",
                    height: "16px",
                    paddingLeft: "15px",
                    paddingTop: "4px",
                    marginRight: "2px",
                }}
                
            >
                <ProductInOutTotalSaveExcelButton
                    date={date}
                    view={view}
                    excelFileName={excelFileName}
                />
            </span>
        );
        if(excelFileName === '일간') {
            setInOutTotalExcelFileButton(<></>)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, view, excelFileName]);

    const onDateChanged = ( date ) => {

        setDate ( date );

    };

    const onTabChanged = (tab) => {
        
        setTab ( tab );

    };

    const onChangeSearch = ( e ) => {

        setSearch ( ( search ) => {

            if ( search ) {

                setSearchVal ( e.target.value );
                search.applySearch ( e.target.value );

            }

            return search;

        } );

    };

    const onSearchInitialized = ( search ) => {

        setSearch ( search );

        setSearchVal ( ( searchVal ) => {

            if ( searchVal ) {

                search.applySearch ( searchVal );
                
            }

            return searchVal;

        } );

    }

    return (
        <>
            <BasicTabScreenType
                id="material-in-out-report"
                tabs={[
                    "daily",
                    "weekly",
                    "monthly"
                ]}
                tabLabels={[
                    "일별",
                    "주별",
                    "월별"
                ]}
                tabsStyle={{
                    left: 0,
                    margin: "0px",
                    padding:"0px",
                    height: "48px",
                    width: "450px"
                }}
                onTabChanged={onTabChanged}
                value = {tab}
                rightGridItems={
                    [
                        <>
                            <Grid item>
                                {inOutTotalExcelFileButton}
                            </Grid>
                            <Grid item>
                                <span
                                    style={{
                                        display: "inline-block",
                                        height: "16px",
                                        paddingLeft: "15px",
                                        paddingTop: "4px",
                                        marginRight: "2px",
                                    }}
                                >
                                    <ProductInOutSaveExcelButton
                                        date={date}
                                        view={view}
                                        excelFileName={excelFileName}
                                    />
                                </span>
                            </Grid>
                            <Grid item>
                                <span
                                    style={{
                                        display: "inline-block",
                                        height: "16px",
                                        paddingLeft: "15px",
                                        paddingTop: "12px",
                                        marginRight: "2px",
                                    }}
                                >
                                    기준 날짜 :
                                </span>
                                
                            </Grid>
                            <Grid item>
                                <FormItemDate
                                    column={{
                                        name: "Date",
                                    }}
                                    value={date}
                                    inputProps={{
                                        style: {
                                            paddingLeft: "8px",
                                        }
                                    }}
                                    onChange={onDateChanged}
                                    style={{
                                        marginTop: "6px",
                                        width: "145px",
                                        paddingLeft : "0px"
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                    {
                                        tab === "daily" ? 
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    height: "16px",
                                                    paddingTop: "4px",
                                                }}
                                            >
                                            <MaterialInOutReTotalizationButton
                                                date = {date}
                                                tab = {tab}
                                                setOpenProgress = {setOpenProgress}
                                                refresh = {api}
                                            />
                                            </span>
                                            :
                                            <></>
                                    }
                            </Grid>
                            <Grid item>
                                <TextField
                                    id={"product-in-out-report-search"}
                                    className={classes.root}
                                    placeholder={t("common.search")}
                                    onChange={ onChangeSearch }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>                    
                                        )
                                    }}
                                    style={{
                                        bottom: "2px",
                                        width: "120px"                        
                                    }}
                                />
                            </Grid>
                        </>
                    ]
                }
            >
                <ProductInOutReportTabContent 
                    tab={tab} 
                    date={date} 
                    setView={setView} 
                    setExcelFileName={setExcelFileName}
                    onSearchInitialized={onSearchInitialized}
                    apiInitialized = {(api) => setApi(api) }
                />
            </BasicTabScreenType>
            <Backdrop className={classes.backdrop} open={openProgress}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </> 
    );

}

export default ProductInOutReport;
