/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import BatchHistoryMaster from "./BatchHistoryMaster";
import BatchHistoryDetail from "./BatchHistoryDetail";

function BatchHistory(props) {

    const [history, setHistory] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectHistory = ( history ) => {

        setHistory ( history );

    }

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <BatchHistoryMaster onSelect={onSelectHistory}/>

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <BatchHistoryDetail history={history}/>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default BatchHistory;
