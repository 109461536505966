import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysCollectionsDetail from "./MomSysCollectionsDetail";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import SaveToExcelButton from "../input/SaveToExcelButton";
import getSpecific from "../../lib/api/getSpecific";
import {debounce} from "lodash";

function MomSysCollections ( props ) {

    const [ onSelectMst, setOnSelectMst ] = useState();

    const [ grid, setGrid ] = useState();

    const [ height, setHeight ] = useState(0);

    const { t } = useTranslation ();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    }, []);

    useEffect(() => {

        ( async () => {

            if (grid) {
    
                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));
    
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([]);

                    };

                }, 10));

            }

        } ) ()

    }, [grid] )

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onUpdateMaster = () => {

        setGrid ( ( grid ) => {

            if ( grid ) {

                ( async () => {

                    let collectionsMst = await getSpecific("MomSysVwCollections", grid.selectedItems[0].orderBuyerId );

                    grid.selectedItems[0].orderBuyerCost = collectionsMst.orderBuyerCost;
                    grid.selectedItems[0].collectionsCost = collectionsMst.collectionsCost;
                    grid.selectedItems[0].collectionsBalance = collectionsMst.collectionsBalance;
                    grid.refresh(false);
    
                } ) ();

            }

            return grid;

        })

    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6}>

                    <BasicCrudScreenType 
                        id="MomSysCollections" 
                        headerTitle={t("term.so")}
                        table="MomSysVwCollections" 
                        view="MomSysVwCollections" 
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onInitialized}
                        onAppendButtonInFrontOfSearch = {
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                    <SaveToExcelButton
                                        id="MomSysCollections"
                                        grid={grid}
                                        view="MomSysVwCollections" 
                                        color="primary"
                                        exportFormatItem={() => {}}
                                    />
                                </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>

                    {
                    (onSelectMst)
                    ?
                    <MomSysCollectionsDetail 
                        id="MomSysCollectionsDetail" 
                        table="MomSysCollectionsDetail" 
                        view="MomSysVwCollectionsDetail" 
                        onUpdateMaster={onUpdateMaster}
                        onSelectMst={onSelectMst}
                    />
                    :
                    <></>
                    }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysCollections;
