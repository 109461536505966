import { Checkbox, DialogContent, FormControlLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import uiConf from "../../../config/uiConf";

import Dialog from "../../dialog/functional/Dialog";
import DialogActionsOk from "../../dialog/functional/DialogActionsOk";
import DialogHeader from "../../dialog/functional/DialogHeader";
import DialogTitle from "../../dialog/functional/DialogTitle";
import DialogHeaderWithClose from "../../dialog/functional/DialogHeaderWithClose";
import ColumnPickerItemLayout from "../layout/ColumnPickerItemLayout";

function ColumnPickerDialog ( props ) {

    const { t } = useTranslation ();

    const {

        id,

        grid,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,

        $parent,

        ...others

    } = props;

    const [ instance, setInstance ] = useState();
    const [ gridRef, setGridRef ] = useState();
    const [ selected, setSelected ] = useState();
    const [ columnMap, setColumnMap ] = useState();

    let title = t ( "dialog.title.grid.columnPicker" );

    useEffect ( () => {

        if ( grid ) {

            setGridRef ( grid );

            grid.hostElement.addEventListener ( "contextmenu", ( e ) => {

                e.preventDefault();

                setInstance ( ( instance ) => {

                    instance.show ();
                    return instance;

                })
            })
        }


    }, [grid] )

    useEffect ( () => {

        if ( schema ) {

            let columnMap = {};

            for ( let column of schema.columns ) {
    
                columnMap[column.name] = column;
    
            }
    
            setColumnMap ( columnMap );
    
        }
        
    }, [schema] )

    const onDialogInitialized = ( instance ) => {

        let myInstance = {

            show: () => {

                instance.show ();

                setGridRef ( ( grid ) => {

                    setSelected ( grid.columns.map ( ( column ) => column.visible ) );

                    return grid;

                });
                
            },

            hide: () => {

                instance.hide ();

            }

        };

        setInstance ( myInstance );

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }
        
    }

    return (

        <Dialog
            id={id}
            data-cy={'columnPickerDialog'}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.confirmDialogMaxWidth}
            onInitialized={( instance ) => onDialogInitialized ( instance )}
            {...others}
        >
            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose instance={instance}>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent dividers>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr"}}>

                {
                    gridRef && selected
                    &&
                    gridRef.columns.map ( ( column, index ) => (

                        <ColumnPickerItemLayout key={column.name} >
                            <FormControlLabel
                                control={

                                    <Checkbox
                                        data-cy="columnPickerCheckbox"
                                        color="primary"
                                        checked={ selected[index] }
                                        size="small"
                                        onChange={ ( e ) => {

                                            setSelected ( ( selected ) => {

                                                let newSelected = [...selected];

                                                newSelected[index] = e.target.checked;

                                                return newSelected;

                                            })

                                            gridRef.columns[index].visible = e.target.checked;

                                        } }
                                        name={ column.name }
                                    />

                                }
                                label={ columnMap[column.name]?.useColumnPickerTranslation ? t ( `columnPicker.${column.name}` ) : t ( column.header ) }
                            />
                        </ColumnPickerItemLayout>        

                    ) )
                }

                </div>

            </DialogContent>

            <DialogActionsOk close />

        </Dialog>

    );

}

export default ColumnPickerDialog;

