/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import ExceptionOutDialog from "../dialogTypes/ExceptionOutDialog";

function ExceptionOutButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSelectGrid, onSuccess, ...others} = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if(onSelectGrid) {
            setFormData(onSelectGrid);
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <ExceptionOutDialog
                id={id+"append-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                initialData={formData}
                onSuccess={onCreateSuccess}
            />
        </>
    );
}

export default ExceptionOutButton;

