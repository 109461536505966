import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import "../grid/layout/DataGridPopColumn.css";
function EguLotListForProcess ( props ) {

    const { t } = useTranslation ();

    const { lotInfo, notifyWarning, onInitialized } = props;

    const [, setApi] = useState ();

    const pattern = /^[+]?\d*(\.?\d*)?$/;

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [lotInfo] );

    const onGridInitialized = ( grid ) => {
        
        grid.selectionMode = "ListBox";
        grid.allowSorting = false;
        grid.isReadOnly = false;

        if(grid) {
    
            grid.loadedRows.addHandler((flex, e) => {

                let materialMap = {};

                for(const row of flex.rows) {

                    let materialKey = row.dataItem.materialCode+ row.dataItem.materialCodeVer;

                    if(materialMap[materialKey]) {

                        materialMap[materialKey].rows.push(row);
                        materialMap[materialKey].inputQty += row.dataItem.inputQty;

                    } else {

                        materialMap[materialKey] = {
                            rows : [row],
                            materialCode : row.dataItem.materialCode,
                            materialCodeVer : row.dataItem.materialCodeVer,
                            requiredQty : row.dataItem.requiredQty,
                            inputQty : row.dataItem.inputQty,
                        }; 
                    }

                }
                
                for(const key in materialMap) {
                    
                    if(Number(materialMap[key].inputQty.toFixed(4)) === Number(materialMap[key].requiredQty.toFixed(4))) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-equals-blue`
                        }
                    } else if(Number(materialMap[key].inputQty.toFixed(4)) > Number(materialMap[key].requiredQty.toFixed(4))) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-many-orange`
                        }
                    }

                    else if(Number(materialMap[key].inputQty.toFixed(4)) < Number(materialMap[key].requiredQty.toFixed(4))) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-little-red`
                        }
                    }
                }
              });
    
            grid.cellEditEnding.addHandler ( ( sender, e ) => { 
    
                let col = sender.columns[e.col];
                let row = sender.rows[e.row]._data;
                switch(col.binding) {
    
                    case "qtyConsumed": 
    
                        if ( parseFloat((parseFloat(row.notInputFailedQty) + parseFloat(sender.activeEditor.value)).toFixed(4)) > parseFloat(parseFloat(row.inputQty).toFixed(4)) || parseFloat(parseFloat(row.inputFailedQty).toFixed(4)) > parseFloat(parseFloat(sender.activeEditor.value).toFixed(4)) ) {
                            
                            notifyWarning ( t ( "warning.invalidConsumedQty" ) );
                            e.cancel = true;
    
                        }
    
                    break;
    
                    case "inputFailedQty":
    
                        if ( parseFloat(parseFloat(sender.activeEditor.value).toFixed(4)) > parseFloat(parseFloat(row.qtyConsumed).toFixed(4))) {
                            notifyWarning ( t ( "warning.invalidInputFailedQty" ) );
                            e.cancel = true;
                            
                        }
    
                    break;
    
                    case "notInputFailedQty":
    
                        if ( parseFloat((parseFloat(sender.activeEditor.value) + parseFloat(row.qtyConsumed)).toFixed(4)) > parseFloat(parseFloat(row.inputQty).toFixed(4))) {
    
                            notifyWarning ( t ( "warning.invalidNotInputFailedQty" ) );
                            e.cancel = true;
    
                        }
                    
                    break;
                    
                    default :
                    break;
    
                }
    
                if(!pattern.test(sender.activeEditor.value)) {
    
                    notifyWarning ( t ( "warning.numberRequired" ) );
                    e.cancel = true;
                }
    
            } );
    
            if ( onInitialized ) {
    
                onInitialized ( grid );
    
            }
        }

    }

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onModifyUrl = ( lotInfo, url ) => {
    
        let newUrl;

        newUrl = `${url}/complete/${lotInfo?lotInfo.planId:null}`;

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="InputLots"
            headerTitle={t("term.inputLots")}
            table="EguVwPopMaterialList"
            view="EguVwPopMaterialList"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewUrl={ ( url ) => onModifyUrl ( lotInfo, url ) }
            onInitialized={onGridInitialized}
            onApiInitialized={ onApiInitialized }
        />

    );

};

export default EguLotListForProcess;
