import DataGridColumn from '../input/DataGridColumn';
import DataGridSelectColumn from '../input/DataGridSelectColumn';
import DataGridIconColumn from "../input/DataGridIconColumn";
import DataGridApprovalStateColumn from '../input/DataGridApprovalStateColumn';
import DataGridBooleanColumn from '../input/DataGridBooleanColumn';
import DataGridAvatarPhotoColumn from '../input/DataGridAvatarPhotoColumn';
import DataGridShipmentStateColumn from '../input/DataGridShipmentStateColumn';
import DataGridImageColumn from '../input/DataGridImageColumn';
import DataGridMomSysUdtCompanyStateColumn from '../input/DataGridMomSysUdtCompanyStateColumn';
import DataGridFileDownloadColumn from '../input/DataGridFileDownloadColumn';
import DataGridTableNameColumn from '../input/DataGridTableNameColumn';
import DataGridExcelDownloadColumn from '../input/DataGridExcelDownloadColumn';
import DataGridQcResultColumn from '../input/DataGridQcResultColumn';
import DataGridDateTimeColumn from '../input/DataGridDateTimeColumn';
import DataGridDateSimpleColumn from '../input/DataGridDateSimpleColumn';
import DataGridCheckboxColumn from '../input/DataGridCheckboxColumn';
import DataGridNumberColumn from '../input/DataGridNumberColumn';
import DataGridDateMonthColumn from '../input/DataGridDateMonthColumn';
import DataGridRatioColumn from '../input/DataGridRatioColumn';
import DataGridMrpEventTypeColumn from '../input/DataGridMrpEventTypeColumn';
import DataGridPsmEventTypeColumn from '../input/DataGridPsmEventTypeColumn';
import DataGridPreViewColumn from "../input/DataGridPreViewColumn";
import DataGridCustomNameFileDownloadColumn from "../input/DataGridCustomNameFileDownloadColumn";
import DataGridOrderPurchaseInputStateColumn from "../input/DataGridOrderPurchaseInputStateColumn";
import DataGridDataMapColumn from "../input/DataGridDataMapColumn";
import DataGridTooTipColumn from "../input/DataGridToolTipColumn";
import DataGridEditorColumn from "../input/DataGridEditorColumn";

function AutoDataGridColumn ( props ) {

    const {

        schema,
        ...others
    
    } = props;

    let columnType = schema.grid ? schema.grid.type : undefined;

    switch ( columnType ) {


        // Add Custom Column Types Here

        case "icon" : // matched to form item type select

            return <DataGridIconColumn schema={schema} {...others} />

        case "select" : // matched to form item type select

            return <DataGridSelectColumn schema={schema} {...others} />

        case "approvalState" : // matched to form item type select

            return <DataGridApprovalStateColumn schema={schema} {...others} />

        case "boolean" : // matched to form item type select

            return <DataGridBooleanColumn schema={schema} {...others} />

        case "avatarPhoto" :

            return <DataGridAvatarPhotoColumn schema={schema} {...others} />

        case "image" :

            return <DataGridImageColumn schema={schema} {...others} />

        case "shipmentState" :

            return <DataGridShipmentStateColumn schema={schema} {...others} />

        case "momSysCompanyState" :

            return <DataGridMomSysUdtCompanyStateColumn schema={schema} {...others} />

        case "fileDownload" :

            return <DataGridFileDownloadColumn schema={schema} {...others} />

        case "tableName" :

            return <DataGridTableNameColumn schema={schema} {...others} />

        case "excelDownload" :

            return <DataGridExcelDownloadColumn schema={schema} {...others} />

        case "qcResult" :

            return <DataGridQcResultColumn schema={schema} {...others} />

        case "dateTime" :

            return <DataGridDateTimeColumn schema={schema} {...others} />

        case "dateSimple" :

            return <DataGridDateSimpleColumn schema={schema} {...others} />

        case "month":

            return <DataGridDateMonthColumn schema={schema} {...others} />
        
        case "checkbox" :

            return <DataGridCheckboxColumn schema={schema} {...others} />

        case "number" :

            return <DataGridNumberColumn schema={schema} {...others} />

        case "ratio" :

            return <DataGridRatioColumn schema={schema} {...others} />

        case "mrpEventType" :

            return <DataGridMrpEventTypeColumn schema={schema} {...others} />

        case "psmEventType" :

            return <DataGridPsmEventTypeColumn schema={schema} {...others} />

        case "preView" :

            return <DataGridPreViewColumn schema={schema} {...others} />
    
        case "customNameFileDownload" :

            return <DataGridCustomNameFileDownloadColumn schema={schema} {...others} />

        case "orderPurchaseInputState" :

            return <DataGridOrderPurchaseInputStateColumn schema={schema} {...others} />

        case "dataMap" :

            return <DataGridDataMapColumn schema={schema} {...others} />

        case "editor" :

            return <DataGridEditorColumn schema={schema} {...others} />

        case "tooltip":

            return <DataGridTooTipColumn schema={schema} {...others} />

        default:

            return (

                <DataGridColumn schema={schema} {...others} />
        
            );        

    }


}

export default AutoDataGridColumn;

