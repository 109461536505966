/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import { cloneDeep } from "lodash";
import ConfirmDialog from "./ConfirmDialog";
import getSpecific from "../../lib/api/getSpecific";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function PopStockMoveCreateDialog(props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [modifiedSchema, setModifiedSchema] = useState();
    const focusRefs = useRef([]);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const MOVETYPE = {
        IMMEDIATELY: 'immediately',
        REGISTRATION: 'registration',
        OVERWRITE: 'overwrite'
    }

    useEffect(() => {
        Object.freeze(MOVETYPE)
    }, [])

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setOpen(true);

        setConfirmMsg(t("dialog.msg.confirm.overwriteWareHouseMove"));
        setConfirmData(formData);

        setOpen(true);
    };

    const onCloseConfirm = () => {
        setOpen(false);
    };

    useEffect(() => {

        let mounted = true;

        if (formData?.lotNo) {

            (async () => {

                let result = await getSpecific('MomSysVwStockMove', formData?.lotNo);

                if (mounted && result?.data) {
                    if (schema) {
                        let newSchema = cloneDeep(schema);
                        for (const column of newSchema.columns) {
                            switch (column.name) {
                                case "afterWareHouseCode":
                                    column.disabled = true;
                                    column.required = true;
                                    column.formItemType = 'text';
                                    column.defaultValue = result?.data?.afterWareHouseCode
                                    break;
                                default:
                                    break;
                            }
                        }
                        console.log(newSchema)
                        setModifiedSchema(newSchema);
                    }
                } else {
                    if (schema) {
                        let newSchema = cloneDeep(schema);
                        for (const column of newSchema.columns) {
                            switch (column.name) {
                                case "extLotNo":
                                    column.disabled = false;
                                    column.required = true;
                                    column.formItemType = 'barcode';
                                    column.codeInfo = {
                                        type: 'extlot'
                                    };
                                    break;
        
                                case "afterWareHouseCode":
                                    column.required = true;
                                    column.type = 'varchar(100)';
                                    column.formItemType = 'barcode';
                                    column.codeInfo = {
                                        type: 'normal',
                                        tableName: "MomSysUdtWareHouse",
                                        nameColumn: ["wareHouseCode", "wareHouseName"],
                                        valueColumn: "wareHouseCode",
                                        seperator: ", ",
                                        useEmptyValue: false,
                                        emptyName: "term.none",
                                    }
                                    break;
        
                                case "lotNo":
                                    column.required = false;
                                    break;
        
                                case "decisionCode":
                                    column.hideInForm = true;
                                    break;
        
                                default:
                                    break;
                            }
                        }
        
                        setModifiedSchema(newSchema);
                    }
                }

            })()
        } else {
            if (schema) {
                let newSchema = cloneDeep(schema);
                for (const column of newSchema.columns) {
                    switch (column.name) {
                        case "extLotNo":
                            column.disabled = false;
                            column.required = true;
                            column.formItemType = 'barcode';
                            column.codeInfo = {
                                type: 'extlot'
                            };
                            break;

                        case "afterWareHouseCode":
                            column.required = true;
                            column.type = 'varchar(100)';
                            column.formItemType = 'barcode';
                            column.codeInfo = {
                                type: 'normal',
                                tableName: "MomSysUdtWareHouse",
                                nameColumn: ["wareHouseCode", "wareHouseName"],
                                valueColumn: "wareHouseCode",
                                seperator: ", ",
                                useEmptyValue: false,
                                emptyName: "term.none",
                            }
                            break;

                        case "lotNo":
                            column.required = false;
                            break;

                        case "decisionCode":
                            column.hideInForm = true;
                            break;

                        default:
                            break;
                    }
                }

                setModifiedSchema(newSchema);
            }
        }

        return (() => {

            mounted = false;

        })

    }, [formData?.lotNo])

    useEffect(() => {
        if (schema) {
            let modifiedSchema = cloneDeep(schema);
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "extLotNo":
                        column.disabled = false;
                        column.required = true;
                        column.formItemType = 'barcode';
                        column.codeInfo = {
                            type: 'extlot'
                        };
                        break;

                    case "afterWareHouseCode":
                        column.required = true;
                        column.type = 'varchar(100)';
                        column.formItemType = 'barcode';
                        column.codeInfo = {
                            type: 'normal',
                            tableName: "MomSysUdtWareHouse",
                            nameColumn: ["wareHouseCode", "wareHouseName"],
                            valueColumn: "wareHouseCode",
                            seperator: ", ",
                            useEmptyValue: false,
                            emptyName: "term.none",
                        }
                        break;

                    case "lotNo":
                        column.required = false;
                        break;

                    case "decisionCode":
                        column.hideInForm = true;
                        break;

                    default:
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [schema]);

    useEffect(() => {
        formDataClear();
    }, [others.open]);

    const barcodeCallback = (barcode) => {
        if (barcode.lotNo !== undefined) {
            let newFormData = {
                lotNo: barcode.lotNo,
                extLotNo: barcode.extLotNo,
                materialCode: barcode.materialCode,
                materialCodeVer: barcode.materialCodeVer,
                orderBuyerId: barcode.orderBuyerId,
                materialName: barcode.materialName,
                abbreviations: barcode.abbreviations,
                itemSpecName: barcode.itemSpecName,
                itemUnitName: barcode.itemUnitName,
                qtyQueued: barcode.qtyCurrent,
                qty: barcode.qtyCurrent,
                mediumUnitQty: barcode.mediumUnitQty,
                smallUnitQty: barcode.smallUnitQty,
                manufacturedDate: barcode.manufacturedDate,
                expirationDate: barcode.expirationDate,
                beforeWareHouseCode: barcode.wareHouseCode,
                beforeWareHouseName: barcode.wareHouseName,
                wareHouseName: barcode.wareHouseName,
                orderPurchaseId: formData.orderPurchaseId,
                workState: formData.workState,
                jaHo: formData.jaHo,
                lineNo: formData.lineNo,
                moveDate: new Date()
            }
            setFormData(newFormData);

            focusRefs.current['afterWareHouseCode'].focus();
        } else if (barcode.wareHouseCode !== undefined) {
            focusRefs.current['extLotNo'].focus();
        }
    }

    const formDataClear = () => {
        setFormData(null);
    }

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }
        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        console.log(result)

        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout(onSuccess(result.data.message), 0);
        }
    };


    const onApiFailure = (reason) => {
        console.log(reason.response)
        if (reason?.response?.data?.recheck) {
            onOpen();
            notifyFailure(t(reason.response.data.message));
            return;
        }

        notifyFailure(t(reason.response.data));
    };

    const onOk = (schema, formData, moveType) => {
        save(schema, formData, moveType);
    }

    function save(schema, formData, moveType) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        let afterWareHouseCode = formData.afterWareHouseCode.split(', ');

        if(afterWareHouseCode[0] === formData.beforeWareHouseCode) {
            nofifyWarning(t("warning.doNotMoveSameWareHouse"));
            return;
        }

        if(formData.qtyQueued < formData.qty){
            nofifyWarning(t("warning.notEnoughQuantity"));
            return;
        }

        let url = "/api/MomSysStockMove";
        let apiData = { ...formData };

        let form = new FormData();
        let json = {};
        let deleted = {};

        if (schema) {
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);
        json.move = moveType;
        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
            return key;
        }
    }

    function notUndefined(apiData, key) {
        if (apiData !== undefined) {
            return key;
        }
    }

    const onCancel = () => {
        setFormData((formData) => {
            let newFormData = {
                ...formData
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        )
    }

    const onChange = (formData) => {
        setFormData(formData);
        setInteraction(true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({ ...notification, open: false });
        });
    };

    const onCloseDialog = () => {
        setInteraction(false);
        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        if (onClose) {
            onClose();
        }
    }

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="lg"
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {t("dialog.title.MomSysStockMove.create")}
                    </DialogTitle>
                </DialogHeaderWithClose>
                <Divider />
                <DialogContent style={{ padding: "0px", height: "700px" }}>
                    <div
                        style={{
                            padding: "16px"
                        }}
                    >
                        <AutoForm
                            id="momSysStockMoveCreateDialog"
                            schema={modifiedSchema}
                            mode="edit"
                            initialData={formData}
                            onChange={onChange}
                            barcodeCallback={barcodeCallback}
                            focusRefs={focusRefs}
                        />
                    </div>
                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>
                <Divider />
                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    onOk={() => onOk(modifiedSchema, formData, MOVETYPE.IMMEDIATELY)}
                    onCancel={onCancel}
                />
            </Dialog>
            <ConfirmDialog
                id={id + "-delete-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.wareHouseMove`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onCloseConfirm}
                onOk={() => onOk(modifiedSchema, formData, MOVETYPE.OVERWRITE)}
                onCancel={onCloseConfirm}
            />
        </>
    );
}

export default PopStockMoveCreateDialog;
