import { atom } from "recoil";

const notificationState = atom({
    key: "mainNotificationState",
    default: {
        open: false,
        severity: "success",
        msg: ""
    }
});

export default notificationState;