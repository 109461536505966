/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import notificationState from "../../states/notificationState";

import Button from "../input/Button";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import StartProcessDialog from "../dialogTypes/MomSysStartProcessDialog";
import CompleteProcessDialog from "../dialogTypes/MomSysCompleteProcessDialog";
import HoldReleaseCancelDialog from "../dialogTypes/MomSysHoldReleaseCancelDialog";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";

function MomSysProductionStatus ( props ) {

    const { t } = useTranslation();

    const [grid, setGrid] = useState();
    const [noData, setNoData] = useState(true);
    const [openCompleteProcessDialog, setOpenCompleteProcessDialog] = useState(false);
    const [openStartProcessDialog, setOpenStartProcessDialog] = useState(false);
    const [openHoldProcessDialog, setOpenHoldProcessDialog] = useState(false);
    const [openReleaseProcessDialog, setOpenReleaseProcessDialog] = useState(false);
    const [openCancelProductionDialog, setOpenCancelProductionDialog] = useState(false);
    const [lotInfo, setLotInfo] = useState();
    const [processing, setProcessing] = useState(false);
    const [startStopButtonTitle, setStartCompleteButtonTitle] = useState("");
    const [holdButtonTitle, setHoldButtonTitle] = useState("");
    const [, setApi] = useState();
    const [product, setProduct] = useState();

    const setNotification = useSetRecoilState(notificationState);
    
    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler((grid, event) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                let lotInfo = grid.selectedItems[0];

                setLotInfo ( lotInfo );
                setProduct ({materialCode: lotInfo.bopMaterialCode, materialCodeVer: lotInfo.bopMaterialCodeVer, materialName: lotInfo.bopMaterialName, qty: lotInfo.qty});

                if ( lotInfo.workState === "PROCESSING" ) {

                    setStartCompleteButtonTitle(t("buttonName.completeProcessing"));
                    setProcessing(true);

                } else {

                    setStartCompleteButtonTitle(t("buttonName.startProcessing"));
                    setProcessing(false);

                }

                if ( lotInfo.hold && lotInfo.hold === "1" ) {

                    setHoldButtonTitle(t("buttonName.releaseHoldProcessing"));

                } else {

                    setHoldButtonTitle(t("buttonName.holdProcessing"));

                }

                setNoData(false);

            } else {
                    
                setLotInfo();
                setNoData(true);
    
            }

        } );

        grid.itemsSourceChanged.addHandler((grid, event) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                let lotInfo = grid.selectedItems[0];

                setLotInfo ( lotInfo );
                setProduct ({materialCode: lotInfo.bopMaterialCode, materialCodeVer: lotInfo.bopMaterialCodeVer, materialName: lotInfo.bopMaterialName, qty: lotInfo.qty});

                if ( lotInfo.workState === "PROCESSING" ) {

                    setStartCompleteButtonTitle(t("buttonName.completeProcessing"));
                    setProcessing(true);

                } else {

                    setStartCompleteButtonTitle(t("buttonName.startProcessing"));
                    setProcessing(false);

                }

                if ( lotInfo.hold && lotInfo.hold === "1" ) {

                    setHoldButtonTitle(t("buttonName.releaseHoldProcessing"));

                } else {

                    setHoldButtonTitle(t("buttonName.holdProcessing"));

                }

                setNoData(false);

            } else {
                    
                setLotInfo();
                setNoData(true);

            }

        } );

        setGrid ( grid );

    };

    const onApiInitialized = (api) => {

        setApi ( api );

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
        setApi ( ( api ) => {

            if ( api ) {
                
                api.refresh ();

            }

            return api;

        } );
        
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const checkStartCompleteProcessingDisabled = ( grid ) => {

        if ( !grid ) {

            return true;

        }

        if ( grid.selectedItems && grid.selectedItems[0] && grid.selectedItems[0].hold && grid.selectedItems[0].hold === "1" ) {

            return true;

        }

        return false;

    };

    const checkHoldReleaseDisabled = ( grid ) => {

        if ( !grid ) {

            return true;

        }

        if ( grid.selectedItems && grid.selectedItems[0] ) {

            if ( grid.selectedItems[0].workState === "COMPLETED" ) {

                return true;

            }

        } else {

            return true;

        }

        return false;

    }

    const onClickStartCompleteProcessing = ( processing ) => {

        if ( processing ) {

            setOpenCompleteProcessDialog ( true );

        } else {

            setOpenStartProcessDialog ( true );

        }

    };

    const onClickHoldReleaseProcessing = ( lotInfo ) => {

        if ( parseInt ( lotInfo.hold ) ) {

            setOpenReleaseProcessDialog ( true );

        } else {

            setOpenHoldProcessDialog ( true );

        }


    };

    const onClickCancelProduction = () => {

        setOpenCancelProductionDialog ( true );

    };

    const onCloseCompleteProcessingDialog  = () => {

        setOpenCompleteProcessDialog ( false );

    };

    const onCloseStartProcessingDialog  = () => {

        setOpenStartProcessDialog ( false );

    };

    const onCloseHoldProcessingDialog  = () => {

        setOpenHoldProcessDialog ( false );

    };

    const onCloseReleaseProcessingDialog  = () => {

        setOpenReleaseProcessDialog ( false );

    };

    const onCloseCancelProductionDialog  = () => {

        setOpenCancelProductionDialog ( false );

    };
    
    return (

        <BasicCrudScreenType
            id="MomSysProductionStatus"
            table="MomSysVwProductionStatus"
            view="MomSysVwProductionStatus"
            onAppendButtonAtFirst={() =>
                (
                noData
                ?
                <></>
                :
                <>

                    <BasicCrudScreenActionItemLayout>

                        <MomSysBomBopTreeButton color="primary" product={product} />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <Button                            
                            id="StartCompleteProcessing"
                            color="primary"
                            disabled={checkStartCompleteProcessingDisabled(grid)}
                            onClick={() => onClickStartCompleteProcessing (processing)}
                        >
                            {startStopButtonTitle}
                        </Button>

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <Button                            
                            id="HoldReleaseProcessing"
                            color="primary"
                            disabled={checkHoldReleaseDisabled(grid)}
                            onClick={() => onClickHoldReleaseProcessing (lotInfo)}
                        >
                            {holdButtonTitle}
                        </Button>

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>

                        <Button                            
                            id="CancelProduction"
                            color="secondary"
                            onClick={onClickCancelProduction}
                        >
                            {t("buttonName.cancelProduction")}
                        </Button>

                    </BasicCrudScreenActionItemLayout>

                    <StartProcessDialog lotInfo={lotInfo} mode="edit" open={openStartProcessDialog} onClose={onCloseStartProcessingDialog} onSuccess={onSuccess} onFailure={onFailure} />
                    <CompleteProcessDialog lotInfo={lotInfo} mode="edit" open={openCompleteProcessDialog} onClose={onCloseCompleteProcessingDialog} onSuccess={onSuccess} onFailure={onFailure} />
                    <HoldReleaseCancelDialog lotInfo={lotInfo} action="release" open={openReleaseProcessDialog} onClose={onCloseReleaseProcessingDialog} onSuccess={onSuccess} onFailure={onFailure} />
                    <HoldReleaseCancelDialog lotInfo={lotInfo} action="hold" open={openHoldProcessDialog} onClose={onCloseHoldProcessingDialog} onSuccess={onSuccess} onFailure={onFailure} />
                    <HoldReleaseCancelDialog lotInfo={lotInfo} action="cancel" open={openCancelProductionDialog} onClose={onCloseCancelProductionDialog} onSuccess={onSuccess} onFailure={onFailure} />

                </>
                )

            }
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default MomSysProductionStatus;
