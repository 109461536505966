import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOk from "../dialog/functional/DialogActionsOk";

import MomSysProductTree from "../screens/MomSysProductTree";

function MomSysProductTreeDialog ( props ) {

    const { t } = useTranslation();

    const {

        id,
        material,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,

        ...others

    } = props;

    const [, setInstance] = useState ();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onCloseNotification = () => {

        setNotification((notification) => {

            return ({ ...notification, open: false });

        });

    };

    const onError = ( reason ) => {

        setNotification({
            severity: "error",
            msg: reason.response ? reason.response.data : reason,
            open: true,
        });

    };

    return (
        <Dialog
            id={id}
            title={t("dialog.title.MomSysProductTree")}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onInitialized={onDialogInitialized}
            {...others}
        >

            {uiConf.modalWithCloseButton ? (
                <DialogHeaderWithClose>
                    <DialogTitle>
                        {t("dialog.title.MomSysProductTree")}
                    </DialogTitle>
                </DialogHeaderWithClose>
            ) : (
                <DialogHeader>
                    <DialogTitle>
                        {t("dialog.title.MomSysProductTree")}
                    </DialogTitle>
                </DialogHeader>
            )}

            <DialogContent style={{height: "400px"}} dividers >
                <MomSysProductTree material={material} onError={onError} />
            </DialogContent>

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>

            <DialogActionsOk close />

        </Dialog>
    );

}

export default MomSysProductTreeDialog;
