import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { useTranslation } from "react-i18next";
import { cloneDeep, debounce } from "lodash";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import notificationState from "../../states/notificationState";
import defineConf from "../../config/defineConf";
import layoutConf from "../../config/layoutConf";

import apiClient from "../../lib/common/apiClient";
import printJS from "print-js";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopOutSourcingMaterialReturnDoInputButton from "../input/PopOutSourcingMaterialReturnDoInputButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

const buildSchemaInfo = () => {
    const schemaInfo = {};

    schemaInfo.name = "PopOutSourcingMaterialReturn";

    schemaInfo.userFormOrder = true;
    schemaInfo.formGroups = ["disable", "value"];
    schemaInfo.columns = [];

    schemaInfo.columns.push({ name: "outSourcingId" });
    schemaInfo.columns.push({ name: "outSourcingDetailId" });
    schemaInfo.columns.push({ name: "materialCode" });
    schemaInfo.columns.push({ name: "materialCodeVer" });
    schemaInfo.columns.push({ name: "materialName" });
    schemaInfo.columns.push({ name: "itemSpecName" });
    schemaInfo.columns.push({ name: "itemUnitName" });
    schemaInfo.columns.push({ name: "materialTypeName" });
    schemaInfo.columns.push({ name: "outSourcingMaterialQty" });
    schemaInfo.columns.push({ name: "outSourcingShipTotalQty" });
    schemaInfo.columns.push({ name: "outSourcingShipQty" });
    schemaInfo.columns.push({ name: "consumeLotNo" });
    schemaInfo.columns.push({ name: "returnedQty" });
    schemaInfo.columns.push({ name: "wareHouseCode" });
    schemaInfo.columns.push({ name: "description" });
    schemaInfo.columns.push({ name: "returnedDate" });

    for (let column of schemaInfo.columns) {
        switch (column.name) {
            case "outSourcingId":
                column.formOrder = 1;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "outSourcingDetailId":
                column.formOrder = 2;
                column.formGroup = 0;
                column.hideInForm = true;

                break;

            case "materialCode":
                column.formOrder = 10;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "materialCodeVer":
                column.formOrder = 11;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "materialName":
                column.formOrder = 12;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "itemSpecName":
                column.formOrder = 13;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "itemUnitName":
                column.formOrder = 14;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "materialTypeName":
                column.formOrder = 15;
                column.formGroup = 0;
                column.formItemType = "text";
                column.disabled = true;

                break;

            case "outSourcingMaterialQty":
                column.formOrder = 20;
                column.formGroup = 0;
                column.disabled = true;
                column.formItemType = "float";

                break;

            case "outSourcingShipTotalQty":
                column.formOrder = 21;
                column.formGroup = 0;
                column.disabled = true;
                column.formItemType = "float";

                break;

            case "outSourcingShipQty":
                column.formOrder = 22;
                column.formGroup = 0;
                column.disabled = true;
                column.formItemType = "float";

                break;

            case "consumeLotNo":
                column.formOrder = 23;
                column.formGroup = 0;
                column.disabled = true;
                column.formItemType = "float";

                break;

            case "returnedQty":
                column.formOrder = 24;
                column.formGroup = 1;
                column.required = true;
                column.formItemType = "float";

                break;

            case "returnedDate":
                column.formOrder = 25;
                column.formGroup = 1;
                column.formItemType = "date";
                
                break;

            case "wareHouseCode":
                column.formOrder = 26;
                column.formGroup = 1;
                column.required = true;
                column.formItemType = "autocomplete";
                column.codeInfo = {
                    tableName: "MomSysVwInputWareHouse",
                    nameColumn: ["wareHouseCode", "wareHouseName"],
                    valueColumn: "wareHouseCode",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                };

                break;

            case "description":
                column.formOrder = 200;
                column.formGroup = 1;
                column.formItemType = "text";

                break;

            default:
                column.hideInForm = true;

                break;
        }
    }

    return schemaInfo;
};

function PopOutSourcingMaterialReturn(props) {
    const id = "PopOutSourcingMaterialReturn";

    const [height, setHeight] = useState(0);
    const { t } = useTranslation();

    const [popVwOutSourcingMaterialReturnTargetListGrid, setPopVwOutSourcingMaterialReturnTargetListGrid] = useState();
    const [popVwOutSourcingMaterialReturnTargetListApi, setPopVwOutSourcingMaterialReturnTargetListApi] = useState();
    const [popVwOutSourcingMaterialReturnTargetListSelect, setPopVwOutSourcingMaterialReturnTargetListSelect] =
        useState();

    const [popVwOutSourcingMaterialReturnHistoryGrid, setPopVwOutSourcingMaterialReturnHistoryGrid] = useState();
    const [popVwOutSourcingMaterialReturnHistoryApi, setPopVwOutSourcingMaterialReturnHistoryApi] = useState();
    const [popVwOutSourcingMaterialReturnHistorySelect, setPopVwOutSourcingMaterialReturnHistorySelect] = useState();

    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    const schema = buildSchemaInfo();

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (popVwOutSourcingMaterialReturnTargetListGrid && mounted) {
                popVwOutSourcingMaterialReturnTargetListGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingMaterialReturnTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingMaterialReturnTargetListSelect();
                        }
                    }),
                    10
                );

                popVwOutSourcingMaterialReturnTargetListGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingMaterialReturnTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingMaterialReturnTargetListSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [popVwOutSourcingMaterialReturnTargetListGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (popVwOutSourcingMaterialReturnHistoryGrid && mounted) {
                popVwOutSourcingMaterialReturnHistoryGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingMaterialReturnHistorySelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingMaterialReturnHistorySelect();
                        }
                    }),
                    10
                );

                popVwOutSourcingMaterialReturnHistoryGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingMaterialReturnHistorySelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingMaterialReturnHistorySelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [popVwOutSourcingMaterialReturnHistoryGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    useEffect(() => {
        const path = `popOutSourcingMaterialReturnLot/${popVwOutSourcingMaterialReturnHistorySelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [popVwOutSourcingMaterialReturnHistorySelect]);

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Target">
                    <BasicCrudScreenType
                        id={id + "-Target"}
                        view="PopVwOutSourcingMaterialReturnTargetList"
                        headerTitle={t("term.popVwOutSourcingMaterialReturnTargetList")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopVwOutSourcingMaterialReturnTargetListGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopVwOutSourcingMaterialReturnTargetListApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <PopCrudScreenActionItemLayout>
                                    <PopOutSourcingMaterialReturnDoInputButton
                                        id={id}
                                        schema={schema}
                                        onSelectMst={popVwOutSourcingMaterialReturnTargetListSelect}
                                        refreshFtn={() => {
                                            popVwOutSourcingMaterialReturnTargetListApi.refresh();

                                            if (popVwOutSourcingMaterialReturnHistoryApi) {
                                                popVwOutSourcingMaterialReturnHistoryApi.refresh();
                                            }
                                        }}
                                        disabled={!popVwOutSourcingMaterialReturnTargetListSelect}
                                        onSuccess={onSuccess}
                                        onFarilur={onFailure}
                                    />
                                </PopCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4} data-cy="History">
                    <BasicCrudScreenType
                        id={id + "-History"}
                        view="PopVwOutSourcingMaterialReturnHistory"
                        headerTitle={t("term.popVwOutSourcingMaterialReturnHistory")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopVwOutSourcingMaterialReturnHistoryGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopVwOutSourcingMaterialReturnHistoryApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <PopCrudScreenActionItemLayout>
                                    <PrintReportButton
                                        noLayout
                                        url={reportUrl}
                                        disabled={!popVwOutSourcingMaterialReturnHistorySelect?.lotNo}
                                    >
                                        {t("buttonName.lotInfoPrint")}
                                    </PrintReportButton>
                                </PopCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default PopOutSourcingMaterialReturn;
