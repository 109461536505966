/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-reflex/styles.css";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";


function MomSysBomBopFileDetail ( props ) {

    const { t } = useTranslation();

    const { file } = props;
    
    const [grid, setGrid] = useState ();
    const [api, setApi] = useState();
    const [material, setMaterial] = useState ();

    useEffect ( () => {

        if ( file && api ) {

            api.refresh ();

        }

    }, [file, api] );
    useEffect( () => {

        let mounted = true;

        ( async () => {

            if (grid) {

                grid.selectionChanged.addHandler( ( grid ) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            let material = grid.selectedItems[0];
                                
                            setMaterial ( material );

                        } else {
    
                            setMaterial ( undefined );
                                
                        }
    
                    }

                } );

                grid.itemsSourceChanged.addHandler( ( grid ) => {

                    if ( mounted ) {
                    
                        if ( grid.selectedItems && grid.selectedItems[0] ) {
    
                            let material = grid.selectedItems[0];
                                
                            setMaterial ( material );

                        } else {
    
                            setMaterial ( undefined );
                                
                        }

                    }

                } );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [grid] );

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        setGrid(grid);
        
    };

    const onModifyUrl = (url) => {
        
        if ( file ) {

            return url + "/" + file.id;

        }

        return url + "/undefined";
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    return (

        <BasicCrudScreenType
            id="MomSysVwBomBopFileDetail"
            headerTitle={t("term.bomBopFileDetail")}
            table="MomSysBomBopFileDetail"
            view="MomSysVwBomBopFileDetail"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            onModifyViewUrl={onModifyUrl}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonAtFirst={() =>
                <>

                    <BasicCrudScreenActionItemLayout>

                        <MomSysBomBopTreeButton color="primary" disabled={!material} product={material} />

                    </BasicCrudScreenActionItemLayout>

                </>

            }
        />                                

    );

}

export default MomSysBomBopFileDetail;
