import { useState } from "react";

import PopOrderPurchaseMaterialFakeInputDialog from "../dialogTypes/PopOrderPurchaseMaterialFakeInputDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function PopOrderPurchaseMaterialFakeInputButton(props) {

    const {
        id,
        schema,
        grid,
        refresh,
        detailRefresh,
        onSuccess,
        ...others
    } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {

        //가입고 수량 - 입고 검사 된 수량
        
        let qtyFakeInput = grid.selectedItems[0].qtyFakeInput - grid.selectedItems[0].inputInspectionSummaryQty;

        if (grid) {
            setFormData({ ...grid.selectedItems[0] , qtyFakeInput : qtyFakeInput}  );
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onPopMaterialFakeInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh && detailRefresh) {
            refresh.refresh();
            detailRefresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout id={id + "create-button"} createButtonName = "popOrderPurchaseMaterialFakeInput" onClick={onOpen} {...others} />
            <PopOrderPurchaseMaterialFakeInputDialog
                id={id + "orderpuchase-material-fakeinput-dialog"}
                formId={id + "orderpuchase-material-fakeinput-form"}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onPopMaterialFakeInputSuccess}
            />
        </>
    );
}

export default PopOrderPurchaseMaterialFakeInputButton;
