/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";


function EguSalesStatus ( props ) {

    const { t } = useTranslation();

    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
            
    };

    return (

        <BasicCrudScreenType
            id="EguSalesStatus"
            headerTitle={t("term.salesStatus")}
            table="EguVwSalesStatusSummary"
            view="EguVwSalesStatusSummary"
            noCreate
            noEdit
            noDelete
            noExcel
            noFilter
            onInitialized={onGridInitialized}
        />                                

    );

}

export default EguSalesStatus;
