/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import ReportViewer from "../input/ReportViewer";

function ReportViewerTest ( props ) {

    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.5}>

                    <ReportViewer
                        id="report1"
                        reportName="LotInfo"
                        reportData={JSON.stringify({
                            "title": "LOT 정보1",
                            "materialCode": "HALFPRODUCT01",
                            "materialCodeVer": 1,
                            "materialName": "김 1장",
                            "itemUnitName": "",
                            "itemSpecName": "",
                            "manufacturedDate": "2023-02-14 04:30:13.212000",
                            "expirationDate": null,
                            "lotNo": "2787",
                            "extLotNo": "20230214-HALFPRODUCT01/1-000000001",
                            "qty": 10,
                            "mediumUnitQty": 10,
                            "smallUnitQty": 10,
                            "jaHo": null,
                            "wareHouseName": "자재창고"                      
                        })}
                    />

                </ReflexElement>

                <ReflexSplitter
                    style={{border: "none",width: "1px",color:"lightgray"}}
                />

                <ReflexElement>

                    <ReportViewer
                        id="report2"
                        reportName="LotInfo"
                        reportData={JSON.stringify({
                            "title": "LOT 정보2",
                            "materialCode": "HALFPRODUCT02",
                            "materialCodeVer": 1,
                            "materialName": "김 100장",
                            "itemUnitName": "",
                            "itemSpecName": "",
                            "manufacturedDate": "2023-02-14 04:30:13.212000",
                            "expirationDate": null,
                            "lotNo": "2787",
                            "extLotNo": "20230214-HALFPRODUCT01/1-000000001",
                            "qty": 10,
                            "mediumUnitQty": 10,
                            "smallUnitQty": 10,
                            "jaHo": null,
                            "wareHouseName": "자재창고"                      
                        })}
                    />

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default ReportViewerTest;
