/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";

import menuInfoState from "../../states/menuInfoState";

import makeSelectionByPath from "../../lib/utils/makeSelectionByPath";
import AlertDialog from "../dialogTypes/AlertDialog";

const useStyles = makeStyles ( () => ( {
    iconButton: {
        padding: 4,
    },
} ) );

function HelpButton ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    let location = useLocation ();
    const menuInfo = useRecoilValue( menuInfoState );

    const [url, setUrl] = useState();
    const [alertDialog, sestAlertDialog] = useState(); 

    const openHelp = ( url ) => {

        if ( !url ) {

            alertDialog.show ();
            
        } else {

            window.open(url);

        }

    };

    const onInitialized = ( instance ) => {

        sestAlertDialog ( instance );

    };

    useEffect(() => {

        let selection = makeSelectionByPath ( menuInfo, location.pathname );

        for ( const menuItem of menuInfo.subMenuItems ) {

            if ( menuItem.subMenuItemId === selection.sub ) {

                setUrl ( menuItem.help );

            }
            
        }

    }, [menuInfo, location]);

    return (

        <>
            <Tooltip title={t("toolTips.help")}>
                <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    onClick={() => openHelp(url) }
                >
                    <HelpIcon />
                </IconButton>
            </Tooltip>
            <AlertDialog msg={t("error.noHelp")} onInitialized={onInitialized} />
        </>

    )
}

export default HelpButton;

