import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysUglStockTakingMstDialog from "../dialogTypes/MomSysUglStockTakingMstDialog";

function CreateStockTakingButton(props) {

  const { t } = useTranslation();

  const { id, schema, refresh, onSuccess, stockTakingType, ...others } = props;
  
  const [open, setOpen] = useState(false);

  const onOpen = () => {

    setOpen(true);

  };

  const onClose = () => {

    setOpen(false);

  };

  const onCreateSuccess = (data) => {
    if (onSuccess) {

      onSuccess(data.message);

    }

    refresh.refresh();

  };

  return (
    <>
      <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} {...others} />
      <MomSysUglStockTakingMstDialog
        id={id + "create-dialog"}
        formId={id + "create-form"}
        title={schema ? t(`dialog.title.${schema.name}.${'create'}`) : ""}
        schema={schema}
        initialData={undefined}
        open={open}
        onClose={onClose}
        onSuccess={onCreateSuccess}
      />
    </> 
  );
}

export default CreateStockTakingButton;
