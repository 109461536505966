/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function EguMaterialInputStatusDetail(props) {
    const { onSelectMst } = props;

    const { t } = useTranslation();

    const [detailApi, setDetailApi] = useState();

    useEffect(() => {
        if (detailApi) {
            detailApi.refresh();
        }
    }, [onSelectMst.orderPurchaseId, onSelectMst.materialFakeInputId]);

    return (
        <BasicCrudScreenType
            noCreate
            noEdit
            noDelete
            noExcel
            id="MomSysZvwInputInspect"
            view="MomSysZvwInputInspect"
            onInitialized={(grid) => (grid.selectionMode = "None")}
            onApiInitialized={(api) => {
                setDetailApi(api);
            }}
            headerTitle={t("term.eguInputMaterialStatusByInspection")}
            onModifyViewUrl={(url) => `${url}/${onSelectMst.orderPurchaseId}/${onSelectMst.materialFakeInputId}`}
        />
    );
}

export default EguMaterialInputStatusDetail;
