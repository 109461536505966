import apiClient from "../common/apiClient";

async function logout( profile ) {

    let response;

    if ( profile ) {

        response = await apiClient.post("/auth/logout", {
            username: profile.username,
            tenant: profile.tenant,
            rolesetId: profile.rolesetId,
            tenantId: profile.tenantId,
        } );

    } else {

        response = await apiClient.get("/auth/logout");

    }

    return response;

}

export default logout;
