import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import EguResearchInputMaterialDetailButton from "../input/EguResearchInputMaterialDetailButton";

import getSchema from "../../lib/api/getSchema";

import AccessControl from "../functional/AccessControl";

import notificationState from "../../states/notificationState";


function EguResearchInputMaterialDetail(props) {

    const { onSelectMst, onInputMaterial } = props;

    const [tableSchema, setTableSchema] = useState();

    const [height, setHeight] = useState();

    const [, setDetailGrid] = useState();
    const [detailApi, setDetailApi] = useState();

    const [, setIsEmpty] = useState();


    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguResearchInputMaterialDetail");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);

    const onDetailInitialized = (grid) => {

        setDetailGrid(grid);

    };

    const onDetailApiInitialized = (api) => {

        setDetailApi(api);

    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)
        onInputMaterial(data.length < 1 ? false : true)

    };

    useEffect(() => {

        if (detailApi) {

            detailApi.refresh();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.eguResearchInputMaterialId])

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (detailApi) {
            detailApi.refresh();
        }
    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <EguResearchInputMaterialDetailButton
                            id="EguResearchInputMaterial"
                            schema={tableSchema}
                            color="primary"
                            onSuccess={onSuccess}
                            refresh={detailApi}
                            onSelectMst={onSelectMst}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id="EguResearchInputMaterialDetail"
                table="EguResearchInputMaterialDetail"
                view="EguVwResearchInputMaterialDetail"
                headerTitle={t("term.eguResearchInputMaterialDetail")}
                noCreate
                noEdit
                noDelete
                noExcel
                onLoadData={onLoadData}
                onInitialized={onDetailInitialized}
                onApiInitialized={onDetailApiInitialized}
                onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                onModifyViewUrl={(url) => url + '/' + onSelectMst?.eguResearchInputMaterialId}
                exportFormatItem={() => { }}
            />
        </div>
    );
}

export default EguResearchInputMaterialDetail;
