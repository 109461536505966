import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

import EguLaborCostCreateDialog from "../dialogTypes/EguLaborCostCreateDialog";

import { cloneDeep } from 'lodash';

function EguLaborCostCreateButton(props) {

    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, modifyFormDataCallback, getFormData, ...others } = props;

    const [open, setOpen] = useState(false);
    const [newSchema, setNewSchema] = useState(schema);

    useEffect(() => {

        if (schema) {

            setNewSchema(schema)

        }

    }, [schema])

    const onOpen = () => {

        let nSchema = cloneDeep(schema)

        setNewSchema(nSchema)

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        if (refresh) {
            refresh.refresh();
        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <EguLaborCostCreateDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={newSchema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                getFormData={getFormData}
            />
        </>

    );
}

export default EguLaborCostCreateButton;

