/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddProcessButtonLayout from "../layout/AddProcessButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSchema from "../../lib/api/getSchema";

function AddProcessButton ( props ) {

    const { t } = useTranslation();

    const { id, product, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ schema, setSchema ] = useState ();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }
        
        refresh.refresh ();

    };

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "MomSysUdtBillOfProcess" );

            if ( mounted && schema ) {
    
                for ( const column of schema.columns ) {
    
                    switch ( column.name ) {
        
                        case "bopMaterialCode":
        
                            column.disabled = true;
        
                            break;
        
                        case "bopMaterialCodeVer":
        
                            column.disabled = true;
        
                            break;
        
                        case "bopMaterialName":
        
                            column.disabled = true;
        
                            break;
        
                        default:
        
                            break;
        
                    }
        
                }
    
                let column = {};
    
                column.name = "bopMaterialName";
                column.formGroup = 0;
                column.formOrder = 3;
                column.type = "text";
                column.readonly = true;     
                column.disabled = true;
                
                schema.columns.push ( column );
    
                column = {};
                            
                column.name = "machineCode";
                column.formGroup = 2;
                column.formOrder = 6;
                column.type = "text";
                column.formItemType = "autocomplete";
                column.codeInfo = {
                    tableName: "MomSysMstMachine",
                    nameColumn: "machineName",
                    valueColumn: "machineCode",
                    useEmptyValue: false,
                    sortByName: true,
                }
    
                schema.columns.push ( column );
    
                setSchema ( schema );
    
                
            }
        } )();


        return ( () => {
            mounted = false;
        } );

    }, [] );

    const setInitialDataCallback = ( formData ) => {
            
        if ( !formData ) {

            formData = {};
            
        }

        formData.bopMaterialCode = product.materialCode;
        formData.bopMaterialCodeVer = product.materialCodeVer;
        formData.bopMaterialName = product.materialName;

    }


    return (

        <>
            <AddProcessButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"append-dialog"}
                mode="create"
                formId={id+"append-form"}
                title={ t ( "dialog.title.MomSysUdtBillOfProcess.create" ) }
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                setInitialDataCallback={setInitialDataCallback}
            />
        </>

    );
}

export default AddProcessButton;

