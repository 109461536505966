import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

function FilterFormItemAndOr ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, label, InputLabelProps, onChange, ...others } = props;

    const andOrItems = [
        {
            name: t("dataTableFilter.andOrValue.and"),
            value: true
        },
        {
            name: t("dataTableFilter.andOrValue.or"),
            value: false
        }
    ];

    const selectProps = {
        onChange: ( event ) => {
            onChange ( event.target.value );
        },
        defaultValue: true,
        ...others
    };

    selectProps.displayEmpty = false;

    if ( column.filter.andOrStyle ) {

        selectProps.style = column.filter.andOrStyle;

    }

    return (
        <FormControl className={className} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>
            <Select
                labelId={ id + "-label" }
                id={ id }
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        square: true
                    }
                }}
                { ...selectProps }
            >
                {
                    andOrItems.map ( ( item ) => <MenuItem key={item.name} value={ t ( item.value ) }>{item.name}</MenuItem> )
                }
            </Select>
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>     
    );

}

export default FilterFormItemAndOr;
