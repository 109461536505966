import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";

import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import EguIngredientRegistraionAutoBomInputButton from "../input/EguIngredientRegistraionAutoBomInputButton";

import layoutConf from "../../config/layoutConf";

import EguIngredientRegistrationCaption from "./EguIngredientRegistrationCaption";

function EguIngredientRegistration() {

    const [tableSchema, setTableSchema] = useState();

    const [height, setHeight] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const [onSelectMst, setOnSelectMst] = useState();


    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguIngredientRegistration");

            if (mounted) {
                setTableSchema(schema);
            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);


    const getData = useCallback((data) => {
        setOnSelectMst(data)
    }, [])

    useEffect(() => {

        if (api) {

            api.refresh();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst?.captionId]);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;

    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <EguIngredientRegistraionAutoBomInputButton
                            id="EguIngredientRegistration"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            selectMst={onSelectMst}
                            customizeErrorMsgCallback={doHandleBackendErrorMsg}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod={
                            tableSchema && (tableSchema.updateWithPatch ? "PATCH" : "PUT")
                        }
                    >
                        <UpdateRecordButton
                            id="EguIngredientRegistration"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="DELETE"
                    >
                        <DeleteRecordButton
                            id="EguIngredientRegistration"
                            schema={tableSchema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                        />
                    </AccessControl>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="SAVETOEXCEL"
                    >
                        <SaveToExcelButton
                            id="EguIngredientRegistration"
                            grid={grid}
                            view="EguVwIngredientRegistration"
                            color="primary"
                            disabled={isEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.35}>
                    <EguIngredientRegistrationCaption
                        getData={getData}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <BasicCrudScreenType
                        id="EguIngredientRegistration"
                        table="EguIngredientRegistration"
                        view="EguVwIngredientRegistration"
                        headerTitle={t("term.eguIngredientRegistration")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={onLoadData}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        onModifyViewUrl={(url) => url + '/' + onSelectMst?.captionId}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguIngredientRegistration;
