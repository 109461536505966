/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

import PopProductShipOfOrderBuyerShipDialog from "../dialogTypes/PopProductShipOfOrderBuyerShipDialog";

function PopProductShipOfOrderBuyerShipButton(props) {
    const { id, schema, api, materialSelect, onUpdateMaster, onSuccess, onFailure, modifyUrlCallback, ...others } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if(materialSelect) {
            setFormData({...materialSelect, shipDate: new Date()});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onAllShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }

        if (api) {
            api.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-all-ship-button"}
                onClick={onOpen}
                createButtonName={"allShip"}
                {...others}
            />
            <PopProductShipOfOrderBuyerShipDialog 
                id={id+"append-dialog"}
                schema={schema}
                open={open}
                formData={formData}
                onClose={onClose}
                onSuccess={onAllShipSuccess}
                modifyUrlCallback={modifyUrlCallback}
            />
        </>
    );
}

export default PopProductShipOfOrderBuyerShipButton;

