import { useEffect, useState } from "react";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";
import layoutConf from "../../config/layoutConf";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import ShipmentDetail from "./ShipmentDetail";

function Shipment (props) {


    const [ grid, setGrid ] = useState();

    const [ shipmentId, setShipmentId ] = useState();

    const [ height, setHeight ] = useState(0);

    const [ refresh, setRefresh ] = useState ();
    
    const [ shipmentRefresh, setShipmentRefresh ] = useState ();

    


    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);

    useEffect(() => {

        ( async () => {
            

            if (grid) {
    
                grid.selectionChanged.addHandler((grid, event) => {
                    setShipmentId(grid.selectedItems[0] && grid.selectedItems[0].shipmentId);
                    if ( refresh ) {
                        refresh.refresh ();
                    }
                });
    
                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if(grid.selectedItems[0]) {
                        setShipmentId(grid.selectedItems[0].shipmentId);
                        if ( refresh ) {
                            refresh.refresh ();
                        }
                    }
                });
            }

        } ) ()


    }, [grid, refresh] )


    const onInitialized = grid => {

        setGrid(grid);
    }

    const onApiInitialized = ( api ) => {

        setRefresh ( api );

    }

    const onShipmentApiInitialized = ( api ) => {

        setShipmentRefresh ( api );

    }

    return (
        <div style={{ height: height }} >

        <ReflexContainer orientation="horizontal">

            <ReflexElement flex={0.6}>

                <BasicCrudScreenType 
                    id="Shipment" 
                    table="Shipment" 
                    view="VwShipment" 
                    onInitialized={onInitialized}
                    onApiInitialized={onShipmentApiInitialized}
                    noEdit
                />

            </ReflexElement>

            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

            <ReflexElement>
          {shipmentId ?
           (
            <ShipmentDetail
              id="ShipmentDetail"
              shipmentId={shipmentId}
              onApiInitialized={onApiInitialized}
              shipmentRefresh = {shipmentRefresh}
            />
          ) 
          :
           (
            <></>
          )
          }
        </ReflexElement>
        </ReflexContainer>
    </div>
);
}

export default Shipment;