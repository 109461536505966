import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DefaultMachineCapacityManage ( props ) {

    return <BasicCrudScreenType id="DefaultMachineCapacity" table="DefaultMachineCapacity" view="VwDefaultMachineCapacity" noExcel />

}

export default DefaultMachineCapacityManage;

