/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import PopOutSourcingShipDoShipDialog from "../dialogTypes/PopOutSourcingShipDoShipDialog";

function PopOutSourcingShipDoShipButton(props) {
    const { id, selectedGrid, grid, refreshFtn, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if(grid) {
            setFormData({...selectedGrid, shipDate: new Date()});
        }
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onDoShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refreshFtn) {
            refreshFtn();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-all-ship-button"}
                onClick={onOpen}
                createButtonName={"allShip"}
                {...others}
            />
            <PopOutSourcingShipDoShipDialog 
                id={id+"append-dialog"}
                open={open}
                formData={formData}
                onClose={onClose}
                onSuccess={onDoShipSuccess}
            />
        </>
    );
}

export default PopOutSourcingShipDoShipButton;

