import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import PopDirectShipDetailCreateDialog from "../dialogTypes/PopDirectShipDetailCreateDialog";
import { useEffect } from "react";
import { cloneDeep } from "lodash";

function PopDirectShipDetailCreateButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, refresh, onClose, onSuccess, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, selectMst, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {

        let modifiedSchema = cloneDeep(schema);

        const idx = modifiedSchema.columns.findIndex(v => v.name === "barCodeExtLotNo");

        modifiedSchema.columns[idx].readonly = false;

        setModifiedSchema(modifiedSchema);

    }, [schema])

    const onOpen = () => {

        setOpen ( true );

    };

    const onDialogClose = () => {

        setOpen ( false );

        if ( onClose ) {

            onClose ();

        }

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <PopDirectShipDetailCreateDialog
                id={id+"create-dialog"}
                mode="create"
                formId={id+"create-form"}
                title={ schema ? t ( `dialog.title.${schema.name}.create` ) : "" }
                schema={modifiedSchema}
                initialData={undefined}
                open={open}
                onClose={onDialogClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                selectMst={selectMst}
            />
        </>

    );
}

export default PopDirectShipDetailCreateButton;

