import React from "react";

function FilterConditionPanelLayout ( props ) {

    const { show, children, ...others } = props;

    return (

        <div>
        {
            show
            ?
            React.Children.map(children, ( child => 
                React.cloneElement(child, others) ) )
            :
            <></>
        }
        </div>

    );

}

export default FilterConditionPanelLayout;

