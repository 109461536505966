/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectionView, SortDescription } from "@grapecity/wijmo";
import { cloneDeep } from "lodash";

import apiClient from "../../lib/common/apiClient";

function GetListApi ( props ) {

    const { t } = useTranslation();

    const {

        $filter,
        $orderBy,
        $start,
        $length,
        $search,
        $queries,
        table,
        onModifyUrl,
        onCountChange,
        onLoadData,
        onSuccess,
        onFailure,
        children,
        onInitialized,
        useTable,
        makeEmptyRow,
        collectionViewChange,

    } = props;

    const [data, setData] = useState();
    const [collectionView, setCollectionView] = useState();

    const [, setModifyUrl] = useState();

    useEffect ( () => {

        setModifyUrl ( () => ( url ) => {

            if (onModifyUrl) {

                return onModifyUrl(url);

            } else {

                return url;

            }

        });

    }, [onModifyUrl] );

    const buildQuery = ( props ) => {

        let first = true;
        let queryString = "";

        for ( const name in props ) {

            if ( name === "$queries" ) {

                continue;

            }

            if ( ! first ) {

                queryString += "&";

            }

            if ( props[name] === "" || props[name] === undefined || props[name] === null ) {

                continue;

            }

            first = false;

            queryString += name + "=" + props[name];

        }

        if ( props && props["$queries"] ) {

            let queries = props["$queries"];

            for ( const name in queries ) {

                if ( ! first ) {

                    queryString += "&";

                }

                if ( queries[name] === "" || queries[name] === undefined || queries[name] === null ) {

                    continue;

                }

                first = false;

                queryString += name + "=" + queries[name];

            }
        }

        return queryString;

    };

    const getData = ( query, mountStatus ) => {
        
        setModifyUrl ( ( modifyUrl ) => {

            if ( query.length > 0 ) {

                query = "?" + query;

            }

            let url = "/api/" + table;

            if ( modifyUrl ) {

                url = modifyUrl ( url ) + query;

            } else {

                url += query;

            }

            if ( ! table ) {

                return;

            }

            apiClient
                .get( url )
                .then( ( response ) => {

                    if  ( mountStatus.isMounted ) {

                        if ( onSuccess ) {

                            onSuccess ( response );

                        }
    
                        if ( onCountChange ) {

                            onCountChange ( response.data.filtered );

                        }
    
                        if ( children ) {
    
                            if ( onLoadData ) {

                                onLoadData ( response.data.data );

                            }
    
                            if ( useTable ) {
    
                                let data = cloneDeep ( response.data.data );
    
                                if ( makeEmptyRow ) {
    
                                    if ( data.length < $length ) {
    
                                        let emptyCount = $length - data.length;
    
                                        for ( let i = 0; i < emptyCount; i++ ) {
    
                                            data.push ( makeEmptyRow ( i ) );
    
                                        }
                                    }
    
                                }
    
                                setData ( data );
    
                            } else {
    
                                let collectionView = new CollectionView ( response.data.data );
                                let orderBy = JSON.parse ( $orderBy );
        
                                for ( const sortOrder of orderBy ) {

                                    let sortDescription = new SortDescription ( sortOrder.column, sortOrder.order === "ASC" );
                                    collectionView.sortDescriptions.push ( sortDescription );

                                }

                                if (collectionViewChange) {

                                    collectionViewChange(collectionView)

                                }
                                
                                setCollectionView ( collectionView );
        
                            }
    
                        }

                        try {

                            if ( JSON.parse ( $filter ).length > 0 || $search ) {

                                if ( response.data.data.length === 0 ) {
    
                                    if ( onFailure ) {
    
                                        onFailure ( t( "infoMsg.noData" ) );
                                    }
    
                                }
                            }
    
                        } catch ( reason ) {

                            // Do nothing

                        }

                    }

                } )
                .catch ( ( reason ) => {

                    if ( mountStatus.isMounted && onFailure ) {

                        onFailure ( reason );

                    }
                    
                } );

            return modifyUrl;

        } );

    };

    useEffect ( () => {

        let mountStatus = {
            isMounted: true,
        };

        let myInstance = {

            refresh: () => {

                getData ( query, mountStatus );

            },

        };

        let query = buildQuery ( {

            $filter,
            $orderBy,
            $start,
            $length,
            $search,
            $queries,

        } );

        getData ( query, mountStatus );

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

        return ( () => {

            mountStatus.isMounted = false;

        } );

    }, [ $filter, $orderBy, $start, $length, $search, $queries ] );

    return (
        
        <>
            {

                children
                ?
                (
                    React.Children.map ( children, ( child ) =>
                        React.isValidElement ( child )
                        ?
                        React.cloneElement (

                            child,

                            {

                                itemsSource: collectionView,
                                data: data,

                            }

                        )
                        :
                        child
                    )
                )
                : 
                <></>

            }
        </>

    );

}

export default GetListApi;
