import { DialogTitle } from "@material-ui/core";

function MaterialDialogTitle ( props ) {

    return <DialogTitle disableTypography {...props} />

}

export default MaterialDialogTitle;

