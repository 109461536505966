/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";

function ApplyStockTakingDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState([]);
    const [disabled, setDisabled] = useState ( false );
    const [,setGrid] = useState();
    
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const {
        
        id,
        schema,
        selectedItems,

        moveable,
        resizable,
        fullWidth,
        maxWidth,
        
        initialData,
        
        onClose,
        onInitialized,
        onSuccess,
        
        ...others
        
    } = props;

    useEffect(()=>{

        setFormData({
            description : initialData?.description
        })

    },[initialData])

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            if(column.name === "description") {

                let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );
    
                if ( !valid ) {
    
                    nofifyWarning ( msg );
                    return false;
    
                }
                
            }

        }

        return true;

    }
    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.updateSuccess", { table: "MomSysStockTakingMst" } ) );

        }

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }
        setDisabled(false);
        instance.hide();

    };

    const onApiFailure = (reason) => {

        setDisabled(false);
        notifyFailure ( t ( "error.stockTakingApply", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, formData ) => {

        setDisabled(true);

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = `/api/Material/${selectedItems?.stockTakingId}/adjust`;
        // let url = `/api/MomSysUglStockTakingMst/${selectedItems?.stockTakingId}/adjust`;

        let apiData = { ...formData };
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {

                deleted[formItem] = apiData[formItem].deletedFiles;
                    
                for ( const file of apiData[formItem].activeFiles ) {

                    if ( file.type === "url" ) continue;

                    form.append ( formItem, file.file );

                }

            } else {

                if ( apiData[formItem] !== undefined ) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        instance.hide();

    };

    const onChange = ( formData ) => {

        setFormData ( formData );

        setDisabled ( false );

    }

    const onGridInitialized = ( grid ) => {
        
        grid.selectionMode = "None";

        setGrid(grid);
    
    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        /* setDisabled ( true ); */

        if ( onClose ) {

            onClose ();

        }
    }

    const onModifyViewUrl = (url)=> {
        let newUrl = `${url}/material/${selectedItems.stockTakingId}`        

        return newUrl;
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.MomSysLogAdjust.applyStockTaking" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "400px"}}>

                <ReflexContainer orientation="horizontal">

                    <ReflexElement flex={0.75} data-cy="ApplyStock" >

                        <BasicCrudScreenType
                            id="MomSysZvwStockTakingDetail"
                            embededInOtherScreenType
                            headerTitle={t("term.quantityMaterialList")}
                            view="MomSysZvwStockTakingDetail"
                            noExcel
                            noFilter
                            onInitialized={onGridInitialized}
                            onModifyViewUrl = {onModifyViewUrl}

                        />

                    </ReflexElement>
                    
                    <ReflexSplitter
                        style={{height: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.25} >

                        <AutoForm
                            id="applyStockTaking-form"
                            schema={schema}
                            mode="edit"
                            initialData={initialData}
                            onChange={onChange}
                        />


                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("common.add")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default ApplyStockTakingDialog;
