import { useState, useEffect } from "react";

import AutoFilterFormItem from "./AutoFilterFormItem";
import FilterFormItemCondition from "../input/FilterFormItemCondition";
import FilterFormItemAndOr from "../input/FilterFormItemAndOr";
import AutoFilterFormGenericLayout from "../layout/AutoFilterFormGenericLayout";
import AutoFilterFormValueItemLayout from "../layout/AutoFilterFormValueItemLayout";
import AutoFilterFormValueConditionItemLayout from "../layout/AutoFilterFormValueConditionItemLayout";
import FilterFormItemAndOrLayout from "../layout/FilterFormItemAndOrLayout";
import { useTranslation } from "react-i18next";

function AutoFilterFormGeneric ( props ) {

    const { t } = useTranslation ();

    const { id, tabIndex, column, filterValue, onChange } = props;

    const [ filter, setFilter ] = useState (
     
        filterValue
        ?
        {...filterValue}
        :
        {

            binding: column.name,
            type: "condition",
            and: true,
            condition1: { operator: "", value: "" },
            condition2: { operator: "", value: "" }
        
        }
        
    );

    useEffect ( () => {

        if ( filterValue ) {

            setFilter ( filterValue );

        }

    }, [filterValue] );

    const onChangeCondition1Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition1Operator = ( condition ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition1.operator = condition;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeAndOr = ( and ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.and = and;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    }

    const onChangeCondition2Value = ( value ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.value = value;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    const onChangeCondition2Operator = ( condition ) => {

        setFilter ( ( filter ) => {
            
            let newFilter = {...filter };

            newFilter.condition2.operator = condition;

            if ( onChange ) {

                setTimeout ( () => onChange ( newFilter ), 0 );

            }

            return newFilter;

        } );

    };

    return (

        <AutoFilterFormGenericLayout column={column}>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem                    
                    id={id+"-condition1-value"}
                    column={column}
                    value={filter.condition1.value}
                    autoFocus={tabIndex===1}
                    onChange={onChangeCondition1Value}
                />

            </AutoFilterFormValueItemLayout>
            
            <AutoFilterFormValueConditionItemLayout column={column} >

                <FilterFormItemCondition
                    id={id+"-condition1-operator"}
                    column={column}
                    label={t("dataTableFilter.inputTitle.filterOpr")}
                    value={filter.condition1.operator}
                    onChange={onChangeCondition1Operator}
                />

            </AutoFilterFormValueConditionItemLayout>

            <FilterFormItemAndOrLayout column={column}>

                <FilterFormItemAndOr                    
                    id={id+"-and"}
                    column={column}
                    label={t("dataTableFilter.inputTitle.filterAndOr")}
                    onChange={onChangeAndOr}
                />
                
            </FilterFormItemAndOrLayout>

            <AutoFilterFormValueItemLayout column={column} >

                <AutoFilterFormItem
                    id={id+"-condition2-value"}
                    column={column}
                    value={filter.condition2.value}
                    onChange={onChangeCondition2Value}
                />

            </AutoFilterFormValueItemLayout>
            
            <AutoFilterFormValueConditionItemLayout column={column} >

                <FilterFormItemCondition
                    id={id+"-condition2-operator"}
                    column={column}
                    label={t("dataTableFilter.inputTitle.filterOpr2")}
                    value={filter.condition2.operator}
                    onChange={onChangeCondition2Operator}
                />

            </AutoFilterFormValueConditionItemLayout>

        </AutoFilterFormGenericLayout>


    );

}

export default AutoFilterFormGeneric;

