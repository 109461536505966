import apiClient from "../common/apiClient";

async function login( username, password, tenant ) {

    let response = await apiClient.post("/auth/login", {
        username: username,
        password: password,
        tenant: tenant
    });

    return response.data;

}

export default login;
