/* eslint-disable react-hooks/exhaustive-deps */
import { gantt } from "dhtmlx-gantt";
import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import '../layout/Gantt.css';

import Avatar from "../../presentation/Avatar";
import GanttWorkerColumn from "./GanttWorkerColumn";
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import getNewDateObj from "../../../lib/common/getNewDateObj";

function GanttChart ( props ) {

    const { t } = useTranslation ();

    const {

        id,

        planData,

        $search,

        hideResourceView,
        
        ordered,
        completed,        
        resource,

        onInitialized,
        onTaskUpdate,
        onTaskSelected,
        onTaskUnselected,
        onLinkAdd,
        onLinkUpdate,
        onContextMenu,
        onMakeTaskTooltipText,
        onGanttReady,
        
        onError,
            
    } = props;

    const [ , setTodayMarker ] = useState ();

    let events = [];

    useEffect ( () => {
        
        gantt.clearAll ();

        let intervalTimer = setInterval ( () => {

            setTodayMarker ( ( todayMarker ) => {

                if ( todayMarker ) {

                    let today = gantt.getMarker ( todayMarker );

                    if ( today ) {

                        today.start_date = new Date ();

                        today.start_date.setHours(0);
                        today.start_date.setMinutes(0);
                        today.start_date.setSeconds(0);
                        today.start_date.setMilliseconds(0);
            
                        gantt.updateMarker ( todayMarker );

                    }

                }

                return todayMarker

            } );

        }, 1000 * 60 )

        return () => {

            clearInterval ( intervalTimer );

        }

    }, [] );

    useEffect ( () => {

        try {

            gantt.$search = $search;

            gantt.render ();

            // 전체 검색시 선택위치로 이동시킨다.
            if ($search === '' ) {
                let selectedTaskId = gantt.getState ().selected_task;
                                    
                if ( gantt.isTaskExists ( selectedTaskId ) ) { 
                    let task = gantt.getTask ( selectedTaskId );

                    let taskPositionTop = 0

                    if (task) {
                        let orderTime = getNewDateObj (task.orderTime);
                        taskPositionTop = gantt.getTaskPosition(task, orderTime).top;
                    }
                        
                    if (taskPositionTop < 40) gantt.scrollTo(null, taskPositionTop )
                    else  gantt.scrollTo(null, taskPositionTop - 40 )
                }
            }
        } catch ( error ) {

        }

    }, [$search] );

    useEffect ( () => {

        try {

            gantt.$ordered = ordered;
            gantt.render ();
            gantt.scrollTo ( null, 1 );
    
        } catch ( reason ) {

        }

    }, [ordered] );

    useEffect ( () => {

        try {

            gantt.$completed = completed;
            gantt.render ();
            gantt.scrollTo ( null, 1 );
    
        } catch ( reason ) {

        }
        
    }, [completed] );

    useEffect ( () => {
    
        if ( resource ) {

            let haveParent = planData.resourceMap[resource]?.parent;

            if ( haveParent ) {

                gantt.$resourceSelection = resource;
    
            } else {
    
                gantt.$resourceSelection = null;
    
            }
    
        } else {

            gantt.$resourceSelection = null;

        }

        try {

            gantt.render ();
            gantt.scrollTo ( null, 1 );


        } catch ( reason ) {
    
        }

    }, [resource] );

    useEffect ( () => {

        if ( planData ) {
            
            gantt.clearAll ();
    
            gantt.plugins ( {
    
                keyboard_navigation: true,
                tooltip: true,
                grouping: true,
                auto_scheduling: true,
                marker: true,
    
            } );
        
            function shouldHighlightTask ( task ) {

                if ( task.type !== 'task' ) {

                    return false;

                }

                let store = gantt.$resourcesStore;

                let selectedResource = parseInt ( store.getSelectedId () );

                if ( !selectedResource ) {

                    return false;

                }

                let resources = []

                for ( let index in task.resources ) {

                    let resource = task.resources[index];
                    resources.push ( resource.resource_id );

                }

                if ( resources.includes ( selectedResource ) ) {

                    return true;

                }

                return false;
    
            }
    
            function shouldHighlightResource ( resource ) {
    
                let selectedTaskId = gantt.getState ().selected_task;
    
                if ( gantt.isTaskExists ( selectedTaskId ) ) {
    
                    let task = gantt.getTask ( selectedTaskId );
    
                    for ( let index in task.resources ) {

                        if ( resource.id === task.resources[index].resource_id ) {
    
                            return true;
        
                        }
    
                    }
    
                }
    
                return false;
    
            }
    
            // get capacity of resource at given date
    
            function getResourceCapacity ( date, resource ) {
    
                let key;
                let holiday;
                let capacity;
    
                switch ( resource.parent ) {
    
                    case 1: // Worker
    
                        if ( resource.id === 4 ) {
                            return 0;
                        }
    
                        key = `${resource.username}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                        capacity = planData.workerDateCapacityMap[key];
    
                        if ( capacity !== undefined ) {
                            return capacity;
                        }
    
                        key = `${date.getMonth() + 1}-${date.getDate()}`;
    
                        holiday = planData.holidayMap[key];
    
                        if ( holiday ) {
                            return 0;
                        }
    
                        holiday = planData.recurringHolidayMap[key];
    
                        if ( holiday ) {
                            return 0;
                        }
    
                        key = `${resource.username}-${date.getDay()}`;
                        capacity = planData.workerDayOfWeekCapacityMap[key];
    
                        if ( capacity !== undefined ) {
                            return capacity;
                        }
    
                        key = `${date.getDay()}`;
                        capacity = planData.defaultWorkerDayOfWeekCapacityMap[key];
    
                        if ( capacity !== undefined ) {
                            return capacity;
                        }

                        if ( ! planData.workdays.includes ( date.getDay () ) ) {
                            return 0;
                        }

                        return planData.defaultCapacity;
    
                    case 2: // Line No
    
                        if ( resource.id === 5 ) {    
                            return 0;
                        }
    
                        key = `${resource.lineNo}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                        capacity = planData.lineNoDateCapacityMap[key];
    
                        if ( capacity !== undefined ) {
                            return capacity;
                        }
    
                        if ( ! planData.workdays.includes ( date.getDay () ) ) {
                            return 0;
                        }

                        key = `${resource.lineNo}`;
                        capacity = planData.defaultLineNoCapacityMap[key];
    
                        if ( capacity ) {
                            return capacity;
                        }
    
                        return planData.defaultCapacity;
    
                    case 3: // Machine
    
                        if ( resource.id === 6 ) {
                            return 0;
                        }
    
                        key = `${resource.machineCode}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                        capacity = planData.machineDateCapacityMap[key];
    
                        if ( capacity ) {
                            return capacity;
                        }
    
                        if ( ! planData.workdays.includes ( date.getDay () ) ) {
                            return 0;
                        }

                        key = `${resource.machineCode}`;
                        capacity = planData.defaultMachineCapacityMap[key];
    
                        if ( capacity ) {
                            return capacity;
                        }
                        
                        return planData.defaultCapacity;
    
                    default:
    
                        break;
    
                }
    
                return planData.defaultCapacity;
    
            }
    
            // get sum of resource capacity for given resource
    
            function getTotalCapacityForSingleResource ( resource ) {
    
                let totalCapacity = 0;
                var state = gantt.getState ();
    
                for ( let time = state.min_date.getTime(); time < state.max_date.getTime(); time += ( 24 * 60 * 60 * 1000 ) ) {
    
                    let date = getNewDateObj ( time );
    
                    let capacity = getResourceCapacity ( date, resource );
    
                    totalCapacity += capacity;
    
                }
    
                return totalCapacity;
    
            }
    
            function getTotalResourceCapacity ( resource ) {
    
                let totalCapacity = 0;
    
                if ( resource.parent ) {
    
                    totalCapacity = getTotalCapacityForSingleResource ( resource );
    
                } else {
    
                    let resources = gantt.$resourcesStore.getItems ().filter ( item => item.parent === resource.id );
    
                    for ( let resource of resources ) {
    
                        let capacity = getTotalCapacityForSingleResource ( resource );
                        totalCapacity += capacity;
    
                    }
    
                }
    
                return totalCapacity;
    
            }
    
            // get allocated resource value at given date
    
            function getResourceAllocation ( tasks, date, resource ) {

                let result = 0;
    
                let assignments = gantt.getResourceAssignments ( resource.id );

                assignments.forEach ( function ( assignment ) {

                    let task = planData.planMap[assignment.task_id];

                    tasks.forEach ( function ( item ) {

                        if ( item.id === task.id ) {
                                            
                            let lastDay = new Date ( task.end_date.getTime() - 24 * 60 * 60 * 1000 );

                            if ( date.getTime() === lastDay.getTime() ) {

                                if ( task.duration === 1 ) {

                                    result += task.durationInHours;

                                } else {

                                    result += ( task.durationInHours % ( task.dailyWorkTime ?? 8 ) ) ? ( task.durationInHours % ( task.dailyWorkTime ?? 8 ) ) : ( task.dailyWorkTime ?? 8 );

                                }


                            } else {
                                
                                result += 8;

                            }
    
                        }

                    } );

                } );

                return result;
    
            }
    
            // get sum of resource allocation for given resource
    
            function getTotalResourcePlannedForSingleResource ( resource ) {
    
                let totalPlanned = 0;
        
                let assignments = gantt.getResourceAssignments ( resource.id );

                assignments.forEach ( function ( assignment ) {

                    let task = planData.planMap[assignment.task_id];
                    totalPlanned += task.durationInHours;

                } );

                return totalPlanned;
    
            }
    
            function getTotalResourcePlanned ( resource ) {
    
                let totalPlanned = 0;
    
                if ( resource.parent ) {
    
                    totalPlanned = getTotalResourcePlannedForSingleResource ( resource );
    
                } else {
    
                    let resources = gantt.$resourcesStore.getItems ().filter ( item => item.parent === resource.id );
    
                    for ( let resource of resources ) {
    
                        let planned = getTotalResourcePlannedForSingleResource ( resource );
                        totalPlanned += planned;
    
                    }
    
                }
    
                return totalPlanned;
    
            }
    
            // get sum of work result for given resource
    
            function getTotalResourceWorkTimeForSingleResource ( resource ) {
    
                let totalWorkTime = 0;
    
                let assignments = gantt.getResourceAssignments ( resource.id );
    
                assignments.forEach ( function ( assignment ) {
    
                    let task = gantt.getTask ( assignment.task_id );
    
                    totalWorkTime += ( task.workTime / 60 / 60 );
        
                } );
    
                return totalWorkTime;
    
            }
    
            function getTotalResourceWorkTime ( resource ) {
    
                let totalWorkTime = 0;
    
                if ( resource.parent ) {
    
                    totalWorkTime = getTotalResourceWorkTimeForSingleResource ( resource );
    
                } else {
    
                    let resources = gantt.$resourcesStore.getItems ().filter ( item => item.parent === resource.id );
    
                    for ( let resource of resources ) {
    
                        let result = getTotalResourceWorkTimeForSingleResource ( resource );
                        totalWorkTime += result;
    
                    }
    
                }
    
                return totalWorkTime;
    
            }


            gantt.templates.task_class  = function(start, end, task) {
                
                switch (task.type){

                    case "project":

                        if (task.dueDate) {

                            let dueDate = getNewDateObj ( task.dueDate );
                            let endTime = task.end_date;

                            if ( task.endTime ) {

                                endTime = getNewDateObj ( task.endTime );

                            } else {

                                endTime = new Date ();

                            }

                            if ( dueDate && ( task.end_date > dueDate || endTime > dueDate ) ) {


                                return "project-overdue";

                            }

                        }
                            
                        return "";

                    case "task":

                        if ( ! parseInt ( task.ordered ) ) {
                            return "task-not-ordered";
                        }

                        if ( ! task.actualStartTime ) {
                            return "task-not-started";                    
                        }

                        return "task-ordered";

                    default:

                        return "";
                }

            };


            gantt.templates.grid_row_class = function ( start, end, task ) {

                var css = [];
    
                if ( gantt.hasChild ( task.id ) ) {
    
                    css.push ( "folder_row" );
    
                }
    
                if ( task.$virtual ) {
    
                    css.push ( "group_row" )
    
                }
    
                if ( shouldHighlightTask ( task ) ) {
    
                    css.push ( "highlighted_resource" );
    
                }
    
                return css.join ( " " );
    
            };
    
            gantt.templates.task_row_class = function ( start, end, task ) {
    
                if ( shouldHighlightTask ( task ) ) {
    
                    return "highlighted_resource";
    
                }
    
                return "";
    
            };
    
            gantt.templates.timeline_cell_class = function ( task, date ) {
    
                if ( ! gantt.isWorkTime ( { date: date, task: task } ) )
                    return "week_end";
    
                return "";
    
            };
    
            gantt.templates.histogram_cell_class = function ( start_date, end_date, resource, tasks ) {
    
                if ( getResourceAllocation(tasks, start_date, resource) > getResourceCapacity (start_date, resource) ) {
    
                    return "column_overload";
    
                }

                return "";
    
            };
    
            gantt.templates.histogram_cell_label = function ( start_date, end_date, resource, tasks ) {

                if ( tasks.length && !gantt.$resourcesStore.hasChild ( resource.id ) ) {

                    if ( [4,5,6].includes ( resource.id ) ) {

                        return getResourceAllocation(tasks, start_date, resource);

                    } else {
    
                        return getResourceAllocation(tasks, start_date, resource) + "/" + getResourceCapacity (start_date, resource);

                    }
    
                } else {
    
                    if ( !gantt.$resourcesStore.hasChild ( resource.id ) ) {
                       
                        return '&ndash;';
    
                    }
    
                    return '';
    
                }
    
            };
    
            gantt.templates.histogram_cell_allocated = function ( start_date, end_date, resource, tasks ) {
    
                let allocation = getResourceAllocation ( tasks, start_date, resource );

                return allocation;
    
            };
    
            gantt.templates.histogram_cell_capacity = function ( start_date, end_date, resource, tasks ) {
    
                return ( getResourceCapacity ( start_date, resource ) );
    
            };

            if ( onMakeTaskTooltipText ) {

                gantt.templates.tooltip_text = onMakeTaskTooltipText;

            }
    
            var resourceTemplates = {
    
                grid_row_class: function ( start, end, resource ) {
    
                    var css = [];
    
                    if ( gantt.$resourcesStore.hasChild ( resource.id ) ) {
    
                        css.push ( "folder_row" );                    
                        css.push ( "group_row" );
    
                    }
    
                    if ( shouldHighlightResource ( resource ) ) {
    
                        css.push ( "highlighted_resource" );
    
                    }
    
                    return css.join ( " " );
    
                },
    
                task_row_class: function ( start, end, resource ) {
    
                    var css = [];
    
                    if ( shouldHighlightResource ( resource ) ) {
    
                        css.push ( "highlighted_resource" );
    
                    }
    
                    if ( gantt.$resourcesStore.hasChild ( resource.id ) ) {
    
                        css.push ( "group_row" );
    
                    }
    
                    return css.join ( " " );
    
                }
    
            };

            gantt.config.keyboard_navigation = true;

            gantt.config.fit_tasks = true;
            
            gantt.config.min_grid_column_width = 0;
            
            gantt.config.show_grid = true;//planData.showGrid;
            gantt.config.open_split_tasks = planData.openSplitTask;

            gantt.config.start_date = planData.startDate;
            gantt.config.end_date = planData.endDate;

            gantt.config.date_format = "%Y-%m-%d %H:%i:%s";
            gantt.config.resource_render_empty_cells = true;

            gantt.config.external_render = { 
                // checks the element is a React element
                isElement: (element) => {
                    return React.isValidElement(element);
                },
                // renders the React element into the DOM
                renderElement: (element, container) => {
                    ReactDOM.render(element, container);
                }
            };
    
            var resourceConfig = {
    
                scale_height: 40,
                row_height: 40,
    
                scales: planData.scales,
    
                columns: [
    
                    {
    
                        name: "name",
                        label: t ( "term.resource"),
                        tree:true,
                        width:150,
                        template: function ( resource ) {

                            return resource.text ? ( resource.text.length > 0 ? resource.text : resource.username ) : resource.username ;
    
                        },
                        resize: true
    
                    },
                    {
    
                        name: "avatar",
                        label: "",
                        align:"center",
                        width: 40,
                        resize: true,
                        onrender: ( resource ) => (
                            resource.parent === 1
                            ?
                            <Avatar
                                photo={resource.photo}
                                email={resource.email}
                                className="owner-label"
                                style={{
                                    display: "inline-block",
                                    marginTop: "9px",
                                    height: "25px",
                                    width: "25px"
                                }}
                            />
                            :
                            <></>
                        )

                    },
                    {
                        
                        name: "capacity",
                        label: t ( "term.capacity" ),
                        align:"center",
                        template: function ( resource ) {

                            return parseFloat ( getTotalResourceCapacity ( resource ).toFixed(2) ) || "";
    
                        }
    
                    },
                    {
    
                        name: "workload",
                        label: t ( "term.workload" ),
                        align:"center",
                        template: function ( resource ) {
    
                            return parseFloat ( getTotalResourcePlanned ( resource ).toFixed(2) ) || "";
    
                        },
                        resize: true
    
                    },
                    {
    
                        name: "worktime",
                        label: t ( "term.worktime" ),
                        align:"center",
                        template: function ( resource ) {
    
                            return parseFloat ( getTotalResourceWorkTime ( resource ).toFixed(2) ) || "";
    
                        },
                        resize: true
    
                    },
    
                ]
            };
    
            gantt.config.scales = planData.scales;

            gantt.config.auto_scheduling = true;
            gantt.config.auto_scheduling_compatibility = true;
            gantt.config.auto_scheduling_descendant_links = true;
            gantt.config.auto_scheduling_strict = false;
            gantt.config.auto_scheduling_initial = true;
            
            gantt.config.work_time = true;
            gantt.config.drag_move = true;
            gantt.config.drag_progress = false;
            gantt.config.drag_project = true;

            for ( let day = 0; day < 7; day++ ) {

                if ( planData.workdays.includes ( day ) ) {

                    gantt.setWorkTime ( { day } );

                } else {

                    gantt.setWorkTime ( { day, hours: false } );

                }

            }
        
            for ( let index in planData.holiday ) {
    
                let holiday = planData.holiday[index];
                let date = getNewDateObj ( holiday );

                gantt.setWorkTime ( { date, hours: false } );
    
            }
        
            for ( let holiday in planData.recurringHolidayMap ) {
    
                let [month, date] = holiday.split ( "-" );

                let holidayDate;
                
                holidayDate = getNewDateObj ( planData.startDate );

                holidayDate.setMonth ( month - 1 );
                holidayDate.setDate ( date );

                gantt.setWorkTime ( { date:  new Date ( holidayDate.getFullYear(), holidayDate.getMonth(), holidayDate.getDate () ), hours: false } ) ;
                
                holidayDate = getNewDateObj ( planData.endDate );

                holidayDate.setMonth ( month - 1 );
                holidayDate.setDate ( date );

                gantt.setWorkTime ( { date:  new Date ( holidayDate.getFullYear(), holidayDate.getMonth(), holidayDate.getDate () ), hours: false } ) ;
    
            }

            for ( let index in planData.additionalWorkDay ) {
    
                let workDay = planData.additionalWorkDay[index];
                let date = getNewDateObj ( workDay );

                gantt.setWorkTime ( { date } );
    
            }

            gantt.config.columns = [
                {
                    name: "overdue",
                    label: "",
                    align: "center",
                    width: planData.showGrid ? 24 : 1,
                    template: function (task) {

                        if ( task.dueDate ) {

                            let dueDate = getNewDateObj ( task.dueDate );
                            let endTime = task.end_date;

                            if ( parseInt ( task.completed ) ) {

                                endTime = getNewDateObj ( task.endTime );

                            } else {

                                endTime = new Date ();

                            }

                            if ( dueDate && ( task.end_date > dueDate || endTime > dueDate ) ) {

                                return '<div class="overdue-indicator">!</div>';

                            }

                        }

                        return '<div></div>';

                    }
                },                
                {
                    name: "taskName",
                    label: t ( "term.taskName" ),
                    tree: true,
                    width: planData.showGrid ? 300 : 1,
                    resize: true,
                    template: function (task) {
                        return task.taskName;
                    }
                },
                {
                    name: "durationInHours",
                    label: t ( "term.durationInHours" ),
                    width: planData.showGrid ? 60 : 1,
                    align: "center",
                    resize: true,
                    template: function (task) {
                        return task.durationInHours;
                    }
                },
                {
                    name: "workers",
                    align: "center",
                    width: planData.showGrid ? 150 : 1,
                    label: t ( "term.worker" ),
                    resize: true,
                    onrender: ( item ) => <GanttWorkerColumn gantt={gantt} task={item} />
                },
                {
                    name: "ordered",
                    align: "center",
                    width: planData.showGrid ? 80 : 1,
                    label: t ( "term.ordered" ),
                    resize: true,
                    template: function (task) {

                        if ( task.type === "milestone" ) {
                            return "";
                        }

                        return parseInt ( task.orderCompleted ) ? t ( "term.ordered" ) : ( parseInt ( task.ordered ) ? t ( "term.partialOrdered" ) : t ( "term.notOrdered" ) );

                    }
                },
                {
                    name: "completed",
                    align: "center",
                    width: planData.showGrid ? 80 : 1,
                    label: t ( "term.completed" ),
                    resize: true,
                    template: function (task) {

                        if ( task.type === "milestone" ) {
                            return "";
                        }

                        return parseInt ( task.started ) ? ( parseInt ( task.completed ) ? t ( "term.completed" ) : t ( "term.notCompleted" ) ) : t ( "term.notStarted" );
                        
                    }
                }
            ];
    
            gantt.config.resource_store = "resource";
            gantt.config.resource_property = "resources";
            gantt.config.order_branch = true;
            gantt.config.open_tree_initially = true;
            gantt.config.scale_height = 40;
            gantt.config.row_height = 40;
            gantt.config.layout = hideResourceView ?
            {
    
                css: "gantt_container",
                rows: [
    
                    {
    
                        gravity: 2,
                        cols: [
    
                            {
                                view: "grid",
                                group:"grids",
                                scrollY: "scrollVer"
    
                            },
                            {
    
                                resizer: true,
                                width: 1
    
                            },
                            {
    
                                view: "timeline",
                                scrollX: "scrollHor",
                                scrollY: "scrollVer"
    
                            },
                            {
                                
                                view: "scrollbar",
                                id: "scrollVer",
                                group:"vertical"
                            
                            }
    
                        ]
    
                    },
                    {
    
                        view: "scrollbar",
                        id: "scrollHor"
    
                    }
    
                ]
    
            }
            :
            {
    
                css: "gantt_container",
                rows: [
    
                    {
    
                        gravity: 2,
                        cols: [
    
                            {
                                view: "grid",
                                group:"grids",
                                scrollY: "scrollVer"
    
                            },
                            {
    
                                resizer: true,
                                width: 1
    
                            },
                            {
    
                                view: "timeline",
                                scrollX: "scrollHor",
                                scrollY: "scrollVer"
    
                            },
                            {
                                
                                view: "scrollbar",
                                id: "scrollVer",
                                group:"vertical"
                            
                            }
    
                        ]
    
                    },
                    {
                        
                        resizer: true,
                        width: 1,
                        next: "resources"
                    
                    },
                    {
    
                        gravity:1,
                        id: "resources",
                        config: resourceConfig,
                        templates: resourceTemplates,
                        cols: planData.showGrid
                            ?
                            [
        
                                {
                                    view: "resourceGrid",
                                    group:"grids",
                                    scrollY: "resourceVScroll"
        
                                },
                                {
        
                                    resizer: true,
                                    width: 1
        
                                },
                                {
                                    
                                    view: "resourceHistogram",
                                    capacity:planData.maxResourceHistorygramCapacity || 24,
                                    scrollX: "scrollHor",
                                    scrollY: "resourceVScroll"
                                
                                },
                                {
                                    
                                    view: "scrollbar",
                                    id: "resourceVScroll",
                                    group: "vertical"
                                
                                }
        
                            ]
                            :
                            [
                                {
                                    view: "resourceGrid",
                                    group:"grids",
                                    scrollY: "resourceVScroll"
        
                                },
                                {
                                    
                                    view: "resourceHistogram",
                                    capacity:planData.maxResourceHistorygramCapacity || 24,
                                    scrollX: "scrollHor",
                                    scrollY: "resourceVScroll"
                                
                                },
                                {
                                    
                                    view: "scrollbar",
                                    id: "resourceVScroll",
                                    group: "vertical"
                                
                                }
        
                            ]                            
                    },
                    {
    
                        view: "scrollbar",
                        id: "scrollHor"
    
                    }
    
                ]
    
            };
    
            gantt.$resourcesStore = gantt.createDatastore ( {
    
                name: gantt.config.resource_store,
                type: "treeDatastore",
                initItem: function ( item ) {
    
                    item.parent = item.parent || gantt.config.root_id;
                    item[gantt.config.resource_property] = item.parent;
                    item.open = true;
    
                    return item;
    
                }
    
            } );

            events.push ( gantt.$resourcesStore.attachEvent ( "onAfterSelect", function ( id ) {

                gantt.refreshData ();

            } ) );;
                
            let today = new Date();

            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);

			let todayMarker = gantt.addMarker({
				start_date: today,
				css: "today",
				text: t("common.today"),
				title: t("common.today")
			});

            setTodayMarker ( todayMarker );

            gantt.i18n.setLocale( i18n.language );
            gantt.init ( id );

            gantt.addTaskLayer({

                renderer: {

                    render: function draw_orderTime(task) {

                        if ( task.type === 'task' && task.orderTime ) {

                            let orderTime = getNewDateObj (task.orderTime);

                            var el = document.createElement('div');

                            el.className = 'orderTime';

                            var sizes = gantt.getTaskPosition(task, orderTime);
        
                            el.style.left = sizes.left + 'px';
                            el.style.top = sizes.top + 'px';

                            el.setAttribute('title', `${t("columnName.orderTime")}: ${orderTime.getFullYear()}-${orderTime.getMonth() + 1}-${orderTime.getDate()} ${orderTime.getHours()}:${orderTime.getMinutes()}`);

                            return el;

                        }

                        return false;

                    },

                    getRectangle: function(task, view){

                        if ( task.orderTime ) {

                            let orderTime = getNewDateObj (task.orderTime);
                            return gantt.getTaskPosition(task, orderTime);
                            
                        }

                        return null;

                    }

                }

            });

            gantt.addTaskLayer({

                renderer: {

                    render: function draw_deadline(task) {

                        if ( task.type === 'project' && task.dueDate ) {

                            let dueDate = getNewDateObj (task.dueDate);
                            dueDate.setHours ( 0, 0, 0, 0 );

                            var el = document.createElement('div');

                            el.className = 'deadline';

                            var sizes = gantt.getTaskPosition(task, dueDate);
        
                            el.style.left = sizes.left + 'px';
                            el.style.top = sizes.top + 'px';

                            el.setAttribute('title', `${t("columnName.dueDate")}: ${dueDate.getFullYear()}-${dueDate.getMonth() + 1}-${dueDate.getDate()}`);

                            return el;

                        }

                        return false;

                    },

                    getRectangle: function(task, view){

                        if ( task.dueDate ) {

                            let dueDate = getNewDateObj (task.dueDate);
                            return gantt.getTaskPosition(task, dueDate);
                            
                        }

                        return null;

                    }

                }

            });

            gantt.addTaskLayer({

                renderer: {

                    render: function draw_actual_start_date(task) {

                        if (task.type === 'task' && parseInt ( task.started ) ) {

                            let actualStartTime = getNewDateObj ( task.actualStartTime );

                            let el = document.createElement('div');
                            el.className = 'actual-start-date';
                            let sizes = gantt.getTaskPosition(task, actualStartTime);
        
                            el.style.left = sizes.left + 'px';
                            el.style.top = sizes.top + 'px';

                            el.setAttribute('title', `${t("columnName.startTime")}: ${actualStartTime.getFullYear()}-${actualStartTime.getMonth() + 1}-${actualStartTime.getDate()} ${actualStartTime.getHours()}:${actualStartTime.getMinutes()}`);

                            return el;

                        }

                        return false;

                    },

                    getRectangle: function(task, view){


                        if ( parseInt ( task.started ) ) {

                            let actualStartTime = getNewDateObj ( task.actualStartTime );
                            return gantt.getTaskPosition(task, actualStartTime);
                            
                        }

                        return null;

                    }

                }

            });

            gantt.addTaskLayer({

                renderer: {

                    render: function draw_actual_end_date(task) {

                        if ( task.type === 'task' && parseInt ( task.started ) ) {

                            let actualEndTime;
                            let className;

                            if ( parseInt ( task.completed ) ) {
    
                                actualEndTime = getNewDateObj ( task.actualEndTime );
                                className = 'actual-end-date';

                            } else {
                                
                                let today = new Date ();
                                actualEndTime = today;

                                let actualStartTime = getNewDateObj ( task.actualStartTime );

                                if ( actualEndTime < actualStartTime ) {
                                    return false;
                                }

                                className = 'actual-end-date-not-complete';

                            }

                            let el = document.createElement('div');
                            el.className = className;

                            let sizes = gantt.getTaskPosition(task, actualEndTime);
        
                            el.style.left = sizes.left + 'px';
                            el.style.top = sizes.top + 'px';

                            if ( parseInt ( task.completed ) ) {
                                el.setAttribute('title', `${t("columnName.endTime")}: ${actualEndTime.getFullYear()}-${actualEndTime.getMonth() + 1}-${actualEndTime.getDate()} ${actualEndTime.getHours()}:${actualEndTime.getMinutes()}`);
                            }

                            return el;

                        }

                        return false;

                    },

                    getRectangle: function(task, view){

                        if ( parseInt ( task.started ) ) {

                            let actualEndTime;

                            if ( parseInt ( task.completed ) ) {
                                
                                actualEndTime = getNewDateObj ( task.actualEndTime );
    
                            } else {
                                    
                                let today = new Date ();                                
                                actualEndTime = today;
    
                            }
    
                            return gantt.getTaskPosition(task, actualEndTime);
    
                        }

                        return null;

                    }

                }

            });

            gantt.addTaskLayer({

                renderer: {

                    render: function draw_work_time_line(task) {

                        if ( task.type === 'task' && parseInt ( task.started ) ) {

                            let actualStartTime = getNewDateObj ( task.actualStartTime );
                            let actualEndTime;

                            if ( parseInt ( task.completed) ) {
    
                                actualEndTime = getNewDateObj ( task.actualEndTime );

                            } else {
                                                                
                                let today = new Date ();
                                actualEndTime = today;

                            }

                            let el = document.createElement('div');
                            el.className = 'work-time-line';

                            let startSizes = gantt.getTaskPosition(task, actualStartTime);
                            let endSizes = gantt.getTaskPosition(task, actualEndTime);
        
                            el.style = `left:${startSizes.left < endSizes.left ? startSizes.left : endSizes.left}px;width:${startSizes.left < endSizes.left ? endSizes.left - startSizes.left : startSizes.left - endSizes.left}px;top:${startSizes.top}px`;

                            return el;

                        }

                        return false;

                    },

                    getRectangle: function(task, view) {

                        if ( parseInt ( task.started ) ) {

                            let actualStartTime;
                            let actualEndTime;

                            actualStartTime = getNewDateObj ( task.actualStartTime );

                            if ( parseInt ( task.completed ) ) {
                                
                                actualEndTime = getNewDateObj ( task.actualEndTime );
    
                            } else {
                                    
                                let today = new Date ();
                                actualEndTime = today;
    
                            }

                            let startSizes = gantt.getTaskPosition(task, actualStartTime);
                            let endSizes = gantt.getTaskPosition(task, actualEndTime);

                            let rect = {
                                left : startSizes.left < endSizes.left ? startSizes.left : endSizes.left,
                                top: startSizes.top,
                                height: startSizes.height,
                                width : startSizes.left < endSizes.left ? endSizes.left - startSizes.left : startSizes.left - endSizes.left,
                                rowHeight: startSizes.rowHeight
                            };

                            return rect;
    
                        }

                        return null;

                    }

                }

            });                

            events.push ( gantt.attachEvent ( "onGanttReady", function () {

                let today = new Date ();
                let yesterday = new Date ( today.getFullYear(), today.getMonth(), today.getDate() - 1 );
                let tomorrow = new Date ( today.getFullYear(), today.getMonth(), today.getDate() + 1 );

                yesterday.setHours ( 24, 59, 59, 99 );
                tomorrow.setHours ( 0, 0, 0, 0 );

                let tasksOfToday = gantt.getTaskByTime ( yesterday, tomorrow );

                if ( onGanttReady ) {

                    onGanttReady ( tasksOfToday );
        
                }

            } ) );

            events.push ( gantt.attachEvent ( "onBeforeTaskAutoSchedule", function ( task, start, link, predecessor ) {

                if ( parseInt ( task.ordered ) ) {

                    return false;

                }

                return true;

            } ) );

            events.push ( gantt.attachEvent ( "onBeforeTaskDisplay", function ( id, task ) {

                let trim = gantt.$search?.trim ();
                
                if ( trim && trim.length > 0 ) {

                    if ( task && task.taskName ) {

                        if ( ! task.taskName.includes ( trim ) ) {
            
                            return false;
                
                        }
    
                    }
                    
                }


                if ( gantt.$resourceSelection ) {

                    if ( task.type === 'task' ) {

                        if ( Array.isArray ( task.resources ) ) {
    
                            let resource = task.resources.find ( ( resource ) => resource.resource_id === gantt.$resourceSelection );
    
                            if ( ! resource ) {
    
                                return false;
    
                            }
    
                        } else {
    
                            if ( task.resources?.resource_id !== gantt.$resourceSelection ) {
    
                                return false;
    
                            }
    
                        }
                    
                    } else {

                        return false;
                        
                    }

                }
    
                if ( gantt.$ordered !== undefined ) {

                    switch ( parseInt ( gantt.$ordered ) ) {
    
                        case 2:
        
                            break;
        
                        default:
        
                            if ( parseInt ( task.orderCompleted ) !== parseInt ( gantt.$ordered ) ) {
        
                                return false;
        
                            }
        
                            break;
        
                    }
    
                }
    
                if ( gantt.$completed !== undefined ) {

                    switch ( parseInt ( gantt.$completed ) ) {
    
                        case 2:
        
                            break;
        
                        default:

                            if ( parseInt ( task.completed ) !== parseInt ( gantt.$completed ) ) {
        
                                return false;
        
                            }
        
                            break;
                    }
    
                }

                return true;
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onBeforeTaskDrag", function ( id, mode, e ) {
                    
                switch ( mode ) {

                    case "move":
                    case "resize":

                        let task = planData.planMap[id];

                        if ( parseInt ( task.ordered ) ) {

                            return false;

                        }

                        break;

                    default:

                        return false;

                }
    
                return true;
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onBeforeTaskMove", function ( id, parent, tindex ) {
    
                return false;
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onTaskCreated", function ( task ) {
    
                if ( ! task.taskType ) {

                    // popup dialog here

                    return false;

                }

                return true;
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onBeforeLightbox", function ( id ) {
    
                // disable dhtml gantt lightbox dialogs

                return false;
    
            } ) );

            events.push ( gantt.attachEvent ( "onBeforeLinkUpdate", function( linkId, link) {

                // only link create and delete is allowed
                
                return false;

            } ) );
    
            events.push ( gantt.attachEvent ( "onAfterTaskUpdate", function ( id, newTask ) {
                    
                let updateTask = false
    
                switch ( newTask.type ) {

                    case "task":
                    
                        let startDate = new Date ( newTask.start_date.getTime () );
    
                        if ( ! gantt.isWorkTime ( startDate ) ) {
        
                            while ( ! gantt.isWorkTime ( startDate ) ) {
            
                                startDate = new Date ( startDate.getTime () + 24 * 60 * 60 * 1000 );
            
                            }
                    
                            newTask.start_date = startDate;
    
                            updateTask = true;
            
                        }
                        
                        let endDate = new Date ( newTask.end_date.getTime () - 24 * 60 * 60 * 1000 );
        
                        if ( ! gantt.isWorkTime ( endDate ) ) {
        
                            while ( ! gantt.isWorkTime ( endDate ) ) {
            
                                endDate = new Date ( endDate.getTime () - 24 * 60 * 60 * 1000 );
            
                            }
            
                            endDate = new Date ( endDate.getTime () + 24 * 60 * 60 * 1000 );
            
                            newTask.end_date = endDate;
            
                            updateTask = true;
    
                        }
            
                        if ( updateTask ) {
                            gantt.updateTask ( id, newTask );
                        }
    
                        break;

                    case "milestone":

                        break;


                    default:

                        return;

                }
    
                if ( onTaskUpdate ) {
    
                    onTaskUpdate ( id, newTask );
    
                }
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onAfterLinkAdd", function ( id, link ) {
    
                if ( onLinkAdd ) {
    
                    onLinkAdd ( id, link );
    
                }
    
            } ) );
    
            events.push ( gantt.attachEvent ( "onAfterLinkUpdate", function ( id, link ) {
    
                if ( onLinkUpdate ) {
    
                    onLinkUpdate ( id, link );
    
                }
    
            } ) );

            gantt.attachEvent("onRowDragStart", function(id, target, e) {

                // prevent row dragging when drag_move is enabled

                return false;

            });

            events.push ( gantt.attachEvent ( "onTaskSelected", function ( id ) {

                let task = planData.planMap[id];

                if ( task && onTaskSelected ) {

                    onTaskSelected ( task );

                }
    
            } ) );

            events.push ( gantt.attachEvent ( "onTaskUnselected", function ( id ) {

                let task = planData.planMap[id];

                if ( task && onTaskUnselected ) {

                    onTaskUnselected ( task );

                }
    
            } ) );

            events.push ( gantt.attachEvent ( "onContextMenu", function (taskId, linkId, event) {

                if ( onContextMenu ) {

                    onContextMenu ( taskId, linkId, event );

                }
                
                return false;

            } ) );

            events.push ( gantt.attachEvent ( "onError", function ( msg ) {
    
                if ( onError ) {
    
                    onError ( msg );
    
                }

                return false;
    
            } ) );

            gantt.$resourcesStore.parse ( planData.resources );

            gantt.parse ( planData.tasks );

            gantt.render ();

            if ( onInitialized ) {

                onInitialized ( gantt );

            }
    
            return (
    
                () => {
    
                    gantt.clearAll ();
    
                    // remove all event listener on destroy

                    while ( events.length ) {
                        
                        gantt.detachEvent ( events.pop() );
    
                    }
    
                }
    
            );
    
        }

    }, [planData] );

    return (

        <></>

    );

}

export default GanttChart;

