import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function HolidayManage ( props ) {

    return <BasicCrudScreenType id="Holiday" table="Holiday" view="Holiday" noEdit noExcel />

}

export default HolidayManage;

