import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import TenantFileTemplateExcelPreview from "./TenantFileTemplateExcelPreview";
import { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import { debounce } from "lodash";


function TenantFileTemplateMgmt(props) {

    const [grid, setGrid] = useState();
    const [filePath, setFilePath] = useState("");
    const [height, setHeight] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        handleResize();


        return (() => {
            window.removeEventListener('resize', handleResize);
        });

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setFilePath(grid.selectedItems[0]?.templateFile)

                    }

                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setFilePath(grid.selectedItems[0]?.templateFile)

                    }
                }, 10));
            }

        })()

    }, [grid])

    const onInitialized = (grid) => {
        setGrid(grid);
    }

    return (

        <div style={{ height: height }}>
            <ReflexContainer orientation="vertical">
                <ReflexElement flex={0.4} data-cy="Master">
                    <BasicCrudScreenType
                        id="TenantFileTemplateMgmt"
                        table="TenantFileTemplateMgmt"
                        view="VwTenantFileTemplateMgmt"
                        onInitialized={onInitialized}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ width: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    <TenantFileTemplateExcelPreview filePath={filePath} />
                </ReflexElement>
            </ReflexContainer>
        </div>
    )
    // return <BasicCrudScreenType id="TenantFileTemplateMgmt" table="TenantFileTemplateMgmt" view="VwTenantFileTemplateMgmt" />

}

export default TenantFileTemplateMgmt;
