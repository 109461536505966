import { useTranslation } from "react-i18next";
import { useState } from "react";

import apiClient from "../../lib/common/apiClient";

import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";


function MomSysBusinessDetailDeleteButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, makeItemName, onSuccess, onFailure, onPartialFailure, title, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const makeReasonStr = ( reasons ) => {

        let reasonStr = " [";

        for ( const index in reasons ) {

            if ( index > 0 ) {

                reasonStr += ", ";

            }

            reasonStr += reasons[index];

        }

        reasonStr += "]";

        return reasonStr;

    };

    const onOpen = () => {

        let deleteData = [];
        let deleteItemStr = "[";

        let firstRow = true;

        for ( const item of grid.selectedItems ) {

            if ( !firstRow ) {

                deleteItemStr += ", ";

            }

            if ( makeItemName ) {

                deleteItemStr += makeItemName( item );

            } else {

                let itemDetail = "{";

                let firstCol = true;
    
                for ( let key of schema.keys ) {
    
                    if ( ! firstCol ) {
    
                        itemDetail += ", ";
    
                    }
    
                    itemDetail += ( t(`columnName.${key}`) + ": " + item[key] );
    
                    firstCol = false;
    
                }
    
                itemDetail += "}";
    
                deleteItemStr += itemDetail;
    
            }

            firstRow = false;

            deleteData.push ( {...item} );

        }

        deleteItemStr += "]"
        
        setConfirmMsg ( t( "dialog.msg.confirm.delete", { type: t(`tableName.${schema.name}`), instance: deleteItemStr } ) );
        setConfirmData ( deleteData );

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onDeleteOk = ( requestData ) => {

        let url = "/api/" + schema.name;

        apiClient.bulkDel ( url, requestData )
            .then ( ( result ) => {

                if ( result.data.fails.length === 0 ) {

                    onSuccess ( t ( "success.deleteSuccess", { table: schema.name, deleteCount: result.data.successes.length.toString() } ) );

                } else if ( result.data.successes.length === 0 ) {

                    let reasons = makeReasonStr ( result.data.reasons );
                    onFailure ( t ( "error.deleteFailure", { reason: reasons } ))

                } else {

                    let reasons = makeReasonStr ( result.data.reasons );
                    onPartialFailure ( t ( "warning.partialDeleteFailure", { success: result.data.successes.length, fail: result.data.successes.length, reson: reasons } ) )

                }

            })
            .catch ( ( reason ) => {

                console.error ( reason );                
                onFailure ( t ( "error.deleteFailure", { reason: reason.response ? reason.response.data : reason } ))

            });

    }

    return (

        <>
            <DeleteRecordButtonLayout id={id+"delete-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"-delete-confirm-dialog"}
                title={ title ? title : schema ? t ( `dialog.title.${schema.name}.delete` ) : "" }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onDeleteOk}
                onCancel={onClose}
            />
        </>

    );

}

export default MomSysBusinessDetailDeleteButton;

