import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function MomSysApplyStockTakingButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.applyStockTaking")}</ActionButtonLayout>;

}

export default MomSysApplyStockTakingButtonLayout;

