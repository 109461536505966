import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import getSpecific from "../../lib/api/getSpecific";
import PopSelfInspectionButton from "../input/PopSelfInspectionButton";
import { debounce } from "lodash";

function PopSelfInspection ( props ) {

    const id = "PopSelfInspection";

    const { t } = useTranslation ();

    const setNotification = useSetRecoilState(notificationState);
    
    const [api, setApi] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const [grid, setGrid] = useState();

    const [onSelect, setOnSelect] = useState();

    const [inspectionGroup, setInspectionGroup] = useState();

    useEffect(() => {

        (async () => {

            const inspectionGroup = await getSpecific("MomSysInspectionGroup");

            setInspectionGroup(inspectionGroup.data);

        })();

    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect();
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect();
                        }
                    },10)
                );
            }
        })();
    }, [grid]);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const onApiInitialized = ( api ) => {

        setApi(api);

    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    {
                        inspectionGroup?.map((v, i) => inspectionButton(v, i))
                    }
                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    const inspectionButton = (inspectionGroup, index) => {
        return (
            <PopSelfInspectionButton
                createButtonName={inspectionGroup.inspectionGroupName}
                id={id}
                disabled={isEmpty}
                onSuccess={onSuccess}
                onFailure={onFailure}
                refresh={api}
                inspectionGroup={inspectionGroup}
                index={index}
                onSelect={onSelect}
            />
        )
    }

    return (

        <BasicCrudScreenType 
            id="EguVwSelfInspectionTargetLot"
            headerTitle={t("term.inspectionTargetLot")}
            table="EguVwSelfInspectionTargetLot"
            view="EguVwSelfInspectionTargetLot"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
            onLoadData={onLoadData}
        />

    );

}

export default PopSelfInspection;
