/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import getSchema from "../../lib/api/getSchema";

import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";


function ModifyPerformanceMetricDialog ( props ) {

    const { t } = useTranslation ();

    const [, setInstance] = useState ();

    const [schema, setSchema] = useState ();
    const [disabled, setDisabled] = useState ( true );
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    } );

    const {

        id,

        performanceMetric,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "PerformanceMetric" );

            for ( let column of schema.columns ) {

                switch ( column.name ) {

                    case "processCode":

                        column.formItemType = "text";
                        column.disabled = true;
                        column.readonly = true;
                        column.required = false;

                        break;

                    case "machineCode":

                        column.formItemType = "text";
                        column.disabled = true;
                        column.readonly = true;
                        column.required = false;

                        break;

                    default:

                        break;

                }
            }

            let column;
            
            column = {
                name: "materialName",
                type: "varchar(50)",
                formItemType: "text",
                formOrder: 4,
                readonly: true,
                disabled: true,
                width: "100%"
            }

            schema.columns.push ( column );
            
            column = {
                name: "processName",
                type: "varchar(50)",
                formItemType: "text",
                formOrder: 6,
                readonly: true,
                disabled: true,
            }

            schema.columns.push ( column );
            
            column = {
                name: "machineName",
                type: "varchar(50)",
                formItemType: "text",
                formOrder: 8,
                readonly: true,
                disabled: true,
            }

            schema.columns.push ( column );

            column = {
                name: "avgProcessPerHour",
                type: "float",
                formOrder: 9,
                readonly: true,
                disabled: true,
            }

            schema.columns.push ( column );

            column = {
                name: "minProcessPerHour",
                type: "float",
                formOrder: 10,
                readonly: true,
                disabled: true,
            }

            schema.columns.push ( column );

            column = {
                name: "maxProcessPerHour",
                type: "float",
                formOrder: 11,
                readonly: true,
                disabled: true,
            }

            schema.columns.push ( column );

            if ( mounted ) {
                
                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );

    }, [] );

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "edit", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                nofifyWarning ( msg );
                return false;

            }
            
        }

        return true;

    }

    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };    

    useEffect ( () => {

        setFormData ( ( formData ) => {

            let newFormData;

            if ( performanceMetric ) {

                newFormData = {

                    ...formData,
                    id: performanceMetric.id,
                    materialCode: performanceMetric.materialCode,
                    materialCodeVer: performanceMetric.materialCodeVer,
                    materialName: performanceMetric.materialName,
                    processCode: performanceMetric.processCode,
                    processName: performanceMetric.processName,
                    machineCode: performanceMetric.machineCode,
                    machineName: performanceMetric.machineName,
                    avgProcessPerHour: performanceMetric.avgProcessPerHour,
                    minProcessPerHour: performanceMetric.minProcessPerHour,
                    maxProcessPerHour: performanceMetric.maxProcessPerHour,
                    processPerHour: performanceMetric.processPerHour,
    
                };
    
            }
    
            return newFormData;

        } );

    }, [performanceMetric] );

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        setInstance ( ( instance ) => {
            instance.hide ();
            return instance;
        } );

        if ( onSuccess ) {

            onSuccess ( t ( "success.updateSuccess", { updateCount: 1 } ) );
    
        }

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                id: undefined,
                materialCode: undefined,
                materialCodeVer: undefined,
                materialName: undefined,
                processCode: undefined,
                processName: undefined,
                machineCode: undefined,
                machineName: undefined,
                avgProcessPerHour: undefined,
                minProcessPerHour: undefined,
                maxProcessPerHour: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.updateFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, formData ) => {

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = `/api/PerformanceMetric/${formData.id}`;

        let apiData = {
            processPerHour : formData.processPerHour
        };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {

                deleted[formItem] = apiData[formItem].deletedFiles;
                    
                for ( const file of apiData[formItem].activeFiles ) {

                    if ( file.type === "url" ) continue;

                    form.append ( formItem, file.file );

                }

            } else {

                if ( apiData[formItem] !== undefined ) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .put(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                id: undefined,
                materialCode: undefined,
                materialCodeVer: undefined,
                materialName: undefined,
                processCode: undefined,
                processName: undefined,
                machineCode: undefined,
                machineName: undefined,
                avgProcessPerHour: undefined,
                minProcessPerHour: undefined,
                maxProcessPerHour: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );

        setDisabled ( true );

        setInstance ( ( instance ) => { 
            instance.hide();
            return instance;
        } );

    };

    const onChange = ( formData ) => {

        setFormData ( formData );

        setDisabled ( false );

    }

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setDisabled ( true );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                id: undefined,
                materialCode: undefined,
                materialCodeVer: undefined,
                materialName: undefined,
                processCode: undefined,
                processName: undefined,
                machineCode: undefined,
                machineName: undefined,
                avgProcessPerHour: undefined,
                minProcessPerHour: undefined,
                maxProcessPerHour: undefined,
                processPerHour: undefined,
            }

            return newFormData;

        } );
        
        if ( onClose ) {

            onClose ();

        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.PerformanceMetric.edit" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "450px"}}>

                <div
                    style={{
                        padding: "16px"
                    }}                    
                >

                    <AutoForm
                        id="performance-metric-form"
                        schema={schema}
                        mode="edit"
                        initialData={formData}
                        onChange={onChange}
                    />

                </div>

                <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("common.edit")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default ModifyPerformanceMetricDialog;
