import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import MomSysBackShipTransactionButton from "../input/MomSysBackShipTransactionButton";
import PopBackShipDetail from "./PopBackShipDetail";
import { debounce } from "lodash";

function PopBackShip ( props ) {

    const id = "PopBackShip";
    const table = "PopBackShipDetail";
    const view = "MomSysVwDeliveryMaterialLotList";

    const { t } = useTranslation ();

    const setNotification = useSetRecoilState(notificationState);
    
    const [ height, setHeight ] = useState(0);

    const [api, setApi] = useState();

    const [detailApi, setDetailApi] = useState();

    const [schema, setSchema] = useState();

    const [grid, setGrid] = useState();

    const [onSelect, setOnSelect] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const [detailIsEmpty, setDetailIsEmpty] = useState();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    }, []);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema(table);

            if (mounted) {

                setSchema(schema);

            }

        })();

        return () => {

            mounted = false;

        };

    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect();
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect();
                        }
                    },10)
                );
            }
        })();
    }, [grid]);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

        if(detailApi) detailApi.refresh();

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid( grid );

    };

    const onApiInitialized = ( api ) => {

        setApi(api);

    };

    const onLoadData = (data) => {

        let isEmpty = data.length < 1 ? true : false;

        setIsEmpty(isEmpty);

    };
    
    const onDetailApi = (api) => {

        setDetailApi(api);

    }

    const onDetailIsEmpty = (detailIsEmpty) => {

        setDetailIsEmpty(detailIsEmpty);

    }

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <MomSysBackShipTransactionButton
                            id={id}
                            refresh={api}
                            modifyUrlCallback ={(url) => url = "/api/PopBackShipDetail/backShip"}
                            disabled={detailIsEmpty}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                        />
                    </AccessControl>
                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.6} data-cy="Master">

                    <BasicCrudScreenType 
                        id={id}
                        headerTitle={t("tableName.MomSysDeliveryDetailLot")}
                        table={table} 
                        view={view} 
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        noFilter
                        onLoadData={onLoadData}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        autoFocus={true}
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4} data-cy="Detail">

                    <PopBackShipDetail 
                        id="PopBackShipDetail" 
                        table="PopBackShipDetail" 
                        view="VwPopBackShipDetail" 
                        onSelect={onSelect}
                        onDetailApi={onDetailApi}
                        mstIsEmpty={isEmpty}
                        detailIsEmpty={onDetailIsEmpty}
                    />

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default PopBackShip;
