import { makeStyles } from "@material-ui/styles";
import IconButton from "../../input/IconButton";
import Icon from "../../presentation/Icon";

const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

function DialogCloseButtonLayout ( props ) {

    const { onInitialized, ...others } = props;
    const classes = useStyles();

    return (
        <IconButton className={classes.closeButton} {...others}>
            <Icon>close</Icon>
        </IconButton>
    );

}

export default DialogCloseButtonLayout;

