import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function FilterFormItemText ( props ) {

    const { t } = useTranslation ();
    
    const { column, InputLabelProps, value, onChange, ...others } = props;

    const [ text, setText ] = useState (value);

    useEffect ( () => {

        setText ( value );

    }, [value, setText] );

    let textFieldProps = {

        InputLabelProps: {
            shrink: true,
            ...InputLabelProps,
        },
        onChange: ( event ) => {

            setText ( event.target.value );
            setTimeout ( () => onChange ( event.target.value ), 0 ); // prevent input delay
            
        },
        ...others
    };

    if ( column.filter.InputLabelProps ) {

        textFieldProps.InputLabelProps = { ...textFieldProps.InputLabelProps, ...column.filter.InputLabelProps };

    }

    if ( column.filter.placeholder ) {

        textFieldProps.placeholder = t ( column.filter.placeholder );

    }

    if ( column.multiline ) {

        textFieldProps.multiline = true

        if ( column.minRows ) {

            textFieldProps.minRows = column.minRows;

        }

        if ( column.rows ) {

            textFieldProps.rows = column.rows;

        }

        if ( column.rowsMax ) {

            textFieldProps.rowsMax = column.rowsMax;

        }

    }

    if ( column.filter.variant ) {

        textFieldProps.variant = column.filter.variant;

    }

    if ( column.filter.inputProps ) {

        textFieldProps.inputProps = column.filter.inputProps;

    }

    if ( column.filter.InputProps ) {

        textFieldProps.InputProps = column.filter.InputProps;

    }

    if ( column.filter.style ) {

        textFieldProps.style = column.filter.style;

    }

    return (
        <TextField value={text} {...textFieldProps} />
    );

}

export default FilterFormItemText;
