import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useState, useEffect } from "react";
import { cloneDeep } from "lodash";

function NumberFormatCustom(props) {

    const { inputRef, column, onChange, ...other } = props;

    const onChangeValue = ( value, sourceInfo ) => {

        if ( onChange ) {

            // change value only if user changed value ( prevent propagation of change from property value )

            if ( sourceInfo?.source === "event" ) {

                let event = cloneDeep ( sourceInfo.event );

                event.target.value = value.floatValue;

                onChange ( sourceInfo.event );

            }

        }

    }
    const thousandSeparator = column ? ( column.thousandSeparator !== undefined ? column.thousandSeparator : true ) : true;
    const allowNegative = column ? ( column.allowNegative !== undefined ? column.allowNegative : true ) : true;

    return (
        <NumberFormat
            {...other}
            prefix={column?.numberPrefix}
            getInputRef={inputRef}
            onValueChange={onChangeValue}
            thousandSeparator={thousandSeparator}
            allowNegative={allowNegative}
        />
    );
}

function FormItemNumber(props) {

    const { column, InputLabelProps, value, onChange, focusRef, ...others } = props;
    
    const [number, setNumber] = useState (  );

    useEffect(() => {

        let initialValue = parseFloat ( value );
    
        setNumber ( isNaN ( initialValue ) ? 0.0 : initialValue );

    }, [column, value])

    let textFieldProps = {

        InputLabelProps: {
            shrink: true,
            ...InputLabelProps,
        },

        onChange: ( event ) => {

            let number = parseFloat ( event.target.value );

            setNumber ( number );

            if ( onChange ) {

                setTimeout ( onChange ( number ), 0 );

            }

        },
        ...others,
    };

    if (column.InputLabelProps) {
        textFieldProps.InputLabelProps = { ...textFieldProps.InputLabelProps, ...column.InputLabelProps };
    }

    if (column.required) {
        textFieldProps.required = true;
    }

    if (column.variant) {
        textFieldProps.variant = column.variant;
    }

    if (column.inputProps) {
        textFieldProps.inputProps = column.inputProps;
    }

    if (column.InputProps) {
        textFieldProps.InputProps = { ...column.InputProps, inputComponent: NumberFormatCustom };
    } else {
        textFieldProps.InputProps = { inputComponent: NumberFormatCustom };
    }

    if (column.InputProps) {
        textFieldProps.InputProps = column.InputProps;
    }

    if (column.style) {
        textFieldProps.style = column.style;
    }

    return <TextField value={number} inputRef={focusRef} {...textFieldProps} />;

}

export default FormItemNumber;
