import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysRawMaterialBackShipDialog from "../dialogTypes/MomSysRawMaterialBackShipDialog";

function MomSysRawMaterialBackShipButton (props) {

    const {id, schema, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, ...others} = props;

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    const modifyUrlCallback = (url) => {

        return url + "/backShip"

    }

    const checkItemValidation = ( formData, column ) => {

        if(column.name === "qtyBackShip") {

            if(formData["qtyCurrent"] < formData["qtyBackShip"]) {

                return [ false, t( "error.inputQtyMustBeLessThanOrEqualCurrentQty" ) ];
            }
        }

        return [ true, "" ];

    }

    return (
        <>
            <CreateRecordButtonLayout id = {id+"-rawMaterialBackShip-button"} onClick = {onOpen} createButtonName ={createButtonName} {...others}/>
            <MomSysRawMaterialBackShipDialog
                id={id+"rawMaterialBackShip-dialog"}
                mode="create"
                formId={id+"rawMaterialBackShip-form"}
                title={schema ? t(`dialog.title.${schema.name}.${'rawMaterialBackShip'}`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidation}
            />
        </>
    )

}

export default MomSysRawMaterialBackShipButton;