/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import apiClient from "../../lib/common/apiClient";

import layoutConf from "../../config/layoutConf";

import notificationState from "../../states/notificationState";


import SaveToExeclButtonLayout from "../layout/SaveToExcelButtonLayout";


import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";

import MomSysBop from "./MomSysBop";
import MomSysBom from "./MomSysBom";
import MomSysBopWarehouse from "./MomSysBopWarehouse";

import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";
import CopyBomBopButton from "../input/CopyBomBopButton";

function MomSysBomBopInfo(props) {

    const { t } = useTranslation();

    const [grid, setGrid] = useState();
    const [materialApi, setMaterialApi] = useState();
    const [wareHouse, setWareHouse] = useState();
    const [product, setProduct] = useState();
    const [process, setProcess] = useState();
    const [height, setHeight] = useState(0);

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];

                        setProduct ( product );

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];

                        setProduct ( product );

                    }

                });

            }
        })();

    }, [grid]);

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";
        
        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setMaterialApi ( api );

    };

    const onWareHouseInitialized = (wareHouse) => {

        setWareHouse ( wareHouse );

    };

    const onSelectProcess = ( process ) => {

        setProcess ( process );

    };

    const onChangeProcess = () => {

        if ( wareHouse ) {

            setProduct ( ( product ) => {

                wareHouse.refresh ( product );
                return product;

            } );

        }

    };

    const fileDownload = () => {

        setProduct ( ( product ) => {

            if ( product ) {

                const doHandleFileStream = (result) => {
                    const blob = new Blob([result.data]);
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `BOM BOP Upload File for ${product.materialCode} ${product.materialCodeVer} ${product.materialName}.xlsx`;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                };
                
                const url = `/api/BomBopFileDownload/${product?.materialCode}/${product?.materialCodeVer}`;
    
                apiClient
                    .get(url, { responseType: "blob" }) // Note : responseType := "blob" !!
                    .then((result) => {
                        doHandleFileStream(result);
                    } );
    
            }
    
            return product;

        } );
    };

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.4}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.6} data-cy="ProductionItem">

                            <BasicCrudScreenType
                                id="MomSysZvwProduceableMaterial"
                                headerTitle={t("term.outputMaterial")}
                                table="MomSysZvwProduceableMaterial"
                                view="MomSysZvwProduceableMaterial"
                                onAppendButtonAtFirst={() =>
                                    <>

                                        <BasicCrudScreenActionItemLayout>

                                            <MomSysBomBopTreeButton color="primary" disabled={!product} product={product} />

                                        </BasicCrudScreenActionItemLayout>

                                    </>

                                }
                                onAppendButtonInFrontOfSearch={() =>

                                    <>
                                        <BasicCrudScreenActionItemLayout>
                                            <SaveToExeclButtonLayout id={"save-bomboptree-to-execl-button"} onClick={fileDownload} />                                            
                                        </BasicCrudScreenActionItemLayout>                                    
                                        <BasicCrudScreenActionItemLayout>

                                            <AccessControl accessTarget="MomSysUdtBillOfProcess" accessMethod="POST">
                                                <CopyBomBopButton
                                                    id={"copy-bom-bop-button"}
                                                    refresh={materialApi}
                                                    onSuccess={( msg ) => notifySuccess ( msg )}
                                                    createButtonName="copyBomBop"
                                                />
                                            </AccessControl>

                                        </BasicCrudScreenActionItemLayout>
                                    </>

                                }
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={onGridInitialized}
                                onApiInitialized={onApiInitialized}
                            />

                        </ReflexElement>

                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterHeight }}
                        />

                        <ReflexElement flex={0.4} data-cy="Warehouse">

                            <MomSysBopWarehouse product={product} onInitialized={onWareHouseInitialized} />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterWidth }}
                />

                <ReflexElement>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5} data-cy="BOP">

                            <MomSysBop product={product} onSelect={onSelectProcess} onChange={onChangeProcess} />

                        </ReflexElement>

                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterHeight }}
                        />

                        <ReflexElement flex={0.5} data-cy="BOM">

                            <MomSysBom process={process} />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysBomBopInfo;
