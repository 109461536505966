/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";


import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysProductionCancelHistory ( props ) {

    const [, setGrid] = useState();


    const onGridInitialized = (grid) => {

        grid.selectionMode = "None";
        setGrid(grid);
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwLogWorkEdit"
            table="MomSysVwLogWorkEdit"
            view="MomSysVwLogWorkEdit"
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onGridInitialized}
        />                                

    );

}

export default MomSysProductionCancelHistory;
