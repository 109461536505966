import { makeStyles } from "@material-ui/styles";

import MaterialDialogContent from "../input/MaterialDialogContent";
import WijmoDialogContent from "../input/WijmoDialogContent";

import uiConf from "../../../config/uiConf";

const useStyles = makeStyles(( theme ) => ({
    root: {
        padding: theme.spacing(2),
    }
}));

function DialogContentLayout ( props ) {

    const { moveable, platform, onInitialized, ...others } = props;

    const classes = useStyles ();

    const styledProps = { classes, ...others };

    if ( platform ) {

        switch ( platform ) {

            case "material-ui":
    
                return <MaterialDialogContent {...styledProps} />
    
            case "wijmo":
    
                return <WijmoDialogContent {...styledProps} />

            default:
    
                return <MaterialDialogContent {...styledProps} />
    
        }

    } else {

        switch ( uiConf.dialogPlatform ) {

            case "material-ui":
    
                return <MaterialDialogContent {...styledProps} />

            case "wijmo":
    
                return <WijmoDialogContent {...styledProps} />

            default:
    
                return <MaterialDialogContent {...styledProps} />
        
        }

    }

};

export default DialogContentLayout;
