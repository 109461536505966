import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";
import { useTranslation } from "react-i18next";

import DeleteRecordButton from "../input/DeleteRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";

import AccessControl from '../functional/AccessControl'

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import CreateSoPoDetailButton from "../input/CraeateSoPoDetailButton";

function SoDetail(props) {

  const { id, soId, soCost, onApiInitialized, exportFormatItem,soRefresh, approvalStateId } = props;

  const [grid, setGrid] = useState();

  const [tableSchema, setTableSchema] = useState();

  const [ notification, setNotification ] = useRecoilState(notificationState);

  const { t } = useTranslation();


  useEffect(() => {

    (async () => {

      let schema = await getSchema("SoDetail");

      for (const column of schema.columns) {

        switch (column.name) {

          case "soId":

            column.defaultValue = soId;
            column.disabled = true;

            break;

          default:

            break;
        }
      }

      setTableSchema(schema);
    })();

  }, [soId,grid]);
  
  useEffect(() => {

    if (notification.open) {

      soRefresh.refresh();

    }

  }, [notification, soRefresh]);


  const onInitialized = (grid) => {

    setGrid(grid);

  };

  const notifySuccess = (msg) => {

    setNotification({

      severity: "success",
      msg: msg,
      open: true,

    });
  };

  const onNotifySuccess = (msg) => {

    notifySuccess(msg);

  };


  const onModifyUrl = (url, soId) => {
      
    return url + "/" + soId;

  };

  const modifyUrlCallback = ( url ) => {

    let modifiedUrl = `${url}/${soId}`;

    return modifiedUrl;

}

const doHandleBackendErrorMsg = (reason) => {
  // reason.response := { data, status, statusText, headers, config, request }
  // data : user error message
  // status : http status code
  // statusText : http status code text
  const response = reason.response;
  return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;

}

  const onAppendHeaderAtFirst = () => {

    return (
        
      <BasicCrudScreenActionItemLayout>
        <AccessControl accessTarget = {tableSchema} accessMethod = "POST">
          <CreateSoPoDetailButton
            id={id}
            schema={tableSchema}
            disabled={Number.isInteger(approvalStateId) ? true : false}
            refresh ={soRefresh}
            onSuccess = {onNotifySuccess}
            modifyUrlCallback = {modifyUrlCallback}
            customizeErrorMsgCallback ={doHandleBackendErrorMsg}
          />
        </AccessControl>

        <AccessControl accessTarget = {tableSchema} accessMethod = {tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
          <UpdateRecordButton 
            id = {id}
            schema = {tableSchema}
            grid ={grid}
            disabled={Number.isInteger(approvalStateId) ? true : false || soCost === 0}
            refresh = {soRefresh}
            onSuccess ={onNotifySuccess}
          />
        </AccessControl>

        
        <AccessControl accessTarget = {tableSchema} accessMethod = "DELETE">
          <DeleteRecordButton
            id = {id}
            schema = {tableSchema}
            grid = {grid}
            refresh ={soRefresh}
            color = "secondary"
            disabled = {Number.isInteger(approvalStateId) ? true :false || soCost === 0}
            onSuccess = {onNotifySuccess}
          />
        </AccessControl>
      </BasicCrudScreenActionItemLayout>

    );
  };

  return (
    <BasicCrudScreenType
      id={id}
      table="SoDetail"
      view="VwSoDetail"
      onApiInitialized={onApiInitialized}
      onModifyViewUrl={(url) => onModifyUrl(url, soId)}
      onInitialized = {onInitialized}
      embededInOtherScreenType
      onNotifySuccess={onNotifySuccess}
      onAppendHeaderAtFirst={onAppendHeaderAtFirst}
      exportFormatItem = {exportFormatItem}
      noCreate
      noEdit
      noDelete
    />
  );
}

export default SoDetail;
