/* eslint-disable react-hooks/exhaustive-deps */
import {  useState } from "react";
import { useTranslation } from "react-i18next";

import del from "../../lib/api/del";

import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function PopMaterialFakeInputDetailDeleteButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, title, refresh, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {

        setConfirmMsg ( t( "dialog.msg.confirm.delete", { type: t(`tableName.${schema.name}`), instance: (grid.selectedItems[0].materialFakeInputDetailId) } ) );
        setConfirmData ( grid.selectedItems[0] );

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onDeleteOk = ( requestData ) => {

        let url = `${id}/${grid.selectedItems[0].materialFakeInputId}/${grid.selectedItems[0].materialFakeInputDetailId}`;
        
        del ( url )
            .then ( ( result ) => {

                if(refresh) {
                    refresh.refresh();
                }
                onSuccess ( t ( "success.deleteSuccess", { table: schema.name, deleteCount: 1 } ) );

            })
            .catch ( ( reason ) => {

                console.error ( reason );                
                onFailure ( t ( "error.deleteFailure", { reason: reason.response ? reason.response.data : reason } ))

            });
    }

    return (

        <>
            <DeleteRecordButtonLayout id={id+"delete-button"} title={title} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"-delete-confirm-dialog"}
                title={ schema ? t ( `dialog.title.${schema.name}.delete` ) : "" }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onDeleteOk}
                onCancel={onClose}
            />
        </>

    );

}

export default PopMaterialFakeInputDetailDeleteButton;

