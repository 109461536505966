import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysProductStatusDetail from "./MomSysProductStatusDetail";
import {debounce} from "lodash";
import EguProductStatusSaveExcelButton from "../input/EguProductStatusSaveExcelButton";

function EguProductStatus ( props ) {

    const [ mstStandardGrid, setMstStandardGrid ] = useState();
    const [ standardProduct, setStandardProduct ] = useState();

    const [searchVal, setSearchVal] = useState();
    const [filterVal, setFilterVal] = useState ();

    const [ height, setHeight ] = useState(0);
    
    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect( () => {

        ( async () => {

            if (mstStandardGrid) {
    
                mstStandardGrid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];
                        
                        setStandardProduct(() => {

                            return (
                                {
                                    ...product,
                                    qty : product.qtyInStock
                                });
                        });
                    } else {

                        setStandardProduct([]);

                    };
                });
                
                mstStandardGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];
                        
                        setStandardProduct(() => {

                            return (
                                {
                                    ...product,
                                    qty : product.qtyInStock
                                });
                        });
                    }
                    
                    else {

                        setStandardProduct([]);

                    };
                }),10);

                mstStandardGrid.loadedRows.addHandler(debounce((flex, e) => {
                    /* let rows  = e.getRow(); */

                    for(const row of flex.rows) {

                        if(row.dataItem.qtySpareStock < row.dataItem.qtySafetyStock) {
                            
                            row.cssClass = `failed-stock-apply`

                        }
                    }
                }),10);

            }

        } ) ()

    },[mstStandardGrid]);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);

    const onMstStandardInitialized = ( grid ) => {

        grid.selectionMode = "Row";
        
        setMstStandardGrid(grid);

    };

    const onSearchChangeValue = (value) => {
        setSearchVal(value);
    }

    const onFilterChangeValue = (value) => {
        setFilterVal(value);
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysVwInventorySummary" 
                        view="MomSysVwInventorySummary" 
                        headerTitle={t("term.standardProductList")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={onMstStandardInitialized}
                        onSearchChangeValue={onSearchChangeValue}
                        onFilterChangeValue={onFilterChangeValue}
                        onModifyViewUrl = {(url) => `${url}/product`}
                        onAppendButtonAtFirst={() =>
                            <>

                                <BasicCrudScreenActionItemLayout>

                                    <MomSysBomBopTreeButton color="primary" product={standardProduct} />

                                </BasicCrudScreenActionItemLayout>

                            </>
                        }
                        onAppendButtonInFrontOfSearch={
                            () => (
                                <>
                                    <BasicCrudScreenActionItemLayout>
                                        <EguProductStatusSaveExcelButton 
                                            searchValue={searchVal}
                                            filterValue={filterVal}
                                        />
                                    </BasicCrudScreenActionItemLayout>
                                </>
                            )
                        }
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>

                        {
                            (standardProduct)
                            ?
                            <MomSysProductStatusDetail
                                id="MomSysVwMaterialStatus" 
                                view="MomSysVwMaterialStatus" 
                                product = {standardProduct}
                            />
                            :
                            <></>
                        }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}
export default EguProductStatus;
