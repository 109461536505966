import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function EguPopNotice(props) {

    const [grid, setGrid] = useState();
    const [, setApi] = useState();
    const [, setSchema] = useState();
    const [empty, setEmpty] = useState();

    const [content, setContent] = useState();
    const [height, setHeight] = useState();

    const { t } = useTranslation ();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        let mounted = true;

        window.addEventListener('resize', handleResize);

        handleResize();

        (async () => {

            let result = await getSpecific(`EguPopNotice/visible/noticeNo`);

            if (mounted && result && result.noticeNo) {

                window.open(`/newWindow/EguPopNotice/${result.noticeNo}`, 'Notice', '_blank, width=' + window.screen.width + ', height=' + window.screen.height)

            }

            let schema = await getSchema("EguPopNotice");

            if (mounted) {

                setSchema(schema);

            }

        })()

        return (() => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        });

    }, [])

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        let data = grid.selectedItems[0]
                        let content = { title: data.title, notice: data.notice };
                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            content.notice = data.notice.replaceAll("/files", "http://localhost:8080/files")

                        }
                        setContent(content);

                    }

                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {
                        let data = grid.selectedItems[0]
                        let content = { title: data.title, notice: data.notice };
                        if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {

                            content.notice = data.notice.replaceAll("/files", "http://localhost:8080/files")

                        }
                        setContent(content);

                    }

                }, 10));

                grid.addEventListener(grid.hostElement, "dblclick", () => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        window.open(`/newWindow/EguPopNotice/${grid.selectedItems[0].noticeNo}`, 'Notice', '_blank, width=' + window.screen.width + ', height=' + window.screen.height)

                    }

                });

            }

        })();

    }, [grid]);

    useEffect(() => {
        if (empty) {
            setContent(null)
        }
    }, [empty])


    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onLoadData = (data) => {

        setEmpty(data.length < 1 ? true : false)
    }

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id="EguPopNotice"
                        table="EguPopNotice"
                        view="EguVwPopNotice"
                        headerTitle={t("menu.PopNotice")}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onLoadData={onLoadData}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement>
                <ReflexSplitter />
                <ReflexElement data-cy="Detail">
                    {content ?
                        <div className="ql-snow">
                            <div className="ql-editor"
                                style={{ width: '100%', height: '100%', padding: '5% 10% 0% 10%', fontSize: '20px', overflow: 'hidden' }}
                            >
                                {!empty && parse(content.notice)}
                            </div>
                        </div>
                        : <></>
                    }
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguPopNotice;
