import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function GroupByColumnPicker ( props ) {

    const { t } = useTranslation ();

    const { groupByColumns, onChange, ...others } = props;

    const onChangeSelection = ( column, checked ) => {

        let newGroupBy = [...groupByColumns ];

        newGroupBy[newGroupBy.findIndex ( ( item ) => item.name === column.name )].value = checked;

        if ( onChange ) {

            onChange ( newGroupBy );

        }

    }

    return (

        ( groupByColumns && groupByColumns.length > 0 )
        ?
        groupByColumns
            .map ( ( column ) => (
                <div key={ column.name } style={{marginTop: "8px"}}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ column.value }
                                name={ column.name }
                                size="small"
                                onChange={
                                    ( e ) => onChangeSelection ( column, e.target.checked )
                                }
                                { ...others }
                            />
                        }
                        style={{ marginTop: "2px" }}
                        label={ t ( `groupByItem.${column.displayName||column.name}` ) }
                    />        

                </div>
            ) )
        :
        <></>
    );

}

export default GroupByColumnPicker;
