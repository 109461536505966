import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../lib/common/apiClient";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysDirectShipDoShipButton(props) {
    const { id, schema, grid, refresh, onSuccess, onFailure, ...others } = props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();
    const [modifySchema, setModifySchema] = useState();

    useEffect(() => {
        let schema = {
            name: "MomSysDirectShip",
            type: "BASE TABLE",
            searchAll: false,
            // formGroups: ["data", "date"],
            columns: [
                {
                    name: "shipDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    required: false,
                    default: null,
                    // formGroup: 1,
                    formOrder: 1,
                    // hideInForm: true,
                    formItemType: "date",
                },
                {
                    name: "directShipName",
                    type: "VARCHAR(255)",
                    nullable: "NO",
                    required: false,
                    default: null,
                    // formGroup: 1,
                    formOrder: 1,
                    hideInForm: true,
                    formItemType: "text",
                },
                {
                    name: "companyCode",
                    type: "VARCHAR(255)",
                    nullable: "NO",
                    required: false,
                    default: null,
                    // formGroup: 1,
                    formOrder: 1,
                    hideInForm: true,
                    formItemType: "text",
                },
            ],
        };

        setModifySchema(schema);
    }, []);

    const onOpen = () => {
        setOpen(true);

        const data = grid.selectedItems[0];

        setConfirmMsg(t("dialog.msg.confirm.directShipDoShip", { directShipName: data.directShipName }));
        setConfirmData(data);

        setOpen(true);
    };

    const onDoShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const modifyFormDataCallback = ( newFormData, name, value ) => {
            
        const data = grid.selectedItems[0];

        if ( !newFormData ) {

            newFormData = {};
            
        }

        newFormData.directShipName = data.directShipName;
        newFormData.companyCode = data.companyCode;

    }

    const setInitialDataCallback = ( newFormData ) => {
            
        const data = grid.selectedItems[0];

        if ( !newFormData ) {

            newFormData = {};
            
        }

        newFormData.directShipName = data.directShipName;
        newFormData.companyCode = data.companyCode;

    }

    const onOk = (data) => {
        let url = `/api/${schema.name}/doShip/${data.directShipId}`;

        let json = {};
        let apiData = { ...data };

        let form = new FormData();

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("json", JSON.stringify(json));
        apiClient
            .put(url, form)
            .then((result) => {
                onDoShipSuccess(result.data);
            })
            .catch((reason) => {
                onFailure(t("error.doShipFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    };

    const modifyUrlCallback = () => {
        return `/api/${schema.name}/doShip/${grid?.selectedItems[0]?.directShipId}`
    }
    
    return (
        <>
            <ActionButtonLayout id={id + "doShip-button"} onClick={onOpen} {...others}>
                {t("buttonName.materialOutput")}
            </ActionButtonLayout>
            {/* <ConfirmDialog
                id={id + "doShip-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.doShip`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            /> */}
            <AutoFormDialog 
                id={id + "doShip-confirm-dialog"}
                mode="edit"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.doShip`) : ""}
                schema={modifySchema}
                initialData={confirmData}
                open={open}
                modifyUrlCallback={modifyUrlCallback}
                onClose={onClose}
                onSuccess={onDoShipSuccess}
                onFailure={onFailure}
                // modifyFormDataCallback={modifyFormDataCallback}
                // setInitialDataCallback={setInitialDataCallback}
            />
        </>
    );
}

export default MomSysDirectShipDoShipButton;
