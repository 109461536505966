/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import MomSysVwSgtLot from "./MomSysVwSgtLot";
import MomSysComponentLots from "./MomSysComponentLots";
import MomSysProductLots from "./MomSysProductLots";
import MomSysVwSgtLotTransLog from "./MomSysVwSgtLotTransLog";

function MomSysLotTrace(props) {

    const [lotInfo, setLotInfo] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);


    const onSelectLot = ( lot ) => {

        setLotInfo ( lot );

    };


    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.4}>

                    <MomSysVwSgtLot                        
                        onSelect={onSelectLot}
                    />

                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterWidth }}
                />

                <ReflexElement flex={0.6}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5}>

                            <ReflexContainer orientation="vertical">

                                <ReflexElement flex={0.5}>

                                    <MomSysComponentLots lotInfo={lotInfo} />

                                </ReflexElement>

                                <ReflexSplitter
                                    style={{ width: layoutConf.reflex.splitterWidth }}
                                />

                                <ReflexElement flex={0.5}>

                                    <MomSysProductLots lotInfo={lotInfo} />

                                </ReflexElement>

                            </ReflexContainer>

                        </ReflexElement>

                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterHeight }}
                        />

                        <ReflexElement flex={0.5}>

                            <MomSysVwSgtLotTransLog lotInfo={lotInfo} />

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysLotTrace;
