/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysOrderBuyerDetailButton from "../input/MomSysOrderBuyerDetailButton";
import MomSysOrderBuyerPurchaseDetailUpdateButton from "../input/MomSysOrderBuyerPurchaseDetailUpdateButton";
import MomSysBusinessDetailDeleteButton from "../input/MomSysBusinessDetailDeleteButton";
import AccessControl from "../functional/AccessControl";
import { useTranslation } from "react-i18next";
import SaveToExcelButton from "../input/SaveToExcelButton";

function MomSysUglOrderBuyerDetail ( props ) {

    const {
        id,
        onSelectMst,
        onUpdateMaster,
        onDetailEmpty,
    } = props;

    const { t } = useTranslation ();

    const [ grid, setGrid ] = useState();

    const [ tableSchema, setTableSchema ] = useState();

    const [ isEmpty, setIsEmpty ] = useState();

    const [ detailApi, setDetailApi ] = useState();

    const [ , setNotification ] = useRecoilState(notificationState);
    
    useEffect ( () => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("MomSysUglOrderBuyerDetail");

            if ( mounted ) {

                for (const column of schema.columns) {
    
                    switch (column.name) {
        
                    case "orderBuyerId":
        
                        column.defaultValue = onSelectMst.orderBuyerId;
                        column.disabled = true;
        
                        break;
        
                    default:
        
                        break;
                    }
                }
        
                setTableSchema(schema);
    
            }
    
        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [] )

    useEffect ( () => {

        if ( detailApi ) {

            detailApi.refresh ();

        }

    }, [onSelectMst.orderBuyerId] )

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {
            
            onUpdateMaster ();

        }

    };

    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );
        
        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const notifyWarning = (msg) => {
        
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

        if ( onUpdateMaster ) {
            
            onUpdateMaster ();

        }
        
    };

    const onPartialFailure = ( msg ) => {

        notifyWarning ( msg );

        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    };
    
    const onModifyUrl = ( url, orderBuyerId ) => {

        return url + "/" + orderBuyerId;

    };

    const modifyUrlCallback = ( url ) => {

        let modifiedUrl = `${url}/${onSelectMst.orderBuyerId}`;
    
        return modifiedUrl;
    
    };

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onLoadData = (data) => {

        let isEmpty = data.length < 1 ? true : false;

        setIsEmpty(isEmpty);

        onDetailEmpty(isEmpty);

    };

    const onApiInitialized = ( api ) => {

        setDetailApi ( api );

    };

    const onDialogUpdateDetail = () => {

        setDetailApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

        if ( onUpdateMaster ) {

            onUpdateMaster();

        }

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    
    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                    
                    <MomSysOrderBuyerDetailButton
                        id={id}
                        schema={tableSchema}
                        disabled={Number.isInteger(onSelectMst.approvalState) ? true : false}
                        onSuccess = {onNotifySuccess}
                        modifyUrlCallback = {modifyUrlCallback}
                        onDialogUpdateDetail={onDialogUpdateDetail}
                        orderBuyerId={onSelectMst.orderBuyerId}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                    <MomSysOrderBuyerPurchaseDetailUpdateButton
                        id = {id}
                        schema = {tableSchema}
                        grid ={grid}
                        disabled={Number.isInteger(onSelectMst.approvalState) ? true : false || isEmpty}
                        onSuccess ={onNotifySuccess}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                    <MomSysBusinessDetailDeleteButton
                        id = {id}
                        schema = {tableSchema}
                        grid = {grid}
                        color = "secondary"
                        disabled = {Number.isInteger(onSelectMst.approvalState) ? true :false || isEmpty}
                        onSuccess = {onNotifySuccess}
                        onFailure={onFailure}
                        onPartialFailure={onPartialFailure}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="SAVETOEXCEL">
                    <SaveToExcelButton
                        id={id}
                        grid={grid}
                        view="MomSysZvwOrderBuyerDetail" 
                        color="primary"
                        exportFormatItem={() => {}}
                        disabled={isEmpty}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
    
        );
    };

    return (
    
        <BasicCrudScreenType 
            id={id}
            headerTitle={t("term.soDetail")}
            table="MomSysUglOrderBuyerDetail" 
            view="MomSysZvwOrderBuyerDetail" 
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={( url ) => onModifyUrl ( url, onSelectMst.orderBuyerId )}
            onInitialized = {onInitialized}
            onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
            noCreate
            noEdit
            noDelete
            noExcel
            onLoadData={onLoadData}
        />
    
    );

}

export default MomSysUglOrderBuyerDetail;
