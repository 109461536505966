import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";

function EguDefectiveStatus(props) {
    const id = "EguDefectiveStatus";

    const lotProcessFailViewName = "MomSysVwLotProcessFail";
    const inputInspectionFailViewName = "EguVwInputInspectionFail";
    const selfInspectionFailViewName = "EguVwSelfInspectionFail";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [screenViewName, setScreenViewName] = useState(lotProcessFailViewName);
    const [screenHeaderTitle, setScreenHeaderTitle] = useState(t("term.MomSysVwLotProcessFail"));

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const onClickMomSysVwLotProcessFailButton = () => {
        setScreenViewName(lotProcessFailViewName);
        setScreenHeaderTitle(t("term.MomSysVwLotProcessFail"));
    };
    const onClickEguVwInputInspectionFailButton = () => {
        setScreenViewName(inputInspectionFailViewName);
        setScreenHeaderTitle(t("term.EguVwInputInspectionFail"));
    };
    const onClickEguVwSelfInspectionFailButton = () => {
        setScreenViewName(selfInspectionFailViewName);
        setScreenHeaderTitle(t("term.EguVwSelfInspectionFail"));
    };

    const onAppendButtonInFrontOfSearch = () => (
        <BasicCrudScreenActionItemLayout>
            <ActionButtonLayout
                onClick={onClickMomSysVwLotProcessFailButton}
                variant={screenViewName === lotProcessFailViewName && "outlined"}
            >
                {t("buttonName.momSysVwLotProcessFail")}
            </ActionButtonLayout>

            <ActionButtonLayout
                onClick={onClickEguVwInputInspectionFailButton}
                variant={screenViewName === inputInspectionFailViewName && "outlined"}
            >
                {t("buttonName.eguVwInputInspectionFail")}
            </ActionButtonLayout>

            <ActionButtonLayout
                onClick={onClickEguVwSelfInspectionFailButton}
                variant={screenViewName === selfInspectionFailViewName && "outlined"}
            >
                {t("buttonName.eguVwSelfInspectionFail")}
            </ActionButtonLayout>
        </BasicCrudScreenActionItemLayout>
    );

    return (
        <div style={{ height: height }}>
            {screenViewName === lotProcessFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + screenViewName}
                    view={screenViewName}
                    headerTitle={screenHeaderTitle}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                    onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {screenViewName === inputInspectionFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + screenViewName}
                    view={screenViewName}
                    headerTitle={screenHeaderTitle}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                    onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {screenViewName === selfInspectionFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + screenViewName}
                    view={screenViewName}
                    headerTitle={screenHeaderTitle}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                    onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </div>
    );
}

export default EguDefectiveStatus;
