import MdIcon from "@material-ui/core/Icon";

function Icon ( props ) {

    const { iconType, children } = props;

    switch ( iconType ) {

        default:

            return <MdIcon>{ children }</MdIcon>;
            
    }

}

export default Icon;
