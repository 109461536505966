/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from "react";

import apiClient from "../../lib/common/apiClient";

function ReportViewer ( props ) {

    const { id, title, reportName, reportData, options } = props;

    const [ reportUrl, setReportUrl ] = useState ( "" );

    useEffect ( () => {

        if ( reportName && reportData ) {

        let url = ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')
            ?
            `/report/CommonReport`
            :
            `http://localhost:8080/report/CommonReport`;

        let form = new FormData ();

        let apiData = {
            title,
            reportName,
            reportData,
            downloadProtocolFile: options?.downloadProtocolFile === undefined ? "true" : options?.downloadProtocolFile.toString(),
            timeout: options?.timeout ?? 600
        };
        
        form.append ( "deleted", [] );
        form.append ( "json", JSON.stringify(apiData) );

        apiClient
            .post ( url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } )
            .then ( ( response ) => {

                let blob = new Blob ( [ response.data ], {
                    type: "text/html;charset=utf-8;"
                } );

                let url = window.URL.createObjectURL ( blob );

                setReportUrl ( url );
                
            } )            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ title, reportName, reportData, options]);

    return (
        <>
            <iframe
                src={reportUrl}
                name={`${id}-report-viewer`}
                style={{
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                }}
            />
        </>
    );

}

export default ReportViewer;
