import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DailyMaterialInOutReport (props){
    
    const table = "DailyMaterialInOutReport";
    const view = "DailyMaterialInOutReport";

    const { date, onSearchInitialized, onFilterInitialized, apiInitialized,} = props;

    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if ( date && api ) {
            
            api.refresh ();

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api, date ] );

    const onApiInitialized = ( api ) => {

        if(apiInitialized) {
            apiInitialized(api)
        } 

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {
        grid.allowSorting = false;
    };

    const onModifyUrl = ( url ) => {

        let newUrl = `${url}/${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`        

        return newUrl;
    };

    return (
    
        <BasicCrudScreenType
            id="DailyMaterialInOutReport"
            noHeader
            table={table}
            view={view}
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onFilterInitialized={onFilterInitialized}
            onInitialized={onGridInitialized}
        />

    );

}
export default DailyMaterialInOutReport;