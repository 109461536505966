/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateSoPoDetailButton from "../input/CraeateSoPoDetailButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import AccessControl from "../functional/AccessControl";

function PoDetail ( props ) {

    const {
        id,
        poId,
        poCost,
        onApiInitialized,
        poRefresh,
        exportFormatItem,
        approvalStateId,
    } = props;

    const [ grid, setGrid ] = useState();

    const [ tableSchema, setTableSchema ] = useState();

    const [ notification, setNotification ] = useRecoilState(notificationState);
    
    useEffect(() => {

        (async () => {
    
            let schema = await getSchema("PoDetail");
    
    
            for (const column of schema.columns) {
    
                switch (column.name) {
    
                case "poId":
    
                    column.defaultValue = poId;
                    column.disabled = true;
    
                    break;
    
                default:
    
                    break;
                }
            }
    
            setTableSchema(schema);
        })();
    
      }, [ poId, grid ] );

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onNotifySuccess = ( msg ) => {

        notifySuccess ( msg );

    };

    useEffect(() => {

        if ( notification.open ) {

            poRefresh.refresh()

        }
        
    }, [notification.open, poRefresh])

    const onModifyUrl = ( url, poId ) => {

        return url + "/" + poId;

    }

    const modifyUrlCallback = ( url ) => {

        let modifiedUrl = `${url}/${poId}`;
    
        return modifiedUrl;
    
    }

    const onInitialized = (grid) => {

        setGrid(grid);
    
    };

    const onAppendHeaderAtFirst = () => {

        return (
            
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                    <CreateSoPoDetailButton
                        id={id}
                        schema={tableSchema}
                        disabled={Number.isInteger(approvalStateId) ? true : false}
                        refresh ={poRefresh}
                        onSuccess = {onNotifySuccess}
                        modifyUrlCallback = {modifyUrlCallback}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                    <UpdateRecordButton
                        id = {id}
                        schema = {tableSchema}
                        grid ={grid}
                        disabled={Number.isInteger(approvalStateId) ? true : false || poCost === `0`}
                        refresh = {poRefresh}
                        onSuccess ={onNotifySuccess}
                    />
                </AccessControl>
        
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                    <DeleteRecordButton
                        id = {id}
                        schema = {tableSchema}
                        grid = {grid}
                        refresh ={poRefresh}
                        color = "secondary"
                        disabled = {Number.isInteger(approvalStateId) ? true :false || poCost === `0`}
                        onSuccess = {onNotifySuccess}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
    
        );
    };

    return (

        <BasicCrudScreenType 
            id={id} 
            table="PoDetail"
            view="VwPoDetail"
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={( url ) => onModifyUrl ( url, poId )}
            onInitialized = {onInitialized}
            embededInOtherScreenType
            onNotifySuccess={onNotifySuccess}
            onAppendHeaderAtFirst={onAppendHeaderAtFirst}
            exportFormatItem={exportFormatItem}
            noCreate
            noEdit
            noDelete
        />
                    
    );

}

export default PoDetail;
