/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import MomSysSelectStandardShipDetail from "../screens/MomSysSelectStandardShipDetail";
import AutoForm from "../form/functional/AutoForm";
import "../grid/layout/DataGridMomSysOrderPurchase.css";
import layoutConf from "../../config/layoutConf";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import getSpecific from "../../lib/api/getSpecific";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysShipActionDialog(props) {
    const {
        id,
        schema,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,

        onShipSelectMst,
        onDialogUpdateDetail,

        ...others
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(true);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [shipDetailGrid, setShipDetailGrid] = useState();
    const [shipDetailSelect, setShipDetailSelect] = useState();

    const [possibleShipLotGrid, setPossibleShipLotGrid] = useState();
    const [possibleShipLotSelect, setPossibleShipLotSelect] = useState();

    const [modifySchema, setModifySchema] = useState();

    const [initialData, setInitialData] = useState();

    useEffect(() => {
        if (schema) {
            let newSchema = { ...schema };

                for (let column of newSchema?.columns) {
                    switch (column.name) {
                        case "shipDate":
                            column.disabled = false;
                            break;
                        default:
                            break;
                    }
                }
            setModifySchema(newSchema);
        }
    }, [schema]);

    useEffect(() => {
        if (shipDetailGrid) {
            (async () => {

                shipDetailGrid.selectionChanging.addHandler((grid, e) => {
                    const row = e.getRow();
                    if (row && (row.dataItem.shipQty >= row.dataItem.shipmentQty)) {
                        e.cancel = true;
                    }
                });

                shipDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setShipDetailSelect();
                        }
                    }, 10)
                );

                shipDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setShipDetailSelect();
                        }
                    }, 10)
                );

                shipDetailGrid.loadedRows.addHandler((flex, event) => {
                    for (let row of flex.rows) {
                        if (Number(row.dataItem.shipmentQty) === Number(row.dataItem.shipQty)) {
                            row.cssClass = `not-regist-materialByCompany`;
                        }
                    }
                });
            })();
        }
    }, [shipDetailGrid]);

    useEffect(() => {
        if (possibleShipLotGrid) {
            (async () => {

                possibleShipLotGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipLotSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipLotSelect();
                        }
                    }, 10)
                );

                possibleShipLotGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipLotSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipLotSelect();
                        }
                    }, 10)
                );
            })();
        }
    }, [possibleShipLotGrid]);

    useEffect(() => {
        if (possibleShipLotGrid && shipDetailSelect) {
            (async () => {
                possibleShipLotGrid.loadedRows.addHandler(
                    debounce((flexGrid, event) => {
                        flexGrid.rows.forEach((v) => {
                            v.cssClass = null;
                        });

                        let toBeShippedQty = Number(shipDetailSelect.shipmentQty) - Number(shipDetailSelect.shipQty);

                        for (const row of flexGrid.rows) {
                            if (toBeShippedQty <= 0) {
                                break;
                            }

                            const qtyCurrent = Number(row.dataItem.qtyCurrent);

                            row.cssClass = "qty-equals-blue";
                            toBeShippedQty -= qtyCurrent;
                        }
                    }),
                    10
                );
            })();
        }
    }, [possibleShipLotGrid, shipDetailSelect]);

    useEffect(() => {
        if (shipDetailSelect && possibleShipLotSelect) {
            const remainShipQty = Number(shipDetailSelect.shipmentQty) - Number(shipDetailSelect.shipQty);
            const shipQty =
                remainShipQty > Number(possibleShipLotSelect.qtyCurrent)
                    ? Number(possibleShipLotSelect.qtyCurrent)
                    : remainShipQty;

            const shipMediumUnitQty = shipDetailSelect.materialMediumUnitQty * shipQty;
            const shipSmallUnitQty = shipDetailSelect.materialSmallUnitQty * shipQty;

            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    shipQty,
                    shipMediumUnitQty,
                    shipSmallUnitQty,

                    shipmentId: onShipSelectMst.shipmentId,
                    shipId: onShipSelectMst.shipId,
                    shipDetailId: shipDetailSelect.shipDetailId,

                    orderBuyerId: onShipSelectMst.orderBuyerId,
                    orderBuyerName: onShipSelectMst.orderBuyerName,
                    companyName: onShipSelectMst.companyName,
                    dueDate: onShipSelectMst.dueDate,

                    materialCode: shipDetailSelect.materialCode,
                    materialCodeVer: shipDetailSelect.materialCodeVer,
                    materialName: shipDetailSelect.materialName,
                    abbreviations: shipDetailSelect.abbreviations,
                    itemSpecName: shipDetailSelect.itemSpecName,
                    itemUnitName: shipDetailSelect.itemUnitName,

                    shipLotNo: shipDetailSelect.shipmentLotNoByItem,

                    shipmentQty: shipDetailSelect.shipmentQty,
                    shipmentMediumUnitQty: shipDetailSelect.shipmentMediumUnitQty,
                    shipmentSmallUnitQty: shipDetailSelect.shipmentSmallUnitQty,

                    lotOrderBuyerId: possibleShipLotSelect.orderBuyerId,
                    remainShipQty: remainShipQty,
                };

                return newFormData;
            });

            setInitialData((initalData) => {
                let newInitData = {
                    ...initalData,

                    shipQty,
                    shipMediumUnitQty,
                    shipSmallUnitQty,

                    shipmentId: onShipSelectMst.shipmentId,
                    shipId: onShipSelectMst.shipId,
                    shipDetailId: shipDetailSelect.shipDetailId,

                    orderBuyerId: onShipSelectMst.orderBuyerId,
                    orderBuyerName: onShipSelectMst.orderBuyerName,
                    companyName: onShipSelectMst.companyName,
                    dueDate: onShipSelectMst.dueDate,

                    materialCode: shipDetailSelect.materialCode,
                    materialCodeVer: shipDetailSelect.materialCodeVer,
                    materialName: shipDetailSelect.materialName,
                    abbreviations: shipDetailSelect.abbreviations,
                    itemSpecName: shipDetailSelect.itemSpecName,
                    itemUnitName: shipDetailSelect.itemUnitName,

                    shipLotNo: shipDetailSelect.shipmentLotNoByItem,

                    shipmentQty: shipDetailSelect.shipmentQty,
                    shipmentMediumUnitQty: shipDetailSelect.shipmentMediumUnitQty,
                    shipmentSmallUnitQty: shipDetailSelect.shipmentSmallUnitQty,

                    lotNo: possibleShipLotSelect.lotNo,
                    extLotNo: possibleShipLotSelect.extLotNo,
                    manufacturedDate: possibleShipLotSelect.manufacturedDate,
                    expirationDate: possibleShipLotSelect.expirationDate,
                    wareHouseName: possibleShipLotSelect.wareHouseName,

                    lotOrderBuyerId: possibleShipLotSelect.orderBuyerId,
                    remainShipQty: remainShipQty,
                };

                return newInitData;
            });

            if (Number(shipDetailSelect.shipmentQty) === Number(shipDetailSelect.shipQty)) {
                let newSchema = { ...modifySchema };

                for (let column of newSchema.columns) {
                    switch (column.name) {
                        case "shipQty":
                        case "shipMediumUnitQty":
                        case "shipSmallUnitQty":
                        case "description":
                            column.disabled = true;
                            break;
                        default:
                            break;
                    }
                }
                setModifySchema(newSchema);
                setOkButtonDisabled(true);
            } else {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        lotNo: possibleShipLotSelect.lotNo,
                        extLotNo: possibleShipLotSelect.extLotNo,
                        manufacturedDate: possibleShipLotSelect.manufacturedDate,
                        expirationDate: possibleShipLotSelect.expirationDate,
                        wareHouseName: possibleShipLotSelect.wareHouseName,
                        lotOrderBuyerId: possibleShipLotSelect.orderBuyerId,
                        remainShipQty: remainShipQty,
                        shipLotNo: shipDetailSelect.shipmentLotNoByItem,
                        shipQty,
                        shipMediumUnitQty,
                        shipSmallUnitQty,
                    };

                    return newFormData;
                });
                setInitialData((initalData) => {
                    let newInitData = {
                        ...initalData,

                        lotNo: possibleShipLotSelect.lotNo,
                        extLotNo: possibleShipLotSelect.extLotNo,
                        manufacturedDate: possibleShipLotSelect.manufacturedDate,
                        expirationDate: possibleShipLotSelect.expirationDate,
                        wareHouseName: possibleShipLotSelect.wareHouseName,
                        lotOrderBuyerId: possibleShipLotSelect.orderBuyerId,
                        remainShipQty: remainShipQty,
                        shipLotNo: shipDetailSelect.shipmentLotNoByItem,
                        shipQty,
                        shipMediumUnitQty,
                        shipSmallUnitQty,
                    };

                    return newInitData;
                });

                let newSchema = { ...modifySchema };
                for (let column of newSchema.columns) {
                    switch (column.name) {
                        case "shipQty":
                        case "shipMediumUnitQty":
                        case "shipSmallUnitQty":
                        case "description":
                            column.disabled = false;
                            break;
                        default:
                            break;
                    }
                }
                setModifySchema(newSchema);
                setOkButtonDisabled(false);
            }
        } else {
            setFormData((formData) => {
                let newFormData = {
                    ...formData,

                    lotNo: undefined,
                    shipQty: undefined,
                    shipMediumUnitQty: undefined,
                    shipSmallUnitQty: undefined,
                    extLotNo: undefined,
                    manufacturedDate: undefined,
                    expirationDate: undefined,
                    wareHouseName: undefined,
                    lotOrderBuyerId: undefined,
                    remainShipQty: undefined,
                };

                return newFormData;
            });

            setInitialData((initalData) => {
                let newInitData = {
                    ...initalData,

                    lotNo: undefined,
                    shipQty: undefined,
                    shipMediumUnitQty: undefined,
                    shipSmallUnitQty: undefined,
                    extLotNo: undefined,
                    manufacturedDate: undefined,
                    expirationDate: undefined,
                    wareHouseName: undefined,
                    lotOrderBuyerId: undefined,
                    remainShipQty: undefined,
                };

                return newInitData;
            });

            if (modifySchema) {
                let newSchema = { ...modifySchema };

                for (let column of newSchema?.columns) {
                    switch (column.name) {
                        case "shipQty":
                        case "shipMediumUnitQty":
                        case "shipSmallUnitQty":
                        case "description":
                            column.disabled = true;
                            break;
                        default:
                            break;
                    }
                }
                setModifySchema(newSchema);
            }
            setOkButtonDisabled(true);
        }
    }, [shipDetailSelect, possibleShipLotSelect]);

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (column.name === "shipQty") {
                if (Number(formData["shipQty"]) + Number(shipDetailSelect.shipQty) > Number(formData["shipmentQty"])) {
                    valid = false;
                    msg = t("error.failToShipQtyThanShipmentQty");

                } else if (parseFloat(formData["shipQty"].toFixed(5)) === 0) {
                    valid = false;
                    msg = t("error.positiveFloatRequired");
                }
            }

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {

        setKeepOpen((keepOpen) => {

            setOkButtonDisabled(false);

            if (!keepOpen) {
                dialogInstance.hide();
            } else {
                if (shipDetailGrid) {
                    (async () => {
                        let shipDetailMst = await getSpecific(
                            "MomSysVwShipDetail",
                            shipDetailGrid.selectedItems[0].shipDetailId
                        );

                        shipDetailGrid.selectedItems[0].shipQty = shipDetailMst.shipQty;
                        shipDetailGrid.selectedItems[0].shipMediumUnitQty = shipDetailMst.shipMediumUnitQty;
                        shipDetailGrid.selectedItems[0].shipSmallUnitQty = shipDetailMst.shipSmallUnitQty;
                        shipDetailGrid.refresh(false);

                        setTimeout(() => setShipDetailSelect(undefined), 0);
                        setTimeout(() => setShipDetailSelect(shipDetailGrid.selectedItems[0]), 0);
                    })();
                }
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {

        setOkButtonDisabled(false);

        notifyFailure(
            t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
        );
    };

    const onOk = (schema, formData) => {

        setOkButtonDisabled(true);

        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");
            setOkButtonDisabled(false);
            return;

        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                setOkButtonDisabled(false);
                return;
            }
        }

        /*---------------------------------------------------------------*/
        if (String(formData.shipQty).includes(".")) {
            notifyWarning(t("warning.formDataValidationFail", { msg: t("error.invalidQtyNumber") }));
            setOkButtonDisabled(false);
            return;
        }
        /*---------------------------------------------------------------*/

        // build base api url

        let url = `/api/Material/${shipDetailSelect.orderBuyerId}/ship`;

        let apiData = { ...formData, insertDelivery: onShipSelectMst?.insertDelivery };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }
        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        dialogInstance.hide();

        setFormData();

        setShipDetailSelect();
        setPossibleShipLotSelect();

        setOkButtonDisabled(true);
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {

        setFormData(formData);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setOkButtonDisabled(true);
        setFormData();
        setInitialData();
        setShipDetailSelect();
        setPossibleShipLotSelect();

        if (onClose) {
            onClose();
        }
    };

    const collectionViewChange = (collectionView) => {
        let notExist = true;
        if (collectionView) {
            for (let [index, item] of collectionView.items.entries()) {
                if (item.shipQty !== item.shipmentQty) {
                    notExist = false;
                    collectionView.currentItem = null;
                    collectionView.currentPosition = index ?? -1;
                    break;
                }
            }
            if (notExist) {
                collectionView.currentItem = null;
                collectionView.currentPosition = -1;
            }
        }

    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.MomSysShip.create")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.65}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement flex={0.5} data-cy="Dialog-Master">
                                <BasicCrudScreenType
                                    id="MomSysVwShipDetailDialog"
                                    embededInOtherScreenType
                                    view="MomSysVwShipDetail"
                                    headerTitle={t("term.shipDetail")}
                                    noExcel
                                    onModifyViewUrl={(url) => `${url}/detail/${onShipSelectMst.shipId}`}
                                    onInitialized={(grid) => {
                                        grid.selectionMode = "Row";
                                        setShipDetailGrid(grid);
                                    }}
                                    collectionViewChange={collectionViewChange}
                                />
                            </ReflexElement>

                            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                            <ReflexElement flex={0.5} data-cy="Dialog-Detail">
                                {shipDetailSelect ? (
                                    <MomSysSelectStandardShipDetail
                                        onSelect={shipDetailSelect}
                                        onInitialized={(grid) => {
                                            grid.selectionMode = "Row";
                                            setPossibleShipLotGrid(grid);
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>

                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                    <ReflexElement flex={0.35}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="ship-lot-create-form"
                                schema={modifySchema}
                                mode="edit"
                                initialData={initialData}
                                onChange={onChangeFormData}
                                modifyFormDataCallback={(formData, name) => {
                                    if (shipDetailSelect) {
                                        let numShipQty = Number(formData.shipQty);

                                        if (name === "shipMediumUnitQty") {
                                            numShipQty =
                                                Number(formData.shipMediumUnitQty) /
                                                shipDetailSelect.materialMediumUnitQty;
                                        } else if (name === "shipSmallUnitQty") {
                                            numShipQty =
                                                Number(formData.shipSmallUnitQTy) /
                                                shipDetailSelect.materialSmallUnitQty;
                                        }

                                        formData.shipQty = numShipQty;
                                        formData.shipMediumUnitQty =
                                            shipDetailSelect.materialMediumUnitQty * numShipQty;
                                        formData.shipSmallUnitQty = shipDetailSelect.materialSmallUnitQty * numShipQty;
                                    }
                                }}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysShipActionDialog;
