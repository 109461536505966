import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

function EguPopProductManageDetail(props) {

    const {
        id,
        view,
        planId,
        detailApiInitialized,
        titleName,
    } = props;

    const { t } = useTranslation();

    const [reportUrl, setReportUrl] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();

    useEffect(() => {

        if (api) {
            api.refresh();
        }


        let url = `http://localhost:8080/report/EguVwPopProductManageDetailByTotal/${planId}/${t("report.inputMaterialList")}`;

        if ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {

            url = `/report/EguVwPopProductManageDetailByTotal/${planId}/${t("report.inputMaterialList")}`;

        }

        setReportUrl ( url );
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planId]);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.loadedRows.addHandler((flex, e) => {
                    for(const row of flex.rows) {
                        let rowData = row._data;
                        row.cssClass = rowColor(rowData.requiredQty, rowData.inputQty);
                    }
                });
            }

            function rowColor(requiredQty, inputQty) {
                if(requiredQty === 0) {
                    return null;
                }

                if(requiredQty === inputQty) {
                    return `qty-equals-blue`;
                }

                if(requiredQty < inputQty) {
                    return `qty-many-orange`;
                }

                if(requiredQty > inputQty || inputQty === 0) {
                    return `qty-little-red`;
                }

                return null;
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onInitialized = (grid) => {

        grid.selectionMode = "None";
        grid.childItemsPath = "material";
        setGrid(grid)

    }

    const onApiInitialized = (api) => {

        detailApiInitialized(api)
        setApi(api);

    };

    const onModifyViewUrl = (url, planId) => {

        return url + "/" + planId;

    };

    return (

        <BasicCrudScreenType
            id={"popDetail"}
            headerTitle={`${id === 'WorkRecordsDetailByTotal' ? t(`term.${id}`) : titleName + ' 상세'}`}
            view={view}
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onModifyViewUrl={(url) => onModifyViewUrl(url, planId)}
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onAppendButtonInFrontOfSearch={
                () => (
                    <PopCrudScreenActionItemLayout>
                        <PrintReportButton
                            noLayout
                            url={reportUrl}
                            disabled={!planId}
                        >
                            {t("buttonName.lotInfoPrint")}
                        </PrintReportButton>
                    </PopCrudScreenActionItemLayout>
                )
            }
        />

    );

}

export default EguPopProductManageDetail;
