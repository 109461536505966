/* eslint-disable react-hooks/exhaustive-deps */
import { Children, cloneElement, useEffect, useState } from "react";

function Pagination ( props ) {

    const { size, onInitialized, children, ...others } = props;

    const [ page, setPage ] = useState(1);

    useEffect ( () => {

        if ( onInitialized ) {

            let myInstance = {

                set: ( page ) => setPage ( page <= 0 ? 1 : page )

            };

            onInitialized ( myInstance );

        }
    }, [] );
    
    return (
        <>
            {
                Children.map(children, (child => 
                    cloneElement(child, {...others, $start: ( page - 1 ) * size, $length: size } )
                ))
            }
        </>
    );

}

export default Pagination;

