import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateEstimateRecordButton from "../input/CreateEstimateRecordButton";
import EstimateFileDownloadButton from "../input/EstimateFileDownloadButton";

import AccessControl from "../functional/AccessControl";

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";

import { useRecoilValue } from "recoil";
import authProfileState from "../../states/authProfileState";
import { useTranslation } from "react-i18next";

import { CellType } from "@grapecity/wijmo.grid";

import notificationState from "../../states/notificationState";

function EstimateVersion(props) {
    const { id, estimateNumber, onInitialized } = props;

    const [data, setData] = useState();

    const [schema, setSchema] = useState();
    const [grid, setGrid] = useState();

    // Elements of estimateVersionInstance
    const [estimateVersionViewListInstance, setEstimateVersionViewListInstance] = useState();
    const [estimateId, setEstimateId] = useState();
    const [versionMajor, setVersionMajor] = useState();
    const [versionMinor, setVersionMinor] = useState();

    const [disabled, setDisabled] = useState(false);

    const { t } = useTranslation();

    const authProfile = useRecoilValue(authProfileState);

    const [approvalCtx, setApprovalCtx] = useState();

    // eslint-disable-next-line no-unused-vars
    const [notification, setNotification] = useRecoilState(notificationState);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyError = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    useEffect(() => {
        let mounted = true;
        (async () => {
            let schema = await getSchema("Estimate");
            if ( mounted ) {
                setSchema(schema);
            }
        })();
        return ( () => {
            mounted = false;
        } );
    }, []);

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 4, // 견적 승인
            tableName: "Estimate",
            statusColumn: "approvalStateId",
            schema: schema,
            onValidateApprovalItem: (item) => {
                if (item.lastWriter !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.estimate", {
                    estimateNumber: item.estimateNumber,
                    versionMajor: item.versionMajor,
                    versionMinor: item.versionMinor,
                })}`;
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);

    useEffect(() => {
        if (grid) {
            grid.selectionChanged.addHandler((grid, event) => {
                setEstimateId(grid.selectedItems[0] && grid.selectedItems[0].estimateId);
                setVersionMajor(grid.selectedItems[0] && grid.selectedItems[0].versionMajor);
                setVersionMinor(grid.selectedItems[0] && grid.selectedItems[0].versionMinor);
            });

            grid.itemsSourceChanged.addHandler((grid, event) => {
                if (grid.selectedItems[0]) {
                    setEstimateId(grid.selectedItems[0].estimateId);
                    setVersionMajor(grid.selectedItems[0].versionMajor);
                    setVersionMinor(grid.selectedItems[0].versionMinor);
                }
            });
        }
    }, [grid]);

    useEffect(() => {
        if ( estimateNumber ) {
            setDisabled ( false );
        } else {
            setDisabled ( true );
        }

        if (estimateVersionViewListInstance) {
            estimateVersionViewListInstance.refresh();
        }
    }, [estimateVersionViewListInstance, estimateNumber]);

    useEffect(() => {
        let estimateVersionInstance = {
            viewListInstance: estimateVersionViewListInstance,
            estimateId: estimateId,
        };

        if (onInitialized) {
            onInitialized(estimateVersionInstance);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimateVersionViewListInstance, estimateId]);

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;

        return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;
    };

    const onAppendHeaderAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={"Estimate"} accessMethod="GET">
                    <CreateEstimateRecordButton
                        id={id}
                        schema={schema}
                        disabled={disabled}
                        estimateNumber={estimateNumber}
                        refresh={estimateVersionViewListInstance}
                        onSuccess={notifySuccess}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    ></CreateEstimateRecordButton>
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        );
    };

    const onAppendHeaderAtLast = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={"Estimate"} accessMethod="GET">
                    <EstimateFileDownloadButton
                        id={id}
                        disabled={disabled}
                        estimateNumber={estimateNumber}
                        versionMajor={versionMajor}
                        versionMinor={versionMinor}
                    ></EstimateFileDownloadButton>
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        );
    };

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (data[row][panel.columns[col].binding]) {
                        case 1:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");
                            break;

                        case 2:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");
                            break;

                        case 3:
                            break;

                        case 5:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");
                            break;

                        case 6:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
        }
    };

    return (
        <BasicCrudScreenType
            id={id}
            table="Estimate"
            view="VwEstimateVersion"
            disableCreate={disabled}
            disableEdit={disabled || !versionMajor}
            disableDelete={disabled || !versionMajor}
            disableExcel={disabled || !versionMajor}
            disableApproval={disabled || !versionMajor}
            onInitialized={(grid) => {
                setGrid(grid);
            }}
            onApiInitialized={(instance) => {
                setEstimateVersionViewListInstance(instance);
            }}
            approvalCtx={approvalCtx}
            onLoadData={(data) => setData(data)}
            exportFormatItem={exportFormatItem}
            onModifyViewUrl={(url) => url + "/" + estimateNumber}
            onNotifySuccess={notifySuccess}
            onNotifyError={notifyError}
            onAppendHeaderAtFirst={onAppendHeaderAtFirst}
            onAppendHeaderAtLast={onAppendHeaderAtLast}
            noCreate
            noEdit
            embededInOtherScreenType
        ></BasicCrudScreenType>
    );
}

export default EstimateVersion;
