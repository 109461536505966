/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { debounce } from "lodash";
import EguOutSourcingLogShip from "./EguOutSourcingLogShip";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import EguOutSourcingShipActionButton from "../input/EguOutSourcingShipActionButton";
import getSchema from "../../lib/api/getSchema";
import EguOutSourcingAllShipButton from "../input/EguOutSourcingAllShipButton";
import defineConf from "../../config/defineConf";

function EguOutSourcingShipDetail(props) {
    const { onSelectMst, title, onUpdateMaster, onSuccess, onFailure, isMstEmpty } = props;

    const id = "EguOutSourcingShipDetail";
    const table = "EguOutSourcingShipDetail";

    const { t } = useTranslation();

    const [eguOutSourcingShipDetailSchema, setEguOutSourcingShipDetailSchema] = useState();
    const [eguOutSourcingShipDetailGrid, setEguOutSourcingShipDetailGrid] = useState();
    const [eguOutSourcingShipDetailApi, setEguOutSourcingShipDetailApi] = useState();
    const [eguOutSourcingShipDetailSelect, setEguOutSourcingShipDetailSelect] = useState();

    const [eguOutSourcingLogShipApi, setEguOutSourcingLogShipApi] = useState();

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setEguOutSourcingShipDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (eguOutSourcingShipDetailApi) {
            eguOutSourcingShipDetailApi.refresh();
        }
    }, [onSelectMst?.outSourcingShipId]);

    useEffect(() => {
        (async () => {
            if (eguOutSourcingShipDetailGrid) {
                eguOutSourcingShipDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingShipDetailSelect([]);
                        }
                    }, 10)
                );

                eguOutSourcingShipDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingShipDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingShipDetailSelect([]);
                        }
                    }, 10)
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eguOutSourcingShipDetailGrid]);

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={0.6} data-cy="Detail">
                <BasicCrudScreenType
                    id={id}
                    headerTitle={t(title)}
                    view="EguVwOutSourcingShipDetail"
                    onInitialized={(grid) => {
                        grid.selectionMode = "Row";

                        setEguOutSourcingShipDetailGrid(grid);
                    }}
                    onApiInitialized={(api) => {
                        setEguOutSourcingShipDetailApi(api);
                    }}
                    onModifyViewUrl={(url) => `${url}/${onSelectMst.outSourcingShipId}`}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                    onAppendButtonInFrontOfSearch={() => (
                        <BasicCrudScreenActionItemLayout>
                            <EguOutSourcingAllShipButton
                                id="EguOutSourcingShipAll"
                                schema={eguOutSourcingShipDetailSchema}
                                outSourcingShipMst={onSelectMst}
                                refresh={eguOutSourcingShipDetailApi}
                                onUpdateMaster={() => {
                                    if (onUpdateMaster) {
                                        onUpdateMaster();
                                    }

                                    if (eguOutSourcingLogShipApi) {
                                        eguOutSourcingLogShipApi.refresh();
                                    }
                                }}
                                disabled={
                                    onSelectMst.outSourcingStatus !== defineConf.outSourcingStatus.NONE_COMPLETE ||
                                    isMstEmpty
                                }
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />

                            <EguOutSourcingShipActionButton
                                id="EguOutSourcingShip"
                                schema={eguOutSourcingShipDetailSchema}
                                outSourcingShipMst={onSelectMst}
                                refresh={eguOutSourcingShipDetailApi}
                                onUpdateMaster={() => {
                                    if (onUpdateMaster) {
                                        onUpdateMaster();
                                    }

                                    if (eguOutSourcingLogShipApi) {
                                        eguOutSourcingLogShipApi.refresh();
                                    }
                                }}
                                disabled={
                                    onSelectMst.outSourcingStatus !== defineConf.outSourcingStatus.NONE_COMPLETE ||
                                    isMstEmpty
                                }
                                onSuccess={onSuccess}
                            />
                        </BasicCrudScreenActionItemLayout>
                    )}
                />
            </ReflexElement>

            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

            <ReflexElement flex={0.4} data-cy="DetailLot">
                {eguOutSourcingShipDetailSelect ? (
                    <EguOutSourcingLogShip
                        title={t("term.outSourcingLogShip")}
                        onSelectMst={eguOutSourcingShipDetailSelect}
                        onLogShipApi={setEguOutSourcingLogShipApi}
                    />
                ) : (
                    <></>
                )}
            </ReflexElement>
        </ReflexContainer>
    );
}

export default EguOutSourcingShipDetail;
