/* eslint-disable react-hooks/exhaustive-deps */
import { Children, cloneElement, useState, useEffect } from "react";

function OrderBy ( props ) {

    const { schema, grid, fixedOrder, children, onChange, onInitialized, ...others } = props;

    const [ , setRestored ] = useState ( false );
    const [ orderBy, setOrderBy ] = useState ( [] );

    useEffect ( () => {

        let myInstance = {
            
            setOrderBy: ( value ) => {

                setOrderBy ( value );
        
            }

        }

        if ( onInitialized ) {

            onInitialized ( myInstance );

        }

    }, [] );

    useEffect ( () => {

        if ( fixedOrder ) {

            setOrderBy ( fixedOrder );

        }

    }, [fixedOrder] );

    useEffect ( () => {

        if ( grid && schema ) {

            setRestored ( ( restored ) => {

                if ( ! restored ) {

                    let orderByString = localStorage.getItem ( `${schema.name}-sort-description` );

                    if ( orderByString ) {

                        setOrderBy ( JSON.parse ( orderByString ) );

                    } else {

                        if ( schema.orderBy ) {

                            setOrderBy ( schema.orderBy );

                        }

                    }

                    restored =  true;

                }

                return restored;

            } );

            grid.sortedColumn.addHandler ( ( grid, event ) => {

                let orderBy = [];

                for ( let sortDescription of grid.collectionView.sortDescriptions ) {
    
                    let column = sortDescription.property;
                    let order = sortDescription.ascending ? "ASC" : "DESC";
    
                    orderBy.push ( { column, order } );
    
                }

                if ( schema ) {

                    localStorage.setItem ( `${schema.name}-sort-description`, JSON.stringify(orderBy) );

                }
    
                setOrderBy ( orderBy );
    
                if ( onChange ) {

                    setTimeout ( () => onChange ( orderBy ), 0 );

                }

            } );

        }

    }, [ grid, schema ] );

    return (
        <>
            {
                Children.map(children, (child => 
                    cloneElement(child, {...others, $orderBy: JSON.stringify(orderBy) } )
                ))
            }
        </>
    );

}

export default OrderBy;

