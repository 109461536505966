import React, { useEffect, useState } from "react";
import defineConf from "../../config/defineConf";
import { useRecoilState, } from "recoil";
import notificationState from "../../states/notificationState";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AccessControl from "../functional/AccessControl";
import getSchema from "../../lib/api/getSchema";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import PopCustomerSupplyInputButton from "../input/PopCustomerSupplyInputButton";
import getExpirationDate from "../../lib/utils/getExpirationDate";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import PrintReportButton from "../input/PrintReportButton";

function PopCustomerSupplyInput(props) {
    const id = "VwPrePopCustomerSupplyInput";
    const table = "MomSysCustomerSupplyInput";
    const view = "MomSysVwCustomerSupplyMaterial";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [PrePopCustomerSupplyInputSchema, setPrePopCustomerSupplyInputSchema] = useState();
    const [PrePopCustomerSupplyInputGrid, setPrePopCustomerSupplyInputGrid] = useState();
    const [PrePopCustomerSupplyInputApi, setPrePopCustomerSupplyInputApi] = useState();
    const [, setPrePopCustomerSupplyInputDataIsEmpty] = useState();
    const [PrePopCustomerSupplyInputSelect, setPrePopCustomerSupplyInputSelect] = useState({});


    const [CustomerSupplyInputSelect, setCustomerSupplyInputSelect] = useState({});
    const [CustomerSupplyInputGrid, setCustomerSupplyInputGrid] = useState();
    const [CustomerSupplyInputApi, setCustomerSupplyInputApi] = useState();
    const [, setCustomerSupplyInputDataIsEmpty] = useState();
    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            schema.columns.push({
                    name: "inputDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    required: false,
                    default: null,
                    formGroup: 1,
                    formOrder: 210,
                    formItemType: "date",
                })
            if (mounted) {

                if (schema) {
                    for (const column of schema.columns) {
                        switch (column.name) {
                            case "wareHouseCode":
                                column.hideInForm = false;
                                column.required = true;
                                column.formOrder = 25;

                                break;
                            case "orderBuyerId":
                                column.disabled = true;
                                column.required = true;

                                column.formGroup = 0;
                                break;

                            case "companyCode":
                                column.disabled = true;
                                column.required = true;

                                column.formGroup = 0;

                                break;

                            // case "description":
                            //     column.required = true;

                            //     break;

                            default:
                                break;

                        }

                    }

                    setPrePopCustomerSupplyInputSchema(schema);
                }
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {

        const path = `customerSupplyInput/${CustomerSupplyInputSelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl ( url );

    }, [CustomerSupplyInputSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (PrePopCustomerSupplyInputGrid && mounted) {
                PrePopCustomerSupplyInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPrePopCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setPrePopCustomerSupplyInputSelect({});
                        }
                    }, 10)
                );

                PrePopCustomerSupplyInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPrePopCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setPrePopCustomerSupplyInputSelect({});
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [PrePopCustomerSupplyInputGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (CustomerSupplyInputGrid && mounted) {
                CustomerSupplyInputGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyInputSelect({});
                        }
                    }, 10)
                );

                CustomerSupplyInputGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyInputSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyInputSelect({});
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [CustomerSupplyInputGrid]);

    useEffect(() => {
        if (CustomerSupplyInputApi) {
            CustomerSupplyInputApi.refresh();
        }
    }, [PrePopCustomerSupplyInputSelect, CustomerSupplyInputApi])
    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const setInitialDataCallback = (formData) => {

        formData.manufacturedDate = formData.manufacturedDate ?? new Date();
        formData.expirationDate = getExpirationDate(
            PrePopCustomerSupplyInputSelect.standardExpirationDate,
            new Date(formData.manufacturedDate)
        );
        formData.orderBuyerId = PrePopCustomerSupplyInputSelect.orderBuyerId;
        formData.materialCode = PrePopCustomerSupplyInputSelect.materialCode;
        formData.materialCodeVer = PrePopCustomerSupplyInputSelect.materialCodeVer;
        formData.materialName = PrePopCustomerSupplyInputSelect.materialName;
        formData.itemSpecName = PrePopCustomerSupplyInputSelect.itemSpecName;
        formData.itemUnitName = PrePopCustomerSupplyInputSelect.itemUnitName;
        formData.companyCode = PrePopCustomerSupplyInputSelect.companyCode;

        formData.extLotNo = "extLot";
    }
    const modifyFormDataCallback = (newFormData, name, value) => {
        if (newFormData.qcResult === defineConf.qcResult.QC_OK) {
            newFormData.defectiveCode = "INS000";
        }
        if (name === "manufacturedDate") {

            newFormData.expirationDate = getExpirationDate(
                PrePopCustomerSupplyInputSelect.standardExpirationDate,
                new Date(newFormData.manufacturedDate)
            );
        }
        if (name === "qtyInput" || name === "inPrice") {
            newFormData.inAmount = newFormData.qtyInput * newFormData.inPrice;
        }
    }

    // const onModifyViewUrl = () => {
    //     return `/api/MomSysVwCustomerSupplyInput/${PrePopCustomerSupplyInputSelect?.orderBuyerId}/${PrePopCustomerSupplyInputSelect?.materialCode}/${PrePopCustomerSupplyInputSelect?.materialCodeVer}`
    // }

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        // table={table}
                        view={view}
                        headerTitle={t("term.MomSysCustomerSupplyInput")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPrePopCustomerSupplyInputGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPrePopCustomerSupplyInputApi(api);
                        }}
                        onLoadData={(data) => {
                            setPrePopCustomerSupplyInputDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>

                                <AccessControl
                                    accessTarget={PrePopCustomerSupplyInputSchema && PrePopCustomerSupplyInputSchema.name}
                                    accessMethod="POST"
                                >
                                    <PopCustomerSupplyInputButton
                                        id={id}
                                        schema={PrePopCustomerSupplyInputSchema}
                                        refresh={PrePopCustomerSupplyInputApi}
                                        onSuccess={onSuccess}
                                        noKeepOpen
                                        setInitialDataCallback={setInitialDataCallback}
                                        modifyFormDataCallback={modifyFormDataCallback}
                                        disabled={!PrePopCustomerSupplyInputSelect.materialCode}
                                    />
                                </AccessControl>
                            </PopCrudScreenActionItemLayout>
                        )}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    />
                </ReflexElement>
                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />
                <ReflexElement data-cy="Detail">
                    <BasicCrudScreenType
                        id="MomSysCustomerSupplyInput"
                        view="MomSysVwCustomerSupplyInput"
                        headerTitle={t("term.MomSysCustomerSupplyInput")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setCustomerSupplyInputGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setCustomerSupplyInputApi(api);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <PopCrudScreenActionItemLayout>
                                <PrintReportButton
                                    noLayout
                                    url={reportUrl}
                                    disabled={!CustomerSupplyInputSelect?.lotNo}
                                >
                                    {t("buttonName.lotInfoPrint")}
                                </PrintReportButton>
                            </PopCrudScreenActionItemLayout>
                        )}
                        onLoadData={(data) => {
                            setCustomerSupplyInputDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                    // onModifyViewUrl={onModifyViewUrl}

                    />
                </ReflexElement>
            </ReflexContainer>
        </div >
    );
}

export default PopCustomerSupplyInput;
