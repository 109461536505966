/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import authConf from "../../config/authConf";

import login from "../../lib/auth/login";
import checkLogin from "../../lib/auth/checkLogin";
import authProfileState from "../../states/authProfileState";

import LoginLayout from "../layout/LoginLayout";
import apiClient from "../../lib/common/apiClient";

// login form 유지를 위한 recoil state

function Login ( props ) {

    const { tenant } = props;

    const authProfile = useRecoilValue ( authProfileState );
    const [ loginForm, setLoginForm ] = useState( { username: "", password: "", tenant: tenant } );
    const [ formDisabled, setFormDisabled ] = useState ( false );
    const [ storeCompanyId, setStoreCompanyId ] = useState ( false );


    useEffect ( () => {

        let jsonStr = localStorage.getItem ( "radixMrm.storedId" );

        let tenantName = tenant;
        let companyAndId;

        if ( jsonStr ) {

            try {

                companyAndId = JSON.parse ( jsonStr );
                setStoreCompanyId ( true );

            } catch ( reason ) {

                localStorage.removeItem ( "radixMrm.storedId" );
                companyAndId = {};

            }

        } else {

            companyAndId = {};

        }

        if ( !tenantName ) {

            tenantName = companyAndId.tenant;

        }

        setLoginForm ( ( loginForm ) => ( { ...loginForm, username: companyAndId.username, tenant: tenantName } ));

    }, [tenant])

    const onChangeTenant = ( tenant ) => {

        setLoginForm ( ( loginForm ) => {

            if ( loginForm.tenant !== tenant ) {

                loginForm = { ...loginForm, tenant: tenant };
    
            }

            return loginForm;
    
        } );

    }

    // username 입력시 recoil state update

    const onChangeUserName = ( event ) => {

        let newUserName = event.target.value;

        setLoginForm ( ( loginForm ) => {

            if ( loginForm.username !== newUserName ) {

                loginForm = { ...loginForm, username: newUserName };

            }

            return loginForm;

        } );

    }

    // password 입력 시 recoil state update

    const onChangePassword = ( event ) => {

        let newPassword = event.target.value;

        if ( loginForm.password !== newPassword ) {

            setLoginForm ( { ...loginForm, password: newPassword } );

        }

    }

    // 주기적인 로그인 상태 확인 및 JWT Token과 Cookie Update

    const doCheckLogin = async () => {

        try {

            let newAuthProfile = await checkLogin ();

            newAuthProfile.loggedIn = true;

            props.onUpdateProfile( newAuthProfile );
            setTimeout ( doCheckLogin, authConf.checkLoginPeriod );

        } catch ( reason ) {

            props.onLogOut ();

        }

    };

    // Browser를 reload하면 모든 recoil state와 timer는 유실되고 따라서 인증 상태를 잃어버리고 다시 로그인 해야 하는 상태가 됨
    // 이를 피하기 위해 최초 기동 시 Browser의 Cookie 가진 상태로 checkLogin API를 호출하여 기존 로그인 상태를 유지하고 authProfile을 다시 설정함

    useEffect ( () => {

        doCheckLogin ();

    }, [] );

    // login button 클릭 시 login API 호출 후 성공 시 onLogin callback 호출
    // 주기적으로 doCheckLogin 실행하도록 타이머 설정

    const onSubmit = async ( event ) => {

        event.preventDefault ();
        setFormDisabled ( true );

        try {


            await apiClient.get ( `/noAuth/VwTenant/${loginForm.tenant}` );

            let authProfile = await login ( loginForm.username, loginForm.password, loginForm.tenant ) ;

            setStoreCompanyId ( ( storeCompanyId ) => {

                let companyAndId = {
                    username: loginForm.username,
                    tenant: loginForm.tenant
                }
                if ( storeCompanyId ) {

                    localStorage.setItem ( "radixMrm.storedId", JSON.stringify( companyAndId ) );

                } else {

                    localStorage.removeItem ( "radixMrm.storedId" );

                }

                return storeCompanyId;

            } );

            authProfile.loggedIn = true;

            setTimeout ( doCheckLogin, authConf.checkLoginPeriod );
            props.onLogin ( authProfile );

            setFormDisabled ( false );

            setStoreCompanyId ( ( storeCompanyId ) => {

                return storeCompanyId;

            } );

        } catch ( reason ) {

            setFormDisabled ( false );
            props.onLoginFailed( reason );

        }

    };

    const onChangeCompanyId = ( storeCompanyId ) => {

        setStoreCompanyId ( storeCompanyId );

    };
   
    return (
        <>
            {
                ( authProfile )
                ?
                    <LoginLayout disabled={formDisabled} tenant={ tenant ? tenant: loginForm.tenant } selectTenant={ tenant ? false : true } storeCompanyId={storeCompanyId} onChangeCompanyId={onChangeCompanyId} onSubmit={onSubmit} username={loginForm.username} onChangeTenant={onChangeTenant}  onChangeUserName={onChangeUserName} password={loginForm.password} onChangePassword={onChangePassword} />
                :
                    <></>
            }
        </>
    );

}

export default Login;
