/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { cloneDeep } from "lodash";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysMaterialByCompanyDialog from "../dialogTypes/MomSysMaterialByCompanyDialog";

function MomSysMaterialByCompanyButton ( props ) {

    const { t } = useTranslation();
    const { id, schema, refresh, onSuccess, ...others } = props;

    const [modifiedSchema, setModifiedSchema] = useState();

    const [ open, setOpen ] = useState(false);

    useEffect ( () => {

        if(schema) {
            
            let modifiedSchema = cloneDeep(schema);

            for( const column of modifiedSchema.columns) {

                switch ( column.name ) {

                    case "companyCode" :

                        delete column.key;

                        break;

                    default :
                        break;
                }
            }

            setModifiedSchema ( modifiedSchema );
        }

    }, [schema] );
    
    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );
            
        }
        
        refresh.refresh ();
    };

    return (

        <>
            <CreateRecordButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <MomSysMaterialByCompanyDialog
                id={id+"append-dialog"}
                title={ t ( "dialog.title.MomSysMaterialByCompany.create" ) }
                schema={modifiedSchema}
                open={open}
                refresh={refresh}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default MomSysMaterialByCompanyButton;