import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysCollectionsReport ( props ) {

    const onInitialized = (grid) => {

        grid.selectionMode = 'Row';

    };

    return (
        <BasicCrudScreenType 
            id="MomSysCollectionsReport" 
            table="MomSysVwCollectionsReport" 
            view="MomSysVwCollectionsReport" 
            noCreate
            noEdit
            noDelete
            onInitialized={onInitialized}
            exportFormatItem={() => {}}
        />
    );

}

export default MomSysCollectionsReport;
