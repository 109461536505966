import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import layoutConf from "../../config/layoutConf";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

function EguMergeSplitHistory ( props ) {

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);
    
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);
    
        handleResize();
    
        return () => window.removeEventListener("resize", handleResize);
    
    }, []);
    
    return (
        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType
                        id="MomSysZvwMerge" 
                        view="MomSysZvwMerge"
                        headerTitle = {t("term.mergeList")}
                        onInitialized = {(grid) => grid.selectionMode = "None"}
                        />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>

                    <BasicCrudScreenType 
                        id="MomSysZvwSplit"
                        view="MomSysZvwSplit"
                        headerTitle = {t("term.splitList")}
                        onInitialized = {(grid) => grid.selectionMode = "None"}
                    />

                </ReflexElement>

            </ReflexContainer>

        </div>
    );

}

export default EguMergeSplitHistory;
    