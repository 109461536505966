import { useRecoilValue, useSetRecoilState } from "recoil";

import authProfileState from "../../../states/authProfileState";
import notificationState from "../../../states/notificationState";

import getSchema from "../../../lib/api/getSchema";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenActionItemLayout from "../layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "./BasicCrudScreenType";

import AccessControl from "../../functional/AccessControl";

import CreateMaterialDueDiligenceButton from "../../input/CreateMaterialDueDiligenceButton";
import CreateMaterialOutputButton from "../../input/CreateMaterialOutputButton";
import CreateMaterialInputButton from "../../input/CreateMaterialInputButton";

function InventoryScreenType(props) {
  const { 
        id,
        table,
        view,
    } = props;

  const { t } = useTranslation();

  const [tableSchema, setTableSchema] = useState();
  const [viewApi, setViewApi] = useState();

  const setNotification = useSetRecoilState(notificationState);

  const profile = useRecoilValue(authProfileState);

  useEffect(() => {

    (async () => {

      let schema = await getSchema(table);
      setTableSchema(schema);

    })();

  }, [table]);

  const notifySuccess = (msg) => {

    setNotification({

      severity: "success",
      msg: msg,
      open: true,

    });
  };

  const onSuccess = (msg) => {

    notifySuccess(msg);
    
  };


  const doHandleBackendErrorMsg = (reason) => {
    // reason.response := { data, status, statusText, headers, config, request }
    // data : user error message
    // status : http status code
    // statusText : http status code text
    const response = reason.response;
    return `${t("error.insertFailed")} : ${response.statusText} : ${response.data} `;

}

  const onAppendHeaderAtFirst = () => {
    return (
      <BasicCrudScreenActionItemLayout>
        <AccessControl accessTarget = {table} accessMethod = "POST">
          <CreateMaterialInputButton
              id={id + "inputButton"}
              onSuccess={onSuccess}
              createButtonName="materialInput"
              schema={tableSchema}
              refresh={viewApi}
              profileName={profile.username}
              />
        </AccessControl>
        <AccessControl accessTarget = {table} accessMethod = "POST">
          <CreateMaterialOutputButton
              id= {id + "outputButton"}
              onSuccess={onSuccess}
              createButtonName="materialOutput"
              schema={tableSchema}
              refresh={viewApi}
              profileName={profile.username}
              customizeErrorMsgCallback = {doHandleBackendErrorMsg}
            />
        </AccessControl>

        <AccessControl accessTarget = {table} accessMethod = "POST">
          <CreateMaterialDueDiligenceButton
              id= {id + "adjustmentButton"}
              dueDiligence="dueDiligence"
              onSuccess={onSuccess}
              createButtonName="adjustment"
              schema={tableSchema}
              refresh={viewApi}
              profileName={profile.username}
            />
        </AccessControl>
      </BasicCrudScreenActionItemLayout>
    );
  };

  return <BasicCrudScreenType
            id ={id}
            table={table}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendHeaderAtFirst={onAppendHeaderAtFirst}
            onApiInitialized={setViewApi} 
        />
}
export default InventoryScreenType;
