/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import {
    Badge,
    makeStyles,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";

import Icon from "./Icon";
import Avatar from "../presentation/Avatar";
import defineConf from "../../config/defineConf";

const Connector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage:
                "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    icon: {
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    avatar: {
        width: 50,
        height: 50,
    },
    requested: {
        backgroundColor: theme.palette.secondary.main,
    },
    selected: {
        border: "2px solid red",
    },
    pending: {},
    approver: {
        backgroundColor: theme.palette.primary.main,
    },
    approved: {
        backgroundColor: theme.palette.secondary.main,
    },
    rejected: {
        backgroundColor: theme.palette.secondary.main,
    },
    cancelled: {
        backgroundColor: theme.palette.secondary.main,
    },
    approvedBadge: {
        color: "#0000ff",
    },
    pendingBadge: {
        color: "#d3d3d3",
    },
    rejectedBadge: {
        color: "#ff0000",
    },
    withdrawnBadge: {
        color: "#ff0000",
    },
    cancelledBadge: {
        color: "#ff0000",
    }
}));

function ApprovalState(props) {
    const { t } = useTranslation();

    const { approvalState, approvalDetails } = props;

    const classes = useStyles();

    const getRequesterIcon = (props) => {

        const { approvalState } = props;

        if ( approvalState ) {

            if ( approvalState.state === defineConf.approvalState.WITHDRAWN ) {

                return (
    
                    <div className={classes.icon}>
                        <Badge
                            className={classes.withdrawnBadge}
                            badgeContent={<Icon>undo</Icon>}
                            anchorOrigin={anchorOrigin}
                        >
                            <Avatar photo={approvalState.requesterPhoto} email={approvalState.requesterEmail} className={clsx(classes.avatar, classes.requested)} />
                        </Badge>
                    </div>
    
                );
    
            } else {
    
                return (
                    <div className={classes.icon}>
                        <Avatar photo={approvalState.requesterPhoto} email={approvalState.requesterEmail} className={clsx(classes.avatar, classes.requested)} />
                    </div>
                );
        
            }
    
        } else {

            return (
                <div className={classes.icon}>
                    <Avatar photo={approvalState.requesterPhoto} email={approvalState.requesterEmail} className={clsx(classes.avatar, classes.requested)} />
                </div>
            );

        }

    };

    const anchorOrigin = {
        vertical: "bottom",
        horizontal: "right",
    };

    const getApproverIcon = ( props ) => {

        const { approvalDetail } = props;


        switch ( approvalDetail.state ) {

            case 0: // Pending

                return (

                    <Badge
                        badgeContent={<Icon>pending_actions</Icon>}
                        className={classes.pendingBadge}
                        anchorOrigin={anchorOrigin}
                    >
                        <Avatar
                            photo={approvalDetail.approverPhoto}
                            email={approvalDetail.approverEmail}
                            className={clsx(classes.icon, classes.avatar, classes.pending)}
                        />
                    </Badge>

                );

            case 1:

                return (
                    
                    <Badge
                        badgeContent={<Icon>approval</Icon>}
                        className={classes.approvedBadge}
                        anchorOrigin={anchorOrigin}
                    >
                        <Avatar
                            photo={approvalDetail.approverPhoto}
                            email={approvalDetail.approverEmail}
                            className={clsx(classes.icon, classes.avatar, classes.approver)}
                        />
                    </Badge>

                );

            case 2:

                return (

                    <Badge
                        badgeContent={<Icon>undo</Icon>}
                        className={classes.rejectedBadge}
                        anchorOrigin={anchorOrigin}
                    >
                        <Avatar
                            photo={approvalDetail.approverPhoto}
                            email={approvalDetail.approverEmail}
                            className={clsx(classes.icon, classes.avatar, classes.rejected)}
                        />
                    </Badge>

                );

            case 3:

                return (

                    <Badge
                        badgeContent={<Icon>block</Icon>}
                        className={classes.cancelledBadge}
                        anchorOrigin={anchorOrigin}
                    >
                        <Avatar
                            photo={approvalDetail.approverPhoto}
                            email={approvalDetail.approverEmail}
                            className={clsx(classes.icon, classes.avatar, classes.cancelled)}
                        />
                    </Badge>

                );

            default:

                return (

                    <Avatar
                        photo={approvalDetail.approverPhoto}
                        email={approvalDetail.approverEmail}
                        className={clsx(classes.icon, classes.avatar, classes.approver)}
                    />

                );

        }
    };

    const getApprovedIcon = (props) => {
        return (
            <div className={classes.icon}>
                <Avatar
                    className={clsx(
                        classes.avatar,
                        approvalState.state === defineConf.approvalState.APPROVED || approvalState.state === defineConf.approvalState.CANCELLED
                            ? classes.approved
                            : classes.pending
                    )}
                >
                    <Icon>approval</Icon>
                </Avatar>
            </div>
        );
    };

    const getActiveStep = (approvalState, approvalDetails) => {

        let details = approvalDetails.slice().sort((a, b) => a.order - b.order);

        if (approvalState.state === defineConf.approvalState.APPROVED ) {

            return ( approvalDetails.length + 2 );

        }

        for (const index in details) {

            switch ( details[index].state ) {

                case 1:
                    continue;

                case 2:
                    return ( parseInt ( index ) + 1 );

                case 3:
                    break;

                default:
                    return ( parseInt ( index ) );
                
            }

        }

        return ( approvalDetails.length + 2 );

    };

    return (
        <div className={classes.root}>
            {approvalState && approvalDetails ? (
                <Stepper
                    alternativeLabel
                    activeStep={getActiveStep(approvalState, approvalDetails)}
                    connector={<Connector />}
                >
                    <Step>
                        <StepLabel StepIconComponent={ () => getRequesterIcon ({approvalState: approvalState})}>
                            {`${t("term.approvalPhase.request")} : ${
                                approvalState.requesterFullName
                            } [${approvalState.requester}]`}
                        </StepLabel>
                    </Step>
                    {approvalDetails
                        .slice()
                        .sort(
                            (detailA, detailB) => ( detailA.approverOrder - detailB.approverOrder )
                        )
                        .map((detail, order) => (
                            <Step key={detail.approver}>
                                <StepLabel
                                    StepIconComponent={() =>
                                        getApproverIcon({
                                            approvalDetail: detail,
                                        })
                                    }
                                >
                                    {`${t("term.approvalPhase.approver")}${
                                        order + 1
                                    } : ${detail.approverFullName} [${
                                        detail.approver
                                    }]`}
                                </StepLabel>
                            </Step>
                        ))}
                    <Step>
                        <StepLabel StepIconComponent={getApprovedIcon}>
                            { approvalState.state === defineConf.approvalState.CANCELLED ? t("term.approvalPhase.cancelled") : t("term.approvalPhase.approved")}
                        </StepLabel>
                    </Step>
                </Stepper>
            ) : (
                <></>
            )}
        </div>
    );
}

export default ApprovalState;
