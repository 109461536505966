import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function UpdateRecordButtonLayout ( props ) {

    const { t } = useTranslation();

    return <ActionButtonLayout {...props}>{t("buttonName.update")}</ActionButtonLayout>;

}

export default UpdateRecordButtonLayout;

