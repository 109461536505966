function getNewDateObj ( value ) {

    let date;

    switch ( typeof value ) {

        case "undefined":

            date = new Date ();
            break;

        case "string":

            date = new Date ( value );
            
            if ( ! value.endsWith ( "Z" ) ) {

                date.setHours ( date.getHours () - ( date.getTimezoneOffset () / 60 ) );

            }

            break;

        default:

            date = new Date ( value );
            break;

    }

    return date;

}

export default getNewDateObj;
