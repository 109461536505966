import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import "react-reflex/styles.css";

import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AddTranslationPathButton from "../input/AddTranslationPathButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";

function TranslationPath ( props ) {

    const { t } = useTranslation();

    const { namespace, onSelect } = props;

    const [ grid, setGrid ] = useState();
    const [ api, setApi ] = useState ();
    const [ schema, setSchema ] = useState();
    const [ disabled, setDisabled ] = useState(false);

    const setNotification = useSetRecoilState(notificationState);

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "TranslationPath" );

            if ( mounted ) {
                
                setSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;

        } );
        
    }, [] );

    useEffect( () => {

        if ( api ) {

            api.refresh ();

        }

    }, [api] );

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [namespace] );

    const onGridInitialized = ( ( grid ) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler ( ( grid ) => {


            if ( grid.selectedItems && grid.selectedItems[0] ) {

                setDisabled ( false );

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

            } else {

                    setDisabled ( true );

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }

            }

        } );

        grid.itemsSourceChanged.addHandler ( ( grid ) => {

            if ( grid.selectedItems && grid.selectedItems[0] ) {

                if ( onSelect ) {

                    onSelect ( grid.selectedItems[0] );

                }

            } else {

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }

            }
        } );

        setGrid ( grid );

    } );

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyFailure = (msg) => {
    
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };
    
    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );
    
    };
        
    const onFailure = ( reason ) => {
    
        notifyFailure ( reason.response ? reason.response.data : reason );
    
    };

    const makeItemName = ( row ) => {

        return `${t("columnName.namespace")}: ${row.namespace}, ${t("columnName.translationPath")}: ${row.translationPath}`;

    };

    const onModifyViewUrl = ( namespace, url ) => {

        return `${url}/${namespace?namespace.namespaceId:undefined}`;

    }

    return <BasicCrudScreenType

        id="VwTranslationPath"
        table="VwTranslationPath"
        view="VwTranslationPath"

        headerTitle={t("term.translationPath")}

        noCreate
        noEdit
        noDelete
        noExcel
                
        onAppendButtonInFrontOfSearch={() =>

            <>

                <BasicCrudScreenActionItemLayout>

                    <AddTranslationPathButton
                        id="AddTranslationPath"
                        namespace={namespace}
                        refresh={api}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />

                </BasicCrudScreenActionItemLayout>

                <BasicCrudScreenActionItemLayout>

                    <DeleteSingleRecordButton
                        id="DeleteTranslationPath"
                        disabled={disabled}
                        color="secondary"
                        grid={grid}
                        title={t("buttonName.deleteTranslationPath")}
                        schema={schema}
                        refresh={api}
                        makeItemName={makeItemName}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                    />

                </BasicCrudScreenActionItemLayout>

            </>

        }

        onInitialized={onGridInitialized}
        onApiInitialized={onApiInitialized}
        onModifyViewUrl={ ( url ) => onModifyViewUrl ( namespace, url ) }

    />

}

export default TranslationPath;

