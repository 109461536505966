/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import "../layout/DataGridShipmentStateColumn.css";

function DataGridShipmentStateColumn (props) {

    const {schema, ...others} = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    useEffect(() => {

        setCellTemplate(() => ((ctx) => {

            let state = ctx.item.shipmentState || ctx.item.shipmentDetailState;

            switch(state) {

                case "부분출하" : 
                return <span className = "part-shipment">{state}</span>;

                case "미출하" : 
                return <span className ="not-shipment">{state}</span>;

                case "출하완료" : 
                return <span className ="completion-shipment">{state}</span>;

                default :
                return <span>{state}</span>
            }
        }))
    },[schema])

    return (
        <DataGridColumn schema={schema} {...others}  >
            <FlexGridCellTemplate  cellType="Cell" template={cellTemplate} />
        </DataGridColumn>
    )
}

export default DataGridShipmentStateColumn