import { useTranslation } from "react-i18next";

import ActionButtonLayout from "./ActionButtonLayout";

function ApprovalButtonLayout ( props ) {

    const { t } = useTranslation ();

    return <ActionButtonLayout {...props}>{t("buttonName.approval")}</ActionButtonLayout>;

}

export default ApprovalButtonLayout;

