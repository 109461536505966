import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function EguInputInspectionInputButton(props) {
    const { id, schema, grid, refresh, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        let modifiedSchema = cloneDeep(schema);

        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "wareHouseCode":
                        column.hideInForm = false;
                        column.required = true;
                        
                        break;
                        
                    case "inputDate":
                        column.hideInForm = false;
                        break;
                        
                    default:
                        column.disabled = true;
                        column.required = false;
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [schema]);

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0], /* extLotNo: "extLot" */ });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <React.Fragment>
            <ActionButtonLayout id={id} onClick={onOpen} {...others}>
                {t("buttonName.input")}
            </ActionButtonLayout>
            <AutoFormDialog
                id={id + "input-dialog"}
                mode="edit"
                formId={id + "input-form"}
                title={schema ? t(`dialog.title.${schema.name}.input`) : ""}
                schema={modifiedSchema}
                modifyUrlCallback={(url) => `/api/EguInputInspection/doInput`}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onInputSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </React.Fragment>
    );
}

export default EguInputInspectionInputButton;
