import { FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { useTranslation } from 'react-i18next';

function DataGridColumn ( props ) {

    const { t } = useTranslation ();

    const {

        schema,
        
        header,
        binding,

        dataType,
        dataMap,
        format,
        align,
        
        width,
        minWidth,
        maxWidth,
        visible,

        aggregate,
        allowSorting,
        allowResizing,
        allowDragging,
        allowMerging,
        quickAutoSizing,

        multiLine,
        wordWrap,
        
        cssClass,
        cssClassAll,

        isContentHtml,

        isReadOnly,
        dataMapEditor,
        editor,
        
        children,
        
        ...others
    
    } = props;

    const columnProps = {

        header: header || ( schema.grid && schema.grid.header ) ? t ( schema.grid.header ): t ( `columnName.${schema.displayName || schema.name}` ),
        binding: binding || schema.name,
        name: schema.name,

        dataMap: dataMap,
        dataType: dataType || ( schema.dataType ? schema.dataType : "String" ),
        format: format || ( schema.grid ? schema.grid.format : undefined ),
        align: align || ( schema.grid ? schema.grid.align : null ),
        
        width: format || ( schema.grid ? schema.grid.width : undefined ),
        minWidth: minWidth || ( schema.grid ? schema.grid.minWidth: undefined ),
        maxWidth: maxWidth || ( schema.grid ? schema.grid.maxWidth: undefined ),
        visible: visible || ( schema.grid ? schema.grid.visible: true ),

        aggregate: aggregate || ( schema.aggregate ? schema.aggregate : "None" ),
        allowSorting: allowSorting || ( schema.grid ? schema.grid.allowSorting : true ),
        allowResizing: allowResizing || ( schema.grid ? schema.grid.allowResizing : true ),
        allowDragging: allowDragging || ( schema.grid ? schema.grid.allowDragging : true ),
        allowMerging: allowMerging || ( schema.grid ? schema.grid.allowMerging : false ),
        quickAutoSizing: quickAutoSizing || ( schema.grid ? schema.grid.quickAutoSizing : undefined ),

        multiLine: multiLine || ( schema.grid ? schema.grid.multiLine : false ),
        wordWrap: wordWrap || ( schema.grid ? schema.grid.wordWrap : false ),

        cssClass: cssClass || ( schema.grid ? schema.grid.cssClass : undefined ),
        cssClassAll: cssClass || ( schema.grid ? schema.grid.cssClass : undefined ),

        isContentHtml: isContentHtml || ( schema.grid ? schema.grid.isContentHtml : false ),

        isReadOnly: isReadOnly || ( schema.grid ? schema.grid.isReadOnly : true ),

        dataMapEditor: dataMapEditor,
        editor: editor,

        ...others

    };

    return (

        <FlexGridColumn {...columnProps}>
            { children }
        </FlexGridColumn>

    );

}

export default DataGridColumn;

