import { useTranslation } from "react-i18next";
import { useState } from "react";

import del from "../../lib/api/del";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import DeleteApproverButtonLayout from "../layout/DeleteApproverButtonLayout";

function DeleteApproverButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, approvalPathNode, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
                
        setConfirmMsg ( t( "dialog.msg.confirm.delete", { type: t("term.approvalPhase.approver"), instance: approvalPathNode.approver } ) );
        setConfirmData ( approvalPathNode );

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onDeleteOk = ( requestData ) => {

        del ( "ApprovalPathNode", approvalPathNode.approvalPathId, approvalPathNode.approver )
            .then ( ( result ) => {

                if ( onSuccess ) {
                    
                    onSuccess ( t ( "success.deleteSuccess", { table: "ApprovalPathNode", deleteCount: 1 } ) );

                }

            } )
            .catch ( ( reason ) => {

                console.error ( reason );

                if ( onFailure ) {

                    onFailure ( t ( "error.deleteFailure", { reason: reason.response ? reason.response.data : reason } ) );
                
                };

            } );
    }

    return (

        <>
            <DeleteApproverButtonLayout id={id+"delete-button"} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id+"-delete-confirm-dialog"}
                title={ schema ? t ( `dialog.title.${schema.name}.delete` ) : "" }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onDeleteOk}
                onCancel={onClose}
            />
        </>

    );

}

export default DeleteApproverButton;

