/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Hidden } from "@material-ui/core";
import { cloneDeep } from "lodash";

import uiConf from "../../../config/uiConf";
import getSchema from "../../../lib/api/getSchema";
import { getOption } from "../../../lib/api/getOptions";

import notificationState from "../../../states/notificationState";
import BasicCrudScreenTypeLayout from "../layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../layout/BasicCrudScreenHeaderLayout";
import BasicCrudScreenContentLayout from "../layout/BasicCrudScreenContentLayout";
import BasicCrudScreenContentWithoutFooterLayout from "../layout/BasicCrudScreenContentWithoutFooterLayout";
import BasicCrudScreenFooterLayout from "../layout/BasicCrudScreenFooterLayout";
import BasicCrudActionsLayout from "../layout/BasicCrudActionsLayout";
import BasicCrudScreenActionItemLayout from "../layout/BasicCrudScreenActionItemLayout";

import ToolbarFlexSpacer from "../../layout/ToolbarFlexSpacer";

import Filter from "../../functional/Filter";
import Search from "../../functional/Search";
import Pagination from "../../functional/Pagination";
import GetListApi from "../../functional/GetListApi";
import GetQueryString from "../../functional/GetQueryString";


import SearchTextField from "../../input/SearchTextField";
import OpenFilterButton from "../../input/OpenFilterButton";

import FilterConditionPanelLayout from "../../layout/FilterConditionPanelLayout";

import AutoFilterForm from "../../filter/functional/AutoFilterForm";
import FilterApplyButton from "../../input/FilterApplyButton";
import FilterClearButton from "../../input/FilterClearButton";
import FilterCancelButton from "../../input/FilterCancelButton";

import PaginationControl from "../../input/PaginationControl";
import OutputSizeSelect from "../../input/OutputSizeSelect";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import FilterDialog from "../../dialogTypes/FilterDialog";

import apiClient from "../../../lib/common/apiClient";

function SimpleScreenTypeWithApi ( props ) {

    const { t } = useTranslation();

    const {

        id,

        embededInOtherScreenType,
        
        noHeader,
        noSearch,
        noFilter,
        noPagination,

        approvalCtx,

        defaultOutputSize,
        
        headerTitle,
        headerTitleStyle,

        table,
        view,

        tableSchemaUrl,
        viewSchemaUrl,
        
        makeDeleteItemName,

        fixedOrder,

        buttonsInFrontOfSearch,

        $queries,

        onApiInitialized,
        onOrderByInitialized,
        onInstanceInitialized,
        
        onLoadData,
        onModifyTableSchema,
        onModifyViewSchema,

        onModifyViewUrl,

        onAppendHeaderAtFirst,
        onAppendContentAtFirst,
        onAppendFooterAtFirst,

        onAppendHeaderAtLast,
        onAppendContentAtLast,
        onAppendFooterAtLast,

        onAppendButtonAtFirst,
        onAppendButtonAtLast,
        onAppendButtonInFrontOfSearch,

        onSearchInitialized,
        
        exportFormatItem,

        createButtonName,
    
        searchRef,
        autoFocus,

        useTable,

        children,
        
        ...others
        
    } = props;

    const [ , setInstance ] = useState( null );
    const [ , setForm ] = useState ();
    const [ , setFilterDialog ] = useState();
    const [ , setQuery] = useState();

    const [ , setTableSchema ] = useState();
    const [ viewSchema, setViewSchema ] = useState();
    const [ , setViewApi ] = useState();
    const [ filter, setFilter ] = useState();
    const [ filterApplyButton, setFilterApplyButton ] = useState();
    const [ filterValue, setFilterValue ] = useState ();
    const [ showFilter, setShowFilter ] = useState(false);
    const [ search, setSearch ] = useState();
    const [ pagination, setPagination ] = useState();
    const [ count, setCount ] = useState(0);
    const [ size, setSize ] = useState(defaultOutputSize || uiConf.defaultOutputSize);
    const [ useFilterDialog, setUseFilterDialog ] = useState(false);

    const setNotification = useSetRecoilState(notificationState);

    useEffect ( () => {

        let mounted = true;

        let instance = {

            refresh: () => {

                setViewApi ( ( api ) => {
                        
                    api.refresh ();
                    return api;

                } );

            },
            notifySuccess: notifySuccess,
            notifyError: notifyError,
            notifyWarning: notifyWarning,

        };

        setInstance ( instance );

        if ( onInstanceInitialized ) {

            onInstanceInitialized ( instance );

        }

        ( async () => {

            let useFilterDialog = await getOption ( "default", "useFilterDialog" );

            if ( mounted ) {

                if ( useFilterDialog && ( useFilterDialog.toLowerCase () === "true" ) ) {

                    setUseFilterDialog ( true );
    
                } else {
    
                    setUseFilterDialog ( false );
    
                }
    
            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [] );

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            if ( table ) {

                let schema;

                if ( tableSchemaUrl ) {

                    if ( tableSchemaUrl !== undefined ) {

                        let result = await apiClient.get ( tableSchemaUrl );
                        schema = result.data;
    
                    }

                } else {

                    schema = await getSchema ( table );

                }

                if ( ! schema ) {

                    schema = {};

                }

                if ( mounted ) {
                
                    if ( onModifyTableSchema ) {
    
                        onModifyTableSchema ( schema );
        
                    }
        
                    setTableSchema ( schema );
    
                }

            } else {

                setTableSchema ( undefined );

            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [table,tableSchemaUrl] );

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            if ( view ) {

                let schema;
            
                if ( viewSchemaUrl !== undefined ) {

                    if ( viewSchemaUrl.length > 0 ) {

                        let result = await apiClient.get ( viewSchemaUrl );
                        schema = result.data;
    
                    }

                } else {

                    schema = await getSchema ( view );

                }

                if ( !schema ) {

                    schema = {};

                }

                if ( mounted ) {
                
                    if ( onModifyViewSchema ) {
        
                        onModifyViewSchema ( schema );

                    }
                    
                    setViewSchema ( schema );
                    
                }

            } else {

                setViewSchema ( undefined );

            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [view,viewSchemaUrl] );

    const notifySuccess = (msg) => {

        if ( embededInOtherScreenType ) {

            if ( props.onNotifySuccess ) {

                props.onNotifySuccess ( msg );

            }

            return;

        }

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyError = (msg) => {

        if ( embededInOtherScreenType ) {

            if ( props.onNotifyError ) {

                props.onNotifyError ( msg );
                
            }

            return;

        }

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const notifyWarning = (msg) => {

        if ( embededInOtherScreenType ) {

            if ( props.onNotifyWarning ) {

                props.onNotifyWarning ( msg );
                
            }

            return;

        }

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
        
    };

    const onViewApiInitialized = ( viewApi ) => {

        setViewApi ( viewApi );

        if ( onApiInitialized ) {

            onApiInitialized ( viewApi );

        }

    };

    const onFormInitialized = ( form ) => {

        setForm ( form );

    }

    const onFilterInitialized = ( filter ) => {

        setFilter ( filter );

    };

    const onFilterApplyButtonInitialized = ( filterApplyButton ) => {

        setFilterApplyButton ( filterApplyButton );

    };

    const onApplyFilter = ( filterValue ) => {

        if ( filterValue ) {

            setFilterValue ( [...filterValue] );

        } else {

            setFilterValue ( () => {

                let newFilterValue = [];

                for ( const column of viewSchema.columns ) {
        
                    if ( column.filter ) {
    
                        newFilterValue.push ( {
    
                            binding: column.name,
                            type: "condition",
                            and: true,
                            condition1: { operator: "", value: "" },
                            condition2: { operator: "", value: "" }
    
                        } );
    
                    }
                }

                return newFilterValue;

            });
        }
        
    }

    const onClearFilter = () => {


        setForm ( ( form ) => {

            form.clear ();
            return form;

        } );

    }

    const toggleOpenFilter = () => {

        setUseFilterDialog ( ( useFilterDialog ) => {

            if ( useFilterDialog ) {

                setFilterDialog ( ( filterDialog ) => {

                    filterDialog.show ();

                    return filterDialog;

                } );


            } else {

                setShowFilter ( ( showFilter ) => ( !showFilter ) );

            }

            return useFilterDialog;

        } );

    };

    const onSearchInitializedInternal = ( search ) => {

        setSearch ( search );

        if ( onSearchInitialized ) {

            onSearchInitialized ( search );

        }

    };

    const onSearchChange = () => {

        setPagination ( ( pagination ) => {

            if ( pagination ) {

                pagination.set ( 1 );

            }

            return pagination;

        } );
        
    }

    const onPaginationInitialized = ( pagination ) => {

        setPagination ( pagination );

    };

    const onCountChange = ( count ) => {

        setCount ( parseInt(count) );

    };

    const onSizeChange = ( size ) => {

        setSize ( parseInt(size) );

    }

    const closeFilter = () => {

        setShowFilter ( false );

    };

    const onFilterDialogInitialized = ( dialog ) => {

        setFilterDialog ( dialog );

    };

    const onQueryChanged = ( query ) => {

        setQuery ( query );

    };

    return (

        <div>

            <BasicCrudScreenTypeLayout>

                {
                    
                    noHeader
                    ?
                    <></>
                    :
                    <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">


                        <BasicCrudActionsLayout className="basicCrudScreenActionsLayout">

        
                            {/* Action Button ids and Dialog ids are assigned inside Action Button Component */}

                            {
                                onAppendHeaderAtFirst
                                ?
                                onAppendHeaderAtFirst ()
                                :
                                <></>
                            }

                            {
                                headerTitle
                                ?
                                <span
                                    color="inherit"
                                    align="center"
                                    style={
                                        headerTitleStyle
                                        ||
                                        {
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            marginLeft: "8px",
                                            marginTop: "20px"
                                        }
                                    }>
                                    { headerTitle }
                                </span>
                                :
                                <></>
                            }

                            <Hidden xsDown>

                            {
                                onAppendButtonAtFirst
                                ?
                                onAppendButtonAtFirst ()
                                :
                                <></>
                            }

                            {
                                buttonsInFrontOfSearch
                                ?
                                <ToolbarFlexSpacer xs />
                                :
                                <></>
                            }

                            {
                                onAppendButtonAtLast
                                ?
                                onAppendButtonAtLast ()
                                :
                                <></>
                            }

                            </Hidden>

                            <Hidden smDown>

                            {

                                buttonsInFrontOfSearch
                                ?
                                <></>
                                :
                                <ToolbarFlexSpacer xs />

                            }

                            {
                                onAppendButtonInFrontOfSearch
                                ?
                                onAppendButtonInFrontOfSearch ()
                                :
                                <></>
                            }


                            {
                                noSearch
                                ?
                                <></>
                                :
                                <BasicCrudScreenActionItemLayout>

                                    <SearchTextField
                                        id={id+"-search-text"}
                                        className="basicCrudScreenSearchInput"
                                        search={search}
                                        searchRef={searchRef}
                                        autoFocus={autoFocus}
                                    />

                                </BasicCrudScreenActionItemLayout>
                            }

                            {
                                noFilter
                                ?
                                <></>
                                :
                                <BasicCrudScreenActionItemLayout>

                                    <OpenFilterButton
                                        id={id+"-open-filter-button"}
                                        className="basicCrudScreenOpenFilterButton"
                                        color="primary"
                                        onClick={toggleOpenFilter}
                                        style={{marginTop: "4px"}}
                                    />
                                    
                                </BasicCrudScreenActionItemLayout>
                            }

                            </Hidden>

                            {

                                buttonsInFrontOfSearch
                                ?
                                <></>
                                :
                                <Hidden mdUp>
                                
                                    <ToolbarFlexSpacer xs />

                                    {
                                        onAppendButtonInFrontOfSearch
                                        ?
                                        onAppendButtonInFrontOfSearch ()
                                        :
                                        <></>
                                    }

                                </Hidden>

                            }

                            {
                                onAppendHeaderAtLast
                                ?
                                onAppendHeaderAtLast ()
                                :
                                <></>
                            }

                        </BasicCrudActionsLayout>

                        {
                            noFilter
                            ?
                            <></>
                            :
                            (
                                useFilterDialog
                                ?
                                <FilterDialog

                                    id={id+"-filter-dialog"}
                                    title={t("dialog.title.Filter")}
                                    schema={viewSchema}
                                    filter={filter}
                                    filterValue={filterValue?cloneDeep(filterValue):filterValue}
                                    onInitialized={onFilterDialogInitialized}
                                    onFormInitialized={onFormInitialized}
                                    onApply={onApplyFilter}
                                    onClear={onClearFilter}
                                    onCancel={closeFilter}

                                />
                                :
                                <FilterConditionPanelLayout
                                    className="basicCrudScreenFilterPanel"
                                    show={showFilter}
                                >

                                    <AutoFilterForm
                                        id={id+"-filter-form"}
                                        className="autoFilterForm"
                                        schema={viewSchema}
                                        filterValue={filterValue?cloneDeep(filterValue):filterValue} // object deep copy - 내부에서 객체 멤버가 수정되어 버그 발생
                                        filterApplyButton={filterApplyButton}
                                        onInitialized={onFormInitialized}
                                    />
                                    <FilterApplyButton
                                        id={id+"-filter-apply-button"}
                                        className="filterApplyButton"
                                        schema={viewSchema}
                                        filter={filter}
                                        onApply={onApplyFilter}
                                        onInitialized={onFilterApplyButtonInitialized}
                                    />
                                    <FilterClearButton
                                        id={id+"-filter-clear-button"}
                                        className="filterClearButton"
                                        onClear={onClearFilter}
                                    />
                                    <FilterCancelButton
                                        id={id+"-filter-cancel-button"}
                                        className="filterCancelButton"
                                        onCancel={closeFilter}
                                    />                    

                                </FilterConditionPanelLayout>

                            )
                        }
                        
                    </BasicCrudScreenHeaderLayout>

                }

                {
                    noPagination
                    ?
                    <BasicCrudScreenContentWithoutFooterLayout className="basicCrudScreenContentLayout">

                        {
                            onAppendContentAtFirst
                            ?
                            onAppendContentAtFirst ()
                            :
                            <></>
                        }

                        <Filter onInitialized={onFilterInitialized} >

                            <Search onChange={onSearchChange} onInitialized={onSearchInitializedInternal}>

                                <GetQueryString $queries={$queries} onQueryChanged={onQueryChanged} />

                                <GetListApi useTable table={view} onModifyUrl={onModifyViewUrl} $queries={$queries} onCountChange={onCountChange} onLoadData={onLoadData} onInitialized={onViewApiInitialized}  >

                                {
                                    children &&
                                    React.Children.map(children, ( ( child ) => (
                                        React.isValidElement ( child )
                                        ?
                                        React.cloneElement(child, { ...others, size } )
                                        :
                                        child
                                    ) ) )
                                }

                                </GetListApi>

                            </Search>

                        </Filter>

                        {
                            onAppendContentAtLast
                            ?
                            onAppendContentAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenContentWithoutFooterLayout>
                    :
                    <BasicCrudScreenContentLayout className="basicCrudScreenContentLayout">

                        {
                            onAppendContentAtFirst
                            ?
                            onAppendContentAtFirst ()
                            :
                            <></>
                        }

                        <Filter onInitialized={onFilterInitialized} >

                            <Search onChange={onSearchChange} onInitialized={onSearchInitializedInternal}>

                                <Pagination size={size} onInitialized={onPaginationInitialized}>

                                    <GetQueryString $queries={$queries} onQueryChanged={onQueryChanged} />

                                    <GetListApi useTable table={view} onModifyUrl={onModifyViewUrl} $queries={$queries} onCountChange={onCountChange} onLoadData={onLoadData} onInitialized={onViewApiInitialized}  >

                                    {
                                        children &&
                                        React.Children.map(children, ( ( child ) => (
                                            React.isValidElement ( child )
                                            ?
                                            React.cloneElement(child, { ...others, size } )
                                            :
                                            child
                                        ) ) )
                                    }

                                    </GetListApi>

                                </Pagination>

                            </Search>

                        </Filter>

                        {
                            onAppendContentAtLast
                            ?
                            onAppendContentAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenContentLayout>                    
                }

                {
                    noPagination
                    ?
                    <></>
                    :
                    <BasicCrudScreenFooterLayout className="basicCrudScreenFooterLayout" embededInOtherScreenType={embededInOtherScreenType}>
                        
                        {
                            onAppendFooterAtFirst
                            ?
                            onAppendFooterAtFirst ()
                            :
                            <></>
                        }

                        <ToolbarFlexSpacer xs />

                            <PaginationControl
                                id={id+"-pagination-control"}
                                className="pagintationConterol"
                                color="primary"
                                style={{leftMargin:0, rightMargin:0, marginTop: "12px"}}
                                pagination={pagination}
                                showFirstButton={true}
                                showLastButton={true}
                                size={size}
                                count={count}
                            />

                        <ToolbarFlexSpacer xs />

                        {
                            <BasicCrudScreenActionItemLayout>

                                <OutputSizeSelect
                                    id={id+"-output-size-select"}
                                    className="outputSizeSelect"
                                    onChange={onSizeChange}
                                />

                            </BasicCrudScreenActionItemLayout>
                        }
                        
                        {
                            onAppendFooterAtLast
                            ?
                            onAppendFooterAtLast ()
                            :
                            <></>
                        }

                    </BasicCrudScreenFooterLayout>

                }


            </BasicCrudScreenTypeLayout>
            
        </div>

    );

}

export default SimpleScreenTypeWithApi;
