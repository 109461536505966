import { atom } from "recoil";

// current root redirect path

const rootRedirectPathState = atom({
        
    key: "rootRedirectPathState",
    default: "/"

});

export default rootRedirectPathState;
