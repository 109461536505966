/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce, } from "lodash";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";

import getSchema from "../../lib/api/getSchema";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import MomSysCustomerSupplyOrderBuyDetailCreateButton from "../input/MomSysCustomerSupplyOrderBuyDetailCreateButton";

function MomSysCustomerSupplyOrderBuyDetail(props) {
    const { onSelectMst, onDetailEmpty } = props;

    const id = "CustomerOrderBuyerDetail";
    const view = "MomSysVwCustomerSupplyOrderBuyDetail";

    const { t } = useTranslation();

    const [eguUglOrderBuyerDetailGrid, setEguUglOrderBuyerDetailGrid] = useState();
    const [eguUglOrderBuyerDetailApi, setEguUglOrderBuyerDetailApi] = useState();
    const [eguUglOrderBuyerDetailDataIsEmpty, setEguUglOrderBuyerDetailDataIsEmpty] = useState();
    const [eguUglOrderBuyerDetailSelect, setEguUglOrderBuyerDetailSelect] = useState({});

    const [momSysCustomerSupplyOrderBuyDetailGrid, setMomSysCustomerSupplyOrderBuyDetailGrid] = useState();
    const [momSysCustomerSupplyOrderBuyDetailApi, setMomSysCustomerSupplyOrderBuyDetailApi] = useState();
    const [momSysCustomerSupplyOrderBuyDetailSchema, setMomSysCustomerSupplyOrderBuyDetailSchema] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        if (eguUglOrderBuyerDetailApi) {
            eguUglOrderBuyerDetailApi.refresh();
        }
    }, [onSelectMst.orderBuyerId]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (mounted) {
                const schema = await getSchema("MomSysCustomerSupplyOrderBuyDetail");
                setMomSysCustomerSupplyOrderBuyDetailSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);


    useEffect(() => {
        (async () => {
            if (eguUglOrderBuyerDetailGrid) {
                eguUglOrderBuyerDetailGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerDetailSelect({});
                        }
                    }, 100)
                );

                eguUglOrderBuyerDetailGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerDetailSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerDetailSelect({});
                        }
                    }, 100)
                );
            }
        })();
    }, [eguUglOrderBuyerDetailGrid]);


    useEffect(() => {
        if (momSysCustomerSupplyOrderBuyDetailApi) {
            momSysCustomerSupplyOrderBuyDetailApi.refresh();
        }
    }, [eguUglOrderBuyerDetailSelect?.materialCode])

    const onModifyViewUrl = (url) => {
        return `${url}/${eguUglOrderBuyerDetailSelect.orderBuyerId}/${eguUglOrderBuyerDetailSelect.materialCode}/${eguUglOrderBuyerDetailSelect.materialCodeVer}`
    }


    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        if (momSysCustomerSupplyOrderBuyDetailApi) {
            momSysCustomerSupplyOrderBuyDetailApi.refresh();
        }

    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onPartialFailure = (msg) => {
        notifyWarning(msg);

        if (momSysCustomerSupplyOrderBuyDetailApi) {
            momSysCustomerSupplyOrderBuyDetailApi.refresh();
        }

    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl
                    accessTarget={momSysCustomerSupplyOrderBuyDetailSchema && momSysCustomerSupplyOrderBuyDetailSchema.name}
                    accessMethod="POST"
                >
                    <MomSysCustomerSupplyOrderBuyDetailCreateButton
                        id={id}
                        schema={momSysCustomerSupplyOrderBuyDetailSchema}
                        grid={momSysCustomerSupplyOrderBuyDetailGrid}
                        refresh={momSysCustomerSupplyOrderBuyDetailApi}
                        color="primary"
                        disabled={eguUglOrderBuyerDetailDataIsEmpty}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onPartialFailure={onPartialFailure}
                        eguUglOrderBuyerDetailSelect={eguUglOrderBuyerDetailSelect}
                    />

                </AccessControl>
            </BasicCrudScreenActionItemLayout>

        )
    }

    const onModifyViewSchema = (schema) => {

        schema.columns.push({
            name: "customerSupply",
            grid: {
                columnOrder: 113,
                type: "boolean"
            }
        })

        schema.columns.push({
            name: "customerSupplyCost",
            grid: {
                columnOrder: 117,
                type: "boolean"
            }
        })

        for (let column of schema.columns) {
            switch (column.name) {
                case "materialCode":
                case "materialCodeVer":
                case "materialName":
                case "customerSupply":
                case "customerSupplyCost":
                case "itemSpecName":
                case "itemUnitName":
                case "qty":
                    break;
                default:
                    column.grid.hide = true
                    break;
            }
        }
    }

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement data-cy="subMaster" flex={0.33}>
                <BasicCrudScreenType
                    id={id}
                    headerTitle={t("term.soDetail")}
                    view={view}
                    onModifyViewUrl={(url) => `${url}/${onSelectMst.orderBuyerId}`}
                    onInitialized={(grid) => {
                        setEguUglOrderBuyerDetailGrid(grid);
                    }}
                    onApiInitialized={(api) => {
                        setEguUglOrderBuyerDetailApi(api);
                    }}
                    onLoadData={(data) => {
                        const isEmpty = data.length < 1 ? true : false;

                        setEguUglOrderBuyerDetailDataIsEmpty(isEmpty);

                        if (onDetailEmpty) {
                            onDetailEmpty(isEmpty);
                        }
                    }}
                    noCreate
                    noEdit
                    noDelete
                    noExcel

                />
            </ReflexElement>

            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

            <ReflexElement data-cy="Detail" flex={0.67}>
                <BasicCrudScreenType
                    id="MomSysCustomerSupplyOrderBuyDetailMaterial"
                    headerTitle={t("term.customerSupplyAvailable")}
                    view="VwPrePopCustomerSupplyInput"
                    onModifyViewUrl={onModifyViewUrl}
                    onInitialized={(grid) => {
                        grid.childItemsPath = "children";
                        setMomSysCustomerSupplyOrderBuyDetailGrid(grid);
                    }}
                    onApiInitialized={(api) => {
                        setMomSysCustomerSupplyOrderBuyDetailApi(api);
                    }}
                    // onLoadData={(data) => {
                    //     const isEmpty = data.length < 1 ? true : false;

                    //     setEguUglOrderBuyerDetailDataIsEmpty(isEmpty);

                    //     if (onDetailEmpty) {
                    //         onDetailEmpty(isEmpty);
                    //     }
                    // }}
                    onModifyViewSchema={onModifyViewSchema}
                    onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                    noPagination
                    noFilter
                    noSearch
                />

            </ReflexElement>
        </ReflexContainer>

    );
}

export default MomSysCustomerSupplyOrderBuyDetail;
