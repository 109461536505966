function printArray ( array, indent ) {

    let result = '[\n';

    let index = 0;

    for ( let value of array ) {

        if ( index > 0 ) {

            result = result + ",\n";

        }

        result = result + indent +  '  ';

        if ( Array.isArray ( value ) ) {

            result = result + printArray ( value, indent + '  ' );

        } else if ( typeof ( value ) === 'object' ) {

            result = result + printJson ( value, indent + '  ' );

        } else if ( typeof ( value ) === 'string' ) {

            result = result + '"' + value + '"';

        } else if ( value === null ) {

            result = result + 'null';

        } else if ( value === undefined ) {

            result = result + 'undefined';
    
        } else {

            result = result + value

        }

        index++;

    }

    result = result + '\n' + indent + ']';

    return result;

}

function printJsonAttribute ( name, value, indent ) {

    if ( value === undefined ) return '';

    let result = indent + name + ': ';

    if ( Array.isArray ( value ) ) {

        result = result + printArray ( value, indent );

    } else if ( typeof ( value ) === 'object' ) {

        result = result + printJson ( value, indent );

    } else if ( typeof ( value ) === 'string' ) {

        result = result + '"' + value + '"';

    } else if ( value === null ) {

        result = result + 'null';

    } else if ( value === undefined ) {

        result = result + 'undefined';

    } else {

        result = result + value;

    }

    return result;

}

function printJson ( obj, indent, noBrace = false ) {

    let attrCount = 0;

    let result = ( noBrace ? '' : '{\n' );

    for ( let attribute in obj ) {
        
        if ( attrCount > 0 ) result = result + ',\n';

        let attrStr = printJsonAttribute ( attribute, obj[attribute], indent + ( noBrace ? '' : '  ' ) );

        if ( attrStr.length > 0 ) {

            attrCount++;
            result = result + attrStr;

        }

    }

    result = result + ( noBrace ? '' : '\n' + indent + '}' );

    if ( attrCount === 0 ) return '';
    
    return result;

}

export default printJson;
