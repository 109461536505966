import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import notificationState from "../../states/notificationState";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import MomSysBackShipFailureButton from "../input/MomSysBackShipFailureButton";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";

function MomSysMaterialBackShip ( props ) {

    const { t } = useTranslation();

    const [backshipGrid, setBackShipGrid ] = useState ();

    const [logShipSchema, setLogShipSchema] = useState();

    const [onBackShipSelect, setOnBackShipSelect] = useState();

    const [height, setHeight] = useState(0);

    const [ ,setBackShipRefresh ] = useState ();

    const [ backShipStatusRefresh, setBackShipStatusRefresh ] = useState ();

    const [ , setNotification ] = useRecoilState(notificationState);

    const [ isEmpty, setIsEmpty ] = useState();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);
    
    };

    useEffect ( () => {

        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        ( async () => {

            let schema = await getSchema ("MomSysLogShip");

            if ( mounted ) {

                setLogShipSchema ( schema );

            }

        } ) ();

        return ( () => {

            mounted = false;
            window.removeEventListener("resize", handleResize);

        } );

    }, [] );

    useEffect(() => {

        (async () => {

          if (backshipGrid) {

            backshipGrid.selectionChanged.addHandler((grid, event) => {

                setOnBackShipSelect(grid.selectedItems[0]);
                
            });
            
            backshipGrid.itemsSourceChanged.addHandler((grid, event) => {
                
                setOnBackShipSelect(grid.selectedItems[0]);
            });
          }
    
        })();
    
    }, [backshipGrid]);

    const onBackShipInitialized = ( grid ) => {

        grid.selectionMode = "Row";
        
        setBackShipGrid ( grid );
        
    }

    const onBackShipApiInitialized = ( api ) => {

        setBackShipRefresh ( api );
    }

    const onBackShipStatusApiInitialized = ( api ) => {

        setBackShipStatusRefresh ( api );

    }

    const notifySuccess = (msg) => {

        setNotification({
    
          severity: "success",
          msg: msg,
          open: true,
    
        });
    };
    
      const onNotifySuccess = (msg) => {
    
        notifySuccess(msg);

        setBackShipRefresh((api) => {
            
            if( api ) {
                
                api.refresh();
            }

            return api;
        });
    
    };



    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    return( 
        <div style={{ height: height }}>
            
            <ReflexContainer orientation="horizontal">
            
                <ReflexElement flex={0.6}>

                <BasicCrudScreenType 
                        id="MomSysVwAvailableBackShip"
                        view="MomSysVwAvailableBackShip"
                        headerTitle={t("term.backShipList")}
                        onLoadData = {onLoadData}
                        onInitialized={onBackShipInitialized}
                        onApiInitialized={onBackShipApiInitialized}
                        onAppendButtonInFrontOfSearch ={
                            () => (
                                <>
                                <BasicCrudScreenActionItemLayout>
                                        <MomSysBackShipFailureButton
                                            id = "MomSysBackShipButton"
                                            refresh = {backShipStatusRefresh}
                                            onSelect = {onBackShipSelect}
                                            schema = {logShipSchema}
                                            onSuccess = {onNotifySuccess}
                                            disabled = {isEmpty}
                                        />
                                </BasicCrudScreenActionItemLayout>
                                </>

                            )
                        }
                        />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4}>

                    <BasicCrudScreenType 
                        id="MomSysMaterialBackShip"
                        view="MomSysZvwShip"
                        headerTitle={t("term.backShipStatus")}
                        onInitialized = {(grid) => grid.selectionMode = "None"}
                        onApiInitialized = {onBackShipStatusApiInitialized}
                        onModifyViewUrl = {(url) => url + "/backShip"}
                        />

                </ReflexElement>
            </ReflexContainer>
        </div>
    )

}

export default MomSysMaterialBackShip;
    