import React, { useState } from "react";
import DialogLayout from "../layout/DialogLayout";

function Dialog ( props ) {

    const [ instance, setInstance ] = useState ( null );

    const { onInitialized, resizeable, maxWidth, fullWidth, children, ...others } = props;

    const onDialogLayoutInitialized = ( instance ) => {

        setInstance ( instance );

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    }

    // Add instance Property to Dialog Sub-Components - especially for closing dialog

    const dialogProps = {
        ...others,
        instance,
    };
    
    return (
        <DialogLayout resizeable={resizeable} maxWidth={maxWidth} fullWidth={fullWidth} onInitialized={onDialogLayoutInitialized} {...dialogProps}>
            {
                children &&
                React.Children.map(children, ( ( child ) => (
                        React.isValidElement ( child )
                        ?
                        React.cloneElement(child, dialogProps )
                        :
                        child
                ) ) )
            }
        </DialogLayout>
    );

}

export default Dialog;
