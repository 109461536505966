import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguPopOutputMaterialDialog from "../dialogTypes/EguPopOutputMaterialDialog";

function EguPopOutputMaterialButton(props) {

    const { t } = useTranslation();

    const { id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, onSelectMst, onTrigger, ...others } = props;

    const [open, setOpen] = useState(false);

    const [schema, setSchema] = useState();

    useEffect(() => {

        let schema = {

            name: "EguPopProductManage",
            type: "BASE TABLE",
            searchAll: false,

            columns: [

                // {

                //     name: "wareHouseCode",
                //     type: "varchar(50)",
                //     nullable: "NO",
                //     default: null,
                //     disabled: false,
                //     formOrder: 10,
                //     formItemType: "autocomplete",
                //     codeInfo: {
                //         tableName: "MomSysUdtWareHouse",
                //         nameColumn: ["wareHouseName", "wareHouseCode"],
                //         valueColumn: "wareHouseCode",
                //         seperator: ": ",
                //         sortByName: true,
                //     },

                // },
                {

                    name: "date",
                    type: "date",
                    nullable: "NO",
                    default: null,
                    disabled: false,
                    formOrder: 20,
                    formItemType: "date",

                },

            ]

        };

        setSchema(schema);

    }, []);

    const onOpen = () => {

        setOpen(true);

        onTrigger(true);

    };

    const onClose = () => {

        setOpen(false);

        onTrigger(false);

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    const modifyUrlCallback = (url) => {

        return `${url}/${onSelectMst.planId}/${onSelectMst.orderBuyerId}/outputMaterial`;

    }

    return (

        <>
            <CreateRecordButtonLayout id={id + "-outputMaterial-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <EguPopOutputMaterialDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={t(`dialog.title.Produce.outputMaterial`)}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyUrlCallback={modifyUrlCallback}
                onSelectMst={onSelectMst}
                noKeepOpen={true}
            />
        </>

    );
}

export default EguPopOutputMaterialButton;

