import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
    label: {
        fontSize: "smaller",
        flexGrow: 1
    },
}));

function ColumnPickerItemLayout ( props ) {

    const classes = useStyle();

    const { children, ...others } = props;

    return (
        children &&
        React.Children.map(children, ( ( child ) => (
                React.isValidElement ( child )
                ?
                React.cloneElement(child, {classes: { label: classes.label }, ...others} )
                :
                child
        ) ) )
    );

}

export default ColumnPickerItemLayout;

