import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import PopNoOrderPurchaseInputDoInputDialog from "../dialogTypes/PopNoOrderPurchaseInputDoInputDialog";
import getExpirationDate from "../../lib/utils/getExpirationDate";

function PopNoOrderPurchaseInputDoInputButton(props) {
    const { id, schema, grid, refresh, onSuccess, onFailure, customizeErrorMsgCallback, ...others } = props;

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    useEffect(() => {
        let modifiedSchema = cloneDeep(schema);

        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "wareHouseCode":
                        column.hideInForm = false;
                        column.required = true;

                        break;

                    case "inputDate":
                        column.formOrder = 190;
                        column.formGroup = 1;
                        column.formItemType = "date";
                        column.hideInForm = false;
                        break;

                    case "jaHo":
                    case "description":
                    case "qcResult":
                    case "defectiveCode":
                        column.hideInForm = true;

                    default:
                        // column.disabled = true;
                        // column.required = false;
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [schema]);

    const onOpen = () => {
        
        if (grid) {
            const selectedItem = grid.selectedItems[0]
            
            setFormData({ ...grid.selectedItems[0] , 
                manufacturedDate: new Date(),
                expirationDate : getExpirationDate(selectedItem.standardExpirationDate, new Date()),
                extLotNo: "extLot", });
        }
        
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {
            onSuccess ( msg );
        }

        if (refresh) {
            refresh.refresh();
        }
    };
    

    return (
        <React.Fragment>
            <ActionButtonLayout id={id+ "-create-button"} onClick={onOpen} {...others}>
                {t("buttonName.input")}
            </ActionButtonLayout>
            
            <PopNoOrderPurchaseInputDoInputDialog
                id={id + "create-form"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
            
        </React.Fragment>
    );
}

export default PopNoOrderPurchaseInputDoInputButton;
