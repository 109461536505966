import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles(() => ({
    root: {
        position: "absolute",
        top: "48px",
        bottom: "52px",
        left: 0,
        right: 0,
        padding: 0,
        margin: 0,
        overflow: "hidden",
        borderTop: "1px solid rgb(212, 212, 212)"
    },
}));

function BasicCrudScreenContentLayout ( props ) {

    const classes = useStyle();
    const { children, className, ...others } = props;

    return (
        <main className={classes.root}>
            {
                React.Children.map(children, ( child => 
                    React.cloneElement(child, {...others}) ) )
            }
        </main>
    );

}

export default BasicCrudScreenContentLayout;

