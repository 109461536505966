import { useRecoilValue } from "recoil";

import TopMenuTitleItem from "../presentation/TopMenuTitleItem";
import TopMenuSelectItem from "../input/TopMenuSelectItem";
import TopPopMenuSelectItem from "../input/TopPopMenuSelectItem";
import TopMenuDividerItem from "../presentation/TopMenuDividerItem";
import TopMenuDividerSmallItem from "../presentation/TopMenuDividerSmallItem";

import topMenuSelectionState from "../../../states/topMenuSelectionState";

import "./TopMenuItemLayout.css";


function TopMenuItemLayout(props) {

    const { classes } = props;
    const topMenuSelection = useRecoilValue(topMenuSelectionState);

    switch ( props.menuItem.menuType.toLowerCase() ) {

        case "title":

            return <TopMenuTitleItem classes={classes} menuItem={props.menuItem} />;

        case "menu":

            return <TopMenuSelectItem classes={classes} selected={topMenuSelection === props.menuItem.topMenuItemId} menuItem={props.menuItem} onClick={props.onClick} />;

        case "popmenu":

            return <TopPopMenuSelectItem classes={classes} selected={topMenuSelection === props.menuItem.topMenuItemId} menuItem={props.menuItem} onClick={props.onClick} />;

        case "dividersmall":

            return <TopMenuDividerSmallItem classes={classes} />;

        default:

            return <TopMenuDividerItem classes={classes} />;

    }

}

export default TopMenuItemLayout;
