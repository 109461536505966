import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import FormCustomDialog from "../dialogTypes/FormCustomDialog";

function SummarizeDailyStockButton(props) {

    const { t } = useTranslation();

    const { id, schema, apiUrl, method, refresh, onClose, onSuccess, customValidation, createButtonName, customizeErrorMsgCallback, setInitialDataCallback, modifyUrlCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {

        setOpen(true);

    };

    const onDialogClose = () => {

        setOpen(false);

        if (onClose) {

            onClose();

        }

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        if (refresh) {
            refresh.refresh();
        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} createButtonName={createButtonName} {...others} />
            <FormCustomDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.apply`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                apiUrl={apiUrl}
                labels={[t('common.apply')]}
                onClose={onDialogClose}
                onSuccess={onCreateSuccess}
                customValidation={customValidation}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                modifyUrlCallback={modifyUrlCallback}
                method={method}
            />
        </>

    );
}

export default SummarizeDailyStockButton;

