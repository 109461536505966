/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, FormHelperText, InputLabel, TextField, Snackbar} from "@material-ui/core";
import { useEffect, useState, useRef} from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../../lib/common/apiClient";
import getSpecific from "../../../lib/api/getSpecific";

import IconButton from "../../input/IconButton";
import Icon from "../../presentation/Icon";

import InputAdornment from '@mui/material/InputAdornment';
import {BsQrCodeScan} from 'react-icons/bs'
import _, { debounce } from "lodash";

import uiConf from "../../../config/uiConf";
import { Alert } from "@material-ui/lab";

const Hangul = require('hangul-js');


function FormItemBarcode ( props ) {

    const { t } = useTranslation ();
    const [barcode , setBarcode ] = useState('');
    const barcodeRef = useRef(null)
    const [barcodeApply, setBarcodeApply] = useState(true)

    const { id, className, column, label, value, helperText, InputLabelProps, onChange, barcodeCallback, focusRef, focusRefCurrent, ...others } = props;

    useEffect ( () => {       
        setBarcode ( value );
    }, [value]);

    
    let barcodeProps = {
        InputLabelProps: {
            shrink: true,
            ...InputLabelProps,
        },
        onChange: ( event ) => {
            setBarcode(event.target.value);        
        },
        ...others
    };

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    if ( column.InputLabelProps ) {
        barcodeProps.InputLabelProps = { ...barcodeProps.InputLabelProps, ...column.InputLabelProps };
    }

    if ( column.inputProps ) {
        barcodeProps.inputProps = column.inputProps;
    }

    if ( column.style ) {
        barcodeProps.style = column.style;
    }

    const barcodeConverter = (input) => {
        // console.log('input:',input)

        const koreanToEnglishMap = {
            'ㅂ': 'Q',
            'ㅈ': 'W',
            'ㄷ': 'E',
            'ㄱ': 'R',
            'ㅅ': 'T',
            'ㅛ': 'Y',
            'ㅕ': 'U',
            'ㅑ': 'I',
            'ㅐ': 'O',
            'ㅔ': 'P',
            'ㅁ': 'A',
            'ㄴ': 'S',
            'ㅇ': 'D',
            'ㄹ': 'F',
            'ㅎ': 'G',
            'ㅗ': 'H',
            'ㅓ': 'J',
            'ㅏ': 'K',
            'ㅣ': 'L',
            'ㅋ': 'Z',
            'ㅌ': 'X',
            'ㅊ': 'C',
            'ㅍ': 'V',
            'ㅠ': 'B',
            'ㅜ': 'N',
            'ㅡ': 'M',
            'ㄲ': 'R',
            'ㄸ': 'E',
            'ㅃ': 'Q',
            'ㅆ': 'T',
            'ㅉ': 'W',
            'ㅒ': 'O',
            'ㅖ': 'P',
        };

        let output = '';

        for (let i = 0; i < input.length; i++) {
            const char = input.charAt(i);
            
            if (koreanToEnglishMap[char]) {
                output += koreanToEnglishMap[char];
            }  else {
                output += char;
            }
        }

        return output
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(barcode)
        }, 10)

        return () =>  clearTimeout(delayDebounceFn) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barcode])

    const barcodeApiProcess = async () => {
        const codeInfo = column.codeInfo;

        try {
            let result = await getSpecific(codeInfo.tableName, barcode)

            let toBarcode = '';
            let first = true;
            let seperator = ', '
            let barcodeCallbackResult = {}

            if (codeInfo.seperator) seperator = codeInfo.seperator

            for ( const columnName of codeInfo.nameColumn ) {
                if (first) {
                    toBarcode = result[columnName]
                    barcodeCallbackResult[columnName] = result[columnName]
                    first = false
                }
                else {
                    toBarcode += seperator + result[columnName]
                    barcodeCallbackResult[columnName] = result[columnName]
                }
            }

            setBarcodeApply(false)
            setBarcode(toBarcode)

            if (barcodeCallback) barcodeCallback(barcodeCallbackResult)   
        } catch ( reason ) {
            console.error ( `Get Code Specific from Table "${ codeInfo.tableName }" Failed` );
            notifyFailure(  
                t("error.notFound", {
                    reason: reason.response
                        ? reason.response.data
                        : JSON.stringify(reason),
                })
            );
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
          // QR 코드 값이 모두 입력되었을 때 처리하는 로직 작성
          
          if (!barcodeApply ) return

          if (barcode.length > 0) {
            const convertedInput = barcodeConverter(Hangul.disassemble(barcode).join(''))

            if (convertedInput != barcode) {
                setBarcode(barcodeConverter(Hangul.disassemble(barcode).join('')))
                return
            }      

            if (column.codeInfo == undefined || column.codeInfo.type == undefined) {
                if (barcodeCallback) barcodeCallback(barcode)   
            }
            else if (column.codeInfo.type == 'normal') {
                barcodeApiProcess()
            }
            else if (column.codeInfo.type == 'extlot') {
                let url = `/api/MomPopLotBarcodeBasicInfo`;

                let form = new FormData();
        
                let json = {};
                let deleted = {};
        
                json['extLotid'] = barcode
        
                form.append("deleted", JSON.stringify(deleted));
                form.append("json", JSON.stringify(json));
        
                apiClient
                    .post(url, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then ( ( result ) => {
                        onApiSuccess ( result );
                    } )
                    .catch ( ( reason ) => {
                        onApiFailure ( reason );
                } );       
            }
          }
        }, 300); // 초의 디바운스 시간 설정
    
        return () => {
          clearTimeout(timerId);
        };
    }, [barcode]);

    const onApiSuccess = (result) => {
        const resultData = result.data.data[0]
        // setBarcodeApply(false)
        if (barcodeCallback) barcodeCallback(resultData)   
    };

    const onApiFailure = (reason) => {
        notifyFailure(  
            t("error.notFound", {
                reason: reason.response
                    ? reason.response.data
                    : JSON.stringify(reason),
            })
        );
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );
    };

    const textClearClick = (e) => {
        focusRefCurrent?.focus()
        setBarcode('');
        setBarcodeApply(true)
    }

    return (
        <FormControl className={className} required={column.required} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { label }
            </InputLabel>

            <TextField inputRef={focusRef} 
                value={barcode} 
                labelId={ id + "-label" }
                id={ id }
                style={{paddingTop: "16px"}}
                
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BsQrCodeScan />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton  onClick={textClearClick} disabled={barcodeProps.disabled}>
                            <Icon >close</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
                { ...barcodeProps }
                />

            <FormHelperText>{ helperText }</FormHelperText>

            <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >

                    {notification.msg}

                </Alert>

            </Snackbar>
        </FormControl>
    );

}

export default FormItemBarcode;
