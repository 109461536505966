import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import "./gridEvent.css"

function InspectionItem(props) {
    const { id, selectMstGrid, selectGroupByMaterialGrid, groupItemGridSetting } = props;

    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [, setOnSelectGrid] = useState();

    const { t } = useTranslation();
    
    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectGroupByMaterialGrid]);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        groupItemGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        groupItemGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        groupItemGridSetting(grid.selectedItems[0]);
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        groupItemGridSetting([]);
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onModifyUrl = (url) => {
        let modifyUrl = 'api/MomSysInspectionRslt';
        modifyUrl += '/selfInspectionGroupItem';
        modifyUrl += `/${selectMstGrid.lotNo}`;
        modifyUrl += `/${selectGroupByMaterialGrid.inspectionGroupId}`;
        modifyUrl += '/null';

        return modifyUrl;
    };

    return (
        <>
            <BasicCrudScreenType
                id={id}
                table="MomSysInspectionRslt"
                view="SelfInspectionGroupItemView"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                onModifyViewUrl={(url) => onModifyUrl(url)}
                headerTitle={t("term.momSysInspectionItem")}
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default InspectionItem;
