import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function PsmMaterialEventData(props) {
    const { psmMaterialListSelect, onEventDataLoad } = props;

    const view = "PsmVwMaterialEventData";

    const [, setPsmMaterialEventDataSelect] = useState();
    const [, setPsmMaterialEventDataIsEmpty] = useState();
    const [psmMaterialEventDataList, setPsmMaterialEventDataList] = useState([]);
    const [psmMaterialEventDataGrid, setPsmMaterialEventDataGrid] = useState();
    const [, setPsmMaterialEventDataApi] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        setPsmMaterialEventDataApi((api) => {
            if (api) {
                api.refresh();
            }

            return api;
        });
    }, [psmMaterialListSelect.materialCode, psmMaterialListSelect.materialCodeVer]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (psmMaterialEventDataGrid && mounted) {
                psmMaterialEventDataGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialEventDataSelect(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialEventDataSelect();
                        }
                    },10)
                );

                psmMaterialEventDataGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPsmMaterialEventDataSelect(grid.selectedItems[0]);
                        } else {
                            setPsmMaterialEventDataSelect();
                        }
                    },10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [psmMaterialEventDataGrid]);

    useEffect(() => {
        if (
            psmMaterialListSelect &&
            psmMaterialEventDataGrid &&
            psmMaterialEventDataList &&
            psmMaterialEventDataList?.length > 0 &&
            psmMaterialListSelect.materialCode === psmMaterialEventDataList[0].eventMaterialCode &&
            psmMaterialListSelect.materialCodeVer === psmMaterialEventDataList[0].eventMaterialCodeVer
        ) {
            let netQty = psmMaterialListSelect.qtyCurrent;

            let netQtyBelowSafetyStockFlag = false;

            /***
            for (const item of psmMaterialEventDataList) {
                netQty += item.eventQty;
                item.netQty = netQty;
            }
            ***/

            psmMaterialEventDataGrid.rows.forEach((row, idx) => {
                netQty += row.dataItem.eventQty;
                row.dataItem.netQty = netQty;

                if (row.dataItem.netQty < psmMaterialListSelect.qtySafetyStock) {
                    if (netQtyBelowSafetyStockFlag === false) {
                        row.cssClass = `failed-stock-apply`;
                        netQtyBelowSafetyStockFlag = true;
                    }
                } else {
                    row.cssClass = null;
                    if (netQtyBelowSafetyStockFlag === true) {
                        netQtyBelowSafetyStockFlag = false;
                    }
                }
            });

            if (onEventDataLoad) {
                onEventDataLoad(psmMaterialEventDataList);
            }

            /*** 2022/08/16 itkim : grid.refresh() is useless
            psmMaterialEventDataGrid.refresh(false); // false : refresh grid content only
            ***/

            /***
            if (onUpdateMaster) {
                console.log("[PsmMaterialEventData] Call onUpdateMaster()...");
                onUpdateMaster(psmMaterialEventDataList);
            }
            ***/
        }
    }, [psmMaterialListSelect, psmMaterialEventDataGrid, psmMaterialEventDataList, onEventDataLoad]);

    return (
        <BasicCrudScreenType
            id="PsmVwMaterialEventData"
            headerTitle={t("term.psmMaterialEventData")}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            noPagination
            fixedOrder={[{column: "eventTime", order: "ASC"}]}
            onModifyViewUrl={(url) =>
                `${url}/${psmMaterialListSelect.materialCode}/${psmMaterialListSelect.materialCodeVer}`
            }
            onInitialized={(grid) => {
                grid.selectionMode = "Row";
                grid.allowSorting = false;
                setPsmMaterialEventDataGrid(grid);
            }}
            onApiInitialized={(api) => {
                setPsmMaterialEventDataApi(api);
            }}
            onLoadData={(data) => {
                setPsmMaterialEventDataIsEmpty(data.length < 1 ? true : false);
                setPsmMaterialEventDataList(data);
            }}
        />
    );
}

export default PsmMaterialEventData;
