import { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateMaterialOutputButton from "../input/CreateMaterialOutputButton";
import CreateMaterialDueDiligenceButton from "../input/CreateMaterialDueDiligenceButton";
import CreateProductInputButton from "../input/CreateProductInputButton";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import authProfileState from "../../states/authProfileState";
import AccessControl from "../functional/AccessControl";

function ProductInventory (props){

    const id ="ProductInventory" ;
    const table ="ProductInOutput";
    const view ="VwProductInventory";

    const [tableSchema, setTableSchema] = useState();
    const [viewApi, setViewApi] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const profile = useRecoilState(authProfileState);

    useEffect(() => {

        (async () => {

            let schema = await getSchema(table);
            setTableSchema(schema);

        })();
    }, [table]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onAppendHeaderAtFirst = () => {
        return (
            <BasicCrudScreenActionItemLayout>
                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="POST">
                    <CreateProductInputButton
                        id={id + "inputButton"}
                        onSuccess={onSuccess}
                        createButtonName="materialInput"
                        schema={tableSchema}
                        refresh={viewApi}
                        profileName={profile[0].username}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod={tableSchema && (tableSchema.updateWithPatch?"PATCH":"PUT")}>
                    <CreateMaterialOutputButton
                        id= {id + "outputButton"}
                        onSuccess={onSuccess}
                        createButtonName="materialOutput"
                        schema={tableSchema}
                        refresh={viewApi}
                        profileName={profile[0].username}
                    />
                </AccessControl>

                <AccessControl accessTarget={tableSchema && tableSchema.name} accessMethod="DELETE">
                    <CreateMaterialDueDiligenceButton
                        id= {id + "adjustmentButton"}
                        dueDiligence="dueDiligence"
                        onSuccess={onSuccess}
                        createButtonName="adjustment"
                        schema={tableSchema}
                        refresh={viewApi}
                        profileName={profile[0].username}
                    />
                </AccessControl>
            </BasicCrudScreenActionItemLayout>
        );
    };

    return <BasicCrudScreenType
            id ={id}
            table={table}
            view={view}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendHeaderAtFirst={onAppendHeaderAtFirst}
            onApiInitialized={setViewApi} 
        />
    }

export default ProductInventory;