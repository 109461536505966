/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { IconButton, makeStyles, Step, StepConnector, StepLabel, Stepper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import clsx from "clsx";

import Avatar from "../presentation/Avatar";
import Icon from "./Icon";

const Connector = withStyles ( {
    alternativeLabel: {
        top: 22,
      },
      active: {
        '& $line': {
          backgroundImage:
            'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
      },
      completed: {
        '& $line': {
          backgroundImage:
            'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
      },
      line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
      },
} ) ( StepConnector );

const useStyles = makeStyles ( ( theme ) => ({
    root: {
        width: '100%',
    },
    icon: {
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatar: {
        width: 50,
        height: 50,
    },
    requested: {
        backgroundColor: theme.palette.secondary.main,
    },
    approver: {
        backgroundColor: theme.palette.primary.main,
    },
    selected: {
        border: '2px solid red'
    },
    notSelected: {
    },
    approved: {
        backgroundColor: theme.palette.secondary.main,
    }
} ) );

function ApprovalPath ( props ) {

    const { t } = useTranslation ();

    const {

        noSelect,

        approvalPath,
        approvalPathNodes,
        
        onApproverSelected
    
    } = props;

    const [ selected, setSelected ] = useState ();

    useEffect ( () => {

        if ( approvalPathNodes ) {
        
            if ( approvalPathNodes.length > 0 ) {

                setSelected ( 0 );

                if ( onApproverSelected ) {

                    onApproverSelected ( approvalPathNodes[0] );

                }
    
            } else {
    
                setSelected ( undefined );
    
            }
    
        }

    }, [approvalPathNodes] );

    const classes = useStyles ();

    const onSelectApprover = ( index, selected ) => {

        setSelected ( index );
                
        if ( onApproverSelected ) {

            onApproverSelected ( selected );

        }

    }

    const getRequesterIcon = ( props ) => {

        return (

            <div
                className={classes.icon}
            >
                <Avatar
                    className={clsx(classes.avatar, classes.requested) }
                />
            </div>

        );
    };

    const getApproverIcon = ( props ) => {

        const { selected, index, approvalPathNode } = props

        return (

            <div
                className={classes.icon}
            >
                {
                    noSelect
                    ?
                    <Avatar
                        photo={approvalPathNode.photo}
                        email={approvalPathNode.email}
                        className={clsx(classes.avatar, classes.approver)}
                    />
                    :
                    <IconButton
                        color="inherit"
                        onClick={ () => onSelectApprover ( index, approvalPathNode ) }
                    >
                        <Avatar
                            photo={approvalPathNode.photo}
                            email={approvalPathNode.email}
                            className={clsx(classes.avatar, classes.approver, selected?classes.selected:classes.notSelected)}
                        />
                    </IconButton>
                }
            </div>

        );
    };

    const getApprovedIcon = ( props ) => {

        return (

            <div
                className={classes.icon}
            >
                <Avatar
                    className={clsx(classes.avatar, classes.approved) }
                >
                    <Icon>approval</Icon>
                </Avatar>
            </div>

        );
    };

    return (

        <div className={classes.root} >

            {
                ( approvalPath && approvalPathNodes )
                ?
                <Stepper alternativeLabel activeStep={approvalPathNodes.length + 1} connector={<Connector />} >
                    <Step>
                        <StepLabel
                            StepIconComponent={getRequesterIcon}
                        >
                            { t ( "term.approvalPhase.request" )  }
                        </StepLabel>

                    </Step>
                    {
                        approvalPathNodes
                            .sort ( ( nodeA, nodeB ) => ( nodeA.order - nodeB.order ) )
                            .map ( ( node, order ) => (
                                <Step key={node.approver} >
                                    <StepLabel
                                        StepIconComponent={ () => getApproverIcon ( { selected: selected===order, index: order, approvalPathNode: node } ) }
                                    >
                                        {`${t("term.approvalPhase.approver")}${order+1} : ${node.fullName} [${node.approver}]`}
                                    </StepLabel>
                                </Step>
                            ) )
                    }
                    <Step>
                        <StepLabel
                            StepIconComponent={getApprovedIcon}
                        >
                            { t ( "term.approvalPhase.approved" ) }
                        </StepLabel>
                    </Step>

                </Stepper>
                :
                <></>

            }

        </div>

    );



}

export default ApprovalPath;

