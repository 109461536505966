import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import apiClient from "../../lib/common/apiClient";

function OptionListLoader ( props ) {

    const { optionPath, onLoaded } = props;

    const [ , setOptionMap] = useState({});

    let location = useLocation ();
    
    useEffect(() => {

        ( async () => {

            let result = await apiClient.get(`/noAuth/OptionList/${optionPath?optionPath:location.pathname.split("/")[2]}`)
            let optionMap = result.data;

            setOptionMap( optionMap );

            if ( onLoaded ) {
                
                onLoaded( optionMap );

            }

        }) ();

    }, [location.pathname, onLoaded, optionPath])

    return <></>;
}

export default OptionListLoader;
