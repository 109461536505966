import { useState, useEffect } from "react";
import { Hidden, MenuItem, Select, Checkbox, FormControlLabel } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useSetRecoilState } from "recoil";
import { ContextMenu } from "dhx-suite-package";

import apiClient from "../../lib/common/apiClient";
import getList from "../../lib/api/getList";
import getSpecific from "../../lib/api/getSpecific";
import getNewDateObj from "../../lib/common/getNewDateObj";
import { getOption } from "../../lib/api/getOptions";

import Search from "../functional/Search";
import SearchTextField from "../input/SearchTextField";

import AccessControl from "../functional/AccessControl";

import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";

import BasicCrudScreenTypeLayout from "../screenTypes/layout/BasicCrudScreenTypeLayout";
import BasicCrudScreenHeaderLayout from "../screenTypes/layout/BasicCrudScreenHeaderLayout";
import BasicCrudActionsLayoutWithoutBorder from "../screenTypes/layout/BasicCrudActionsLayoutWithoutBorder";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenContentWithoutFooterLayout from "../screenTypes/layout/BasicCrudScreenContentWithoutFooterLayout";

import GanttChart from "../gantt/input/GanttChart";
import { useTranslation } from "react-i18next";
import ActionButtonLayout from "../layout/ActionButtonLayout";

import notificationState from "../../states/notificationState";

import Notification from "../presentation/Notification";
import CreateProductionPlanDialog from "../dialogTypes/CreateProductionPlanDialog";
import AssignWorkerDialog from "../dialogTypes/AssignWorkerDialog";
import AssignMachineDialog from "../dialogTypes/AssignMachineDialog";
import SplitTaskDialog from "../dialogTypes/SplitTaskDialog";
import ChangeTaskDurationDialog from "../dialogTypes/ChangeTaskDurationDialog";
import OrderTaskDialog from "../dialogTypes/OrderTaskDialog";
import CancelOrderDialog from "../dialogTypes/CancelOrderDialog";
import CreateMilestoneDialog from "../dialogTypes/CreateMilestoneDialog";
import ChangeMilestoneNameDialog from "../dialogTypes/ChangeMilestoneNameDialog";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";


import 'dhx-suite-package/codebase/suite.css';
import '../gantt/layout/Gantt.css';

const style ={
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    margin: 0,
    overflow: "hidden",
    borderTop: "1px solid rgb(212, 212, 212)"
};

const reloadFlag = {
    default: 0,
    showGrid: 1,
    addPlan: 2
}

function ProductionPlan ( props ) {

    const { t } = useTranslation ();

    const setNotification = useSetRecoilState(notificationState);

    const months = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ];

    const scales = {
        "day": [
            {
                unit: "month",
                step: 1,
                format: "%M"
            },    
            {

                unit: "day",
                step: 1,
                format: "%n/%j"

            }
        ],
        "week": [
            {
                unit: "month",
                step: 1,
                format: "%M"
            },    
            {

                unit: "week",
                step: 1,
                format: "%n/%j"

            }
        ],
        "month": [
            {
                unit: "month",
                step: 1,
                format: "%M"
            }
        ]
    };

    const orderedSelections = [
        {
            name: "selectItems.orderedSelection.notOrdered",
            value: "0"
        },
        {
            name: "selectItems.orderedSelection.ordered",
            value: "1"
        },
        {
            name: "selectItems.orderedSelection.all",
            value: "2"
        },
    ];
    
    const completedSelections = [
        {
            name: "selectItems.completedSelection.notCompleted",
            value: "0"
        },
        {
            name: "selectItems.completedSelection.completed",
            value: "1"
        },
        {
            name: "selectItems.completedSelection.all",
            value: "2"
        },
    ];

    const now = new Date ();

    if ( now.getDate () < 15 ) {

        now.setMonth ( now.getMonth () - 1 );

    }

    const [search, setSearch] = useState ();
    const [years, setYears] = useState ([]);
    const [resources, setResources] = useState ( [] );

    const [selectedYear, setSelectedYear] = useState ( now.getFullYear () );
    const [selectedMonth, setSelectedMonth] = useState ( now.getMonth () + 1 );
    const [selectedResource, setSelectedResource ] = useState ( 0 );
    const [showMonthCount, setShowMonthCount] = useState ( 2 );

    const [ordered, setOrdered] = useState ( "2" ); // 0: not ordered, 1: ordered, 2: all
    const [completed, setCompleted] = useState ( "2" ); // 0: not completed, 1: completed, 2: all
    const [scale, setScale] = useState ( "day" );
    const [hideResourceView, setHideResourceView] = useState ( false );

    const [plan, setPlan] = useState ();
    const [planData, setPlanData] = useState ();

    const [confirmDialogTitle, setConfirmDialogTitle] = useState ();
    const [confirmMsg, setConfirmMsg] = useState ();
    const [confirmData, setConfirmData] = useState ();
    const [openConfirmDialog, setOpenConfirmDialog] = useState ( false );
    const [showGrid, setShowGrid] = useState ( true );
    const [, setIsSplitTask] = useState( false );
    
    const [ , setMoveToTodayTask ] = useState ( false );
    const [, setAddMilestoneDisabled] = useState ( true );
    const [, setGantt] = useState ();
    const [, setTaskSelected] = useState ();
    const [, setBackupPlanData] = useState ();
    const [, setCreateProductionPlanDialog] = useState ();
    const [, setAssignWorkerDialog] = useState ();
    const [, setAssignMachineDialog] = useState ();
    const [, setSplitTaskDialog] = useState ();
    const [, setChangeTaskDurationDialog] = useState();
    const [, setOrderTaskDialog] = useState ();
    const [, setCancelOrderDialog] = useState ();
    const [, setCreateMilestoneDialog] = useState ();
    const [, setChangeMilestoneNameDialog] = useState();
    const [, setTimer] = useState ();
    const [, setLastTaskId] = useState ();
    const [, setUpdateCtx] = useState ({
        queue: [],
        map: {}
    } );

    useEffect( () => {

        let mounted = true;

        ( async () => {

            let moveToTodayTask = await getOption ( "productionPlan", "moveToTodayTask" );

            if ( mounted ) {

                if ( moveToTodayTask && moveToTodayTask.toLowerCase() === "true" ) {

                    setMoveToTodayTask ( true );
    
                } else {
    
                    setMoveToTodayTask ( false );
    
                }
    
            }

            let apiResult;
            let minYear, maxYear;

            apiResult = await getList ( "VwProcessPlanMinMaxYear" );

            if ( apiResult.data.length ) {

                minYear = apiResult.data[0].minYear;
                maxYear = apiResult.data[0].maxYear;

                if ( !minYear ) {
                    minYear = now.getFullYear ();
                }

                if ( !maxYear ) {
                    maxYear = now.getFullYear ();
                }

                if ( now.getFullYear () < minYear ) {

                    minYear = now.getFullYear ();

                }

                if ( now.getFullYear () > maxYear ) {

                    maxYear = now.getFullYear ();

                }

            } else {


                minYear = now.getFullYear ();
                maxYear = minYear;

            }

            let years = [];

            for ( let year = minYear; year <= maxYear; year++ ) {
                years.push ( year.toString() );
            }

            if ( mounted ) {

                setYears ( years );

                setSelectedYear ( now.getFullYear ().toString() );
                setSelectedMonth ( ( now.getMonth () + 1 ).toString() );
    
            }
    
        } ) ();
    
        return ( () => {

            mounted = false;

        } );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );
    
    const reload = ( selectedYear, selectedMonth, reloadType, reloadTaskId ) => {

        setScale ( ( scale ) => {

            setShowMonthCount ( ( monthCount ) => {

                setShowGrid ( ( showGrid ) => {

                    if ( scale ) {
                        
                        ( async () => {
                            
                            let openSplitTask = await getOption ( "productionPlan", "openSplitTask" );
                            let splitMaterialPlan = await getOption ( "productionPlan", "splitMaterialPlan" );

                            openSplitTask = openSplitTask === "true" ? true : false;
                            splitMaterialPlan = splitMaterialPlan === "true" ? true : false;

                            let startDate = `${selectedYear}-${selectedMonth}-01 00:00:00`;
                            let date = new Date ( startDate );

                            startDate = date.toISOString();
                    
                            date.setMonth ( date.getMonth () + monthCount );
                            date.setDate ( 0 );
                            
                            let endDate = date.toISOString();
                    
                            let planData = await getSpecific ( `EguPlan?startDate=${startDate}&endDate=${endDate}` );
                    
                            planData.planMap = {};
                    
                            for ( let task of planData.tasks.data ) {

                                switch ( task.taskType ) {

                                    case "materialPlan":

                                        if ( splitMaterialPlan ) {

                                            task.render = "split";

                                        }

                                        break;

                                    default:
                                        break;

                                }
                                planData.planMap[task.id] = task;
                    
                            }
                    
                            planData.resourceMap = {};
                    
                            for ( let resource of planData.resources ) {
                                planData.resourceMap[resource.id] = resource;
                            }
                    
                            let backupCloneData = cloneDeep ( planData );
                            
                            let selectTaskId = undefined
                            let scrollPosition = undefined

                            if (reloadType === reloadFlag.showGrid ) {
                                
                                setGantt ( ( gantt ) => {
                                    selectTaskId = gantt?.getSelectedId()
                                    scrollPosition = gantt?.getScrollState()
                                    return gantt;
                                } );
                            }
                            else setTaskSelected ( undefined );

                            let resources = cloneDeep ( planData.resources ).sort ( ( a, b ) => b.text.localeCompare( a.text ) ).filter ( ( resource ) => ! [1,2,3].includes ( resource.id ) );
                            resources.unshift ( { id: "", text: t ( "term.all" ) } );
                    
                            setResources ( resources );
                    
                            planData.scales = scales[scale];
                            planData.startDate = startDate;
                            planData.endDate = endDate;
                            planData.showGrid = showGrid;                
                            planData.openSplitTask =  openSplitTask;

                            setPlanData ( planData );
                            setBackupPlanData ( backupCloneData );

                            if (reloadType === reloadFlag.showGrid) {
                                setGantt ( ( gantt ) => {
                                    setIsSplitTask( ( isSplit ) => {
                                        /**
                                         * 분할될 계획을 왼클릭 후 분할하면 gantt?.selectTask(selectedTaskId); 
                                         * 부분에서 3번 반복하면서 $split_subtask undefined 오류발생 하므로 selectedTaskId 를 null 처리
                                         */

                                        let selectedTaskId = selectTaskId;
    
                                        if(isSplit) {
                                            selectedTaskId = null;
                                        }
                                        
                                        gantt?.selectTask(selectedTaskId);
                                        
                                        if (scrollPosition){
                                            gantt?.scrollTo(scrollPosition.x, scrollPosition.y)
                                        }
                                    
                                        return isSplit;
                                    })

                                    return gantt;
                                                        
                                } )

                                setIsSplitTask(false);
                            }
                            else if (reloadType === reloadFlag.addPlan) {
                                setGantt ( ( gantt ) => {
                                    
                                    gantt?.selectTask(reloadTaskId)
                                    const reloadTask = gantt?.getTask(reloadTaskId)
                                    
                                    let taskPositionTop = 0

                                    if (reloadTask) {
                                        let orderTime = getNewDateObj (reloadTask.orderTime);
                                        taskPositionTop = gantt?.getTaskPosition(reloadTask, orderTime).top;
                                    }
                                    
                                    if (taskPositionTop < 40) gantt?.scrollTo(0, taskPositionTop )
                                    else  gantt?.scrollTo(0, taskPositionTop - 40 )

                                    return gantt;
                                                        
                                } )
                            }
                            else setTimeout ( showToday (), 0 );
                        } ) ();
            
                    }
    
                    return showGrid;

                } );

                return monthCount;
                
            } );

            
            return scale;

        } );


    };

    useEffect ( () => {

        reload ( selectedYear, selectedMonth, reloadFlag.default, 0 );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, selectedMonth] );

    useEffect ( () => {

        setGantt ( ( gantt ) => {

            gantt?.refreshData ();

            return gantt;

        } );

    }, [search] );

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const notifyError = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
        
    };

    const notifyWarning = (msg) => {

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
        
    };

    const onSuccess = ( msg ) => {

        setSelectedYear ( ( selectedYear ) => {

            setSelectedMonth ( ( selectedMonth ) => {

                reload ( selectedYear, selectedMonth, reloadFlag.showGrid, 0 );

                setTimeout ( notifySuccess ( msg ), 500 );

                return selectedMonth;

            } );

            return selectedYear;

        } );

    };

    const onSuccessOrderTask = ( msg, taskId, orderTime ) => {

        onSuccess ( msg );

    };

    const onSuccessCancelOrder = ( msg, taskId ) => {

        onSuccess ( msg );

    };

    const onSuccessCreate = ( msg, planId ) => {

        setLastTaskId ( planId );
        
        setSelectedYear ( ( selectedYear ) => {

            setSelectedMonth ( ( selectedMonth ) => {
                
                reload ( selectedYear, selectedMonth, reloadFlag.addPlan, planId );

                setTimeout ( notifySuccess ( msg ), 500 );

                return selectedMonth;

            } );

            return selectedYear;

        } );
        return planId
        

    };

    const onYearChange = ( event ) => {

        setSelectedYear ( parseInt ( event.target.value ) );

    };

    const onMonthChange = ( event ) => {

        setSelectedMonth ( parseInt ( event.target.value ) );

    };


    const onResourceChange = ( event ) => {

        setTaskSelected ( ( task ) => {

            if ( task ) {

                setGantt( ( gantt ) => {

                    gantt.unselectTask ( task.id )
                    return gantt;
        
                } )
    
            }
    
            return task;

        } );

        setSelectedResource ( event.target.value );

    };

    const onOrderedChange = ( event ) => {

        setTaskSelected ( ( task ) => {

            if ( task ) {

                setGantt( ( gantt ) => {

                    gantt.unselectTask ( task.id )
                    return gantt;
        
                } )
    
            }
    
            return task;

        } );

        setOrdered ( event.target.value );

    };

    const onCompletedChange = ( event ) => {

        setTaskSelected ( ( task ) => {

            if ( task ) {

                setGantt( ( gantt ) => {

                    gantt.unselectTask ( task.id )
                    return gantt;
        
                } )
    
            }
    
            return task;

        } );

        setCompleted ( event.target.value );

    };

    const moveToday = () => {

        setSelectedYear ( ( year ) => {

            setSelectedMonth ( ( month ) => {

                let today = new Date ();

                if ( today.getDate () < 15 ) {
                        
                    today.setMonth ( today.getMonth () - 1 );

                }

                if ( today.getFullYear() !== parseInt ( year ) || today.getMonth () + 1 !== parseInt ( month ) ) {

                    year =  today.getFullYear();
                    month = today.getMonth () + 1;

                }

                setGantt ( ( gantt ) => {

                    setMoveToTodayTask ( ( enabled ) => {

                        if ( enabled ) {
                        
                            let today = new Date ();
                            let yesterday = new Date ( today.getFullYear(), today.getMonth(), today.getDate() - 1 );
                            let tomorrow = new Date ( today.getFullYear(), today.getMonth(), today.getDate() + 1 );
            
                            yesterday.setHours ( 24, 59, 59, 99 );
                            tomorrow.setHours ( 0, 0, 0, 0 );
            
                            let tasksOfToday = gantt.getTaskByTime ( yesterday, tomorrow );
            
                            if ( tasksOfToday.length > 0 ) {
            
                                let taskExist = false;
        
                                for ( let task of tasksOfToday ) {
        
                                    if ( task.type === "task" ) {
        
                                        taskExist = true;
                                        gantt.showTask ( task.id );
        
                                    }
        
                                }
        
                                if ( ! taskExist ) {
        
                                    gantt.showTask ( tasksOfToday[0].id );
        
                                }
            
                            }
    
                        } else {

                            showToday ();

                        }
    
                        return enabled;

                    } );
    
                    return gantt;

                } );

                return month;

            } );

            return year;
            
        } );

    }

    const showToday = () => {

        setGantt ( ( gantt ) => {

            let showDate = new Date ();

            showDate.setDate ( showDate.getDate () - 3 );
            gantt.showDate( showDate );
    
            return gantt;

        } );
        
    }

    const onGanttInitialized = ( gantt ) => {

        setGantt ( gantt );

    }

    const onTaskSelected = ( task ) => {

        switch ( task.taskType ) {

            case "processPlan":
            case "milestone":

                setAddMilestoneDisabled ( true );
                break;

            default:

                setAddMilestoneDisabled ( false );
                break;

        }

        setTaskSelected ( task );

    };

    const onTaskUnselected = ( task ) => {

        setAddMilestoneDisabled ( true );
        setTaskSelected ( undefined );

    }

    const executeUpdate = () => {

        setUpdateCtx ( ( ctx ) => {

            for ( let task of ctx.queue ) {

                let apiData = {
                    start_date: task.start_date,
                    end_date: task.end_date,
                    duration: task.duration,
                    durationInHours: task.durationInHours
                };
                    
                let form = new FormData ();
                let json = apiData;
                let deleted = {};

                form.append ( "deleted", JSON.stringify ( deleted ) );
                form.append ( "json", JSON.stringify ( json ) );

                apiClient
                    .put(`/api/EguPlan/${task.id}`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) =>  {})
                    .catch((reason) => {

                        notifyError ( t ( "error.updatePlanFailed", { taskName: task.taskName } ) );

                        setTimer ( ( timer ) => {

                            if ( timer ) {

                                clearTimeout ( timer );

                            }

                        } );
                
                    } );

            }

            return ( {
                queue: [],
                map: {}
            } );

        } );

    }

    const onTaskUpdate = ( id, newTask ) => {

        setBackupPlanData ( ( planData ) => {

            let oldTask = planData.planMap[id];
    
            if ( oldTask.duration !== newTask.duration ) {

                newTask.durationInHours = newTask.duration * 8;

                oldTask.duration = newTask.duration;
                oldTask.durationInHours = newTask.durationInHours;

            }

            setUpdateCtx ( ( ctx ) => {

                if ( ! ctx.map[newTask.id] ) {

                    ctx.queue.push ( newTask );
                    ctx.map[newTask.id] = newTask;

                }

                setTimer ( ( timer ) => {

                    if ( timer ) {
                            
                        clearTimeout ( timer );

                    }

                    return setTimeout ( () => executeUpdate (), 500 );

                } );

                return ctx;

            } );

            return planData;
    
        } );

    };

    const onTaskDelete = ( taskId ) => {

        setGantt ( ( gantt ) => {

            let task = gantt.getTask ( taskId );

            if ( parseInt ( task.ordered ) ) {

                notifyWarning ( t ( "warning.canNotDeleteOrderedTask" ) );
                return gantt;

            }

            setConfirmDialogTitle ( t ( "dialog.title.ConfirmDeleteTask" ) );
            setConfirmMsg ( t ( "dialog.msg.ConfirmDeleteTask", { taskName: task.taskName } ) );
            setConfirmData ( {
                type: "deleteTask",
                task: task
            } );

            setOpenConfirmDialog ( true );
    
            return gantt;

        } );

    };

    const createMilestone = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );
            setLastTaskId ( taskId );

            setCreateMilestoneDialog ( ( dialog ) => {
                dialog.show ();
                return dialog;
            } );
        
            return gantt;

        } );

    };

    const createProductionPlan = () => {

        setCreateProductionPlanDialog ( ( dialog ) => {
            dialog.show ();
            return dialog;
        } );

    };

    const assignWorker = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );
            setLastTaskId ( taskId );

            setAssignWorkerDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );

    };

    const assignMachine = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );
            setLastTaskId ( taskId );

            setAssignMachineDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );

    };

    const splitTask = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );            
            setLastTaskId ( task.parent );

            setSplitTaskDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );

    };

    const modifyDuration = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );

            setChangeTaskDurationDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );
        
    };

    const orderTask = ( taskId ) => {

        setLastTaskId ( taskId );
        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );

            setOrderTaskDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );

    };

    const cancelOrder = ( taskId ) => {

        setLastTaskId ( taskId );
        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );

            setCancelOrderDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );

    };

    const changeMilestoneName = ( taskId ) => {

        setGantt ( ( gantt ) => {
        
            let task = gantt.getTask ( taskId );

            setPlan ( task );

            setChangeMilestoneNameDialog ( ( dialog ) => {

                dialog.show ();

                return dialog;

            } );
    
            return gantt;

        } );
    };

    const onAssignWorkerDialogInitialized = ( dialog ) => {

        setAssignWorkerDialog ( dialog );

    };

    const onAssignMachineDialogInitialized = ( dialog ) => {

        setAssignMachineDialog ( dialog );

    };

    const onSplitTaskDialogInitialized  = ( dialog ) => {

        setSplitTaskDialog ( dialog );

    };

    const onChangeTaskDurationDialogInitialized = ( dialog ) => {

        setChangeTaskDurationDialog ( dialog );

    };

    const onOrderTaskDialogInitialized  = ( dialog ) => {

        setOrderTaskDialog ( dialog );

    };

    const onCancelOrderDialogInitialized  = ( dialog ) => {

        setCancelOrderDialog ( dialog );

    };

    const onCreateMilestoneDialogInitialized = ( dialog ) => {
        
        setCreateMilestoneDialog ( dialog );

    };

    const onChangeMilestoneNameDialogInitialized = ( dialog ) => {
        
        setChangeMilestoneNameDialog ( dialog );

    };

    const onCreateProductionPlanDialogInitialized = ( dialog ) => {
        
        setCreateProductionPlanDialog ( dialog );

    };

    const onOpenContextMenu = ( taskId, linkId, event ) => {

        if ( linkId ) {

            const linkContextMenu = new ContextMenu ( "gantt-context-menu", {

                css: "dhx_widget--bg-gray",
                data: [
                    {
                        type: "menuItem",
                        id: "deleteLink",
                        html: `<span style="color:#f00;" data-cy="DeleteLink">${t("menu.DeleteLink")}</span>`,
                        color: "danger",
                        css: "context_menu_danger"
                    }
                ]

            } );

            linkContextMenu.events.on ( "click", ( id, e ) => onLinkDelete ( linkId ) );
            linkContextMenu.showAt ( event );

            return;

        }

        if ( taskId ) {

            setGantt( ( gantt ) => {

                let task = gantt.getTask ( taskId );

                if ( ! task ) {

                    return gantt;

                }

                if ( parseInt ( task.started ) ) {

                    return gantt;

                }

                let taskContextMenu;
                
                if ( task.type === "milestone" ) {

                    taskContextMenu = new ContextMenu ( "gantt-context-menu", {

                        css: "dhx_widget--bg-gray",
                        data: [
                            {
                                type: "menuItem",
                                id: "changeMilestoneName",
                                value: t ( "menu.ChangeMilestoneName" ),
                            },
                            {
                                type: "menuItem",
                                id: "deleteTask",
                                html: `<span style="color:#f00;" data-cy="DeleteTask">${t("menu.DeleteTask")}</span>`,
                            }
                        ]
        
                    } );

                } else {

                    switch ( task.taskType ) {

                        case "processPlan":
    
                            if ( parseInt ( task.ordered ) ) {
    
                                taskContextMenu = new ContextMenu ( "gantt-context-menu", {
    
                                    css: "dhx_widget--bg-gray",
                                    data: [
                                        {
                                            type: "menuItem",
                                            id: "cancelOrder",
                                            value: t ( "menu.CancelOrder" ),
                                            html: `<span data-cy="CancelOrder">${t("menu.CancelOrder")}</span>`,
                                        }
                                    ]
                    
                                } );
    
                            } else {
    
                                taskContextMenu = new ContextMenu ( "gantt-context-menu", {
    
                                    css: "dhx_widget--bg-gray",
                                    data: [
                                        {
                                            type: "menuItem",
                                            id: "assignWorker",
                                            value: t ( "menu.AssignWorker" ),
                                            html: `<span data-cy="AssignWorker">${t("menu.AssignWorker")}</span>`,
                                        },
                                        {
                                            type: "menuItem",
                                            id: "assignMachine",
                                            value: t ( "menu.AssignMachine" ),
                                            html: `<span data-cy="AssignMachine">${t("menu.AssignMachine")}</span>`,
                                        },
                                        {
                                            type: "menuItem",
                                            id: "orderTask",
                                            value: t ( "menu.OrderTask" ),
                                            html: `<span data-cy="OrderTask">${t("menu.OrderTask")}</span>`,
                                        },
                                        {
                                            type: "menuItem",
                                            id: "modifyDuration",
                                            value: t ( "menu.ModifyDuration" ),
                                            html: `<span data-cy="ModifyDuration">${t("menu.ModifyDuration")}</span>`,
                                        },
                                        {
                                            type: "menuItem",
                                            id: "deleteTask",
                                            html: `<span style="color:#f00;" data-cy="DeleteTask">${t("menu.DeleteTask")}</span>`,
                                            css: "context_menu_danger"
                                        }
                                    ]
                    
                                } );
    
                            }
    
    
                            break;
    
                        case "materialPlan":
    
                            if ( parseInt ( task.ordered ) ) {
    
                                return gantt;
    
                            }
    
                            taskContextMenu = new ContextMenu ( "gantt-context-menu", {
    
                                css: "dhx_widget--bg-gray",
                                data: [
                                    {
                                        type: "menuItem",
                                        id: "splitTask",
                                        value: t ( "menu.SplitTask" ),
                                        html: `<span data-cy="SplitTask">${t("menu.SplitTask")}</span>`,
                                    },
                                    {
                                        type: "menuItem",
                                        id: "addMilestone",
                                        value: t ( "menu.AddMilestone" ),
                                        html: `<span data-cy="AddMilestone">${t("menu.AddMilestone")}</span>`,
                                    },
                                    {
                                        type: "menuItem",
                                        id: "deleteTask",
                                        html: `<span style="color:#f00;" data-cy="DeleteTask">${t("menu.DeleteTask")}</span>`,
                                        css: "context_menu_danger"
                                    }
                                ]
                
                            } );
    
                            break;
    
                        default:
    
                            if ( parseInt ( task.ordered ) ) {
    
                                return gantt;
    
                            }
    
                            taskContextMenu = new ContextMenu ( "gantt-context-menu", {
    
                                css: "dhx_widget--bg-gray",
                                data: [
                                    {
                                        type: "menuItem",
                                        id: "addMilestone",
                                        value: t ( "menu.AddMilestone" ),
                                    },
                                    {
                                        type: "menuItem",
                                        id: "deleteTask",
                                        html: `<span style="color:#f00;" data-cy="DeleteTask">${t("menu.DeleteTask")}</span>`,
                                    }
                                ]
                
                            } );
    
                            break;
            
                    }

                }

                taskContextMenu.events.on ( "click", ( id, e ) => {

                    switch ( id ) {

                        case "assignWorker":

                            assignWorker ( taskId );
                            break;

                        case "assignMachine":

                            assignMachine ( taskId );
                            break;

                        case "splitTask":

                            splitTask ( taskId );
                            break;

                        case "orderTask":

                            orderTask ( taskId );
                            break;

                        case "cancelOrder":

                            cancelOrder ( taskId );
                            break;

                        case "modifyDuration":

                            modifyDuration ( taskId );
                            break;    

                        case "deleteTask":

                            onTaskDelete ( taskId );
                            break;

                        case "addMilestone":

                            createMilestone ( taskId );
                            break;

                        case "changeMilestoneName":

                            changeMilestoneName ( taskId );
                            break;

                        default:

                            break;
                    }

                } );

                taskContextMenu.showAt ( event );
    
                return gantt;
    
            } )

            return;

        }


        const genericContextMenu = new ContextMenu ( "gantt-context-menu", {
            css: "dhx_widget--bg-gray",
            data: [
                {
                    type: "menuItem",
                    id: "openAll",
                    value: t ( "menu.OpenAll" ),
                    html: `<span data-cy="OpenAll">${t("menu.OpenAll")}</span>`,
                },
                {
                    type: "menuItem",
                    id: "closeAll",
                    value: t ( "menu.CloseAll" ),
                    html: `<span data-cy="CloseAll">${t("menu.CloseAll")}</span>`,
                },
            ]
        } );

        genericContextMenu.events.on ( "click", ( id, e ) => {

            switch ( id ) {

                case "openAll":

                    openAll ();
                    break;

                case "closeAll":

                    closeAll ();
                    break;

                default:

                    break;

            }

        } );

        genericContextMenu.showAt ( event );


    };

    const onLinkAdd = ( linkId, link ) => {

        let apiData = {
            type: link.type,
            sourceTaskId: link.source,
            targetTaskId: link.target
        };
            
        let form = new FormData ();
        let json = apiData;
        let deleted = {};

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        
        apiClient.post ( "/api/EguPlan/link", form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {

                setGantt ( ( gantt ) => {

                    gantt.getLink ( linkId ).backendId = result.data.id;
                    notifySuccess ( t ( "success.createLinkSuccess" ) );
    
                    return gantt;

                } );

            })
            .catch((reason) => {

                notifyError ( t ( "error.createLinkFailed" ) );
                
                setTimeout ( () => {
                        
                    setSelectedYear ( ( selectedYear ) => {

                        setSelectedMonth ( ( selectedMonth ) => {
            
                            reload ( selectedYear, selectedMonth, reloadFlag.default, 0 );                            
                            return selectedMonth;
            
                        } );
            
                        return selectedYear;
            
                    } );

                }, 1000 );

            } );

    };
    
    const onLinkDelete = ( linkId ) => {

        setGantt ( ( gantt ) => {

            let backendLinkId = gantt.getLink ( linkId ).backendId || linkId;

            apiClient.del ( `/api/EguPlan/link/${backendLinkId}` )
                .then((result) =>  {
    
                    notifySuccess ( t ( "success.delteLinkSuccess" ) );
                    gantt.deleteLink ( linkId );
    
                } )
                .catch((reason) => {
    
                    notifyError ( t ( "error.deleteLinkFailed" ) );
    
                } );
                
            return gantt;

        })


    };

    const onCloseConfirmDialog = () => {

        setOpenConfirmDialog ( false );

    };

    const deleteTask = ( task ) => {

        setGantt ( ( gantt ) => {
            
            setPlanData ( ( planData ) => {

                let parentId = task.parent;

                if (!gantt.isTaskExists(task.id)) return planData;

                gantt.deleteTask ( task.id )

                // delete task from planData

                let taskIdx = planData.tasks.data.findIndex ( ( item ) => item.id === task.id );

                planData.tasks.data = planData.tasks.data.slice ( 0, taskIdx ).concat ( planData.tasks.data.slice ( taskIdx + 1 ) );

                // delete relevant links from planData

                let newLinks = [];
                
                for ( let link of planData.tasks.links ) {

                    if ( link.source !== task.id && link.target !== task.id ) {

                        newLinks.push ( link );

                    }

                }

                planData.tasks.links = newLinks;

                // delete task from planMap

                planData.planMap[task.id] = undefined;

                // use setTimeout to prevent parent deleted before planData state change is applied

                setTimeout ( () => {

                    if ( parentId ) {

                        let parent = gantt.getTask ( parentId );
    
                        if ( parent ) {
    
                            let siblingCount = 0;
    
                            for ( let item of planData.tasks.data ) {
            
                                if ( item.parent === parentId ) {
            
                                    siblingCount++;
            
                                }
            
                            }
            
                            if ( siblingCount === 0 ) {
                                            
                                // delete parent if have no more children
            
                                deleteTask ( parent );
            
                            }
    
                        }
        
                    }

                }, 0 );

                return planData;

            } );

            return gantt;

        } );

    };
    
    const onConfirmOk = ( confirmData ) => {

        switch ( confirmData.type ) {

            case "deleteTask":

                apiClient.del ( `/api/EguPlan/${confirmData.task.id}` )
                    .then((result) =>  {
        
                        notifySuccess ( t ( "success.deleteTaskSuccess" ) );
                        deleteTask ( confirmData.task );
        
                    } )
                    .catch((reason) => {
        
                        notifyError ( t ( "error.deleteTaskFailed" ) );
        
                    } );

                break;

            default:

                break;

        }

    }

    const onMakeTaskTooltipText = ( start, end, task, planData ) => {

        let toolTipText;

        let status;

        if ( parseInt ( task.completed ) ) {
            status = t("term.completed");
        } else {

            if ( parseInt ( task.ordered ) ) {

                if ( parseInt ( task.started ) ) {
                    status = t("term.started");                        
                } else {
                    status = t("term.notStarted");
                }
    
            } else {
                status = t("term.notOrdered");
            }
    
        }

        if ( task.type === "milestone" ) {

            toolTipText = `<b>${t("columnName.milestoneName")} : </b> ${task.taskName}<br/>`;

            return toolTipText;

        }

        let materialPlan;
        let orderBuyerDetailPlan;
        let orderBuyerMstPlan;

        switch ( task.taskType ) {

            case "orderBuyerMstPlan":

                let dueDate = getNewDateObj ( task.dueDate );

                toolTipText = `<b>${t("columnName.taskName")} : </b> ${task.taskName}<br/>` +
                              `<b>${t("columnName.dueDate")} : </b> ${dueDate.getFullYear ()}/${dueDate.getMonth() + 1}/${dueDate.getDate()}<br/>` +
                              `<b>${t("columnName.status")} : </b> ${status}<br/>` +
                              `<b>${t("columnName.progress")} : </b> ${task.progress * 100}%<br/>`;
                
                break;

            case "orderBuyerDetailPlan":

                orderBuyerMstPlan = planData.planMap[task.parent];

                toolTipText = `<b>${t("columnName.orderBuyerId")} : </b> ${orderBuyerMstPlan.orderBuyerId}<br/>` +
                              `<b>${t("columnName.taskName")} : </b> ${task.taskName}<br/>` +
                              `<b>${t("columnName.qty")} : </b> ${task.qty}<br/>` +
                              `<b>${t("columnName.status")} : </b> ${status}<br/>` +
                              `<b>${t("columnName.progress")} : </b> ${task.progress * 100}%<br/>`;

                break;

            case "materialPlan":

                orderBuyerDetailPlan = planData.planMap[task.parent];
                orderBuyerMstPlan = planData.planMap[orderBuyerDetailPlan?.parent];

                toolTipText = `<b>${t("columnName.orderBuyerId")} : </b> ${orderBuyerMstPlan.orderBuyerId}<br/>` +
                              `<b>${t("term.soProduct")} : </b> ${orderBuyerDetailPlan.materialCode}/${orderBuyerDetailPlan.materialCodeVer} ${orderBuyerDetailPlan.materialName}<br/>` +
                              `<b>${t("columnName.taskName")} : </b> ${task.taskName}<br/>` +
                              `<b>${t("columnName.qty")} : </b> ${task.qty}<br/>` +
                              `<b>${t("columnName.status")} : </b> ${status}<br/>` +
                              `<b>${t("columnName.progress")} : </b> ${task.progress * 100}%<br/>`;

                break;

            case "processPlan":

                materialPlan = planData.planMap[task.parent];
                orderBuyerDetailPlan = planData.planMap[materialPlan?.parent];
                orderBuyerMstPlan = planData.planMap[orderBuyerDetailPlan?.parent];

                let plannedStartTime = getNewDateObj ( task.plannedStartTime );

                let orderTime = task.orderTime ? getNewDateObj ( task.orderTime ) : undefined;                
                let startTime = parseInt ( task.started ) ? getNewDateObj ( task.actualStartTime ) : undefined;
                let endTime = parseInt ( task.completed ) ? getNewDateObj ( task.actualEndTime  ) : undefined;
                let workers = "";
    
                if ( task.workers ) {

                    for ( let resource of task.workers ) {

                        let worker = planData.resourceMap[resource.resource_id];
    
                        if ( workers.length > 0 ) {
    
                            workers += ", ";
    
                        }
    
                        if ( worker ) {
                                
                                workers += worker.text;
        
                        }
    
                    }
    
                }

                let machines = "";

                if ( task.machines ) {
                
                    for ( let resource of task.machines ) {

                        let machine = planData.resourceMap[resource.resource_id];

                        if ( machines.length > 0 ) {

                            machines += ", ";

                        }

                        if ( machine ) {
                                
                            machines += machine.text;
        
                        }

                    }

                }

                toolTipText = `<b>${t("columnName.orderBuyerId")} : </b> ${orderBuyerMstPlan.orderBuyerId}<br/>` +
                              `<b>${t("term.soProduct")} : </b> ${orderBuyerDetailPlan.materialCode}/${orderBuyerDetailPlan.materialCodeVer} ${orderBuyerDetailPlan.materialName}<br/>` +
                              `<b>${t("term.productionMaterial")} : </b> ${materialPlan.materialCode}/${materialPlan.materialCodeVer} ${materialPlan.materialName}<br/>` +
                              `<b>${t("columnName.taskName")} : </b> ${task.taskName}<br/>` +
                              `<b>${t("columnName.plannedQty")} : </b> ${task.plannedQty}<br/>` +
                              `<b>${t("columnName.resultQty")} : </b> ${task.resultQty}<br/>` +
                              (plannedStartTime?`<b>${t("columnName.plannedStartTime")} : </b> ${plannedStartTime?.getFullYear ()}/${plannedStartTime?.getMonth() + 1}/${plannedStartTime?.getDate()}<br/>`:"") +
                              (orderTime?`<b>${t("columnName.orderTime")} : </b> ${orderTime?.getFullYear ()}/${orderTime?.getMonth() + 1}/${orderTime?.getDate()}<br/>`:"") +
                              `<b>${t("columnName.plannedDuration")} : </b> ${task.durationInHours} ${t("term.hour")}<br/>` +
                              `<b>${t("columnName.dailyWorkTime")} : </b> ${task.dailyWorkTime} ${t("term.hour")}<br/>` +
                              (startTime?`<b>${t("columnName.taskStartTime")} : </b> ${startTime?.getFullYear ()}/${startTime?.getMonth() + 1}/${startTime?.getDate()} ${("0" + startTime?.getHours()).slice(-2)}:${("0" + startTime.getMinutes()).slice(-2)}<br/>`:"") +
                              (endTime?`<b>${t("columnName.taskEndTime")} : </b> ${endTime?.getFullYear ()}/${endTime?.getMonth() + 1}/${endTime?.getDate()} ${("0" + endTime?.getHours()).slice(-2)}:${("0" + endTime.getMinutes()).slice(-2)}<br/>`:"") +
                              `<b>${t("columnName.status")} : </b> ${status}<br/>` +
                              `<b>${t("columnName.progress")} : </b> ${task.progress * 100}%<br/>` +
                              `<b>${t("columnName.outsourceFlag")} : </b> ${task.outsourcing ? t("term.outSourced") : t("term.notOutSourced")}<br/>` +
                              ( ( workers.length > 0 ) ? `<b>${t("columnName.workers")} : </b> ${workers}<br/>` : "" ) +
                              ( (  machines.length > 0 ) ? `<b>${t("columnName.machines")} : </b> ${machines}<br/>` : "" );

                break;

            default:

                return;

        }

        return toolTipText;

    }

    const onChangeScale = ( event ) => {

        let scale = event.target.value;

        setScale ( scale );

        if ( scale === "day" ) {
            setHideResourceView ( false );
        } else {
            setHideResourceView ( true );
        }

        setSelectedYear ( ( selectedYear ) => {

            setSelectedMonth ( ( selectedMonth ) => {

                reload ( selectedYear, selectedMonth, reloadFlag.default, 0 );

                return selectedMonth;

            } );

            return selectedYear;

        } );

    };

    const onShowMonthCountChange = ( event ) => {

        setShowMonthCount ( parseInt ( event.target.value ) );

        setSelectedYear ( ( selectedYear ) => {

            setSelectedMonth ( ( selectedMonth ) => {

                reload ( selectedYear, selectedMonth, reloadFlag.default, 0 );

                return selectedMonth;

            } );

            return selectedYear;

        } );

    };

    const onShowGridChange = ( event ) => {

        setShowGrid ( event.target.checked );

        setSelectedYear ( ( selectedYear ) => {

            setSelectedMonth ( ( selectedMonth ) => {

                
                reload ( selectedYear, selectedMonth, reloadFlag.showGrid, 0 );
            

                return selectedMonth;

            } );

            return selectedYear;

        } );

    };

    const onSearchInitialized = ( search ) => {

        setSearch ( search );

    }

    const openAll = () => {

        setPlanData ( ( planData ) => {

            setGantt ( ( gantt ) => {

                for ( let task of planData.tasks.data ) {

                    if ( task.taskType === "orderBuyerMstPlan" ) {

                        setTimeout ( () => gantt.open ( task.id ), 0 );

                    }

                }

                return gantt;

            } );

            return planData;

        } );

    };

    const closeAll = () => {

        setPlanData ( ( planData ) => {

            setGantt ( ( gantt ) => {

                for ( let task of planData.tasks.data ) {

                    if ( task.taskType === "orderBuyerMstPlan" ) {

                        setTimeout ( () => gantt.close ( task.id ), 0 );

                    }

                }

                return gantt;

            } );

            return planData;

        } );

    };

    const onGanttReady = () => {

        setGantt ( ( gantt ) => {

            if ( gantt ) {

                setLastTaskId ( ( lastTaskId ) => {

                    // move to last task

                    if ( lastTaskId ) {

                        gantt.showTask ( lastTaskId );
                        return;

                    }
                    
                    // move to selected task

                    let taskId = gantt.getSelectedId ();

                    if ( taskId ) {
    
                        gantt.showTask ( taskId );
    
                    }
    
                    return;

                } );
        
            }

            return gantt;

        } );

    };

    return (

        <div>

            <BasicCrudScreenTypeLayout>

                <BasicCrudScreenHeaderLayout className="basicCrudScreenHeaderLayout">

                    <BasicCrudActionsLayoutWithoutBorder className="basicCrudScreenActionsLayout">

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "18px",
                                        marginTop: "20px",
                                        marginRight: "12px",
                                }}
                            >
                                { t("term.year") } : 
                            </span>

                            <Select
                                id={ "gantt-select-year" }
                                value={selectedYear}
                                onChange={ onYearChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "60px",
                                    textAlign: "center"
                                }}
                            >
                                {
                                    years
                                        .sort ( ( a, b ) => parseInt(a) - parseInt(b) )
                                        .map ( ( item ) => <MenuItem key={item} value={item} style={{ fontSize: "14px "}}>{item}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "6px",
                                        marginTop: "20px",
                                        marginRight: "12px",
                                }}
                            >
                                { t("term.month") } :
                            </span>

                            <Select
                                id={ "gantt-select-month" }
                                value={selectedMonth}
                                onChange={ onMonthChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "50px",
                                    textAlign: "center"
                                }}
                            >
                                {
                                    months
                                        .sort ( ( a, b ) => parseInt(a) - parseInt(b) )
                                        .map ( ( item ) => <MenuItem key={item} value={item} style={{ fontSize: "14px "}}>{item}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        <Hidden smDown>
                        
                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "15px",
                                        marginTop: "20px",
                                        marginRight: "8px",
                                }}
                            >
                                { t("term.scale") } :
                            </span>

                            <Select
                                id={ "gantt-select-scale" }
                                value={scale}
                                onChange={ onChangeScale }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "50px",
                                    textAlign: "center"
                                }}

                            >
                                <MenuItem key="day" value="day" style={{ fontSize: "14px "}}>{t("term.day")}</MenuItem>
                                <MenuItem key="week" value="week" style={{ fontSize: "14px "}}>{t("term.week")}</MenuItem>
                                <MenuItem key="month" value="month" style={{ fontSize: "14px "}}>{t("term.month")}</MenuItem>
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "6px",
                                        marginTop: "20px",
                                        marginRight: "12px",
                                }}
                            >
                                { t("term.showMonthCount") } :
                            </span>

                            <Select
                                id={ "gantt-select-show-month-count" }
                                value={showMonthCount}
                                onChange={ onShowMonthCountChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "50px",
                                    textAlign: "center"
                                }}
                            >
                                {
                                    months
                                        .sort ( ( a, b ) => parseInt(a) - parseInt(b) )
                                        .map ( ( item ) => <MenuItem key={item} value={item} style={{ fontSize: "14px "}}>{item}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        </Hidden>

                        <Hidden lgDown>

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "15px",
                                        marginTop: "20px",
                                        marginRight: "8px",
                                }}
                            >
                                { t("term.resource") } :
                            </span>

                            <Select
                                id={ "gantt-select-resource" }
                                value={selectedResource}
                                onChange={ onResourceChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "100px",
                                    textAlign: "center"
                                }}

                            >
                                {
                                    resources
                                        .map ( ( item, index ) => <MenuItem key={index} value={index} style={{ fontSize: "14px "}}>{t(item.text)}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        </Hidden>

                        <Hidden mdDown>

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "15px",
                                        marginTop: "20px",
                                        marginRight: "8px",
                                }}
                            >
                                { t("term.selectOrdered") } :
                            </span>

                            <Select
                                id={ "gantt-select-ordered" }
                                value={ordered}
                                onChange={ onOrderedChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "80px",
                                    textAlign: "center"
                                }}

                            >
                                {
                                    orderedSelections
                                        .sort ( ( a, b ) => parseInt ( b.value ) - parseInt ( a.value ) )
                                        .map ( ( item ) => <MenuItem key={item.value} value={item.value} style={{ fontSize: "14px "}}>{t(item.name)}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <span
                                color="inherit"
                                align="center"
                                style={{
                                        fontSize: "14px",
                                        marginLeft: "6px",
                                        marginTop: "20px",
                                        marginRight: "8px",
                                }}
                            >
                                { t("term.selectCompleted") } :
                            </span>

                            <Select
                                id={ "gantt-select-completed" }
                                value={completed}
                                onChange={ onCompletedChange }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        square: true
                                    }
                                }}

                                style={{
                                    fontSize: "14px",
                                    width: "80px",
                                    textAlign: "center"
                                }}

                            >
                                {
                                    completedSelections
                                        .sort ( ( a, b ) => parseInt ( b.value ) - parseInt ( a.value ) )
                                        .map ( ( item ) => <MenuItem key={item.value} value={item.value} style={{ fontSize: "14px "}}>{t(item.name)}</MenuItem> )
                                }
                            </Select>

                        </BasicCrudScreenActionItemLayout>

                        </Hidden>

                        <BasicCrudScreenActionItemLayout>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showGrid}
                                        color="primary"
                                        size="small"
                                        onChange={onShowGridChange}
                                    />
                                }
                                label={ t("term.showGrid") }
                                style={{
                                    marginLeft: "6px",
                                    transform: "scale(0.85)"
                                }}
                            />

                        </BasicCrudScreenActionItemLayout>

                        <ToolbarFlexSpacer xs />

                        <BasicCrudScreenActionItemLayout>

                                <ActionButtonLayout
                                    onClick={()=>moveToday()}
                                >
                                    {t("common.today")}
                                </ActionButtonLayout>

                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>

                            <AccessControl accessTarget="ProductionPlan" accessMethod="POST">
                                <ActionButtonLayout data-cy="createProductionPlan" onClick={createProductionPlan}>
                                    {t("term.addPlan")}
                                </ActionButtonLayout>
                            </AccessControl>

                        </BasicCrudScreenActionItemLayout>

                        <Hidden mdDown>
                
                            <BasicCrudScreenActionItemLayout>

                                <SearchTextField
                                    id={"production-plan-search-text"}
                                    className="basicCrudScreenSearchInput"
                                    search={search}
                                />
                                <span
                                    style={{
                                            marginRight: "12px",
                                    }}
                                />

                            </BasicCrudScreenActionItemLayout>

                        </Hidden>

                    </BasicCrudActionsLayoutWithoutBorder>

                </BasicCrudScreenHeaderLayout>

                <BasicCrudScreenContentWithoutFooterLayout className="basicCrudScreenContentLayout">

                    <div

                        id="production-plan-gantt-chart"
                        style={style}

                    >

                        <Search onInitialized={onSearchInitialized}>

                            <GanttChart

                                id="production-plan-gantt-chart"

                                year={selectedYear}
                                month={selectedMonth}
                                
                                planData={planData}

                                hideResourceView={hideResourceView}
                                
                                resource={resources[selectedResource]?.id || ""}
                                ordered={ordered}
                                completed={completed}

                                onInitialized={onGanttInitialized}

                                onTaskUpdate={onTaskUpdate}
                                onTaskSelected={onTaskSelected}
                                onTaskUnselected={onTaskUnselected}

                                onLinkAdd={onLinkAdd}

                                onContextMenu={onOpenContextMenu}                    

                                onMakeTaskTooltipText={ ( start, end, task ) => onMakeTaskTooltipText ( start, end, task, planData ) }

                                onGanttReady={onGanttReady}

                            />

                        </Search>

                    </div>

                </BasicCrudScreenContentWithoutFooterLayout>

            </BasicCrudScreenTypeLayout>

            <Notification />

            <CreateProductionPlanDialog onInitialized={onCreateProductionPlanDialogInitialized} onSuccess={onSuccessCreate} />

            <AssignWorkerDialog task={plan} onInitialized={onAssignWorkerDialogInitialized} onSuccess={onSuccess} />

            <AssignMachineDialog task={plan} onInitialized={onAssignMachineDialogInitialized} onSuccess={onSuccess} />

            <SplitTaskDialog task={plan} onInitialized={onSplitTaskDialogInitialized} onSuccess={onSuccess} setIsSplitTask={setIsSplitTask}/>

            <ChangeTaskDurationDialog task={plan} onInitialized={onChangeTaskDurationDialogInitialized} onSuccess={onSuccess} />

            <OrderTaskDialog task={plan} planData={planData} onInitialized={onOrderTaskDialogInitialized} onSuccess={onSuccessOrderTask} />

            <CancelOrderDialog task={plan} planData={planData} onInitialized={onCancelOrderDialogInitialized} onSuccess={onSuccessCancelOrder} />

            <CreateMilestoneDialog task={plan} onInitialized={onCreateMilestoneDialogInitialized} onSuccess={onSuccess} />

            <ChangeMilestoneNameDialog task={plan} onInitialized={onChangeMilestoneNameDialogInitialized} onSuccess={onSuccess} />

            <ConfirmDialog
                id="production-plan-confirm-dialog"
                title={ confirmDialogTitle}
                msg={confirmMsg}
                data={confirmData}
                open={openConfirmDialog}
                onClose={onCloseConfirmDialog}
                onOk={onConfirmOk}
                onCancel={onCloseConfirmDialog}
            />

            <div id="gantt-context-menu" />

        </div>
    )
}

export default ProductionPlan;
