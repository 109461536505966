function getDefineConf() {
    return {
        // MomSysUglOrderBuyerMst.orderBuyerState
        orderBuyerState: {
            ENROLL: 0, // 등록
            HOLD: 1, // 보류
            CANCEL: 2, // 취소
        },

        // MomSysUglOrderPurchaseMst.orderPurchaseInputState
        orderPurchaseInputState: {
            NOT_COMPLETED: 0, // 미입고
            PARTIALLY_COMPLETED: 1, // 부분 입고
            COMPLETED: 2, // 입고 완료
        },

        // ApprovalState.state
        approvalState: {
            PENDING: 1, //  대기
            PARTIALLY_APPROVED: 2, // 부분 승인
            APPROVED: 3, // 승인 완료
            REJECTED: 4, // 승인 거절
            WITHDRAWN: 5, // 승인 철회
            CANCELLED: 6, // 승인 취소
        },

        // ApprovalDetail.state
        approvalDetailState: {
            PENDING: 0, //  대기
            APPROVED: 1, // 승인 완료
            REJECTED: 2, // 승인 거절
            CANCELLED: 3, // 승인 취소
        },

        // MomSysInspection.inspectionType
        inspectionType: {
            INPUT_INSPECTION: 1, // 입고 검사
            SELF_INSPECTION: 2, // 자주 검사
            OUTSOURCING_INPUT_INSPECTION: 3, // 외주 입고 검사
            NO_ORDER_PURCHASE_INPUT_INSPECTION: 4, // 무발주 입고 검사
            MATERIAL_RETURN_INPUT_INSPECTION: 5, // 자재 회수 입고 검사
            CUSTOMER_SUPPLY_INPUT_INSPECTION: 6, // 사급 자재 입고 검사
        },

        // MomSysInspection.qcResult
        qcResult: {
            QC_OK: 0,
            QC_NOK: 1,
        },

        defectiveCode: {
            INS_OK: "INS000",
            PRC_OK: "PRC000",
        },

        // EguOutSourcingPurchase.outSourcingStatus
        outSourcingStatus: {
            NONE_COMPLETE: 0,
            SHIP_COMPLETE: 1,
            FAKE_INPUT_COMPLETE: 2,
            INPUT_INSPECTION_COMPLETE: 3,
            INPUT_COMPLETE: 4,
        },

        // MomSysSgtWipLot.outSourcing
        outSourcing: {
            NON_OUTSOURCED: 0,
            OUTSOURCED: 1,
        },

        // EguPopProductManage.produceStatus
        produceStatus: {
            UNWORKED: 0,
            WORKING: 1,
            HOLDING: 2,
            COMPLETION: 3,
            OUTPUT: 4,
        },

        // EguPopProductManage.takeOverStatus
        takeOverStatus: {
            unacquired: 0,
            partial: 1,
            completion: 2,
        },

        // MomSysShip.shipState
        shipState: {
            NOT_SHIP: 0,
            SHIP_PART_COMPLETE: 1,
            SHIP_COMPLETE: 2,
        },

        // EguOutSourcingShip.outSourcingShipState
        outSourcingShipState: {
            NOT_SHIP: 0,
            SHIP_PART_COMPLETE: 1,
            SHIP_COMPLETE: 2,
        },

        // Mrp Event Type
        mrpEventType: {
            CONSUME: 1,
            PRODUCTION: 2,
            INPUT_BY_PURCHASE: 3,
        },

        // Psm Event Type
        psmEventType: {
            SHIP: 1,
            PRODUCTION: 2,
            INPUT_BY_PURCHASE: 3,
        },

        // materialTypeCode
        materialTypeCode: {
            완제품: 10,
            FINISHED_PRODUCT: 10,

            반제품: 20,
            HALF_FINISHED_PRODUCT: 20,

            원자재: 30,
            RAW_MATERIAL: 30,

            재공품: 40,
            WIP_PRODUCT: 40, // Work In Process

            상품: 50,
            GOODS: 50,

            부자재: 60,
            SUBSIDIARY_MATERIAL: 60,

            외주: 70,
            OUTSOURCING: 70,
        },

        //MomSysUglOrderBuyerMst.shipmentState
        shipmentState: {
            NOT_SHIP: 0, // 미출하
            PARTIAL_SHIP: 1, // 부분 출하
            COMPLETE_SHIP: 2, // 출하 완료
        },

        //MomSysUglOrderBuyerMst.deliveryState
        deliveryState: {
            NO_DELIVERY: 0, // 미납
            PARTIAL_DELIVERY: 1, // 부분 납품
            COMPLETE_DELIVERY: 2, // 납품 완료
        },

        //EguProductSample.sampleState
        sampleState: {
            COMPLETE_SAMPLE: 0, // 완료
            CANCEL_SAMPLE: 1, // 취소
            ETC_SAMPLE: 2, // 기타
            STOP_SAMPLE: 3, // 중지
            HOLD_SAMPLE: 4, // 보류
            EMPTY_SAMPLE: 5, // 없음
        },

        //EguProductSample.passJudgment
        passJudgment: {
            inadequate: 1, // 부적합
            sampleEtc: 2, // 기타
            suitable: 3, // 적합
        },

        //MomSysStockTakingMst.stockTakingType
        stockTakingType: {
            ALL: 0, // 전체 재고 조사
            PRODUCT: 1, // 제품 재고 조사
            MATERIAL: 2, // 자재 재고 조사
        },

        //CompanyBranchType
        branchType: {
            office: 0,
            factory: 1,
        },

        // TkPopProductManage.buttonFilterIndex
        buttonFilterIndex: {
            TODAY: "today",
            TOMORROW: "tomorrow",
            UNWORKED: "unworked",
            WORKING: "working",
            POPALL: null,
        },

        //MomSysBomBopFileDetail
        bomBopRegistrationStatus: {
            failure: 0,
            success: 1,
            duplicated: 2,
            notApplicable: 3,
        },

        //MomSysBackShip
        backShipStatus: {
            NOT_COMPLETED: 0, // 미반품
            COMPLETED: 1, // 반품 완료
        },
        
        // ItemUnitName
        itemUnitName: {
            MM: 'mm',
            CM: 'cm',
            M: 'm',
            KM: 'km',
            MG: 'mg',
            G: 'g',
            KG: 'kg',
            TON: 'ton',
            ML: 'ml',
            DL: 'dl',
            L: 'L',
            BOX: 'box',
            EA: 'ea',
            ROLL: 'roll'
        }
    };
}

const defineConf = getDefineConf();

module.exports = defineConf;
