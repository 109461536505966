import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysDirectShipDetailLotCreateDialog from "../dialogTypes/MomSysDirectShipDetailLotCreateDialog";

function MomSysDirectShipDetailLotCreateButton(props) {
    const { id, schema, refresh, onSelectDetail, onSuccess, customizeErrorMsgCallback, ...others } = props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onApiSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout id={id + "create-button"} onClick={onOpen} {...others} />
            <MomSysDirectShipDetailLotCreateDialog
                id={id + "create-dialog"}
                schema={schema}
                onSelectDetail={onSelectDetail}
                open={open}
                onClose={onClose}
                onSuccess={onApiSuccess}
            />
        </>
    );
}

export default MomSysDirectShipDetailLotCreateButton;
