import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function EguPopHoldAndReleaseDialog ( props ) {

    const {

        id,
        schema,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,
        formId,

        orderBuyerId,
        modifyUrlCallback,

        onSelectMst,

        customizeErrorMsgCallback,

        ...others

    } = props;

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                nofifyWarning ( msg );
                return false;

            }
            
        }

        return true;

    }

    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    useEffect ( () => {

        setFormData ( ( formData ) => {
    
            let newFormData = {

                ...formData,
                lotNo: onSelectMst?.resultLotNo,
                // lotNo: 999,
                planId: onSelectMst?.planId,

            };

            return newFormData;

        } );

    }, [onSelectMst] );

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        instance.hide();

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }

    };

    const onApiFailure = (reason) => {

        if ( customizeErrorMsgCallback ) {

            notifyFailure ( customizeErrorMsgCallback ( reason ) );

        } else {

            notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

        }

    };

    const onOk = ( schema, formData ) => {

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = `/api/${schema.name}`;

        if ( modifyUrlCallback ) {

            url = modifyUrlCallback( url );

        }

        let apiData = { ...formData };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( apiData[formItem] !== undefined ) {

                json[formItem] = apiData[formItem];

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        instance.hide();

    };

    const onChange = ( formData ) => {

        setFormData ( formData );

    }

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
            }

            return newFormData;

        } );
        
        if ( onClose ) {

            onClose ();

        }
    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { title }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent dividers>

                <AutoForm
                    id="pop-hold-release-form"
                    schema={schema}
                    mode="edit"
                    initialData={formData}
                    onChange={onChange}
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("common.add")]}
                buttonDisabled={[false, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default EguPopHoldAndReleaseDialog;
