import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/styles";

import NumberFormat from 'react-number-format';

import getNewDateObj from '../../lib/common/getNewDateObj';

const useStyles = makeStyles((theme) => ({
    container: {
        position: "absolute",
        width: "100%",
        height: "100.1%",
        overflow: "auto",
        borderTop: "1px solid #e0e0e0",
        borderRight: "1px solid rgba(0,0,0,.2)",
    },
    table: {
        fontSize: "1rem",        
        position: "absolute",
        width: "100%",
        height: "100%",
        margin: 0,
        padding: 0,
        textAlign: "center",        
        vertialAlign: "center",
    },
    header: {
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid rgba(0,0,0,.2)",
        borderLeft: "1px solid rgba(0,0,0,.2)",
        color: "#000000",
        backgroundColor: "#eeeeee",
    },
    orderBuyerId: {
        width: "160px",
    },
    category: {
        width: "70px",
    },
    outSourcing: {
        width: "80px",
    },
    status: {
        width: "80px",
    },
    row: {
        borderBottom: "1px solid rgba(0,0,0,.2)",
    },
    col: {
        textAlign: "left",
        paddingLeft: "12px",
        borderLeft: "1px solid rgba(0,0,0,.2)",
    },
    alignCenter: {
        paddingLeft: "0px",
        textAlign: "center",
    },
    number: {
        width: "120px",
        textAlign: "right",
        paddingRight: "12px",
    },
    date: {
        width: "120px",
    },
    time: {
        width: "170px",
    },
    failRatio: {
        width: "100px",
    }
} ) );

function EguProductionStatusTable ( props ) {

    const classes = useStyles();

    const { t } = useTranslation();
    const { data } = props;

    const formatTime = ( time ) => {

        if ( time === null || time === undefined || time === "") {

            return "";

        }

        let dateObj = getNewDateObj( time );

        if ( !dateObj ) {
            return "";
        }

        return `${dateObj.getFullYear ()}-${dateObj.getMonth() + 1}-${dateObj.getDate()} ${("0" + dateObj.getHours()).slice(-2)}:${("0" + dateObj.getMinutes()).slice(-2)}`;

    }

    return (

        <div className={classes.container}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={`${classes.header} ${classes.orderBuyerId}`}>{t("columnName.orderBuyerId")}</th>
                        <th className={`${classes.header}`}>{t("columnName.materialName")}</th>
                        <th className={`${classes.header} ${classes.category}`}>{t("columnName.category")}</th>
                        <th className={`${classes.header} ${classes.outSourcing}`}>{t("columnName.outSourcing")}</th>
                        <th className={`${classes.header}`}>{t("columnName.plannedQty")}</th>
                        <th className={`${classes.header} ${classes.status}`}>{t("columnName.status")}</th>
                        <th className={`${classes.header} ${classes.time}`}>{t("columnName.startTime")}</th>
                        <th className={`${classes.header} ${classes.time}`}>{t("columnName.endTime")}</th>
                        <th className={`${classes.header}`}>{t("columnName.resultQty")}</th>
                        <th className={`${classes.header}`}>{t("columnName.failQty")}</th>
                        <th className={`${classes.header}`}>{t("columnName.failRatio")}</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data?.map ( ( item, index ) =>
                        <tr className={`${classes.row}`} key={index}>
                            {
                                item.category === "제심" ? <td className={`${classes.col}`} rowSpan={4}>{item.orderBuyerId}</td> : <></>

                            }
                            {
                                item.category === "제심" ? <td className={`${classes.col}`} rowSpan={4}>{item.materialName}</td> : <></>
                            }
                            <td className={`${classes.col} ${classes.alignCenter}`} >{item.category}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{item.outSourcing}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.plannedQty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.plannedQty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />
                                }
                            </td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{item.status}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatTime(item.startTime)}</td>
                            <td className={`${classes.col} ${classes.alignCenter}`} >{formatTime(item.endTime)}</td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.resultQty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.resultQty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />   
                                }                         
                            </td>
                            <td className={`${classes.col} ${classes.number}`}>
                                {
                                    item.failQty === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.failQty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                        fixedDecimalScale={true}
                                    />                            
                                }
                            </td>
                            <td className={`${classes.col} ${classes.number} ${classes.failRatio}`}>
                                {
                                    item.failRatio === null
                                    ?
                                    <></>
                                    :
                                    <NumberFormat
                                        value={item.failRatio}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        suffix={'%'}
                                    />                            
                                }
                            </td>
                        </tr>
                    )

                }
                </tbody>
            </table>
        </div>

    )

}

export default EguProductionStatusTable;;
