/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddRoleSetNamespaceButtonLayout from "../layout/AddRoleSetNamespaceButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSchema from "../../lib/api/getSchema";

function AddRoleSetNamespaceButton ( props ) {

    const { t } = useTranslation();

    const { id, roleSet, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ schema, setSchema ] = useState ();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }
        
        refresh.refresh ();

    };

    useEffect ( async () => {

        let mounted = true;

        let schema = await getSchema ( "RoleSetTranslation" );

        if ( mounted && schema ) {

            let column = {};

            column.name = "roleSetName";
            column.formGroup = 0;
            column.formOrder = 1;
            column.type = "text";
            column.readonly = true;     
            column.disabled = true;
            
            schema.columns.push ( column );

            setSchema ( schema );

        }

        return ( () => {
            mounted = false;
        } );

    }, [] );

    const setInitialDataCallback = ( formData ) => {
            
        if ( !formData ) {

            formData = {};
            
        }

        formData.roleSetId = roleSet.roleSetId;
        formData.roleSetName = roleSet.roleSetName;

    }

    return (

        <>
            <AddRoleSetNamespaceButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"append-dialog"}
                mode="create"
                formId={id+"append-form"}
                title={ t ( "dialog.title.RoleSetTranslation.create" ) }
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                setInitialDataCallback={setInitialDataCallback}
            />
        </>

    );
    
}

export default AddRoleSetNamespaceButton;

