/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddApproverButtonLayout from "../layout/AddApproverButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function AddApproverButton ( props ) {

    const { t } = useTranslation();

    const { id, approvalPathId, approverCount, schema, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ modifiedSchema, setModifiedSchema ] = useState ();

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

    };

    useEffect ( () => {

        if ( schema ) {

            let modifiedSchema = schema;

            for ( const column of modifiedSchema.columns ) {

                switch ( column.name ) {
    
                    case "approvalPathId":
    
                        column.hideOnCreate = true;
                        column.disabled = true;
                        column.defaultValue = approvalPathId;
                        setFormData ( ( formData ) => ({...formData, approvalPathId: approvalPathId}) );
    
                        break;
    
                    case "order":
    
                        column.hideOnCreate = true;
                        column.disabled = true;
                        column.defaultValue = approverCount + 1;
                        setFormData ( ( formData ) => ({...formData, order: approverCount + 1}) );
    
                        break;
    
                    default:
    
                        break;
    
                }
    
                setModifiedSchema ( modifiedSchema );
            }

    
        }

    }, [schema, approvalPathId, approverCount] );

    return (

        <>
            <AddApproverButtonLayout id={id+"append-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"append-dialog"}
                mode="create"
                formId={id+"append-form"}
                title={ t ( "dialog.title.ApprovalPath.appendApprover" ) }
                schema={modifiedSchema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
            />
        </>

    );
}

export default AddApproverButton;

