/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-reflex/styles.css";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function DbTaskDetail ( props ) {

    const { t } = useTranslation();

    const { master } = props;
    
    const [api, setApi] = useState();

    useEffect ( () => {

        if ( master && api ) {

            api.refresh ();

        }

    }, [master, api] );

    const onModifyUrl = (url) => {
        
        if ( master ) {

            url = url + "/" + master.id;

            return url;

        }

        return url + "/undefined";
        
    };

    const onApiInitialized = ( api ) => {

        setApi ( api );

    }

    return (

        <BasicCrudScreenType
            id="DbTaskDetail"
            headerTitle={t("term.dbTaskDetail")}
            table="DbTaskDetail"
            view="DbTaskDetail"
            noCreate
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
        />                                

    );

}

export default DbTaskDetail;
