import apiClient from "../common/apiClient";

async function getList( tableName ) {

    let response = await apiClient.get(`/api/${tableName}`);

    return response.data;

}

export default getList;