import ButtonLayout from "../layout/ButtonLayout";

function Button ( props ) {

    return <ButtonLayout {...props} />

}

export default Button;

