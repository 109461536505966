import React from "react";
import {
    unstable_createMuiStrictModeTheme,
    ThemeProvider,
    makeStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

let theme = unstable_createMuiStrictModeTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: "#18202c",
            },
        },
        MuiButton: {
            label: {
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
            },
        },
        MuiTabs: {
            root: {
                // marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                bottom: 8,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
                // margin: "0 16px",
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
            textColorPrimary: {
                color: theme.palette.primary.contrastText
            },
            textColorSecondary: {
                color: theme.palette.secondary.contrastText
            },
        },
        MuiIconButton: {
            root: {
                // padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 16,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minHeight: "100vh",
        overflow: "hidden"
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            // width: layoutConf.sidePane.defaultSize,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: 0,
    },
    main: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        padding: 0,
        // marginTop: "48px",
        background: "white",
    },
    footer: {
        position: "absolute",
        overflow: "hidden",
        top: "auto",
        left: 179,
        right: 0,
        bottom: 0,
        height: "48px",
        background: "#eaeff1",
    },
}));

function NewWindowLayout(props) {

    const { children } = props;

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <div className={classes.app}>
                    <main className={classes.main}>
                        {
                            children
                                ?
                                React.cloneElement(children)
                                :
                                <></>
                        }
                    </main>
                </div>
            </div>
        </ThemeProvider>
    );

}

export default NewWindowLayout;
