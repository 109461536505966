/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysMaterialByCompanyExcelUploadButton ( props ) {
    const {t} = useTranslation();
    const {id, schema, refresh, onSuccess, createButtonName, modifyUrlCallback, ...others} = props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess (msg);
        }
        refresh.refresh ();
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <AutoFormDialog
                id={id+"create-dialog"}
                formId={id+"create-form"}
                title={t(`dialog.title.MomSysMaterialByCompany.excelUpload`)}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback={modifyUrlCallback}
            />
        </>
    );
}

export default MomSysMaterialByCompanyExcelUploadButton;

