import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from "recoil";
import apiClient from '../../lib/common/apiClient';
import notificationState from "../../states/notificationState";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import ExcelIcon from "../presentation/ExcelIcon";

function UserInOutTotalSaveExcelButton ( props ) {

    const {tab, startDate, endDate, searchValue, filterValue, ...others} = props;

    const { t } = useTranslation ();
    const setNotification = useSetRecoilState(notificationState);

    const successNotification = (result) => {
        setNotification({
            severity : "success",
            msg : t("success.fileDownloadSuccess"),
            open : true
        });
    };

    const failureNotification = (reason) => {
        setNotification({
            severity : "error",
            msg : t("error.fileDownloadFailure", {reason: reason.response ? reason.response.data : reason}),
            open : true
        });
    };

    const doHandleFileStream = (result) => {
        
        let  targetFileName;
        
        if ( startDate.getTime () === endDate ) {

            targetFileName = '일간 수불부 집계 ' + startDate.getFullYear () + '-' + ( startDate.getMonth () + 1 ).toString().padStart(2, '0') + '-' + startDate.getDate ().toString().padStart(2, '0') + '.xlsx';

        } else {

            targetFileName = '수불부 집계 ' +
                startDate.getFullYear () + '-' + ( startDate.getMonth () + 1 ).toString().padStart(2, '0') + '-' + startDate.getDate ().toString().padStart(2, '0') + '~' +
                endDate.getFullYear () + '-' + ( endDate.getMonth () + 1 ).toString().padStart(2, '0') + '-' + endDate.getDate ().toString().padStart(2, '0') + '.xlsx';

        }
        
        const blob = new Blob([result.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = targetFileName;
        link.click();
        window.URL.revokeObjectURL(link.href);

    };

    const onSaveToExcel = () => {
    
        let url;
        
        url = `/api/UserMaterialInOutReport/totalExcelDownload/${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth()+1}/${endDate.getDate()}`;

        let query = false;

        if ( searchValue ) {

            url += '?$search=' + searchValue;
            query = true;

        }

        if ( filterValue ) {

            try {

                let $filter;


                if ( filterValue.length > 0 ) {

                    $filter = JSON.stringify ( filterValue );

                }

                if ( query ) {

                    url += '&';

                } else {
                        
                    url += '?';

                }

                url += `$filter=${$filter}`;

            } catch ( reason ) {
                    
            }
        
        }

        apiClient
            .get(url, { responseType: "blob" })
            .then((result) => {
                doHandleFileStream(result);
                successNotification(result);
            })
            .catch((reason) => failureNotification(reason));
    }

    return (
        <>
            <ActionButtonLayout
                id={"save-to-execl-button"}
                startIcon={<ExcelIcon />}
                color="primary" 
                onClick={onSaveToExcel}
                {...others}
            >
                {t("buttonName.inOutTotalExcelDownload")}
            </ActionButtonLayout>
        </>
    );
    
}

export default UserInOutTotalSaveExcelButton;
