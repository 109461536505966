import { useEffect, useState } from "react";

import { cloneDeep } from "lodash";

import MomSysMergeLotButtonLayout from '../layout/MomSysMergeLotButtonLayout';
import MergeLotDialog from "../dialogTypes/MergeLotDialog";

function MomSysMergeLotButton (props) {

    const {id, grid, refresh, schema, onSelect, standard, onSuccess, customizeErrorMsgCallback, ...others} = props;
    
    const [formData, setFormData] = useState();

    const [modifiedSchema, setModifiedSchema] = useState();

    const [ open, setOpen ] = useState(false);
    
    useEffect ( () => {

        if(schema) {

            let modifiedSchema = cloneDeep(schema);

            let lotNo = {};
            
            lotNo.name = "lotNo";
            
            lotNo.readonly = true;
            lotNo.disabled = true;
            
            lotNo.formOrder = 1;
            lotNo.formItemType = "text";
            
            modifiedSchema.columns.push (lotNo);

            let lineNo = {};
            
            lineNo.name = "lineNo";
            
            lineNo.readonly = true;
            lineNo.disabled = true;

            lineNo.formOrder = 2;
            lineNo.formItemType = "text";
            
            modifiedSchema.columns.push (lineNo);

            let wareHouseCode = {};
            
            wareHouseCode.name = "wareHouseCode";
            
            wareHouseCode.readonly = true;
            wareHouseCode.disabled = true;

            wareHouseCode.formOrder = 3;
            wareHouseCode.formItemType = "text";
            
            modifiedSchema.columns.push (wareHouseCode);

            let materialName = {};
            
            materialName.name = "materialName";
            
            materialName.readonly = true;
            materialName.disabled = true;

            materialName.formOrder = 4;
            materialName.formItemType = "text";
            
            modifiedSchema.columns.push (materialName);
            
            let qtyCurrent = {};
            
            qtyCurrent.name = "qtyCurrent";
            
            qtyCurrent.readonly = true;
            qtyCurrent.disabled = true;
            qtyCurrent.formOrder = 7;
            qtyCurrent.formItemType = "float";
            
            modifiedSchema.columns.push (qtyCurrent);
            
            setModifiedSchema(modifiedSchema);

        }
    },[schema]);
        
    const onOpen = () => {

        if(grid) {

            setFormData ( {...(grid.selectedItems[0])} );
        }
        
        setOpen ( true );
    };

    const onClose = () => {

        setOpen ( false );

    };

    const onMergeSuccess = (msg) => {

        if (onSuccess) {
    
          onSuccess(msg);
    
        }
    
        refresh.refresh();
    
    };


    return (
        <>
        <MomSysMergeLotButtonLayout id = {id+"-mergeLot-button"} onClick = {onOpen} {...others}/>
        <MergeLotDialog
                id={id+"mergeLot-dialog"}
                schema={modifiedSchema}
                initialData = {formData}
                onSelect = {onSelect}
                standard = {standard}
                open={open}
                onClose={onClose}
                onSuccess={onMergeSuccess}
            />
        </>
    )

}

export default MomSysMergeLotButton;