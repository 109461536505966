import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getSchema from "../../lib/api/getSchema";

import EguResearchInputMaterialDetailDialog from "../dialogTypes/EguResearchInputMaterialDetailDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";

function EguResearchInputMaterialDetailButton(props) {

    const { id, onSelectMst, onUpdateMaster, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [schema, setSchema] = useState();

    const { t } = useTranslation();

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema("EguResearchInputMaterialDetail");

            if (mounted && schema) {

                let modifiedSchema = { ...schema }
                modifiedSchema.columns = [{
                    name: "inputQty",
                    nullable: "YES",
                    required: true,
                    type: "float",
                    formItemType: 'float',
                    Immediately: true
                }, {

                    name: "lineNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    formGroup: 0,
                    formOrder: 4,
                    formItemType: "autocomplete",
                    codeInfo: {
                        tableName: `MomSysUdtLineNo`,
                        nameColumn: "lineNo",
                        valueColumn: "lineNo",
                        useEmptyValue: false,
                        sortByName: true,
                    }

                }]
                setSchema(modifiedSchema)

            }

        })();

        return (() => {
            mounted = false;
        });

    }, []);

    const onOpen = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false)
    }

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg)
        }
    }

    return (
        <>
            <CreateRecordButtonLayout createButtonName="InputMaterial" id={id + "-create-materialInput-button"} onClick={onOpen} {...others} />
            <EguResearchInputMaterialDetailDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.materialInput`) : ""}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                onSelectMst={onSelectMst}
                onUpdateMaster={onUpdateMaster}
            />
        </>
    )
}

export default EguResearchInputMaterialDetailButton;