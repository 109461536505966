import { makeStyles } from "@material-ui/styles";
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

import "./SubMenuSelectItem.css";

const useStyle = makeStyles((theme) => ({
    root: {
        fontSize: "smalle",
    },
}));

function SubMenuSelectItem ( props ) {

    const classes = useStyle();

    const { t } = useTranslation();

    return (
        <Tab className={classes.root} textColor="primary" label={t("menu." + props.menuItem.menuItemName)} data-cy-sub-menu-item={t("menu." + props.menuItem.menuItemName)} onClick={props.onClick} />
    );

}

export default SubMenuSelectItem;