import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import RegisterMachineFailureButtonLayout from "../layout/RegisterMachineFailureButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getSchema from "../../lib/api/getSchema";

function RegisterMachineFailureButton ( props ) {

    const { t } = useTranslation();

    const { id, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [ schema, setSchema ] = useState ();
    const [ open, setOpen ] = useState(false);
    const [formData, setFormData] = useState();

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            let schema = await getSchema ( "MomSysUglMachineMain" );

            if ( mounted ) {

                schema.name = "RegisterMachineFailure";

                for ( const column of schema.columns ) {
    
                    switch ( column.name ) {
    
                        case "machineCode":
                        case "available":
    
                            column.hideInForm = true;
    
                            break;
    
                        default:
    
                            break;
    
                    }
    
                }
    
                setSchema ( schema );
    
            }

        } ) ();

        return ( () => {
            mounted = false;
        } );

    }, [] );

    const onOpen = () => {

        if ( grid ) {

            let machine = cloneDeep ( grid.selectedItems[0] );

            let maintenance = {

                machineCode: machine.machineCode,

            };

            setFormData ( maintenance );

        }

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        refresh.refresh ();

    };

    const modifyFormDataCallback = ( data, formData ) => {

        data.machineCode = formData.machineCode;

    }

    return (

        <>
            <RegisterMachineFailureButtonLayout id={id+"-register-machine-failure-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id+"-register-machine-failure-dialog"}
                mode="create"
                formId={id+"-register-machine-failure-form"}
                title={ schema ? t ( "dialog.title.MomSysMsgMachine.registerFailure" ) : "" }
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                modifyFormDataCallback={( data ) => modifyFormDataCallback ( data, formData ) }
            />
        </>

    );

}

export default RegisterMachineFailureButton;

