/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { useEffect, useState } from "react";
import { InputDate, InputTime, ComboBox, AutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { DataMap } from '@grapecity/wijmo.grid';
import getList from '../../../lib/api/getList';

async function getData(schema) {

    if(schema.dataMap.tableName) {

        const result = await getList(schema.dataMap.tableName);

        return result.data;

    } else {

        return schema.dataMap.items;

    }

}

function DataGridEditorColumn ( props ) {

    const { schema, ...others } = props;

    const [editorProps, setEditorProps] = useState({});

    useEffect ( async () => {

        let editorProps = {};

        switch(schema.editor?.type) {

            case "number" :
                editorProps.dataType = "Number";
                editorProps.editor = new InputNumber(document.createElement('div'), {
                    ...schema.editor.props
                }); 
                break;

            case "date" :
                editorProps.dataType = "Date";
                editorProps.editor = new InputDate(document.createElement('div'), {
                    ...schema.editor.props
                }); 
                break;

            case "autoComplete" :
                const data = await getData(schema);
                editorProps.dataMap = new DataMap(data, schema.dataMap.selectedValuePath, schema.dataMap.displayMemberPath);
                editorProps.editor = new AutoComplete(document.createElement('div'), {
                    ...schema.editor.props,
                    itemsSource: data
                })
                break;

            default:
                break;

        }

        setEditorProps(editorProps);

    }, [] );

    return (

        <DataGridColumn 
            schema={schema} 
            {...editorProps}
            {...others} 
        />

    );

}

export default DataGridEditorColumn;
