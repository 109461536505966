import getList from "./getList";
import getSpecific from "./getSpecific";

async function getAccessControlList ( roleId ) {

    let accessControlList = {};

    let accessControlTargets = await (await getList("AccessControlTarget" )).data;

    for ( const target of accessControlTargets ) {

        accessControlList[target.accessControlTargetName] = {};
        accessControlList[target.accessControlTargetName].defaultAccess = target.defaultAccess;
        accessControlList[target.accessControlTargetName].defaultUiConf = target.defaultUiConf;

    }

    let accessControlRules = await (await getSpecific("VwAccessControlRule", roleId )).data;

    for ( const rule of accessControlRules ) {

        accessControlList[rule.accessControlTargetName][rule.action.toLowerCase()] = { accessRule: rule.accessRule, hideUi: rule.hideUi };

    }

    return accessControlList;

}

export default getAccessControlList;