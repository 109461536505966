import { useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateRecordButtonLayout from "../layout/UpdateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";
import getExpirationDate from "../../lib/utils/getExpirationDate";

function EguUpdateMaterialFakeInputDetailButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, refresh, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        if (grid) {
            setFormData({ ...grid.selectedItems[0] });
        }

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onUpdateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const checkFormValidationCallback = (formData) => {
        // 2022/12/26 itkim : 가입고 수량이 발주 수량과 상관 없이 설정 가능하도록 함
        //if (formData.qtyFakeInput < formData.qty) {
        //    return [false, t("error.fakeInputQtyCannotBeSmallerThanOutSourcingQty")];
        //}

        return [true, ""];
    };

    return (
        <>
            <UpdateRecordButtonLayout id={id + "-update-button"} onClick={onOpen} {...others} />
            <AutoFormDialog
                id={id + "-update-dialog"}
                mode="edit"
                formId={id + "-update-form"}
                title={schema ? t(`dialog.title.${schema.name}.update`) : ""}
                schema={schema}
                initialData={formData}
                open={open}
                onClose={onClose}
                onSuccess={onUpdateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkFormValidationCallback={checkFormValidationCallback}
                modifyFormDataCallback={(formData, name) => {
                    if (name === "manufacturedDate") {
                        formData.expirationDate = getExpirationDate(
                            formData.standardExpirationDate,
                            new Date(formData.manufacturedDate)
                        );
                    }
                }}
            />
        </>
    );
}

export default EguUpdateMaterialFakeInputDetailButton;
