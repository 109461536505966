import { useState } from "react";
import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import PopCustomerSupplyInputDialog from "../dialogTypes/PopCustomerSupplyInputDialog";

function PopCustomerSupplyInputButton(props) {

    const { t } = useTranslation();

    const { id, schema, refresh, onSuccess, createButtonName, modifyFormDataCallback, customizeErrorMsgCallback, setInitialDataCallback, noKeepOpen, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {

        setOpen(true);

    };

    const onClose = () => {

        setOpen(false);

    };

    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

        if (refresh) {
            refresh.refresh();
        }

    };

    const modifyUrlCallback = () => {
        return "/api/MomSysCustomerSupplyInput/popDoInput"
    }
    return (

        <>
            <ActionButtonLayout id={`${id}-create-button`} onClick={onOpen} {...others}>
                {t("buttonName.input")}
            </ActionButtonLayout>
            <PopCustomerSupplyInputDialog
                id={id + "create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback={modifyUrlCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                setInitialDataCallback={setInitialDataCallback}
                modifyFormDataCallback={modifyFormDataCallback}
                noKeepOpen
            />
        </>

    );
}

export default PopCustomerSupplyInputButton;

