/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import YearMonthDateRangeSelector from "../input/YearMonthDateRangeSelector";
import GroupByColumnPicker from "../input/GroupByColumnPicker";
import * as wjcGrid from '@grapecity/wijmo.grid';
import format from 'format-number';
import {debounce} from "lodash";

function MomSysSalesAnalysisGrid ( props ) {

    const {
        id,
    } = props;

    const [grid, setGrid] = useState();
    const [view, setView] = useState("MomSysVwSalesAnalysis");
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [orderBy, setOrderBy] = useState();
    const [groupBy, setGroupBy] = useState([

        { name: "division", value: true },
        { name: "companyCode", value: true },

    ] );

    const [groupByFields, setGroupByFields] = useState ( [
        "division",
        "companyCode",

    ] );

    const { t } = useTranslation();

    useEffect(()=> {

        if (grid) {

            if(groupBy.every((selectedItem) => selectedItem.value)) {

                grid.loadedRows.addHandler( debounce(( grid, e ) => {

                    let v;
                
                    let sales = 0;
                    let deliveryQty = 0;
                    let unitCost = 0;
                    
                    let cycle = 0;

                    for( const row of grid.rows ) {
                        
                        if ( row.index === 0) {
                
                            v = row.dataItem.division;
                
                        }
        
                        if ( !row.dataItem.division.startsWith(v, 0) ) {
        
                                let newRow = new wjcGrid.Row();
                                newRow.dataItem = {
                    
                                division: t("rowName.subTotal"),
                    
                                sales: sales === 0 ? null : sales,
                                deliveryQty : deliveryQty === 0 ? null : deliveryQty,
                                unitCost : unitCost === 0 ? null : unitCost / cycle,

                                }
                    
                                grid.rows.insert(row.index, newRow);
                    
                                v = row.dataItem.division;
                                sales = 0;
                                deliveryQty = 0;
                                unitCost = 0;
                                cycle = 0;

                        } else {
                                sales += row.dataItem.sales;
                                deliveryQty += row.dataItem.deliveryQty;
                                unitCost += row.dataItem.unitCost;

                                cycle ++;
                        }
                
                    }
        
                    let newRow = new wjcGrid.Row();
        
                    newRow.dataItem = {
        
                    division: t("rowName.subTotal"),
        
                    sales: sales === 0 ? null : sales,
                    deliveryQty: deliveryQty === 0 ? null : deliveryQty,
                    unitCost: unitCost === 0 ? null : unitCost,
        
                    }
                
                        grid.rows.insert(grid.rows.length, newRow);
                
                }), 10);
            }
        }

        return ()=> {

            if(grid?.loadedRows) {

                grid.loadedRows.removeHandler();
            }


        }

    },[grid, groupBy])


    const onGridInitialized = (grid) => {

        grid.allowMerging = "Cells";
        grid.selectionMode = "None";
        grid.allowDragging = false;
        grid.allowPinning = false;

        setGrid(grid);

    };

    const onDateRangeChanged = ( from, to ) => {

        setFrom ( from );
        setTo ( to );

    };

    const checkItemSelected = ( groupBy, name ) => {

        let selected = groupBy[groupBy.findIndex ( ( item ) => item.name === name )].value;
        return selected;

    }

    const onModifyViewSchema = ( groupBy, schema ) => {

        let isDivisionChecked = true;
        let isCompanyCodeChecked = true;

        for ( const column of schema.columns ) {

            switch ( column.name ) {

                case "division" :

                    column.grid.hide = checkItemSelected ( groupBy, "division" ) ? false : true;
                    isDivisionChecked = column.grid.hide;
                    
                    break;

                case "companyCode" :
                case "companyName" :
                    
                    column.grid.hide = checkItemSelected ( groupBy, "companyCode" ) ? false : true;
                    isCompanyCodeChecked = column.grid.hide;

                    break;

                case "cumulativeSales" :

                    if(isDivisionChecked || isCompanyCodeChecked) {

                        column.grid.hide = true;

                    } else {

                        column.grid.hide = false;

                    }

                    break;

                default :

                    break;

            }
        }

    };

    const onGroupByChanged = ( groupBy, orderBy ) => {

        let selectedCount = 0;

        for ( const item of groupBy ) {

            if ( item.value ) {

                selectedCount ++;

            }

        }

        if ( selectedCount === 0 ) {

            groupBy = [

                { name: "division", value: true },
                { name: "companyCode", value: true },

            ];

        }

        let newGroupBy = [...groupBy];
        let newGroupByFields = [];

        for ( const item of groupBy ) {

            if ( item.name === "division" && item.value ) {

                newGroupByFields.push ( "division" );
                
            }
            if ( item.name === "companyCode" && item.value ) {

                newGroupByFields.push ( "companyCode" );
                
            }

        }
            
        let gridStorageKey = `grid-${id}-data-grid-layout`;
        localStorage.removeItem ( gridStorageKey );

        
        // clear order by info to avoid internal server error by order by not included column

        orderBy.setOrderBy ( [] );

        // To make set state instantly

        setTimeout( () => setGroupBy ( newGroupBy ), 0 );
        setTimeout( () => setGroupByFields ( newGroupByFields ), 0 );

        // To make schema updated

        setTimeout ( () => setView ( undefined ), 0 );
        setTimeout ( () => setView ( "MomSysVwSalesAnalysis" ), 0 );

    };

    const makeQueries = ( from, to, groupByFields ) => {

        let queries = {
            $from: from,
            $to: to,
            $groupBy: JSON.stringify(groupByFields)
        };

        return queries;
    }

    const onOrderByInitialized = (orderBy) => {

        setOrderBy ( orderBy );

    }

    const onLoadData = ( data ) => {
        for ( let row of data ) {

            row.percent = format({ round: 2, suffix: "%" })(row.percent);        
            /* row.deliveryQty = format({ round: 0})(row.deliveryQty);        
            row.unitCost = format({ round: 0})(row.unitCost);  */       

        }

    }

    return (
        
        <BasicCrudScreenType 
            id={id}
            table="MomSysVwSalesAnalysis"
            view={view}
            $queries={ makeQueries ( from, to, groupByFields ) }
            onLoadData={onLoadData}
            noCreate
            noEdit
            noDelete
            noExcel
            noPagination
            noSearch
            noFilter
            onAppendHeaderAtFirst={ () =>

                <>
                    <YearMonthDateRangeSelector onChange={onDateRangeChanged} />
                    <div style={{marginLeft: "32px"}} />
                    <GroupByColumnPicker color="primary" groupByColumns={ groupBy } onChange={( groupBy ) => onGroupByChanged ( groupBy,orderBy ) } />
                </>
            
            }
            onInitialized={onGridInitialized}
            onOrderByInitialized={onOrderByInitialized}
            onModifyViewSchema={ ( schema ) => onModifyViewSchema ( groupBy, schema ) }
        />
    );
    
}

export default MomSysSalesAnalysisGrid;