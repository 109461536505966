import { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Grid, InputAdornment, TextField, Toolbar, makeStyles,  } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';
import { cloneDeep } from "lodash";

import getSchema from "../../lib/api/getSchema";
import { getOption } from "../../lib/api/getOptions";

import DateRangeSelectorWithButtons from "../input/DateRangeSelectorWithButtons";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import OpenFilterButton from "../input/OpenFilterButton";

import FilterConditionPanelLayout from "../layout/FilterConditionPanelLayout";

import AutoFilterForm from "../filter/functional/AutoFilterForm";
import FilterApplyButton from "../input/FilterApplyButton";
import FilterClearButton from "../input/FilterClearButton";
import FilterCancelButton from "../input/FilterCancelButton";

import FilterDialog from "../dialogTypes/FilterDialog";

import UserMaterialInOutReport from "./UserMaterialInOutReport";
import DailyMaterialInOutReport from "./DailyMaterialInOutReport";

import UserInOutSaveExcelButton from "../input/UserInOutSaveExcelButton";
import UserInOutTotalSaveExcelButton from "../input/UserInOutTotalSaveExcelButton";
import UserInOutReTotalizationButton from "../input/MaterialInOutReTotalizationButton";
import MaterialInOutSaveExcelButton from "../input/MaterialInOutSaveExcelButton";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function FilterConditionUI ( props ) {

    const { startDate, endDate, useFilterDialog, filter, showFilter, onFilterClosed, onFilterChanged, onFilterUIInitialized } = props;

    const { t } = useTranslation();

    const [ , setForm ] = useState ();
    const [ useDialiog, setUseDialog ] = useState ( false );
    const [ schema, setSchema ] = useState ();
    const [ filterApplyButton, setFilterApplyButton ] = useState();
    const [ filterValue, setFilterValue ] = useState ();
    const [ filterDialog, setFilterDialog ] = useState ();
    
    useEffect ( () => {

        setUseDialog ( useFilterDialog );

    }, [ useFilterDialog ] );

    useEffect ( () => {
    
        if ( showFilter && filterDialog ) {

            filterDialog.show ();

        } else {

        }

    }, [ showFilter, filterDialog ] );

    useEffect ( () => {

        if ( onFilterUIInitialized ) {
        
            if ( schema && filter ) {

                onFilterUIInitialized ( true );

                if ( filterValue ) {

                    filter.set ( filterValue );

                    if ( onFilterChanged ) {

                        onFilterChanged ( filterValue );

                    }

                }
    
            } else {
                    
                onFilterUIInitialized ( false );
    
            }
    
        }

    }, [ onFilterChanged, onFilterUIInitialized, schema, filter, filterValue] );

    useEffect ( () => {

        let mounted = true;

        if ( startDate && endDate ) {
        
            ( async () => {

                if ( startDate.getTime () === endDate.getTime () ) {
    
                    let schema = await getSchema ( "DailyMaterialInOutReport" );
    
                    if ( mounted ) {
    
                        setSchema ( schema );
    
                    }
        
                } else {
    
                    let schema = await getSchema ( `/api/UserMaterialInOutReportSchema/${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth()+1}/${endDate.getDate()}` );
    
                    if ( mounted ) {
    
                        setSchema ( schema );
    
                    }
        
                }
        
            } ) ();
    
        }

        return ( () => {

            mounted = false;

        } );

    }, [ startDate, endDate] );

    const closeFilter = () => {

        if ( onFilterClosed ) {

            onFilterClosed ();

        }

    };

    const onFilterDialogInitialized = ( dialog ) => {

        setFilterDialog ( dialog );

    };

    const onFormInitialized = ( form ) => {

        setForm ( form );

    }

    const onFilterApplyButtonInitialized = ( filterApplyButton ) => {

        setFilterApplyButton ( filterApplyButton );

    };

    const onApplyFilter = ( filterValue ) => {

        if ( filterValue ) {

            setFilterValue ( [...filterValue] );

        } else {

            setFilterValue ( () => {

                let newFilterValue = [];

                for ( const column of schema.columns ) {
        
                    if ( column.filter ) {
    
                        newFilterValue.push ( {
    
                            binding: column.name,
                            type: "condition",
                            and: true,
                            condition1: { operator: "", value: "" },
                            condition2: { operator: "", value: "" }
    
                        } );
    
                    }
                }

                return newFilterValue;

            } );
        }
        
    }

    const onClearFilter = () => {

        setForm ( ( form ) => {

            form.clear ();
            return form;

        } );

    }

    return (
        useDialiog
        ?
        <FilterDialog
            id={"user-int-out-report-filter-dialog"}
            title={t("dialog.title.Filter")}
            schema={schema}
            filter={filter}
            filterValue={filterValue?cloneDeep(filterValue):filterValue}
            onInitialized={onFilterDialogInitialized}
            onFormInitialized={onFormInitialized}
            onApply={onApplyFilter}
            onClear={onClearFilter}
            onCancel={closeFilter}
        />
        :
        <FilterConditionPanelLayout
            className="basicCrudScreenFilterPanel"
            show={showFilter}
        >
            <AutoFilterForm
                id={"user-int-out-report-filter-form"}
                className="autoFilterForm"
                schema={schema}
                filterValue={filterValue?cloneDeep(filterValue):filterValue} // object deep copy - 내부에서 객체 멤버가 수정되어 버그 발생
                filterApplyButton={filterApplyButton}
                onInitialized={onFormInitialized}
            />
            <FilterApplyButton
                id={"user-int-out-report-filter-apply-button"}
                className="filterApplyButton"
                schema={schema}
                filter={filter}
                onApply={onApplyFilter}
                onInitialized={onFilterApplyButtonInitialized}
            />
            <FilterClearButton
                id={"user-int-out-report-filter-clear-button"}
                className="filterClearButton"
                onClear={onClearFilter}
            />
            <FilterCancelButton
                id={"user-int-out-report-filter-cancel-button"}
                className="filterCancelButton"
                onCancel={closeFilter}
            />                    

        </FilterConditionPanelLayout>
    )

}

function UserInOutReportContent (props) {

    const { startDate, endDate, onSearchInitialized, onFilterInitialized, apiInitialized } = props;

    // dummy functions for compatibility
            
    if ( startDate.getTime () === endDate.getTime () ) {

        return <DailyMaterialInOutReport 
            date={startDate}
            onSearchInitialized={onSearchInitialized}
            onFilterInitialized={onFilterInitialized}
            apiInitialized = {apiInitialized}
        />;

    } else {

        return <UserMaterialInOutReport 
            startDate={startDate}
            endDate={endDate} 
            onSearchInitialized={onSearchInitialized}
            onFilterInitialized={onFilterInitialized}
            apiInitialized = {apiInitialized}
        />;

    }

}

function UserInOutReport (props) {

    const classes=useStyle();

    const { t } = useTranslation();

    let now = new Date();

    let year = now.getFullYear();
    let month = now.getMonth();
    let day = now.getDate() - 1;
    
    let defaultStartDate = new Date(year, month, day);
    let defaultEndDate = new Date(year, month, day);

    const [ startDate, setStartDate ] = useState(defaultStartDate);
    const [ endDate, setEndDate ] = useState(defaultEndDate);

    const [ , setSearch ] = useState ();
    const [ searchVal, setSearchVal ] = useState ();

    const [ openProgress, setOpenProgress ] = useState(false);

    const [ api , setApi ] = useState ();
    const [ filter, setFilter ] = useState ();
    const [ filterValue, setFilterValue ] = useState ();
    const [ showFilter, setShowFilter ] = useState ( false );
    const [ useFilterDialog, setUseFilterDialog ] = useState(false);
    const [ filterUIInitialized, setFilterUIInitialized ] = useState ( false );

    useEffect(() => {

        let mounted = true;

        ( async () => {

            let useFilterDialog = await getOption ( "default", "useFilterDialog" );

            if ( mounted ) {

                if ( useFilterDialog && ( useFilterDialog.toLowerCase () === "true" ) ) {

                    setUseFilterDialog ( true );
    
                } else {
    
                    setUseFilterDialog ( false );
    
                }
    
            }

        } ) ();

        /*
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth();
        let day = now.getDate() -1;
        
        setStartDate(new Date(year, month, day));
        setEndDate(new Date(year, month, day));
        */

        return ( () => {
            mounted = false;
        } );

    }, []);


    const onDateRangeChanged = ( from, to ) => {
        
        setStartDate ( from );
        setEndDate ( to );

    };

    const onChangeSearch = ( e ) => {

        setSearch ( ( search ) => {

            if ( search ) {

                setSearchVal ( e.target.value );
                search.applySearch ( e.target.value );

            }

            return search;

        } );

    };

    const onSearchInitialized = ( search ) => {

        setSearch ( search );

        setSearchVal ( ( searchVal ) => {

            if ( searchVal ) {

                search.applySearch ( searchVal );
                
            }

            return searchVal;

        } );

    }

    const onFilterInitialized = ( filter ) => {

        setFilter ( filter );

    }

    const onFilterUIInitialized = ( initialized ) => {

        setFilterUIInitialized ( initialized );

    }

    const onFilterChanged = ( filterValue ) => {

        setFilterValue ( filterValue );

    }

    const onFilterClosed = () => {

        setShowFilter ( false );

    }

    const toggleOpenFilter = () => {

        setShowFilter ( ( showFilter ) => {

            return !showFilter;

        } );

    }; 

    return (

        <>
            <div id="material-in-out-report">

                <Toolbar style={ {
                    top: "0px",
                    margin: "0px",
                    padding: "0px",
                    marginLeft: "0px",
                    height: "48px",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    backgroundColor: "rgb(255,255,255)",
                    borderBottom: "1px solid rgb(212, 212, 212)",
                } }>
                    <Grid item>
                        <DateRangeSelectorWithButtons
                            disableCheckAll
                            maxRange={61}
                            startDate={startDate}
                            endDate={endDate}
                            style={{
                                marginTop: "5px"
                            }}
                            yesterday prevWeek prevMonth today currentWeek currentMonth
                            onChange={onDateRangeChanged}
                        />                                
                    </Grid>
                    <Grid item xs>
                            <span></span>
                    </Grid>
                    <Grid item>
                        {
                            startDate.getTime () === endDate.getTime ()
                            ?
                            <></>
                            :
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "4px",
                                    marginRight: "2px",
                                }}
                                
                            >
                                <UserInOutTotalSaveExcelButton
                                    startDate={startDate}
                                    endDate={endDate}
                                    searchValue={searchVal}
                                    filterValue={filterValue}
                                />
                            </span>

                        }
                    </Grid>
                    <Grid item>
                        <span
                            style={{
                                display: "inline-block",
                                height: "16px",
                                paddingLeft: "15px",
                                paddingTop: "4px",
                                marginRight: "2px",
                            }}
                        >
                            {
                                startDate.getTime () === endDate.getTime ()
                                ?
                                <MaterialInOutSaveExcelButton
                                    date={startDate}
                                    view={"DailyMaterialInOutReport"}
                                    excelFileName={`일간`}
                                    searchValue={searchVal}
                                    filterValue={filterValue}
                                />
                                :                            
                                <UserInOutSaveExcelButton
                                    startDate={startDate}
                                    endDate={endDate}
                                    searchValue={searchVal}
                                    filterValue={filterValue}
                                />
                            }
                        </span>
                    </Grid>
                    <Grid item>
                        {
                            startDate.getTime () === endDate.getTime ()
                            ? 
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingTop: "4px",
                                }}
                            >
                            <UserInOutReTotalizationButton
                                date = {startDate}
                                setOpenProgress = {setOpenProgress}
                                refresh = {api}
                            />
                            </span>
                            :
                            <></>
                        }
                    </Grid>
                    <Grid item>
                        <TextField
                            id={"mateirla-in-out-report-search"}
                            className={classes.root}
                            placeholder={t("common.search")}
                            onChange={ onChangeSearch }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>                    
                                )
                            }}
                            style={{
                                bottom: "2px",
                                width: "120px"                        
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <BasicCrudScreenActionItemLayout>

                            <OpenFilterButton
                                id={"user-in-out-report-open-filter-button"}
                                className="basicCrudScreenOpenFilterButton"
                                color="primary"
                                style={{marginTop: "4px"}}
                                disabled={!filterUIInitialized}
                                onClick={toggleOpenFilter}
                            />
                            
                        </BasicCrudScreenActionItemLayout>
                    </Grid>
                </Toolbar>

                <UserInOutReportContent 
                    startDate={startDate} 
                    endDate={endDate} 
                    onSearchInitialized={onSearchInitialized}
                    onFilterInitialized={onFilterInitialized}
                    apiInitialized = {(api) => setApi(api) }
                />

                <FilterConditionUI
                    useFilterDialog={useFilterDialog}
                    startDate={startDate}
                    endDate={endDate}
                    filter={filter}
                    showFilter={showFilter}
                    onFilterChanged={onFilterChanged}
                    onFilterClosed={onFilterClosed}
                    onFilterUIInitialized={onFilterUIInitialized}
                />

            </div>

            <Backdrop className={classes.backdrop} open={openProgress}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
        
    );

}

export default UserInOutReport;
