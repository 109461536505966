import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import MomSysProductStatusDetail from "./MomSysProductStatusDetail";

import "../grid/layout/DataGridMomSysSafetyStockInventoryStateColumn.css"
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysBomBopTreeButton from "../input/MomSysBomBopTreeButton";

function MomSysProductStatus ( props ) {

    const [ mstStandardGrid, setMstStandardGrid ] = useState();
    const [ standardProduct, setStandardProduct ] = useState();
    
    const [ height, setHeight ] = useState(0);

    
    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect( () => {

        ( async () => {

            if (mstStandardGrid) {
    
                mstStandardGrid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];
                        
                        setStandardProduct(() => {

                            return (
                                {
                                    ...product,
                                    qty : product.qtyInStock
                                });
                        });
                    }
                });
                
                mstStandardGrid.itemsSourceChanged.addHandler((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let product = grid.selectedItems[0];
                        
                        setStandardProduct(() => {

                            return (
                                {
                                    ...product,
                                    qty : product.qtyInStock
                                });
                        });
                    }
                });

                mstStandardGrid.loadedRows.addHandler((flex, e) => {
                    /* let rows  = e.getRow(); */

                    for(const row of flex.rows) {

                        if(row.dataItem.qtySpareStock < row.dataItem.qtySafetyStock) {
                            
                            row.cssClass = `failed-stock-apply`

                        }
                    }
                })

            }

        } ) ()

    },[mstStandardGrid]);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);



    const onMstStandardInitialized = ( grid ) => {

        grid.selectionMode = "Row";
        
        setMstStandardGrid(grid);

    };

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="vertical">

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType 
                                id="MomSysVwInventorySummary" 
                                view="MomSysVwInventorySummary" 
                                headerTitle={t("term.standardProductList")}
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={onMstStandardInitialized}
                                onModifyViewUrl = {(url) => `${url}/product`}
                                onAppendButtonAtFirst={() =>
                                    <>

                                        <BasicCrudScreenActionItemLayout>

                                            <MomSysBomBopTreeButton color="primary" product={standardProduct} />

                                        </BasicCrudScreenActionItemLayout>

                                    </>
                                }
                                
                            />
                     </ReflexElement>

                     <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                        <ReflexElement flex={0.5}>

                                {
                                    (standardProduct)
                                    ?
                                    <MomSysProductStatusDetail
                                        id="MomSysVwMaterialStatus" 
                                        view="MomSysVwMaterialStatus" 
                                        product = {standardProduct}
                                    />
                                    :
                                    <></>
                                }

                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }}/>


                <ReflexElement flex={0.5}>

                    <MomSysProductStatusDetail
                        id="MomSysNsZvwSgtWipLot" 
                        view="MomSysNsZvwSgtWipLot"
                        nonStandard = {true}
                    />

                </ReflexElement>

                

            </ReflexContainer>

        </div>

    );

}

export default MomSysProductStatus;
