import i18n from "i18next";

function checkValidation ( mode, column, condition, value ) {

    let msg = "";
    let result = true;
    let msg1, msg2;
    let result1, result2;
    
    if ( mode === "create" && column.useAuthProfileUsernameOnInsert ) {

        return [true, ""];

    }
    
    if ( mode === "edit" && column.useAuthProfileUsernameOnUpdate ) {

        return [true, ""];

    }    
    if ( mode === "edit" && column.password && value === "" ) {

        return [true, ""] ;

    }


    if ( column.required && !column.hideInForm && ( value === undefined || value === null || value === "" ) ) {

        if ( ! ( column.ignoreRequiredOnEdit && mode === "edit" ) ) {

            return [ false,  i18n.t ( "error.columnRequired", { column:  i18n.t ( `columnName.${column.displayName||column.name}` ) } ) ];

        }
 
    }

    if ( !condition )
    {

        return [ true, "" ];

    }

    if ( value === "" ) {

        return [ true, "" ];

    }
    
    switch ( condition.type ) {

        case "conditions":

            switch ( condition.operator ) {

                case "and":

                    [result1, msg1] = checkValidation ( mode, column, condition.value[0], value );
                    [result2, msg2] = checkValidation ( mode, column, condition.value[1], value );

                    result = result1 && result2;

                    msg =  result ? "" : i18n.t ( condition.msg, { msg: result2 ? msg1 : msg1, msg1: msg1, msg2: msg2 } );

                    break;

                case "or":

                    [result1, msg1] = checkValidation ( mode, column, condition.value[0], value );
                    [result2, msg2] = checkValidation ( mode, column, condition.value[1], value );

                    result = result1 || result2;
                    msg =  result ? "" : i18n.t ( condition.msg, {  msg1:  msg1, msg2:  msg2 } )

                    break;

                case "xor":

                    [result1, msg1] = checkValidation ( mode, column, condition.value[0], value );
                    [result2, msg2] = checkValidation ( mode, column, condition.value[1], value );

                    result = ( result1 ? 1 : 0 ) ^ ( result2 ? 1 : 0 );
                    msg =  result ? "" : i18n.t ( condition.msg, {  msg1:  msg1, msg2:  msg2 } )

                    break;

                case "not":

                    [result, msg] = checkValidation ( mode, column, condition.value, value );
                    result = !result;
                    msg =  result ? "" : i18n.t ( condition.msg, {  msg:  i18n.t ( msg ) } )

                    break;

                default:

                    result = true;
                    msg = "";
                    break;

            }

            break;

        case "value":

            switch ( condition.operator ) {

                case "lt":

                    result = value < condition.value;
                    break;

                case "le":

                    result = value <= condition.value;
                    break;

                case "eq":

                    result = value === condition.value;
                    break;

                case "ge":

                    result = value >= condition.value;
                    break;

                case "gt":

                    result = value > condition.value;
                    break;

                case "contain":

                    result = value.includes(condition.value);
                    break;

                case "begin with":

                    result = value.startsWith(condition.value);
                    break;

                case "end with":

                    result = value.endsWith(condition.value);
                    break;

                case "in":

                    result = value in (condition.values);
                    break;

                default:

                    result = true;
                    break;

            }
                    
            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: value } );

            }
            
            break;

        case "regexp":

            let regexp = new RegExp ( condition.value );

            result = regexp.test ( value );
                    
            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: value } );

            }
            
            break;

        case "length":

            switch ( condition.operator ) {

                case "lt":

                    result = value.length < condition.value;
                    break;

                case "le":

                    result = value.length <= condition.value;
                    break;

                case "eq":

                    result = value.length === condition.value;
                    break;

                case "ge":

                    result = value.length >= condition.value;
                    break;

                case "gt":

                    result = value.length > condition.value;
                    break;

                default:

                    result = true;
                    msg = "";
                    break;

            }
                    
            if ( ! result ) {

                msg =  i18n.t ( condition.msg, { value: value } );

            }
            
            break;

        default:

            result = true;
            msg = "";
            break;

    }

    return [ result, msg ];

}

export default checkValidation;
