import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import MomSysUdtOwnCompanyCreateButton from "../input/MomSysUdtOwnCompanyCreateButton";
import MomSysUdtOwnCompanyUpdateButton from "../input/MomSysUdtOwnCompanyUpdateButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"
import DeleteRecordButton from "../input/DeleteRecordButton";

function MomSysOwnCompany ( props ) {
    const {id, masterGridSetting} = props;

    const [schema, setSchema] = useState();
    const [grid, setGrid] = useState();
    const [mstApi, setMstApi] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(()=> {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysUdtCompany");
            if (mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    },[]);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setSelectGrid(undefined);
                    };
                });

                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onSuccess = ( msg ) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    }

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onApiInitialized = ( api ) => {
        setMstApi ( api );
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };
    
    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <BasicCrudScreenType 
            id={id}
            table="MomSysUdtCompany" 
            view="MomSysVwOwnCompany" 
            noCreate
            noEdit
            noDelete
            noExcel
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onAppendButtonInFrontOfSearch = {
                () => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <MomSysUdtOwnCompanyCreateButton
                                id = "MomSysOwnCreateCompany"
                                grid = {grid}
                                schema = {schema}
                                refresh = {mstApi}
                                onSuccess = {onSuccess}
                                onFailure = {onFailure}
                            />
                        </BasicCrudScreenActionItemLayout>
                        
                        <BasicCrudScreenActionItemLayout>
                            <MomSysUdtOwnCompanyUpdateButton
                                id = "MomSysOwnUpdateCompany"
                                grid = {grid}
                                schema = {schema}
                                refresh = {mstApi}
                                onSuccess = {onSuccess}
                                onFailure = {onFailure}
                                disabled={selectGrid ? false : true}
                            />
                        </BasicCrudScreenActionItemLayout>

                        <BasicCrudScreenActionItemLayout>
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={mstApi}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={selectGrid ? false : true}
                            />
                        </BasicCrudScreenActionItemLayout>
                    </>
                )
            }    
        />
    )
}

export default MomSysOwnCompany;
