/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";

function ExecuteBatchJobDialog ( props ) {

    const { t } = useTranslation ();

    const [, setInstance] = useState ();
    const [formData, setFormData] = useState ({outsourcing: false});

    const [schema, setSchema] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        
        job,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    useEffect ( () => {

        let mounted = true;

        ( async () => {

            if ( mounted ) {

                const schema = {

                    name: "ExecuteBatchJob",
                    type: "BASE TABLE",
                    searchAll: false,
            
                    columns: [
                        {
                            name: "name",
                            formOrder: 1,
                            formItemType: "text",
                            readonly: true,
                            visible: true,
                            width: "100%",
                        },
                        {
                            name: "moduleName",
                            formOrder: 2,
                            formItemType: "text",
                            readonly: true,
                            visible: true,
                            width: "100%",
                        },
                        {
                            name: "tenantName",
                            formOrder: 3,
                            formItemType: "text",
                            readonly: true,
                            visible: true,
                            width: "100%",
                        },
                        {
                            name: "date",
                            formOrder: 4,
                            formItemType: "date",
                            required: true,
                            visible: true,
                            width: "100%",
                        }
                    ]

                };

                setSchema ( schema );

            }

        } ) ();

        return ( () => {
            mounted = false;
        });

    }, [] );

    useEffect ( () => {

        if ( job ) {

            setFormData ( ( formData ) => {

                formData.id = job.id;
                formData.name = job.name;
                formData.moduleName = job.moduleName;
                formData.tenantName = job.tenantName;
                formData.date = new Date();

                return formData;

            } );

        }

    }, [job] );

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = ( result ) => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

        if ( onSuccess ) {

            setFormData ( ( formData ) => {

                onSuccess ( t ( "success.executeTaskSuccess", { name: formData.name, moduleName: formData.moduleName, tenantName: formData.tenantName, date: formData.date } ) );

                return formData;

            } );

        }

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onApiFailure = ( apiData, reason ) => {

        notifyFailure ( t ( "error.executeTaskFailed", { name: formData.name, moduleName: formData.moduleName, tenantName: formData.tenantName, date: formData.date } ) + ` : ${ reason.response ? reason.response.data : JSON.stringify ( reason ) }` );

    };

    const onOk = () => {                            

        setFormData ( ( formData ) => {

            let apiData = {
                id: formData.id,
                name: formData.name,
                moduleName: formData.moduleName,
                tenantName: formData.tenantName,
                date: formData.date
            };
                
            let form = new FormData ();
            let json = apiData;
            let deleted = {};

            form.append ( "deleted", JSON.stringify ( deleted ) );
            form.append ( "json", JSON.stringify ( json ) );

            apiClient
                .post(`/api/BatchHistory`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => onApiSuccess(result))
                .catch((reason) => onApiFailure(apiData, reason));

            return formData;

        } );

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onCancel = () => {

        setInstance ( ( instance ) => {

            if ( instance ) {

                instance.hide();

            }

            return instance;

        } );

    };

    const onCloseNotification = () => {

        setNotification ( ( notification ) => {

            return ( {...notification, open: false} );

        } );

    };

    const onCloseDialog = () => {
        
        if ( onClose ) {

            onClose ();

        }
 
    };

    const onChangeForm = ( formData ) => {

        setFormData ( formData );

    };

    return (

        <Dialog
            id={id ? id : "execute-batch-job-dialog"}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="sm"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.ExecuteBatchJob" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent>

                <AutoForm
                    id="execute-batchjob-form"
                    initialData={formData}
                    schema={schema}
                    mode="edit"
                    onChange={ onChangeForm }
                />

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >

                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                onOk={() => onOk()}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default ExecuteBatchJobDialog;
