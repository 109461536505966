import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageResize from "quill-image-resize-module-react";

import uiConf from "../../config/uiConf";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import "react-quill/dist/quill.snow.css";

import SimpleHtmlEditorScreen from "./SimpleHtmlEditorScreen";
import { Quill } from "react-quill";
import { useEffect } from "react";
import { useState } from "react";
import { ReflexContainer, ReflexElement } from "react-reflex";

Quill.register("modules/imageResize", ImageResize);

function MomSysPopNoticeUpdate(props) {

    const { id, open, moveable, resizable, fullWidth, title, schema, onClose, onSuccess, ...others } = props;

    const [cancelDisabled,] = useState(true);
    const [instance, setInstance] = useState();

    const [height, setHeight] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    const params = useParams();

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const { t } = useTranslation();

    const onCloseDialog = () => {
        window.close();
        if (onClose) {
            onClose();
        }
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
    };

    const onApiSuccess = (result) => {
        instance.hide();
        if (onSuccess) {
            setTimeout(onSuccess(result.data), 0);
        }

        if (window.opener) {

            window.opener.postMessage({ message: "Success", t: result }, "*")
            window.close();
        }
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = (reason) => {
        window.opener.postMessage(
            {
                message: "Failure",
                t: t("error.insertFailure", {
                    reason: reason.response ? reason.response.data : JSON.stringify(reason),
                }),
            },
            "*"
        );

        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };


    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCancel = () => {
        instance.hide();
        window.close();
    };

    return (
        <div>
            <Dialog
                id={id}
                // moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                // maxWidth="lg"
                fullScreen={true}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                open={true}
                {...others}
            >
                <DialogHeaderWithClose></DialogHeaderWithClose>

                <DialogContent orientation="vertical" style={{ height: height }}>
                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={1} data-cy="MomSysPopNoticeUpdate" minSize={520} propagateDimensions={true}>

                            <SimpleHtmlEditorScreen
                                noheader
                                haveHtmlTitle
                                path="MomSysPopNotice"
                                memoKey={`${params.orderBuyerDetailId}-${params.processCode}`}
                                onSuccess={onApiSuccess}
                                onFailure={onApiFailure}
                                onCancel={onCancel}
                                cancelDisabled={cancelDisabled}
                            />

                        </ReflexElement>

                    </ReflexContainer>


                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default MomSysPopNoticeUpdate;
