import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import WarehouseMaterialList from "./WarehouseMaterialList";
import WarehouseLotList from "./WarehouseLotList";
import {debounce} from "lodash";

function WarehouseStockStatus ( props ) {

    const [ warehouseGrid, setWarehouseGrid ] = useState();
    const [ warehouse, setWarehouse ] = useState();
    const [ material, setMaterial ] = useState();
    
    const [ height, setHeight ] = useState(0);

    
    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect( () => {

        ( async () => {

            if (warehouseGrid) {
    
                warehouseGrid.selectionChanged.addHandler((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let warehouse = grid.selectedItems[0];
                        
                        setWarehouse(warehouse);

                    } else {

                        setWarehouse();

                    };
                    
                } );
                
                warehouseGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        let warehouse = grid.selectedItems[0];
                        
                        setWarehouse(warehouse);

                    }
                    
                    else {

                        setWarehouse();

                    };

                } ), 10 );

            }

        } ) ()

    },[warehouseGrid]);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    
    
    }, []);

    const onWarehouseInitialized = ( grid ) => {
            
        grid.selectionMode = "Row";
        setWarehouseGrid(grid);

    }

    const onMaterialChange = (material) => {

        setMaterial(material);

    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <ReflexContainer orientation="vertical">

                        <ReflexElement flex={0.5}>

                            <BasicCrudScreenType 
                                table="MomSysUdtWareHouse" 
                                view="MomSysVwWareHouse" 
                                headerTitle={t("term.warehouse")}
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={onWarehouseInitialized}
                            />
                            
                        </ReflexElement>

                        <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                        <ReflexElement >

                            <WarehouseMaterialList warehouse={warehouse} onSelect={onMaterialChange} />

                        </ReflexElement>

                    </ReflexContainer>


                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5}>

                    <WarehouseLotList warehouse={warehouse} material={material} />

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}
export default WarehouseStockStatus;
