/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import cloneDeep from "lodash/cloneDeep";

import notificationState from "../../states/notificationState";

import ProduceButton from "../input/MomSysProduceButton";
import ToolbarFlexSpacer  from "../layout/ToolbarFlexSpacer";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

function MomSysSoProductionProduct ( props ) {

    const { t } = useTranslation();

    const { soDetail, onSelect, onApiInitialized } = props;

    const [product, setProduct] = useState();
    const [disabled, setDisabled] = useState(true);
    const [, setOrderBuyerId] = useState();
    const [, setApi] = useState();

    const setNotification = useSetRecoilState(notificationState);

    useEffect(() => {

        if ( soDetail ) {

            setOrderBuyerId ( soDetail.orderBuyerId );
    
        }

    }, [soDetail]);

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
        
    };

    const onGridInitialized = (grid) => {

        grid.selectionMode = "Row";

        grid.selectionChanged.addHandler((grid, event) => {

            setOrderBuyerId ( ( orderBuyerId ) => { 

                let product = cloneDeep ( grid.selectedItems[0] );

                if ( product ) {

                    product.orderBuyerId = orderBuyerId;
                    setDisabled ( false );

                } else {

                    setDisabled ( true );

                }

                setProduct( product );

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    if ( onSelect ) {

                        onSelect(grid.selectedItems[0]);

                    }

                } else {

                        if ( onSelect ) {

                            onSelect ( undefined );

                        }

                }

                return orderBuyerId;

            } );


        });

        grid.itemsSourceChanged.addHandler((grid, event) => {

            setOrderBuyerId ( ( orderBuyerId ) => {

                let product = cloneDeep ( grid.selectedItems[0] );

                if ( product ) {

                    setDisabled ( false );
                    product.orderBuyerId = orderBuyerId;

                } else {

                    setDisabled ( true );
                    
                }

                setProduct( product );

                if ( grid.selectedItems && grid.selectedItems[0] ) {

                    if ( onSelect ) {

                        onSelect(grid.selectedItems[0]);

                    }

                } else {

                    if ( onSelect ) {

                        onSelect ( undefined );

                    }
                    
                }

                return orderBuyerId;

            } );

        });
        
    };

    const onModifyUrl = ( soDetail, url ) => {
        
        let newUrl;

        if ( soDetail ) {

            newUrl = `${url}/${soDetail.orderBuyerId}/${soDetail.materialCode}/${soDetail.materialCodeVer}/${soDetail.qtyToProduce}`;

        } else {

            newUrl = url + "/undefined/undefined/undefined/undefined";

        }

        return newUrl;
        
    };

    const onSuccess = ( msg ) => {
    
        notifySuccess ( msg );

        setApi ( ( api ) => {

            api.refresh ();

            return api;

        } );
    
    };

    const onApiInitializedLocal = ( api ) => {

        setApi ( api );

        if ( onApiInitialized ) {

            onApiInitialized ( api );

        }

    };

    return (

        <BasicCrudScreenType
            id="MomSysSoProductionProduct"
            headerTitle={t("term.outputMaterial")}
            table="MomSysSgtLot"
            view="MomSysVwMaterialsToProduce"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            onInitialized={onGridInitialized}
            onAppendButtonInFrontOfSearch={() =>

                <>

                    <ToolbarFlexSpacer xs />

                    <BasicCrudScreenActionItemLayout>

                        <ProduceButton
                            id="ProduceProductButton"
                            disabled={disabled}
                            product={product}
                            onSuccess={onSuccess}
                        />

                    </BasicCrudScreenActionItemLayout>


                </>

            }
            onModifyViewUrl={( url ) => onModifyUrl ( soDetail, url )}
            onApiInitialized={onApiInitializedLocal}
        />                                

    );

}

export default MomSysSoProductionProduct;
