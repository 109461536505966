import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import uiConf from "../../config/uiConf";

import getSpecific from "../../lib/api/getSpecific";
import getList from "../../lib/api/getList";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogContent from "../dialog/functional/DialogContent";
import DialogActionsOk from "../dialog/functional/DialogActionsOk";

import ApprovalStatus from "../presentation/ApprovalStatus";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    }
}));

function ApprovalStatusDialog ( props ) {

    const { t } = useTranslation ();

    const classes = useStyles ();

    const [ , setInstance ] = useState ();
    const [ approvalState, setApprovalState ] = useState ();
    const [ approvalDetails, setApprovalDetails ] = useState ();

    const { id, approvalStateId, moveable, resizable, fullWidth, maxWidth, onClose, onInitialized, $parent, ...others } = props;

    useEffect ( () => {

        ( async () => {

            if ( approvalStateId ) {

                let approvalState = await getSpecific ( "VwApprovalState", approvalStateId );

                setApprovalState ( approvalState );
    
                let approvalDetails = ( await getList ( `VwApprovalDetail/${approvalStateId}` ) ).data;
    
                setApprovalDetails ( approvalDetails );
    
            }

        } ) ();

    }, [approvalStateId])

    const onDialogInitialized = ( instance ) => {

        setInstance ( instance );
        
        if ( onInitialized ) {

            onInitialized ( instance );
            
        }

    };

    const onCloseDialog = () => {

        if ( onClose ) {
    
            onClose ();
            
        }
    
    };
    
    return (

        <Dialog
            id={id || "approval-status"}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth={maxWidth ? maxWidth : uiConf.approvalStateDialogMaxWidth}
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            {
                uiConf.modalWithCloseButton
                ?
                    <DialogHeaderWithClose>
                        <DialogTitle>
                            { t ( "dialog.title.Approval.status" ) }
                        </DialogTitle>
                    </DialogHeaderWithClose>
                :
                <DialogHeader>
                    <DialogTitle>
                        { t ( "dialog.title.Approval.status" ) }
                    </DialogTitle>
                </DialogHeader>
            }

            <DialogContent className={classes.root} dividers>

                <ApprovalStatus approvalState={approvalState} approvalDetails={approvalDetails} />

            </DialogContent>

            <DialogActionsOk
                close
            />

        </Dialog>
        
    );

}

export default ApprovalStatusDialog;

