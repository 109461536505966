
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import getNewDateObj from "../../lib/common/getNewDateObj";
import DateSelectForm from "../form/input/DateSelectForm";
import { useSetRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import SummarizeDailyStockButton from "../input/SummarizeDailyStockButton";
import SummarizeTrackingDailyStockButton from "../input/SummarizeTrackingDailyStockButton";


function SummarizeDailyStock(props) {
    const id = "DailyStockSummary"
    const table = "DailyStockSummary"
    const view = "VwDailyStockSummary"

    const today = new Date();

    const [grid, setGrid] = useState();

    const [api, setApi] = useState();

    const [applySchema, setApplySchema] = useState();
    const [trackingSchema, setTrackingSchema] = useState();

    const [isEmpty, setIsEmpty] = useState(true);

    const [searchValues, setSearchValues] = useState({
        materialType: "",
        endTime: getNewDateObj(today),
        startTime: getNewDateObj(today.setDate(today.getDate() - 1)),
    });

    const setNotification = useSetRecoilState(notificationState);

    // useEffect(() => {

    //     let mounted = true;

    //     (async () => {

    //         let schema = await getSchema(table);
    //         console.log(schema)
    //         if (mounted) {
    //             setSchema(schema);
    //         }

    //     })();

    //     return (() => {
    //         mounted = false;
    //     });


    // }, []);

    useEffect(() => {
        let applySchema = {
            name: "DailyStockSummary",
            type: "BASE TABLE",
            searchAll: false,
            // formGroups: ["data", "date"],
            columns: [
                {
                    name: "appliedDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    default: null,
                    // formGroup: 1,
                    formOrder: 9,
                    // hideInForm: true,
                    formItemType: "date",
                },
            ],
        };

        setApplySchema(applySchema);

        let trackingSchema = {
            name: "DailyStockSummary",
            type: "BASE TABLE",
            searchAll: false,
            // formGroups: ["data", "date"],
            columns: [
                {
                    name: "totalizationDate",
                    type: "DATETIME(6)",
                    nullable: "NO",
                    default: null,
                    // formGroup: 1,
                    formOrder: 9,
                    // hideInForm: true,
                    formItemType: "date",
                },
            ],
        };

        setTrackingSchema(trackingSchema);
    }, []);

    useEffect(() => {

        if (searchValues && api) {

            api.refresh();
        }


    }, [searchValues, api])

    const { t } = useTranslation();

    const onGridInitialized = (grid) => {
        // grid.headersVisibility = "All";
        // grid.rowHeaders.columns.minSize = 180;
        // grid.rowHeaders.minHeight = 72;
        grid.selectionMode = "Row";
        // grid.allowSorting = false;
        // grid.allowPinning = false;
        // grid.itemsSourceChanged.addHandler((grid, e) => setTimer((timer) => {
        //     clearTimeout(timer);
        //     return setTimeout(() => {
        //         grid.autoSizeColumns();

        //         // let rng = grid.viewRange;
        //         // for (let i = rng.leftCol; i <= rng.rightCol; i++) {
        //         //     grid.autoSizeColumn(i);
        //         // }
        //     }, 100)
        // }));
        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onModifyUrl = (url) => {

        let newUrl = `${url}/${searchValues.startTime}/${searchValues.endTime}`
        return newUrl;
    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onChangeCondition1Value = (value) => {

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: value,

            };
            return searchValues;
        })


    }
    const onChangeCondition2Value = (value) => {

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                endTime: value,

            };
            return searchValues;
        })
    };

    const onTodayClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);


        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onYesterdayClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setDate(now.getDate() - 1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setDate(now.getDate() - 1);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onPrevWeekClick = () => {

        let now = new Date();
        let start = new Date(now);

        let day = now.getDay();

        if (day === 0) {

            day = 6;

        } else {

            day--;

        }

        start.setDate(now.getDate() - 7 - day);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        let end = new Date(start);

        end.setDate(start.getDate() + 6);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onCurrentWeekClick = () => {

        let now = new Date();
        let start = new Date(now);

        let day = now.getDay();

        if (day === 0) {

            day = 6;

        } else {

            day--;

        }

        start.setDate(now.getDate() - day);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        let end = new Date(start);

        end.setDate(start.getDate() + 6);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onPrevMonthClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setMonth(now.getMonth() - 1);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setMonth(now.getMonth());
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };

    const onCurrentMonthClick = () => {

        let now = new Date();
        let start = new Date(now);
        let end = new Date(now);

        start.setMonth(now.getMonth());
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        end.setMonth(now.getMonth() + 1);
        end.setDate(0);
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        end.setMilliseconds(999);

        setSearchValues((prevValues) => {

            let searchValues = {
                ...prevValues,
                startTime: start,
                endTime: end,

            };
            return searchValues;
        })

    };


    // const exportFormatItem = (args) => {
    //     const textContent = args.getFormattedCell().textContent;

    //     if (textContent === "제품자재") {

    //         args.xlsxCell.value = "";

    //     } else {

    //         args.xlsxCell.value = textContent;

    //     }

    // };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);
    };

    const onSuccess = (msg) => {
        onNotifySuccess(msg)
    }

    const customValidation = (schema, formData) => {
        if (formData?.calculationDate > new Date()) {
            nofifyWarning(t("warning.dontChooseFuture"));
            return true;
        }
        return false
    }

    return (
        <BasicCrudScreenType
            id={id}
            table={table}
            view={view}
            noCreate
            // noSearch
            noEdit
            noDelete
            noExcel
            onModifyViewUrl={(url) => onModifyUrl(url)}
            onInitialized={onGridInitialized}
            onApiInitialized={onApiInitialized}
            onLoadData={onLoadData}
            onAppendHeaderAtFirst={() => (
                <>
                    <BasicCrudScreenActionItemLayout>

                        <div style={{ display: "inline-block" }} >
                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "7px",
                                    marginRight: "2px",
                                }}
                            >
                                시작 일 :
                            </span>

                            <DateSelectForm
                                id={"DailyStockSummary-condition1-value"}
                                value={searchValues.startTime}
                                onChange={onChangeCondition1Value}
                            />

                        </div>

                        <div style={{ display: "inline-block" }} >

                            <span
                                style={{
                                    display: "inline-block",
                                    height: "16px",
                                    paddingLeft: "15px",
                                    paddingTop: "7px",
                                    marginRight: "2px",
                                }}
                            >
                                ~ &nbsp;&nbsp;종료 일 :
                            </span>

                            <DateSelectForm
                                id={"DailyStockSummary-condition2-value"}
                                value={searchValues.endTime}
                                onChange={onChangeCondition2Value}
                            />

                        </div>
                        <Button style={{ paddingBottom: "10px" }} onClick={onYesterdayClick}>{t("term.yesterday")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onTodayClick}>{t("term.today")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onPrevWeekClick}>{t("term.prevWeek")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onCurrentWeekClick}>{t("term.currentWeek")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onPrevMonthClick}>{t("term.prevMonth")}</Button>
                        <Button style={{ paddingBottom: "10px" }} onClick={onCurrentMonthClick}>{t("term.currentMonth")}</Button>

                    </BasicCrudScreenActionItemLayout>
                </>
            )}

            onAppendButtonInFrontOfSearch={() => (
                <>
                    <BasicCrudScreenActionItemLayout>
                        <SummarizeDailyStockButton
                            id={id}
                            schema={applySchema}
                            refresh={api}
                            onSuccess={onSuccess}
                            createButtonName="apply"
                            customValidation={customValidation}
                        />

                    </BasicCrudScreenActionItemLayout>

                    <BasicCrudScreenActionItemLayout>
                        <SummarizeTrackingDailyStockButton
                            id={id}
                            schema={trackingSchema}
                            refresh={api}
                            onSuccess={onSuccess}
                            createButtonName="totalization"
                            customValidation={customValidation}
                            apiUrl="/api/DailyStockSummary/tracking"
                        />

                    </BasicCrudScreenActionItemLayout>

                </>
            )}
        />
    );

}

export default SummarizeDailyStock;
