
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import { makeStyles } from "@material-ui/styles";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import AutoForm from "../form/functional/AutoForm";

import getList from "../../lib/api/getList";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function EguResearchInputMaterialDetailDialog(props) {

    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        onSelectMst,
        onUpdateMaster,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        ...others
    } = props;

    const [keepOpen, setKeepOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [initialData, setInitailData] = useState();
    const [instance, setInstance] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const [lineNo, setLineNo] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [height, setHeight] = useState(0);

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const onViewInitialized = (grid) => {
        grid.selectionMode = "Row"
        setGrid(grid)
    }
    const onApiInitialized = (api) => {
        setApi(api)
    }

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };
    useEffect(() => {

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {

        let mounted = true;

        (async () => {

            let result = await getList('MomSysUdtLineNo');

            if (mounted) {

                setLineNo(result?.data[0]?.lineNo ? result?.data[0]?.lineNo : undefined)

            }

        })();

        return (() => {

            mounted = false;

        });

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setInitailData(grid.selectedItems[0]);

                    } else {
                        setInitailData(null)

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setInitailData(grid.selectedItems[0])

                    } else {
                        setInitailData(null)

                    }

                });
            }

        })()

    }, [grid]);

    useEffect(() => {
        if (isEmpty) {
            setInitailData(() => {
                return null;
            })
        }
    }, [isEmpty])

    useEffect(() => {

        if (initialData) {

            setFormData((preFormData) => {

                let newFormData = {
                    ...preFormData,
                    materialCode: initialData.materialCode,
                    materialCodeVer: initialData.materialCodeVer,
                    inputQty: initialData.requiredQty,
                    materialName: initialData.materialName,
                    lotNo: initialData.lotNo,
                    lineNo: lineNo,
                };

                return newFormData;
            });

        }
    }, [initialData, lineNo]);

    useEffect(() => {

        if (formData && initialData) {

            if (formData.inputQty === initialData.requiredQty) {

                setDisabled(false)
            }
        }

    }, [formData, initialData])

    const { t } = useTranslation();

    const onCloseDialog = () => {

        setDisabled(true);

        setFormData((formData) => {

            let newFormData = {
                ...formData,
                materialCode: undefined,
                materialCodeVer: undefined,
                inputQty: undefined,
                materialName: undefined,
                lotNo: undefined,
                orderBuyerId: undefined,
                requiredQty: undefined,
                menuInfo: undefined,
                process: undefined,
            }

            return newFormData
        })

        if (onClose) {

            onClose();

        }

        if (onUpdateMaster) {

            onUpdateMaster()

        }

    };

    const onCancel = () => {

        setFormData((formData) => {

            let newFormData = {
                ...formData,
                materialCode: undefined,
                materialCodeVer: undefined,
                inputQty: undefined,
                materialName: undefined,
                lotNo: undefined,
                orderBuyerId: undefined,
                requiredQty: undefined,
                menuInfo: undefined,
                process: undefined,

            }

            return newFormData
        })

        setDisabled(true);

        instance.hide();

        if (onUpdateMaster) {

            onUpdateMaster()

        }

    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);

        if (onInitialized) {

            onInitialized(instance);

        }
    };

    const onChange = (formData) => {

        setFormData(formData);

    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response
                    ? reason.response.data
                    : JSON.stringify(reason),
            })
        );
    };

    const onApiSuccess = (result) => {

        if (api) {

            api.refresh();

        }

        if (onSuccess) {

            setTimeout(onSuccess(result.data), 0);

        }

        if (!keepOpen) {

            instance.hide();

            if (onUpdateMaster) {

                onUpdateMaster()

            }


        }

    };

    const checkItemValidation = (schema, formData) => {

        for (const column of schema.columns) {

            let [valid, msg] = checkValidation(
                "create",
                column,
                column.validation,
                formData[column.name] || ""
            );

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const checkInStock = (formData) => {

        if (initialData?.qtyCurrent < formData.inputQty) {
            nofifyWarning(t("warning.outOfStock"));
            return false
        }
        return true;
    }

    const checkInput = (formData) => {

        if (formData.inputQty <= 0) {
            nofifyWarning(t("warning.invalidQty"));
            return false
        }
        return true;
    }

    const checkWareHouse = (formData) => {
        if (initialData.wareHouseCode === 'W000' && (parseFloat((formData.inputQty).toFixed(6)) !== parseFloat((initialData.qtyCurrent).toFixed(6)))) {
            nofifyWarning(t("warning.notSplitOnTheLine"))
            return false
        }
        return true;
    }

    const onOk = (schema, formData) => {

        if (!checkInStock(formData)) {
            return;
        }

        if (!checkInput(formData)) {
            return;
        }

        if (!checkWareHouse(formData)) {
            return;
        }

        if (!checkItemValidation(schema, formData)) {

            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning(
                    t("warning.formDataValidationFail", { msg: msg })
                );
                return;
            }
        }

        let url = `/api/EguResearchInputMaterialDetail/${onSelectMst?.eguResearchInputMaterialId}`;

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() =>
                                setKeepOpen((keepOpen) => !keepOpen)
                            }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={
                        <span className={classes.label}>
                            {t("term.keepDialogOpen")}
                        </span>
                    }
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    };

    return (
        <>
            <Dialog
                id={id}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth="xl"
                // fullScreen={true}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeaderWithClose>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeaderWithClose>

                <Divider />

                <DialogContent
                    style={{ height: height }}
                >
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.7}>
                            <BasicCrudScreenType
                                id={formId}
                                embededInOtherScreenType
                                headerTitle={t("term.materialInfo")}
                                headerTitleStyle={{
                                    fontSize: "1.5em",
                                    fontWeight: "bold",
                                    marginLeft: "8px",
                                    marginTop: "10px",
                                }}
                                table="EguVwPreResearchInputMaterialDetail"
                                view="EguVwPreResearchInputMaterialDetail"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                noOutputSize
                                noFilter
                                onInitialized={onViewInitialized}
                                onApiInitialized={onApiInitialized}
                                onLoadData={onLoadData}

                            />
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement>
                            <div
                                style={{
                                    padding: "32px",
                                }}
                            >
                                <AutoForm
                                    id="EguPopInputMaterialDialog"
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChange}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={[t("common.add")]}
                    buttonDisabled={[disabled, false]}
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default EguResearchInputMaterialDetailDialog;
