/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";
import { debounce } from "lodash";
import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

function MergeLotDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [formData, setFormData] = useState({});
    const [interaction, setInteraction] = useState(false);
    const [grid, setGrid] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const {
        
        id,
        schema,
        onSelect,
        standard,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,
        
        initialData,
        
        onClose,
        onInitialized,
        onSuccess,
        
        ...others
        
    } = props;


    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                nofifyWarning ( msg );
                return false;

            }
            
        }

        return true;

    }
    const nofifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {
        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };


    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if ( grid.selectedItems && grid.selectedItems[0] ) {

                        setFormData((formData) => {
                         
                            let newFormData = {
                                ...formData,
                                mergeLotNo : grid.selectedItems[0] && grid.selectedItems[0].lotNo,
                            };

                            return newFormData;
                        });

                        setInteraction(true);
                        
                    }
                    
                }, 10));
                
                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    
                    if ( grid.selectedItems && grid.selectedItems[0] ) {
                        
                        setFormData((formData) => {
                            
                            let newFormData = {
                                ...formData,
                                mergeLotNo : grid.selectedItems[0] && grid.selectedItems[0].lotNo,
                            };
                            
                            return newFormData;
                            
                        });

                        setInteraction(true);
                    }

                }, 10));

            }
            

        })();

    }, [grid]);

    useEffect ( () => {

        if(initialData) {
            
            setFormData ( ( formData ) => {
                
                let newFormData = {
                    
                    ...formData,
                    lotNo : initialData.lotNo,
                    lineNo: initialData.lineNo,
                    wareHouseCode: initialData.wareHouseCode,
                    materialCode: initialData.materialCode,
                    materialCodeVer: initialData.materialCodeVer,
                    materialName: initialData.materialName,
                    qtyCurrent: initialData.qtyCurrent,
                    processCode: initialData.processCode,
                    
                };
                
                return newFormData;
                
            } );
        }
            
    }, [initialData] );

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.updateSuccess", { table: "MomSysLotMerge" } ) );

        }

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }

        instance.hide();

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.undefinedError", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, formData ) => {

        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url
        let url ;

        if(standard) {
            
            url = `/api/Material/${onSelect.lotNo}/merge`;
        }
        else {

            url = `/api/NsMaterial/${onSelect.lotNo}/merge`;
            
        }


        let apiData = { ...formData };
        let form = new FormData ();
        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file" ) {

                deleted[formItem] = apiData[formItem].deletedFiles;
                    
                for ( const file of apiData[formItem].activeFiles ) {

                    if ( file.type === "url" ) continue;

                    form.append ( formItem, file.file );

                }

            } else {

                if ( apiData[formItem] !== undefined ) {

                    json[formItem] = apiData[formItem];

                }

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));

    }

    const onCancel = () => {

        instance.hide();

    };

    const onChange = ( formData ) => {

        setFormData ( formData );

    }

    const onGridInitialized = ( grid ) => {

        setGrid(grid);

    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setInteraction ( false );

        if ( onClose ) {

            onClose ();

        }
    }
    
    return (

        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.MomSysLogSplit.mergeLot" ) }
                </DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{padding: "0px", height: "350px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.565} >

                        <BasicCrudScreenType
                            id={standard ? "MomSysZvwSgtWipLot" : "MomSysNsZvwSgtWipLot"}
                            embededInOtherScreenType
                            headerTitle={t("term.mergeLotSelect")}
                            view={ standard ? "MomSysZvwSgtWipLot" : "MomSysNsZvwSgtWipLot"}
                            noExcel
                            onInitialized={onGridInitialized}
                            onModifyViewUrl = {(url) => ( 
                                standard 
                                ?
                                `${url}/${formData.materialCode}/${formData.materialCodeVer}/${formData.wareHouseCode}/${formData.lotNo}`
                                :
                                `${url}/${formData.materialCode}/${formData.materialCodeVer}/${formData.wareHouseCode}/${formData.materialName}/${formData.lotNo}`
                            )}

                        />

                    </ReflexElement>
                    
                    <ReflexSplitter
                        style={{width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.435} >

                    <div
                        style={{
                            padding: "16px"
                        }}                    
                    >

                        <AutoForm
                            id="merge-lot-form"
                            schema={schema}
                            mode="edit"
                            initialData={formData}
                            onChange={onChange}
                        />

                    </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("common.add")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default MergeLotDialog;
