import { DialogTitle } from "@material-ui/core";

function WijmoDialogHeader ( props ) {

    return (
        <DialogTitle className="modal-header" {...props} />
    );

}

export default WijmoDialogHeader;
