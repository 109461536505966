import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useRecoilState } from "recoil";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import getSchema from "../../lib/api/getSchema";
import apiClient from "../../lib/common/apiClient";

import notificationState from "../../states/notificationState";

import AccessControl from "../functional/AccessControl";

import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import MomSysDeliveryCreateButton from "../input/MomSysDeliveryCreateButton";
import SaveToExcelButton from "../input/SaveToExcelButton";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import MomSysDeliveryDetail from "./MomSysDeliveryDetail";
import { debounce, cloneDeep } from "lodash";

import defineConf from "../../config/defineConf";

function MomSysDelivery() {
    const id = "MomSysDelivery";
    const table = "MomSysDelivery";
    const view = "MomSysVwDelivery";

    const [height, setHeight] = useState();

    const [momSysDeliverySchema, setMomSysDeliverySchema] = useState();
    const [modifiedUpdateSchema, setModifiedUpdateSchema] = useState();

    const [momSysDeliveryGrid, setMomSysDeliveryGrid] = useState();
    const [momSysDeliveryApi, setMomSysDeliveryApi] = useState();
    const [momSysDeliveryDataIsEmpty, setMomSysDeliveryDataIsEmpty] = useState();
    const [momSysDeliverySelect, setMomSysDeliverySelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setMomSysDeliverySchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const modifiedSchema = cloneDeep(momSysDeliverySchema);

        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "shipmentNo":
                    case "shipmentOrderDate":
                    case "shipDate":
                        column.hideInForm = true;
                        break;

                    case "companyName":
                    case "orderBuyerDate":
                        column.hideInForm = false;
                        break;

                    default:
                        break;
                }
            }

            setModifiedUpdateSchema(modifiedSchema);
        }
    }, [momSysDeliverySchema]);

    useEffect(() => {
        (async () => {
            if (momSysDeliveryGrid) {
                momSysDeliveryGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDeliverySelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDeliverySelect([]);
                        }
                    },10)
                );

                momSysDeliveryGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysDeliverySelect(grid.selectedItems[0]);
                        } else {
                            setMomSysDeliverySelect([]);
                        }
                    },10)
                );
            }
        })();
    }, [momSysDeliveryGrid]);

    useEffect(() => {
        (async () => {
            if (momSysDeliveryGrid) {
                momSysDeliveryGrid.loadedRows.addHandler((flex, event) => {
                    for (let row of flex.rows) {
                        if (row.dataItem.deliveryState !== defineConf.deliveryState.COMPLETE_DELIVERY) {
                            if (new Date(row.dataItem.dueDate) < new Date()) {
                                console.log(row.dataItem.dueDate);

                                row.cssClass = `failed-stock-apply`;
                            } else {
                                row.cssClass = null;
                            }
                        }
                    }
                });
            }
        })();
    }, [momSysDeliveryGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const updateState = () => {
        if (momSysDeliverySelect) {
            apiClient.patch(`/api/MomSysDelivery/deliveryState/${momSysDeliverySelect.orderBuyerId}`);
        }
    };

    const onUpdateMaster = () => {
        if (momSysDeliveryApi) {
            momSysDeliveryApi.refresh();
        }
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);

        updateState();

        if (momSysDeliveryApi) momSysDeliveryApi.refresh();
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);

        updateState();

        if (momSysDeliveryApi) momSysDeliveryApi.refresh();
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        headerTitle={t("term.MomSysDelivery")}
                        onInitialized={(grid) => {
                            setMomSysDeliveryGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMomSysDeliveryApi(api);
                        }}
                        onLoadData={(data) => {
                            setMomSysDeliveryDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <AccessControl
                                    accessTarget={momSysDeliverySchema && momSysDeliverySchema.name}
                                    accessMethod="POST"
                                >
                                    <MomSysDeliveryCreateButton
                                        id="MomSysDelivery"
                                        schema={momSysDeliverySchema}
                                        color="primary"
                                        createButtonName="create"
                                        onSuccess={notifySuccess}
                                        refresh={momSysDeliveryApi}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={momSysDeliverySchema && momSysDeliverySchema.name}
                                    accessMethod={
                                        momSysDeliverySchema && (momSysDeliverySchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <UpdateRecordButton
                                        id="MomSysDelivery"
                                        schema={modifiedUpdateSchema}
                                        grid={momSysDeliveryGrid}
                                        refresh={momSysDeliveryApi}
                                        disabled={momSysDeliveryDataIsEmpty}
                                        onSuccess={notifySuccess}
                                    />
                                </AccessControl>

                                {/* 
                                <AccessControl
                                    accessTarget={momSysDeliverySchema && momSysDeliverySchema.name}
                                    accessMethod="DELETE"
                                >
                                    <DeleteRecordButton
                                        id="MomSysDelivery"
                                        schema={momSysDeliverySchema}
                                        grid={momSysDeliveryGrid}
                                        refresh={momSysDeliveryApi}
                                        color="secondary"
                                        disabled={momSysDeliveryDataIsEmpty}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        onPartialFailure={onNotifyWarning}
                                    />
                                </AccessControl>
                                */}

                                <AccessControl
                                    accessTarget={momSysDeliverySchema && momSysDeliverySchema.name}
                                    accessMethod="SAVETOEXCEL"
                                >
                                    <SaveToExcelButton
                                        id="MomSysDelivery"
                                        grid={momSysDeliveryGrid}
                                        view={view}
                                        color="primary"
                                        disabled={momSysDeliveryDataIsEmpty}
                                    />
                                </AccessControl>
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    {momSysDeliverySelect ? (
                        <MomSysDeliveryDetail onUpdateMaster={onUpdateMaster} shipId={momSysDeliverySelect.shipId} />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysDelivery;
