import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import CreateRecordButton from "./CreateRecordButton";
import EguOutSourcingInputInspectionRegisterDialog from "../dialogTypes/EguOutSourcingInputInspectionRegisterDialog";

function EguOutSourcingInputInspectionRegisterButton(props) {
    const { id, refresh, schema, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onRegisterSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <React.Fragment>
            <CreateRecordButton id={id} onClick={onOpen} {...others} />
            <EguOutSourcingInputInspectionRegisterDialog
                id={id + "-register-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onRegisterSuccess}
            />
        </React.Fragment>
    );
}

export default EguOutSourcingInputInspectionRegisterButton;
