import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function FormItemText ( props ) {

    const { t } = useTranslation ();
    
    const { column, InputLabelProps, value, onChange, focusRef, ...others } = props;

    const [ text, setText ] = useState ("");

    useEffect ( () => {

        setText ( value );

    }, [value, setText] );

    let textFieldProps = {
        InputLabelProps: {
            shrink: true,
            ...InputLabelProps,
        },
        onChange: ( event ) => {

            setText ( event.target.value );
            // setTimeout ( () => onChange ( event.target.value ), 0 ); // prevent input delay
            
        },
        ...others
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(text)
        }, 10)

        return () =>  clearTimeout(delayDebounceFn) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    if ( column.InputLabelProps ) {

        textFieldProps.InputLabelProps = { ...textFieldProps.InputLabelProps, ...column.InputLabelProps };

    }

    if ( column.placeholder ) {

        textFieldProps.placeholder = t ( column.placeholder );

    }

    if ( column.multiline ) {

        textFieldProps.multiline = true

        if ( column.minRows ) {

            textFieldProps.minRows = column.minRows;

        }

        if ( column.rows ) {

            textFieldProps.rows = column.rows;

        }

        if ( column.rowsMax ) {

            textFieldProps.rowsMax = column.rowsMax;

        }

    }

    if ( column.required ) {

        textFieldProps.required = true;

    }

    if ( column.variant ) {

        textFieldProps.variant = column.variant;

    }

    if ( column.inputProps ) {

        textFieldProps.inputProps = column.inputProps;

    }
    if ( column.password ) {

        textFieldProps.inputProps = { type: "password", ...textFieldProps.inputProps };

    }

    if ( column.InputProps ) {

        textFieldProps.InputProps = column.InputProps;

    }

    if ( column.style ) {

        textFieldProps.style = column.style;

    }

    return (
        <TextField value={text} inputRef={focusRef} {...textFieldProps} />
    );

}

export default FormItemText;
