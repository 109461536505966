/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";

import MomSysBomBopFileMaster from "./MomSysBomBopFileMaster";
import MomSysBomBopFileDetail from "./MomSysBomBopFileDetail";

function MomSysUploadBomBopFile(props) {

    const [file, setFile] = useState();
    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onSelectFile = ( file ) => {

        setFile ( file );

    }

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.5}>

                    <MomSysBomBopFileMaster onSelect={onSelectFile}/>

                </ReflexElement>

                <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <MomSysBomBopFileDetail file={file}/>

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MomSysUploadBomBopFile;
