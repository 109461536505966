/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TreeView, TreeItem } from '@material-ui/lab';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import getSpecific from "../../lib/api/getSpecific";

const useStyles = makeStyles({
    root: {
        height: 240,
        flexGrow: 1,
    },
} );

function MaterialNodes ( props ) {

    const { t } = useTranslation();

    const { products, level } = props;

    if ( !products ) {

        return null;

    }

    return ( products.map ( ( material ) => {

        let label;

        switch ( material.category ) {
    
            case "inputMaterial":
    
                label = `${material.materialName}[${material.materialCode}/${material.materialCodeVer}] ${t("term.qty")} [${material.qty}]`;
                break;
    
            default:
    
                label = `${material.materialName}[${material.materialCode}/${material.materialCodeVer}] ${t("term.process")} [${material.processName}(${material.processCode})] ${t("term.qty")} [${material.qty}]`;
                break;
    
        }
    
        return (

            material.products && material.products.length > 0
            ? 
            <TreeItem key={`${material.materialCode}/${material.materialCodeVer}/${level}`} nodeId={`${material.materialCode}/${material.materialCodeVer}/${level}`} label={label}>
                <MaterialNodes products={material.products} level={level+1} />
            </TreeItem>
            :
            <TreeItem key={`${material.materialCode}/${material.materialCodeVer}/${level}`} nodeId={`${material.materialCode}/${material.materialCodeVer}/${level}`} label={label} />
    
        );

    } ) );

}

function MomSysProductTree ( props ) {

    const { t } = useTranslation();

    const classes = useStyles();

    const { material, onError } = props;

    const [products, setProducts] = useState();
    const [code, setCode ] = useState();
    const [ver, setVer] = useState();
    const [name, setName] = useState();
    const [qty, setQty] = useState(1.0);
    const [expanded, setExpanded] = useState([]);

    const getMaterialExpanded = ( products, level ) => {

        let expanded = [];

        if ( !products ) {
                
                return expanded;
    
        }

        for ( const material of products ) {

            expanded.push ( `${material.materialCode}/${material.materialCodeVer}/${level}` );
            expanded = [...expanded, ...getMaterialExpanded ( material.products, level + 1 )];

        }

        return expanded;

    }

    const getExpanded = ( tree ) => {

        let expanded = [];

        expanded.push (`${tree.materialCode}/${tree.materialCodeVer}/0` )

        expanded = [...expanded, ...getMaterialExpanded ( tree.products, 1 )]

        return expanded;

    }
 
    useEffect ( () => {

        ( async () => {

            try {

                let productTree = await getSpecific ( "MomSysProductTree", material ? material.materialCode : undefined, material ? material.materialCodeVer : undefined, material ? ( material.qty ? parseFloat ( material.qty ) : 1.0 ) : 1.0);


                setExpanded ( getExpanded ( productTree ) );
    
                setCode ( productTree.materialCode );
                setVer ( productTree.materialCodeVer );
                setName ( productTree.materialName );
                setProducts ( productTree.products );
                setQty ( material.qty || 1.0);
    
            } catch ( reason ) {

                if ( onError ) {

                    onError ( reason );
                    
                }
            }

        } ) ();

    }, [material] )

    const onNodeToggle = ( event, nodeIds ) => {

        setExpanded ( nodeIds );

    };

    return (
        material && code
        ?
        (
            products
            ?
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={onNodeToggle}
            >
                {
                    name
                    ?
                    <TreeItem key={`${code}/${ver}/0`} nodeId={`${code}/${ver}/0`} label={`${name}[${code}/${ver}] ${t("term.qty")} [${qty}]`}>
                        <MaterialNodes products={products} level={1}/>
                    </TreeItem>
                    :
                    <></>
                }
            </TreeView>
            :
            <Typography color="inherit" variant="h6" style={{margin: "auto", position: "absolute", top: "50%", left: "35%", transform: "translateY(-50%)"}}>{ t("error.noProductTree") }</Typography>
        )
        :
        <></>
    );

}

export default MomSysProductTree;

