import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";

function MomSysProductInputStatus ( props ) {

    const { t } = useTranslation ();

    return (
            <BasicCrudScreenType 
                id="MomSysVwProductProductionStatus" 
                view="MomSysVwProductProductionStatus" 
                headerTitle = {t("term.productProductionStatus")}
                onInitialized = {(grid) => grid.selectionMode = "None"}
                noExcel
            />
    )
}   

export default MomSysProductInputStatus;
