import React, { useEffect, useState } from "react";
import layoutConf from "../../config/layoutConf";
import defineConf from "../../config/defineConf";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";

import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { cloneDeep, debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";

import MrpMaterialListRegisterButton from "../input/MrpMaterialListRegisterButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import MrpMaterialEventData from "./MrpMaterialEventData";

function MrpMaterialList(props) {
    const id = "MrpMaterialList";
    const table = "MrpMaterialList";
    const view = "MrpVwMaterialList";

    const [height, setHeight] = useState(0);

    const [schema, setSchema] = useState();
    const [modifiedSchemaForUpdate, setModifiedSchemaForUpdate] = useState();

    const [mrpMaterialListSelect, setMrpMaterialListSelect] = useState();
    const [mrpMaterialListData, setMrpMaterialListData] = useState();
    const [mrpMaterialListIsEmpty, setMrpMaterialListIsEmpty] = useState();
    const [mrpMaterialListGrid, setMrpMaterialListGrid] = useState();
    const [mrpMaterialListApi, setMrpMaterialListApi] = useState();

    const [mrpEventDataList, setMrpEventDataList] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        window.addEventListener("resize", handleResize);
        handleResize();

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setSchema(schema);
            }
        })();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let modifiedSchemaForUpdate = cloneDeep(schema);

        if (modifiedSchemaForUpdate && mrpMaterialListSelect) {
            for (const column of modifiedSchemaForUpdate.columns) {
                switch (column.name) {
                    case "basePurchasingTime":
                    case "adhocPurchasingTime":
                    case "adhocPurchasingTimeQty":
                        // Note : materialTypeCode is VARCHAR(50)
                        const materialTypeCode = parseInt(mrpMaterialListSelect?.materialTypeCode);

                        if (
                            materialTypeCode === defineConf.materialTypeCode.RAW_MATERIAL ||
                            materialTypeCode === defineConf.materialTypeCode.SUBSIDIARY_MATERIAL
                        ) {
                            column.hideInForm = false;
                        } else {
                            column.hideInForm = true;
                        }

                        break;

                    case "materialTypeName":
                        column.hideInForm = true;
                        break;

                    default:
                        break;
                }
            }

            setModifiedSchemaForUpdate(modifiedSchemaForUpdate);
        }
    }, [schema, mrpMaterialListSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (mrpMaterialListGrid && mounted) {
                mrpMaterialListGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMrpMaterialListSelect(grid.selectedItems[0]);
                        } else {
                            setMrpMaterialListSelect([]);
                        }
                    },10)
                );

                mrpMaterialListGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMrpMaterialListSelect(grid.selectedItems[0]);
                        } else {
                            setMrpMaterialListSelect([]);
                        }
                    },10)
                );

                mrpMaterialListGrid.loadedRows.addHandler(
                    debounce((grid, event) => {
                        for (let row of grid.rows) {
                            if (row.dataItem.qtyCurrent < row.dataItem.qtySafetyStock) {
                                row.cssClass = `failed-stock-apply`;
                            } else {
                                row.cssClass = null;
                            }
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [mrpMaterialListGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (mrpMaterialListData && mounted) {
                const { data: producingTimeList } = await getSpecific("MrpMaterialProducingTime");

                for (const item of mrpMaterialListData) {
                    const mat = producingTimeList.find(
                        (v) => v.materialCode === item.materialCode && v.materialCodeVer === item.materialCodeVer
                    );

                    if (mat) {
                        item.baseProducingTime = mat.producingTimeInSecPerCount;
                    }
                }

                /*** 2022/08/16 itkim : grid.refresh() is useless
                if (mrpMaterialListGrid) {
                    mrpMaterialListGrid.refresh(false); // false : refresh grid content only
                }
                ***/
            }
        })();

        return () => {
            mounted = false;
        };
    }, [mrpMaterialListData, mrpMaterialListGrid]);

    /***
    const onUpdateMaster = (eventDataList) => {
        if (
            mrpMaterialListSelect &&
            mrpMaterialListGrid &&
            eventDataList &&
            eventDataList?.length > 0 &&
            mrpMaterialListSelect.materialCode === eventDataList[0].eventMaterialCode &&
            mrpMaterialListSelect.materialCodeVer === eventDataList[0].eventMaterialCodeVer
        ) {
            const selectedItem = mrpMaterialListGrid.selectedItems[0];

            const firstLackingEventData = eventDataList.find((v) => v.netQty < 0);
            const lastEventData = eventDataList[eventDataList.length - 1];

            if (firstLackingEventData) {
                selectedItem.firstLackingQty = -1.0 * firstLackingEventData.netQty;
                selectedItem.firstLackingDate = firstLackingEventData.eventTime;
            }

            if (lastEventData.netQty < 0) {
                selectedItem.totalLackingQty = -1.0 * lastEventData.netQty;
            }

            mrpMaterialListGrid.refresh(false); // false : refresh grid content only
        }
    };
    ***/

    useEffect(() => {
        if (
            mrpMaterialListSelect &&
            mrpMaterialListGrid &&
            mrpEventDataList &&
            mrpEventDataList?.length > 0 &&
            mrpMaterialListSelect.materialCode === mrpEventDataList[0].eventMaterialCode &&
            mrpMaterialListSelect.materialCodeVer === mrpEventDataList[0].eventMaterialCodeVer
        ) {
            const selectedItem = mrpMaterialListGrid.selectedItems[0];

            const firstLackingEventData = mrpEventDataList.find((v) => v.netQty < 0);
            const lastEventData = mrpEventDataList[mrpEventDataList.length - 1];

            if (firstLackingEventData) {
                selectedItem.firstLackingQty = -1.0 * firstLackingEventData.netQty;
                selectedItem.firstLackingDate = firstLackingEventData.eventTime;
            }

            if (lastEventData.netQty < 0) {
                selectedItem.totalLackingQty = -1.0 * lastEventData.netQty;
            }

            /*** 2022/08/16 itkim : grid.refresh() is useless
            mrpMaterialListGrid.refresh(false); // false : refresh grid content only
            ***/
        }
    }, [mrpMaterialListSelect, mrpMaterialListGrid, mrpEventDataList]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} data-cy="Master">
                    <BasicCrudScreenType
                        id="MrpVwMaterialList"
                        view={view}
                        headerTitle={t("term.mrpMaterialList")}
                        onLoadData={(data) => {
                            setMrpMaterialListIsEmpty(data.length < 1 ? true : false);
                            setMrpMaterialListData(data);
                        }}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setMrpMaterialListGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMrpMaterialListApi(api);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <MrpMaterialListRegisterButton
                                        id={id}
                                        schema={schema}
                                        refresh={mrpMaterialListApi}
                                        onSuccess={onSuccess}
                                    />

                                    <UpdateRecordButton
                                        id={id}
                                        schema={modifiedSchemaForUpdate}
                                        grid={mrpMaterialListGrid}
                                        refresh={mrpMaterialListApi}
                                        disabled={!mrpMaterialListSelect}
                                        onSuccess={onSuccess}
                                    />

                                    <DeleteSingleRecordButton
                                        id={id}
                                        schema={schema}
                                        grid={mrpMaterialListGrid}
                                        refresh={mrpMaterialListApi}
                                        makeItemName={(row) => {
                                            return `${t("columnName.materialCode")}: ${row.materialCode}, ${t(
                                                "columnName.materialCodeVer"
                                            )}: ${row.materialCodeVer}`;
                                        }}
                                        color="secondary"
                                        disabled={mrpMaterialListIsEmpty || !mrpMaterialListSelect}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.5} data-cy="Detail">
                    {!mrpMaterialListIsEmpty && mrpMaterialListSelect ? (
                        <MrpMaterialEventData
                            mrpMaterialListSelect={mrpMaterialListSelect}
                            onEventDataLoad={(data) => {
                                setMrpEventDataList(data);
                            }}
                        />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MrpMaterialList;
