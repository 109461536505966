/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import getList from "../../lib/api/getList";

function MaterialList ( props ) {

    const { t } = useTranslation ();

    const { product, lineNo, qty, process, onMaterialChecked } = props;

    const [ , setApi ] = useState ();

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [product, lineNo, qty, process] );

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "None";

        grid.itemsSourceChanged.addHandler((grid, event) => {

            let materialInStock = true;

            for ( const row of grid.rows ) {

               if ( row.dataItem.qtyInStock < row.dataItem.qtyRequired ) {

                   materialInStock = false;

               }

               if ( onMaterialChecked ) {

                    onMaterialChecked ( materialInStock );

               }
    
            }
    
        } );

    }

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onModifyUrl = ( product, lineNo, qty, process, url ) => {
    
        let newUrl;

        if ( process ) {

            newUrl = `${url}/${product?product.orderBuyerId:null}/${product?product.materialCode:null}/${product?product.materialCodeVer:null}/${lineNo}/${qty||0}/${process}`;

        } else {

            newUrl = `${url}/${product?product.orderBuyerId:null}/${product?product.materialCode:null}/${product?product.materialCodeVer:null}/${lineNo}/${qty||0}`;

        }

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="MomSysVwMaterialsOnLine"
            headerTitle={t("term.inputMaterial")}
            table="MomSysVwMaterialsForProcessOnLine"
            view="MomSysVwMaterialsForProcessOnLine"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewUrl={ ( url ) => onModifyUrl ( product, lineNo, qty, process, url ) }
            onInitialized={onGridInitialized}
            onApiInitialized={ onApiInitialized }
        />

    );

};

function ProduceDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [disabled, setDisabled] = useState ( false );
    const [formData, setFormData] = useState({});
    const [lineNo, setLineNo] = useState();
    const [schema, setSchema] = useState();
    const [qty, setQty] = useState();
    const [process, setProcess] = useState();
    const [materialInStock, setMaterialInStock] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        product,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        ...others

    } = props;

    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                notifyWarning ( msg );
                return false;

            }
            
        }

        if ( formData.qty <= 0 ) {

            notifyWarning ( t ( "error.emptyOrNegativeProductionQty") );
            return false;


        }
        return true;

    }

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };


    useEffect ( () => {

        ( async () => {

            let result = await getList ( "MomSysUdtLineNo" );
            let lineNoList = result && result.data ? result.data : [];
    
            if ( lineNoList.length > 0 ) {
    
                setFormData ( ( formData ) => {
    
                    let newFormData = { ...formData };
                    newFormData.lineNo = lineNoList[0].lineNo;
                    newFormData.productDate = new Date();
    
                    return newFormData;
    
                } );
    
                setLineNo ( lineNoList[0].lineNo );
                
            }

            let schema = {

                name: "Production",
                type: "BASE TABLE",
                searchAll: false,
                formGroups: [
                    "materialInfo",
                    "lineNoAndQty"
                ],
                "columns": [
                                    
                    {
                        
                        name: "bopMaterialCode",
                        type: "varchar(50)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: null,
                        formGroup: 0,
                        formOrder: 1,
                        formItemType: "text",
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },
                    {
    
                        name: "bopMaterialCodeVer",
                        type: "int(11)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: "0",
                        formGroup: 0,
                        formOrder: 2,
                        formItemType: "number",
                        validation: {
                            msg: "error.numberRequired",
                            type: "regexp",
                            value: "^[0-9]*$"
                        }
    
                    },
                    {
    
                        name: "bopMaterialName",
                        type: "varchar(50)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: null,
                        formGroup: 0,
                        formOrder: 3,
                        formItemType: "text",
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },
                    {

                        name: "processCode",
                        type: "varchar(50)",
                        nullable: "NO",
                        default: null,
                        formGroup: 0,
                        formOrder: 4,
                        formItemType: "autocomplete",
                        codeInfo: {
                            tableName: `MomSysVwOrderedBillOfProcess/${product?product.materialCode:undefined}/${product?product.materialCodeVer:undefined}`,
                            nameColumn: ["processCode", "processName"],
                            valueColumn: "processCode",
                            seperator: ": ",
                            useEmptyValue: false,
                            sortByName: true,
                        }
            
                    },    
                    {

                        name: "productDate",
                        type: "varchar(50)",
                        nullable: "NO",
                        default: null,
                        formGroup: 0,
                        formOrder: 5,
                        formItemType: "date",
                    },    
                    {
                        name: "lineNo",
                        type: "varchar(50)",
                        nullable: "NO",
                        required: true,
                        default: lineNoList.length > 0 ? lineNoList[0].lineNo : null,
                        formGroup: 1,
                        formOrder: 5,
                        formItemType: "select",
                        selectItems: lineNoList.map ( ( item ) => ( {
                            name: item.lineNo,
                            value: item.lineNo
                        } ) ),
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },
                    {

                        name: "qty",
                        type: "float",
                        nullable: "NO",
                        key: "",
                        required: true,
                        default: null,
                        formGroup: 1,
                        formOrder: 6,
                        formItemType: "text",
                        validation: {
                          msg: "error.floatRequired",
                          type: "regexp",
                          value: "^[+-]?([0-9]*[.])?[0-9]+$"
                        }

                    },
                    {
    
                        name: "description",
                        type: "varchar(50)",
                        nullable: "NO",
                        key: "",
                        default: null,
                        formGroup: 1,
                        formOrder: 7,
                        formItemType: "text",
                        width: "94%",
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },    
                ]
    
            };

            setSchema ( schema );
        
            if ( product ) {

                let processList = await getList ( `MomSysVwOrderedBillOfProcess/${product?product.materialCode:undefined}/${product?product.materialCodeVer:undefined}` );               
                
                setFormData ( ( formData ) => {
    
                    let newFormData = {
                        ...formData,
                        bopMaterialCode: product.materialCode,
                        bopMaterialCodeVer: product.materialCodeVer,
                        bopMaterialName: product.materialName,
                        processCode: processList ? processList.data[0].processCode : undefined,
                        qty: parseFloat(parseFloat(product.qtyRequired)),
                    };
    
                    return newFormData;
    
                } );
    
                setQty ( product.qtyRequired );
    
            } else {
    
                setFormData ( ( formData ) => {
    
                    let newFormData = {
                        ...formData,
                        bopMaterialCode: undefined,
                        bopMaterialCodeVer: undefined,
                        bopMaterialName: undefined,
                        qty: undefined,
                    };
    
                    return newFormData;
    
                } );
    
                setQty ( undefined );
    
            }

        } ) ();

    }, [product])

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.insertSuccess", { table: "Production" } ) );

        }

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
            }

            return newFormData;

        } );

        setDisabled ( true );
        instance.hide();

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const onOk = ( schema, product, formData, materialInStock ) => {
        
        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );
            return;

        }

        if ( !materialInStock ) {

            console.error ( t("error.materialNotOnProductionLine") );
            notifyFailure ( t ("error.materialNotOnProductionLine") );

            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {

                notifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;

            }

        }

        // build base api url

        let url = "/api/Production";

        let apiData = { ...formData, orderBuyerId: product.orderBuyerId };
            
        let form = new FormData ();

        let json = {};
        let deleted = {};

        for ( const formItem in apiData ) {

            if ( apiData[formItem] !== undefined ) {

                json[formItem] = apiData[formItem];

            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
            
    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
                productDate: undefined
            }

            return newFormData;

        } );

        setDisabled ( true );
        instance.hide();

    };

    const onChange = ( formData, newFormData ) => {

        if ( formData.lineNo !== newFormData.lineNo ) {

            setLineNo ( newFormData.lineNo );

        }

        if ( formData.qty !== newFormData.qty ) {

            setQty ( newFormData.qty );

        }

        if ( formData.processCode !== newFormData.processCode ) {

            setProcess ( newFormData.processCode );

        }

        setFormData ( newFormData );
        setDisabled ( false );

    }

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setDisabled ( true );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
                componentQty: undefined,
                description: undefined,
                productDate: undefined
            }

            return newFormData;

        } );

        setProcess ( undefined );
        
        if ( onClose ) {

            onClose ();

        }

    };

    const onMaterialChecked = ( materialInStock ) => {

        setMaterialInStock ( materialInStock );

    };
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeader>
                <DialogTitle>
                    { t ( "dialog.title.Produce.startProduction" ) }
                </DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{padding: "0px", height: "330px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.55} >

                        <MaterialList product={product} lineNo={lineNo} qty={qty} process={process} onMaterialChecked={onMaterialChecked} />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.45} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id="bop-out-material-warehouse-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={ ( newFormData ) => onChange( formData, newFormData ) }
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("buttonName.produce")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, product, formData, materialInStock)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default ProduceDialog;
