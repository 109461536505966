import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import notificationState from "../../states/notificationState";
import defineConf from "../../config/defineConf";

import { useTranslation } from "react-i18next";
import { cloneDeep, debounce } from "lodash";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import getSchema from "../../lib/api/getSchema";
import EguOutSourcingInputInspectionRegisterButton from "../input/EguOutSourcingInputInspectionRegisterButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import EguOutSourcingInputInspectionInputButton from "../input/EguOutSourcingInputInspectionInputButton";

function EguOutSourcingInputInspection(props) {
    const id = "EguOutSourcingInputInspection";
    const view = "EguVwOutSourcingInputInspection";
    const table = "EguOutSourcingInputInspection";

    const [height, setHeight] = useState(0);
    const { t } = useTranslation();

    const [modifiedSchema, setModifiedSchema] = useState();
    const [outSourcingInputInspectionSchema, setOutSourcingInputInspectionSchema] = useState();
    const [outSourcingInputInspectionGrid, setOutSourcingInputInspectionGrid] = useState();
    const [outSourcingInputInspectionApi, setOutSourcingInputInspectionApi] = useState();
    const [outSourcingInputInspectionSelect, setOutSourcingInputInspectionSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setOutSourcingInputInspectionSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let modifiedSchema = cloneDeep(outSourcingInputInspectionSchema);

        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "qcResult":
                        column.required = false;
                        break;

                    default:
                        break;
                }
            }

            setModifiedSchema(modifiedSchema);
        }
    }, [outSourcingInputInspectionSchema]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outSourcingInputInspectionGrid && mounted) {
                outSourcingInputInspectionGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingInputInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingInputInspectionSelect();
                        }
                    }, 10)
                );

                outSourcingInputInspectionGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutSourcingInputInspectionSelect(grid.selectedItems[0]);
                        } else {
                            setOutSourcingInputInspectionSelect();
                        }
                    }, 10)
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outSourcingInputInspectionGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id={id}
                view={view}
                headerTitle={t("term.eguOutSourcingInputInspection")}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setOutSourcingInputInspectionGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setOutSourcingInputInspectionApi(api);
                }}
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <React.Fragment>
                        <BasicCrudScreenActionItemLayout>
                            <EguOutSourcingInputInspectionRegisterButton
                                id={id}
                                schema={outSourcingInputInspectionSchema}
                                refresh={outSourcingInputInspectionApi}
                                onSuccess={onSuccess}
                            />

                            <UpdateRecordButton
                                id={id}
                                schema={modifiedSchema}
                                grid={outSourcingInputInspectionGrid}
                                refresh={outSourcingInputInspectionApi}
                                modifyFormDataCallback={(formData) => {
                                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                        formData.defectiveCode = "INS000";
                                    }
                                }}
                                disabled={
                                    !outSourcingInputInspectionSelect ||
                                    outSourcingInputInspectionSelect?.outSourcing ===
                                        defineConf.outSourcing.NON_OUTSOURCED ||
                                    outSourcingInputInspectionSelect?.outSourcingStatus ===
                                        defineConf.outSourcingStatus.INPUT_COMPLETE
                                }
                                onSuccess={onSuccess}
                            />

                            <DeleteSingleRecordButton
                                id={id}
                                schema={modifiedSchema}
                                grid={outSourcingInputInspectionGrid}
                                refresh={outSourcingInputInspectionApi}
                                makeItemName={(row) => {
                                    return ` - ${t("columnName.outSourcingInputInspectionId")}: ${
                                        row.outSourcingInputInspectionId
                                    }`;
                                }}
                                color="secondary"
                                disabled={
                                    !outSourcingInputInspectionSelect ||
                                    outSourcingInputInspectionSelect?.outSourcing ===
                                        defineConf.outSourcing.NON_OUTSOURCED ||
                                    outSourcingInputInspectionSelect?.outSourcingStatus ===
                                        defineConf.outSourcingStatus.INPUT_COMPLETE
                                }
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                            />

                            <EguOutSourcingInputInspectionInputButton
                                id={id}
                                schema={outSourcingInputInspectionSchema}
                                grid={outSourcingInputInspectionGrid}
                                refresh={outSourcingInputInspectionApi}
                                disabled={
                                    !outSourcingInputInspectionSelect ||
                                    outSourcingInputInspectionSelect?.qcResult !== defineConf.qcResult.QC_OK ||
                                    outSourcingInputInspectionSelect?.outSourcing ===
                                        defineConf.outSourcing.NON_OUTSOURCED ||
                                    outSourcingInputInspectionSelect?.outSourcingStatus ===
                                        defineConf.outSourcingStatus.INPUT_COMPLETE ||
                                    outSourcingInputInspectionSelect?.wareHouseName
                                }
                                onSuccess={onSuccess}
                            />
                        </BasicCrudScreenActionItemLayout>
                    </React.Fragment>
                )}
            />
        </div>
    );
}

export default EguOutSourcingInputInspection;
