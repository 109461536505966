import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import MrpMaterialListRegisterDialog from "../dialogTypes/MrpMaterialListRegisterDialog";

function MrpMaterialListRegisterButton(props) {
    const { id, refresh, schema, onSuccess, customizeErrorMsgCallback, ...others } = props;

    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onRegisterSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }
        refresh.refresh();
    };

    return (
        <React.Fragment>
            <ActionButtonLayout id={id + "create-button"} onClick={onOpen} {...others}>
                {t("buttonName.create")}
            </ActionButtonLayout>
            <MrpMaterialListRegisterDialog
                id={id + "-register-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onRegisterSuccess}
            />
        </React.Fragment>
    );
}

export default MrpMaterialListRegisterButton;
