import { useState } from "react";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysShipOfOrderBuyerDialog from "../dialogTypes/MomSysShipOfOrderBuyerDialog";

function MomSysShipOfOrderBuyerButton(props) {
    const { id, grid, refresh, schema, createButtonName, onSuccess, customizeErrorMsgCallback, ...others } = props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout id={id+"create-button"} onClick={onOpen} createButtonName ={createButtonName} {...others} />
            <MomSysShipOfOrderBuyerDialog
                id={id + "ship-dialog"}
                schema={schema}
                open={open}
                onClose={onClose}
                onSuccess={onShipSuccess}
            />
        </>
    );
}

export default MomSysShipOfOrderBuyerButton;
