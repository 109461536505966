import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function UserMaterialInOutReport (props){

    const table = "UserMaterialInOutReport";
    const view = "UserMaterialInOutReport";

    const { startDate, endDate, onSearchInitialized, onFilterInitialized, apiInitialized } = props;
    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if ( startDate && endDate && api ) {

            api.refresh ();

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api, startDate, endDate ] );

    const onModifyUrl = ( url ) => {

        let newUrl = `${url}/${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth()+1}/${endDate.getDate()}`        

        return newUrl;

    };

    const onApiInitialized = ( api ) => {
        
        if(apiInitialized) {
            apiInitialized(api)
        } 

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {
        grid.allowSorting = false;
    };

    return (
    
        <BasicCrudScreenType
            id="UserMaterialInOutReport"
            noHeader
            table={table}
            view={view}
            viewSchemaUrl={`/api/UserMaterialInOutReportSchema/${startDate.getFullYear()}/${startDate.getMonth()+1}/${startDate.getDate()}/${endDate.getFullYear()}/${endDate.getMonth()+1}/${endDate.getDate()}`}
            onModifyViewUrl={onModifyUrl}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onFilterInitialized={onFilterInitialized}            
            onInitialized={onGridInitialized}
        />

    );

}
export default UserMaterialInOutReport;